import React, { useEffect } from "react";
import Switch from "@mui/material/Switch";
import moment from "moment";
import { axiosE } from "Service/service";

function UserActivityForm() {
  const [checked, setChecked] = React.useState(false);
  const [isNotificationSchedule, setIsNotificationSchedule] =
    React.useState(false);
  const [notificationTitle, setNotificationTitle] = React.useState("");
  const [notificationText, setNotificationText] = React.useState("");
  const [notificationDate, setNotificationDate] = React.useState("");
  const [result, setResult] = React.useState();

  const handleChange = (event) => {
    setChecked(event.target.checked);
    setIsNotificationSchedule(event.target.checked);
  };

  const handleSubmit = () => {
    const formattedDate = moment
      .utc(notificationDate)
      .local()
      .format("YYYY-MM-DD hh:mm ");

    let data = JSON.stringify({
      UserId: localStorage.getItem("UserId"),
      OrganizerId: localStorage.getItem("UserId"),
      EventId: JSON.parse(localStorage.getItem("CurrentEventId")),
      ScheduleDateTime: !checked ? "" : formattedDate,
      Title: notificationTitle,
      Body: notificationText,
      SendImmediately: !checked,
    });



    axiosE
      .post("/MobileNotificationQueue/Add", data, {
        headers: {
          Authorization: localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      })
      .then((response) => {

        setResult(response?.data?.results?.Message);
        setNotificationDate("");
        setNotificationText("");
        setNotificationTitle("");
        setTimeout(() => {
          setResult();
        }, 4000);
      })
      .catch((error) => console.log(error));
  };

  return (
    <div className="userActivityForm">
      <div className="noti">
        <p>
          Notification title <span>*</span>
        </p>
        <input
          type="text"
          value={notificationTitle}
          onChange={(e) => setNotificationTitle(e.target.value)}
          placeholder="How is your experience at Garlic 2020?"
        />
      </div>
      <div className="noti">
        <p>
          Notification text <span>*</span>
        </p>
        <textarea
          name=""
          id=""
          value={notificationText}
          onChange={(e) => setNotificationText(e.target.value)}
          cols="30"
          rows="10"
          placeholder="Thankyou coming out to Garlic fest 2020"
        />
      </div>
      <div className="switchButtonNoti">
        <p>Schedule Notification</p>
        <Switch
          checked={checked}
          onChange={handleChange}
          inputProps={{ "aria-label": "controlled" }}
        />
      </div>
      {isNotificationSchedule && (
        <div className="noti">
          <p>
            Notification date <span>*</span>
          </p>
          <input
            value={notificationDate}
            onChange={(e) => setNotificationDate(e.target.value)}
            type={"datetime-local"}
          />
        </div>
      )}

      {result && <p className="notificationResult">{result}</p>}
      <div
        className={` ${
          notificationText && notificationTitle && !checked
            ? "scheduleButton"
            : notificationDate && notificationText && notificationTitle
            ? "scheduleButton"
            : "scheduleButton-disabled"
        }`}
        onClick={() => handleSubmit()}
      >
        <h2>{isNotificationSchedule ? "Schedule Notification" : "Send Now"}</h2>
      </div>
      <p className="fieldReq">fields are required</p>
    </div>
  );
}

export default UserActivityForm;
