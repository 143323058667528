import moment from "moment";
import { array } from "prop-types";
import React, { useState } from "react";
import { TbMailShare } from "react-icons/tb";
import { axiosE } from "../../../../../Service/service";
import * as constants from "Constants";
import { Button, Modal } from "@material-ui/core";
const ScheduleEmail = ({
  setFiles,
  setIsEdit,
  setEventTitle,
  setdataArray,
  scheduledEmailData,
  setScheduledEmailData,
  setEditScheduleEmailData,
  setEditorState,
  setMainEventId,
}) => {
  const [modal, setModal] = useState({ isShow: false, QueueId: "" });
  const handleClick = (QueueId) => {
    axiosE
      .get(`/GetEmailQueueInvitationByQueueId/${QueueId}`)
      .then(async ({ data }) => {
        setIsEdit(QueueId);
        setdataArray([]);
        setFiles([]);
        // setEditorState();
        setEditScheduleEmailData(data.results);
        setMainEventId(data.results.MainEventId);
        let EventIds = [];
        if (data.results.EventIds?.length > 0) {
          EventIds = data.results.EventIds.split(", ");

          if (!EventIds[0] === data.results.MainEventId) {
            EventIds = EventIds.filter((id) => id !== data.results.MainEventId);
            EventIds.unshift(data.results.MainEventId);
          }
        }

        fetchEmailEditScheduleData(EventIds);
      })
      .catch((error) => {});
    console.log(QueueId);
  };

  const fetchEmailEditScheduleData = async (EventIds) => {
    // console.time("for {}");
    for (let i = 0; i < EventIds.length; i++) {
      await GetEventMasterById(EventIds[i]);
    }
    // console.time("for {}");

    // await Promise.all(
    //   EventIds.map(async (id) => {
    //     GetEventMasterById(id);
    //   })
    // );
  };

  const GetEventMasterById = async (id) => {
    try {
      let userId = localStorage.getItem("UserId");
      const res = await axiosE.get(`/GetEventMasterById/${id}/${userId}/Admin`);
      if (res.data.results.ResponseCode) {
        setdataArray((prev) => [...prev, res.data.results]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleDelete = async (QueueId) => {
    try {
      const res = await axiosE.post("/QueueEventInvitationEmail/Delete", {
        QueueId,
      });
      setModal({ isShow: false, QueueId: "" });
      setScheduledEmailData(
        scheduledEmailData.filter((e) => e.QueueId !== QueueId)
      );
    } catch (error) {}
  };

  const scheduleEmailDeleteModal = () => {
    // const { classes } = this.props;
    // const { show, index } = this.state.deleteTicketModal;
    const deleteLabels = constants.Tickets.deleteTicket;
    return (
      <Modal
        open={modal.isShow}
        onClose={() => setModal({ isShow: false, QueueId: "" })}
      >
        <div className="delete_modal_wrapper">
          <div
            style={{
              width: "100px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100px",
            }}
          >
            <img
              src={deleteLabels.img}
              alt="Delete Icon"
              style={{ width: 30 }}
              // className={classes.deleteModalImg}
            />
          </div>
          <p style={{ textAlign: "center" }}>
            Are you sure you want to delete this scheduled email?
          </p>
          <div>
            <Button
              // className={classes.deleteModalSaveAndCancelButton}
              onClick={() => setModal({ isShow: false, QueueId: "" })}
              style={{
                color: "blue",
                fontSize: "1.5rem",
                padding: "10px 25px",
              }}
            >
              Cancel
            </Button>

            <Button
              // className={classes.deleteModalSaveAndCancelButton}
              onClick={() => handleDelete(modal.QueueId)}
              style={{ color: "red", fontSize: "1.5rem", padding: "10px 25px" }}
            >
              Delete
            </Button>
          </div>
        </div>
      </Modal>
    );
  };

  const convertTimeUtcToLocal = (time, TimeZone) => {
    const UtcToLocal = moment.utc(time).local().format("MM/DD/YYYY hh:mm:ss A");
    var Time = moment(UtcToLocal, "MM/DD/YYYY hh:mm:ss A");

    const convertedTime = Time.tz(TimeZone);
    return moment(convertedTime);
  };

  //  console.log("@@@@@@@@",scheduledEmailData);
  //  console.log("filter#############",scheduledEmailData.filter((t) => moment(t.ScheduleDateTime, "MM/DD/YYYY HH:mm:ss A").isAfter(
  //   moment(moment().tz(t.EventTimeZone), "MM/DD/YYYY HH:mm:ss A")
  // )));

  return (
    <React.Fragment>
      {scheduleEmailDeleteModal()}
      {scheduledEmailData && scheduledEmailData?.length > 0 ? (
        <div className="scheduled_email">
          <p className="scheduled_email_invitation_title">
            {constants.Text.ScheduledEmailInvitations}
          </p>
          {scheduledEmailData &&
            scheduledEmailData.map((items, idx) => (
              <div className="scheduled_email_container" key={idx}>
                <div className="scheduled_email_icon_element">
                  <TbMailShare size={25} color={"gray"} />
                  <div className="scheduled_email_title_time_element">
                    <p className="scheduled_email_title">
                      {items?.Subject === ""
                        ? "Event Name is not specified"
                        : items?.Subject}
                    </p>
                    <p className="scheduled_email_time">
                      {constants.Text.ScheduledFor}{" "}
                      {moment(
                        convertTimeUtcToLocal(
                          items?.ScheduleDateTime,
                          items.EventTimeZone
                        )
                      ).format("llll")}
                    </p>
                  </div>
                </div>
                <div className="scheduled_email_btn_element">
                  <span>
                    {items.Status === "Processing"
                      ? "Processing"
                      : items.Status === "Completed"
                      ? "Completed"
                      : "Scheduled"}
                  </span>
                </div>
                {items.Status === "Processing" ||
                items.Status === "Completed" ? null : (
                  <div className="scheduled_email_edit_delete">
                    <span
                      onClick={() => {
                        // setEventTitle(items?.Subject)

                        handleClick(items?.QueueId);
                      }}
                      className="scheduled_email_edit"
                    >
                      {" "}
                      {constants.Text.Edit}
                    </span>
                    <span
                      onClick={() =>
                        setModal({ isShow: true, QueueId: items?.QueueId })
                      }
                      className="scheduled_email_delete"
                    >
                      {constants.Text.Delete}
                    </span>
                  </div>
                )}
              </div>
            ))}
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default ScheduleEmail;
