import React, { useEffect, useRef, useState, useCallback } from "react";
import Paper from "@mui/material/Paper";
import { ViewState } from "@devexpress/dx-react-scheduler";
import Divider from "@mui/material/Divider";
import {
  Scheduler,
  Toolbar,
  MonthView,
  WeekView,
  ViewSwitcher,
  Appointments,
  AppointmentTooltip,
} from "@devexpress/dx-react-scheduler-material-ui";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import moment, { duration } from "moment/moment";
import { default as momentTz } from "moment-timezone";
import "./eventBookings.css";
import { v4 as uuidv4 } from "uuid";
import { IconButton, Modal } from "@material-ui/core";
import CircularProgress from "@mui/material/CircularProgress";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { axiosE } from "Service/service";
import OrderDetail from "../EventAttendence/Component/ModalAction/OrderDetail";
import Notes from "../EventAttendence/Component/ModalAction/Notes";
import CloseIcon from "@mui/icons-material/Close";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import { IoTicketSharp } from "react-icons/io5";
import LensIcon from "@mui/icons-material/Lens";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import dayjs from "dayjs";
import ErrorComponent from "Helper/ErrorPage/errorPage";
import { makeStyles } from "@material-ui/core";
import { B } from "Components/UBMeEvents/Components_UBMeEvents/Navbar/Alphabets/UserIcons";
import { Redirect } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 400,
  minWidth: 250,
  width: "90%",
  bgcolor: "background.paper",
  boxShadow: 24,
};

const useStyles = makeStyles({
  timeTableCell: {
    width: "280px",
  },
  dayScaleTableCell: {
    width: "280px",
  },
  monthTimeTableCell: {
    width: "150px",
    height: "100px",
  },
  monthDayScaleTableCell: {
    width: "150px",
    height: "100px",
  },
});

const EventBookings = ({ match }) => {
  const [currentDate, setCurrentDate] = useState(moment().format("YYYY-MM-DD"));
  const [startDayHour, setStartDayHour] = useState(0);
  const [endDayHour, setEndDayHour] = useState(24);
  const [currentViewName, setCurrentViewName] = useState("Month");
  const [orderId, setOrderId] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isNotesOpen, setIsNotesOpen] = useState(false);
  const [isMonthOpen, setIsMonthOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [attendeeData, setAttendeeData] = useState([]);
  const [currenTicketData, setCurrentTicketData] = useState({});

  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [recurringTickets, setRecurringTickets] = useState([]);
  const [allAppointments, setAllAppointments] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [monthViewData, setMonthViewData] = useState([]);
  const [sameDateAppointments, setSameDateAppointments] = useState([]);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const timeZone = JSON.parse(localStorage.getItem("CurrentTimeZone"));
  const CurrentEventId = JSON.parse(localStorage.getItem("CurrentEventId"));
  const eventId = match.params.eventId;

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const toggleNotesModal = () => {
    setIsNotesOpen(!isNotesOpen);
  };

  const toggleMonthModal = () => {
    setIsMonthOpen(!isMonthOpen);
  };
  const handleMonthClose = () => {
    setIsMonthOpen(false);
  };

  const handleCurrentViewNameChange = (currentViewName) => {
    setCurrentViewName(currentViewName); // Update the current view
  };

  // Custom Toolbar component to display current month and year
  const CustomToolbar = React.memo(() => {
    return (
      <Toolbar.Root className="bookingsToolbar">
        <IconButton
          color="primary"
          onClick={handlePrevClick}
          className="dateNavigators"
          style={{ border: "1px solid #ca3dd92e" }}
        >
          <ArrowBackIosNewIcon />
        </IconButton>
        <h5 style={{ whiteSpace: "nowrap" }}>
          {new Date(currentDate).toLocaleString("default", {
            month: "long",
            year: "numeric",
          })}
        </h5>
        <IconButton
          color="primary"
          onClick={handleNextClick}
          className="dateNavigators"
          style={{ border: "1px solid #ca3dd92e" }}
        >
          <ArrowForwardIosIcon />
        </IconButton>
      </Toolbar.Root>
    );
  });

  const CustomAppointmentTooltipContent = (props) => {
    const handleTooltipClick = () => {
      setTooltipVisible(!tooltipVisible);
    };

    return (
      <div style={{ marginLeft: "5px", padding: "10px" }}>
        {attendeeData
          ?.sort((a, b) => a.FullName.localeCompare(b.FullName))
          ?.map((item, idx) => {
            const { FullName, Status, OrderId, ChekedStatus, Sold } = item;
            return (
              <div
                key={idx}
                style={{
                  display: "flex",
                  gap: "5px",
                  margin: "5px 0px 10px 0px",
                }}
                onClick={() => {
                  setOrderId({
                    orderId: OrderId,
                    status: Status,
                  });
                }}
              >
                <h4
                  // style={{
                  //   color: "#ca3dd9",
                  // }}
                >
                  {`${idx + 1}.`}
                </h4>
                <h4
                  // style={{
                  //   cursor: "pointer",
                  //   color: "#ca3dd9",
                  //   textDecoration: "underline",
                  // }}
                >
                  {FullName}
                </h4>

                <h4
                  style={{
                    color: "#ca3dd9",
                    fontSize: "16px",
                    display: "table",
                    marginTop: "3px"
                  }}
                >
                  <IoTicketSharp color="#ca3dd9" /> {Sold}
                </h4>
                <h4
                    style={{
                      cursor: "pointer",
                      color: "#ca3dd9",
                      textDecoration: "underline",
                      marginLeft: "auto"
                    }}
                    onClick={() => {
                      handleClose();
                      toggleModal();
                      handleTooltipClick();
                    }}
                >
                  Details
                </h4>
                <h4
                    style={{
                      cursor: "pointer",
                      color: "#ca3dd9",
                      textDecoration: "underline",
                      marginLeft: "10px"
                    }}
                    onClick={() => {
                      handleClose();
                      toggleNotesModal();
                      handleTooltipClick();
                    }}
                >
                  Notes
                </h4>
              </div>
            );
          })}
      </div>
    );
  };

  // Custom rendering for the appointment cards
  const CustomAppointment = React.memo(
    ({ children, style, data, ...restProps }) => {
      const {
        date,
        time,
        startDate,
        endDate,
        Sold,
        TotalTicketIssue,
        TicketId,
      } = data;

      // Calculate start and end times based on the startDate and endDate
      const startTime = moment(startDate).format("h:mm A");
      const endTime = moment(endDate).format("h:mm A");

      const currentTicket = recurringTickets?.filter(
        (item) => item.TicketId === TicketId
      );

      return (
        <Appointments.Appointment
          {...restProps}
          style={{
            ...style,
            width: "100%",
            backgroundColor: "#ca3dd9",
            color: "white",
            padding: "6px 2px",
            borderRadius: "8px",
            boxShadow:
              "rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em",
            minHeight: "40px",
            textAlign: "center",
            cursor: "pointer",
          }}
          onClick={() => {
            currentTicketDataUpdate({ ...data, startTime, endTime });
            GetAllAttendees(data);
            if (currentViewName === "Week") {
              handleOpen();
            } else {
              toggleMonthModal();
              setSameDateAppointments(data);
            }
          }}
        >
          <div key={selectedTicket}>
            {currentViewName === "Month" && (
              <div style={{ fontSize: "13px", padding: "0px 7px" }}>
                {`${data?.appointments?.length} ${
                  data?.appointments?.length === 1 ? "Time Slot" : "Time Slots"
                }`}
              </div>
            )}

            <div className="eventCards">
              {/*<div style={{ fontSize: "16px", padding: "0px 5px" }}>*/}
              {/*  {currentTicket[0]?.Title}*/}
              {/*</div>*/}

              <div
                style={{
                  height: currentViewName !== "Week" && "100%",
                  display: "inline-flex",
                }}
              >
                <img
                  src={require("../../../../images/attendee.png").default}
                  alt="icon"
                  style={{ width: "20px", height: "20px" }}
                />
                <p>
                  <strong>{`${Sold}/${TotalTicketIssue}`}</strong>
                </p>
                {currentViewName === "Week" && (
                  <div style={{ fontSize: "13px", padding: "4px 2px" }}>
                    {startTime} - {endTime}
                  </div>
                )}
              </div>
            </div>
          </div>
        </Appointments.Appointment>
      );
    }
  );

  const handleNextClick = () => {
    // Update the currentDate state to the next week
    if (currentViewName === "Week") {
      setCurrentDate((prevDate) => {
        const nextWeekDate = new Date(prevDate);
        nextWeekDate.setDate(nextWeekDate.getDate() + 7);
        return nextWeekDate.toISOString().split("T")[0];
      });
    } else if (currentViewName === "Month") {
      // Update the currentDate state to the next month
      setCurrentDate((prevDate) => {
        const nextMonthDate = new Date(prevDate);
        nextMonthDate.setMonth(nextMonthDate.getMonth() + 1);
        return nextMonthDate.toISOString().split("T")[0];
      });
    }
  };

  const handlePrevClick = useCallback(() => {
    // Update the currentDate state to the previous week
    if (currentViewName === "Week") {
      setCurrentDate((prevDate) => {
        const prevWeekDate = new Date(prevDate);
        prevWeekDate.setDate(prevWeekDate.getDate() - 7);
        return prevWeekDate.toISOString().split("T")[0];
      });
    } else if (currentViewName === "Month") {
      // Update the currentDate state to the previous month
      setCurrentDate((prevDate) => {
        const prevMonthDate = new Date(prevDate);
        prevMonthDate.setMonth(prevMonthDate.getMonth() - 1);
        return prevMonthDate.toISOString().split("T")[0];
      });
    }
  });

  const GetAllRecurringTicketsByEventId = async () => {
    setLoading(true);
    try {
      await axiosE
        .get(`/GetAllRecurringTicketsByEventId/${eventId}`)
        .then((res) => {
          const recTickets = JSON.parse(res.data.results.Data);
          setRecurringTickets(recTickets);
          if (recTickets.length > 0) {
            setSelectedTicket(recTickets[0]?.TicketId);
            GetAllRecurringTicketsByTicketId(recTickets[0]?.TicketId);
          }
        })
        .catch((err) => {
          console.log({ err });
        })
        .finally(() => setLoading(false));
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  };

  const GetAllRecurringTicketsByTicketId = async (ticketId) => {
    if (ticketId === "") {
      setSelectedTicket("");
      setAllAppointments([]);
    } else {
      setLoading(true);
      try {
        await axiosE
          .get(`/GetAllRecurringTicketsByTicketId/${ticketId}`)
          .then((res) => {
            const appointments = JSON.parse(res.data.results.Data);
            const convertedData = convertDataToAppointments(
              appointments,
              ticketId
            );
            setAllAppointments(convertedData);
          })
          .catch((err) => {
            console.log({ err });
          })
          .finally(() => setLoading(false));
      } catch (error) {
        setLoading(false);
        console.log("error", error);
      }
    }
  };

  const GetAllAttendees = async (ticket) => {
    const { date, time, TicketId } = ticket;
    try {
      setIsLoading(true);
      await axiosE
        .post(`/Ticket/TimeSlotOrders`, {
          TicketId: TicketId,
          BookingTime: time,
          BookingDate: date?.split("/").reverse().join("-"),
        })
        .then((res) => {
          const formatedData = jsonFormatterHelper(
            JSON.parse(res?.data?.results?.Data),
            TicketId
          );
          setAttendeeData(formatedData);
        })
        .catch((err) => {
          console.log({ err });
        })
        .finally(() => setIsLoading(false));
    } catch (error) {
      setIsLoading(false);
      console.log("error", error);
    }
  };

  const handleChange = (event) => {
    const ticketId = event.target.value;
    setMonthViewData([]);
    setSelectedTicket(ticketId);
    GetAllRecurringTicketsByTicketId(ticketId);
    setCurrentTicketData({});
  };

  const convertDataToAppointments = (eventData, ticketId) => {
    const timeZone = JSON.parse(localStorage.getItem("CurrentTimeZone"));
    const appointments = [];

    let earliestDate = moment(eventData[0]?.BookingDate?.split("T")[0]);

    eventData.forEach((event) => {
      const { BookingDate, TimeSlots } = event;
      if (BookingDate && TimeSlots && TimeSlots.length > 0) {
        const startDate = BookingDate.split("T")[0];
        TimeSlots.forEach((slot, idx) => {
          const currentDate = moment(startDate);
          if (currentDate.isBefore(earliestDate)) {
            earliestDate = currentDate;
          }

          const {
            Time,
            Sold,
            TotalTicketIssue,
            RecurringInterval,
            RecurringStartTime,
            RecurringEndTime,
          } = slot;

          const startDateTime = formatDateWithTimezone(
            startDate,
            Time,
            timeZone
          );

          let endDateTime = dayjs(startDateTime)
            .add(RecurringInterval, "minutes")
            .format();

          if (RecurringInterval == 0) {
            const isEndTime = true;

            const newEndDate = convertAndAdjustDatetime(
              startDate,
              RecurringEndTime,
              timeZone
            );

            endDateTime = formatDateWithTimezone(
              newEndDate,
              RecurringEndTime,
              timeZone,
              isEndTime
            );
          }

          const uniqueId = uuidv4();
          const appointment = {
            id: uniqueId,
            date: startDate,
            time: Time,
            startDate: startDateTime,
            endDate: endDateTime,
            Sold,
            TotalTicketIssue,
            TicketId: ticketId,
          };

          appointments.push(appointment);
        });
      }
    });

    setCurrentDate(earliestDate.format("YYYY-MM-DD"));

    // console.log({ appointments });

    return appointments;
  };

  const convertAppointmentsToMonthView = (inputData) => {
    const groupedAppointments = {};


    inputData.forEach((appointment) => {
      const startDate = appointment.startDate;
      const date = moment(startDate).format("YYYY-MM-DD");
      const currentTicket = recurringTickets?.filter(
        (item) => item.TicketId === selectedTicket
      );
      const eventDate = momentTz.utc(startDate).format("ddd, MMMM D, YYYY");

      // const date = appointment.date;
      if (!groupedAppointments[date]) {
        groupedAppointments[date] = {
          ticketTitle: currentTicket[0].Title,
          date: eventDate,
          startDate: appointment.startDate,
          endDate: appointment.endDate,
          Sold: appointment.Sold,
          TotalTicketIssue: appointment.TotalTicketIssue,
          appointments: [appointment],
        };
      } else {
        const group = groupedAppointments[date];
        group.startDate =
          new Date(group.startDate) < new Date(appointment.startDate)
            ? group.startDate
            : appointment.startDate;
        group.endDate =
          new Date(group.endDate) > new Date(appointment.endDate)
            ? group.endDate
            : appointment.endDate;
        group.Sold += appointment.Sold;
        group.TotalTicketIssue += appointment.TotalTicketIssue;
        group.appointments.push(appointment);
      }
    });

    const monthViewAppointments = Object.values(groupedAppointments);

    return monthViewAppointments;
  };

  useEffect(() => {
    if (allAppointments.length > 0) {
      const convertedMonthViewAppointments =
        convertAppointmentsToMonthView(allAppointments);

      setMonthViewData(convertedMonthViewAppointments);
    }
  }, [allAppointments]);

  useEffect(() => {
    GetAllRecurringTicketsByEventId();

    // return () => {
    //   localStorage.removeItem("CurrentEventId");
    // };
  }, []);

  const filterUnwantedKeys = (obj) => {
    const filteredObject = {};

    for (const key in obj) {
      if (isNaN(key)) {
        filteredObject[key] = obj[key];
      }
    }

    return filteredObject;
  };

  const isPlainObject = (variable) => {
    return (
      typeof variable === "object" &&
      variable !== null &&
      !Array.isArray(variable)
    );
  };

  const parseAndValidateJSON = (jsonString) => {
    // Replace 'null' with 'null' and single quotes with double quotes
    const replacedString = jsonString
        .replace(/'/g, '"')
        .replace(/null/g, "null");

    try {
      const parsedData = JSON.parse(replacedString);
      return { success: true, data: parsedData };
    } catch (error) {
      return { success: false, error: error.message };
    }
  };

  const jsonFormatterHelper = (ListAttendee, TicketId) => {
    const filterdObjects = ListAttendee.map((item) => {
      const jsonobj = item.AdditionalDetails;
      const { AdditionalDetails, ...restItem } = item;

      if (jsonobj) {
        let parsedData = {};
        try {
          const parsedJsonObj = parseAndValidateJSON(jsonobj);
          let restInfo = {};
          if (parsedJsonObj.success && parsedJsonObj.data?.FullName) {
            restInfo = { ...parsedJsonObj.data };
          } else {
            const modifiedJson = JSON.parse(jsonobj, (key, value) => {
              if (typeof value === "string") {
                // Replace single quotes within string values with apostrophes
                return value.replace(/'/g, "\\'");
              }
              return value;
            });

            parsedData = JSON.parse(jsonobj);
            const data = JSON.parse(parsedData);

            restInfo = {...modifiedJson, ...data, TicketId};
          }
          return { ...restInfo, ...restItem };
        } catch (e) {
          const isObject = isPlainObject(parsedData);

          if (isObject) {
            return { ...parsedData, ...restItem };
          }

          // Replace single quotes with double quotes
          const correctedJsonString = parsedData.replace(/'/g, '"');
          const newParsedData = JSON.parse(correctedJsonString);
          return { ...newParsedData, ...restItem };
        }
      } else {
        return { FullName: "", Email: "", Phone: "", TicketId, ...restItem };
      }
    });
    const filteredDetails = filterdObjects?.map((obj) =>
      filterUnwantedKeys(obj)
    );
    return filteredDetails;
  };

  const formatDateWithTimezone = (
    startDate,
    time,
    timeZone,
    isEndTime = false
  ) => {
    // Parse the UTC date and time
    const [year, month, day] = startDate.split("-").map(Number);
    const [hour, minute, second] = time.split(":").map(Number);

    // if (timeZone !== "Asia/Kolkata") {
    //   // Create a new Date object using the provided date, time, and UTC timezone
    //   const utcDateTime = new Date(
    //     Date.UTC(year, month - 1, day, hour, minute, second)
    //   );

    //   // Format the date and time to the target timezone
    //   const options = {
    //     timeZone,
    //     year: "numeric",
    //     month: "2-digit",
    //     day: "2-digit",
    //     hour: "2-digit",
    //     minute: "2-digit",
    //     second: "2-digit",
    //     hour12: false,
    //   };

    //   if (isEndTime) {
    //     // Get the initial formatted date and time
    //     const initialFormattedDateTime = new Date(
    //       utcDateTime.toLocaleString("en-US", options)
    //     );

    //     // Adjust the date by adding 1 day to handle the time zone issue if necessary
    //     if (initialFormattedDateTime.getUTCDate() !== day) {
    //       utcDateTime.setUTCDate(utcDateTime.getUTCDate() + 1);
    //     }
    //   }

    //   // Format the final date and time to the target timezone
    //   const finalFormattedDateTime = new Date(
    //     utcDateTime.toLocaleString("en-US", options)
    //   );
    //   return finalFormattedDateTime;
    // }

    const targetDateTime = new Date(
      Date.UTC(year, month - 1, day, hour, minute, second)
    );

    return targetDateTime;
  };

  const convertAndAdjustDatetime = (utcDate, utcTime, timeZone) => {
    const utcDateTime = momentTz.utc(
      `${utcDate} ${utcTime}`,
      "YYYY-MM-DD HH:mm:ss"
    );
    const convertedDateTime = momentTz.tz(utcDateTime, timeZone);

    if (convertedDateTime.date() < utcDateTime.date()) {
      const revertedDateTime = momentTz.tz(utcDateTime, "UTC").add(1, "day");
      return revertedDateTime.format("YYYY-MM-DD");
    }

    return convertedDateTime.format("YYYY-MM-DD");
  };

  const currentTicketDataUpdate = (data) => {
    const currentTicket = recurringTickets?.filter(
      (item) => item.TicketId === data?.TicketId
    );

    if (currentTicket.length > 0) {
      const { startTime, endTime, Sold, TotalTicketIssue, startDate } = data;
      const date = momentTz.utc(startDate).format("ddd, MMMM D, YYYY");

      setCurrentTicketData({
        ticketId: data?.TicketId,
        ticketTitle: currentTicket[0]?.Title,
        date,
        startTime,
        endTime,
        Sold,
        TotalTicketIssue,
      });
    }
  };

  if (CurrentEventId !== eventId) {
    return <Redirect to="/" />;
  }

  const TimeTableCell = (props) => {
    const classes = useStyles();
    return (
      <WeekView.TimeTableCell {...props} className={classes.timeTableCell} />
    );
  };

  const DayScaleTableCell = (props) => {
    const classes = useStyles();
    return (
      <WeekView.DayScaleCell {...props} className={classes.dayScaleTableCell} />
    );
  };

  const MonthTimeTableCell = (props) => {
    const classes = useStyles();
    return (
      <MonthView.TimeTableCell
        {...props}
        className={classes.monthTimeTableCell}
      />
    );
  };

  const MonthDayScaleTableCell = (props) => {
    const classes = useStyles();
    return (
      <MonthView.DayScaleCell
        {...props}
        className={classes.monthDayScaleTableCell}
      />
    );
  };

  return (
    <>
      <div className="bookingsContainer">
        <div className="bookingsHeader">
          <h1>Bookings</h1>
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <Select
              size="small"
              value={selectedTicket}
              onChange={handleChange}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              sx={{ fontSize: "15px" }}
            >
              {/* <MenuItem value="">
                <em>Choose Ticket</em>
              </MenuItem> */}
              {recurringTickets
                ?.sort((a, b) => a.Title.localeCompare(b.Title))
                ?.map((ticket) => {
                  return (
                    <MenuItem key={ticket?.TicketId} value={ticket?.TicketId}>
                      {ticket?.Title}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </div>
        <Paper>
          <Scheduler
            data={currentViewName === "Week" ? allAppointments : monthViewData}
            height={660}
          >
            <ViewState
              currentDate={currentDate}
              currentViewName={currentViewName} // Pass the current view to ViewState
              onCurrentViewNameChange={handleCurrentViewNameChange}
            />
            <MonthView
              timeTableCellComponent={MonthTimeTableCell}
              dayScaleCellComponent={MonthDayScaleTableCell}
            />
            <WeekView
                startDayHour={startDayHour}
                endDayHour={endDayHour}
                cellDuration={15}
                timeTableCellComponent={TimeTableCell}
                dayScaleCellComponent={DayScaleTableCell}
            />

            {/* Use custom rendering for the appointment cards */}
            <Appointments appointmentComponent={CustomAppointment} />

            <Toolbar flexibleSpaceComponent={CustomToolbar} />
            <ViewSwitcher />
          </Scheduler>
        </Paper>

        <style>
          {`
          /* Adjust the font size for calendar weekdays (day names) */
          .Cell-dayOfWeek-73 {
            font-size: 12px !important;
          }

          /* Adjust the font size for calendar dates */
          .Cell-dayOfMonth-74 {
            font-size: 20px !important;
          }
         
          /* Adjust the font size for the left-side time bar */
          .Label-text-84 {
            font-size: 12px !important;
          }

          /* Adjust the width size for the appointmentTooltip */
          .Layout-popover-237{
            max-width: 200px;
            padding: 10px 15px
          }

          .Header-flexContainer-325{
            display: none;
          }
        `}
        </style>
      </div>

      <Modal open={isOpen} onClose={toggleModal}>
        <div
          style={{
            position: "relative",
            float: "right",
          }}
          className="addAttendeeModal"
        >
          <div className="innerOrderDetailsModal">
            <OrderDetail
              // GetAllAttendees={}
              TimeZone={timeZone}
              orderId={orderId}
              setOrderId={setOrderId}
              isOpen={() => setIsOpen(false)}
              isEventBookings={true}
            />
          </div>
        </div>
      </Modal>

      <Modal open={isNotesOpen} onClose={toggleNotesModal}>
        <div
            style={{
              position: "relative",
              float: "right",
            }}
            className="addAttendeeModal"
        >
          <div className="innerOrderDetailsModal">
            <Notes
                TimeZone={timeZone}
                orderId={orderId}
                setOrderId={setOrderId}
                isOpen={() => setIsNotesOpen(false)}
                isEventBookings={true}
            />
          </div>
        </div>
      </Modal>

      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={modalStyle} padding={"10px 5px"}>
            <Box
              className="attendeesModal"
              pb={currentViewName === "Week" && "10px"}
            >
              {currentViewName === "Month" ? (
                <IconButton
                  aria-label="delete"
                  size="medium"
                  onClick={() => {
                    handleClose();
                    toggleMonthModal();
                  }}
                >
                  <ArrowBackIcon fontSize="large" />
                </IconButton>
              ) : (
                <Box className="attendeesInnerDiv">
                  <Box>
                    <LensIcon sx={{ color: "#ca3dd9", fontSize: "30px" }} />
                    <Box>
                      <Typography variant="h4">
                        {currenTicketData?.ticketTitle}
                      </Typography>
                      <p>{currenTicketData?.date}</p>
                    </Box>
                  </Box>
                  <Box>
                    <AccessTimeIcon sx={{ color: "gray", fontSize: "20px" }} />
                    <p>{`${currenTicketData?.startTime} - ${currenTicketData?.endTime}`}</p>
                  </Box>
                </Box>
              )}
              <IconButton
                aria-label="delete"
                size="medium"
                onClick={handleClose}
              >
                <CloseIcon fontSize="large" />
              </IconButton>
            </Box>

            {currentViewName === "Month" && (
              <Box
                className="attendeesInnerDiv"
                pt={currentViewName === "Week" && "10px"}
              >
                <Box>
                  <LensIcon sx={{ color: "#ca3dd9", fontSize: "30px" }} />
                  <Box>
                    <Typography variant="h4">
                      {currenTicketData?.ticketTitle}
                    </Typography>
                    <p>{currenTicketData?.date}</p>
                  </Box>
                </Box>
                <Box>
                  <AccessTimeIcon sx={{ color: "gray", fontSize: "20px" }} />
                  <p>{`${currenTicketData?.startTime} - ${currenTicketData?.endTime}`}</p>
                </Box>
              </Box>
            )}

            <Divider style={{ margin: "0px 15px" }} />
            <Typography
              padding={"10px 10px 10px 15px"}
              marginTop={"10px"}
              variant="h4"
            >
              Attendees
            </Typography>

            {isLoading ? (
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  padding: "20px",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <CustomAppointmentTooltipContent />
            )}
          </Box>
        </Fade>
      </Modal>

      <Modal open={isMonthOpen} onClose={toggleMonthModal}>
        <Box sx={modalStyle} padding={"0px"}>
          <List>
            <Box
              padding={"5px 20px 5px !important"}
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"flex-start"}
            >
              <Box
                className="attendeesInnerDiv"
                p={currentViewName === "Month" && "0px"}
                pt={"10px"}
              >
                <Box>
                  <LensIcon sx={{ color: "#ca3dd9", fontSize: "30px" }} />
                  <Box>
                    <Typography variant="h4">
                      {sameDateAppointments?.ticketTitle}
                    </Typography>
                    <p>{sameDateAppointments?.date}</p>
                  </Box>
                </Box>
              </Box>

              <IconButton
                aria-label="delete"
                size="medium"
                onClick={handleMonthClose}
              >
                <CloseIcon fontSize="large" />
              </IconButton>
            </Box>
            <Divider style={{ margin: "10px 15px" }} />
            {sameDateAppointments?.appointments?.map((item) => {
              return (
                <ListItem
                  onClick={() => {
                    const startTime = moment(item?.startDate).format("h:mm A");
                    const endTime = moment(item?.endDate).format("h:mm A");
                    currentTicketDataUpdate({ ...item, startTime, endTime });
                    GetAllAttendees(item);
                    handleOpen();
                    handleMonthClose();
                  }}
                >
                  <ListItemButton className="monthModalEvents">
                    <Box>
                      <img
                        src={
                          require("../../../../images/userPurple.png").default
                        }
                        alt="icon"
                        style={{ width: "20px", height: "20px" }}
                      />
                      <Typography variant="h4">
                        {`${item?.Sold}/${item?.TotalTicketIssue}`}
                      </Typography>
                    </Box>
                    {/* <Typography variant="h4">{item?.date}</Typography> */}
                    <Box>
                      <AccessTimeIcon
                        sx={{ color: "gray", fontSize: "20px" }}
                      />
                      <p>{`${moment(item?.startDate).format(
                        "h:mm A"
                      )} - ${moment(item?.endDate).format("h:mm A")}`}</p>
                    </Box>
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </Box>
      </Modal>

      <Modal
        open={loading}
        onClose={loading === false}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Box className="progressLoader">
          <CircularProgress color="secondary" size={"70px"} />
        </Box>
      </Modal>
    </>
  );
};

export default EventBookings;
