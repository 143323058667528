import React from "react";
import "./PaymentSold.css";
import { Text } from "Constants";

function PaymentCard({payoutData}) {
  const data = [
    {
      number: payoutData?.Sale,
      title: "Total Sales",
    },
    {
      number: payoutData?.UBMeFeeAmount,
      title: "UBMe Fees",
    },
    // {
    //   number: payoutData?.ProcessingFee,
    //   title: "Processing Fee",
    // },
  ];
  return (
    <div className="paymentCard">
      <div className="paymentCardHeader">
          <h2>{localStorage.getItem("CurrencySymbol")}{payoutData?.TotalPayout ? Intl.NumberFormat(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(payoutData?.TotalPayout) : 0}</h2>
          {/*<div className="headerText">*/}
              <h4>{Text.TotalPayout}</h4>
              <h6 style={{textAlign: "center"}}>{Text.CreditCardFeesNotIncluded}</h6>
          {/*</div>*/}
      </div>
      <div className="paymentCardDesc">
        {data.map((item) => (
          <div className="paymentCardSold">
            <p> {item.title === "UBMe Fees" || item.title === "Processing Fee" ? "-" : "" }{localStorage.getItem("CurrencySymbol")}{Intl.NumberFormat(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.number ? item.number : 0)}</p>
            <p>{item.title}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default PaymentCard;
