import React from "react";
import { MdDone, MdEmail } from "react-icons/md";
import {
  FacebookShareButton,
  WhatsappIcon,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  EmailIcon,
  EmailShareButton,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import { FaFacebookF, FaLinkedinIn, FaTwitter } from "react-icons/fa";
import { BsFacebook, BsTwitter, BsMessenger } from "react-icons/bs";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { HiMail } from "react-icons/hi";
import { IoLogoWhatsapp } from "react-icons/io";
import { ticktesCartItem } from "Actions/eventActions";
import moment from "moment";
import { useEffect } from "react";
import { createBrowserHistory } from "history";
import * as constants from "Constants";
import { useState } from "react";
import { IoArrowBack } from "react-icons/io5";
import { history } from "store";
import { routeName } from "Service/env";
import Navbar from "Components/UBMeEvents/Components_UBMeEvents/Navbar/Navbar";
function PaymentSuccess({
  isLoggedIn,
  currentEventDetails,
  results,
  cartItems,
  ticktesCartItem,
  dispatch,
}) {
  const ticketQty = cartItems?.reduce((a, c) => a + Number(c.Quantity), 0);
  // const [ locationKeys, setLocationKeys ] = useState([])
  // if(isLoggedIn) {

  //   window.onpopstate = function(event) {
  //     history.go(1);
  //   };
  // }


  useEffect(() => {
    localStorage.setItem("payment", "success");
  }, []);

  let appToken = localStorage.getItem("app-token");


  const backToHome = () =>
    history.replace(`${routeName}/event/${currentEventDetails.EventId}`);

  const isRecurringTicket = cartItems.some(
    (x) => x.RecurringTicket === "True" || x.RecurringTicket === "true"
  );
  return (
    <React.Fragment>
      {appToken === "null" ? <Navbar /> : null}

      <div className="paymentSuccess">
        <div className="paymentUpper">
          {/* <div>
        
          <IoArrowBack style={{cursor:'pointer', marginBottom:20}} onClick={backToHome} size={25} />
        
        </div> */}
          <div className="paymentHeader">
            <MdDone className="doneIcon" size={30} />
            <h1>Thanks for your order!</h1>
          </div>
          <div className="orderDetail">
            <h3>YOU'RE GOING TO</h3>
            <h1 style={{ marginTop: 20, marginBottom: 50 }}>
              {currentEventDetails?.Title}
            </h1>

            <div className="orderDesc">
              <div className="orderDescLeft">
                <h3>
                  {ticketQty} {ticketQty > 1 ? "TICKETS" : "TICKET"} SENT TO
                </h3>
                <p style={{ marginTop: 10, color: "rgb(58 58 58)" }}>
                  {JSON.parse(localStorage.getItem("additionalInfo"))?.Email}
                </p>
                {/* <a href="">Send to another email address</a> */}
              </div>
              {isRecurringTicket ? (
                ""
              ) : (
                <div className="orderDescRighjt">
                  <h3>DATE</h3>
                  <p style={{ marginTop: 10, color: "rgb(58 58 58)" }}>
                    {moment(currentEventDetails.StartDateTime).format("llll")} -{" "}
                    {moment(currentEventDetails.EndDateTime).format("llll")}
                  </p>
                </div>
              )}
            </div>
            <div className="orderLoaction">
              <h3>LOCATION</h3>
              <p style={{ marginTop: 10, color: "rgb(58 58 58)" }}>
                {" "}
                {currentEventDetails?.Add1}
              </p>
            </div>
          </div>
        </div>
        <div className="orderDetailFooter">
          <a id="view-tickets" href={`${routeName}/tickets`} className="leave">
            View Tickets
          </a>
          <div className="orderSocial">
            <FacebookShareButton
              bgstyle="red"
              url={`${constants.URLs.baseUrl}/event/${currentEventDetails.EventId}`}
              // quote={"CampersTribe - World is yours to explore"}
              hashtag="#UBMeEvents"
              // className={styles.social_share_button}
            >
              <FaFacebookF className="orderSocioIcon" />
            </FacebookShareButton>
            <TwitterShareButton
              url={`${constants.URLs.baseUrl}/event/${currentEventDetails.EventId}`}
              // title={"CampersTribe - World is yours to explore"}
              hashtag="#UBMeEvents"
              // className={styles.social_share_button}
            >
              <BsTwitter className="orderSocioIcon" />
            </TwitterShareButton>
            <WhatsappShareButton
              url={`${constants.URLs.baseUrl}/event/${currentEventDetails.EventId}`}
              // title={"CampersTribe - World is yours to explore"}
              hashtag="#UBMeEvents"
              // className={styles.social_share_button}
            >
              <div
              //  className={styles.social_share_icon_bg}
              >
                <IoLogoWhatsapp className="orderSocioIcon" />
              </div>
            </WhatsappShareButton>
            <EmailShareButton
              url={`${constants.URLs.baseUrl}/event/${currentEventDetails.EventId}`}
              // title={"CampersTribe - World is yours to explore"}
              hashtag="#UBMeEvents"
              //  className={styles.social_share_button}
            >
              <div
              // className={styles.social_share_icon_bg}
              >
                <HiMail className="orderSocioIcon" />
              </div>
            </EmailShareButton>
            {/* <MdEmail className="orderSocioIcon" /> */}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    results: state.loginResponse.results,
    isLoggedIn: state.loginResponse.isLoggedIn,
    currentEventDetails: state.eventReducer.currentEventDetails,
    cartItems: state.eventReducer.cartData,
  };
};
const matchDispatchToProps = (dispatch) => {
  return {
    // AddToFavorite: () => dispatch(AddToFavorite),
    // SetCardData: () => dispatch(SetCardData),
    // LoginPopup: () => dispatch(LoginPopup),
    // currentEventTicketDetails,
    // ticktesCartItem,
    // GetDiscountDetails,
    // GetDiscountData,
    // GetEventMasterById,
    ticktesCartItem,
    dispatch,
  };
};

export default connect(mapStateToProps, matchDispatchToProps)(PaymentSuccess);
