import React from "react";
import { URLs, Text as Texts } from "Constants";
import { AiFillInfoCircle } from "react-icons/ai";
import GraphData from "../../Document/GraphData";
import { useState } from "react";
import PDFDocument from "../../Document";
import PDFImg from "images/pdf.png";
import {  CircularProgress } from "@material-ui/core";
import Backdrop from '@mui/material/Backdrop';
import {
  pdf,
  Page,
  Image,
  StyleSheet,
  Text,
  Document,
  View,
  Link,
} from "@react-pdf/renderer";
import { useEffect } from "react";
import { useRef } from "react";
const SponsorView = ({
  checkisArray,
  activityData,
  Logo,
  Name,
  website,
  Interaction,
  eventTitle
}) => {
  const [startDownload, setStartDownload] = useState("");
  const [image1, setImage1] = useState("");
  const [image2, setImage2] = useState("");
  const [downloading, setDownloading] = useState(false)


  const isFunctionCalledRef = useRef(false);
  
   // Getting Vendors data by callback function
   const ImpressionsData = checkisArray(
    activityData?.LandingPageStatistics?.Click,
    "impressions"
  );

  const ClicksData = checkisArray(Interaction, "URL");

  //  Getting chart numbers from vendors data to pass in PDF.

  const ImpressionCount = ImpressionsData?.reduce((a, b) => a + Number(b.value),0);

  const ClicksCount = ClicksData?.reduce((a, b) => a + Number(b.value), 0);







  useEffect(() => {
    if (image1 !== "" && image2 !== "" && !isFunctionCalledRef.current) {
      myFunction();
      isFunctionCalledRef.current = true;
    }
  }, [image1, image2]);

  const myFunction = async () => {
    const pdfBlob = await pdf(
      <PDFDocument {...{  ClicksCount,
        ImpressionCount, Logo, Name, website, image1, image2, Pdfname:"Sponsor" ,eventTitle}} />
    ).toBlob();
    const pdfDataUrl = URL.createObjectURL(pdfBlob);
    const link = document.createElement("a");
    link.href = pdfDataUrl;
    link.download = "my-pdf-file.pdf";
    document.body.appendChild(link);
    setImage1("")
    setImage2("")
    link.click();
    setDownloading(false)
    // Perform any actions that need to be done after all states have been updated
  };

  const downloadPdf = async (Logo, Name, website) => {
    setStartDownload("start");
  };

  return (
    <>
 <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={downloading}
        onClick={()=> {}}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    <div className="monitizationSection">
      <div className="monitizationAward">
        <img src={`${URLs.vendorImgUrl}/SponsorLogo/${Logo}`} />
        <div className="sponser_text">
          <h4>{Name}</h4>
          <a style={{ color: "#0066FF" }} href={`${website}`}>
            {website}
          </a>
        </div>
      </div>
      <GraphData
        setImage={setImage1}
        startDownload={startDownload}
        data={ImpressionsData}
        title="Impressions"
      />
      <GraphData
        startDownload={startDownload}
        setImage={setImage2}
        data={ClicksData}
        title="Clicks"
      />
      <div
        style={{
          width: 100,
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          marginBottom:30
        }}
        onClick={() => {setDownloading(true); setTimeout(() => {
          setStartDownload("start");
        }, 800); }}
      >
        <p>{Texts.PrintReport}</p>
        <img
          src={PDFImg}
          style={{
            width: 50,
            filter: "drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.2))",
          }}
        />
      </div>
    </div>
    </>
  );
};

export default SponsorView;
