import React from "react";
import frequentlyAskedQuestionsImg from "images/FrequentlyAskedQuestions.jpg";
import Navbar from "../Components_UBMeEvents/Navbar/Navbar";
import Footer from "../Components_UBMeEvents/Footer/Footer";
import "./FrequentlyAskedQuestions.css";
import Fade from 'react-reveal/Fade';
import Faq from "./Faq.js/Faq";
import { connect } from "react-redux";

const FrequentlyAskedQuestions = () => {

    return (
        <div>
            {" "}
            <Navbar />
            <div className="">
                <img
                    style={{ width: "100%", height: "100%" }}
                    src={frequentlyAskedQuestionsImg}
                    alt=""
                />
            </div>
            <div className="pricing_container">
                {/* FAQ Section  */}
                <div className="faq_data">
                    <br/>
                    <br/>
                    <br/>
                    <Fade bottom>
                        <h1>Questions About Sign-Up Process</h1>
                    </Fade>
                    <Faq context="FrequentlyAskedQuestionSignup" />
                    <br/>
                    <Fade bottom>
                        <h1>Questions About Adding Event</h1>
                    </Fade>
                    <Faq context="FrequentlyAskedQuestionAddingEventDetails" />
                    <br/>
                    <Fade bottom>
                        <h1>Questions About Creating Event Tickets</h1>
                    </Fade>
                    <Faq context="FrequentlyAskedQuestionCreatingEventTickets" />
                    <br/>
                    <Fade bottom>
                        <h1>Questions About Marketing/ Promoting Events</h1>
                    </Fade>
                    <Faq context="FrequentlyAskedQuestionPromotingEvents" />
                    <br/>
                    <Fade bottom>
                        <h1>Questions About Managing Your Account</h1>
                    </Fade>
                    <Faq context="FrequentlyAskedQuestionManagingYourAccount" />
                    <br/>
                    <Fade bottom>
                        <h1>More Questions</h1>
                    </Fade>
                    <Faq context="FrequentlyAskedQuestionMoreQuestions" />
                    <br/>
                </div>
            </div>
            <Footer />
        </div>
    );
};


const mapStateToProps = (state) => {
    return {
    };
};
const matchDispatchToProps = (dispatch) => {
    return {
        dispatch,
    };
};
export default connect(mapStateToProps, matchDispatchToProps)(FrequentlyAskedQuestions);
