import React from 'react' 
import './SurveyCard.css'
import { Tooltip } from "@mui/material";
import { withStyles } from "@material-ui/styles";
import { Info } from "@material-ui/icons";

function SurveyCard({ title, number, Icon , tipText }) {
    const CreateEventDetailsAboutToolTip = withStyles(() => ({
        tooltip: {
          backgroundColor: "#f5f5f9",
          color: "rgba(0, 0, 0, 0.87)",
          maxWidth: 220,
          fontSize:20,
          border: "1px solid #dadde9",
        },
      }))(Tooltip);
      
      const titleWithToolTip = (title, mandatory = false) => {
        return (
          <span style={{ fontSize: 16, fontWeight: "bold", color: "#000000" }}>
            {/* {title} */}
            {mandatory && <span style={{ color: "red" }}> *</span>}
            <CreateEventDetailsAboutToolTip
              title={<h5>{tipText}</h5>}
            >
              <span
                style={{
                  padding: "0 0 0 10px",
                  cursor: "pointer",
                  color: "#7a237a",
                }}
              >
                <Info />
              </span>
            </CreateEventDetailsAboutToolTip>
          </span>
        );
      };

  return (
      <div className="SurveyCard">
          <div className="surveycontent">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                  {Icon && <img style={{width:28}} src={Icon} alt={Icon} className="iconsPrint"/>}
                  <p>{title}</p>
              </div>
              <h3>{number}</h3>
          </div>
          <div className="cardIcon">
          <p style={{fontSize:"16px",}}> {titleWithToolTip(title, false)}</p>
          {/* <AiFillInfoCircle className="infoIcon" /> */}
        </div>
      </div>
  )
}

export default SurveyCard