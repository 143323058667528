import React from "react";
import Grid from "styles/Grid.jsx";
import styles from "./EventDetail.module.css";
import { FaCalendarAlt } from "react-icons/fa";
import { MdLocationOn } from "react-icons/md";
import when from "images/When.png";
import where from "images/WHERE.png";

import moment from "moment";
import { Text } from "Constants";

const EventDetail = ({
  address,
  StartDateTime,
  EndDateTime,
  VenueName,
  title,
  currentEventDetails,
}) => {
  return (
    <div>
      {" "}
      <Grid row={true}>
        <Grid column={true} sm={12} md={6}>
          <div className={styles.event_time} style={{ backgroundColor: (currentEventDetails !== null && currentEventDetails.EventColor !== null && currentEventDetails.EventColor !== undefined && currentEventDetails.EventColor !== "") ? currentEventDetails.EventColor : "#ff8080"}}>
            <div className={styles.event_time_icon}>
              <div className={styles.event_time_icon_circle}>
                <img src={when} style={{ width: 60 }} />
                {/* <FaCalendarAlt size={15} /> */}
              </div>
            </div>
            <div className={styles.event_time_detail}>
              <div className={styles.event_time_detail_content}>
                <p className={styles.event_time_heading}>{Text.WHEN}</p>
                <p className={styles.event_time_stamp}>
                  {moment(StartDateTime).format("llll")} -
                </p>

                <p className={styles.event_time_stamp}>
                  {moment(EndDateTime).format("llll")}
                </p>
                <a
                  className={styles.event_time_calender}
                  target="_blank"
                  href={`https://calendar.google.com/calendar/render?action=TEMPLATE&text=${title}&details=${VenueName}&dates=${moment(
                    StartDateTime
                  )
                    .toISOString()
                    .replace(/[-:.]/g, "")}/${moment(EndDateTime)
                    .toISOString()
                    .replace(/[-:.]/g, "")}&location=${address}`}
                >
                  <u>{Text.AddToCalendar}</u>
                </a>
              </div>
            </div>
          </div>
        </Grid>
        <Grid column={true} sm={12} md={6}>
          <div className={styles.event_location} style={{ backgroundColor: (currentEventDetails !== null && currentEventDetails.EventColor !== null &&  currentEventDetails?.EventColor !== undefined && currentEventDetails.EventColor !== "") ? currentEventDetails.EventColor : "#cc00cc"}}>
            <div className={styles.event_location_detail}>
              <div className={styles.event_time_detail_content}>
                <p className={styles.event_time_heading}>WHERE</p>
                <p style={{ marginBottom: "10px" , fontWeight:"bold", fontSize:16}}>{VenueName}</p>
                <p style={{ marginBottom: "10px" , fontWeight:"bold",   fontSize:16}}>{address}</p>
                {address !== undefined && (
                <a
                    className={styles.event_location_detail_map}
                    href={`https://www.google.com/maps/dir/?api=1&destination=${address.replace(/ /g, '+')}`}
                    target="_blank"
                    style={{paddingRight:'10px'}}
                >
                  <u>{Text.Directions}</u>
                </a>
                )}
                {currentEventDetails?.EventMap !== null && (
                  <a
                    className={styles.event_location_detail_map}
                    href={`${currentEventDetails?.EventMap}`}
                    target="_blank"
                  >
                    <u>{Text.ViewEventMap}</u>
                  </a>
                )}
              </div>
            </div>
            <div className={styles.event_location_icon}>
              <div className={styles.event_location_icon_circle}>
                <img src={where} style={{ width: 60 }} />
                {/* <MdLocationOn size={15} /> */}
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default EventDetail;
