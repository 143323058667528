import { Text } from "Constants";
import React from "react";

const Subject = ({ fromName, setfromName, setEventTitle, eventTitle }) => {
  return (
    <>
      <form autoComplete="off">
        <div className="input_area">
          <div class="label-float">
            <input
              style={{ backgroundColor: "transparent" }}
              onChange={(e) => setEventTitle(e.target.value)}
              // type="text"
              placeholder=""
              value={eventTitle}
            />
            <label>
              {Text.Subject}<sup style={{ color: "red" }}>*</sup>
            </label>
          </div>
        </div>
        <div className="input_area">
          <div class="label-float">
            <input
              style={{ backgroundColor: "transparent" }}
              onChange={(e) => setfromName(e.target.value)}
              // type="text"
              placeholder=""
              value={fromName}
            />
            <label>
              {Text.From}<sup style={{ color: "red" }}>*</sup>
            </label>
          </div>
        </div>
      </form>
      <style jsx="true">
        {`
          .card-small {
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16),
              0 3px 6px rgba(0, 0, 0, 0.23);
            padding: 5px 10px 0 10px;
            margin-bottom: 16px;
          }
          .small-text {
            font-size: 14px;
            color: #666;
          }
          .text {
            margin-top: 0;
            padding-bottom: 10px;
          }
        `}
      </style>
    </>
  );
};

export default Subject;
