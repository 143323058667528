import React, { useEffect, useState } from "react";
import "./ResetPassword.css";
import { FaUserAlt, FaUnlock } from "react-icons/fa";
import * as constants from "Constants";
import axiosI from "Service/service";
const ResetPassword = ({ UserId, setPassword }) => {
  const [passwordData, setPasswordData] = useState({
    oldPassword: "",
    newPassword: "",
  });
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [passwordError, setPasswordError] = useState("");

  useEffect(() => {
    if (passwordData.oldPassword) {
      axiosI
        .post("CheckOldPassword", {
          OldPassword: passwordData.oldPassword,
          UserId,
        })
        .then(({ data }) => {

          if (data.results.Message === "Incorrect Password") {
            setPasswordError("The passwords you have entered do not match.");
            setPasswordMatch(false);
          } else {
            setPasswordMatch(true);
            setPasswordError("");
          }
        })
        .catch((err) => {});
    }

    if (passwordData.oldPassword === "") {
      setTimeout(() => {
        setPasswordError("");
      }, 500);
    }
  }, [passwordData.oldPassword]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setPasswordData({ ...passwordData, [name]: value });
  };

  return (
    <div style={{ marginLeft: 30, marginRight: 30, marginTop: 25 }}>
      <h4
        style={{
          marginTop: 10,
          marginBottom: 16,
          color: "#000000",
          fontWeight: "bold",
        }}
      >
        {constants.UserAccountText.resetpassword}
      </h4>

      <div class="row_input">
        <span className="form_icon">
          <FaUnlock color="lightgray" />
        </span>
        <input
          placeholder={constants.UserAccountText.currpassword}
          name="oldPassword"
          value={passwordData.oldPassword}
          onChange={handleChange}
          type="password"
        />
        {passwordError !== "" ? (
          <div className="error">
            <span>{passwordError}</span>
          </div>
        ) : null}
      </div>
      <div class="row_input">
        <span className="form_icon">
          <FaUnlock color="lightgray" />
        </span>
        <input
          placeholder={constants.UserAccountText.newpassword}
          name="newPassword"
          value={passwordData.newPassword}
          onChange={handleChange}
          type="password"
        />
      </div>

      <div className="login_cred">
        <a
          onClick={() => {
            passwordMatch ? setPassword(passwordData) : console.log("");
          }}
          className="login_button"
          href="#!"
          style={{
            backgroundColor: passwordMatch ? "#7B3FE4" : "#c2a4f5",
            color: "#fff",
            fontSize: 18,
            textDecoration: "none",
            marginBottom: 20,
          }}
        >
          {constants.UserAccountText.resetpassword}
        </a>
      </div>
    </div>
  );
};

export default ResetPassword;
