import React, { useState } from "react";
import {
  FacebookShareButton,
  WhatsappIcon,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  EmailIcon,
  EmailShareButton,
} from "react-share";
import "./Share.css";
import * as constants from "Constants";
import { MdOutlineContentCopy } from "react-icons/md";
const Share = ({ url }) => {
  const [copy, setCopy] = useState("copy");

  return (
    <div style={{ marginBottom: 50 }}>
      <div className="share_header">
        <h4 className="share_header_title">{constants.Message.share}</h4>
      </div>

      <div className="share_box">
        <div
          className="share_event_icons"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 50,
          }}
        >
          <FacebookShareButton
            url={url}
            // quote={"CampersTribe - World is yours to explore"}
            hashtag="#UBMeEvents"
            className="social-share-button"
          >
            <FacebookIcon size={40} round={true} />
            <h5>{constants.Message.Facebook}</h5>
          </FacebookShareButton>
          <TwitterShareButton
            url={url}
            // title={"CampersTribe - World is yours to explore"}
            hashtag="#UBMeEvents"
            className="social-share-button"
          >
            <TwitterIcon size={40} round={true} />
            <h5>{constants.Message.Twitter}</h5>
          </TwitterShareButton>
          <WhatsappShareButton
            url={url}
            // title={"CampersTribe - World is yours to explore"}
            separator=":: "
            className="social-share-button"
          >
            <WhatsappIcon size={40} round={true} />
            <h5>{constants.Message.Whatsapp}</h5>
          </WhatsappShareButton>
          <EmailShareButton
            url={url}
            // title={"CampersTribe - World is yours to explore"}
            separator=":: "
            className="social-share-button"
          >
            <EmailIcon size={40} round={true} />
            <h5>{constants.Message.Mail}</h5>
          </EmailShareButton>
        </div>
        <div className="share_copy_to_clipboard">
          <div className="share_event_url">
            <h6 style={{ marginLeft: 5, fontSize: 15, color: "#808080" }}>
              {constants.Message.events}
            </h6>
            <input value={url} className="clipboard_input" type="text" />
          </div>
          <span
            className="clip_board_icon"
            onClick={() => {
              navigator.clipboard.writeText(url);
              setCopy("Copied");
            }}
          >
            <MdOutlineContentCopy size={20} />
            <div className="share_clip_tooltip">
              <p
                style={{
                  marginBottom: 0,
                  fontSize: 15,
                  color: "#808080",
                  fontWeight: "bold",
                }}
              >
                {copy}
              </p>
            </div>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Share;
