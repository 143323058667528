import React from "react";
import HeroSection from "../HeroSection/HeroSection";
import MainContainer from "../Container/MainContainer";
import Navbar from "Components/UBMeEvents/Components_UBMeEvents/Navbar/Navbar";
import Footer from "Components/UBMeEvents/Components_UBMeEvents/Footer/Footer";
import { history } from "store";
const HomeScreen = () => {
  
  return (
    <>
      <Navbar/>
      <HeroSection />
      <MainContainer />
      <Footer/>
    </>
  );
};

export default HomeScreen;
