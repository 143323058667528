import React from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Label ,
  ResponsiveContainer,
} from "recharts";
const data = [
  {
    Age: "Others",
    Count: 0,
  },
];

const data2 = [
  {
    name: "Easton",
    uv: 4000,
  },
  {
    name: "Bethlehem",
    uv: 3000,
  },
  {
    name: "Allentown",
    uv: 2000,
  },
  {
    name: "Cooperburg",
    uv: 2780,
  },
  {
    name: "Nazareth",
    uv: 1890,
  },
];

function Chart({
  chartData,
  displayTitle,
  legendPosition,
  displayLegend,
  yaxis,
  chart2,
  activityData,
}) {

  let AgeGroup = [];
  if (Array.isArray(activityData?.CustomersByAgeGroup?.AgeGroup)) {
    AgeGroup = activityData?.CustomersByAgeGroup?.AgeGroup;
  } else {
    if (activityData?.CustomersByAgeGroup?.AgeGroup) {
      AgeGroup.push(activityData?.CustomersByAgeGroup?.AgeGroup);
    }
  }

  const yAxisFormatter = (value) => parseInt(value, 10);

  return (
    <div className="chart">
      <BarChart
        width={500}
        height={400}
        data={
             AgeGroup?.length > 0 ? AgeGroup?.map((item) => ({
              ...item,
              Age: item.Age === "Unknown" ? "Other" : item.Age === "[Under_18]" ? "Under 18" : item.Age,
            })) : data 
        }
        margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
        // margin={{
        //   top: 5,
        //   right: 30,
        //   left: 20,
        //   bottom: 5,
        // }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="Age" >
        <Label value="Age" position="bottom" offset={0} />
        </XAxis>
        {yaxis && <YAxis
        tickFormatter={yAxisFormatter}
        //  ticks={[0, 2000, 4000, 6000, 8000, 10000]}
          >
        <Label value="Attendee Count" angle={-90} position="left" offset={0} dx={20} dy={-40} />
          </YAxis>}
        <Tooltip />
        <Bar dataKey="Count" fill="#0099E5" />
      </BarChart>
    </div>
  );
}

Chart.defaultProps = {
  displayTitle: true,
  displayLegend: false,
  legendPosition: "bottom",
};

export default Chart;
