import {
  FETCH_OWN_EVENTS,
  CREATE_EVENT,
  DELETE_EVENT,
  UPDATE_EVENT,
  REFETCH_OWNEVENTS,
  CURRENT_EVENT_STATUS,
  CURRENT_EVENT_DETAILS,
} from "../Actions/actionTypes";
import moment from "moment";

const initial = {
  EventDetailData: [],
  TotalCounts: 0,
  ownEventReceived: false,
  status: false,
};
export default (state = initial, action) => {
  switch (action.type) {
    case CREATE_EVENT:
      let { EventDetailData } = state;
      EventDetailData.unshift(action.payload.EventDetailData[0]);
      return {
        ...state,
        EventDetailData,
        TotalCounts: state.TotalCounts + 1,
        ownEventReceived: true,
      };
    case FETCH_OWN_EVENTS:
      if (state.TotalCounts < action.payload.results.TotalCounts)
        return {
          ...state,
          ownEventReceived: true,
          TotalCounts: action.payload.results.TotalCounts,
          EventDetailData: action.payload.results.EventDetailData
            ? state.EventDetailData.concat(
                action.payload.results.EventDetailData
              ).sort(
                (a, b) =>
                  moment(b.CreatedDateTime, "MM/DD/YYYY hh:mm:ss P").unix() -
                  moment(a.CreatedDateTime, "MM/DD/YYYY hh:mm:ss P").unix()
              )
            : [],
        };
      else
        return {
          ...state,
          ownEventReceived: true,
          TotalCounts: state.TotalCounts,
        };
      break;
    case REFETCH_OWNEVENTS:
      return {
        ...state,
        ownEventReceived: false,
      };
      break;
    case UPDATE_EVENT:
      let newEditState = { ...state };
      let ind = newEditState.EventDetailData.findIndex(
        (event) =>
          event.EventId === action.payload.Results.EventDetailData[0].EventId
      );
      newEditState.EventDetailData.splice(ind, 1, {
        ...action.payload.event,
        startDateTime: action.payload.Results.EventDetailData[0].startDateTime,
        endDateTime: action.payload.Results.EventDetailData[0].endDateTime,
      });
      return {
        ...newEditState,
        TotalCounts: state.TotalCounts,
        ownEventReceived: true,
      };
    case DELETE_EVENT:
      let newState = { ...state };
      let eventArray = {};
      eventArray["EventDetailData"] = newState.EventDetailData.filter(
        (event) => event.EventId !== action.payload.EventId
      );
      return {
        ...eventArray,
        TotalCounts: state.TotalCounts - 1,
        ownEventReceived: true,
      };
    case CURRENT_EVENT_STATUS:
      return { ...state, status: action.payload };

    case CURRENT_EVENT_DETAILS:
      return { ...state, details: action.payload };
    default:
      return state;
  }
};
