import moment from "moment";
import React from "react";
import { useEffect } from "react";
import * as constants from "Constants";
import Select from "react-select";
import { useState } from "react";
const RenderTicktsData = ({
                            item,
                            handleChange,
                            TimeZone,
                            cartItems,
                            selectedOption,
                            setSelectedOption,
                            checkDateWithinRange,
                            currentTicket,
                            recurringData,
                            shoppingCartDetails,
                            bundleSelectedQuantity,
                            setBundleSelectedQuantity
                          }) => {
  const [isShowInput, setIsShowInput] = useState(false);
  const [availableQnty, setAvailableQnty] = useState(0);

  useEffect(() => {
    const isRecData = cartItems.filter((x) => x.TicketId === item?.TicketId);
    if (
        isRecData?.length > 0 &&
        isRecData[0]?.selectedDate === selectedOption?.selectedDate &&
        isRecData[0]?.selectedSlot !== null
    ) {

      setAvailableQnty(isRecData[0]?.selectedSlot?.AvailableTickets);
      setIsShowInput(true);
      console.log("6");
    } else if (
        isRecData?.length > 0 &&
        isRecData[0]?.selectedDate === selectedOption?.selectedDate &&
        isRecData[0]?.selectedSlot == null
    ) {
      setIsShowInput(false);
      console.log("7");
    } else if (
        isRecData?.length > 0 &&
        isRecData[0]?.selectedDate === selectedOption?.selectedDate &&
        isRecData[0]?.selectedSlot !== null
    ) {
      setAvailableQnty(isRecData[0]?.selectedSlot?.AvailableTickets);
      setIsShowInput(true);
      console.log("8");
    } else if (
        isRecData?.length > 0 &&
        isRecData[0]?.selectedDate !== selectedOption?.selectedDate &&
        isRecData[0]?.selectedSlot !== null
    ) {
      setAvailableQnty(isRecData[0]?.selectedSlot?.AvailableTickets);
      setIsShowInput(true);
      console.log("8.5");
    } else if (
        selectedOption?.TicketId === item?.TicketId &&
        selectedOption?.selectedSlot == null
    ) {
      setIsShowInput(false);
      console.log("9");
    } else {

      console.log("10");
    }
  }, [selectedOption?.selectedDate]);

  useEffect(() => {
    const isRecData = cartItems.filter((x) => x.TicketId === item?.TicketId);
    if (
        isRecData?.length > 0 &&
        isRecData[0]?.selectedDate === selectedOption?.selectedDate &&
        isRecData[0]?.selectedSlot !== null
    ) {
      setAvailableQnty(isRecData[0]?.selectedSlot?.AvailableTickets);
      setIsShowInput(true);
      console.log("1");
    } else if (
        isRecData?.length > 0 &&
        isRecData[0]?.selectedDate === selectedOption?.selectedDate &&
        isRecData[0]?.selectedSlot == null
    ) {
      setIsShowInput(false);
      console.log("2");
    } else {
      if (
          selectedOption?.item?.TicketId === item?.TicketId &&
          selectedOption?.selectedSlot !== null
      ) {

        setAvailableQnty(selectedOption?.selectedSlot?.AvailableTickets);
        setIsShowInput(true);
        console.log("3");
      } else if (
          selectedOption?.TicketId === item?.TicketId &&
          selectedOption?.selectedSlot == null
      ) {
        setIsShowInput(false);
        console.log("4");
      } else {
        console.log({ selectedOption, cartItems, isRecData });
        console.log("5");
      }
    }
  }, [selectedOption?.selectedSlot]);

  const [isSaleOn, setIsSaleOn] = useState(false);
  const [saleEnded, setSaleEnded] = useState(false);
  useEffect(() => {
    if (
        moment(item?.ActiveDate, "MM/DD/YYYY hh:mm:ss A").isAfter(
            moment(
                moment().tz(TimeZone).format("MM/DD/YYYY hh:mm:ss A"),
                "MM/DD/YYYY hh:mm:ss A"
            )
        )
    ) {
      setIsSaleOn(true);
    }
    if (
        moment(item?.InActiveDate, "MM/DD/YYYY hh:mm:ss A").isBefore(
            moment(
                moment().tz(TimeZone).format("MM/DD/YYYY hh:mm:ss A"),
                "MM/DD/YYYY hh:mm:ss A"
            )
        )
    ) {
      setSaleEnded(true);
    }
  }, []);

  const handleNumberOnlyInput = (e) => {
    if (e.nativeEvent.code === "KeyE" || e.nativeEvent.code === "Minus") {
      e.preventDefault();
    }

    // return (
    //   <>
    //     <div key={item.EventId} class="body_row render-ticket-mobile">
    //       <div style={{ justifyContent: "flex-start" }} class="col_2">
    //         <div className="ticket_menu_options">
    //           {saleEnded && Number(item?.AvailableTicket) !== 0 && (
    //             <p
    //               style={{
    //                 fontWeight: 600,
    //                 color: "rgb(135, 143, 153)",
    //                 bottom: -6,
    //                 left: 36,
    //                 fontSize: 14,
    //                 padding: "20px 0px",
    //                 textAlign: "right",
    //               }}
    //             >
    //               {constants.Text.ticketSaleEnd}
    //             </p>
    //           )}

    //           {Number(item?.AvailableTicket) === 0 &&
    //             Number(item?.ReservedTicket) === 0 && (
    //               <p
    //                 style={{
    //                   fontWeight: 600,
    //                   color: "rgb(135, 143, 153)",
    //                   bottom: -6,
    //                   left: 36,
    //                   fontSize: 14,
    //                   padding: "20px 0px",
    //                   textAlign: "right",
    //                 }}
    //               >
    //                 {constants.Text.soldOut}
    //               </p>
    //             )}

    //           {Number(item?.AvailableTicket) === 0 &&
    //             Number(item?.ReservedTicket) !== 0 && (
    //               <p
    //                 style={{
    //                   fontWeight: 600,
    //                   color: "rgb(135, 143, 153)",
    //                   bottom: -6,
    //                   left: 36,
    //                   fontSize: 14,
    //                   padding: "20px 0px",
    //                   textAlign: "right",
    //                 }}
    //               >
    //                 {constants.Text.Reserved}
    //               </p>
    //             )}

    //           {isSaleOn && (
    //             <p
    //               style={{
    //                 fontWeight: 600,
    //                 color: "rgb(135, 143, 153)",
    //                 bottom: -6,
    //                 left: 36,
    //                 fontSize: 14,
    //                 textAlign: "right",
    //                 padding: "20px 0px",
    //               }}
    //             >
    //               {`On Sale ${moment(item?.ActiveDate).format("MMM DD")} at
    //                  ${moment(item?.ActiveDate).format("hh:mm A")}`}
    //             </p>
    //           )}

    //           {Number(item?.AvailableTicket) !== 0 ? (
    //             <React.Fragment>
    //               {!isSaleOn && !saleEnded && (
    //                 <Select
    //                   styles={customStyles}
    //                   // maxMenuHeight={500}
    //                   components={{
    //                     IndicatorSeparator: () => null,
    //                   }}
    //                   isSearchable={false}
    //                   // isDisabled={isSaleOn || saleEnded}
    //                   onKeyDown={handleNumberOnlyInput}
    //                   defaultValue={{
    //                     value:
    //                       defaultValue === undefined
    //                         ? 0
    //                         : cartItems.length > 0
    //                         ? Number(defaultValue?.Quantity)
    //                         : 0,
    //                     label:
    //                       defaultValue === undefined
    //                         ? 0
    //                         : cartItems.length > 0
    //                         ? defaultValue?.Quantity
    //                         : 0,
    //                   }}
    //                   // value={selectedOption}
    //                   onChange={(val) => {
    //                     handleChange(val, item);
    //                     // setSelectedOption(val);
    //                   }}
    //                   options={[
    //                     ...Array(
    //                       Number(
    //                         Number(item?.AvailableTicket) > 15
    //                           ? 16
    //                           : Number(item?.AvailableTicket) + 1
    //                       )
    //                     ).keys(),
    //                   ]?.map((i) => {
    //                     return { value: i, label: i };
    //                   })}
    //                 />
    //               )}
    //             </React.Fragment>
    //           ) : null}
    //         </div>
    //       </div>
    //       <div style={{ direction: "rtl" }} class="col_10">
    //         <h4 style={{ fontSize: 22, marginBottom: 10, fontWeight: 600 }}>
    //           {item.Title}
    //         </h4>
    //         <h5 style={{ fontSize: 20, marginBottom: 5, fontWeight: 600 }}>
    //           {item.TicketType === "0"
    //             ? "Free"
    //             : `$${Intl.NumberFormat(undefined, {
    //                 minimumFractionDigits: 2,
    //                 maximumFractionDigits: 2,
    //               }).format(item?.Price)}`}
    //         </h5>

    //         <div>
    //           {!isSaleOn && (
    //             <p style={{ color: "#878f99", fontWeight: 600 }}>
    //               {" "}
    //               {constants.Text.salesEndOn}{" "}
    //               {moment(item?.InActiveDate).format("MMM DD, yyyy")}
    //             </p>
    //           )}
    //         </div>
    //       </div>
    //     </div>
    //     {/* <div className="underline" /> */}
    //   </>
    // );
  };

  // useEffect(() => {
  const defaultValue = cartItems?.find((p) => p.TicketId === item?.TicketId);
  // if (!cartItems.length > 0) {
  //   setSelectedOption("");
  // }
  // }, [cartItems]);

  // console.log("defaultValue", defaultValue);
  const customStyles = {
    // control represent the select component
    control: (provided) => ({
      ...provided,
      width: "100px",
    }),
  };

  return (
      <>
        <div key={item.EventId} class="body_row render-ticket-mobile">
          <div style={{ justifyContent: "flex-start" }} class="col_2">
            <div className="ticket_menu_options">
              {saleEnded && Number(item?.AvailableTicket) !== 0 && (
                  <p
                      style={{
                        fontWeight: 600,
                        color: "rgb(135, 143, 153)",
                        bottom: -6,
                        left: 36,
                        fontSize: 14,
                        padding: "20px 0px",
                        textAlign: "right",
                      }}
                  >
                    {constants.Text.ticketSaleEnd}
                  </p>
              )}

              {item?.RecurringTicket !== "True" &&
                  Number(item?.AvailableTicket) === 0 &&
                  Number(item?.ReservedTicket) === 0 && (
                      <p
                          style={{
                            fontWeight: 600,
                            color: "rgb(135, 143, 153)",
                            bottom: -6,
                            left: 36,
                            fontSize: 14,
                            padding: "20px 0px",
                            textAlign: "right",
                          }}
                      >
                        {constants.Text.soldOut}
                      </p>
                  )}

              {item?.RecurringTicket == "True" &&
              selectedOption?.availableSlots?.length > 0
                  ? selectedOption?.availableSlots?.every(
                  (obj) =>
                      obj.TicketId === item?.TicketId && obj.AvailableTickets == 0
              ) && (
                  <p
                      style={{
                        fontWeight: 600,
                        color: "rgb(135, 143, 153)",
                        bottom: -6,
                        left: 36,
                        fontSize: 14,
                        padding: "20px 0px",
                        textAlign: "right",
                      }}
                  >
                    {constants.Text.soldOut}
                  </p>
              )
                  : ""}

              {item?.RecurringTicket !== "True" &&
                  Number(item?.AvailableTicket) === 0 &&
                  Number(item?.ReservedTicket) !== 0 && (
                      <p
                          style={{
                            fontWeight: 600,
                            color: "rgb(135, 143, 153)",
                            bottom: -6,
                            left: 36,
                            fontSize: 14,
                            padding: "20px 0px",
                            textAlign: "right",
                          }}
                      >
                        {constants.Text.Reserved}
                      </p>
                  )}

              {isSaleOn && (
                  <p
                      style={{
                        fontWeight: 600,
                        color: "rgb(135, 143, 153)",
                        bottom: -6,
                        left: 36,
                        fontSize: 14,
                        textAlign: "right",
                        padding: "20px 0px",
                      }}
                  >
                    {`On Sale ${moment(item?.ActiveDate).format("MMM DD")} at
                   ${moment(item?.ActiveDate).format("hh:mm A")}`}
                  </p>
              )}

              {((item?.RecurringTicket !== "True" &&
                  Number(item?.AvailableTicket) !== 0) || item?.isBundle) ? (
                  <React.Fragment>
                    {!isSaleOn && !saleEnded && (
                        item?.isBundle ? (
                            <Select
                                styles={customStyles}
                                components={{
                                  IndicatorSeparator: () => null,
                                }}
                                isSearchable={false}
                                onKeyDown={handleNumberOnlyInput}
                                value={{
                                  value: (() => {
                                    const isRecData = cartItems.find((x) => x.TicketId === item.TicketId);
                                    return isRecData ? isRecData.Quantity : bundleSelectedQuantity[item.TicketId] || 0;
                                  })(),
                                  label: (() => {
                                    const isRecData = cartItems.find((x) => x.TicketId === item.TicketId);
                                    return isRecData ? isRecData.Quantity : bundleSelectedQuantity[item.TicketId] || 0;
                                  })(), // Use defaultValue
                                }}
                                // cartItems?.find((x) => x.TicketId === item.TicketId) ? bundleSelectedQuantity[item.TicketId] || 0, // Use defaultValue

                                onChange={(val) => {
                                    handleChange(val, item);
                                }}
                                options={[
                                  ...Array(
                                      Number(16)
                                  ).keys(),
                                ].map((i) => {
                                  return { value: i, label: i };
                                })}
                            />
                        ) : (
                            <Select
                                styles={customStyles}
                                // maxMenuHeight={500}
                                components={{
                                  IndicatorSeparator: () => null,
                                }}
                                isSearchable={false}
                                // isDisabled={isSaleOn || saleEnded}
                                onKeyDown={handleNumberOnlyInput}
                                value={{
                                  value:
                                      defaultValue === undefined
                                          ? 0
                                          : cartItems.length > 0
                                              ? Number(defaultValue?.Quantity)
                                              : 0,
                                  label:
                                      defaultValue === undefined
                                          ? 0
                                          : cartItems.length > 0
                                              ? defaultValue?.Quantity
                                              : 0,
                                }}
                                // value={selectedOption}
                                onChange={(val) => {
                                  handleChange(val, item);
                                }}
                                options={[
                                  ...Array(
                                      Number(
                                          Number(item?.AvailableTicket) > 15
                                              ? 16
                                              : Number(item?.AvailableTicket) + 1
                                      )
                                  ).keys(),
                                ].map((i) => {
                                  return { value: i, label: i };
                                })}
                            />
                        )
                    )}
                  </React.Fragment>
              ) : null}

              {item?.RecurringTicket === "True" && !item?.isBundle ? (
                  <React.Fragment>
                    {checkDateWithinRange(item?.ActiveDate, item?.InActiveDate) &&
                        isShowInput && (
                            <Select
                                styles={customStyles}
                                // maxMenuHeight={500}
                                components={{
                                  IndicatorSeparator: () => null,
                                }}
                                isSearchable={false}
                                // isDisabled={isSaleOn || saleEnded}
                                onKeyDown={handleNumberOnlyInput}
                                value={{
                                  value:
                                      defaultValue === undefined
                                          ? 0
                                          : cartItems.length > 0
                                              ? Number(defaultValue?.Quantity)
                                              : 0,
                                  label:
                                      defaultValue === undefined
                                          ? 0
                                          : cartItems.length > 0
                                              ? defaultValue?.Quantity
                                              : 0,
                                }}
                                // value={selectedOption}
                                onChange={(val) => {
                                  const isRecData = cartItems.filter(
                                      (x) => x.TicketId === item?.TicketId
                                  );
                                  isRecData.length > 0
                                      ? handleChange(val, item, {
                                        selectedDate: isRecData[0]?.selectedDate,
                                        selectedSlot: isRecData[0]?.selectedSlot,
                                      })
                                      : handleChange(val, item, recurringData);
                                }}
                                options={[
                                  ...Array(
                                      Number(
                                          Number(availableQnty) > 15
                                              ? 16
                                              : Number(availableQnty) + 1
                                      )
                                  ).keys(),
                                ].map((i) => {
                                  return { value: i, label: i };
                                })}
                            />
                        )}
                  </React.Fragment>
              ) : null}
            </div>
          </div>
          <div style={{ direction: "rtl" }} class="col_10">
            <h4 style={{ fontSize: 22, marginBottom: 10, fontWeight: 600 }}>
              {item.Title}
            </h4>
            {item.Description !== "" && (
                <h4 style={{ fontSize: 15, marginBottom: 10, color: "darkgray", wordWrap: "break-word" }}>
                  {item.Description}
                </h4>
            )}
            <h5 style={{ fontSize: 20, marginBottom: 5, fontWeight: 600 }}>
              {item.TicketType === "0"
                  ? "Free"
                  : `${(shoppingCartDetails !== undefined && shoppingCartDetails !== '') ? shoppingCartDetails?.CartSession.CurrencySymbol : ""}${Number(item?.Price).toFixed(2)}`}
            </h5>

            <div>
              {!isSaleOn && (
                  <p style={{ color: "#878f99", fontWeight: 600 }}>
                    {" "}
                    {constants.Text.salesEndOn}{" "}
                    {moment(item?.InActiveDate).format("MMM DD, yyyy")}
                  </p>
              )}
            </div>
            {
              item.isBundle ?
                  (bundleSelectedQuantity[item.TicketId] > 0) ? (
                      cartItems?.find((x) => x.TicketId === item.TicketId && (x.selectedSlots !== undefined && x.selectedSlots !== null  && x.selectedSlots.length) === parseInt(item.SlotPerBundle)) ? (
                          <div>
                            {!isSaleOn && (
                                <p style={{ color: "#47c94d", fontWeight: 600 }}>
                                  {" "}
                                  {constants.Text.bundleTicketSelectionSuccessMessage1}{item.SlotPerBundle}{constants.Text.bundleTicketSelectionSuccessMessage2}
                                </p>
                            )}
                          </div> ) :( <div>
                            {!isSaleOn && (
                                <p style={{ color: "#FF0000", fontWeight: 600 }}>
                                  {" "}
                                  {constants.Text.bundleTicketMessage1}{(cartItems?.find((x) => x.TicketId === item.TicketId && (x.selectedSlots !== undefined && x.selectedSlots !== null && x.selectedSlots.length)) ? cartItems.find((x) => x.TicketId === item.TicketId).selectedSlots.length : 0)}{"/"}{item.SlotPerBundle}{constants.Text.bundleTicketMessage2}
                                </p>
                            )}
                          </div>
                      )
                  ) : (
                      <div>
                        {!isSaleOn && (
                            <p style={{ fontWeight: 600 }}>
                              {" Please select the number of people attending."}
                            </p>
                        )}
                      </div>
                  )
                  : ""
            }
          </div>
        </div>
        {/* <div className="underline" /> */}
      </>
  );
};
export default RenderTicktsData;
