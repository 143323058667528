import { ENABLE, DISABLE } from '../Actions/actionTypes';
export default (state=false,action)=>{
    switch(action.type){
        case ENABLE:
            return action.payload;
	    case DISABLE:
		    return action.payload;
        default:
            return state;
    }
}