import React, {Component, useEffect, useState} from "react";
import "./orderDetail.css";
import { IoCloseOutline } from "react-icons/io5";
import { axiosE } from "Service/service";
import { default as momentTz } from "moment-timezone";
import moment from "moment/moment";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import {eventApis, Events, Text} from "Constants";
import { Box, Button, Modal } from "@material-ui/core";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/Create";
import {reusableClasses} from "../../../EventDetails/resusableClasses";
import DeleteConfirmationModal from "../ModalAction/DeleteConfirmationDialog"; // Import the DeleteConfirmationModal component
import * as constants from "Constants";

function Notes({
                         isOpen,
                         orderId,
                         setOrderId,
                         TimeZone,
                         GetAllAttendees,
                         isEventBookings = false,
                     }) {
    const [isLoading, setIsLoading] = useState(false);
    const [noteText, setNoteText] = useState(""); // State to manage textarea content
    const [notesList, setNotesList] = useState([]); // State for notes list
    const [editingNoteId, setEditingNoteId] = useState(null);
    const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
    const [noteToDeleteId, setNoteToDeleteId] = useState(null);

    useEffect(() => {
        if (isOpen) {
            fetchNotesDataByOrderId()
        }
    }, [isOpen]);


    const [isModalOpen, setIsModalOpen] = useState(false);
    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const fetchNotesDataByOrderId = async () => {
        try {
            const response = await axiosE.get(`GetNotesByOrderId/${orderId.orderId}`);
            const notesData = response.data.results.Data;
            setNotesList(notesData);
        } catch (error) {
            // Handle error
        }
    };

    const addNotes = async () => {
        try {
            const { data } = await axiosE.post("AddAdminNote", {
                Note: {
                    OrderId: orderId.orderId,
                    Note: noteText,
                },
            });
            if (data.results.ResponseCode) {
                toast("Note added successfully");
                setNoteText(""); // Clear the textarea
                fetchNotesDataByOrderId();
            } else {
                // Handle add failure
            }

        } catch (error) {}
    };

    const editNote = (noteId, noteText) => {
        setEditingNoteId(noteId);
        setNoteText(noteText);
    };

    const updateNote = async () => {
        try {
            const { data } = await axiosE.post("UpdateAdminNote", {
                Note: {
                    NoteId: editingNoteId,
                    Note: noteText,
                },
            });

            if (data.results.ResponseCode) {
                toast("Note updated successfully");
                setEditingNoteId(null);
                setNoteText(""); // Clear the textarea
                fetchNotesDataByOrderId(); // Refresh notes list
            } else {
                // Handle update failure
            }
        } catch (error) {
            // Handle error
        }
    };

    const deleteNote = async (noteId) => {
        try {
            const { data } = await axiosE.post("DeleteAdminNote", {
                NoteId: noteId,
            });
            if (data.results.ResponseCode) {
                toast("Note deleted successfully");
                setIsDeleteConfirmationOpen(false);
                setNoteToDeleteId(null);
                fetchNotesDataByOrderId();
            } else {
                // Handle delete failure
            }
        } catch (error) {
            // Handle error
        }
    };

    const timeUTCtoLocal = (date) => {
        var local = momentTz
            .tz(moment.utc(date), "MM/DD/YYYY hh:mm:ss a", TimeZone)
            .format("MM/DD/YYYY hh:mm:ss a");
        return moment(local, "MM/DD/YYYY hh:mm:ss a").format("llll");
    };

    const sortedNotesList = notesList.sort((a, b) =>
        new Date(b.CreatedDateTime) - new Date(a.CreatedDateTime)
    );

    const progressLoader = () => {
        return (
            <Box
                sx={{
                    display: "flex",
                    width: "100%",
                    height: "80vh",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "20px",
                }}
            >
                <CircularProgress color="secondary" />
            </Box>
        );
    };

    return (
        <div className="orderDetailModal">
            <ToastContainer
                className="toaster-container"
                position="top-right"
                autoClose={5000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="edit_container">
                <div style={{ padding: 10 }} className="edit_container_close_btn">
                    <IoCloseOutline
                        size={25}
                        color="rgba(0, 0, 0, 0.54)"
                        onClick={isOpen}
                    />
                </div>
                <p className="edit_container_header">Add Notes</p>
                <div />
            </div>
            {!isLoading ? (
                <>
                    <textarea
                        value={noteText}
                        onChange={(e) => setNoteText(e.target.value)}
                        style={{
                            height: 200,
                            width: "100%",
                            resize: "none",
                            padding: 10,
                            whiteSpace: "pre-line",
                            margin: "10px 0px 20px 0px",
                            border: "1px solid #ced4da",
                            outline: "none",
                            overflowWrap: "break-word",
                        }}
                    />
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end", // Right-align the content
                        }}
                    >
                        {!editingNoteId ? (
                        <Button
                            className={reusableClasses.addOrUpdateModalHeaderButtonSave}
                            onClick={() => addNotes()}
                            variant={"contained"}
                            disabled={!noteText.trim()}
                            style={{
                                backgroundColor: !noteText.trim() ? "rgba(163, 56, 164, 0.4)" : "rgb(163, 56, 164)",
                                color: "white",
                                width: "140px",
                                height: "50px",
                                fontSize:"15px",
                            }}
                        >
                            {Text.Save}
                        </Button>
                        ) : (
                            <div>
                                <Button
                                    className={reusableClasses.addOrUpdateModalHeaderButtonSave}
                                    onClick={updateNote}
                                    variant="contained"
                                    disabled={!noteText.trim()}
                                    style={{
                                        backgroundColor: !noteText.trim() ? "rgba(163, 56, 164, 0.4)" : "rgb(163, 56, 164)",
                                        color: "white",
                                        width: "140px",
                                        height: "50px",
                                        fontSize:"15px",
                                        marginRight: "10px",
                                    }}
                                >
                                    {Text.Update}
                                </Button>
                                <Button
                                    className={reusableClasses.addOrUpdateModalHeaderButtonSave}
                                    onClick={() => {
                                        setEditingNoteId(null);
                                        setNoteText("");
                                    }}
                                    style={{
                                        border: "1px solid #968e8e",
                                        width: "140px",
                                        height: "50px",
                                        fontSize:"15px",
                                    }}
                                >
                                    Cancel
                                </Button>
                            </div>
                            )}
                    </div>

                    {/* Add space before the table */}
                    <div style={{ marginTop: "40px" }} />

                    {/* Display notes in tabular view */}
                    <table style={{ width: "100%" }}>
                        <tbody>
                        {sortedNotesList.map((note, index) => (
                            <tr
                                key={note.NoteId}
                                style={{ backgroundColor: index % 2 === 0 ? "#f2f2f2" : "white" }}
                            >
                                <td>
                                    <span style={{ fontWeight: "bold" }}>
                                      {timeUTCtoLocal(note.CreatedDateTime)} {/* Assuming this is the date */}
                                    </span>
                                    {" - "}
                                    {note.Note}
                                </td>
                                <td>
                                    {!editingNoteId ? (
                                        <div style={{ display: "flex" }}>
                                            <IconButton onClick={() => editNote(note.NoteId, note.Note)}>
                                                <CreateIcon />
                                            </IconButton>
                                            {/*<IconButton onClick={() => deleteNote(note.NoteId)}>*/}
                                            <IconButton onClick={() => {
                                                setIsDeleteConfirmationOpen(true);
                                                setNoteToDeleteId(note.NoteId)
                                            }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </div>
                                    ) : (
                                        null // No Edit/Delete icons during editing
                                    )}
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>

                    {/* Delete Confirmation Dialog */}
                    <DeleteConfirmationModal
                        isOpen={isDeleteConfirmationOpen}
                        onClose={() => setIsDeleteConfirmationOpen(false)}
                        onDelete={() => {deleteNote(noteToDeleteId)}}
                        message={constants.Text.deleteNoteMessage} // Pass the message
                    />
                </>
            ) : (
                progressLoader()
            )}
        </div>
    );
}

export default Notes;
