import React, { useState } from "react";
import "./Login.css";
import { Link } from "react-router-dom";
import { FaUserAlt, FaUnlock } from "react-icons/fa";
import { FormControl } from "react-bootstrap";
import { bindActionCreators } from "redux";
import { history } from "store";
import { connect } from "react-redux";
import { loginAction, saveUserData,GetUserProfileDetail } from "Actions";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { homeRoute, routeName } from "Service/env";
import { audioFunc } from "Service/helper";
import * as constants from "Constants";
import "../../Components_UBMeEvents/modal/Modal.css";
import axiosI from "Service/service";
import { useEffect } from "react";
import {ws} from "../../../../App";

const Login = (props) => {

  const {callBackfn = () =>{}} = props

  const [credentials, setCredential] = useState({
    Email: "",
    Password: "",
  });
  const [lblErrorMsg, setLblErrorMsg] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;

    setCredential({ ...credentials, [name]: value });
    setLblErrorMsg("");
  };


  // useEffect(() => {
  //   const keyDownHandler = event => {
  //     console.log('User pressed: ', event.key);

  //     if (event.key === 'Enter') {
  //       event.preventDefault();

  //       // 👇️ call submit function here
  //       handleSubmit(credentials);
  //     }
  //   };

  //   document.addEventListener('keydown', keyDownHandler);

  //   return () => {
  //     document.removeEventListener('keydown', keyDownHandler);
  //   };
  // }, []);

  const _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmit(e)
    }
  }

  const handleSubmit = (e) => {
    audioFunc(true, false, "");
    e.preventDefault();

    if (
      Object.keys(credentials).length <= 1 ||
      credentials.Email === "" ||
      credentials.Password === ""
    ) {
      setLblErrorMsg(constants.Message.bothFieldsAreRequiredMsg);
    } else {
      props
        .loginAction(credentials)
        .then((data) => {

          if (data !== undefined && data.results !== undefined) {
            //
            if (
              (!data.results.ResponseCode && data.results === undefined) ||
              !data.results.ResponseCode
            ) {
              setLblErrorMsg(constants.Message.invalidCredentialsMsg);
            } else if (data.results.ResponseCode && data.results.Token) {


              // let date = new Date();
              //
              // ws.json({
              //   token_id: localStorage.getItem("token"),
              //   user_id: data.results.UserId.toString(),
              //   type: "identity",
              //   isOnline: 1,
              //   msgid: date.getTime().toString().slice(4),
              // });

              if (
                props.userHistory !== null &&
                props.userHistory !== undefined
              ) {
                if(props.userHistory !== 'loggedin' && props.userHistory !== "messages" && !localStorage.getItem("organizerLandingPageButtonClick")){
                  history.replace(`${routeName}/${props.userHistory}`);
                }
                if(localStorage.getItem("pendingEventsButtonClick") === "messages"){
                 localStorage.setItem("messageClickWithoutLogin", "true");
                  if(props.userHistory === "messages"){
                    setTimeout(() => {
                      callBackfn()
                    }, 1000);
                  }
                }
              } else {
                history.replace(`/${homeRoute}`);
              }
              props.closeModal();
            } else {
              props.next(props.data);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const onForgotPassword = () => {
    props.setForgotPassword(true);
  };

  return (
    <div style={{ marginLeft: 30, marginRight: 30, marginTop: 25 }}>
      <form>

      <h4
        style={{
          marginTop: 10,
          marginBottom: 16,
          color: "#000000",
          fontWeight: "bold",
        }}
      >
        {constants.LoginSignUpText.loginToDashboard}
      </h4>
      <div className="error">
        {lblErrorMsg !== "" ? <span>{lblErrorMsg}</span> : <span />}
      </div>
      <div class="row_input">
        {/* <label for="email">Email</label> */}
        <span className="form_icon">
          <FaUserAlt color="lightgray" />
        </span>
        <input
          placeholder={constants.LoginSignUpText.usernameoremail}
          name={constants.LoginSignUpText.email}
          value={credentials.Email}
          onChange={handleChange}
          type="text"
          autoComplete="new-password"
        />
      </div>
      <div class="row_input">
        {/* <label for="password">Password</label> */}
        <span className="form_icon">
          <FaUnlock color="lightgray" />
        </span>
        <input
          placeholder={constants.LoginSignUpText.password}
          onChange={handleChange}
          onKeyDown={_handleKeyDown}
          name={constants.LoginSignUpText.password}
          value={credentials.Password}
          type="password"
          // autocomplete="rutjfkde"
        />
      </div>

      <div className="login_cred">
        <Link style={{ color: "#03ad31" }} onClick={onForgotPassword} to="#">
          {" "}
          <h4 className="forgot_password">
            {constants.LoginSignUpText.forgotPassword}{" "}
          </h4>
        </Link>
        <button
          // type="submit"
          onClick={handleSubmit}
          className="login_button"
          // href="#!"
          style={{ color: "#fff", fontSize: 18, textDecoration: "none" }}
        >
          {constants.LoginSignUpText.logIn}
        </button>
        <h4 className="forgot_password" style={{ color: "#000000" }}>
          {constants.LoginSignUpText.newhere}{" "}
          <Link
            onClick={() => props.setIsSignUp(true)}
            style={{ color: "#03ad31", fontSize: 15 }}
            to="#!"
          >
            {constants.LoginSignUpText.signUp}
          </Link>
        </h4>
      </div>
      </form>
    </div>
  );
};

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      loginAction,
      saveUserData,
      GetUserProfileDetail,
    },
    dispatch
  );
};

export default connect(null, matchDispatchToProps)(Login);



// const payload = {

//   userId:"",// 13173
//   ticketData:[
//     {
//       event_id:"",// 4738573930384ht9348hf
//       type:"", // vip
//       tickets:"" // 4 (number of tickets)
//     },
//     {
//       event_id:"",// 4738573930384ht9348hf
//       type:"", // standerd
//       tickets:"" // 2 (number of tickets)
//     }
// ],

//   total_amount:"950", // total ticket amount
//   payment_id:"", // ewieb76sd7s8sd8s

// }


