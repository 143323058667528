import React from "react";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import {Events, Text} from "../../../../../../Constants";
import {Box} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    DeleteModal: {
        width: "430px",
        height: "160px",
        backgroundColor: "white",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "10px",
        padding: "10px",

        "@media (max-width: 540px)": {
            width: "70%",
            height: "200px",
        },
        "@media (max-width: 410px)": {
            width: "90%",
        },
    },
    DeleteModalImgWrapper: {
        width: "100px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100px",
    },
    DeleteModalFAQImg: {
        width: "30px",
    },
    DeleteModalFAQButton: {
        fontSize: "10px",
        padding: "10px 25px",
    },
}));

function DeleteConfirmationModal({ isOpen, onClose, onDelete, message }) {
    const classes = useStyles(); // Call the makeStyles hook to get the classes object

    // Convert \n characters to <br> elements in the message
    const messageWithLineBreaks = message.split('\n').map((line, index) => (
        <React.Fragment key={index}>
            {line}
            <br />
        </React.Fragment>
    ));

    return (
        <Modal open={isOpen} onClose={onClose}>
            <Box className={classes.DeleteModal}>
                <div className={classes.DeleteModalImgWrapper}>
                    <img
                        src={Events.FAQ.deleteFAQ.img}
                        alt="Delete Icon"
                        className={classes.DeleteModalFAQImg}
                    />
                </div>

                <p style={{ textAlign: "center" }}>{messageWithLineBreaks}</p>

                <div>
                    <Button
                        className={classes.DeleteModalFAQButton}
                        onClick={onClose}
                        style={{ color: "blue" }}
                    >
                        {Text.Cancel}
                    </Button>

                    <Button
                        className={classes.DeleteModalFAQButton}
                        onClick={onDelete}
                        style={{ color: "red" }}
                    >
                        {Text.Delete}
                    </Button>
                </div>
            </Box>
        </Modal>
    );
}

export default DeleteConfirmationModal;
