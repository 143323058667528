import React, { Component } from 'react';
import { FormControl } from 'react-bootstrap';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import Logo from '../../Helper/Logo/logo';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { resetPasswordAction } from '../../Actions';

const styles={
    Button: {
        color: '#fff',
        borderRadius: '5px',
        fontSize:'medium',
        marginBottom:'3%',
        textTransform:'none'
    },
};

class ForgotPassword extends Component {
    constructor(props){
        super(props);
        this.state={
            email:"",
            showMsg:false,
            lblErrorMsg:''
        }
    }
    handleChange=(e)=>{
        const { value } = e.target;
        this.setState({email:value})
    };

    onSubmit=()=>{
        this.props.resetPasswordAction(this.state.email).then((data)=>{
            if(data.results.ResponseCode) {
                this.setState({
                    showMsg:true
                })
            }
            else{
                this.setState({
                    lblErrorMsg:data.results.Message
                })
            }
        });
    };

    render(){
        const { email,lblErrorMsg } = this.state;
        const { classes } = this.props;
        return(
            <section className="container">
                <div id="loader"/>
                <div className="row">
                    <div className="col-12 text-center">
                        <Logo/>
                    </div>
                    <div className="col-12">
                        <h1 style={{color:'#851679'}}>Forgot Password</h1>
                        <div className="error">
                            {
                                (lblErrorMsg!=='')?<span>{lblErrorMsg}</span>:<span/>
                            }
                        </div>
                        <div className="form-group">
                            <FormControl type={'text'} autoComplete={'off'} placeholder="Your Email Address"
                                         name="email"
                                         onChange={this.handleChange}
                                         value={email}
                            />
                        </div>
                        <div className="form-group text-right mt-30">
                            <Button className={classes.Button} color={'primary'} variant={'contained'} onClick={this.onSubmit}>Reset Password</Button>
                        </div>
                        <div style={{color:'#b764ac '}}>
                            {
                                (this.state.showMsg) &&
                                'An Email with a Link to Reset Your Password has been sent to given Email Address.'
                            }
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
const matchDispatchToProps=(dispatch)=> {
    return bindActionCreators({
        resetPasswordAction
    },dispatch)
};
export default connect(null,matchDispatchToProps)(withStyles(styles)(ForgotPassword));
