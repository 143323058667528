export const EmailHeaderValidator = (headerType) => {
  switch (headerType) {
    case "emailaddress":
      return true;

    case "emails":
      return true;

    case "email":
      return true;

    case "emailid":
      return true;

    case "id":
      return true;

    case "mail":
      return true;

    case "mailid":
      return true;

    case "eid":
      return true;

    case "emailids":
      return true;

    case "address":
      return true;

    case "ids":
      return true;

    case "mails":
      return true;

    case "mailids":
      return true;

    case "eids":
      return true;

    case "contacts":
      return true;

    case "emailcontacts":
      return true;

    case "emailcontact":
      return true;

    case "contactemails":
      return true;

    case "contactsid":
      return true;

    case "contactid":
      return true;

    case "contactids":
      return true;
    case "contactsmail":
      return true;

    case "contactsmails":
      return true;

    case "contactmails":
      return true;

    case "contactmail":
      return true;
    case "name":
      return true;

    case "names":
      return true;
    case "user":
      return true;

    case "users":
      return true;
    case "useremails":
      return true;

    case "useremails":
      return true;
    case "useremails":
      return true;

    case "usermails":
      return true;

    case "usermail":
      return true;
    case "usersmails":
      return true;

    default:
      return false;
  }
};
