import React from "react";

import TextField from "../TextField/TextField";
import "./HeroSection.css";
import * as constants from "Constants";
function HeroSection() {
  return (
    <div className="hero-container">
      <h1>{constants.Text.welcomeHeading}</h1>
      <p>{constants.Text.welcomeSubtitle}</p>

      <TextField />


    </div>
  );
}

export default HeroSection;
