import React from "react";
import styles from "./EventFooter.module.css";
import checkInImg from "../../../../images/Required Assets/check-in with text.png";
import haveFunImg from "../../../../images/Required Assets/have fun with text.png";
import textImg from "../../../../images/Required Assets/text.png";
import playStoreImg from "../../../../images/Required Assets/UBMe Playstore.png";
import appStoreImg from "../../../../images/Required Assets/UBMe Appstore.png";
import playStore from "../../../../images/play_store.png";
import appStore from "../../../../images/App_store.png";
const Aboutapp = ({ title, VenueName }) => {
  return (
    <div className={styles.event_footer_app}>
      <div className={styles.event_footer_app_content}>
        <div className={styles.about__Heading}>
          <h2>
            {" "}
            <span className={styles.event_footer_app_about}>
            Check In For Better Experience At
            </span>{" "}
            {title?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}{" "}
          </h2>
        </div>
        <p className={styles.event_footer_app_content_text_2}>
         When you arrive{' '}
          check in to the event using <a style={{color:'#3f71cd' , fontSize:18 , fontWeight:500}} href="https://apps.apple.com/us/app/ubme/id1200370709"> UBMe App on App Store</a>  or {' '}
           <a style={{color:'#3f71cd',  fontSize:18 , fontWeight:500}} href="https://play.google.com/store/apps/details?id=com.UBMe&hl=en_IN&gl=US">UBMe App on
          Google Play </a>  to join a live conversation with everyone at {title} at  {VenueName}, participate in fun interactive
          activities, get real time event updates, see what's coming up next and
          so much more...
        </p>
        <div className={styles.event_footer_app_content_link_text}>
          <p >Print Check In Instructions: <a style={{color:'#3f71cd',  fontSize:18 , fontWeight:500}} href="https://d2gnb7jphto2jj.cloudfront.net/img/How-To-Check-In.pdf" target="_blank">Click Here</a></p>
          <p >Video Check In Instructions: <a style={{color:'#3f71cd',  fontSize:18 , fontWeight:500}} href="https://www.youtube.com/embed/-eINhd1FGpU?si=euZ24ptsrqKeWgD7" target="_blank">Click Here</a></p>
        </div>
      </div>
      <div className={styles.event_footer_app_content_img_container}>
      <div className={styles.event_footer_app_content_top}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={textImg}
              alt=""
              className={styles.event_footer_app_content_textimg}
            />
          </div>

          <div className={styles.storeImgaes}>
            <div className={styles.appWithURl}>
                {/*<span style={{*/}
                {/*    color: "red",*/}
                {/*    fontSize: "20px",*/}
                {/*}}><strong>COMING SOON!</strong>*/}
                {/*</span>*/}
              <img
                style={{ marginTop: 20, width: '30%' }}

                src={playStoreImg}
                alt=""
                // className="playStoreImg"
              />
              <a
                style={{ display: "contents" }}
                href="https://play.google.com/store/apps/details?id=com.UBMe&hl=en_IN&gl=US"
                target="_blank"
              >
                <img


                  src={playStore}
                  alt=""
                  className={styles.playStoreImg}
                />
              </a>
            </div>
            <div className={styles.appWithURl}>
              <img
                  style={{ marginTop: 20, width: '30%' }}

                src={appStoreImg}
                alt=""
                // className="appStoreImg"
              />
              <a
                style={{ display: "contents" }}
                href="https://apps.apple.com/us/app/ubme/id1200370709"
                target="_blank"
              >
                <img


                  src={appStore}
                  alt=""
                  className={styles.playStoreImg}
                />
              </a>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={checkInImg}
            alt=""
            className={styles.event_footer_app_content_checkInImg}
          />
        </div>
        <div className={styles.event_footer_app_content_center}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={textImg}
              alt=""
              className={styles.event_footer_app_content_textimg}
            />
          </div>

          <div className={styles.storeImgaes}>
            <div className={styles.appWithURl}>
                {/*<span style={{*/}
                {/*    color: "red",*/}
                {/*    fontSize: "20px",*/}
                {/*    paddingTop: "27%"*/}
                {/*}}><strong>COMING SOON!</strong>*/}
                {/*</span>*/}
              <img
                style={{ marginTop: 20, width: '30%' }}

                src={playStoreImg}
                alt=""
                // className="playStoreImg"
              />
              <a
                style={{ display: "contents" }}
                href="https://play.google.com/store/apps/details?id=com.UBMe&hl=en_IN&gl=US"
                target="_blank"
              >
                <img


                  src={playStore}
                  alt=""
                  className={styles.playStoreImg}
                />
              </a>
            </div>
            <div className={styles.appWithURl}>
              <img
                  style={{ marginTop: 20, width: '30%' }}

                src={appStoreImg}
                alt=""
                // className="appStoreImg"
              />
              <a
                style={{ display: "contents" }}
                href="https://apps.apple.com/us/app/ubme/id1200370709"
                target="_blank"
              >
                <img


                  src={appStore}
                  alt=""
                  className={styles.playStoreImg}
                />
              </a>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={haveFunImg}
            alt=""
            className={styles.event_footer_app_content_checkInImg}
          />
        </div>
      </div>
    </div>
  );
};

export default Aboutapp;
