import React, { useRef } from "react";
import { AiFillInfoCircle } from "react-icons/ai";
import "./Polls.css";
import ToolTip from "./tools/ToolTip";
import { UserIconsPic } from "Components/UBMeEvents/Components_UBMeEvents/Navbar/Alphabets/UserIconsPic";
import moment from "moment";
import { pdf, Page, Text, View, StyleSheet, Font, Document} from "@react-pdf/renderer";
import store from "store";
import PDFImg from "images/pdf.png";

const pdfStyles = StyleSheet.create({
  page: {
    height: 200,
    backgroundColor: "#fff",
    padding: 50,
    flexDirection: "column",
  },
  pollQuestion: {
    fontSize: 11,
    marginBottom: 10,
    backgroundColor: "#9a3bbf",
    borderRadius: 10,
    paddingTop:8,
    paddingBottom:8,
    paddingLeft:10,
    width:"auto"
  },
  pollAnswer: {
    display: 'flex',
    fontSize:10,
    borderRadius: 8,
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop:8,
    paddingBottom:8,
    paddingLeft:10,
    position: 'relative',
    marginBottom: 8,
    width:"100%",
    height:"auto",
    backgroundColor:"#CAD8E2"
  },
  title: { margin: 10, fontSize: 30, textAlign: "center", color: "#ca3dd9" },
  heading: { margin: 10, fontSize: 22, textAlign: "center", color: "#ca3dd9" },

  sub_heading: {
    margin: 10,
    fontSize: 24,
    fontWeight:700,
    textAlign: "center",
    color: "#000000",
  },
  description: {
    margin: 5,
    fontSize: 20,
    fontWeight:700,
    textAlign: "center",
    color: "#000000",
  },
  box: {
    padding: "10px",
    margin: 10,
    backgroundColor: "#f0f0f0",
  },
  section: {
    marginBottom: 30,
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  poll:{
    paddingBottom:25,
  }
});


const generatePdf = (pollData) => {
  const state = store.getState();
  const { eventsAbout } = state.eventUserEvents;
  let title =  eventsAbout?.Title;
  const doc = (
    <Document>
      <Page pageNumber={1} size="A4" style={pdfStyles.page}>
          <View style={pdfStyles.box}>
            <View style={{ border: "1px splid green", padding: "10px" }}>
              <View>
                <Text style={pdfStyles.title}>{"Polls Data"}</Text>
                <Text style={pdfStyles.sub_heading}>{title}</Text>
              </View>
            </View>
          </View>
        </Page>
      <Page style={pdfStyles.page} pageNumber={2} size="A4">
        <View>
          {pollData.map((poll, index) => {
            const percentages = poll?.question ? poll?.pollAnswers?.map(answer => (answer.votes / poll?.totalVotes) * 100) : 0;
            const maxPercentage = poll?.question ? Math.max(...percentages) : 0;
            const isMultipleMax = poll?.question ? percentages.filter(percentage => percentage === maxPercentage).length > 1 : 0;
            return(
              <View key={index} style={pdfStyles.poll}>
              <Text style={pdfStyles.pollQuestion}>{poll.question}</Text>
              {poll.pollAnswers.map((answer, answerIdx) => {
                const percentage = (answer.votes / poll?.totalVotes) * 100;
                const backgroundColor = (percentage === maxPercentage && !isMultipleMax) ? '#c277ca' : '#cbd9e3';
                return(
                  <Text
                  key={answerIdx}
                  style={
                    pdfStyles.pollAnswer
                  }
                  >
                  <Text>{answer.option} : {answer.votes} votes</Text>
                </Text>
                )
              })}
            </View>
            )
          })}
        </View>
      </Page>
    </Document>
  );

  // Download the PDF
  pdf(doc).toBlob().then((blob) => {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "poll_results.pdf";
    a.click();
  });
};

function Polls({ pollData }) {
  const userImage = localStorage.getItem("ProfileImage");
  const UserName = localStorage.getItem("userFullName");
  const user = localStorage.getItem("UserName")
  const timeZone = localStorage.getItem("CurrentTimeZone");
  const profileImage = userImage == null || userImage == "null" ? UserIconsPic(user?.[0].toUpperCase()) : `${userImage}`;
  const targetRef = useRef(null);

  return (
    <div className="pols">

      <div className="activityHeader">
        {ToolTip("Polls", "Poll results and details", false)}
        {/* <div
          style={{
            width: 80,
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            marginBottom:20
          }}
          onClick={() => {generatePdf(pollData)}
        }
        >
        <p>Download Pdf</p>
        <img
          src={PDFImg}
          style={{
            width: 40,
            marginTop:-13,
            filter: "drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.2))",
          }}
        />
        </div> */}
      </div>

      <div className="messageSection" ref={targetRef}>
        {
          pollData?.length === 0 || pollData === null ? (<div>
            No data available
          </div>) : (
            pollData.sort((a, b) => new Date(a.pollDateTime) - new Date(b.pollDateTime)).map((item) => {
              const time = moment(item?.pollDateTime).utc(item?.pollDateTime).tz(JSON.parse(timeZone))
              const percentages = item?.question ? item?.pollAnswers?.map(answer => (answer.votes / item?.totalVotes) * 100) : 0;
              const maxPercentage = item?.question ? Math.max(...percentages) : 0;
              const isMultipleMax = item?.question ? percentages.filter(percentage => percentage === maxPercentage).length > 1 : 0;
              return (
                <div className="messageBar pollData">
                  <div className="messageTop">
                    <img
                      src={
                        profileImage
                      }
                      alt=""
                      style={{ marginTop: "27px" }}
                    />
                    <div className="messageBarRight">
                      <h6>{UserName}</h6>
                      <div className="message">
                        <p class="from-them msg-large">
                          {item?.question}
                        </p>
                      </div>

                      {item?.pollAnswers?.map(
                        (answer, index) => {
                          const percentage = (answer.votes / item?.totalVotes) * 100;
                          const backgroundColor = (percentage === maxPercentage && !isMultipleMax) ? '#c277ca' : '#cbd9e3';
                          return (
                            <div key={answer.optionId}
                              className="progress"
                              style={{
                                display: 'flex',
                                borderRadius: '8px',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                paddingBlock: '5px',
                                paddingInline: '10px',
                                position: 'relative',
                                marginBottom: '4px',
                                background: `linear-gradient(to right, ${backgroundColor} 0%, ${backgroundColor} ${percentage}%, transparent ${percentage}%, transparent 100%)`,
                                width:"100%",
                                height:"auto"
                              }}>
                              <div style={{ display: 'flex', gap: '7px', alignItems: 'center' }}>
                                <div style={{
                                  position: 'relative'
                                }}>
                                  {answer?.option ? answer?.option : answer?.name}
                                </div>
                              </div>

                              <div style={{fontWeight: 'bold', marginLeft: '20px' }}>
                                {answer?.votes ? answer?.votes == 0 ? "0" : Math.round(((answer?.votes / item?.totalVotes) * 100) * 10) / 10 : "0"}%
                              </div>
                            </div>
                          )
                        })}
                      <h6 style={{ color: "gray" }}>{item?.totalVotes} {item?.totalVotes > 1 ? "votes":"vote"} - {moment(time).format("MMM Do h:mm a")}</h6>
                    </div>
                  </div>
                </div>
              )
            })
          )
        }
      </div>
    </div>
  );
}

export default Polls;
