import ForgotPassword from "Components/UBMeEvents/auth/Forgotpasswoed/ForgotPassword";
import SignUp from "Components/UBMeEvents/auth/Signup/SignUp";
import Login from "Components/UBMeEvents/auth/Login/Login";
import React, { useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import OrderSummary from "../ticketOrderSummary/OrderSummary";
import "./loginbeforecheckout.css";
import { useState } from "react";
import { connect } from "react-redux";
import { IoClose } from "react-icons/io5";
import moment from "moment";
const LoginBeforeCheckOut = ({currentEventDetails, setOpenCheckOut, hasCheckoutQuery}) => {
  const [isSignUp, setIsSignUp] = useState(true);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [userHistory, setHistory] = useState('loggedin');
  const [showOrderSummary, setShowOrderSummary] = useState(false)
  const closeModal = () => {};
  const rebderSignupLogin = () =>
    isSignUp ? (
      <SignUp userHistory={userHistory} closeModal={closeModal} setIsSignUp={setIsSignUp} />
    ) : forgotPassword ? (
      <ForgotPassword
        closeModal={closeModal}
        setForgotPassword={setForgotPassword}
      />
    ) : (
      <Login
        userHistory={userHistory}
        setForgotPassword={setForgotPassword}
        closeModal={closeModal}
        setIsSignUp={setIsSignUp}
      />
    );
    function useWindowSize() {
      const [size, setSize] = useState([0, 0]);
      useLayoutEffect(() => {
        function updateSize() {
          setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener("resize", updateSize);
        updateSize();
        return () => window.removeEventListener("resize", updateSize);
      }, []);
      return size;
    }
    const [width, height] = useWindowSize();
  return (
    <div className="login-container">
      <div class="container_1">
        <div class="row1" style={{flex:1}}>
          <div class="col_9">
            <div id="header">
              <h3 id="header_h3">{currentEventDetails?.Title}</h3>
              <br/>
              <h4 id="header_h4"> {moment(currentEventDetails?.StartDateTime).format("llll")} -{" "}
                {moment(currentEventDetails?.EndDateTime).format("llll")}</h4>
                {width < 800  && !hasCheckoutQuery ? (
                <div
                  onClick={() => setOpenCheckOut(false)}
                  className="order_close"
                >
                  <IoClose size={20} />
                </div>
              ) : null}
            </div>
            <div className="underline" />
            <div className="login_box">{rebderSignupLogin()}</div>
          </div>
        </div>
        {
           width < 800 ? (

        <div className="language-button">
              {/* <div className="underline" /> */}
              <div id="footer" style={{padding:0}}>

                  <a
                    className="open_summury_btn"
                    onClick={() => setShowOrderSummary(!showOrderSummary)}
                    href="#"
                  >
                    {showOrderSummary ? "Hide Summary " : "View Summary"}
                  </a>


              </div>
            </div>
            ) : null }
      </div>

      <OrderSummary
          setShowOrderSummary={setShowOrderSummary}
          showOrderSummary={showOrderSummary}
          currentEventDetails={currentEventDetails}
          setOpenCheckOut={setOpenCheckOut}
          hasCheckoutQuery={hasCheckoutQuery}
      />
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    results: state.loginResponse.results,
    isLoggedIn: state.loginResponse.isLoggedIn,
    currentEventDetails: state.eventReducer.currentEventDetails,
  };
};
export default connect(mapStateToProps)(LoginBeforeCheckOut);

