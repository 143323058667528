import React from 'react';
import Card from '@material-ui/core/Card';
import PropTypes from 'prop-types';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import { withStyles } from '@material-ui/core/styles';
import './cardWrapper.css';

const styles= {
    Paper: {
        borderRadius:0,
        position:'relative',
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        marginLeft: '-5%',
    },
};
const CardWrapper=(props) => {
    const {classes} = props;
    return(
        <Card className={props.paperClasses||classes.Paper} {...props}>
            {
                props.header &&
                <CardHeader {...props.CardHeaderProps} title={props.header} />
            }
            {
                props.image &&
                <CardMedia className={classes.media} image={props.image} />
            }
            <CardContent {...props.CardContentProps}>
                {props.content}
                {props.actions && <CardActions className='action' {...props.CardActionsProps}>
                    {props.actions}
                </CardActions>}
            </CardContent>
        </Card>
    )
};

CardWrapper.propTypes={
    content:PropTypes.node,
    actions:PropTypes.node,
    header:PropTypes.node,
    paperClasses:PropTypes.string,
    image:PropTypes.object
};
export default  withStyles(styles)(CardWrapper);