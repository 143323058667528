import React, { useEffect, useState } from "react";
import styles from "./EventHeader.module.css";
import share from "../../../../images/share2.png";
import Share from "Components/UBMeEvents/Components_UBMeEvents/share/Share";
import Modal from "Components/UBMeEvents/Components_UBMeEvents/modal/Modal";
import { axiosE } from "Service/service";
import * as constants from "Constants";
import { LazyLoadImage } from "react-lazy-load-image-component";
const EventHeader = ({
  title,
  eventImage,
  EventId,
  isLoggedIn,
  eventData,
  LoginPopup,
  favoriteData,
  AddFavorite,
  notPublished,
}) => {
  const [visible, setVisible] = useState(false);
  const [like, setLike] = useState(false);

  const shareButoon = (event) => {
    event.stopPropagation();
    setVisible(true);
  };
  const closeModal = () => setVisible(false);

  const handleClick = async (data) => {
    if (favoriteData.find((x) => x.EventId === EventId)) {
      let newdata = [...favoriteData];
      const filterData = newdata.filter((i) => i.EventId !== EventId);

      AddFavorite(filterData);
    } else {
      AddFavorite([...favoriteData, data]);
    }
    try {
      const res = await axiosE.post("/Event/UpdateLike", {
        EventId: EventId,
        UserId: localStorage.getItem("UserId"),
        Platform: "Web",
      });
      if (res.data.results.ResponseCode) {
        // setLike(true);
        // console.log(res.data);
        // console.log({ favoriteData });
      }
    } catch (error) {
      console.log(error);
    }
    if (localStorage.getItem("pendingUpdateEventApiCall")) {
      localStorage.removeItem("pendingUpdateEventApiCall");
    }
  };

  useEffect(() => {
    localStorage.setItem(
      "CurrentTimeZone",
      JSON.stringify(eventData?.TimeZone)
    );
    const pendingUpdateEventApiCall = localStorage.getItem(
      "pendingUpdateEventApiCall"
    )
      ? JSON.parse(localStorage.getItem("pendingUpdateEventApiCall"))
      : null;
    if (isLoggedIn && pendingUpdateEventApiCall) {

      if (eventData?.Liked !== null && eventData?.Liked === "false") {
        handleClick(eventData);
      } else {
        AddFavorite([...favoriteData, pendingUpdateEventApiCall]);
        localStorage.removeItem("pendingUpdateEventApiCall");
      }
    }
  }, [eventData]);

  const handleLoginCall = (type, eventId) => {

    if (!isLoggedIn) {
      localStorage.setItem("pendingUpdateEventApiCall", eventId);
      localStorage.setItem("pendingEventsButtonClick", type);
      LoginPopup(true);
    }
  };

  return (
    <div
      className={styles.event_header}
      // style={{
      //   backgroundImage: `url(${eventImage})`,
      //   backgroundPosition: "center",
      //   backgroundSize: "cover",
      //   backgroundRepeat: "no-repeat",
      // }}
    >
      <div className={styles.image_container}>
        <LazyLoadImage
          src={eventImage}
          width={"100%"}
          height={"100%"}
          PlaceholderSrc={"PlaceholderImage"}
          effect="blur"
        />
        {/* <img
          style={{ width: "100%" , height: "100%" }}
          src={eventImage}
          alt=""
        /> */}
      </div>

      <h1>{title}</h1>
      {visible && (
        <Modal closeModal={closeModal}>
          <Share url={`${constants.URLs.baseUrl}/event/` + EventId} />
        </Modal>
      )}
      <div className={styles.event_header_action}>
        <div className={styles.event_header_action_content}>
          {favoriteData && favoriteData.find((x) => x.EventId === EventId) ? (
            <span
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                e.stopPropagation();
                isLoggedIn ? handleClick(eventData) : LoginPopup(true);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="svg_select"
                width="32"
                height="32"
                viewBox="0 0 24 24"
              >
                <path d="M12 4.435c-1.989-5.399-12-4.597-12 3.568 0 4.068 3.06 9.481 12 14.997 8.94-5.516 12-10.929 12-14.997 0-8.118-10-8.999-12-3.568z" />
              </svg>
            </span>
          ) : (
            <span
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                e.stopPropagation();
                isLoggedIn
                  ? handleClick(eventData)
                  : handleLoginCall("Like", JSON.stringify(eventData));
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="svg"
                width="32"
                height="32"
                viewBox="0 0 24 24"
              >
                <path d="M12 4.435c-1.989-5.399-12-4.597-12 3.568 0 4.068 3.06 9.481 12 14.997 8.94-5.516 12-10.929 12-14.997 0-8.118-10-8.999-12-3.568z" />
              </svg>
            </span>
          )}
          <a href="#!" onClick={shareButoon} className={styles.share_event}>
            <img src={share} alt="share" className={styles.share_image} />
            <p style={{ marginRight: 0, marginBottom: 0 }}>
              {constants.Text.share}
            </p>
          </a>
        </div>
      </div>
    </div>
  );
};

export default EventHeader;
