import { FAVORITE, CARD_DATA } from "./actionTypes";

// export const AddToFavorite=(data)=>{
//     return (dispatch) => {
//         return axiosI.get(`/GetBusinessActivity/${placeId}`).then(({data})=>{
//             dispatch({
//                 type: GET_ACTIVITY,
//                 payload: data
//             });
//         }).catch((err)=>{
//             console.log(err);
//         })
//     })
// };

export const AddToFavorite = (data) => {
  // console.log("yyyyyyyyyyy", data);
  return (dispatch) =>{
    dispatch({
      type: FAVORITE,
      payload: data,
    });
  }
};
export const SetCardData = (data) => {
  // console.log("xxxxxxxxxxxxx", data);
  return (dispatch) =>
    dispatch({
      type: CARD_DATA,
      payload: data,
    });
};

