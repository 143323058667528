import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Grid, withStyles } from "@material-ui/core";
import { getGraphDataAction, getUserAction } from "../../../Actions/index";
import RecentActivity from "../RecentActivity/recentActivity";
import ManageEventsLineGraph from "../ManageEventsLineGraph/manageEventsLineGraph";
import Card from "../../../Helper/CardWrapper/cardWrapper";
import ChartHeader from "./chartHeader";
import EventsOverviewCard from "./EventsOverviewCard/eventsOverviewCard";
import ContentGrid from "../../../Helper/gridWrapper/gridWrapper";
import "./EventsOverview.css";
import { axiosE } from "Service/service";
import * as constants from "Constants";
import moment from "moment";
import { default as momentTz } from "moment-timezone";
import { clearEventExperienceChat , clearEventExperienceActivity , clearEventUserCheckedInList } from "../../../Actions/messagesAction";

const styles = {
  eventsOverviewchart: {
    padding: "0px 10px 10px 0px",
    "@media screen and (max-width: 900px)": {
      padding: "0px 0px 10px 0px",
    },
  },
  eventsOverviewLineGraph: {
    height: "345px",
  },
  eventsOverviewRecentActivity: {
    height: "99%",
  },
};

class ManageEvents extends Component {
  constructor() {
    super();
    this.timer = null;
    this.state = {
      startDate: moment().format("YYYY/MM/DD"),
      endDate: "",
      isAllTime: false,
      refresher: 0,
      attendees: 0,
      gross: 0,
      engagement: 0,
      callOverview: false,
      chartData: [],
      activeGraphData: {
        name: "Attendees",
        colorScheme: [239, 135, 51],
      },
    };
  }

  componentDidMount() {
    localStorage.removeItem("currentDialogId");
    localStorage.setItem("ChatsIndex", JSON.stringify(0));
    localStorage.setItem("TotalCount", JSON.stringify(0));


    this.props.clearEventExperienceChat();
    this.props.clearEventExperienceActivity();
    this.props.clearEventUserCheckedInList();

    this.timer = setInterval(() => {
      this.setState((prevState) => ({
        refresher: prevState.refresher + 1,
      }));
    }, 5 * 60 * 1000);

    let time = moment().format("LT");
    let startDate = `${moment()
      .subtract(6, "days")
      .format("YYYY/MM/DD")} 12:00:00 AM`;
    let endDate = moment().format("YYYY/MM/DD");
    endDate = `${endDate} ${time}`;

    this.setState({
      startDate,
      endDate,
    });

    if (this.timer === null) {
      this.GetSummeryData(startDate, endDate);
      this.GetGraphData(constants.API.TicketsSoldGraphData, startDate, endDate);
    }
  }

  timeLocaltoUtc = (dateAndTime) => {
    const formattedDate = moment(dateAndTime).format("YYYY/MM/DD hh:mm:ss a");

    var dateTimeInUtc = momentTz
      .tz(formattedDate, "YYYY/MM/DD hh:mm:ss a", moment.tz.guess())
      .utc()
      .format("YYYY/MM/DD hh:mm:ss a");

    return dateTimeInUtc;
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.refresher !== prevState.refresher) {
      let time = moment().format("LT");
      let startDate = this.state.startDate;
      let endDate = this.state.endDate;

      // this.setState({
      //   startDate,
      //   endDate,
      // });

      this.GetSummeryData(startDate, endDate);
      this.GetGraphData(constants.API.TicketsSoldGraphData, startDate, endDate);
    }
  }

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }
  GetSummeryData = (startDate, endDate) => {
    const { user } = this.props;
    try {
      axiosE
        .post(constants.API.SummeryData, {
          OrganizerId: user.UserId,
          StartDate: this.timeLocaltoUtc(startDate),
          EndDate: this.timeLocaltoUtc(endDate),
        })
        .then(({ data }) => {
          this.setState({
            gross: Number(JSON.parse(data.results.Data).Sales).toFixed(2),
            attendees: Number(
              JSON.parse(data.results.Data).TicketsSold
            ).toFixed(0),
            engagement: Number(
              JSON.parse(data.results.Data).PercentageCheckedIn
            ),
            // engagement:20
          });
        })
        .catch(() => {});
    } catch (error) {}
  };
  timeUTCtoLocal = (date, timezone) => {
    var local = momentTz
      .tz(moment.utc(date), "MM/DD/YYYY hh:mm:ss a", timezone)
      .format("MM/DD/YYYY hh:mm:ss a");
    return moment(local, "MM/DD/YYYY hh:mm:ss a");
  };

  sumGraphsDataByDate(items) {

    if (items) {
      const otherKey = Object.keys(items[0]).filter((key) => key !== "date")[0];

      const graphDataByDate = {};
      // Group data by date
      items.forEach((item) => {
        // if date is not present then set 0 to the date's sales, attendee or engagement values
        const unknownKey = Object.keys(item).find((key) => key !== "date");
        if (!graphDataByDate[item.date]) {
          graphDataByDate[item.date] = 0;
        }
        // if date is already present then update the date with existing sales, attendee or engagement values
        graphDataByDate[item.date] += item[unknownKey];
      });
      // Convert object to array
      const result = Object.keys(graphDataByDate).map((date) => ({
        date,
        [otherKey]: graphDataByDate[date],
      }));
      return result;
    } else {
      return;
    }
  }

  GetGraphData = (endPoint, startDate, endDate) => {
    this.props.getGraphDataAction(endPoint, this.timeLocaltoUtc(startDate), this.timeLocaltoUtc(endDate)).then((data) => {
      // if (endPoint === constants.API.SalesGraphDate) {
      //   this.setState({
      //     chartData:this.sumGraphsDataByDate(data?.map((item) => ({
      //       date: this.timeUTCtoLocal(item.OrderDate, item.Timezone).format(
      //         "MM/DD/YYYY"
      //       ),
      //       Sales: Number(item.Sales),
      //     }))),
      //   });
      // } else
      if (endPoint === constants.API.TicketsSoldGraphData) {
        this.setState({
          chartData: this.sumGraphsDataByDate(data?.map((item) => ({
                date: this.timeUTCtoLocal(item.OrderDate, item.Timezone).format(
                    "MM/DD/YYYY"
                ),
              }))
          ),
        });
      } else if (endPoint === constants.API.EngagementGraphData) {
          this.setState({
            chartData: this.sumGraphsDataByDate(
              data?.map((item) => ({
                date: this.timeUTCtoLocal(item.EventDate, item.Timezone).format(
                  "MM/DD/YYYY"
                ),
                Engagement: Number(item.Percent_Difference),
              }))
            ),
          });
        }
      });
  };

  handleChangeTime = (val) => {
    if (val.startDate) {
      this.GetSummeryData(val.startDate, val.endDate);
      this.GetGraphDataByTime(val.startDate, val.endDate);
    }
    this.setState(val);
  };

  calculate = (attendees, gross, engagement) => {
    this.setState({ attendees, gross, engagement });
  };

  showData = (element) => {
    const { startDate, endDate } = this.state;
    this.GetGraphDataByTime(startDate, endDate, element);
    this.setState({
      activeGraphData: {
        name: element.name,
        colorScheme:
          // element.name === "Sales"
          //   ? [3, 127, 3]
          //   :
            element.name === "Attendees"
            ? [239, 135, 51]
            : [200, 50, 200],
      },
    });
  };

  GetGraphDataByTime = (
    startDate,
    endDate,
    element = this.state.activeGraphData
  ) => {
    // Choose API endpoint according to Tabs
    let endPoint =
      // element.name === "Sales"
      //   ? constants.API.SalesGraphDate
      //   :
        element.name === "Attendees"
        ? constants.API.TicketsSoldGraphData
        : constants.API.EngagementGraphData;

    // Calling API to fetch Graph Data according to Tabs
    this.GetGraphData(endPoint, startDate, endDate);
  };

  render() {
    const { classes, history, user } = this.props;
    const { activeGraphData, gross, chartData } = this.state;

    const data = [
      // {
      //   name: "Sales",
      //   styleName: "grossProfit",
      //   data: `$${
      //     this.state.gross >= 1000
      //       ? (this.state.gross / 1000).toFixed(2)
      //       : this.state.gross
      //   }${this.state.gross >= 1000 ? "K" : ""}`,
      // },
      {
        name: "Attendees",
        styleName: "attendees",
        data: this.state.attendees,
      },
      {
        name: "Engagement",
        styleName: "engagement",
        data: parseFloat(this.state.engagement) + "%",
      },
    ];

    return (
      <div className="container-div">
        <div>
          <ContentGrid
            content={
              <Grid container spacing={24}>
                <Grid
                  item
                  md={8}
                  xs={12}
                  className={classes.eventsOverviewchart}
                >
                  <Card
                    className={classes.chart}
                    header={
                      <ChartHeader
                        data={data}
                        refresher={this.state.refresher}
                        state={this.state}
                        handleChangeTime={this.handleChangeTime}
                        className="overview-graph-space"
                        GetGraphDataByTime={this.GetGraphDataByTime}
                        showData={this.showData}
                        activeGraphData={activeGraphData}
                      />
                    }
                    content={
                      <ManageEventsLineGraph
                        chartData={chartData}
                        activeGraphData={this.state.activeGraphData}
                        dataCount={this.calculate}
                      />
                    }
                    CardContentProps={{ style: styles.eventsOverviewLineGraph }}
                    style={{ height: "100%" }}
                  />
                </Grid>
                <Grid
                  item
                  md={4}
                  // md={12}
                  xs={12}
                  style={{ padding: "0px 0px 10px 0px" }}
                >
                  <RecentActivity
                    refresher={this.state.refresher}
                    rowsPerPage={5}
                    user={user.UserId}
                    manageEvents={true}
                    className={classes.eventsOverviewRecentActivity}
                  />
                </Grid>
                <EventsOverviewCard
                  refresher={this.state.refresher}
                  history={history}
                />
              </Grid>
            }
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.loginResponse.results.ProfileDetail,
    activity: state.activity,
    visitors: state.visitors,
    messageData: state.messageReducer,
  };
};

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { getUserAction, getGraphDataAction, clearEventExperienceChat ,clearEventUserCheckedInList , clearEventExperienceActivity},
    dispatch
  );
};

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(withStyles(styles)(ManageEvents));
