import React from "react";
import { Redirect, Route } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { routeName } from "../../Service/env";
import Loader from "../../Helper/loader/loader";

const PrivateRoute = ({ ...props }) => {
  const token = localStorage.getItem("token");
  let access = false;
  let decoded;
  try {
    if (token === undefined) {
      access = false;
    } else {
      decoded = jwt_decode(token);
    }
  } catch (err) {
    console.log(err);
  }
  let currentTime = Math.floor(Date.now() / 1000);
  if (!decoded || !decoded.exp) {
    localStorage.removeItem("token");
    access = false;
  } else {
    if (decoded.exp < currentTime) {
      access = false;
      localStorage.removeItem("user");
    } else {
      access = true;
    }
  }
  return access ? (
    true ? (
      <Route {...props} />
    ) : (
      <div className="no-data">
        <Loader />
      </div>
    )
  ) : (
    <div>
      {localStorage.clear()}
      <Redirect to={`${routeName}`} />
    </div>
  );
};
export default PrivateRoute;
