import React, { useState } from "react";
import {
  FacebookShareButton,
  WhatsappIcon,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  EmailIcon,
  EmailShareButton,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import styles from "./Footer.module.css";
import * as constants from "Constants";
import { MdOutlineContentCopy } from "react-icons/md";
import { FaFacebookF, FaLinkedinIn, FaTwitter } from "react-icons/fa";
import { RiMessengerFill } from "react-icons/ri";
import { HiMail } from "react-icons/hi";
import { IoLogoWhatsapp } from "react-icons/io";
const Footer = ({ url }) => {
  return (
    <div style={{ marginBottom: 50 }}>
      <div className={styles.share_header}>
        <h4 className={styles.share_header_title}>{constants.Message.share}</h4>
      </div>

      <div className={styles.share_box}>
        <div
          className="share_event_icons"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            //   marginTop: 50
          }}
        >
          <FacebookShareButton
            bgstyle="red"
            url={url}
            // quote={"CampersTribe - World is yours to explore"}
            hashtag="#UBMeEvents"
            className={styles.social_share_button}
          >
            {/* <FacebookIcon  size={40} round={true} /> */}
            <div className={styles.social_share_icon_bg}>
              <FaFacebookF color="#fff" size={20}/>
            </div>
          </FacebookShareButton>
         
         
          <TwitterShareButton
            url={url}
            // title={"CampersTribe - World is yours to explore"}
            hashtag="#UBMeEvents"
            className={styles.social_share_button}
          >
            <div className={styles.social_share_icon_bg}>
              <FaTwitter color="#fff" size={20}/>
            </div>
          </TwitterShareButton>
          <WhatsappShareButton     
            url={url}
            // title={"CampersTribe - World is yours to explore"}
            hashtag="#UBMeEvents"
            className={styles.social_share_button}>
 
            <div className={styles.social_share_icon_bg}>
            <IoLogoWhatsapp color="#fff" size={22}/>
            </div>
          </WhatsappShareButton>
          
          <EmailShareButton
           url={url}
           // title={"CampersTribe - World is yours to explore"}
           hashtag="#UBMeEvents"
           className={styles.social_share_button}
          >
            <div className={styles.social_share_icon_bg}>
              <HiMail color="#fff" size={20}/>
            </div>
          </EmailShareButton>
        </div>
        {/* <div className="share_copy_to_clipboard">
        <div className="share_event_url">
      <h6 style={{marginLeft:5, fontSize:15,color: "#808080"}}>{constants.Message.events}</h6>
      <input value={url} className="clipboard_input" type="text"  />
        </div>
        <span className="clip_board_icon" onClick={()=>{navigator.clipboard.writeText(url);  setCopy("codied")}}>
        <MdOutlineContentCopy size={20} />
        <div className="share_clip_tooltip">
          <p style={{marginBottom:0, fontSize:15,color: "#808080",fontWeight:"bold"}}>{copy}</p>
        </div>
        </span>
     </div> */}
      </div>
    </div>
  );
};

export default Footer;
