import React from "react";
import "./Modal.css";
const Modal = ({ children, closeModal ,signup = false}) => {
  return (
    <div>
      <div id="demo-modal" onClick={() => closeModal()} style={{marginTop:signup ? 0 : 0}} class="modal_Container">
        <div
        onClick={(e) => e.stopPropagation()} class="modal__content">
          {children}
          <a href="#!" onClick={() => closeModal()} class="modal__close">
            &times;
          </a>
        </div>
      </div>
    </div>
  );
};

export default Modal;
