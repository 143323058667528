import React from "react";
import storeFolder from '../../../../../store';
import { UserIconsPic } from "Components/UBMeEvents/Components_UBMeEvents/Navbar/Alphabets/UserIconsPic";
import { TablePagination } from "@material-ui/core";
import { LazyLoadImage } from "react-lazy-load-image-component";

function ActiveAttendee({ checkedInUsersList }) {
  let parsedData = [];
  let storeData = storeFolder.getState();
  const eventDetails = localStorage.getItem("UserId")
  // Check if checkedInUsersList is a non-empty string before parsing
  if (checkedInUsersList && typeof checkedInUsersList === 'string' && checkedInUsersList.trim() !== '') {
    try {
      parsedData = JSON.parse(checkedInUsersList);
    } catch (error) {
      // Handle the JSON parsing error
      console.error("Error parsing JSON:", error);
    }
  }
  return (
    <div className="activeAttendees">
      {checkedInUsersList.length ? 
        (
          <>
            {
             checkedInUsersList?.filter((item) => item.User_ID == eventDetails).map((item, idx) => {
                return (
                  <div key={idx} className="activeAttendee">
                    <LazyLoadImage
                      src={
                        item?.Photo === null || item?.Photo === ""
                          ? UserIconsPic(item?.First_Name?.[0]?.toUpperCase())
                          : `https://d2gnb7jphto2jj.cloudfront.net/UploadImages/AppUserProfileImages/ProfileOriginalImage/${item?.Photo}`
                      }
                      alt=""
                      // effect="blur"
                    />
                    <div className="userDetail">
                      <h2 style={{ marginBottom: "10px", color: eventDetails == item?.User_ID ? "#a740b3" : "black" }}>{item?.First_Name}</h2>
                      <p style={{ color: "black" }}>@{item?.UserName}</p>
                    </div>
                  </div>
                )
              })
            }
            {checkedInUsersList?.sort((a, b) => {
              const timeA = new Date(a.CheckInDate)
              const timeB = new Date(b.CheckInDate)
  
              return timeB - timeA;
            }).filter((item) => item.User_ID != eventDetails).map((item, idx) => (
              <div key={idx} className="activeAttendee">
                <LazyLoadImage
                  src={
                    item.Photo === null || item.Photo === ""
                      ? UserIconsPic(item.First_Name[0]?.toUpperCase())
                      : `https://d2gnb7jphto2jj.cloudfront.net/UploadImages/AppUserProfileImages/ProfileOriginalImage/${item.Photo}`
                  }
                  alt=""
                  // effect="blur"
                  // onClick={()=>{onContactClick()
                  //   setUserData(item);
                  // }}
                />
                <div className="userDetail">
                  <h2 style={{ marginBottom: "10px", color: eventDetails == item?.User_ID ? "#a740b3" : "black" }}>{item.First_Name}</h2>
                  <p style={{ color: "black" }}>@{item.UserName}</p>
                </div>
              </div>
            ))}
          </>
        )
      : (
        // <>{refresh ? (
        //   <>
        //     {
        //     storeData.eventReducer?.checkedInUsersList.filter((item) => item.User_ID == eventDetails).map((item, idx) => {
        //       return (
        //         <div key={idx} className="activeAttendee">
        //           <img
        //             src={
        //               item.Photo === null || item.Photo === ""
        //                 ? UserIconsPic(item.First_Name[0]?.toUpperCase())
        //                 : `https://d2gnb7jphto2jj.cloudfront.net/UploadImages/AppUserProfileImages/ProfileOriginalImage/${item.Photo}`
        //             }
        //             alt=""
        //           />
        //           <div className="userDetail">
        //             <h2 style={{ marginBottom: "10px", color: eventDetails == item?.User_ID ? "#a740b3" : "black" }}>{item.First_Name}</h2>
        //             <p style={{ color: "black" }}>@{item.UserName}</p>
        //           </div>
        //         </div>
        //       )
        //     })
        //   }
        //   {storeData.eventReducer?.checkedInUsersList.sort((a, b) => {
        //     const timeA = new Date(a.CheckInDate)
        //     const timeB = new Date(b.CheckInDate)

        //     return timeB - timeA;
        //   }).filter((item) => item.User_ID != eventDetails).map((item, idx) => (
        //     <div key={idx} className="activeAttendee">
        //       <img
        //         src={
        //           item.Photo === null || item.Photo === ""
        //             ? UserIconsPic(item.First_Name[0]?.toUpperCase())
        //             : `https://d2gnb7jphto2jj.cloudfront.net/UploadImages/AppUserProfileImages/ProfileOriginalImage/${item.Photo}`
        //         }
        //         alt=""
        //         // onClick={()=>{onContactClick()
        //         //   setUserData(item);
        //         // }}
        //       />
        //       <div className="userDetail">
        //         <h2 style={{ marginBottom: "10px", color: eventDetails == item?.User_ID ? "#a740b3" : "black" }}>{item.First_Name}</h2>
        //         <p style={{ color: "black" }}>@{item.UserName}</p>
        //       </div>
        //     </div>
        //   ))}
        //   </>
        // ):"No user online"}
        // </>
        <div>
          No user online
        </div>
      )}

    </div>

  );
}

export default ActiveAttendee;
