import React, { useEffect, useState } from "react";
import EventActivity from "./Component/EventActivity";
import EventChat from "./Component/EventChat";
import "./EventExperience.css";
import store from "store";
import storeFolder from "../../../../store";
import { ws } from "../../../../App";
import { axiosE } from "Service/service";
import { eventApis } from "../../../../Constants";
import Logo from '../../../../Helper/Logo/logo';
import { history } from '../../../../store';
import { routeName } from '../../../../../src/Service/env';
import WebView from "Components/BusinessPortal/Messages/WebView";
import Card from "../../../../Helper/CardWrapper/cardWrapper";
import Modal from "@material-ui/core/Modal";
// import WebView from "../../../BusinessPortal/Messages/WebView";

const EventExperience = ({ dispatch }) => {

  const [apiRes, setApiRes] = useState("");
  const [loading, setLoading] = useState(false);
  const onLogoClick = () => {
    localStorage.clear();
    history.push(`${routeName}`);
  };
  const [userMessagesModalIsOpen, setUserMessagesModalIsOpen] = useState(false);
  const [userMessages, setUserMessages] = useState({});
  let storeData = storeFolder.getState();
  const eventDetails = storeData?.eventReducer
  const [userData , setUserData] = useState("");
  useEffect(() => {
    const currentEventId = JSON.parse(
      localStorage.getItem("CurrentEventId")
    );
    const userId = localStorage.getItem("UserId");
    setTimeout(() => {
      if(storeData.messageReducer?.connection){
        const state = store.getState();
        const { eventId } = state.eventUserEvents;
        let storeData = storeFolder.getState();
        const date = new Date();
        const eventID = state.eventUserEvents.eventId;
        const dialogId = localStorage.getItem("currentDialogId");
        if (!dialogId) {
          ws?.json({
            chat_type: "Event",
            EventId: currentEventId ? currentEventId : eventId,
            msgid: date.getTime().toString().slice(4),
            type: "getdialog",
          });
        }

        if (dialogId) {
          ws?.json({
            index: "0",
            count: "20",
            user_id: storeData.loginResponse.results.ProfileDetail.UserId,
            type: "businessrecentchat",
            chat_type: "Event",
            deleteCount: "0",
            googlePlacedId: dialogId,
          });
        }
      }
    }, 2200);

    setLoading(true);
    axiosE
      .get(
        `${eventApis.GetEventMasterById}${currentEventId}/${userId}/Admin`
      )
      .then(({ data }) => {
        setApiRes(`${data.results.IsEventExperiance}`)
        setLoading(false);
      });
  }, []);

  const userMessagesModal = (data) => {
    // const userId = results?.ProfileDetail?.UserId;
    const userId = localStorage.getItem("UserId");
    let storeData = storeFolder.getState();
    const messageData = storeData?.messageReducer
    let selectedChatIndex = -1;
    if (userMessagesModalIsOpen && messageData) {
      messageData.recentChat?.forEach((message, index) => {
        if (data.UserId + "" === message.userid) {
          selectedChatIndex = index;
        }
      });
    }
    let showInfo = false;
    return (
      // <div style={{backgroundColor:'red', width:'250px', height:'250px'}}>
      //   <h2>MY Title</h2>
      // </div>
      <Modal
        open={userMessagesModalIsOpen}
        onClose={() => closeUserMessagesModal(data)}
      >
        <Card
          CardContentProps={{
            style: userMessagesModalStyles(),
          }}
          content={
            <WebView
              userId={userId}
              updateState={updateState}
              showInfo={showInfo}
              curbsideCommunication={true}
              curbsideCommunicationUserData={{
                FullName: userData?.First_Name,
                ProfileImage: userData?.Photo,
                // UserName: data.UserName,
                UserId: userData.User_ID,
              }}
              curbsideCommunicationSelectedChatIndex={selectedChatIndex}
            />
          }
        />
      </Modal>
    );
  };

  const showUserMessagesModal = (userMessages) => {
    setUserMessagesModalIsOpen(true);
    // setUserMessages(userMessages)
  };

  const closeUserMessagesModal = (data) => {
    // if (Object.keys(messageData.selectedChat.last_message_json).length == 0) {
    //   removeEmptyDialog(data);
    // }
    localStorage.removeItem("curbsideCommunicationUserId");
    localStorage.removeItem("curbsideCommunicationUserName");
    localStorage.removeItem("curbsideCommunicationFullName");
    localStorage.removeItem("curbsideCommunicationProfileImage");

    setUserMessagesModalIsOpen(false);
    setUserMessages({});
  };

  const userMessagesModalStyles = () => {
    return {
      position: "fixed",
      top: window.screen.Height < 1100 ? "10%" : "5%",
      width:
        window.screen.width < 1100
          ? window.screen.width < 850
            ? "84%"
            : "55%"
          : "",
      left:
        window.screen.width < 1100
          ? window.screen.width < 850
            ? "8%"
            : "22%"
          : "30%",
      right:
        window.screen.width < 1100
          ? window.screen.width < 850
            ? "8%"
            : "22%"
          : "30%",
      borderRadius: "8px",
      backgroundColor: "white",
      maxHeight: "80vh", // Set a maximum height for the card
      overflowY: "auto", // Enable vertical scrolling
    };
  };

  const updateState = (state, value) => {
    this.setState({
      [state]: value,
    });
  };

  return (
    <div>
      {
        loading ? (
          <>
            <div
              className="Loadercen"
            >
              <div class="spinnerr"></div>
            </div>
          </>
        ) : (
          <>
            <div>
              {
                apiRes == "False" || apiRes == "" ? (
                  <>
                    <div className='error-container no-data'>
                      <span onClick={onLogoClick}><Logo /></span>
                      <h2 className='error-text'>The url is invalid. Click on the logo to get redirected to our homepage.</h2>
                    </div>
                  </>
                ) :
                  (
                    <>
                      <div className="sales_container">

                        <div className="email_invite_wrapper">
                          <div className="email_invite_header">
                            <div className="email_invite">
                              <span style={{ fontSize: 30 }}>Event Experience</span>
                              {/* <div className="AnlayticHeader-Print">
                                <p>Print Report</p>
                                <AiFillPrinter className="iconsPrint" />
                              </div> */}
                            </div>
                          </div>
                        </div>
                        <div className="eventExperienceContent">
                          {/* <div className="eventExperienceEngage">
                            <h1>Engage - </h1>
                            <div className="engageDesc">
                              <h3>
                                Below is your event's live chat. Use it to engage with people here
                              </h3>
                              <p>
                                Post live updates, create, polls, or schedule posts ahead of time
                              </p>
                            </div>
                          </div> */}
                          <div className="Activities">
                            {userMessagesModalIsOpen && userData && userMessagesModal(userData) }
                            <EventChat onContactClick={showUserMessagesModal} setUserData={setUserData}/>
                            <EventActivity />
                          </div>
                        </div>
                      </div>
                    </>
                  )
              }
            </div>
          </>
        )
      }
    </div>
  );
};

export default EventExperience
