import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import "../emailinvitation.css";
import { IoClose } from "react-icons/io5";
import { Text } from "Constants";
export default function ImageUpload({
  files,
  setFiles,
  image,
  setBase64img,
  setBaseImgUrl,
  showError,
}) {
  const [fileErrors, setfileErrors] = useState([]);
  const MAX_SIZE = 2000000;
  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result;

        resolve(baseURL);
      };

    });
  };

  const SetProfilePic = async (file) => {
    if (file) {
      getBase64(file)
        .then((result) => {
          file["base64"] = result;

          var base64 = result;
          setBase64img(base64);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: "image/jpg, image/jpeg, image/png, image/gif",
    maxSize: MAX_SIZE,
    onDrop: (acceptedFiles, rejectedFiles) => {
      SetProfilePic(acceptedFiles[0]);

      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      setfileErrors(rejectedFiles.length > 0 ? rejectedFiles[0].errors : []);
      showText = files?.length === 0;
    },
    onDragEnter: () => {
      setFiles([]);
      setfileErrors([]);
    },
  });

  let classes = "dropzone";
  let showText = files?.length === 0;

  const additionalClass = isDragAccept
    ? `${classes} accept`
    : isDragReject
    ? `${classes} reject`
    : classes;

  const previewStyle = {
    width: "100%",
    height: "auto",
    objectFit: "scale-down",
  };

  const iconStyle = {
    display: "block",
    width: "48px",
    height: "48px",
  };

  const revokeDataUri = (files) => {
    files.forEach((file) => URL.revokeObjectURL(file.preview));
  };

  const onClickHandler = () => {
    revokeDataUri(files);
    setFiles([]);
    setfileErrors([]);
  };

  const onSubmitHandler = () => alert("This will save to S3 as the farm logo");

  const errors = {
    FILESIZE: "The maximum size allowed is 2MB.",
    FILETYPE: "Not an image file",
  };

  const getErrorMessage = () => {
    switch (fileErrors[0].code) {
      case "file-invalid-type":
        showError(errors.FILETYPE);
        onClickHandler();
      case "file-too-large":
        showError(errors.FILESIZE);
        onClickHandler();
      default:
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  style={{ width: 100 }}
                  src="https://socialistmodernism.com/wp-content/uploads/2017/07/placeholder-image-300x225.png"
                />
              </div>

              <p
                style={{
                  fontSize: 18,
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                {Text.AddHeaderImage}
              </p>
              <p>{Text.DropYourFile}</p>
            </div>
          </div>
        );
    }
  };
  const removeFile = (e) => {
    e.stopPropagation();
    setFiles([]);
    setBase64img("");
    setBaseImgUrl("");
  };
  return (
    <section style={{ width: "100%" }}>
      <div {...getRootProps({ className: `${additionalClass}` })}>
        <input {...getInputProps()} />
        {isDragActive ? (
          isDragReject ? (
            <p>{Text.NotAnImage}</p>
          ) : (
            <p>{Text.DropYourFileHere}</p>
          )
        ) : (
          image === "" &&
          showText &&
          (fileErrors.length > 0 ? (
            getErrorMessage()
          ) : (
           
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    style={{ width: 100 }}
                    src="https://socialistmodernism.com/wp-content/uploads/2017/07/placeholder-image-300x225.png"
                  />
                </div>

                <p
                  style={{
                    fontSize: 18,
                    color: "#000",
                    fontWeight: "bold",
                  }}
                >
                  {Text.AddHeaderImage}
                </p>
                <p>{Text.DropYourFile}</p>
                <br />
                <br />
              </div>
            </div>
          ))
        )}
        {files?.length === 0 && image !== "" && (
          <div style={{ position: "relative" }}>
            <a
              href="#"
              onClick={(e) => removeFile(e)}
              style={{ color: "#000000" }}
              className="order_close"
            >
              <IoClose size={20} />
            </a>
            <img
              alt="Preview"
              key={image}
              src={image}
              className="cover-pic"
              // style={previewStyle}
            />
          </div>
        )}
        {files?.map((file) => (
          <div style={{ position: "relative" }}>
            <a
              href="#"
              onClick={(e) => removeFile(e)}
              style={{ color: "#000000" }}
              className="order_close"
            >
              <IoClose size={20} />
            </a>
            <img
              alt="Preview"
              key={file.preview}
              src={file.preview}
              className="cover-pic"
              // style={previewStyle}
            />
          </div>
        ))}
      </div>
    </section>
  );
}
