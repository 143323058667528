import React from "react";
import "./SoldCard.css";
import { Text } from "Constants";

function SoldCard({payoutData}) {
  const data = [
    {
      number: payoutData?.Sold,
      title: "Ordered",
    },
    {
      number: payoutData?.Available,
      title: "Available",
    },
  ];
  return (
    <div className="soldCard">
      <div className="soldCardHeader">
        <h2>{payoutData?.Sold ? Intl.NumberFormat().format(payoutData?.Sold) : 0 }</h2>
        <h4>{Text.TicketsHolders}</h4>
        <h6 style={{height:"13px"}}> </h6>
      </div>
      <div className="soldCardDesc">
        {data.map((item) => (
          <div className="soldCardSold">
            <p>{item.number ? Intl.NumberFormat().format(item.number) : 0}</p>
            <p>{item.title}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SoldCard;
