import React from "react";
import "./reportissue.css";
import JotformEmbed from "react-jotform-embed";
import reportImg from "images/Report.jpg";
import Navbar from "../Components_UBMeEvents/Navbar/Navbar";
import Footer from "../Components_UBMeEvents/Footer/Footer";
const ReportIssue = () => {
  return (
    <div>
      <Navbar />
      <div className="">
        <img style={{ width: "100%", height: "100%" }} src={reportImg} alt="" />
      </div>
      <div className="jot_form_wrapper">
        <div className="jot_form">
          <JotformEmbed
            className="jot_form"
            src="https://form.jotform.com/212957235112047"
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ReportIssue;
