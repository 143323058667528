import React, { useState } from "react";
import styles from "./Faq.module.css";
import Grid from "styles/Grid.jsx";
import Accordion from "./Accordion/Accordion";

const Faq = ({ currentEventDetails }) => {
  const [openKey, setOpenKey] = useState()

  const handleToggle = key => {
    setOpenKey(openKey !== key ? key : null)
  }
  return (
    <>
      <div className={styles.faq__Container} style={{ backgroundColor: (currentEventDetails !== null && currentEventDetails.EventColor !== null && currentEventDetails.EventColor !== undefined && currentEventDetails.EventColor !== "") ? currentEventDetails.EventColor : "#9b02a2"}}>
        <Grid row={true}>
          <Grid row={true} sm={12} md={6}>
            <div className={styles.faq__Text}>
              <span className={styles.faq_underline}>FAQ</span>
            </div>

          </Grid>
          <Grid column={true} sm={12} md={6}>
            <div className={styles.accordion__Container}>
              {currentEventDetails && currentEventDetails?.FaqData.map((item, index) => (
                <Accordion keyIndex={index} id={item.FaqId} open={openKey == item.FaqId} toggle={handleToggle} question={item.Question} answer={item.Answer} />
              ))}
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Faq;
