import React, { useEffect, useState } from "react";
import { axiosE } from "Service/service";
import "./unsubscribe.css";
import * as constants from "Constants";
import { history } from "store";
import { routeName } from "Service/env";
import { Base64 } from "js-base64";

const UnSubscribe = ({ match }) => {

  const [isUnsubscribe, setIsUnsubscribe] = useState(false);
  const [organizer, setOrganizer] = useState("");
  const UserId = match.params.userid;
  const encoded_string = match.params.email;
  const EmailAddress = Base64.decode(encoded_string);

  const UnSubscribeEmail = async () => {
    try {
      const { data } = await axiosE.post(constants.API.UnSubscribe, {
        EmailAddress,
        UserId,
      });
      if (data.results.ResponseCode) {
        let organizerData = JSON.parse(data.results.Data);
        setOrganizer(
          `${organizerData?.OrganizerFirstName} ${organizerData?.OrganizerLastName === null ? "" : organizerData?.OrganizerLastName }`
        );
        setIsUnsubscribe(true);
      }
    } catch (error) {}
  };

  return (
    <React.Fragment>
      <div className="unsubscribe_container">
        {isUnsubscribe ? (
          <div className="unsubscribe_card_wrapper">
            <div className="unsubscribe_card">
              <div className="unsubscribe_card_main_content">
                <h1>{constants.Text.Done}</h1>
              </div>
              <div className="unsubscribe_card_devider" />
              <div className="unsubscribe_card_header">
                <h2>{EmailAddress}</h2>
                <p>{constants.Text.UnSubscribed} {organizer}</p>
              </div>
            </div>
          </div>
        ) : (
          <div className="unsubscribe_card_wrapper">
            <div className="unsubscribe_card">
              <div className="unsubscribe_card_header">
                <h2>{EmailAddress}</h2>
                <p>{constants.Text.IsMailingList}</p>
              </div>
              <div className="unsubscribe_card_devider" />
              <div className="unsubscribe_card_main_content">
                <h1>{constants.Text.UnSubscribedMailingList}</h1>
                <p>
                  {constants.Text.NewsLetter}
                </p>

                <button
                  onClick={UnSubscribeEmail}
                  class="unsubscribe_card_button"
                  role="button"
                >
                  {constants.Text.Unsubscribe}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};
export default UnSubscribe;
