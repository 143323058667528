import React from 'react'
import { Link } from 'react-router-dom';
import "./success.css"

const Success = ({setOpenCheckOut, setLeaveCheckOut}) => {




  return (
    <div class="ticket-container">
    <div class="info-1">
    <h1 style={{marginBottom:30}}>Leave Checkout?</h1>
        <div style={{justifyContent: 'center',alignItems: 'center', display: 'flex'}}>


        {/* <img src="https://icons.veryicon.com/png/o/miscellaneous/8atour/success-35.png" style={{marginBottom:30, width:100, height:"auto"}}/> */}
        
        </div>
       
        <p>Are you sure you want to leave checkout? The items </p>
        <p>you've selected may not be available later.</p>
    </div>
    <div class="button-group">
        <Link style={{color:"#000"}} to="#" onClick={()=>setLeaveCheckOut(false)} class="stay">Stay</Link>
        <Link  style={{color:"#fff"}} to="#" onClick={()=>setOpenCheckOut(false)} class="leave">Leave</Link>
    </div>
    </div>
  )
}

export default Success;