import React from 'react';
import {homeRoute, routeName} from '../../Service/env';
class Index extends React.Component{
    componentWillMount(){
        this.props.history.push(`${homeRoute}`);
    }
    render(){
        return null;
    }
}
export default Index;