import React, { Component } from "react";
import {
  withStyles,
  Button,
  Modal,
  Box,
  Paper,
  IconButton,
  Collapse,
  TextField,
  Grid,
  Card,
  DialogTitle,
  FormControl,
} from "@material-ui/core";
import {
  DateRange,
  Delete,
  ExpandMore,
  AddCircle,
  RemoveCircle,
  Create,
  Close,
  ContactSupportOutlined,
} from "@material-ui/icons";
import moment, { isMoment } from "moment";
import ContentGrid from "../../../../Helper/gridWrapper/gridWrapper";
import DateFnsUtils from "@date-io/date-fns";
import Drawer from "@material-ui/core/Drawer";
import { Editor } from "react-draft-wysiwyg";
import { connect } from "react-redux";
import EventHeader from "./eventHeader";
import "./editor.css";
import { axiosE } from "../../../../Service/service";
import {
  addAgenda,
  addAgendaSpeaker,
  deleteAgenda,
  deleteAgendaSpeaker,
  updateAgenda,
  updateAgendaSpeaker,
} from "../../../../Actions/eventActions";
import { bindActionCreators } from "redux";
import { eventApis, Events, Text } from "../../../../Constants";
import { reusableClasses } from "./resusableClasses";
import { CustomRule } from "devextreme-react/data-grid";
import { DateAndTime } from "../Tickets/dateAndTime";
import TimePicker from "rc-time-picker";
import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import { default as momentTz } from "moment-timezone";
import AddIcon from "@material-ui/icons/Add";
import Loader from "../../../../Helper/loader/loader";
import { checkIfEventExists } from "../../../../Helper/Validations";
import { cloneDeep } from "lodash";

const styles = {
  createEventAgendaGrid: {
    ...reusableClasses.mainWrapper,
  },
  createEventAgendaWrapper: {
    ...reusableClasses.contentWrapper,
    flex: 1,
    height: "auto",
  },
  createEventAgendaVerticalLineWithCirclesWrapper: {
    margin: "47px 0px 0px 0px",
    "@media screen and (max-device-width:450px)": {
      display: "none",
    },
  },

  createEventAgendaFormWrapper: {},

  createEventAgendaAddSessionDaysButtonWrapper: {
    ...reusableClasses.addButtonWrapper,
  },
  createEventAgendaAddSessionDaysButton: {
    ...reusableClasses.addButton,
  },
  createEventAgendaAddDaysButton: {
    backgroundColor: "rgb(182, 14, 181)",
    color: "white",
    borderRadius: "5px",
    fontFamily:
        "HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif",
    fontSize: "14px",

    padding: "4px 8px",
    textTransform: "none",
    width: "auto",
    alignSelf: "flex-end",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  createEventAgendaAddSessionDaysButtonText: {
    ...reusableClasses.addButtonText,
  },
  createEventAgendaModalDatesWrapper: {
    display: "flex",
    margin: "25px 0px 25px 0px",
  },
  createEventAgendaModalDays: {
    "&:hover": {
      backgroundColor: "rgb(200, 200, 200)",
    },
  },
  modalStyle: {
    backgroundColor: "rgba(0, 0, 0, 0.25)",
  },

  createEventAgendaModalRightColumnDays: {
    width: "100%",
  },
  createEventAgendaModalCircle: {
    backgroundColor: "rgb(182, 14, 181)",
    float: "right",
    height: "13px",
    width: "13px",
    margin: "5px 5px 0px 0px",
    borderRadius: "50%",
    alignSelf: "center",
  },
  createEventAgendaModalUnselectedCircle: {
    float: "right",
    height: "13px",
    width: "13px",
    margin: "5px 5px 0px 0px",
    borderRadius: "50%",
    alignSelf: "center",
    border: "2px solid lightgray",
  },
  createEventAgendaDateSessionsWrapper: {
    margin: "30px 0px 0px 0px",

    "@media (max-width: 450px)": {
      padding: "0px 5%",
    },
  },
  createEventAgendaDeleteExpandSessionButton: {
    color: "gray",
    cursor: "pointer",
  },
  createEventAgendaAddSessionButton: {
    fontSize: "14px",
    backgroundColor: "rgb(0, 185, 255)",
    padding: "10px 25px 10px 25px",
    margin: "20px 0px 20px 25px",
    color: "white",
    textTransform: "none",
    borderRadius: "5px",
    fontFamily:
        "HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif",

    width: "auto",

    "@media (max-width: 450px)": {
      marginLeft: "0px !important",
    },

    "&:hover": {
      color: "black !important",
    },
  },
  createEventAgendaModalAddSessionToDay: {
    position: "absolute",
    backgroundColor: "white",
    padding: "10px",

    borderRadius: "3px",
    top: `50%`,
    left: `50%`,
    transform: "translate(-50%, -50%)",
    overflow: "auto",

    "@media (max-width: 768px)": {
      width: "100%",
    },
  },
  createEventAgendaAddSessionsToDayModalWrapper: {
    margin: "0px 0px 0px 0px",
  },
  createEventAgendaAddSessionToDayModalTextInputWithBorder: {
    border: "1px solid #ced4da",
    borderRadius: "3px",
    padding: "10px",
    margin: "10px 0px 10px 0px",
    width: "100%",
    borderLeft: "15px solid rgb(0, 185, 255)",
  },
  createEventAgendaAddSessionToDayModalTextInput: {
    border: "1px solid #ced4da",
    borderRadius: "3px",
    padding: "10px",
    margin: "10px 0px 10px 0px",
    width: "100%",
  },
  createEventAgendaAddSessionToDayModalTextInputLabel: {
    margin: "15px 0px 0px 0px",
  },
  createEventAgendaAddSessionToDayModalTextInputText: {
    fontSize: "16px",
  },
  createEventAgendaAddSessionToDayModalLargeTextInput: {
    border: "1px solid #ced4da",
    borderRadius: "3px",
    padding: "10px",
    margin: "10px 0px 10px 0px",
    height: "300px",
    overflow: "auto",
    width: "500px",
    "@media  screen and (max-device-width:450px)": {
      width: "100%",
    },
    scrollbarWidth: "none",
  },
  createEventAgendaAddSessionToDayModalTimePicker: {
    fontSize: "16px",
    border: "1px solid #ced4da",
    borderRadius: "5px",
    width: "100%",
    margin: "5px 0px 0px 0px",
    padding: "5px 5px 5px 10px",
  },
  createEventAgendaAddSessionToDayModalIcon: {
    color: "green",
  },
  createEventAgendaRemoveSessionToDayModalIcon: {
    color: "red",
  },
  createEventAgendaAddSessionToDayModalSpeakerTextField: {
    border: "1px solid #ced4da",
    borderRadius: "3px",
    padding: "10px",
    margin: "10px 10px 10px 0px",
    width: "40%",
  },
  createEventAgendaAddSessionToDayModalTopicTextField: {
    border: "1px solid #ced4da",
    borderRadius: "3px",
    padding: "10px",
    margin: "10px 0px 10px 0px",
    width: "50%",
  },
  createEventAgendaAddSessionToDayModalSaveSession: {
    ...reusableClasses.addOrUpdateModalHeaderButtonSave,
    "@media (max-width: 410px)": {
      width: "100px",
    },
  },
  createEventAgendaSessionTimeForDay: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "auto",
    margin: "35px 0px 25px -10px",
    borderLeft: "10px solid green !important",
    boxShadow: "none",
    border: "1px solid lightgray",
    padding: "5px",
  },
  createEventAgendaSessionTimeForDayTimeText: {
    margin: "0px 0px 0px 5px",
    fontSize: "15px",
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
  },
  createEventAgendaSessionVerticalLineDivider: {
    borderLeft: "3px solid lightgray",
    margin: "0px 3px 0px 3px",
    display: "grid",
    overflow: "hidden",
    width: "100%",
  },
  createEventAgendaSessionTimeForDaySessionText: {
    margin: "0px 0px 0px 3px",
    fontSize: "16px",
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
  },
  paper: {
    position: "absolute",
    width: "55px",
    backgroundColor: "#fff",
    padding: "10px 0px 0px 5px",
    top: "25%",
    left: "30%",
    height: "508px",
    outline: "none",
    border: "none",
  },
  drawerTitle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 !important",
  },
  createEventAgendaVerticalLine: {
    float: "left",
    borderLeft: "2px solid lightgray",
    margin: "136px 0px 0px 0px",
    "@media screen and (max-device-width:450px)": {
      display: "none",
    },
  },
  createEventFAQHeader: {
    position: "absolute",
    margin: "29px 35px",
    width: "76%",
    height: "fit-content",

    display: "flex",
    alignItems: "start",
    justifyContent: "space-between",

    paddingBottom: "20px",

    borderBottom: "1px solid #F5F5F5",
  },

  createEventFAQButtonWrapper: {
    margin: "2% 0% 0% 0%",
    textAlign: "end",
  },

  createEventAgendaImgWrapper: {
    width: "100px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100px",
  },
  createEventFAQSaveAndContinueButton: {
    ...reusableClasses.saveAndContinueButton,
  },

  createEvenFAQDeleteFAQModal: {
    width: "430px",
    height: "150px",
    backgroundColor: "white",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "10px",
    padding: "10px",

    "@media (max-width: 540px)": {
      width: "70%",
    },
    "@media (max-width: 410px)": {
      width: "90%",
    },
  },
  createEventFAQCancelFAQButton: {
    ...reusableClasses.addOrUpdateModalHeaderButtonCancel,
    "@media (max-width: 410px)": {
      width: "100px",
    },
  },
  createEventDeleteFAQCancel: {
    fontSize: "10px",
    padding: "10px 25px",
  },

  createEventFAQSaveFAQButton: {
    backgroundColor: "rgb(182, 14, 181)",
    fontSize: "1.5rem",
    color: "white",
    padding: "8px 25px",
    borderRadius: "9px",
  },

  createEventFAQFaqsAndCircleLinesWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    flex: "0.95",

    "@media (max-width: 450px)": {
      flex: 1,
    },
  },

  createEventAgendaPlusButtonToPlus: {
    ...reusableClasses.addButtonIcon,
  },

  createEventAgendaDisplayAgendaWrapper: {
    width: "100%",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    margin: "auto 0px",

    "@media (max-width: 445px)": {
      margin: "0px",
    },
  },

  createEventAgendaTimeWrapper: {
    width: "25%",

    "@media (max-width: 768px)": {
      width: "60%",
    },
    "@media (max-width: 445px)": {
      width: "90%",
    },
  },
  addUpdateModalOuterWrapper: {
    ...reusableClasses.addOrUpdateModalMainWrapperOuter,
  },
  addUpdateModalInnerWrapper: {
    ...reusableClasses.addOrUpdateModalMainWrapperInner,
    overflowX: "hidden",
  },
  addUpdateModalButtonWrapper: {
    ...reusableClasses.addOrUpdateModalHeaderButtonWrapper,
  },
};

const pattern = /^(http:\/\/|https:\/\/|www\.)/i;
let UrlError =
    "Please enter a valid URL starting with http://, https://, or www.";

class CreateEventAgenda extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addSessionDaysModalIsOpen: false,
      addSessionToDayModalIsOpen: false,
      deleteSessionModal: { show: false, target: "", type: "" },
      currentSession: {
        sessionTitle: "",
        sessionDescription: "",
        sessionStartTime: moment(),
        sessionEndTime: moment(),
        sessionLocation: "",
        sessionSpeakers: [
          {
            speakerName: "",
            speakerTopic: "",
            speakerLinkedInURL: "",
            speakerWebsiteURL: "",
          },
        ],
      },

      daysSelected: this.setDaysSelctedState(),
      daysToShow: [],
      editingSession: {},
      selectedDay: "",
      verticalLineHeight: "0px",
      isDateAdded: false,
      editorValue: "",
      loader: false,
      urlError: "",
      linkedInErrro: "",
    };
  }

  // timeUTCtoLocal = (time) => {
  //   const { timezone } = this.props;
  //   var local = momentTz
  //     .tz(moment.utc(time, "HH:mm:ss"), "HH:mm:ss", timezone)
  //     .format("HH:mm:ss");
  //   return moment(local, "HH:mm:ss");
  // };

  timeUTCtoLocalTime = (date, time) => {
    const { timezone } = this.props;
    const formattedDate = moment(date).format("MM/DD/YYYY");
    const formattedTime = moment(time, "HH:mm:ss").format("hh:mm:ss a");
    const dateAndTimeString = formattedDate + " " + formattedTime;
    var local = momentTz
        .tz(moment.utc(dateAndTimeString), "MM/DD/YYYY hh:mm:ss a", timezone)
        .format("MM/DD/YYYY hh:mm:ss a");
    const Time = moment(local, "MM/DD/YYYY hh:mm:ss a");
    return Time;
  };

  dateUTCtoLocal = (date, format = "MM/DD/YYYY hh:mm:ss a") => {
    const { timezone } = this.props;
    var local = momentTz.tz(moment.utc(date), format, timezone).format(format);
    // console.log("sessionDay - Local -- ", date, " --> ", local);
    return moment(local, format);
  };

  getCompositeTime = (date, time) => {
    const { timezone } = this.props;
    let timeString = time;
    // if (isMoment(timeString)) {
    //   timeString = timeString.format("HH:mm:ss");
    // }
    let agendaDate = moment(date).format("MM/DD/YYYY");
    let agendaTime = isMoment(timeString)
        ? timeString.format("HH:mm:ss")
        : moment(timeString).format("HH:mm:ss");
    const dateAndTimeString = agendaDate + " " + agendaTime;

    var timeInUtc = momentTz
        .tz(dateAndTimeString, "MM/DD/YYYY HH:mm:ss", timezone)
        .utc()
        .format("HH:mm:ss");

    return timeInUtc;
  };

  htmlToEditorState(prop) {
    const blocksFromHTML = convertFromHTML(prop);
    const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
    );
    const newState = this.customContentStateConverter(state);
    return EditorState.createWithContent(newState);
  }
  customContentStateConverter = (contentState) => {
    const newBlockMap = contentState.getBlockMap().map((block) => {
      const entityKey = block.getEntityAt(0);
      if (entityKey !== null) {
        const entityBlock = contentState.getEntity(entityKey);
        const entityType = entityBlock.getType();
        switch (entityType) {
          case "IMAGE": {
            const newBlock = block.merge({
              type: "atomic",
              text: "img",
            });
            return newBlock;
          }
          default:
            return block;
        }
      }
      return block;
    });
    const newContentState = contentState.set("blockMap", newBlockMap);
    return newContentState;
  };

  editorStateToHTML(prop) {
    return draftToHtml(convertToRaw(prop.getCurrentContent()));
  }

  progressLoader = () => {
    const { loader } = this.state;

    return loader && <Loader />;
  };

  changeServerToClientFormat = (completeSession) => {
    // check if the session has speakers array
    if (completeSession.sessionSpeakers.length <= 0) {
      completeSession.sessionSpeakers = [
        {
          speakerLinkedInURL: "",
          speakerName: "",
          speakerTopic: "",
          speakerWebsiteURL: "",
          SpeakerId: "",
        },
      ];
    }
    return {
      sessionDescription: completeSession.Description,
      sessionEndTime: this.timeUTCtoLocalTime(
          completeSession.SessionDate,
          completeSession.SessionEndTime
      ),
      sessionID: completeSession.AgendaSessionId,
      sessionLocation: completeSession.Location || "",
      sessionStartTime: this.timeUTCtoLocalTime(
          completeSession.SessionDate,
          completeSession.SessionStartTime
      ),
      sessionTitle: completeSession.SessionTitle,
      sessionSpeakers: completeSession["sessionSpeakers"].map((speaker) => ({
        speakerLinkedInURL: speaker.LinkedIn || "",
        speakerName: speaker.SpeakerName || "",
        speakerTopic: speaker.Topic || "",
        speakerWebsiteURL: speaker.Website || "",
        SpeakerId: speaker.SpeakerId,
      })),
    };
  };

  componentDidMount() {
    console.log(
        "date",
        moment("2023-04-03T23:00:00Z").format("MM/DD/YYYY hh:mm:ss A")
    );

    checkIfEventExists(this.props.history, this.props.eventId);
    const { timezone } = this.props;
    this.setState({
      loader: true,
    });
    axiosE
        .get(`${eventApis.getAgendaSessionByEventId}${this.props.eventId}/Admin`)
        .then((res) => {
          let agendaData = res.data.results.ListAgendaSession;

          // return if no agenda data
          if (!Boolean(agendaData)) return this.setState({ loader: false });

          const timeUTCtoLocal = (date, time) => {
            const formattedDate = moment(date).format("MM/DD/YYYY");
            const formattedTime = moment(time, "HH:mm:ss").format("hh:mm:ss a");
            const dateAndTimeString = formattedDate + " " + formattedTime;
            var local = momentTz
                .tz(
                    moment.utc(dateAndTimeString),
                    "MM/DD/YYYY hh:mm:ss a",
                    timezone
                )
                .format("MM/DD/YYYY hh:mm:ss a");
            const Time = moment(local, "MM/DD/YYYY hh:mm:ss a");
            return moment(Time).format("MMMM DD, YYYY");
          };

          // loop over agendaData and set speakers
          agendaData = agendaData.map(async (agenda, agendaIndex) => {

            // get speakers related to that session
            let speakerRes = await axiosE.get(
                eventApis.getAgendaSpeakerByAgendaSessionId + agenda.AgendaSessionId
            );
            let speakerData = await speakerRes.data.results.ListAgendaSpeaker;
            // get Agenda Speakers in agenda
            let completeSession = {
              ...agenda,
              sessionSpeakers: speakerData || [],
            };
            // convert session to its own look
            let convertedSession =
                this.changeServerToClientFormat(completeSession);
            // console.log("convertedSession", convertedSession);

            const convertTimeUtcToLocal = (time) => {
              console.log("time", time);
              var local = momentTz
                  .tz(moment.utc(time, "HH:mm:ss"), "HH:mm:ss", timezone)
                  .format("HH:mm:ss");
              return moment(local, "HH:mm:ss");
            };
            // To check is dateTime in UTC is correct
            const timeLocaltoUtc = (date, time) => {
              // console.log("##########", time);
              const UTCtoLocalTime = convertTimeUtcToLocal(time);
              // console.log("UTCtoLocalTime", UTCtoLocalTime);

              const formattedDate = moment(date).format("MM/DD/YYYY");
              const formattedTime = moment(UTCtoLocalTime, "HH:mm:ss").format(
                  "hh:mm A"
              );
              const dateAndTimeString = formattedDate + " " + formattedTime;
              // console.log("dateAndTimeString", dateAndTimeString);
              var dateTimeInUtc = momentTz
                  .tz(dateAndTimeString, "MM/DD/YYYY hh:mm:ss a", timezone)
                  .utc()
                  .format();
              return dateTimeInUtc;
            };

            // moment(d.SessionDate, "MM/DD/YYYY hh:mm:ss A").isSame(
            //   moment(convertedAgendaData.SessionDate, "MM/DD/YYYY hh:mm:ss A")
            // )

            const IsCorrectUTC = timeLocaltoUtc(
                agenda.SessionDate,
                completeSession.SessionStartTime
            );
            const NewUtcDate = moment(IsCorrectUTC, "MM/DD/YYYY").isSame(
                moment(agenda.SessionDate, "MM/DD/YYYY")
            )
                ? agenda.SessionDate
                : IsCorrectUTC;

            // console.log("IsCorrectUTC",IsCorrectUTC);
            // console.log("NewUtcDate",NewUtcDate);

            let sessionDay = timeUTCtoLocal(
                agenda.SessionDate,
                completeSession.SessionStartTime
            );

            // console.log("agenda.SessionDate", sessionDay);
            // create a daysSelected array copy
            let daysSelectedCopy = cloneDeep(this.state.daysSelected);
            // console.log("daysSelectedCopy", daysSelectedCopy);
            // find the index of the day in daysSelected
            let dayIndex = daysSelectedCopy.findIndex((day) => {
              // console.log("@@@@@@@@",day.date + "===" + sessionDay);
              return day.date === sessionDay;
            });
            // console.log("dayIndex", dayIndex);
            // if day is not present in daysSelected
            // console.log("dayIndex",dayIndex);
            if (dayIndex === -1) {
              // add the day to daysSelected
              daysSelectedCopy.push({
                date: sessionDay,
                expanded: true,
                hovered: false,
                selected: true,
                sessionDeleteModal: false,
                sessions: [convertedSession],
              });
            } else {
              // else add the session to the day
              daysSelectedCopy[dayIndex] = {
                ...daysSelectedCopy[dayIndex],
                sessions: [
                  ...daysSelectedCopy[dayIndex].sessions,
                  convertedSession,
                ],
                selected: true,
                expanded: true,
              };
            }
            // filter days to show
            let daysToShow = daysSelectedCopy.filter((day) => day.selected);

            // console.log("daysToShow",daysToShow);
            // set the daysSelected
            this.setState({
              daysSelected: daysSelectedCopy,
              daysToShow: daysToShow,
            });

            // when its the last session to be added
            if (agendaIndex === agendaData.length - 1) {
              // check is any day is selected
              if (this.state.daysToShow.length === 0) {
                // if no day is selected, select the first day
                this.setState({
                  isDateAdded: false,
                });
              } else {
                // else set isDateAdded to true
                this.setState({
                  isDateAdded: true,
                });
              }
            }
          });
        })
        .finally(() => {
          this.setState({
            loader: false,
          });
        });

    // if (agendaSessions && Object.keys(agendaSessions).length) {
    //   this.setLoaderState(true);
    //   const newDaysSelected = daysSelected.map((day) => {
    //     if (agendaSessions[day.date] && agendaSessions[day.date].length) {
    //       return {
    //         date: day.date,
    //         expanded: true,
    //         hovered: false,
    //         selected: true,
    //         sessionDeleteModal: false,
    //         sessions: agendaSessions[day.date].map((session) => ({
    //           sessionDescription: this.htmlToEditorState(
    //             session.Description || ""
    //           ),
    //           sessionEndTime: this.timeUTCtoLocal(session.SessionEndTime),
    //           sessionID: session.AgendaSessionId,
    //           sessionLocation: session.Location || "",
    //           sessionStartTime: this.timeUTCtoLocal(session.SessionStartTime),
    //           sessionTitle: session.SessionTitle,
    //           sessionSpeakers: session["Speakers"].map((speaker) => ({
    //             speakerLinkedInURL: speaker.LinkedIn || "",
    //             speakerName: speaker.SpeakerName || "",
    //             speakerTopic: speaker.Topic || "",
    //             speakerWebsiteURL: speaker.Website || "",
    //             SpeakerId: speaker.SpeakerId,
    //           })),
    //         })),
    //       };
    //     } else {
    //       return day;
    //     }
    //   });
    //   const newDaysToShow = newDaysSelected.filter((day) => day.selected);
    //   if (newDaysToShow.length <= 0) {
    //     this.setState({
    //       daysSelected: newDaysSelected,
    //       isDateAdded: false,
    //       daysToShow: [],
    //       loader: false,
    //     });
    //   } else {
    //     this.setState({
    //       daysSelected: newDaysSelected,
    //       isDateAdded: true,
    //       daysToShow: newDaysToShow,
    //       loader: false,
    //     });
    //   }
    // }
  }

  setDaysSelctedState = (eventDetails) => {
    const startDate = this.dateUTCtoLocal(this.props.eventStartDateTime);
    const endDate = this.dateUTCtoLocal(this.props.eventEndDateTime);
    let currentDate = startDate;

    const isSingleDayEvent = startDate.isSame(endDate, 'day');

    let days = [];

    if (isSingleDayEvent) {
      // For a single-day event, only add the start date to the array
      days.push({
        date: startDate.format("MMMM DD, YYYY").toString(),
        selected: false,
        hovered: false,
        expanded: false,
        sessionDeleteModal: false,
        sessions: [],
      });
    } else {
      // For a multi-day event, add all dates from start to end
      while (currentDate.isSameOrBefore(endDate, 'day')) {
        days.push({
          date: currentDate.format("MMMM DD, YYYY").toString(),
          selected: false,
          hovered: false,
          expanded: false,
          sessionDeleteModal: false,
          sessions: [],
        });
        currentDate.add(1, "days");
      }
    }

    return days;
  };

  circleOnVerticalLineStyles = (index) => {
    return {
      backgroundColor: "rgb(182, 14, 181)",
      height: "15px",
      width: "15px",
      margin:
          "0px 0px " + this.calculateCircleOnVerticalLineMargin(index) + " -9px",
      borderRadius: "50%",
      "@media screen and (max-device-width:450px)": {
        display: "none",
      },
    };
  };

  daySelectedModalStyles = (day) => {
    const { daysSelected } = this.state;
    const currentDay = daysSelected.filter((element) => element === day)[0];
    return {
      border: "1px solid #ced4da",

      cursor: "pointer",
      backgroundColor: currentDay.selected
          ? "rgb(200, 200, 200)"
          : currentDay.hovered
              ? "rgb(200, 200, 200)"
              : "white",
      fontSize: "14px",
      fontWeight: "600",
      padding: "10px",
      textTransform: "none",
      width: "180px",
      boxShadow: "0.5px 0.5px 0.5px 0.5px #9E9E9E",
    };
  };

  verticalLineStyles = (extraCircles) => {
    return {
      float: "left",
      borderLeft: "2px solid lightgray",
      margin: "56px 0px 0px 0px",
      height: this.calculateLineHeight(extraCircles),

      "@media screen and (max-device-width:450px)": {
        display: "none",
      },
    };
  };

  createEventAgendaDateSession = (day) => {
    return {
      backgroundColor: "rgb(240, 240, 240)",
      height: "42px",
      width: "auto",
      margin:
          "0px 0px " + this.createEventAgendaDateSessionMargin(day) + " 25px",
      padding: "0px 5px 0px 10px",

      "@media (max-width: 445px)": {
        margin:
            "0px 0px " + this.createEventAgendaDateSessionMargin(day) + " 0px",
      },
    };
  };

  createEventAgendaDateSessionMargin = (day) => {
    return day.expanded ? 0 + 100 * day.sessions.length + "px" : "0px";
  };

  calculateLineHeight = (extraCircles) => {
    const { daysToShow, verticalLineHeight } = this.state;
    let extraLineHeight = 0;
    daysToShow.forEach(
        (day, index) =>
            (extraLineHeight +=
                day.expanded && index != daysToShow.length - 1
                    ? 0 + 100 * day.sessions.length
                    : 0)
    );
    return daysToShow.length <= 1
        ? "0px"
        : (extraCircles - 1) * 132 + extraLineHeight + "px";
  };

  calculateCircleOnVerticalLineMargin = (index) => {
    const { daysToShow } = this.state;
    return daysToShow.length > 0
        ? daysToShow[index].expanded && index != daysToShow.length - 1
            ? 115 + 100 * daysToShow[index].sessions.length + "px"
            : "115px"
        : "115px";
  };

  verticalLineWithCircles = () => {
    const { classes } = this.props;
    const { daysToShow } = this.state;
    let extraCircles = daysToShow.map((day, index) => (
        <div style={this.circleOnVerticalLineStyles(index)} key={index}></div>
    ));
    let elements = [];
    let verticalLineKey = 0;
    elements.push(
        <div key={verticalLineKey++} style={{ display: "flex" }}>
        <span
            className={classes.createEventAgendaVerticalLine}
            style={this.verticalLineStyles(extraCircles.length)}
        ></span>
          <div
              className={classes.createEventAgendaVerticalLineWithCirclesWrapper}
          >
            {extraCircles}
          </div>
        </div>
    );
    return elements;
  };

  expandOrCollapseDaySession = (day) => {
    const { daysSelected, daysToShow } = this.state;
    this.setState({
      daysSelected: daysSelected.map((currentDay) =>
          JSON.stringify(currentDay) === JSON.stringify(day)
              ? { ...currentDay, expanded: !currentDay.expanded }
              : currentDay
      ),
      daysToShow: daysToShow.map((currentDay) =>
          JSON.stringify(currentDay) === JSON.stringify(day)
              ? { ...currentDay, expanded: !currentDay.expanded }
              : currentDay
      ),
    });
  };

  removeSessionDay = (index) => {
    const { daysSelected, daysToShow } = this.state;
    const { deleteAgenda } = this.props;

    const day = this.state.daysToShow[index];
    const sessions = day.sessions;
    const dayDate = day.date;
    sessions.forEach((session) => {
      deleteAgenda({ AgendaSessionId: session.sessionID, day });
    });
    this.setState({
      daysSelected: daysSelected.map((currentDay) =>
          JSON.stringify(currentDay) === JSON.stringify(day)
              ? { ...currentDay, selected: false, sessions: [] }
              : currentDay
      ),
      daysToShow: daysToShow.filter(
          (currentDay) => JSON.stringify(currentDay) !== JSON.stringify(day)
      ),
    });
    this.setSessionDayDelete(false);
  };

  selectDay = (day) => {
    const { daysSelected } = this.state;
    this.setState({
      daysSelected: daysSelected.map((currentDay, index) =>
          JSON.stringify(currentDay) === JSON.stringify(day)
              ? { ...currentDay, selected: !daysSelected[index].selected }
              : currentDay
      ),
    });
  };
  toggleSessionDaysModal = () => {
    const { addSessionDaysModalIsOpen } = this.state;
    this.setState({
      addSessionDaysModalIsOpen: !addSessionDaysModalIsOpen,
    });
  };

  toggleSessionToDayModal = (day) => {
    const { addSessionToDayModalIsOpen, currentSession, selectedDay } =
        this.state;
    this.setState({
      addSessionToDayModalIsOpen: !addSessionToDayModalIsOpen,
      editingSession: {},
      currentSession: !addSessionToDayModalIsOpen
          ? currentSession
          : {
            sessionTitle: "",
            sessionDescription: "",
            sessionStartTime: moment(),
            sessionEndTime: moment().add(1, "hours"),
            sessionLocation: "",
            sessionSpeakers: [
              {
                speakerName: "",
                speakerTopic: "",
                speakerLinkedInURL: "",
                speakerWebsiteURL: "",
              },
            ],
          },
      selectedDay: !addSessionToDayModalIsOpen ? day : "",
    });
  };

  showSelectedDays = () => {
    const { daysSelected } = this.state;
    this.setState({
      addSessionDaysModalIsOpen: false,
      daysToShow: daysSelected.filter((day) => day.selected),
    });
  };

  addSessionDaysModalAddSpeaker = (day) => {
    const { daysSelected, daysToShow, currentSession } = this.state;
    this.setState({
      currentSession: {
        ...currentSession,
        sessionSpeakers: [
          ...currentSession.sessionSpeakers,
          {
            speakerName: "",
            speakerTopic: "",
            speakerLinkedInURL: "",
            speakerWebsiteURL: "",
          },
        ],
      },
    });
  };

  removeSessionDaysModalSpeaker = async (day, index) => {
    const { daysSelected, daysToShow, currentSession } = this.state;
    this.setState({
      currentSession: {
        ...currentSession,
        sessionSpeakers: currentSession.sessionSpeakers.filter(
            (session, i) => i !== index
        ),
      },
    });
  };

  onTextChangeAddSessionToDayModal = (e, textFieldName, day, index) => {
    const { currentSession, daysSelected, daysToShow } = this.state;
    const value =
        textFieldName === "Session description"
            ? e
            : e.target !== undefined
                ? e.target.value
                : moment(e);
    const addSessionLabels = Events.Agenda.addSession;
    let textFieldSpeakerMap = {
      [addSessionLabels.speakerName]: "speakerName",
      [addSessionLabels.speakerTopic]: "speakerTopic",
      [addSessionLabels.speakerLinkedin]: "speakerLinkedInURL",
      [addSessionLabels.speakerWebsite]: "speakerWebsiteURL",
    };
    let textFieldAddSessionMap = {
      [addSessionLabels.title]: "sessionTitle",
      [addSessionLabels.description]: "sessionDescription",
      "Start Time": "sessionStartTime",
      "End Time": "sessionEndTime",
      [addSessionLabels.location]: "sessionLocation",
    };

    if (textFieldName === addSessionLabels.speakerLinkedin) {
      const pattern = /^(http:\/\/|https:\/\/|www\.)/i;
      if (
          !pattern.test(
              currentSession?.sessionSpeakers[index][
                  textFieldSpeakerMap[addSessionLabels.speakerLinkedin]
                  ]
          )
      ) {
        this.setState({
          linkedInErrro:
              "Please enter a valid URL starting with http://, https://, or www.",
        });
      } else {
        this.setState({ linkedInErrro: "" });
      }
    }
    if (textFieldName === addSessionLabels.speakerLinkedin && value === "") {

      this.setState({ linkedInErrro: "" });
    }

    if (textFieldName === addSessionLabels.speakerWebsite) {
      const pattern = /^(http:\/\/|https:\/\/|www\.)/i;
      if (
          !pattern.test(
              currentSession?.sessionSpeakers[index][
                  textFieldSpeakerMap[addSessionLabels.speakerWebsite]
                  ]
          )
      ) {
        this.setState({
          urlError:
              "Please enter a valid URL starting with http://, https://, or www.",
        });
      } else {
        this.setState({ urlError: "" });
      }
    }
    if (textFieldName === addSessionLabels.speakerWebsite && value === "") {

      this.setState({ urlError: "" });
    }

    // let err2 = '';
    // const pattern2 = /^(http:\/\/|https:\/\/|www\.)/i;
    // if (!pattern2.test(currentSession?.sessionSpeakers[index][
    //   textFieldSpeakerMap["Speaker's website URL (optional)"]
    // ])) {
    //   err2  = 'Please enter a valid URL starting with http://, https://, or www.'
    // } else {
    //   err2 = ''
    // }

    this.setState({
      currentSession:
          Object.keys(day).length !== 0 && index !== -1
              ? {
                ...currentSession,
                sessionSpeakers: currentSession.sessionSpeakers.map(
                    (sessionSpeaker, i) =>
                        i === index
                            ? {
                              ...sessionSpeaker,
                              [textFieldSpeakerMap[textFieldName]]: value,
                            }
                            : sessionSpeaker
                ),
              }
              : {
                ...currentSession,
                [textFieldAddSessionMap[textFieldName] != undefined
                    ? textFieldAddSessionMap[textFieldName]
                    : "sessionSpeakers"]:
                    textFieldAddSessionMap[textFieldName] != undefined
                        ? value
                        : [
                          {
                            ...currentSession.sessionSpeakers[0],
                            [textFieldSpeakerMap[textFieldName]]: value,
                          },
                        ],
              },
    });
  };

  setLoaderState = (state, addSessionModal) => {
    this.setState({
      loader: state,
    });
  };

  toggleModal = () => {
    this.setState({
      addSessionDaysModalIsOpen: false,
      addSessionToDayModalIsOpen: false,
    });
  };

  setSpeakerIds = async (speakers, id) => {
    const { currentSession } = this.state;
    const { addAgendaSpeaker, updateAgendaSpeaker } = this.props;
    const selectedDate = this.state.selectedDay.date;


    const speakerValues = speakers.filter(
        (speaker) => speaker.speakerName.trim() !== ""
    );
    if (speakerValues.length <= 0) return speakers;

    const speakerIds = await Promise.all(
        // eslint-disable-line
        speakers.map(async (speaker) => {
          let speakerId;
          if (speaker.hasOwnProperty("SpeakerId") && speaker.SpeakerId !== "") {
            speakerId = await updateAgendaSpeaker({
              speakerData: {
                SpeakerName: speaker.speakerName || "  ",
                LinkedIn: speaker.speakerLinkedInURL,
                Website: speaker.speakerWebsiteURL,
                AgendaSessionId: id,
                SpeakerId: speaker.SpeakerId,
                Topic: speaker.speakerTopic,
              },
              day: selectedDate,
            });
          } else {
            speakerId = await addAgendaSpeaker({
              speakerData: {
                SpeakerName: speaker.speakerName || "  ",
                LinkedIn: speaker.speakerLinkedInURL,
                Website: speaker.speakerWebsiteURL,
                AgendaSessionId: id,
                Topic: speaker.speakerTopic,
              },
              day: selectedDate,
            });
          }
          return { ...speaker, SpeakerId: speakerId.SpeakerId };
        })
    );
    return speakerIds;
  };

  getDeleteSpeakerIds = (currentSession, daySelected) => {
    const { daysToShow } = this.state;
    const { deleteAgendaSpeaker } = this.props;

    if (currentSession.hasOwnProperty("sessionID")) {
      let session;
      daysToShow.forEach((day) => {
        if (day.date === daySelected.date) {
          day.sessions.forEach((sessionInDay) => {
            if (sessionInDay.sessionID === currentSession.sessionID) {
              session = sessionInDay;
            }
          });
        }
      });


      const deletedSpeakers = [];
      for (let existingField of session.sessionSpeakers) {
        let notPresent = true;
        for (let currentField of currentSession.sessionSpeakers) {
          if (existingField.SpeakerId === currentField.SpeakerId) {
            if (currentField.speakerName === "" && currentField.speakerTopic === "") {
              notPresent = true;
              if (currentSession.sessionSpeakers.length > 1) {
                const indexToRemove = currentSession.sessionSpeakers.indexOf(currentField);
                if (indexToRemove !== -1) {
                  currentSession.sessionSpeakers.splice(indexToRemove, 1); // Remove the currentField
                }
              } else {
                currentField.SpeakerId = "";
              }
            } else {
              notPresent = false;
            }
          }
        }
        if (notPresent) deletedSpeakers.push(existingField);
      }
      deletedSpeakers.forEach((speaker) => {
        deleteAgendaSpeaker({
          SpeakerId: speaker.SpeakerId,
          day: daySelected.date,
          sessionID: currentSession.sessionID,
        });
      });
    }
  };

  saveSession = async (day) => {
    const {
      currentSession,
      daysSelected,
      daysToShow,
      editingSession,
      selectedDay,
    } = this.state;

    this.setLoaderState(true);
    this.toggleModal();
    let sessionID;
    let speakers = [];
    if (currentSession.sessionID) {
      const updated = await this.updateSessionRemote(
          currentSession,
          selectedDay
      );
      sessionID = currentSession.sessionID;
      this.setLoaderState(false);
    } else {
      sessionID = await this.addSessionRemote(currentSession, selectedDay);
      this.setLoaderState(false);
    }

    if (
        currentSession.sessionSpeakers[0].speakerName !== "" &&
        currentSession.sessionSpeakers[0].speakerTopic !== ""
    ) {
      speakers = await this.setSpeakerIds(
          currentSession.sessionSpeakers,
          sessionID
      );
    }

    this.getDeleteSpeakerIds(currentSession, selectedDay);
    const dayToUse = Object.keys(selectedDay).length > 0 ? selectedDay : day;

    this.setState({
      addSessionToDayModalIsOpen: false,
      currentSession: {
        sessionTitle: "",
        sessionDescription: "",
        sessionStartTime: moment(),
        sessionEndTime: moment().add(1, "hours"),
        sessionLocation: "",
        sessionSpeakers: [
          {
            speakerName: "",
            speakerTopic: "",
            speakerLinkedInURL: "",
            speakerWebsiteURL: "",
          },
        ],
      },
      daysSelected: daysSelected.map((currentDay, i) =>
          JSON.stringify(currentDay) === JSON.stringify(dayToUse)
              ? {
                ...currentDay,
                sessions:
                    Object.keys(editingSession).length > 0
                        ? currentDay.sessions.map((session, j) =>
                            JSON.stringify(session) === JSON.stringify(editingSession)
                                ? {
                                  ...currentSession,
                                  sessionID,
                                  sessionSpeakers:
                                      speakers.length > 0
                                          ? speakers
                                          : currentSession.sessionSpeakers,
                                }
                                : session
                        )
                        : [
                          ...currentDay.sessions,
                          {
                            ...currentSession,
                            sessionID,
                            sessionSpeakers:
                                speakers.length > 0
                                    ? speakers
                                    : currentSession.sessionSpeakers,
                          },
                        ],
                expanded: true,
              }
              : currentDay
      ),
      daysToShow: daysToShow.map((currentDay, i) =>
          JSON.stringify(currentDay) === JSON.stringify(dayToUse)
              ? {
                ...currentDay,
                sessions:
                    Object.keys(editingSession).length > 0
                        ? currentDay.sessions.map((session, j) =>
                            JSON.stringify(session) === JSON.stringify(editingSession)
                                ? {
                                  ...currentSession,
                                  sessionID,
                                  sessionSpeakers:
                                      speakers.length > 0
                                          ? speakers
                                          : currentSession.sessionSpeakers,
                                }
                                : session
                        )
                        : [
                          ...currentDay.sessions,
                          {
                            ...currentSession,
                            sessionID,
                            sessionSpeakers:
                                speakers.length > 0
                                    ? speakers
                                    : currentSession.sessionSpeakers,
                          },
                        ],
                expanded: true,
              }
              : currentDay
      ),
      editingSession: {},
    });
  };

  getMomentFromTime = (time) => {
    return isMoment(time)
        ? time.format("hh:mm a")
        : moment(time, "HH:mm:ss").format("hh:mm a");
  };

  getMoment = (date) => {
    return isMoment(date) ? date : moment(date, "HH:mm:ss");
  };

  addSessionRemote = async (currentSession, day) => {
    console.log(
        "currentSession.sessionStartTime",
        currentSession.sessionStartTime
    );
    const { addAgenda, eventId } = this.props;
    const sessionId = await addAgenda({
      EventId: eventId,
      SessionTitle: currentSession.sessionTitle,
      SessionDate: this.getTzDate(day.date, currentSession.sessionStartTime),
      SessionStartTime: this.getCompositeTime(
          day.date,
          currentSession.sessionStartTime
      ),
      SessionEndTime: this.getCompositeTime(
          day.date,
          currentSession.sessionEndTime
      ),
      Location: currentSession.sessionLocation || "",
      // Description: this.editorStateToHTML(currentSession.sessionDescription),
      Description: currentSession.sessionDescription,
    });
    return sessionId.AgendaSessionId;
  };

  getTzDate = (date, time) => {
 
    let agendaDate = moment(date).format("MM/DD/YYYY");
    let agendaTime = moment(time, "HH:mm:ss").format("hh:mm A");
    const dateAndTimeString = agendaDate + " " + agendaTime;
    // console.log("dateTimeLocal",dateAndTimeString);
    const { timezone } = this.props;
    // console.log("date", moment(`${agendaDate} ${agendaTime}`).format("MM/DD/YYYY hh:mm:ss A") );
    var dateTimeInUtc = momentTz
        .tz(dateAndTimeString, "MM/DD/YYYY hh:mm:ss A", timezone)
        .utc()
        .format();

    // console.log("dateTimeInUtc", moment(dateTimeInUtc).utc().format("MM/DD/YYYY hh:mm:ss A"));

    return dateTimeInUtc;
  };

  updateSessionRemote = async (currentSession, day) => {
    const { updateAgenda, eventId } = this.props;
    const updatedSession = await updateAgenda({
      AgendaSessionId: currentSession.sessionID,
      EventId: eventId,
      SessionTitle: currentSession.sessionTitle,
      SessionDate: this.getTzDate(day.date, currentSession.sessionStartTime),
      SessionStartTime: this.getCompositeTime(
          day.date,
          currentSession.sessionStartTime
      ),
      SessionEndTime: this.getCompositeTime(
          day.date,
          currentSession.sessionEndTime
      ),
      Location: currentSession.sessionLocation || "",
      // Description: this.editorStateToHTML(currentSession.sessionDescription),
      Description: currentSession.sessionDescription,
    });
    return updatedSession.AgendaSessionId;
  };

  deleteSessionFromDay = ({ day, session }) => {
    const { daysSelected, daysToShow } = this.state;
    this.setState({
      daysSelected: daysSelected.map((currentDay, i) =>
          JSON.stringify(currentDay) === JSON.stringify(day)
              ? {
                ...currentDay,
                sessions: currentDay.sessions.filter(
                    (currentSession, j) =>
                        JSON.stringify(currentSession) !== JSON.stringify(session)
                ),
              }
              : currentDay
      ),
      daysToShow: daysToShow.map((currentDay, i) =>
          JSON.stringify(currentDay) === JSON.stringify(day)
              ? {
                ...currentDay,
                sessions: currentDay.sessions.filter(
                    (currentSession, j) =>
                        JSON.stringify(currentSession) !== JSON.stringify(session)
                ),
              }
              : currentDay
      ),
    });
    this.props.deleteAgenda({ AgendaSessionId: session.sessionID, day });
    this.setSessionDayDelete(false);
  };

  editSessionFromDay = (day, session) => {
    this.setState({
      addSessionToDayModalIsOpen: true,
      editingSession: session,
      currentSession: session,
      selectedDay: day,
    });
  };

  addSessionDaysModal = () => {
    const { classes } = this.props;
    const { addSessionDaysModalIsOpen } = this.state;
    return (
        <Modal
            open={addSessionDaysModalIsOpen}
            onClose={this.toggleSessionDaysModal}
            sx={{ backgroundColor: "rgba(0, 0, 0, 0.25)", zIndex: 1 }}
        >
          <Grid container spacing={1}>
            <Grid item sm={12} />
            <div
                className={classes.createEventAgendaModalAddSessionToDay}
                style={{ maxHeight: "80vh" }}
            >
              <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    float: "right",
                  }}
              >
                <IconButton
                    onClick={() => {
                      this.toggleSessionDaysModal();
                    }}
                >
                  <Close height={30} />
                </IconButton>
              </div>
              <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "50px",
                  }}
              >
                {this.displayAgenda()}
              </div>
            </div>
          </Grid>
        </Modal>
    );
  };

  mandatoryFieldTag = (title) => {
    const mandatoryFields = [
      "Session title",
      "Start Time",
      "End Time",
      "Session description",
      "speakerName",
      "speakerTopic",
    ];

    return mandatoryFields.includes(title) ? (
        <span style={{ color: "red" }}>*</span>
    ) : (
        ""
    );
  };

  addSessionsToDayModalLabelAndTextField = (
      textFieldName,
      placeHolder,
      hasBorder,
      isLargeInputField
  ) => {
    const { classes } = this.props;
    const { currentSession } = this.state;
    let textFieldAddSessionMap = {
      "Session title": "sessionTitle",
      "Session description": "sessionDescription",
      "Start Time": "sessionStartTime",
      "End Time": "sessionEndTime",
      "Location (optional)": "sessionLocation",
    };
    const textFieldClass = hasBorder
        ? classes.createEventAgendaAddSessionToDayModalTextInputWithBorder
        : isLargeInputField
            ? classes.createEventAgendaAddSessionToDayModalLargeTextInput
            : classes.createEventAgendaAddSessionToDayModalTextInput;
    return (
        <React.Fragment>
          <p
              className={
                classes.createEventAgendaAddSessionToDayModalTextInputLabel
              }
          >
            {textFieldName} {this.mandatoryFieldTag(textFieldName)}
          </p>
          <TextField
              className={textFieldClass}
              placeholder={placeHolder}
              multiline={isLargeInputField}
              rows={10}
              onChange={(e) =>
                  this.onTextChangeAddSessionToDayModal(e, textFieldName, {}, -1)
              }
              value={currentSession[textFieldAddSessionMap[textFieldName]]}
              InputProps={{
                disableUnderline: true,
                className:
                classes.createEventAgendaAddSessionToDayModalTextInputText,
              }}
          />
        </React.Fragment>
    );
  };

  addSessionsToDayDrawerLabelAndEditor = (textFieldName) => {
    const { classes } = this.props;
    const { currentSession } = this.state;

    return (
        <React.Fragment>
        <span
            className={
              classes.createEventAgendaAddSessionToDayModalTextInputLabel
            }
        >
          {textFieldName} {this.mandatoryFieldTag(textFieldName)}
        </span>
          <textarea
              value={this.state.currentSession.sessionDescription}
              onChange={(e) =>
                  this.setState({
                    currentSession: {
                      ...this.state.currentSession,
                      sessionDescription: e.target.value,
                    },
                  })
              }
              style={{
                height: 300,
                width: "100%",
                resize: "none",
                padding: 10,
                whiteSpace: "pre-line",
                margin: "10px 0px 20px 0px",
                border: "1px solid #ced4da",
                outline: "none",
                overflowWrap: "break-word",
              }}
          />
        </React.Fragment>
    );
  };

  ChangeStartTime = (date) => {
    const { currentSession, daysSelected, daysToShow } = this.state;
    if (date === null || date === "invalid date") {
      this.setState({
        currentSession: {
          ...currentSession,
          sessionStartTime: moment(),
        },
      });
    } else {
      this.setState({
        currentSession: {
          ...currentSession,
          sessionStartTime: moment(date),
        },
      });
    }
  };

  ChangeEndTime = (date) => {
    const { currentSession, daysSelected, daysToShow } = this.state;
    if (date === null || date === "invalid date") {
      this.setState({
        currentSession: {
          ...currentSession,
          sessionEndTime: moment(),
        },
      });
    } else {
      this.setState({
        currentSession: {
          ...currentSession,
          sessionEndTime: moment(date),
        },
      });
    }
  };

  addSessionsToDayModalTimeField = (textFieldName) => {
    const { currentSession } = this.state;
    return (
        <React.Fragment>
          <TimePicker
              placeholder="Select Time"
              use12Hours
              showSecond={false}
              focusOnOpen={true}
              format="hh:mm a"
              value={
                textFieldName === "Start Time"
                    ? this.getMoment(currentSession.sessionStartTime)
                    : this.getMoment(currentSession.sessionEndTime)
              }
              onChange={(e) =>
                  textFieldName === "Start Time"
                      ? this.ChangeStartTime(e)
                      : this.ChangeEndTime(e)
              }
              className="time_picker"
              style={{ margin: "15px !important" }}
          />
        </React.Fragment>
    );
  };
  addSessionDaysModalSpeakerTextField = (
      day,
      placeHolder,
      isTopicTextField,
      isLongField,
      index
  ) => {
    const { classes } = this.props;
    const { currentSession, daysToShow } = this.state;
    const textFieldClass = isLongField
        ? classes.createEventAgendaAddSessionToDayModalTextInput
        : isTopicTextField
            ? classes.createEventAgendaAddSessionToDayModalTopicTextField
            : classes.createEventAgendaAddSessionToDayModalSpeakerTextField;
    let textFieldSpeakerMap = {
      "Speaker's Name*": "speakerName",
      "Topic*": "speakerTopic",
      "Speaker's LinkedIn profile URL (optional)": "speakerLinkedInURL",
      "Speaker's website URL (optional)": "speakerWebsiteURL",
    };

    // console.log("currentSession",currentSession);

    const speakerNameValue =
        currentSession?.sessionSpeakers[index][
            textFieldSpeakerMap["Speaker's Name*"]
            ];
    const speakerTopicValue =
        currentSession?.sessionSpeakers[index][textFieldSpeakerMap["Topic*"]];
    const isSpeakerNameNotEmpty = speakerNameValue !== "";
    const isSpeakerTopicNotEmpty = speakerTopicValue !== "";

    return (
        <React.Fragment key={placeHolder}>
          <TextField
              style={{
                width:
                    (placeHolder === "Speaker's Name*" || placeHolder === "Topic*") &&
                    "42%",
              }}
              className={textFieldClass}
              placeholder={placeHolder}
              onChange={(e) =>
                  this.onTextChangeAddSessionToDayModal(e, placeHolder, day, index)
              }
              value={
                currentSession?.sessionSpeakers[index][
                    textFieldSpeakerMap[placeHolder]
                    ]
              }
              InputProps={{
                disableUnderline: true,
                className:
                classes.createEventAgendaAddSessionToDayModalTextInputText,
              }}
          />

          {currentSession?.sessionSpeakers[index][
                  textFieldSpeakerMap["Speaker's LinkedIn profile URL (optional)"]
                  ] !== "" &&
              placeHolder === "Speaker's LinkedIn profile URL (optional)" && (
                  <p style={{ color: "red" }}>
                    {!pattern.test(
                        currentSession?.sessionSpeakers[index][
                            textFieldSpeakerMap[
                                "Speaker's LinkedIn profile URL (optional)"
                                ]
                            ]
                    )
                        ? UrlError
                        : null}
                  </p>
              )}
          {currentSession?.sessionSpeakers[index][
                  textFieldSpeakerMap["Speaker's website URL (optional)"]
                  ] !== "" &&
              placeHolder === "Speaker's website URL (optional)" && (
                  <p style={{ color: "red" }}>
                    {!pattern.test(
                        currentSession?.sessionSpeakers[index][
                            textFieldSpeakerMap["Speaker's website URL (optional)"]
                            ]
                    )
                        ? UrlError
                        : null}
                  </p>
              )}
          {placeHolder === "Topic*" && index != 0 ? (
              <div
                  style={{
                    flexDirection: "flex",
                    justifyContent: "center",
                    alignSelf: "center",
                  }}
              >
                <IconButton
                    className={classes.createEventAgendaRemoveSessionToDayModalIcon}
                    onClick={() => this.removeSessionDaysModalSpeaker(day, index)}
                >
                  <RemoveCircle height={24} width={24} />
                </IconButton>
              </div>
          ) : (
              ""
          )}
        </React.Fragment>
    );
  };

  addSessionsToDayModal = (day) => {
    const { classes } = this.props;
    const { addSessionToDayModalIsOpen, currentSession } = this.state;
    let speakerTextFields = [];
    let linkedInErrro = currentSession.sessionSpeakers.every(
        ({ speakerLinkedInURL }) =>
            speakerLinkedInURL === "" ? true : pattern.test(speakerLinkedInURL)
    );

    let websiteError = currentSession.sessionSpeakers.every(
        ({ speakerWebsiteURL }) =>
            speakerWebsiteURL === "" ? true : pattern.test(speakerWebsiteURL)
    );
    console.log(
        "speakerTextFields",
        linkedInErrro,
        currentSession.sessionSpeakers
    );

    {
      currentSession.sessionSpeakers.forEach((d, i) => {
        {
          i != 0 &&
          speakerTextFields.push(
              <React.Fragment key={i}>
                <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                >
                  {this.addSessionDaysModalSpeakerTextField(
                      day,
                      "Speaker's Name*",
                      false,
                      false,
                      i
                  )}
                  {this.addSessionDaysModalSpeakerTextField(
                      day,
                      "Topic*",
                      true,
                      false,
                      i
                  )}
                </div>
                {this.addSessionDaysModalSpeakerTextField(
                    day,
                    "Speaker's LinkedIn profile URL (optional)",
                    false,
                    true,
                    i
                )}
                {this.addSessionDaysModalSpeakerTextField(
                    day,
                    "Speaker's website URL (optional)",
                    false,
                    true,
                    i
                )}
              </React.Fragment>
          );
        }
      });
    }
    return (
        <Modal
            open={addSessionToDayModalIsOpen}
            onClose={() => this.toggleSessionToDayModal(day)}
            // className={classes.modalStyle}

            BackdropProps={{ style: { backgroundColor: "rgba(0, 0, 0, 0.25)" } }}
        >
          <div className={classes.addUpdateModalOuterWrapper}>
            <div className={classes.addUpdateModalInnerWrapper}>
              <div>
                <DialogTitle
                    disableTypography
                    contentEditable={false}
                    className={classes.drawerTitle}
                >
                  <IconButton
                      onClick={() => {
                        this.toggleSessionToDayModal(day);
                      }}
                  >
                    <Close height={30} />
                  </IconButton>
                  <span
                      style={{
                        color: "rgb(182, 14, 181)",
                        fontSize: 20,
                        fontWeight: "500",
                      }}
                  >
                  {Text.SessionDetails}
                </span>
                  <div></div>
                </DialogTitle>
                <div className={classes.createEventAgendaDrawerAddSessionToDay}>
                  {this.addSessionsToDayModalLabelAndTextField(
                      "Session title",
                      "Ex. Registration",
                      true,
                      false
                  )}
                  <Grid container spacing={1}>
                    <Grid item xs sm>
                      {this.addSessionsToDayDrawerLabelAndEditor(
                          "Session description"
                      )}
                    </Grid>
                  </Grid>
                  <Grid
                      container
                      spacing={2}
                      className={
                        classes.createEventAgendaAddSessionsToDayModalWrapper
                      }
                  >
                    <Grid item xs={6} sm={3}>
                    <span
                        className={
                          classes.createEventAgendaAddSessionToDayModalTextInputLabel
                        }
                    >
                      {"Start Time"} {this.mandatoryFieldTag("Start Time")}
                    </span>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                    <span
                        className={
                          classes.createEventAgendaAddSessionToDayModalTextInputLabel
                        }
                    >
                      {"End Time"} {this.mandatoryFieldTag("End Time")}
                    </span>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={3}>
                      {this.addSessionsToDayModalTimeField("Start Time")}
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      {this.addSessionsToDayModalTimeField("End Time")}
                    </Grid>
                  </Grid>
                  {this.addSessionsToDayModalLabelAndTextField(
                      "Location (optional)",
                      "Ex. Room 202",
                      false,
                      false
                  )}
                  <Grid container spacing={1}>
                    <Grid item xs sm>
                    <span
                        className={
                          classes.createEventAgendaAddSessionToDayModalTextInputLabel
                        }
                    >
                      {"Add Speakers (optional)"}
                    </span>
                      <IconButton
                          className={
                            classes.createEventAgendaAddSessionToDayModalIcon
                          }
                          onClick={() => this.addSessionDaysModalAddSpeaker(day)}
                      >
                        <AddCircle height={24} width={24} />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs sm>
                      {this.addSessionDaysModalSpeakerTextField(
                          day,
                          "Speaker's Name*",
                          false,
                          false,
                          0
                      )}
                      {this.addSessionDaysModalSpeakerTextField(
                          day,
                          "Topic*",
                          true,
                          false,
                          0
                      )}
                    </Grid>
                  </Grid>
                  {this.addSessionDaysModalSpeakerTextField(
                      day,
                      "Speaker's LinkedIn profile URL (optional)",
                      false,
                      true,
                      0
                  )}
                  {this.addSessionDaysModalSpeakerTextField(
                      day,
                      "Speaker's website URL (optional)",
                      false,
                      true,
                      0
                  )}
                  {speakerTextFields}
                </div>
              </div>
              <div
                  className={classes.addUpdateModalButtonWrapper}
                  id="eventCommonModal"
              >
                <Button
                    className={classes.createEventFAQCancelFAQButton}
                    onClick={() => this.toggleSessionToDayModal(day)}
                >
                  {Text.Cancel}
                </Button>
                <Button
                    className={
                      classes.createEventAgendaAddSessionToDayModalSaveSession
                    }
                    onClick={() => this.saveSession(day)}
                    variant={"contained"}
                    disabled={
                        !(
                            currentSession.sessionTitle &&
                            currentSession.sessionDescription &&
                            // currentSession.sessionDescription
                            //   .getCurrentContent()
                            //   .hasText() &&
                            currentSession.sessionStartTime &&
                            currentSession.sessionEndTime &&
                            linkedInErrro &&
                            websiteError
                        ) ||
                        currentSession.sessionSpeakers.some((speaker) => {
                          if (!speaker.speakerName && !speaker.speakerTopic) {
                            return false;
                          } else if (speaker.speakerName && !speaker.speakerTopic) {
                            return true;
                          } else if (!speaker.speakerName && speaker.speakerTopic) {
                            return true;
                          } else {
                            return false;
                          }
                        })
                    }
                    style={{
                      backgroundColor:
                          !(
                              currentSession.sessionTitle &&
                              currentSession.sessionDescription &&
                              // currentSession.sessionDescription
                              //   .getCurrentContent()
                              //   .hasText() &&
                              currentSession.sessionStartTime &&
                              currentSession.sessionEndTime &&
                              linkedInErrro &&
                              websiteError
                          ) ||
                          currentSession.sessionSpeakers.some((speaker) => {
                            if (!speaker.speakerName && !speaker.speakerTopic) {
                              return false;
                            } else if (speaker.speakerName && !speaker.speakerTopic) {
                              return true;
                            } else if (!speaker.speakerName && speaker.speakerTopic) {
                              return true;
                            } else {
                              return false;
                            }
                          })
                              ? "rgba(163, 56, 164,.4)"
                              : "rgb(163, 56, 164)",
                      color: "white",
                    }}
                >
                  {Text.Save}
                </Button>
              </div>
            </div>
          </div>
        </Modal>
    );
  };
  daysAgenda = () => {
    const { classes } = this.props;
    const { daysSelected, daysToShow } = this.state;
    let elements = [];
 

    daysToShow.forEach((day, index) => {
      if (daysToShow[index] && daysToShow[index].sessions !== null && daysToShow[index].sessions !== undefined) {

        daysToShow[index].sessions.sort((a, b) => {
          const dateA = a.sessionStartTime.toDate(); // Convert Moment to JavaScript Date
          const dateB = b.sessionStartTime.toDate(); // Convert Moment to JavaScript Date

          if (dateA < dateB) {
            return -1;
          }
          if (dateA > dateB) {
            return 1;
          }
          return 0;
        });
      }
    });

    daysToShow.forEach((day, index) => {
      elements.push(
          <React.Fragment key={day.date}>
            <Box
                style={this.createEventAgendaDateSession(day)}
                sx={{
                  "@media screen and (max-width: 450px)": {
                    marginLeft: "0px !important",
                  },
                }}
            >
              <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
              >
                <span style={{ width: "70%" }}>{day.date}</span>

                <div style={{ display: "flex" }}>
                  <IconButton
                      className={classes.createEventAgendaDeleteExpandSessionButton}
                      onClick={() => this.setSessionDayDelete(true, index, "day")}
                  >
                    <Delete />
                  </IconButton>

                  <IconButton
                      className={classes.createEventAgendaDeleteExpandSessionButton}
                      style={{
                        transform: day.expanded ? "rotate(180deg)" : "rotate(0deg)",
                      }}
                  >
                    <ExpandMore
                        onClick={() => this.expandOrCollapseDaySession(day)}
                    />
                  </IconButton>
                </div>
              </div>
              <Collapse
                  in={day.expanded}
                  style={{ overflow: "inherit" }}
                  unmountOnExit={true}
                  timeout={0}
              >
                <div>
                  {day.sessions.map((session, i) => {
                    return (
                        <React.Fragment key={i}>
                          <Card
                              className={classes.createEventAgendaSessionTimeForDay}
                          >
                            <div className={classes.createEventAgendaTimeWrapper}>
                              <p
                                  className={
                                    classes.createEventAgendaSessionTimeForDayTimeText
                                  }
                              >
                                {this.getMomentFromTime(session.sessionStartTime) +
                                    " - " +
                                    this.getMomentFromTime(session.sessionEndTime)}
                              </p>
                            </div>
                            <div
                                className={
                                  classes.createEventAgendaSessionVerticalLineDivider
                                }
                            >
                              <p
                                  className={
                                    classes.createEventAgendaSessionTimeForDaySessionText
                                  }
                              >
                                {session.sessionTitle}
                              </p>
                            </div>

                            <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  alignSelf: "center",
                                }}
                            >
                              <IconButton
                                  onClick={() =>
                                      this.editSessionFromDay(day, session)
                                  }
                              >
                                <Create />
                              </IconButton>
                              <IconButton
                                  onClick={() =>
                                      this.setSessionDayDelete(true, { day, session })
                                  }
                              >
                                <Delete />
                              </IconButton>
                            </div>
                          </Card>
                        </React.Fragment>
                    );
                  })}
                </div>
              </Collapse>
            </Box>
            <div>
              <Button
                  className={classes.createEventAgendaAddSessionButton}
                  onClick={(e) => {
                    this.toggleSessionToDayModal(day);
                  }}
              >
                {Text.AddSession}
              </Button>
              {this.addSessionsToDayModal(day)}
            </div>
          </React.Fragment>
      );
    });
    return (
        <div className={classes.createEventAgendaDateSessionsWrapper}>
          {elements}
        </div>
    );
  };

  getAllAgendaModalDates = () => {
    const { classes } = this.props;
    const { daysSelected } = this.state;
    let rightElements = [];

    daysSelected.forEach((day, index) => {
      const element = (
          <React.Fragment key={index}>
            <p
                className={classes.createEventAgendaModalDays}
                style={this.daySelectedModalStyles(day)}
                onClick={() => this.selectDay(day)}
            >
              {day.date}
              {day.selected ? (
                  <span className={classes.createEventAgendaModalCircle}></span>
              ) : (
                  <span
                      className={classes.createEventAgendaModalUnselectedCircle}
                  ></span>
              )}
            </p>
          </React.Fragment>
      );

      rightElements.push(element);
    });
    return (
        <div className={classes.createEventAgendaModalDatesWrapper}>
          <div className={classes.createEventAgendaModalRightColumnDays}>
            {rightElements}
          </div>
        </div>
    );
  };

  displayAgenda = () => {
    const { classes } = this.props;
    const { daysToShow, daysSelected } = this.state;
    const isAnyDateSelected = !daysSelected.filter((day) => day.selected)
        .length;
    const { Agenda } = Events;
    return (
        <div style={{}}>
        <span
            style={{
              fontSize: "18px",
              fontWeight: "550",
            }}
        >
          {Agenda.startPage}
        </span>

          {this.getAllAgendaModalDates()}
          <div
              style={{
                display: "flex",
                justifyContent: "end",
              }}
          >
            <Button
                className={classes.createEventAgendaAddDaysButton}
                onClick={() => {
                  this.setState({ isDateAdded: true });
                  this.showSelectedDays();
                }}
                disabled={isAnyDateSelected}
                variant={"contained"}
                style={{
                  padding: "8px 40px 8px 40px",
                  margin: "0px 0px 10px 0px",
                  backgroundColor: isAnyDateSelected
                      ? "rgba(163, 56, 164,.4)"
                      : "rgb(163, 56, 164)",
                  color: "white",
                }}
            >
              {Text.Save}
            </Button>
          </div>
        </div>
    );
  };

  setSessionDayDelete = (openModal, targetItem, targetType) => {
    if (openModal) {
      this.setState({
        deleteSessionModal: {
          show: true,
          target: targetItem,
          type: targetType,
        },
      });
    } else if (!openModal) {
      this.setState({
        deleteSessionModal: { show: false, target: "", type: "" },
      });
    }
  };

  sessionDeleteModal = () => {
    const { classes } = this.props;
    const { show, target, type } = this.state.deleteSessionModal;
    const agendaDeleteLabels = Events.Agenda.deleteAgenda;
    return (
        <Modal open={show} onClose={() => this.setSessionDayDelete(false)}>
          <Box className={classes.createEvenFAQDeleteFAQModal}>
            <div className={classes.createEventAgendaImgWrapper}>
              <img
                  src={agendaDeleteLabels.img}
                  alt="Delete Icon"
                  style={{ width: "30px" }}
              />
            </div>
            {type === "day" ? (
                <p style={{ textAlign: "center" }}>{agendaDeleteLabels.day}</p>
            ) : (
                <p>{agendaDeleteLabels.session}</p>
            )}
            <div>
              <Button
                  className={classes.createEventDeleteFAQCancel}
                  onClick={() => this.setSessionDayDelete(false)}
                  style={{ color: "blue" }}
              >
                {Text.Cancel}
              </Button>

              <Button
                  className={classes.createEventDeleteFAQCancel}
                  onClick={() =>
                      type === "day"
                          ? this.removeSessionDay(target)
                          : this.deleteSessionFromDay(target)
                  }
                  style={{ color: "red" }}
              >
                {Text.Delete}
              </Button>
            </div>
          </Box>
        </Modal>
    );
  };

  render() {
    const { classes } = this.props;
    return (
        <React.Fragment>
          <ContentGrid
              content={
                <Grid className={classes.createEventAgendaGrid}>
                  <EventHeader title={"Agenda"}>
                    {this.state.isDateAdded && (
                        <div
                            className={
                              classes.createEventAgendaAddSessionDaysButtonWrapper
                            }
                        >
                          <Button
                              className={classes.createEventAgendaAddSessionDaysButton}
                              onClick={() => {
                                this.toggleSessionDaysModal();
                              }}
                          >
                      <span
                          className={
                            classes.createEventAgendaAddSessionDaysButtonText
                          }
                      >
                        {Text.AddSessionDays}
                      </span>
                            <AddIcon
                                className={classes.createEventAgendaPlusButtonToPlus}
                            />
                          </Button>
                        </div>
                    )}
                  </EventHeader>
                  <div className={classes.createEventAgendaWrapper}>
                    <div
                        className={classes.createEventFAQFaqsAndCircleLinesWrapper}
                    >
                      {this.state.isDateAdded && this.verticalLineWithCircles()}
                      {this.state.isDateAdded ? (
                          <div
                              style={{
                                width: "100%",
                                justifyContent: "center",
                              }}
                          >
                            <div className={classes.createEventAgendaFormWrapper}>
                              {this.daysAgenda()}
                            </div>
                          </div>
                      ) : (
                          <div
                              className={classes.createEventAgendaDisplayAgendaWrapper}
                          >
                            {this.displayAgenda()}
                          </div>
                      )}
                    </div>
                    <> {this.addSessionDaysModal()}</>
                    <> {this.sessionDeleteModal()} </>
                    <> {this.progressLoader()}</>
                    <div className={classes.createEventFAQButtonWrapper}>
                      <Button
                          className={classes.createEventFAQSaveAndContinueButton}
                          variant={"contained"}
                          onClick={() => this.props.history.push("Ads")}
                      >
                        {Text.SaveAndContinue}
                      </Button>
                    </div>
                  </div>
                </Grid>
              }
          />
        </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  const { eventUserEvents } = state;
  return {
    eventStartDateTime: eventUserEvents.eventsAbout.StartDateTime,
    eventEndDateTime: eventUserEvents.eventsAbout.EndDateTime,
    eventId: eventUserEvents.eventId,
    agendaSessions: eventUserEvents.agendaSessions,
    timezone: eventUserEvents.eventsAbout.TimeZone,
  };
};

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators(
      {
        addAgenda,
        deleteAgenda,
        updateAgenda,
        addAgendaSpeaker,
        updateAgendaSpeaker,
        deleteAgendaSpeaker,
      },
      dispatch
  );
};

export default connect(
    mapStateToProps,
    matchDispatchToProps
)(withStyles(styles)(CreateEventAgenda));

