import React from 'react'
import "./faq.css"
import { useState } from 'react';
import { AiOutlinePlus, AiOutlineClose } from "react-icons/ai";
import Fade from 'react-reveal/Fade';

const Faq = ({ context }) => {
    const [activeIndex, setActiveIndex] = useState(null);

    const handleClick = (index) => {
      setActiveIndex(index === activeIndex ? null : index);
    };

    const signUpQuestions = [
        {
          question: "Can I create additional admin accounts for other people on my team?",
          answer:
            "UBMe Events does not allow you to add multiple users to the same account. <br><br>" +
            "Please make sure to only share your account’s username and password with members of your team since they will all have access to the Stripe account on file, per event.",
        },
        {
          question: "How do I add my business name to my account?",
          answer:
            "During the sign-up process, you will be asked to provide a name for your account. This is the name that will be displayed when people view your events + account on UBMe. You can choose to use your first and last name or your business name.<br><br>" +
              "<i>**Currently the ‘name section’ only allows for 1 space within the name that is being entered. This has been noted and we are addressing this with an update that will be available soon.</i>",
        },
        {
          question: "How do I add my business logo as my profile image on my account?",
          answer:
            "When you are logged into your account on <a href='https://www.ubmeevents.com' target='_blank'>www.UBMeEvents.com</a>, you will notice that the first initial of the name you entered during the setup process appears in the upper right-hand corner of the screen.<br><br>" +
              "When you hover over the letter, the drop-down menu appears and you will see 'Account' as one of the selections. By clicking ‘Account,’ you will be taken to your account settings and from here are able to change the profile and header images.",
        },
        {
            question: "Will I be notified each time a ticket is purchased?",
            answer: "Yes you will! UBMe sends out an automatic email to your registered account’s email that details the order #, event and attendee who purchased the ticket."
        },
        {
            question: "Can I change the email that gets notified when a ticket order goes through?",
            answer: "Yes! When you are logged into your account, please go to the top right circle with your first initial and go to ‘Account’. Here is where you can change the email to the desired email for notifications."
        },
        {
            question: "Is there a limit on the number of free/paid events I can set up per year?",
            answer: "No, there is no limit on how many free or paid events you can set up with UBMe."
        },
      ];

    const AddingEventDetails = [
        {
            question: "What should I do when I am uploading an image and an error message appears?",
            answer:
                "If you are unable to upload a cover image for your event, it is most likely due to the image size being too large. The easiest way to fix this issue is to open the image on your computer or your mobile device and take a screenshot of the image. The screenshot will be a smaller file size and you will be able to use it for your event’s cover image.<br><br>" +
                "For assistance, please reach out to our team via email at <a href='mailto:Support@UBMe.com'>Support@UBMe.com</a> and we will be able to help resize the image for you.",
        },
        {
            question: "How do I post an online event without adding a location/venue?",
            answer:
                "In order to post an event on UBMe, you must add an address for the event. If the event is a virtual event, please use the city/state where the event is being hosted as a work-around.<br><br>" +
                "<i>**This is required because UBMe’s technology is location-based and users find events in their local area based on the location set by the organizer.</i>",
        },
        {
            question: "Do I need to fill in all of the information in the 'Event Details' section to post my event?",
            answer:
                "When you click the 'Create Event' button, you will notice there is a 3 step process to setting up your event. First, you add the event details, starting with the 'About' section. This is the only ‘Details’ section that is mandatory.<br><br>" +
                "<i><u>If you do not have FAQs, an agenda, sponsors or vendors to add to your event, then you can skip those sections by clicking through to the next sections via the ‘Save and Continue’ button or left-hand side navigation.</u></i>",
        },
        {
            question: "Can I create private and public events?",
            answer: "Yes! You have the ability to choose whether or not your event is private or public (if it is public, it will appear on UBMeEvents.com and the UBMe App) when you are publishing the event.",
        },
        {
            question: "Can I duplicate an event when it recurs monthly or is similar to my other events?",
            answer: "Yes, you can duplicate any event! Under ‘Manage Events,’ go to the 3 dots to the right-hand side of the event in which you want to duplicate. Click ‘Clone’ and your duplicated event will occur! Make sure to change the time to the correct start and end date as well as the ticket selling period, under ‘Tickets’ before publishing.",
        },
        {
            question: "Can I add vendors/sponsors after the event is live?",
            answer: "Yes! You can edit your event at any point after it is live, including the agenda, vendors and sponsors.",
        },
        {
            question: "Is there a way for food vendors to link directly to their online ordering menu?",
            answer:
                "Absolutely! Every vendor has the option for a web link to be added for viewers to see on the registration page.<br><br>" +
                "<i>UBMe also powers <a href='https://www.ubmefood.com' target='_blank'>www.UBMeFood.com</a>, an online ordering platform that is great for food vendors to take advantage of at events!</i>",
        },
        {
            question: "What happens if my event date or time changes?",
            answer: "If you have to change your event date/time, no worries! You can edit the date and time at any point after publishing your event and your attendees will automatically be notified about the changes as well.",
        },
        {
            question: "Can I edit my agenda if a speaker or session changes/gets canceled?",
            answer: "Yes! You can absolutely edit your agenda sessions and speakers at any point. Go to the ‘Agenda’ page and delete or edit the session you would like to change.",
        },
        {
            question: "Can I add images or videos to my registration page?",
            answer: "You can! Under ‘About’ you can add images and web links/video links to the ‘Description’ section. Here is where you can also have fun ‘designing’ the layout by changing up the font, text size, positioning and more!",
        },
        {
            question: "Is there a way to remove the map from the registration?",
            answer: "No, there is not a way to remove the map, as it coincides with our location-based technology to help attendees get a better overall picture of the event itself, through timing, location and your description!",
        },
        {
            question: "Am I able to customize the color scheme of the Registration Pages?",
            answer:
                "At the moment you are not able to change the color scheme of the registration page, however you are able to customize the ‘Description’ section and your event’s Cover Photo to make it unique to your brand and business!!<br><br>" +
                "<i>**We are working on adding color-customization in a future update</i>",
        },
    ];

    const CreatingEventTickets = [
        {
            question: "How do I confirm tickets when my attendees arrive at my event?",
            answer:
                "UBMe Events utilizes the UBMe Organizer app to confirm attendees manually or automatically during the check-in process of your event. The UBMe organizer app is free to download and can be found by going into the app store (on any device) and searching for \"UBMe Organizer\". You can also download the app by selecting one of the links below:<br><br>" +
                "<i>The app is available for download on all <a href='https://play.google.com/store/apps/details?id=com.UBMe.organizer' target='_blank'>Android</a> and <a href='https://apps.apple.com/us/app/ubme-event-organizer/id6446975551' target='_blank'>Apple</a> devices.</i>"
        },
        {
            question: "What do I do if the attendee did not purchase a ticket?",
            answer:
                "When editing an event after it is live, you have access to a ‘Flyers’ tab. When you click it, you have access to numerous flyers that all have the QR code that directs users to the registration page. We register having one of these printed out in case someone arrives without a ticket.<br><br>" +
                "Upon entering, the attendee without a ticket will be able to scan the QR code and register easily from their phone, and then upon getting their ticket emailed and texted, will be able to be admitted into the event.",
        },
        {
            question: "How do I handle assigned seats on UBMe Events?",
            answer:
                "The way organizers set up assigned seating on UBMe is by creating a digital event map with clearly numbered sections/ seats. From there, they create a ticket for each section/seat on the map.<br><br>" +
                "Ex: If there are 10 sections on your map labeled Section 1 - Section 10, then you will create10 ticket types titled Section 1, Section 2, Section 3, etc. with the correct quantities assigned to each section.",
        },
        {
            question: "What is the difference between the ticketing fee and the processing fee?",
            answer:
                "When you visit <a href='https://www.ubmeevents.com/Pricing' target='_blank'>www.UBMeEvents.com/Pricing</a>, you will notice that we do not charge any fees for free events that take place on our platform.<br><br>" +
                "For paid events there is a 3% + $1.49 ticketing fee (<a href='https://www.ubmeevents.com/ticketfees' target='_blank'>varies by country</a>) paid by the attendee as well as a 2.9% + .30 cents processing fee which is paid to Visa, Discover, Mastercard and AMEX whenever there is a credit/ debit card used for a sale. This fee can be absorbed by the organizer or passed to the customer under the ‘Taxes & Fees’ section.<br><br>" +
                "The ticketing fee is the fee that attendees pay to UBMe and the processing fee gets paid to Stripe for processing the card payment.",
        },
        {
            question: "Can I gather custom information from attendees?",
            answer: "Yes! You are able to add an unlimited number of custom question fields for  attendees to fill out during their checkout/registration for your event. When an attendee places an order, you will be able to access the order form entries by downloading the csv file/attendee list download from the attendees tab.",
        },
        {
            question: "Will all custom fields that I set be mandatory for attendees to fill out while registering?",
            answer:
                "Yes, every field that you add will become mandatory for attendees to fill out during the registration/check-out process.<br><br>" +
                "If you want to make it clear that a field does not need to have an answer, an example of how to phrase the custom field is: ‘Are there any food allergies? Write NA if non-applicable",
        },
        {
            question: "How do I create an event calendar?",
            answer:
                "To create a calendar for recurring events/class, you must go to the “Create Tickets” section and begin creating a ticket. You will see a checkbox with the question, “Is this a recurring event?” After you select the checkbox, you will see additional ticket options with the ability to add time slots and days that your recurring event falls under. Save your recurring ticket and your customers will now see a calendar when they go to register for this ticket type.<br><br>" +
                "To view what time-slots are booked, go to ‘Edit’ the event and click the ‘Bookings’ tab. From here you can see how many spots are filled."
        },
        {
            question: "Can I set up 'Group Discounts'?",
            answer:
                "UBMe provides organizers the ability to create a discount code where attendees can only get the applied amount off  if they purchase the designated number of tickets.<br><br>" +
                "Ex: you can name a discount code <b>\"BUYTHREE\"</b> and the conditions would be \"Get 25% Off when you purchase 3 or more tickets.\" That would be setup in the discount section by setting the \"Minimum number of tickets required\" to 3."
        },
        {
            question: "Can I issue comped tickets to sponsors, family, friends, performers, etc.?",
            answer: "You can absolutely issue comped tickets! When you are editing your event, go to the ‘Attendees’ Tab and ‘Add Attendee +’. Here you will be able to issue the tickets manually and free of charge, at any point leading up to the event."
        },
        {
            question: "Can I scan attendees in and out of a session to document whether or not they stayed the entire time?",
            answer: "Yes, you can! You can scan an attendee’s ticket as often as you’d like and every ‘scan’ will appear on the Excel export or Attendee export for you to review."
        },
        {
            question: "How many ticket types and quantities am I able to set for free when setting up my tickets?",
            answer: "You can create an unlimited number of both free and paid Ticket Types for free with UBMe and set those quantities to your max venue size, with no additional charge."
        },
        {
            question: "What if my event is only recurring monthly? Can I utilize the calendar?",
            answer:
                "For monthly recurring events, we suggest either creating the event and duplicating it 12 times (to get it set for the year) or by creating one event and having 12 ticket types, with each ticket type being a specific date + month.<br><br>" +
                "<i>The calendar is utilized best when it is used for weekly recurring events, such as classes, workshops or seminars.</i>",
        },
        {
            question: "Can I set up ‘class packs’ to entice people to register ahead of time for multiple classes?",
            answer: "Yes, you can! When setting up your tickets, you can select ‘Is this event a recurring event?’ and then choose the quantity included in the package. This will allow attendees to purchase ‘x’ number of classes in advance! They will receive all of their tickets at the same time and appear on your Bookings Calendar in each time-slot.",
        },
        {
            question: "Are tickets non-refundable?",
            answer: "It is up to the discretion of the organizer if they would like to allow refunds to their attendees or not.",
        },
        {
            question: "Can I set up ‘Early-Bird’ pricing or ‘Late Registration’ pricing that have different ticket-selling time-frames?",
            answer:
                "Yes! With UBMe, you can set up an unlimited number of ticket types, including ‘Early Registration’, ‘Regular Registration’, ‘Late Registration’, etc. You are able to set specific ticket selling time-frames for each individual ticket type, so as soon as the ‘Early Registration’ time-frame is over, attendees will be unable to purchase it and instead have to purchase the ‘Regular Registration.’<br><br>" +
                "For assistance with setting up these ticket types, please contact <a href='mailto:Support@UBMe.com'>Support@UBMe.com</a>",
        },
    ];

    const PromotingEvents = [
        {
            question: "Once the event is published, will there be a link that I can share on other platforms?",
            answer: "Yes! After your event is published, you will get a link to your event registration page that you can share on your website, social media or on any print flyers. You will also notice that once you post an event on UBMe, the Email Invitation + Flyer Generator tools become activated and you can utilize them for free event promotion.",
        },
        {
            question: "What are the limits for email invites with UBMe Events? How do I increase the number of email contacts and number of sends for my email invitations?",
            answer: "Everybody has access to our complimentary free tier with 2,000 contacts and 8,000 monthly sends. To get your current limits increased, please reach out to us at <a href='mailto:Support@UBMe.com'>Support@UBMe.com</a>",
        },
        {
            question: "Where can I download a QR code to use for my event?",
            answer: "We do not provide a direct QR code download, however in the event flier creation section there is a QR code available on the automatically generated flier that appears there. You are able to screenshot/ cut and paste the QR Code from there and use it on any other flyers that you might have.",
        },
        {
            question: "Should I add the registration link to my website?",
            answer: "Yes, we always encourage all organizers to add their UBMe Registration Page onto their website. We are always happy to provide assistance with doing so as well, so please reach out to <a href='mailto:Support@UBMe.com'>Support@UBMe.com</a> if you would like help getting it added to your website!",
        },
        {
            question: "Does UBMe manage email invites/registration page updates?",
            answer: "UBMe offers a Monthly Marketing Subscription for businesses, and that includes:  weekly email blasts, email list management + updates, monthly newsletters, registration page maintenance, adding of paid events, social media promotion, customized content for business use on social media and adding registration links onto your website",
        },
        {
            question: "Can I create ‘Event Groupings’ with my UBMe account?",
            answer:
                "As of now, you cannot group your events, however all events by an organizer appear at the bottom of each registration page that viewers can browse through.<br><br>" +
                "<i>**We currently are working on an Event Grouping solution that will be launched in a future update.</i>"
        },
        {
            question: "Where will my public and private events get featured?",
            answer:
                "As the organizer, you have the option of setting your event up as a ‘Public’ or ‘Private’ event. When you make your event public, it becomes visible on <a href='https://www.ubmeevents.com'>www.UBMeEvents.com</a>, the UBMe App and can be featured on UBMe’s social media.<br><br>" +
                "When you choose to make your event ‘Private’, you are the only one who can make the event ‘visible’ to people, by sharing the registration page link via email, text or on social media. Private events DO NOT appear on <a href='https://www.ubmeevents.com'>www.UBMeEvents.com</a>, the UBMe App or UBMe’s social media.",
        },
    ];

    const ManagingYourAccount = [
        {
            question: "When do event organizers receive funds from ticket transactions?",
            answer: "Since we connect with Stripe for your ticketing transactions, you have access to your event funds right away. Whenever a new attendee purchases a ticket, the funds from the purchase go directly into your Stripe account. You are able to withdraw the funds right away, or by default Stripe will transfer the collected funds to your bank account every 24 hours.",
        },
        {
            question: "How do I access information about my payouts?",
            answer: "To see more detailed information about your incoming transactions and outgoing payouts, please go to your Stripe account and view your dashboard by visiting: <a href='https://dashboard.stripe.com' target='_blank'>www.dashboard.stripe.com</a>",
        },
        {
            question: "How do I cancel an event and refund all of the tickets?",
            answer:
                "Sign-in to your account and hover over the profile image in the upper right hand corner. Select the \"Manage Events'' tab from the drop-down menu and then scroll down to reveal the list of events you have created thus far.<br><br>" +
                "Navigate to the event that you wish to cancel and select the three dots menu located on the far right side of the event’s name. When you open the three dots menu, you will see the option to ‘cancel’.<br><br>" +
                "Once you cancel the event, everyone who purchased a ticket will receive a notification that the event has been canceled and that they will receive a refund for their purchase. The money for the refund is taken from the Stripe account that is connected to that event."
        },
    ];

    const MoreQuestions = [
        {
            question: "Does UBMe Provide services for festivals, expos, conferences and other large events?",
            answer: "Yes! If you are interested in receiving additional support for your event, please visit our sign-up page at <a href='https://www.ubme.com/more' target='_blank'>www.UBMe.com/more</a> and you will receive a quote within 24 hours.",
        },
        {
            question: "Does UBMe Events have a referral program?",
            answer: "Yes! If you are interested in becoming an official ambassador for UBMe, you will be able to refer businesses to us in exchange for compensation, then please email <a href='mailto:david@UBMe.com'>david@UBMe.com</a>",
        },
        {
            question: "Why are my attendees required to provide a name, phone number and email in order to purchase tickets for my event?",
            answer: "This profile requirement is to protect both the attendee from fraud and lost tickets, as well as protect our event organizers from bank chargebacks.",
        },
        {
            question: "Can you create an event without connecting a Stripe Account?",
            answer: "Stripe is required for all paid events, because they allow attendees to pay via credit or debit card. The only time you are not required to connect a Stripe account is when you are setting up a free event.",
        },
        {
            question: "Can I send email invitations from your platform?",
            answer:
                "Absolutely! Once you create and publish your event, you will gain access under ‘Promote Event’ to the ‘Email Invitation tab’. From here you can either utilize our automated template or design your own email invite. Upload any existing email lists, schedule or send immediately and your email marketing has begun!<br><br>" +
                "Do you need more contacts or monthly sends? We are happy to cater to your email marketing needs, so let us know what quantities you are in need of and we’ll accommodate that for you, complimentary.",
        },
        {
            question: "Am I able to import contacts/email lists?",
            answer: "Yes! Once you publish an event, you have access while ‘editing’ your event to the ‘Email Invitation’ tab under ‘Promote Event’. Here is where you can upload all of your email contacts through a csv or excel file. Let us know if you need your contacts or monthly sends increased, we are happy to accommodate!",
        },
        {
            question: "Does UBMe integrate with other software?",
            answer:
                "Yes! We currently integrate with over 6,500+ software solutions, including Salesforce, Hubspot, Mailchimp and Quickbooks, to name a few. <br><br>" +
                "<i>**Looking to begin your integration? Contact our team at <a href='mailto:Support@UBMe.com'>Support@UBMe.com</a> to get started!</i>"
        },
    ];

    const Pricing = [
        {
            question: "Are UBMe Events still free overall if I only publish free events?",
            answer:
                "Yes! Free events are always free to post and the only associated fees with UBMe Events are attendee fees, which are on ticketed events only.",
        },
        {
            question: "When do I receive my funds?",
            answer:
                "You’ll receive your funds daily via Stripe, our processing partner! Please allow up to 3-5 business days for your first transaction to deposit into your account and log into your Stripe account at Dashboard.Stripe.com for more details.",
        },
        {
            question: "Can I create multiple ticket types for an event?",
            answer:
                "Absolutely! Our system allows you to create as many ticket types as needed for your event.",
        },
        {
            question: "For attendees who want to use the app for additional engagement, is that free as well?",
            answer:
                "Yes, the UBMe app is 100% free for all attendees and organizers to use!",
        },
        {
            question: "How do you handle large events?",
            answer:
                "We are well equipped to handle large events due to our vast array of features that no other event management platform currently offers. We have the ability to set agendas for events, provide speaker/performer information, display maps for parking/the venue, capacity regulations and much more! If you would like to work with us directly to help plan a larger event please contact David @ David@UBMe.com.",
        },
        {
            question: "Can you help us manage capacity?",
            answer:
                "Absolutely! We allow organizers to set a finite number of tickets that can be sold, and our system will keep track of how many remain. Your landing page will notify customers when your event ‘sells out’ as well.",
        },
        {
            question: "Can I offer discounts?",
            answer:
                "Yes! Our system allows you to add coupon codes during the Ticket creation process and you can create as many tickets and promotions as you’d like.",
        },
        {
            question: "How do we educate attendees on using the UBMe app?",
            answer:
                "When an attendee receives a ticket for your event they also receive instructions on how to use the UBMe app when they arrive, so education is built-in. Everyone with a ticket should see how they can use the app at your event for a more enjoyable experience.",
        },
        {
            question: "Will we be able to promote vendors/sponsors for our event?",
            answer:
                "Absolutely! There are two separate sections for vendors and sponsors within the event creation process and you can include their profile, website, contact information, description and more.",
        },
    ];

    // Use the appropriate set of questions based on the context
    const questions = getQuestionsByContext(context);

    function getQuestionsByContext(context) {
        switch (context) {
            case "FrequentlyAskedQuestionSignup":
                return signUpQuestions;
            case "FrequentlyAskedQuestionAddingEventDetails":
                return AddingEventDetails;
            case "FrequentlyAskedQuestionCreatingEventTickets":
                return CreatingEventTickets;
            case "FrequentlyAskedQuestionPromotingEvents":
                return PromotingEvents;
            case "FrequentlyAskedQuestionManagingYourAccount":
                return ManagingYourAccount;
            case "FrequentlyAskedQuestionMoreQuestions":
                return MoreQuestions;
            case "Pricing":
                return  Pricing;
            default:
                return [];
        }
    }

  return (
    <div className="faq">
    {questions.map((q, index) => (
      <div
        key={index}
        className={`faq-item ${index === activeIndex ? "active" : ""}`}
        onClick={() => handleClick(index)}
      >
        <div className="faq-question">
            <h5 style={{marginRight:10}}>{q.question}</h5>
            {index === activeIndex ?
                <AiOutlineClose size={20}  style={{ flexShrink: 0 }} /> :
                <AiOutlinePlus size={20} style={{ flexShrink: 0 }} />}
        </div>
        <Fade big>
            {/* Render the answer using dangerouslySetInnerHTML */}
            <div
                className="faq-answer"
                dangerouslySetInnerHTML={{ __html: q.answer }}
            ></div>
        </Fade>
      </div>
    ))}
  </div>
  )
}

export default Faq;
