import A from "images/BusinessProfile/0.jpg"
import B from "images/BusinessProfile/1.jpg"
import C from "images/BusinessProfile/2.jpg"
import D from "images/BusinessProfile/4.jpg"


export  const UserCoverPhoto = (userType) => {
    switch (userType) {
        case 1:
          return A;
  
        case 2:
          return B;
  
        case 3:
          return C;
  
        case 4:
          return D;

        default:
          return A;
      }

}