import React, { useEffect, useState } from "react";
import "./SignUp.css";
import { Link } from "react-router-dom";
import { FaUserAlt, FaUnlock } from "react-icons/fa";
import { FormControl } from "react-bootstrap";
import { bindActionCreators } from "redux";
import { history } from "store";
import { connect } from "react-redux";
import { GetUserProfileDetail, loginAction, registerAction,saveUserData } from "Actions";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { homeRoute, routeName } from "Service/env";
import { audioFunc } from "Service/helper";
import * as constants from "Constants";
import "../../Components_UBMeEvents/modal/Modal.css";
import { MdAlternateEmail } from "react-icons/md";
import ReCAPTCHA from "react-google-recaptcha";
import axiosI from "Service/service";
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  validateEmail,
  ValidateName,
  validatePassword,
  validateUserName,
} from "Helper/Validations";
import { getUnreadCount } from "Actions/messagesAction";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

const SignUp = (props) => {
  const [credentials, setCredential] = useState({
    Latitude: props.latlong.lat,
    Longitude: props.latlong.lng,
    FirstName: "",
    LastName: "",
    Email: "",
    UserName: "",
    Password: "",
    cpassword: "",
  });
  const [isChecked, setIsChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };

  const [formErrors, setFormErrors] = useState({
    FirstNameError: "",
    LastNameError: "",
    UserNameError: "",
    EmailError: "",
    PasswordError: "",
  });

  const [lblErrorMsg, setLblErrorMsg] = useState("");
  const [suggestUserName, setSuggestUserName] = useState([]);
  const [emailError, setEmailError] = useState("");
  const [userNameError, setUserNameError] = useState("");
  const clearErrors = () => {};
  const handleChange = (e) => {
    const { name, value } = e.target;

    let formDataErrors = { ...formErrors };

    switch (name) {
      case "FirstName":
        formDataErrors.FirstNameError = ValidateName(value);
        break;
      case "LastName":
        formDataErrors.LastNameError = ValidateName(value);
        break;
      case "UserName":
        formDataErrors.UserNameError = validateUserName(value);

        break;
      case "Password":
        formDataErrors.PasswordError = validatePassword(value);

        break;
      case "Email":
        formDataErrors.EmailError = validateEmail(value);

        break;

      default:
        break;
    }

    setFormErrors(formDataErrors);
    setCredential({ ...credentials, [name]: value });
  };

  useEffect(()=>{
    if (
      !userNameError &&
      !emailError &&
      !formErrors.EmailError &&
      !formErrors.FirstNameError &&
      !formErrors.LastNameError &&
      !formErrors.UserNameError &&
      !formErrors.PasswordError &&
      isChecked
    ){
      setLblErrorMsg("");
      setEmailError("");
      setUserNameError("");
    }
  },[formErrors,emailError,userNameError, isChecked])


  useEffect(() => {
    let apiRequestData = {
      EmailId: credentials.Email,
      Username: credentials.UserName,
      Name: credentials.FirstName,
    };

    if (credentials.Email) {
      axiosI
        .get(`${constants.API.VerifyAccountEmail}/${apiRequestData.EmailId}`)
        .then(async ({ data }) => {
          if (data.results.Message === "Email exist") {
            setEmailError("Email already exists");
          } else {
            setEmailError("");
          }
        })
        .catch((err) => {});
    }

    if (credentials.Email && credentials.FirstName && credentials.UserName) {
      axiosI
        .post(constants.API.VerifyAccountUserName, apiRequestData)
        .then(async ({ data }) => {
          if (data.results.Message === "username exist") {
            setUserNameError("Username already exists");
            setSuggestUserName(data.results.Suggestions);
          } else {
            setUserNameError("");
          }
        })
        .catch((err) => {});
    }
    if (credentials.UserName === "") {
      setSuggestUserName([]);
    }
  }, [credentials.UserName, credentials.Email]);

  const handleSubmit = () => {
    if (
      credentials.Email &&
      credentials.Password &&
      credentials.UserName &&
      credentials.FirstName &&
      credentials.LastName &&
      credentials.cpassword
    ) {
      if (credentials.Password.length >= 6) {
        if (credentials.Password === credentials.cpassword) {
          // console.log("credentials", credentials);
          setLblErrorMsg("");

          if (
            !userNameError &&
            !emailError &&
            !lblErrorMsg &&
            !formErrors.EmailError &&
            !formErrors.FirstNameError &&
            !formErrors.LastNameError &&
            !formErrors.UserNameError &&
            !formErrors.PasswordError
          ) {


            if(isChecked){
              setLoading(true)
              const { LastName, cpassword, ...newCredentials } = credentials;
              let credentialsData = {
                ...newCredentials,
                UserName:
                  credentials.UserName[0] === "@"
                  ?   credentials.UserName.substring(1)
                    : credentials.UserName,
                FirstName: credentials.FirstName + " " + credentials.LastName,
                Bio:"I love UBMe"
              };

              props
                .registerAction(credentialsData)
                .then((data) => {
                  if(data.results.ResponseCode){
                    // setLoading(false)

                    if (
                      props.userHistory !== null &&
                      props.userHistory !== undefined
                    ) {
                      if(props.userHistory !== 'loggedin' && props.userHistory !== "messages" && !localStorage.getItem("organizerLandingPageButtonClick")){
                        history.replace(`${routeName}/${props.userHistory}`);
                      }
                    } else if (!localStorage.getItem("pendingFollowUnfollowOrganizerCall") && !localStorage.getItem("pendingEventsButtonClick") === "messages") {
                      history.replace(`/${homeRoute}`);
                    }
                  }
                  props.closeModal();
                })
                .catch((err) => console.log(err));
            }





          }else{
            setLblErrorMsg(constants.Message.validDetails);
          }
        } else {
          setLblErrorMsg(constants.Message.passwordNotMatch);
        }
      } else {
        setLblErrorMsg(constants.Message.passwordMessage);
      }
    } else {
      setLblErrorMsg(constants.Message.AllFieldsAreRequiredMsg);
    }
  };

  const onForgotPassword = () => {
    history.push(`${routeName}/ForgotPassword`);
  };

  return (
    <div
      style={{ marginLeft: 30, marginRight: 30, marginTop: 10 }}
      className="signupp_container"
    >
      {
        loading &&  <div className="overlay_loading">
        <CircularProgress/>
        </div>
      }

      <h4
        style={{
          marginTop: 10,
          marginBottom: 16,
          color: "#000000",
          fontWeight: "bold",
          paddingTop: 10,
        }}
      >
        {constants.LoginSignUpText.signupToDashboard}
      </h4>

      <div style={{ marginBottom: 10 }} className="error">
        {lblErrorMsg !== "" ? <span>{lblErrorMsg}</span> : <span />}
      </div>
      <div class="row_input">
        <span className="form_icon">
          <FaUserAlt color="lightgray" />
        </span>
        <input
          placeholder={constants.LoginSignUpText.firstname}
          name="FirstName"
          value={credentials.FirstName}
          onChange={handleChange}
          type="text"
          autoComplete="off"
        />
        {formErrors.FirstNameError !== "" ? (
          <div className="error">
            <span>{formErrors.FirstNameError}</span>
          </div>
        ) : null}
      </div>
      <div class="row_input">
        <span className="form_icon">
          <FaUserAlt color="lightgray" />
        </span>
        <input
          placeholder={constants.LoginSignUpText.lastname}
          name="LastName"
          value={credentials.LastName}
          onChange={handleChange}
          type="text"
          autoComplete="off"
        />
        {formErrors.LastNameError !== "" ? (
          <div className="error">
            <span>{formErrors.LastNameError}</span>
          </div>
        ) : null}
      </div>
      <div class="row_input">
        <span className="form_icon">
          <MdAlternateEmail color="lightgray" />
        </span>
        <input
          placeholder={constants.LoginSignUpText.email}
          onChange={handleChange}
          name={constants.LoginSignUpText.email}
          value={credentials.Email}
          type="email"
        />
        {formErrors.EmailError !== "" ? (
          <div className="error">
            <span>{formErrors.EmailError}</span>
          </div>
        ) : null}
        {emailError !== "" ? (
          <div style={{ marginTop: 20 }} className="error">
            <span>{emailError}</span>
          </div>
        ) : null}
      </div>
      <div class="row_input">
        <span className="form_icon">
          <FaUserAlt color="lightgray" />
        </span>
        <input
          placeholder={constants.LoginSignUpText.username}
          name="UserName"
          value={credentials.UserName}
          onChange={handleChange}
          type="text"
          autoComplete="off"
        />

        {userNameError !== "" && credentials.UserName.length > 3 && (
          <div className="error">
            <span>{userNameError}</span>
          </div>
        )}

        {formErrors.UserNameError && formErrors.UserNameError.length > 0 && (
          <div className="error">
            <span>{formErrors.UserNameError}</span>
          </div>
        )}

        {credentials.UserName.length > 3 && (suggestUserName && suggestUserName.length > 0) && (
          <div
            style={{ marginTop: userNameError !== "" ? 6 : 20 ,width:"100%", flexWrap:'wrap'}}
            className="suggetios"
          >
            {suggestUserName.length > 0 &&
              suggestUserName.map((usernames, index) => (
                <h6
                  key={index}
                  onClick={() => {
                    setCredential({
                      ...credentials,
                      UserName: usernames,
                    });
                    clearErrors();
                  }}
                  style={{
                    marginBottom:8,
                    marginRight: 10,
                    cursor: "pointer",
                    color: "#000000",
                  }}
                >
                  {usernames}
                </h6>
              ))}
          </div>
        )}
      </div>

      <div class="row_input">
        <span className="form_icon">
          <FaUnlock color="lightgray" />
        </span>
        <input
          placeholder={constants.LoginSignUpText.password}
          name="Password"
          value={credentials.Password}
          onChange={handleChange}
          type="password"
          autoComplete="new-password"
        />
        {formErrors.PasswordError !== "" ? (
          <div className="error">
            <span>{formErrors.PasswordError}</span>
          </div>
        ) : null}
      </div>
      <div class="row_input">
        <span className="form_icon">
          <FaUnlock color="lightgray" />
        </span>
        <input
          placeholder={constants.LoginSignUpText.cpassword}
          name="cpassword"
          value={credentials.cpassword}
          onChange={handleChange}
          type="password"
          autoComplete="new-password"
        />
      </div>

      <div style={{ display: "flex", alignItems: "self-start" }}>
        <input type="checkbox" id="topping" name="topping" checked={isChecked} onChange={handleOnChange} />
        <div style={{ marginLeft: 10 }}>
          <p>
            {constants.LoginSignUpText.privacyPolicy}
            <a
              style={{ color: "#ca3dd9" }}
              target="_blank"
              href={`${routeName}/terms`}
            >
              {constants.LoginSignUpText.termsOfservices}
            </a>{" "}
            {constants.LoginSignUpText.termsOfservicesAnd}{" "}
            <a
              style={{ color: "#ca3dd9" }}
              target="_blank"
              href={`${routeName}/privacy`}
            >
              {constants.LoginSignUpText.privacyandPolicy}
            </a>
          </p>
        </div>
      </div>

      <div className="login_cred">
        <a
          onClick={() => handleSubmit()}
          className="login_button"
          href="#!"
          style={{ color: "#fff", fontSize: 15,backgroundColor:
          !userNameError &&
          !emailError &&
          !formErrors.EmailError &&
          !formErrors.FirstNameError &&
          !formErrors.LastNameError &&
          !formErrors.UserNameError &&
          !formErrors.PasswordError &&
          isChecked &&
          credentials.Email &&
          credentials.Password &&
          credentials.UserName &&
          credentials.FirstName &&
          credentials.LastName &&
          credentials.cpassword

          ? "#7B3FE4" : "#c2a4f5", }}
        >
          {constants.LoginSignUpText.signUp}
        </a>
        <h4 className="forgot_password">
          {constants.LoginSignUpText.alreadyregistered}{" "}
          <Link
            onClick={() => props.setIsSignUp(false)}
            style={{ color: "#03ad31", fontSize: 15 }}
            to="#"
          >
            {constants.LoginSignUpText.login}
          </Link>
        </h4>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    favoriteData: state.favoriteReducer.favoriteData,
    cardData: state.favoriteReducer.cardData,
    latlong: state.favoriteReducer.latlong,
    results: state.loginResponse.results,
    isLoggedIn: state.loginResponse.isLoggedIn,
    eventSearchData: state.favoriteReducer.EventSearchData,
  };
};
const matchDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      loginAction,
      saveUserData,
      getUnreadCount,
      registerAction,
      GetUserProfileDetail

    },
    dispatch
  );
};

export default connect(mapStateToProps, matchDispatchToProps)(SignUp);
