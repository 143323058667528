import React, { Component } from "react";
import DateRange from "./dateRange";
import { IconButton, Tooltip, withStyles } from "@material-ui/core";
import { Info } from "@material-ui/icons";
import moment from "moment";

const styles = {
  chartHeader: {
    margin: "20px 0px 0px 20px",
  },
  dataCategory: {
    fontFamily:
      "HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif",
    fontSize: "15px",
    marginBottom:"10px"
  },
  dataValue: {
    fontFamily:
      "HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif",
    fontSize: "24px",
    fontWeight: "bold",
    
  },
  activeTab: {
    backgroundColor: "#4336a0",
    color: "white",
    margin: "-1px 0px 0px 0px",
    height: "102px",
    width: "140px",
    fontFamily: "Ropa Sans",
    cursor: "pointer",
    "@media screen and (max-device-width: 491px)": {
      width: "100%",
    },
  },
  inactiveTab: {
    backgroundColor: "#ffffff",
    color: "purple",
    height: "100px",
    width: "140px",
    fontFamily: "Ropa Sans",
    boxShadow: "0 0 0 1px #ccc",
    cursor: "pointer",
    "@media screen and (max-device-width: 491px)": {
      width: "100%",
    },
  },
  header: {
    display: "flex",
    flexWrap: "wrap",
    gap:"20px"
  },
  dateRange: {
    display: "inline-flex",
    float: "right",
    padding: "16px 16px 0 0",
    textAlign: "center",
  },
  infoIcon: {
    float: "right",
    margin: "-8px 0px 0px 7px",
    color: "inherit",
  },
};

const EventDashboardTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: "16px",
    border: "1px solid #dadde9",
  },
}))(Tooltip);

class ChartHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      deal: 0,
      checkIn: 0,
    };
  }

  handleOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  getElements = () => {
    const { activeGraphData, classes, data, state } = this.props;

    let elements = [];
    data.forEach((element, i) => {
      elements.push(
        <div
          key={i}
          // onClick={() => (data.length > 2 ? this.props.showData(element) : "")}
          onClick={() => (data.length > 1 ? this.props.showData(element) : "")}
          className={
            activeGraphData !== undefined
              ? element.name === activeGraphData.name
                ? classes.activeTab
                : classes.inactiveTab
              : classes[element.styleName]
          }
        >
          <div className={classes.chartHeader}>
            <p className={classes.dataCategory}>{element.name === "Attendees" ? "Ticket Holders" : element.name}</p>
            <p className={classes.dataValue}>{element.data}</p>
          </div>
          <EventDashboardTooltip
            // title={<React.Fragment>{element.name === "Attendees" ? "Total number of ticket holders for your events" : element.name === "Sales" ? "Total number of ticket sales" : "Amount of overall engagement at your events"}</React.Fragment>}
              title={<React.Fragment>{element.name === "Attendees" ? "Total number of ticket holders for your events" : "Amount of overall engagement at your events"}</React.Fragment>}
          >
            <IconButton className={classes.infoIcon}>
              <Info />
            </IconButton>
          </EventDashboardTooltip>
        </div>
      );
    });
    return elements;
  };

  render() {
    const { classes, state } = this.props;
    return (
      <div>
        <div className={classes.header}>{this.getElements()}</div>
        <div className={classes.dateRange}>
          <DateRange
            state={state}
            onStateChange={this.props.handleChangeTime}
            fetchGraphDatabyTime={this.props.GetGraphDataByTime}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ChartHeader);
