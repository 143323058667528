import { FAVORITE,  CARD_DATA,CAT_DATA, LATITUDE_LONG,CITY_ADDRESS,LATITUDE_LONG_SYNC_V2,EVENT_SEARCH_DATA} from "../Actions/actionTypes";
import { cloneDeep } from "lodash";
const initial = {
  favoriteData: [],
  cardData: [],
  categoryData:[],
  latlong: {
    lat: 0,
    lng: 0,
  },
  address:"",
  LatitudeSyncv1:{
    lat: 0,
    lng: 0,
  },
  LatitudeSyncv2:{
    lat: 0,
    lng: 0,
  },
 

};

export default (state = initial, action) => {
 
  switch (action.type) {
   
    case CITY_ADDRESS:
      state.address = action.payload;
      return cloneDeep({ ...state });
      case LATITUDE_LONG_SYNC_V2:
      state.LatitudeSyncv2 = action.payload;
      return cloneDeep({ ...state });
    case LATITUDE_LONG:
      state.latlong = action.payload;
      return cloneDeep({ ...state });
    case FAVORITE:
      state.favoriteData = action.payload;
      return cloneDeep({ ...state });

    case CARD_DATA:
      state.cardData = action.payload;
      return cloneDeep({ ...state });
      case CAT_DATA:
        state.categoryData = action.payload;
        return cloneDeep({ ...state });

    default:
      return state;
  }
};
