import React from "react";
import "./ChatActivity.css";
import { AiFillInfoCircle } from "react-icons/ai";
import ToolTip from "./tools/ToolTip";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import moment from "moment";

function ChatActivity({messageData , checkInsCount}) {
  const timeZone = localStorage.getItem("CurrentTimeZone");
  const data = [
    {
      title: "posts",
      number: "54,049",
    },
    {
      title: "Engagements",
      number: "12,223",
    },
  ];

  const Tabledata = [
    {
    name: moment().format("MM/DD/YYYY"),
    count:0
    },
  
  ]

  // const formattedData = messageData?.map(item => {
  //   const dateTime = moment(`${item.Date} ${item.Hour}`, 'MM/DD/YYYY HH');
  //   const formattedDateTime = dateTime.format(); // Adjust the format as needed
  //   const timeZonedate = moment(formattedDateTime).tz(JSON.parse(timeZone));
  //   return {
  //       // name:item.Hour,
  //       name:moment(timeZonedate).format("h a"),
  //       // date: moment(timeZonedate).format("HH"),
  //       count: item.Count
  //   };
  // });

  const formattedData = messageData
  ?.sort((a, b) => new Date(a.Date) - new Date(b.Date))
  ?.reduce((accumulator, item) => {
    const { Date, Hour, Count } = item;
    const existingEntry = accumulator.find(entry => entry.name === Date);

    if (existingEntry) {
      existingEntry.count += Count;
    } else {
      const dateTime = moment(`${item.Date} ${item.Hour}`, 'MM/DD/YYYY HH');
      const formattedDateTime = dateTime.format();
      const timeZonedate = moment(formattedDateTime).tz(JSON.parse(timeZone));
      accumulator.push({
        name: messageData?.every(entry => entry.Date === Date) ? moment(timeZonedate).format("h a") : Date,
        count: Count
      });
    }

    return accumulator;
  }, []);

  const formattedData2 = checkInsCount
  ?.sort((a, b) => new Date(a.Date) - new Date(b.Date))
  ?.reduce((accumulator, item) => {
    const { Date, Hour, Count } = item;
    const existingEntry = accumulator.find(entry => entry.name === Date);

    if (existingEntry) {
      existingEntry.count += Count;
    } else {
      const dateTime = moment(`${item.Date} ${item.Hour}`, 'MM/DD/YYYY HH');
      const formattedDateTime = dateTime.format();
      const timeZonedate = moment(formattedDateTime).tz(JSON.parse(timeZone));

      accumulator.push({
        name: checkInsCount?.every(entry => entry.Date === Date) ? moment(timeZonedate).format("h a") : Date,
        count: Count
      });
    }

    return accumulator;
  }, []);

  const yAxisFormatter = (value) => parseInt(value, 10);

  const CustomizedAxisTick = ({ x, y, payload }) => {
    if (payload.index === 0 || payload.index === formattedData?.length - 1) {
      return <text x={x} y={y} dy={16} textAnchor="middle">{payload.value}</text>;
    }
    return null;
  };

  const CustomizedAxisTick2 = ({ x, y, payload }) => {
    if (payload.index === 0 || payload.index === formattedData2?.length - 1) {
      return <text x={x} y={y} dy={16} textAnchor="middle">{payload.value}</text>;
    }
    return null;
  };

  let postCount = 0;

  messageData?.forEach(item => {
      postCount += item.Count;
  });

  let engagementCount = 0;
  // let test = []

  checkInsCount?.forEach(item => {
    engagementCount += item.Count;
  });
  // console.log(test);
  // let num = 0;
  // test.forEach(no => num = num+no)
  // console.log(num)
  return (
    <div className="chat-activity">
      <div className="activityHeader">
      
       {ToolTip("Chat Activity", "Breakdown of Live Chat activity that occurred before and during your event",  false)}
      </div>
      {/* <div className="activityCards">
        {data.map((item) => (
            <div className="activityCard">
            <div style={{display:"flex",alignItems:'center'}}>
              <div className="indicator" style={{backgroundColor:item.title==="posts"?"#0072ff":'#ffcd03'}}/>
              <h3>{item.number}</h3>
            </div>
            <p>{item.title}</p>
          </div>
        ))}
      </div> */}
      <div className="messages-like-chart">
        <div className="lineChart">
        <div className="chatActivityLineChartHeading">
          <h3 style={{marginTop:8}}>Posts</h3>
          <h1 style={{marginTop:8}}>{postCount}</h1>
        </div>
          <LineChart
            width={500}
            height={300}
            data={formattedData ? formattedData : Tabledata}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" 
            // tick={<CustomizedAxisTick />
            // }
            />
            <YAxis  dataKey={"count"} tickFormatter={yAxisFormatter}/>
            <Tooltip />
            <Line
              type="monotone"
              dataKey="pv"
              stroke="blue"
              activeDot={{ r: 8 }}
            />
            <Line type="monotone" dataKey="count" stroke="#c277ca" />
            {/* <Line type="monotone" dataKey="uv" stroke="yellow" /> */}
          </LineChart>

        </div>
        <div className="lineChart">
          <div className="chatActivityLineChartHeading">
            <h3 style={{marginTop:8}}>Check-ins</h3>
            <h1 style={{marginTop:8}}>{engagementCount}</h1>
          </div>
          <LineChart
            width={500}
            height={300}
            data={formattedData2 ? formattedData2 : Tabledata}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" 
            // tick={<CustomizedAxisTick2 />}
            />
            <YAxis  dataKey={"count"} tickFormatter={yAxisFormatter}/>
            <Tooltip />
            <Line
              type="monotone"
              dataKey="pv"
              stroke="blue"
              activeDot={{ r: 8 }}
            />
            <Line type="monotone" dataKey="count" stroke="#c277ca" />
            {/* <Line type="monotone" dataKey="uv" stroke="yellow" /> */}
          </LineChart>

        </div>
      </div>
    </div>
  );
}

export default ChatActivity;
