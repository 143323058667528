import React from "react";
import moment from "moment";
import Loader from "../../../Helper/loader/loader";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// import axiosI from "../../../Service/service";
import { withStyles } from "@material-ui/core/styles";
import { ws } from "../../../App";
import Alert from "../../../Helper/alert/alert";
import {
    getGIF,
    deleteMessage,
    getDetailGIF,
    deleteBusinessMessage,
    clearDetailedGif,
} from "../../../Actions/messagesAction";

import "./messages.css";
import WebViewChat from "./WebView/WebViewChat";
import WebViewFooter from "./WebView/WebViewFooter";
import Gif from "./WebView/Gif";
import QuestionChat from "./QuestionChat/questionChat";
import QuestionChatFooter from "./QuestionChat/questionChatFooter";
import AlertComponent from "../../../Helper/ErrorComponent/errorComponent";
import GifComponent from "./WebView/GifComponent";
import { Grid, Modal } from "@material-ui/core";
import Camera from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
import axiosI from "Service/service";
import {audioFunc} from "../../../Service/helper";

const styles = {
    modal: {
        height: 350,
        width: 350,
        marginTop: "250px",
        marginLeft: "250px",
    },
};

class WebView extends React.Component {
    constructor() {
        super();
        this.state = {
            showCamera: false,
            showGif: false,
            gif: [],
            dataToDelete: {},
            showDelete: false,
            deleteCount: 0,
            showModal: false,
            detailGifData: [],
            pressStart: 0,
            audioURL: "",
            selectedGif: "",
            showDetailed: false,
            searchArray: [],
            isSearching: false,
            backgroundColorDiv: [
                "#516077",
                "#9b8f88",
                "#c14b05",
                "#5e3721",
                "#8c824e",
                "#e2e1d9",
                "#f2d702",
                "#51db02",
                "#289186",
                "#5f7baf",
                "#9050c4",
                "#511682",
                "#af1574",
            ],
        };
    }

    componentDidMount() {
        this.props.getGIF("https://api.tenor.co/v1/tags?key=RH3P1OZMBSCK");
    }

    componentDidUpdate() {
        let { detailedGIF } = this.props.messageData;
        let { detailGifData } = this.state;
        let { getDetailGIF } = this.props;

        let scrollElement = document.getElementById("web-modal");
        if (scrollElement) {
            if (
                scrollElement.scrollTop ===
                scrollElement.scrollHeight - scrollElement.clientHeight
            ) {
                let details = {
                    name: detailGifData.searchterm,
                    page: detailedGIF && detailedGIF.next,
                };
                detailedGIF && detailedGIF.next && getDetailGIF(details);
            }
        }
    }

    updateWebViewState = (state, value) => {
        this.setState({
            [state]: value,
        });
    };

    MouseDown = (e) => {
        this.setState({
            pressStart: e.timeStamp,
        });
    };

    MouseUp = (e, data) => {
        if (this.state.pressStart + 1000 < e.timeStamp) {
            if (
                Number(data.from_user_id) === this.props.userId ||
                data.GooglePlaceId ||
                data.GooglePlacedId
            ) {
                if (data.RowNumber !== "1") {
                    // this.setState({
                    //     dataToDelete: {
                    //         data: data
                    //     },
                    //     showDelete: true,
                    // });
                }
            }
        } else {
            if (!data.GooglePlaceId && !data.GooglePlacedId) {
                // if (localStorage.getItem("curbsideCommunicationUserId")) {
                //     window.open(e.currentTarget.src + "", "_blank");
                //     window.close();
                // } else {
                    this.imageClicked(e, data);
                // }
            }
        }
    };

    deleteChat = () => {
        let { dataToDelete, deleteCount } = this.state;
        let showDelete;
        let { messageData, deleteBusinessMessage, deleteMessage } = this.props;
        if (messageData.selectedChat.Type === "OneToOne") {
            ws.json({
                chat_id: dataToDelete.data.chat_id,
                to_user_id: messageData.selectedChat.userid,
                type: "removemessage",
            });
            deleteMessage(dataToDelete.data.chat_id);
            showDelete = false;
            deleteCount = deleteCount + 1;
        } else {
            ws.json({
                chat_type:
                    messageData.selectedChat.Type === "PlaceQuestionChat"
                        ? "PlaceQuestionChat"
                        : messageData.selectedChat.Type === "Feed"
                        ? "Feed"
                        : "Event",
                chat_id: dataToDelete.data.CommentId,
                user_id: dataToDelete.data.UserId,
                type: "removebusinessmessage",
                googlePlacedId: dataToDelete.data.GooglePlaceId,
                question_id: "0",
            });
            deleteBusinessMessage(dataToDelete.data.CommentId);
            showDelete = false;
            deleteCount = deleteCount + 1;
        }
        this.setState({ showDelete, deleteCount });
    };

    hideDelete = () => {
        this.setState({
            showDelete: false,
        });
    };

    imageClicked = (e, data) => {
        let { audioURL } = this.state;
        if (JSON.parse(data.message_json).text === "[Sticker message]") {
            if (Number(JSON.parse(data.message_json).stickerId.split(".")[0]) <= 30) {
                audioURL = require(`../../../Sounds/${
                    JSON.parse(data.message_json).stickerId.split(".")[0]
                }.wav`).default;
                audioFunc(false, false, audioURL);
            } else {
                audioURL = `https://d2gnb7jphto2jj.cloudfront.net/ChatImages/Sticker/Sounds/${
                    JSON.parse(data.message_json).stickerId.split(".")[0]
                }.wav`;
                audioFunc(false, false, audioURL);

            }
        } else if (
            JSON.parse(data.message_json).text === "[GIF message]" ||
            JSON.parse(data.message_json).text === "[Picture message]"
        ) {
            let modal = document.getElementById("myModal");
            let targetDestination = document.getElementById("zoomImage");
            modal.style.display = "block";
            targetDestination.src = e.target.src;
        }
        this.setState({ audioURL });
    };

    onGifClick = () => {
        const { messageData } = this.props;
        this.setState(
            {
                showGif: !this.state.showGif,
            },
            () => {
                if (this.state.showGif) {
                    let { gif } = this.state;
                    messageData &&
                    messageData.gif &&
                    messageData.gif.forEach((g, index) => {
                        gif.push(
                            <div
                                key={index}
                                className="gif"
                                onClick={() => {

                                    if (g) {
                                        let details = {
                                            name: g.searchterm,
                                            page: messageData.detailedGIF
                                                ? messageData.detailedGIF.next
                                                : 0,
                                        };
                                        this.props.getDetailGIF(details);
                                        this.setState({
                                            showDetailed: true,
                                            detailGifData: g,
                                        });
                                    }
                                    // this.openDetailed(g);
                                }}
                            >
                                <img src={g.image} alt={g.name} className="gif-img" />
                                <p className="gifText">{g.searchterm}</p>
                            </div>
                        );
                    });
                    this.setState({
                        gif,
                    });
                } else {
                }
            }
        );
    };

    onCameraClick = (image) => {
        const { messageData, userId, error } = this.props;
        let date = new Date();
        image = image.substring(image.indexOf(",") + 1);
        let data = {
            ProfileImage: image,
            messageId: date.getTime().toString().slice(4),
        };
        axiosI
            .post("/UploadChatImage", data, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                if (response.data.results.Message !== "Chat Image does not saved") {
                    let currentDate = moment().utc().format("YYYY-MM-DD HH:mm:ss");
                    let data = {
                        text: "[Picture message]",
                        imgUrl: response.data.results.ImageUrl,
                    };
                    ws.json({
                        msgid: response.data.results.Id,
                        to_user_id: messageData.selectedChat.userid.toString(),
                        dialog_id: messageData.selectedChat.dialog_id.toString(),
                        from_user_id: userId.toString(),
                        chat_time: currentDate,
                        message_type: "2",
                        type: "chat",
                        token_id: localStorage.getItem("token"),
                        chat_message: data,
                    });
                }
            })
            .catch((err) => {
                console.log("Error uploading image to the server.", err);
                error();
            });
        this.setState({
            showCamera: false,
        });
    };

    toggleCamera = () => {
        this.setState({
            showCamera: !this.state.showCamera,
        });
    };

    GifView() {
        const {
            showGif,
            showCamera,
            detailGifData,
            gif,
            searchArray,
            isSearching,
            showDetailed,
        } = this.state;
        const { userId, toggleAlert, messageData } = this.props;
        return showGif || showCamera ? (
            //   <div>
            //     <GifComponent
            //       onGifClick={this.onGifClick}
            //       sendGif={this.sendGif}
            //       updateWebViewState={this.updateWebViewState}
            //       userId={userId}
            //       showCamera={showCamera}
            //       toggleCamera={this.toggleCamera}
            //       toggleAlert={toggleAlert}
            //       showGif={showGif}
            //       onCameraClick={this.onCameraClick}
            //     />
            //     <p>hatatta</p>
            //   </div>
            <React.Fragment>
                {showCamera ? (
                    <Modal
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                        open={true}
                        onClose={this.toggleCamera}
                    >
                        <div onClick={this.toggleCamera} className="display-camera">
                            <Camera
                                onTakePhoto={this.onCameraClick}
                                onCameraError={(e) => {
                                    if (
                                        e.toString() === "NotFoundError: Requested device not found"
                                    ) {
                                        this.toggleCamera();
                                        toggleAlert(
                                            "Please connect camera to use this functionality."
                                        );
                                    } else if (
                                        e.toString().includes("Only secure origins are allowed")
                                    ) {
                                        this.toggleCamera();
                                        toggleAlert(`${window.location.origin} is not secured.`);
                                    } else if (e.toString().includes("Permission denied")) {
                                        this.toggleCamera();
                                        toggleAlert(
                                            `Permission Denied. Please allow the camera to use this functionality.`
                                        );
                                    } else if (e.toString().includes("no stream to stop!")) {
                                    } else {
                                        this.toggleCamera();
                                        toggleAlert(`Something went wrong while accessing camera.`);
                                        console.log(e);
                                    }
                                }}
                            />
                        </div>
                    </Modal>
                ) : (
                    <React.Fragment>
                        <Modal
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                            open={true}
                            onClose={this.onGifClick}
                        >
                            <Grid container spacing={24}>
                                <Grid item sm={12} />
                                <div className="paper">
                                    <div className="gifWrapper">
                                        <input
                                            type="text"
                                            placeholder="Search for GIF"
                                            onChange={(e) => {
                                                if (e.target.value.length > 0) {
                                                    let { searchArray } = this.state;
                                                    searchArray = [];
                                                    gif.forEach(
                                                        (v) =>
                                                            v.props.children[1].props.children
                                                                .toLowerCase()
                                                                .includes(e.target.value.toLowerCase()) &&
                                                            searchArray.push(v)
                                                    );
                                                    this.setState({
                                                        isSearching: true,
                                                        searchArray,
                                                    });
                                                } else {
                                                    this.setState({
                                                        isSearching: false,
                                                    });
                                                }
                                            }}
                                        />
                                        <div className="gifComponent">
                                            {isSearching ? searchArray : gif}
                                        </div>
                                    </div>
                                </div>
                                <Grid />
                            </Grid>
                        </Modal>
                        {this.state.showDetailed && (
                            <Modal
                                aria-labelledby="simple-modal-title"
                                aria-describedby="simple-modal-description"
                                open={true}
                                onClose={() => {
                                    this.setState({
                                        showDetailed: false,
                                    });
                                    this.props.clearDetailedGif();
                                }}
                            >
                                <div className="paper">
                                    <div
                                        className="gifWrapper"
                                        id="gifWrapper"
                                        onScroll={() => {
                                            let scrollElement = document.getElementById("gifWrapper");
                                            if (scrollElement) {
                                                if (
                                                    scrollElement.scrollTop >=
                                                    scrollElement.scrollHeight -
                                                    scrollElement.clientHeight
                                                ) {
                                                    let details = {
                                                        name: detailGifData.searchterm,
                                                        page:
                                                            messageData.detailedGIF &&
                                                            messageData.detailedGIF.next,
                                                    };
                                                    messageData.detailedGIF &&
                                                    messageData.detailedGIF.next &&
                                                    this.props.getDetailGIF(details);
                                                }
                                            }
                                        }}
                                    >
                                        <div className="detailGifComponent">
                                            {messageData.detailedGIF &&
                                            messageData.detailedGIF.results.map((gif) => {
                                                let color =
                                                    this.state.backgroundColorDiv[
                                                    Math.floor(Math.random() * 13) + 1
                                                        ];
                                                return (
                                                    <div
                                                        className="gif"
                                                        onClick={() => {
                                                            let date = new Date();
                                                            let currentDate = moment().utc().format(
                                                                "YYYY-MM-DDTHH:mm:ss"
                                                            );
                                                            let data = {
                                                                text: "[GIF message]",
                                                                imgUrl: `${gif.itemurl}.gif`,
                                                            };
                                                            ws.json({
                                                                msgid: date.getTime().toString().slice(4),
                                                                to_user_id: messageData.selectedChat.userid,
                                                                dialog_id: messageData.selectedChat.dialog_id,
                                                                from_user_id: userId,
                                                                chat_time: currentDate,
                                                                message_type: "6",
                                                                type: "chat",
                                                                token_id: localStorage.getItem("token"),
                                                                chat_message: data,
                                                            });
                                                            this.updateWebViewState("showGif", false);
                                                            this.updateWebViewState("scroll", true);
                                                            this.setState({
                                                                showDetailed: false,
                                                            });
                                                            this.props.clearDetailedGif();
                                                        }}
                                                    >
                                                        <img
                                                            src={`${gif.itemurl}.gif`}
                                                            alt={"GIF"}
                                                            className="gif-img"
                                                            gifstyle={{ background: color }}
                                                        />
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </Modal>
                        )}
                    </React.Fragment>
                )}
            </React.Fragment>
        ) : null;
    }

    render() {
        const {
            showAlert,
            showGif,
            showCamera,
            showDelete,
            dataToDelete,
            audioURL,
            deleteCount,
            detailGifData,
            gif,
            searchArray,
            isSearching,
        } = this.state;
        const {
            userId,
            toggleAlert,
            messageData,
            showInfo,
            curbsideCommunication,
            curbsideCommunicationUserData,
            curbsideCommunicationSelectedChatIndex,
        } = this.props;


        return (
            <React.Fragment>
                {showAlert && <AlertComponent clickMethod={this.toggleAlert} />}
                {this.GifView()}
                {showDelete && (
                    <Alert
                        warning="true"
                        showCancel="true"
                        confirmText="Delete"
                        confirmStyle="danger"
                        cancelStyle="default"
                        title={<span>"Like it never existed"</span>}
                        Confirm={this.deleteChat}
                        Cancel={this.hideDelete}
                    />
                )}
                {(messageData && messageData.totalRecentChat) !== 0 ? (
                    messageData.selectedChat ? (
                        <div>
                            {curbsideCommunication &&
                            curbsideCommunicationSelectedChatIndex == -1
                                ? ""
                                : messageData.isUserOnline && (
                                <div className="detail-chat-header">
                                    <h1>
                                        {messageData.selectedChat.Type === "OneToOne" &&
                                        messageData.isUserOnline &&
                                        messageData.isUserOnline.isOnline === 1 && (
                                            <span className="online" />
                                        )}
                                        {messageData.selectedChat.Type === "OneToOne"
                                            ? messageData.selectedChat.FullName
                                            : messageData.selectedChat.Type ===
                                            "PlaceQuestionChat"

                                                ? Number(messageData.selectedChat.userid) === Number(this.props.userId)
                                                    ? "Question for " + messageData.selectedChat.locatoinInfo
                                                    : messageData.selectedChat.FullName.trim() + "'s Question"

                                                : messageData.selectedChat.Type === "Feed"
                                                    ? `${messageData.selectedChat.feed.First_Name}'s Moment`
                                                    : messageData.selectedChat.event?.Name}
                                    </h1>
                                    {messageData.selectedChat.Type === "OneToOne" &&
                                    messageData.isUserOnline.to_user_id ===
                                    messageData.selectedChat.userid ? (
                                        messageData.isUserOnline &&
                                        messageData.isUserOnline.locationInfo && (
                                            <p className="text-center">
                                                {messageData.isUserOnline.locationInfo}
                                            </p>
                                        )
                                    ) : (
                                        <React.Fragment />
                                    )}
                                </div>
                            )}
                            <div
                                className={
                                    curbsideCommunication
                                        ? "chat-size-3"
                                        : showInfo
                                        ? "chat-size"
                                        : "chat-size-2"
                                }
                            >
                                {messageData.selectedChat.Type ? (
                                    messageData.selectedChat.Type === "OneToOne" ? (
                                        <WebViewChat
                                            dataToDelete={dataToDelete}
                                            showDelete={showDelete}
                                            audioURL={audioURL}
                                            updateWebViewState={this.updateWebViewState}
                                            deleteCount={deleteCount}
                                            MouseUp={this.MouseUp}
                                            MouseDown={this.MouseDown}
                                            webViewFooter={this.WebViewFooter}
                                            ref={(e) => (this.timepass = e)}
                                            curbsideCommunication={curbsideCommunication}
                                            curbsideCommunicationUserData={
                                                curbsideCommunicationUserData != undefined
                                                    ? curbsideCommunicationUserData
                                                    : null
                                            }
                                            curbsideCommunicationSelectedChatIndex={
                                                curbsideCommunicationSelectedChatIndex
                                            }
                                        />
                                    ) : (
                                        <QuestionChat
                                            dataToDelete={dataToDelete}
                                            deleteCount={deleteCount}
                                            showDelete={showDelete}
                                            updateWebViewState={this.updateWebViewState}
                                            MouseUp={this.MouseUp}
                                            MouseDown={this.MouseDown}
                                            questionChatFooter={this.questionChatFooter}
                                            ref={(e) => (this.questionBridge = e)}
                                        />
                                    )
                                ) : (
                                    ""
                                )}
                            </div>
                            {messageData.typing &&
                            messageData.typing.status &&
                            messageData.selectedChat.dialog_id ===
                            messageData.typing.data.dialog_id && (
                                <p className="typing">Typing...</p>
                            )}
                            {messageData.selectedChat.Type === "OneToOne" ? (
                                <WebViewFooter
                                    updateWebViewState={this.updateWebViewState}
                                    detailGifData={detailGifData}
                                    gif={gif}
                                    showGif={showGif}
                                    toggleCamera={this.toggleCamera}
                                    onGifChange={this.onGifChange}
                                    //   isSearching={isSearching}
                                    //   searchArray={searchArray}
                                    onGifClick={this.onGifClick}
                                    toggleAlert={toggleAlert}
                                    ref={(e) => (this.WebViewFooter = e)}
                                    curbsideCommunication={curbsideCommunication}
                                />
                            ) : (
                                <QuestionChatFooter
                                    updateWebViewState={this.updateWebViewState}
                                    ref={(e) => (this.questionChatFooter = e)}
                                />
                            )}
                        </div>
                    ) : (
                        <Loader />
                    )
                ) : (
                    <div className="no-message-display-webview">
                        No Messages to display
                    </div>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        messageData: state.messageReducer,
        chatHistory: state.messageReducer.chatHistory,
    };
};
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getGIF,
            deleteMessage,
            getDetailGIF,
            deleteBusinessMessage,
            clearDetailedGif,
        },
        dispatch
    );
};

export default withStyles(styles)(
    connect(mapStateToProps, mapDispatchToProps)(WebView)
);
