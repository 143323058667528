import {
  IDENTITY,
  RECENT_CHAT,
  CHAT_HISTORY,
  SELECTED_CHAT,
  CHAT_SENT_ACK,
  USER_ONLINE,
  GET_GIF,
  GET_DETAILED_GIF,
  START_TYPING_ACK,
  STOP_TYPING_ACK,
  CHAT_RECEIVED,
  REMOVE_MESSAGE,
  OPEN,
  IS_USER_ONLINE,
  READ_ALL_MESSAGES,
  BUSINESS_CHAT_HISTORY,
  BUSINESS_DELETE_MESSAGE,
  ERROR,
  HIDE_POP_UP,
  DELETE_CHAT,
  ALL_LIVE_CHAT,
  LIVE_CHAT_REMOVE_BUSINESS_MESSAGE,
  LIVE_CHAT_UPDATE_RECENT_CHAT,
  SET_SCROLL,
  UNREAD_COUNT,
  CLEAR_GIF,
  INCREASE_MESSAGE_INDEX,
  CLEAR_ALL_DATA,
  GET_DIALOG,
  REMOVE_EMPTY_DIALOG,
  UNSELECT_CHAT,
  INITIAL_LOAD,
  EMPTY_BACKUP_CHAT,
  EMPTY_LIVE_CHAT,
  EVENT_EXPERIENCE_EVENT_CHAT,
  GET_ALL_EVENT_EXPERIENCE_CHAT,
  CLEAR_ALL_EVENT_EXPERIENCE_CHAT,
  IS_EVENT_EXPERIENCE_CHAT_LOADING,
  SELECTED_MULTIMEDIA_DATA,
  EVENT_EXPERIENCE_EVENT_SCHEDULED_CHAT,
  GET_ALL_CHECKED_IN_USERS,
  GET_ALL_EVENT_EXPERIENCE_ACTIVITIES,
  CLEAR_ALL_EVENT_EXPERIENCE_ACTIVITY,
  GET_EVENT_ACTIVITIES,
  ADD_MESSAGE_LIKE,
  CLEAR_EVENT_ACTIVITIES,
  CLEAR_EVENT_CHECKED_IN_LIST,
  GET_SCHEDULED_MESSAGES,
  ACK_ADD_MESSAGE_LIKE,
  SET_USER_CHECKED_IN_LIST,
  SET_LOAD_EARLIER_MESSAGES,
  IS_CHECKED_IN_USER_LIST_LOADING,
  IS_EVENT_ACTIVITY_LOADING,
  ACK_DELETE_MESSAGE_LIKE,
  POLL_ACK,
} from "./actionTypes";
import axios from "axios";
import axiosI from "../Service/service";
import storeFolder from "../store";
import { routeName } from "../../src/Service/env";
import { ws } from "../App";
import { convertUnicode } from "../Helper/methods";

export const messageAction = (data) => {
  return async (dispatch) => {
    let storeData = storeFolder.getState();
    let response = JSON.parse(data.data);
    switch (response.type) {
      case "getdialog":
        const messageChatData = {
          dialog_id: response.dialog_id,
          last_message_json: {},
          utc_message_date: "",
          UserName: localStorage.getItem("curbsideCommunicationUserName"),
          userid: localStorage.getItem("curbsideCommunicationUserId"),
          locatoinInfo: null,
          photo: "",
          FullName: localStorage.getItem("curbsideCommunicationFullName"),
          Bio: "",
          unread_count: 0,
          from_user_id: storeData.loginResponse.results.ProfileDetail.UserId,
          IsOnlyFriendsMsgMe: "",
          IsAccountPrivate: "",
          Type: "OneToOne",
          EventId: null,
          FeedId: null,
          RowNumber: !storeData?.messageReducer?.recentChat
            ? 0
            : storeData.messageReducer.recentChat.length,
        };

        if (response.chat_type === "Event") {
          if (response.dialog_id)
            localStorage.setItem("currentDialogId", response.dialog_id);

          ws.json({
            index: "0",
            count: "20",
            user_id: storeData.loginResponse.results.ProfileDetail.UserId,
            type: "businessrecentchat",
            chat_type: "Event",
            deleteCount: "0",
            googlePlacedId: response.dialog_id,
          });
        }

        dispatch({
          type: GET_DIALOG,
          payload: messageChatData,
        });
        break;
      case "identity":
        dispatch({
          type: IDENTITY,
          payload: response,
        });
        break;
      case "recentChat":
        if (
          storeData.messageReducer.chatHistory &&
          storeData.messageReducer.chatHistory.length === 0
        ) {
          ws.json({
            count: 20,
            deleteCount: 0,
            dialog_id: response.chats,
            index: 1,
            type: "chatHistory",
            user_id: storeData.loginResponse.results.ProfileDetail.UserId,
          });
        }
        dispatch({
          type: RECENT_CHAT,
          payload: response,
        });
        break;
      case "chatHistory":
        dispatch({
          type: CHAT_HISTORY,
          payload: response,
        });
        getUnreadCount()(dispatch);
        break;
      case "chatsentack":
        dispatch({
          type: CHAT_SENT_ACK,
          payload: response,
        });
        break;
      case "userOnline":
        dispatch({
          type: USER_ONLINE,
          payload: response,
        });
        break;
      case "starttypingack":
        dispatch({
          type: START_TYPING_ACK,
          payload: response,
        });
        break;
      case "stoptypingack":
        dispatch({
          type: STOP_TYPING_ACK,
          payload: response,
        });
        break;
      case "chat":
        if (storeData.messageReducer.selectedChat) {
          if (
            storeData.messageReducer.selectedChat.dialog_id.toUpperCase() ===
            response.dialog_id.toUpperCase() &&
            window.location.pathname === `${routeName}/Dashboard/Messages`
          ) {
            ws.json({
              chat_id: response.chat_id,
              type: "chatrecieveack",
              user_id: storeData.loginResponse.results.ProfileDetail.UserId,
            });
          }
          getUnreadCount()(dispatch);
        } else {
          ws.json({
            user_id: storeData.loginResponse.results.ProfileDetail.UserId,
            type: "recentchat",
            count: 20,
            index: 1,
          });
        }
        if (
          localStorage.getItem("UserId") &&
          !document.location.toString().includes("Messages")
        ) {
          var message = response.chat_message.text;
          if (message === "[Sticker message]") {
            message = "Sound Moji Received";
          } else if (message === "[Picture message]") {
            message = "Picture Received";
          } else if (message === "[GIF message]") {
            message = "GIF Received";
          } else {
            message = convertUnicode(message);
          }
        }

        dispatch({
          type: CHAT_RECEIVED,
          payload: response,
        });
        break;
      case "removemessage":
        ws.json({
          user_id: localStorage.getItem("UserId"),
          type: "recentchat",
          count: 20,
          index: 1,
        });
        getUnreadCount()(dispatch);
        dispatch({
          type: REMOVE_MESSAGE,
          payload: response,
        });
        break;
      case "ackUserStatus":
        dispatch({
          type: IS_USER_ONLINE,
          payload: response,
        });
        break;
      case "businessrecentchat":
        let count = response?.scheduleMessageCount
        // console.log("businessrecentchat", count);
        count && localStorage.setItem("ScheduledChatCount", JSON.parse(count)[0]?.Count)

        if (response.MainQuestionId) {
          dispatch({
            type: BUSINESS_CHAT_HISTORY,
            payload: response,
          });
        } else {
          // console.log("ALL_LIVE_CHAT");
          dispatch({
            type: ALL_LIVE_CHAT,
            payload: response,
          });
        }

        if (response) {
          localStorage.setItem(
            "TotalCount",
            JSON.stringify(response.totalCount)
          );

          // const unscheduledChatsCount = await response?.chats
          // .filter(chat=>chat.isScheduled && moment(chat?.ScheduleDateTime)
          // .utc(chat?.ScheduleDateTime).isSameOrAfter(moment()
          // .utc()))
          // .filter(chat => chat.isScheduled);
          // let storeData = storeFolder.getState();
          // let storeData = storeFolder.getState();
          // let scheduleCount = storeData.messageReducer.eventExperienceEventChat
          // .filter((item) => item.isScheduled && moment(item?.scheduleDateTime)
          // .utc(item?.scheduleDateTime).isSameOrAfter(moment()
          // .utc()))
          // .sort((a, b) => {
          //   const timeA = a.isScheduled ? new Date(a.ScheduleTime) : new Date(a.chat_time);
          //       const timeB = b.isScheduled ? new Date(b.ScheduleTime) : new Date(b.chat_time);

          //       return timeA - timeB;
          // });

          // if(!unscheduledChatsCount){

          // }
          // localStorage.setItem(
          //   "TotalScheduledChatCount",
          //   JSON.stringify(scheduleCount.length)
          // );
          dispatch({
            type: GET_ALL_EVENT_EXPERIENCE_CHAT,
            payload: response,
          });
        }

        break;
      case "businesschat":
        if (
          storeData.messageReducer.selectedChat.dialog_id.toUpperCase() ===
          response.googlePlacedId.toUpperCase()
        ) {
          ws.json({
            chat_id: response.chat_id,
            type: "chatrecieveack",
            user_id: storeData.loginResponse.results.ProfileDetail.UserId,
          });

          if ((response.isScheduled === 'true' || response.isScheduled) && !response?.edit) {
            const currentScheduledMessageCount = localStorage.getItem("ScheduledChatCount")
            localStorage.setItem(
              "ScheduledChatCount", Number(currentScheduledMessageCount) + 1
            )
          }
        } else {
          getUnreadCount()(dispatch);
        }

        if (response && response.chat_type && response.chat_type === "Feed") {
          dispatch({
            type: LIVE_CHAT_UPDATE_RECENT_CHAT,
            payload: response,
          });
        } else if (
          response &&
          response.chat_type &&
          response.chat_type === "Event"
        ) {
          dispatch({
            type: EVENT_EXPERIENCE_EVENT_CHAT,
            payload: response,
          });
        }
        break;
      case "getscheduledpendingchat":
        dispatch({
          type: EVENT_EXPERIENCE_EVENT_SCHEDULED_CHAT,
          payload: response,
        });
        break;
      case "ackDeleteChat":
        dispatch({
          type: DELETE_CHAT,
          payload: response,
        });
        break;
      case "participantACK":
        break;
      case "ackRemoveBusinessMessage":
        dispatch({
          type: LIVE_CHAT_REMOVE_BUSINESS_MESSAGE,
          payload: response,
        });
        break;
      case "chatstatusupdated":
        break;
      case "updateRecentChat":
        if (
          storeData.messageReducer.selectedChat.dialog_id.toUpperCase() ===
          response.googlePlacedId.toUpperCase()
        ) {
          ws.json({
            chat_id: response.chat_id,
            type: "chatrecieveack",
            user_id: storeData.loginResponse.results.ProfileDetail.UserId,
          });

        } else {
          getUnreadCount()(dispatch);
        }

        if (response && response.chat_type && response.chat_type === "Feed") {
          dispatch({
            type: LIVE_CHAT_UPDATE_RECENT_CHAT,
            payload: response,
          });
        } else if (
          response &&
          response.chat_type &&
          response.chat_type === "Event"
        ) {
          dispatch({
            type: EVENT_EXPERIENCE_EVENT_CHAT,
            payload: response,
          });
        }

        // dispatch({
        //   type: UPDATE_RECENT_CHAT,
        //   payload: response,
        // });
        break;
      case 'geteventcheckedinuserslist':
        if (response && response.chats) {
          const simplifiedPayload = response.chats
          // console.log("total count",response.totalCount);
          // console.log("simplifiedPayload", (simplifiedPayload))
          dispatch({
            type: GET_ALL_CHECKED_IN_USERS,
            payload: {
              // ...response,
              chats: simplifiedPayload,
              totalCheckedInUsers:response.totalCount
            }
          })
        }
        break;
      // case 'getEventActivities':
      //   if (response && response.chats) {
      //     const simplifiedPayload = JSON.parse(response.chats)
      //     console.log("simplifiedPayload", simplifiedPayload)
      //     dispatch({
      //       type: GET_ALL_EVENT_EXPERIENCE_ACTIVITIES,
      //       payload: {
      //         // ...response,
      //         chats: simplifiedPayload
      //       }
      //     })
      //   }
      //   break;
        case 'getEventActivities':
          if (response) {
            const simplifiedPayload = response.chats
            const activities = JSON.parse(response.chats);
            // console.log("EventActivities",activities);
            dispatch({
              type: GET_EVENT_ACTIVITIES,
              payload: {
                // ...response,
                eventActivity: activities
              }
            })
          }
          break;
          case 'addMessageLike':
            if (response) {
              // console.log("message like",response);
              dispatch({
                type: ADD_MESSAGE_LIKE,
                payload: {
                  // ...response,
                  messageLike: response
                }
              })
            }
            break;
            case 'getScheduledMessages':
              if (response.CommentDetail) {
                const currentScheduledMessageCount = localStorage.getItem("ScheduledChatCount")
                localStorage.setItem("ScheduledChatCount",Number(currentScheduledMessageCount)-1);
                dispatch({
                  type: GET_SCHEDULED_MESSAGES,
                  payload: {
                    // ...response,
                    getScheduledMessages: response
                  }
                });
              }
              break;
              case 'ackAddMessageLike':
                  dispatch({
                    type: ACK_ADD_MESSAGE_LIKE,
                    payload: {
                      // ...response,
                      ackMessageLike: response
                    }
                  })
                break;
              case 'ackDeleteMessageLike':
                dispatch({
                  type: ACK_DELETE_MESSAGE_LIKE,
                  payload: {
                    // ...response,
                    ackDeleteMessageLike: response
                  }
                })
              break;
              case 'pollACK':
                dispatch({
                  type: POLL_ACK,
                  payload: {
                    // ...response,
                    pollAck: response
                  }
                })
              break;
      default:
        break;
    }
  };
};

export const removeEmptyDialog = (data) => {
  return (dispatch) => {
    dispatch({
      type: REMOVE_EMPTY_DIALOG,
      payload: data,
    });
  };
};

export const emptyBackupChat = (data) => {
  return (dispatch) => {
    dispatch({
      type: EMPTY_BACKUP_CHAT,
      payload: data,
    });
  };
};

export const emptyLiveChat = (data) => {
  return (dispatch) => {
    dispatch({
      type: EMPTY_LIVE_CHAT,
      payload: data,
    });
  };
};

export const unselectChat = (data) => {
  return (dispatch) => {
    dispatch({
      type: UNSELECT_CHAT,
      payload: data,
    });
  };
};

export const selectedChat = (data) => {
  return (dispatch) => {
    dispatch({
      type: SELECTED_CHAT,
      payload: data,
    });

    if (data.Type !== "PlaceQuestionChat") {
      dispatch({
        type: READ_ALL_MESSAGES,
        payload: data,
      });
    }
  };
};
export const getGIF = (url) => {
  return (dispatch) =>
    axios
      .post(url)
      .then((response) => {
        dispatch({
          type: GET_GIF,
          payload: response.data.tags,
        });
      })
      .catch((err) => {
        console.log(err);
      });
};
export const getDetailGIF = (data) => {
  return (dispatch) =>
    axios
      .post(
        `https://api.tenor.co/v1/search?key=RH3P1OZMBSCK&tag=${data.name}k&pos=${data.page}`
      )
      .then((response) => {

        dispatch({
          type: GET_DETAILED_GIF,
          payload: response.data,
        });
      })
      .catch((err) => {
        console.log("Error getting detailed GIF.", err);
        error("No Internet Connection")(dispatch);
      });
};
export const deleteMessage = (data) => {
  return (dispatch) =>
    dispatch({
      type: REMOVE_MESSAGE,
      payload: {
        chatId: data,
      },
    });
};
export const readAllMessages = (data) => {
  return (dispatch) =>
    dispatch({
      type: READ_ALL_MESSAGES,
      payload: data,
    });
};
export const deleteBusinessMessage = (data) => {
  return (dispatch) =>
    dispatch({
      type: BUSINESS_DELETE_MESSAGE,
      payload: data,
    });
};
export const onOpen = () => {
  return (dispatch) => {
    dispatch({
      type: OPEN,
    });
  };
};
export const setInitial = (type) => {
  return (dispatch) =>
    dispatch({
      type: INITIAL_LOAD,
      payload: type,
    });
};
export const error = (message) => {
  return (dispatch) => {
    dispatch({
      type: ERROR,
      payload: {
        message: message,
      },
    });
  };
};

export const hidePopUp = () => {
  return (dispatch) => {
    dispatch({
      type: HIDE_POP_UP,
      payload: false,
    });
  };
};

export const setScrollFalse = () => {
  return (dispatch) => {
    dispatch({
      type: SET_SCROLL,
      payload: false,
    });
  };
};

export const getUnreadCount = () => {
  return (dispatch) => {
    let data = localStorage.getItem("UserId");
    axiosI
      .get(`GetCountByStatus/${data}/${data}/Pending`)
      .then((response) => {
        dispatch({
          type: UNREAD_COUNT,
          payload: response.data.results,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const clearDetailedGif = () => {
  return (dispatch) =>
    dispatch({
      type: CLEAR_GIF,
    });
};
export const increaseMessageIndex = (data) => {
  return (dispatch) =>
    dispatch({
      type: INCREASE_MESSAGE_INDEX,
      payload: data,
    });
};

export const clearData = (data) => {
  return (dispatch) =>
    dispatch({
      type: CLEAR_ALL_DATA,
      payload: data,
    });
};

export const unreadCountToZero = (data) => {
  return (dispatch) =>
    axiosI
      .get(`/SetChatAsRead/${data}`)
      .then((response) => {
        getUnreadCount()(dispatch);
      })
      .catch((err) => {
        console.log(err);
      });
};

export const clearEventActivities = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_EVENT_ACTIVITIES,
    });
  };
};

export const clearEventUserCheckedInList = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_EVENT_CHECKED_IN_LIST,
    });
  };
};

export const isCheckedInUserListLoading = () => {
  return (dispatch) => {
    dispatch({
      type: IS_CHECKED_IN_USER_LIST_LOADING,
    });
  };
};

export const isEventActivityListLoading = () => {
  return (dispatch) => {
    dispatch({
      type: IS_EVENT_ACTIVITY_LOADING,
    });
  };
};

export const clearEventExperienceChat = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_ALL_EVENT_EXPERIENCE_CHAT,
    });
  };
};

export const clearEventExperienceActivity = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_ALL_EVENT_EXPERIENCE_ACTIVITY,
    });
  };
}

export const setEventExperienceMessagesState = (data) => {
  return (dispatch) => {
    dispatch({
      type: IS_EVENT_EXPERIENCE_CHAT_LOADING,
      payload: data
    })
  }
}

export const setUserCheckedInList = () => {
  return (dispatch) => {
    dispatch({
      type: SET_USER_CHECKED_IN_LIST,
    })
  }
}

export const setSelectedMultimediaData = (data) => {
  return (dispatch) => {
    dispatch({
      type: SELECTED_MULTIMEDIA_DATA,
      payload: data
    })
  }
}

export const setLoadEarlierMessage = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_LOAD_EARLIER_MESSAGES,
      payload: data
    })
  }
}
