import React from 'react'
import "./index.css"
import { routeName } from 'Service/env';
const TermAndCondtion = () => {
  return (
    <div className='terms_and_condition'>
          <div >

<p  style={{textAlign:"justify"}}><b><span style={{fontSize:"10.0pt"}}></span></b></p>

<p  align="center" style={{textAlign:"center"}}>
    <b >
        <span >UBME, INC.</span>
    </b><b >
        <span ></span>
    </b>
</p>

<p  align="center" style={{textAlign:"center"}}>
    <b >
        <span >TERMS AND CONDITIONS OF USE</span>
    </b>
</p>

<p  align="center" style={{textAlign:"center"}}>
    <b >
        <span>Effective Date: January 25, 2017</span>
    </b>
</p>

<p  style={{textAlign:"justify"}}>
    <span></span>
</p>

<p  style={{textAlign:"justify"}}>
    <span>
        Welcome to UBME!<span style={{msoSpacerun:"yes"}}></span>
    </span>
</p>

<p  style={{textAlign:"justify"}}>
    <span></span>
</p>

<p  style={{textAlign:"justify"}}>
    <span>
        These terms and conditions of use (this
        “Agreement” or “Terms”) contain the complete terms and conditions applicable to
        your (“you” or “your”) access to and use of the services and features available
        on UBME, Inc’s (“Company”, “we”, “us” or “our”) website and domains, including at
        {window.location.hostname} and all of its webpages, subdomains, country level domain variants
        and subparts of those websites (collectively, our “Site”), services available
        through our mobile application (the “App”) as well as any software that we
        provide to you that allows you to access either our Site or App (collectively,
        the “Services”),
    </span><span >
        and
        any information, text, graphics, photos or other materials posted or submitted
        on or through our Services (“Content”)
    </span><span>
        .<span style={{msoSpacerun:"yes"}}>
        </span>YOUR USE OF OUR SERVICES CONSTITUTES ACCEPTANCE AND ACKNOWLEDGMENT OF
        THE TERMS AND CONDITIONS OF THIS AGREEMENT.<span style={{msoSpacerun:"yes"}}>
        </span>
    </span>
</p>

<p  style={{textAlign:"justify"}}>
    <span></span>
</p>

<p  style={{textAlign:"justify"}}>
    <b >
        <span >IMPORTANT NOTE</span>
    </b><span>
        : This Agreement contains a dispute
        resolution and arbitration provision, including class action waiver, which
        affects your rights under these Terms and with respect to any dispute you may
        have with the Company. You may opt out of the binding individual arbitration
        and class action waiver as provided below.
    </span>
</p>

<p  style={{textAlign:"justify"}}>
    <span></span>
</p>

<p  style={{textAlign:"justify"}}>
    <span>
        Changes. Company may change, update, or
        add or remove provisions of these Terms at any time by posting the updated
        Terms on the App or the Site, by posting a notice on the Site, via email, text
        message, or through in-App notifications. By using the Services after we have
        updated the Terms, you are agreeing to all the updated Terms; if you do not
        agree with any of the updated Terms, you must stop using the Services.
    </span>
</p>

<p  style={{textAlign:"justify"}}>
    <span></span>
</p>

<p  style={{textAlign:"justify"}}>
    <span>
        Users. Through our Services, we provide
        simple and quick means for registered users to connect with their community,
        other users, or events that they or someone else has created.<span style={{msoSpacerun:"yes"}}></span>We refer to event organizers, attendees, and
        any other visitors or browsers of the Services collectively as “Users” or “you.”
    </span>
</p>

<p  style={{textAlign:"justify"}}>
    <span></span>
</p>

<p  style={{textAlign:"justify"}}>
    <span>
        Incorporation by Reference. These Terms and
        the rights and obligations contained herein are in addition to and incorporate
        the <a href={`${routeName}/privacy`} target="_blank">Privacy Policy</a> by reference,
    </span>
</p>

<p  style={{textAlign:"justify"}}>
    <span></span>
</p>

<p  style={{textAlign:"justify"}}>
    <b>
        <span style={{fontSize:"10.0pt"}}>
            1.<span style={{msoSpacerun:"yes"}}></span><u>General</u>
        </span>
    </b><span style={{fontSize:"10.0pt"}}>
        . <span style={{msoSpacerun:"yes"}}> </span>You are
        responsible for your use of the Services, for any Content you post to the Services,
        and for any consequences arising out of your use of the Services.<span style={{msoSpacerun:"yes"}}></span>Much, if not all, of the Content that you post
        on the Services will be viewable by Users or through third party services and
        websites, and you should only provide Content that you are comfortable sharing
        with others under this Agreement.<span style={{msoSpacerun:"yes"}}>  </span>
    </span>
</p>



<p  style={{textAlign:"justify"}}>
    <span >
        You acknowledge that the Services may not be
        available in all locations, and we may block access to the Services from
        certain locations based on your device’s geolocation information. We may add to
        or remove the areas in which the Services are not available at any time,
        without notice to you.<u><span style={{msoSpacerun:"yes"}}>  </span></u>
    </span><span style={{fontSize:"10.0pt"}}>
        You acknowledge that the Services are evolving and
        that their form and nature may change from time to time without notice to you.<span style={{msoSpacerun:"yes"}}></span>
    </span><span lang="EN">
        In addition, we may stop (permanently or temporarily)
        providing either the Website or App, or any features within the Services) to you
        or to Users generally at our sole discretion and may not be able to provide you
        with prior notice.
    </span><span style={{fontSize:"10.0pt"}}></span>
</p>

<p  style={{textAlign:"justify"}}><span style={{fontSize:"10.0pt"}}></span></p>

<p  >
    <b>
        <span style={{fontSize:"10.0pt"}}>
            2.<span style={{msoSpacerun:"yes"}}>  </span><u>
                Access
                and Use Terms
            </u>
        </span>
    </b><span style={{fontSize:"10.0pt"}}>
        .<span style={{msoSpacerun:"yes"}}></span><b></b>
    </span>
</p>

<p  style={{textAlign:"justify"}}>
    <span style={{fontSize:"10.0pt"}}>
        (a)<span style={{msoSpacerun:"yes"}}></span><u>General Access and Use Rights</u>.<span style={{msoSpacerun:"yes"}}></span>Company hereby grants you a personal,
        non-exclusive, non-transferable, non-sublicensable, revocable, limited right to
        access or use the Services solely for the purposes of (a) browsing the Services
        and searching for, viewing, registering for or purchasing tickets or making
        donations to an event that is registered on the Services; (b) communicate with
        other Users via our Services pursuant to this Agreement; or (c) if you are an event
        organizer, creating event registration, featured guest profile, organizer
        profile, fundraising and other webpages with respect to, and promoting,
        managing, tracking, and collecting sales proceeds for an event, in each case
        (i) in compliance with this Agreement; and (ii) to the extent permitted under
        all applicable local, state, provincial, national and other laws, rules and
        regulations. 
    </span>
</p>

<p  style={{textAlign:"justify"}}><span style={{fontSize:"10.0pt"}}></span></p>

<p  style={{textAlign:"justify"}}>
    <span style={{fontSize:"10.0pt"}}>
        Notwithstanding
        the foregoing, you shall not, and shall not permit anyone else to, directly or
        indirectly (a) copy, modify, reproduce, translate, localize, port or otherwise
        create derivatives of any part of the Services; (b) reverse engineer,
        disassemble, decompile or otherwise attempt to discover the source code or
        structure, sequence and organization of all or any part of the Services (except
        that this restriction shall not apply to the limited extent restrictions on
        reverse engineering are prohibited by applicable local, state, provincial,
        national or other law, rule or regulation); (c) rent, lease, resell,
        distribute, use the Services for timesharing, service bureau or other commercial
        purposes not contemplated by this paragraph or otherwise exploit the Services
        in any unauthorized or unintended manner; (d) remove or alter any proprietary
        notices or labels on or in the Services; or (e) engage in any activity that
        interferes with or disrupts the Services. Any rights not expressly granted in
        this paragraph are reserved. 
    </span>
</p>

<p  style={{textAlign:"justify"}}><span style={{fontSize:"10.0pt"}}></span></p>

<p  style={{textAlign:"justify"}}>
    <span style={{fontSize:"10.0pt"}}>
        In
        order to use certain features of the Services, you must register as a User with
        us.<span style={{msoSpacerun:"yes"}}>  </span>For registered Users, you access of
        the Services <span style={{msoSpacerun:"yes"}}> </span>must be via login credentials
        provided by us (“Login Credentials”).<span style={{msoSpacerun:"yes"}}>  </span>You
        agree that you are responsible for protecting your Login Credentials from
        unauthorized use, and you are responsible for all activity that occurs under
        those Login Credentials. You agree to notify us immediately at support@ubme.com
        if you believe that any of your Login Credentials has been or may be used
        without your permission so that appropriate action can be taken. You may not
        create more than one account to access the Services, share your Login
        Credentials with any third party or transfer your account with us to any third
        party.<span style={{msoSpacerun:"yes"}}>  </span>Company is not responsible for
        any loss or damage caused by, or expense incurred by you as a result of your
        failure to safeguard your Login Credentials.<span style={{msoSpacerun:"yes"}}>
        </span>You agree that you shall not rent, resell, or remarket the Services or
        provide access to the Services to any third party.<span style={{msoSpacerun:"yes"}}></span>
    </span>
</p>

<p  style={{textAlign:"justify"}}><span style={{fontSize:"10.0pt"}}></span></p>

<p  style={{textAlign:"justify"}}>
    <span style={{fontSize:"10.0pt"}}>
        You
        may use the Services to post an event that you think will be interesting to other
        Users of the Services.<span style={{msoSpacerun:"yes"}}>  </span>You agree to post
        truthful information about any event that you post.<span style={{msoSpacerun:"yes"}}></span>If there is a website link for the event that
        you post, we ask that you include the website link so that other Users may find
        out more information about that event.<span style={{msoSpacerun:"yes"}}>  </span>If
        you post an event on our Services, and the event is cancelled, you agree to cancel
        your event on the Services at least 24 hours prior to the time that your event
        is scheduled to occur.<span style={{msoSpacerun:"yes"}}>  </span>If you
        consistently post events that are cancelled on the Services less than 24 hours
        before such event was scheduled to occur or do not cancel your event on the Services,
        then Company may terminate your Login Credentials and this Agreement in its
        sole discretion or restrict your ability to post events on the Services, and you
        will not be entitled to create a new account to access the Services if your
        prior Login Credentials have been terminated or restricted by the Company.
    </span>
</p>

<p  style={{textAlign:"justify"}}><span style={{fontSize:"10.0pt"}}></span></p>

<p  style={{textAlign:"justify"}}>
    <span style={{fontSize:"10.0pt"}}>
        (b)<span style={{msoSpacerun:"yes"}}></span><u>Communications and Content</u>.<span style={{msoSpacerun:"yes"}}></span>In connection with obtaining your Login
        Credentials, you may opt to receive emails from third party service providers
        and websites.<span style={{msoSpacerun:"yes"}}>  </span>If you prefer not to
        receive such emails, you may opt out by following the instructions in any such
        email or on our Services and you will be unsubscribed from receiving such
        emails in the future.
    </span>
</p>

<p  style={{textAlign:"justify"}}><span style={{fontSize:"10.0pt"}}></span></p>

<p  style={{textAlign:"justify"}}>
    <span style={{fontSize:"10.0pt"}}>
        You
        may submit comments, suggestions, questions, or other information for
        publication and distribution to other Users or the general public, as the case
        may be, so long as such content is not unlawful, harmful, threatening, abusive,
        harassing, defamatory, vulgar, offensive, obscene, pornographic, hateful or
        threatening to any group defined by race, religion, gender, national origin or
        sexual orientation, or otherwise objectionable, including without limitation
        blatant expressions of bigotry, prejudice, racism, hatred or excessive
        profanity or post any obscene, lewd, lascivious, excessively violent, harassing
        or otherwise objectionable content.<span style={{msoSpacerun:"yes"}}>  </span>If you
        decide to submit Content on the Services, you grant us an irrevocable,
        transferable, sublicensable, non-exclusive and royalty-free right to use,
        modify, reproduce, publish, disclose, display or adapt such Content throughout
        the world at our sole discretion. 
    </span>
</p>

<p  style={{textAlign:"justify"}}><span style={{fontSize:"10.0pt"}}></span></p>

<p  style={{textAlign:"justify"}}>
    <span style={{fontSize:"10.0pt"}}>
        Content
        on the Services may also be generated through web scraping and/or third-party
        websites (e.g., “Content Curation”).<span style={{msoSpacerun:"yes"}}>  </span>Content
        Curation is an automated process, where computers search or import Content that
        we believe may be of interest to our Users.<span style={{msoSpacerun:"yes"}}>
        </span>We will arrange for information regarding those events to be posted on
        our Services but cannot guarantee the appropriateness or accuracy such Content.
    </span>
</p>

<p  style={{textAlign:"justify"}}><span style={{fontSize:"10.0pt"}}></span></p>

<p  style={{textAlign:"justify"}}>
    <span style={{fontSize:"10.0pt"}}>
        Company
        does not assume any liability or responsibility for Content submitted by you,
        other Users, Content Curation, or third party content or materials posted or
        submitted on the Services.<span style={{msoSpacerun:"yes"}}>  </span>Company
        reserves the right to remove or edit Content, but is not obligated to review Content.<span style={{msoSpacerun:"yes"}}></span>Any use or reliance on any content or
        materials displayed on the Services is at your own risk.<span style={{msoSpacerun:"yes"}}></span>You agree and acknowledge that by using the Services,
        attending any event, or interacting with any other Users, including and
        especially offline, in-person interactions, you may be exposed to Content,
        materials, or experiences that might be offensive, harmful, inaccurate,
        misleading, deceptive, or otherwise inappropriate.<span style={{msoSpacerun:"yes"}}></span>Company does not endorse, support, represent
        or guarantee the completeness, truthfulness, accuracy, safety, or reliability
        of any Content or communications, or endorse any opinions, posted on the Services,
        and under no circumstances will Company be liable in any way for any Content or
        the acts of any of our Users.<span style={{msoSpacerun:"yes"}}>  </span>For example,
        but without limitation, the group running an event may cancel it, change the
        time or change the location, and our Services may not reflect the latest
        information on that event.<span style={{msoSpacerun:"yes"}}>  </span>We encourage
        you to validate the location and time of each event and verify any information
        you receive from other Users, through online and offline means, before acting
        upon it – especially as it relates to meeting Users offline, whether in public
        or private.<span style={{msoSpacerun:"yes"}}>  </span>COMPANY ENCOURAGES YOU TO
        USE CAUTION BEFORE YOU GO TO ANY EVENT OR MEET OR COMMUNICATE WITH USERS ONLINE
        OR OFFLINE; WE DO NOT VERIFY THAT ANY EVENT OR USER IS SAFE, REASONABLE, OR
        SUITABLE FOR YOU TO MEET OR ATTEND.<span style={{msoSpacerun:"yes"}}>  </span>WE
        DO NOT CONDUCT CRIMINAL BACKGROUND CHECKS OF USERS.<span style={{msoSpacerun:"yes"}}></span>We reserve the right to conduct a criminal
        background check on you, and you hereby authorize such criminal background
        check.<span style={{msoSpacerun:"yes"}}>  </span>WE DO NOT MAKE ANY
        REPRESENTATIONS OR WARRANTIES ABOUT ANY USERS OR EVENTS, INCLUDING WITHOUT
        LIMITATION REGARDING THE ACCURACY, COMPLETENESS OR USEFULNESS OF ANY
        INFORMATION REGARDING SUCH USER PROFILES OR EVENTS, WHETHER POSTED BY USERS OR
        OTHERWISE.
    </span>
</p>

<p  style={{textAlign:"justify"}}><span style={{fontSize:"10.0pt"}}></span></p>

<p  style={{textAlign:"justify"}}>
    <span style={{fontSize:"10.0pt"}}>
        We
        have the right, but not the obligation, to monitor Content submitted to our Services
        through an online discussion forum or chat room to determine compliance with this
        Agreement and any other applicable rules that we may establish.<span style={{msoSpacerun:"yes"}}></span>We have the right in our sole discretion to
        edit or remove any material submitted to or posted in any online discussion
        forum or chat room provided through the Services, or any other Content
        submitted to the Website or App.<span style={{msoSpacerun:"yes"}}>  </span>Without
        limiting the foregoing, we have the right to remove any material that we, in our
        sole discretion, find to be in violation of this Agreement or otherwise
        objectionable, and you are solely responsible for the Content that you post to
        the Services.<span style={{msoSpacerun:"yes"}}>  </span>
    </span>
</p>

<p  style={{textAlign:"justify"}}>
    <span style={{fontSize:"10.0pt"}}>
        <span style={{msoSpacerun:"yes"}}></span>
    </span>
</p>

<p >
    <span style={{fontSize:"10.0pt"}}>
        (c)<span style={{msoSpacerun:"yes"}}></span><u>
            User-Specific Representations; Rights in your
            Content
        </u>.<span style={{msoSpacerun:"yes"}}>  </span>you acknowledge and agree
        that if you contribute, provide or make available any Content to the Services
        (“your Content”), you hereby grant Company a non-exclusive, worldwide,
        perpetual, irrevocable, royalty-free, transferable, sublicensable (through
        multiple tiers) right and license to access, use, reproduce, transmit, adapt,
        modify, perform, display, distribute, translate, publish and create derivative
        works based on your Content, in whole or in part, in any media, for the purpose
        of operating the Services (including our promotional and marketing services),
        and you hereby waive any and all moral right to use the name you submit with your
        Content. <span style={{msoSpacerun:"yes"}}>  </span>You represent and warrant that
        you have all the rights, power and authority necessary to grant the foregoing
        license, and that all your Content (a) does not infringe, violate,
        misappropriate or otherwise conflict with the rights of any third party; (b)
        complies with all applicable local, state, provincial, national and other laws,
        rules and regulations; and (c) does not violate these Terms, including without
        limitation our <a href={`${routeName}/privacy`} target="_blank">Privacy Policy</a>. In
        addition, your Content must be accurate and truthful.
    </span>
</p>

<p ><span style={{fontSize:"10.0pt"}}></span></p>

<p >
    <span style={{fontSize:"10.0pt"}}>
        We reserve the right to
        remove any of your Content from the Services at any time if we believe in our
        sole discretion that it violates this Agreement, including without limitation,
        these Terms or our <a href={`${routeName}/privacy`} target="_blank">Privacy Policy</a>.
        In addition, you agree that we may use your name and logo (whether or not you
        have made it available through the Services) for the purpose of identifying you
        as an existing or past customer of ours both on the Services and in marketing,
        advertising and promotional materials.
    </span>
</p>

<p ><span style={{fontSize:"10.0pt"}}></span></p>

<p >
    <span style={{fontSize:"10.0pt"}}>
        Finally, you represent and
        warrant that if you use the Services to post events or interact with Users, you
        will only act in your personal capacity and not as Company’s or any other
        party’s employee, agent, franchisee, or servant.<span style={{msoSpacerun:"yes"}}></span>You shall not hold yourself out as having
        authority to make contracts or promises on behalf of <span style={{msoSpacerun:"yes"}}></span>the Company.<span style={{msoSpacerun:"yes"}}>
        </span>
    </span>
</p>

<p ><span style={{fontSize:"10.0pt"}}></span></p>

<p  style={{textAlign:"justify"}}>
    <span style={{fontSize:"10.0pt"}}>
        (d)<span style={{msoSpacerun:"yes"}}></span><u>Privacy</u>.<span style={{msoSpacerun:"yes"}}></span>You acknowledge and agree that your Content
        may be aggregated with the data of others that submit any data or information
        to Company or our Services in a manner that does not identify you (the
        “Aggregated Data”) and that Company may sell or use for any purpose such
        Aggregated Data, without limitation hereunder.<span style={{msoSpacerun:"yes"}}>
        </span>If you object to your Content being used in a manner permitted by this
        Agreement and the <a href={`${routeName}/privacy`} target="_blank">Privacy Policy</a>, you
        may not use the Services.<span style={{msoSpacerun:"yes"}}>  </span>Upon
        termination of your Login Credentials for any reason, you may no longer have
        access to any of the Content that you provided to the Services.
    </span>
</p>

<p  style={{textAlign:"justify"}}>
    <span style={{fontSize:"10.0pt"}}>
        <span style={{msoSpacerun:"yes"}}></span>
    </span>
</p>

<p  style={{textAlign:"justify"}}>
    <span style={{fontSize:"10.0pt"}}>
        (e)<span style={{msoSpacerun:"yes"}}></span><u>Term</u>.<span style={{msoSpacerun:"yes"}}>
        </span>This Agreement shall be in effect until terminated by either you or the Company
        as follows.<span style={{msoSpacerun:"yes"}}>  </span>Except to the extent you
        have agreed otherwise in a separate written agreement between you and an
        authorized a Company officer, you may terminate your access to the Services and
        these Terms by deleting your account. If you need help deleting your account,
        please contact us at support@ubme.com. In the event there is a separate
        agreement between you and the Company governing your use of the Services and
        that agreement terminates or expires, these Terms (as unmodified by such
        agreement) shall govern your use of the Services unless and until you delete
        your account.
    </span>
</p>

<p  style={{textAlign:"justify"}}><span style={{fontSize:"10.0pt"}}></span></p>

<p  style={{textAlign:"justify"}}>
    <span style={{fontSize:"10.0pt"}}>
        Except
        to the extent we have agreed otherwise in a separate written agreement between
        you and a Company officer, we may terminate your right to use the Services at
        any time for (a) your violation or breach of these Terms; (b) your misuse or
        abuse of the Services; or (c) if allowing you to access and use the Services
        would violate any applicable local, state, provincial, national and other laws,
        rules and regulations or would expose us to legal liability; (d) at any other
        time in that we decide in our sole discretion. We will use reasonable efforts
        to provide you notice of any such termination. Further, you agree that we shall
        not be liable to you or any third-party for any such termination of your right
        to use or otherwise access the Services.
    </span>
</p>

<p  style={{textAlign:"justify"}}><span style={{fontSize:"10.0pt"}}></span></p>

<p  style={{textAlign:"justify"}}>
    <span style={{fontSize:"10.0pt"}}>
        All
        provisions of this Agreement that by their nature should survive termination of
        these Terms shall survive (including, without limitation, all limitations on
        liability, releases, indemnification obligations, disclaimers of warranties,
        agreements to arbitrate, choices of law and judicial forum and intellectual
        property protections and licenses).
    </span>
</p>

<p  style={{textAlign:"justify"}}><span style={{fontSize:"10.0pt"}}></span></p>

<p  style={{textAlign:"justify"}}><span style={{fontSize:"10.0pt"}}></span></p>

<p  >
    <b>
        <span style={{fontSize:"10.0pt"}}></span>
    </b>
</p>

<p  >
    <b>
        <span style={{fontSize:"10.0pt"}}>3.<span style={{msoSpacerun:"yes"}}>  </span><u>Charges</u></span>
    </b><span style={{fontSize:"10.0pt"}}>
        . <span style={{msoSpacerun:"yes"}}> </span>Using our Services
        is currently free.<span style={{msoSpacerun:"yes"}}>  </span>We may choose to
        change our policy regarding fees from time to time.<span style={{msoSpacerun:"yes"}}></span>You may have to pay fees to attend an event
        that you find through our Services or to access additional portions of our Services.<span style={{msoSpacerun:"yes"}}></span>We cannot and do not verify whether an event
        is paid or free; you are responsible for verifying whether an event is paid or
        free and purchasing any necessary tickets.<b></b>
    </span>
</p>

<p  >
    <span style={{fontSize:"10.0pt"}}></span>
</p>

<p  >
    <b>
        <span style={{fontSize:"10.0pt"}}>
            4.<span style={{msoSpacerun:"yes"}}>  </span><u>
                Standards
                of Conduct and Applicable Law
            </u>
        </span>
    </b><b>
        <span >
            .<span style={{msoSpacerun:"yes"}}></span>
        </span>
    </b>
</p>

<p  >
    <b>
        <span ></span>
    </b>
</p>

<p  >
    <span >
        (a)<span style={{msoSpacerun:"yes"}}>
        </span><u>Conduct</u>.<span style={{msoSpacerun:"yes"}}>  </span>You may not use the
        Services to:
    </span>
</p>

<p  >
    <b>
        <span ></span>
    </b>
</p>

<p >
    <span >
        <span >
            (1)<span >&nbsp;&nbsp;&nbsp;</span>
        </span>
    </span><span >
        post any Content or transmit via or
        through the Services any information, data, text, images, files, links,
        software, chat, communication or other content that is, or which Company
        considers in its sole discretion to be, unlawful, harmful, threatening,
        abusive, harassing, defamatory, vulgar, offensive, obscene, pornographic,
        hateful or threatening to any group defined by race, religion, gender, national
        origin or sexual orientation, or otherwise objectionable, including without
        limitation blatant expressions of bigotry, prejudice, racism, hatred or
        excessive profanity or post any obscene, lewd, lascivious, excessively violent,
        harassing or otherwise objectionable content;
    </span>
</p>

<p >
    <span >
        <span >
            (2)<span >&nbsp;&nbsp;&nbsp;</span>
        </span>
    </span><span >
        introduce viruses, worms, Trojan
        horses, or harmful code to the Services or on the Internet;
    </span>
</p>

<p >
    <span >
        <span >
            (3)<span >&nbsp;&nbsp;&nbsp;</span>
        </span>
    </span><span >
        display material that exploits
        children under 18 years of age;
    </span>
</p>

<p >
    <span >
        <span >
            (4)<span >&nbsp;&nbsp;&nbsp;</span>
        </span>
    </span><span >
        post any content or otherwise
        infringe in any way or violate any copyright, patent, <span >trademark</span>, service
        mark, trade name, trade secret or other intellectual property right of any
        third party;
    </span>
</p>

<p >
    <span >
        <span >
            (5)<span >&nbsp;&nbsp;&nbsp;</span>
        </span>
    </span><span >
        harass, embarrass, or cause distress
        or discomfort upon another Diner;
    </span>
</p>

<p >
    <span >
        <span >
            (6)<span >&nbsp;&nbsp;&nbsp;</span>
        </span>
    </span><span >
        impersonate any other person,
        including but not limited to, another User or Company official;
    </span>
</p>

<p >
    <span >
        <span >
            (7)<span >&nbsp;&nbsp;&nbsp;</span>
        </span>
    </span><span >
        post or disclose any personally
        identifying information or <span tagtype="5"
                                                                      >private</span> information about
        children, User, or any third parties without their consent (or their parent's
        consent in case of a child under 18 years of age);
    </span>
</p>

<p >
    <span >
        <span >
            (8)<span >&nbsp;&nbsp;&nbsp;</span>
        </span>
    </span><span >
        use the Services for the purpose of
        spamming anyone or for soliciting personal information, including credit card
        numbers;
    </span>
</p>

<p >
    <span >
        <span >
            (9)<span >&nbsp;&nbsp;&nbsp;</span>
        </span>
    </span><span >
        intentionally or unintentionally
        violate any applicable local, state, national or international law, including
        but not limited to any regulations having the force of law while using or
        accessing the Services or in connection with your use of the Services, in any
        manner; 
    </span>
</p>

<p >
    <span >
        <span >
            (10)<span ></span>
        </span>
    </span><span >
        invade the privacy or violate any
        personal or proprietary right (including intellectual property rights) of any
        person or entity; or
    </span>
</p>

<p >
    <span >
        <span >
            (11)<span ></span>
        </span>
    </span><span >
        collect, save or otherwise download Content
        from our Services using automated means such as bots, robots, or scrapers.
    </span>
</p>

<p  >
    <span >
        Company reserves the right, without
        prior notice and in its sole discretion, to decide whether your Content
        violates these terms and conditions for any of the above reasons or for any
        other reason, and if Company does so, to remove such Content and/or terminate your
        access to certain areas of the Services.
    </span>
</p>

<p  >
    <span ></span>
</p>

<p  >
    <span >
        (b)<span style={{msoSpacerun:"yes"}}>
        </span><u>Applicable Law</u>.<span style={{msoSpacerun:"yes"}}>  </span>Your use
        of the Services is subject to all applicable, local, state, national laws and
        regulations.<span style={{msoSpacerun:"yes"}}>  </span>You may only use the Services
        for lawful purposes. <span style={{msoSpacerun:"yes"}}> </span>You shall not use
        or allow others to use the Services in any manner that attempts to, or is
        likely to, violate any applicable laws or regulations or violate or infringe
        any intellectual property rights, rules of publicity or privacy.<span style={{msoSpacerun:"yes"}}></span>If you share Content using the Services, then
        you are solely responsible for ensuring that such Content conforms to the
        standards of conduct set forth herein.<span style={{msoSpacerun:"yes"}}>  </span>
    </span>
</p>

<p  >
    <span >
        <span style={{msoSpacerun:"yes"}}></span>
    </span>
</p>

<p  >
    <b>
        <span style={{fontSize:"10.0pt"}}>
            5.<span style={{msoSpacerun:"yes"}}>  </span><u>
                Intellectual
                Property Rights; Restrictions
            </u>
        </span>
    </b><span >.</span><span style={{fontSize:"10.0pt"}}></span>
</p>

<p  >
    <span style={{fontSize:"10.0pt"}}>
        (a) <u>Restrictions</u>.<span style={{msoSpacerun:"yes"}}></span>You shall not, and shall not permit any
        person or entity to: use the Services on a service bureau, time sharing or any
        similar basis, or for the benefit of any other person or entity; alter,
        enhance, or make derivative works of the Services; reverse engineer, reverse
        assemble or decompile, or otherwise attempt to derive source code from, the Services
        or any software component of the Services; use, or allow the use of, the Services
        for any unfair or deceptive practices or in contravention of any federal,
        state, local, foreign, or other applicable law, or rules and regulations of
        regulatory or administrative organizations; and/or act in a fraudulent,
        tortious, malicious, or negligent manner when using the Services.<span style={{msoSpacerun:"yes"}}></span>
    </span>
</p>

<p  >
    <span style={{fontSize:"10.0pt"}}></span>
</p>

<p  >
    <span style={{fontSize:"10.0pt"}}>
        (b)<span style={{msoSpacerun:"yes"}}>  </span><u>Ownership</u>.<span style={{msoSpacerun:"yes"}}></span>You acknowledge that the structure,
        organization, and code used in conjunction with the Services are proprietary to
        Company and that Company and/or its licensors, if any, retain exclusive
        ownership of the Services, documentation, and any other intellectual property
        rights relating to the Services, including without limitation all modifications,
        enhancements, derivatives, and other software and materials relating to the Services,
        and all copies thereof.<span style={{msoSpacerun:"yes"}}>  </span>You shall not
        sell, transfer, publish, disclose, display or otherwise make available the Services
        including without limitation any modifications, enhancements, derivatives, and
        other software and materials provided hereunder by Company or copies thereof to
        others in violation of this Agreement.<span style={{msoSpacerun:"yes"}}>  </span>
    </span>
</p>

<p  >
    <span style={{fontSize:"10.0pt"}}></span>
</p>

<p  >
    <span style={{fontSize:"10.0pt"}}>
        (c)<span style={{msoSpacerun:"yes"}}>  </span><u>
            No Other
            Rights
        </u>.<span style={{msoSpacerun:"yes"}}>  </span>Except as expressly set
        forth in this Agreement or other written agreement between you and Company, no
        license or other right in or to the Services or any content provided thereon
        are granted to you, by implication, estoppel, or otherwise, and all such
        licenses and rights are hereby reserved.
    </span>
</p>

<p  >
    <span style={{fontSize:"10.0pt"}}></span>
</p>

<p  >
    <span style={{fontSize:"10.0pt"}}>
        (d)<span style={{msoSpacerun:"yes"}}>  </span><u>
            Feedback
            and Suggestions
        </u>.<span style={{msoSpacerun:"yes"}}>  </span>You hereby
        acknowledge that (a) any and all suggestions for correction, change and
        modification to the Services and other feedback (including but not limited to
        quotations of written or oral feedback), information and reports provided to Company
        by you (collectively “Feedback”); and (b) all improvements, updates,
        modifications or enhancements, whether made, created or developed by Company or
        otherwise relating to Feedback (collectively, “Revisions”), are and will remain
        the Company’s property. 
    </span>
</p>

<p  >
    <span style={{fontSize:"10.0pt"}}></span>
</p>

<p  >
    <span style={{fontSize:"10.0pt"}}>
        All Feedback and Revisions become the sole and
        exclusive property of Company, and we may use and disclose Feedback, Revisions,
        or both in any manner and for any purpose whatsoever without further notice or
        compensation to you and without retention by you of any proprietary or other
        right or claim. you hereby assign to us any and all right, title and interest
        (including, but not limited to, any patent, copyright, future copyright, trade
        secret, trademark, show-how, know-how and any and all other intellectual property
        right) that you may have in and to any and all Feedback and Revisions. You
        hereby agree to waive any moral rights you may have in any and all Feedback and
        Revisions, and consent to any act which amounts to an infringement of any such
        moral right, in our favor. At Company’s request, you will execute any document,
        registration, or filing required to give effect to these provisions. 
    </span>
</p>

<p  style={{textAlign:"justify"}}><b><span style={{fontSize:"10.0pt"}}></span></b></p>

<p  style={{textAlign:"justify"}}>
    <b>
        <span style={{fontSize:"10.0pt"}}>
            6.<span style={{msoSpacerun:"yes"}}></span><u>Limited Warranty</u>
        </span>
    </b><b ><span style={{fontSize:"10.0pt"}}>.</span></b><span style={{fontSize:"10.0pt"}}><span style={{msoSpacerun:"yes"}}>  </span></span>
</p>

<p  style={{textAlign:"justify"}}><span style={{fontSize:"10.0pt"}}></span></p>

<p  style={{textAlign:"justify"}}>
    <span style={{fontSize:"10.0pt"}}>
        (a)<span style={{msoSpacerun:"yes"}}></span><u>Warranties; Disclaimer</u>.<span style={{msoSpacerun:"yes"}}></span>As a condition of your use of our Services, you
        warrant that (i) you are at least 13 years of age and a legal resident of the
        US or Canada; (ii) if you are older than 13 but not yet 18 years of age, you
        will not use our Services without the close supervision of a parent or legal
        guardian; (iii) you possess or will acquire the legal authority to create a
        binding legal obligation; (iv) you will use this Services in accordance with this
        Agreement; (v) all information supplied by you on or through our Services is
        true, accurate, current and complete, and (vi) if you have an account with us, you
        will safeguard your account information, including Login Credentials, and will
        supervise and be completely responsible for any use of your account by you and
        anyone other than you. We retain the right at our sole discretion to deny you,
        any other User, or anyone else access to the Services, at any time and for any
        reason, including without limitation for violation of this Agreement.
    </span>
</p>

<p  style={{textAlign:"justify"}}><span style={{fontSize:"10.0pt"}}></span></p>

<p  style={{textAlign:"justify"}}>
    <span style={{fontSize:"10.0pt"}}>
        (b)<span style={{msoSpacerun:"yes"}}></span><u>Disclaimers</u>.<span style={{msoSpacerun:"yes"}}></span>YOU USE OUR SERVICES AT YOUR OWN RISK AND
        SUBJECT TO THE FOLLOWING DISCLAIMERS. WE ARE PROVIDING OUR SERVICES ON AN “AS
        IS” BASIS WITHOUT ANY EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED
        TO, WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE,
        NON-INFRINGEMENT, AND FREEDOM FROM COMPUTER VIRUS OR OTHER HARMFUL CODE. WE DO
        NOT WARRANT THAT ANY INFORMATION PROVIDED BY US IS ACCURATE, COMPLETE, OR
        USEFUL, THAT OUR SERVICES WILL BE OPERATIONAL, ERROR FREE, SECURE, OR SAFE, OR
        THAT OUR SERVICES WILL FUNCTION WITHOUT DISRUPTIONS, DELAYS, OR IMPERFECTIONS.
        WE DO NOT CONTROL, AND ARE NOT RESPONSIBLE FOR, CONTROLLING HOW OR WHEN OUR
        USERS USE OUR SERVICES OR THE FEATURES, SERVICES, AND INTERFACES OUR SERVICES
        PROVIDE. WE ARE NOT RESPONSIBLE FOR AND ARE NOT OBLIGATED TO CONTROL THE
        ACTIONS OR INFORMATION (INCLUDING CONTENT) OF OUR USERS OR OTHER THIRD PARTIES.
        YOU RELEASE US, OUR SUBSIDIARIES, AFFILIATES, AND OUR AND THEIR DIRECTORS,
        OFFICERS, EMPLOYEES, PARTNERS, AND AGENTS (TOGETHER, THE “UBME PARTIES”) FROM
        ANY CLAIM, COMPLAINT, CAUSE OF ACTION, CONTROVERSY, OR DISPUTE (TOGETHER,
        “CLAIM”) AND DAMAGES, KNOWN AND UNKNOWN, RELATING TO, ARISING OUT OF, OR IN ANY
        WAY CONNECTED WITH ANY SUCH CLAIM YOU HAVE AGAINST ANY THIRD PARTIES. YOU WAIVE
        ANY RIGHTS YOU MAY HAVE UNDER CALIFORNIA CIVIL CODE §1542, OR ANY OTHER SIMILAR
        APPLICABLE STATUTE OR LAW OF ANY OTHER JURISDICTION, WHICH SAYS THAT: A GENERAL
        RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT
        TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH IF
        KNOWN BY HIM OR HER MUST HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH
        THE DEBTOR.
    </span>
</p>

<p  style={{textAlign:"justify"}}><span style={{fontSize:"10.0pt"}}></span></p>

<p  style={{textAlign:"justify"}}>
    <span style={{fontSize:"10.0pt"}}>
        NOTHING
        IN THESE TERMS OF SERVICE IS INTENDED TO EXCLUDE OR LIMIT ANY CONDITION,
        WARRANTY, RIGHT OR LIABILITY WHICH MAY NOT BE LAWFULLY EXCLUDED OR LIMITED.
        SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR
        CONDITIONS OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR LOSS OR DAMAGE
        CAUSED BY WILLFUL ACTS, NEGLIGENCE, BREACH OF CONTRACT OR BREACH OF IMPLIED
        TERMS, OR INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, ONLY THOSE
        LIABILITY AND OTHER LIMITATIONS WHICH ARE LAWFUL IN YOUR JURISDICTION (IF ANY)
        WILL APPLY TO YOU AND OUR LIABILITY IS LIMITED TO THE MAXIMUM EXTENT PERMITTED
        BY LAW.
    </span>
</p>

<p  style={{textAlign:"justify"}}><span style={{fontSize:"10.0pt"}}></span></p>

<p  style={{textAlign:"justify"}}><span style={{fontSize:"10.0pt"}}></span></p>

<p  style={{textAlign:"justify"}}>
    <b>
        <span style={{fontSize:"10.0pt"}}>
            7.<span style={{msoSpacerun:"yes"}}></span><u>Limitation of Liability</u>. <span style={{msoSpacerun:"yes"}}></span>
        </span>
    </b><span style={{fontSize:"10.0pt"}}>
        THE UBME
        PARTIES WILL NOT BE LIABLE TO YOU FOR ANY LOST PROFITS OR CONSEQUENTIAL,
        SPECIAL, PUNITIVE, INDIRECT, OR INCIDENTAL DAMAGES RELATING TO, ARISING OUT OF,
        OR IN ANY WAY IN CONNECTION WITH OUR TERMS, US, OR OUR SERVICES, EVEN IF THE UBME
        PARTIES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. OUR AGGREGATE
        LIABILITY RELATING TO, ARISING OUT OF, OR IN ANY WAY IN CONNECTION WITH OUR
        TERMS, US, OR OUR SERVICES WILL NOT EXCEED THE GREATER OF ONE HUNDRED DOLLARS
        ($100) OR THE AMOUNT YOU HAVE PAID US IN THE PAST TWELVE MONTHS. THE FOREGOING
        DISCLAIMER OF CERTAIN DAMAGES AND LIMITATION OF LIABILITY WILL APPLY TO THE
        MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW. THE LAWS OF SOME STATES OR
        JURISDICTIONS MAY NOT ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES, SO
        SOME OR ALL OF THE EXCLUSIONS AND LIMITATIONS SET FORTH ABOVE MAY NOT APPLY TO
        YOU. NOTWITHSTANDING ANYTHING TO THE CONTRARY IN OUR TERMS, IN SUCH CASES, THE
        LIABILITY OF THE UBME PARTIES WILL BE LIMITED TO THE FULLEST EXTENT PERMITTED
        BY APPLICABLE LAW.
    </span>
</p>

<p  style={{textAlign:"justify"}}><span style={{fontSize:"10.0pt"}}></span></p>

<p  style={{textAlign:"justify"}}><span style={{fontSize:"10.0pt"}}></span></p>

<p  style={{textAlign:"justify"}}><span style={{fontSize:"10.0pt"}}></span></p>

<p  style={{textAlign:"justify"}}>
    <b>
        <span style={{fontSize:"10.0pt"}}>
            8.<span style={{msoSpacerun:"yes"}}></span><u>Indemnification</u>
        </span>
    </b><span style={{fontSize:"10.0pt"}}>
        .<span style={{msoSpacerun:"yes"}}>  </span>You agree to
        defend, indemnify and hold UBME Parties harmless from any and all damage
        (whether direct, indirect, incidental, consequential or otherwise), loss,
        liability, cost and expense (including, without limitation, reasonable
        attorneys’ and accounting fees) resulting from any claim, demand, suit,
        proceeding (whether before an arbitrator, court, mediator or otherwise) or
        investigation made by any third party (each a “Claim”) due to or arising out of:
        (a) your breach of these Terms; (b) your improper use of the Services; (c) your
        breach of any applicable local, state, provincial, national or other law, rule
        or regulation or the rights of any third party; or (d) in the case of you
        uploading Content, creating an event, or communicating or interacting with
        Users or anyone else on or through our Services, whether offline or online,
        provided that in the case of (d), this indemnification shall not apply to the
        extent that the Claim arises out of our gross negligence or willful misconduct.
        We shall provide notice to you of any such Claim, provided that the failure or
        delay by Company in providing such notice shall not limit your obligations
        hereunder except to the extent you are materially disadvantaged by such
        failure.
    </span>
</p>

<p  style={{textAlign:"justify"}}>
    <span style={{fontSize:"10.0pt"}}>
        <span style={{msoSpacerun:"yes"}}></span>
    </span>
</p>

<p  style={{textAlign:"justify"}}>
    <span style={{fontSize:"10.0pt"}}>
        A
        party’s obligations to indemnify the other party with respect to any third
        party claim, action or proceeding shall be conditioned upon the indemnified
        party: providing the indemnifying party with prompt written notice of such
        claim, action or proceeding, permitting the indemnifying party to assume and
        solely control the defense of such claim, action or proceeding and all related
        settlement negotiations, with counsel chosen by the indemnifying party, and
        cooperating at the indemnifying party’s request and expense with the defense or
        settlement of such claim, action or proceeding which cooperation shall include
        providing reasonable assistance and information.<span style={{msoSpacerun:"yes"}}></span>No indemnified party shall enter into any
        settlement agreement for which it will seek indemnification under this
        Agreement from the indemnifying party without the prior written consent of the
        indemnifying party.<span style={{msoSpacerun:"yes"}}>  </span>Nothing herein shall
        restrict the right of a party to participate in a claim, action or proceeding
        through its own counsel and at its own expense.
    </span>
</p>

<p  style={{textAlign:"justify"}}><span style={{fontSize:"10.0pt"}}></span></p>

<p  style={{textAlign:"justify"}}>
    <b >
        <span style={{fontSize:"10.0pt"}}>
            9.<span style={{msoSpacerun:"yes"}}>
            </span><u>Forum and Venue</u>
        </span>
    </b><span style={{fontSize:"10.0pt"}}>
        . If you
        are a User located in the United States or Canada, the “Special Arbitration
        Provision for United States or Canada Users” section below applies to you.
        Please also read that section carefully and completely. If you are not subject
        to the “Special Arbitration Provision for United States or Canada Users”
        section below, you agree that you will resolve any Claim you have with us
        relating to, arising out of, or in any way in connection with our Terms, us, or
        our Services (each, a “Dispute,” and together, “Disputes”) exclusively in the
        United States District Court for the District of Massachusetts or a state court
        located in Suffolk County, Massachusetts, and you agree to submit to the
        personal jurisdiction of such courts for the purpose of litigating all such
        Disputes.
    </span>
</p>

<p  style={{textAlign:"justify"}}><span style={{fontSize:"10.0pt"}}></span></p>

<p  style={{textAlign:"justify"}}>
    <b >
        <span style={{fontSize:"10.0pt"}}>
            10.<span style={{msoSpacerun:"yes"}}>
            </span><u>Governing Law</u>
        </span>
    </b><span style={{fontSize:"10.0pt"}}>
        . The laws
        of the State of Delaware govern our Terms, as well as any Disputes, whether in
        court or arbitration, which might arise between the Company and you, without
        regard to conflict of law provisions.
    </span>
</p>

<p  style={{textAlign:"justify"}}><span style={{fontSize:"10.0pt"}}></span></p>

<p  style={{textAlign:"justify"}}>
    <b ><span style={{fontSize:"10.0pt"}}>11.</span></b><span >
        <span style={{msoSpacerun:"yes"}}> </span><b ><u>Special Arbitration Provision for United States or Canada Users</u></b>.
    </span>
</p>

<p  style={{textAlign:"justify"}}><span style={{fontSize:"10.0pt"}}></span></p>

<p  style={{textAlign:"justify"}}>
    <span style={{fontSize:"10.0pt"}}>
        PLEASE
        READ THIS SECTION CAREFULLY BECAUSE IT CONTAINS ADDITIONAL PROVISIONS
        APPLICABLE ONLY TO OUR UNITED STATES AND CANADA USERS. IF YOU ARE A USER
        LOCATED IN THE UNITED STATES OR CANADA, IT REQUIRES YOU TO SUBMIT TO BINDING
        INDIVIDUAL ARBITRATION OF ALL DISPUTES, EXCEPT FOR THOSE THAT INVOLVE
        INTELLECTUAL PROPERTY DISPUTES AND EXCEPT THOSE THAT CAN BE BROUGHT IN SMALL
        CLAIMS COURT. THIS MEANS YOU ARE WAIVING YOUR RIGHT TO HAVE SUCH DISPUTES
        RESOLVED IN COURT BY A JUDGE OR JURY. THIS SECTION ALSO LIMITS THE TIME YOU
        HAVE TO START AN ARBITRATION OR, IF PERMISSIBLE, A COURT ACTION. FINALLY, THIS
        SECTION WAIVES YOUR RIGHT TO HAVE YOUR DISPUTE HEARD AND RESOLVED AS A CLASS
        ACTION, CLASS ARBITRATION, OR A REPRESENTATIVE ACTION.
    </span>
</p>

<p  style={{textAlign:"justify"}}><span style={{fontSize:"10.0pt"}}></span></p>

<p  style={{textAlign:"justify"}}>
    <span style={{fontSize:"10.0pt"}}>
        Note
        that many customer concerns can be resolved quickly and to a customer’s
        satisfaction by contacting us at support@ubme.com. We ask that you please
        contact us if you have any concerns.
    </span>
</p>

<p  style={{textAlign:"justify"}}><span style={{fontSize:"10.0pt"}}></span></p>

<p  style={{textAlign:"justify"}}>
    <span style={{fontSize:"10.0pt"}}>
        “Excluded
        Dispute” means any Dispute relating to the enforcement or infringement of your
        or our intellectual property rights (such as copyrights, trademarks, domains,
        logos, trade dress, trade secrets, and patents). For clarity and
        notwithstanding the foregoing, those Disputes relating to, arising out of, or
        in any way in connection with your rights of privacy and publicity are not
        Excluded Disputes.
    </span>
</p>

<p  style={{textAlign:"justify"}}><span style={{fontSize:"10.0pt"}}></span></p>

<p  style={{textAlign:"justify"}}>
    <span style={{fontSize:"10.0pt"}}>
        Federal
        Arbitration Act. The United States Federal Arbitration Act governs the
        interpretation and enforcement of this “Special Arbitration Provision for
        United States or Canada Users” section, including any question whether a
        Dispute between us and you is subject to arbitration.
    </span>
</p>

<p  style={{textAlign:"justify"}}><span style={{fontSize:"10.0pt"}}></span></p>

<p  style={{textAlign:"justify"}}>
    <span style={{fontSize:"10.0pt"}}>
        Agreement
        to Arbitrate for Users Located in the United States or Canada. For Users
        located in the United States or Canada, Company and you each agree to waive the
        right to a trial by judge or jury for all Disputes, except for the Excluded
        Disputes. Company and you agree that all Disputes (except for the Excluded
        Disputes), including those relating to, arising out of, or in any way in
        connection with your rights of privacy and publicity, will be resolved through
        final and binding arbitration. Company and you agree not to combine a Dispute
        that is subject to arbitration under our Terms with a Dispute that is not
        eligible for arbitration under our Terms.
    </span>
</p>

<p  style={{textAlign:"justify"}}><span style={{fontSize:"10.0pt"}}></span></p>

<p  style={{textAlign:"justify"}}>
    <span style={{fontSize:"10.0pt"}}>
        The
        arbitration will be administered by the American Arbitration Association (“AAA”)
        under its Commercial Arbitration Rules in effect at the time the arbitration is
        started, including the Optional Rules for Emergency Measures of Protection and
        the Supplementary Procedures for Consumer-Related Disputes (together, the “AAA
        Rules”). The arbitration will be presided over by a single arbitrator selected
        in accordance with the AAA Rules. The AAA Rules, information regarding
        initiating a Dispute, and a description of the arbitration process are
        available at www.adr.org. The arbitrator will decide whether a Dispute can be
        arbitrated. The location of the arbitration and the allocation of fees and
        costs for such arbitration shall be determined in accordance with the AAA
        Rules. Notwithstanding the AAA Rules, we will reimburse you for all the AAA
        administrative fees in Disputes that are subject to the Supplementary
        Procedures for Consumer-Related Disputes, unless the arbitrator determines that
        a Dispute was filed for purposes of harassment or is patently frivolous.
    </span>
</p>

<p  style={{textAlign:"justify"}}><span style={{fontSize:"10.0pt"}}></span></p>

<p  style={{textAlign:"justify"}}>
    <span style={{fontSize:"10.0pt"}}>
        Opt-Out
        Procedure. You may opt out of this agreement to arbitrate. If you do so,
        neither we nor you can require the other to participate in an arbitration
        proceeding. To opt out, you must notify us in writing postmarked within 30 days
        of the later of: (i) the date that you first accepted our Terms; and (ii) the
        date you became subject to this arbitration provision. You must use this
        address to opt-out:
    </span>
</p>

<p  style={{textAlign:"justify"}}><span style={{fontSize:"10.0pt"}}></span></p>

<p  style={{textAlign:"justify"}}>
    <span style={{fontSize:"10.0pt"}}>
        UBME,
        Inc. 
    </span>
</p>

<p  style={{textAlign:"justify"}}>
    <span style={{fontSize:"10.0pt"}}>
        Arbitration
        Opt-Out 
    </span>
</p>

<p  style={{textAlign:"justify"}}>
    <span style={{fontSize:"10.0pt"}}>
        543
        West 3<sup>rd</sup> 1a
    </span>
</p>

<p  style={{textAlign:"justify"}}>
    <span style={{fontSize:"10.0pt"}}>
        Bethlehem,
        PA 18015
    </span>
</p>

<p  style={{textAlign:"justify"}}>
    <span style={{fontSize:"10.0pt"}}>
        United
        States of America
    </span>
</p>

<p  style={{textAlign:"justify"}}><span style={{fontSize:"10.0pt"}}></span></p>

<p  style={{textAlign:"justify"}}>
    <span style={{fontSize:"10.0pt"}}>
        You
        must include: (1) your name and residence address; (2) the email address
        associated with your account; and (3) a clear statement that you want to opt
        out of our Terms’ agreement to arbitrate.
    </span>
</p>

<p  style={{textAlign:"justify"}}><span style={{fontSize:"10.0pt"}}></span></p>

<p  style={{textAlign:"justify"}}>
    <span style={{fontSize:"10.0pt"}}>
        Small
        Claims Court. As an alternative to arbitration, if permitted by your local
        “small claims” court’s rules, you may bring your Dispute in your local “small
        claims” court, as long as the matter advances on an individual (non-class)
        basis.
    </span>
</p>

<p  style={{textAlign:"justify"}}><span style={{fontSize:"10.0pt"}}></span></p>

<p  style={{textAlign:"justify"}}>
    <span style={{fontSize:"10.0pt"}}>
        Time
        Limit to Start Arbitration. We and you agree that for any Dispute (except for
        the Excluded Disputes) we and you must commence an arbitration proceeding
        within one year after the Dispute first arose; otherwise, such Dispute is
        permanently barred. This means that if we or you do not commence arbitration
        within one year after the Dispute first arose, then the arbitration will be
        dismissed because it was started too late.
    </span>
</p>

<p  style={{textAlign:"justify"}}><span style={{fontSize:"10.0pt"}}></span></p>

<p  style={{textAlign:"justify"}}>
    <span style={{fontSize:"10.0pt"}}>
        No
        Class Actions, Class Arbitrations, or Representative Actions for Users Located
        in the United States or Canada. We and you each agree that if you are a User of
        our Services located in the United States or Canada, each party (we and you)
        may bring Disputes against the other only on our or your own behalf, and not on
        behalf of any other person or entity, or any class of people. We and you each
        agree not to participate in a class action, a class-wide arbitration, Disputes
        brought in a private attorney general or representative capacity, or
        consolidated Disputes involving any other person or entity in connection with
        any Dispute.
    </span>
</p>

<p  style={{textAlign:"justify"}}><span style={{fontSize:"10.0pt"}}></span></p>

<p  style={{textAlign:"justify"}}>
    <span style={{fontSize:"10.0pt"}}>
        Severability.
        If the prohibition against class actions and other Disputes brought on behalf
        of third parties is found to be unenforceable for a Dispute, then all of the
        provisions above under the caption “Special Arbitration Provision for United
        States or Canada Users” will be null and void as to that Dispute.
    </span>
</p>

<p  style={{textAlign:"justify"}}><span style={{fontSize:"10.0pt"}}></span></p>

<p  style={{textAlign:"justify"}}>
    <span style={{fontSize:"10.0pt"}}>
        Place
        to File Permitted Court Actions. If you opt out of the agreement to arbitrate,
        if your Dispute is an Excluded Dispute, or if the arbitration agreement is
        found to be unenforceable, you agree to be subject to the “Forum and Venue”
        provisions in the “Dispute Resolution” section set forth above.
    </span>
</p>

<p  style={{textAlign:"justify"}}><b><span style={{fontSize:"10.0pt"}}></span></b></p>

<p  >
    <b>
        12.<span style={{msoSpacerun:"yes"}}></span><u>
            Notice and Takedown Procedures; Copyright
            Agent
        </u>
    </b>.<span style={{msoSpacerun:"yes"}}>  </span>Company respects the
    intellectual property of others and asks that Users of our Services do the
    same.<span style={{msoSpacerun:"yes"}}>  </span>In connection with our Services,
    we have adopted and implemented a policy respecting copyright law that provides
    for the removal of any infringing materials and for the termination, in
    appropriate circumstances, of Users of our Services who are repeat infringers
    of intellectual property rights, including copyrights.<span style={{msoSpacerun:"yes"}}></span>If you believe that one of our Users is,
    through the use of our Services, unlawfully infringing the copyright(s) in a
    work, and wish to have the allegedly infringing material removed, the following
    information in the form of a written notification (pursuant to 17 U.S.C. §
    512(c)) must be provided to our designated Copyright Agent:<b>
        <span ></span>
    </b>
</p>

<ol  start="1" type="1">
    <li >
        <span style={{fontSize:"10.0pt"}}>
            your physical or
            electronic signature;
        </span>
    </li>
    <li >
        <span style={{fontSize:"10.0pt"}}>
            identification of the
            copyrighted work(s) that you claim to have been infringed;
        </span>
    </li>
    <li >
        <span style={{fontSize:"10.0pt"}}>
            identification of the
            material on our services that you claim is infringing and that you request
            us to remove;
        </span>
    </li>
    <li >
        <span style={{fontSize:"10.0pt"}}>
            sufficient information
            to permit us to locate such material;
        </span>
    </li>
    <li >
        <span style={{fontSize:"10.0pt"}}>
            your address,
            telephone number, and e-mail address;
        </span>
    </li>
    <li >
        <span style={{fontSize:"10.0pt"}}>
            a statement that you
            have a good faith belief that use of the objectionable material is not
            authorized by the copyright owner, its agent, or under the law; and
        </span>
    </li>
    <li >
        <span style={{fontSize:"10.0pt"}}>
            a statement that the
            information in the notification is accurate, and under penalty of perjury,
            that you are either the owner of the copyright that has allegedly been
            infringed or that you are authorized to act on behalf of the copyright
            owner.
        </span>
    </li>
</ol>

<p  ></p>

<p  style={{textAlign:"justify"}}>
    <span style={{fontSize:"10.0pt"}}>
        Please
        note that, pursuant to 17 U.S.C. § 512(f), any misrepresentation of material
        fact (falsities) in a written notification automatically subjects the
        complaining party to liability for any damages, costs and attorney’s fees
        incurred by us in connection with the written notification and allegation of
        copyright infringement.
    </span>
</p>

<p  style={{textAlign:"justify"}}><span style={{fontSize:"10.0pt"}}></span></p>

<p  style={{textAlign:"justify"}}>
    <span style={{fontSize:"10.0pt"}}>
        UBMe
        Inc.
    </span>
</p>

<p  style={{textAlign:"justify"}}>
    <span style={{fontSize:"10.0pt"}}>
        Attn:
        Copyright Agent
    </span>
</p>

<p  style={{textAlign:"justify"}}>
    <span style={{fontSize:"10.0pt"}}>
        543
        West 3rd Street 1A
    </span>
</p>

<p  style={{textAlign:"justify"}}>
    <span style={{fontSize:"10.0pt"}}>
        Bethlehem,
        PA 18015
    </span>
</p>

<p  style={{textAlign:"justify"}}>
    <span style={{fontSize:"10.0pt"}}>
        Phone:
        973-943-6815
    </span>
</p>

<p  style={{textAlign:"justify"}}>
    <span style={{fontSize:"10.0pt"}}>
        Email:
        <a href="mailto:copyrite@ubme.com">copyrite@ubme.com</a>
    </span>
</p>

<p  style={{textAlign:"justify"}}><b><span style={{fontSize:"10.0pt"}}></span></b></p>

<p  style={{textAlign:"justify"}}>
    <b>
        <span style={{fontSize:"10.0pt"}}>
            13.<span style={{msoSpacerun:"yes"}}></span><u>Miscellaneous</u>.
        </span>
    </b><span style={{fontSize:"10.0pt"}}></span>
</p>

<p  style={{textAlign:"justify"}}><span style={{fontSize:"10.0pt"}}></span></p>

<p  style={{textAlign:"justify"}}>
    <span style={{fontSize:"10.0pt"}}>
        (a)<span style={{msoSpacerun:"yes"}}></span><u>Links to Third-Party Web Sites</u>. The Services
        may contain links to non-Company web sites. These links are provided to you as
        a convenience, and Company is not responsible for the content of any linked web
        site. Any non-Company web site accessed from the Services is independent from Company,
        and Company has no control over the content of that web site. In addition, a
        link to any non-Company web site does not imply that Company endorses or
        accepts any responsibility for the content or use of such web site.
    </span>
</p>

<p  style={{textAlign:"justify"}}><span style={{fontSize:"10.0pt"}}></span></p>

<p  style={{textAlign:"justify"}}>
    <span style={{fontSize:"10.0pt"}}>
        (b)<span style={{msoSpacerun:"yes"}}></span><u>No Implied Endorsements</u>.<span style={{msoSpacerun:"yes"}}></span>In no event shall any reference to any third
        party or third party product or service be construed as an approval or
        endorsement by Company of that third party or of any product or service
        provided by a third party.
    </span>
</p>

<p  style={{textAlign:"justify"}}><span style={{fontSize:"10.0pt"}}></span></p>

<p  style={{textAlign:"justify"}}>
    <span style={{fontSize:"10.0pt"}}>
        (c)<span style={{msoSpacerun:"yes"}}></span><u>Assignment</u>.<span style={{msoSpacerun:"yes"}}></span>You may not assign or transfer this Agreement
        in whole or in part to any third party.<span style={{msoSpacerun:"yes"}}>
        </span>This Agreement shall bind and inure to the benefit of the parties to
        this Agreement and their respective successors, permitted transferees, and
        permitted assigns.<span style={{msoSpacerun:"yes"}}>  </span>
    </span>
</p>

<p  style={{textAlign:"justify"}}><span style={{fontSize:"10.0pt"}}></span></p>

<p  style={{textAlign:"justify"}}>
    <span style={{fontSize:"10.0pt"}}>
        (d)<span style={{msoSpacerun:"yes"}}></span><u>Independent Contractors</u>.<span style={{msoSpacerun:"yes"}}></span>Company and you are not partners, joint
        venturers, agents, employees or representatives of the other party.<span style={{msoSpacerun:"yes"}}></span>This Agreement, together with the <a href={`${routeName}/privacy`} target="_blank">Privacy Policy</a>, any written agreement
        between you and Company, and any other terms and conditions set forth on the Services,
        contains the entire understanding of the parties with respect to the
        transactions and matters contemplated hereby, supersedes all previous
        communications, understandings and agreements (whether oral or written).
    </span>
</p>

<p  style={{textAlign:"justify"}}><span style={{fontSize:"10.0pt"}}></span></p>

<p  style={{textAlign:"justify"}}>
    <span style={{fontSize:"10.0pt"}}>
        (e)<span style={{msoSpacerun:"yes"}}></span><u>Export Restrictions</u>.<span style={{msoSpacerun:"yes"}}></span>The laws of the 
            <span>United States of America</span>
        prohibit
        the export of certain products, software and data to particular persons,
        territories and foreign states. Nothing from the Services may be exported, in
        any way, in violation of
          
        law.
    </span>
</p>

<p  style={{textAlign:"justify"}}><span style={{fontSize:"10.0pt"}}></span></p>

<p  style={{textAlign:"justify"}}>
    <span style={{fontSize:"10.0pt"}}>
        (f)<span style={{msoSpacerun:"yes"}}></span><u>Headings; Severability</u>.<span style={{msoSpacerun:"yes"}}></span>The headings and captions used in this
        Agreement are used for convenience only and are not to be considered in
        construing or interpreting this Agreement. If any part of this Agreement is
        held to be unlawful, void, or unenforceable, that part will be deemed severable
        and shall not affect the validity and enforceability of the remaining
        provisions.
    </span>
</p>

<p  style={{textAlign:"justify"}}><span style={{fontSize:"10.0pt"}}></span></p>

<p  style={{textAlign:"justify"}}>
    <span style={{fontSize:"10.0pt"}}>
        (h)<span style={{msoSpacerun:"yes"}}></span><u>iOS App; Parties Relationship</u>.<span style={{msoSpacerun:"yes"}}></span>Although your use of our iOS App must comply
        with Apple’s then-current App Store Terms of Service, you acknowledge that
        these Terms are between Company and you, not Apple, Inc. (“Apple”).<span style={{msoSpacerun:"yes"}}></span>You further agree that Company, and not
        Apple, is solely responsible for our iOS App and the Services and Content
        available thereon.<span style={{msoSpacerun:"yes"}}>  </span>You recognize that
        Apple has no obligation to provide maintenance and support services with respect
        to our iOS App.<span style={{msoSpacerun:"yes"}}>  </span>To the maximum extent
        permitted by applicable law, Apple will have no warranty obligation whatsoever
        with respect to our iOS App; and Company is responsible, to the extent required
        by these Terms, for the investigation, defense, settlement and discharge of any
        third-party intellectual property infringement claim related to our iOS App or
        your possession and use of our iOS App.
    </span>
</p>

<p  style={{textAlign:"justify"}}><span style={{fontSize:"10.0pt"}}></span></p>

<p  style={{textAlign:"justify"}}>
    <b>
        <span style={{fontSize:"10.0pt"}}>
            For
            Additional Information
        </span>
    </b>
</p>

<p  style={{textAlign:"justify"}}>
    <span style={{fontSize:"10.0pt"}}>
        If
        you have any questions about the rights and restrictions above, please contact
        us by email at support@ubme.com
    </span>
</p>

<p  style={{textAlign:"justify"}}><span style={{fontSize:"10.0pt"}}></span></p>

<p  style={{textAlign:"justify"}}>
    <span style={{fontSize:"10.0pt"}}>
        Copyright
        © 2017 UBME, Inc. All Rights Reserved.
    </span>
</p>

<p  style={{textAlign:"justify"}}><span style={{fontSize:"10.0pt"}}></span></p>

<p  style={{textAlign:"justify"}}><span style={{fontSize:"10.0pt"}}></span></p>

</div>

    </div>
  )
}

export default TermAndCondtion;