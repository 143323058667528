import { UserIconsPic } from "Components/UBMeEvents/Components_UBMeEvents/Navbar/Alphabets/UserIconsPic";
import React, { useEffect } from "react";
import { useState } from "react";
import { CircularProgress } from "@material-ui/core";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { axiosE } from "Service/service";
import TablePagination from "@mui/material/TablePagination";
import moment from "moment";
import { default as momentTz } from "moment-timezone";
const RecentActivities = () => {
  const [activity, setActivity] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  // const [activity, setActivity] = useState([]);
  const [loading, setLoading] = useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };





  useEffect(() => {
    GetActivitiesByOrganizerId();
  }, [page]);
  const GetActivitiesByOrganizerId = async () => {

    setLoading(true);

    try {
      const { data } = await axiosE.post(
        "/ManageEvents/GetActivitiesByOrganizerId",
        {
          OrganizerId: localStorage.getItem("UserId"),
          PageNumber: page + 1,
          PageSize: rowsPerPage,
        }
      );

      if (data.results.ResponseCode) {
        setActivity(JSON.parse(data.results.Data));

      }
      setLoading(false);
    } catch (error) { }
  };

  const timeUTCtoLocal = (date, timezone) => {
    var local = momentTz
      .tz(moment.utc(date), "MM/DD/YYYY hh:mm:ss a", timezone)
      .format("MM/DD/YYYY hh:mm:ss a");

    const Time = moment(local, "MM/DD/YYYY hh:mm:ss a");
    return `${moment(Time).format("MMM DD, YYYY")} at ${moment(Time).format(
      "hh:mm A"
    )}`;
  };

  const ConvertedStartDateTime = (Time) => {
    return `${moment(Time).format("MMM DD, YYYY")} at ${moment(Time).format(
      "hh:mm A"
    )}`;
  };
  return (
    <div>
      {
        loading ? <div
          style={{
            width: "100%",
            height: "475px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={50} color="secondary" />
        </div> :
          <div>
            <div>
              {activity?.length > 0 ? (
                activity?.map((item) => {
                  const username = item?.ActivityNote?.split(" ");
                  const key = "purchased";
                  let string = "";
                  const arr = item?.ActivityNote?.split(" ");
                  const index = arr.indexOf(key);
                  if (index !== -1) {

                    if (Number(arr[index + 1]) > 1) {
                      arr[index + 2] = "tickets";
                      string = arr.join(" ");
                    } else {
                      arr[index + 2] = "ticket";
                      string = arr.join(" ");
                    }
                  } else {
                    //
                  }

                  return (
                    <div style={{ justifyContent: "space-between" }} className="activity_wrapper">
                      <div style={{ display: 'flex' }}>
                        <LazyLoadImage
                          src={
                            item.Photo === null
                              ? UserIconsPic(username[0][0]?.toUpperCase())
                              : item.Photo
                          }
                          style={{ boxShadow: "none" }}
                          className="Profile_img"
                          PlaceholderSrc={"PlaceholderImage"}
                          effect="blur"
                        />
                        <div className="activity_note">
                          <p>{string === "" ? item.ActivityNote : string}</p>
                        </div>
                      </div>

                      <div className="activity_date">
                        <p>
                          {timeUTCtoLocal(item?.CreatedDateTime, item.TimeZone)}
                        </p>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div
                  style={{
                    width: "100%",
                    height: "475px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <h3 style={{ color: "#808080", textAlign: "center" }}>
                    There is no recent activity!
                  </h3>
                </div>
              )}
            </div>
            {activity?.length > 0 &&
              <div className="custom-pagination">
                <TablePagination
                  rowsPerPageOptions={[]}
                  component="div"
                  count={activity?.length > 0 ? Number(activity[0]?.TotalCounts) : 0}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  showFirstButton
                  showLastButton
                />
              </div>
            }
          </div>
      }


    </div>
  );
};

export default RecentActivities;
