import { reusableClasses } from "../EventDetails/resusableClasses";

export const styleClasses = {
  eventCreateTicketContentWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  generateHeight: {
    display: "flex",
    minHeight: "75vh",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  emptyState: {
    flex: "0.9",
    margin: "50px auto",
    display: "flex",
    opacity: "0.2",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    maxWidth: "58rem",
    textAlign: "center",
  },
  addButtonWrapper: {
    ...reusableClasses.addButtonWrapper,
  },
  addButton: {
    ...reusableClasses.addButton,
  },
  createButtonAddText: {
    ...reusableClasses.addButtonText,
  },
  createButtonPlusIcon: {
    ...reusableClasses.addButtonIcon,
  },
  saveAndContinueButtonWrapper: {
    ...reusableClasses.saveAndContinueButtonWrapper,
  },
  saveAndContinueButton: {
    ...reusableClasses.saveAndContinueButton,
    margin: "20px 0px 40px 0px",
  },

  continueButton: {
    ...reusableClasses.continueButton,
    marginTop: "20px",
  },

  inputLabel: {
    fontSize: "14px",
    fontWeight: "400",
  },
  mainContentWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    marginTop: "30px",

    "@media (max-width: 768px)": {
      paddingBottom: "60px",
    },
  },

  inputFieldWrapper: {
    display: "flex",
    gap: "30px",
    maxWidth: "750px",

    "@media (max-width: 600px)": {
      display: "block",
    },
  },
  addUpdateModal: {
    ...reusableClasses.addOrUpdateModalMainWrapperOuter,
  },

  addUpdateModalMainWrapper: {
    ...reusableClasses.addOrUpdateModalMainWrapperInner,
  },

  addUpdateModalHeader: {
    ...reusableClasses.addOrUpdateModalHeader,
  },

  addUpdateModalHeaderText: {
    ...reusableClasses.addOrUpdateModalHeaderText,
  },

  addUpdateModalCloseIcon: {
    flex: 0.05,
  },
  addUpdateModalButtonWrapper: {
    ...reusableClasses.addOrUpdateModalHeaderButtonWrapper,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
    "@media  screen and (max-width:450px)": {
      // flexDirection:'column',
      margin: "0px 0px 14px 0px",
    },
  },

  addUpdateModalButtonCancel: {
    ...reusableClasses.addOrUpdateModalHeaderButtonCancel,
    margin: "0px !important",
  },

  addUpdateModalButtonSave: {
    ...reusableClasses.addOrUpdateModalHeaderButtonSave,
    color: "white !important",
  },

  // DELETE MODAL

  deleteModalMainWrapper: {
    minWidth: "310px !important",
    height: "200px",
    backgroundColor: "white",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "10px",
    padding: "10px",

    "@media (max-width: 540px)": {
      width: "70%",
    },
    "@media (max-width: 410px)": {
      width: "90%",
    },
  },
  deleteModalImgWrapper: {
    width: "100px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100px",
  },
  deleteModalImg: {
    width: "30px",
  },

  deleteModalSaveAndCancelButton: {
    fontSize: "1.5rem",
    padding: "10px 25px",
  },
};
