import {
  GET_EVENT_USER_EVENTS,
  CURRENT_EVENT_STATUS,
  CURRENT_EVENT_DETAILS,
  ADD_UPDATE_EVENT_BY_EVENT_USER,
  ADD_FAQ_BY_EVENT_USER,
  DELETE_FAQ_BY_EVENT_USER,
  UPDATE_FAQ_BY_EVENT_USER,
  UPDATE_EVENT_BY_EVENT_USER,
  ADD_AGENDA_BY_EVENT_USER,
  DELETE_AGENDA_BY_EVENT_USER,
  ADD_ADS_BY_EVENT_USER,
  DELETE_ADS_BY_EVENT_USER,
  ADD_VENDOR_BY_EVENT_USER,
  DELETE_VENDOR_BY_EVENT_USER,
  UPDATE_VENDOR_BY_EVENT_USER,
  UPDATE_ADS_BY_EVENT_USER,
  ADD_SPONSOR_BY_EVENT_USER,
  DELETE_SPONSOR_BY_EVENT_USER,
  UPDATE_SPONSOR_BY_EVENT_USER,
  ADD_TICKET_BY_EVENT_USER,
  DELETE_TICKET_BY_EVENT_USER,
  UPDATE_TICKET_BY_EVENT_USER,
  CLEAR_EVENT_DETAILS,
  ADD_DISCOUNT_BY_EVENT_USER,
  DELETE_DISCOUNT_BY_EVENT_USER,
  UPDATE_DISCOUNT_BY_EVENT_USER,
  ADD_ADDITIONAL_TAX_BY_EVENT_USER,
  DELETE_ADDITIONAL_TAX_BY_EVENT_USER,
  UPDATE_ADDITIONAL_TAX_BY_EVENT_USER,
  ADD_ORDER_FORM_BY_EVENT_USER,
  DELETE_ORDER_FORM_BY_EVENT_USER,
  UPDATE_ORDER_FORM_BY_EVENT_USER,
  PUBLISH_EVENT,
  EVENT_STATUS,
  UPDATE_AGENDA_BY_EVENT_USER,
  SET_RANDOM_CODE,
  ADD_SPEAKER_BY_EVENT_USER,
  SET_EVENT_TYPE,
  UPDATE_SPEAKER_BY_EVENT_USER,
  DELETE_SPEAKER_BY_EVENT_USER,
  SET_STRIPE_USER_ID,
  UPDATE_DESCRIPTOR_NAME,
  SET_SIDEBAR_FUNCTIONS,
  UPDATE_EVENT_DETAILS_BY_EVENT_USER,
  REMOVE_EVENT_BY_EVENT_USER
} from "../Actions/actionTypes";
import { cloneDeep } from "lodash";
import moment from "moment";

const initial = "";

export default (state = initial, action) => {
  const statesArrayIfExists = (state, pro, newItem) => {
    if (state.hasOwnProperty(pro)) {
      return [...state[pro], newItem];
    } else {
      return [newItem];
    }
  };
  let eventId;
  switch (action.type) {
    case GET_EVENT_USER_EVENTS:
      return cloneDeep(action.payload);
    case ADD_UPDATE_EVENT_BY_EVENT_USER:
      const data = JSON.parse(action.payload.results.Data);
      return {
        ...state,
        eventId: data.EventId,
        eventsAbout: data,
      };
    case UPDATE_EVENT_BY_EVENT_USER:
      return {
        ...state,
        eventId: action.payload.EventId,
        eventsAbout: action.payload,
      };

      case REMOVE_EVENT_BY_EVENT_USER:
        return {
          ...state,
          eventId: "",
          eventsAbout: action.payload,
          eventsDetails:action.payload
        };
      
      case UPDATE_EVENT_DETAILS_BY_EVENT_USER:
        return {
          ...state,
          // eventId: action.payload.EventId,
          eventsDetails: action.payload,
        };

    case CURRENT_EVENT_STATUS:
      return { ...state, status: action.payload };
    case CURRENT_EVENT_DETAILS:
      return action.payload;
    case ADD_FAQ_BY_EVENT_USER:
      return {
        ...state,
        faqs: statesArrayIfExists(
          state,
          "faqs",
          JSON.parse(action.payload.results.Data)
        ),
      };
    case DELETE_FAQ_BY_EVENT_USER:

      return {
        ...state,
        faqs: state.faqs.filter((faq) => faq.FaqId !== action.payload.FaqId),
      };
    case SET_RANDOM_CODE:
      return {
        ...state,
        hashCode: action.payload,
      };
    case UPDATE_FAQ_BY_EVENT_USER:
      return {
        ...state,
        faqs: state.faqs.map((faq) =>
          faq.FaqId === action.payload.FaqId ? action.payload : faq
        ),
      };
    case ADD_AGENDA_BY_EVENT_USER:
      let newData = JSON.parse(action.payload.results.Data);
      newData["Speakers"] = [];
      let newDate = moment(newData.SessionDate)
        .format("MMMM DD, YYYY")
        .toString();
      return {
        ...state,

        agendaSessions: state.hasOwnProperty("agendaSessions")
          ? state.agendaSessions.hasOwnProperty(newDate)
            ? {
                ...state.agendaSessions,
                [newDate]: [...state.agendaSessions[newDate], newData],
              }
            : {
                ...state.agendaSessions,
                [newDate]: [newData],
              }
          : { [newDate]: [newData] },
      };
    case ADD_SPEAKER_BY_EVENT_USER:
      let addSpeakerData = JSON.parse(action.payload.data.results.Data);
      let addSpeakerDate = action.payload.day;
      let ourState = cloneDeep(state);
      if (ourState.hasOwnProperty("agendaSessions")) {
        if (ourState.agendaSessions.hasOwnProperty(addSpeakerDate)) {
          let addSpeakerIndex = ourState.agendaSessions[
            addSpeakerDate
          ].findIndex(
            (session) =>
              session.AgendaSessionId === addSpeakerData.AgendaSessionId
          );
          ourState.agendaSessions[addSpeakerDate][addSpeakerIndex][
            "Speakers"
          ].push(addSpeakerData);
        }
      }
      return {
        ...ourState,
      };
    case UPDATE_SPEAKER_BY_EVENT_USER:
      let updateSpeakerData = JSON.parse(action.payload.data.results.Data);
      let updateSpeakerDate = action.payload.day;
      let updatedState = cloneDeep(state);

      if (updatedState.hasOwnProperty("agendaSessions")) {
        if (updatedState.agendaSessions.hasOwnProperty(updateSpeakerDate)) {
          let addSpeakerIndex = updatedState.agendaSessions[
              updateSpeakerDate
              ].findIndex(
              (session) =>
                  session.AgendaSessionId === updateSpeakerData.AgendaSessionId
          );

          let updateSpeakerIndex = updatedState.agendaSessions[updateSpeakerDate][
              addSpeakerIndex
              ]["Speakers"].findIndex(
              (speaker) => speaker.SpeakerId === updateSpeakerData.SpeakerId
          );
          updatedState.agendaSessions[updateSpeakerDate][addSpeakerIndex][
              "Speakers"
              ][updateSpeakerIndex] = updateSpeakerData;
        }
      }
      return {
        ...updatedState,
      };
    case DELETE_SPEAKER_BY_EVENT_USER:
      let deleteSpeakerDate = action.payload.day;
      let deleteSessionId = action.payload.sessionID;
      let deleteSpeakerId = action.payload.SpeakerId;
      let stateAfterDelete = cloneDeep(state);

      if (stateAfterDelete.hasOwnProperty("agendaSessions")) {
        if (stateAfterDelete.agendaSessions.hasOwnProperty(deleteSpeakerDate)) {
          let speakerIndex = stateAfterDelete.agendaSessions[
              deleteSpeakerDate
              ].findIndex((session) => session.AgendaSessionId === deleteSessionId);
          let deleteIndex = stateAfterDelete.agendaSessions[deleteSpeakerDate][
              speakerIndex
              ]["Speakers"].findIndex(
              (speaker) => speaker.SpeakerId === deleteSpeakerId
          );
          stateAfterDelete.agendaSessions[deleteSpeakerDate][speakerIndex][
              "Speakers"
              ].splice(deleteIndex, 1);
        }
      }
      return {
        ...stateAfterDelete,
      };
    case DELETE_AGENDA_BY_EVENT_USER:
      const targetDate = action.payload.day.date;
      return {
        ...state,
        agendaSessions: {
          ...state.agendaSessions,
          [targetDate]: state.agendaSessions[targetDate].filter(
            (session) =>
              session.AgendaSessionId !== action.payload.AgendaSessionId
          ),
        },
      };
    case UPDATE_AGENDA_BY_EVENT_USER:
      let AgendaSessionId = action.payload.AgendaSessionId;
      let updatedDate = moment(action.payload.SessionDate)
        .format("MMMM DD, YYYY")
        .toString();
      return {
        ...state,
        agendaSessions: {
          ...state.agendaSessions,
          [updatedDate]: state.agendaSessions[updatedDate].map((session) =>
            session.AgendaSessionId === action.payload.AgendaSessionId
              ? { ...action.payload, Speakers: session.Speakers }
              : session
          ),
        },
      };
    case ADD_ADS_BY_EVENT_USER:
      return {
        ...state,
        ads: statesArrayIfExists(
          state,
          "ads",
          JSON.parse(action.payload.results.Data)
        ),
      };
    case DELETE_ADS_BY_EVENT_USER:
      return {
        ...state,
        ads: state.ads.filter(
          (ad) => ad.AdvertismentId !== action.payload.AdvertismentId
        ),
      };
    case UPDATE_ADS_BY_EVENT_USER: {

      return {
        ...state,
        ads: state.ads.map((ad) =>
          ad.AdvertismentId === action.payload.AdvertismentId
            ? action.payload
            : ad
        ),
      };
    }
    case ADD_VENDOR_BY_EVENT_USER:
      return {
        ...state,
        vendors: statesArrayIfExists(
          state,
          "vendors",
          JSON.parse(action.payload.results.Data)
        ),
      };
    case DELETE_VENDOR_BY_EVENT_USER:
      return {
        ...state,
        vendors: state.vendors.filter(
          (vendor) => vendor.VendorId !== action.payload.VendorId
        ),
      };
    case UPDATE_VENDOR_BY_EVENT_USER:
      return {
        ...state,
        vendors: state.vendors.map((vendor) =>
          vendor.VendorId === action.payload.VendorId ? action.payload : vendor
        ),
      };
    case ADD_SPONSOR_BY_EVENT_USER:
      return {
        ...state,
        sponsors: statesArrayIfExists(
          state,
          "sponsors",
          JSON.parse(action.payload.results.Data)
        ),
      };
    case DELETE_SPONSOR_BY_EVENT_USER:
      return {
        ...state,
        sponsors: state.sponsors.filter(
          (sponsor) => sponsor.SponsorId !== action.payload.SponsorId
        ),
      };
    case UPDATE_SPONSOR_BY_EVENT_USER:
      return {
        ...state,
        sponsors: state.sponsors.map((sponsor) =>
          sponsor.SponsorId === action.payload.SponsorId
            ? action.payload
            : sponsor
        ),
      };
    case ADD_TICKET_BY_EVENT_USER:
      return {
        ...state,
        tickets: statesArrayIfExists(
          state,
          "tickets",
          JSON.parse(action.payload.results.Data)
        ),
      };
    case DELETE_TICKET_BY_EVENT_USER:
      return {
        ...state,
        tickets: state.tickets.filter(
          (ticket) => ticket.TicketId !== action.payload.TicketId
        ),
      };
    case UPDATE_TICKET_BY_EVENT_USER:
      return {
        ...state,
        tickets: state.tickets.map((ticket) =>
          ticket.TicketId === action.payload.TicketId ? action.payload : ticket
        ),
      };
    case ADD_DISCOUNT_BY_EVENT_USER:
      return {
        ...state,
        discounts: statesArrayIfExists(
          state,
          "discounts",
          JSON.parse(action.payload.results.Data)
        ),
      };
    case DELETE_DISCOUNT_BY_EVENT_USER:
      return {
        ...state,
        discounts: state.discounts.filter(
          (discount) => discount.DiscountId !== action.payload.DiscountId
        ),
      };
    case UPDATE_DISCOUNT_BY_EVENT_USER:
      return {
        ...state,
        discounts: state.discounts !== undefined ?  state.discounts.map((discount) => {

          return discount.DiscountId === action.payload.DiscountId
          ? action.payload
          : discount
        }
        ): [action.payload]
      };
    case ADD_ADDITIONAL_TAX_BY_EVENT_USER:
      return {
        ...state,
        additionalTaxes: statesArrayIfExists(
          state,
          "additionalTaxes",
          JSON.parse(action.payload.results.Data)
        ),
      };
    case DELETE_ADDITIONAL_TAX_BY_EVENT_USER:
      return {
        ...state,
        additionalTaxes: state.additionalTaxes.filter(
          (additionalTax) =>
            additionalTax.AdditionalTaxId !== action.payload.AdditionalTaxId
        ),
      };
    case UPDATE_ADDITIONAL_TAX_BY_EVENT_USER:
      return {
        ...state,
        additionalTaxes: state.additionalTaxes.map((additionalTax) =>
          additionalTax.AdditionalTaxId === action.payload.AdditionalTaxId
            ? action.payload
            : additionalTax
        ),
      };
    case ADD_ORDER_FORM_BY_EVENT_USER:
      return {
        ...state,
        orderForms: statesArrayIfExists(
          state,
          "orderForms",
          JSON.parse(action.payload.results.Data)
        ),
      };
    case DELETE_ORDER_FORM_BY_EVENT_USER:
      return {
        ...state,
        orderForms: state.orderForms.filter(
          (orderForm) => orderForm.FormFieldId !== action.payload.FormFieldId
        ),
      };
    case UPDATE_ORDER_FORM_BY_EVENT_USER:
      return {
        ...state,
        orderForms: state.orderForms.map((orderForm) =>
          orderForm.FormFieldId === action.payload.FormFieldId
            ? action.payload
            : orderForm
        ),
      };
    case PUBLISH_EVENT:
      return {
        ...state,
        eventPublish: true,
      };
      case EVENT_STATUS:

      return {
        ...state,
        eventStatus: action.payload,
      };
    case SET_EVENT_TYPE:

      return {
        ...state,
        eventType: action.data.type,
      };
    case UPDATE_DESCRIPTOR_NAME:
      return {
        ...state,
        descriptorName: action.payload.name,
      };
    case SET_STRIPE_USER_ID:
      return {
        ...state,
        payouts: action.data.stripeId,
      };
    case SET_SIDEBAR_FUNCTIONS:
      return {
        ...state,
        sidebarFunctions: action.payload,
      };

    case CLEAR_EVENT_DETAILS:
      return initial;
    default:
      return state;
  }
};
