import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  selectedChat,
  setScrollFalse,
  readAllMessages,
  emptyLiveChat,
  increaseMessageIndex,
} from "../../../../Actions/messagesAction";
import { Twemoji } from "react-emoji-render";
import moment from "moment";
import { Avatar } from "@material-ui/core";
import { ws } from "../../../../App";
import { convertUnicode } from "../../../../Helper/methods";
import "./questionChat.css";

class QuestionChat extends React.Component {
  componentDidMount() {
    const { messageData, user, livechatData } = this.props;

    const { backUpData, recentChat, selectedChat, initialCall } = messageData;

    // const initialCall = (messageData.selectedChat.Type === 'PlaceQuestionChat' || 'Feed' || 'Event') ? true : false

    livechatData.AllliveChatScroll = true;
    this.initialScroll();

    if (selectedChat) {
      ws?.json({
        to_user_id: selectedChat.from_user_id,
        type: "userstatus",
      });
    }

    if (
      initialCall &&
      initialCall.QuestionChat &&
      ((recentChat && !backUpData) || Object.keys(backUpData).length)
    ) {
      messageData.selectedChat.Type === "PlaceQuestionChat"
        ? ws?.json({
            chat_type: "PlaceQuestionChat",
            count: 20,
            deleteCount: 0,
            googlePlacedId: messageData.selectedChat.dialog_id,
            index: 0,
            requesterId: user?.ProfileDetail.UserId,
            type: "businessrecentchat",
            user_id: messageData.selectedChat.userid,
          })
        : ws?.json({
            chat_type:
              messageData.selectedChat.Type === "Feed" ? "Feed" : "Event",
            count: 20,
            deleteCount: 0,
            googlePlacedId: messageData.selectedChat.dialog_id,
            index: 0,
            type: "businessrecentchat",
            user_id: messageData.selectedChat.userid,
          });

      this.props.selectedChat(messageData.selectedChat);
      this.props.increaseMessageIndex(1);
      messageData.recentChat &&
        this.props.readAllMessages(messageData.selectedChat);
    }
  }

  componentDidUpdate() {
    const { messageData, setScrollFalse, chatHistory } = this.props;
    if (messageData.scroll) {
      let scrollElement = document.getElementById("chatWrapper");
      scrollElement.scrollTop =
        scrollElement.scrollHeight - scrollElement.clientHeight;
      setScrollFalse();
    }
    if (
      chatHistory &&
      chatHistory[messageData.chatHistory - 1] &&
      chatHistory[messageData.chatHistory - 1].questionUnreadCount.length > 0
    ) {
      ws.json({
        chat_id: chatHistory[chatHistory - 1].CommentId,
        chat_type: "PlaceQuestionChat",
        googlePlacedId: chatHistory[chatHistory - 1].GooglePlaceId,
        type: "chatrecieveack",
        user_id: chatHistory[chatHistory - 1].UserId,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { deleteCount, messageData, user, chatHistory } = nextProps;
    // console.log("messageData",messageData);
    // console.log("QuestionchatHistory", chatHistory);
    if (chatHistory && chatHistory.length === 0) {
      messageData.selectedChat.Type === "PlaceQuestionChat"
        ? ws?.json({
            chat_type: "PlaceQuestionChat",
            count: 20,
            deleteCount: deleteCount,
            googlePlacedId: messageData.selectedChat.dialog_id,
            index: 0,
            requesterId: user?.ProfileDetail.UserId,
            type: "businessrecentchat",
            user_id: messageData.selectedChat.userid,
          })
        : ws?.json({
            chat_type:
              messageData.selectedChat.Type === "Feed" ? "Feed" : "Event",
            count: 20,
            deleteCount: deleteCount,
            googlePlacedId: messageData.selectedChat.dialog_id,
            index: 0,
            type: "businessrecentchat",
            user_id: messageData.selectedChat.userid,
          });

      nextProps.selectedChat(messageData.selectedChat);
      nextProps.increaseMessageIndex(1);
      messageData.recentChat &&
        nextProps.readAllMessages(messageData.selectedChat);
    }
  }

  componentWillUnmount() {
    //this.props.emptyLiveChat();
  }

  getBusinessChat = () => {
    const {
      deleteCount,
      messageData,
      messageIndex,
      user,
      increaseMessageIndex,
    } = this.props;

    messageData.selectedChat.Type === "PlaceQuestionChat"
      ? ws?.json({
          chat_type: "PlaceQuestionChat",
          count: 20,
          deleteCount: deleteCount,
          googlePlacedId: messageData.selectedChat.dialog_id,
          index: messageIndex,
          requesterId: user?.ProfileDetail.UserId,
          type: "businessrecentchat",
          user_id: messageData.selectedChat.userid,
        })
      : ws?.json({
          chat_type:
            messageData.selectedChat.Type === "Feed" ? "Feed" : "Event",
          count: 20,
          deleteCount: deleteCount,
          googlePlacedId: messageData.selectedChat.dialog_id,
          index: messageIndex,
          type: "businessrecentchat",
          user_id: messageData.selectedChat.userid,
        });
    increaseMessageIndex(messageIndex + 1);
  };

  onChatScroll = (e) => {
    // let el = e.target;
    // if ((el.clientHeight + el.scrollTop) < el.scrollHeight) {
    //     if ((el.clientHeight + el.scrollTop + 35) >= el.scrollHeight) {
    //         this.props.questionChatFooter.selector.props.hidePopUp();
    //     }
    // }
  };

  initialScroll = () => {
    const { livechatData, chatHistory } = this.props;

    if (livechatData.AllliveChatScroll) {
      let scrollElement = document.getElementById("chatWrapper");
      scrollElement.scrollTop =
        scrollElement.scrollHeight - scrollElement.clientHeight;
      setScrollFalse();
    }
  };

  render() {
    const { chatHistory, user } = this.props;

    let selectedChat,
      totalChat,
      chatReceived = "";
    if (this.props.messageData.selectedChat.Type === "Feed") {
      totalChat = this.props.livechatData.totalData;
      chatReceived = this.props.livechatData.chatReceived;
    } else {
      totalChat = this.props.messageData.totalChat;
      chatReceived = this.props.messageData.chatReceived;
    }

    selectedChat = this.props.messageData.selectedChat;

    let previousDate = "";
    return (
      <div
        id="chatWrapper"
        className="chat-wrapper"
        onScroll={this.onChatScroll}
      >
        {chatHistory &&
          chatHistory[0] &&
          selectedChat.dialog_id ===
            (chatHistory
              ? chatHistory[0].GooglePlacedId !== undefined
                ? chatHistory[0].GooglePlacedId
                : chatHistory[0].GooglePlaceId
              : "") &&
          chatReceived < totalChat && (
            <p onClick={this.getBusinessChat} className="loadMessages">
              Load Earlier Messages
            </p>
          )}
        {chatHistory && chatHistory.length === 0 ? (
          <div>
            <h1 className="no-message-display-webview">
              No Messages to display
            </h1>
          </div>
        ) : (
          chatHistory &&
          chatHistory[0] &&
          selectedChat.dialog_id ===
            (chatHistory
              ? chatHistory[0].GooglePlacedId !== undefined
                ? chatHistory[0].GooglePlacedId
                : chatHistory[0].GooglePlaceId
              : "") &&
          chatHistory.map((data, index) => {
            let currentDate = moment();
            let stillUTC = moment.utc(data.CreatedDate).toDate();
            let messageDate = moment(stillUTC).local();
            let diff = currentDate.endOf("day").diff(messageDate, "days");
            let display = true;
            if (previousDate === diff) {
              display = false;
            }
            previousDate = diff;
            let res;
            res =
              /^(http:\/\/\.|https:\/\/\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm.test(
                JSON.parse(data.CommentDetail).text
              );
            return (
              <div
                key={index}
                className="chats"
                onMouseDown={this.props.MouseDown}
                onMouseUp={(e) => {
                  this.props.MouseUp(e, data);
                }}
              >
                {display &&
                  (diff === 1 ? (
                    <p className="date-display">Yesterday</p>
                  ) : diff === 0 ? (
                    <p className="date-display">Today</p>
                  ) : diff <= 7 ? (
                    <p className="date-display">
                      {moment(messageDate).format("dddd")}
                    </p>
                  ) : (
                    <p className="date-display">
                      {moment(messageDate).format("LL")}
                    </p>
                  ))}
                {Number(data.UserId) !== Number(user?.ProfileDetail.UserId) && (
                  <p className="QC-chats-username-receiver">
                    {data.First_Name}
                  </p>
                )}
                <div
                  className={
                    Number(data.UserId) === Number(user?.ProfileDetail.UserId)
                      ? "QC-chat-wrapper-sender"
                      : "QC-chat-wrapper-receiver"
                  }
                  onMouseDown={this.props.MouseDown}
                  onMouseUp={(e) => {
                    this.props.MouseUp(e, data);
                  }}
                >
                  {Number(data.UserId) !==
                    Number(user?.ProfileDetail.UserId) && (
                    <Avatar
                      className={
                        JSON.parse(data.CommentDetail).text ===
                          "[Sticker message]" ||
                        JSON.parse(data.CommentDetail).text ===
                          "[Picture message]" ||
                        JSON.parse(data.CommentDetail).text === "[GIF message]"
                          ? "QC-avatar-image"
                          : "QC-avatar-text"
                      }
                    >
                      <img
                        src={require(`./../../../../images/Alphabets/${data.First_Name.match(
                          /[a-z]/i
                        )[0].toUpperCase()}.png`).default}
                        alt="I"
                        height={40}
                        width={40}
                      />
                    </Avatar>
                  )}
                  {JSON.parse(data.CommentDetail).text ===
                  "[Sticker message]" ? (
                    JSON.parse(data.CommentDetail).stickerId.split(".")[0] >
                      0 &&
                    JSON.parse(data.CommentDetail).stickerId.split(".")[0] <
                      30 ? (
                      <div
                        className={
                          Number(data.UserId) ===
                          Number(user?.ProfileDetail.UserId)
                            ? "QC-sender-image"
                            : "QC-receiver-image"
                        }
                      >
                        <img
                          id="chatImage"
                          src={require(`../../../../images/Stickers/${
                            JSON.parse(data.CommentDetail).stickerId
                          }`).default}
                          alt="stickerImage"
                          height={150}
                          width={150}
                          onMouseDown={this.props.MouseDown}
                          onMouseUp={(e) => {
                            this.props.MouseUp(e, data);
                          }}
                        />
                      </div>
                    ) : (
                      <div
                        className={
                          Number(data.UserId) ===
                          Number(user?.ProfileDetail.UserId)
                            ? "QC-sender-image"
                            : "QC-receiver-image"
                        }
                      >
                        <img
                          id="chatImage"
                          src={`https://d2gnb7jphto2jj.cloudfront.net/ChatImages/Sticker/Images/${
                            JSON.parse(data.CommentDetail).stickerId
                          }`}
                          alt="stickerImage"
                          height={150}
                          width={150}
                          onMouseDown={this.props.MouseDown}
                          onMouseUp={(e) => {
                            this.props.MouseUp(e, data);
                          }}
                        />
                      </div>
                    )
                  ) : JSON.parse(data.CommentDetail).text ===
                      "[Picture message]" ||
                    JSON.parse(data.CommentDetail).text === "[GIF message]" ? (
                    <div
                      className={
                        Number(data.UserId) ===
                        Number(user?.ProfileDetail.UserId)
                          ? "QC-sender-image"
                          : "QC-receiver-image"
                      }
                    >
                      <img
                        id="chatImage"
                        src={JSON.parse(data.CommentDetail).imgUrl}
                        alt="img"
                        height={150}
                        width={250}
                        onMouseDown={this.props.MouseDown}
                        onMouseUp={(e) => {
                          this.props.MouseUp(e, data);
                        }}
                      />
                    </div>
                  ) : (
                    <div
                      className={
                        Number(data.UserId) ===
                        Number(user?.ProfileDetail.UserId)
                          ? "QC-chat-bubble-sender"
                          : "QC-chat-bubble-receiver"
                      }
                    >
                      <p
                        onMouseDown={this.props.MouseDown}
                        onMouseUp={(e) => {
                          this.props.MouseUp(e, data);
                        }}
                      >
                        {!res ? (
                          <Twemoji
                            text={convertUnicode(
                              JSON.parse(data.CommentDetail).text
                            )}
                          />
                        ) : (
                          JSON.parse(data.CommentDetail).text
                        )}
                      </p>
                    </div>
                  )}
                </div>
                <p
                  className={
                    Number(data.UserId) === Number(user?.ProfileDetail.UserId)
                      ? "time-display text-right"
                      : "time-display text-left"
                  }
                >
                  {moment(data.CreatedDate.toString()).format("LT")}
                </p>
              </div>
            );
          })
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    messageData: state.messageReducer,
    livechatData: state.liveChatReducer,
    messageIndex:
      state.messageReducer.selectedChat.Type === "Feed"
        ? state.liveChatReducer.liveChatIndex
        : state.messageReducer.messageIndex,
    chatHistory:
      state.messageReducer.selectedChat.Type === "Feed"
        ? state.liveChatReducer.liveChat
        : state.messageReducer.chatHistory,
    user: state.loginResponse.results,
  };
};

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      selectedChat,
      increaseMessageIndex,
      setScrollFalse,
      readAllMessages,
      emptyLiveChat,
    },
    dispatch
  );
};

export default connect(mapStateToProps, matchDispatchToProps)(QuestionChat);
