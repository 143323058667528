import React from "react";
import "./Pricing.css";
import Fade from "react-reveal/Fade";
const Features = ({ item }) => {
  return (
    <div className="features_card_box">
      <Fade bottom>
        <div className="features_card">
          <img src={item.img1} />
          <h4>{item.text}</h4>
        </div>
      </Fade>
      <Fade bottom>
        <div className="features_card">
          <img src={item.img2} />
          <h4>{item.text2}</h4>
        </div>
      </Fade>
    </div>
  );
};

export default Features;
