import React from "react";
import moment from "moment";
import axiosI from "../../../../Service/service";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Avatar } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import {
    getDetailGIF,
    error,
    hidePopUp,
} from "../../../../Actions/messagesAction";
import { ws } from "../../../../App";
import converter from "../../../../Helper/convertFile";

const styles = {
    avatar: {
        margin: 10,
        height: 50,
        width: 50,
        fontSize: 40,
    },
};

class WebViewFooter extends React.Component {
    constructor() {
        super();
        this.state = {
            showStickers: false,
            makeGif: true,
            textMessage: "",
            gif: [],
            stickers: [],
            showPopUp: false,
        };
    }

    componentDidMount() {
        let { stickers } = this.state;
        for (let i = 1; i <= 30; i++) {
            stickers.push(
                <div className="sticker" onClick={() => this.sendSticker(i)}>
                    <img
                        src={require(`../../../../images/Stickers/${i}.png`).default}
                        alt={i}
                        height={100}
                        width={100}
                    />
                </div>
            );
        }
        this.setState({ stickers });
    }

    componentWillReceiveProps(nextProps) {
        const { messageData } = nextProps;
        let { showPopUp } = this.state;
        if (messageData.showPopUp) {
            let chatElement = document.getElementById("chatWrapper");
            if (
                chatElement.clientHeight + chatElement.scrollTop + 65 <
                chatElement.scrollHeight
            ) {
                showPopUp = true;
            } else {
                this.props.hidePopUp();
            }
        } else {
            showPopUp = false;
        }
        this.setState({ showPopUp });
    }

    sendSticker = (id) => {
        const { messageData, user } = this.props;
        let date = new Date();
        let currentDate = moment().utc().format("YYYY-MM-DD HH:mm:ss");
        let data = {
            text: "[Sticker message]",
            stickerId: `${id}.png`,
        };
        ws.json({
            msgid: date.getTime().toString().slice(4),
            to_user_id: messageData.selectedChat.userid.toString(),
            dialog_id: messageData.selectedChat.dialog_id.toString(),
            from_user_id: user.ProfileDetail.UserId.toString(),
            chat_time: currentDate,
            message_type: "5",
            type: "chat",
            token_id: localStorage.getItem("token"),
            chat_message: data,
        });
        this.props.updateWebViewState("scroll", true);
    };

    onTextChange = (e) => {
        const { messageData } = this.props;
        this.setState(
            {
                [e.target.id]: e.target.value,
                scroll: false,
            },
            () => {
                if (this.state.textMessage.trim().length > 0) {
                    ws.json({
                        type: "starttypingack",
                        dialog_id: messageData.selectedChat.dialog_id,
                        to_user_id: messageData.selectedChat.userid,
                    });
                } else {
                    ws.json({
                        type: "stoptypingack",
                        dialog_id: messageData.selectedChat.dialog_id,
                        to_user_id: messageData.selectedChat.userid,
                    });
                }
            }
        );
    };

    sendMessage = () => {
        let { textMessage } = this.state;
        const { messageData, user } = this.props;

        if (textMessage.trim().length > 0) {
            let date = new Date();
            let currentDate = moment().utc().format("YYYY-MM-DD HH:mm:ss");
            ws.json({
                msgid: date.getTime().toString().slice(4),
                to_user_id: messageData.selectedChat.userid.toString(),
                dialog_id: messageData.selectedChat.dialog_id.toString(),
                from_user_id: user.ProfileDetail.UserId.toString(),
                chat_time: currentDate, //  moment().format('YYYY-mm-DD HH:MM');
                message_type: "1",
                type: "chat",
                token_id: localStorage.getItem("token"),
                chat_message: {
                    text: textMessage,
                },
            });
            ws.json({
                type: "stoptypingack",
                dialog_id: messageData.selectedChat.dialog_id,
                to_user_id: messageData.selectedChat.userid,
            });
            textMessage = "";
            this.props.updateWebViewState("scroll", true);
            this.setState({ textMessage });
        }
    };

    onStickerClick = () => {
        this.setState({
            showStickers: !this.state.showStickers,
        });

        // let panel = document.getElementById('webViewMessage');
        // if (!this.state.showStickers) {
        //     panel.style.height = 'calc(100vh - 360px)';
        // } else {
        //     panel.style.height = 'calc(100vh - 360px)';
        // }
    };

    convertUnicode = (input) => {
        return input.replace(/\\u(\w\w\w\w)/g, (a, b) => {
            let charcode = parseInt(b, 16);
            return String.fromCharCode(charcode);
        });
    };

    handleImageChange = (e) => {
        let { messageData, user } = this.props;
        let url = "";
        let length;
        let date = new Date();
        if (e.target.files.length > 5) {
            alert("Only 5 images can be selected per time.");
            length = 5;
        } else length = e.target.files.length;
        for (let i = 0; i < length; i++) {
            if (e.target.files[i].type.split("/")[0] === "image") {
                converter.toBase64(e.target.files[i]).then((dt) => {
                    dt = dt.substring(dt.indexOf(",") + 1);
                    url = dt;
                    let data = {
                        ProfileImage: url,
                        messageId: date.getTime().toString().slice(4),
                    };

                    axiosI
                        .post("/UploadChatImage", data, {
                            headers: {
                                "Content-Type": "application/json",
                            },
                        })
                        .then((response) => {
                            if (
                                response.data.results.Message !== "Chat Image does not saved"
                            ) {
                                let currentDate = moment().utc().format("YYYY-MM-DD HH:mm:ss");
                                let socketData = {
                                    text: "[Picture message]",
                                    imgUrl: response.data.results.ImageUrl,
                                };
                                ws.json({
                                    msgid: response.data.results.Id,
                                    to_user_id: messageData.selectedChat.userid.toString(),
                                    dialog_id: messageData.selectedChat.dialog_id.toString(),
                                    from_user_id: user.ProfileDetail.UserId,
                                    chat_time: currentDate,
                                    message_type: "2",
                                    type: "chat",
                                    token_id: localStorage.getItem("token"),
                                    chat_message: socketData,
                                });
                            }
                        })
                        .catch((err) => {
                            console.log("Error uploading image to the server.", err);
                            this.props.error();
                        });
                });
            } else {
                this.props.toggleAlert(
                    "Please select image of png, jpg and jpeg type only."
                );
            }
        }
    };

    do_resize = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            this.sendMessage(e);
        } else {
            let el = e.target;
            setTimeout(() => {
                // for box-sizing other than "content-box" use:
                // el.style.cssText = '-moz-box-sizing:content-box';
                if (el.scrollHeight < 120) {
                    el.style.cssText = "padding:0 max-height:200px overflow-y:scroll";
                    el.style.cssText = "height:" + el.scrollHeight + "px";
                }
            }, 0);
        }
    };

    hidePopUp = () => {
        this.setState({
            showPopUp: false,
        });
        this.props.hidePopUp();
        let chatElement = document.getElementById("chatWrapper");
        chatElement.scrollTop = chatElement.scrollHeight - chatElement.clientHeight;
    };

    render() {
        const { classes, messageData } = this.props;
        const { showPopUp, showGif, showStickers, textMessage, stickers } = this.state;

        return (
            <React.Fragment>
                {showPopUp &&
                Object.keys(messageData.backUpChat).includes(
                    messageData.selectedChat.dialog_id
                ) && (
                    <div className="unread-pop-up" onClick={this.hidePopUp}>
                        <Avatar className={classes.avatar}>
                            <img
                                src={require(`./../../../../images/Alphabets/${messageData.selectedChat.FullName.match(
                                    /[a-z]/i
                                )[0].toUpperCase()}.png`).default}
                                height={65}
                                width={65}
                                alt="img"
                            />
                        </Avatar>
                        <div className="unread-pop-up-wrapper">
                            <p>{messageData.selectedChat.FullName}</p>
                            <p>
                                {
                                    JSON.parse(
                                        messageData.chatHistory[
                                        messageData.chatHistory.length - 1
                                            ].message_json
                                        ).text
                                    }
                                </p>
                            </div>
                        </div>
                    )}
                <div className="footer">
                    <div className="chat-text-area">
                        <textarea
                            disabled={showGif || showStickers}
                            id="textMessage"
                            placeholder="Type your message"
                            onChange={this.onTextChange}
                            value={textMessage}
                            onKeyDown={this.do_resize}
                            cols="20"
                            rows="1"
                        />
                    </div>
                    <div className="chat-emoji-send-area">
                        {textMessage.trim().length > 0 ? (
                            <label
                                className="footer-chat-btn-send-messages"
                                onClick={this.sendMessage}
                            >
                                Send
                            </label>
                        ) : (
                            <img
                                src={require("../../../../images/Message Icons/message_Emoji_icon@3x.png").default}
                                alt="img"
                                height={40}
                                className="stickerEmoji"
                                onClick={this.onStickerClick}
                            />
                        )}
                    </div>
                </div>
                <div className="imageWrapper">
                    <img
                        src={require("../../../../images/Message Icons/message_photo_library@3x.png").default}
                        alt="img"
                        height={45}
                        onClick={() => this.refs.img.click()}
                    />
                    <input
                        type="file"
                        ref="img"
                        multiple={true}
                        style={{ display: "none" }}
                        name="chatImage"
                        title="Load File"
                        onChange={(e) => {
                            this.handleImageChange(e);
                        }}
                        accept="image/*"
                    />
                    <img
                        src={require("../../../../images/Message Icons/message_camera@3x.png").default}
                        alt="img"
                        height={45}
                        onClick={this.props.toggleCamera}
                    />
                    <img
                        src={require("../../../../images/Message Icons/message_gif@3x.png").default}
                        alt="img"
                        height={45}
                        onClick={this.props.onGifClick}
                    />
                </div>
                {showStickers && <div className="sticker-wrapper">{stickers}</div>}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        messageData: state.messageReducer,
        chatHistory: state.messageReducer.chatHistory,
        user: state.loginResponse.results,
    };
};

const matchDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getDetailGIF,
            error,
            hidePopUp,
        },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    matchDispatchToProps
)(withStyles(styles)(WebViewFooter));
