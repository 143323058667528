export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const REGISTER = "REGISTER";
export const GET_USER = "GET_USER";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const FLOW = "FLOW";
export const STARTED_FROM_BEGINNING = "STARTED_FROM_BEGINNING";

//Messages
export const OPEN = "OPEN";
export const IDENTITY = "IDENTITY";
export const RECENT_CHAT = "RECENT_CHAT";
export const CHAT_HISTORY = "CHAT_HISTORY";
export const SELECTED_CHAT = "SELECTED_CHAT";
export const CHAT_SENT_ACK = "CHAT_SENT_ACK";
export const USER_ONLINE = "USER_ONLINE";
export const GET_GIF = "GET_GIF";
export const GET_DETAILED_GIF = "GET_DETAILED_GIF";
export const DELETE_MESSAGE = "DELETE_MESSAGE";
export const START_TYPING_ACK = "START_TYPING_ACK";
export const STOP_TYPING_ACK = "STOP_TYPING_ACK";
export const CHAT_RECEIVED = "CHAT_RECEIVED";
export const REMOVE_MESSAGE = "REMOVE_MESSAGE";
export const IS_USER_ONLINE = "IS_USER_ONLINE";
export const READ_ALL_MESSAGES = "READ_ALL_MESSAGES";
export const BUSINESS_CHAT_RECEIVED = "BUSINESS_CHAT_RECEIVED";
export const BUSINESS_CHAT_HISTORY = "BUSINESS_CHAT_HISTORY";
export const BUSINESS_DELETE_MESSAGE = "BUSINESS_DELETE_MESSAGE";
export const ERROR = "ERROR";
export const INITIAL_LOAD = "INITIAL_LOAD";
export const HIDE_POP_UP = "HIDE_POP_UP";
export const DELETE_CHAT = "DELETE_CHAT";
export const SET_SCROLL = "SET_SCROLL";
export const UNREAD_COUNT = "UNREAD_COUNT";
export const CLEAR_GIF = "CLEAR_GIF";
export const INCREASE_MESSAGE_INDEX = "INCREASE_MESSAGE_INDEX";
export const UPDATE_RECENT_CHAT = "UPDATE_RECENT_CHAT";
export const GET_DIALOG = "GET_DIALOG";
export const REMOVE_EMPTY_DIALOG = "REMOVE_EMPTY_DIALOG";
export const UNSELECT_CHAT = "UNSELECT_CHAT";
export const CLEAR_ALL_DATA = "CLEAR_ALL_DATA";
export const EMPTY_BACKUP_CHAT = "EMPTY_BACKUP_CHAT";
export const ALL_LIVE_CHAT = "ALL_LIVE_CHAT";
export const SET_LOAD_EARLIER_MESSAGES = "SET_LOAD_EARLIER_MESSAGES";
export const HIDE_LIVE_CHAT_POP_UP = "HIDE_LIVE_CHAT_POP_UP";
export const SCROLL_LIVE_CHAT = "SCROLL_LIVE_CHAT";
export const INCREASE_LIVE_CHAT_INDEX = "INCREASE_LIVE_CHAT_INDEX";
export const EMPTY_LIVE_CHAT = "EMPTY_LIVE_CHAT";
export const GET_EVENT_ACTIVITIES = "GET_EVENT_ACTIVITIES";
export const SET_EVENT_EXPERIENCE_DATA = "SET_EVENT_EXPERIENCE_DATA";
export const LIVE_CHAT_SENT_MESSAGE_ACK = "LIVE_CHAT_SENT_MESSAGE_ACK";
export const LIVE_CHAT_REMOVE_BUSINESS_MESSAGE =
  "LIVE_CHAT_REMOVE_BUSINESS_MESSAGE";
export const LIVE_CHAT_UPDATE_RECENT_CHAT = "LIVE_CHAT_UPDATE_RECENT_CHAT";
export const EVENT_EXPERIENCE_EVENT_CHAT = "EVENT_EXPERIENCE_EVENT_CHAT";
export const EVENT_EXPERIENCE_EVENT_SCHEDULED_CHAT = "EVENT_EXPERIENCE_EVENT_SCHEDULED_CHAT";
export const GET_ALL_EVENT_EXPERIENCE_ACTIVITIES = "GET_ALL_EVENT_EXPERIENCE_ACTIVITIES";
export const GET_ALL_CHECKED_IN_USERS = "GET_ALL_CHECKED_IN_USERS";
export const IS_CHECKED_IN_USER_LIST_LOADING = "IS_CHECKED_IN_USER_LIST_LOADING";
export const IS_EVENT_ACTIVITY_LOADING = "IS_EVENT_ACTIVITY_LOADING";
export const GET_ALL_EVENT_EXPERIENCE_CHAT = "GET_ALL_EVENT_EXPERIENCE_CHAT";
export const CLEAR_ALL_EVENT_EXPERIENCE_CHAT =
  "CLEAR_ALL_EVENT_EXPERIENCE_CHAT";
export const GET_SCHEDULED_MESSAGES = "GET_SCHEDULED_MESSAGES";
export const ACK_ADD_MESSAGE_LIKE = "ACK_ADD_MESSAGE_LIKE";
export const ACK_DELETE_MESSAGE_LIKE = "ACK_DELETE_MESSAGE_LIKE";
export const POLL_ACK = "POLL_ACK";
export const CLEAR_EVENT_ACTIVITIES = "CLEAR_EVENT_ACTIVITIES";
export const CLEAR_EVENT_CHECKED_IN_LIST = "CLEAR_EVENT_CHECKED_IN_LIST" ;
export const CLEAR_ALL_EVENT_EXPERIENCE_ACTIVITY = "CLEAR_ALL_EVENT_EXPERIENCE_ACTIVITY";
export const LOAD_EARLIER_MESAGES = "LOAD_EARLIER_MESAGES";
export const SELECTED_MULTIMEDIA_DATA = "SELECTED_MULTIMEDIA_DATA";

//EVENTS
export const CREATE_EVENT = "CREATE_EVENT";
export const FETCH_EVENTS = "FETCH_EVENTS";
export const REFETCH_EVENTS = "REFETCH_EVENTS";
export const REFETCH_OWNEVENTS = "REFETCH_OWNEVENTS";
export const FETCH_OWN_EVENTS = "FETCH_OWN_EVENTS";
export const DELETE_EVENT = "DELETE_EVENT";
export const UPDATE_EVENT = "UPDATE_EVENT";
export const GET_EVENT_USER_EVENTS = "GET_EVENT_USER_EVENTS";
export const CURRENT_EVENT_STATUS = "CURRENT_EVENT_STATUS";
export const CURRENT_EVENT_DETAILS = "CURRENT_EVENT_DETAILS";
export const ADD_MESSAGE_LIKE = "ADD_MESSAGE_LIKE";
export const DELETE_MESSAGE_LIKE = "DELETE_MESSAGE_LIKE";
// EVENTS -> USED
// About
export const ADD_UPDATE_EVENT_BY_EVENT_USER = "ADD_UPDATE_EVENT_BY_EVENT_USER";
export const UPDATE_EVENT_BY_EVENT_USER = "UPDATE_EVENT_BY_EVENT_USER";
export const REMOVE_EVENT_BY_EVENT_USER = "REMOVE_EVENT_BY_EVENT_USER";
// FAQ
export const ADD_FAQ_BY_EVENT_USER = "ADD_FAQ_BY_EVENT_USER";
export const DELETE_FAQ_BY_EVENT_USER = "DELETE_FAQ_BY_EVENT_USER";
export const UPDATE_FAQ_BY_EVENT_USER = "UPDATE_FAQ_BY_EVENT_USER";
// Agenda
export const ADD_AGENDA_BY_EVENT_USER = "ADD_AGENDA_BY_EVENT_USER";
export const DELETE_AGENDA_BY_EVENT_USER = "DELETE_AGENDA_BY_EVENT_USER";
export const UPDATE_AGENDA_BY_EVENT_USER = "UPDATE_AGENDA_BY_EVENT_USER";
// -- Agenda -> Speakers
export const ADD_SPEAKER_BY_EVENT_USER = "ADD_SPEAKER_BY_EVENT_USER";
export const DELETE_SPEAKER_BY_EVENT_USER = "DELETE_SPEAKER_BY_EVENT_USER";
export const UPDATE_SPEAKER_BY_EVENT_USER = "UPDATE_SPEAKER_BY_EVENT_USER";

// ADS
export const ADD_ADS_BY_EVENT_USER = "ADD_ADS_BY_EVENT_USER";
export const DELETE_ADS_BY_EVENT_USER = "DELETE_ADS_BY_EVENT_USER";
export const UPDATE_ADS_BY_EVENT_USER = "UPDATE_ADS_BY_EVENT_USER";
// VENDOR
export const ADD_VENDOR_BY_EVENT_USER = "ADD_VENDOR_BY_EVENT_USER";
export const DELETE_VENDOR_BY_EVENT_USER = "DELETE_VENDOR_BY_EVENT_USER";
export const UPDATE_VENDOR_BY_EVENT_USER = "UPDATE_VENDOR_BY_EVENT_USER";
// SPONSOR
export const ADD_SPONSOR_BY_EVENT_USER = "ADD_SPONSOR_BY_EVENT_USER";
export const DELETE_SPONSOR_BY_EVENT_USER = "DELETE_SPONSOR_BY_EVENT_USER";
export const UPDATE_SPONSOR_BY_EVENT_USER = "UPDATE_SPONSOR_BY_EVENT_USER";

// EVENT -> Tickets Section
// Create Ticket
export const ADD_TICKET_BY_EVENT_USER = "ADD_TICKET_BY_EVENT_USER";
export const DELETE_TICKET_BY_EVENT_USER = "DELETE_TICKET_BY_EVENT_USER";
export const UPDATE_TICKET_BY_EVENT_USER = "UPDATE_TICKET_BY_EVENT_USER";
// Create Discount
export const ADD_DISCOUNT_BY_EVENT_USER = "ADD_DISCOUNT_BY_EVENT_USER";
export const DELETE_DISCOUNT_BY_EVENT_USER = "DELETE_DISCOUNT_BY_EVENT_USER";
export const UPDATE_DISCOUNT_BY_EVENT_USER = "UPDATE_DISCOUNT_BY_EVENT_USER";
// Sales Tax
export const ADD_ADDITIONAL_TAX_BY_EVENT_USER =
  "ADD_ADDITIONAL_TAX_BY_EVENT_USER";
export const DELETE_ADDITIONAL_TAX_BY_EVENT_USER =
  "DELETE_ADDITIONAL_TAX_BY_EVENT_USER";
export const UPDATE_ADDITIONAL_TAX_BY_EVENT_USER =
  "UPDATE_ADDITIONAL_TAX_BY_EVENT_USER";
// Order Form
export const ADD_ORDER_FORM_BY_EVENT_USER = "ADD_ORDER_FORM_BY_EVENT_USER";
export const DELETE_ORDER_FORM_BY_EVENT_USER =
  "DELETE_ORDER_FORM_BY_EVENT_USER";
export const UPDATE_ORDER_FORM_BY_EVENT_USER =
  "UPDATE_ORDER_FORM_BY_EVENT_USER";
// EVENT PUBLISH
export const PUBLISH_EVENT = "PUBLISH_EVENT";

// Set Event type
export const SET_EVENT_TYPE = "SET_EVENT_TYPE";

// Set Event Status
export const EVENT_STATUS = "EVENT_STATUS";

// Set Random Code
export const SET_RANDOM_CODE = "SET_RANDOM_CODE";

// EVENT -> Update Descriptor Name
export const UPDATE_DESCRIPTOR_NAME = "UPDATE_DESCRIPTOR_NAME";

// To Clear Event Details
export const CLEAR_EVENT_DETAILS = "CLEAR_EVENT_DETAILS";

// set sidebar functions
export const SET_SIDEBAR_FUNCTIONS = "SET_SIDEBAR_FUNCTIONS";

// Set StripeUserId
export const SET_STRIPE_USER_ID = "SET_STRIPE_USER_ID";

//Overview
export const GET_ACTIVITY = "GET_ACTIVITY";
export const UPDATE_VISIT = "UPDATE_VISIT";

//CARD_DATA
export const GET_PROFILE = "GET_PROFILE";
export const CANCEL_PLAN = "CANCEL_PLAN";
export const GET_ACCOUNT_DETAIL = "GET_ACCOUNT_DETAIL";
export const UPDATE_NAME = "UPDATE_NAME";
export const UPDATE_USERNAME = "UPDATE_USERNAME";
export const UPDATE_EMAIL = "UPDATE_EMAIL";
export const UPDATE_PHONE = "UPDATE_PHONE";
export const UPDATE_ADDRESS = "UPDATE_ADDRESS";
export const UPDATE_BIO = "UPDATE_BIO";
export const CHANGE_PROFILE_IMAGE = "CHANGE_PROFILE_IMAGE";
export const CHANGE_COVER_IMAGE = "CHANGE_COVER_IMAGE";

//businessProfile
export const GET_BUSINESS_PROFILE = "GET_BUSINESS_PROFILE";

//Get_chart
export const EVENT_LINE_GRAPH_DATA = "EVENT_LINE_GRAPH_DATA";

//LOADER
export const ENABLE_LOADER = "ENABLE_LOADER";
export const DISABLE_LOADER = "DISABLE_LOADER";
export const ENABLE = "ENABLE";
export const DISABLE = "DISABLE";
export const IS_EVENT_EXPERIENCE_CHAT_LOADING = "IS_EVENT_EXPERIENCE_CHAT_LOADING";
export const SET_USER_CHECKED_IN_LIST = "SET_USER_CHECKED_IN_LIST";
//Overview

//myAccount

//businessProfile
export const START_TIMMER = "START_TIMMER";

//Get_chart
export const DISCOUNT_DETAILS = "DISCOUNT_DETAILS";
export const REMAINING_UTILIZATION = "REMAINING_UTILIZATION";
export const CREATE_SHOPPING_DETAILS = "CREATE_SHOPPING_DETAILS";
export const PAYMENT_INTENT_DETAILS = "PAYMENT_INTENT_DETAILS";
//LOADER

export const FAVORITE = "FAVORITE";
export const CARD_DATA = "CARD_DATA";
export const EVENTS_TOTAL_COUNT = "EVENTS_TOTAL_COUNT";

export const LOGIN_POP_UP = "LOGIN_POP_UP";
export const LATITUDE_LONG = "LATITUDE_LONG";
export const EVENT_SEARCH_DATA = "EVENT_SEARCH_DATA";
export const HISTORY = "HISTORY";
export const CAT_DATA = "CAT_DATA";
export const TICKET_CART_ITEM = "TICKET_CART_ITEM";
export const LATITUDE_LONG_SYNC_V2 = "LATITUDE_LONG_SYNC_V2";
export const CITY_ADDRESS = "CITY_ADDRESS";
export const CURRENT_EVENT_TICKET_DETAILS = "CURRENT_EVENT_TICKET_DETAILS";
export const UPDATE_EVENT_DETAILS_BY_EVENT_USER =
  "UPDATE_EVENT_DETAILS_BY_EVENT_USER";

export const PERSIST_SHOPING_CART_DATA = "PERSIST_SHOPING_CART_DATA";

export const CLEAR_CART_SESSION_DISCOUNT_ID = "CLEAR_CART_SESSION_DISCOUNT_ID";

export const STORE_EVENT_DATE_DETAILS = "STORE_EVENT_DATE_DETAILS";
export const REMOVE_EVENT_DATE_DETAILS_BY_TICKET_ID =
  "REMOVE_EVENT_DATE_DETAILS_BY_TICKET_ID";
export const REMOVE_ALL_EVENT_DATE_DETAILS = "REMOVE_ALL_EVENT_DATE_DETAILS";