import React from "react";
import styles from "./Accordion.module.css";
import { AiOutlineDown, AiOutlineRight } from 'react-icons/ai'
import { useState } from "react";

const Accordion = ({ question, answer, open, toggle, id, keyIndex }) => {

  const [openAccordion, setOpenAccordion] = useState(false);

  const handleClick = () => {
    setOpenAccordion(!openAccordion);
  }

  const detectAndWrapLinks = (text) => {
    const urlPattern = /(\b(?:https?:\/\/|www\.)\S+\b)/g;
    return text.replace(urlPattern, (url) => {
      const href = url.startsWith('http') ? url : `http://${url}`;
      return `<a href="${href}" target="_blank" rel="noopener noreferrer" class="${styles.link}">${url}</a>`;
    });
  };

  return (
    <>
      <div key={keyIndex} className={styles.accordion_Container}>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={styles.tabs}>
              <div onClick={() => toggle(id)} className={styles.tab}>
                <div style={{cursor:"pointer"}}>
                  <label style={{cursor:"pointer"}} className={open ? styles.tab_label_Open : styles.tab_label}>
                    {question}
                    <span className={styles.arrow_right}>{open ? <AiOutlineDown /> : <AiOutlineRight />}</span>
                  </label>
                </div>
              </div>
              {open && <div className={styles.tab_content}>
                <div style = {{wordWrap: "break-word"}} dangerouslySetInnerHTML={{ __html: detectAndWrapLinks(answer) }} />
                {/* <p>{answer}</p> */}
              </div>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Accordion;
