

import React from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { routeName } from "Service/env";
import { LoginPopup, SetHistory } from "Actions/index";
import styled from "styled-components";
import { useState } from "react";
// import { Link } from "react-router-dom";






const Nav = styled.nav`
  padding: 0 20px;
  min-height: 9vh;
  background: #1c2022;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled.h1`
  font-size: 25px;
  color: white;
`;

const Menu = styled.ul`
  list-style: none;
  display: flex;

  li:nth-child(2) {
    margin: 0px 20px;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const Item = styled.li``;

const Link = styled.a`
  color: white;
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }
`;

const NavIcon = styled.button`
  background: none;
  cursor: pointer;
  border: none;
  outline: none;

  @media (min-width: 769px) {
    display: none;
  }
`;

const Line = styled.span`
  display: block;
  border-radius: 50px;
  width: 25px;
  height: 3px;
  margin: 5px;
  background-color: #fff;
  transition: width 0.4s ease-in-out;

  :nth-child(2) {
    width: ${props => (props.open ? "40%" : "70%")};
  }
`;

const Overlay = styled.div`
  position: absolute;
  right:0px;
  height: ${props => (props.open ? "70vh" : 0)};
  width: 40vw;
  background: #1c2022;
  transition: height 0.4s ease-in-out;

  @media (min-width: 769px) {
    display: none;
  }
`;

const OverlayMenu = styled.ul`
  list-style: none;
  position: absolute;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -50%);

  li {
    opacity: ${props => (props.open ? 1 : 0)};
    font-size: 25px;
    margin: 50px 0px;
    transition: opacity 0.4s ease-in-out;
  }

  li:nth-child(2) {
    margin: 50px 0px;
  }
`;






const CreateEvent = ({ isLoggedIn, dispatch, history, SetHistory }) => {
  //   useEffect(() => {
  //     dispatch(SetHistory("myhistey"))
  //   }, [])
  const [toggle, toggleNav] = useState(false);
  useEffect(() => {
    scrollToTop();
  }, []);

  const scrollToTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      // behavior: "smooth",
    });
  };

  return (
    <>
      {/* {localStorage.getItem("UserId") === null ? <Redirect to={`${routeName}`} /> : null} */}
      <>
      
        <NavIcon onClick={() => toggleNav(!toggle)}>
          <Line open={toggle} />
          <Line open={toggle} />
          <Line open={toggle} />
        </NavIcon>
     
      <Overlay open={toggle}>
        <OverlayMenu open={toggle}>
        
          
          <li className="nav-item">
                  <Link to="/" className="nav-links" >
                    login
                  </Link>
                </li>
          
        </OverlayMenu>
      </Overlay>
    </>
      <div style={{ margin: 100, textAlign: "center" }}>CreateEvent</div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    favoriteData: state.favoriteReducer.favoriteData,
    cardData: state.favoriteReducer.cardData,
    results: state.loginResponse.results,
    history: state.loginResponse.history,
    isLoggedIn: state.loginResponse.isLoggedIn,
    loginPopUp: state.loginResponse.loginPopUp,
  };
};
const matchDispatchToProps = (dispatch) => {
  return {
    LoginPopup: () => dispatch(LoginPopup),
    SetHistory,
    dispatch,
  };
};
export default connect(mapStateToProps, matchDispatchToProps)(CreateEvent);
