import { UserIconsPic } from "Components/UBMeEvents/Components_UBMeEvents/Navbar/Alphabets/UserIconsPic";
import React, { useEffect } from "react";
import { useState } from "react";
import { CircularProgress } from "@material-ui/core";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { axiosE } from "Service/service";
import TablePagination from "@mui/material/TablePagination";
import moment from "moment";
import { default as momentTz } from "moment-timezone";
import { ws } from "App";
import storeFolder from "../../../../../store";
import img from "../Component/RefreshIcon.png";
import ToolTip from "../../Analytics/Component/tools/ToolTip";
import { connect } from 'react-redux';
import { clearEventActivities , isEventActivityListLoading } from 'Actions/messagesAction';
import { bindActionCreators } from "redux";

const UserActivity = (props) => {
  let storeData = storeFolder.getState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState("");
  const [activityData, setActivityData] = useState([]);
  const [update, setupdate] = useState(false);
  const timeZone = localStorage.getItem("CurrentTimeZone");
  const [activity , setActivity] = useState(props.eventActivityUserList || []);
  const [refresh , setrefresh] = useState(false);
  const [isRefresh , setIsRefresh] = useState(true);
  const activityLength = storeData?.eventReducer?.totalActivity;
  const CurrentEventId = JSON.parse(localStorage.getItem("CurrentEventId"));

  const handleChangePage = (event, newPage) => {
    props.isEventActivityListLoading();
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  useEffect(() => {
    // !refresh && setLoading(true);
    setActivityData([{}]);
    const timeoutId = setTimeout(() => {
      setActivityData(props.eventActivityUserList);
      setLoading(false);
    }, 500);
    const timeoutId2 = setTimeout(() => {
      setrefresh(false);
    }, 600);
    return () => {clearTimeout(timeoutId)
      clearTimeout(timeoutId2)
    };
  }, [update , props.eventActivityUserList]);

  useEffect(() => {
    GetActivitiesByOrganizerId();
  }, [page]);

  useEffect(()=>{
    if(page == 0 && isRefresh){
      const intervalId = setInterval(()=>{
        GetActivitiesByOrganizerIdRefresh();
      }, 300000);
      return () => clearInterval(intervalId);
    }
  },[page])

  const GetActivitiesByOrganizerId = () => {
    try {
        setTimeout(() => {
          if(storeData.messageReducer?.connection){
            ws.json(
              {
                pageIndex: page + 1,
                pageSize: 5,
                eventId: CurrentEventId,
                type: "getEventActivities"
              }
            )
          };
        }, 1000);

      setupdate(prevUpdate => !prevUpdate);
      setLoading(true);
      setIsRefresh(true);
    } catch (error) { }
  };

  const GetActivitiesByOrganizerIdRefresh = async () => {
    const CurrentEventId = JSON.parse(localStorage.getItem("CurrentEventId"));
    setrefresh(true);
    try {
      // const { data } = await axiosE.post(
      //   "/ManageEvents/GetActivitiesByOrganizerId",
      //   {
      //     OrganizerId: localStorage.getItem("UserId"),
      //     PageNumber: page + 1,
      //     PageSize: rowsPerPage,
      //   }
      // );
      await ws.json(
        {
          pageIndex: page + 1,
          pageSize: 5,
          eventId: CurrentEventId,
          type: "getEventActivities"
        }
      )
      setupdate(prevUpdate => !prevUpdate);
      // console.log("@@@@@@@@@", JSON.parse(data.results.Data));
      // if (data.results.ResponseCode) {
      //   setActivity(JSON.parse(data.results.Data));
      // }
      setLoading(false);
    } catch (error) { }
  };

  useEffect(() => {
    return () => {
      props.clearEventActivities();
      props.isEventActivityListLoading();
    }
  }, [])

  const timeUTCtoLocal = (date, timezone) => {
    var local = momentTz
      .tz(moment.utc(date), "MM/DD/YYYY hh:mm:ss a", timezone)
      .format("MM/DD/YYYY hh:mm:ss a");

    const Time = moment(local, "MM/DD/YYYY hh:mm:ss a");
    return `${moment(Time).format("MMM DD, YYYY")} at ${moment(Time).format(
      "hh:mm A"
    )}`;
  };

  const ConvertedStartDateTime = (Time) => {
    return `${moment(Time).format("MMM DD, YYYY")} at ${moment(Time).format(
      "hh:mm A"
    )}`;
  };
  return (
    <div style={{ width: "100%" }}>
      {/* {loading ? (
        <div
          style={{
            width: "100%",
            height: "473px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={50} color="secondary" />
        </div>
      ) : ( */}
      <div>
        <div style={{
          // overflow: "auto",
          padding: 0,
          // borderTop: "1px solid rgb(221, 221, 221)",
          maxHeight: "fit-content",
          minHeight: "370px"
        }}>
          <div className="email_invite_header">
                    <div className="email_invite">
                      <div style={{marginTop:"15px", display:"flex"}}>
                        <span style={{ fontSize: 30 }}>Activity</span>
                        <span>
                          <p style={{ paddingTop:"8px" }}>{ToolTip(
                            "",
                            "See real-time activity updates 2 hours before and during your event!"
                          )}</p>
                        </span>
                        <span
                          className="refresh-btn"
                          style={{ marginTop: "-2px", cursor: "pointer", marginLeft:"15px" }}
                        >
                          <img
                            src={img}
                            alt="refresh icon"
                            style={{ height: "20px",marginBottom:"5px" }}
                            onClick={() => {
                              setPage(0);
                              setIsRefresh(false);
                              props.isEventActivityListLoading();
                              GetActivitiesByOrganizerId()
                            }}
                          />
                      </span>
                      </div>
                    </div>
                  </div>
          {
            props.isEventActivityLoading ? (<>
              <div
                style={{
                  width: "100%",
                  height: "350px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress size={50} color="secondary" />
              </div>
            </>) :
              (
                <>
                  {
                  activityData?.length > 0 &&
                  !refresh ?
                  (
                    activityData?.map((item,index) => {
                      return (
                        <div
                          key={index}
                          style={{ justifyContent: "space-between" }}
                          className="activity_wrapper"
                        >
                          <div style={{ display: "flex" }} className="userActivity">
                            <LazyLoadImage
                              src={
                                (item.Photo !== null && item.Photo !== "")
                                  ? `https://d2gnb7jphto2jj.cloudfront.net/UploadImages/AppUserProfileImages/ProfileOriginalImage/${item.Photo}`
                                  : UserIconsPic(item?.UserName?.[0][0]?.toUpperCase())
                              }
                              style={{ boxShadow: "none",marginRight:"14px"}}
                              // className="Profile_img"
                              PlaceholderSrc={"PlaceholderImage"}
                              // effect="blur"
                            />
                            <div className="activity_note">
                              <p style={{ fontSize: 14, fontWeight: 600 }}>{item?.Activity}</p>
                            </div>
                          </div>

                          <div className="activity_date">
                            <p style={{ fontSize: 14, fontWeight: 600 }}>
                              {moment(item?.ActivityDateTime).tz(JSON.parse(timeZone))?.format("MMM DD, YYYY [at] hh:mm A")}
                            </p>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        {refresh ? (
                          storeData?.eventReducer?.eventActivityList?.map((item) => {
                            return (
                              <div
                                style={{ justifyContent: "space-between" }}
                                className="activity_wrapper"
                              >
                                <div style={{ display: "flex" }} className="userActivity">
                                  <img
                                    src={
                                      (item.Photo !== null && item.Photo !== "")
                                        ? `https://d2gnb7jphto2jj.cloudfront.net/UploadImages/AppUserProfileImages/ProfileOriginalImage/${item.Photo}`
                                        : UserIconsPic(item?.UserName?.[0][0]?.toUpperCase())
                                    }
                                    style={{ boxShadow: "none" }}
                                    // className="Profile_img"
                                    PlaceholderSrc={"PlaceholderImage"}
                                    // effect="blur"
                                  />
                                  <div className="activity_note">
                                    <p style={{ fontSize: 14, fontWeight: 600 }}>{item?.Activity}</p>
                                  </div>
                                </div>

                                <div className="activity_date">
                                  <p style={{ fontSize: 14, fontWeight: 600 }}>
                                    {moment(item?.ActivityDateTime).tz(JSON.parse(timeZone))?.format("MMM DD, YYYY [at] hh:mm A")}
                                  </p>
                                </div>
                              </div>
                            );
                          })
                        ):(
                          <h3 style={{ color: "#808080", textAlign: "center" }}>
                            There is no recent activity!
                          </h3>
                        )}
                      </div>
                    </div>
                  )}
                </>
              )
          }
        </div>
        {/* {activityData?.length > 0 && (
            <div className="custom-pagination">
              <TablePagination
                rowsPerPageOptions={[]}
                component="div"
                count={
                  activityData?.length > 0 ? Number(activityData[0]?.TotalCounts) : 0
                }
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                showFirstButton
                showLastButton
              />
            </div>
          )} */}
        {activityData?.length > 0 && (
          <div className="custom-pagination">
            <TablePagination
              rowsPerPageOptions={[]}
              component="div"
              count={
                activityData.length ? activityLength :  0
              }
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              showFirstButton
              showLastButton
            />
          </div>
        )}
        <div style={{ width: "100%", height: "1px" }}></div>
      </div>
      {/* )} */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  eventActivityUserList:state.eventReducer?.eventActivityList,
  isEventActivityLoading:state.eventReducer?.isEventActivityListLoading,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      clearEventActivities,
      isEventActivityListLoading
    },
    dispatch
  );
};

export default connect(mapStateToProps,mapDispatchToProps)(UserActivity);

