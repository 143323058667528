import React, { Component } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css"; // see installation section above for versions of NPM older than 3.0.0
import { Button, IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { Text } from "Constants";

export default class ImageCropper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: props.imgSrc,
      cropData: "#",
      cropper: "",
    };

  }

  onChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      this.setState({ image: reader.result });
    };
    reader.readAsDataURL(files[0]);
  };

  getCropData = () => {
    const { cropper } = this.state;
    if (typeof cropper !== "undefined") {
      // this.props.setImage(cropper.getCroppedCanvas().toBlob());
      cropper.getCroppedCanvas().toBlob((blob) => {
        this.props.setImage(blob);
      });
    }
  };

  render() {
    const imageCropperModalButtonsWrapper = {
      textAlign: "center",
      borderTop: "1px solid #ced4da",
      display: "flex",
      justifyContent: "flex-end",
      flex: 0.1,
      alignItems: "center",
      padding: "10px 22px 0px",
      alignItems:'center'
    };
    const cancelButton = {
      fontSize: "1.2rem",
      marginRight: "20px",
      border: "1px solid #968e8e",
      width: "120px",
      height: "50px",
    };

    const saveButton = {
      backgroundColor: "rgb(182, 14, 181)",
      fontSize: "1.2rem",
      color: "white",
      borderRadius: "9px",

      width: "120px",
      height: "50px",
    };

    const createEventFAQModalHeader = {
      display: "flex",
      alignItems: "center",
      borderBottom: "1px solid #F5F5F5",
      marginBottom: "30px",
      justifyContent: "center",

      padding: "0px 10px 10px",
    };

    const createFAQModalHeader = {
      margin: "0px",
      color: "#A338A4",
      fontSize: "2.5rem",
      flex: 1,
      textAlign: "center",
    };

    const cropperConatiner = {
      flex: 0.8,
      width: "50vw",
      "@media (max-width: 480px)": {
        width: "100vw !important",
        backgroundColor:'red'
      }, 
    }

    const { image, cropData, cropper } = this.state;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          flex: 1,
          alignItems:'center'
        }}
      >
        <div style={createEventFAQModalHeader}>
          <IconButton
            onClick={() => this.props.cropModal(false)}
            style={{ flex: 0 }}
          >
            <Close height={30} width={30} />
          </IconButton>
          <p style={createFAQModalHeader}>{Text.CropImage}</p>
        </div>
        <div className="cropper_conatiner"  >
          <Cropper
             style={{
              height: 422,
              width: "100%",
              margin: "0 auto",
              marginTop: 25
          }}
            zoomTo={0}
            aspectRatio={2 / 1}
            preview=".img-preview"
            src={image}
            viewMode={2}
            minCropBoxHeight={10}
            minCropBoxWidth={10}
            responsive={true}
            autoCropArea={0}
            center={true}
            checkOrientation={false}
            onInitialized={(instance) => {
              this.setState({ cropper: instance });
            }}
            zoomable={false}
            guides={true}
            background={false}
          />
        </div>
        <div style={imageCropperModalButtonsWrapper}>
          <Button
            style={cancelButton}
            onClick={() => this.props.cropModal(false)}
          >
            {Text.Cancel}
          </Button>
          <Button
            style={saveButton}
            onClick={this.getCropData}
            variant={"contained"}
          >
            {Text.Save}
          </Button>
        </div>
      </div>
    );
  }
}
