/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable eqeqeq */
/* eslint-disable no-unreachable */
import {
  IDENTITY,
  RECENT_CHAT,
  CHAT_HISTORY,
  SELECTED_CHAT,
  CHAT_SENT_ACK,
  USER_ONLINE,
  GET_GIF,
  DELETE_MESSAGE,
  START_TYPING_ACK,
  STOP_TYPING_ACK,
  CHAT_RECEIVED,
  REMOVE_MESSAGE,
  OPEN,
  IS_USER_ONLINE,
  READ_ALL_MESSAGES,
  GET_DETAILED_GIF,
  BUSINESS_CHAT_RECEIVED,
  BUSINESS_CHAT_HISTORY,
  BUSINESS_DELETE_MESSAGE,
  ERROR,
  HIDE_POP_UP,
  DELETE_CHAT,
  SET_SCROLL,
  UNREAD_COUNT,
  CLEAR_GIF,
  GET_DIALOG,
  REMOVE_EMPTY_DIALOG,
  EMPTY_BACKUP_CHAT,
  EMPTY_LIVE_CHAT,
  UNSELECT_CHAT,
  INCREASE_MESSAGE_INDEX,
  UPDATE_RECENT_CHAT,
  CLEAR_ALL_DATA,
  INITIAL_LOAD,
  LOGOUT,
  EVENT_EXPERIENCE_EVENT_CHAT,
  GET_ALL_EVENT_EXPERIENCE_CHAT,
  CLEAR_ALL_EVENT_EXPERIENCE_CHAT,
  SELECTED_MULTIMEDIA_DATA,
  EVENT_EXPERIENCE_EVENT_SCHEDULED_CHAT,
  GET_ALL_CHECKED_IN_USERS,
  GET_ALL_EVENT_EXPERIENCE_ACTIVITIES,
  CLEAR_ALL_EVENT_EXPERIENCE_ACTIVITY,
  GET_EVENT_ACTIVITIES,
  SET_EVENT_EXPERIENCE_DATA,
  ADD_MESSAGE_LIKE,
  GET_SCHEDULED_MESSAGES,
  ACK_ADD_MESSAGE_LIKE,
  ACK_DELETE_MESSAGE_LIKE,
  POLL_ACK,
} from "../Actions/actionTypes";
import { routeName } from "../../src/Service/env";
import { ws } from "../App";
import _ from "lodash";
import sound from "../Sounds/messageReceived_notification.mp3";
import { audioFunc } from "../Service/helper";
import moment from "moment";

const initial = {
  messageIndex: 0,
  backUpChat: {},
  backUpIndex: {},
  initialCall: {
    OneToOne: true,
    QuestionChat: true,
  },
  backUpChatSupporter: {},
  drawerStatus: false,
  showAllMessages: false,
  toastList: [],
  eventExperienceEventChat: [],
  isEventExperienceChatLoading: true,
  selectedMultimediaData: {},
  scheduledMessages: [],

  isCheckedInUsersLoading: true,
  eventExperienceEventActivities: [],

  ApiResponse:"",
  messageLike:"",

};

export default (state = initial, action) => {
  switch (action.type) {
    case OPEN:
      state.connection = true;
      return _.cloneDeep({ ...state });
    case IDENTITY:
      state.identity = action.payload;
      return _.cloneDeep({ ...state });
    case USER_ONLINE:
      return _.cloneDeep({ ...state, userOnline: action.payload });
    case IS_USER_ONLINE:
      state.isUserOnline = action.payload;
      return _.cloneDeep({ ...state });
    case RECENT_CHAT:
      let recentChatData = state.recentChat;
      if (
        (state.recentChat && state.recentChat.length) <
        action.payload.totalCount
      ) {
        if (recentChatData) {
          recentChatData = action.payload.chats
            ? recentChatData.concat(action.payload.chats)
            : recentChatData;
        } else {
          recentChatData = action.payload.chats;
        }
      } else {
        if (action.payload.chats.length !== 0) {
          recentChatData = action.payload.chats;
        }
      }
      if (!state.selectedChat && recentChatData) {
        state.selectedChat = recentChatData[0];
      }
      return _.cloneDeep({
        ...state,
        recentChat: recentChatData,
        totalRecentChat: action.payload.totalCount,
        scroll: true,
      });
    case UPDATE_RECENT_CHAT:
      let rcIndex = _.findIndex(
        state.recentChat,
        (rc) => rc.dialog_id === action.payload.googlePlacedId
      );
      if (rcIndex !== -1) {
        if (state.selectedChat) {
          if (
            state.selectedChat.dialog_id.toUpperCase() !==
            action.payload.googlePlacedId.toUpperCase() ||
            window.location.pathname !== `${routeName}/Dashboard/Messages`
          ) {
            state.recentChat[rcIndex] &&
              (state.recentChat[rcIndex].unread_count =
                state.recentChat[rcIndex].unread_count + 1);
          }
          state.recentChat[rcIndex] &&
            (state.recentChat[rcIndex].last_message_json = JSON.stringify(
              action.payload.chat_message
            ));

          if (state.chatHistory) {
            if (
              state.chatHistory[0].GooglePlacedId ===
              action.payload.googlePlacedId
            ) {
              let chat = {
                CommentDetail: JSON.stringify(action.payload.chat_message),
                CommentId: action.payload.chat_id,
                CreatedDate: action.payload.chat_time,
                First_Name: action.payload.firstname,
                GooglePlacedId: action.payload.googlePlacedId,
                Photo: null,
                UserId: action.payload.user_id,
                UserName: action.payload.firstname,
                message_type: Number(action.payload.message_type),
              };
              state.chatHistory.push(chat);
              state.showPopUp = true;
            }
            let scrollElement = document.getElementById("chatWrapper");
            if (scrollElement) {
              if (
                scrollElement.clientHeight + scrollElement.scrollTop + 5 >=
                scrollElement.scrollHeight
              ) {
                state.scroll = true;
              } else {
                state.scroll = false;
              }
            }
          }
        } else {
          state.recentChat[rcIndex] &&
            (state.recentChat[rcIndex].unread_count =
              state.recentChat[rcIndex].unread_count + 1);
          state.recentChat[rcIndex] &&
            (state.recentChat[rcIndex].last_message_json = JSON.stringify(
              action.payload.chat_message
            ));
        }
      } else {
        let recentChat;
        if (state.recentChat) {
          recentChat = {
            FullName: action.payload.FullName,
            RowNumber: state.recentChat.length,
            Type: "OneToOne",
            UserName: action.payload.UserName,
            dialog_id: action.payload.dialog_id,
            from_user_id: action.payload.from_user_id,
            last_message_json: JSON.stringify(action.payload.chat_message),
            photo: action.payload.photo
              ? `https://d2gnb7jphto2jj.cloudfront.net/UploadImages/AppUserProfileImages/ProfileOriginalImage/${action.payload.photo}`
              : null,
            unread_count: 1,
            userid: action.payload.from_user_id,
            utc_message_date: action.payload.chat_time,
          };
          state.recentChat.unshift(recentChat);
        } else {
          recentChat = {
            unread_count: 1,
          };
          let tempRecentChat = [];
          tempRecentChat.push(recentChat);
          state.recentChat = tempRecentChat;
        }
      }
      return _.cloneDeep({ ...state });
    case GET_DIALOG:
      state.messageIndex = state?.recentChat?.length;
      state.selectedChat = action.payload;
      // state?.recentChat?.unshift(action.payload);
      state.totalRecentChat++;
      state.chatHistory = [];
      return _.cloneDeep({ ...state });
    case REMOVE_EMPTY_DIALOG:
      state.recentChat = state.recentChat.filter(
        (chat) => chat.userid != action.payload.UserId
      );
      state.totalRecentChat--;
      state.selectedChat = state.recentChat[0];
      ws.json({
        count: 20,
        deleteCount: 0,
        dialog_id: state.recentChat[0].dialog_id,
        index: 1,
        type: "chatHistory",
        user_id: localStorage.getItem("UserId"),
      });

      return _.cloneDeep({ ...state });
    case CHAT_HISTORY:
      let chatScroll = false;
      if (
        state.backUpChat[
        action.payload.chats && action.payload.chats[0].dialog_id
        ]
      ) {
        let dialog_id = action.payload.chats[0].dialog_id;
        if (state.messageIndex === state.backUpIndex[dialog_id]) {
          chatScroll = true;
          if (
            action.payload.chats[0].chat_id !==
            state.chatHistory[state?.chatHistory?.length - 1].chat_id
          ) {
            state.chatHistory = action.payload.chats.reverse();
          }
          return _.cloneDeep({ ...state, scroll: chatScroll });
        }
      }
      let chatHistoryData = state.chatHistory;
      if (action.payload.chats && action.payload.chats.length > 0) {
        if (chatHistoryData && chatHistoryData.length !== 0) {
          if (
            chatHistoryData[0].dialog_id ===
            (action.payload.chats && action.payload.chats[0].dialog_id)
          ) {
            chatHistoryData = chatHistoryData.reverse();
            chatHistoryData = chatHistoryData.concat(action.payload.chats);
            chatHistoryData = chatHistoryData.reverse();
          } else {
            if (action.payload.chats) {
              chatHistoryData = action.payload.chats.reverse();
              chatScroll = true;
            } else {
              !chatHistoryData && (chatHistoryData = []);
            }
          }
        } else {
          chatHistoryData =
            action.payload.chats && action.payload.chats.reverse();
          chatScroll = true;
        }
      }
      return _.cloneDeep({
        ...state,
        chatHistory: chatHistoryData,
        totalChat: action.payload.count,
        chatReceived: chatHistoryData ? chatHistoryData.length : 0,
        scroll: chatScroll,
      });
    case READ_ALL_MESSAGES:
      let all_data = { ...state };
      let index = "";
      if (all_data.selectedChat.Type === "PlaceQuestionChat") {
        index = _.findIndex(
          all_data.recentChat,
          (rc) =>
            rc.dialog_id === action.payload.dialog_id &&
            (rc.QuestionId.toString() ===
              action.payload.QuestionId.toString() ||
              rc.userid.toString() === action.payload.userid.toString())
        );

        if (
          action.payload.from_user_id.toString() ===
          localStorage.getItem("UserId").toString()
        ) {
          if (index >= 0) {
            all_data.recentChat[index].unread_count = 0;
          }
        }
      } else {
        index = _.findIndex(
          all_data.recentChat,
          (ad) => ad.dialog_id === action.payload.dialog_id
        );

        if (index >= 0) {
          all_data.recentChat[index].unread_count = 0;
        }
      }
      return _.cloneDeep({ ...all_data });
    case START_TYPING_ACK:
      let startTyping;
      if (
        action.payload.dialog_id.toUpperCase() ===
        (state.selectedChat && state.selectedChat.dialog_id.toUpperCase())
      ) {
        startTyping = {
          status: true,
          data: action.payload,
        };
      }
      return _.cloneDeep({ ...state, typing: startTyping });
    case STOP_TYPING_ACK:
      let stopTyping;
      if (
        action.payload.dialog_id.toUpperCase() ===
        (state.selectedChat && state.selectedChat.dialog_id.toUpperCase())
      ) {
        stopTyping = {
          status: false,
          data: action.payload,
        };
      }
      return _.cloneDeep({ ...state, typing: stopTyping });
    case CHAT_SENT_ACK:
      let allData = { ...state };
      let ChatIndex = _.findIndex(
        allData.recentChat,
        (rc) => rc.dialog_id === action.payload.dialog_id
      );
      if (ChatIndex !== -1) {
        if (state.chatHistory) {
          let data = {
            Delete_Messase: null,
            NUMBER: state.chatHistory ? state.chatHistory.length.toString() : 1,
            chat_id: action.payload.chat_id,
            dialog_id: action.payload.dialog_id,
            from_user_id: action.payload.from_user_id.toString(),
            message_json: JSON.stringify(action.payload.chat_message),
            message_status: 1,
            message_type: action.payload.message_type.toString(),
            tbl_chat_groupchat_status: "null",
            tbl_useraccount: "null",
            utc_message_date: action.payload.chat_time,
          };
          allData.chatHistory.push(data);
          let recentChatIndex = _.findIndex(
            allData.recentChat,
            (chat) => chat.dialog_id === allData.selectedChat.dialog_id
          );
          allData.recentChat[recentChatIndex].last_message_json =
            JSON.stringify(action.payload.chat_message);
          allData.recentChat[recentChatIndex].utc_message_date =
            action.payload.chat_time;
          allData.scroll = true;
        } else {
          let recentChatIndex = _.findIndex(
            allData.recentChat,
            (chat) => chat.dialog_id === allData.selectedChat.dialog_id
          );
          allData.recentChat[recentChatIndex].last_message_json =
            JSON.stringify(action.payload.chat_message);
          allData.recentChat[recentChatIndex].utc_message_date =
            action.payload.chat_time;
          var arr = allData.recentChat[recentChatIndex];
          allData.recentChat.splice(recentChatIndex, 1);
          allData.recentChat.unshift(arr);
          allData.scroll = true;
        }
      } else {
        let recentChat = {
          FullName: action.payload.FullName,
          RowNumber: state.recentChat ? state.recentChat.length : 0,
          Type: "OneToOne",
          UserName: action.payload.UserName,
          dialog_id: action.payload.dialog_id,
          from_user_id: action.payload.from_user_id,
          last_message_json: JSON.stringify(action.payload.chat_message),
          photo: action.payload.photo
            ? `https://d2gnb7jphto2jj.cloudfront.net/UploadImages/AppUserProfileImages/ProfileOriginalImage/${action.payload.photo}`
            : null,
          unread_count:
            action.payload.from_user_id === localStorage.getItem("UserId")
              ? 0
              : 1,
          userid: action.payload.to_user_id,
          utc_message_date: action.payload.chat_time,
        };
        if (allData.recentChat) {
          let data = {
            Delete_Messase: null,
            NUMBER: state.chatHistory ? state.chatHistory.length.toString() : 1,
            chat_id: action.payload.chat_id,
            dialog_id: action.payload.dialog_id,
            from_user_id: action.payload.from_user_id.toString(),
            message_json: JSON.stringify(action.payload.chat_message),
            message_status: 1,
            message_type: action.payload.message_type.toString(),
            tbl_chat_groupchat_status: "null",
            tbl_useraccount: "null",
            utc_message_date: action.payload.chat_time,
          };
          allData.chatHistory.push(data);

          allData.recentChat.unshift(recentChat);
        } else {
          allData.recentChat = [recentChat];
          allData.totalRecentChat = allData.totalRecentChat + 1;
          if (allData.chatHistory) {
            allData.chatHistory = [recentChat];
          } else {
            allData.chatHistory && allData.chatHistory.push(recentChat);
          }
        }
      }
      return _.cloneDeep(allData);
    case CHAT_RECEIVED:
      let CRAllData = { ...state };

      audioFunc(false, false, sound);

      let parentChatIndex = _.findIndex(
        CRAllData.recentChat,
        (rc) => rc.dialog_id === action.payload.dialog_id
      );
      if (parentChatIndex !== -1) {
        CRAllData.recentChat[parentChatIndex] &&
          (CRAllData.recentChat[parentChatIndex].last_message_json =
            JSON.stringify(action.payload.chat_message));
        if (CRAllData.selectedChat) {
          if (state.chatHistory) {
            let receivedData = {
              Delete_Messase: null,
              NUMBER: state.chatHistory.length.toString(),
              chat_id: action.payload.chat_id,
              dialog_id: action.payload.dialog_id,
              from_user_id: action.payload.from_user_id.toString(),
              message_json: JSON.stringify(action.payload.chat_message),
              message_status: 1,
              message_type: action.payload.message_type.toString(),
              tbl_chat_groupchat_status: "null",
              tbl_useraccount: "null",
              utc_message_date: action.payload.chat_time,
            };
            CRAllData.recentChat[parentChatIndex] &&
              (CRAllData.recentChat[parentChatIndex].unread_count =
                CRAllData.recentChat[parentChatIndex].unread_count + 1);

            let temp = CRAllData.recentChat[parentChatIndex];
            CRAllData.recentChat.splice(parentChatIndex, 1);
            CRAllData.recentChat.unshift(temp);

            if (
              action.payload.dialog_id.toUpperCase() ===
              CRAllData.selectedChat.dialog_id.toUpperCase()
            ) {
              CRAllData.chatHistory.push(receivedData);
              CRAllData.showPopUp = true;
            }
          } else {
            CRAllData.recentChat[parentChatIndex] &&
              (CRAllData.recentChat[parentChatIndex].unread_count =
                CRAllData.recentChat[parentChatIndex].unread_count + 1);

            let temp = CRAllData.recentChat[parentChatIndex];
            CRAllData.recentChat.splice(parentChatIndex, 1);
            CRAllData.recentChat.unshift(temp);
          }
        } else {
          CRAllData.recentChat[parentChatIndex] &&
            (CRAllData.recentChat[parentChatIndex].unread_count =
              CRAllData.recentChat[parentChatIndex].unread_count + 1);
        }
      } else {
        let recentChat = {
          FullName: action.payload.FullName,
          RowNumber: state.recentChat ? state.recentChat.length : 0,
          Type: "OneToOne",
          UserName: action.payload.UserName,
          dialog_id: action.payload.dialog_id,
          from_user_id: action.payload.from_user_id,
          last_message_json: JSON.stringify(action.payload.chat_message),
          photo: action.payload.photo
            ? `https://d2gnb7jphto2jj.cloudfront.net/UploadImages/AppUserProfileImages/ProfileOriginalImage/${action.payload.photo}`
            : null,
          unread_count: 1,
          userid: action.payload.from_user_id,
          utc_message_date: action.payload.chat_time,
        };
        if (CRAllData.recentChat) {
          CRAllData.recentChat.unshift(recentChat);
        } else {
          CRAllData.recentChat = [recentChat];
          CRAllData.totalRecentChat = CRAllData.totalRecentChat + 1;
          if (CRAllData.chatHistory) {
            CRAllData.chatHistory = [recentChat];
          } else {
            CRAllData.chatHistory && CRAllData.chatHistory.push(recentChat);
          }
        }
      }
      CRAllData.scroll = false;
      let chatElement = document.getElementById("chatWrapper");
      if (chatElement) {
        if (
          chatElement.clientHeight + chatElement.scrollTop + 5 >=
          chatElement.scrollHeight
        ) {
          CRAllData.scroll = true;
        }
      }
      return _.cloneDeep({ ...state, chatReceived: CRAllData });
    case GET_GIF:
      return _.cloneDeep({ ...state, gif: action.payload });
    case GET_DETAILED_GIF:
      let detailGifData = state.detailedGIF;
      if (
        state.detailedGIF &&
        state.detailedGIF.weburl === action.payload.weburl
      ) {
        detailGifData.results = detailGifData.results.concat(
          action.payload.results
        );
        detailGifData.next = action.payload.next;
      } else {
        detailGifData = action.payload;
      }
      return _.cloneDeep({ ...state, detailedGIF: detailGifData });
    case UNREAD_COUNT:
      state.unreadCount = action.payload.UnreadCount;
      return _.cloneDeep({ ...state });
      break;
    case BUSINESS_CHAT_HISTORY:
      // if (
      //   state.backUpChat[
      //     action.payload.chats[0] && action.payload.chats[0].GooglePlaceId
      //   ]
      // ) {
      //   return state;
      // }

      let BusinessChatHistoryData = state.chatHistory;
      let scroll = false;
      if (action.payload.chats && action.payload.chats.length > 0) {
        if (BusinessChatHistoryData && BusinessChatHistoryData.length !== 0) {
          if (action.payload.MainQuestionId !== undefined) {
            // <<<<<<< Updated upstream
            //             if (
            //               state.selectedChat.MainQuestionId !==
            //                 action.payload.chats[0].MainQuestionId &&
            //               state.selectedChat.dialog_id &&
            //               state.selectedChat.dialog_id ===
            //                 (action.payload.chats && action.payload.chats[0].GooglePlaceId)
            //             ) {
            //               BusinessChatHistoryData = BusinessChatHistoryData.reverse();
            //               BusinessChatHistoryData = BusinessChatHistoryData.concat(
            //                 action.payload.chats
            //               );
            //               BusinessChatHistoryData = BusinessChatHistoryData.reverse();
            // =======
            if (
              state.selectedChat.dialog_id &&
              state.selectedChat.dialog_id ===
              (action.payload.chats && action.payload.chats[0].GooglePlaceId)
            ) {
              // Need Similar obj
              const result1 = BusinessChatHistoryData.filter(function (o1) {
                return action.payload.chats.some(function (o2) {
                  return o1.CommentId == o2.CommentId; // id is unnique both array object
                });
              });

              if (state.questionId === action.payload.MainQuestionId) {
                if (result1.length === 0) {
                  BusinessChatHistoryData = BusinessChatHistoryData.reverse();
                  BusinessChatHistoryData = BusinessChatHistoryData.concat(
                    action.payload.chats
                  );
                  BusinessChatHistoryData = BusinessChatHistoryData.reverse();
                } else if (
                  result1.length > 0 &&
                  result1.length !== action.payload.chats.length
                ) {
                  BusinessChatHistoryData = action.payload.chats.reverse();
                }
                if (state.messageIndex === 1) {
                  scroll = true;
                }
              } else {
                if (action.payload.chats) {
                  BusinessChatHistoryData = action.payload.chats.reverse();
                  scroll = true;
                } else {
                  BusinessChatHistoryData = [];
                }
              }
              // >>>>>>> Stashed changes
            } else {
              if (action.payload.chats) {
                BusinessChatHistoryData = action.payload.chats.reverse();
                scroll = true;
              } else {
                BusinessChatHistoryData = [];
              }
            }
          } else if (
            state.selectedChat.dialog_id &&
            state.selectedChat.dialog_id ===
            (action.payload.chats && action.payload.chats[0].GooglePlaceId)
          ) {
            BusinessChatHistoryData = BusinessChatHistoryData.reverse();
            BusinessChatHistoryData = BusinessChatHistoryData.concat(
              action.payload.chats
            );
            BusinessChatHistoryData = BusinessChatHistoryData.reverse();
          } else {
            if (action.payload.chats) {
              BusinessChatHistoryData = action.payload.chats.reverse();
              scroll = true;
            } else {
              BusinessChatHistoryData = [];
            }
          }
        } else {
          BusinessChatHistoryData =
            action.payload.chats && action.payload.chats.reverse();
          scroll = true;
        }
      }
      return _.cloneDeep({
        ...state,
        chatHistory: BusinessChatHistoryData,
        isEventExperienceChatLoading: BusinessChatHistoryData.length ? false : true,
        totalChat: action.payload.totalCount,
        chatReceived: BusinessChatHistoryData
          ? BusinessChatHistoryData.length
          : 0,
        scroll: scroll,
        questionId: action.payload.MainQuestionId,
      });
    case BUSINESS_CHAT_RECEIVED:
      let BusinessCRAllData = { ...state };

      audioFunc(false, false, sound);

      let BusinessChatIndex = _.findIndex(
        BusinessCRAllData.recentChat,
        (rc) =>
          rc.dialog_id === action.payload.googlePlacedId &&
          (rc?.QuestionId?.toString() === action?.payload?.question_id?.toString() ||
            rc?.userid?.toString() === action?.payload.user_id?.toString())
      );

      if (BusinessChatIndex !== -1) {
        BusinessCRAllData.recentChat[BusinessChatIndex].last_message_json =
          JSON.stringify(action.payload.chat_message);
        if (state.chatHistory) {
          // let businessReceivedData = {
          //   Delete_Messase: null,
          //   RowNumber: BusinessCRAllData.chatHistory.length.toString(),
          //   CommentId: action.payload.chat_id,
          //   CreatedDate: action.payload.chat_time,
          //   First_Name: action.payload.firstname,
          //   GooglePlaceId: action.payload.googlePlacedId,
          //   photo: action.payload.photo,
          //   UserId: action.payload.user_id,
          //   UserName: action.payload.firstname.toLowerCase(),
          //   CommentDetail: JSON.stringify(action.payload.chat_message),
          //   message_type: action.payload.message_type,
          //   questionUnreadCount: action.payload.questionUnreadCount,
          // };
          let businessReceivedData = {
            Delete_Messase: null,
            FullName: action?.payload?.firstname,
            First_Name: action?.payload?.firstname,
            UserName: action?.payload?.firstname?.toLowerCase(),
            QuestionId: action.payload.question_id,
            CommentId: action.payload.chat_id,
            RowNumber: BusinessCRAllData?.chatHistory?.length?.toString(),
            Type: action.payload.chat_type,
            dialog_id: action.payload.googlePlacedId,
            GooglePlaceId: action.payload.googlePlacedId,
            from_user_id: action.payload.from_user_id,
            last_message_json: JSON.stringify(action.payload.chat_message),
            CommentDetail: JSON.stringify(action.payload.chat_message),
            photo: action?.payload?.photo,
            questionUnreadCount: action.payload.questionUnreadCount,
            userid: action.payload.user_id,
            UserId: action.payload.user_id,
            CreatedDate: action.payload.chat_time,
            message_type: action.payload.message_type,
          };

          if (
            localStorage.getItem("UserId").toString() !==
            action.payload.user_id.toString()
          ) {
            BusinessCRAllData.recentChat[BusinessChatIndex] &&
              (BusinessCRAllData.recentChat[BusinessChatIndex].unread_count =
                BusinessCRAllData.recentChat[BusinessChatIndex].unread_count +
                1);
          }

          let temp = BusinessCRAllData.recentChat[BusinessChatIndex];
          BusinessCRAllData.recentChat.splice(BusinessChatIndex, 1);
          BusinessCRAllData.recentChat.unshift(temp);

          if (
            ((action.payload.chat_type &&
              BusinessCRAllData.selectedChat.Type) === "PlaceQuestionChat" &&
              action.payload.question_id.toString() ===
              BusinessCRAllData.selectedChat.QuestionId.toString()) ||
            ((action.payload.chat_type &&
              BusinessCRAllData.selectedChat.Type) === "PlaceQuestionChat" &&
              action.payload.user_id === BusinessCRAllData.selectedChat.userid)
          ) {
            BusinessCRAllData.chatHistory.push(businessReceivedData);
            BusinessCRAllData.showPopUp = true;
          } else if (
            ((action.payload.chat_type &&
              BusinessCRAllData.selectedChat.Type) === "PlaceQuestionChat" &&
              action.payload.question_id !==
              BusinessCRAllData.selectedChat.QuestionId) ||
            ((action.payload.chat_type &&
              BusinessCRAllData.selectedChat.Type) === "PlaceQuestionChat" &&
              action.payload.user_id !== BusinessCRAllData.selectedChat.userid)
          ) {
          } else if (
            action.payload.googlePlacedId ===
            BusinessCRAllData.selectedChat.dialog_id
          ) {
            BusinessCRAllData.chatHistory.push(businessReceivedData);
            BusinessCRAllData.showPopUp = true;
          }
        } else {
          if (
            localStorage.getItem("UserId").toString() !==
            action.payload.user_id.toString()
          ) {
            BusinessCRAllData.recentChat[BusinessChatIndex] &&
              (BusinessCRAllData.recentChat[BusinessChatIndex].unread_count =
                BusinessCRAllData.recentChat[BusinessChatIndex].unread_count +
                1);
          }

          let temp = BusinessCRAllData.recentChat[BusinessChatIndex];
          BusinessCRAllData.recentChat.splice(BusinessChatIndex, 1);
          BusinessCRAllData.recentChat.unshift(temp);
        }
      } else {
        let businessReceivedData = {
          Delete_Messase: null,
          FullName: action.payload.firstname,
          First_Name: action.payload.firstname,
          UserName: action.payload.firstname.toLowerCase(),
          QuestionId:
            action.payload.question_id !== "0"
              ? action.payload.question_id
              : action.payload.chat_id,
          CommentId: action.payload.chat_id,
          RowNumber: BusinessCRAllData.chatHistory.length.toString(),
          Type: action.payload.chat_type,
          dialog_id: action.payload.googlePlacedId,
          GooglePlaceId: action.payload.googlePlacedId,
          from_user_id: action.payload.from_user_id,
          last_message_json: JSON.stringify(action.payload.chat_message),
          CommentDetail: JSON.stringify(action.payload.chat_message),
          photo: action.payload.photo,
          questionUnreadCount: action.payload.questionUnreadCount,
          userid: action.payload.user_id,
          UserId: action.payload.user_id,
          CreatedDate: action.payload.chat_time,
          message_type: action.payload.message_type,
        };

        if (BusinessCRAllData.recentChat) {
          BusinessCRAllData.recentChat.unshift(businessReceivedData);
        } else {
          BusinessCRAllData.recentChat = [businessReceivedData];
          BusinessCRAllData.totalRecentChat =
            BusinessCRAllData.totalRecentChat + 1;
          if (BusinessCRAllData.chatHistory) {
            BusinessCRAllData.chatHistory = [businessReceivedData];
          } else {
            BusinessCRAllData.chatHistory &&
              BusinessCRAllData.chatHistory.push(businessReceivedData);
          }
        }
      }

      BusinessCRAllData.scroll = false;
      let businessChatElement = document.getElementById("chatWrapper");
      if (businessChatElement) {
        if (
          businessChatElement.clientHeight +
          businessChatElement.scrollTop +
          5 >=
          businessChatElement.scrollHeight
        ) {
          BusinessCRAllData.scroll = true;
        }
      }
      return _.cloneDeep(BusinessCRAllData);
    case BUSINESS_DELETE_MESSAGE:
      let BusinessDeleteIndex = _.findIndex(
        state.chatHistory,
        (s) => s.CommentId === action.payload
      );
      state.chatHistory.splice(BusinessDeleteIndex, 1);
      let businessRecentChatIndex = _.findIndex(
        state.recentChat,
        (a) => a.dialog_id === state.selectedChat.dialog_id
      );
      state.recentChat[businessRecentChatIndex].last_message_json =
        state.chatHistory[state.chatHistory.length - 1].CommentDetail;
      return _.cloneDeep({ ...state });
    case DELETE_MESSAGE:
      if (state.chatHistory && state.chatHistory.length > 0) {
        let deleteIndex = _.findIndex(
          state.chatHistory,
          (s) => s.chat_id === action.payload
        );
        if (deleteIndex !== -1) {
          let tempRecord = state.chatHistory[deleteIndex];
          let RemoveMessageIndex = _.findIndex(
            state.recentChat,
            (rc) => rc.dialog_id === tempRecord.dialog_id && rc
          );
          if (RemoveMessageIndex !== -1) {
            state.chatHistory.splice(deleteIndex, 1);
            if (state.chatHistory.length === 0) {
              state.chatHistory = [];
              // state.recentChat.splice([RemoveMessageIndex],1);
              state.selectedChat = state.recentChat[0];
            } else {
              state.recentChat[RemoveMessageIndex].last_message_json =
                state.chatHistory[state.chatHistory.length - 1] &&
                state.chatHistory[state.chatHistory.length - 1].message_json;
            }
          }
        }
      }
      return _.cloneDeep({ ...state });
    case REMOVE_MESSAGE:
      if (state.chatHistory && state.chatHistory.length > 0) {
        let deleteChatIndex = _.findIndex(
          state.chatHistory,
          (s) => s.chat_id.toUpperCase() === action.payload.chatId.toUpperCase()
        );
        if (deleteChatIndex !== -1) {
          let tempRecord = state.chatHistory[deleteChatIndex];
          let RemoveMessageIndex = _.findIndex(
            state.recentChat,
            (rc) => rc.dialog_id === tempRecord.dialog_id && rc
          );
          if (RemoveMessageIndex !== -1) {
            state.chatHistory.splice(deleteChatIndex, 1);
            if (state.chatHistory.length === 0) {
              state.chatHistory = [];
              state.totalRecentChat--;
              state.recentChat.splice([RemoveMessageIndex], 1);
              if (
                state.selectedChat.from_user_id ==
                localStorage.getItem("UserId") &&
                localStorage.getItem("curbsideCommunicationUserId")
              ) {
                let date = new Date();
                ws.json({
                  type: "getdialog",
                  chat_type: "OneToOne",
                  user1: localStorage.getItem("UserId"),
                  user2: localStorage.getItem("curbsideCommunicationUserId"),
                  msgid: date.getTime().toString().slice(4),
                });
                ws.json({
                  to_user_id: localStorage.getItem(
                    "curbsideCommunicationUserId"
                  ),
                  type: "userstatus",
                });
              } else {
                state.selectedChat = state.recentChat[0];
              }
            } else {
              if (deleteChatIndex === state.chatHistory.length) {
                state.recentChat[RemoveMessageIndex].last_message_json =
                  state.chatHistory[state.chatHistory.length - 1] &&
                  state.chatHistory[state.chatHistory.length - 1].message_json;
              }
            }
          }
        }
      }
      state.scroll = false;
      return _.cloneDeep({ ...state });
    case DELETE_CHAT:
      let chatIndex = _.findIndex(
        state.recentChat,
        (chat) => chat.dialog_id === action.payload.dialog_id
      );
      state.recentChat?.splice(chatIndex, 1);
      if (
        !state.selectedChat ||
        state.selectedChat.dialog_id === action.payload.dialog_id
      ) {
        state.selectedChat = state.recentChat[0];
        state.chatHistory = [];
      }
      return _.cloneDeep({ ...state });
    case SELECTED_CHAT:
      state.chatHistory = state.backUpChat[action.payload.dialog_id];
      if (action.payload.Type == "PlaceQuestionChat") {
        state.messageIndex = 1;

        state.totalChat = 0;

        state.chatReceived = 0;
      } else {
        state.messageIndex = state?.backUpIndex[action?.payload?.dialog_id]
          ? state?.backUpIndex[action?.payload?.dialog_id]
          : state?.messageIndex;

        state.totalChat = state?.backUpChatSupporter[action?.payload?.dialog_id]
          ? state?.backUpChatSupporter[action.payload.dialog_id]?.totalChat
          : 0;

        state.chatReceived = state?.backUpChatSupporter[action?.payload?.dialog_id]
          ? state?.backUpChatSupporter[action?.payload?.dialog_id]?.chatReceived
          : 0;
      }

      state.selectedChat = action.payload;
      return _.cloneDeep({ ...state });
    case UNSELECT_CHAT:
      state.selectedChat = [];
      return _.cloneDeep({ ...state });
    case EMPTY_BACKUP_CHAT:
      state.backUpChat = {};
      state.backUpIndex = {};
      state.backUpChatSupporter = {};
      return _.clone({ ...state });
    case EMPTY_LIVE_CHAT:
      state.liveChat = {};
      return _.clone({ ...state });
    case ERROR:
      let error = {
        message: action.payload.message,
        error: action.payload.error,
      };
      if (!action.payload.message) {
        error = {};
      }
      return _.cloneDeep({ ...state, error: error, connection: false });
    case HIDE_POP_UP:
      return _.cloneDeep({ ...state, showPopUp: action.payload });
    case SET_SCROLL:
      return _.cloneDeep({ ...state, scroll: action.payload });
    case INITIAL_LOAD:
      state.initialCall[action.payload] = false;
      return _.cloneDeep({ ...state });
    case CLEAR_GIF:
      return { ...state, detailedGIF: { ...state.detailedGIF, results: [] } };
      break;
    case INCREASE_MESSAGE_INDEX:
      state.messageIndex = action.payload;
      return _.cloneDeep({ ...state });
    case CLEAR_ALL_DATA:
      if (state.selectedChat.dialog_id) {
        state.backUpChat[state.selectedChat.dialog_id] = state.chatHistory;
        state.backUpChatSupporter[state.selectedChat.dialog_id] = {
          chatReceived: state.chatReceived,
          totalChat: state.totalChat,
        };
        state.backUpIndex[state.selectedChat.dialog_id] = state.messageIndex
          ? state.messageIndex
          : 0;
      }
      state.chatHistory = undefined;
      state.scroll = true;
      return _.cloneDeep({ ...state });
    case LOGOUT:
      return initial;
    case EVENT_EXPERIENCE_EVENT_CHAT:
      const eventChatData = state.eventExperienceEventChat
        ? _.cloneDeep(state.eventExperienceEventChat)
        : [];
      const payloadMsgId = action.payload?.msgid;

      // Check if the payloadMsgId already exists in eventChatData
      const isDuplicate =
        eventChatData &&
        eventChatData.some((val) => val?.msgid === payloadMsgId);
      if (!isDuplicate && !action.payload?.isScheduled) {
        eventChatData.push(action.payload);
      }
      const eventChat = eventChatData.sort((a, b) => {
        const timeA = a.isScheduled ? new Date(a.scheduleDateTime) : new Date(a.chat_time);
        const timeB = b.isScheduled ? new Date(b.scheduleDateTime) : new Date(b.chat_time);

        return timeA - timeB;
      })

      return _.cloneDeep({
        ...state,
        eventExperienceEventChat: eventChat,
      });
    case EVENT_EXPERIENCE_EVENT_SCHEDULED_CHAT:

      const parsedScheduledChats = action.payload.chats


      if (parsedScheduledChats && parsedScheduledChats.length) {
        const eventScheduledChatData = parsedScheduledChats
          ? _.cloneDeep(parsedScheduledChats)
          : [];
        const eventScheduledChat = eventScheduledChatData.sort((a, b) => {
          const timeA = a.isScheduled ? new Date(a.scheduleDateTime) : new Date(a.chat_time);
          const timeB = b.isScheduled ? new Date(b.scheduleDateTime) : new Date(b.chat_time);

          return timeA - timeB;
        })
        return _.cloneDeep({
          ...state,
          scheduledMessages: eventScheduledChat
        })
      } else {
        return _.cloneDeep({
          ...state,
          scheduledMessages: []
        })
      }


    // case GET_ALL_EVENT_EXPERIENCE_ACTIVITIES:
    //   console.log("userList", action.payload.chats)

    //   if (action.payload.chats && action.payload.chats.length > 0) {
    //     const eventScheduledChat = action?.payload?.chats?.sort((a, b) => {
    //       return new Date(a.ActivityDateTime) - new Date(b.ActivityDateTime);
    //     })
    //     return _.cloneDeep({
    //       ...state,
    //       eventExperienceEventActivities: eventScheduledChat,
    //     })
    //   } else {
    //     return _.cloneDeep({
    //       ...state,
    //       eventExperienceEventActivities: [],
    //     })
    //   }
    case GET_ALL_EVENT_EXPERIENCE_CHAT:
      if (action.payload.chats && action.payload.chats.length > 0) {

        localStorage.setItem(
          "TotalCount",
          JSON.stringify(action.payload.totalCount)
        );

        function convertToValidJSON(jsonString) {
          // Replace escaped double quotes within the PollAnswers property value
          jsonString = jsonString.replace(/\\"/g, '"');

          // Replace escaped double quotes around the PollAnswers property value
          jsonString = jsonString.replace(/"({.*})"/g, "$1");
          return jsonString;
        }
        const timeZone = localStorage.getItem("CurrentTimeZone");
        let convertedChats = action?.payload?.chats
          .map((item) => {
            const chatObj = {};

            chatObj.msgid = null;
            try {
              chatObj.chat_message = convertToValidJSON(item.CommentDetail);
            } catch (error) {
              // invalid JSON
              console.log({ "JSON ERROR": error });
            }
            // chatObj.chat_message = JSON.parse(item.CommentDetail);
            chatObj.message_type = String(item.message_type);
            chatObj.chat_time = new Date(item.CreatedDate)
              .toISOString()
              .replace("T", " ")
              .replace("Z", "");
            chatObj.user_id = item.UserId;
            chatObj.type = "updateRecentChat";
            chatObj.chat_type = "Event";
            chatObj.token_id = null;
            chatObj.googlePlacedId = item.GooglePlacedId;
            chatObj.photo = item.Photo;
            chatObj.firstname = item.First_Name;
            chatObj.chat_id = item.CommentId;
            chatObj.isScheduled = item.isScheduled;
            chatObj.scheduleDateTime = item?.ScheduleDateTime
            chatObj.TotalVotes = item?.TotalVotes
            chatObj.messageLikesCount = item?.messageLikesCount;
            chatObj.messageLikes = item?.messageLikes;
            if (item?.isScheduled) {
              chatObj.ScheduleTime = item?.ScheduleDateTime;
              chatObj.ScheduleEndTime = item?.scheduleEndDateTime;
            }
            // .toISOString()
            // .replace("T", " ")
            // .replace("Z", "");
            return chatObj;
          })
          .sort((a, b) => {
            return new Date(a.chat_time) - new Date(b.chat_time);
          });


        const chatsIndex = localStorage.getItem("ChatsIndex");
        if (chatsIndex === 0) {
          return {
            ...state,
            eventExperienceEventChat: convertedChats,
          };
        }

        const allChats = [...convertedChats, ...state.eventExperienceEventChat];

        // let scheduleCount = allChats
        //   .filter((item) => item.isScheduled && moment(item?.scheduleDateTime)
        //   .utc(item?.scheduleDateTime).isSameOrAfter(moment()
        //   .utc()))
        //   .sort((a, b) => {
        //     const timeA = a.isScheduled ? new Date(a.ScheduleTime) : new Date(a.chat_time);
        //         const timeB = b.isScheduled ? new Date(b.ScheduleTime) : new Date(b.chat_time);

        //         return timeA - timeB;
        //   });
        //   localStorage.setItem(
        //     "TotalScheduledChatCount",
        //     JSON.stringify(scheduleCount.length)
        //   );

        const uniqueObjects = [];
        const uniqueValues = new Set();

        allChats.forEach((obj) => {
          const value = obj.chat_id.toLowerCase();
          if (!uniqueValues.has(value)) {
            uniqueValues.add(value);
            uniqueObjects.push(obj);
          }
        });

        let scheduleCount = uniqueObjects
          .filter((item) => item.isScheduled && moment(item?.scheduleDateTime)
            .utc(item?.scheduleDateTime).isSameOrAfter(moment()
              .utc()))
          .sort((a, b) => {
            const timeA = a.isScheduled ? new Date(a.ScheduleTime) : new Date(a.chat_time);
            const timeB = b.isScheduled ? new Date(b.ScheduleTime) : new Date(b.chat_time);

            return timeA - timeB;
          });
        localStorage.setItem(
          "TotalScheduledChatCount",
          JSON.stringify(scheduleCount.length)
        );

        return _.cloneDeep({
          ...state,
          eventExperienceEventChat: uniqueObjects,
        });
      } else {
        return _.cloneDeep({
          ...state,
          eventExperienceEventChat: [],
        });
      }
    case CLEAR_ALL_EVENT_EXPERIENCE_CHAT:
      return _.cloneDeep({
        ...state,
        eventExperienceEventChat: [],
      });

    case CLEAR_ALL_EVENT_EXPERIENCE_ACTIVITY:
      return _.cloneDeep({
        ...state,
        eventExperienceEventActivities: []
      })

    case SELECTED_MULTIMEDIA_DATA:
      return _.cloneDeep({
        ...state,
        selectedMultimediaData: action?.payload,
      });

        case ADD_MESSAGE_LIKE:
          if(action.payload.messageLike){
            return _.cloneDeep({
              ...state,
              messageLike: action?.payload.messageLike,
            });
          } else {
            return _.cloneDeep({
              ...state,
              messageLike: "",
            });
          };
    case GET_SCHEDULED_MESSAGES:
      if(action.payload.getScheduledMessages){

        const eventChatData = state.eventExperienceEventChat
        ? _.cloneDeep(state.eventExperienceEventChat)
        : [];

        function convertToValidJSON(jsonString) {
          // Replace escaped double quotes within the PollAnswers property value
          jsonString = jsonString.replace(/\\"/g, '"');

          // Replace escaped double quotes around the PollAnswers property value
          jsonString = jsonString.replace(/"({.*})"/g, "$1");

          return jsonString;
        }
        const chatObj = {};

        chatObj.msgid = null;
        try {
          chatObj.chat_message = convertToValidJSON(action.payload.getScheduledMessages?.CommentDetail);
        } catch (error) {
          // invalid JSON
          console.log({ "JSON ERROR": error });
        }
        // chatObj.chat_message = JSON.parse(item.CommentDetail);
        chatObj.message_type = String(action.payload.getScheduledMessages.message_type);
        chatObj.chat_time = new Date(action.payload.getScheduledMessages.CreatedDate)
          .toISOString()
          .replace("T", " ")
          .replace("Z", "");
        chatObj.user_id = action.payload.getScheduledMessages.UserId;
        // chatObj.type = "updateRecentChat";
        chatObj.chat_type = "Event";
        chatObj.token_id = null;
        chatObj.googlePlacedId = action.payload.getScheduledMessages.GooglePlacedId;
        chatObj.photo = action.payload.getScheduledMessages.Photo;
        chatObj.firstname = action.payload.getScheduledMessages.First_Name;
        chatObj.chat_id = action.payload.getScheduledMessages.CommentId;
        chatObj.isScheduled = action.payload.getScheduledMessages.isScheduled;
        chatObj.scheduleDateTime = action.payload.getScheduledMessages?.ScheduleDateTime
        chatObj.TotalVotes = action.payload.getScheduledMessages?.TotalVotes
        chatObj.messageLikesCount = action.payload.getScheduledMessages?.messageLikesCount;
        chatObj.messageLikes = action.payload.getScheduledMessages?.messageLikes;
        if (action.payload.getScheduledMessages?.isScheduled) {
          chatObj.ScheduleTime = action.payload.getScheduledMessages?.ScheduleDateTime;
          chatObj.ScheduleEndTime = action.payload.getScheduledMessages?.scheduleEndDateTime;
        }

        eventChatData.push(chatObj);

        return _.cloneDeep({
          ...state,
          eventExperienceEventChat: eventChatData,
        });
      } else {
        return _.cloneDeep({
          ...state,
          eventExperienceEventChat: eventChatData,
        });
      };
      case ACK_ADD_MESSAGE_LIKE:
      if(action.payload.ackMessageLike){

        const eventChatData = state.eventExperienceEventChat
        ? _.cloneDeep(state.eventExperienceEventChat)
        : [];
        const lowerCaseId = action.payload.ackMessageLike?.chat_id.toUpperCase();
        const likeMessage = eventChatData.filter((msg)=> msg?.chat_id == lowerCaseId);
        const updatedEventChatData = eventChatData.map((msg) => {
          if (msg?.chat_id === lowerCaseId) {
            if(action.payload.ackMessageLike?.user_id == localStorage.getItem("UserId")){
              return {
                ...msg,
                messageLikesCount: action.payload.ackMessageLike?.messageLikesCount,
                messageLikes: action.payload.ackMessageLike?.messageLikes,
              };
            }else{
              return {
                ...msg,
                messageLikesCount: action.payload.ackMessageLike?.messageLikesCount,
                // messageLikes: action.payload.ackMessageLike?.messageLikes,
              };
            }
          }
          return msg;
        });

        // eventChatData.push(action.payload.getScheduledMessages);
        return _.cloneDeep({
          ...state,
          eventExperienceEventChat: updatedEventChatData,
        });
      } else {
        return _.cloneDeep({
          ...state,
          eventExperienceEventChat: eventChatData,
        });
      };
        case ACK_DELETE_MESSAGE_LIKE:
          if(action.payload.ackDeleteMessageLike){

            const eventChatData = state.eventExperienceEventChat
            ? _.cloneDeep(state.eventExperienceEventChat)
            : [];
            const lowerCaseId = action.payload.ackDeleteMessageLike?.chat_id.toUpperCase();

            const likeMessage = eventChatData.filter((msg)=> msg?.chat_id == lowerCaseId);
            const updatedEventChatData = eventChatData.map((msg) => {
              if (msg?.chat_id === lowerCaseId) {
                if(action.payload.ackDeleteMessageLike?.user_id == localStorage.getItem("UserId")){
                  return {
                    ...msg,
                    messageLikesCount: action.payload.ackDeleteMessageLike?.messageLikesCount,
                    messageLikes: action.payload.ackDeleteMessageLike?.messageLikes,
                  };
                }else{
                  return {
                    ...msg,
                    messageLikesCount: action.payload.ackDeleteMessageLike?.messageLikesCount,
                    // messageLikes: action.payload.ackMessageLike?.messageLikes,
                  };
                }
              }
              return msg;
            });

            // eventChatData.push(action.payload.getScheduledMessages);
            return _.cloneDeep({
              ...state,
              eventExperienceEventChat: updatedEventChatData,
            });
          } else {
            return _.cloneDeep({
              ...state,
              eventExperienceEventChat: eventChatData,
            });
          };
          case POLL_ACK:
            if(action.payload.pollAck){
              const eventChatData = state.eventExperienceEventChat
              ? _.cloneDeep(state.eventExperienceEventChat)
              : [];
              const questionId = action.payload.pollAck?.question_id;

              const updatedEventChatData = eventChatData.map((msg) => {
                const messageData = JSON.parse(msg?.chat_message);
                if (messageData?.questionId === questionId) {
                  messageData.pollAnswers = action.payload.pollAck?.results;
                  messageData.totalVotes = action.payload.pollAck?.totalVotes;
                  return {
                    ...msg,
                    chat_message: JSON.stringify(messageData)
                  };
                }
                return msg;
              });

              return _.cloneDeep({
                ...state,
                eventExperienceEventChat: updatedEventChatData,
              });
            } else {
              return _.cloneDeep({
                ...state,
                eventExperienceEventChat: eventChatData,
              });
            };
    default:
      return state;
  }
};
