import React from "react";
import GooglePlacesV1 from "./GooglePlacesV1";

const AutoCompleteV1 = ({
  styles,
  selected,
  setLatlLong,
  setSelected,
  isFirstRender,
}) => {
  return (
    <>
      <GooglePlacesV1
        {...{ styles, selected, setLatlLong, setSelected, isFirstRender }}
      />
    </>
  );
};

export default AutoCompleteV1;
