import React, { useEffect, useState } from "react";
import { AiFillInfoCircle } from "react-icons/ai";
import ActiveAttendee from "./ActiveAttendee";
import ToolTip from "../../Analytics/Component/tools/ToolTip";
import { ws } from "App";
import store from "store";
import img from "../Component/RefreshIcon.png";
import TablePagination from "@mui/material/TablePagination";
import storeFolder from "../../../../../store";
import { IoReloadOutline } from "react-icons/io5";
import "../Component/ChattingNowLoader.css"
import { setUserCheckedInList, clearEventUserCheckedInList ,isCheckedInUserListLoading } from "Actions/messagesAction";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";

function ChattingNow(props) {
  const state = store.getState();
  const { eventId } = state.eventUserEvents;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  let storeData = storeFolder.getState();
  const [checkedInUsersList, setCheckedInUsersList] = useState([]);
  const [update, setupdate] = useState(false);
  const [refresh, setrefresh] = useState(false);
  const currentEventId = JSON.parse(
    localStorage.getItem("CurrentEventId")
  );
  useEffect(() => {

    // refresh && setCheckedInUsersList([]);
    setCheckedInUsersList(props.checkedInUsersListData);
    // setTimeout(() => {
    //   setrefresh(false);
    // }, 700);
  
  }, [update, props.checkedInUsersListData , props.isUserCheckedInLoading]);

  useEffect(() => {
    fetchDataFromWebSocket();
    if (page == 0) {
      const intervalId = setInterval(() => {
        fetchDataFromWebSocket();
        setrefresh(true);
      }, 300000);
      return () => clearInterval(intervalId);
    };
  }, [page]);

  const fetchDataFromWebSocket = () => {
    try {
        setTimeout(() => {
          if(storeData.messageReducer?.connection){
            const response = ws?.json({
              eventId: currentEventId ? currentEventId : eventId,
              pageIndex: page + 1,
              pageSize: "25",
              type: "getEventCheckedInUsersList",
            });
          }
        }, 1000);

      setupdate(prevUpdate => !prevUpdate);
    } catch (error) {
      console.error("Error fetching data from WebSocket:", error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    props.clearEventUserCheckedInList();
    props.isCheckedInUserListLoading();
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    return () => {
      props.clearEventUserCheckedInList();
      props.isCheckedInUserListLoading();
      setCheckedInUsersList([])
    }
  }, [])

  return (
    <div className="chattingNow">
      <div className="event_activity_base" />
      <div className="chattingNowHeader">
        <div className="activityHeader chattingHeader">
          <div style={{ width: "max-content" }}>
            {ToolTip(
              "Chatting Now:",
              "View attendees who have checked-in and entered the Live Chat on the UBMe App!"
            )}
          </div>
      { !props.isUserCheckedInLoading &&
        <span
        className="refresh-btn"
        style={{ marginRight: "20px", marginTop: "-2px", cursor: "pointer", marginLeft: "5px" }}
      >
        <img
          src={img}
          alt="refresh icon"
          style={{ height: "20px", marginBottom: "5px" }}
          onClick={() => {
            setPage(0)
            props.clearEventUserCheckedInList();
            props.isCheckedInUserListLoading();
            if(page === 0){
              fetchDataFromWebSocket()
            }
          }}
        />
      </span>
      }
        </div>
        <p style={{ marginBottom: "10px", maxWidth: "500px" }}>
          (Below is a list of attendees engaging and participating at your event! Click 'Refresh' for the most up-to-date list of engaged attendees.)
        </p>
      </div>
      <div className="activeUser" >
        {
        props.isUserCheckedInLoading ? (
          <div
            className="Loadercen"
          >
            <div class="spinnerr"></div>
          </div>
        ) : 
        (
          <ActiveAttendee
            checkedInUsersList={checkedInUsersList}
          />
        )}
      </div>
      <div style={{ fontSize: "30px" }}>
        {storeData.eventReducer.totalCheckedInUsers > 0 && (
          <div className="custom-pagination2">
            <TablePagination
              rowsPerPageOptions={[]}
              component="div"
              count={
                props.totalCheckedInUserCount > 0 ? props.totalCheckedInUserCount : 0
              }
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              showFirstButton
              showLastButton
            />
          </div>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  isUserCheckedInLoading:state.eventReducer.isCheckedInUsersLoading,
  checkedInUsersListData:state.eventReducer.checkedInUsersList,
  totalCheckedInUserCount:state.eventReducer.totalCheckedInUsers
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      clearEventUserCheckedInList,
      isCheckedInUserListLoading
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ChattingNow);