import React from "react";
import {
  pdf,
  Page,
  Image,
  StyleSheet,
  Text,
  Document,
  View,
  Link,
} from "@react-pdf/renderer";
import PDFDocument from "../../Document";
import { useCurrentPng } from "recharts-to-png";
import { URLs, Text as Texts } from "Constants";
import { AiFillInfoCircle } from "react-icons/ai";
import GraphData from "../../Document/GraphData";
import PDFImg from "images/pdf.png";
import Vendor from "images/vendor.png";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { BsLink, BsFillTelephoneFill } from "react-icons/bs";
import { IoLocationSharp } from "react-icons/io5";
import { BiPhone } from "react-icons/bi";
import { FaEnvelopeOpenText } from "react-icons/fa";
import {  CircularProgress } from "@material-ui/core";
import Backdrop from '@mui/material/Backdrop';

const VendorView = ({
  checkisArray,
  activityData,
  Description,
  Email,
  Logo,
  Name,
  Phone,
  Website,
  Interaction,
  eventTitle,
  Address
}) => {
  const [startDownload, setStartDownload] = useState("");
  const [downloading, setDownloading] = useState(false)
  const [image1, setImage1] = useState("");
  const [image2, setImage2] = useState("");
  const [image3, setImage3] = useState("");
  const [image4, setImage4] = useState("");
   const [url, setUrl] = useState("")
  const isFunctionCalledRef = useRef(false);

  useEffect(() => {
    
    if (
      image1 !== "" &&
      image2 !== "" &&
      image3 !== "" &&
      image4 !== "" &&
      !isFunctionCalledRef.current
    ) {
      
      myFunction();
      isFunctionCalledRef.current = true;
    }
  }, [image1, image2, image3, image4]);

  // Getting Vendors data by callback function
  const ImpressionsData = checkisArray(
    activityData?.LandingPageStatistics?.Click,
    "impressions"
  );

  const ClicksData = checkisArray(Interaction, "URL");
  const PhoneData = checkisArray(Interaction, "Mobile");
  const EmailData = checkisArray(Interaction, "Email");

  //  Getting chart numbers from vendors data to pass in PDF.

  const ImpressionCount = ImpressionsData?.reduce((a, b) => a + Number(b.value),0);

  const ClicksCount = ClicksData?.reduce((a, b) => a + Number(b.value), 0);

  const PhoneCount = PhoneData?.reduce((a, b) => a + Number(b.value), 0);

  const EmailCount = EmailData?.reduce((a, b) => a + Number(b.value), 0);

  const myFunction = async () => {
    const pdfBlob = await pdf(
      <PDFDocument
        {...{
          EmailCount,
          PhoneCount,
          ClicksCount,
          ImpressionCount,
          Description,
          Email,
          Logo,
          Name,
          Address,
          Phone,
          website:Website,
          image1,
          image2,
          image3,
          image4,
          eventTitle,
          Pdfname: "Vendor",
        }}
      />
    ).toBlob();
    // const timestamp = new Date().getTime();
    if (url) {
      URL.revokeObjectURL(url); // Revoke the previous URL
    }
    const pdfDataUrl = URL.createObjectURL(pdfBlob);
    setUrl(pdfDataUrl); // Save the new URL to state
    const link = document.createElement("a");
    link.href = pdfDataUrl;
    link.download = "my-pdf-file.pdf";
    document.body.appendChild(link);
    setDownloading(false)
    setImage1("")
    setImage2("")
    setImage3("")
    setImage4("")
    link.click();
    // Perform any actions that need to be done after all states have been updated
  };



  return (
    <>
     <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={downloading}
        onClick={()=> {}}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    <div className="vendorSection">
         
      <div className="vendorLeft">
        <img
          src={Logo ? `${URLs.vendorImgUrl}/VendorLogo/${Logo}` : Vendor}
          alt=""
        />

        <div className="vendorLeftDetail">
          <h3 style={{ fontSize: 16, fontWeight: 600 }}>{Name}</h3>
          <p
            style={{
              fontSize: 16,
              fontWeight: 500,
              color: "#808080",
              marginTop: 5,
            }}
          >
            {Description}
          </p>
          {
            Website && 
            <div className="AboutVendor">
            <div style={{width:40}}>
            <BsLink size={30} className="vendorIcon" />
            </div>
            <a style={{ color: "#0066FF" }} href={Website}>
              {Website}
            </a>
          </div>
          }
          
          {
            Address && <div className="AboutVendor">
            <div style={{width:30}}>
              <IoLocationSharp className="vendorIcon" />
              </div>
              <span  style={{ color: "#0066FF", fontWeight:600, fontSize:14, margin:0 }} >
                {Address}
              </span>
            </div>
          }
 


          {
            Phone && <div className="AboutVendor">
            <div style={{width:30}}>
              <BiPhone className="vendorIcon" />
              </div>
              <a style={{ color: "#0066FF" }} href={`tel:${Phone}`}>
                {Phone}
              </a>
            </div>
          }

          {
            Email &&
            <div className="AboutVendor">
            <div style={{width:30}}>
              <FaEnvelopeOpenText className="vendorIcon" />
              </div>
              <a style={{ color: "#0066FF" }} href={`mailto:${Email}`}>
                {Texts.sendMail}
              </a>
            </div>
          }
          
        
        </div>
      </div>
      <div className="vendorRight">
        <div className="vendorRight_Left">
          <GraphData
            setImage={setImage1}
            data={ImpressionsData}
            startDownload={startDownload}
            title="Impressions"
          />
          <GraphData
            setImage={setImage2}
            data={ClicksData}
            startDownload={startDownload}
            title="Clicks"
            number="47"
          />
        </div>
        <div className="vendorRight_Right">
          <GraphData
            setImage={setImage3}
            data={PhoneData}
            startDownload={startDownload}
            title="Phone Calls"
            number="2"
          />

          <GraphData
            setImage={setImage4}
            data={EmailData}
            startDownload={startDownload}
            title="Email Inquiries"
            number="47"
          />
        </div>
      </div>
      <div
        style={{
          width: 100,
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          marginBottom:30
        }}
        onClick={() => {setDownloading(true); 
          // setStartDownload("start");
          setTimeout(() => {
          setStartDownload("start");
          }, 800); 
      }
      }
      >
        <p>{Texts.PrintReport}</p>
        <img
          src={PDFImg}
          style={{
            width: 50,
            filter: "drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.2))",
          }}
        />
      </div>
    </div>
    </>
  );
};

export default VendorView;

