import React, { useEffect } from "react";
import Grid from "styles/Grid.jsx";
import styles from "./Vendors.module.css";
import { AiOutlineDown, AiOutlineRight } from "react-icons/ai";
import { BsLink, BsFillTelephoneFill } from "react-icons/bs";
import { IoLocationSharp } from "react-icons/io5";
import { BiPhone } from "react-icons/bi";
import { FaEnvelopeOpenText } from "react-icons/fa";
import Vendor from "images/vendor.png";
import { axiosE } from "Service/service";
import { Text } from "Constants";

const Vendors = ({ currentEventDetails }) => {
  let newVendorData = [];
  const mydata = currentEventDetails?.VendorData.map((vendor) => {
    let obj = {
      VendorId: "",
      Type: "",
      data: [],
    };

    if (!newVendorData.some((d) => d.Type === vendor.Type)) {
      obj.Type = vendor.Type;
      obj.VendorId = vendor.VendorId;
      obj.data.push(vendor);
      newVendorData.push(obj);
    } else {
      let data = newVendorData.find((d) => d.Type === vendor.Type);
      data.data.push(vendor);
    }
  });

  newVendorData.sort((a, b) => a.Type.localeCompare(b.Type));


  const InsertEngagementClickthrough = (Interaction, typeId) => {
    try {
      const {data} = axiosE.post("/Event/InsertEngagementClickthrough",
      {
        EventId: currentEventDetails.EventId,
        ClickthroughType: "Vendor",
        Interaction: Interaction,
        Platform: "Web",
        TypeId:typeId
    }
      )

    } catch (error) {

    }
  }

  return (
    <>
      <div className={styles.container__Vendors}>
        <div className={styles.vendors__Heading}>
          <h2>
            <span className={styles.event_footer_app_vendors}>{Text.Vendors}</span> At{" "}
            {currentEventDetails?.Title.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}
          </h2>
        </div>

        {newVendorData.map((d) => (
          <>
            <div className={styles.find__Vendors__Button} >
              <button style={{ backgroundColor: (currentEventDetails !== null && currentEventDetails.EventColor !== null && currentEventDetails.EventColor !== undefined && currentEventDetails.EventColor !== "") ? currentEventDetails.EventColor : "#b610bf"}}>{d.Type}</button>
            </div>
            <Grid row={true}>
              {currentEventDetails &&
                d.data.map((item, index) => (
                  <Grid column={true} sm={12} md={4}>
                    <div className={styles.vendors__Card} key={item.VendorId}>
                      <Grid column={true} md={3} sm={5}>
                        {/* <span> */}
                        {item.Logo !== "" ? (
                          <img className={styles.vendors_card_img} src={item.Logo} />
                        ) : (
                          <img className={styles.vendors_card_logo} src={Vendor} />
                        )}
                        {/* </span> */}
                      </Grid>
                      <Grid column={true} md={9} sm={7}>
                        <div className={styles.vendors__Shop__details}>
                          <h3 style={{ fontWeight: 600 }}>{item.Name}</h3>
                          <p>
                            <div
                              className={styles.vendor_shop_des}
                              dangerouslySetInnerHTML={{
                                __html: item.Description,
                              }}
                            />
                          </p>
                          <ul className={styles.vendors_other_details}>
                            {item.Website && (
                              <li style={{ wordBreak: "break-all" }}>
                                <span>
                                  <BsLink />
                                </span>

                                <a
                                  onClick={() => InsertEngagementClickthrough("URL", item.VendorId)}
                                  style={{ padding: 0 }}
                                  href={

                                    item?.Website.substring(0, 8) === "https://" ||
                                     item?.Website.substring(0, 7) === "http://" ?
                                     `${item?.Website}`:
                                     `https://${item.Website}`





                        //  item?.Website.substring(0, 8) !== "https://" ?
                        //   item?.Website.substring(0, 7) !== "http://" :
                        // `https://${item.Website}`
                        //               ?
                        //               : `${item?.Website}`
                                  }
                                  target="_blank"
                                >
                                 {Text.VisitWebsite}
                                </a>
                              </li>
                            )}
                            {item.Address && (
                              <li>
                                <span>
                                  <IoLocationSharp />
                                </span>
                                <a  href={`http://maps.google.com/?q=${item.Address}`} target="_blank">{item.Address}</a>
                              </li>
                            )}

                            {item.Phone && (
                              <li>
                                <span>
                                  <BiPhone />
                                </span>

                                <a onClick={() => InsertEngagementClickthrough("Mobile", item.VendorId)} href={"tel:" + item.Phone}>{item.Phone}</a>
                              </li>
                            )}

                            {item.Email && (
                              <li>
                                <span>
                                  <FaEnvelopeOpenText size={16} />
                                </span>
                                <a
                                  onClick={() => InsertEngagementClickthrough("Email", item.VendorId)}
                                  href={"mailto:" + item.Email}
                                  style={{ marginTop: "3px" }}
                                >
                                 {Text.SendMail}
                                </a>
                              </li>
                            )}
                          </ul>
                        </div>
                      </Grid>
                    </div>
                  </Grid>
                ))}
            </Grid>
          </>
        ))}
      </div>
    </>
  );
};

export default Vendors;
