import React, { useEffect, CSSProperties } from "react";
import EventCardWrapper from "../Components_UBMeEvents/EventCardWrapper/EventCardWrapper";
import "./Favorite.css";
import { AddToFavorite, SetCardData } from "Actions/favoriteAction";
import { connect } from "react-redux";
import FavoriteImage from "images/Favorites.jpg";
import * as constants from "Constants";
import { axiosE } from "Service/service";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useState } from "react";
import FavPagiNation from "./FavPagination/FavPagiNation";
import moment from "moment";
import "moment-timezone";
import Navbar from "../Components_UBMeEvents/Navbar/Navbar";
import Footer from "../Components_UBMeEvents/Footer/Footer";
import { routeName } from "Service/env";
import { Redirect } from "react-router-dom";

const Favorite = ({
  dispatch,
  favoriteData,
  cardData,
  isLoggedIn,
  results,
  AddToFavorite,
}) => {
  const [loading, setLoading] = useState(true);
  const [totalCounts, setTotalCounts] = useState("");
  const [pageNO, setPageNo] = useState(1);
  const [page, setPage] = useState(0);
  const [resultsPageNo, setResultsPageNo] = useState(1);
  const handleFavData = (data) => {
    scrollToTop();

    dispatch(AddToFavorite(data));
  };

  const scrollToTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const favCardData = async (pageNO) => {
    try {
      setLoading(true);
      const { data } = await axiosE.post(
        `${constants.API.GetAllEventsByUserLiked}`,
        {
          UserId: isLoggedIn ? results.ProfileDetail.UserId : 0,

          PageNumber: pageNO,
          RowsOfPage: 20,
        }
      );
      setTotalCounts(data.results.TotalCounts);
      isInt(data.results.TotalCounts);

      const newTimeZoneData = data.results.EventList.map((item) => {
        const UtcToLocal = moment
          .utc(item.StartDateTime)
          .local()
          .format("MM/DD/YYYY HH:mm:ss A");
        var Time = moment(UtcToLocal, "MM/DD/YYYY HH:mm:ss A");
        const convertedTime = Time.tz(item.TimeZone);
        const obj = Object.assign({}, item);
        obj["StartDateTime"] = convertedTime;
        return obj;
      });

      let newArr = [...newTimeZoneData];
      const sortedArray = newArr.sort(
        (a, b) =>
          new moment(a.StartDateTime).format("X") -
          new moment(b.StartDateTime).format("X")
      );

      setTimeout(() => {
        setLoading(false);
      }, 1000);

      setResultsPageNo(pageNO);
      handleFavData(sortedArray);
    } catch (error) {}
  };

  useEffect(() => {
  
      favCardData(pageNO);
 
  }, [pageNO]);

  function isInt(n) {
    let num = 0;
    if (n % 20 !== 0) {
      num = Math.floor(n / 20) + 1;
    } else {
      num = n / 20;
    }
    setPage(num);
  }

  useEffect(() => {
    if (pageNO > 1 && favoriteData.length === 0) {
      setPageNo(pageNO - 1);
    }
    if (favoriteData.length < 20 && resultsPageNo < page) {
      favCardData(pageNO);
    }
  }, [favoriteData]);

  return (
    <>
      {localStorage.getItem("UserId") === null ? (
        <Redirect to={`${routeName}`} />
      ) : null}
      <Navbar />
      <div className="">
        <img
          style={{ width: "100%", height: "100%" }}
          src={FavoriteImage}
          alt=""
        />
      </div>
      <div className="cards_container">
        <>
          <EventCardWrapper
            results={results}
            LoginPopup={() => {}}
            isLoggedIn={isLoggedIn}
            data={favoriteData}
            favoriteData={favoriteData}
            AddFavorite={(item) => {
              dispatch(AddToFavorite(item));
            }}
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {loading ? (
              <CircularProgress size={50} color={"#ca3dd9"} />
            ) : (
              !favoriteData.length > 0 && (
                <div
                  style={{
                    margin: "auto",
                    padding: "100px 0px",
                  }}
                >
                  <div>
                    <h1
                      style={{ textAlign: "center", color: "gray", margin: 0 }}
                    >
                      {constants.Text.nofavoriteevents}
                    </h1>
                  </div>
                </div>
              )
            )}
          </div>
          {favoriteData.length > 0 && (
            <div>
              <div style={{ marginTop: 60 }}>
                <FavPagiNation
                  pageNO={pageNO}
                  resultsPageNo={resultsPageNo}
                  setPageNo={setPageNo}
                  page={page}
                  handleFavData={handleFavData}
                  length={favoriteData.length}
                />
              </div>
            </div>
          )}
        </>
      </div>
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    favoriteData: state.favoriteReducer.favoriteData,
    cardData: state.favoriteReducer.cardData,
    isLoggedIn: state.loginResponse.isLoggedIn,
    results: state.loginResponse.results,
  };
};
const matchDispatchToProps = (dispatch) => {
  return {
    AddToFavorite,
    SetCardData: () => dispatch(SetCardData),
    dispatch,
  };
};
export default connect(mapStateToProps, matchDispatchToProps)(Favorite);
