import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { EventDashboardLineGraphData } from '../../../Actions/chartAction';
import 'bootstrap-daterangepicker/daterangepicker.css';

const styles = {
  headerDate: {
    fontSize: '14px',
    marginTop: '5px',
    textAlign: 'center',
    width: '200px'
  }
}

class DateCalender extends Component  {

  constructor(){
    super();
    this.state = {
      startDate: moment().format('MM/DD/YYYY'),
      endDate: '',
      isAllTime: false
    }
  }

  componentDidMount() {
    let { user , onStateChange} = this.props;
    let time = moment().format('LT');

    let startDate = `${moment().subtract(6,'days').format('MM/DD/YYYY')} 12:00:00 AM`;
    let endDate = moment().format('MM/DD/YYYY');
    endDate = `${endDate} ${time}`;
    // this.props.EventDashboardLineGraphData(
    //     user.results.UserId,
    //     moment(startDate).utc().format('MM-DD-YYYY'),
    //     moment(endDate).utc().format('MM-DD-YYYY'),
    //     false);
    onStateChange({
      startDate:moment().subtract(6,'days').format('MM/DD/YYYY'),
      endDate:endDate
    })
    // this.setState({
    //   startDate:moment().subtract(6,'days').format('MM/DD/YYYY'),
    //   endDate:moment().format('MM/DD/YYYY')
    // })

    this.displayDate();
  }

  handleEvent = (ev, picker) => {
    const { user ,onStateChange } = this.props;
    let time = moment().format('LT');
    let startDate = `${picker.startDate.format('MM/DD/YYYY')} 12:00:00 AM`;
    let endDate = picker.endDate.format('MM/DD/YYYY');
    let choosenLabel = picker.chosenLabel;
    if(choosenLabel === "Yesterday" || choosenLabel === "Last Month") {
      endDate =`${endDate} 11:59:00 PM`;
    } else if(choosenLabel === "Custom Range") {
      if(endDate >= moment().format('MM/DD/YYYY')) {
        endDate =`${endDate} ${time}`;
      } else {
        endDate = `${endDate} 11:59:00 PM`;
      }
    } else {
      endDate = `${endDate} ${time}`;
    }
    if(picker.chosenLabel === "All time") {
      onStateChange({
        isAllTime: true
      }, () => {

      })
      // this.setState({
      //   isAllTime: true
      // }, () => {

      // });
    } else {
      onStateChange({
        isAllTime : false
      },()=>{

      })
      // this.setState();
    }
    if(moment(startDate).format('MM/DD/YYYY') !== moment(endDate).format('MM/DD/YYYY')) {
      // this.setState();
      onStateChange({
        startDate,
        endDate
      })
    } else {
      onStateChange({
        startDate,
        endDate
      })
    }
    ev.preventDefault();
    ev.stopPropagation();
  }

  displayDate = () => {
    let { endDate, startDate, isAllTime } = this.props.state;

    if(isAllTime) {
      return "All Time";
    } else {
      if (endDate === "") {
        return moment(startDate).format('MMM Do, YYYY').toString();
      } else if (moment(startDate).format('MMM Do, YYYY').split(' ')[0] === moment(endDate).format('MMM Do, YYYY').split(' ')[0]) {
        return `${moment(startDate).format('MMM Do, YYYY')} - ${(moment(endDate).format('MMM Do, YYYY')).substring(4, 14)}`;
      } else if (moment(startDate).format('MMM Do, YYYY').split(' ')[0] !== moment(endDate).format('MMM Do, YYYY').split(' ')[0]) {
        return `${moment(startDate).format('MMM Do, YYYY')} - ${(moment(endDate).format('MMM Do, YYYY').substring(0, 14))}`;
      }
    }
  }

  render() {
    const { classes ,user } = this.props;
    const userRegisterTime = user?.results?.ProfileDetail?.CreationDateTime

    return (
            <DateRangePicker
                startDate={moment(this.props.state.startDate)}
                endDate={
                  this.props.state.endDate === "" ?
                      moment(this.props.state.startDate) :
                      moment(this.props.state.endDate)}
              ranges = {{
                'Today': [
                    moment().startOf('day'),
                  moment()
                ],
                'Yesterday': [
                    moment().subtract(1, 'days').startOf('day'),
                  moment().subtract(1, 'days').endOf('day')
                ],
                'Last 7 Days': [
                    moment().subtract(6, 'days'),
                  moment()
                ],
                'Last 30 Days': [
                    moment().subtract(29, 'days'),
                  moment()
                ],
                'This Month': [
                    moment().startOf('month'),
                  moment()
                ],
                'Last Month': [
                    moment().subtract(1, 'month').startOf('month'),
                  moment().subtract(1, 'month').endOf('month')
                ],
                'All time':[
                  userRegisterTime ? moment(userRegisterTime) : moment("01/01/2015"),
                  moment()
                ]
              }}
              showCustomRangeLabel={true}
              alwaysShowCalendars={true}
              opens={'center'}
              drops={'down'}
              onApply={this.handleEvent}
              applyClass="apply-btn"
              linkedCalendars={false}
            >
              <TextField
                  InputProps={{className:classes.headerDate}}
                  value={this.displayDate()}/>
            </DateRangePicker>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    chartDetail: state.chartDetail,
    user: state.loginResponse
  }
};

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators({ EventDashboardLineGraphData }, dispatch);
};

export default withStyles(styles)(connect(mapStateToProps, matchDispatchToProps)(DateCalender));