import React from "react";
import pricingImg from "images/Pricing.jpg";
import Navbar from "../Components_UBMeEvents/Navbar/Navbar";
import Footer from "../Components_UBMeEvents/Footer/Footer";
import "./Pricing.css";
import Fade from 'react-reveal/Fade';
import { GetUserProfileDetail, LoginPopup, logoutAction } from "Actions";
import { history } from "store";
import { clearEventDetails, removeEventByEventUser } from "Actions/eventActions";
import Features from "./Features";
import img1 from "images/PricingIcons/Sell-a-variety-of-ticket-types.png";
import img2 from "images/PricingIcons/Option-for-ticket-will-call.png";
import img3 from "images/PricingIcons/Create-live-engagements-during-the-event-or-schedule-them-a-ahead-of-time.png";
import img4 from "images/PricingIcons/Pre-set-organizer-to-attendee-communication.png";

import img5 from "images/PricingIcons/Attendee-check-in.png";
import img6 from "images/PricingIcons/Manual-confirmation-OR-scanner-confirmation-via-Organizer-App.png";

import img7 from "images/PricingIcons/send-email-invitations.png";
import img8 from "images/PricingIcons/Downloadable-flyers-for-digital-or-print.png";

import img9 from "images/PricingIcons/Dynamic-landing-page.png";
import img10 from "images/PricingIcons/Easy-ticket-purchasing-online-and-in-app.png";

import img11 from "images/PricingIcons/Polls-and-Surveys.png";
import img12 from "images/PricingIcons/Advanced-insights-and-analytics-post-event.png";

import img13 from "images/PricingIcons/Live-chat-with-fellow-attendees.png";
import img14 from "images/PricingIcons/Setting-of-event-agenda-(for-one-day-or-multi-day-events).png";

import img15 from "images/PricingIcons/Promote-your-vendors-and-sponsors-on-your-event-landing-page.png";
import img16 from "images/PricingIcons/Capacity-control.png";

import img17 from "images/PricingIcons/Ability-to-add-speakers-and-provide-their-info.png";
import img18 from "images/PricingIcons/Ability-to-create-ads-within-your-event-that-appear-on-the-UBMe-app-all-throughout-your-event.png";


import Faq from "../FrequentlyAskedQuestions/Faq.js/Faq";
import { connect } from "react-redux";
import { homeRoute, routeName } from "Service/env";
import {Helmet} from "react-helmet";

const Pricing = ({LoginPopup, dispatch, isLoggedIn, clearEventDetails, removeEventByEventUser}) => {
  const features = [
    {
      img1: img1,
      img2: img2,
      text: "Sell a variety of ticket types",
      text2: "Option for ticket will-call",
    },
    {
      img1: img3,
      img2: img4,
      text: "Create real-time digital interactions between attendees",
      text2: "Direct access to attendees for individual + group notifications",
    },
    {
      img1: img5,
      img2: img6,
      text: "Manual attendee check-in",
      text2: "Ticket scanner via Organizer App",
    },
  ];
  const Marketing  = [
    {
      img1: img7,
      img2: img8,
      text: "Ability to send email invitations",
      text2: "Downloadable flyers for digital or print",
    },
    {
      img1: img9,
      img2: img10,
      text: "Dynamic landing page",
      text2: "Easy ticket purchasing online and in-app",
    },
    {
      img1: img11,
      img2: img12,
      text: "Polls + Surveys for attendee input",
      text2: "Advanced insights and analytics post-event",
    },
  ];

  const Experience  = [
    {
      img1: img13,
      img2: img14,
      text: "Event live-chat between attendees",
      text2: "Capacity control",
    },
    {
      img1: img15,
      img2: img16,
      text: "Create multi-day event agendas",
      text2: "Ability to add speakers/performers and provide their info",
    },
    {
      img1: img17,
      img2: img18,
      text: "Vendor and Sponsor placement on event landing page + UBMe App",
      text2: "Ability to create ads within your event that appear on the UBMe app all throughout your event",
    },
  ];

  const LoginPopupOpen = (val) => {
    dispatch(LoginPopup(val));
  };



  const cleareEventsAndCreateNewone = () => {
    dispatch(clearEventDetails());
    dispatch(removeEventByEventUser())
    localStorage.setItem("isItCreateOrEditEvent", "Edit");
    // localStorage.setItem("persist:persisted-key", "");
    // this.props.removeEventByEventUser();
    localStorage.setItem("buyerFee", null);
    localStorage.removeItem("isCloneEvent");

    setTimeout(() => {
      if(isLoggedIn){
        window.location.href = `${routeName}/Dashboard/About`
      }else{
        history.push(`/${homeRoute}`)
      }
    }, 200);
  }

  const shouldRenderTag = true;

  return (
      <>
        <Helmet>
          {shouldRenderTag && (
              <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11228957629"></script>
          )}
          {shouldRenderTag && (
              <script>
                {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'AW-11228957629');
            `}
              </script>
          )}
        </Helmet>
    <div>
      {" "}
      <Navbar />
      <div className="">
        <img
          style={{ width: "100%", height: "100%" }}
          src={pricingImg}
          alt=""
        />
      </div>
      <div className="pricing_container">
        <div className="pricing_text">
          <h1>Simple Pricing. Nothing Hidden.</h1>
          <p>
            Create your event on UBMe and provide a better experience for your
            atteendees!
          </p>
        </div>
        <div className="pricing_box">
          <h4>Free for Event Organizers</h4>
          <p>Fees are paid by attendees unless you choose to absorb the fee.</p>
          <div className="pricing_box_bottom">
            <p>No fees applied for free events</p>
            <p>3% + $1.49 Service fee per paid ticket</p>
            <p>2.9% + 30¢ Processing Fee</p>
            <p>
              <a style={{
              textDecoration: "underline",
              color: "white",
              fontWeight: "600",
              cursor: "pointer"
            }} href={`${routeName}/ticketfees`} target="_blank">
                Click Here For International Pricing
              </a>
            </p>
            <button  onClick={() =>
              !isLoggedIn ? LoginPopupOpen({path:"Dashboard/About", value:true}) : cleareEventsAndCreateNewone()

                }  >Get Started</button>
          </div>
        </div>
        {/* Premium Features Included */}
        <div className="features-container">
        <Fade bottom>

          <h1>Premium Features Included</h1>
          </Fade>
          <div className="features-content">
            {features.map((item, i) => (
              <Features key={i} item={item} />
            ))}
          </div>
        </div>
          {/* Built-in Marketing Tools */}
          <div className="features-container">
          <Fade bottom>
          <h1>Built-In Marketing Tools</h1>
          </Fade>
          <div className="features-content">
            {Marketing.map((item, i) => (
              <Features key={i} item={item} />
            ))}
          </div>
        </div>
        {/* Provide a Better Experience */}
        <div className="features-container">
        <Fade bottom>
          <h1>Provide a Better Experience</h1>
          </Fade>
          <div className="features-content">
            {Experience.map((item, i) => (
              <Features key={i} item={item} />
            ))}
          </div>
        </div>
        {/* FAQ Section  */}
        <div className="faq_data">
        <Fade bottom>
        <h1 >Commonly Asked Questions</h1>
        </Fade>
        <Faq context="Pricing" />
        </div>

      </div>
      <Footer />
    </div>
        </>
  );
};


const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.loginResponse.isLoggedIn,

  };
};
const matchDispatchToProps = (dispatch) => {
  return {
    // AddToFavorite: () => dispatch(AddToFavorite),
    // SetCardData: () => dispatch(SetCardData),
    // LoginPopup: () => dispatch(LoginPopup),
    clearEventDetails,
    removeEventByEventUser,
    LoginPopup,
    dispatch,
  };
};
export default connect(mapStateToProps, matchDispatchToProps)(Pricing);

