import React from 'react'
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import CloseIcon from "@material-ui/icons/Close";
import { Box, IconButton } from "@material-ui/core";
const ShowErrorToast = ({message, closeToast}) => {
  return (
    <div
    style={{
    display: message !== "" ? "block" : "none",
    position: "absolute",
    top: 0,
    right: "0",
    zIndex: "2",
    width: "100%",
  }}
>
  <Box
    sx={{
      background: "white",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "10px 30px",
      background: "#d90d0d",
      color: "white",
      margin: "0px auto",
      animationName: "slide-from-bottom",
      animationDuration: "1s",
    }}
  >
    <div
      style={{
        display: "flex",
        alignItems: "center",
       
      }}
    >
      <ErrorOutlineIcon />
      <p
        style={{
          margin: "0 0 0 20px",
          textAlign: "start",
        }}
      >
        {message}
      </p>
    </div>
    <IconButton onClick={closeToast}>
      <CloseIcon height={30} width={30} style={{ color: "white" }} />
    </IconButton>
  </Box>
</div>
  )
}

export default ShowErrorToast