import { withStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react'
import { Info } from "@material-ui/icons";
import { Tooltip } from '@material-ui/core';
import { axiosE } from 'Service/service';
import { Text } from 'Constants';
const CurrentPlanCard = ({batchLimit, limits}) => {

  // const [limits, setLimits] = useState("")
  const CreateEventDetailsAboutToolTip = withStyles(() => ({
    tooltip: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: "16px",
      border: "1px solid #dadde9",
    },
  }))(Tooltip);

  const titleWithToolTip = (title, tipText, mandatory = false) => {
    return (
      <span style={{ fontSize: 16, fontWeight: "bold", color: "#000000" }}>
        {title}
        {mandatory && <span style={{ color: "red" }}> *</span>}
        <CreateEventDetailsAboutToolTip
          title={<React.Fragment>{tipText}</React.Fragment>}
        >
          <span
            style={{
              padding: "0 0 0 10px",
              cursor: "pointer",
              color: "rgb(161, 3, 150)",
            }}
          >
            <Info />
          </span>
        </CreateEventDetailsAboutToolTip>
      </span>
    );
  };
  
  // useEffect(()=>{
  //   try {
  //     axiosE.get(`GetEmailQuota/${userId}`).then(({data})=>{
  //     setLimits(JSON.parse(data.results.Data))
  //     console.log("==========>>>>>>>>>>>>>>>>>>>>",JSON.parse(data.results.Data));
  //     }).catch(()=>{

  //     })
  //   } catch (error) {
      
  //   }
  // },[])

  return (
    <div className='current-plan-card-wrapper'>
      {
        limits !== "" &&  <div className='current-plan-card'>
        <div style={{display:"flex", padding:15, justifyContent:"space-between", alignItems:'center'}}>
        <p style={{fontSize:"16px", }}>{titleWithToolTip("Current Plan Details:", "view your current plan's number of email contacts and monthly emails sent", false)} </p>
        </div>
        <div className='current-plan-card-limit' >
        <p style={{fontSize:"16px", }}> {Text.NumberOfContacts} </p>
        <p style={{fontSize:"16px", fontWeight:600,}}>{Intl.NumberFormat().format(limits.TotalSubscribers)}/{Intl.NumberFormat().format(limits.EmailSubscribersLimit)}</p>
        </div>
        <div className='vertical-line'>

        <div className="vertical"></div>
        </div>
        <div className='current-plan-card-limit' >
        <p style={{fontSize:"16px", }}>{Text.MonthlySendLimit}</p>
        <p style={{fontSize:"16px", fontWeight:600 }}>{Intl.NumberFormat().format(limits.TotalCurrentMonthEmailSent)}/{Intl.NumberFormat().format(limits.MonthlySendingEmailQuota)}</p>
        </div>
        
        <div className='current-plan-card-increse-limit'>
        <p style={{fontSize:"14px", fontWeight:600, textAlign:'center'}}>
         <sup>*</sup>{Text.increaseLimit},
           </p>
           <p style={{fontSize:"14px", fontWeight:600, textAlign:'center'}}>
           {Text.PleaseContacts} <span>{Text.PhoneNumber}</span>!
           </p>
        </div>
        
    </div> 
      }
       
    </div>
  )
}

export default CurrentPlanCard