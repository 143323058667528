import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Routes from '../Routes/routes';
import ProgressBar from '../../../Helper/ProgressBar/progress';

const styles= theme=>({
    content: {
        flexGrow: 1,
        padding: 0,
        minWidth: 0,
        backgroundColor:'#f5f5f5',
        overflow:'scroll',
    },
    toolbar: theme.mixins.toolbar,
    Paper: {
        margin:0,
        borderRadius:0
    },
});
const Main=(props)=>{
    const {classes} = props;
    return(
        <main className={classes.content}>
            <div className={classes.toolbar} />
            <div>
                <ProgressBar/>
            </div>
            <div>
                <Routes/>
            </div>
        </main>
    )
};
export default  withStyles(styles)(Main);