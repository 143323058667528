import { GetAllEvents } from "Actions/eventActions";
import React from "react";
import { connect } from "react-redux";
import * as constants from "Constants";
import "./FavPagiNation.css";

import { AddToFavorite } from "Actions/favoriteAction";
const FavPagiNation = ({ length, pageNO, setPageNo, page, resultsPageNo }) => {
  return (
    <div>
      <div className="fav_card_pagination">
        <div className="fav_card_pagination_result">
          <p>
            {constants.Message.ShowingResults}{' '}
            {resultsPageNo > 1 ? resultsPageNo * 10 + 1 : resultsPageNo} -{" "}
            {resultsPageNo === 1 ? length : length + 20}
          </p>
          <div className="fav_card_pagination_arrow_div">
            <span
              style={{ color: resultsPageNo === 1 ? "gray" : "black" }}
              className="fav_card_pagination_arrow"
              onClick={() => {
                if (pageNO > 1) {
                  setPageNo(pageNO - 1);
                }
              }}
            >
              <i class="fa-solid fa-angle-left"></i>
            </span>
            <span
              style={{ color: resultsPageNo < page ? "black" : "gray" }}
              className="fav_card_pagination_arrow"
              onClick={() => {
                if (resultsPageNo < page) setPageNo(pageNO + 1);
              }}
            >
              <i class="fa-solid fa-angle-right"></i>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    favoriteData: state.favoriteReducer.favoriteData,
    cardData: state.favoriteReducer.cardData,
    results: state.loginResponse.results,
    isLoggedIn: state.loginResponse.isLoggedIn,
    latlong: state.favoriteReducer.latlong,
  };
};
const matchDispatchToProps = (dispatch) => {
  return {
    GetAllEvents: () => dispatch(GetAllEvents),
    AddToFavorite: () => dispatch(AddToFavorite),
    dispatch,
  };
};
export default connect(mapStateToProps, matchDispatchToProps)(FavPagiNation);
