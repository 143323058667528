import React from 'react';
import {Redirect,Route} from 'react-router-dom';
import {routeName} from '../../Service/env';
import store from '../../store';

const PublicRoute = ({...props})=>{
    const token = localStorage.getItem('token');
    let access = false;
    try {
        if (token === undefined || token === null) {
            access = true
        }
    } catch (err) {
        console.log(err);
    }
    return (
        access ?
          (!props.onpage) ?
            <div>
                <Route {...props}/>
            </div>
            :
                <Redirect to={`${routeName}`}/>
            : <div>
                <Route {...props}/>
            </div>
    )
};

export default PublicRoute;