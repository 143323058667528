// EVENTS IMAGES
import emptyFAQ from "./images/CreateEvent/Create/Faq/FAQ.png";
import deleteIcon from "./images/Icons/DeleteIcon.png";
import emptyAd from "./images/CreateEvent/Create/Ads/Ads.png";
import emptyVendor from "./images/CreateEvent/Create/Vendor/Vendors.png";
import emptySponsor from "./images/CreateEvent/Create/Sponsor/Sponsors.png";
import emptyTickets from "./images/CreateEvent/Ticket/CreateTicket/Create-Ticket.png";
import discount from "./images/CreateEvent/Ticket/Discount/Discount.png";
const isDevelopment = process.env.NODE_ENV === "development";
const originName = window.location.origin.toString();
export const eventRoute = "event_development";
// URls
export const URLs = Object.freeze({
  privacyURL: `${originName}/privacy.html`,
  termsURL: `${originName}/terms.html`,
  baseUrl: isDevelopment ? `${originName}/${eventRoute}` : `${originName}`,
  hostName: originName,
  vendorImgUrl:
    "https://d2gnb7jphto2jj.cloudfront.net/UploadImages/EventImages",
  playStoreUrl:
    "https://play.google.com/store/apps/details?id=com.UBMe.organizer",
  appStoreUrl:
    "https://apps.apple.com/us/app/ubme-event-organizer/id6446975551",
});

// Apis
export const API = Object.freeze({
  userLoginApi: "UserLogin",
  // Ubme API's
  GetOrdersByUserId: "GetOrdersByUserId",
  GetTotalTicketsPurchasedByUserId: "GetTotalTicketsPurchasedByUserId",
  GetAllPublishedEventsByOrganizer: "GetAllPublishedEventsByOrganizer",
  GetAllPublishedEventsByOrganizerLandingPage: "GetAllPublishedEventsByOrganizerLandingPage",
  GetAllPublishedEventsForOrganizerLandingPage: "GetAllPublishedEventsForOrganizerLandingPage",
  GetStripeSupportedCountries: "GetStripeSupportedCountries",
  GetDiscountDetails: "GetDiscountDetails",
  PersistShoppingCartSession: "PersistShoppingCartSession",
  UnSubscribe: "UnSubscribe",
  PersistSubscribers: "PersistSubscribers",
  CreateShoppingCartSession: "CreateShoppingCartSession",
  GetAllOrderFormFieldsByEventId: "GetAllOrderFormFieldsByEventId",
  UploadUserCoverImage: "UploadUserCoverImage",
  UploadUserProfileImage: "UploadUserProfileImage",
  VerifyAccountPhoneNumber: "VerifyAccountPhoneNumber",
  UpdateUserData: "UpdateUserData",
  GetUserProfileDetail: "GetUserProfileDetail",
  GetEventMasterDetailsById: "GetEventMasterDetailsById",
  VerifyAccountUserName: "VerifyAccountUserName",
  VerifyAccountEmail: "VerifyAccountEmail",
  GetBusinessDetails: "GetBusinessDetails",
  UserCheckOutPlace: "UserCheckOutPlace",
  GetAllEventsByUserLiked: "GetAllEventsByUserLiked",
  GetEventsByUserTimeZoneWithImage: "GetEventsByUserTimeZoneWithImage",
  GetUserOwnEvents: "GetUserOwnEvents",
  UpdateEventByUser: "UpdateEventByUser",
  DeleteEventByUser: "DeleteEventByUser",
  AddUpdateEventByEventUser: "AddUpdateEventByEventUser",
  AddEventByUser: "AddEventByUser",
  GetAllEventCategories: "GetAllEventCategories",
  GetAllEvents: "GetAllEvents",
  UserCheckIns: "UserCheckIns",
  UsernameSuggestions: "UsernameSuggestions",
  LinkUpsUserForgetPassword: "LinkUpsUserForgetPassword",
  ValidateUser: "ValidateUser",
  registerUser: "LinkUpsUserRegistrationWithReferredBy",
  userLoginApi: "UserLogin",

  Geolocation_V2: "https://ipinfo.io/?token=919b7878c4da31",
  Geolocation: "https://maps.googleapis.com/maps/api/geocode/json?latlng=",
  GOOGLE_API_KEY: "AIzaSyDOEDrHL3W3HUA6hk2u59bITUj-a2HgO4U",

  // Manage Event API endpoints
  TicketsSoldGraphData: "ManageEvents/TicketsSoldGraphData",
  SummeryData: "ManageEvents/SummeryData",
  SalesGraphDate: "ManageEvents/SalesGraphDate",
  EngagementGraphData: "ManageEvents/EngagementGraphData",
  SalesListByOrganizerId: "ManageEvents/SalesListByOrganizerId",
  DeleteEventByOrganizer: "Event/Delete",
});

//Messages
export const Message = Object.freeze({
  // invalidCredentialsMsg: "Invalid Credentials.",
  // bothFieldsAreRequiredMsg: "Both Fields are Required.",
  // yourNameMustHaveAtLeast3CharactersMsg:
  //   "Your Name must have at least 3 characters",
  // Ubme mesage
  InvalidUrl: "The url is invalid. Click on the logo to get redirected to our homepage.",
  SeveralMinutes: "This may take up to several minutes.",
  check: "Please check privacy policy",
  validDetails: "Please Enter valid details",
  NoresultFound: "No Search Results Found",
  EmailLink: "Email link to",
  passwordMessage: "Password must be greater than or equal to 6",
  forgotPasswordplaceholder: "Enter Email, phone or username",
  notAssosiate: "We couldn't find your account with that information",
  uservalid: "We found the following information associated with your account",
  usernotvalid: "Please try searching for your email, phone or username again",
  invalidCredentialsMsg:
    "The password or username that you’ve entered is incorrect",
  bothFieldsAreRequiredMsg: "Both Fields are Required.",
  AllFieldsAreRequiredMsg: "* All fields are required.",
  passwordNotMatch: "The passwords you have entered do not match",
  yourNameMustHaveAtLeast3CharactersMsg:
    "Your Name must have at least 3 characters",
  share: "Share with friends",
  events: "Event Url",
  Facebook: "Facebook",
  Twitter: "Twitter",
  Mail: "Mail",
  Whatsapp: "Whatsapp",
  ShowingResults: "Showing Results",
  OutOf: "out of",
});

//String
export const Text = Object.freeze({
  //Analytics
  Male: "Male",
  Female: "Female",
  vs: "vs",
  Report: "Report",
  PrintReport: "Print Report",
  LandingPage: "Landing Page",
  LandingPageDesc: "See your landing page analytics.",
  NoData: "There is no data",
  Vendors: "Vendors",
  Clicks: "Clicks",
  Calls: "Calls",
  Confirm: "Confirm",
  TotalPayout: "Total Payout",
  AddAttendees: "Add Attendees",
  Tickets: "Tickets",
  Quantity: "Quantity",
  TicketType: "Ticket Type",
  Save: "Save",
  Update: "Update",
  Cancel: "Cancel",
  Price: "Price",
  IssueRefund: "Issue Refund",
  RefundAll: "Refund All",
  REFUNDED: "REFUNDED",
  ORDERHash: "ORDER #",
  STATUS: "STATUS",
  ORDERTOTAL: "ORDER TOTAL",
  NoAttendeeFound: "No Attendee records found!",
  ORDERDATE: "ORDER DATE",
  ORDERTYPE: "ORDER TYPE",
  Search: "Search",
  TicketsHolders: "Tickets Holders",
  SearchAttendees: "Search attendees by order #, ticket #, name or email",
  ProcessRefund: "Process Refund/Revoke",
  RefundText:
    "Select which tickets to refund (and revoke). Refund includes cost of ticket, fees and sales tax (if applicable)",
  OrderDetails: "Order Details",
  ConfirmAttendees:
    "Confirm attendees using their order #, ticket #, name or email",
  Reportedby: "Reported by",
  EmailInquires: "Email Inquires",
  Attendees: "Attendees",
  AddAttendeePlus: "Add Attendee +",
  UBMeOrganizerAppText:
    "Confirm attendees below or download UBMe Organizer App to use a ticket scanner.",
  Highlights: "Highlights",
  Impressions: "Impressions",
  EmailInquiries: "Email Inquiries",
  PhoneCalls: "Phone Calls",
  sendMail: "send mail",
  WebsiteClicks: "Website Clicks",
  YourAdBroughtIn: "Your ad brought in",
  Youradreceived: "Your ad received",
  VendorsText: "Print results for your vendors to see.",
  OverallEngagement: "Overall Engagement",
  OverallEngagementDesc: "See what's happening at",
  RealTime: "in real time!",
  AppEngagement: "App Engagement",
  AppEngagementDesc: "See what's your engagement is like on UBMe app for",
  All: "All",
  Name: "Name",
  Email: "Email",
  OrderHash: "Order #",
  TicketHash: "Ticket #",
  SureToCancelEvent: "Are you sure you want to",

  // Flyers
  DownloadPDF: "Download PDF",
  DownloadJPG: "Download JPG",

  //EventDetailsAbout
  EventSaved: "Your event has been saved!",
  SaveAndContinue: " Save and Continue",
  UploadMap: "Upload Map",
  ViewMap: "View Map",
  UploadImage: "Upload Image",
  publishYourEvent:
    "Please make sure to scroll down and publish your event once you complete editing your event details",
  Understood: "Understood!",
  CreateAd: "Create Ad",
  AddSessionDays: "Add Session Days",
  AddSession: "Add Session",
  SessionDetails: "Session Details",
  AddSponsor: "Add Sponsor",
  Logo: "Logo",
  AddPlus: "+ Add",
  VendorLogo: "Vendor Logo",
  AddVendor: " Add Vendor",
  EditVendor: "Edit Vendor",
  CropImage: "Crop Image",

  // Create Tickets
  PayoutMethod: "make sure the payout method is setup",
  ActiveAtLeastOneTicket:
    "There must be at least one active ticket type listed. If you added a paid ticket type,",
  Ticket: "Ticket",
  Paid: "Paid",
  Free: "Free",
  TIMESUSED: "TIMES USED",
  OFF: "OFF",
  AdditionalTerms: "additional terms and conditions",
  PayoutsText:
    "View new payouts, manage previous payouts, and update bank account info from your Stripe Dashboard. Stripe will issue daily payouts to your bank.",
  Next: "Next",
  PoweredByStripe: "Your payouts are now powered by Stripe!",
  StripeText:
    "Stripe will issue daily payouts to your bank unless otherwise specified in your Stripe Dashboard.",
  Gotit: "Got it",

  // Email invites
  EditEmailInvite: "Edit Email Invite",
  EmailInvitations: "Email Invitations",
  Congratulations: "Congratulations!",
  SendAnotherInvite: "Send Another Invite",
  updatesText:
    "Please make sure to click save button, once you finish making any updates to the email invitation!",
  ChooseAdditionalInclude: "Choose Additional Events To Include",
  Footer: "Footer",
  spamText:
    "To help your email go through to inboxes instead of spam, the best practice is to have these filled out.",
  OrganizerName: "Organizer name",
  Address1: "Address 1",
  Address2: "Address 2",
  City: "City",
  State: "State",
  ZipCode: "ZIP Code",
  Country: "Country",
  FacebookLink: "Facebook link",
  InstagramLink: "Instagram link",
  TwitterLink: "Twitter link",
  Continue: "Continue",
  ToIncreaseYourLimit: "to increase your limit!",
  monthlyLimit: "You have exceeded your monthly sending limit. Please contact",
  PhoneNumber: "(800) 359-0059",
  DeleteContact: "Are you sure you want to delete this contacts file?",
  Delete: "Delete",
  AllContacts: "All Contacts",
  ContactsSelected: "Contacts Selected",
  NewContacts: "New contacts",
  EmailsAdded: "Emails Added:",
  DuplicateEmails: "Duplicate Emails:",
  InvalidEmails: "Invalid Emails:",
  UnsubscribedEmails: "Unsubscribed Emails",
  LimitReached:
    " You have reached your limit on the number of contacts that you can have in your account. In order to add additional contacts, please delete one of the existing contact lists that you have uploaded or call us at",
  InvalidCsv:
    "The CSV file you provided is invalid as it does not contain email addresses in a single line. Please ensure that all email addresses are listed in a single cell within the same row",
  AccountLimit1: "Your account has a limit of",
  AccountLimit2:
    "contacts remaining, please upload a smaller contact list or contact us at",
  CheckYourFile: "Please check your file and try again",
  UploadContacts: "+ Upload new contacts",
  NumberOfContacts: "Number Of Email Contacts",
  MonthlySendLimit: "Monthly Send Limit",
  increaseLimit: "If you would like to increase your limit",
  PleaseContacts: "please contact",
  Unsubscribe: "Unsubscribe",
  EnhancedExperienceAvailable: "Enhanced Experience Available",
  CheckIns:
    " Check-in using UBMe app for a better experience during the event!",
  Register: "Register",
  LetsToGather: "Let's get together",
  HoistingNewEvent:
    "We're hosting a new event, and we'd love to see you there. Join us for",
  LimitedSpace: "Register soon because space is limited.",
  JoinUs: "We hope you're able to join us!",
  AddHeaderImage: "Add header image",
  DropYourFile: "Drag and drop or click here to choose your file",
  NotAnImage: "Not an image file",
  DropYourFileHere: "Drop file here ...",
  SendEmailLimitText: " You can send only 10 test email at a time",
  Send: "Send",
  Edit: "Edit",
  ScheduledFor: "Scheduled for",
  ScheduledEmailInvitations: "Scheduled Email Invitations:",
  Subject: "Subject",
  From: "From",
  OptimisedTime: "Will be sent out at best optimised time",
  DaysBeforeEvent: "Days before event",
  Immediately: "Immediately",
  SelectDate: "Select Date :",

  // Sales
  NoSales: "No sales records found!",
  TotalPayout: "Total Payout",
  CreditCardFeesNotIncluded: "**Credit card processing fees NOT included - Visit stripe.com for current card processing rates.",
  TotalSales: "Total Sales:",
  Export: "Export",
  Search: "Search",
  RecentActivity: "Recent Activity",

  // Event OverView
  EngagementScore: "Engagement Score:",
  Status: "Status:",
  AppEngagements: "App Engagements:",
  LandingPageViews: "Landing Page Views:",
  CheckIns: "Check-ins:",
  NoRecordsFound: "No event records found!",
  NoEventCreated:
    " You do not have any events created, click on Create Event button to get started!",
  CreateEvent: "Create Event",
  Live: "Live",
  Draft: "Draft",
  Ended: "Ended",
  Canceled: "Canceled",
  ThereIsNoRecentActivity: "There is no recent activity!",

  // Checkout Payment
  PlaceOrder: "Place Order",
  StriptAccountPending: "Stripe account status still PENDING",
  Payment: "Payment",
  AdditionalTermsDesc:
    "Organizer has requested the following additional information.",
  AllFieldsMustBeFilledOut:
      " All fields below must be filled out before you can place the order.",
  AdditionalInformation: "Additional Information",
  ClickingPlaceOrder: 'By placing order below, I agree to the UBMe\'s',
  TermsOfService: "Terms of Service",
  PrivacyPolicy: "Privacy Policy",
  TimeLeft: "Time left",
  Checkout: "Checkout",
  OrderSummary: "Order Summary",
  SubTotal: "Sub Total",

  //EventDetails
  WHEN: "WHEN",
  AddToCalendar: "Add to Calendar",
  ViewEventMap: "View Event Map",
  Directions: "Directions",

  //EventFooter
  ORGANIZERDETAILS: "ORGANIZER DETAILS",
  Contact: "Contact",
  Follow: "Follow",
  Unfollow: "Unfollow",
  MOREEVENTSFROM: "MORE EVENTS FROM",

  // EventMap
  About: "About",
  Description: "Description:",
  Parking: "Parking:",

  // EventNavbar
  Home: "Home",
  About: "About",
  FAQ: "FAQ",
  Agenda: "Agenda",
  Vendors: "Vendors",
  WhenYouArrive: "When You Arrive",
  Organizer: "Organizer",
  SoldOut: "Sold Out",
  share: "share",

  // Sponsors
  ThankYouToOurSponsors: "Thank you to our sponsors!",

  // Vendors
  SendMail: " Send mail",
  VisitWebsite: "Visit website",
  Vendors: "Vendors",

  // Unsubscribe Emails
  Done: "Done!",
  Unsubscribe: "Unsubscribe",
  UnSubscribed: "You've successfully been unsubscribed from",
  IsMailingList: "is subscribed to our mailing list(s)",
  UnSubscribedMailingList: "Unsubscribe from our mailing list",
  NewsLetter: "If you unsubscribe, you will stop receiving our newsletter.",

  // Tickets
  TicketPurchases: "You don't have any ticket purchases!",

  //Login
  welcomeHeading: "Connecting the World",
  welcomeSubtitle: "Search Events Near You!",
  nofavoriteevents: "You do not have any favorite events",
  noUpcomingEventsFound: "No upcoming events, check back soon!",
  username: "Username",
  password: "Password",
  loginToDashboard: "Login To Dashboard",
  forgotPassword: "Forgot Password?",
  login: "Log in",
  email: "Email",

  //Main
  getStarted: "Get Started!",
  loginToYourPortalBelow: "Log in to your portal below",
  logIn: "Log In",
  signUp: "Sign Up",

  //AppLogin
  loginToImportYourProfile: "Log in to import your profile",
  emailPhoneOrUsername: "Email, phone or username",
  cancel: "Cancel",

  //NameSection
  whatMayWeCallYou: "Hi, what may we call you?",
  enterYourFullName: "Enter your full name",
  name: "Name",
  byContinuingIagree: "By continuing I agree that I have read and accepted the",
  termsOfUse: "Terms of use",
  and: "and",
  privacyPolicy: "Privacy Policy",
  next: "Next",

  // UBme text
  // Landing page
  draftEvent: "Your event is not live!",
  salesEndOn: "Sale ends on",
  bundleTicketMessage1: "You have ",
  bundleTicketMessage2: " selected",
  bundleTicketSelectionSuccessMessage1: "Awesome, the required ",
  bundleTicketSelectionSuccessMessage2: " time slots have been selected",
  soldOut: "Sold Out",
  frontRowSeats: "Get up close and personal with these front row seats!",
  ticketSaleEnd: "Ticket sales have ended!",
  continue: "Continue",
  Reserved: "The tickets are in process of being purchased by another user",
  // Google Auto Complete
  location: "Location",
  searchplaces: "Accessing Location...",
  usemylocation: "Use My Location",
  //  Ticket text
  PrintTickets: "Print Tickets",
  ContactOrganizer: "Contact Organizer",

  // Notes
  deleteNoteMessage: "Are you sure you want to delete this note?",

  // Order Form
  deleteOrderFormCustomFieldMessage: "Are you sure you want to delete this field?\n\nIf you delete this field, you will lose the associated submission data. Are you sure you want to proceed?"
});

export const ErrorMessages = Object.freeze({
  imageSize: "Your Image Size Exceeds 5MB",
  imageSize2MB: "Your Image Size Exceeds 2MB",
  imageNotFound: "You Did'nt Upload Anything",
  somethingWentWrong: "Something Went Wrong",
});

//Events
export const Events = Object.freeze({
  deleteImg: deleteIcon,
  About: {
    title: "Event Title",
    address: "Address",
    addressExtraText: "Type venue address and select from drop box. For virtual events, select the city you'd like to target.",
    venue: "Venue Name",
    image: "Event Profile Image",
    pdf: "Event Map (PDF file only)",
    type: "Event Type",
    description: "Event Description",
    parking: "Parking",
    virtualInvitationDetails: "Virtual Invitation Details",
  },
  FAQ: {
    emptyFaq: {
      headline: "Add FAQ's",
      message: "Click Add button to get started!",
      img: emptyFAQ,
    },
    addFAQ: {
      question: "Enter new frequently asked question",
      answer: "Enter an answer to the frequently asked question",
      questionPlaceHolder:
        "Enter a question that attendees will find most helpful to know an answer to",
      headline: "Add FAQ",
    },
    deleteFAQ: {
      message: "Are you sure you want to delete this FAQ?",
      img: deleteIcon,
    },
  },
  Agenda: {
    startPage: "Please select the days you would like to set agenda for:",
    addSession: {
      headline: "Session Details",
      title: "Session title",
      description: "Session description",
      descriptionPlaceHolder: "Enter Description here...",
      location: "Location (optional)",
      addSpeakers: "Add Speakers (optional)",
      speakerName: "Speaker's Name*",
      speakerTopic: "Topic*",
      speakerLinkedin: "Speaker's LinkedIn profile URL (optional)",
      speakerWebsite: "Speaker's website URL (optional)",
    },
    deleteAgenda: {
      day: "Are you sure you want to delete agenda for this day?",
      session: "Are you sure you want to delete this session?",
      img: deleteIcon,
    },
  },
  ADS: {
    emptyAd: {
      headline: "Create Ads",
      message:
        " Great way to promote sponsors, additional products or future events. When attendees visit your event's page or check-in to the event, your created ads will be shown. (Results will appear under your analytics tab)",
      img: emptyAd,
    },
    adAdvertisement: {
      banner1: "Enter text for line 1 of your banner ad",
      banner2: "Enter text for line 2 of your banner ad",
      website: "www.website.com",
    },
    deleteAd: {
      message: "Are you sure you want to delete this Ad?",
      img: deleteIcon,
    },
  },
  Vendors: {
    emptyVendor: {
      headline: "Add Vendors",
      message:
        " Have Vendors that you would like to showcase? Click Add button to get started!",
      img: emptyVendor,
    },
    error: {
      message: "The maximum size allowed is 2MB.",
    },
  },
  Sponsors: {
    emptySponsor: {
      headline: "Add Sponsors",
      message:
        " Have sponsors that you would like to showcase? Click Add button to get started!",
      img: emptySponsor,
    },
  },
  deleteAd: (name) => {
    return {
      message: `Are you sure you want to delete this ${name}?`,
      img: deleteIcon,
    };
  },
});

export const SidebarLabels = Object.freeze({
  eventUrl: isDevelopment
    ? `${originName}/${eventRoute}/event/`
    : `${originName}/event/`,
  eventView: "View Event",
  eventStatus: {
    live: { text: "Live", color: "green" },
    draft: { text: "Draft", color: "gray" },
    ended: { text: "Ended", color: "gray" },
  },
  eventDetailsError: "Please complete your event details.",
  eventAboutError: "Please complete event about section first.",
  eventPublishError: "Only available once event is published.",
});

export const Tickets = Object.freeze({
  createTickets: {
    emptyTickets: {
      headline: "Time to create your first ticket!",
      message: "Click Add button to get started!",
      img: emptyTickets,
    },
    addTicket: {
      headline: "Add Ticket",
      name: "Ticket Name",
      description: "Ticket Description (optional)",
      quantity: "Quantity Available",
      price: "Price",
      buyerSection: {
        headline: "What the buyer pays:",
        price: "Ticket Price",
        fees: "Fees:",
        total: "Buyer Total:",
      },
      revenueSection: {
        headline: "What you get:",
        buyerTotal: "Buyer Total:",
        fees: "Fees:",
        total: "Your Revenue:",
      },
      absorbFees:
        "Absorb Fees: Ticketing Fee are deducted from your ticket revenue",
      saleTime: "When Should tickets go on sale?",
    },
    paymentError:
      "You must set up your payout method on the next page before tickets can be purchased!",
    lastTicketError: "There must be at least one active ticket type listed.",
    ticketPaymentError: `add payment method`,
    deleteError: "You are not able to delete this ticket",
  },
  deleteTicket: {
    message: "Are you sure you want to delete",
    img: deleteIcon,
    info: "Deleting this ticket will NOT refund any tickets that have already been sold up to this point - to issue a refund, please refer to the 'Attendees' tab",
  },
  payout: {
    emptyPage: {
      headline: "Set up your payout method",
      message1: "Our secure payment system supports several payout methods, ",
      message2: "which can be setup and edited here.",
      cta: "Select Payout Method",
    },
    header: {
      title: "Select a Payout Method",
      afterAdd: "Payout Method",
    },
    page1: {
      col1: {
        title: "Method",
        option: "Stripe",
      },
      col2: {
        title: "Details",
        option: {
          line1Head: "Speed: ",
          line1: "Funds delivered to your account in real time",
          line2Head: "Control: ",
          line2: "Payouts scheduled on your own terms.",
          line3Head: "Protection: ",
          line3: "Fraud blocked using advanced machine learning.",
        },
      },
      col3: {
        title: "Payout Timing",
        option: {
          line1: "Daily",
          line2: "Details",
          url: "https://stripe.com/payments/features#payout-timing",
        },
      },
    },
    page2: {
      title: "A simple and powerful way to accept payments online.",
      message1:
        "Stripe is an all-in-one, PCI-compliant solution that's fully integrated with our platform.",
      message2: "Click below to connect or create your free Merchant Account.",
      cta: "Connect with Stripe",
    },
    page3: {
      title: "APPROVED",
      vendor: "Stripe",
      stripeAccount: "Stripe Account: UBMEFOOD",
      descriptorName: "Descriptor Name",
      footer:
        "View new payouts, manage pervious payouts, and update bank account info from your Stripe Dashboard.",
      cta: "Take Me To Stripe",
      link: "https://dashboard.stripe.com/balance/overview",
    },

    // ClientId: coming from stripe account connect settings (https://dashboard.stripe.com/settings/connect)
    //live:  ca_MrfVjGVjErbbjtfQrpudPyXHfieRw2sh
    //local: ca_MrfVQmQYze2l5piKAebRDry26AqrCoSM
    connectUrl: (code) =>
      `https://connect.stripe.com/oauth/v2/authorize?response_type=code&client_id=ca_MrfVjGVjErbbjtfQrpudPyXHfieRw2sh&scope=read_write&state=${code}&redirect_uri=${originName}/Dashboard/Tickets/Payouts`,
  },
  orderForm: {
    headline: "Order Form Questions",
    subHead:
      "Manage the information you collect from attendees during checkout.",
    fields: [
      { label: "Age", state: false },
      { label: "Address", state: false },
      { label: "Gender", state: false },
      { label: "Birthday", state: false },
      { label: "Website", state: false },
      { label: "Company", state: false },
      { label: "Job Title", state: false },
      { label: "Work Address", state: false },
      { label: "Work Phone", state: false },
      { label: "Suffix", state: false },
      { label: "Blog URL", state: false },
      { label: "LinkedIn URL", state: false },
      { label: "Custom", state: false, value: "" },
    ],
    defaultFields: [
      { label: "Full Name", required: true },
      { label: "Email", required: true },
      { label: "Phone", state: false },
    ],
    termsAndConditions: {
      headline: " Additional Terms and Conditions",
      placeHolder: "Type here the additional terms you want to show your users",
    },
  },
  discount: {
    emptyDiscount: {
      headline: "Attract more attendees with discount codes!",
      description: "Click Add button to get started!",
      img: discount,
    },
    addDiscount: {
      headline: " Discount",
      name: "Name this code",
      amount: "Discount Amount",
      number: "Number available",
      required: "Minimum required",
      appliesTo: "Applies to",
      validFrom: "Valid From",
      codeError:
        "Provide a code name, Access codes should not contain spaces, apostrophes or non-alphanumeric characters (except '-', '_', '(', ')', '/', '', '+', '@', and '.')",
      invalidNumber: "Please enter a valid number",
      greaterThenUsed: "Available should be greater than used",
    },
    deleteDiscount: {
      message: "Are you sure you want to delete this discount code?",
      img: deleteIcon,
    },
  },
  taxOption: {
    headline: "Tax Options and/or Additional Fees",
    description:
      "You may be required by state or local regulations to charge a tax or an additional fee. We encourage you to consult a tax professional to ensure you understand your tax obligations.",
    dropdown:
      "Do you need to charge a tax and/or additional fee for this event?",
    taxName: "Tax or Fee Name",
    taxNamePlaceholder: "e.g. Sales Tax",
    taxRate: "Tax Rate",
  },
  publish: {
    headline: "Publish Your Event",
    subHead: "What type of event you want to create?",
    publicEvent: "Public Event",
    publicEventDesc: "(Promoted across UBMe platform and beyond)",
    inviteOnly: "Invite Only",
    inviteOnlyDesc: "(Promoted across UBMe platform and beyond)",
    sendEmail: "Send Email Invitations",

    eventLiveHead: "Your Event Is ",
    liveSubHead: "Promoting your event with UBMe is easier then ever.",
    liveSubHead1: "Sharing your event on social media can do wonders!",
    liveSubHead2: "Follow the check-list below to increase your registration quantities by 30%!",
    ctaAfterPublish: "View Event",
    ctaBeforePublish: "Event Preview",
    cta2: "Publish Event",
    eventLink: `${originName}${isDevelopment ? `/${eventRoute}` : ""}/event/`,
  },
});

export const eventApis = Object.freeze({
  // About Section API's
  getAllEventCategories: "/GetAllEventCategories",
  GetEventMasterById: "/GetEventMasterById/",
  addEvent: "/Event/Add",
  updateEvent: "/Event/Update",
  deleteEventMap: "/EventMap/Delete",
  // Agenda API's
  addAgendaSession: "/AgendaSession/Add",
  deleteAgendaSession: "/AgendaSession/Delete",
  updateAgendaSession: "/AgendaSession/Update",
  addAgendaSpeaker: "/AgendaSessionSpeaker/Add",
  updateAgendaSpeaker: "/AgendaSessionSpeaker/Update",
  deleteAgendaSpeaker: "/AgendaSessionSpeaker/Delete",
  getAgendaSessionByEventId: "/GetAgendaSessionByEventId/",
  getAgendaSpeakerByAgendaSessionId: "/GetAgendaSpeakerByAgendaSessionId/",
  getAgendaSessionById: "/GetAgendaSessionById/",

  // FAQ API's
  addFaq: "/FAQ/Add",
  updateFaq: "/FAQ/Update",
  deleteFaq: "/FAQ/Delete",
  getFaqById: "/GetFaqById/",
  getFaqByEventId: "/GetFaqByEventId/",
  // AD API's
  addAd: "/Advertisment/Add",
  updateAd: "/Advertisment/Update",
  deleteAd: "/Advertisment/Delete",
  getAdvertismentByEventId: "/GetAdvertismentByEventId/",
  getAdvertismentById: "/GetAdvertismentById/",
  // Sponsor API's
  addSponsor: "/Sponsor/Add",
  updateSponsor: "/Sponsor/Update",
  deleteSponsor: "/Sponsor/Delete",
  getSponsorByEventId: "/GetSponsorByEventId/",
  getSponsorById: "/GetSponsorById/",
  // Vendor API's
  addVendor: "/Vendor/Add",
  updateVendor: "/Vendor/Update",
  deleteVendor: "/Vendor/Delete",
  getAllEventVendorType: "/GetAllEventVendorType",
  getVendorByEventId: "/GetVendorByEventId/",
  getVendorById: "/GetVendorById/",

  // Tickets Section
  // Create Tickets API's
  addTicket: "/Ticket/Add",
  updateTicket: "/Ticket/Update",
  deleteTicket: "/Ticket/Delete",
  getTicketByEventId: "/GetAllTicketByEventId/",
  getTicketById: "/GetTicketById/",
  // Discount API's
  addDiscount: "/Discount/Add",
  updateDiscount: "/Discount/Update",
  deleteDiscount: "/Discount/Delete",
  getDiscountByEventId: "/GetAllDiscountByEventIdOrUserId/",
  getDiscountById: "/GetDiscountById/",
  // Order Form API's
  addOrderForm: "/OrderFormField/Add",
  updateOrderForm: "/OrderFormField/Update",
  deleteOrderForm: "/OrderFormField/Delete",
  getOrderFormByEventId: "/GetAllOrderFormFieldsByEventId/",
  getOrderFormById: "/GetOrderFormFieldById/",
  // Tax API's
  addTax: "/AdditionalTax/Add",
  updateTax: "/AdditionalTax/Update",
  deleteTax: "/AdditionalTax/Delete",
  getTaxByEventId: "/GetAllAdditionalTaxByEventId/",
  getTaxById: "/GetAdditionalTaxById/",
  // Payout API's
  OAuthTokenCreate: "/OAuthTokenCreate",
  Descriptor: "/Event/UpdatePaymentDescriptor",
  // Publish API's
  publishEvent: "/Event/Publish",
  // Event Status API's
  getEventStatus: "/GetEventPublishedStatusByEventId/",

  //  LiveChatCheckIn API
  LiveChatCheckIn: "/LiveChatCheckIn",
});

// * UBMe constant text
export const FooterText = Object.freeze({
  navigation: "Navigation",
  About: "About Us",
  Pricing: "Pricing",
  FrequentlyAskedQuestions: "FAQ",
  overview: "Overview",
  Contact: "Contact",
  AddRestaurant: "Create Event",
  Restaurantlogin: "Overview",
  Foodblogging: "Pricing",
  ContactUs: "Contact Us",
  Reportissue: "Report an issue",
  email: "support@ubme.com",
  address: "520 Evens St Suite 7, Bethlehem, PA 18015",
  phone: "(800) 359-0059",
  terms: "TERMS AND CONDITIONS",
  privacy: "PRIVACY",
  rights: "©️ 2024 UBMe, Inc. All Rights Reserved.",
  AboutUs:
    "UBMe Events is an event marketplace + attendee engagement solution that creates a seamless management experience for event organizers while simultaneously allowing attendees to connect and engage with one another in real-time at local events.",
});

// Navbar
export const NavbarText = Object.freeze({
  brandname: "UBMe Events",
  ubmeEvents: "Overview",
  Pricing: "Pricing",
  FrequentlyAskedQuestions: "FAQ",
  createEventPlus: "Create Event +",
  signin: "Sign In",
  browseEvents: "Browse Events",
  createEvent: "Create Event",
  tickets: "Tickets",
  manageEvents: "Manage Events",
  visitMyProfile: "Visit My Profile",
  favorite: "Favorites",
  message: "Messages",
  account: "Account",
  logout: "Logout",
});

// HomeTextFeilds
export const HomeTextFeilds = Object.freeze({
  entername: "Enter name",
  selectCategory: "Select Category",
  Pricing: "Pricing",
  search: "Search",
  searchplaces: "Search Places..",
  dance: "Dance",
  music: "Music",
  gamePlay: "GamePlay",
  movie: "Movie",
});

// LoginText
export const LoginSignUpText = Object.freeze({
  //Login
  termsOfservicesAnd: "and",
  usernameoremail: "Email, Phone or username",
  firstname: "First name",
  lastname: "Last name",
  username: "Username",
  password: "Password",
  cpassword: "Confirm Password",
  loginToDashboard: "Login To Your Account",
  signupToDashboard: "Sign Up",
  forgotPassword: "Forgot Password?",
  forgot_Password: "Forgot Password",
  login: "Click Here to Sign In",
  email: "Email",
  logIn: "Log In",
  signUp: "Sign Up",
  signup: "Signup",
  submit: "Submit",
  loginHere: "Login Here",
  sendemailtoforgotpass: "Find your UBMe account",
  alreadyregistered: "Already have an account?",
  privacyandPolicy: "Privacy Policy",
  termsOfservices: "Terms of Services",
  privacyPolicy: "By signing up, you agree to the ",
  note: "note: please input correct email and we will a password on that email",
  newhere: "New Here?",
});

//String

// User-account
export const UserAccountText = Object.freeze({
  name: "Name",
  username: "Username",
  password: "Password",
  newpassword: "New Password",
  cpassword: "Confirm Password",
  currpassword: "Current Password",
  email: "Email",
  phone: "Phone",
  gender: "Gender",
  birthday: "Birthday",
  friends: "Friends",
  followers: "Followers",
  following: "Following",
  resetpassword: "Reset Your Password",
  update: "Update",
  profilePicture: "Profile Picture",
  coverPhoto: "Cover photo",
  submit: "Submit",
  save: "Save",
});

export const ButtonText = Object.freeze({
  Online: "Online",
  today: "Today",
  thisWeekend: "This weekend",
  free: "Free",
  music: "Music",
  fooddrink: "Food & Drink",
});
