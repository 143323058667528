import React from "react";
import "./index.css";
import { GetUserProfileDetail, LoginPopup, logoutAction } from "Actions";
import { history } from "store";
import {
  clearEventDetails,
  removeEventByEventUser,
} from "Actions/eventActions";
import { connect } from "react-redux";
import { homeRoute, routeName } from "Service/env";
const OverViewPricing = ({
  LoginPopup,
  dispatch,
  isLoggedIn,
  clearEventDetails,
  removeEventByEventUser,
}) => {
  const LoginPopupOpen = (val) => {
    dispatch(LoginPopup(val));
  };

  const cleareEventsAndCreateNewone = () => {
    dispatch(clearEventDetails());
    dispatch(removeEventByEventUser());
    localStorage.setItem("isItCreateOrEditEvent", "Edit");
    // localStorage.setItem("persist:persisted-key", "");
    // this.props.removeEventByEventUser();
    localStorage.setItem("buyerFee", null);
    localStorage.removeItem("isCloneEvent");

    setTimeout(() => {
      if (isLoggedIn) {
        window.location.href = `${routeName}/Dashboard/About`;
      } else {
        history.push(`/${homeRoute}`);
      }
    }, 200);
  };

  return (
    <div className="overview-pricing-container">
      <div className="overview-pricing">
        <div className="pricing_box" style={{zIndex: "unset"}}>
          <h4>Free for Event Organizers</h4>
          <p>Fees are paid by attendees unless you choose to absorb the fee.</p>
          <div className="pricing_box_bottom">
            <p>No fees applied for free events</p>
            <p>3% + $1.49 Service fee per paid ticket</p>
            <p>2.9% + 30¢ Processing Fee</p>
            <p>
              <a style={{
                textDecoration: "underline",
                color: "white",
                fontWeight: "600",
                cursor: "pointer"
              }} href={`${routeName}/ticketFees`} target="_blank">
                Click Here For International Pricing
              </a>
            </p>
            <button
              onClick={() =>
                !isLoggedIn
                  ? LoginPopupOpen({ path: "Dashboard/About", value: true })
                  : cleareEventsAndCreateNewone()
              }
            >
              Get Started
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.loginResponse.isLoggedIn,
  };
};
const matchDispatchToProps = (dispatch) => {
  return {
    // AddToFavorite: () => dispatch(AddToFavorite),
    // SetCardData: () => dispatch(SetCardData),
    // LoginPopup: () => dispatch(LoginPopup),
    clearEventDetails,
    removeEventByEventUser,
    LoginPopup,
    dispatch,
  };
};
export default connect(mapStateToProps, matchDispatchToProps)(OverViewPricing);
