import React, { useEffect, useRef, useState } from "react";
import "./Textfield.css";
import styles from "./AutoCompletePlaces_v1/PlacesV1.module.css";
import {
  IoLocationOutline,
  IoSearchOutline,
  IoCloseCircleSharp,
} from "react-icons/io5";
import { CgNotes } from "react-icons/cg";
import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md";
import AutoCompleteV1 from "./AutoCompletePlaces_v1/AutoCompleteV1";
import * as constants from "Constants";
import UseOutsideClick from "../../Components_UBMeEvents/OutSideClick/UseOutsideClick";

import { connect } from "react-redux";
import {
  Link,
  Button,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";
import {
  GetAllEvents,
  LatLongData,
  EventSearch,
  GetAllEventCategories,
  SyncLatLongDataV2,
} from "Actions/eventActions";
import moment from "moment";
const TextField = ({
  isLoggedIn,
  EventSearch,
  eventSearchData,
  latlong,
  results,
  GetAllEventCategories,
  dispatch,
  LatLongData,
  cardData,
  loginPopUp,
}) => {
  const [text, setText] = useState(
    `${constants.HomeTextFeilds.selectCategory}`
  );

  const ref = useRef();

  UseOutsideClick(
    () => {
      if (active) {
        setActive(false);
      }
    },
    ".Menu-list-modal",
    ref
  );

  const [active, setActive] = useState(false);
  const [latlLong, setLatlLong] = useState({
    lat: latlong?.lat,
    lng: latlong?.lng,
  });

  const [catData, setCatData] = useState([]);
  const [selected, setSelected] = useState(false);
  const [searchItem, setSearchItem] = useState("");
  const [category, setCategory] = useState("");
  const [isFirstRender, setIsFirstRender] = useState(false);

  useEffect(() => {
    dispatch(GetAllEventCategories()).then(({ results }) => {
      setCatData(results.Categories);
    });
  }, []);

  useEffect(() => {
    if (eventSearchData.category === "") {
      setCategory("");
      setText(`${constants.HomeTextFeilds.selectCategory}`);
    }
  }, [eventSearchData]);

  useEffect(() => {
    const listener = (event) => {
      if (loginPopUp) {
        if (event.code === "Enter" || event.code === "NumpadEnter") {
          if (searchItem !== "" || category !== "" || latlLong.lat !== null) {
            GetEventData(event);
          }
        }
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [category, searchItem, latlLong]);

  const GetEventData = async (e) => {
    e.preventDefault();
    const searchData = {
      radius: "30",
      page: 1,
      rowOfPages: 10,
      category: category,
      searchItem: searchItem,
      totalCounts: "",
      categoryName: text,
    };

    dispatch(EventSearch(searchData));
    setIsFirstRender(true);
    dispatch(
      LatLongData({
        lat: latlLong.lat,
        lng: latlLong.lng,
      })
    );
    // dispatch(SyncLatLongDataV2(latlong));
    let eventData = {
      Latitude: latlLong?.lat,
      Longitude: latlLong?.lng,
      Radius: eventSearchData.radius,
      SearchTerm: searchItem,
      UserId: isLoggedIn ? results.ProfileDetail.userID : 0,
      CategoryIds: category,
      PageNumber: eventSearchData.page,
      RowsOfPage: eventSearchData.rowOfPages,
    };

    dispatch(GetAllEvents(eventData))
      .then((data) => {
        dispatch({ type: "EVENTS_TOTAL_COUNT", payload: data.TotalCounts });
        dispatch({
          type: "CARD_DATA",
          payload: data.EventList,
        });
      })
      .catch((err) => console.error(err));
    scrollToTop();
  };

  const scrollToTop = () => {
    window.scroll({
      top: document.body.offsetHeight / 3,
      left: 0,
      behavior: "smooth",
    });
  };
  const onKeyDownHandler = (e) => {
    if (e.keyCode === 13) {
      GetEventData(e);
    }
  };
  return (
    <form onKeyDown={onKeyDownHandler}>
      <div class="button-group-field">
        <div class="before__Search">
          <span className="search__Icon">
            <IoSearchOutline className="revers" size={20} color="#e86c60" />
          </span>

          <input
            style={{ fontSize: 16 }}
            onChange={(e) => setSearchItem(e.target.value)}
            value={searchItem}
            type="search"
            name=""
            id=""
            placeholder={constants.HomeTextFeilds.entername}
            class="search__Input"
          />
        </div>

        <div className="select_option">
          <span className="search__Icon">
            <CgNotes className="revers-up" size={20} color="#e86c60" />
          </span>

          <div onClick={() => setActive(!active)} className="select__Input">
            <div className="selected_text_container">
              <p className="selected_text" style={{ margin: 0, fontSize: 16 }}>
                {text}
              </p>
            </div>

            {active && (
              <div
                onKeyDown={onKeyDownHandler}
                ref={ref}
                className="menu_options Menu-list-modal"
              >
                {catData?.map((opt, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      setText(opt.CategoryName);
                      setCategory(opt.CategoryId);
                      setActive(false);
                    }}
                    className="span"
                  >
                    <h5>{opt.CategoryName}</h5>
                  </div>
                ))}
              </div>
            )}
            <span
              onClick={(e) => {
                e.stopPropagation();
                if (category !== "") {
                  setCategory("");
                  setText(`${constants.HomeTextFeilds.selectCategory}`);
                }
              }}
              className="search_drop_Icon"
            >
              {category !== "" ? (
                <IoCloseCircleSharp size={25} color="#808080" />
              ) : (
                <>
                  {active ? (
                    <MdArrowDropUp size={20} color="#808080" />
                  ) : (
                    <MdArrowDropDown size={20} color="#808080" />
                  )}
                </>
              )}
            </span>
          </div>
        </div>

        <div onKeyDown={onKeyDownHandler} className="select_option">
          <span className="search__Icon">
            <IoLocationOutline size={20} color="#e86c60" />
          </span>

          <AutoCompleteV1
            {...{ styles, selected, setSelected, setLatlLong, isFirstRender }}
          />
        </div>

        <Link
          activeClass="active"
          to="main_containerv"
          spy={true}
          smooth={true}
          offset={-60}
          duration={500}
          onClick={GetEventData}
          className="btn__Search"
        >
          {constants.HomeTextFeilds.search}
        </Link>
      </div>
    </form>
  );
};

const mapStateToProps = (state) => {
  return {
    latlong: state.favoriteReducer.latlong,
    isLoggedIn: state.loginResponse.isLoggedIn,
    results: state.loginResponse.results,
    cardData: state.favoriteReducer.cardData,
    eventSearchData: state.eventReducer.EventSearchData,
    loginPopUp: state.loginResponse.loginPopUp,
  };
};
const matchDispatchToProps = (dispatch) => {
  return {
    GetAllEvents: () => dispatch(GetAllEvents),
    LatLongData,

    SyncLatLongDataV2,
    GetAllEventCategories,
    EventSearch,
    dispatch,
  };
};
export default connect(mapStateToProps, matchDispatchToProps)(TextField);
