import React, { Component } from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { Text } from 'Constants';

const styles = {
    eventStatusDropdown:{
        margin: '10px 10px 0px 0px',
        border: '1px solid #e0e0e0',
        width: '220px',
        color: 'grey',
        verticalAlign: 'top',
        height: '45px',
        "@media screen and (max-device-width: 545px)": {
            width:'100%',
            margin: '10px 10px 10px 10px',
        }
    },
    menuItem:   {
        fontSize: '16px'
    },
    eventStatusLabel:   {
        margin: '3px 0px 0px 25px',
        fontSize: '12px'
    }
};

export class EventStatusDropdown extends Component {

    constructor(props)  {
        super(props);
        this.state = {
            name: 'All'
        }
    }

    handleChange = (event) => {
        this.props.onEventStatusDropdownChange(event);
        this.setState({ name: event.target.value });
      };
  
    render() {
        const { classes, title } = this.props;
        return (
            <FormControl className={classes.eventStatusDropdown}>
                <InputLabel className={classes.eventStatusLabel}>{title}</InputLabel>
                <Select
                    value={this.state.name}
                    onChange={this.handleChange}
                    name='All'
                    className={classes.menuItem}
                    disableUnderline
                    SelectDisplayProps={{
                        style: {padding: '5px 0px 5px 25px'}
                    }}
                >
                    <MenuItem
                        value={'All'}
                        className={classes.menuItem}>
                        {Text.All}
                    </MenuItem>
                    <MenuItem
                        value={'Published'}
                        className={classes.menuItem}>
                        {Text.Live}
                    </MenuItem>
                    <MenuItem
                        value={'Draft'}
                        className={classes.menuItem}>
                        {Text.Draft}
                    </MenuItem>
                    <MenuItem
                        value={'Ended'}
                        className={classes.menuItem}>
                        {Text.Ended}
                    </MenuItem>
                    <MenuItem
                        value={'Canceled'}
                        className={classes.menuItem}>
                        {Text.Canceled}
                    </MenuItem>
                </Select>
            </FormControl>
        )
    }
}

export default withStyles(styles)(EventStatusDropdown);
