import React from "react";
import { BsSearch } from "react-icons/bs";
import "./SearchBar.css";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { AiFillCaretDown } from "react-icons/ai";
import EventStatusDropdown from "./attendeeDropdown";
import AttendeeSearchBar from "./attendeeSearchBar";
import { Text } from "Constants";
function SearchBar({
  searchTerm,
  setSearchTerm,
  setSearchBy,
  searchBy,
  handleSearchAttendees,
  handleClearSearch,
  isSearched,
  setIsSearched
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [name, setName] = React.useState("AttendeeName")
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const setCategoryType = (type) => {
    setSearchBy(type);
    setName(type)
    handleClose();
  };

  const ResetSearch = () => {
    handleSearchAttendees(true)
    setName("AttendeeName")
    setSearchTerm("")
    setSearchBy("AttendeeName")
    setIsSearched(false)
  }

  return (
    <div style={{marginTop:50}} className="searchAttendees">
      <div className="searchAttendeesHeader">
        <h2>{Text.SearchAttendees}</h2>
      </div>
      <div className="searchBar">
        <AttendeeSearchBar value={searchTerm} onSearch={(e) => setSearchTerm(e.target.value)}/>
        <div className="search-bar-btn">
          <EventStatusDropdown
            name={name}
            title="Attendee status"
            setCategoryType={setCategoryType}
          />
           <Button
            variant={"contained"}
            id="basic-button"
            // color={"primary"}
            onClick={() => handleSearchAttendees(false)}
            style={{
              fontSize: 16,
              backgroundColor: "#851679",
              fontSize: "16px",
              textTransform: "none",
              height: "50px",
              width: "150px",
              margin: "10px 0px 10px 0px;",
          }}
          >
            {Text.Search}
          </Button>
        </div>
      </div>
      {
        isSearched && <div style={{margin:"10px 0px"}}>
        <span onClick={()=>ResetSearch()}  style={{color:"rgb(0, 102, 255)", fontWeight:500, fontSize:16 , cursor:"pointer" }}>Reset Search & See All Attendees</span>
      </div>
      }
      
    </div>
  );
}

export default SearchBar;
