import React, { Component } from "react";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import CloseIcon from "@material-ui/icons/Close";
import { Box, IconButton } from "@material-ui/core";

export default class CreateEventErrorMessage extends Component {
  constructor(props) {
    super(props);
  }

  componentDidUpdate() {
    const { show } = this.props.state;
    const { close } = this.props;
    const myFun = () => close();
    if (show) {
      setTimeout(() => {
        myFun();
      }, 5000);
    }
  }

  render() {
    const { show, message } = this.props.state;
    const { close } = this.props;
    return (
      <div
        style={{
          display: show ? "block" : "none",
          position: "absolute",

          top: 0,
          right: "0",
          zIndex: "2",
          width: "100%",
        }}
      >
        <Box
          sx={{
            background: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "10px 30px",
            background: "#d90d0d",
            color: "white",
            margin: "0px auto",
            animationName: "slide-from-bottom",
            animationDuration: "1s",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <ErrorOutlineIcon />
            <p
              style={{
                margin: "0 0 0 20px",
                textAlign: "start",
              }}
            >
              {message}
            </p>
          </div>
          <IconButton onClick={close}>
            <CloseIcon height={30} width={30} style={{ color: "white" }} />
          </IconButton>
        </Box>
      </div>
    );
  }
}
