import React, { useEffect, useState } from "react";
import { default as momentTz } from "moment-timezone";
import "./sales.css";
import { BsSearch } from "react-icons/bs";
import { useLayoutEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import SalesTable from "./SalesTable";
import { axiosE } from "Service/service";
import TablePagination from "@mui/material/TablePagination";
import { Parser } from "json2csv";
import moment from "moment/moment";
import { CircularProgress } from "@material-ui/core";
import { Text } from "Constants";

const Sales = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [salesData, setSalesData] = useState(true);
  const [editAttendee, setEditAttendee] = useState(true);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [UserId] = useState(localStorage.getItem("UserId"));
  const [loading, setLoading] = useState(false);
  const [searchBy, setSearchBy] = useState("");
  const [summaryData, setSummaryData] = useState("");
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    GetSalesListData();
    EventsSummeryByOrganizerId();
  }, [page]);

  const EventsSummeryByOrganizerId = async () => {
    try {
      const { data } = await axiosE.get(
        `Sales/EventsSummeryByOrganizerId/${UserId}`
      );
      setSummaryData(JSON.parse(data.results.Data));
    } catch (error) {}
  };

  const GetSalesListData = async () => {
    setLoading(true);
    const payload = {
      OrganizerId: UserId,
      Status: "",
      SearchTerm: searchBy,
      PageNumber: page + 1,
      RowsOfPage: rowsPerPage,
    };

    try {
      const { data } = await axiosE.post(
        "Sales/SearchEventsByOrganizerId",
        payload
      );
      if(data.results.ResponseCode){
        const timeUTCtoLocal = (date, TimeZone) => {
          var local = momentTz
            .tz(moment.utc(date), "MM/DD/YYYY hh:mm:ss a", TimeZone)
            .format("MM/DD/YYYY hh:mm:ss a");
          return moment(local, "MM/DD/YYYY hh:mm:ss a").format(
            "llll"
          );
        };
        const newData = JSON.parse(data.results.Data)?.map((item) => ({
          ...item,
          TicketSold: Intl.NumberFormat().format(item.TicketSold),
          Sale: item.Sale === "0" ? `${item.CurrencySymbol}0` : `${item.CurrencySymbol}${new Intl.NumberFormat(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.Sale)}`,
          // ProcessingFee: item.ProcessingFee === "0" ? "$0" : `$${new Intl.NumberFormat(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.ProcessingFee)}`,
          TaxAmount: item.TaxAmount === "0" ? "-" : `${item.CurrencySymbol}${new Intl.NumberFormat(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.TaxAmount)}`,
          Payout: item.Payout === "0" ? `${item.CurrencySymbol}0` : `${item.CurrencySymbol}${new Intl.NumberFormat(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.Payout)}`,
          UBMeFee:item.UBMeFee === "0" ? `${item.CurrencySymbol}0` : `${item.CurrencySymbol}${new Intl.NumberFormat(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.UBMeFee)}`,
          StartDateTime: timeUTCtoLocal(item.StartDateTime, item.TimeZone),
        }));

        const sortedArray = newData?.sort(
          (a, b) =>
            new moment(b.StartDateTime).format("X") -
            new moment(a.StartDateTime).format("X")
        );

        setSalesData(sortedArray);
        setLoading(false);
      }else{
        setSalesData([])
        setLoading(false);
      }

    } catch (error) {
      console.log("error", error);
      setLoading(false);

    }
  };

  const DownloadEventByEventId = async () => {
    try {
      const { data } = await axiosE.get(
          `Sales/DownloadEventsByOrganizerId/${UserId}`
      );

      const timeUTCtoLocal = (date, TimeZone) => {
        var local = momentTz
            .tz(moment.utc(date), "MM/DD/YYYY hh:mm:ss a", TimeZone)
            .format("MM/DD/YYYY hh:mm:ss a");
        return moment(local, "MM/DD/YYYY hh:mm:ss a").format(
            "llll"
        );
      };

      const newData = JSON.parse(data.results.Data)?.map((item) => {
        const { TimeZone, CurrencySymbol, ProcessingFee, CurrencyCode, ...restItem } = item;

        return {
          ...restItem,
          Sale: item.CurrencySymbol + (restItem.Sale === "0" ? 0 : new Intl.NumberFormat(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(restItem.Sale)),
          TaxAmount: item.CurrencySymbol + (restItem.TaxAmount === "0" ? 0 : new Intl.NumberFormat(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(restItem.TaxAmount)),
          Payout: item.CurrencySymbol + (restItem.Payout === "0" ? 0 : new Intl.NumberFormat(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(restItem.Payout)),
          UBMeFee: item.CurrencySymbol + (restItem.UBMeFee === "0" ? 0 : new Intl.NumberFormat(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(restItem.UBMeFee)),
          StartDateTime: timeUTCtoLocal(restItem.StartDateTime, TimeZone),
        };
      });

      newData.sort((a, b) =>
          moment(b.StartDateTime, "llll").diff(
              moment(a.StartDateTime, "llll")
          )
      );

      // Define the desired column order
      const columnOrder = [
        "Event ID",
        "Event Name",
        "Event Start Date",
        "Tickets Holders",
        "Tax / Fee Collected",
        "Total Sales",
        "UBMe Fees",
        "Total Payout"
      ];

      const headerMappings = {
        EventId: "Event ID",
        Title: "Event Name",
        TaxAmount: "Tax / Fee Collected",
        Sale: "Total Sales",
        UBMeFee: "UBMe Fees",
        TicketSold: "Tickets Holders",
        StartDateTime: "Event Start Date",
        Payout: "Total Payout",
        // Add more header mappings if needed
      };

      const renamedData = newData.map((item) => {
        const renamedItem = { ...item };
        for (const key in headerMappings) {
          if (renamedItem.hasOwnProperty(key)) {
            renamedItem[headerMappings[key]] = renamedItem[key];
            delete renamedItem[key];
          }
        }
        return renamedItem;
      });

      // Sort the columns based on the desired order
      const sortedData = renamedData.map((item) => {
        const sortedItem = {};
        columnOrder.forEach((column) => {
          sortedItem[column] = item[column];
        });
        return sortedItem;
      });

      console.log("newData", sortedData);
      const parser = new Parser({ fields: columnOrder });
      let csv = parser.parse(sortedData);

      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

      // Create URL for the Blob
      const url = URL.createObjectURL(blob);

      // Create download link
      const link = document.createElement("a");
      link.href = url;
      link.download = `sales_data_${UserId}_${Date.now()}.csv`;
      document.body.appendChild(link);
      link.click();

      // Cleanup
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      // Handle errors
    }
  };

  function toggleModal() {
    setIsOpen(!isOpen);
  }
  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  }
  const [width, height] = useWindowSize();
  // const classes = useStyles();
  return (
    <div>
      <div className="sales_container">
        <div className="email_invite_wrapper">
          {/* <ShowErrorToast message={error} closeToast={() => setError("")} /> */}
          <div className="email_invite_header">
            <div className="email_invite">
              <span style={{ fontSize: 30 }}>Sales</span>
              <button
                onClick={DownloadEventByEventId}
                className="sales_invite_button"
              >
                {Text.Export}
              </button>
            </div>
          </div>
        </div>
        <div className="sales_searchBar">
          <div className="sales_searchInput">
            <input
              type="text"
              value={searchBy}
              onChange={(e) => setSearchBy(e.target.value)}
              placeholder="Search for event by name"
            />
            <BsSearch />
          </div>
          <button onClick={GetSalesListData} className="search_invite_button">
            {Text.Search}
          </button>
        </div>

        {/*<div className="sales_card_wrapper">*/}
        {/*  <div className="sales_card">*/}
        {/*    <p style={{ marginBottom: 5 }}>{Text.TotalSales}</p>*/}
        {/*    <h4 style={{ fontWeight: 600 }}>*/}
        {/*      ${Number(summaryData?.Sale) ? Intl.NumberFormat(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(summaryData?.Sale) : 0}*/}
        {/*    </h4>*/}
        {/*  </div>*/}
        {/*  <div className="sales_card">*/}
        {/*    <p style={{ marginBottom: 5 }}>{Text.TotalPayout}</p>*/}
        {/*    <h4 style={{ fontWeight: 600 }}>*/}
        {/*      $*/}
        {/*      {Number(summaryData?.Payout)*/}
        {/*        ? Intl.NumberFormat(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(summaryData?.Payout)*/}
        {/*        : 0}*/}
        {/*    </h4>*/}
        {/*  </div>*/}
        {/*</div>*/}

        {loading ? (
          <div
            style={{
              width: "100%",
              height: "200px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress size={50} color="secondary" />
          </div>
        ) : (
          <div>
            {salesData?.length > 0 ? (
              <div>
                <SalesTable salesData={salesData} />
                <div className="custom-pagination">
          <TablePagination
            size={50}
            rowsPerPageOptions={[]}
            component="div"
            count={
              Number(salesData[0]?.TotalRows)
                ? Number(salesData[0]?.TotalRows)
                : 0
            }
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            showFirstButton
            showLastButton
          />
        </div>
              </div>
            ) : (
              <div
                style={{
                  width: "100%",
                  height: "30vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h1 style={{ color: "#808080", textAlign: "center" }}>
                  {Text.NoSales}
                </h1>
              </div>
            )}
          </div>
        )}

      </div>
    </div>
  );
};

export default Sales;
