import React, { Component } from "react";
import {
  Button,
  Card,
  IconButton,
  withStyles,
  TextField,
  Grid,
  Modal,
  Box,
  styled,
} from "@material-ui/core";
import {
  Close,
  Create,
  Delete,
  Info,
  MonetizationOn,
  ThreeSixty,
} from "@material-ui/icons";
import ContentGrid from "../../../../Helper/gridWrapper/gridWrapper";
import EventHeader from "./eventHeader";
import converter from "../../../../Helper/convertFile";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  addSponsor as addSponsorServer,
  deleteSponsor as deleteSponsorServer,
  updateSponsor as updateSponsorServer,
} from "../../../../Actions/eventActions";
import { eventApis, Events, Text } from "../../../../Constants";
import Loader from "../../../../Helper/loader/loader";
import AddIcon from "@material-ui/icons/Add";
import { reusableClasses } from "./resusableClasses";
import CreateEventErrorMessage from "../createEventErrorMessage";
import { checkIfEventExists } from "../../../../Helper/Validations";
import { axiosE } from "../../../../Service/service";
const styles = {
  createEventSponsorsGrid: {
    position: "relative",
  },
  createEventSponsorsVerticalLine: {
    margin: "57px 0px 0px 0px",
    "@media screen and (max-device-width:450px)": {
      display: "none",
    },
  },
  createEventSponsorsVerticalLineWithCirclesWrapper: {
    margin: "42px 0px 0px 0px",
    "@media screen and (max-device-width:450px)": {
      display: "none",
    },
  },
  createEventSponsorsCircleOnVerticalLine: {
    backgroundColor: "rgb(182, 14, 181)",
    height: "15px",
    width: "15px",
    margin: "5px 0px 234px -9px",
    borderRadius: "50%",
  },
  createEventSponsorsWrapper: {
    ...reusableClasses.mainWrapper,
  },
  createEventAgendaAddSessionDaysButtonWrapper: {
    ...reusableClasses.addButtonWrapper,
  },
  createEventAgendaAddSessionDaysButton: {
    ...reusableClasses.addButton,
  },
  createEventAgendaAddSessionDaysButtonText: {
    ...reusableClasses.addButtonText,
  },
  createEventAdPlusIcon: {
    ...reusableClasses.addButtonIcon,
  },
  createEventAdsEmptyScreenWrapper: {
    width: "100%",
    margin: "auto 10px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    opacity: 0.3,
  },
  createEventSponsorsFormWrapper: {
    width: "100%",
  },
  createEventSponsorsTierHeader: {
    margin: "0px 0px 0px 10px",
    fontSize: "18px",
  },
  createEventSponsorsTierSectionWrapper: {
    display: "flex",
  },
  createEventSponsorsAddLogoButton: {
    border: "dotted #ced4da",
    borderBottom: "8px solid #579cfc",
    margin: "25px 0px 0px 10px",
    height: "80px",
    width: "100px",
    color: "black",

    fontSize: "14px",
    textTransform: "none",
  },
  createEventSponsorsTierTextFieldArea: {
    display: "grid",
    margin: "10px 0px 0px 30px",
    width: "100%",
  },
  createEventSponsorsAddSponsorButton: {
    backgroundColor: "rgb(0, 185, 255)",
    margin: "20px 0px 10px 20px",
    color: "white",
    fontFamily:
      "HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif",
    fontSize: "14px",
    textTransform: "none",
    width: "auto",
    padding: "10px 25px 10px 25px",
  },
  createEventSponsorsAddSponsorTextField: {
    borderBottom: "1px solid rgb(200, 200, 200)",
    borderRadius: "5px",
    fontSize: "16px",
    margin: "10px 0px 10px 0px",
    width: "auto",
  },
  createEventSponsorsInputText: {
    fontSize: "16px",
  },
  createEventSponsorsAddedSponsorsCard: {
    ...reusableClasses.card,
  },
  createEventSponsorsAddedSponsorsSponsorLogo: {
    height: "60px",
    width: "70px",
    alignSelf: "center",
  },
  createEventSponsorsVerticalLineDivider: {
    borderLeft: "3px solid lightgray",
    margin: "0px 5px 0px 5px",
    display: "grid",
    width: "100%",
    height: "52px",
  },
  createEventSponsorsAddedSponsorsCardTextFieldWrapper: {
    margin: "0px 0px 0px 5px",
    width: "100%",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",

    "& > p": {
      margin: "0px",
    },
    "& > a > p": {
      margin: 0,
    },
  },
  createEventSponsorsAddedSponsorsCardTextField: {
    fontSize: "16px",
    width: "auto",
    display: "-webkit-box",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
  },
  createEventSponsorsAddedSponsorsCardEmailTextField: {
    fontSize: "14px",
    width: "auto",
    color: "blue",
    textDecoration: "none",
    fontStyle: "none",
    display: "-webkit-box",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
  },
  createEventSponsorsAddedSponsorsCardEditDeleteWrapper: {
    margin: "0px 0px 0px 10px",
    justifyContent: "flex-end",
    alignSelf: "center",
    display: "flex",
  },
  createEventSponsorsButtonWrapper: {
    ...reusableClasses.addOrUpdateModalHeaderButtonWrapper,
  },
  createEventSponsorSaveAndContinueButton: {
    ...reusableClasses.saveAndContinueButton,
  },
  createEventSponsorImage: {
    margin: "25px 0px 0px 10px",
    height: "80px",
    width: "100px",
  },
  createEventSponsorModal: {
    ...reusableClasses.addOrUpdateModalMainWrapperInner,
  },
  createEventFAQModalHeader: {
    ...reusableClasses.addOrUpdateModalHeader,

    display: "flex",
    alignItems: "center",
    paddingBottom: "10px",
    borderBottom: "1px solid #F5F5F5",
    marginBottom: "30px",
  },
  createEventSponsorAddSponsorButton: {
    ...reusableClasses.addOrUpdateModalHeaderButtonSave,
    "@media (max-width: 410px)": {
      width: "100px",
    },
  },
  createEventModal: {
    ...reusableClasses.addOrUpdateModalMainWrapperOuter,
  },
  createEventSponsorCancelSponsorButton: {
    ...reusableClasses.addOrUpdateModalHeaderButtonCancel,
    "@media (max-width: 410px)": {
      width: "100px",
    },
  },
  createEventAdDeleteAdModal: {
    width: "430px",
    height: "150px",
    backgroundColor: "white",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "10px",
    padding: "10px",

    "@media (max-width: 540px)": {
      width: "70%",
    },
    "@media (max-width: 410px)": {
      width: "90%",
    },
  },
  createEventFAQModalHeaderText: {
    ...reusableClasses.addOrUpdateModalHeaderText,
  },

  createEventSponsorImgWrapper: {
    width: "100px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100px",
  },
  createEventAdCancelAdButton: {
    fontSize: "10px",
    padding: "10px 25px",
  },
  createEventAdSaveAdButton: {
    fontSize: "10px",
    padding: "10px 25px",
  },
  createEventSponsorImg: {
    width: "30px",
  },
  createEventSponsorsContentWrapper: {
    ...reusableClasses.contentWrapper,
  },
  createEventSponsorsSaveAndConButtonWrapper: {
    ...reusableClasses.saveAndContinueButtonWrapper,
  },
  sponsorCardWrapper: {
    ...reusableClasses.cardWrapper,
  },
  verticalLinesWrapper: {
    ...reusableClasses.verticalLineWrapper,
  },
};
const RedAsterisk = styled("span")({
  color: "red",
  position: "absolute",
  left: 275,
});
const pattern = /^(http:\/\/|https:\/\/|www\.)/i;
let UrlError =
  "Please enter a valid URL starting with http://, https://, or www.";
class EventDetailsSponsors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      createAdModal: false,
      sponsors: [],
      currentSponsor: {
        sponsorName: "",
        sponsorWebsite: "",
        sponsorLogo: "",
        showEdit: false,
      },
      verticalLineHeight: "160px",
      deleteSponsorModal: { show: false, sponsorId: "" },
      loader: false,
      urlError: "",
      error: { show: false, message: "" },
    };
  }
  componentDidMount() {
    checkIfEventExists(this.props.history, this.props.eventId);

    this.setState({ loader: true });

    axiosE
      .get(eventApis.getSponsorByEventId + this.props.eventId)
      .then((res) => {
        const sponsors = res.data.results.ListSponsor;
        if (sponsors && sponsors.length > 0) {
          this.setState({
            sponsors: sponsors.map((sponsor) => ({
              SponsorId: sponsor.SponsorId,
              sponsorName: sponsor.Name,
              sponsorWebsite: sponsor.Url,
              sponsorLogo: sponsor.Logo,
              showEdit: false,
            })),
          });
        }
        this.setState({ loader: false });
      });
  }

  showError = (message) => {
    this.setState({
      error: { show: true, message },
    });
  };
  hideError = () => {
    this.setState({
      error: { show: false, message: "" },
    });
  };

  componentDidUpdate() {
    console.log("UPDATE STATE", this.state);
  }
  calculateCircleOnVerticalLineMargin = (index) => {
    const { sponsors } = this.state;
    return sponsors.length > 0
      ? index !== sponsors.length - 1
        ? "82px "
        : "82px "
      : "82px ";
  };
  circleOnVerticalLineStyles = (index) => {
    return {
      backgroundColor: "rgb(182, 14, 181)",
      height: "15px",
      width: "15px",
      margin:
        "0px 0px " + this.calculateCircleOnVerticalLineMargin(index) + "-9px",
      borderRadius: "50%",
      "@media screen and (max-device-width:450px)": {
        display: "none",
      },
    };
  };

  calculateLineHeight = (extraCircles) => {
    const { sponsors } = this.state;
    let extraLineHeight = 0;

    sponsors.forEach((sponsor, index) => (extraLineHeight += 10));
    return sponsors.length > 1 ? 0 + (extraCircles - 1) * 98.8 + "px" : "0px";
  };
  createEventSponsorsVerticalLineStyles = (extraCircles) => {
    return {
      float: "left",
      borderLeft: "2px solid lightgray",
      margin: "46px 0px 0px 30px",
      height: this.calculateLineHeight(extraCircles),
      "@media screen and (maxDeviceWidth:450px)": {
        display: "none",
      },
    };
  };
  verticalLineWithCircles = () => {
    const { classes } = this.props;
    const { sponsors } = this.state;

    let extraCircles = sponsors.map((sponsor, index) => (
      <div key={index} style={this.circleOnVerticalLineStyles(index)}></div>
    ));
    extraCircles = extraCircles.splice(0, extraCircles.length - 1);
    let elements = [];
    elements.push(
      <div className={classes.verticalLinesWrapper}>
        <span
          style={this.createEventSponsorsVerticalLineStyles(sponsors.length)}
          className={classes.createEventSponsorsVerticalLine}
        ></span>
        <div
          className={classes.createEventSponsorsVerticalLineWithCirclesWrapper}
        >
          {extraCircles}
          {sponsors.length > 0 && (
            <div
              style={this.circleOnVerticalLineStyles(sponsors.length - 1)}
            ></div>
          )}
        </div>
      </div>
    );
    return <React.Fragment>{elements}</React.Fragment>;
  };
  addExtraSpaceToBalance = () => {
    return <span style={{ width: "30px", height: "30px" }}></span>;
  };
  onChangeCurrentSponsor = (property, e) => {
    const { currentSponsor } = this.state;

    this.setState({
      currentSponsor: {
        ...currentSponsor,
        [property]: e.currentTarget.value,
      },
    });
  };

  toggleEditSponsor = (index, show) => {
    this.setState({
      sponsors: this.state.sponsors.map((sponsor, i) =>
        i === index
          ? {
              ...this.state.sponsors[i],
              showEdit: show,
            }
          : this.state.sponsors[i]
      ),
    });
  };

  editSponsorForm = (index) => {
    return {
      display: this.state.sponsors[index].showEdit ? "grid" : "none",
      overflow: "auto",
      margin: "20px 0px 0px 0px",
    };
  };

  progressLoader = () => {
    const { loader } = this.state;

    return loader && <Loader />;
  };

  loaderState = (show) => {
    this.setState({ loader: show });
  };

  addSponsor = async () => {
    this.loaderState(true);
    this.setState({ createAdModal: false });
    const { currentSponsor, sponsors } = this.state;
    const SponsorId = await this.addSponsorRemote(currentSponsor);
    this.setState({
      currentSponsor: {
        sponsorName: "",
        sponsorWebsite: "",
        sponsorLogo: "",
        showEdit: false,
      },

      sponsors: [...sponsors, { ...currentSponsor, SponsorId }],
    });
  };

  addSponsorRemote = async (sponsor) => {
    const { addSponsorServer, eventId } = this.props;
    const sponsorRes = await addSponsorServer({
      EventId: eventId,

      Name: sponsor.sponsorName,
      SponsorUrl: sponsor.sponsorWebsite,
      Logo: sponsor.sponsorLogo.split(",")[1],
    }).finally(() => this.loaderState(false));
    return sponsorRes.SponsorId;
  };

  deleteSponsor = () => {
    const { deleteSponsorModal, sponsors } = this.state;
    this.setState({
      sponsors: sponsors.filter(
        (currentSponsor, i) => i !== deleteSponsorModal.sponsorId
      ),
    });
    this.setDeleteModalState(false);

    this.props.deleteSponsorServer({
      SponsorId: sponsors[deleteSponsorModal.sponsorId].SponsorId,
    });
  };
  uploadPicture = async (e, index) => {
    e.preventDefault();
    e.stopPropagation();
    const { currentSponsor } = this.state;

    const file = e.target.files[0];
    if (file !== undefined) {
      if (
        file.type === "image/png" ||
        file.type === "image/jpg" ||
        file.type === "image/jpeg"
      ) {
        if (file.size <= 10 * 1000000) {
          const reader = new FileReader();
          reader.onload = async (event) => {
            const img = new Image();
            img.src = event.target.result;
            img.onload = async () => {
              const maxWidth = 220; // Maximum width for the resized image
              const maxHeight = 140; // Maximum height for the resized image
              let width = img.width;
              let height = img.height;

              // Calculate new width and height to maintain aspect ratio
              if (width > height) {
                if (width > maxWidth) {
                  height *= maxWidth / width;
                  width = maxWidth;
                }
              } else {
                if (height > maxHeight) {
                  width *= maxHeight / height;
                  height = maxHeight;
                }
              }

              // Create a canvas element to draw the resized image
              const canvas = document.createElement('canvas');
              canvas.width = width;
              canvas.height = height;
              const ctx = canvas.getContext('2d');
              ctx.drawImage(img, 0, 0, width, height);

              // Convert the canvas content to base64 encoded data
              const resizedEncodedData = canvas.toDataURL('image/jpeg'); // Change format if needed

              this.setState({
                currentSponsor: { ...currentSponsor, sponsorLogo: resizedEncodedData },
              });
            };
          };
          reader.readAsDataURL(file);

          // const encodedData = await converter.toBase64(file);
          //
          // this.setState({
          //   currentSponsor: { ...currentSponsor, sponsorLogo: encodedData },
          // });
        } else {
          this.showError("The maximum size allowed is 2MB.");
        }
      } else {
        this.showError("Invalid file type");
      }
    } else {
      this.showError("User didn't upload anything");
    }
  };

  getSponsorUrl = (url) => {
    if (url.includes("http://") || url.includes("https://")) {
      return url;
    } else {
      return "http://" + url;
    }
  };

  tierSponsorsSection = (title, index) => {
    const { classes } = this.props;
    const { currentSponsor, sponsors } = this.state;

    return (
      <React.Fragment>
        <p className={classes.createEventSponsorsTierHeader}>
          {title + " Sponsor"}
        </p>
        <div className={classes.createEventSponsorsTierSectionWrapper}>
          <input
            style={{ display: "none" }}
            id={"sponsorLogo" + index}
            multiple={false}
            type="file"
            accept="image/*"
            onInput={(e) => this.uploadPicture(e, index)}
            onClick={(event) => {
              event.target.value = null;
            }}
          />
          <label htmlFor={"sponsorLogo" + index}>
            {currentSponsor.sponsorLogo ? (
              <img
                className={classes.createEventSponsorImage}
                src={currentSponsor.sponsorLogo}
                component={"span"}
              />
            ) : (
              <Button
                className={classes.createEventSponsorsAddLogoButton}
                variant={"outlined"}
                component={"span"}
              >
                {Text.Logo}
                <sup style={{ fontSize: 16, color: "red" }}>*</sup>
              </Button>
            )}
          </label>
          <div className={classes.createEventSponsorsTierTextFieldArea}>
            <TextField
              className={classes.createEventSponsorsAddSponsorTextField}
              placeholder={"Enter sponsor's name"}
              onChange={(e) =>
                this.onChangeCurrentSponsor("sponsorName", e, index)
              }
              value={currentSponsor.sponsorName}
              InputProps={{
                disableUnderline: true,
                className: classes.createEventSponsorsInputText,
                startAdornment: currentSponsor.sponsorName === "" && (
                  <RedAsterisk style={{ left: 160 }}>*</RedAsterisk>
                ),
              }}
            />

            <TextField
              className={classes.createEventSponsorsAddSponsorTextField}
              placeholder={"Enter sponsor's website"}
              onChange={(e) =>
                this.onChangeCurrentSponsor("sponsorWebsite", e, index)
              }
              value={currentSponsor.sponsorWebsite}
              InputProps={{
                disableUnderline: true,
                className: classes.createEventSponsorsInputText,
                startAdornment: currentSponsor.sponsorWebsite === "" && (
                  <RedAsterisk style={{ left: 175 }}>*</RedAsterisk>
                ),
              }}
            />
            {currentSponsor.sponsorWebsite !== "" && (
              <p style={{ color: "red" }}>
                {!pattern.test(currentSponsor.sponsorWebsite) ? UrlError : null}
              </p>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  };

  updateRemoteSponsor = () => {
    const { currentSponsor } = this.state;

    this.props
      .updateSponsorServer({
        SponsorId: currentSponsor.SponsorId,
        Name: currentSponsor.sponsorName,
        SponsorUrl: currentSponsor.sponsorWebsite,
        Logo: currentSponsor.sponsorLogo.split(",")[1],
      })
      .finally(() => this.loaderState(false));
  };

  editModalToggle = (index, show, save) => {
    const currentDefault = {
      sponsorName: "",
      sponsorWebsite: "",
      sponsorLogo: "",
      showEdit: false,
    };

    this.setState({
      error: { show: false, message: "" },
    });

    if (show && !save) {
      this.setState({
        currentSponsor: { ...this.state.sponsors[index] },
      });
      this.toggleEditSponsor(index, show);
    } else if (!show && save) {
      this.loaderState(true);
      this.updateRemoteSponsor();
      this.setState({
        sponsors: this.state.sponsors.map((sponsor, i) =>
          i === index
            ? {
                ...this.state.currentSponsor,
                showEdit: false,
              }
            : sponsor
        ),
        currentSponsor: {
          ...currentDefault,
        },
      });
    } else if (!show && !save) {
      this.setState({
        currentSponsor: { ...currentDefault },
      });
      this.toggleEditSponsor(index, show);
    }
  };

  addedSponsors = () => {
    const { classes } = this.props;
    const { sponsors, currentSponsor } = this.state;
    return (
      <div>
        {sponsors.map((sponsor, i) => {
          return (
            <React.Fragment key={i}>
              <Card className={classes.createEventSponsorsAddedSponsorsCard}>
                <div>
                  <img
                    className={
                      classes.createEventSponsorsAddedSponsorsSponsorLogo
                    }
                    src={sponsor.sponsorLogo}
                  />
                </div>
                <div className={classes.createEventSponsorsVerticalLineDivider}>
                  <div
                    className={
                      classes.createEventSponsorsAddedSponsorsCardTextFieldWrapper
                    }
                  >
                    <p
                      className={
                        classes.createEventSponsorsAddedSponsorsCardTextField
                      }
                    >
                      {sponsor.sponsorName}
                    </p>
                    <a
                      href={this.getSponsorUrl(sponsor.sponsorWebsite)}
                      target="_blank"
                    >
                      <p
                        className={
                          classes.createEventSponsorsAddedSponsorsCardEmailTextField
                        }
                      >
                        {sponsor.sponsorWebsite}
                      </p>
                    </a>
                  </div>
                </div>

                <div
                  className={
                    classes.createEventSponsorsAddedSponsorsCardEditDeleteWrapper
                  }
                >
                  <IconButton
                    onClick={() => this.editModalToggle(i, true, false)}
                  >
                    <Create />
                  </IconButton>
                  <IconButton onClick={() => this.setDeleteModalState(true, i)}>
                    <Delete />
                  </IconButton>
                </div>
              </Card>
              <Modal
                open={this.state.sponsors[i].showEdit}
                onClose={() => this.editModalToggle(i, false, false)}
              >
                <div className={classes.createEventModal}>
                  <div className={classes.createEventSponsorModal}>
                    <div>
                      <div className={classes.createEventFAQModalHeader}>
                        <IconButton
                          onClick={() => this.editModalToggle(i, false, false)}
                        >
                          <Close height={30} width={30} />
                        </IconButton>
                        <p className={classes.createEventFAQModalHeaderText}>
                          Edit Sponsor
                        </p>

                        {this.addExtraSpaceToBalance()}
                      </div>
                      {this.tierSponsorsSection("Edit", i)}
                    </div>

                    <div
                      className={classes.createEventSponsorsButtonWrapper}
                      id="eventCommonModal"
                    >
                      <Button
                        className={
                          classes.createEventSponsorCancelSponsorButton
                        }
                        onClick={() => this.editModalToggle(i, false, false)}
                      >
                        {Text.Cancel}
                      </Button>
                      <Button
                        className={classes.createEventSponsorAddSponsorButton}
                        onClick={() => this.editModalToggle(i, false, true)}
                        variant={"contained"}
                        disabled={
                          !(
                            this.state.currentSponsor.sponsorName.trim() &&
                            this.state.currentSponsor.sponsorLogo &&
                            this.state.currentSponsor.sponsorWebsite.trim() &&
                            pattern.test(currentSponsor.sponsorWebsite)
                          )
                        }
                        style={{
                          backgroundColor: !(
                            this.state.currentSponsor.sponsorName.trim() &&
                            this.state.currentSponsor.sponsorLogo &&
                            this.state.currentSponsor.sponsorWebsite.trim() &&
                            pattern.test(currentSponsor.sponsorWebsite)
                          )
                            ? "rgba(163, 56, 164,.4)"
                            : "rgb(163, 56, 164)",
                          color: "white",
                        }}
                      >
                        {Text.Save}
                      </Button>
                    </div>
                  </div>
                  <CreateEventErrorMessage
                    state={this.state.error}
                    close={this.hideError}
                  />
                </div>
              </Modal>
            </React.Fragment>
          );
        })}
      </div>
    );
  };

  setDeleteModalState = (openModal, id) => {
    if (openModal) {
      this.setState({
        deleteSponsorModal: {
          show: true,
          sponsorId: id,
        },
      });
    } else if (!openModal) {
      this.setState({
        deleteSponsorModal: { show: false, sponsorId: "" },
      });
    }
  };
  sessionDeleteModal = () => {
    const { classes } = this.props;
    const { show, sponsor } = this.state.deleteSponsorModal;
    const deleteSponsor = Events.deleteAd("Sponsor");
    return (
      <Modal open={show} onClose={() => this.setDeleteModalState(false)}>
        <Box className={classes.createEventAdDeleteAdModal}>
          <div className={classes.createEventSponsorImgWrapper}>
            <img
              src={deleteSponsor.img}
              alt="Delete Icon"
              className={classes.createEventSponsorImg}
            />
          </div>
          <p style={{ textAlign: "center" }}>{deleteSponsor.message}</p>
          <div>
            <Button
              className={classes.createEventAdCancelAdButton}
              onClick={() => this.setDeleteModalState(false)}
              style={{ color: "blue" }}
            >
              {Text.Cancel}
            </Button>

            <Button
              className={classes.createEventAdSaveAdButton}
              onClick={() => this.deleteSponsor(sponsor)}
              style={{ color: "red" }}
            >
              {Text.Delete}
            </Button>
          </div>
        </Box>
      </Modal>
    );
  };
  handCreateAdModal = (openORclose) => {
    this.setState({
      createAdModal: openORclose,
      error: { show: false, message: "" },
    });
  };

  emptyAddsScreen = () => {
    const { classes } = this.props;
    return (
      <div className={classes.createEventAdsEmptyScreenWrapper}>
        <img
          src={Events.Sponsors.emptySponsor.img}
          alt="Empty Ad"
          style={{ width: "150px" }}
        />
        <h1>{Events.Sponsors.emptySponsor.headline}</h1>
        <p style={{ width: "60%" }}>{Events.Sponsors.emptySponsor.message}</p>
      </div>
    );
  };

  ShowAddedAds = () => {
    const { sponsors } = this.state;
    const { classes } = this.props;

    return sponsors.length > 0 ? (
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "center",
        }}
      >
        {this.verticalLineWithCircles()}
        <div class={classes.sponsorCardWrapper}>{this.addedSponsors()}</div>
      </div>
    ) : (
      this.emptyAddsScreen()
    );
  };

  render() {
    const { classes } = this.props;
    const { allAds, createAdModal, loader } = this.state;
    const { currentSponsor } = this.state;
    return (
      <React.Fragment>
        <div>
          <ContentGrid
            content={
              <Grid className={classes.createEventSponsorsGrid}>
                <section style={{ pointerEvents: loader ? "none" : "auto" }}>
                  <div className={classes.createEventSponsorsWrapper}>
                    <EventHeader title={"Sponsors"}>
                      <div
                        className={
                          classes.createEventAgendaAddSessionDaysButtonWrapper
                        }
                      >
                        <Button
                          className={
                            classes.createEventAgendaAddSessionDaysButton
                          }
                          onClick={() => {
                            this.handCreateAdModal(true);
                          }}
                          variant={"contained"}
                        >
                          <span
                            className={
                              classes.createEventAgendaAddSessionDaysButtonText
                            }
                          >
                            {Text.AddSponsor}
                          </span>
                          <AddIcon className={classes.createEventAdPlusIcon} />
                        </Button>
                      </div>
                    </EventHeader>
                    <div className={classes.createEventSponsorsContentWrapper}>
                      {this.ShowAddedAds()}
                      <div
                        className={
                          classes.createEventSponsorsSaveAndConButtonWrapper
                        }
                      >
                        <Button
                          className={
                            classes.createEventSponsorSaveAndContinueButton
                          }
                          variant={"contained"}
                          onClick={() => {
                            this.props.history.push("Tickets/Create");
                            this.props.sidebarFunctions.setDetail(false);
                            this.props.sidebarFunctions.setTicket(true);
                          }}
                        >
                          {Text.SaveAndContinue}
                        </Button>
                      </div>
                    </div>
                    {this.sessionDeleteModal()}

                    <Modal
                      open={createAdModal}
                      onClose={() => this.handCreateAdModal(false)}
                    >
                      <div className={classes.createEventModal}>
                        <div className={classes.createEventSponsorModal}>
                          <div>
                            <div className={classes.createEventFAQModalHeader}>
                              <IconButton
                                onClick={() => this.handCreateAdModal(false)}
                              >
                                <Close height={30} width={30} />
                              </IconButton>
                              <p
                                className={
                                  classes.createEventFAQModalHeaderText
                                }
                              >
                                {Text.AddSponsor}
                              </p>

                              {this.addExtraSpaceToBalance()}
                            </div>
                            <div
                              className={classes.createEventSponsorsFormWrapper}
                            >
                              {this.tierSponsorsSection("Add", -1)}
                            </div>
                          </div>
                          <div
                            className={classes.createEventSponsorsButtonWrapper}
                            id="eventCommonModal"
                          >
                            <Button
                              className={
                                classes.createEventSponsorCancelSponsorButton
                              }
                              onClick={() => this.handCreateAdModal(false)}
                            >
                              {Text.Cancel}
                            </Button>
                            <Button
                              className={
                                classes.createEventSponsorAddSponsorButton
                              }
                              variant={"contained"}
                              onClick={() => this.addSponsor()}
                              disabled={
                                !(
                                  currentSponsor.sponsorName.trim() &&
                                  currentSponsor.sponsorLogo &&
                                  currentSponsor.sponsorWebsite.trim() &&
                                  pattern.test(currentSponsor.sponsorWebsite)
                                )
                              }
                              style={{
                                backgroundColor: !(
                                  currentSponsor.sponsorName.trim() &&
                                  currentSponsor.sponsorLogo &&
                                  currentSponsor.sponsorWebsite.trim() &&
                                  pattern.test(currentSponsor.sponsorWebsite)
                                )
                                  ? "rgba(163, 56, 164,.4)"
                                  : "rgb(163, 56, 164)",
                                color: "white",
                              }}
                            >
                              {Text.Save}
                            </Button>
                          </div>
                        </div>
                        <CreateEventErrorMessage
                          state={this.state.error}
                          close={this.hideError}
                        />
                      </div>
                    </Modal>
                  </div>
                </section>
                {this.progressLoader()}
              </Grid>
            }
          />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const { eventUserEvents } = state;
  return {
    eventId: eventUserEvents.eventId,
    sponsors: eventUserEvents.sponsors,
    sidebarFunctions: eventUserEvents.sidebarFunctions,
  };
};
const matchDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { addSponsorServer, deleteSponsorServer, updateSponsorServer },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(withStyles(styles)(EventDetailsSponsors));
