import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@material-ui/core";
import React, {Component, useState} from "react";
import { default as Layout } from "./Tickets/layout";
import EventHeader from "./EventDetails/eventHeader";
import { withStyles } from "@material-ui/core";
import { styleClasses } from "./Tickets/ticketsStyleClasses";
import Checkbox from "@material-ui/core/Checkbox";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import FiberManualRecordTwoToneIcon from "@material-ui/icons/FiberManualRecordTwoTone";
import LaunchIcon from "@material-ui/icons/Launch";
import publishImg from "../../../images/CreateEvent/Publish/Publish.png";
import PublishedEventImg from "../../../images/CreateEvent/Publish/Publish-in-green-after-event-is-live.png";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { publishEvent as publishEventAction, setEventStatus } from "../../../Actions/eventActions";
import { checkIfEventExists } from "../../../Helper/Validations";
import { eventApis, Tickets } from "../../../Constants";
import { axiosE } from "../../../Service/service";
import Loader from "../../../Helper/loader/loader";
import ShareModal from "../../UBMeEvents/Components_UBMeEvents/modal/Modal";
import Share from "../../UBMeEvents/Components_UBMeEvents/share/Share";
import * as constants from "../../../Constants";
import {routeName} from "../../../Service/env";

const styles = {
  eventCreateTicketContentWrapper: styleClasses.eventCreateTicketContentWrapper,
  generateHeight: styleClasses.generateHeight,

  // Save And Continue Button Styles
  saveAndContinueButton: styleClasses.saveAndContinueButton,
  saveAndContinueButtonWrapper: styleClasses.saveAndContinueButtonWrapper,

  formControlLabel: {
    marginTop: "10px",
    fontSize: "16px",
  },
  decreaseVisibility: {
    opacity: 0.4,
  },
  publishWrapper: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "20px",
    gap: "20px",
    "@media (max-width: 480px)": {
      marginTop: "0px",
      gap: "15px",

      },
  },
  urlwithButtonBox:{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media (max-width: 480px)": {
    flexDirection:'column'

    },

  },
  buttonStyle: {
    fontSize: "16px",
    position: "relative",
    width: "100px",
    padding: "12px",
    paddingBottom: "12px",
    // margin: "15px",
  },
  Copy: {
    background: "##cedafa",
    color: "#2a5bd7",

    "&:hover": {
      background: "#edf2fe",
    },
  },
  Copied: {
    background: "#649949",
    color: "#fefefe",

    "&:hover": {
      background: "#4a7d4a",
    },
  },
  Share: {
        background: "##cedafa",
        color: "#2a5bd7",
        "&:hover": {
            background: "#edf2fe",
        },
    },
};

class CreateEventPublish extends Component {
  constructor(props) {
    super(props);
    this.state = {
      missingFields: [
        { title: "Tickets", route: "Tickets/Create" },
        { title: "Payout", route: "Tickets/Payouts" },
      ],
      eventType: 0,
      eventPublished: false,
      copyButton1: "Copy",
      copyButton2: "Copy",
      loader: false,
      visible: false,
      shareUrl: "",
    };
  }

  // Define a function to show the share modal
    shareButton = (url) => {
        this.setState({
            visible: true,
            shareUrl: url, // Set the URL to be shared
        });
    };

  // Define a function to close the share modal
  closeModal = () => {
      this.setState({
          visible: false,
          shareUrl: "",
      });
  };

  componentDidMount() {
    checkIfEventExists(this.props.history, this.props.eventId);
    const { eventFields, eventPublish, eventType } = this.props;

    this.setState({
      loader: true,
    });
    axiosE
      .get(eventApis.getEventStatus + this.props.eventId)
      .then(({ data }) => {
        console.log("dataa",data)
        const { Status, BillingAmount, StripeAccountStatus } = data && JSON.parse(
          data?.results?.Data
        );


        let mandatoryFields = [];
        if (BillingAmount === "") {
          mandatoryFields = [
            { title: "Tickets", route: "Tickets/Create" },
            { title: "Payouts", route: "Tickets/Payouts" },
          ];
        } else if (parseInt(BillingAmount) > 0 && StripeAccountStatus === "") {
          mandatoryFields = [{ title: "Payouts", route: "Tickets/Payouts" }];
        } else {
          mandatoryFields = [];
        }
        eventPublish && this.setState({ eventPublished: true });

        this.setState({
          missingFields: mandatoryFields,
          eventPublished: Status === "Published" ? true : false,
        });
      })
      .finally(() => {
        this.setState({
          loader: false,
        });
      });
  }

  progressLoader = () => {
    const { loader } = this.state;

    return loader && <Loader />;
  };

  checkBoxes = () => {
    const { classes } = this.props;
    const labels = Tickets.publish;
    return (
      <FormGroup>
        <FormControlLabel
          onClick={() => this.setState({ eventType: 0 })}
          control={
            <Checkbox
              style={{
                transform: "scale(1.7)",
              }}
              icon={<FiberManualRecordTwoToneIcon />}
              checkedIcon={<FiberManualRecordIcon />}
              checked={this.state.eventType === 0}
            />
          }
          label={
            <Typography className={classes.formControlLabel}>
              {labels.publicEvent} <br />
              <span className={classes.decreaseVisibility}>
                {labels.publicEventDesc}
              </span>
            </Typography>
          }
        />
        <FormControlLabel
          onClick={() => this.setState({ eventType: 1 })}
          control={
            <Checkbox
              style={{
                transform: "scale(1.7)",
              }}
              icon={<FiberManualRecordTwoToneIcon />}
              checkedIcon={<FiberManualRecordIcon />}
              checked={this.state.eventType === 1}
            />
          }
          label={
            <Typography className={classes.formControlLabel}>
              {labels.inviteOnly} <br />
              <span className={classes.decreaseVisibility}>
                {labels.inviteOnlyDesc}
              </span>
            </Typography>
          }
        />
      </FormGroup>
    );
  };

  saveAndContinue = () => {
    const { classes } = this.props;

    return (
      <div className={classes.saveAndContinueButtonWrapper}>
        <Button
          className={classes.saveAndContinueButton}
          variant={"contained"}
          onClick={() => this.props.history.push("EmailInvitation")}
        >
          {Tickets.publish.sendEmail}
        </Button>
      </div>
    );
  };

  missingPagesError = () => {
    const { classes, history } = this.props;
    const { missingFields } = this.state;
    const missingFieldsExists = missingFields.length > 0;
    return (
      missingFieldsExists && (
        <Box
          sx={{
            padding: "30px",
            border: "1px solid",
            borderRadius: "20px",
            fontSize: "16px !important",
            color: "#CA3DD9",

            "& ul": {
              marginLeft: "15px",
              "& li": {
                listStyleType: "decimal",
                "& a": {
                  color: "blue",
                },
                "& *": {
                  fontSize: "14px !important",
                },
              },
            },
          }}
        >
          <p>
            <strong>Status:</strong> you are almost there
          </p>
          <ul>
            {missingFields.map((item, index) => (
              <li key={index} style={{ listStyleType: "decimal" }}>
                <span>{item.title} are missing. </span>
                <a onClick={() => history.push(item.route)}>
                  {item.title === "payouts"
                    ? "Set up payout method."
                    : `Complete ${item.title} section.`}
                </a>
              </li>
            ))}
          </ul>
        </Box>
      )
    );
  };

    copyText = (url, buttonKey) => {
        if (this.state[buttonKey] === "Copy") {
            navigator.clipboard.writeText(url);
            this.setState({ [buttonKey]: "Copied" });
            setTimeout(() => {
                this.setState({ [buttonKey]: "Copy" });
            }, 2000);
        }
    };

  publishedEvent = () => {
    const { classes, eventId } = this.props;
    const labels = Tickets.publish;
    const url =  constants.URLs.hostName+routeName+"/event/"+eventId;
    const checkoutUrl = constants.URLs.hostName+routeName+"/event/"+eventId + "?checkout=true";

    return (
      <>
        {
            this.state.visible && (
                <ShareModal closeModal={this.closeModal}>
                    <Share url={this.state.shareUrl} />
                </ShareModal>
            )
        }

        <div style={{ textAlign: "center" }}>
          <img src={PublishedEventImg} alt="published event" width="100px" />
          <h1>
            <span className={classes.decreaseVisibility}>
              {labels.eventLiveHead}
            </span>{" "}
            <span style={{ color: "green" }}>Live!</span>
          </h1>
          <p className={classes.decreaseVisibility}>
            {labels.liveSubHead1}
          </p>
        </div>
        <div>
            <p style={{ color:"#a338a4",paddingBottom:"5px"}}><strong>Registration Page URL:</strong></p>
            <div
            className={classes.urlwithButtonBox}
            >
              <p
                style={{
                  padding: "15px 20px",
                  border: "1px solid black",
                  borderRadius: "10px",
                  margin: 0,
                  padding:"10px",
                  color: "blue",
                }}
              >
                {url.length >= 30 ? url.slice(0, 30) + "..." : url}
              </p>
                <div style={{display: "flex"}}>
                  <Button
                    onClick={() => this.copyText(url, "copyButton1")}
                    className={`${classes.buttonStyle} ${
                      classes[this.state.copyButton1]
                    }`}
                  >
                    {this.state.copyButton1 === "Copy" ? "Copy" : "Copied!"}
                  </Button>
                  <Button
                   onClick={() => this.shareButton(url)}
                   className={`
                        ${classes.buttonStyle}
                        ${classes.Share}
                   `}
                  >
                   Share
                  </Button>
                </div>
            </div>
        </div>

        <div>
            <p style={{ color:"#a338a4", paddingBottom:"5px"}}><strong>Direct Check-Out URL:</strong></p>

            <div
                className={classes.urlwithButtonBox}
            >
                  <p
                      style={{
                          padding: "15px 20px",
                          border: "1px solid black",
                          borderRadius: "10px",
                          margin: 0,
                          padding:"10px",
                          color: "blue",
                      }}
                  >
                      {checkoutUrl.length >= 30 ? checkoutUrl.slice(0, 30) + "..." : checkoutUrl}
                  </p>
                  <div style={{display: "flex"}}>
                      <Button
                          onClick={() => this.copyText(checkoutUrl, "copyButton2")}
                          className={`${classes.buttonStyle} ${
                              classes[this.state.copyButton2]
                          }`}
                      >
                          {this.state.copyButton2 === "Copy" ? "Copy" : "Copied!"}
                      </Button>
                      <Button
                          onClick={() => this.shareButton(checkoutUrl)}
                          className={`
                            ${classes.buttonStyle}
                            ${classes.Share}
                          `}
                      >
                          Share
                      </Button>
                  </div>
              </div>
          </div>

        <div style={{textAlign: "center"}}>
            <p style={{
                fontWeight:'600',
                textAlign: "center"
            }}>
              {labels.liveSubHead2}
            </p>
            <br/>
            <br/>
            <p style={{
                textAlign: "justify",
                marginLeft: "20px",
                marginBottom: "20px"
            }}>
              1. <a href={`https://www.ubmepremium.com/setup-email-campaigns/`} target="_blank" style={{ color: "blue", textDecoration: "underline" }}>Setup Email Campaigns</a><br/>
              2. <a href={`https://www.ubmepremium.com/add-your-events-to-your-website/`} target="_blank" style={{ color: "blue", textDecoration: "underline" }}>Add Your Events to Your Website</a><br/>
              3. <a href={`https://www.ubmepremium.com/share-on-your-social-media/`} target="_blank" style={{ color: "blue", textDecoration: "underline" }}>Share on Your Social Media</a><br/>
              4. <a href={`https://www.ubmepremium.com/app-engagements/`} target="_blank" style={{ color: "blue", textDecoration: "underline" }}>Activate App Engagements</a><br/>
              5. <a href={`https://www.ubmepremium.com/give-your-marketing-a-boost-2/`} target="_blank" style={{ color: "blue", textDecoration: "underline" }}>Give Your Marketing a Boost</a><br/>
            </p>
        </div>
        <a href={`${url}`} target="_blank" style={{ color: "blue", fontSize:"large" }}>
          {labels.ctaAfterPublish}
          <LaunchIcon
            style={{
              transform: "scale(1.5)",
              marginLeft: "10px",
            }}
          />
        </a>
      </>
    );
  };

  selectEventType = () => {

    const { classes, eventId, eventPublish } = this.props;
    const { missingFields } = this.state;
    const labels = Tickets.publish;
    const url = labels.eventLink + eventId;

    return (
      <>
        <div style={{ textAlign: "center" }}>
          <img
            src={publishImg}
            alt="rocket"
            className={classes.decreaseVisibility}
            width="100px"
          />
          <h1 className={classes.decreaseVisibility}>{labels.headline}</h1>
          <p className={classes.decreaseVisibility}>{labels.subHead}</p>
        </div>
        {this.checkBoxes()}
        {this.missingPagesError()}
        <a href={`${url}`} target="_blank" style={{ color: "blue" }}>
          {labels.ctaBeforePublish}
          <LaunchIcon
            style={{
              transform: "scale(1.5)",
              marginLeft: "10px",
            }}
          />
        </a>
        <Button
          variant="contained"
          color="primary"
          style={{
            padding: "13px 75px",
            fontSize: "14px",
          }}
          onClick={this.handlePublish}
          disabled={missingFields.length > 0}
        >
          {labels.cta2}
        </Button>
      </>
    );
  };

  handlePublish = () => {
    const { publishEventAction, eventId } = this.props;
    const userId = localStorage.getItem("UserId");
    publishEventAction({
      UserId: userId,
      EventId: eventId,
      IsPublic: this.state.eventType === 0 ? true : false,
    }).then((res) => {

      axiosE.get(eventApis.getEventStatus + eventId).then(({ data }) => {

        this.props.setEventStatus(data.results.Data)
        // this.setState({ eventStatus: JSON.parse(data.results.Data) });
      });
      this.setState({
        eventPublished: true,
      });
    });
  };

  render() {
    const { classes } = this.props;
    const { eventPublished } = this.state;
    return (
      <Layout>
        <EventHeader title="Publish" />

        <div
          className={`${classes.eventCreateTicketContentWrapper} ${classes.generateHeight}`}
        >
          <div className={classes.publishWrapper}>
            {eventPublished ? this.publishedEvent() : this.selectEventType()}
          </div>
          {eventPublished && this.saveAndContinue()}
        </div>
        {this.progressLoader()}
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    eventFields: state.eventUserEvents,
    eventId: state.eventUserEvents.eventId,
    eventPublish: state.eventUserEvents.eventPublish,
    eventType: state.eventUserEvents.eventType,
  };
};

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators({ publishEventAction ,setEventStatus}, dispatch);
};

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(withStyles(styles)(CreateEventPublish));
