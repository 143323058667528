import React from "react";
import Sound from "react-sound";
import moment from "moment";
import { Twemoji } from "react-emoji-render";
import Loader from "../../../../Helper/loader/loader";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  selectedChat,
  setScrollFalse,
  increaseMessageIndex,
  readAllMessages,
  setInitial,
  unreadCountToZero,
  getUnreadCount,
} from "../../../../Actions/messagesAction";
import { ws } from "../../../../App";
import { convertUnicode } from "../../../../Helper/methods";

class WebViewChat extends React.Component {
  constructor() {
    super();
    this.state = {
      audioURL: null,
      deleteCount: 0,
      unreadCount: 0,
    };
  }

  componentDidMount() {
    const {
      userData,
      messageData,
      curbsideCommunication,
      curbsideCommunicationUserData,
      curbsideCommunicationSelectedChatIndex,
      setInitial,
    } = this.props;

    const user = userData?.ProfileDetail;
    const { backUpData, recentChat, initialCall } = messageData;

    if (curbsideCommunication) {
      localStorage.setItem(
        "curbsideCommunicationUserId",
        curbsideCommunicationUserData.UserId
      );
      localStorage.setItem(
        "curbsideCommunicationUserName",
        curbsideCommunicationUserData.UserName
      );
      localStorage.setItem(
        "curbsideCommunicationFullName",
        curbsideCommunicationUserData.FullName
      );
      localStorage.setItem(
        "curbsideCommunicationProfileImage",
        curbsideCommunicationUserData.ProfileImage
      );
    }

    if (curbsideCommunicationSelectedChatIndex != -1) {
      messageData.scroll = true;
      this.initialScroll();

      if (ws !== null) {
        ws.json({
          to_user_id: curbsideCommunication
            ? messageData.recentChat[curbsideCommunicationSelectedChatIndex]
                .from_user_id
            : messageData.selectedChat.from_user_id,
          type: "userstatus",
        });
      }
      if (
        curbsideCommunication ||
        (initialCall &&
          initialCall.OneToOne &&
          ((recentChat && !backUpData) || (backUpData && Object.keys(backUpData).length)))
      ) {
        if (ws !== null) {
          ws.json({
            count: 20,
            deleteCount: 0,
            dialog_id: curbsideCommunication
              ? messageData.recentChat[curbsideCommunicationSelectedChatIndex]
                  .dialog_id
              : recentChat[0].dialog_id,
            index: 1,
            type: "chatHistory",
            user_id: user?.UserId,
          });
        }
        this.props.selectedChat(
          recentChat[
            curbsideCommunication ? curbsideCommunicationSelectedChatIndex : 0
          ]
        );
        this.props.increaseMessageIndex(2);
        this.props.readAllMessages(
          recentChat &&
            recentChat[
              curbsideCommunication ? curbsideCommunicationSelectedChatIndex : 0
            ]
        );
      }
    } else {
      let date = new Date();
      if (ws !== null) {
        ws.json({
          type: "getdialog",
          chat_type: "OneToOne",
          user1: user.UserId,
          user2: curbsideCommunicationUserData.UserId,
          msgid: date.getTime().toString().slice(4),
        });
        ws.json({
          to_user_id: curbsideCommunicationUserData.UserId,
          type: "userstatus",
        });
      }
    }
  }

  componentDidUpdate() {
    this.initialScroll();
  }

  componentWillReceiveProps(nextProps) {
    const { chatHistory, recentChat } = nextProps.messageData;
    const { user } = nextProps;
    const userID = localStorage.getItem("UserId");
    const { curbsideCommunication, curbsideCommunicationSelectedChatIndex } =
      this.props;
    if (!curbsideCommunication && chatHistory && chatHistory.length === 0) {
      ws.json({
        count: 20,
        deleteCount: 0,
        dialog_id: curbsideCommunication
          ? recentChat[curbsideCommunicationSelectedChatIndex]?.dialog_id
          : recentChat?.[0].dialog_id,
        index: 1,
        type: "chatHistory",
        user_id: userID,
      });
      nextProps.selectedChat(
        recentChat[
          curbsideCommunication ? curbsideCommunicationSelectedChatIndex : 0
        ]
      );
      nextProps.increaseMessageIndex(2);
      recentChat &&
        nextProps.readAllMessages(
          recentChat[
            curbsideCommunication ? curbsideCommunicationSelectedChatIndex : 0
          ]
        );
    }
  }

  loadMessages = () => {
    const {
      deleteCount,
      messageData,
      messageIndex,
      increaseMessageIndex,
      userData,
      curbsideCommunication,
      curbsideCommunicationSelectedChatIndex,
    } = this.props;
    ws.json({
      count: 20,
      deleteCount: deleteCount,
      dialog_id: curbsideCommunication
        ? messageData.recentChat[curbsideCommunicationSelectedChatIndex]
            .dialog_id
        : messageData.selectedChat.dialog_id,
      index: messageIndex,
      type: "chatHistory",
      user_id: userData?.ProfileDetail.UserId,
    });
    increaseMessageIndex(messageIndex + 1);
  };

  onChatScroll = () => {
    let chatElement = document.getElementById("chatWrapper");
    if (
      chatElement.clientHeight + chatElement.scrollTop <
      chatElement.scrollHeight
    ) {
      let scrollElement = document.getElementById("chatWrapper");
      if (
        scrollElement.clientHeight + scrollElement.scrollTop + 35 >=
        scrollElement.scrollHeight
      ) {
        this.props.webViewFooter.selector.props.hidePopUp();
      }
    }
  };

  initialScroll = () => {
    const {
      chatHistory,
      messageData,
      setScrollFalse,
      curbsideCommunication,
      curbsideCommunicationSelectedChatIndex,
    } = this.props;
    if (
      chatHistory &&
      !(curbsideCommunication && curbsideCommunicationSelectedChatIndex == -1)
    ) {
      if (messageData.scroll) {
        let scrollElement = document.getElementById("chatWrapper");
        scrollElement.scrollTop =
          scrollElement.scrollHeight - scrollElement.clientHeight;
        setScrollFalse();
      }
    }
  };

  render() {
    let { chatHistory, userData, audioURL, curbsideCommunication } = this.props;
    let { selectedChat, totalChat, chatReceived } = this.props.messageData;
    let previousDate = "";
    const user = userData.ProfileDetail;

    return (
      <div
        id="chatWrapper"
        className="chat-wrapper"
        onScroll={this.onChatScroll}
      >
        {chatReceived < totalChat &&
          chatHistory &&
          chatHistory[0] &&
          chatHistory[0].dialog_id === selectedChat.dialog_id && (
            <p onClick={this.loadMessages} className="loadMessages">
              Load Earlier Messages
            </p>
          )}
        {
          // chatHistory || curbsideCommunication ?
          chatHistory === undefined || chatHistory.length === 0 ? (
            <div>
              <h1 className="no-message-display-webview">
                No Messages to display
              </h1>
            </div>
          ) : (
            selectedChat.dialog_id === chatHistory[0].dialog_id &&
            chatHistory &&
            chatHistory?.map((data, index) => {
              let currentDate = moment();
              let stillUTC = moment.utc(data.utc_message_date).toDate();
              let messageDate = moment(stillUTC).local();
              let diff = currentDate.endOf("day").diff(messageDate, "days");
              let display = true;
              if (previousDate === diff) {
                display = false;
              }
              previousDate = diff;
              let res = data?.message_json ?
                /^(http:\/\/\.|https:\/\/\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm.test(
                  JSON.parse(data?.message_json).text
                ) : "";
              return (
                <React.Fragment key={index}>
                  <div key={index} className="chats">
                    {display &&
                      (diff === 1 ? (
                        <p className="date-display">Yesterday</p>
                      ) : diff === 0 ? (
                        <p className="date-display">Today</p>
                      ) : diff <= 7 ? (
                        <p className="date-display">
                          {moment(messageDate).format("dddd")}
                        </p>
                      ) : (
                        <p className="date-display">
                          {moment(messageDate).format("LL")}
                        </p>
                      ))}

                    <div
                      className={
                        Number(data.from_user_id) === user.UserId
                          ? "sender"
                          : "receiver"
                      }
                    >
                      {data.message_json && JSON.parse(data.message_json).text ===
                      "[Sticker message]" ? (
                        JSON.parse(data.message_json).stickerId.split(".")[0] >
                          0 &&
                        JSON.parse(data.message_json).stickerId.split(".")[0] <=
                          30 ? (
                          <div>
                            <img
                              src={require(`../../../../images/Stickers/${
                                JSON.parse(data.message_json).stickerId
                              }`).default}
                              alt="none"
                              height={0}
                              width={0}
                            />
                            <img
                              id="chatImage"
                              src={require("../../../../images/glow.gif").default}
                              alt="stickerImage"
                              height={120}
                              width={120}
                              onLoad={(e) => {
                                e.target.src = require(`../../../../images/Stickers/${
                                  JSON.parse(data.message_json).stickerId
                                }`).default;
                              }}
                              onMouseDown={this.props.MouseDown}
                              onMouseUp={(e) => {
                                this.props.MouseUp(e, data);
                              }}
                            />
                          </div>
                        ) : ( data.message_json &&
                          <div>
                            <img
                              src={`https://d2gnb7jphto2jj.cloudfront.net/ChatImages/Sticker/Images/${
                                JSON.parse(data.message_json).stickerId
                              }`}
                              alt="none"
                              height={0}
                              width={0}
                            />
                            <img
                              id="chatImage"
                              src={require("../../../../images/glow.gif").default}
                              alt="stickerImage"
                              height={150}
                              width={150}
                              onLoad={(e) => {
                                e.target.src = `https://d2gnb7jphto2jj.cloudfront.net/ChatImages/Sticker/Images/${
                                  JSON.parse(data.message_json).stickerId
                                }`;
                              }}
                              onMouseDown={this.props.MouseDown}
                              onMouseUp={(e) => {
                                this.props.MouseUp(e, data);
                              }}
                            />
                          </div>
                        )
                      ) : data.message_json && JSON.parse(data.message_json).text ===
                          "[Picture message]" ||
                        JSON.parse(data.message_json).text ===
                          "[GIF message]" ||
                        JSON.parse(data.message_json).text ===
                          "[Gif message]" ? (
                        JSON.parse(data.message_json)
                          .imgUrl.split(".")
                          .pop() === "gif" ? (
                          <div>
                            <img
                              src={JSON.parse(data.message_json).imgUrl}
                              alt="none"
                              height={150}
                              width={250}
                              onMouseDown={this.props.MouseDown}
                              onMouseUp={(e) => {
                                this.props.MouseUp(e, data);
                              }}
                            />
                          </div>
                        ) : (
                          <div>
                            <img
                              src={JSON.parse(data.message_json).imgUrl}
                              alt="none"
                              height={0}
                              width={0}
                              onLoad={() => {
                                document.getElementById("chatImage").src =
                                  JSON.parse(data.message_json).imgUrl;
                              }}
                            />
                            <img
                              id="chatImage"
                              src={JSON.parse(data.message_json).imgUrl}
                              alt="img"
                              height={150}
                              width={250}
                              onLoad={(e) => {
                                e.target.src = JSON.parse(
                                  data.message_json
                                ).imgUrl;
                              }}
                              onMouseDown={this.props.MouseDown}
                              onMouseUp={(e) => {
                                this.props.MouseUp(e, data);
                              }}
                            />
                          </div>
                        )
                      ) : (
                        <div
                          onMouseDown={this.props.MouseDown}
                          onMouseUp={(e) => {
                            this.props.MouseUp(e, data);
                          }}
                        >
                          <p className="web-view-chat">
                            {!res ? (
                              containsUrl ? (
                                generateHyperlink(
                                  JSON.parse(data.message_json).text
                                )
                              ) : (
                                <Twemoji
                                  text={convertUnicode(
                                    JSON.parse(data.message_json).text
                                  )}
                                />
                              )
                            ) : (
                              generateHyperlink(
                                JSON.parse(data.message_json).text
                              )
                            )}
                          </p>
                        </div>
                      )}
                      <span
                        className={
                          Number(data.from_user_id) === user.UserId
                            ? "time-display text-right"
                            : "time-display text-left"
                        }
                      >{`${moment(moment.utc(messageDate).toDate())
                        .local()
                        .format("LT")}`}</span>
                      {/*${moment(messageDate.toString()).local().format('LT')}*/}
                    </div>
                  </div>
                </React.Fragment>
              );
            })
          )
          // ) : (
          //   <div>
          //     <Loader />
          //   </div>
        }
      </div>
    );
  }
}

function containsUrl(text) {
  const urlPattern = /((http|https):\/\/[^\s]+)|(www.[^\s]+)/g;
  return urlPattern.test(text);
}

function generateHyperlink(text) {
  const urlPattern = /(https?:\/\/[^\s]+)|(www\.[^\s]+)/gi;
  const parts = text.split(urlPattern);

  return (
    <span>
      {parts.map((part, index) => {
        if (urlPattern.test(part)) {
          const url = /^https?:\/\//i.test(part) ? part : `https://${part}`;
          return (
            <a key={index} href={url} target="_blank" rel="noopener noreferrer">
              {part}
            </a>
          );
        } else {
          return part;
        }
      })}
    </span>
  );
}

const mapStateToProps = (state) => {
  return {
    messageData: state.messageReducer,
    userData: state.loginResponse.results,
    chatHistory: state.messageReducer.chatHistory,
    messageIndex: state.messageReducer.messageIndex,
    unreadCount: state.messageReducer.unreadCount,
  };
};

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      selectedChat,
      setScrollFalse,
      setInitial,
      increaseMessageIndex,
      readAllMessages,
      unreadCountToZero,
      getUnreadCount,
    },
    dispatch
  );
};

export default connect(mapStateToProps, matchDispatchToProps)(WebViewChat);
