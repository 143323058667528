import React, { useEffect, useState, useCallback } from "react";
import Grid from "styles/Grid.jsx";
import "./organizerLandingPage.css";
import { GetUserProfileDetail, LoginPopup } from "Actions";
import { AddToFavorite } from "Actions/favoriteAction";
import { connect } from "react-redux";
import * as constants from "Constants";
import { axiosE } from "Service/service";
import axiosI from "Service/service";
import "moment-timezone";
import { CircularProgress } from "@material-ui/core";
import { removeEmptyDialog } from "../../../Actions/messagesAction";
import Modal from "@material-ui/core/Modal";
import Card from "../../../Helper/CardWrapper/cardWrapper";
import WebView from "../../BusinessPortal/Messages/WebView";
import styles from "../Events/EventMap/EventMap.module.css";
import {Text} from "Constants";
import {UserIconsPic} from "../Components_UBMeEvents/Navbar/Alphabets/UserIconsPic";
import {toast, ToastContainer} from "react-toastify";
import {history} from "../../../store";
import {routeName} from "../../../Service/env";
import EventCardWrapper from "../Components_UBMeEvents/EventCardWrapper/EventCardWrapper";
import FavPagiNation from "../Favorites/FavPagination/FavPagiNation";
import Navbar from "../Components_UBMeEvents/Navbar/Navbar";
import Footer from "../Components_UBMeEvents/Footer/Footer";
import moment from "moment";
import "moment-timezone";

const OrganizerLandingPage = ({
                                match,
                                dispatch,
                                isLoggedIn,
                                loginData,
                                favoriteData,
                                messageData,
                                removeEmptyDialog,
                              }) => {

  const organizerId = match.params.organizerId;
  const [allEventData, setAllEventData] = useState([]);
  const [totalCounts, setTotalCounts] = useState("");
  const [loading, setLoading] = useState(false);
  const [userMessagesModalIsOpen, setUserMessagesModalIsOpen] = useState(false);
  const [userMessages, setUserMessages] = useState({});
  const [userProfileData, setUserProfileData] = useState({});
  const [isFollowing, setIsFollowing] = useState(false);
  const [followerCount, setFollowerCount] = useState(0);
  const [pageNO, setPageNo] = useState(1);
  const [page, setPage] = useState(0);
  const [resultsPageNo, setResultsPageNo] = useState(1);
  const [toastMessage, setToastMessage] = useState('');

  useEffect(() => {
    fetchUserProfileData();
  }, [organizerId]);

  useEffect(() => {
    fetchEventData(organizerId, pageNO);
  }, [pageNO]);

  useEffect(() => {
    if (pageNO > 1 && allEventData.length === 0) {
      setPageNo(pageNO - 1);
    }
    if (allEventData.length < 20 && resultsPageNo < page) {
      fetchEventData(organizerId, pageNO);
    }
  }, [allEventData]);

  useEffect(() => {
    const isLogedIn = localStorage.getItem("isLogedIn");
    const pendingAction = localStorage.getItem("organizerLandingPageButtonClick");
    const pendingFollowUnfollowOrganizerCall = localStorage.getItem("pendingFollowUnfollowOrganizerCall");

    if (isLogedIn === "true" && pendingAction !== undefined && pendingAction !== null) {
      fetchUserProfileData().then(response => {
        // Check if the response is successful or has the necessary data
        if (response.ResponseCode) {
          if (pendingAction === "followUnfollow" && pendingFollowUnfollowOrganizerCall === "true") {
            // Perform the follow/unfollow action
            handleFollowClick(response?.ProfileDetail?.Follow === "Follow" ? Text.Unfollow : Text.Follow);
          } else if (pendingAction === "messages") {
            showUserMessagesModal()
          }

          if (localStorage.getItem("organizerLandingPageButtonClick")) {
            localStorage.removeItem("organizerLandingPageButtonClick");
          }

          if (localStorage.getItem("pendingFollowUnfollowOrganizerCall")) {
            localStorage.removeItem("pendingFollowUnfollowOrganizerCall");
          }
        } else {
          // Handle the case where fetchUserProfileData did not return a success
          console.error("Failed to fetch user profile data.");
        }
      }).catch(error => {
        // Handle any errors that occurred during the fetch
        console.error("An error occurred while fetching user profile data:", error);
      });
    }
  }, [isLoggedIn]);  // Assuming you meant to use isLogedIn here

  useEffect(() => {
    if (toastMessage) {
      toast(toastMessage);
      setToastMessage(''); // Clear the toast message after displaying
    }
  }, [toastMessage]);

  const fetchUserProfileData = async () => {
    try {
      const geLocationResponse = await fetch(constants.API.Geolocation_V2);
      const currentIPDataResponse = await geLocationResponse.json();
      const latlong = currentIPDataResponse.loc.split(",");
      const UserDataRequest = {
        UserId: isLoggedIn ? loginData?.ProfileDetail?.UserId : 0,
        OtherUserId: organizerId,
        lat: latlong[0],
        lng: latlong[1],
      };

      const userProfileDataResponse = await dispatch(GetUserProfileDetail(UserDataRequest));
      const response = userProfileDataResponse.results;

      if (response.ResponseCode) {
        setUserProfileData(response);
        setFollowerCount(
            response?.ProfileDetail?.FollowerCount
                ? parseInt(response.ProfileDetail?.FollowerCount, 10)
                : 0
        );

        setIsFollowing(response?.ProfileDetail?.Follow === "Follow");
        setLoading(false);
        return response; // Indicate success
      } else {
        setLoading(false);
        history.replace(`${routeName}/404`);
        return false; // Indicate failure
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching user profile details:", error);
      return false; // Indicate failure
    }
  };

  const fetchEventData = async (organizerId, pageNO) => {
    try {
      const userId = isLoggedIn ? loginData?.ProfileDetail?.UserId : 0;
      const apiRequestData = {
        UserId: userId,
        OrganizerId: organizerId,
        PageNumber: pageNO,
        RowsOfPage: 20,
      };

      const { data } = await axiosE.post(
          constants.API.GetAllPublishedEventsForOrganizerLandingPage,
          apiRequestData
      );

      if (data.results.ResponseCode) {
        const newTimeZoneData = (data.results.EventList).map((item) => {
          const UtcToLocal = moment.utc(item.StartDateTime).local().format("MM/DD/YYYY HH:mm:ss A");
          const convertedTime = moment(UtcToLocal, "MM/DD/YYYY HH:mm:ss A").tz(item.TimeZone);
          return { ...item, StartDateTime: convertedTime };
        });

        const sortedArray = [...newTimeZoneData].sort(
            (a, b) => new moment(a.StartDateTime).format("X") - new moment(b.StartDateTime).format("X")
        );

        setAllEventData(sortedArray);

        setTotalCounts(data.results.TotalCounts);
        calculatePage(data.results.TotalCounts);
        setResultsPageNo(pageNO);
      }
    } catch (error) {
      console.error("Error fetching event data:", error);
    }
  };

  const calculatePage = (n) => {
    let num = Math.ceil(n / 20);
    setPage(num);
  };

  const handleLoginCall = (type) => {
    if (!isLoggedIn) {
      localStorage.setItem("organizerLandingPageButtonClick", type);
      if (type === "followUnfollow") {
        localStorage.setItem("pendingFollowUnfollowOrganizerCall", "true");
      }
      LoginPopupOpen(true);
    }
  };

  // Event handler for follow button click
  const handleFollowClick = async (followUnfollowText) => {
    if (!isLoggedIn) {
      handleLoginCall("followUnfollow");

      return;
    }

    try {
      const { data } = await axiosI.get(
          `FollowAndUnFollowUser/${loginData?.ProfileDetail.UserId}/${userProfileData?.ProfileDetail?.UserId}/${followUnfollowText}`
      );
      if(data.results.ResponseCode){
        // Determine the new state
        const newIsFollowing = followUnfollowText === Text.Follow;

        // Update state
        setIsFollowing(newIsFollowing);
        setFollowerCount(prevFollowerCount =>
            newIsFollowing ? prevFollowerCount + 1 : prevFollowerCount - 1
        );

        // Store the action type to show the toast message after state updates
        setToastMessage(newIsFollowing ? `You are now following ${userProfileData?.ProfileDetail?.FirstName}.` : `You have unfollowed ${userProfileData?.ProfileDetail?.FirstName}.`);
      };
    } catch (error) {
      console.error('Error updating follow status:', error);
    }
  };

  const LoginPopupOpen = (val) => {
    dispatch(LoginPopup({ path: "", value: val }));
  };


  const userMessagesModalStyles = () => {
    return {
      position: "fixed",
      top: window.screen.Height < 1100 ? "10%" : "5%",
      width:
          window.screen.width < 1100
              ? window.screen.width < 850
                  ? "84%"
                  : "55%"
              : "",
      left:
          window.screen.width < 1100
              ? window.screen.width < 850
                  ? "8%"
                  : "22%"
              : "30%",
      right:
          window.screen.width < 1100
              ? window.screen.width < 850
                  ? "8%"
                  : "22%"
              : "30%",
      borderRadius: "8px",
      backgroundColor: "white",
      maxHeight: "80vh", // Set a maximum height for the card
      overflowY: "auto", // Enable vertical scrolling
    };
  };

  const showUserMessagesModal = (userMessages) => {
    setUserMessagesModalIsOpen(true);
  };

  const closeUserMessagesModal = (data) => {
    if (Object.keys(messageData.selectedChat.last_message_json).length == 0) {
      removeEmptyDialog(data);
    }
    localStorage.removeItem("curbsideCommunicationUserId");
    localStorage.removeItem("curbsideCommunicationUserName");
    localStorage.removeItem("curbsideCommunicationFullName");
    localStorage.removeItem("curbsideCommunicationProfileImage");
    localStorage.removeItem("organizerLandingPageButtonClick");

    setUserMessagesModalIsOpen(false);
    setUserMessages({});
  };

  const userMessagesModal = (data) => {
    if (data !== null) {
      const userId = loginData?.ProfileDetail?.UserId;
      let selectedChatIndex = -1;
      if (userMessagesModalIsOpen && messageData) {
        messageData?.recentChat?.forEach((message, index) => {
          if (data.ProfileDetail?.UserId + "" === message.userid) {
            selectedChatIndex = index;
          }
        });
      }
      let showInfo = false;
      const organizerFirstName = data.ProfileDetail ? data.ProfileDetail?.FirstName : '';
      const organizerProfileImage = data.ProfileDetail ? data.ProfileDetail?.ProfileImage : '';

      return (
          // <div style={{backgroundColor:'red', width:'250px', height:'250px'}}>
          //   <h2>MY Title</h2>
          // </div>
          <Modal
              open={userMessagesModalIsOpen}
              onClose={() => closeUserMessagesModal(data)}
          >
            <Card
                CardContentProps={{
                  style: userMessagesModalStyles(),
                }}
                content={
                  <WebView
                      userId={userId}
                      updateState={updateState}
                      showInfo={showInfo}
                      curbsideCommunication={true}
                      curbsideCommunicationUserData={{
                        FullName: organizerFirstName,
                        ProfileImage: organizerProfileImage,
                        // UserName: data.UserName,
                        UserId: data.ProfileDetail?.UserId,
                      }}
                      curbsideCommunicationSelectedChatIndex={selectedChatIndex}
                  />
                }
            />
          </Modal>
      );
    }
  };

  const renderContactButton = () => {
    if (!isLoggedIn || (loginData?.ProfileDetail.UserId !== userProfileData?.ProfileDetail?.UserId)) {
      return (
          <button
              onClick={!isLoggedIn ? () => handleLoginCall("messages") : showUserMessagesModal}
              className={styles.event_map_btn}
              style={{ backgroundColor: "#9b189b"}}
          >
            {Text.Contact}
          </button>
      );
    }
    return null;
  };

  const renderFollowButton = () => {
    // Render the follow button
    if (!isLoggedIn || (userProfileData?.ProfileDetail?.UserId !== loginData?.ProfileDetail?.UserId)) {
      return (
          <button
              onClick={() => handleFollowClick(isFollowing ? Text.Unfollow : Text.Follow)}
              className={styles.event_map_btn}
              style={{ backgroundColor: "#9b189b"}}
          >
            {isFollowing ? Text.Unfollow : Text.Follow}
          </button>
      );
    }

    return null;
  };

  const updateState = (state, value) => {
    this.setState({
      [state]: value,
    });
  };

  const contactButton = renderContactButton();
  const followButton = renderFollowButton();

  return (
      <>
        <ToastContainer
            className="toaster-container"
            position="top-right"
            autoClose={5000}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />

        {userMessagesModalIsOpen ? userMessagesModal(userProfileData) : null}

        {loading ? (
            <div
                style={{
                  height: "100vh",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
            >
              {" "}
              <CircularProgress size={50} color="secondary" />
            </div>
        ) : (
            <div>
              <Navbar />
              <div id="about">
                <Grid>
                  <div style={{
                    position: 'relative',
                    width: '100%',
                    overflow: 'hidden',
                  }}>
                    <div
                        style={{
                          position: 'absolute',
                          height: '100vh',
                          width: '100%',
                          zIndex: -1,
                        }}
                    >
                      <div
                          style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                          }}
                      ></div>
                    </div>
                    <div
                        style={{
                          position: 'relative',
                          zIndex: 0,
                          width: '100%',
                          textAlign: 'center',
                        }}
                    >
                      <div style={{
                        // paddingBottom:'40px'
                      }}>
                        <h2 className={styles.event_map_heading}>
                          {Text.ORGANIZERDETAILS}
                        </h2>
                        <img
                            src={
                              userProfileData?.ProfileDetail?.ProfileImage === null
                                  ? UserIconsPic(userProfileData?.ProfileDetail?.FirstName[0].toUpperCase())
                                  : userProfileData?.ProfileDetail?.ProfileImage
                            }
                            alt=""
                            className={styles.event_map_image}
                            style={{ maxWidth: "100px", height: "100px" }}
                        />
                        <h4 style={{
                          paddingTop:'20px'
                        }}
                        >
                          {followerCount}  {'followers'}
                        </h4>
                        <h3 className={styles.event_map_Organizername}>
                          {`${userProfileData?.ProfileDetail?.FirstName} ${userProfileData?.ProfileDetail?.LastName || ''}`}
                        </h3>
                        <p className={styles.event_map_OrganizerBio}>
                          {userProfileData?.ProfileDetail?.Bio || "I Love UBMe"}
                        </p>
                      </div>

                      <div className={styles.event_map_btn_box}>
                        {contactButton}
                        {contactButton && followButton && <div style={{ padding: '0 10px' }} />}
                        {followButton}
                      </div>
                    </div>
                  </div>
                </Grid>
              </div>
              <hr/>
              <div style={{paddingTop:"10px"}}>
                <h1 style={{marginLeft:"2%", marginBottom:"2%"}}><strong>Upcoming Events</strong></h1>
                <EventCardWrapper
                    results={loginData}
                    LoginPopup={LoginPopupOpen}
                    isLoggedIn={isLoggedIn}
                    data={allEventData}
                    favoriteData={favoriteData}
                    AddFavorite={(item) => {
                      dispatch(AddToFavorite(item));
                    }}
                />
                <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                >
                  {loading ? (
                      <CircularProgress size={50} color={"#ca3dd9"} />
                  ) : (
                      !allEventData.length > 0 && (
                          <div
                              style={{
                                margin: "auto",
                                padding: "100px 0px",
                              }}
                          >
                            <div>
                              <h1
                                  style={{ textAlign: "center", color: "gray", margin: 0 }}
                              >
                                {constants.Text.noUpcomingEventsFound}
                              </h1>
                            </div>
                          </div>
                      )
                  )}
                </div>
                {allEventData.length > 0 && (
                    <div>
                      <div style={{ marginTop: 60 }}>
                        <FavPagiNation
                            pageNO={pageNO}
                            resultsPageNo={resultsPageNo}
                            setPageNo={setPageNo}
                            page={page}
                            length={allEventData.length}
                        />
                      </div>
                    </div>
                )}
              </div>
              <Footer />
            </div>
        )}
      </>
  );
};
const mapStateToProps = (state) => {
  return {
    favoriteData: state.favoriteReducer.favoriteData,
    loginData: state.loginResponse.results,
    isLoggedIn: state.loginResponse.isLoggedIn,
    latlong: state.favoriteReducer.latlong,
    cardData: state.favoriteReducer.cardData,
    messageData: state.messageReducer,
  };
};
const matchDispatchToProps = (dispatch) => {
  return {
    AddToFavorite,
    LoginPopup,
    removeEmptyDialog,
    GetUserProfileDetail,
    dispatch,
  };
};
export default connect(mapStateToProps, matchDispatchToProps)(OrganizerLandingPage);
