import React, { Component } from "react";
import {
  Box,
  Button,
  withStyles,
  Modal,
  Input,
  InputAdornment,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import { Close, MapOutlined } from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";

import moment from "moment";
import TicketLayout, { ContentLayout } from "./layout";
import EventHeader from "../EventDetails/eventHeader";
import { styleClasses } from "./ticketsStyleClasses";
import DateAndTime from "./dateAndTime";
import { bindActionCreators } from "redux";
import {
  addDiscount as addDiscountAction,
  deleteDiscount as deleteDiscountAction,
  updateDiscount as updateDiscountAction,
} from "../../../../Actions/eventActions";
import { connect } from "react-redux";
import { default as momentTz } from "moment-timezone";
import Loader from "../../../../Helper/loader/loader";
import { axiosE } from "../../../../Service/service";
import CreateEventErrorMessage from "../createEventErrorMessage";
import { cloneDeep } from "lodash";
import AddIcon from "@material-ui/icons/Add";
import { eventApis, Events, Text, Tickets } from "../../../../Constants";
import { checkIfEventExists } from "../../../../Helper/Validations";
import TitleWithToolTip from "./TitleWithToolTip";

const styles = {
  eventCreateTicketContentWrapper: styleClasses.eventCreateTicketContentWrapper,
  generateHeight: styleClasses.generateHeight,
  emptyState: styleClasses.emptyState,
  addButton: styleClasses.addButton,
  createButtonAddText: styleClasses.createButtonAddText,
  createButtonPlusIcon: styleClasses.createButtonPlusIcon,
  // Add Modal Styles
  addUpdateModal: styleClasses.addUpdateModal,
  addUpdateModalMainWrapper: styleClasses.addUpdateModalMainWrapper,
  addUpdateModalHeader: styleClasses.addUpdateModalHeader,
  addUpdateModalHeaderText: styleClasses.addUpdateModalHeaderText,
  addUpdateModalButtonWrapper: styleClasses.addUpdateModalButtonWrapper,
  addUpdateModalButtonCancel: styleClasses.addUpdateModalButtonCancel,
  addUpdateModalButtonSave: styleClasses.addUpdateModalButtonSave,
  // Save And Continue Button Styles
  saveAndContinueButton: styleClasses.saveAndContinueButton,
  saveAndContinueButtonWrapper: styleClasses.saveAndContinueButtonWrapper,
  // Main Screen Content Wrapper
  mainContentWrapper: styleClasses.mainContentWrapper,
  // Delete Modal Styles
  deleteModalMainWrapper: styleClasses.deleteModalMainWrapper,
  deleteModalImgWrapper: styleClasses.deleteModalImgWrapper,
  deleteModalImg: styleClasses.deleteModalImg,
  deleteModalSaveAndCancelButton: styleClasses.deleteModalSaveAndCancelButton,

  createEventFAQModal: {
    minHeight: "100vh",
    backgroundColor: "white",
    width: "600px",
    position: "absolute",
    right: 0,
    padding: "20px",

    "@media (max-width: 540px)": {
      width: "100%",
    },
  },
  createEventFAQModalHeader: {
    display: "flex",
    alignItems: "center",
    paddingBottom: "10px",
    borderBottom: "1px solid #F5F5F5",
    marginBottom: "30px",
  },
  createEventFAQFormWrapper: {},
  createFAQModalHeader: {
    margin: "0px",
    color: "#A338A4",
    fontSize: "2.5rem",
    flex: 0.9,
    textAlign: "end",
    marginRight: "40%",
    "@media (max-width: 600px)": {
      textAlign: "start",
      margin: 0,
      marginLeft: "30%",
    },
  },
  createEventFAQGridWrapper: {
    margin: "2px 0px 0px 0px",
    display: "grid",
  },
  createEventFAQLongInputField: {
    border: "1px solid #ced4da",
    borderRadius: "5px",
    fontSize: "16px",
    padding: "10px",
    margin: "15px 0px 20px 0px",
    width: "auto",
  },
  createEventFAQInputLabel: {
    fontSize: "16px",
    fontWeight: "550",
  },
  createEventFAQInputText: {
    fontSize: "16px",
  },
  createEventFAQSubmittedFAQ: {
    borderLeft: "10px solid #00BAFF",
  },
  createEventFAQButtonWrapperModal: {
    textAlign: "center",
    borderTop: "1px solid #ced4da",
    paddingTop: "20px",
    marginTop: "auto",
    marginBottom: "40px",
  },
  createEventFAQCancelFAQButton: {
    fontSize: "1.5rem",
    marginRight: "20px",
    border: "1px solid #968e8e",
    width: "120px",
    height: "50px",
  },
  createEventFAQSaveFAQButton: {
    backgroundColor: "rgb(182, 14, 181)",
    fontSize: "1.5rem !important",
    color: "white",
    borderRadius: "9px",
    width: "120px",
    height: "50px",
  },
  createEventFAQEditAndDeleteFAQButtonWrapper: {
    margin: "0px 0px 0px 5px",
    justifyContent: "flex-end",
    display: "flex",
  },
  createEventFAQEditAndDeleteFAQButton: {
    color: "gray",
    cursor: "pointer",
  },
  cardAvailableFrom: {
    opacity: 0.4,

    "@media (max-width: 750px)": {
      display: "block",
      marginTop: "10px",
    },
  },
  discountsCardWrapper: {
    padding: "20px",
    display: "flex",
    justifyContent: "space-between",
    textAlign: "end",
    "@media (max-width: 480px)": {
      display: "block",
      marginTop: "10px",
      textAlign: "start",
      alignItems: "start",
    },
  },

  discountNameInputHelperText: {
    textAlign: "end",
    margin: 0,
    fontSize: "12px",
    color: "#808080 !important",
  },

  discountNameInputHelperTextwithErr: {
    textAlign: "start",
    margin: 0,
    fontSize: "9px",
  },

  discountsCardWrapperContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "end",
    textAlign: "end",
    "@media (max-width: 480px)": {
      flexDirection: "row-reverse",
      marginTop: "10px",
      textAlign: "start",
      alignItems: "start",
    },
  },
};

let currentDefault = {
  discountName: "",
  discountAmount: "",
  discountPercentage: "",
  available: "",
  minimumQuantity: "",
  all: false,
  categories: {
    "Early Bird": false,
    "General Admission": false,
    VIP: false,
  },
  ticketsTypes: [],
  startDate: moment(),
  endDate: moment(),
  startTime: moment(),
  endTime: moment(),
};

class discounts extends Component {
  constructor(props) {
    super(props);
    const todayDateAndTime = moment();
    this.state = {
      currentDiscount: cloneDeep(currentDefault),
      discounts: [],
      addDiscountModal: false,
      editDiscountModal: { show: false, index: "" },
      deleteModal: { show: false, index: "" },
      errorMessage: "",
      loading: false,
      error: { show: false, message: "" },
      disableButton: false,
      discountAmountError: false,
      discountPercentageError: false,
      availableError: false,
      discountAmountErrorMessage: "",
      discountPercentageErrorMessage: "",
      availableErrorMessage: "",
    };

    // Access props safely
    console.log('Props:', this.props);

    currentDefault = {
      ...currentDefault,
      endDate: this.props && this.props.eventAbout && this.timeUTCtoLocal(this.props.eventAbout.EndDateTime),
      endTime: this.props && this.props.eventAbout && this.timeUTCtoLocal(this.props.eventAbout.EndDateTime),
    };

    // Initialize moment objects in the constructor
    this.state.currentDiscount.startDate = moment();
    this.state.currentDiscount.endDate = this.props && this.props.eventAbout && this.props.eventAbout.EndDateTime;
    this.state.currentDiscount.startTime = moment();
    this.state.currentDiscount.endTime = this.props && this.props.eventAbout && this.props.eventAbout.EndDateTime;
  }

  getTicketsCat = (discounts, isReturn = false) => {
    const { eventId } = this.props;
    axiosE
      .get(`${eventApis.getTicketByEventId}/${eventId}`)
      .then(({ data }) => {
        const Tickets = data.results.ListTicket;
        if (Tickets && Tickets.length > 0) {
          currentDefault = {
            ...currentDefault,
            ticketsTypes: data.results.ListTicket.map((ticket) => {
              return {
                title: ticket.Title,
                type: ticket.TicketType,
                checked: false,
                ticketId: ticket.TicketId,
              };
            }),
          };
          this.setState({
            currentDiscount: cloneDeep(currentDefault),
          });
          const isPaidExits = Tickets.some(
            (ticket) => ticket.TicketType === "1"
          );
          this.GetDiscountByEventId(eventId);
          if (!isPaidExits) {
            this.setState({
              error: {
                show: true,
                message:
                  "You do not have any paid tickets created. Please create paid tickets under Create Tickets tab.",
              },
              disableButton: true,
            });
          }
        } else {
          this.setState({
            error: {
              show: true,
              message:
                "You do not have any tickets created. Please create paid tickets under Create Tickets tab.",
            },
            disableButton: true,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  setDeleteModalState = (show, index) => {
    this.setState({
      deleteModal: { show, index },
    });
  };

  setTicketsFromTicketId = (ticketStr, ticketData) => {
    const ticketsArray = ticketStr.split(",").map((ticket) => ticket.trim());
    const { tickets, eventUserEvents } = this.props;
    const newCat = [];

    const data = ticketData.ticketsTypes?.forEach((ticket) => {
      let ticketId = ticketsArray.includes(ticket.ticketId)
        ? {
            checked: true,
            ticketId: ticket.ticketId,
            title: ticket.title,
            type: ticket.type,
          }
        : {
            checked: false,
            ticketId: ticket.ticketId,
            title: ticket.title,
            type: ticket.type,
          };
      newCat.push(ticketId);
    });

    return newCat;
  };

  checkEventIsExpired = () => {
    const { StartDateTime, EndDateTime } = this.props.eventAbout;

    if (this.timeUTCtoLocal(EndDateTime).isBefore(moment())) {
      this.setState({
        loading: false,
        error: {
          show: true,
          message: "Event Expired! Change event end date or create a new Date",
          disableButton: true,
        },
      });
    }
  };

  GetDiscountByEventId = (eventId) => {
    const userId = localStorage.getItem("UserId");
    axiosE
      .get(`${eventApis.getDiscountByEventId}${eventId}/${userId}`)
      .then((res) => {
        const discounts = res.data.results.ListDiscount;

        if (discounts && discounts.length > 0) {
          this.setState({
            discounts: discounts?.map((discount) => {
              const ticketsTypes = this.setTicketsFromTicketId(
                discount.TicketsId,
                this.state.currentDiscount
              );
              return {
                discountName: discount.DiscountCode,
                discountAmount:
                  discount.DiscountType === "0" ? discount.DiscountAmount : "",
                discountPercentage:
                  discount.DiscountType === "1" ? discount.DiscountAmount : "",
                available: discount.MaxUtilization,
                all: ticketsTypes.every((x) => x.checked),
                ticketsTypes,
                startDate: this.timeUTCtoLocal(discount.ActiveDate),
                endDate: this.timeUTCtoLocal(discount.InActiveDate),
                startTime: this.timeUTCtoLocal(discount.ActiveDate),
                endTime: this.timeUTCtoLocal(discount.InActiveDate),
                DiscountId: discount.DiscountId,
                status: discount.Status,
                ApplyGlobally: discount.ApplyGlobally,
                ImmidiateActive: discount.ImmidiateActive,
                NeverExpires: discount.NeverExpires,
                DiscountCodeUsed: discount.DiscountCodeUsed,
                MinimumQuantity: discount.MinimumQuantity,
                minimumQuantity:
                  discount.MinimumQuantity === ""
                    ? "0"
                    : discount.MinimumQuantity,
              };
            }),
            loading: false,
          });
        } else {
          this.setState({
            loading: false,
          });
        }
      });
  };

  componentDidMount() {
    if (checkIfEventExists(this.props.history, this.props.eventId)) {
      const { eventId } = this.props;

      // Check if event is Expired
      this.checkEventIsExpired();

      this.setState({ loading: true });
      // Get Tickets
      this.getTicketsCat();
      // Start Start/End Time Based on event Start/End Time
      // Get Old Discounts
    }
  }

  setDatesAndTimes = () => {
    const { StartDateTime } = this.props.eventAbout;
    currentDefault = {
      ...currentDefault,
      startDate: this.timeUTCtoLocal(StartDateTime),
      endDate: this.timeUTCtoLocal(StartDateTime),
      startTime: this.timeUTCtoLocal(StartDateTime),
      endTime: this.timeUTCtoLocal(StartDateTime),
    };
  };

  timeUTCtoLocal = (date) => {
    if (this.props.eventAbout) {
      const { TimeZone } = this.props.eventAbout;

      var local = momentTz
        .tz(moment.utc(date), "MM/DD/YYYY hh:mm:ss a", TimeZone)
        .format("MM/DD/YYYY hh:mm:ss a");
      return moment(local, "MM/DD/YYYY hh:mm:ss a");
    }
  };
  deleteDiscount = async () => {
    const { deleteModal } = this.state;
    const { index } = deleteModal;
    const { discounts } = this.state;
    this.setState({ loading: true });
    const { eventId } = this.props;
    const newDiscounts = [...discounts];
    const deleteRemote = await this.deleteDiscountRemote(
      discounts[index].DiscountId
    ).then(() => {
      this.setState({
        discounts: this.state.discounts.filter(
          (d) => d.DiscountId !== discounts[index].DiscountId
        ),
      });
      this.setState({ loading: false });
      // this.GetDiscountByEventId(eventId)
    });

    newDiscounts.splice(index, 1);
    this.setState({
      discounts: newDiscounts,
      deleteModal: { show: false, index: "" },
    });
  };

  deleteDiscountRemote = async (id) => {
    const { deleteDiscountAction } = this.props;
    deleteDiscountAction({
      DiscountId: id,
    });
  };
  toggleAddDiscountModal = () => {
    this.setState({
      addDiscountModal: !this.state.addDiscountModal,
      discountAmountError: false,
      discountPercentageError: false,
      availableError: false,
      discountAmountErrorMessage: "",
      discountPercentageErrorMessage: "",
      availableErrorMessage: "",
    });
  };

  addDiscount = async () => {
    // Fire up the loading spinner
    this.setState({ loading: true });
    const DiscountId = await this.sendDiscountRemote();
    this.setState({
      addDiscountModal: !this.state.addDiscountModal,
      discounts: [
        ...this.state.discounts,
        { ...this.state.currentDiscount, DiscountId },
      ],
      currentDiscount: cloneDeep(currentDefault),
      loading: false,
    });
  };

  categoriesString = (tickets) => {
    let categoriesString = "";
    let checkedList = tickets.filter(
      (ticket) => ticket.checked && ticket.type === "1"
    );
    checkedList.map(
      (ticket, index) =>
        (categoriesString += `${ticket.ticketId}${
          index === checkedList.length - 1 ? "" : ", "
        }`)
    );
    return categoriesString;
  };
  sendDiscountRemote = async () => {
    const { currentDiscount } = this.state;
    const { addDiscountAction, eventId } = this.props;
    const userId = localStorage.getItem("UserId");

    const res = await addDiscountAction({
      EventId: eventId,
      UserId: userId,
      DiscountCode: currentDiscount.discountName,
      ActiveDate: this.getCompositeDateAndTime(
        currentDiscount.startDate,
        currentDiscount.startTime
      ),
      InActiveDate: this.getCompositeDateAndTime(
        currentDiscount.endDate,
        currentDiscount.endTime
      ),
      DiscountAmount:
        currentDiscount.discountAmount || currentDiscount.discountPercentage,
      MaxUtilization: currentDiscount.available,
      TicketsId: this.categoriesString(currentDiscount.ticketsTypes),
      ApplyGlobally: 0,
      ImmidiateActive: 0,
      NeverExpires: 0,
      DiscountType: currentDiscount.discountAmount ? "0" : "1",
      MinimumQuantity:
        currentDiscount.minimumQuantity === ""
          ? "0"
          : currentDiscount.minimumQuantity,
    });

    return res.DiscountId;
  };

  getCompositeDateAndTime = (date, time) => {
    const { TimeZone } = this.props.eventAbout;

    const formattedDate = date.format("DD-MM-YY");
    const formattedTime = time.format("hh:mm:ss a");

    const dateAndTimeString = formattedDate + " " + formattedTime;
    const dateAndTime = moment(dateAndTimeString, "DD-MM-YY hh:mm:ss a");
    var dateTimeInUtc = momentTz
      .tz(dateAndTimeString, "DD-MM-YY hh:mm:ss a", TimeZone)
      .utc()
      .format();

    return dateTimeInUtc;
  };

  componentDidUpdate() {

  }
  saveAndContinue = () => {
    const { classes } = this.props;

    return (
      <div className={classes.saveAndContinueButtonWrapper}>
        <Button
          className={classes.saveAndContinueButton}
          variant={"contained"}
          onClick={() => this.props.history.push("SalesTax")}
        >
          {Text.SaveAndContinue}
        </Button>
      </div>
    );
  };

  combineDateAndTime = (date, time) => {
    const formattedDate = date.format("DD-MM-YY");
    const formattedTime = time.format("hh:mm:ss a");

    const dateAndTime = moment(
      formattedDate + " " + formattedTime,
      "DD-MM-YY hh:mm:ss a"
    );

    return dateAndTime;
  };

  displayDiscount = () => {
    const { classes } = this.props;
    const { discounts } = this.state;

    return (
      <div className={classes.mainContentWrapper}>
        {discounts.map((discount, index) => {
          return (
            <Paper key={index}>
              <div className={classes.discountsCardWrapper}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",
                    alignItems: "start",
                    wordBreak: "break-all",
                  }}
                >
                  <h1>{discount.discountName}</h1>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "15px",
                      alignItems: "start",
                    }}
                  >
                    <span
                      style={{
                        backgroundColor:
                          discount.DiscountId === "" ||
                          discount.status === "Deleted"
                            ? "rgb(255, 48, 48)"
                            : "rgb(182, 14, 181)",
                        color: "white",
                        textAlign: "center",
                        marginRight: "10px",
                        padding: "3px 30px",
                        borderRadius: "12px",
                      }}
                    >
                      {discount.DiscountId === "" ||
                      discount.status === "Deleted"
                        ? "Deleted"
                        : this.combineDateAndTime(
                            discount.startDate,
                            discount.startTime
                          ).isBefore(moment())
                        ? this.combineDateAndTime(
                            discount.endDate,
                            discount.endTime
                          ).isAfter(moment())
                          ? "Active"
                          : "InActive"
                        : "Scheduled"}
                    </span>
                    {discount.status !== "Deleted" ? (
                      <span
                        style={{ opacity: 0.4, textAlign: "start" }}
                        className={classes.cardAvailableFrom}
                      >
                        {this.combineDateAndTime(
                          discount.startDate,
                          discount.startTime
                        ).isBefore(moment())
                          ? ""
                          : `Available from ${discount.startDate.format(
                              "MM/DD/YYYY"
                            )} to ${discount.endDate.format("MM/DD/YYYY")}`}
                      </span>
                    ) : null}
                  </div>
                </Box>
                <div className={classes.discountsCardWrapperContent}>
                  <div
                    className={
                      classes.createEventFAQEditAndDeleteFAQButtonWrapper
                    }
                  >
                    {discount.status === "Deleted" ? (
                      <div></div>
                    ) : (
                      <div>
                        <IconButton
                          className={
                            classes.createEventFAQEditAndDeleteFAQButton
                          }
                          onClick={() => this.setDeleteModalState(true, index)}
                        >
                          <DeleteIcon />
                        </IconButton>
                        <IconButton
                          className={
                            classes.createEventFAQEditAndDeleteFAQButton
                          }
                          onClick={() => this.setEditState(true, index)}
                        >
                          <CreateIcon />
                        </IconButton>
                      </div>
                    )}
                  </div>
                  <div>
                    <Box
                      component={"p"}
                      sx={{
                        margin: "0px",
                        fontSize: "30px",
                        fontWeight: "500",
                        padding: "10px 0px",
                        lineHeight: "35px",
                      }}
                    >
                      {discount.discountAmount
                        ? `${localStorage.getItem("CurrencySymbol")}${Intl.NumberFormat(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(discount.discountAmount)}`
                        : `${Intl.NumberFormat(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format( discount.discountPercentage)}%`}{" "}
                      {Text.OFF}
                    </Box>
                    <div>
                      <Box
                        component={"p"}
                        sx={{ color: "#959595", margin: "0px" }}
                      >
                        {discount.hasOwnProperty("DiscountCodeUsed")
                          ? discount.DiscountCodeUsed
                          : 0}
                        /{discount.available} {Text.TIMESUSED}
                      </Box>
                    </div>
                  </div>
                </div>
              </div>
            </Paper>
          );
        })}
      </div>
    );
  };

  handleInputChange = (e, field) => {
    const { currentDiscount } = this.state;
    const value = e.target.value.trim();
    let newValue = value;
    if (!isNaN(value)) {
      newValue = value >= 0 ? value : 0;
    }
    currentDiscount[field] = newValue;
    if (field === "discountPercentage") {
      currentDiscount["discountPercentage"] =
        newValue == 0 ? "" : Math.min(Math.max(Number(newValue), 0), 100);
    }
    this.setState({ currentDiscount });
  };
  handleCheckboxChange = (event, index) => {
    const { currentDiscount } = this.state;
    const newCategories = [...currentDiscount.ticketsTypes];
    newCategories[index].checked = event.target.checked;

    this.setState({
      currentDiscount: {
        ...currentDiscount,
        ticketsTypes: [...newCategories],
        all: newCategories.every((category) => category.checked),
      },
    });
  };
  handleCheckboxAllChange = (event) => {
    const { currentDiscount } = this.state;
    this.setState({
      currentDiscount: {
        ...currentDiscount,
        all: event.target.checked,
        ticketsTypes: currentDiscount.ticketsTypes.map((ticket) => {
          // if(ticket.type === "1"){
          return { ...ticket, checked: event.target.checked };

          // }
        }),
      },
    });
  };

  emptyPageState = () => {
    const { classes } = this.props;
    const labels = Tickets.discount.emptyDiscount;

    return (
      <div className={classes.emptyState}>
        <img
          src={labels.img}
          alt="empty"
          style={{ objectFit: "contain", width: "150px" }}
        />
        <h1>{labels.headline}</h1>
        <p>{labels.description}</p>
      </div>
    );
  };

  validateDiscountName = (e, name) => {
    const newValue = e.target.value;
    const { currentDiscount } = this.state;

    if (newValue.match("^[A-Za-z0-9_-]*$")) {
      this.handleInputChange(e, name);
      this.setState({
        error: false,
        errorMessage: "",
      });
    } else {
      this.setState({
        error: true,
        errorMessage: Tickets.discount.addDiscount.codeError,
      });
    }
  };

  validateInput = (e, name) => {
    const newValue = e.target.value;

    const { currentDiscount } = this.state;
    if (name === "available") {
      if (newValue > 0) {
        if (
          currentDiscount.hasOwnProperty("DiscountId") &&
          currentDiscount.hasOwnProperty("DiscountCodeUsed")
        ) {
          if (newValue >= currentDiscount.DiscountCodeUsed) {
            this.handleInputChange(e, name);
            this.setState({
              [`${name}Error`]: false,
              [`${name}ErrorMessage`]: "",
            });
          } else {
            this.setState({
              [`${name}Error`]: true,
              [`${name}ErrorMessage`]:
                Tickets.discount.addDiscount.greaterThenUsed,
            });
          }
        } else {
          this.setState({
            [`${name}Error`]: false,
            [`${name}ErrorMessage`]: "",
          });
        }
      } else {
        this.setState({
          [`${name}Error`]: true,
          [`${name}ErrorMessage`]: Tickets.discount.addDiscount.invalidNumber,
        });
      }
    } else {
      if (newValue > 0) {
        this.setState({
          [`${name}Error`]: false,
          [`${name}ErrorMessage`]: "",
        });
      } else {
        this.setState({
          [`${name}Error`]: true,
          [`${name}ErrorMessage`]: Tickets.discount.addDiscount.invalidNumber,
        });
      }
    }
    this.handleInputChange(e, name);
  };

  inputFields = (state) => {
    const { classes } = this.props;
    const {
      discountName,
      discountAmount,
      discountPercentage,
      available,
      all,
      categories,
      startDate,
      endDate,
      startTime,
      endTime,
      ticketsTypes,
      minimumQuantity,
    } = state;
  
    const isPaidExists = ticketsTypes.some((ticket) => ticket.type == "1");


    const labels = Tickets.discount.addDiscount;

    // console.log("DHVANI Currentdefault ", this.timeUTCtoLocal(currentDefault.endDate));
    // console.log("DHVANI Current EndDateTime ", this.state.currentDiscount.endDate && this.timeUTCtoLocal(this.state.currentDiscount.endDate));
    // console.log("DHVANI True/False ", (this.state.currentDiscount.endDate && this.timeUTCtoLocal(this.state.currentDiscount.endDate)).isSame(this.timeUTCtoLocal(currentDefault.endDate)));

    return (
      <Grid className={classes.createEventFAQGridWrapper}>
        <FormControl>
          <span className={classes.createEventFAQInputLabel}>
            {labels.name}
            <span style={{ color: "red" }}> *</span>
          </span>
          <TextField
            required
            className={`${classes.createEventFAQLongInputField} ${classes.createEventFAQSubmittedFAQ}`}
            placeholder={"Enter name of code"}
            margin="normal"
            value={discountName}
            onChange={(e) => this.validateDiscountName(e, "discountName")}
            fullWidth
            InputProps={{
              disableUnderline: true,
              className: classes.createEventFAQInputText,
            }}
            onInput={(e) => {
              e.target.value =
                e.target.value.length > 30
                  ? e.target.value.slice(0, 30)
                  : e.target.value;
            }}
            helperText={
              this.state.errorMessage === ""
                ? `${discountName.length}/30`
                : this.state.errorMessage
            } // error message
            error={this.state.error}
            FormHelperTextProps={{
              className:
                this.state.errorMessage === ""
                  ? classes.discountNameInputHelperText
                  : classes.discountNameInputHelperTextwithErr,
            }}
          />{" "}
        </FormControl>
        <div>
          <span className={classes.createEventFAQInputLabel}>
            {labels.amount}
            <span style={{ color: "red" }}> *</span>
          </span>
          <div
            style={{
              display: "flex",
              gap: "20px",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <TextField
              id="standard-adornment-amount"
              type="number"
              InputProps={{
                startAdornment: (
                  <span style={{ fontSize: "16px", marginRight: "10px" }}>
                    {localStorage.getItem("CurrencySymbol")}
                  </span>
                ),
                disableUnderline: true,
                style: { fontSize: "16px" },
              }}
              onWheel={(e) => e.target.blur()}
              value={discountAmount}
              onKeyDown={(e) =>
                ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
              }
              onChange={(e) =>
                discountPercentage === ""
                  ? this.validateInput(e, "discountAmount")
                  : null
              }
              disableUnderline={true}
              style={{
                border: "1px solid #ced4da",
                margin: "15px 0px 20px 0px",
                padding: "10px",
                width: "100%",
              }}
              helperText={
                this.state.discountAmountErrorMessage
                  ? this.state.discountPercentageErrorMessage
                    ? this.state.discountAmountErrorMessage
                    : ""
                  : ""
              }
              error={
                this.state.discountAmountError
                  ? this.state.discountPercentageError
                    ? true
                    : false
                  : false
              }
            />
            <span style={{ fontSize: "16px" }}>or</span>
            <TextField
              id="standard-adornment-amount"
              type="number"
              InputProps={{
                endAdornment: (
                  <span style={{ fontSize: "16px", marginRight: "10px" }}>
                    %
                  </span>
                ),
                disableUnderline: true,
                style: { fontSize: "16px" },
              }}
              onWheel={(e) => e.target.blur()}
              value={discountPercentage}
              onKeyDown={(e) =>
                ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
              }
              onChange={(e) =>
                discountAmount === ""
                  ? this.validateInput(e, "discountPercentage")
                  : null
              }
              disableUnderline={true}
              style={{
                border: "1px solid #ced4da",
                margin: "15px 0px 20px 0px",
                padding: "10px",
                width: "100%",
              }}
              helperText={
                this.state.discountPercentageErrorMessage
                  ? this.state.discountAmountErrorMessage
                    ? this.state.discountPercentageErrorMessage
                    : ""
                  : ""
              } // error message
              error={
                this.state.discountPercentageError
                  ? this.state.discountAmountError
                    ? true
                    : false
                  : false
              }
            />
          </div>
        </div>
        <FormControl>
          <span className={classes.createEventFAQInputLabel}>
            {labels.number}
            <span style={{ color: "red" }}> *</span>
          </span>

          <TextField
            required
            className={`${classes.createEventFAQLongInputField}`}
            placeholder={"100"}
            margin="normal"
            fullWidth
            type="number"
            value={available}
            onChange={(e) => this.validateInput(e, "available")}
            onKeyDown={(e) =>
              ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
            }
            InputProps={{
              disableUnderline: true,
              className: classes.createEventFAQInputText,
            }}
            onInput={(e) => {
              let isSpecial = !/[*\":<>[\]{}`\\()'=;@%#!^$.]/.test(
                e.target.value
              );
              e.target.value = isSpecial ? e.target.value : available;
            }}
            helperText={this.state.availableErrorMessage} // error message
            error={this.state.availableError}
          />
        </FormControl>

        <FormControl>
          <span className={classes.createEventFAQInputLabel}>
            {labels.required}
            <span>
              <TitleWithToolTip
                tooltipText={
                  "Minimum Ticket # Required To Activate Coupon Code"
                }
              />
            </span>
          </span>

          <TextField
            className={`${classes.createEventFAQLongInputField}`}
            placeholder={"100"}
            margin="normal"
            fullWidth
            type="number"
            value={minimumQuantity}
            onChange={(e) => this.validateInput(e, "minimumQuantity")}
            onKeyDown={(e) =>
              ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
            }
            InputProps={{
              disableUnderline: true,
              className: classes.createEventFAQInputText,
            }}
            onInput={(e) => {
              let isSpecial = !/[*\":<>[\]{}`\\()'=;@%#!^$.]/.test(
                e.target.value
              );
              e.target.value = isSpecial ? e.target.value : minimumQuantity;
            }}
          />
        </FormControl>

        <FormControl>
          <span className={classes.createEventFAQInputLabel}>
            {labels.appliesTo}
            <span style={{ color: "red" }}> *</span>
          </span>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginBottom: "20px",
              "& > label": {
                "& span:nth-child(2)": {
                  fontSize: "16px",
                  marginTop: "9px",
                },
              },
              "& > label:nth-child(1)": {
                borderBottom: "1px solid #ced4da",
                paddingBottom: "8px",
              },
            }}
          >
            {isPaidExists && (
              <FormControlLabel
                value="All Tickets"
                control={
                  <Checkbox
                    style={{
                      transform: "scale(1.7)",
                      marginLeft: "10px",
                      marginTop: "10px",
                      marginRight: "10px",
                    }}
                    checked={ticketsTypes
                      .filter((i) => i.type !== "0")
                      .every((ticket) => ticket.checked)}
                    color="primary"
                    onClick={(e) => this.handleCheckboxAllChange(e)}
                  />
                }
                label="All tickets"
                labelPlacement="end"
              />
            )}
            {ticketsTypes.map((ticket, index) => {
              if (ticket.type === "1") {
                return (
                  <FormControlLabel
                    key={index}
                    value={ticket.title}
                    control={
                      <Checkbox
                        style={{
                          transform: "scale(1.7)",
                          marginLeft: "10px",
                          marginTop: "10px",
                          marginRight: "10px",
                        }}
                        checked={ticket.checked}
                        color="primary"
                        onClick={(e) => this.handleCheckboxChange(e, index)}
                      />
                    }
                    label={ticket.title.toUpperCase()}
                    labelPlacement="end"
                  />
                );
              }
            })}
          </Box>
        </FormControl>
        <FormControl>
          <span className={classes.createEventFAQInputLabel}>
            {labels.validFrom}
            <span style={{ color: "red" }}> *</span>
          </span>

          <DateAndTime
            varStartDate={startDate}
            varEndDate={this.timeUTCtoLocal(endDate)}
            varEndTime={this.timeUTCtoLocal(endTime)}
            // varEndDate={this.state.addDiscountModal ? this.state.currentDiscount.endDate && this.timeUTCtoLocal(this.state.currentDiscount.endDate).isSame(this.timeUTCtoLocal(currentDefault.endDate)) ? (this.timeUTCtoLocal(
            //     this.props.eventAbout && this.props.eventAbout.EndDateTime
            // )) : endDate : endDate}
            // varEndTime={this.state.addDiscountModal ? this.state.currentDiscount.endTime &&  this.timeUTCtoLocal(this.state.currentDiscount.endTime).isSame(this.timeUTCtoLocal(currentDefault.endTime)) ? (this.timeUTCtoLocal(
            //     this.props.eventAbout && this.props.eventAbout.EndDateTime
            // )) : endTime : endTime}
            varStartTime={startTime}
            maxEndDate={this.timeUTCtoLocal(
              this.props.eventAbout && this.props.eventAbout.EndDateTime
            )}
            minDate={this.timeUTCtoLocal(
              this.props.eventAbout && this.props.eventAbout.StartDateTime
            )}
            funcChangeStartDate={this.ChangeStartDate}
            funcChangeEndDate={this.ChangeEndDate}
            funcChangeStartTime={this.ChangeStartTime}
            funcChangeEndTime={this.ChangeEndTime}
          />
        </FormControl>
      </Grid>
    );
  };
  ChangeStartDate = (date) => {
    const completeDate = `${date.day}-${date.month}-${date.year}`;
    const newDate = moment(completeDate, "DD-MM-YYYY");

    const { eventAbout } = this.props;
    const { EndDateTime } = eventAbout;

    const endDate = moment(
      this.timeUTCtoLocal(EndDateTime).format("DD-MM-YYYY"),
      "DD-MM-YYYY"
    );

    if (newDate.isSame(endDate)) {
      this.setState({
        currentDiscount: {
          ...this.state.currentDiscount,
          startDate: newDate,
          endDate: newDate,
          endTime: this.timeUTCtoLocal(EndDateTime),
        },
      });
    } else {
      this.setState({
        currentDiscount: {
          ...this.state.currentDiscount,
          startDate: newDate,
          endDate: newDate,
        },
      });
    }
  };
  ChangeEndDate = (date) => {
    const completeDate = `${date.day}-${date.month}-${date.year}`;
    const newDate = moment(completeDate, "DD-MM-YYYY");

    const { eventAbout } = this.props;
    const { EndDateTime } = eventAbout;

    const endDate = moment(
      this.timeUTCtoLocal(EndDateTime).format("DD-MM-YYYY"),
      "DD-MM-YYYY"
    );

    if (newDate.isSame(endDate)) {
      this.setState({
        currentDiscount: {
          ...this.state.currentDiscount,
          endDate: newDate,
          endTime: this.timeUTCtoLocal(EndDateTime),
        },
      });
    } else {
      this.setState({
        currentDiscount: {
          ...this.state.currentDiscount,
          endDate: newDate,
        },
      });
    }
  };
  ChangeStartTime = (date) => {
    if (date === null || date === "invalid date") {
      this.setState({
        currentDiscount: {
          ...this.state.currentDiscount,
          startTime: moment(),
          endTime: moment(),
        },
      });
    } else {
      this.setState({
        currentDiscount: {
          ...this.state.currentDiscount,
          endTime: moment(date),
          startTime: moment(date),
        },
      });
    }
  };
  ChangeEndTime = (date) => {
    if (date === null || date === "invalid date") {
      this.setState({
        currentDiscount: {
          ...this.state.currentDiscount,
          endTime: moment(),
        },
      });
    } else {
      this.setState({
        currentDiscount: {
          ...this.state.currentDiscount,
          endTime: moment(date),
        },
      });
    }
  };
  setEditState = (show, index = "") => {
    this.setState({
      editDiscountModal: { show: show, index: index },
    });

    //  console.log("{ ...currentDefault }",{ ...this.state.currentDiscount });

    if (show) {
      this.setState({
        currentDiscount: cloneDeep({
          ...this.state.discounts[index],
          ticketsTypes: this.state.discounts[index].ticketsTypes,
        }),
      });
    } else {
      this.setState({
        currentDiscount: { ...currentDefault },
        discountAmountError: false,
        discountPercentageError: false,
        availableError: false,
        discountAmountErrorMessage: "",
        discountPercentageErrorMessage: "",
        availableErrorMessage: "",
      });
    }
  };
  discountAddOrUpdate = () => {
    const { classes } = this.props;
    const { currentDiscount, addDiscountModal, editDiscountModal } = this.state;
    var mandatoryFields = [
      currentDiscount.discountName.length > 0,
      currentDiscount.discountPercentage > 0 ||
        currentDiscount.discountAmount.length > 0,
      currentDiscount.available.length > 0,
      currentDiscount.all ||
        currentDiscount.ticketsTypes.some((ticket) => ticket.checked),
      !this.state.discountAmountError || !this.state.discountPercentageError,
      !this.state.availableError,
    ];

    const labels = Tickets.discount.addDiscount;
    return (
      <Modal
        open={addDiscountModal || editDiscountModal.show}
        onClose={() =>
          editDiscountModal.show
            ? this.setEditState(false)
            : this.toggleAddDiscountModal()
        }
      >
        <Box className={classes.addUpdateModal}>
          <div className={classes.addUpdateModalMainWrapper}>
            <div>
              <div className={classes.addUpdateModalHeader}>
                <IconButton
                  onClick={() =>
                    editDiscountModal.show
                      ? this.setEditState(false)
                      : this.toggleAddDiscountModal()
                  }
                >
                  <Close height={30} width={30} />
                </IconButton>
                <p className={classes.addUpdateModalHeaderText}>
                  {editDiscountModal.show ? "Edit" : "Add"}
                  {labels.headline}
                </p>
              </div>
              <div> {this.inputFields(currentDiscount)}</div>
            </div>

            <div
              className={classes.addUpdateModalButtonWrapper}
              id="eventCommonModal"
            >
              <Button
                className={classes.addUpdateModalButtonCancel}
                onClick={() =>
                  editDiscountModal.show
                    ? this.setEditState(false)
                    : this.toggleAddDiscountModal()
                }
              >
                {Text.Cancel}
              </Button>
              <Button
                className={classes.addUpdateModalButtonSave}
                variant={"contained"}
                onClick={() =>
                  editDiscountModal.show
                    ? this.saveEdited()
                    : this.addDiscount()
                }
                disabled={mandatoryFields.some((field) => field === false)}
                style={{
                  backgroundColor: mandatoryFields.some(
                    (field) => field === false
                  )
                    ? "rgba(163, 56, 164,.4)"
                    : "rgb(163, 56, 164)",
                }}
              >
                {Text.Save}
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    );
  };
  saveEdited = async () => {
    const { editDiscountModal } = this.state;
    // Fire up the loading spinner
    this.setState({ loading: true });

    const DiscountId = await this.updateDiscountRemote();

    this.setState({
      discounts: this.state.discounts.map((discount, i) =>
        i === editDiscountModal.index
          ? {
              ...this.state.currentDiscount,
              DiscountId,
            }
          : discount
      ),
      currentDiscount: cloneDeep(currentDefault),
      editDiscountModal: { show: false, index: "" },
      loading: false,
    });
  };

  updateDiscountRemote = async () => {
    const { currentDiscount } = this.state;
    const { updateDiscountAction, eventId } = this.props;
    const userId = localStorage.getItem("UserId");

    const res = await updateDiscountAction({
      DiscountId: currentDiscount.DiscountId,
      EventId: eventId,
      UserId: userId,
      DiscountCode: currentDiscount.discountName,

      ActiveDate: this.getCompositeDateAndTime(
        currentDiscount.startDate,
        currentDiscount.startTime
      ),
      InActiveDate: this.getCompositeDateAndTime(
        currentDiscount.endDate,
        currentDiscount.endTime
      ),
      DiscountAmount:
        currentDiscount.discountAmount || currentDiscount.discountPercentage,
      MaxUtilization: currentDiscount.available,
      TicketsId: this.categoriesString(currentDiscount.ticketsTypes),
      ApplyGlobally: 0,
      ImmidiateActive: 0,
      NeverExpires: 1,
      DiscountType: currentDiscount.discountAmount ? "0" : "1",
      MinimumQuantity:
        currentDiscount.minimumQuantity === ""
          ? "0"
          : currentDiscount.minimumQuantity,
    });



    return currentDiscount.DiscountId;
  };

  deleteModal = () => {
    const { classes } = this.props;
    const { show } = this.state.deleteModal;
    const labels = Tickets.discount.deleteDiscount;
    return (
      <Modal open={show} onClose={() => this.setDeleteModalState(false)}>
        <Box className={classes.deleteModalMainWrapper}>
          <div className={classes.deleteModalImgWrapper}>
            <img
              src={labels.img}
              alt="Delete Icon"
              className={classes.deleteModalImg}
            />
          </div>
          <p style={{ textAlign: "center" }}>{labels.message}</p>
          <div>
            <Button
              className={classes.deleteModalSaveAndCancelButton}
              onClick={() => this.setDeleteModalState(false)}
              style={{ color: "blue" }}
            >
              {Text.Cancel}
            </Button>

            <Button
              className={classes.deleteModalSaveAndCancelButton}
              onClick={() => this.deleteDiscount()}
              style={{ color: "red" }}
            >
              {Text.Delete}
            </Button>
          </div>
        </Box>
      </Modal>
    );
  };

  progressLoader = () => {
    const { loading } = this.state;

    return loading && <Loader />;
  };

  handleError = () => {
    this.setState({ error: { show: false, message: "" } });
  };

  errorMessage = () => (
    <CreateEventErrorMessage
      state={this.state.error}
      close={this.handleError}
    />
  );

  render() {
    const { classes, discounts } = this.props;
    const { error, disableButton, deleteModal, currentDiscount } = this.state;

    return (
      <TicketLayout>
        <EventHeader title={"Discounts"}>
          {!disableButton && (
            <Button
              onClick={this.toggleAddDiscountModal}
              className={classes.addButton}
            >
              <span className={classes.createButtonAddText}>Add Discount</span>
              <AddIcon className={classes.createButtonPlusIcon} />
            </Button>
          )}
        </EventHeader>
        <ContentLayout>
          {this.state.discounts.length > 0
            ? this.displayDiscount()
            : this.emptyPageState()}
          {this.saveAndContinue()}
        </ContentLayout>
        {this.discountAddOrUpdate()}
        {this.deleteModal()}
        {this.progressLoader()}
        {this.errorMessage()}
      </TicketLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const { eventUserEvents } = state;
  return {
    eventId: eventUserEvents.eventId,
    discounts: eventUserEvents.discounts,
    eventAbout: state.eventUserEvents.eventsAbout,
    tickets: eventUserEvents.tickets,
    eventUserEvents: eventUserEvents,
  };
};
const matchDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { addDiscountAction, deleteDiscountAction, updateDiscountAction },
    dispatch
  );
};
export default connect(
  mapStateToProps,
  matchDispatchToProps
)(withStyles(styles)(discounts));
