import React from "react";
import LandingPageLineChart from "./LandingPageLineChart";
import moment from "moment";
import { Text } from "Constants";

function LandingPage({ activityData }) {
   
  const checkisArray = (data, name) => {
    let PageStatistics = [];
    if (Array.isArray(data)) {
      PageStatistics = data;
    } else {
      PageStatistics.push(data);
    }
    return !PageStatistics[0] ? []:  PageStatistics?.map(
      (items) => ({
        value: Number(name === "Sales" ? items?.Sum?.replace(/[^0-9.-]/g, "") || 0 : items?.Count?.replace(/,/g, "") || 0),
        title: name,
        Date:moment(items.Date).format("MM/DD/YYYY"),
        currencySymbol: items.CurrencySymbol ? items.CurrencySymbol : ""
      })
    );
  };
  return (
    <div>
      <div className="appEngageBorder" />
      <div className="appEngagementDesc">
        <h3 style={{ fontSize: 20, fontWeight: 500 }}>
          {Text.LandingPage} -{" "}
          <span style={{ fontSize: 16, fontWeight: 500 }}>
            {/* {Text.LandingPageDesc} */}
            View your landing page analytics
          </span>
        </h3>
      </div>
      <div className="landingLineChart">
        <LandingPageLineChart
          LandingPageStatistics={checkisArray(activityData?.LandingPageStatistics?.Click, "Clicks")}
          title="Clicks"
        />
        <LandingPageLineChart
          LandingPageStatistics={checkisArray(activityData?.LandingPageStatistics?.Sales, "Sales")}
          title="Sales"
        />
        <LandingPageLineChart
          LandingPageStatistics={checkisArray(activityData?.LandingPageStatistics?.TicketHolders, "Tickets Sold")}
          title="Tickets Sold"

        />
      </div>
    </div>
  );
}

export default LandingPage;
