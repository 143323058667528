import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';

class Alert extends React.Component{
    render() {
        return (
            <SweetAlert
                warning={this.props.warning}
                showCancel={this.props.showCancel}
                confirmBtnText={this.props.confirmText}
                confirmBtnBsStyle={this.props.confirmStyle}
                cancelBtnBsStyle="default"
                title={this.props.title}
                onConfirm={this.props.Confirm}
                onCancel={this.props.Cancel}
            />
        )
    }
}

export default Alert;
