import React from "react";
import Camera from "react-html5-camera-photo";
import { Modal, Grid } from "@material-ui/core";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import { ws } from "../../../../App";
import { withStyles } from "@material-ui/core/styles";
import {
  getDetailGIF,
  clearDetailedGif,
} from "../../../../Actions/messagesAction";
import "../messages.css";
import "react-html5-camera-photo/build/css/index.css";

class GifComponent extends React.Component {
  constructor() {

    super();
    this.state = {
      selectedGif: "",
      showDetailed: false,
      gif: [],
      backgroundColorDiv: [
        "#516077",
        "#9b8f88",
        "#c14b05",
        "#5e3721",
        "#8c824e",
        "#e2e1d9",
        "#f2d702",
        "#51db02",
        "#289186",
        "#5f7baf",
        "#9050c4",
        "#511682",
        "#af1574",
      ],
    };
  }

  componentDidMount() {
    if (this.props.showGif) {
      let { gif } = this.state;
      this.props.messageData.gif &&
        this.props.messageData.gif.forEach((g, index) => {
          gif.push(
            <div
              key={index}
              className="gif"
              onClick={() => {
                if (g) {
                  let details = {
                    name: g.searchterm,
                    page: this.props.messageData.detailedGIF
                      ? this.props.messageData.detailedGIF.next
                      : 0,
                  };
                  this.props.getDetailGIF(details);
                  this.setState({
                    showDetailed: true,
                    detailGifData: g,
                  });
                }
              }}
            >
              <img src={g.image} alt={g.name} className="gif-img" />
              <p className="gifText">{g.searchterm}</p>
            </div>
          );
        });
      this.setState({
        gif,
      });
    }
  }

  openDetailed = (data) => {
    if (data) {
      let details = {
        name: data.searchterm,
        page: this.props.messageData.detailedGIF
          ? this.props.messageData.detailedGIF.next
          : 0,
      };
      this.props.getDetailGIF(details);
      this.setState({
        showDetailed: true,
        detailGifData: data,
      });
    }
  };

  closeDetailed = () => {
    this.setState({
      showDetailed: false,
    });
    this.props.clearDetailedGif();
  };

  scrollModal = () => {
    let scrollElement = document.getElementById("gifWrapper");
    if (scrollElement) {
      if (
        scrollElement.scrollTop >=
        scrollElement.scrollHeight - scrollElement.clientHeight
      ) {
        let details = {
          name: this.state.detailGifData.searchterm,
          page:
            this.props.messageData.detailedGIF &&
            this.props.messageData.detailedGIF.next,
        };
        this.props.messageData.detailedGIF &&
          this.props.messageData.detailedGIF.next &&
          this.props.getDetailGIF(details);
      }
    }
  };

  sendGif = (gif) => {
    let date = new Date();
    let currentDate = moment().utc().format("YYYY-MM-DDTHH:mm:ss");
    let data = {
      text: "[GIF message]",
      imgUrl: `${gif.itemurl}.gif`,
    };
    ws.json({
      msgid: date.getTime().toString().slice(4),
      to_user_id: this.props.messageData.selectedChat.userid,
      dialog_id: this.props.messageData.selectedChat.dialog_id,
      from_user_id: this.props.userId,
      chat_time: currentDate,
      message_type: "6",
      type: "chat",
      token_id: localStorage.getItem("token"),
      chat_message: data,
    });
    this.props.updateWebViewState("showGif", false);
    this.props.updateWebViewState("scroll", true);
    this.closeDetailed();
  };

  onGifSearch = (e) => {
    if (e.target.value.length > 0) {
      let { searchArray } = this.state;
      searchArray = [];
      this.state.gif.forEach(
        (v) =>
          v.props.children[1].props.children
            .toLowerCase()
            .includes(e.target.value.toLowerCase()) && searchArray.push(v)
      );
      this.setState({
        isSearching: true,
        searchArray,
      });
    } else {
      this.setState({
        isSearching: false,
      });
    }
  };

  onCameraError = (e) => {
    if (e.toString() === "NotFoundError: Requested device not found") {
      this.props.toggleCamera();
      this.props.toggleAlert(
        "Please connect camera to use this functionality."
      );
    } else if (e.toString().includes("Only secure origins are allowed")) {
      this.props.toggleCamera();
      this.props.toggleAlert(`${window.location.origin} is not secured.`);
    } else if (e.toString().includes("Permission denied")) {
      this.props.toggleCamera();
      this.props.toggleAlert(
        `Permission Denied. Please allow the camera to use this functionality.`
      );
    } else if (e.toString().includes("no stream to stop!")) {
    } else {
      this.props.toggleCamera();
      this.props.toggleAlert(`Something went wrong while accessing camera.`);
      console.log(e);
    }
  };

  render() {

    return (
      <React.Fragment>
        {this.props.showCamera ? (
          <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={true}
            onClose={this.props.toggleCamera}
          >
            <div className="display-camera">
              <Camera
                onTakePhoto={this.props.onCameraClick}
                onCameraError={(e) => {
                  if (
                    e.toString() === "NotFoundError: Requested device not found"
                  ) {
                    this.props.toggleCamera();
                    this.props.toggleAlert(
                      "Please connect camera to use this functionality."
                    );
                  } else if (
                    e.toString().includes("Only secure origins are allowed")
                  ) {
                    this.props.toggleCamera();
                    this.props.toggleAlert(
                      `${window.location.origin} is not secured.`
                    );
                  } else if (e.toString().includes("Permission denied")) {
                    this.props.toggleCamera();
                    this.props.toggleAlert(
                      `Permission Denied. Please allow the camera to use this functionality.`
                    );
                  } else if (e.toString().includes("no stream to stop!")) {
                  } else {
                    this.props.toggleCamera();
                    this.props.toggleAlert(
                      `Something went wrong while accessing camera.`
                    );
                    console.log(e);
                  }
                }}
              />
            </div>
          </Modal>
        ) : (
          <React.Fragment>
            <Modal
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              open={true}
              onClose={this.props.onGifClick}
            >
              <Grid container spacing={24}>
                <Grid item sm={12} />
                <div className="paper">
                  <div className="gifWrapper">
                    <input
                      type="text"
                      placeholder="Search for GIF"
                      onChange={(e) => {
                        if (e.target.value.length > 0) {
                          let { searchArray } = this.state;
                          searchArray = [];
                          this.state.gif.forEach(
                            (v) =>
                              v.props.children[1].props.children
                                .toLowerCase()
                                .includes(e.target.value.toLowerCase()) &&
                              searchArray.push(v)
                          );
                          this.setState({
                            isSearching: true,
                            searchArray,
                          });
                        } else {
                          this.setState({
                            isSearching: false,
                          });
                        }
                      }}
                    />
                    <div className="gifComponent">
                      {this.state.isSearching
                        ? this.state.searchArray
                        : this.state.gif}
                    </div>
                  </div>
                </div>
                <Grid />
              </Grid>
            </Modal>
            {this.state.showDetailed && (
              <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={true}
                onClose={() => {
                  this.setState({
                    showDetailed: false,
                  });
                  this.props.clearDetailedGif();
                }}
              >
                <div className="paper">
                  <div
                    className="gifWrapper"
                    id="gifWrapper"
                    onScroll={() => {
                      let scrollElement = document.getElementById("gifWrapper");
                      if (scrollElement) {
                        if (
                          scrollElement.scrollTop >=
                          scrollElement.scrollHeight -
                            scrollElement.clientHeight
                        ) {
                          let details = {
                            name: this.state.detailGifData.searchterm,
                            page:
                              this.props.messageData.detailedGIF &&
                              this.props.messageData.detailedGIF.next,
                          };
                          this.props.messageData.detailedGIF &&
                            this.props.messageData.detailedGIF.next &&
                            this.props.getDetailGIF(details);
                        }
                      }
                    }}
                  >
                    <div className="detailGifComponent">
                      {this.props.messageData.detailedGIF &&
                        this.props.messageData.detailedGIF.results.map(
                          (gif) => {
                            let color =
                              this.state.backgroundColorDiv[
                                Math.floor(Math.random() * 13) + 1
                              ];
                            return (
                              <div
                                className="gif"
                                onClick={() => {
                                  let date = new Date();
                                  let currentDate = moment().utc().format(
                                    "YYYY-MM-DDTHH:mm:ss"
                                  );
                                  let data = {
                                    text: "[GIF message]",
                                    imgUrl: `${gif.itemurl}.gif`,
                                  };
                                  ws.json({
                                    msgid: date.getTime().toString().slice(4),
                                    to_user_id:
                                      this.props.messageData.selectedChat
                                        .userid,
                                    dialog_id:
                                      this.props.messageData.selectedChat
                                        .dialog_id,
                                    from_user_id: this.props.userId,
                                    chat_time: currentDate,
                                    message_type: "6",
                                    type: "chat",
                                    token_id: localStorage.getItem("token"),
                                    chat_message: data,
                                  });
                                  this.props.updateWebViewState(
                                    "showGif",
                                    false
                                  );
                                  this.props.updateWebViewState("scroll", true);
                                  this.closeDetailed();
                                }}
                              >
                                <img
                                  src={`${gif.itemurl}.gif`}
                                  alt={"GIF"}
                                  className="gif-img"
                                  gifstyle={{ background: color }}
                                />
                              </div>
                            );
                          }
                        )}
                    </div>
                  </div>
                </div>
              </Modal>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    messageData: state.messageReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getDetailGIF,
      clearDetailedGif,
    },
    dispatch
  );
};

export default withStyles(
  connect(mapStateToProps, mapDispatchToProps)(GifComponent)
);
