import { GET_PROFILE, CHANGE_PROFILE_IMAGE, CHANGE_COVER_IMAGE } from '../Actions/actionTypes';
export default (state=[],action)=>{
    switch(action.type){
        case GET_PROFILE :
            return action.payload;
        case CHANGE_PROFILE_IMAGE:
            return state;
        case CHANGE_COVER_IMAGE:
            return state;
        default:
            return state;
    }
}