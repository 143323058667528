import React, { useState } from "react";

import "./recentActivity.css";
import { BsSearch } from "react-icons/bs";
import { useLayoutEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import RecentActivities from "./recentActivities";
import { Text } from "Constants";
// import SalesTable from "./SalesTable";

const EventActivity = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [addAttendee, setAddAttendee] = useState(true);
  const [editAttendee, setEditAttendee] = useState(true);
  function toggleModal() {
    setIsOpen(!isOpen);
  }
  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  }
  const [width, height] = useWindowSize();
  // const classes = useStyles();
  return (
    <div>
     
      <div className="activity_container">
      <div className="email_invite_wrapper">
        <div className="email_invite_header">
          <div className="email_invite">
            <span style={{ fontSize: 30 }}>{Text.RecentActivity}</span>
          </div>
        </div>
      </div>  
       <RecentActivities/>
      </div>
    </div>
  );
};

export default EventActivity;
