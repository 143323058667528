import React, { Component } from "react";
import {
  Box,
  Button,
  Card,
  Collapse,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Select,
  TextField,
  Tooltip,
  withStyles,
} from "@material-ui/core";
import { Close, Create, Delete, ExpandMore, Info } from "@material-ui/icons";
import garlicFest from "../../../../images/garlicFest.png";
import ContentGrid from "../../../../Helper/gridWrapper/gridWrapper";
import EventHeader from "./eventHeader";
import { Editor } from "react-draft-wysiwyg";
import converter from "../../../../Helper/convertFile";
import emptyAd from "../../../../images/emptyAd.png";
import vendorDefault from "../../../../images/CreateEvent/Create/Vendor/vendorDefault.png";
import "./editor.css";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  addVendor as addVendorServer,
  deleteVendor as deleteVendorServer,
  updateVendor as updateVendorServer,
} from "../../../../Actions/eventActions";
import draftToHtml from "draftjs-to-html";
import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState,
} from "draft-js";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import load from "load-script";
import { axiosE } from "../../../../Service/service";
import Loader from "../../../../Helper/loader/loader";
import { eventApis, Events, Text } from "../../../../Constants";
import AddIcon from "@material-ui/icons/Add";
import { reusableClasses } from "./resusableClasses";
import CreateEventErrorMessage from "../createEventErrorMessage";
import { checkIfEventExists } from "../../../../Helper/Validations";
import tz from "tz-lookup";
const styles = {
  createEventVendorsGrid: {
    position: "relative",

    "@media (max-width: 480px)": {
      padding: "0px",
    },
  },
  createEventVendorsWrapper: {
    ...reusableClasses.contentWrapper,
  },
  createEventVendorsGridWrapper: {
    margin: "2px 0px 0px 0px",
    display: "grid",
  },

  createEventVendorsVerticalLineWithCirclesWrapper: {
    margin: "30px 0px 0px 0px",
    "@media screen and (max-device-width:450px)": {
      display: "none",
    },
  },
  createEventVendorsCircleOnVerticalLine: {
    backgroundColor: "rgb(182, 14, 181)",
    height: "15px",
    width: "15px",
    margin: "20px 0px 615px -9px",
    borderRadius: "50%",
    "@media screen and (max-device-width:450px)": {
      display: "none",
    },
  },

  createEventVendorsTextInputLabel: {
    margin: "10px 0px 0px 0px",
    fontSize: "18px",
  },
  createEventVendorsTextInputWithBorder: {
    border: "1px solid #ced4da",
    borderRadius: "3px",
    padding: "10px",
    margin: "10px 0px 10px 0px",
    width: "100%",
    borderLeft: "10px solid rgb(0, 185, 255)",
  },
  createEventVendorsLargeTextFieldInput: {
    border: "1px solid #ced4da",
    borderRadius: "3px",
    padding: "10px",
    margin: "10px 0px 0px 0px",
    height: "180px",
    width: "80%",
  },
  createEventVendorsTextFieldInput: {
    border: "1px solid #ced4da",
    borderRadius: "5px",
    fontSize: "16px",
    padding: "10px",
    margin: "15px 0px 20px 0px",
    width: "100%",
  },
  createEventVendorsTextInputText: {
    fontSize: "16px",
  },
  createEventVendorsAddLogo: {
    border: "dotted #ced4da",
    borderBottom: "8px solid #579cfc",
    margin: "10px 0px 0px 0px",
    fontSize: "18px",

    padding: "20px",
    color: "black",
    backgroundColor: "white",
    width: "100px",
    textTransform: "none",
    textAlign: "center",
  },
  createEventVendorsAddLogoLabel: {
    margin: "23px 0px 1px 0px",
    fontSize: "18px",
  },
  createEventVendorsAddLogowithType: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "20px",

    "@media  screen and (max-width:450px)": {
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "0px",
      margin: "0px 0px 14px 0px",
    },
  },
  createEventVendorsVendorTypeDropdownMenu: {
    fontSize: "16px",
    border: "1px solid lightgray",
    borderRadius: "5px",
    padding: "5px 20px 5px 5px",
    margin: "0px 0px 0px 5px",
  },
  createEventVendorsAddVendorButton: {
    ...reusableClasses.addOrUpdateModalHeaderButtonSave,
  },
  createEventVendorsCard: {
    ...reusableClasses.card,
    margin: "0px 30px",
    marginBottom: "25px",
  },
  createEventVendorsCardImage: {
    height: "50px",
    width: "70px",
    alignSelf: "center",
  },
  eventDetailsAboutInputLabel: {
    fontSize: "16px",
    fontWeight: "bold",
  },
  createEventVendorsCardTextFieldWrapper: {
    display: "grid",
    margin: "0px",
    width: "100%",
    overflow: "hidden",
    paddingLeft: "3px",
    flexDirection: "column",

    "& > p": {
      margin: "0px",
    },
    "& > a > p": {
      margin: 0,
    },
  },
  createEventVendorsCardTextField: {
    fontSize: "16px",
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
  },
  createEventVendorsCardEditDeleteWrapper: {
    margin: "0px 0px 0px 0px",
    justifyContent: "flex-end",
    alignSelf: "center",
    display: "flex",
  },
  createEventVendorsButtonWrapper: {
    ...reusableClasses.addOrUpdateModalHeaderButtonWrapper,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
    "@media  screen and (max-width:450px)": {
      // flexDirection:'column',
      margin: "0px 0px 14px 0px",
    },
  },
  createEventVendorsVerticalLine: {
    "@media screen and (max-device-width:450px)": {
      display: "none",
    },
  },

  createEventVendorsShowFieldsButton: {
    backgroundColor: "rgb(182, 14, 181)",
    color: "white",
    fontFamily:
      "HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif",
    fontSize: "14px",
    width: "auto",
    textTransform: "none",
    margin: "10px 0px 0px 0px",
  },
  createEventVendorSaveAndContinueButton: {
    ...reusableClasses.saveAndContinueButton,
  },
  createEventVendorsVerticalLineDivider: {
    borderLeft: "3px solid lightgray",
    margin: "0px 3px 0px 3px",
    display: "grid",
    width: "100%",
    height: "45px",
  },
  createEventVendorImage: {
    margin: "10px 0px 0px 0px",
    height: "80px",
    width: "100px",
  },
  createEventFAQHeader: {
    position: "absolute",
    height: "fit-content",

    display: "flex",
    alignItems: "start",
    justifyContent: "space-between",

    paddingBottom: "20px",

    borderBottom: "1px solid #F5F5F5",
    width: "76vw",

    "@media (max-width: 1280px)": {
      width: "90vw",
    },
    "@media (max-width: 900px)": {
      width: "75vw",
    },
  },
  createEventAgendaAddSessionDaysButtonWrapper: {
    ...reusableClasses.addButtonWrapper,
  },
  createEventAgendaAddSessionDaysButton: {
    ...reusableClasses.addButton,
  },
  createEventAgendaAddSessionDaysButtonText: {
    ...reusableClasses.addButtonText,
  },
  createEventVendorsVendorTypeDropdownOption: {
    fontSize: "16px",
  },
  createEventAdsEmptyScreenWrapper: {
    width: "100%",
    margin: "auto 10px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    opacity: 0.3,
  },
  createEventVendorModal: {
    ...reusableClasses.addOrUpdateModalMainWrapperOuter,
  },

  createEventFAQModalHeader: {
    ...reusableClasses.addOrUpdateModalHeader,
  },
  eventDetailsVendorsMainWrapper: {
    ...reusableClasses.mainWrapper,
  },
  createEventVendorButtonWrapper: {
    ...reusableClasses.saveAndContinueButtonWrapper,
  },
  createEventVendorPlusIcon: {
    ...reusableClasses.addButtonIcon,
  },
  createEventFAQVerticalLineWithCirclesWrapper: {
    margin: "57px 0px 0px 0px",
    "@media screen and (max-device-width:450px)": {
      display: "none",
    },
  },
  createFAQAdAndDeleteModal: {
    ...reusableClasses.addOrUpdateModalMainWrapperInner,
  },
  createEvenVendorDeleteVendorModal: {
    width: "430px",
    height: "150px",
    backgroundColor: "white",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "10px",
    padding: "10px",

    "@media (max-width: 540px)": {
      width: "70%",
    },
    "@media (max-width: 410px)": {
      width: "90%",
    },
  },
  createEventVendorImgWrapper: {
    width: "100px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100px",
  },

  createEventVendorImg: {
    width: "30px",
  },

  createVendorDeleteVendorButton: {
    fontSize: "10px",
    padding: "10px 25px",
  },

  addMargin: {
    margin: "0px 0px 71.80px -9px !important",
  },
  addMarginSimple: {
    margin: "0px 0px 0px -9px",
  },
  createEventFAQCancelFAQButton: {
    ...reusableClasses.addOrUpdateModalHeaderButtonCancel,
    margin: "0px !important",
  },
  createEventFAQModalHeaderText: {
    ...reusableClasses.addOrUpdateModalHeaderText,
  },
  vendorCardWrapper: {
    ...reusableClasses.cardWrapper,
    marginTop: "15px",
  },
  verticalLineWrapper: {
    ...reusableClasses.verticalLineWrapper,
    marginTop: "15px",
  },
  eventDetailsAboutLongInputField: {
    border: "1px solid #ced4da",
    borderRadius: "5px",
    fontSize: "16px",
    padding: "10px",
    margin: "15px 0px 35px 0px",
    width: "auto",
  },

  eventDetailsAboutInputText: {
    fontSize: "16px",
  },
  suggestionActiveItem: {
    padding: "3px",
    backgroundColor: " #f1f1f1",
    cursor: "pointer",
    fontSize: " 14px",
    color: "black",
  },

  suggestionItem: {
    padding: "3px",
    backgroundColor: "#fff",
    cursor: "pointer",
    fontSize: "14px",
    color: "black",
  },
};

const CreateEventDetailsAboutToolTip = withStyles(() => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: "16px",
    border: "1px solid #dadde9",
  },
}))(Tooltip);
const pattern = /^(http:\/\/|https:\/\/|www\.)/i;
let UrlError =
  "Please enter a valid URL starting with http://, https://, or www.";
class CreateEventVendors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      createAdModal: false,
      addressError: false,
      allVendors: [],
      loadAutocomplete: false,
      currentVendor: {
        vendorName: "",
        vendorDescription: "",
        vendorLogo: "",
        vendorWebsite: "",
        vendorAddress: "",
        vendorPhoneNumber: "",
        vendorEmailAddress: "",
        vendorType: 1,
        showEdit: false,
        showOptionalFields: false,
      },
      deleteModal: { show: false, targetIndex: "" },
      showOptionalFields: false,
      verticalLineHeight: "685px",
      vendorTypes: [],
      loader: false,
      urlError: "",
      error: { show: false, message: "" },
    };
  }

  componentDidUpdate = () => [console.log(this.state)];

  componentDidMount() {
    checkIfEventExists(this.props.history, this.props.eventId);
    console.log("");
    axiosE.get(eventApis.getAllEventVendorType).then(({ data }) => {
      let vendorTypes = data.results.VendorType;
      this.setState({ vendorTypes: vendorTypes });

      axiosE
        .get(`${eventApis.getVendorByEventId}${this.props.eventId}/Admin`)
        .then(({ data }) => {
          const vendors = data.results.ListVendor;

          if (vendors && vendors.length > 0) {
            // console.log("vendors", .TypeId);

            this.setState({
              allVendors: vendors.map((vendor) => {
                const vandorId = vendorTypes?.find(
                  (v) => v.TypeName === vendor.Type
                );


                return {
                  vendorName: vendor.Name,
                  vendorDescription: vendor.Description,
                  vendorLogo: vendor.Logo,
                  vendorWebsite: vendor.Website,
                  vendorAddress: vendor.Address,
                  vendorPhoneNumber: vendor.Phone,
                  vendorEmailAddress: vendor.Email,
                  vendorType: vandorId.TypeId,
                  showEdit: false,
                  showOptionalFields: false,
                  vendorId: vendor.VendorId,
                };
              }),
            });
          }
        });
    });
  }
  componentWillMount() {
    load(
      "https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyBr9MSaEjodT_Q9bnUqUcusJ8HWmQmJRC0",
      (e, err) => {
        if (e) {
          this.setState({ error: { show: true, message: err.message } });
        } else {
          this.setState({
            loadAutocomplete: true,
          });
        }
      }
    );
  }
  htmlToEditorState(prop) {
    const blocksFromHTML = convertFromHTML(prop);
    const state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );
    const newState = this.customContentStateConverter(state);
    return EditorState.createWithContent(newState);
  }
  customContentStateConverter = (contentState) => {
    const newBlockMap = contentState.getBlockMap().map((block) => {
      const entityKey = block.getEntityAt(0);
      if (entityKey !== null) {
        const entityBlock = contentState.getEntity(entityKey);
        const entityType = entityBlock.getType();
        switch (entityType) {
          case "IMAGE": {
            const newBlock = block.merge({
              type: "atomic",
              text: "img",
            });
            return newBlock;
          }
          default:
            return block;
        }
      }
      return block;
    });
    const newContentState = contentState.set("blockMap", newBlockMap);
    return newContentState;
  };

  setDeleteModalState = (openModal, ad) => {
    if (openModal) {
      this.setState({
        deleteAdModal: {
          show: true,
          ad,
        },
      });
    } else if (!openModal) {
      this.setState({
        deleteAdModal: { show: false, ad: "" },
      });
    }
  };

  handCreateAdModal = (openORclose) => {
    this.setErrorState();
    this.setState({
      createAdModal: openORclose,
    });

  };

  emptyAddsScreen = () => {
    const { classes } = this.props;
    return (
      <div className={classes.createEventAdsEmptyScreenWrapper}>
        <img
          src={Events.Vendors.emptyVendor.img}
          alt="Empty Ad"
          style={{ width: "150px" }}
        />
        <h1>{Events.Vendors.emptyVendor.headline}</h1>
        <p style={{ width: "60%" }}>{Events.Vendors.emptyVendor.message}</p>
      </div>
    );
  };

  ShowAddedAds = () => {
    const { allVendors } = this.state;
    const { classes } = this.props;

    return allVendors.length > 0 ? (
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "center",
        }}
      >
        {this.verticalLineWithCircles()}
        <div className={classes.vendorCardWrapper}>{this.displayVendors()}</div>
      </div>
    ) : (
      this.emptyAddsScreen()
    );
  };

  changeVendorDropdownValue = (e) => {
    const { currentVendor } = this.state;
    this.setState({
      currentVendor: { ...currentVendor, vendorType: e.target.value },
    });
  };

  currentVendorTextChange = (e, textField, index) => {
    const { currentVendor, allVendors } = this.state;
    const value = textField == "vendorDescription" ? e : e.target.value;

    {
      this.setState({
        currentVendor: { ...currentVendor, [textField]: value },
      });
    }
  };

  createEventDetailsAboutErrorMessageLabelStyles = (textField) => {
    const { CreateEventErrorMessage } = this.state;
    return {
      color: "red",
      fontSize: "16px",
      fontWeight: "100",
      margin: "0px 0px 0px 10px",
      display:
        CreateEventErrorMessage && this.state[textField] === ""
          ? "initial"
          : "none",
    };
  };

  vendorPhoneNumberFormatter = (value) => {
    if (value !== null) {
      const onlyNums = value.replace(/[^\d]/g, "");
      if (onlyNums.length <= 3) return onlyNums;
      if (onlyNums.length <= 6)
        return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3)}`;
      return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(
        3,
        6
      )}-${onlyNums.slice(6, 10)}`;
    } else {
      return "";
    }
  };

  addExtraSpaceToBalance = () => {
    return <span style={{ width: "30px", height: "30px" }}></span>;
  };

  setErrorState = () => {
    this.setState({
      error: { show: false, message: "" },
    });
  };

  editModalToggle = (index, show, save) => {

    const currentDefault = {
      vendorName: "",
      vendorDescription: "",
      vendorLogo: "",
      vendorWebsite: "",
      vendorAddress: "",
      vendorPhoneNumber: "",
      vendorEmailAddress: "",
      vendorType: 1,
      showEdit: false,
      showOptionalFields: false,
    };

    this.setErrorState();
    if (show && !save) {
      this.setState({
        currentVendor: { ...this.state.allVendors[index] },
      });
      this.toggleEditVendor(index, show);
    } else if (!show && save) {

      this.loaderState(true);
      this.updateRemoteVendor();
      this.setState({
        allVendors: this.state.allVendors.map((vendor, i) =>
          i === index
            ? {
                ...this.state.currentVendor,
                showEdit: false,
              }
            : vendor
        ),
        currentVendor: {
          ...currentDefault,
        },
      });
    } else if (!show && !save) {

      this.setState({
        currentVendor: { ...currentDefault },
      });
      this.toggleEditVendor(index, show);
    }
  };

  getHtml = (state) => {
    return draftToHtml(convertToRaw(state.getCurrentContent()));
  };

  progressLoader = () => {
    const { loader } = this.state;

    return loader && <Loader />;
  };

  loaderState = (show) => {
    this.setState({ loader: show });
  };

  addVendor = async () => {
    this.loaderState(true);
    this.setState({
      createAdModal: false,
    });

    const { allVendors, currentVendor } = this.state;
    const vendorId = await this.getVendorId(currentVendor);


    this.setState({
      currentVendor: {
        vendorName: "",
        vendorDescription: "",
        vendorLogo: "",
        vendorWebsite: "",
        vendorAddress: "",
        vendorPhoneNumber: "",
        vendorEmailAddress: "",
        vendorType: 1,
        showEdit: false,
        showOptionalFields: false,
      },

      allVendors: [...allVendors, { ...currentVendor, vendorId }],

      showOptionalFields: false,
    });
  };
  getVendorId = async (vendor) => {
    const { eventId } = this.props;

    const vendorRes = await this.props
      .addVendorServer({
        EventId: eventId,

        Name: vendor.vendorName,
        Description: vendor.vendorDescription,
        VendorType: vendor.vendorType,
        Logo: vendor.vendorLogo !== "" ? vendor.vendorLogo.split(",")[1] : "",
        Website: vendor.vendorWebsite,
        Address: vendor.vendorAddress,
        Phone: vendor.vendorPhoneNumber,
        Email: vendor.vendorEmailAddress,
      })
      .finally(() => this.loaderState(false));
    return vendorRes.VendorId;
  };
  toggleEditVendor = (index, show) => {
    this.setState({
      allVendors: this.state.allVendors.map((vendor, i) =>
        i === index
          ? {
              ...this.state.allVendors[i],
              showEdit: show,
            }
          : this.state.allVendors[i]
      ),
    });
  };

  updateRemoteVendor = () => {

    this.props
      .updateVendorServer({
        VendorId: this.state.currentVendor.vendorId,
        Name: this.state.currentVendor.vendorName,
        Description: this.state.currentVendor.vendorDescription,
        VendorType: this.state.currentVendor.vendorType,
        Logo:
          this.state.currentVendor.vendorLogo !== ""
            ? this.state.currentVendor.vendorLogo.split(",")[1]
            : "",
        Website: this.state.currentVendor.vendorWebsite,
        Address: this.state.currentVendor.vendorAddress,
        Phone: this.state.currentVendor.vendorPhoneNumber,
        Email: this.state.currentVendor.vendorEmailAddress,
      })
      .finally(() => this.loaderState(false));
  };

  deleteVendor = () => {
    const { allVendors, deleteModal } = this.state;

    this.setState({
      allVendors: allVendors.filter(
        (currentVendor, i) => i !== deleteModal.targetIndex
      ),
      deleteModal: { show: false, targetIndex: "" },
    });

    this.props.deleteVendorServer({
      VendorId: allVendors[deleteModal.targetIndex].vendorId,
    });
  };

  editVendorForm = (index) => {
    return {
      display: this.state.allVendors[index].showEdit ? "grid" : "none",
      overflow: "auto",
      margin: "20px 0px 0px 0px",
    };
  };
  calculateCircleOnVerticalLineMargin = (index) => {
    const { allVendors, showOptionalFields } = this.state;
    return allVendors.length > 0
      ? index !== allVendors.length - 1
        ? "71.222px "
        : "71.222px "
      : "71.222px ";
  };
  circleOnVerticalLineStyles = (index) => {
    return {
      backgroundColor: "rgb(182, 14, 181)",
      height: "15px",
      width: "15px",
      margin: "0px 0px 0px -9px",
      borderRadius: "50%",
      "@media screen and (max-device-width:450px)": {
        display: "none",
      },
    };
  };
  calculateLineHeight = (extraCircles) => {
    const { allVendors, showOptionalFields } = this.state;
    return allVendors.length > 1
      ? (extraCircles - 1) * 86.9645098039 + "px"
      : "0px";
  };
  createEventVendorsVerticalLineStyles = (extraCircles) => {
    return {
      float: "left",
      borderLeft: "2px solid lightgray",
      margin: "36.11px 0px 0px 30px",
      height: this.calculateLineHeight(extraCircles),
      "@media screen and (max-device-width:450px)": {
        display: "none",
      },
    };
  };

  verticalLineWithCircles = () => {
    const { classes } = this.props;
    const { allVendors } = this.state;

    let extraCircles = allVendors.map((vendor, index) => (
      <div
        key={index}
        style={this.circleOnVerticalLineStyles(index)}
        className={
          allVendors.length > 0 ? classes.addMargin : classes.addMarginSimple
        }
      ></div>
    ));
    extraCircles = extraCircles.splice(0, extraCircles.length - 1);
    let elements = [];
    elements.push(
      <div className={classes.verticalLineWrapper}>
        <span
          style={this.createEventVendorsVerticalLineStyles(allVendors.length)}
          className={classes.createEventFAQVerticalLineWithCirclesWrapper}
        ></span>
        <div
          className={classes.createEventVendorsVerticalLineWithCirclesWrapper}
        >
          {extraCircles}
          {allVendors.length > 0 && (
            <div
              style={this.circleOnVerticalLineStyles(allVendors.length - 1)}
            ></div>
          )}
        </div>
      </div>
    );
    return <React.Fragment>{elements}</React.Fragment>;
  };

  titleWithToolTip = (title, tipText, mandatory = false) => {
    const { classes } = this.props;
    return (
      <span className={classes.createEventVendorsTextInputLabel}>
        {title} {this.mandatoryFieldTag(title)}
        <CreateEventDetailsAboutToolTip
          title={<React.Fragment>{tipText}</React.Fragment>}
          interactive
        >
          <IconButton color={"primary"} variant="outlined">
            <Info />
          </IconButton>
        </CreateEventDetailsAboutToolTip>
      </span>
    );
  };

  mandatoryFieldTag = (title) => {
    const mandatoryFields = [
      "Vendor Name",
      "Vendor Description",
      "Vendor Type",
    ];

    return mandatoryFields.includes(title) ? (
      <span style={{ color: "red" }}>*</span>
    ) : (
      ""
    );
  };

  getCityStateCountry = (results) => {
    const address = results[0].formatted_address;
    let city, state, country;
    for (let i = 0; i < results[0].address_components.length; i++) {
      for (let j = 0; j < results[0].address_components[i].types.length; j++) {
        switch (results[0].address_components[i].types[j]) {
          case "sublocality":
            city = results[0].address_components[i].long_name;
            break;
          case "locality":
            city = results[0].address_components[i].long_name;
            break;
          case "administrative_area_level_1":
            state = results[0].address_components[i].long_name;
            break;
          case "country":
            country = results[0].address_components[i].long_name;
            break;
        }
      }
    }
    return [address, country, city, state];
  };

  handleLocationSelect = (address) => {
    let fullAddress;
    geocodeByAddress(address)
      .then((results) => {
        fullAddress = this.getCityStateCountry(results);

        return getLatLng(results[0]);
      })
      .then((latLng) => {
        this.setState({
          currentVendor: {
            ...this.state.currentVendor,
            vendorAddress: address,
          },
        });

      });
  };

  inputChange = (textField, e) => {

    if (
      textField === "vendorAddress" &&
      !this.state.currentVendor["vendorAddress"]
    ) {
      this.setState({ addressError: true });

    }
    this.setState({
      currentVendor: { ...this.state.currentVendor, vendorAddress: e },
    });
  };

  addressErrorMessage = () => {
    const { addressError } = this.state;
    return {
      color: "red",
      fontSize: "16px",
      fontWeight: "100",
      margin: "0px 0px 0px 10px",
      display:
        addressError && this.state["eventAddress"] === "" ? "initial" : "none",
    };
  };

  textFieldSuggestionInputWithTitle = (title) => {
    const { classes } = this.props;
    const formTitleMap = {
      "Event Title": "eventTitle",
      [title]: "vendorAddress",
      "Venue Name": "eventVenue",
    };
    const searchOptions = {
      types: ["(cities)"],
    };

    return (
      <FormControl>
        <span className={classes.eventDetailsAboutInputLabel}>
          {title === "vendorAddress" && this.titleWithToolTip(title, true)}
          <p style={this.addressErrorMessage()}>{`Please Select a Location`}</p>
        </span>

        {this.state.loadAutocomplete && (
          <PlacesAutocomplete
            value={this.state.currentVendor.vendorAddress}
            onChange={(e) => this.inputChange(formTitleMap[title], e)}
            onSelect={(address) => this.handleLocationSelect(address)}
            // searchOptions={searchOptions}
          >
            {({ getInputProps, suggestions, getSuggestionItemProps }) => (
              <>
                <TextField
                  {...getInputProps({
                    fullWidth: true,
                    required: true,
                    className: classes.eventDetailsAboutLongInputField,
                    placeholder: title,
                    margin: "normal",
                    InputProps: {
                      disableUnderline: true,
                      className: classes.eventDetailsAboutInputText,
                    },
                  })}
                />
                <div
                  style={{
                    margin: "-35px 0px 10px 0px",
                    // overflow: "hidden",
                    overflowY: "auto",
                    backgroundColor: "white",
                    border:
                      suggestions.length > 0 ? "1px solid #ced4da" : "0px",
                    borderRadius: "5px",
                  }}
                >
                  {suggestions.map((suggestion) => {
                    const className = suggestion.active
                      ? classes.suggestionActiveItem
                      : classes.suggestionItem;

                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                        })}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </PlacesAutocomplete>
        )}
      </FormControl>
    );
  };

  vendorsTextFieldWithLabel = (
    textFieldName,
    placeHolder,
    hasBorder,
    isLargeInputField,
    value,
    index
  ) => {
    const { classes } = this.props;
    const { currentVendor, allVendors } = this.state;
    let vendorTextFieldMap = {
      "Vendor Name": "vendorName",
      "Vendor Description": "vendorDescription",
      "Vendor Website (Optional)": "vendorWebsite",
      "Vendor Address (Optional)": "vendorAddress",
      "Vendor Phone Number (Optional)": "vendorPhoneNumber",
      "Vendor's Email (Optional)": "vendorEmailAddress",
    };
    const textFieldClass = hasBorder
      ? classes.createEventVendorsTextInputWithBorder
      : isLargeInputField
      ? classes.createEventVendorsLargeTextFieldInput
      : classes.createEventVendorsTextFieldInput;

    return (
      <React.Fragment>
        <Grid className={classes.createEventVendorsGridWrapper}>
          <FormControl>
            {textFieldName == "Vendor's Email (Optional)" ? (
              this.titleWithToolTip(
                "Vendor's Email (Optional)",
                "Vendor's Email (Optional)"
              )
            ) : (
              <p className={classes.createEventVendorsTextInputLabel}>
                {textFieldName} {this.mandatoryFieldTag(textFieldName)}
              </p>
            )}
            {textFieldName != "Vendor Description" ? (
              textFieldName === "Vendor Phone Number (Optional)" ? (
                <TextField
                  className={textFieldClass}
                  placeholder={placeHolder}
                  multiline={isLargeInputField}
                  rows={8}
                  onChange={(e) =>
                    this.currentVendorTextChange(
                      e,
                      vendorTextFieldMap[textFieldName],
                      index
                    )
                  }
                  value={this.vendorPhoneNumberFormatter(
                    currentVendor[vendorTextFieldMap[textFieldName]]
                  )}
                  InputProps={{
                    disableUnderline: true,
                    className: classes.createEventVendorsTextInputText,
                  }}
                />
              ) : textFieldName === "Vendor Address (Optional)" ? (
                this.textFieldSuggestionInputWithTitle(
                  "Vendor Address (Optional)"
                )
              ) : (
                <div style={{ width: "100%" }}>
                  <TextField
                    className={textFieldClass}
                    placeholder={placeHolder}
                    multiline={isLargeInputField}
                    rows={8}
                    onChange={(e) =>
                      this.currentVendorTextChange(
                        e,
                        vendorTextFieldMap[textFieldName],
                        index
                      )
                    }
                    onInput={(e) => {
                      e.target.value =
                        textFieldName === "Vendor Name"
                          ? e.target.value.length > 30
                            ? e.target.value.slice(0, 30)
                            : e.target.value
                          : e.target.value;
                    }}
                    value={currentVendor[vendorTextFieldMap[textFieldName]]}
                    helperText={
                      textFieldName === "Vendor Name"
                        ? `${
                            currentVendor[vendorTextFieldMap[textFieldName]]
                              ?.length
                          }/30`
                        : null
                    }
                    InputProps={{
                      disableUnderline: true,
                      className: classes.createEventVendorsTextInputText,
                    }}
                  />
                  {this.state.currentVendor.vendorWebsite !== "" &&
                    textFieldName === "Vendor Website (Optional)" && (
                      <p style={{ color: "red" }}>
                        {!pattern.test(this.state.currentVendor.vendorWebsite)
                          ? UrlError
                          : null}
                      </p>
                    )}
                </div>
              )
            ) : (
              <div style={{ position: "relative" }}>
                <textarea
                  maxLength={200}
                  value={this.state.currentVendor.vendorDescription}
                  onChange={(e) =>
                    this.setState({
                      currentVendor: {
                        ...this.state.currentVendor,
                        vendorDescription: e.target.value,
                      },
                    })
                  }
                  style={{
                    height: 300,
                    width: "100%",
                    resize: "none",
                    padding: 10,
                    whiteSpace: "pre-line",
                    margin: "10px 0px 20px 0px",
                    border: "1px solid #ced4da",
                    outline: "none",
                    overflowWrap: "break-word",
                  }}
                />
                <span
                  style={{
                    color: "rgba(0, 0, 0, 0.54)",
                    position: "absolute",
                    bottom: 30,
                    left: 14,
                    fontSize: 12,
                  }}
                >{`${this.state.currentVendor.vendorDescription.length}/200`}</span>
              </div>
            )}
          </FormControl>
        </Grid>
      </React.Fragment>
    );
  };

  vendorsTypeDropdown = (value) => {
    const { classes } = this.props;
    const { currentVendor, vendorTypes } = this.state;
    const vendorTypeId = vendorTypes.find(
      (v) => v.TypeName === currentVendor.vendorType
    );

    return (
      <React.Fragment>
        <div style={{ margin: "0px" }}>
          <FormControl>
            {this.titleWithToolTip(
              "vendor type",
              "Select category that applies to vendor",
              true
            )}

            <Select
              className={classes.createEventVendorsVendorTypeDropdownMenu}
              onChange={this.changeVendorDropdownValue}
              disableUnderline={true}
              value={
                value
                  ? value
                  : vendorTypeId?.TypeId !== undefined
                  ? vendorTypeId?.TypeId
                  : currentVendor.vendorType
              }
              SelectDisplayProps={{
                style: { padding: "5px 0px 5px 10px" },
              }}
            >
              {vendorTypes.map((vendor) => (
                <MenuItem
                  className={classes.createEventVendorsVendorTypeDropdownOption}
                  value={vendor.TypeId}
                  selected={true}
                >
                  {vendor.TypeName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </React.Fragment>
    );
  };

  setDelete = (modal, item, index = "") => {
    if (modal && !item) {
      this.setState({
        deleteModal: { show: !this.state.deleteModal.show, targetIndex: index },
      });
    } else if (!modal && item) {
      this.deleteVendor();
    } else {
      this.setState({
        deleteModal: false,
      });
    }
  };

  displayVendors = () => {
    const { classes } = this.props;
    const { allVendors, deleteModal } = this.state;
    return allVendors.map((vendor, index) => {
      return (
        <React.Fragment key={index}>
          <Card className={classes.createEventVendorsCard}>
            <div>
              <img
                className={classes.createEventVendorsCardImage}
                src={vendor.vendorLogo ? vendor.vendorLogo : vendorDefault}
                style={{ objectFit: vendor.vendorLogo ? "initial" : "contain" }}
              />
            </div>
            <div className={classes.createEventVendorsVerticalLineDivider}>
              <div className={classes.createEventVendorsCardTextFieldWrapper}>
                <p className={classes.createEventVendorsCardTextField}>
                  {vendor.vendorName}
                </p>
              </div>
            </div>
            <div className={classes.createEventVendorsCardEditDeleteWrapper}>
              <IconButton
                onClick={() =>
                  this.editModalToggle(index, !vendor.showEdit, false)
                }
              >
                <Create />
              </IconButton>
              <IconButton onClick={() => this.setDelete(true, false, index)}>
                <Delete />
              </IconButton>
            </div>
          </Card>
          <Modal
            open={this.state.allVendors[index].showEdit}
            onClose={() => this.editModalToggle(index, !vendor.showEdit, false)}
          >
            <Box className={classes.createEventVendorModal}>
              <div className={classes.createFAQAdAndDeleteModal}>
                <div>
                  <div className={classes.createEventFAQModalHeader}>
                    <IconButton
                      onClick={() =>
                        this.editModalToggle(index, !vendor.showEdit)
                      }
                    >
                      <Close height={30} width={30} />
                    </IconButton>
                    <p className={classes.createEventFAQModalHeaderText}>
                      {Text.EditVendor}
                    </p>
                    {this.addExtraSpaceToBalance()}
                  </div>
                  {this.vendorsTextFieldWithLabel(
                    "Vendor Name",
                    "Enter vendor's name",
                    true,
                    false,
                    vendor.vendorName,
                    index
                  )}
                  {this.vendorsTextFieldWithLabel(
                    "Vendor Description",
                    "Enter vendor's description",
                    false,
                    true,
                    vendor.vendorDescription,
                    index
                  )}
                  <div className={classes.createEventVendorsAddLogowithType}>
                    <div
                      style={{ margin: "0px 30px 0px 0px", display: "grid" }}
                    >
                      <p className={classes.createEventVendorsAddLogoLabel}>
                        {Text.vendorLogo}
                      </p>
                      <input
                        style={{ display: "none" }}
                        id={"eventVendorLogo" + index}
                        multiple={true}
                        type="file"
                        accept="image/*"
                        onInput={(e) => this.uploadPicture(e, index)}
                        onClick={(event) => {
                          event.target.value = null;
                        }}
                      />

                      <label htmlFor={"eventVendorLogo" + index}>
                        {vendor.vendorLogo ? (
                          <img
                            className={classes.createEventVendorImage}
                            src={
                              this.state.allVendors[index].showEdit
                                ? this.state.currentVendor.vendorLogo
                                : vendor.vendorLogo
                            }
                            component={"span"}
                          />
                        ) : (
                          <Button
                            className={classes.createEventVendorsAddLogo}
                            variant={"outlined"}
                            component={"span"}
                          >
                            {Text.AddPlus}
                          </Button>
                        )}
                      </label>
                    </div>
                    {this.vendorsTypeDropdown(false)}
                  </div>
                  {this.vendorsExtraFields(
                    "Expand for additional options",
                    index
                  )}
                </div>
                <div
                  className={classes.createEventVendorsButtonWrapper}
                  id="eventCommonModal"
                >
                  <Button
                    className={classes.createEventFAQCancelFAQButton}
                    onClick={() =>
                      this.editModalToggle(index, !vendor.showEdit, false)
                    }
                  >
                    {Text.Cancel}
                  </Button>
                  <Button
                    className={classes.createEventVendorsAddVendorButton}
                    onClick={() => this.editModalToggle(index, false, true)}
                    variant={"contained"}
                    disabled={
                      !(
                        (this.state.currentVendor.vendorName.trim() &&
                        this.state.currentVendor.vendorDescription &&
                        this.state.currentVendor.vendorWebsite === ""
                          ? true
                          : pattern.test(
                              this.state.currentVendor.vendorWebsite
                            ))
                        // &&
                        // this.state.currentVendor.vendorDescription
                        //   .getCurrentContent()
                        //   .getPlainText("\u0001")
                        //   .trim()
                      )
                    }
                    style={{
                      backgroundColor: !(
                        (this.state.currentVendor.vendorName.trim() &&
                        this.state.currentVendor.vendorDescription &&
                        this.state.currentVendor.vendorWebsite === ""
                          ? true
                          : pattern.test(
                              this.state.currentVendor.vendorWebsite
                            ))

                        //  &&
                        // this.state.currentVendor.vendorDescription
                        //   .getCurrentContent()
                        //   .getPlainText("\u0001")
                        //   .trim()
                      )
                        ? "rgba(163, 56, 164,.4)"
                        : "rgb(163, 56, 164)",
                      color: "white",
                    }}
                  >
                    {Text.Save}
                  </Button>
                </div>
                {this.errorMessage()}
              </div>
            </Box>
          </Modal>
        </React.Fragment>
      );
    });
  };

  vendorsExtraFields = (textFieldName, index) => {
    const { classes } = this.props;
    const { showOptionalFields, allVendors } = this.state;
    const showFields =
      index != null ? allVendors[index].showOptionalFields : showOptionalFields;

    console.log(
      `allVendors[index].showOptionalFields`,
      index != null && allVendors[index].showOptionalFields
    );
    return (
      <React.Fragment>
        <Grid className={classes.createEventVendorsGridWrapper}>
          <span style={{ display: "flex" }}>
            <p
              className={classes.createEventVendorsTextInputLabel}
              style={{ color: "#a338a4" }}
            >
              {textFieldName}
            </p>
            <IconButton
              style={{
                transform: showFields ? "rotate(180deg)" : "rotate(0deg)",
                // margin: "30px 0px 0px 0px",
              }}
              onClick={() =>
                index != null
                  ? this.setState({
                      allVendors: allVendors.map((vendor, i) =>
                        i === index
                          ? {
                              ...allVendors[i],
                              showOptionalFields: !showFields,
                            }
                          : allVendors[i]
                      ),
                    })
                  : this.setState({ showOptionalFields: !showFields })
              }
            >
              <ExpandMore />
            </IconButton>
          </span>
          <Collapse
            in={showFields}
            style={{ overflow: "inherit" }}
            unmountOnExit={true}
            timeout={0}
          >
            <FormControl style={{ display: "flex" }}>
              {this.vendorsTextFieldWithLabel(
                "Vendor Website (Optional)",
                "Enter vendor's website",
                false,
                false,
                false,
                index != null ? index : -1
              )}
              {this.vendorsTextFieldWithLabel(
                "Vendor Address (Optional)",
                "Enter vendor's address",
                false,
                false,
                false,
                index != null ? index : -1
              )}
              {this.vendorsTextFieldWithLabel(
                "Vendor Phone Number (Optional)",
                "Enter vendor's phone number",
                false,
                false,
                false,
                index != null ? index : -1
              )}

              {this.vendorsTextFieldWithLabel(
                "Vendor's Email (Optional)",
                "Enter vendor's email",
                false,
                false,
                false,
                index != null ? index : -1
              )}
            </FormControl>
          </Collapse>
        </Grid>
      </React.Fragment>
    );
  };

  uploadPicture = async (e, index) => {
    e.preventDefault();
    e.stopPropagation();
    const { currentVendor } = this.state;
    const vendorLabels = Events.Vendors.error;

    const file = e.target.files[0];
    if (file !== undefined) {
      if (
        file.type === "image/png" ||
        file.type === "image/jpg" ||
        file.type === "image/jpeg"
      ) {
        if (file.size <= 10 * 1000000) {
          const reader = new FileReader();
          reader.onload = async (event) => {
            const img = new Image();
            img.src = event.target.result;
            img.onload = async () => {
              const maxWidth = 220; // Maximum width for the resized image
              const maxHeight = 140; // Maximum height for the resized image
              let width = img.width;
              let height = img.height;

              // Calculate new width and height to maintain aspect ratio
              if (width > height) {
                if (width > maxWidth) {
                  height *= maxWidth / width;
                  width = maxWidth;
                }
              } else {
                if (height > maxHeight) {
                  width *= maxHeight / height;
                  height = maxHeight;
                }
              }

              // Create a canvas element to draw the resized image
              const canvas = document.createElement('canvas');
              canvas.width = width;
              canvas.height = height;
              const ctx = canvas.getContext('2d');
              ctx.drawImage(img, 0, 0, width, height);

              // Convert the canvas content to base64 encoded data
              const resizedEncodedData = canvas.toDataURL('image/jpeg'); // Change format if needed

              this.setState({
                currentVendor: { ...currentVendor, vendorLogo: resizedEncodedData },
              });
            };
          };
          reader.readAsDataURL(file);

          // const encodedData = await converter.toBase64(file);
          //
          // this.setState({
          //   currentVendor: { ...currentVendor, vendorLogo: encodedData },
          // });
        } else {
          this.setState({
            error: { show: true, message: vendorLabels.message },
          });
        }
      } else {
        console.log("Invalid file type");
        this.setState({
          error: { show: true, message: "Invalid file type" },
        });
      }
    } else {
      console.log("User didn't upload anything");

      this.setState({
        error: { show: true, message: "User didn't upload anything" },
      });
    }
  };

  handleError = (error) => {
    this.setState({
      error: { show: false, message: "" },
    });
  };

  errorMessage = () => (
    <CreateEventErrorMessage
      state={this.state.error}
      close={this.handleError}
    />
  );
  vendorDeleteModal = () => {
    const { classes } = this.props;
    const deleteVendor = Events.deleteAd("Vendor");
    return (
      <Modal
        open={this.state.deleteModal.show}
        onClose={() => this.setDelete(false, false)}
      >
        <Box className={classes.createEvenVendorDeleteVendorModal}>
          <div className={classes.createEventVendorImgWrapper}>
            <img
              src={deleteVendor.img}
              alt="Delete Icon"
              className={classes.createEventVendorImg}
            />
          </div>

          <p style={{ textAlign: "center" }}>{deleteVendor.message}</p>

          <div>
            <Button
              className={classes.createVendorDeleteVendorButton}
              onClick={() => this.setDelete(true, false)}
              style={{ color: "blue" }}
            >
              {Text.Cancel}
            </Button>

            <Button
              className={classes.createVendorDeleteVendorButton}
              onClick={() => this.setDelete(false, true)}
              style={{ color: "red" }}
            >
              {Text.Delete}
            </Button>
          </div>
        </Box>
      </Modal>
    );
  };
  render() {
    const { classes } = this.props;
    const { allAds, createAdModal, loader } = this.state;
    const { currentVendor } = this.state;
    console.log("this.state.urlError", this.state.urlError);
    return (
      <React.Fragment>
        <ContentGrid
          content={
            <Grid className={classes.createEventVendorsGrid}>
              <section
                className={classes.eventDetailsVendorsMainWrapper}
                style={{ pointerEvents: loader ? "none" : "auto" }}
              >
                <EventHeader title={"Vendors"}>
                  <div
                    className={
                      classes.createEventAgendaAddSessionDaysButtonWrapper
                    }
                  >
                    <Button
                      className={classes.createEventAgendaAddSessionDaysButton}
                      onClick={() => {
                        this.handCreateAdModal(true);
                      }}
                      variant={"contained"}
                    >
                      <span
                        className={
                          classes.createEventAgendaAddSessionDaysButtonText
                        }
                      >
                        {Text.AddVendor}
                      </span>
                      <AddIcon className={classes.createEventVendorPlusIcon} />
                    </Button>
                  </div>
                </EventHeader>
                <div className={classes.createEventVendorsWrapper}>
                  <Modal
                    open={createAdModal}
                    onClose={() => this.handCreateAdModal(false)}
                  >
                    <Box className={classes.createEventVendorModal}>
                      <div className={classes.createFAQAdAndDeleteModal}>
                        <div>
                          <div className={classes.createEventFAQModalHeader}>
                            <IconButton
                              onClick={() => this.handCreateAdModal(false)}
                            >
                              <Close height={30} width={30} />
                            </IconButton>
                            <p
                              className={classes.createEventFAQModalHeaderText}
                            >
                              {Text.AddVendor}
                            </p>
                            {this.addExtraSpaceToBalance()}
                          </div>
                          <div
                            className={classes.createEventVendorsFormWrapper}
                          >
                            {this.vendorsTextFieldWithLabel(
                              "Vendor Name",
                              "Enter vendor's name",
                              true,
                              false,
                              false,
                              -1
                            )}
                            {this.vendorsTextFieldWithLabel(
                              "Vendor Description",
                              "Enter vendor's description",
                              false,
                              true,
                              false,
                              -1
                            )}
                            <div
                              className={
                                classes.createEventVendorsAddLogowithType
                              }
                            >
                              <div
                                style={{
                                  display: "grid",
                                }}
                              >
                                <p
                                  className={
                                    classes.createEventVendorsAddLogoLabel
                                  }
                                >
                                  {Text.VendorLogo}
                                </p>
                                <input
                                  style={{ display: "none" }}
                                  id="eventVendorLogo"
                                  multiple={true}
                                  type="file"
                                  accept="image/*"
                                  onInput={(e) => this.uploadPicture(e, -1)}
                                  onClick={(event) => {
                                    event.target.value = null;
                                  }}
                                />

                                <label htmlFor="eventVendorLogo">
                                  {currentVendor.vendorLogo ? (
                                    <img
                                      className={classes.createEventVendorImage}
                                      src={currentVendor.vendorLogo}
                                      component={"span"}
                                    />
                                  ) : (
                                    <Button
                                      className={
                                        classes.createEventVendorsAddLogo
                                      }
                                      variant={"outlined"}
                                      component={"span"}
                                    >
                                      {Text.AddPlus}
                                    </Button>
                                  )}
                                </label>
                              </div>
                              {this.vendorsTypeDropdown(false)}
                            </div>

                            {this.vendorsExtraFields(
                              "Expand for additional options",
                              null
                            )}
                          </div>
                        </div>
                        <div
                          className={classes.createEventVendorsButtonWrapper}
                          id="eventCommonModal"
                        >
                          <Button
                            className={classes.createEventFAQCancelFAQButton}
                            onClick={() => this.handCreateAdModal(false)}
                          >
                            {Text.Cancel}
                          </Button>
                          <Button
                            className={
                              classes.createEventVendorsAddVendorButton
                            }
                            variant={"contained"}
                            onClick={() => this.addVendor()}
                            disabled={
                              !(
                                (currentVendor.vendorName.trim() &&
                                currentVendor.vendorDescription &&
                                this.state.currentVendor.vendorWebsite === ""
                                  ? true
                                  : pattern.test(
                                      this.state.currentVendor.vendorWebsite
                                    ))

                                //  &&
                                // currentVendor.vendorDescription
                                //   .getCurrentContent()
                                //   .getPlainText("\u0001")
                                //   .trim()
                              )
                            }
                            style={{
                              backgroundColor: !(
                                (currentVendor.vendorName.trim() &&
                                currentVendor.vendorDescription &&
                                this.state.currentVendor.vendorWebsite === ""
                                  ? true
                                  : pattern.test(
                                      this.state.currentVendor.vendorWebsite
                                    ))

                                // &&
                                // currentVendor.vendorDescription
                                //   .getCurrentContent()
                                //   .getPlainText("\u0001")
                                //   .trim()
                              )
                                ? "rgba(163, 56, 164,.4)"
                                : "rgb(163, 56, 164)",
                              color: "white",
                            }}
                          >
                            {Text.Save}
                          </Button>
                        </div>
                        {this.errorMessage()}
                      </div>
                    </Box>
                  </Modal>

                  <div
                    className="div"
                    style={{
                      flex: 0.95,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {this.ShowAddedAds()}
                  </div>
                  <div className={classes.createEventVendorButtonWrapper}>
                    <Button
                      className={classes.createEventVendorSaveAndContinueButton}
                      variant={"contained"}
                      onClick={() => this.props.history.push("Sponsors")}
                    >
                      {Text.SaveAndContinue}
                    </Button>
                  </div>
                </div>
              </section>
              {this.vendorDeleteModal()}
              {this.progressLoader()}
            </Grid>
          }
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const { eventUserEvents } = state;
  return { eventId: eventUserEvents.eventId, vendors: eventUserEvents.vendors };
};
const matchDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { addVendorServer, deleteVendorServer, updateVendorServer },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(withStyles(styles)(CreateEventVendors));
