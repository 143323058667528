import React from 'react';
import Logo from '../../Helper/Logo/logo';
import { history } from '../../store';
import {routeName} from '../../../src/Service/env';
import './errorPage.css';
import * as constants from "Constants";

const onLogoClick = () => {
    localStorage.clear();
    history.push(`${routeName}`);
};

const ErrorComponent = () =>{
    return(
        <div className='error-container no-data'>
          <span onClick={onLogoClick}><Logo /></span>
            <h2 className='error-text'>{constants.Message.InvalidUrl}</h2>
        </div>
    )
};
export default ErrorComponent;