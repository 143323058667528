import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types';
import * as constants from "Constants";
const MetaDecorator = ({currentEventDetails, url}) => {

  return (
<Helmet>
    <meta property="og:title" content={currentEventDetails?.Title} />
    <meta property="og:description" content={currentEventDetails?.VenueName} />
    <meta property="og:url" content={`${constants.URLs.baseUrl}/event/${currentEventDetails?.EventId}`} />
    <meta property="og:image" content={currentEventDetails?.ImageLarge} />
</Helmet>
  )
}



MetaDecorator.prototype = {
    title:PropTypes.string.isRequired,
    description:PropTypes.string.isRequired,
    imageUrl:PropTypes.string.isRequired,
    imageAlt:PropTypes.string.isRequired
}
export default MetaDecorator;
