import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { FaCalendarAlt } from "react-icons/fa";
import "./datePickerCalender.css";
import dayjs from "dayjs";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import { DayCalendarSkeleton } from "@mui/x-date-pickers/DayCalendarSkeleton";
import { createStyles, makeStyles } from "@material-ui/styles";
import store from "../../../../../../store";

const useStyles = makeStyles((theme) =>
  createStyles({
    selectedDate: {
      backgroundColor: "#c31491 !important",
      color: "white",
      "&:hover": {
        backgroundColor: "#c31491 !important",
        color: "white",
      },
    },
  })
);

const ServerDay = (props) => {
  const {
    day,
    outsideCurrentMonth,
    selected,
    inRange,
    highlightedDates,
    ...other
  } = props;

  const isHighlighted = highlightedDates.some((date) =>
    date.isSame(day, "day")
  );
  const isPastDay = day.isBefore(dayjs(), "day");
  const isDisabled =
    !isHighlighted ||
    isPastDay ||
    (isHighlighted && day.isBefore(dayjs(), "day"));

  const classes = useStyles();

  return (
    <PickersDay
      {...other}
      className={selected || inRange ? classes.selectedDate : undefined}
      outsideCurrentMonth={outsideCurrentMonth}
      day={day}
      selected={selected}
      disabled={isDisabled}
      sx={{
        bgcolor: selected
          ? "#c31491"
          : isHighlighted
          ? isPastDay
            ? "#f9d7f7d9"
            : "#ff1dbec4"
          : undefined,
        color: isHighlighted && !isPastDay ? "white" : undefined,
        "&:hover": {
          bgcolor: isHighlighted && !isPastDay ? "#ff1dbee0" : undefined,
          color: isHighlighted && !isPastDay ? "white" : undefined,
        },
      }}
    />
  );
};

const DatePickerCalendar = ({
  selectedDate,
  setSelectedDate,
  item,
  isLoading,
  highlightedDays,
  fetchHighlightedDaysData,
  setSelectedOption,
  setCurrentTicket,
  getRecurringData,
  setSelectedSlot,
  cartItems,
  availableSlots,
  recurringData,
  handleChange,
  selectedOption,
}) => {
  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
    getRecurringData(newDate);

    setSelectedOption({
      qnty: { value: null },
      item,
      selectedDate: newDate,
      selectedSlot: null,
      TicketId: item?.TicketId,
      availableSlots,
    });

    handleChange({ value: 0 }, item, {
      selectedDate: newDate,
      selectedSlot: null,
    });
  };

  const formatDate = (date) => {
    return date.format("MM/DD/YYYY");
  };

  const findEarliestDate = (itemData) => {
    try {
      const dates = JSON.parse(itemData?.AvailableDates);

      if (!dates || !Array.isArray(dates.Dates)) {
        return false;
      }

      const formattedDates = dates.Dates.map((date) =>
        dayjs(date).format("DD/MM/YYYY")
      );

      if (formattedDates.length > 0) {
        let earliestDate = moment(formattedDates[0], "DD/MM/YYYY");
        for (let i = 1; i < formattedDates.length; i++) {
          let currentDate = moment(formattedDates[i], "DD/MM/YYYY");
          if (currentDate.isBefore(earliestDate)) {
            earliestDate = currentDate;
          }
        }
        const SelectedEarliestDate = earliestDate.format("MM/DD/YYYY");
        return SelectedEarliestDate;
      }

      // const formattedDate = moment(
      //   item.ActiveDate,
      //   "MM/DD/YYYY hh:mm:ss A"
      // ).format("MM/DD/YYYY");

      // return formattedDate;
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return false;
    }
  };

  useEffect(() => {
    const earliestDate = dayjs(findEarliestDate(item));
    const currentDate = dayjs(); // Get the current date

    const selectedHighlightDate = findFirstFutureHighlightedDate(item);
    const hasFutureHighlightedDate = selectedHighlightDate !== null;

    // Set the selectedDate only if there are no future highlighted dates
    if (!hasFutureHighlightedDate || currentDate.isAfter(earliestDate)) {
      setSelectedDate(null);
    } else {
      setSelectedDate(earliestDate);
    }
  }, []);

  useEffect(() => {
    fetchHighlightedDaysData();
  }, [setSelectedDate]);

  const findFirstFutureHighlightedDate = (itemData) => {
    try {
      const dates = JSON.parse(itemData?.AvailableDates);

      if (!dates || !Array.isArray(dates.Dates)) {
        return null;
      }

      const formattedDates = dates.Dates.map((date) =>
        dayjs(date).format("DD/MM/YYYY")
      );

      if (formattedDates.length > 0) {
        const currentDate = dayjs();
        for (let i = 0; i < formattedDates.length; i++) {
          const highlightedDate = dayjs(formattedDates[i], "DD/MM/YYYY");
          if (highlightedDate.isAfter(currentDate, "day")) {
            return highlightedDate;
          }
        }
      }

      return null;
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return null;
    }
  };

  const handleMonthChange = () => {
    fetchHighlightedDaysData();
  };

  return (
    <div className="calenderDiv">
      <div className="calenderHeader">
        <p>{selectedDate ? formatDate(selectedDate) : "MM/DD/YYYY"}</p>
        <p>
          <FaCalendarAlt size={18} />
        </p>
      </div>
      <style>
        {`.css-rhmlg1-MuiTypography-root-MuiDayCalendar-weekDayLabel {
          font-size: 14px;
          color: white;
        }`}
      </style>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div style={{ fontSize: "inherit" }}>
          <DateCalendar
            className="calender"
            disablePast
            sx={{ width: "300px" }}
            views={["year", "month", "day"]}
            value={selectedDate}
            onChange={handleDateChange}
            loading={isLoading}
            onMonthChange={handleMonthChange}
            renderLoading={() => <DayCalendarSkeleton />}
            slots={{
              day: ServerDay,
            }}
            slotProps={{
              day: {
                highlightedDates: highlightedDays,
              },
            }}
          />
        </div>
      </LocalizationProvider>
    </div>
  );
};

export default DatePickerCalendar;
