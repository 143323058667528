import { validateEmail } from "Helper/Validations";
import React, { useState } from "react";
import { axiosE } from "Service/service";
import { Button, Chip, FormControl, Grid, TextField } from "@material-ui/core";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import "react-multi-email/style.css";
import { Text } from "Constants";

const Invitation = ({
  subject,
  emailBody,
  titleWithToolTip,
  UserId,
  limits,
}) => {
  const [emails, setEmails] = useState([]);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const InputEmail = {
    border: "1px solid #ccc",
    padding: "10px 10px",
    borderRadius: "5px",
    width: "40%",
  };
  const SendInviteEmail = () => {
    if (
      emails.length >
      Number(limits.MonthlySendingEmailQuota) -
        Number(limits.TotalCurrentMonthEmailSent)
    ) {
      setError("Your monthly limit might have been exhausted");
      setTimeout(() => {
        setError("");
      }, 5000);
    } else {
      axiosE
        .post("/QueueEventInvitationTestEmail", {
          Subject: subject,
          Body: emailBody,
          EmailAddress: emails.join(", "),
          OrganizerId: UserId,
        })
        .then(({ data }) => {
          if (data.results) {
            setSuccess("Your test email is on its way to the test recipients");
            setTimeout(() => {
              setSuccess("");
            }, 5000);
          } else {
            setError("Your monthly limit might have been exhausted");
            setTimeout(() => {
              setError("");
            }, 5000);
          }
        })
        .catch(() => {});
    }
  };

  return (
    <>
      <div>
        <span style={{ marginBottom: 10 }}>
          {titleWithToolTip(
            "Send a test invitation to:",
            "Test your email by sending one to yourself before blasting it out to your subscribers"
          )}
        </span>
        <div style={{ marginBottom: 20 }} className="input-box">
          {/* <form autoComplete="off"> */}
          <ReactMultiEmail
            // onDisabled={true}

            placeholder="Enter Emails"
            emails={emails}
            onChange={(_emails) => {
              setEmails(_emails);
            }}
            validateEmail={(email) => {
              return isEmail(email); // return boolean
            }}
            style={{ width: "100%", marginTop: 10 }}
            getLabel={(email, index, removeEmail) => {
              return (
                <Chip
                  label={email}
                  onDelete={() => removeEmail(index)}
                  variant="outlined"
                  style={{
                    margin: "2px",
                    fontSize: "11.5px",
                  }}
                />
              );
            }}
            className={"eventDetailsPromoteLongInputField"}
          />
          {/* </form> */}

          {error !== "" ? (
            <div
              style={{
                color: "rgb(255, 48, 48)",
                fontSize: 16,
                marginTop: 5,
                marginBottom: 5,
              }}
            >
              {error}
            </div>
          ) : (
            ""
          )}
          {success !== "" ? (
            <div
              style={{
                color: "rgb(0, 171, 85)",
                fontSize: 16,
                marginTop: 5,
                marginBottom: 5,
              }}
            >
              {success}
            </div>
          ) : (
            ""
          )}
          {emails?.length > 10 ? (
            <div
              style={{
                color: "rgb(255, 48, 48)",
                fontSize: 16,
                marginTop: 5,
                marginBottom: 5,
              }}
            >
              {Text.SendEmailLimitText}
            </div>
          ) : null}
          {Number(limits.TotalCurrentMonthEmailSent) >=
          Number(limits.MonthlySendingEmailQuota) ? (
            <div className="limit_exceeds">
              <p>
                {Text.monthlyLimit} <b>{Text.PhoneNumber}</b>{" "}
                {Text.ToIncreaseYourLimit}
              </p>
            </div>
          ) : (
            <button
              style={{
                backgroundColor:
                  !emails.length > 0 || emails.length > 10 ? "#eeedf2" : "#fff",
                marginTop: 15,
              }}
              disabled={!emails.length > 0 || emails.length > 10}
              onClick={SendInviteEmail}
              className="send-btn"
            >
              {Text.Send}
            </button>
          )}
        </div>
      </div>
      <style jsx="true">
        {`
          .invitation {
            margin-top: 10px;
          }
          .send-btn {
            background-color: #fff;
            border: 1px solid #ccc;
            border-radius: 5px;
            padding: 10px 30px;
            font-weight: 600;
          }
        `}
      </style>
    </>
  );
};

export default Invitation;
