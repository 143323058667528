import { applyMiddleware, createStore, compose } from "redux";
import { routerMiddleware } from "react-router-redux";
import { createBrowserHistory } from "history";
import thunk from "redux-thunk";
import indexReducer from "./Reducers/index";

// Persisted Store
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
const persistedConfig = {
  key: "persisted-key",
  storage,
};
const persistedReducer = persistReducer(persistedConfig, indexReducer);

export const history = createBrowserHistory();
const enhancers = [];

const middleware = [thunk, routerMiddleware(history)];
if (process.env.NODE_ENV === "development") {
  const devToolsExtension =
    window.__REDUX_DEVTOOLS_EXTENSION__ &&
    window.__REDUX_DEVTOOLS_EXTENSION__();

  if (typeof devToolsExtension === "function") {
    enhancers.push(devToolsExtension);
  }
}
const composeEnhancers = compose(applyMiddleware(...middleware), ...enhancers);
const store = createStore(persistedReducer, composeEnhancers);
const persistor = persistStore(store);
export default store;
export { persistor };
