import _ from 'lodash';
import {DISABLE_LOADER, ENABLE_LOADER} from '../Actions/actionTypes';

const initial = {
    show: false
};
export default (state=initial,action) => {
    switch(action.type) {
        case ENABLE_LOADER:
            state.show = true;
            return _.cloneDeep({...state});
        case DISABLE_LOADER:
            state.show = false;
            return _.cloneDeep({...state});
        default:
            return {...state};
    }
};

