import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import './gridWrapper.css';

const styles={
    parentGrid:{
        width:'100%'
    }
};

class GridWrapper extends React.Component {
    constructor(props){
        super(props);
        this.state={
            open:true
        }
    }
    render(){
        const { classes } = this.props;

        return(
            <Grid container spacing={24} className={classes.parentGrid}>
                {(this.props.appView || this.props.appView==="")?<Grid item sm={12} xs={12} md={8}>
                        <section className='header-section'>
                            <div>
                                {this.props.header}
                            </div>
                            <div>
                                {this.props.content}
                            </div>
                        </section>
                    </Grid>
                    :
                    <Grid item sm={12} xs={12} md={12}>
                        <section className='header-section'>
                            <div>
                                {this.props.header}
                            </div>
                            <div>
                                {this.props.content}
                            </div>
                        </section>
                    </Grid>
                }
                <Grid item sm={12} xs={12} md={4}>
                    <section className={this.props.appView?'appView-section-with-border':'appView-section-no-border'}>
                        {this.props.appView}
                    </section>
                </Grid>

            </Grid>
        )
    }
}
GridWrapper.propTypes={
    header:PropTypes.node,
    content:PropTypes.node,
    appView:PropTypes.node
};
export default withStyles(styles)(GridWrapper);