import moment from "moment";
import { default as momentTz } from "moment-timezone";
import React, { useLayoutEffect } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useMemo } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import OrderSummary from "../ticketOrderSummary/OrderSummary";
import "./payment.css";
import { red } from "@mui/material/colors";
import Checkbox from "@mui/material/Checkbox";
import {
  GetAllOrderFormFieldsByEventId,
  ticktesCartItem,
  PersistShoppingCartSession,
  GetDiscountData,
  getPersistShopingCartData,
  clearCartSessionDiscountId,
} from "Actions/eventActions";
import { IoClose } from "react-icons/io5";
import * as constants from "Constants";
import { CircularProgress } from "@material-ui/core";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentForm from "./PaymentForm";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import CheckoutForm from "./PaymentForm";
import { axiosE } from "Service/service";
import { routeName } from "Service/env";
import { history } from "store";
import { IoArrowBack } from "react-icons/io5";
import Success from "../SuccessFull/Success";
import TimeOutScreen from "../SuccessFull/TimeOutScreen";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import { validatePhoneNo } from "Helper/Validations";
import { PERSIST_SHOPING_CART_DATA } from "Actions/actionTypes";
import store from "../../../../../store";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { parsePhoneNumberWithError, ParseError } from 'libphonenumber-js';
import Linkify from 'react-linkify';

const libphonenumber = require('libphonenumber-js');

const countryFormatMap = {
  US: '(XXX) XXX-XXXX',
  CA: '(XXX) XXX-XXXX',
  GB: 'XXXXX XXXXXX',
  IN: 'XX XXXX-XXXX',
  // Add more countries and their phone number formats as needed
};

const Payment = ({
                   setOpenCheckOut,
                   currentEventDetails,
                   GetAllOrderFormFieldsByEventId,
                   dispatch,
                   results,
                   cartItems,
                   ticktesCartItem,
                   setContinueToCheckOut,
                   isLoggedIn,
                   shoppingCartDetails,
                   PersistShoppingCartSession,
                   discountData,
                   minutes,
                   seconds,
                   paymentIntent,
                   setMinutes,
                   newCartItemsData,
                   setNewCartItemsData,
                   ticketQty,
                   Token = null,
                   recurringData,
                   hasCheckoutQuery,
                 }) => {
  const [publicKey, setPublicKey] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState([]);
  const stripePromise =
      paymentIntent?.PublicKey !== null
          ? loadStripe(paymentIntent?.PublicKey)
          : null;
  const [isChecked, setIsChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [leaveCheckOut, setLeaveCheckOut] = useState(false);
  const [payment_id, setPayment_id] = useState("");
  const [isFreeTicket, setIsFreeTicket] = useState(false);
  const [showOrderSummary, setShowOrderSummary] = useState(false);
  const [isNotFilled, setIsNotFilled] = useState(false);
  const [checkboxValue, setCheckBoxValue] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [freeBtndisabled, setFreeBtndisabled] = useState(false);
  const [DiscountCodeExist, setDiscountCodeExist] = useState(false);
  // const [disableDialCode, setDisableDialCode] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('us'); // Default country code
    const [previousCountry, setPreviousCountry] = useState("us");
    const [newCartItems, setNewCartItems] = useState([]);

    useEffect(() => {
    // Fetch the user's IP address
    fetch(constants.API.Geolocation_V2)
        .then((response) => response.json())
        .then((data) => {
          // Extract the country code from the IP address data
          const countryCode = data.country.toLowerCase();
          setSelectedCountry(countryCode); // Set the default country
        })
        .catch((error) => {
          console.log('Error fetching IP address:', error);
        });
  }, []);

  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };

  // Define a function to handle phone number change
    const handlePhoneNumberChange = (value, country) => {
        try {
            // Detect if the country has changed
            if (country.countryCode !== previousCountry) {
                setPreviousCountry(country.countryCode);
                setSelectedCountry(country.countryCode);
                setPhoneNumber(`${country.dialCode}`);
                setIsNotFilled(true);
                setPhoneError("");
                return;
            }

            // Remove all non-numeric characters from the value
            value = value.replace(/\D/g, "");

            const countryCode = `${country.dialCode}`;

            // Check if the value starts with the country's dial code
            if (!value.startsWith(countryCode)) {
                value = countryCode + value;
            }

            // Ensure the input value has a minimum length before parsing
            if (value.length >= 6) {
                const phoneNumberObject = parsePhoneNumberWithError(value, country.countryCode.toUpperCase());
                if (phoneNumberObject && phoneNumberObject.isValid()) {
                    // Valid phone number
                    setIsNotFilled(false);
                    setPhoneNumber(value);
                    setPhoneError("");

                    let newData = [...additionalInfo];
                    if (newData) {
                        let foundObject = newData.find((item) => item.FieldName === 'Phone');
                        if (foundObject) {
                            foundObject.DefaultValue = value;
                        }
                        setAdditionalInfo(newData);
                    }
                } else {
                    // Invalid phone number
                    setIsNotFilled(true);
                    setPhoneError("Please enter a valid phone number.");
                }
            } else {
                // Input doesn't meet minimum length
                setIsNotFilled(true);
                setPhoneError("Please enter a valid phone number.");
            }

            if (selectedCountry !== country.countryCode) {
                setIsNotFilled(true);
                setPhoneError("Phone no. is required");
            }
        } catch (error) {
            if (error instanceof ParseError) {
                // Handle parsing error
                console.log(error.message);
            } else {
                throw error;
            }
        }
    };

    const getPlaceholder = (countryCode) => {
        const phoneNumber = '0000000000';
        const formattedNumber = libphonenumber.formatNumber(phoneNumber, countryCode, 'INTERNATIONAL');
        return formattedNumber;
    };

    const memoizedPlaceholder = useMemo(() => getPlaceholder(selectedCountry ? selectedCountry.toUpperCase() : 'US'), [selectedCountry]);

  useEffect(() => {
    //Find index of specific object using findIndex method.
    let objIndex = additionalInfo?.findIndex(
        (obj) => obj?.FieldName === "Terms And Conditions"
    );
    //Update object's name property.
    // if (objIndex > 0) {
    //   additionalInfo[objIndex].DefaultValue = isChecked;
    // }
  }, [isChecked]);

  const checkboxItem = additionalInfo.find(
      (x) => x.FieldName === "Terms And Conditions"
  );

  useEffect(() => {
    if (
        additionalInfo?.some((i) =>
            i?.FieldName === "Terms And Conditions" && i?.DefaultValue === ""
                ? false
                : i?.DefaultValue === ""
                    ? true
                    : false
        )
    ) {
      setIsNotFilled(true);
    } else {
      console.log(
          "@@@@",
          checkboxItem && checkboxItem.DefaultValue !== "" && !isChecked
      );
      if (checkboxItem && checkboxItem.DefaultValue !== "" && !isChecked) {
        setIsNotFilled(true);
      } else {
          if (
              additionalInfo?.some((i) =>
                  (i?.FieldName === "Full Name" || i?.FieldName === "Email") && (i?.IsValid === false)
          )) {
              setIsNotFilled(true);
          } else {
              setIsNotFilled(false);
          }
      }
    }

    if (checkboxItem) {
      setCheckBoxValue(checkboxItem.DefaultValue);
    }


  }, [additionalInfo, checkboxItem, isChecked]);



  // console.log("additionalInfo",additionalInfo);
  let obj = {};
  const data = additionalInfo.map(({ FieldName, DefaultValue }) =>
      Object.assign(obj, {
        [FieldName.replace(/\s/g, FieldName === "Full Name" ? "" : "_")]:
        DefaultValue,
      })
  );
  obj.Phone = obj?.Phone?.replace(/[^\d]+/g, "");
  if (obj.hasOwnProperty("TermsAndConditions")) {
    let specialChars =
        /[\\"/\#\$\%\&\(\)\*\+\,\-\.\:\;\<\=\>\?\@\[\]\^\`\{\|\}\~\'\!]/g;
    obj.TermsAndConditions = obj.TermsAndConditions.replace(
        specialChars,
        "\\$&"
    );
  }

  localStorage.setItem("additionalInfo", JSON.stringify(obj));

  const createPaymentIntent = async (additional) => {
    try {
      const payload = {
        EventId: currentEventDetails?.EventId,
        SessionId: shoppingCartDetails?.CartSession.SessionId,
        PaymentIntentId: paymentIntent?.paymentId,
        OrderAdditionalData: additional
            ? localStorage.getItem("additionalInfo")
            : null,
      };
      const { data } = await axiosE.post("CreatePaymentIntent", payload);
      setClientSecret(data.results.ClientSecret);
      dispatch({
        type: "PAYMENT_INTENT_DETAILS",
        payload: {
          PublicKey: data.results.PublicKey,
          paymentId: data.results.Id,
        },
      });

      localStorage.setItem("public_key", data.results.PublicKey);
      setPublicKey(data.results.PublicKey);
      setLoading(false);
    } catch (error) {}
  };

  const appearance = {
    theme: "stripe",
  };

  const options = {
    clientSecret,
    appearance,
  };

  // useEffect(() => {
  //   if (minutes === 0 && seconds === 0) {
  //     console.log("Time Out", minutes, seconds);
  //     // setContinueToCheckOut(false)
  //   }
  // }, [minutes, seconds]);

  const clearCartItem = () => {
    dispatch(ticktesCartItem([]));
  };

  const handleChange = (e) => {
    let { name, value, id } = e.target;
    let newData = [...additionalInfo];

      // Validation for full name
      if (name === "Full Name") {
          if (!/^(?=.*[A-Za-z])[\w\s'-]+ [A-Za-z\d\s'-]+$/.test(value)) {
              // If the entered value doesn't match the pattern, handle it accordingly
              setIsNotFilled(true);
              newData[id]["IsValid"] = false;
              newData[id]["ErrorMessage"] = "Please enter a valid first and last name.";
          } else {
              setIsNotFilled(false);
              newData[id]["IsValid"] = true;
              newData[id]["ErrorMessage"] = "";
          }
      } else if (name === "Email") {
          // Validation for email
          // You can use a regular expression or any other method to validate the email format
          // Here, I'm using a simple format validation for demonstration
          if (!/\S+@\S+\.\S+/.test(value)) {
              setIsNotFilled(true);
              newData[id]["IsValid"] = false;
              newData[id]["ErrorMessage"] = "Please enter a valid email address.";

          } else {
              setIsNotFilled(false);
              newData[id]["IsValid"] = true;
              newData[id]["ErrorMessage"] = "";
          }
      }

    // if (name === "Phone") {
    //   if (!/[a-zA-Z]/.test(value)) {
    //     console.log("!/[a-zA-Z]/.test(value)", !/[a-zA-Z]/.test(value));
    //     let cleaned = "" + value.replace(/\D/g, "");
    //     for (let index = 0; index < cleaned.length; index++) {
    //       if (index == 0) {
    //         value = "(";
    //       } else if (index == 3) {
    //         value = value + ") ";
    //       } else if (index == 6) {
    //         value = value + "-";
    //       }
    //       value = value + cleaned[index];
    //     }
    //   } else {
    //     value = newData[id]["DefaultValue"];
    //   }
    //   setPhoneError(validatePhoneNo(value));
    // }

    newData[id]["DefaultValue"] = value;
    setAdditionalInfo(newData);
  };

    const handleInput = (e) => {
        e.target.style.height = 'auto'; // Reset height
        e.target.style.height = `${e.target.scrollHeight}px`; // Set height based on scroll height
        handleChange(e); // Call the existing handleChange function to handle other logic
    };

  const handlePersistCart = (response, error) => {
    if (response) {
      // Response handling logic
      if (
          response.CartSession.Total === null ||
          Number(response.CartSession.Total) === 0
      ) {
        setClientSecret("");
        setPublicKey("");
        setIsFreeTicket(true);
        setLoading(false);
      } else {
        createPaymentIntent(false)
            .then((/* handle the response from createPaymentIntent */) => {
              // Update the isFreeTicket state to false
              setIsFreeTicket(false);
            })
            .catch((err) => {
              // Handle errors from createPaymentIntent if needed
            });
      }
    } else if (error) {
      // Error handling logic
      ErrorInPersist();
    }
  }

  const ErrorInPersist = () => {
    console.log("ErrorInPersist");
    const data = {
      DiscountType: "",
      disCountAmount: 0.0,
      disCountId: "",
      TicketsId: [],
      RemainingUtilization: "",
      ResponseCode: false,
      DiscountCode: "",
    };
    dispatch({ type: "REMAINING_UTILIZATION", payload: null });
    // if DiscountType is Percentage
    dispatch(GetDiscountData(data));
    dispatch(ticktesCartItem([]));
    dispatch({
      type: "PAYMENT_INTENT_DETAILS",
      payload: { PublicKey: null, paymentId: null },
    });
    dispatch({
      type: "START_TIMMER",
      payload: false,
    });
    // For restart cartSession
    try {
      axiosE
          .post("ShoppingCart/Delete", {
            SessionId: shoppingCartDetails?.CartSession?.SessionId,
          })
          .then(() => {
            dispatch({ type: "CREATE_SHOPPING_DETAILS", payload: null });
            setContinueToCheckOut(false);
          })
          .catch(() => {});
    } catch (error) {}
  };

  const OrderAdditionalData = () => {
    dispatch(GetAllOrderFormFieldsByEventId(currentEventDetails?.EventId))
        .then((data) => {
          if (data.results.ResponseCode) {
            const defaultAdditionalData = data.results.ListOrderFormField.map(
                (item) => {
                  const obj = Object.assign({}, item);
                  if (
                      item.FieldName === "Full Name" &&
                      results?.ProfileDetail?.FirstName !== null
                  ) {
                    obj["DefaultValue"] = results?.ProfileDetail?.FirstName;
                  }
                  if (
                      item.FieldName === "Email" &&
                      results?.ProfileDetail?.Email !== null
                  ) {
                    obj["DefaultValue"] = results?.ProfileDetail?.Email;
                  }
                  if (
                      item.FieldName === "Phone" &&
                      results?.ProfileDetail?.PhoneNumber !== null
                  ) {
                    let fomatedNumber = "";
                    if (results?.ProfileDetail?.PhoneNumber) {
                      //     let cleaned =
                      //       "" + results?.ProfileDetail?.PhoneNumber.replace(/\D/g, "");
                      //     for (let index = 0; index < cleaned.length; index++) {
                      //       if (index == 0) {
                      //         fomatedNumber = "(";
                      //       } else if (index == 3) {
                      //         fomatedNumber = fomatedNumber + ") ";
                      //       } else if (index == 6) {
                      //         fomatedNumber = fomatedNumber + "-";
                      //       }
                      //       fomatedNumber = fomatedNumber + cleaned[index];
                      //     }
                      fomatedNumber = results?.ProfileDetail?.PhoneNumber
                    }
                    obj["DefaultValue"] = fomatedNumber;
                  }
                  console.log("$$$$$$$$", obj, results?.ProfileDetail?.PhoneNumber);
                  return obj;
                }
            );
            setAdditionalInfo(defaultAdditionalData);
          }
        })
        .catch(() => {});
  };

  const convertAndAdjustDatetime = (utcDate, utcTime) => {
    const targetTimezone = JSON.parse(localStorage.getItem("CurrentTimeZone"));
    // if (targetTimezone === "Asia/Kolkata") {
      const isItMomentObject = utcDate && typeof utcDate === 'object' && typeof utcDate.format === 'function'
      const selectedDate = isItMomentObject ? utcDate.format("DD/MM/YYYY") : utcDate;
    const utcDateTime = momentTz.utc(
        `${selectedDate} ${utcTime}`,
        "DD/MM/YYYY HH:mm:ss"
    );
    const convertedDateTime = momentTz.tz(utcDateTime, targetTimezone);

    if (convertedDateTime.date() > utcDateTime.date()) {
      const revertedDateTime = momentTz
          .tz(utcDateTime, "UTC")
          .subtract(1, "day");
      return revertedDateTime.format("MM/DD/YYYY");
    } else if (convertedDateTime.date() < utcDateTime.date()) {
      const revertedDateTime = momentTz.tz(utcDateTime, "UTC").add(1, "day");
      return revertedDateTime.format("MM/DD/YYYY");
    }

    return convertedDateTime.format("MM/DD/YYYY");
    // }

    // return moment(utcDate, "DD/MM/YYYY").format("MM/DD/YYYY");
  };

    const sortSelectedSlots = (item) => {
        if (item.selectedSlots) {
            item.selectedSlots.sort((a, b) => {
                // Convert date strings to JavaScript Date objects for comparison
                const dateA = parseDate(a.date, a.StartTime);
                const dateB = parseDate(b.date, b.StartTime);

                // Compare dates
                if (dateA < dateB) {
                    return -1;
                } else if (dateA > dateB) {
                    return 1;
                } else {
                    // If dates are equal, compare start times
                    const timeA = parseInt(a.StartTime.replace(':', ''));
                    const timeB = parseInt(b.StartTime.replace(':', ''));

                    return timeA - timeB;
                }
            });
        }
    };

    // Parse date in DD/MM/YYYY format and time in HH:mm format
    const parseDate = (date, time) => {
        const [day, month, year] = date.split('/');
        const hours = time ? parseInt(time.split(':')[0]) : 0;
        const minutes = time ? parseInt(time.split(':')[1]) : 0;
        // Months are zero-based in JavaScript Date, so we subtract 1 from the month
        return new Date(year, month - 1, day, hours, minutes);
    };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (results?.ProfileDetail?.UserId) {
        setLoading(true);
          cartItems.forEach((item) => sortSelectedSlots(item));
          const data = {
              ShoppingCart: {
                  CartSession: {
                      EventId: currentEventDetails?.EventId,
                      SessionId: shoppingCartDetails?.CartSession.SessionId,
                      DiscountId: discountData?.disCountId,
                      UserId: results?.ProfileDetail?.UserId,
                      Salt: "",
                      ValidationCode: "",
                  },
                  CartItems: cartItems.flatMap((cartItem) => {
                          const {
                              Quantity,
                              TicketId,
                              ItemId,
                              RecurringTicket,
                              selectedDate,
                              selectedSlots,
                              selectedSlot,
                              isBundle,
                          } = cartItem;

                          const RecurringTicketValue = RecurringTicket === "True" ? "true" : "false";

                          if (isBundle) {
                              // If it's a bundle, iterate through selectedSlots
                              return selectedSlots.map((slot) => ({
                                  Quantity,
                                  TicketId,
                                  // ItemId: slot.ItemId,
                                  RecurringTicket: RecurringTicketValue,
                                  BookingDate: convertAndAdjustDatetime(slot.date, slot.StartTime),
                                  BookingTime: slot.StartTime,
                                  isBundle: isBundle ? 1 : 0
                              }));
                          } else {
                              // If it's not a bundle, return a single item
                              return {
                                  Quantity,
                                  TicketId,
                                  ItemId,
                                  RecurringTicket: RecurringTicketValue,
                                  BookingDate: RecurringTicket === "True" ? convertAndAdjustDatetime(selectedDate, selectedSlot?.StartTime) : "",
                                  BookingTime: RecurringTicket === "True" ? selectedSlot?.StartTime : "",
                              };
                          }
                      }
                  ),
              },
          };

          dispatch(getPersistShopingCartData(data));

        if (cartItems?.length > 0) {
          setTimeout(() => {
            dispatch(PersistShoppingCartSession(data))
                .then((res) => {
                  if (res.ResponseCode) {
                    localStorage.setItem(
                        "CurrentSessionId",
                        JSON.stringify(res.CartSession.SessionId)
                    );
                    setDiscountCodeExist(res.CartSession.DiscountExist);
                    if (res?.CartItems?.every((e) => e.ResponseCode === true)) {

                        // Update the existing array in place
                        const updatedCartItems = cartItems.map((item) => {
                            let updatedSelectedSlots;

                            if (item.selectedSlots !== undefined) {
                                updatedSelectedSlots = item.selectedSlots.map((slot) => {
                                    const matchingCartItem = res?.CartItems?.find(
                                        (cartItem) =>
                                            moment(cartItem.BookingDate, 'MM/DD/YYYY').format('DD/MM/YYYY') === slot.date &&
                                            cartItem.BookingTime === slot.StartTime
                                    );

                                    if (matchingCartItem) {
                                        return {
                                            ...slot,
                                            ItemId: matchingCartItem.ItemId,
                                        };
                                    }

                                    return slot;
                                });
                            }

                            return {
                                ...item,
                                ...(updatedSelectedSlots && { selectedSlots: updatedSelectedSlots }),
                            };
                        });

                        setNewCartItems(updatedCartItems);

                      OrderAdditionalData();
                      dispatch({
                        type: "START_TIMMER",
                        payload: true,
                      });

                      if (
                          res.CartSession.Total === null ||
                          Number(res.CartSession.Total) === 0
                      ) {
                        setIsFreeTicket(true);
                        setLoading(false);
                      } else {
                        createPaymentIntent(false);
                      }
                    } else {
                      ErrorInPersist();
                    }
                  } else {
                    ErrorInPersist();
                  }
                })
                .catch((e) => {
                  console.log(e);
                });
          }, 1000);
        }
      }
    }
    return () => {
      isMounted = false;
    };
  }, []);

  const PersistFreeOrder = () => {
    // localStorage.setItem("additionalInfo", JSON.stringify(obj))
      newCartItems.forEach((item) => sortSelectedSlots(item));
    const data = {
      ShoppingCart: {
        CartSession: {
          EventId: currentEventDetails?.EventId,
          SessionId: shoppingCartDetails?.CartSession.SessionId,
          DiscountId: discountData?.disCountId,
          UserId: results?.ProfileDetail?.UserId,
          OrderAdditionalData: localStorage.getItem("additionalInfo"),
        },
        CartItems: newCartItems.flatMap(
            ({
               Quantity,
               TicketId,
               ItemId,
               RecurringTicket,
               selectedDate,
               selectedSlot,
               selectedSlots,
               isBundle
             }) => {
                const RecurringTicketValue = RecurringTicket === "True" ? "true" : "false";

                if (isBundle) {
                    // If it's a bundle, iterate through selectedSlots
                    return selectedSlots.map((slot) => ({
                        Quantity,
                        TicketId,
                        // ItemId: slot.ItemId,
                        RecurringTicket: RecurringTicketValue,
                        BookingDate: convertAndAdjustDatetime(slot.date, slot.StartTime),
                        BookingTime: slot.StartTime,
                        isBundle: isBundle ? 1 : 0
                    }));
                } else {
                    return {
                        Quantity,
                        TicketId,
                        ItemId,
                        RecurringTicket: RecurringTicketValue,
                        BookingDate: RecurringTicket === "True" ? convertAndAdjustDatetime(selectedDate, selectedSlot?.StartTime) : "",
                        BookingTime: RecurringTicket === "True" ? selectedSlot?.StartTime : "",
                    };
                }
            }
        ),
      },
    };
    // if (cartItems?.length > 0) {
    axiosE
        .post("PersistFreeOrder", data)
        .then(({ data }) => {
          if (data.results.ResponseCode) {
            history.push(`${routeName}/success`);
          }
          setFreeBtndisabled(false);
        })
        .catch((e) => {
          setFreeBtndisabled(false);
          console.log(e);
        });
    // }
  };
  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  }
  const [width, height] = useWindowSize();
    // For sorting additional fields
    if (additionalInfo.length > 0) {
    ["Phone", "Email", "Full Name"].map((item) => {
      const index = additionalInfo?.findIndex((object) => {
        return object?.FieldName === item;
      });
      const toIndex = 0;
      const element = additionalInfo.splice(index, 1)[0];
      additionalInfo.splice(toIndex, 0, element);
    });
  }

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank');
    newWindow.opener = null;
  };

  return (
      <>
        {localStorage.getItem("payment") === "success" ? (
            history.replace(`${routeName}/event/${currentEventDetails.EventId}`)
        ) : minutes === 0 && seconds === 0 ? (
            <TimeOutScreen
                setMinutes={setMinutes}
                setOpenCheckOut={setOpenCheckOut}
                setContinueToCheckOut={setContinueToCheckOut}
                hasCheckoutQuery={hasCheckoutQuery}
            />
        ) : leaveCheckOut ? (
            <Success
                setOpenCheckOut={setOpenCheckOut}
                setLeaveCheckOut={setLeaveCheckOut}
            />
        ) : (
            <React.Fragment>
              {loading ? (
                  <div
                      style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                  >
                    <CircularProgress size={50} color="secondary" />
                  </div>
              ) : (
                  <div className="payment-container">
                    <div class="checkout-container">
                      <div class="main-heading">
                  <span className="main-heading-back">
                    {" "}
                    <IoArrowBack
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setContinueToCheckOut(false);
                          dispatch(clearCartSessionDiscountId());
                          const data = {
                            DiscountType: "",
                            disCountAmount: 0.0,
                            disCountId: "",
                            TicketsId: [],
                            RemainingUtilization: "",
                            ResponseCode: false,
                            DiscountCode: "",
                          };
                          dispatch(GetDiscountData(data));

                          let storeData = store.getState();
                        }}
                        size={25}
                    />
                  </span>
                        <h3>{constants.Text.Checkout}</h3>

                        {
                          <small>
                            {constants.Text.TimeLeft}{" "}
                            {minutes < 10 ? `0${minutes}` : minutes}:
                            {seconds < 10 ? `0${seconds}` : seconds}
                          </small>
                        }
                        {width < 800 && !Token && !hasCheckoutQuery ? (
                            <div
                                onClick={() => setLeaveCheckOut(true)}
                                className="order_close"
                                style={{ top: 16 }}
                            >
                              <IoClose size={20} />
                            </div>
                        ) : null}

                        {/* <small>{timeBetween.minutes()}:{timeBetween.seconds()}</small> */}
                      </div>
                      <main
                          className="payment_main"
                          style={{
                            height: isFreeTicket ? "77%" : width > 800 ? "90%" : "80%",
                          }}
                      >
                        <div class="main-card">
                          <div class="info">
                            <p style={{textAlign:"left"}}>
                              {constants.Text.ClickingPlaceOrder}
                              <a style={{ color: "#1894ff" }}
                                 target="_blank"
                                 href={`${routeName}/terms`}
                              >
                                {" "}
                                {constants.Text.TermsOfService}{" "}
                              </a>
                            </p>
                          </div>
                          {additionalInfo.length > 0 && (
                              <div>
                                <div class="additional-information">
                                  <h1>{constants.Text.AdditionalInformation}</h1>
                                  <p>{constants.Text.AdditionalTermsDesc}<b style={{color:"rgb(244, 67, 54)",  fontWeight:"600"}}>{constants.Text.AllFieldsMustBeFilledOut}</b></p>
                                </div>

                                <div className="input-group">
                                  {additionalInfo?.map(({ FieldName, FormFieldId, DefaultValue, Status, IsValid, ErrorMessage }, index) =>
                                      FieldName === "Terms And Conditions" ? null : (
                                          <div className="input-6-outer"
                                               style={{
                                            position: "relative",
                                            marginBottom: 10,
                                            // overflow: "auto",
                                          }} >
                                              {FieldName !== "Full Name" && FieldName !== "Email" ? (
                                                  FieldName === "Phone" ? (
                                                      <div>
                                                          <span style={{ color: "red" }}>* </span>Phone Number
                                                      </div>
                                                  ) : (
                                                      <div>
                                                          <span style={{ color: "red" }}>* </span>{FieldName}
                                                      </div>
                                                  )
                                              ) : null}
                                            <div className="input-6">
                                              {FieldName === "Phone" ? (
                                                  <div className="phone-input-container">
                                                    <PhoneInput
                                                        country={selectedCountry}
                                                        value={phoneNumber}
                                                        onChange={(value, country) => handlePhoneNumberChange(value, country)}
                                                        inputProps={{
                                                          name: 'phone',
                                                          required: true,
                                                          placeholder: memoizedPlaceholder,
                                                        }}
                                                        containerStyle={{ borderWidth: 0 }} // Optional: To remove the border of the parent div
                                                        inputStyle={{
                                                          border: 'none',
                                                          marginLeft: '50px',
                                                          width:'80%'
                                                          // background: 'aliceblue'
                                                        }}
                                                        enableSearch={true}
                                                        countryCodeEditable={false}
                                                        // disableCountryCode={disableDialCode}
                                                    />
                                                  </div>
                                              ) : (
                                                  <div  className = "div-with-scroll">
                                                      {FieldName === "Full Name" || FieldName === "Email" || FieldName === "Phone" ? (
                                                          <input
                                                              className="add_info_input"
                                                              id={index.toString()}
                                                              name={FieldName}
                                                              value={DefaultValue}
                                                              key={FormFieldId}
                                                              type="text"
                                                              required={true}
                                                              // maxLength="260"
                                                              onChange={(e) => handleChange(e)}
                                                          />
                                                      ) : (
                                                          <textarea
                                                              className="add_info_textarea"
                                                              id={index.toString()}
                                                              name={FieldName}
                                                              value={DefaultValue}
                                                              key={FormFieldId}
                                                              required={true}
                                                              onChange={(e) => handleChange(e)}
                                                              onInput={(e) => handleInput(e)}
                                                              rows="1"  // Start with a single row
                                                          />
                                                      )
                                                      }
                                                    {DefaultValue === "" && (
                                                        <label
                                                            htmlFor={index.toString()}
                                                            style={{
                                                              position: "absolute",
                                                              // top: "-5px",
                                                              left: 4,
                                                              fontSize: 15,
                                                              margin: 0,
                                                              fontWeight: 400,
                                                              color: "#b1b1b1",
                                                              paddingTop: "0.5%",
                                                              minHeight: "33px",
                                                            }}
                                                        >
                                                            {FieldName !== "Full Name" && FieldName !== "Email" && FieldName !== "Phone" ? (
                                                                "Type your answer"
                                                            ) : (
                                                                <>
                                                                  <span
                                                                      style={{
                                                                          color: "red",
                                                                          fontSize: "medium",
                                                                      }}
                                                                  >
                                                                    *
                                                                  </span>{" "}
                                                                  {FieldName} {/* Correct placement of FieldName */}
                                                                </>
                                                            )}
                                                        </label>
                                                    )}
                                                  </div>
                                              )}
                                            </div>
                                            {FieldName === "Phone" &&
                                                (phoneError &&
                                                    <div style={{color: "red"}}>
                                                      {phoneError}
                                                    </div>
                                                )}
                                              {(FieldName === "Full Name" || FieldName === "Email") && !IsValid && (
                                                      <div style={{color: "red"}}>
                                                          {ErrorMessage}
                                                      </div>
                                                  )}
                                          </div>
                                      )
                                  )}
                                </div>

                                {/*<div class="message-rate">*/}
                                {/*  <p>*A copy of your ticket(s) will be sent to your phone. Message and data rates may apply.</p>*/}
                                {/*</div>*/}
                              </div>
                          )}
                          {additionalInfo.some(
                              (f) => f.FieldName === "Terms And Conditions"
                          )
                              ? checkboxValue !== "" && (
                              <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: 20,
                                    position: "relative",
                                    left: "-10px",
                                  }}
                              >
                                {/* <input
                        type="checkbox"
                        id="topping"
                        name="topping"
                        checked={isChecked}
                        onChange={handleOnChange}
                      /> */}

                                <Checkbox
                                    id="topping"
                                    name="topping"
                                    checked={isChecked}
                                    onChange={handleOnChange}
                                    sx={{
                                      color: red[500],
                                    }}
                                />
                                <div>
                                  <p style={{ color: !isChecked && red[500] }}>
                                    <Linkify
                                        componentDecorator={(decoratedHref, decoratedText, key) => (
                                            <a
                                                href={decoratedHref}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  openInNewTab(decoratedHref);
                                                }}
                                                key={key}
                                            >
                                              {decoratedText}
                                            </a>
                                        )}
                                    >
                                      {checkboxValue}
                                    </Linkify>
                                    {/*<Linkify>{checkboxValue}</Linkify>{" "}*/}
                                  </p>
                                </div>
                              </div>
                          )
                              : null}

                          {clientSecret && paymentIntent?.PublicKey ? (
                              <React.Fragment>
                                <div class="payment">
                                  <h1>{constants.Text.Payment}</h1>
                                  {/* <input type="text" placeholder="Card number*" /> */}
                                </div>
                                <Elements options={options} stripe={stripePromise}>
                                  <CheckoutForm
                                      dispatch={dispatch}
                                      isNotFilled={isNotFilled}
                                      createPaymentIntent={createPaymentIntent}
                                  />
                                </Elements>
                              </React.Fragment>
                          ) : (
                              !isFreeTicket && (
                                  <div
                                      style={{
                                        gap: 10,
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        padding: 20,
                                        borderRadius: 15,
                                        backgroundColor: "#e3fff1",
                                      }}
                                  >
                                    <p
                                        style={{
                                          color: "rgb(0, 171, 85)",
                                          textAlign: "center",
                                        }}
                                    >
                                      {constants.Text.StriptAccountPending}{" "}
                                    </p>
                                    <HiOutlineExclamationCircle
                                        color="rgb(0, 171, 85)"
                                        size={20}
                                    />{" "}
                                  </div>
                              )
                          )}
                        </div>
                      </main>

                      {isFreeTicket ? (
                          <div className="payment-button-free">
                            <div
                                class="purchase-button"
                                style={{ paddingRight: "2.5rem" }}
                            >
                              {width < 800 && cartItems.length > 0 ? (
                                  <a
                                      className="open_summury_btn"
                                      onClick={() => setShowOrderSummary(!showOrderSummary)}
                                      href="#"
                                  >
                                    {showOrderSummary ? "Hide Summary " : "View Summary"}
                                  </a>
                              ) : null}
                              <button
                                  disabled={isNotFilled || freeBtndisabled}
                                  onClick={() => {
                                    setFreeBtndisabled(true);
                                    PersistFreeOrder();
                                  }}
                                  className="purchase-link"
                                  style={{
                                    margin: 0,
                                    backgroundColor:
                                        isNotFilled || freeBtndisabled
                                            ? "#ff91e0"
                                            : "#c31491",
                                    width: 160,
                                    padding: "10px 0px",
                                  }}
                              >
                                {constants.Text.PlaceOrder}
                              </button>
                            </div>
                          </div>
                      ) : (
                          <>
                            {width < 800 && cartItems.length > 0 ? (
                                <div className="payment-button-paid">
                                  <a
                                      className="open_summury_btn"
                                      onClick={() => setShowOrderSummary(!showOrderSummary)}
                                      href="#"
                                  >
                                    {showOrderSummary ? "Hide Summary " : "View Summary"}
                                  </a>
                                </div>
                            ) : null}
                          </>
                      )}
                    </div>

                    <OrderSummary
                        {...{ newCartItemsData, setNewCartItemsData, ticketQty }}
                        Token={Token}
                        showOrderSummary={showOrderSummary}
                        setShowOrderSummary={setShowOrderSummary}
                        isPromo={true}
                        DiscountExist={DiscountCodeExist}
                        setOpenCheckOut={setOpenCheckOut}
                        payment_id={paymentIntent?.paymentId}
                        setLeaveCheckOut={setLeaveCheckOut}
                        onPersistCart={handlePersistCart}
                        hasCheckoutQuery={hasCheckoutQuery}
                    />
                  </div>
              )}
            </React.Fragment>
        )}
      </>
  );
};
const mapStateToProps = (state) => {
  return {
    results: state.loginResponse.results,
    isLoggedIn: state.loginResponse.isLoggedIn,
    currentEventDetails: state.eventReducer.currentEventDetails,
    shoppingCartDetails: state.eventReducer.createshoppingCartDetails,
    cartItems: state.eventReducer.cartData,
    discountData: state.eventReducer.discountData,
    paymentIntent: state.eventReducer.paymentIntent,
  };
};
const matchDispatchToProps = (dispatch) => {
  return {
    ticktesCartItem,
    GetAllOrderFormFieldsByEventId,
    PersistShoppingCartSession,
    dispatch,
  };
};
export default connect(mapStateToProps, matchDispatchToProps)(Payment);
