import {
  LOGIN,
  LOGOUT,
  GET_USER,
  REGISTER,
  UPDATE_NAME,
  UPDATE_USERNAME,
  UPDATE_EMAIL,
  UPDATE_PHONE,
  UPDATE_BIO,
  LOGIN_POP_UP,
  HISTORY,
} from "../Actions/actionTypes";
import { cloneDeep } from "lodash";

const initial = {
  isLoggedIn: false,
  loginPopUp: false,
  loginHistory:"",
  history: "",
  results: {
    FirstName: "",
    UserId: localStorage.getItem("UserId"),
    ProfileImage: "",
  },
};
export default (state = initial, action) => {
  switch (action.type) {
    case LOGIN:
      state.results = action.payload.results;
      state.isLoggedIn = true;
      return cloneDeep({ ...state });
    case HISTORY:
      state.history = action.payload;
      return cloneDeep({ ...state });
    case LOGOUT:
      return initial;
    case GET_USER:
      return cloneDeep({
        ...state,
        results: action.payload.results,
      });
    case LOGIN_POP_UP:
      state.loginHistory = action.payload.path
      state.loginPopUp = action.payload.value;
      return cloneDeep({ ...state });
    case REGISTER:
      state.results = action.payload.results;
      state.isLoggedIn = true;
      return cloneDeep({ ...state });
    case UPDATE_NAME:
      state.results.FirstName = action.payload;
      return cloneDeep({ ...state });
    case UPDATE_USERNAME:
      state.results.UserName = action.payload;
      return cloneDeep({ ...state });
    case UPDATE_EMAIL:
      state.results.Email = action.payload;
      return cloneDeep({ ...state });
    case UPDATE_PHONE:
      state.results.PhoneNo = action.payload;
      return cloneDeep({ ...state });
    case UPDATE_BIO:
      state.results.Bio = action.payload;
      return cloneDeep({ ...state });
    default:
      return state;
  }
};
