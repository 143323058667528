import React, { Component } from "react";
import { Button, Modal, TextField, withStyles } from "@material-ui/core";
import TicketLayout, { ContentLayout } from "./layout";
import EventHeader from "../EventDetails/eventHeader";
import { styleClasses } from "./ticketsStyleClasses";
import payoutLogo from "../../../../images/CreateEvent/Ticket/Payouts/Payout.png";
import coin from "../../../../images/CreateEvent/Ticket/Payouts/coin.png";
import { Box } from "@material-ui/core";
import axiosI, { axiosE } from "../../../../Service/service";
import axios from "axios";
import CreateEventErrorMessage from "../createEventErrorMessage";
import { bindActionCreators } from "redux";
import {
  setRandomCode,
  setStripeUserId,
  updateDescriptor,
} from "../../../../Actions/eventActions";
import { connect } from "react-redux";
import swal from "sweetalert";
import { checkIfEventExists } from "../../../../Helper/Validations";
import { eventApis, Text, Tickets } from "../../../../Constants";
import Loader from "../../../../Helper/loader/loader";

const styles = {
  eventCreateTicketContentWrapper: styleClasses.eventCreateTicketContentWrapper,
  generateHeight: styleClasses.generateHeight,

  // Main Screen Content Wrapper
  mainContentWrapper: { ...styleClasses.mainContentWrapper, flex: "0.95" },

  footerButtonsCancelButton: styleClasses.addUpdateModalButtonCancel,
  footerButtonsSaveButton: styleClasses.addUpdateModalButtonSave,

  welcomeScreenMainWrapper: {
    ...styleClasses.emptyState,
    opacity: "1",
  },
  opacityLow: {
    opacity: "0.4",
  },
  setupPaymentButton: {
    fontSize: "14px",
    padding: "15px 50px",
    textTransform: "none",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "10px",
  },
  paymentMethodsMainWrapper: {
    display: "flex",
    padding: "15px 15px",
    alignItems: "center",
    borderRadius: "10px",
    textAlign: "center",
  },
  paymentMethodsMethod: {
    flex: ".25",
  },
  paymentMethodsDetails: {
    flex: ".5",
  },
  paymentMethodsTiming: {
    flex: ".25",
  },
  footerButtons: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  connectStripMainWrapper: {
    ...styleClasses.emptyState,
    opacity: "1",
    gap: "10px",
  },
  takeMeToStripeButton: {
    ...styleClasses.addUpdateModalButtonSave,
    margin: "10px",
    width: "236px",
    "&:hover": {
      color: "black !important",
    },
  },
  termsAndConditionsModal: {
    top: "50%",
    left: "50%",

    minHeight: "300px",
    display: "flex",
    position: "absolute",
    transform: "translate(-50%, -50%)",
    alignItems: "center",
    borderRadius: "10px",
    flexDirection: "column",
    justifyContent: "flex-start",
    backgroundColor: "#C94BD9",
    maxWidth: "475px",
    width: "95%",
    padding: "20px",
    color: "white",
    textAlign: "center",
  },
  inputTextField: {
    border: "1px solid #ced4da",
    borderRadius: "5px",
    fontSize: "16px",
    padding: "10px",
    margin: "10px 0px 15px 0px",
    width: "100%",
  },
  // Save And Continue Button
  continueButton: styleClasses.continueButton,
  saveAndContinueButtonWrapper: styleClasses.saveAndContinueButtonWrapper,
};
class payouts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 0,
      error: { show: false, message: "" },
      StripeUserId: "",
      stripeConnected: false,
      editing: false,
      currentDescriptor: "",
      stripeConnectedModal: false,
      descriptor: this.props.descriptorName,
      loader: false,
      capability: "",
    };
  }

  progressLoader = () => {
    const { loader } = this.state;

    return loader && <Loader />;
  };

  getEventStatus = (eventId) => {
    try {
      axiosE
        .get(eventApis.getEventStatus + eventId)
        .then((res) => {
          let { StripeAccountStatus, Capability } = JSON.parse(
            res.data.results.Data
          );
          if (res.data.results.ResponseCode) {
            if (StripeAccountStatus) {
              // this.setState({ stripeConnected: true, step: 3 });
              console.log(
                "StripeAccountStatus",
                StripeAccountStatus,
                Capability
              );
              this.setState({
                step: 3,
                StripeUserId: StripeAccountStatus,
                stripeConnected: true,
                capability: Capability,
              });
              this.props.setStripeUserId({
                stripeId: StripeAccountStatus,
              });
            }
          } else {
            setStripeUserId({
              stripeId: "",
            });
            this.setState({
              step: 0,
              StripeUserId: "",
              stripeConnected: false,
              capability: "",
            });
          }
        })
        .finally(() => {
          this.setState({ loader: false });
        });
    } catch (error) {}
  };

  componentDidMount() {
    checkIfEventExists(this.props.history, this.props.eventId);
    let url = window.location.href;
    const { code, stripeId, setStripeUserId, eventId } = this.props;
    const urlParams = new URLSearchParams(url);
    this.setState({ loader: true });
    if (eventId) {
      if (!urlParams.has("code")) {
        this.getEventStatus(eventId);
      } else {
        this.setState({ loader: false });
      }
    }


    if (stripeId) {
      this.setState({ stripeConnected: true, StripeUserId: stripeId, step: 3 });
    } else if (urlParams.has("state")) {
      this.setState({ loader: true });
      if (urlParams.get("state") === code) {
        if (urlParams.has("error_description")) {
          this.setState({
            step: 2,
            error: {
              show: true,
              message: urlParams.get("error_description"),
            },
          });
          this.setState({ loader: false });
        }
        if (urlParams.has("code")) {
          this.updateDescriptorName(this.props.eventName);

          axiosE
            .post(eventApis.OAuthTokenCreate, {
              Code: urlParams.get("code"),
              UserId: localStorage.getItem("UserId"),
              EventId: this.props.eventId,
            })
            .then(({ data }) => {
              if (data.results.ResponseCode) {
                this.handleStripeConnectedModal(true);
                setStripeUserId({
                  stripeId: data.results.StripeAccountStatus,
                });
                this.setState({
                  step: 3,
                  StripeUserId: data.results.StripeAccountStatus,
                  stripeConnected: true,
                  capability: data.results.Capability,
                });
                setTimeout(() => {
                  this.getEventStatus(eventId);
                }, 1000);
                this.setState({ loader: false });
              } else {
                this.setState({
                  step: 2,
                  error: {
                    show: true,
                    message: data.results.Message,
                  },
                });
                this.setState({ loader: false });
              }
            });
        }
      }
      console.log("code is correct");
    }
  }

  errorMessage = () => (
    <CreateEventErrorMessage
      state={this.state.error}
      close={this.handleError}
    />
  );

  handleError = () => {
    this.setState({ error: { show: false, message: "" } });
  };

  welcomeScreen = () => {
    const { classes } = this.props;
    const labels = Tickets.payout.emptyPage;
    return (
      <div className={classes.welcomeScreenMainWrapper}>
        <div className={classes.emptyState}>
          <img src={payoutLogo} alt="Payout" className={classes.opacityLow} />
          <h1 className={classes.opacityLow}>{labels.headline}</h1>
          <p className={classes.opacityLow}>
            {labels.message1} <br /> {labels.message2}
          </p>
          <Button
            color="primary"
            variant="contained"
            className={classes.setupPaymentButton}
            onClick={() => this.setStepState(1)}
          >
            {labels.cta}
          </Button>
        </div>
      </div>
    );
  };

  setStepState = (step) => {
    this.setState({
      step,
    });
  };
  pageStepHeader = () => {
    const { classes } = this.props;
    const { step } = this.state;

    const labels = Tickets.payout.header;
    return (
      <Box
        className={classes.header}
        sx={{
          backgroundColor: "rgb(163, 56, 164)",
          color: "white",
          padding: "15px 15px",
          "& p": {
            margin: 0,
            fontSize: "16px",
          },
        }}
      >
        <p> {step === 3 ? labels.afterAdd : labels.title} </p>
        <p>{step !== 3 ? `Step ${step} of 3` : ""}</p>
      </Box>
    );
  };
  paymentMethods = () => {
    const { classes } = this.props;
    const labels = Tickets.payout.page1;
    return (
      <>
        <Box
          className={classes.paymentMethodsMainWrapper}
          sx={{
            backgroundColor: "#E5E5E5",
          }}
        >
          <div className={classes.paymentMethodsMethod}>
            {labels.col1.title}
          </div>
          <div className={classes.paymentMethodsDetails}>
            {labels.col2.title}
          </div>
          <div className={classes.paymentMethodsTiming}>
            {labels.col3.title}
          </div>
        </Box>
        <Box className={classes.paymentMethodsMainWrapper}>
          <div
            className={classes.paymentMethodsMethod}
            style={{ fontSize: "16px", fontWeight: "600" }}
          >
            {labels.col1.option}
          </div>
          <div className={classes.paymentMethodsDetails}>
            <p>
              <strong>{labels.col2.option.line1Head}</strong>
              {labels.col2.option.line1}
              time.
            </p>
            <p>
              <strong>{labels.col2.option.line2Head}</strong>
              {labels.col2.option.line2}
            </p>
            <p>
              <strong>{labels.col2.option.line3Head}</strong>
              {labels.col2.option.line3}
            </p>
          </div>
          <div className={classes.paymentMethodsTiming}>
            <p>{labels.col3.option.line1}</p>
            <a
              href={labels.col3.option.url}
              style={{ fontSize: "14px" }}
              target="_blank"
            >
              {labels.col3.option.line2}
            </a>
          </div>
        </Box>
      </>
    );
  };

  updateDescriptorName = (name) => {
    const { eventId, updateDescriptor } = this.props;
    updateDescriptor({
      EventId: eventId,
      PaymentDescriptor: name,
    });
  };

  footerButtons = () => {
    const { classes } = this.props;
    return (
      <div className={classes.footerButtons}>
        <Button
          className={classes.footerButtonsCancelButton}
          onClick={() => this.setStepState(0)}
        >
          {Text.Cancel}
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.footerButtonsSaveButton}
          onClick={() => this.setStepState(2)}
        >
          {Text.Next}
        </Button>
      </div>
    );
  };
  connectStrip = () => {
    const { classes } = this.props;
    const labels = Tickets.payout.page2;
    return (
      <div className={classes.connectStripMainWrapper}>
        <strong style={{ fontSize: "18px" }}>{labels.title}</strong>
        <p>
          {labels.message1} <br /> {labels.message2}
        </p>
        <a href="#" class="stripe-connect" onClick={() => this.connectStrip2()}>
          <span>{labels.cta}</span>
        </a>
        {/* </StripeCheckout> */}
      </div>
    );
  };

  getRandomCode = () => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < 15; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  connectStrip2 = async (page) => {
    const rCode = this.getRandomCode();
    const { setRandomCode } = this.props;
    setRandomCode(rCode);
    window.open(Tickets.payout.connectUrl(rCode));
  };
  stripeInfo = () => {
    const { classes } = this.props;
    const { editing, descriptor, currentDescriptor, StripeUserId, capability } =
      this.state;
    const labels = Tickets.payout.page3;

    return (
      <>
        <div>
          <Box sx={{ marginBottom: "10px" }}>
            <p
              style={{
                color:
                  StripeUserId.toUpperCase() === "AUTHORISED" &&
                  capability.toUpperCase() !== "INACTIVE" &&
                  capability.toUpperCase() !== "PENDING"
                    ? "green"
                    : StripeUserId.toUpperCase() === "AUTHORISED"
                    ? "red"
                    : StripeUserId.toUpperCase() === "PENDING"
                    ? "orange"
                    : "red",
              }}
            >
              {" "}
              {StripeUserId.toUpperCase() === "AUTHORISED" &&
              capability.toUpperCase() !== "INACTIVE" &&
              capability.toUpperCase() !== "PENDING"
                ? "Approved, your payouts are now powered by Stripe!"
                : StripeUserId.toUpperCase() === "AUTHORISED"
                ? "Missing Information, before you can sell tickets, please visit your Stripe account and complete any missing information listed on your Stripe dashboard"
                : StripeUserId.toUpperCase() === "PENDING"
                ? "Pending, please wait 60 seconds and refresh this page!"
                : "Deauthorize"}
              {/* {StripeUserId.toUpperCase() === "AUTHORISED"
                ? "Approved, your payouts are now powered by Stripe!"
                : StripeUserId.toUpperCase() === "PENDING"
                ? "Pending, please wait 60 seconds and refresh this page!"
                : capability.toUpperCase() === "INACTIVE" ||
                  capability.toUpperCase() === "PENDING"
                ? "Missing Information, before you can sell tickets, please visit your Stripe account and complete any missing information listed on your Stripe dashboard"
                : "Deauthorize"} */}
            </p>
            {/* <p
              style={{
                fontSize: "16px",
                textTransform: "uppercase",
                fontWeight: 600,
              }}
            >
              {labels.vendor}
            </p>
            <p style={{ opacity: ".7" }}>{labels.vendor}</p> */}
          </Box>
          <Box
            sx={{
              "& p": {
                margin: 0,
                opacity: ".7",
              },
            }}
          >
            <p>{labels.descriptorName}</p>
            <p>
              TIX*{" "}
              {editing ? (
                <TextField
                  required
                  className={classes.inputTextField}
                  value={currentDescriptor?.substring(0, 22)}
                  margin="normal"
                  onChange={(e) =>
                    this.setState({ currentDescriptor: e.target.value })
                  }
                  fullWidth
                  InputProps={{
                    disableUnderline: true,
                    style: { fontSize: "14px" },
                  }}
                  onInput={(e) => {
                    let isSpecial = !/[*\":<>[\]{}`\\()'=;@%#!^$]/.test(
                      e.target.value
                    );
                    e.target.value = isSpecial
                      ? e.target.value.length > 22
                        ? e.target.value.slice(0, 22)
                        : e.target.value
                      : currentDescriptor;
                  }}
                  helperText={`${currentDescriptor?.length}/22`}
                />
              ) : (
                // <input
                //   value={currentDescriptor}
                //   maxLength={22}
                //   onChange={(e) =>
                //     this.setState({ currentDescriptor: e.target.value })
                //   }
                // />
                descriptor
              )}
              <a
                href="#"
                style={{
                  fontSize: "14px",
                  textDecoration: "none",
                  marginLeft: "5px",
                }}
                onClick={() => {
                  if (editing) this.updateDescriptorName(currentDescriptor);
                  this.setState({
                    currentDescriptor: editing ? "" : descriptor,
                    editing: !editing,
                    descriptor: editing ? currentDescriptor : descriptor,
                  });
                }}
              >
                {editing ? " Save" : " Edit"}
              </a>
              {editing ? (
                <a
                  href="#"
                  style={{
                    fontSize: "14px",
                    textDecoration: "none",
                    marginLeft: "5px",
                  }}
                  onClick={() =>
                    this.setState({
                      currentDescriptor: "",
                      editing: !editing,
                    })
                  }
                >
                  {Text.Cancel}
                </a>
              ) : null}
            </p>
          </Box>
        </div>
        <div
          style={{
            textAlign: "center",
            marginTop: "40px",
          }}
        >
          <p> {Text.PayoutsText}</p>
          {/* <p>{labels.footer}</p> */}
          <Button
            variant="contained"
            className={classes.takeMeToStripeButton}
            onClick={() => window.open(labels.link)}
          >
            {labels.cta}
          </Button>
        </div>
      </>
    );
  };
  pageDisplay = () => {
    const { classes } = this.props;
    const { step } = this.state;
    switch (step) {
      case 1:
        return (
          <>
            {this.pageStepHeader()}
            {this.paymentMethods()}
            {this.footerButtons()}
          </>
        );
      case 2:
        return (
          <>
            {this.pageStepHeader()}
            {this.connectStrip()}
          </>
        );
      case 3:
        return (
          <>
            {this.pageStepHeader()}
            {this.stripeInfo()}
          </>
        );
      default:
        return this.welcomeScreen();
    }
  };

  saveAndContinue = () => {
    const { classes, eventType } = this.props;
    const { stripeConnected } = this.state;
    // let continuePro = false;
    // if (eventType === "Paid" && stripeConnected) {
    //   continuePro = true;
    // }
    // if (eventType === "Free") {
    //   continuePro = true;
    // }
    return (
      <div className={classes.saveAndContinueButtonWrapper}>
        <Button
          className={classes.continueButton}
          variant={"contained"}
          onClick={() => this.props.history.push("OrderForm")}
        >
          {Text.Continue}
        </Button>
      </div>
    );
  };

  handleStripeConnectedModal = (state) => {
    this.setState({ stripeConnectedModal: state });
  };
  payoutConnected = () => {
    const { classes } = this.props;
    const { stripeConnectedModal, StripeUserId } = this.state;
    return (
      <Modal
        open={stripeConnectedModal}
        onClose={() => this.handleStripeConnectedModal(false)}
      >
        <Box
          className={classes.termsAndConditionsModal}
          sx={{
            "&:focus-visible": {
              outline: "none",
            },
          }}
        >
          <img
            src={coin}
            style={{
              background: "transparent",
              width: "65px",
            }}
          />
          <p
            style={{
              fontSize: "20px",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            {/* {StripeUserId === "AUTHORISED"
              ? "Approved"
              : StripeUserId === "PENDING"
              ? "IMPORTANT: Your verification is in progress!"
              : "Deauthorize "} */}
            {Text.PoweredByStripe}
          </p>

          <p>
            {/* {StripeUserId === "AUTHORISED"
              ? "your payouts are now powered by Stripe!"
              : StripeUserId === "PENDING"
              ? "Please wait 60 seconds and refresh this page! Once you see STATUS: APPROVED, you are ready to start selling tickets!"
              : "Missing Information, before you can sell tickets, please visit your Stripe account and complete any missing information listed on your Stripe dashboard"}
               */}
            {Text.StripeText}
          </p>
          <Button
            variant="contained"
            onClick={() => this.handleStripeConnectedModal(false)}
            style={{
              marginTop: "55px",
              width: "110px",
              height: "36px",
              fontSize: "16px",
              backgroundColor: "#fff",
              textTransform: "none",
            }}
          >
            {Text.Gotit}
          </Button>
        </Box>
      </Modal>
    );
  };

  render() {
    const { classes } = this.props;
    return (
      <TicketLayout>
        <EventHeader title={"Payouts"}>
          {/* HERE COMES THE HEADER BUTTONS IF ANY */}
        </EventHeader>
        <ContentLayout>
          <div className={classes.mainContentWrapper}>{this.pageDisplay()}</div>
           {this.saveAndContinue()}
        </ContentLayout>
        {this.errorMessage()}
        {this.payoutConnected()}
        {this.progressLoader()}
      </TicketLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const { eventUserEvents } = state;
  return {
    code: eventUserEvents.hashCode,
    eventType: eventUserEvents.eventType,
    stripeId: eventUserEvents.payouts,
    eventName: eventUserEvents.eventsAbout && eventUserEvents.eventsAbout.Title,
    eventId: eventUserEvents.eventsAbout && eventUserEvents.eventId,
    descriptorName:
      eventUserEvents.eventsAbout && eventUserEvents.descriptorName,
  };
};
const matchDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { setRandomCode, setStripeUserId, updateDescriptor },
    dispatch
  );
};
export default connect(
  mapStateToProps,
  matchDispatchToProps
)(withStyles(styles)(payouts));
