import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  useLoadScript,
} from "@react-google-maps/api";

import moment from "moment";
import "./Map.css";
import { Link } from "react-router-dom";
import { routeName } from "Service/env";

function Map({ latlong, cardData }) {
  const [activeMarker, setActiveMarker] = useState(null);

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const OPTIONS = {
    restriction: {
      latLngBounds: { north: 85, south: -85, west: -180, east: 180 },
      strictBounds: true,
    },
    minZoom: 6,
    maxZoom: 18,
  };
  return (
    <GoogleMap
      zoom={11}
      options={OPTIONS}
      center={{ lat: latlong.lat, lng: latlong.lng }}
      mapContainerStyle={{ width: "100%", height: "100vh" }}
    >
      <Marker position={{ lat: latlong.lat, lng: latlong.lng }}></Marker>
      {cardData?.map((item, index) => (
        <InfoWindow
          key={index}
          options={{
            pane: "overlayLayer",
            pixelOffset: new window.google.maps.Size(108, 0),
            alignBottom: true,
            boxStyle: {
              backgroundColor: "red",
              borderRadius: `10px`,
              boxShadow: `3px 3px 10px rgba(0,0,0,0.6)`,
            },
            closeBoxURL: "",
          }}
          position={{
            lat: Number(item?.Latitude),
            lng: Number(item?.Longitude),
          }}
          onCloseClick={() => setActiveMarker(null)}
        >
          
         
          <div style={{ paddingBottom: 50 }}>
            <div
              style={{
                padding: "1px 1px",

                backgroundColor: "#fff",
              }}
            >
               <Link to={`${routeName}event/${item.EventId}`}>
              <div
                style={{
                  marginLeft: 4,
                  marginRight: 10,
                  marginTop: 5,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  style={{ width: 50, height: 50, borderRadius: 50 ,objectFit: 'cover'}}
                  src={item.ImageSmall}
                  alt=""
                />
                <div style={{ marginLeft: 8, width: 200 }}>
                  <h6 style={{ textAlign: "center", marginBottom: 5,color:"#000000" }}>
                    {item?.Title}
                  </h6>
                  <h6 style={{ textAlign: "center", color: "lightgray" }}>
                    {moment(item?.StartDateTime).format("llll")}
                  </h6>
                </div>
                <div class="triangle-5"></div>
              </div>
              </Link>
            </div>
          </div>
       
        </InfoWindow>
      ))}
    </GoogleMap>
  );
}

export default Map;
