import { withStyles } from '@material-ui/styles';
import React from 'react'

import { Info } from "@material-ui/icons";
import { Tooltip } from '@material-ui/core';
const ToolTip = (title, tipText, mandatory = false) => {

     // const [limits, setLimits] = useState("")
     const CreateEventDetailsAboutToolTip = withStyles(() => ({
        tooltip: {
          backgroundColor: "#f5f5f9",
          color: "rgba(0, 0, 0, 0.87)",
          maxWidth: 220,
          fontSize:20,
          border: "1px solid #dadde9",
        },
      }))(Tooltip);
      
      
        return (
          <span style={{ fontSize: 16, fontWeight: "bold", color: "#000000" }}>
            {title}
            {mandatory && <span style={{ color: "red" }}> *</span>}
            <CreateEventDetailsAboutToolTip
              title={<h5>{tipText}</h5>}
            >
              <span
                style={{
                  padding: "0 0 0 10px",
                  cursor: "pointer",
                  color: "rgb(161, 3, 150)",
                }}
              >
                <Info />
              </span>
            </CreateEventDetailsAboutToolTip>
          </span>
        );
    


 
}

export default ToolTip;