import { LinearProgress } from "@material-ui/core";
import React from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { axiosE } from "../../../../../Service/service";
import { useEffect } from "react";
import { useState } from "react";
import { FcOk, FcExternal } from "react-icons/fc";
import { MdDelete } from "react-icons/md";
import { IoCloseSharp } from "react-icons/io5";
import * as constants from "Constants";
import { Button, Modal } from "@material-ui/core";
import { Tooltip } from "@material-ui/core";
const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 6,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#1a90ff",
  },
}))(LinearProgress);

const Contact = ({
  limits,
  UserId,
  error,
  fileName,
  barValue,
  isDataEmpty,
  invalidEmails,
  contactIds,
  unsubscribeEmails,
  setContactIds,
  handleFileChange,
  uploadedContacts,
  totaUploadedEmail,
  duplicateEmails,
  setUploadedContacts,
  getUserplandetails,
  scheduledEmailData,
}) => {
  const [contactsData, setContactsData] = useState([]);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [totalContactsSlected, setTotalContactsSlected] = useState(0);
  const [hideBarValue, sethideBarValue] = useState(false);
  const [limitError, setLimitError] = useState("");
  const [modal, setModal] = useState({ isShow: false, contacts: "" });

  const CreateEventDetailsAboutToolTip = withStyles(() => ({
    tooltip: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: "16px",
      border: "1px solid #dadde9",
    },
  }))(Tooltip);

  const fetchAllContactsbyUserId = async (UserId) => {
    try {
      const { data } = await axiosE.get(`/GetSubscribersFileUserId/${UserId}`);
      if (data.results.ResponseCode) {
        setContactsData(data.results.SubscribersFiles);
      }
    } catch (error) {}
  };

  const selectAllCointacts = (data) => {
    if (selectedContacts.length !== data.length) {
      let num = 0;
      setSelectedContacts(data);
      setTotalContactsSlected(
        data.reduce((a, b) => a + Number(b.TotalEmails), 0)
      );
      setContactIds(data.map((contact) => contact.FileId));
    } else {
      setSelectedContacts([]);
      setTotalContactsSlected(0);
      setContactIds([]);
    }
  };

  useEffect(() => {
    if (Math.floor(barValue) === 100 || Math.floor(barValue) === 99) {
      fetchAllContactsbyUserId(UserId);
      getUserplandetails();
      setTimeout(() => {
        sethideBarValue(true);
      }, 800);
    }
  }, [barValue]);

  useEffect(() => {
    fetchAllContactsbyUserId(UserId);
  }, []);

  useEffect(() => {
    Number(limits.EmailSubscribersLimit) === Number(limits.TotalSubscribers) &&
    Number(limits.TotalCurrentMonthEmailSent) ===
      Number(limits.MonthlySendingEmailQuota)
      ? setLimitError("You have reached your monthly sending limit")
      : Number(limits.EmailSubscribersLimit) === Number(limits.TotalSubscribers)
      ? setLimitError("limit exceeded")
      : setLimitError("");
  }, [limits]);

  const handleChange = (contacts) => {
    if (!selectedContacts.some(({ FileId }) => FileId === contacts.FileId)) {
      setSelectedContacts([...selectedContacts, contacts]);
      setTotalContactsSlected((prev) => prev + Number(contacts.TotalEmails));
      setContactIds([...contactIds, contacts.FileId]);
    } else {
      let freshArray = selectedContacts.filter(
        ({ FileId }) => FileId !== contacts.FileId
      );
      setContactIds(contactIds.filter((id) => id !== contacts.FileId));
      setSelectedContacts([...freshArray]);
      if (totalContactsSlected > 0)
        setTotalContactsSlected((prev) => prev - Number(contacts.TotalEmails));
    }
  };

  const DeleteContacts = async (contacts) => {
    try {
      handleChange(contacts);
      setContactsData(
        contactsData.filter((item) => item.FileId !== contacts?.FileId)
      );
      setModal({ isShow: false, contacts: "" });
      const { data } = await axiosE.post("/DeleteSubscriberFile", {
        FileId: contacts?.FileId,
      });
      if (data.results.ResponseCode) {
        getUserplandetails();
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (!contactsData.length > 0) {
      setContactIds([]);
    }
  }, [contactsData]);

  const scheduleEmailDeleteModal = () => {
    const deleteLabels = constants.Tickets.deleteTicket;
    return (
      <Modal
        open={modal.isShow}
        onClose={() => setModal({ isShow: false, contacts: "" })}
      >
        <div className="delete_modal_wrapper">
          <div
            style={{
              width: "100px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100px",
            }}
          >
            <img
              src={deleteLabels.img}
              alt="Delete Icon"
              style={{ width: 30 }}
              // className={classes.deleteModalImg}
            />
          </div>
          <p style={{ textAlign: "center" }}>
            {constants.Text.DeleteContact}
          </p>
          <div>
            <Button
              // className={classes.deleteModalSaveAndCancelButton}
              onClick={() => {
                setModal({ isShow: false, contacts: "" });

                if (
                  selectedContacts.some(
                    ({ FileId }) => FileId === modal.contacts.FileId
                  )
                ) {
                  handleChange(modal.contacts);
                }
              }}
              style={{
                color: "blue",
                fontSize: "1.5rem",
                padding: "10px 25px",
              }}
            >
              {constants.Text.Cancel}
            </Button>

            <Button
              // className={classes.deleteModalSaveAndCancelButton}
              onClick={() => DeleteContacts(modal.contacts)}
              style={{ color: "red", fontSize: "1.5rem", padding: "10px 25px" }}
            >
              {constants.Text.Delete}
            </Button>
          </div>
        </div>
      </Modal>
    );
  };

  // console.log("contactsData", contactsData);
  return (
    <>
      {scheduleEmailDeleteModal()}
      <div className="checkbox-field">
        <div>
          <input
            checked={
              selectedContacts.length !== contactsData.length
                ? false
                : contactsData.length > 0
                ? true
                : false
            }
            onChange={() => selectAllCointacts(contactsData)}
            className="contacts_checkbox"
            type="checkbox"
          />
          <span style={{ marginLeft: 15 }}>&nbsp;{constants.Text.AllContacts}</span>
        </div>

        <span className="float-right">
          {totalContactsSlected} {constants.Text.ContactsSelected}
        </span>
      </div>

      {contactsData.length > 0 &&
        contactsData.map((contacts) => {
          const isEventInProccess = scheduledEmailData.some(
            ({ Status, SubscriberFileIds }) =>
              SubscriberFileIds === contacts.FileId && Status === "Processing"
          );
          return (
            <div>
              <div
                style={{
                  backgroundColor:
                    uploadedContacts !== "" &&
                    uploadedContacts?.FileId === contacts?.FileId
                      ? "#f4f4f4"
                      : "#fff",
                }}
                className="checkbox-field-1"
              >
                <div
                  style={{ display: "flex", gap: 20, alignItems: "flex-start" }}
                >
                  <input
                    checked={selectedContacts.some(
                      ({ FileId }) => FileId === contacts?.FileId
                    )}
                    onChange={(e) => handleChange(contacts)}
                    className="contacts_checkbox"
                    type="checkbox"
                  />
                  <div>
                    <p className="texts">{contacts?.FileName}</p>
                    <p className="small-texts">{contacts?.TotalEmails}</p>
                    {uploadedContacts !== "" &&
                      uploadedContacts?.FileId === contacts?.FileId && (
                        <span style={{ color: "rgb(0, 171, 85)" }}>
                          {constants.Text.NewContacts}
                        </span>
                      )}
                  </div>
                </div>

                <div
                  className="edit_container_close_btn"
                  // disabled={true}
                  onClick={() => {
                    if (!isEventInProccess) {
                      setModal({ isShow: true, contacts: contacts });
                      if (
                        !selectedContacts.some(
                          ({ FileId }) => FileId === contacts.FileId
                        )
                      ) {
                        handleChange(contacts);
                      }
                    }
                  }}
                >
                  {isEventInProccess ? (
                    <CreateEventDetailsAboutToolTip
                      title={
                        <React.Fragment>
                          You have a scheduled email in process using this
                          contact list.
                        </React.Fragment>
                      }
                    >
                      <div>
                        <MdDelete size={20} color={"#fca9a9"} />
                      </div>
                    </CreateEventDetailsAboutToolTip>
                  ) : (
                    <div>
                      <MdDelete size={20} color={"rgb(255, 48, 48)"} />
                    </div>
                 
                  )}
                </div>
              </div>
              <hr
                style={{
                  backgroundColor: "rgb(244 244 244 / 90%)",
                  margin: "4px 0px",
                }}
              />
            </div>
          );
        })}

      {uploadedContacts !== "" && (
        <div className="uploading_file_container">
          <div className="uploading_file_content">
            <div className="uploading_file_success_msg">
              <span style={{ display: "flex", alignItems: "center", gap: 20 }}>
                <span className="uploading_file_msg">
                  {Math.floor(barValue) === 100 || Math.floor(barValue) === 99
                    ? "Uploaded"
                    : "Uploading"}{" "}
                </span>
                <span>
                  {Math.floor(barValue) === 100 ||
                  Math.floor(barValue) === 99 ? (
                    <FcOk size={20} />
                  ) : (
                    <FcExternal size={20} />
                  )}
                </span>
              </span>
              {!hideBarValue && isDataEmpty?.length > 0 ? null : (
                <div
                  className="edit_container_close_btn"
                  onClick={() => {
                    setUploadedContacts("");
                    sethideBarValue(false);
                  }}
                >
                  <IoCloseSharp size={20} />
                </div>
              )}
            </div>
            {fileName !== "" && (
              <small style={{ fontSize: 13, color: "#000", fontWeight: 600 }}>
                {fileName}
              </small>
            )}
            {Math.floor(barValue) === 100 && (
              <div className="uploading_file_success_inserted_emails">
                <p>{constants.Text.EmailsAdded} {totaUploadedEmail}</p>
                <p>{constants.Text.DuplicateEmails} {duplicateEmails}</p>
                <p>{constants.Text.InvalidEmails} {invalidEmails}</p>
                <p>{constants.Text.UnsubscribedEmails} {unsubscribeEmails}</p>
              </div>
            )}
            {hideBarValue && !isDataEmpty?.length > 0 ? null : (
              <div style={{ width: "100%", marginTop: 10 }}>
                <BorderLinearProgress variant="determinate" value={barValue} />
              </div>
            )}
          </div>
        </div>
      )}

      {limitError !== "" ? (
        <div className="limit_exceeds">
          <p>
           {constants.Text.LimitReached} <b>{constants.Text.PhoneNumber}</b> {constants.Text.ToIncreaseYourLimit}
          </p>
        </div>
      ) : null}

      {error !== "" ? (
        <div className="limit_exceeds">
          {error === constants.Text.CheckYourFile ? (
            <p>{constants.Text.CheckYourFile}</p>
          ) : (
            <>
         
            {
              error === "file-error" ? <p>{constants.Text.InvalidCsv}</p> : 
              <p>
              {constants.Text.AccountLimit1} <b>{error}</b> {constants.Text.AccountLimit2}
              <b>{constants.Text.PhoneNumber}</b> {constants.Text.ToIncreaseYourLimit}
            </p>
            }
              </>
          )}
        </div>
      ) : null}

      {limitError !== "" ? null : (
        <div className="upload">
          <input
            disabled={isDataEmpty?.length > 0}
            id="upload_file"
            style={{ display: "none" }}
            type="file"
            accept=".xls, .xlsx, .csv"
            onChange={(e) => {
              handleFileChange(e);
              e.target.value = null;
              sethideBarValue(false);
            }}
            className="default-file-input"
          />
          <label
            style={{
              color: isDataEmpty?.length > 0 ? "#faa5fa" : "rgb(165 0 147)",
              fontSize: 14,
              marginTop: 20,
            }}
            className="input_field_label"
            for="upload_file"
          >
            {constants.Text.UploadContacts}
          </label>
        </div>
      )}
    </>
  );
};

export default Contact;
