import {
    ALL_LIVE_CHAT,
    LIVE_CHAT_SENT_MESSAGE_ACK,
    LIVE_CHAT_REMOVE_BUSINESS_MESSAGE,
    HIDE_LIVE_CHAT_POP_UP,
    SCROLL_LIVE_CHAT,
    INCREASE_LIVE_CHAT_INDEX,
    LOGOUT,
    IS_EVENT_EXPERIENCE_CHAT_LOADING,
    SET_LOAD_EARLIER_MESSAGES
} from '../Actions/actionTypes';
import _ from 'lodash';
import sound from "../Sounds/liveChat.mp3";
import { audioFunc } from "../Service/helper";

const initial = {
    liveChatIndex: 0,
    showLiveChatPopUp: false,
    chatState: "LOADING",
    loadEarlierMessageState:"TRUE"
};

export default (state = initial, action) => {
    switch (action.type) {
        case ALL_LIVE_CHAT:
            let AllliveChatScroll = false;
            let allData = state.liveChat;
            if (allData) {
                let tempStore = allData;
                if ((state.chatReceived) < action.payload.totalCount) {
                    allData = allData.reverse();
                    allData = allData.concat(action.payload.chats);
                    allData = allData.reverse();
                    if (allData.length > state.totalData) {
                        allData = tempStore;
                    }
                }
            }
            else {
                allData = action.payload.chats.reverse();
                AllliveChatScroll = true;
            }
            console.log("AGAIN TRIGGRED",initial.loadEarlierMessageState);
            return _.cloneDeep({ liveChat: allData, totalData: action.payload.totalCount, chatReceived: allData.length, scroll: AllliveChatScroll, chatState: action.payload.chats.length ? 'FETCHED' : !action.payload.chats.length ? 'EMPTY' : 'LOADING',
            loadEarlierMessageState: "FALSE"
            });
        case LIVE_CHAT_SENT_MESSAGE_ACK:
            audioFunc(false, false, sound)

            // var audio = new Audio();                     // create audio wo/ src
            // audio.preload = "auto";                      // intend to play through
            // audio.autoplay = true;                       // autoplay when loaded
            // audio.loop = false;
            // audio.muted = false;
            //
            // audio.src =  sound ; // just for example

            let sent_message_ack = {
                CommentDetail: JSON.stringify(action.payload.chat_message),
                CommentId: action.payload.chat_id,
                CreatedDate: action.payload.chat_time,
                First_Name: action.payload.firstname,
                GooglePlacedId: action.payload.googlePlacedId,
                Photo: action.payload.photo,
                UserId: action.payload.user_id,
                UserName: action.payload.firstName,
                message_type: action.payload.message_type
            };
            if (state.liveChat) {
                state.liveChat.push(sent_message_ack);
            }
            let UserId = localStorage.getItem('UserId');
            let scroll = action.payload.user_id === UserId;
            let chatElement = document.getElementsByClassName('live-chat-panel');
            if (chatElement.length > 0) {
                if ((chatElement[0].clientHeight + chatElement[0].scrollTop + 5) > chatElement[0].scrollHeight) {
                    scroll = true;
                }
            }
            return _.cloneDeep({ ...state, scroll: scroll });
        case LIVE_CHAT_REMOVE_BUSINESS_MESSAGE:
            let deleteIndex = _.findIndex(state.liveChat, (chat) => chat.CommentId === action.payload.chatId);
            if (state.liveChat && (deleteIndex !== -1)) {
                state.liveChat.splice(deleteIndex, 1);
            }
            return _.cloneDeep({ ...state });
        case HIDE_LIVE_CHAT_POP_UP:
            return _.cloneDeep({ ...state, showLiveChatPopUp: action.payload });
        case SCROLL_LIVE_CHAT:
            return _.cloneDeep({ ...state, scroll: action.payload });
        case INCREASE_LIVE_CHAT_INDEX:
            state.liveChatIndex = state.liveChatIndex + 1;
            return _.cloneDeep({ ...state });
        case LOGOUT:
            state = {
                liveChatIndex: 0
            };
            return _.cloneDeep({ ...state });
        case IS_EVENT_EXPERIENCE_CHAT_LOADING:
            return _.cloneDeep({
                ...state,
                chatState: action.payload
            })
        case SET_LOAD_EARLIER_MESSAGES:
            console.log("CHANGE TRIGGERED",action.payload);
            return _.cloneDeep({
                ...state,
                loadEarlierMessageState: action.payload
            })
        default:
            return state;
    }
};
