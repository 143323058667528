import React from "react";
import Modal from "@material-ui/core/Modal";
import Card from "../../../../Helper/CardWrapper/cardWrapper";
import WebView from "../../../BusinessPortal/Messages/WebView";
const userMessagesModalStyles = () => {
  return {
    position: "fixed",
    top: window.screen.Height < 1100 ? "10%" : "5%",
    width:
      window.screen.width < 1100
        ? window.screen.width < 850
          ? "84%"
          : "55%"
        : "",
    left:
      window.screen.width < 1100
        ? window.screen.width < 850
          ? "8%"
          : "22%"
        : "30%",
    right:
      window.screen.width < 1100
        ? window.screen.width < 850
          ? "8%"
          : "22%"
        : "30%",
    borderRadius: "8px",
    backgroundColor: "white",
  };
};

const updateState = (state, value) => {
  this.setState({
    [state]: value,
  });
};


const UserMessagesModal = ({ messagesModalIsOpen, UserId, organizer, closeUserMessagesModal, messageData }) => {
  //   const userId = results.ProfileDetail.UserId;
  let selectedChatIndex = -1;
  if (messagesModalIsOpen) {
    messageData.recentChat.forEach((message, index) => {
      if (organizer?.OrganizerId
        + "" === message.userid) {
        selectedChatIndex = index;
      }
    });
  }
  let showInfo = false;



  return (
    <React.Fragment>
      <Modal
        open={messagesModalIsOpen}
        onClose={() => closeUserMessagesModal(UserId)}
      >
        <Card
          CardContentProps={{
            style: userMessagesModalStyles(),
          }}
          content={
            <WebView
              userId={UserId}
              updateState={updateState}
              showInfo={showInfo}
              curbsideCommunication={true}
              curbsideCommunicationUserData={{
                FullName: organizer?.FirstName,
                ProfileImage: organizer?.ProfileImage,
                UserId: organizer?.organizerId,
              }}
              curbsideCommunicationSelectedChatIndex={selectedChatIndex}
            />
          }
        />
      </Modal>
    </React.Fragment>
  );
};

export default UserMessagesModal;
