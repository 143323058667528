import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Chart from "./Chart";
import "./AnalyticChart.css";
import { FaMale, FaFemale } from "react-icons/fa";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from "recharts";
import { AiFillInfoCircle } from "react-icons/ai";
import {
  BsEmojiFrown,
  BsEmojiFrownFill,
  BsFillEmojiNeutralFill,
  BsFillEmojiSmileFill,
} from "react-icons/bs";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import ToolTip from "./tools/ToolTip";
import { Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { Info } from "@material-ui/icons";
import { Text } from "Constants";

const malePercentage = 60;
const feMalePercentage = 40;

const data = [
  { name: "Group A", value: 400 },
  { name: "Group B", value: 300 },
  { name: "Group C", value: 300 },
  { name: "Group D", value: 200 },
];

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];
const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const emojiData = [
  {
    emoji: BsEmojiFrownFill,
    name: "Disengaged",
  },
  {
    emoji: BsEmojiFrownFill,
    name: "Mildly Engaged",
  },
  {
    emoji: BsFillEmojiNeutralFill,
    name: "Engaged",
  },
  {
    emoji: BsFillEmojiSmileFill,
    name: "Highly Engaged",
  },
  {
    emoji: BsFillEmojiSmileFill,
    name: "Impossible",
  },
];

const CreateEventDetailsAboutToolTip = withStyles(() => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: 20,
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const titleWithToolTip = (title, tipText, mandatory = false) => {
  return (
    <span style={{ fontSize: 16, fontWeight: "bold", color: "#000000" }}>
      {title}
      {mandatory && <span style={{ color: "red" }}> *</span>}
      <CreateEventDetailsAboutToolTip title={<h5>{tipText}</h5>}>
        <span
          style={{
            padding: "0 0 0 10px",
            cursor: "pointer",
            color: "rgb(161, 3, 150)",
          }}
        >
          <Info />
        </span>
      </CreateEventDetailsAboutToolTip>
    </span>
  );
};

function AnalyticChart({ activityData }) {
  let GenderData = [];
  if (Array.isArray(activityData?.GenderStatistics)) {
    GenderData = activityData?.GenderStatistics;
  } else {
    GenderData.push(activityData?.GenderStatistics);
  }

  const male = GenderData?.find((x) => x?.Gender.trim() === "Male");
  const female = GenderData?.find((x) => x?.Gender.trim() === "Female");

  
  return (
    <div className="AnalyticData">
      <div className="analyticChart">
        <div className="pieChart">
          
          <h2 style={{marginLeft:"10%", marginTop:20}}>
            {titleWithToolTip(
              "Gender",
              "Number of male vs female attendees who registered on the app",
              false
            )}
          </h2>
        
       
          <div className="genderChart">
            <div className="maleOuter">
              <h2 style={{marginBottom:15}}>{Text.Male}</h2>
              <CircularProgressbar
                styles={{
                  width: 800,
                  path: {
                    stroke: "#2e69ff",
                    strokeLinecap: 'square',
                  },
                  text: {
                    fill: "#111",
                    fontWeight: 700,
                    fontSize:16
                  },
                }}
                
                strokeWidth={14}
                value={male ? male?.Percentage : 0}
                text={`${male ? male?.Percentage : 0}%`}
              />

              <FaMale className="maleIcon" />
            </div>

            <div className="vertical_divider">
              <div className="vertical_divider_circle">{Text.vs}</div>
              <div className="pluses" />
            </div>
            <div className="femaleOuter">
              <h2  style={{marginBottom:15}}>{Text.Female}</h2>

              <CircularProgressbar
                styles={{
                  width: 800,
                  path: {
                    stroke: "#ca3dd9",
                    strokeLinecap: 'square',
                  },
                  text: {
                    fill: "#111",
                    fontWeight: 700,
                    fontSize:16
                  },
                }}
                strokeWidth={14}

                value={female ? female?.Percentage : 0}
                text={`${female ? female?.Percentage : 0}%`}
              />

              <FaFemale className="feMaleIcon" />
            </div>
          </div>
        </div>
        <div className="ageBarChart">
          <div className="ageBardChartHeading">
            <h2 >
              {titleWithToolTip(
                "Age",
                "Age range of attendees who registered on the app",
                false
              )}
            </h2>
          </div>
          <Chart yaxis activityData={activityData} />
        </div>
        {/* <div className="homeTownBarChart">
          <h2>Hometowns</h2>
          <div className="lineChart">
            <Chart chart2 />
          </div>
        </div> */}
      </div>
      {/* <div className="analyticScore">
        <div className="analyticScoreLeft">
          <div className="analyticScoreChart">
            <div className="engagementScore">
              <h3>Engagement Score</h3>
              <h2>0%</h2>
            </div>
            <div className="engagementPieChart">
              <PieChart width={400} height={400}>
                <Pie
                  data={data}
                  cx="20%"
                  cy="40%"
                  labelLine={false}
                  label={renderCustomizedLabel}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {data.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </div>
          </div>
          <div className="analyticScoreEmojis">
            <div className="emajiData">
              {emojiData.map((item) => (
                <div className="emojiDataContent">
                  <div
                    style={{
                      fontSize: 35,
                      display: "flex",
                      alignItems: "center",
                      borderRadius: 50,
                      borderColor: "#fff",
                      backgroundColor: "#000000",
                      color:
                        item.name === "Disengaged"
                          ? "red"
                          : item.name === "Mildly Engaged"
                          ? "orange"
                          : item.name === "Engaged"
                          ? "yellow"
                          : item.name === "Highly Engaged"
                          ? "lightgreen"
                          : "green",
                    }}
                  >
                    {<item.emoji />}
                  </div>
                  <p>{item.name}</p>
                </div>
              ))}
            </div>
            <div className="boostButton">
              <p>Boost Engagement</p>
            </div>
          </div>
        </div>
       
      </div> */}
    </div>
  );
}

export default AnalyticChart;
