import React, { useEffect, useState } from "react";
import "./EventCardWrapper.css";
import EventCardView from "./EventCardView/EventCardView";
import { axiosE } from "Service/service";

function Cards({
  gridStyle,
  data,
  AddFavorite,
  favoriteData,
  isLoggedIn,
  LoginPopup,
  results,
}) {
  const handleClick = async (data) => {

    if (favoriteData.find((x) => x.EventId === data.EventId)) {
      let newdata = [...favoriteData];
      const filterData = newdata.filter((i) => i.EventId !== data.EventId);

      AddFavorite(filterData);
    } else {
      AddFavorite([...favoriteData, data]);
    }
    try {
      const res = await axiosE.post("/Event/UpdateLike", {
        EventId: data.EventId,
        UserId: results.ProfileDetail.UserId,
        Platform: 'Web'
      });
      if (res.data.results.ResponseCode) {

      }
    } catch (error) {
      let newdata = [...favoriteData];
      const filterData = newdata.filter((i) => i.EventId !== data.EventId);
      AddFavorite(filterData);
    }
  };

  const checkForAlreadyFavorite = async () => {
    const localPendingApiData = localStorage.getItem('pendingUpdateEventApiCall') ? JSON.parse(localStorage.getItem('pendingUpdateEventApiCall')) : null;
    if (localPendingApiData && favoriteData && favoriteData.length) {
      const isAlreadyAddedToFavorite = favoriteData.some((data) => {
 
        return (
          data.EventId === localPendingApiData.EventId
        )
      });
      if (!isAlreadyAddedToFavorite) {

        await handleClick(localPendingApiData)
        localStorage.removeItem('pendingUpdateEventApiCall')
      } else {

        localStorage.removeItem('pendingUpdateEventApiCall')
      }
    }
  }
  useEffect(() => {
    checkForAlreadyFavorite()
  }, [favoriteData, isLoggedIn])

  return (
    <main class={gridStyle === 4 ? "grid_4" : "grid"}>
      {data?.map(
        ({
          CategoryName,
          City,
          Distance,
          EventId,
          ImageMedium,
          Latitude,
          Liked,
          Longitude,
          Price,
          Region,
          StartDateTime,
          Title,
          CategoryId,
          VenueName,
          BillingAmount,
          CurrencySymbol
        }) => (
          <EventCardView
            key={EventId}
            {...{
              CategoryName,
              CategoryId,
              City,
              Distance,
              EventId,
              ImageMedium,
              Latitude,
              Liked,
              Longitude,
              Price,
              Region,
              StartDateTime,
              Title,
              VenueName,
              BillingAmount,
              CurrencySymbol
            }}
            results={results}
            LoginPopup={LoginPopup}
            isLoggedIn={isLoggedIn}
            AddFavorite={AddFavorite}
            favoriteData={favoriteData}
          />
        )
      )}
    </main>
  );
}

export default Cards;
