import React, { Component } from "react";
import {
  Button,
  Card,
  Grid,
  IconButton,
  styled,
  Switch,
  TextField,
  Tooltip,
  withStyles,
} from "@material-ui/core";
import {
  CameraAlt,
  Close,
  Create,
  Delete,
  Group,
  Info,
  MonetizationOn,
} from "@material-ui/icons";
import garlicFest from "../../../../images/garlicFest.png";
import ContentGrid from "../../../../Helper/gridWrapper/gridWrapper";
import EventHeader from "./eventHeader";
import converter from "../../../../Helper/convertFile";
import { connect } from "react-redux";
import { Box, Modal } from "@material-ui/core";
import { addAd, deleteAd, updateAd } from "../../../../Actions/eventActions";
import { bindActionCreators } from "redux";
import { eventApis, Events, Text } from "../../../../Constants";
import Loader from "../../../../Helper/loader/loader";
import CreateEventErrorMessage from "../createEventErrorMessage";
import AddIcon from "@material-ui/icons/Add";
import { reusableClasses } from "./resusableClasses";
import { checkIfEventExists } from "../../../../Helper/Validations";
import { axiosE } from "../../../../Service/service";

const styles = {
  createEventAdsGrid: {
    flex: 1,

    "@media screen and (max-device-width:960px)": {
      flex: "1 !important",
      flexBasis: "100% !important",
    },
    "@media screen and (max-device-width:600px)": {},
  },
  createEventLiveChatWrapper: {
    ...reusableClasses.contentWrapper,
  },
  createEventLiveChatVerticalLine: {
    float: "left",
    borderLeft: "2px solid lightgray",
    margin: "136px 0px 0px 30px",
    "@media screen and (max-device-width:450px)": {
      display: "none",
    },
  },
  createEventLiveChatVerticalLineWithCirclesWrapper: {
    margin: "45px 0px 0px 0px",
    "@media screen and (max-device-width:450px)": {
      display: "none",
    },
  },
  createEventLiveChatCircleOnVerticalLine: {
    backgroundColor: "rgb(182, 14, 181)",
    height: "15px",
    width: "15px",
    margin: "10px 0px 302px -9px",
    borderRadius: "50%",
  },
  createEventLiveChatFormWrapper: {
    flex: 1,
    display: "flex",
  },
  createEventLiveChatInputLabel: {
    fontSize: "18px",
  },
  createEventLiveChatInputWithBorder: {
    border: "1px solid #ced4da",
    borderRadius: "3px",
    padding: "10px",
    margin: "10px 0px 10px 0px",
    width: "80%",
    borderLeft: "15px solid rgb(0, 185, 255)",
  },
  createEventLiveChatInputText: {
    fontSize: "16px",
  },
  createEventLiveChatSurveyRecommendation: {
    fontStyle: "italic",
    color: "rgb(120, 120, 120)",
  },
  createEventLiveChatSwitchLabel: {
    margin: "50px 0px 0px 0px",
    fontSize: "18px",
  },
  createEventLiveChatSwitch: {
    margin: "5px 0px 0px -15px",
  },
  createEventLiveChatFooter: {
    fontSize: "14px",
    color: "rgb(120, 120, 120)",
  },
  createEventLiveChatWelcomeMessage: {
    margin: "50px 0px 0px 0px",
    fontSize: "18px",
  },
  createEventLiveChatLargeTextInput: {
    border: "1px solid #ced4da",
    borderRadius: "3px",
    padding: "10px",
    margin: "10px 0px 0px 0px",
    height: "180px",
    overflow: "auto",
    width: "80%",
  },
  createEventLiveChatMonetizationIcon: {
    margin: "0px 0px 0px -13.5px",
    transform: "scale(1.5)",
    color: "rgb(200, 200, 0)",
  },
  createEventLiveChatCreateAds: {
    margin: "0px 0px 0px 10px",
    fontSize: "18px",
  },
  createEventLiveChatAddAdvertisementWrapper: {
    display: "flex",
  },
  createEventLiveChatAddImageToAdvertisement: {
    height: "75px",
    width: "75px",
    margin: "25px 0px 0px 0px",
    border: "1px solid rgb(200, 200, 200)",
  },
  createEventLiveChatAddAdvertisementImageButton: {
    border: "dotted #ced4da",
    borderBottom: "8px solid #579cfc",
    margin: "25px 0px 0px 10px",
    height: "80px",
    width: "100px",
    color: "black",

    fontSize: "14px",
    textTransform: "none",
  },
  createEventLiveChatAddImageToAdvertisementLabel: {
    margin: "-23px 0px 0px 14px",
    fontSize: "16px",
  },
  createEventLiveChatAddAdvertisementCameraIcon: {
    margin: "-8px 0px 0px 0px",
    color: "purple",
  },
  createEventLiveChatAddAdvertisementTextFieldWrapper: {
    display: "grid",
    margin: "10px 0px 0px 30px",
    width: "100%",
  },
  createEventLiveChatCreateAdTextField: {
    borderBottom: "1px solid rgb(200, 200, 200)",
    fontSize: "16px",
    margin: "10px 0px 10px 0px",
    width: "auto",
  },
  createEventLiveChatAddAdvertisementButtonWrapper: {
    display: "flex",
    justifyContent: "center",
    height: "fit-content",
    margin: "auto 10px 0px",
    alignItems: "center",
    borderTop: "1px solid #ced4da",
    paddingTop: "20px",
  },
  createEventLiveChatAddAdvertisementButton: {
    ...reusableClasses.addOrUpdateModalHeaderButtonSave,
  },
  createEventLiveChatAdvertisementCard: {
    ...reusableClasses.card,
  },
  createEventLiveChatAdvertisementCardTextFieldWrapper: {
    margin: "0px 5px 0px 5px",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",

    "& > p": {
      margin: "0px",
    },
    "& > a > p": {
      margin: 0,
    },
  },
  createEventLiveChatAdvertisementCardImage: {
    height: "60px",
    width: "80px",
    alignSelf: "center",
  },
  createEventLiveChatAdvertisementCardTextField: {
    fontSize: "16px",
    width: "auto",
    display: "-webkit-box",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
  },
  createEventLiveChatAdvertisementCardEditDeleteWrapper: {
    margin: "0px 0px 0px 5px",
    justifyContent: "flex-end",
    alignSelf: "center",
    display: "flex",
  },
  createEventAdvertisementVerticalLineDivider: {
    borderLeft: "3px solid lightgray",
    margin: "0px 3px 0px 3px",
    display: "grid",
    width: "100%",
    height: "52px",
  },
  createEventAdvertisementSaveAndContinueButton: {
    ...reusableClasses.saveAndContinueButton,
    "@media (max-width: 768px)": {},
    "@media (max-width: 770px)": {
      margin: 0,
      width: "100%",
      borderRadius: 0,
      position: "fixed",
      bottom: 0,
      right: 0,
    },
  },
  createEventAdvertisementImage: {
    margin: "25px 0px 0px 10px",
    height: "80px",
    width: "100px",
  },
  createEventAdvertisementPreviewGrid: {
    flex: 0.4334,
    "@media screen and (max-device-width:960px)": {
      // flex: 1,
      flexBasis: "100% !important",
      maxWidth: "100% !important",
      flexGrow: 0,
      height: "45vh",
    },
    "@media screen and (max-device-width:600px)": {
      height: "95vh",
    },
  },

  createEventFAQHeader: {
    position: "absolute",
    margin: "29px 35px",
    height: "fit-content",

    display: "flex",
    alignItems: "start",
    justifyContent: "space-between",

    paddingBottom: "20px",

    borderBottom: "1px solid #F5F5F5",
    width: "76vw",

    "@media (max-width: 1280px)": {
      width: "90vw",
    },
    "@media (max-width: 900px)": {
      width: "75vw",
    },
  },

  createEventAgendaAddSessionDaysButtonWrapper: {
    ...reusableClasses.addButtonWrapper,
    "@media (max-width: 445px)": {
      borderRadius: "50%",
      width: "50px",
      height: "50px",
      position: "fixed",
      bottom: "0",
      right: "10px",
      minWidth: "50px",
    },
  },
  createEventAgendaAddSessionDaysButton: {
    ...reusableClasses.addButton,
  },
  createEventAgendaAddSessionDaysButtonText: {
    ...reusableClasses.addButtonText,
  },

  createEventFAQModal: {
    ...reusableClasses.addOrUpdateModalMainWrapperOuter,
  },

  createEventFAQModalHeader: {
    ...reusableClasses.addOrUpdateModalHeader,
  },

  createEventFAQCancelFAQButton: {
    ...reusableClasses.addOrUpdateModalHeaderButtonCancel,
  },

  createEventAdDeleteAdModal: {
    width: "430px",
    height: "150px",
    backgroundColor: "white",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "10px",
    padding: "10px",

    "@media (max-width: 540px)": {
      width: "70%",
    },
    "@media (max-width: 410px)": {
      width: "90%",
    },
  },

  createEventAdCancelAdButton: {
    fontSize: "1.5rem",
    padding: "10px 25px",
  },
  createEventFAQButtonWrapper: {
    ...reusableClasses.saveAndContinueButtonWrapper,
  },

  createEventAdsEmptyScreenWrapper: {
    width: "100%",
    margin: "auto 10px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    opacity: 0.3,
    height: "67vh",
  },
  createEventAdPlusIcon: {
    ...reusableClasses.addButtonIcon,
  },
  eventDetailsAdsMainWrapper: {
    ...reusableClasses.mainWrapper,
    "@media (max-width: 960px)": {
      height: "100%",
      minHeight: "45vh",
      maxHeight: "45vh",
    },
    "@media (max-width: 600px)": {
      maxHeight: "90vh",
      minHeight: "90vh",
    },
  },
  createAdAddModal: {
    ...reusableClasses.addOrUpdateModalMainWrapperInner,
  },

  createEventAdImgWrapper: {
    width: "100px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100px",
  },
  createEventAdImg: {
    width: "30px",
  },
  createEventFAQModalHeaderText: {
    ...reusableClasses.addOrUpdateModalHeaderText,
  },
  mainAdsWrapper: {
    position: "relative",
    display: "flex",
    gap: "10px",
    "@media screen and (max-width:960px)": {
      flexWrap: "wrap",
      flexDirection: "column",
    },
  },
  liveChatWrapper: {
    margin: "0px",
    height: "90vh",
    background: "white",
    padding: "0px",
    overflow: "auto",
    marginTop: "0px -12px 0px -12px",
    justifyContent: "center",

    "@media screen and (max-device-width:960px)": {
      height: "100%",
    },
  },
  addChatWrapper: {
    "@media screen and (max-device-width:960px)": {
      height: "100%",
    },
  },
  cardWrapper: {
    ...reusableClasses.cardWrapper,
  },
  verticalLineWrapper: {
    ...reusableClasses.verticalLineWrapper,
  },
};

const RedAsterisk = styled("span")({
  color: "red",
  position: "absolute",
  left: 275,
});

const CreateEventLiveChatToolTipSurveyURL = withStyles(() => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 650,
    margin: "-40px 0px 0px 520px",
    fontSize: "16px",
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const CreateEventLiveChatToolTipCreateAds = withStyles(() => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 650,
    margin: "-40px 0px 0px 460px",
    fontSize: "16px",
    border: "1px solid #dadde9",
  },
}))(Tooltip);

class EventDetailsAds extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteAdModal: { show: false, ad: "" },
      createAdModal: false,
      allAds: [],
      currentAd: {
        adBanner1: "",
        adBanner2: "",
        adWebsite: "",
        adLogo: "",
        showEdit: false,
      },
      verticalLineHeight: "0px",
      adToDisplay: [],
      currentIndex: 0,
      loader: false,
      urlError: "",
      error: { show: false, message: "" },
    };
    this.displayAds();
  }

  // componentDidUpdate = () => {
  //   console.log("STATE UPDATED", this.state);
  // };

  componentDidMount() {
    const { history, eventId } = this.props;
    checkIfEventExists(history, eventId);

    this.setState({
      loader: true,
    });
    axiosE.get(eventApis.getAdvertismentByEventId + eventId + "/" + "Admin").then((res) => {
      let ads = res.data.results.ListAdvertisment;

      if (ads && ads.length > 0) {
        this.setState({
          allAds: ads.map((ad) => ({
            adBanner1: ad.Title1,
            adBanner2: ad.Title2,
            adWebsite: ad.Url,
            adLogo: ad.Logo,
            showEdit: false,
            adId: ad.AdvertismentId,
          })),
        });
      }
      this.setState({
        loader: false,
      });
    });
  }

  calculateCircleOnVerticalLineMargin = (index) => {
    const { allAds } = this.state;
    return allAds.length > 0
      ? allAds[index].showEdit && index !== allAds.length - 1
        ? "82px "
        : "82px "
      : "80px ";
  };
  circleOnVerticalLineStyles = (index) => {
    return {
      backgroundColor: "rgb(182, 14, 181)",
      height: "15px",
      width: "15px",
      margin:
        "0px 0px " + this.calculateCircleOnVerticalLineMargin(index) + "-9px",
      borderRadius: "50%",
      "@media screen and (max-device-width:450px)": {
        display: "none",
      },
    };
  };

  calculateLineHeight = (extraCircles) => {
    const { allAds } = this.state;

    return allAds.length > 1 ? (extraCircles - 1) * 97 + "px" : "0px";
  };
  createEventSponsorsVerticalLineStyles = (extraCircles) => {
    return {
      float: "left",
      borderLeft: "2px solid lightgray",
      margin: "56px 0px 0px 30px",
      height: this.calculateLineHeight(extraCircles),
    };
  };
  verticalLineWithCircles = () => {
    const { classes } = this.props;
    const { allAds } = this.state;

    let extraCircles = allAds.map((ad, index) => (
      <div key={index} style={this.circleOnVerticalLineStyles(index)}></div>
    ));
    extraCircles = extraCircles.splice(0, extraCircles.length - 1);
    let elements = [];
    elements.push(
      <div className={classes.verticalLineWrapper}>
        <span
          style={this.createEventSponsorsVerticalLineStyles(allAds.length)}
          className={classes.createEventLiveChatVerticalLine}
        ></span>
        <div
          className={classes.createEventLiveChatVerticalLineWithCirclesWrapper}
        >
          {extraCircles}
          {allAds.length > 0 && (
            <div
              style={this.circleOnVerticalLineStyles(allAds.length - 1)}
            ></div>
          )}
        </div>
      </div>
    );
    return <React.Fragment>{elements}</React.Fragment>;
  };

  createAds = (title, index) => {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <h1 className={classes.createEventLiveChatCreateAds}>
          {title + " ad"}
          <CreateEventLiveChatToolTipCreateAds
            title={
              <React.Fragment>{Events.ADS.emptyAd.message}</React.Fragment>
            }
          >
            <IconButton color={"primary"}>
              <Info />
            </IconButton>
          </CreateEventLiveChatToolTipCreateAds>
        </h1>
        {this.addAdvertisementSection(index)}
      </React.Fragment>
    );
  };
  uploadPicture = async (e, index) => {

    e.preventDefault();
    e.stopPropagation();
    const { currentAd, allAds } = this.state;

    const file = e.target.files[0];
    if (file !== undefined) {
      if (file.size <= 10 * 1000000) {
        const reader = new FileReader();
        reader.onload = async (event) => {
          const img = new Image();
          img.src = event.target.result;
          img.onload = async () => {
            const maxWidth = 220; // Maximum width for the resized image
            const maxHeight = 140; // Maximum height for the resized image
            let width = img.width;
            let height = img.height;

            // Calculate new width and height to maintain aspect ratio
            if (width > height) {
              if (width > maxWidth) {
                height *= maxWidth / width;
                width = maxWidth;
              }
            } else {
              if (height > maxHeight) {
                width *= maxHeight / height;
                height = maxHeight;
              }
            }

            // Create a canvas element to draw the resized image
            const canvas = document.createElement('canvas');
            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0, width, height);

            // Convert the canvas content to base64 encoded data
            const resizedEncodedData = canvas.toDataURL('image/jpeg'); // Change format if needed

            this.setState({
              currentAd: { ...currentAd, adLogo: resizedEncodedData },
            });
          };
        };
        reader.readAsDataURL(file);

        // const encodedData = await converter.toBase64(file);
        //
        // encodedData &&
        //   this.setState({
        //     currentAd: { ...currentAd, adLogo: encodedData },
        //   });
      } else {
        this.setState({
          error: { show: true, message: "The maximum size allowed is 2MB." },
        });
      }
    } else {
      this.setState({
        error: { show: true, message: "User didn't upload anything" },
      });
    }
  };

  addAdvertisementSection = (index) => {
    const { classes } = this.props;
    const { allAds, currentAd } = this.state;
    const pattern = /^(http:\/\/|https:\/\/|www\.)/i;
    let UrlError =
      "Please enter a valid URL starting with http://, https://, or www.";

    return (
      <>
        <div className={classes.createEventLiveChatAddAdvertisementWrapper}>
          <input
            style={{ display: "none" }}
            id={"adLogo" + index}
            multiple={false}
            type="file"
            accept="image/*"
            onInput={(e) => this.uploadPicture(e, index)}
            onClick={(event) => {
              event.target.value = null;
            }}
          />
          <label htmlFor={"adLogo" + index}>
            {currentAd.adLogo ? (
              <img
                className={classes.createEventAdvertisementImage}
                src={currentAd.adLogo}
                component={"span"}
              />
            ) : (
              <div>
                <Button
                  className={
                    classes.createEventLiveChatAddAdvertisementImageButton
                  }
                  variant={"outlined"}
                  component={"span"}
                >
                  Logo
                  <sup style={{ fontSize: 16, color: "red" }}>*</sup>
                </Button>
              </div>
            )}
          </label>
          <div
            className={
              classes.createEventLiveChatAddAdvertisementTextFieldWrapper
            }
          >
            <div style={{ width: "100%", display: "grid" }}>
              <TextField
                className={classes.createEventLiveChatCreateAdTextField}
                placeholder={Events.ADS.adAdvertisement.banner1}
                value={currentAd.adBanner1}
                onChange={(e) => this.updateTextField("adBanner1", e)}
                InputProps={{
                  // endAdornment: currentAd.adBanner1 === "" && <RedAsterisk>*</RedAsterisk>,
                  disableUnderline: true,
                  className: classes.createEventLiveChatInputText,
                  maxLength: 5,
                  startAdornment: currentAd.adBanner1 === "" && (
                    <RedAsterisk style={{ left: 275 }}>*</RedAsterisk>
                  ),
                }}
                onInput={(e) => {
                  e.target.value =
                    e.target.value.length > 30
                      ? e.target.value.slice(0, 30)
                      : e.target.value;
                }}
                helperText={`${currentAd.adBanner1?.length}/30`}
              />
            </div>
            <TextField
              className={classes.createEventLiveChatCreateAdTextField}
              placeholder={Events.ADS.adAdvertisement.banner2}
              value={currentAd.adBanner2}
              onChange={(e) => this.updateTextField("adBanner2", e)}
              InputProps={{
                disableUnderline: true,
                className: classes.createEventLiveChatInputText,
                maxLength: 5,
              }}
              onInput={(e) => {
                e.target.value =
                  e.target.value.length > 30
                    ? e.target.value.slice(0, 30)
                    : e.target.value;
              }}
              helperText={`${currentAd.adBanner2?.length}/30`}
            />
            <TextField
              className={classes.createEventLiveChatCreateAdTextField}
              placeholder={Events.ADS.adAdvertisement.website}
              value={currentAd.adWebsite}
              onChange={(e) => this.updateTextField("adWebsite", e)}
              InputProps={{
                disableUnderline: true,
                className: classes.createEventLiveChatInputText,
                maxLength: 5,
              }}
            />
            {currentAd.adWebsite !== "" && (
              <span style={{ color: "red" }}>
                {!pattern.test(currentAd.adWebsite) ? UrlError : null}
              </span>
            )}
          </div>
        </div>
        <div
          className={classes.createEventLiveChatAddAdvertisementButtonWrapper}
          id="eventCommonModal"
        >
          <Button
            className={classes.createEventFAQCancelFAQButton}
            onClick={() =>
              index == -1
                ? this.cancelAdvertisement()
                : this.editModalToggle(index, false, false)
            }
          >
            Cancel
          </Button>
          <Button
            className={classes.createEventLiveChatAddAdvertisementButton}
            variant={"contained"}
            onClick={() =>
              index == -1
                ? this.addAdvertisement()
                : this.editModalToggle(index, false, true)
            }
            disabled={
              !(
                currentAd.adBanner1 &&
                currentAd.adLogo &&
                (currentAd.adWebsite === ""
                  ? true
                  : pattern.test(currentAd.adWebsite))
              )
            }
            style={{
              backgroundColor: !(
                currentAd.adBanner1 &&
                currentAd.adLogo &&
                (currentAd.adWebsite === ""
                  ? true
                  : pattern.test(currentAd.adWebsite))
              )
                ? "rgba(163, 56, 164,.4)"
                : "rgb(163, 56, 164)",
              color: "white",
              padding: "10px 51px",
              margin: 0,
              borderRadius: "10px",
              fontSize: "1.5rem",
            }}
          >
            {index == -1 ? "Save" : "Save"}
          </Button>
        </div>
      </>
    );
  };

  editModalToggle = (index, show = false, save = false) => {
    const currentDefault = {
      adBanner1: "",
      adBanner2: "",
      adWebsite: "",
      adLogo: "",
      showEdit: false,
    };

    if (show && !save) {
      this.setState({
        currentAd: { ...this.state.allAds[index] },
      });
      this.toggleEditAdvertisement(index, show);
    } else if (!show && save) {
      this.loaderState(true);
      this.updateRemoteAd();
      this.setState({
        allAds: this.state.allAds.map((ad, i) =>
          i === index
            ? {
                ...this.state.currentAd,
                showEdit: false,
              }
            : ad
        ),
        currentAd: {
          ...currentDefault,
        },
      });
    } else if (!show && !save) {
      this.setState({
        currentAd: { ...currentDefault },
      });
      this.toggleEditAdvertisement(index, show);
    }
  };

  addExtraSpaceToBalance = () => {
    return <span style={{ width: "30px", height: "30px" }}></span>;
  };

  getUrl = (url) => {
    if (url.includes("http://") || url.includes("https://")) {
      return url;
    } else {
      return "http://" + url;
    }
  };

  addedAdvertisements = () => {
    const { classes } = this.props;
    const { allAds } = this.state;

    return (
      <div className={classes.cardWrapper}>
        {allAds.map((currentAd, i) => {
          return (
            <React.Fragment key={i}>
              <Card className={classes.createEventLiveChatAdvertisementCard}>
                <div>
                  <img
                    className={
                      classes.createEventLiveChatAdvertisementCardImage
                    }
                    src={currentAd.adLogo}
                  />
                </div>
                <div
                  className={
                    classes.createEventAdvertisementVerticalLineDivider
                  }
                  onClick={() =>
                    currentAd.adWebsite
                      ? window.open(this.getUrl(currentAd.adWebsite), "_blank")
                      : null
                  }
                  style={{ cursor: currentAd.adWebsite ? "pointer" : "auto" }}
                >
                  <div
                    className={
                      classes.createEventLiveChatAdvertisementCardTextFieldWrapper
                    }
                  >
                    <p
                      className={
                        classes.createEventLiveChatAdvertisementCardTextField
                      }
                    >
                      {currentAd.adBanner1}
                    </p>
                    <p
                      className={
                        classes.createEventLiveChatAdvertisementCardTextField
                      }
                    >
                      {currentAd.adBanner2}
                    </p>
                    {/* <a
                      className={
                        classes.createEventLiveChatAdvertisementCardTextField
                      }
                      href={
                        currentAd.adWebsite
                          ? `https://${currentAd.adWebsite}`
                          : "#"
                      }
                      target="_blank"
                    >
                      {currentAd.adWebsite}
                    </a> */}
                  </div>
                </div>
                <div
                  className={
                    classes.createEventLiveChatAdvertisementCardEditDeleteWrapper
                  }
                >
                  <IconButton
                    onClick={() =>
                      this.editModalToggle(i, !currentAd.showEdit, false)
                    }
                  >
                    <Create />
                  </IconButton>
                  <IconButton
                    onClick={() => this.setDeleteModalState(true, currentAd)}
                  >
                    <Delete />
                  </IconButton>
                </div>
              </Card>
              <Modal
                open={this.state.allAds[i].showEdit}
                onClose={() => this.editModalToggle(i, false)}
              >
                <Box className={classes.createEventFAQModal}>
                  <div className={classes.createAdAddModal}>
                    <div className={classes.createEventFAQModalHeader}>
                      <IconButton
                        onClick={() => this.editModalToggle(i, false)}
                      >
                        <Close height={30} width={30} />
                      </IconButton>
                      <p className={classes.createEventFAQModalHeaderText}>
                        Edit Ad
                      </p>

                      {this.addExtraSpaceToBalance()}
                    </div>
                    {this.createAds("Edit", i)}
                  </div>
                </Box>
              </Modal>
            </React.Fragment>
          );
        })}
      </div>
    );
  };
  updateTextField = (fieldName, e) => {
    const { currentAd, allAds } = this.state;
    this.setState({
      currentAd: {
        ...currentAd,
        [fieldName]: e.currentTarget.value,
      },
    });
  };

  progressLoader = () => {
    const { loader } = this.state;

    return loader && <Loader />;
  };

  loaderState = (show) => {
    console.log("LOADER STATE", show);
    this.setState({ loader: show });
  };

  addAdvertisement = async () => {
    this.loaderState(true);
    this.setState({
      createAdModal: false,
    });
    const { currentAd, allAds } = this.state;
    const adId = await this.addAdToRemote(currentAd);
    this.setState({
      currentAd: {
        adBanner1: "",
        adBanner2: "",
        adWebsite: "",
        adLogo: "",
        showEdit: false,
      },
      allAds: [...allAds, { ...currentAd, adId }],
    });
  };

  addAdToRemote = async (currentAd) => {
    const { addAd, eventId } = this.props;
    // const { addAd } = this.props;
    // const eventId = "b5f6f152-20d0-4c9a-8345-82b5d6668717";
    const adId = await addAd({
      EventId: eventId,
      Title1: currentAd.adBanner1,
      Title2: currentAd.adBanner2,
      AdvertimentUrl: currentAd.adWebsite,
      Logo: currentAd.adLogo.split(",")[1],
    }).finally(() => this.loaderState(false));
    // console.log("ADD ADDED RECENTLY", adId);
    return adId.AdvertismentId;
  };

  cancelAdvertisement = () => {
    this.setState({
      currentAd: {
        adBanner1: "",
        adBanner2: "",
        adWebsite: "",
        adLogo: "",
        showEdit: false,
      },
    });
    this.handCreateAdModal(false);
  };

  toggleEditAdvertisement = (index, show) => {
    this.setState({
      allAds: this.state.allAds.map((ad, i) =>
        i === index
          ? {
              ...this.state.allAds[i],
              showEdit: show,
            }
          : this.state.allAds[i]
      ),
    });
  };

  updateRemoteAd = () => {
    const { currentAd } = this.state;

    this.props
      .updateAd({
        AdvertismentId: currentAd.adId,
        Title1: currentAd.adBanner1,
        Title2: currentAd.adBanner2,
        AdvertimentUrl: currentAd.adWebsite,
        Logo: currentAd.adLogo.split(",")[1],
      })
      .finally(() => this.loaderState(false));

    console.log("ad update Request", {
      AdvertismentId: currentAd.adId,
      Title1: currentAd.adBanner1,
      Title2: currentAd.adBanner2,
      AdvertimentUrl: currentAd.adWebsite,
      Logo: currentAd.adLogo.split(",")[1],
    });
  };

  deleteAdvertisement = (currentAd) => {
    const { allAds, currentIndex } = this.state;
    this.setState({
      currentIndex:
        allAds[currentIndex] == currentAd && currentIndex >= allAds.length - 1
          ? 0
          : allAds.length - 1,
      allAds: allAds.filter(
        (ad) => JSON.stringify(ad) !== JSON.stringify(currentAd)
      ),
    });
    this.props.deleteAd({
      AdvertismentId: currentAd.adId,
    });
    this.setDeleteModalState(false);
  };

  displayAds() {
    let { allAds, currentIndex } = this.state;
    setTimeout(() => {
      this.setState(
        {
          currentIndex:
            currentIndex >= allAds.length - 1 ? 0 : currentIndex + 1,
        },
        this.displayAds()
      );
    }, 10000);
  }

  displayPreview = () => {
    const { classes } = this.props;
    const { allAds, currentIndex } = this.state;
    const { eventTitle, eventImg } = this.props;

    return (
      <div className={classes.liveChatWrapper}>
        <React.Fragment>
          <div>
            <p
              style={{
                padding: "15px 5px 15px 5px",
                textAlign: "center",
                backgroundColor: "#db6160",
                justifyContent: "center",
                color: "white",
                fontSize: "14px",
                fontFamily:
                  "HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif",
              }}
            >
              UBMe App Preview
            </p>
          </div>
          <div>
            <Card
              style={{
                boxShadow: "none",
                borderBottom: "1px solid #ced4da",
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
                margin: "5px 0px 0px 0px",
                alignItems: "center",
              }}
            >
              <div>
                <IconButton>
                  <Close
                    style={{
                      fontSize: "24px",
                      color: "rgb(182, 14, 181)",
                    }}
                  />
                </IconButton>
                <IconButton>
                  <Group
                    style={{
                      fontSize: "24px",
                      color: "rgb(182, 14, 181)",
                    }}
                  />
                </IconButton>
              </div>
              <p
                style={{
                  textAlign: "center",
                  color: "rgb(182, 14, 181)",
                  fontSize: "18px",
                  // padding: "12px 0px 0px 0px",
                  fontWeight: "500",
                  alignItems: "center",
                  alignSelf: "center",
                }}
              >
                {eventTitle.length > 20
                  ? eventTitle.substring(0, 20) + "..."
                  : eventTitle}
              </p>

              <div>
                <img
                  style={{
                    height: "32px",
                    width: "32px",
                    borderRadius: "50%",
                    margin: "1px 5px 0px 0px",
                  }}
                  src={eventImg}
                />
              </div>
            </Card>
          </div>

          <div>
            <React.Fragment>
              <Card
                style={{
                  boxShadow: "none",
                  border: "2px solid #d3d1d2",
                  display: "flex",
                  flexDirection: "row",
                  //   width: "100%",
                  margin: "8px",
                  padding: "5px 8px 5px 8px",
                  justifyContent: "space-between",
                  alignItems: "center",
                  cursor:
                    allAds[currentIndex] && allAds[currentIndex].adWebsite
                      ? "pointer"
                      : "auto",
                }}
                onClick={() =>
                  allAds[currentIndex] && allAds[currentIndex].adWebsite
                    ? window.open(
                        this.getUrl(allAds[currentIndex].adWebsite),
                        "_blank"
                      )
                    : null
                }
              >
                <div>
                  <img
                    className={
                      classes.createEventLiveChatAdvertisementCardImage
                    }
                    src={allAds[currentIndex] && allAds[currentIndex].adLogo}
                  />
                </div>

                <div
                  className={
                    classes.createEventLiveChatAdvertisementCardTextFieldWrapper
                  }
                  style={{
                    justifyContent: "flex-start",
                    padding: "0px 5px 0px 5px",
                    width: "100%",
                  }}
                >
                  <p
                    className={
                      classes.createEventLiveChatAdvertisementCardTextField
                    }
                  >
                    {allAds[currentIndex] && allAds[currentIndex].adBanner1}
                  </p>
                  <p
                    className={
                      classes.createEventLiveChatAdvertisementCardTextField
                    }
                  >
                    {allAds[currentIndex] && allAds[currentIndex].adBanner2}
                  </p>
                </div>
              </Card>
            </React.Fragment>
          </div>

          <div
            style={{
              display: "flex",
              margin: "40px 30px 0px 5px",
            }}
          >
            <div>
              <img
                style={{
                  height: "30px",
                  width: "30px",
                  borderRadius: "50%",
                  marginTop: "42px",
                }}
                src={eventImg}
              />
            </div>
            <div style={{ margin: "0px 0px 0px 10px" }}>
              <p
                style={{
                  backgroundColor: "#e6e5ea",
                  padding: "15px",
                  borderRadius: "15px",
                }}
              >
                Welcome to {eventTitle}, we hope you enjoy today's event!
              </p>
            </div>
          </div>
        </React.Fragment>
      </div>
    );
  };
  sessionDeleteModal = () => {
    const { classes } = this.props;
    const { show, ad } = this.state.deleteAdModal;
    return (
      <Modal open={show} onClose={() => this.setDeleteModalState(false)}>
        <Box className={classes.createEventAdDeleteAdModal}>
          <div className={classes.createEventAdImgWrapper}>
            <img
              src={Events.ADS.deleteAd.img}
              alt="Delete Icon"
              className={classes.createEventAdImg}
            />
          </div>
          <p style={{ textAlign: "center" }}>{Events.ADS.deleteAd.message}</p>
          <div>
            <Button
              className={classes.createEventAdCancelAdButton}
              onClick={() => this.setDeleteModalState(false)}
              style={{ color: "blue" }}
            >
              Cancel
            </Button>

            <Button
              className={classes.createEventAdCancelAdButton}
              onClick={() => this.deleteAdvertisement(ad)}
              style={{ color: "red" }}
            >
              Delete
            </Button>
          </div>
        </Box>
      </Modal>
    );
  };

  setDeleteModalState = (openModal, ad) => {
    if (openModal) {
      this.setState({
        deleteAdModal: {
          show: true,
          ad,
        },
      });
    } else if (!openModal) {
      this.setState({
        deleteAdModal: { show: false, ad: "" },
      });
    }
  };

  handCreateAdModal = (openORclose) => {
    this.setState({
      createAdModal: openORclose,
    });
  };

  emptyAddsScreen = () => {
    const { classes } = this.props;
    return (
      <div className={classes.createEventAdsEmptyScreenWrapper}>
        <img
          src={Events.ADS.emptyAd.img}
          alt="Empty Ad"
          style={{ width: "150px" }}
        />
        <h1>{Events.ADS.emptyAd.headline}</h1>
        <p style={{ maxWidth: "460px" }}>{Events.ADS.emptyAd.message}</p>
      </div>
    );
  };

  handleError = () => {
    this.setState({ error: { show: false, message: "" } });
  };

  ShowAddedAds = () => {
    const { classes } = this.props;
    const { allAds } = this.state;
    return allAds.length > 0 ? (
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "center",
        }}
      >
        {this.verticalLineWithCircles()}
        {this.addedAdvertisements()}
      </div>
    ) : (
      <div className={classes.createEventLiveChatFormWrapper}>
        {this.emptyAddsScreen()}
      </div>
    );
  };

  setStyle = (present, loader) => {
    if (present) {
      return {
        pointerEvents: loader ? "none" : "auto",
      };
    } else {
      return {
        pointerEvents: loader ? "none" : "auto",
        minHeight: "90vh",
        maxHeight: "90vh",
      };
    }
  };

  render() {
    const { classes } = this.props;
    const { allAds, createAdModal, loader } = this.state;
    // const isTablet = window.matchMedia(`(max-width: 768px)`).matches;
    const isTablet = false;
    const showAppPreview = allAds.length > 0;


    return (
      <React.Fragment>
        <ContentGrid
          content={
            <div className={classes.mainAdsWrapper}>
              <Grid
                className={classes.createEventAdsGrid}
                style={{
                  flex: !showAppPreview ? "1" : "0.666 0.0666",
                }}
              >
                <section
                  className={classes.eventDetailsAdsMainWrapper}
                  style={this.setStyle(showAppPreview, loader)}
                >
                  <EventHeader title={"Ads"}>
                    <div
                      className={
                        classes.createEventAgendaAddSessionDaysButtonWrapper
                      }
                    >
                      <Button
                        className={
                          classes.createEventAgendaAddSessionDaysButton
                        }
                        onClick={() => {
                          this.handCreateAdModal(true);
                        }}
                        variant={"contained"}
                      >
                        <span
                          className={
                            classes.createEventAgendaAddSessionDaysButtonText
                          }
                        >
                          {Text.CreateAd}
                        </span>

                        <AddIcon className={classes.createEventAdPlusIcon} />
                      </Button>
                    </div>
                  </EventHeader>
                  <div className={classes.createEventLiveChatWrapper}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {this.ShowAddedAds()}
                      <Modal
                        open={createAdModal}
                        onClose={() => this.handCreateAdModal(false)}
                      >
                        <Box className={classes.createEventFAQModal}>
                          <div className={classes.createAdAddModal}>
                            <div className={classes.createEventFAQModalHeader}>
                              <IconButton
                                onClick={() => this.handCreateAdModal(false)}
                              >
                                <Close height={30} width={30} />
                              </IconButton>
                              <p
                                className={
                                  classes.createEventFAQModalHeaderText
                                }
                              >
                                {Text.CreateAd}
                              </p>

                              {this.addExtraSpaceToBalance()}
                            </div>
                            {this.createAds("Create", -1)}
                          </div>

                          <CreateEventErrorMessage
                            state={this.state.error}
                            close={this.handleError}
                          />
                        </Box>
                      </Modal>
                    </div>

                    <div className={classes.createEventFAQButtonWrapper}>
                      <Button
                        className={
                          classes.createEventAdvertisementSaveAndContinueButton
                        }
                        variant={"contained"}
                        onClick={() => this.props.history.push("Vendors")}
                      >
                        {Text.SaveAndContinue}
                      </Button>
                    </div>
                  </div>
                </section>
                {this.sessionDeleteModal()}
                {this.progressLoader()}
              </Grid>
              {showAppPreview && (
                <Grid className={classes.createEventAdvertisementPreviewGrid}>
                  <section className={classes.addChatWrapper}>
                    {this.displayPreview()}
                  </section>
                </Grid>
              )}
            </div>
          }
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    details: state.eventUserEvents,
    eventId: state.eventUserEvents.eventId,
    ads: state.eventUserEvents.ads,
    eventTitle:
      state.eventUserEvents.eventsAbout &&
      state.eventUserEvents.eventsAbout.Title,
    eventImg:
      (state.eventUserEvents.eventsAbout &&
        state.eventUserEvents.eventsAbout.SmallImage) ||
      state.eventUserEvents.eventsAbout.ImageSmall,
  };
};

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators({ addAd, deleteAd, updateAd }, dispatch);
};

export default withStyles(styles)(
  connect(mapStateToProps, matchDispatchToProps)(EventDetailsAds)
);
