import { GetAllEvents } from "Actions/eventActions";
import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import "./PagiNation.css";
import { Link } from "react-scroll";
import * as constants from "Constants";
const PagiNation = ({
  totalCounts,
  length,
  eventSearchData,
  latlong,
  results,
  isLoggedIn,
  dispatch,
  scrollToTop,
  cardData,
  selectedCategory,
  fetchAllData,
  eventsCount,
}) => {
  const [pageNO, setPageNo] = useState(1);
  const [page, setPage] = useState(0);
  const [resultsPageNo, setResultsPageNo] = useState(1);
  const [isFirstRender, setIsFirstRender] = useState(false);
  const GetEventData = async (pageNO) => {
    // console.log("caliing@@@@@@@@@@@@@@@@@@@");
    let categories =
      selectedCategory.length > 0
        ? selectedCategory.toString()
        : eventSearchData.category;
    let eventData = {
      Latitude: latlong?.lat,
      Longitude: latlong?.lng,
      Radius: eventSearchData.radius,
      SearchTerm: eventSearchData.searchItem,
      UserId: isLoggedIn ? results.ProfileDetail?.UserId : 0,
      CategoryIds: categories,
      PageNumber: pageNO,
      RowsOfPage: eventSearchData.rowOfPages,
    };

    dispatch(GetAllEvents(eventData))
      .then((data) => {
        isInt(data.TotalCounts);
        dispatch({
          type: "CARD_DATA",
          payload: data.EventList,
        });
        setResultsPageNo(pageNO);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    isInt(Number(eventsCount));
  }, [eventsCount]);

  useEffect(() => {
    isInt(totalCounts);
  }, [totalCounts]);

  useEffect(() => {
    if (isFirstRender) {
      GetEventData(pageNO);
    }
    setIsFirstRender(true);
  }, [pageNO, fetchAllData, isFirstRender]);

  useEffect(() => {
    if (resultsPageNo > 1) {
      setResultsPageNo(1);
    }
    // do not add resultsPageNo in dependency array
  }, [selectedCategory]);

  useEffect(() => {
    if (fetchAllData) {
      if (pageNO > 1) {
        setPageNo(1);
        setResultsPageNo(1);
      } else {
        setPageNo(1);
      }
    }
  }, [fetchAllData, pageNO]);

  useEffect(() => {

    if (eventSearchData.totalCounts < 10 && resultsPageNo > 1) {

      setPageNo(1);
      setResultsPageNo(1);
    }
  }, [eventSearchData]);

  function isInt(n) {

    let num = 0;
    if (n % 10 !== 0) {
      num = Math.floor(n / 10) + 1;
    } else {
      num = n / 10;
    }
    setPage(num);
    return num;
  }

  return (
    <div>
      <div className="card_pagination">
        <div className="card_pagination_result">
          <p>
            {constants.Message.ShowingResults}{" "}
            {resultsPageNo > 1 ? (resultsPageNo - 1) * 10 + 1 : resultsPageNo} -{" "}
            {resultsPageNo === 1 ? length : (resultsPageNo - 1) * 10 + length}
            {" "}({constants.Message.OutOf} {eventsCount} total results)
          </p>
          <div className="card_pagination_arrow_div">
            <Link
              style={{
                color:
                  resultsPageNo > 1 && !selectedCategory.length > 0
                    ? "black"
                    : "gray",
              }}
              onClick={() => {
                if (resultsPageNo > 1 || !selectedCategory.length > 0) {
                  setPageNo(pageNO - 1);

                  scrollToTop();
                }
              }}
              activeClass="active"
              to="main_containerv"
              spy={true}
              smooth={true}
              offset={-60}
              duration={500}
              className="card_pagination_arrow"
            >
              <i class="fa-solid fa-angle-left"></i>
            </Link>

            <Link
              style={{
                color: resultsPageNo < page && length >= 10 ? "black" : "gray",
              }}
              onClick={() => {
                if (
                  resultsPageNo < page &&
                  length >= 10 &&
                  !selectedCategory.length > 0
                )
                  setPageNo(pageNO + 1);

                scrollToTop();
              }}
              activeClass="active"
              to="main_containerv"
              spy={true}
              smooth={true}
              offset={-60}
              duration={500}
              className="card_pagination_arrow"
            >
              <i class="fa-solid fa-angle-right"></i>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    cardData: state.favoriteReducer.cardData,
    results: state.loginResponse.results,
    isLoggedIn: state.loginResponse.isLoggedIn,
    latlong: state.favoriteReducer.latlong,
    eventSearchData: state.eventReducer.EventSearchData,
    eventsCount: state.eventReducer.eventsCount,
  };
};
const matchDispatchToProps = (dispatch) => {
  return {
    GetAllEvents: () => dispatch(GetAllEvents),
    dispatch,
  };
};
export default connect(mapStateToProps, matchDispatchToProps)(PagiNation);
