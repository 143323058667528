import React from "react";
import moment from "moment";
import { ws } from "../../../../App";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Avatar from "@material-ui/core/Avatar/Avatar";
import { withStyles } from "@material-ui/core/styles";
import {
  hidePopUp,
  readAllMessages,
  selectedChat,
} from "../../../../Actions/messagesAction";

const styles = {
  avatar: {
    margin: 10,
    height: 50,
    width: 50,
    fontSize: 40,
  },
};

class QuestionChatFooter extends React.Component {
  constructor() {
    super();
    this.state = {
      textMessage: "",
      showPopUp: false,
    };
  }
  componentWillReceiveProps(nextProps) {
    const { messageData } = nextProps;
    let { showPopUp } = this.state;
    if (messageData.showPopUp) {
      let chatElement = document.getElementById("chatWrapper");
      if (
        chatElement.clientHeight + chatElement.scrollTop + 65 <
        chatElement.scrollHeight
      ) {
        showPopUp = true;
      } else {
        this.props.hidePopUp();
      }
    } else {
      showPopUp = false;
    }
    this.setState({ showPopUp });
  }

  do_resize = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      this.sendMessage(e);
    } else {
      let el = e.target;
      setTimeout(() => {
        // for box-sizing other than "content-box" use:
        // el.style.cssText = '-moz-box-sizing:content-box';
        if (el.scrollHeight < 120) {
          el.style.cssText = "padding:0 max-height:200px overflow-y:scroll";
          el.style.cssText = "height:" + el.scrollHeight + "px";
        }
      }, 0);
    }
  };

  onTextChange = (e) => {
    this.setState(
      {
        [e.target.id]: e.target.value,
        scroll: false,
      },
      () => {
        if (this.state.textMessage.length > 0) {
          ws.json({
            type: "starttypingack",
            dialog_id: this.props.messageData.selectedChat.dialog_id,
            to_user_id: this.props.messageData.selectedChat.userid,
          });
        } else {
          ws.json({
            type: "stoptypingack",
            dialog_id: this.props.messageData.selectedChat.dialog_id,
            to_user_id: this.props.messageData.selectedChat.userid,
          });
        }
      }
    );
  };

  sendMessage = () => {
    let { textMessage, scroll } = this.state;
    if (textMessage.trim().length > 0) {
      const { messageData, user } = this.props;
      let date = new Date();
      let currentDate = moment().utc().format("YYYY-MM-DD HH:mm:ss");
      ws.json({
        msgid: date.getTime().toString().slice(4),
        googlePlacedId: messageData.selectedChat.dialog_id,
        chat_message: {
          text: textMessage,
        },
        message_type: "1",
        chat_time: currentDate,
        question_id: messageData.questionId ? messageData.questionId : "0",
        user_id: user?.ProfileDetail.UserId,
        chat_type:
          messageData.selectedChat.Type === "Feed"
            ? "Feed"
            : messageData.selectedChat.Type === "Event"
            ? "Event"
            : "PlaceQuestionChat",
        token_id: localStorage.getItem("token"),
        type: "businesschat",
      });

      ws.json({
        type: "stoptypingack",
        dialog_id: messageData.selectedChat.dialog_id,
        to_user_id: messageData.selectedChat.userid,
      });

      let data = {
        FullName: messageData.selectedChat.FullName,
        QuestionId: messageData.selectedChat.QuestionId,
        RowNumber: messageData.selectedChat.RowNumber,
        Type: messageData.selectedChat.Type,
        UserName: messageData.selectedChat.UserName,
        dialog_id: messageData.selectedChat.dialog_id,
        from_user_id: user.ProfileDetail.UserId,
        last_message_json: messageData.selectedChat.last_message_json,
        unread_count: messageData.selectedChat.unread_count,
        userid: messageData.selectedChat.userid,
        utc_message_date: messageData.selectedChat.utc_message_date,
      };

      this.props.readAllMessages(data);

      textMessage = "";
      scroll = true;
      this.setState({ textMessage, scroll });
    }
  };

  hidePopUp = () => {
    this.setState({
      showPopUp: false,
    });
    this.props.hidePopUp();
    let scrollElement = document.getElementById("chatWrapper");
    scrollElement.scrollTop =
      scrollElement.scrollHeight - scrollElement.clientHeight;
  };

  render() {
    const { messageData, classes } = this.props;
    const { showPopUp, textMessage } = this.state;
    return (
      <React.Fragment>
        {showPopUp && (
          <div className="unread-pop-up" onClick={this.hidePopUp}>
            <Avatar className={classes.avatar}>
              <img
                src={require(`./../../../../images/Alphabets/${messageData.selectedChat.FullName.match(
                  /[a-z]/i
                )[0].toUpperCase()}.png`).default}
                height={65}
                width={65}
                alt="img"
              />
            </Avatar>
            <div className="unread-pop-up-wrapper">
              <p>
                {messageData.selectedChat.Type === "PlaceQuestionChat"
                  ? messageData.selectedChat.locatoinInfo
                  : messageData.selectedChat.Type === "Feed"
                  ? `${messageData.selectedChat.feed.First_Name}'s Moment`
                  : messageData.selectedChat.event.Name}
              </p>
              <p>
                {
                  JSON.parse(
                    messageData.chatHistory &&
                      messageData.chatHistory[
                        messageData.chatHistory.length - 1
                      ].CommentDetail
                  ).text
                }
              </p>
            </div>
          </div>
        )}
        <div className="footer">
          <textarea
            id="textMessage"
            placeholder="Write your Message"
            onChange={this.onTextChange}
            value={textMessage}
            onKeyDown={this.do_resize}
          />
          <label className="footer-chat-btn-send" onClick={this.sendMessage}>
            Send
          </label>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    messageData: state.messageReducer,
    user: state.loginResponse.results,
  };
};

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      hidePopUp,
      readAllMessages,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(withStyles(styles)(QuestionChatFooter));
