import { useEffect } from "react";

const UseOutsideClick = (handler, className, ...targetRefs) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      for (const ref of targetRefs) {
        if (
          ref.current &&
          !ref.current.contains(event.target) &&
          !event.target.closest(className)
        ) {
          handler();
          return;
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handler, className, targetRefs]);
};

export default UseOutsideClick;
