
export const CategoryBG = (CategoryId) => {
    let bg = "rgb(111 238 118)";

  switch (CategoryId) {
    case 1:
      bg = "#A0522D";
      break;
    case 2:
      bg = "#FFE4C4";
      break;
    case 3:
      bg = "#87CEEB";
      break;
    case 4:
      bg = "#3CB371";
      break;
    case 5:
      bg = "#B0C4DE";
      break;
    case 6:
      bg = "#B0E0E6";
      break;
    case 7:
      bg = "#FFA500";
      break;
    case 8:
      bg = "#4682B4";
      break;
    case 9:
      bg = "#F08080";
      break;
    case 10:
      bg = "#4B0082";
      break;
    case 11:
      bg = "#9ACD32";
      break;
    case 12:
      bg = "#FFA500";
      break;
    case 13:
      bg = "#00008B";
      break;
    case 14:
      bg = "#228B22";
      break;
    case 15:
      bg = "#DC143C";
      break;
    case 16:
      bg = "#FFD700";
      break;
    case 17:
      bg = "#DA70D6";
      break;
    case 18:
      bg = "#87CEFA";
      break;
    case 19:
      bg = "rgb(111 238 118)";
      break;
  }

  return bg;
}