import React, {useEffect, useState} from "react";
import RenderTicketsData from "../RenderTicketData/RenderTicketData";
import moment from "moment";
import "moment-timezone";
import {Button} from "@material-ui/core";
import {IoCloseOutline} from "react-icons/io5";
import OrderDetail from "../ModalAction/OrderDetail";
import UpdateAttendeeModal from "../UpdateAttendee/UpdateAttendee";
import {Text} from "Constants";
import EventSlotsScheduler from "Components/UBMeEvents/Events/checkout/EventSlotsScheduler/EventSlotsScheduler";
import Notes from "../ModalAction/Notes";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';
import {ParseError, parsePhoneNumberWithError} from "libphonenumber-js";
import BundleScheduler from "../../../../../UBMeEvents/Events/checkout/BundleScheduler/BundleScheduler";

const AddAttendeeModal = ({
                              OrderAdditionalData,
                              modalType,
                              editAdditionalInfo,
                              phoneError,
                              // AllTicketsAnalyticByEventId,
                              CartSessionByOrganizer,
                              PersistShoppingCart,
                              setIsOpen,
                              TimeZone,
                              additionalInfo,
                              handleChange,
                              handleChangeAdd,
                              handleInput,
                              handlePhoneNumberChange,
                              getPlaceholder,
                              toggleModal,
                              cartItems,
                              width,
                              ticketData,
                              orderId,
                              setOrderId,
                              disableDialCode,
                              setEditAdditionalinfo,
                              AttendeeUpdate,
                              GetAllAttendees,
                              setRecurringData,
                              recurringData,
                              shoppingCartDetails,
                              phoneNumber,
                              selectedCountry,
                              bundleSelectedQuantity,
                              setBundleSelectedQuantity
                          }) => {
    const [isNotFilled, setIsNotFilled] = useState(false);
    const [selectedOption, setSelectedOption] = useState({});
    const [currentTicket, setCurrentTicket] = useState("");

    useEffect(() => {
        if (modalType === "Add") {
            OrderAdditionalData();
            // AllTicketsAnalyticByEventId();
            CartSessionByOrganizer();
        }
    }, []);

    useEffect(() => {


        additionalInfo?.some((i) =>
            i?.FieldName === "Terms And Conditions" && i?.DefaultValue === ""
                ? setIsNotFilled(false)
                : i?.DefaultValue === "") || !cartItems.length > 0
            ? setIsNotFilled(true)
            : setIsNotFilled(false)
    }, [additionalInfo, cartItems]);

    const currentTimeZone = JSON.parse(localStorage.getItem("CurrentTimeZone"));

    const istToUtc = (datetime) => {
        const timeZone = JSON.parse(localStorage.getItem("CurrentTimeZone"));
        const istDateTime = moment.tz(datetime, "MM/DD/YYYY hh:mm:ss A", timeZone);
        return istDateTime.clone().tz("UTC");
    };

    const checkDateWithinRange = (startDateTime, endDateSTime) => {
        const nowUtc = moment.utc();
        const startUtc = istToUtc(startDateTime);
        const endUtc = istToUtc(endDateSTime);
        return nowUtc.isBetween(startUtc, endUtc);
    };

    return (
        <div
            style={{
                position: "relative",
                float: "right",
            }}
            className="addAttendeeModal"
        >
            <div
                style={{
                    overflow: "auto",
                    backgroundColor: "#fff",
                    width: width < 600 ? width : 600,
                    height: "100vh",
                    padding: "10px 15px",
                    boxSizing: "border-box",
                }}
            >
                {modalType === "Add" ? (
                    <div>
                        <div className="edit_container">
                            <div style={{padding: 10}} className="edit_container_close_btn">
                                <IoCloseOutline
                                    size={25}
                                    color="rgba(0, 0, 0, 0.54)"
                                    onClick={toggleModal}
                                />
                            </div>
                            <p className="edit_container_header">{Text.AddAttendees}</p>
                            <div/>
                        </div>
                        <div
                            className="modalDetail"
                            style={{
                                paddingBottom: "10px",
                                height: "100%",
                            }}
                        >
                            <div className="modalForm">
                                {additionalInfo?.map(
                                    ({FieldName, FormFieldId, DefaultValue, Status}, index) =>
                                        FieldName === "Terms And Conditions" ? null : (
                                            <div
                                                style={{
                                                    position: "relative",
                                                    marginBottom: 20,
                                                }}
                                            >
                                                <p
                                                    style={{
                                                        fontSize: 14,
                                                        fontWeight: 400,
                                                        marginBottom: 8,
                                                    }}
                                                >
                                                    {FieldName}{" "}
                                                    <span
                                                        style={{
                                                            color: "red",
                                                            fontSize: "medium",
                                                        }}
                                                    >
                                                            *
                                                        </span>{" "}
                                                </p>
                                                {FieldName === "Phone" ? (
                                                    <div style={{
                                                        paddingLeft: "2%",
                                                        border: "2px solid #d8d8d8",
                                                        borderRadius: "5px",
                                                        outlineColor: "#fff",
                                                    }}
                                                    >
                                                        <div className="phone-input-container">
                                                        <PhoneInput
                                                            country={selectedCountry || 'us'}
                                                            value={phoneNumber}
                                                            onChange={(value, country) => handlePhoneNumberChange(value, country)}
                                                            inputProps={{
                                                                name: 'phone',
                                                                required: true,
                                                            }}
                                                            // placeholder={getPlaceholder(selectedCountry.toUpperCase())}
                                                            containerStyle={{borderWidth: 0}} // Optional: To remove the border of the parent div
                                                            inputStyle={{
                                                                border: 'none',
                                                                marginLeft: '50px',
                                                                width: '80%'
                                                                // background: 'aliceblue'
                                                            }}
                                                            enableSearch={true}
                                                            countryCodeEditable={false}
                                                            // disableCountryCode={disableDialCode}
                                                        />
                                                    </div>
                                                    </div>
                                                ) : (
                                                    <div style={{
                                                                 paddingLeft: "2%",
                                                                 border: "2px solid #d8d8d8",
                                                                 borderRadius: "5px",
                                                                 outlineColor: "#fff",
                                                             }}
                                                    >
                                                        <div className="div-with-scroll">
                                                            {FieldName === "Full Name" || FieldName === "Email" || FieldName === "Phone" ? (
                                                                <input
                                                                    className="add_info_input"
                                                                    id={index.toString()}
                                                                    name={FieldName}
                                                                    value={DefaultValue}
                                                                    key={FormFieldId}
                                                                    type="text"
                                                                    required={true}
                                                                    // maxLength="260"
                                                                    onChange={(e) => handleChangeAdd(e)}
                                                                />
                                                            ) : (
                                                                <textarea
                                                                    className="add_info_textarea"
                                                                    id={index.toString()}
                                                                    name={FieldName}
                                                                    value={DefaultValue}
                                                                    key={FormFieldId}
                                                                    required={true}
                                                                    onChange={(e) => handleChangeAdd(e)}
                                                                    onInput={(e) => handleInput(e)}
                                                                    rows="1"  // Start with a single row
                                                                />
                                                            )
                                                            }
                                                        {DefaultValue === "" && (
                                                            <label
                                                                htmlFor={index.toString()}
                                                                style={{
                                                                    position: "absolute",
                                                                    // top: "-5px",
                                                                    left: 4,
                                                                    fontSize: 15,
                                                                    margin: 0,
                                                                    fontWeight: 400,
                                                                    color: "#b1b1b1",
                                                                    paddingTop: "0.5%",
                                                                    minHeight: "33px",
                                                                }}
                                                            >
                                                                {FieldName !== "Full Name" && FieldName !== "Email" && FieldName !== "Phone" ? (
                                                                    "Type your answer"
                                                                ) : (
                                                                    <>
                                                                  <span
                                                                      style={{
                                                                          color: "red",
                                                                          fontSize: "medium",
                                                                      }}
                                                                  >
                                                                    *
                                                                  </span>{" "}
                                                                        {FieldName} {/* Correct placement of FieldName */}
                                                                    </>
                                                                )}
                                                            </label>
                                                        )}
                                                    </div>
                                                    </div>
                                                )}
                                                {FieldName === "Phone" && phoneError && (
                                                    <div
                                                        style={{
                                                            marginTop: 10,
                                                            color: "red",
                                                            fontSize: 12,
                                                        }}
                                                    >
                                                        {phoneError}
                                                    </div>
                                                )}
                                            </div>
                                        )
                                )}
                                <div className="attendees_ticket_wrapper">
                                    <div className="attendees_tickets">
                                        <p style={{fontSize: 16, fontWeight: 600}}>
                                            {Text.Tickets}
                                        </p>
                                        <div className="render-ticket-header">
                                            <p>{Text.Quantity}</p>
                                            <div style={{width: 100}}>
                                                <p>{Text.TicketType}</p>
                                            </div>
                                        </div>
                                        {ticketData?.length > 0 &&
                                            ticketData.map((item, idx) => (
                                                <div key={idx}>
                                                    <RenderTicketsData
                                                        item={item}
                                                        cartItems={cartItems}
                                                        TimeZone={TimeZone}
                                                        handleChange={handleChange}
                                                        selectedOption={selectedOption}
                                                        checkDateWithinRange={checkDateWithinRange}
                                                        currentTicket={currentTicket}
                                                        recurringData={recurringData}
                                                        shoppingCartDetails={shoppingCartDetails}
                                                        bundleSelectedQuantity={bundleSelectedQuantity}
                                                        setBundleSelectedQuantity = {setBundleSelectedQuantity}
                                                    />

                                                    {item?.RecurringTicket === "True" &&
                                                        checkDateWithinRange(
                                                            item.ActiveDate,
                                                            item.InActiveDate,
                                                            currentTimeZone
                                                        ) && (
                                                            item.isBundle ? (
                                                                (() => {
                                                                    // const isRecData = cartItems.find((x) => x.TicketId === item.TicketId);
                                                                    if (bundleSelectedQuantity[item.TicketId] > 0) {
                                                                        return (
                                                                            <BundleScheduler
                                                                                isAttendee={true}
                                                                                item={item}
                                                                                key={item?.TicketId}
                                                                                cartItems={cartItems}
                                                                                handleChange={handleChange}
                                                                                selectedOption={selectedOption}
                                                                                setSelectedOption={setSelectedOption}
                                                                                setRecurringData={setRecurringData}
                                                                                currentTicket={currentTicket}
                                                                                setCurrentTicket={setCurrentTicket}
                                                                                recurringData={recurringData}
                                                                                bundleSelectedQuantity={bundleSelectedQuantity}
                                                                            />
                                                                        );
                                                                    }
                                                                })()
                                                            ) : (
                                                            <EventSlotsScheduler
                                                                isAttendee={true}
                                                                item={item}
                                                                key={item?.TicketId}
                                                                cartItems={cartItems}
                                                                handleChange={handleChange}
                                                                selectedOption={selectedOption}
                                                                setSelectedOption={setSelectedOption}
                                                                setRecurringData={setRecurringData}
                                                                currentTicket={currentTicket}
                                                                setCurrentTicket={setCurrentTicket}
                                                                recurringData={recurringData}
                                                            />
                                                            )
                                                        )}
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            </div>
                            <div className="detailAction" style={{paddingBottom: "20px"}}>
                                <Button
                                    onClick={() => setIsOpen(false)}
                                    style={{
                                        width: 120,
                                        height: 50,
                                        backgroundColor: "#fff",
                                        fontSize: "1.5rem",
                                        color: "#000000",
                                        padding: "12px 60px",
                                        borderColor: "#968e8e",
                                    }}
                                    className="btn_save_edit_details"
                                    // className={classes.eventDetailsAboutUploadMap}
                                    color={"primary"}
                                    variant={"outlined"}
                                    component="span"
                                >
                                    {Text.Cancel}
                                </Button>

                                <Button
                                    onClick={() => {

                                        PersistShoppingCart();
                                    }}
                                    disabled={isNotFilled}
                                    style={{
                                        backgroundColor: isNotFilled
                                            ? "#ff91e0"
                                            : "rgb(163, 56, 164)",
                                        fontSize: "1.5rem",
                                        height: 50,
                                        color: "#fff",
                                        padding: "12px 60px",
                                        border: "none",
                                        width: 120,
                                        borderRadius: 9,
                                    }}
                                    className="btn_save_edit_details"
                                    // className={classes.eventDetailsAboutUploadMap}
                                    color={"primary"}
                                    variant={"contained"}
                                    component="span"
                                >
                                    {Text.Save}
                                </Button>
                            </div>
                        </div>
                    </div>
                ) : modalType === "edit" ? (
                    <UpdateAttendeeModal
                        AttendeeUpdate={AttendeeUpdate}
                        editAdditionalInfo={editAdditionalInfo}
                        isOpen={() => {
                            setEditAdditionalinfo("");
                            setIsOpen(false);
                        }}
                    />
                ) : modalType === "notes" ? (
                    <Notes
                        TimeZone={TimeZone}
                        orderId={orderId}
                        setOrderId={setOrderId}
                        isOpen={() => setIsOpen(false)}
                    />
                ) : (
                    <OrderDetail
                        GetAllAttendees={GetAllAttendees}
                        TimeZone={TimeZone}
                        orderId={orderId}
                        setOrderId={setOrderId}
                        isOpen={() => setIsOpen(false)}
                    />
                )}
            </div>
        </div>
    )
        ;
};

export default AddAttendeeModal;
