import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Sidebar from "../SideBar/sideBar";
import Main from "../Main/main";
import { connect } from "react-redux";
import "./index.css";
import Navbar from "Components/UBMeEvents/Components_UBMeEvents/Navbar/Navbar";

const styles = {
    root: {
        flexGrow: 1,
        height: "100vh",
        zIndex: 1,
        overflow: "hidden",
        position: "relative",
        display: "flex",
        color: "#000000",
        width: "100%",
    },
};

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
        };
    }
    handleToggle = () => {
        this.setState({ open: !this.state.open });
    };

    render() {
        const { classes } = this.props;
        return (
            <div className="scrollable-div">
                <Navbar isToggle={true} toggleSideNav={this.handleToggle} position={'fixed'}/>
                <div className={classes.root}>
                    <Sidebar toggle={this.handleToggle} mobileOpen={this.state.open} />
                    <Main user={this.props.user} />
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        user: state.loginResponse,
    };
};
export default withStyles(styles)(connect(mapStateToProps, null)(Index));
