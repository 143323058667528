import React from 'react'
import ChatComponent from './ChatComponent'
import ChattingNow from './ChattingNow'
import './EventChat.css'

function EventChat({onContactClick , setUserData}) {
  return (
    <div className="eventChat">
      <ChatComponent />
      <ChattingNow onContactClick={onContactClick} setUserData={setUserData}/>
    </div>
  )
}

export default EventChat