import React, {useEffect} from "react";
import { useState } from "react";
import ticketFeesImg from "images/TicketingFees/TicketingFees.jpg";
import Navbar from "../Components_UBMeEvents/Navbar/Navbar";
import Footer from "../Components_UBMeEvents/Footer/Footer";
import "./TicketingFees.css";
import { connect } from "react-redux";
import {axiosE} from "../../../Service/service";
import * as constants from "../../../Constants";
import './../../BusinessPortal/Account/account.css';

const Pricing = ({}) => {

    const [data, setData] = useState(null);

    useEffect(() => {
        GetAllStripeSupportedCountries();
    }, []);

    const GetAllStripeSupportedCountries = async () => {
        try {
            const { data } = await axiosE.get(
                `${constants.API.GetStripeSupportedCountries}`
            );
            if (data.results.ResponseCode) {

                const parsedData = JSON.parse(data.results.Data);
                // Sort the data alphabetically based on the "title" property
                parsedData.sort((a, b) => a.CountryName.localeCompare(b.CountryName));

                setData(parsedData);
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    return (
        <div>
            {" "}
            <Navbar />
            <div className="">
                <img
                    style={{ width: "100%", height: "100%" }}
                    src={ticketFeesImg}
                    alt=""
                />
            </div>
            <div>
                <div className="ticketingFees_text">
                    <h3 style={{
                        lineHeight: "52px",
                    }}>Choose who pays the Ticket Fees</h3>
                    <p>
                        By default, ticket fees are paid by your attendees. However, when you create a paid ticket type, you can choose who will pay the ticket fees.
                    </p>
                    <ul>
                        <li>
                            <strong>Absorb fees:</strong> Check the box next to “Absorb fees” if you want the fees to be included in the ticket price you set. Fees will be deducted from your sales at the time of your payout.
                        </li>
                        <li>
                            <strong>Pass fees on:</strong> Leave the box next to “Absorb fees” unchecked if you want attendees to pay the fees on top of the ticket price you set. Fees will be collected off the top of your ticket sales at the time of your payout.
                        </li>
                    </ul>
                    <br/>
                    <br/>
                    <h3>Ticketing Fees per country</h3>
                    <p>
                        You can't change your payout country and currency when sales are pending or after tickets have been sold.
                    </p>
                </div>
                <div className="table-responsive-div">
                    {(data !== null && data !== undefined) &&
                        <table className="table-responsive">
                        <thead>
                        <tr>
                            <th className="table-responsive-th">Payout Country</th>
                            <th className="table-responsive-th">Service Fee</th>
                            <th className="table-responsive-th">Payment Processing Fee</th>
                        </tr>
                        </thead>
                        <tbody>
                        {data.map((item, index) => (
                            <tr key={index}>
                                <td className="table-responsive-td">{item.CountryName}</td>
                                <td className="table-responsive-td">{item.UBMeFee + "%" + (item.UbmeAdditionalCharge ? " + " + item.CurrencySymbol + item.UbmeAdditionalCharge : "") + " per sold ticket"}</td>
                                {index === 0 &&
                                    <td
                                        className="table-responsive-td"
                                        style = {{
                                            textAlign: "center"
                                        }}
                                        rowSpan={data.length}
                                    >
                                        <strong>See Stripe For Details:</strong>
                                        <br></br>
                                        <a
                                            href="https://stripe.com/pricing"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style = {{
                                                cursor: "pointer",
                                                textDecoration: "underline",
                                                fontWeight: "300",
                                            }}
                                        >
                                             https://stripe.com/pricing
                                        </a>
                                    </td>
                                }
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    }
                </div>
            </div>
            <Footer />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
    };
};
const matchDispatchToProps = (dispatch) => {
    return {
        dispatch,
    };
};
export default connect(mapStateToProps, matchDispatchToProps)(Pricing);
