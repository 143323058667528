/* eslint-disable */
import React, { useEffect, useState } from "react";
import EventCardWrapper from "../../Components_UBMeEvents/EventCardWrapper/EventCardWrapper";

import "./container.css";
import { axiosE } from "Service/service";
import moment from "moment";
import "moment-timezone";

import { connect } from "react-redux";
import { AddToFavorite, SetCardData } from "Actions/favoriteAction";
import { LoginPopup } from "Actions";
import * as Scroll from "react-scroll";
import CircularProgress from "@material-ui/core/CircularProgress";
import Map from "../Map/Map";
import PagiNation from "../../Components_UBMeEvents/EventCardWrapper/Pagination/PagiNation";
import * as constants from "Constants";

import AutoCompleteV2 from "Components/UBMeEvents/Home/Container/AutoCompletePlaces_v2/AutoCompleteV2";
import { EventSearch, GetAllEvents } from "Actions/eventActions";

import { useRef } from "react";
import favoriteReducer from "Reducers/favoriteReducer";


const MainContainer = ({
  cardData,
  dispatch,
  favoriteData,
  results,
  isLoggedIn,
  LoginPopup,
  latlong,
  EventSearch,
  eventSearchData,
  eventsCount
}) => {
  const [eventData, setEventData] = useState([]);
  const [selectCat, setSelectCat] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [fetchAllData, setFetchAllData] = useState(false);
  const [totalCounts, setTotalCounts] = useState(0);
  const [running, setRunning] = useState(false);

  var scroller = Scroll.scroller;
  useEffect(() => {
    if (!running) {
      if (eventSearchData.categoryName !== undefined) {
        if (!selectCat.includes(eventSearchData.categoryName)) {
          setSelectCat([...selectCat, eventSearchData.categoryName]);
        }
      }
      setEventData(cardData);
    }
    setTimeout(() => {
      setRunning(false);
    }, 1000);
  }, [cardData, isLoggedIn]);

  const CategoryData = [];

  cardData.map((x) =>
    CategoryData.filter((a) => a.CategoryName == x.CategoryName).length > 0
      ? null
      : CategoryData.push(x)
  );

  setTimeout(() => {
    setFetchAllData(false);
  }, 10);

  useEffect(() => {
    if (selectCat.length > 0) {
      AddFilterCategoryData();
    } else {
      setEventData(cardData);
    }
  }, [selectCat]);
  const AddFilterCategoryData = (i) => {
    const newCatData = cardData.filter((c) =>
      selectCat?.includes(c.CategoryName)
    );
    setEventData(newCatData);
  };

  const GetEventData = async (data) => {
    setLoading(true);
    const searchData = {
      radius: "30",
      page: 1,
      rowOfPages: 10,
      category: "",
      searchItem: "",
      totalCounts: "",
    };

    dispatch(EventSearch(searchData));
    let eventData = {
      Latitude: data?.lat,
      Longitude: data?.lng,
      Radius: eventSearchData.radius,
      SearchTerm: "",
      UserId: isLoggedIn ? results.ProfileDetail.UserId : 0,
      CategoryIds: "",
      PageNumber: 1,
      RowsOfPage: eventSearchData.rowOfPages,
    };
    dispatch(GetAllEvents(eventData))
      .then((data) => {
        setLoading(false);
        setEventData(data.EventList);
        setTotalCounts(data.TotalCounts)
        dispatch({ type: "EVENTS_TOTAL_COUNT", payload: data.TotalCounts })
        dispatch({
          type: "CARD_DATA",
          payload: data.EventList,
        });
      })
      .catch((error) => { });
  };

  const favCardData = async () => {
    // console.log("cardData",cardData);
    // let eventsByUserLiked = []
    // if(cardData?.length > 0){
    //   AddDataTofavorite(cardData?.map((item)=>{
    //     if(item?.Liked === "true"){
    //     return item
    //     }

    //   }));

    // }


    // console.log("eventsByUserLiked",eventsByUserLiked);
    // try {
    //   const { data } = await axiosE.post(
    //     `${constants.API.GetAllEventsByUserLiked}`,
    //     {
    //       UserId: isLoggedIn ? results.ProfileDetail.UserId : 0,
    //       PageNumber: 1,
    //       RowsOfPage: 20,
    //     }
    //   );

    //   const newTimeZoneData = data.results.EventList.map((item) => {
    //     const UtcToLocal =  moment.utc(item.StartDateTime).local().format('MM/DD/YYYY HH:mm:ss A')
    //     var Time = moment(UtcToLocal, "MM/DD/YYYY HH:mm:ss A");
    //     const convertedTime = Time.tz(item.TimeZone);
    //     const obj = Object.assign({}, item);
    //     obj["StartDateTime"] = convertedTime;
    //     return obj;
    //   });



    //   let newArr = [...newTimeZoneData];
    //   const sortedArray = newArr.sort(
    //     (a, b) =>
    //       new moment(a.StartDateTime).format("X") -
    //       new moment(b.StartDateTime).format("X")
    //   );
    //   AddDataTofavorite(sortedArray);
    // } catch (error) {}

  };


  useEffect(() => {
    if (isLoggedIn) favCardData();
  }, [results]);

  const AddDataTofavorite = (item) => {
    setRunning(true);
    dispatch(AddToFavorite(item));
  };
  const LoginPopupOpen = (val) => {
    dispatch(LoginPopup({ path: "", value: val }));
  };
  const scrollRef = useRef();
  const scrollToTop = () => {
    setTimeout(() => {
      scrollRef.current?.scrollIntoView({
        behavior: "smooth",
        inline: "nearest",
      });
    }, 1000);
    setTimeout(() => {
      scroller.scrollTo("myScrollToElement", {
        duration: 1500,
        delay: 100,
        smooth: true,
        containerId: "main_containerv",
        offset: -60,
      });
    }, 2000);
  };

  useEffect(() => {
    if (isLoggedIn) {
      const data = {
        lat: latlong.lat,
        lng: latlong.lng,
      }
      GetEventData(data)
    }
  }, [isLoggedIn])

  return (
    <>
      <div id="main_containerv" class="main_container">
        <div id="cards_element_1" class="cards_element_1">
          <div
            style={{ backgroundColor: '#FFF' }}
            // style={{ height: eventData.length > 0 ? "88vh" : "100vh" }}
            className="cards_elements"
            id="cards_scroller"
          >
            <div name="myScrollToElement" ref={scrollRef} />
            <div style={{ margin: 20 }}>
              <AutoCompleteV2
                GetEventData={GetEventData}
                isFirstRender={isFirstRender}
              />

              <div style={{ flexWrap: "wrap" }}>
                {CategoryData.length > 0 && (
                  <button
                    onClick={() => {
                      const data = {
                        lat: latlong.lat,
                        lng: latlong.lng,
                      };
                      setFetchAllData(true);
                      setSelectCat([]);

                      setSelectedCategory("");
                      setEventData(cardData);
                      GetEventData(data);
                      const searchData = {
                        radius: "30",
                        page: 1,
                        rowOfPages: 10,
                        category: "",
                        searchItem: "",
                        totalCounts: "",
                      };

                      dispatch(EventSearch(searchData));
                    }}
                    style={{
                      backgroundColor:
                        !selectCat.length > 0 && eventSearchData.category === ""
                          ? "#ca3dd9"
                          : "#f8f8fa",
                      color:
                        !selectCat.length > 0 && eventSearchData.category === ""
                          ? "#FFFFFF"
                          : "#808080",
                    }}
                    className="chip_button"
                  >
                    All
                  </button>
                )}

                {CategoryData?.map((i, index) => (
                  <>
                    <button
                      onClick={() => {
                        if (!selectCat.includes(i.CategoryName)) {
                          setSelectCat([...selectCat, i.CategoryName]);
                          setSelectedCategory([
                            ...selectedCategory,
                            i.CategoryId,
                          ]);
                        } else {
                          setSelectCat(
                            selectCat.filter((c) => c !== i.CategoryName)
                          );
                          setSelectedCategory(
                            selectedCategory.filter((c) => c !== i.CategoryId)
                          );
                        }
                      }}
                      className="chip_button"
                      style={{
                        color: selectCat?.includes(i.CategoryName)
                          ? "#FFFFFF"
                          : "#808080",
                        backgroundColor: selectCat?.includes(i.CategoryName)
                          ? "#ca3dd9"
                          : "#f8f8fa",
                      }}
                      key={index}
                    >
                      {i.CategoryName}
                    </button>
                  </>
                ))}
              </div>
            </div>

            <div style={{ marginBottom: 50 }}>
              <EventCardWrapper
                gridStyle={0}
                isLoggedIn={isLoggedIn}
                data={eventData}
                results={results}
                favoriteData={favoriteData}
                LoginPopup={LoginPopupOpen}
                AddFavorite={AddDataTofavorite}
              />
            </div>
            {!eventData.length > 0 && (
              <div className="no_events">
                {loading ? (
                  <CircularProgress size={50} color="#ca3dd9" />
                ) : (
                  !eventData.length > 0 && (
                    <div className="no_event_found">
                      <div>
                        <h2
                          style={{
                            textAlign: "center",
                            color: "gray",
                            margin: 0,
                          }}
                        >
                          {constants.Message.NoresultFound}
                        </h2>
                      </div>
                    </div>
                  )
                )}
              </div>
            )}
          </div>
          <div>
            {eventData.length > 0 && (
              <PagiNation
                totalCounts={totalCounts}
                fetchAllData={fetchAllData}
                selectedCategory={selectedCategory}
                scrollToTop={scrollToTop}
                GetEventData={GetEventData}
                length={eventData.length}
              />
            )}
          </div>
        </div>
        <div class="map_container">
          <Map latlong={latlong} cardData={eventData} />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    favoriteData: state.favoriteReducer.favoriteData,
    cardData: state.favoriteReducer.cardData,
    latlong: state.favoriteReducer.latlong,
    results: state.loginResponse.results,
    isLoggedIn: state.loginResponse.isLoggedIn,
    eventSearchData: state.eventReducer.EventSearchData,
    categoryData: state.favoriteReducer.categoryData,
    eventsCount: state.eventReducer.eventsCount
  };
};
const matchDispatchToProps = (dispatch) => {
  return {
    AddToFavorite: () => dispatch(AddToFavorite),
    SetCardData: () => dispatch(SetCardData),
    LoginPopup,
    GetAllEvents: () => dispatch(GetAllEvents),
    EventSearch,
    dispatch,
  };
};
export default connect(mapStateToProps, matchDispatchToProps)(MainContainer);
