import React from 'react';
import Card from '../../../Helper/CardWrapper/cardWrapper';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import './routeHeader.css';
import Grid from '@material-ui/core/Grid';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { updateVisitAction } from '../../../Actions/overviewActions';

class RouteHeader extends React.Component{

    render() {
        const {title,text} = this.props;
        return (
            <div style={{marginBottom:'2%'}}>
            <Card
                content={
                    <Grid container>
                        <Grid item sm={9} xs={12} md={10}>
                            <div className="overview-text">
                                <p><span>{title}</span> - {text} </p>
                            </div>
                        </Grid>
                        <Grid item sm={3} xs={12} md={2} className="got-it-btn">
                            <div>
                            <Button className="btn-got"
                                    size="medium"
                                    variant={'contained'}
                                    color={'primary'}
                                    onClick={this.onClick}>
                                Got it
                            </Button>
                            </div>
                        </Grid>
                    </Grid>
                }
                CardContentProps={{
                    style: {paddingBottom:5,width:this.props.width}
                }}
            />
            </div>
        )
    }
}
RouteHeader.propTypes={
    title:PropTypes.string.isRequired,
    text:PropTypes.string.isRequired,
    onClick:PropTypes.func
};
const mapStateToProps=(state)=> {
    return {
        user:state.loginResponse
    }
};
const matchDispatchToProps=(dispatch)=> {
    return bindActionCreators({
        updateVisitAction
    },dispatch)
};
export default connect(mapStateToProps,matchDispatchToProps)(RouteHeader);