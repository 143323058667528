import React, { useEffect } from "react";
import { useState } from "react";
import "./ticketOrderSummary.css";
import { RiCoupon3Fill } from "react-icons/ri";
import { axiosE } from "Service/service";
import { AiFillCloseCircle } from "react-icons/ai";
import { connect } from "react-redux";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { useLayoutEffect } from "react";
import dayjs from "dayjs";
import {
  GetDiscountDetails,
  GetDiscountData,
  ticktesCartItem,
  PersistShoppingCartSession,
  removeAllEventDateDetails,
} from "Actions/eventActions";
import { IoClose } from "react-icons/io5";
import moment from "moment";
import "moment-timezone";
import {
  Box,
  Button,
  createMuiTheme,
  FormControl,
  Grid,
  IconButton,
  Modal,
  TextField,
  Tooltip,
  withStyles,
} from "@material-ui/core";
import { Info } from "@material-ui/icons";
import { Text } from "Constants";
import store from "../../../../../store";

const Decimal = require('decimal.js');

const OrderSummary = ({
                        showOrderSummary = false,
                        currentEventDetails,
                        cartItems = [],
                        DiscountExist = false,
                        GetDiscountDetails,
                        discountData,
                        dispatch,
                        ticktesCartItem,
                        setOpenCheckOut,
                        shoppingCartDetails,
                        setLeaveCheckOut,
                        payment_id = "",
                        isPromo,
                        DiscountUtilization,
                        setShowOrderSummary,
                        onPersistCart,
                        Token = null,
                        hasCheckoutQuery,
                      }) => {
  const [isPromoCode, setIsPromoCode] = useState(false);
  const [promoCode, setPromoCode] = useState(discountData?.DiscountCode);
  const [appliedCode, setAppliedCode] = useState("");
  const [discountError, setDiscountError] = useState("");
  const [newCartItemsData, setNewCartItemsData] = useState([]);

  useEffect(() => {
    const CartItemsData = cartItems?.map((item) => {
      const AdditionalFee = Number(
          shoppingCartDetails?.CartSession?.AdditionalFee
      );
      const obj = Object.assign({}, item);
      obj["TaxAmount"] =
          (Number(item.Quantity) * Number(item.Price) * Number(item.TaxAmount)) /
          100;
      // if(discountData?.ResponseCode === false){
      if (item.isAbsorb === true) {
        obj["UBMeFeePercent"] = 0;
      } else {
        obj["UBMeFeePercent"] =
            (Number(item.Quantity) *
                Number(item.Price) *
                Number(item.UBMeFeePercent)) /
            100 +
            (Number(item.Price) === 0
                ? 0
                : Number(item.Quantity) * AdditionalFee);
      }
      // }

      if (discountData?.ResponseCode) {
        if (Number(item.Price) === 0) obj["UBMeFeePercent"] = 0;
        if (
            discountData?.DiscountType === "Amount" &&
            discountData?.TicketsId?.includes(item.TicketId)
        ) {
          const newItemPrice =
              Number(item.Price) - Number(discountData?.disCountAmount);
          // console.log("newItemPrice", newItemPrice);
          obj["DiscountAmount"] =
              Number(item.Quantity) * Number(discountData?.disCountAmount);
          if (item.isAbsorb === true) {
            obj["UBMeFeePercent"] = 0;
          } else {
            obj["UBMeFeePercent"] =
                (
                    Number(item.Quantity) *
                    newItemPrice *
                    Number(item.UBMeFeePercent)
                ) / 100 +
                (
                    Number(item.Price) === 0 ?
                        0 :
                        (
                            Number(item.Quantity) *
                            newItemPrice *
                            Number(item.UBMeFeePercent)
                        ) / 100 === 0 ?
                            0 :
                            Number(item.Quantity) * AdditionalFee
                );
          }
        }
        if (
            discountData?.DiscountType !== "Amount" &&
            discountData?.TicketsId?.includes(item.TicketId)
        ) {
          obj["DiscountAmount"] =
              (Number(item.Quantity) *
                  Number(item.Price) *
                  Number(discountData?.disCountAmount)) /
              100;
          if (item.isAbsorb === true) {
            obj["UBMeFeePercent"] = 0;
          } else {
            obj["UBMeFeePercent"] =
                (
                    Number(item.Quantity) *
                    Number(item.Price - (Number(item.Price) * Number(discountData?.disCountAmount)) / 100) *
                    Number(item.UBMeFeePercent)
                ) / 100 +
                (
                    Number(item.Price) === 0 ?
                        0 :
                        (
                            Number(item.Quantity) *
                            Number(item.Price - (Number(item.Price) * Number(discountData?.disCountAmount)) / 100) *
                            Number(item.UBMeFeePercent)
                        ) / 100 === 0 ?
                            0 :
                            Number(item.Quantity) * AdditionalFee);
          }
        }
      } else {
        if (discountData?.TicketsId?.includes(item.TicketId)) {
          obj["DiscountAmount"] = 0;
        }
      }
      return obj;
    });

    CartItemsData.forEach((item) => sortSelectedSlots(item));

    setNewCartItemsData(CartItemsData);
  }, [cartItems, discountData]);

  const itemsPrice = newCartItemsData?.reduce(
      (a, c) => a + Number(c.Quantity) * Number(c.Price.replace(/,/g, "")),
      0
  );
  const discount = newCartItemsData?.reduce(
      (a, c) => a + Number(c.DiscountAmount == null ? 0 : c.DiscountAmount),
      0
  );

  const subTotal = itemsPrice - discount;

  const tax =
      (Number(subTotal) *
          Number(
              shoppingCartDetails?.CartSession?.Tax === undefined
                  ? 0
                  : shoppingCartDetails?.CartSession?.Tax
          )) /
      100;
  const fee = newCartItemsData?.reduce(
      (a, c) => a.plus(new Decimal(c.UBMeFeePercent)),
      new Decimal(0)
  ).toNumber();
  const totalPrice = subTotal + tax + fee;

  const ApplyPromoCode = (code) => {
    return new Promise(async (resolve, reject) => {
      setAppliedCode("");
      if (code) {
        try {
          let storeData = store.getState();
          const data = await dispatch(GetDiscountDetails(currentEventDetails?.EventId, code));

          if (data.results.Message === "Valid discount code found") {
            const persistData = storeData.eventReducer.persistShopingCart;
            persistData.ShoppingCart.CartSession.DiscountId = data.results.DiscountId;

            // After PersistShoppingCartSession completes, resolve the promise with the response
            try {
              const res = await dispatch(PersistShoppingCartSession(persistData));
              onPersistCart(res, null);
            } catch (err) {
              // Handle errors
              // You can also pass an error to the callback if needed
              onPersistCart(null, err);
            }

            setDiscountError("");
            const disCountData = {
              DiscountType: data.results.DiscountType,
              disCountAmount: data.results.DiscountAmount,
              disCountId: data.results.DiscountId,
              TicketsId: data.results.TicketsId.replace(/ /g, "").split(","),
              RemainingUtilization: data.results.RemainingUtilization,
              ResponseCode: data.results.ResponseCode,
              DiscountCode: code,
            };
            if (
                newCartItemsData?.some((id) =>
                    discountData?.TicketsId?.includes(id.TicketId)
                )
            ) {
              setAppliedCode(`${promoCode} Applied`);
            } else {
              setAppliedCode("Discount is not applicable for this ticket");
            }

            dispatch(GetDiscountData(disCountData));
            setIsPromoCode(true);
          } else if (data.results.DiscountId && !data.results.ResponseCode) {
            setDiscountError(data.results.Message);
          } else {
            // setIsPromoCode(false)
            setAppliedCode("");
            setDiscountError("Sorry, your promotion code is invalid");
            if (discount > 0) {
              const data = {
                DiscountType: "",
                disCountAmount: 0.0,
                disCountId: "",
                TicketsId: [],
                RemainingUtilization: "",
                ResponseCode: false,
                DiscountCode: "",
              };
              dispatch(GetDiscountData(data));
            }
          }
        } catch (error) {
          reject(error);
        }
      } else {
        let storeData = store.getState();
        const persistData = storeData.eventReducer.persistShopingCart;
        persistData.ShoppingCart.CartSession.DiscountId = "";
        console.log({ persistData });

        // After PersistShoppingCartSession completes, resolve the promise with the response
        try {
          const res = await dispatch(PersistShoppingCartSession(persistData));
          onPersistCart(res, null);
        } catch (err) {
          // Handle errors
          // You can also pass an error to the callback if needed
          onPersistCart(null, err);
        }

        setIsPromoCode(!isPromoCode);
        resolve(); // Resolve the promise without a response when there's no code
      }
    });
  };

  useEffect(() => {
    if (
        newCartItemsData?.some((id) =>
            discountData?.TicketsId?.includes(id.TicketId)
        )
    ) {
      setAppliedCode(`${discountData.DiscountCode} Applied`);
    } else {
      setAppliedCode("Discount is not applicable for this ticket");
    }
  }, [newCartItemsData]);

  useEffect(() => {
    if (discountData.ResponseCode) {
      ApplyPromoCode(discountData.DiscountCode);
    }
  }, []);

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  }
  const [width, height] = useWindowSize();

  const CreateEventDetailsAboutToolTip = withStyles(() => ({
    tooltip: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: "16px",
      border: "1px solid #dadde9",
    },
  }))(Tooltip);

  const titleWithToolTip = (title, tipText, mandatory = false) => {
    return (
        <span style={{ fontSize: 16 }}>
        {title}
          {mandatory && <span style={{ color: "red" }}> *</span>}
          <CreateEventDetailsAboutToolTip
              title={<React.Fragment>{tipText}</React.Fragment>}
          >
          <span
              style={{
                padding: "0 0 0 10px",
                cursor: "pointer",
                color: "rgb(161, 3, 150)",
              }}
          >
            <Info style={{ fontSize: 20 }} />
          </span>
        </CreateEventDetailsAboutToolTip>
      </span>
    );
  };

  const convertTo12HourFormat = (time, ticket) => {
    // console.log({ time, ticket });
    const isItMomentObject = ticket?.selectedDate && typeof ticket.selectedDate === 'object' && typeof ticket.selectedDate.format === 'function'
    const selectedDate = isItMomentObject ? ticket?.selectedDate.format("DD/MM/YYYY") : ticket?.selectedDate;
    const timeZone = JSON.parse(localStorage.getItem("CurrentTimeZone"));
    const dateTimeString = `${selectedDate} ${time}`;
    const utcDateTime = moment.utc(dateTimeString, "DD/MM/YYYY HH:mm");
    const istDateTime = utcDateTime?.tz(timeZone);
    const istLocalTime = istDateTime?.format("h:mm A");
    return istLocalTime;
  };

  const formatDate = (dateString) => {
    const parsedDate = dayjs(dateString, "DD/MM/YYYY");
    const formattedDate = parsedDate.format("ddd, MMM D YYYY");
    return formattedDate;
  };

  const sortSelectedSlots = (item) => {
    if (item.selectedSlots) {
      item.selectedSlots.sort((a, b) => {
        // Convert date strings to JavaScript Date objects for comparison
        const dateA = parseDate(a.date, a.StartTime);
        const dateB = parseDate(b.date, b.StartTime);

        // Compare dates
        if (dateA < dateB) {
          return -1;
        } else if (dateA > dateB) {
          return 1;
        } else {
          // If dates are equal, compare start times
          const timeA = parseInt(a.StartTime.replace(':', ''));
          const timeB = parseInt(b.StartTime.replace(':', ''));

          return timeA - timeB;
        }
      });
    }
  };

  // Parse date in DD/MM/YYYY format and time in HH:mm format
  const parseDate = (date, time) => {
    const [day, month, year] = date.split('/');
    const hours = time ? parseInt(time.split(':')[0]) : 0;
    const minutes = time ? parseInt(time.split(':')[1]) : 0;
    // Months are zero-based in JavaScript Date, so we subtract 1 from the month
    return new Date(year, month - 1, day, hours, minutes);
  };

  const eventDateDetails = (ticket) => {
    if (ticket) {
      if (ticket.isBundle && ticket?.selectedSlots !== undefined && ticket?.selectedSlots !== null) {
        const formattedDateTimeStrings = ticket?.selectedSlots.map((item) => {
          return `${formatDate(item.date)}, ${convertTo12HourFormat(item.StartTime, ticket)} - ${formatDate(item.date)}, ${convertTo12HourFormat(item.EndTime, ticket)}`;
        });

        const formattedDateTime = formattedDateTimeStrings.join('\n');

        return formattedDateTime;
      } else {
        return `${formatDate(ticket?.selectedDate)}, ${convertTo12HourFormat(ticket?.selectedSlot?.StartTime, ticket)} - ${formatDate(ticket?.selectedDate)}, ${convertTo12HourFormat(ticket?.selectedSlot?.EndTime, ticket)}`;
      }

    }
  };

  return (
      <React.Fragment>
        <div
            id="order_container"
            style={{
              opacity: width > 800 ? "1" : showOrderSummary ? "1" : "0",
              visibility:
                  width > 800 ? "visible" : showOrderSummary ? "visible" : "hidden",
              height: width > 800 ? "100%" : showOrderSummary ? "100%" : 0,
              transition:
                  width < 800 && showOrderSummary ? "all .5s ease-in-out" : "none",
            }}
        >
          <main className="main">
            <div class="description-card">
              <div class="venue-description">
                {width > 800 && !Token  && !hasCheckoutQuery ? (
                    <div
                        onClick={() => {
                          if (payment_id === "") {
                            setOpenCheckOut(false);
                            window.location.reload();
                          } else {
                            setLeaveCheckOut(true);
                          }

                          dispatch(removeAllEventDateDetails());

                          // payment_id === '' ? setOpenCheckOut(false) : setLeaveCheckOut(true)
                        }}
                        className="order_close"
                    >
                      <IoClose size={20} />
                    </div>
                ) : (
                    !Token ||
                    (width < 800  && !hasCheckoutQuery && (
                        <a
                            href="#"
                            onClick={() => {
                              setShowOrderSummary(!showOrderSummary);
                              dispatch(removeAllEventDateDetails());
                            }}
                            style={{ color: "#000000" }}
                            className="order_close"
                        >
                          <IoClose size={20} />
                        </a>
                    ))
                )}

                <img src={currentEventDetails?.ImageMedium} alt="" />
              </div>
              {newCartItemsData?.length > 0 ? (
                  <div class="order-summary">
                    <h2>{Text.OrderSummary}</h2>
                    <hr style={{ backgroundColor: "black", height: 1 }} />
                    {/* <div class="border-bottom" /> */}

                    {newCartItemsData?.map((item, idx) => (
                        <div key={idx}>
                          <div class="order-description">
                            <ul>
                              <li style={{ fontWeight: 600 }}>
                                {item.Quantity} x {item.Title}
                              </li>
                            </ul>
                            <ul>
                              <li>
                                {shoppingCartDetails?.CartSession?.CurrencySymbol}{(item?.Quantity * Number(item?.Price)).toFixed(2)}
                              </li>
                            </ul>
                          </div>
                          {item?.RecurringTicket === "True" && (
                              <div className="date-description">
                                <ul>
                                  <li dangerouslySetInnerHTML={{
                                    __html: eventDateDetails(item).replace(/\n/g, '<br />'),
                                  }}>
                                  </li>
                                </ul>
                              </div>
                          )}
                        </div>
                    ))}

                    <div>
                      {/* <div class="border-bottom" /> */}
                      <hr style={{ backgroundColor: "black", height: 1 }} />

                      <div class="grand-total">
                        <ul>
                          <li>{Text.SubTotal}</li>
                          {discount > 0 ? (
                              <li style={{ color: "#47c94d" }}>
                                {titleWithToolTip("Discount", "Discount")}
                              </li>
                          ) : null}

                          <li>
                            {titleWithToolTip(
                                "Fee",
                                "This fee helps operate UBMe and power your next event experience!"
                            )}
                          </li>
                          <li>
                            {titleWithToolTip(
                                cartItems[0]?.TaxName !== null
                                    ? cartItems[0]?.TaxName
                                    : "Tax",
                                "Tax: Tax rates are set by event organizer"
                            )}
                          </li>
                        </ul>
                        <ul style={{ textAlign: "right" }}>
                          <li>
                            {shoppingCartDetails?.CartSession?.CurrencySymbol}
                            {Intl.NumberFormat("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }).format(itemsPrice)}
                          </li>
                          {discount > 0 ? (
                              <li style={{ color: "#47c94d" }}>
                                {" "}
                                - {shoppingCartDetails?.CartSession?.CurrencySymbol}
                                {Intl.NumberFormat("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }).format(discount)}
                              </li>
                          ) : null}
                          <li>
                            {shoppingCartDetails?.CartSession?.CurrencySymbol}
                            {Intl.NumberFormat("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }).format(fee)}
                          </li>
                          <li>
                            {shoppingCartDetails?.CartSession?.CurrencySymbol}
                            {Intl.NumberFormat("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }).format(tax)}
                          </li>
                        </ul>
                      </div>
                    </div>

                    {newCartItemsData?.length > 0 ? (
                        <div>
                          {" "}
                          <div class="full-rupees">
                            <ul>
                              <li>Total</li>
                            </ul>
                            <ul>
                              <li>
                                {shoppingCartDetails?.CartSession?.CurrencySymbol}
                                {Intl.NumberFormat("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }).format(totalPrice)}
                              </li>
                            </ul>
                          </div>
                          {/* <div class="border-bottom" />{" "} */}
                        </div>
                    ) : null}
                    {isPromo && DiscountExist === "True" ? (
                        <div>
                          {(newCartItemsData && newCartItemsData?.length > 0) ||
                          discountData.ResponseCode ? (
                              <div style={{ position: "relative" }}>
                                {isPromoCode && (
                                    <>
                                      {promoCode && (
                                          <span
                                              onClick={() => {
                                                const data = {
                                                  DiscountType: "",
                                                  disCountAmount: 0.0,
                                                  disCountId: "",
                                                  TicketsId: [],
                                                  RemainingUtilization: "",
                                                  ResponseCode: false,
                                                  DiscountCode: "",
                                                };
                                                dispatch(GetDiscountData(data));
                                                setPromoCode("");
                                                setDiscountError("");
                                                ApplyPromoCode("")
                                              }}
                                              style={{
                                                position: "absolute",
                                                top: 18,
                                                right: 22,
                                                cursor: "pointer",
                                              }}
                                          >
                                <AiFillCloseCircle size={20} />
                              </span>
                                      )}

                                      <input
                                          type="text"
                                          value={promoCode}
                                          onChange={(e) => setPromoCode(e.target.value)}
                                          className="promo_code_feild"
                                          placeholder="Promo code"
                                      />
                                    </>
                                )}

                                {discountError !== "" && promoCode !== "" && (
                                    <p
                                        style={{
                                          // position: "absolute",
                                          textAlign: "center",
                                          fontWeight: 600,
                                          color: "#f03063",
                                          bottom: -6,
                                          left: 36,
                                          fontSize: 16,
                                          padding: "10px 0px",
                                        }}
                                    >
                                      {discountError}
                                    </p>
                                )}
                                {discountError === "" &&
                                    appliedCode !== "" &&
                                    discountData.ResponseCode && (
                                        <p
                                            style={{
                                              // position: "absolute",
                                              textAlign: "center",
                                              fontWeight: 600,
                                              color:
                                                  appliedCode ===
                                                  "Discount is not applicable for this ticket"
                                                      ? "#f03063"
                                                      : "#47c94d",
                                              bottom: -6,
                                              left: 36,
                                              fontSize: 16,
                                              padding: "10px 0px",
                                            }}
                                        >
                                          {/* {discountError} */}
                                          {`${appliedCode}`}
                                        </p>
                                    )}
                                <div className="ticket_add_promo_box">
                          <span
                              onClick={() => ApplyPromoCode(promoCode)}
                              className="ticket_add_promo_btn"
                          >
                            {promoCode === ""
                                ? "Add promo code "
                                : "Apply promo code"}{" "}
                            <RiCoupon3Fill style={{ marginLeft: 10 }} />
                          </span>
                                </div>
                              </div>
                          ) : null}
                        </div>
                    ) : null}
                  </div>
              ) : (
                  <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                  >
                    <AiOutlineShoppingCart color="lightgray" size={40} />
                  </div>
              )}
            </div>
          </main>
        </div>
      </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    results: state.loginResponse.results,
    isLoggedIn: state.loginResponse.isLoggedIn,
    currentEventDetails: state.eventReducer.currentEventDetails,
    cartItems: state.eventReducer.cartData,
    discountData: state.eventReducer.discountData,
    shoppingCartDetails: state.eventReducer.createshoppingCartDetails,
    DiscountUtilization: state.eventReducer.DiscountUtilization,
  };
};
const matchDispatchToProps = (dispatch) => {
  return {
    // AddToFavorite: () => dispatch(AddToFavorite),
    // SetCardData: () => dispatch(SetCardData),
    // LoginPopup: () => dispatch(LoginPopup),
    // currentEventTicketDetails,
    ticktesCartItem,
    GetDiscountDetails,
    GetDiscountData,
    // GetEventMasterById,
    dispatch,
  };
};

export default connect(mapStateToProps, matchDispatchToProps)(OrderSummary);
