import React, { useEffect } from "react";
import "./Agenda.css";
import { MdLocationOn } from "react-icons/md";
import { BsClockFill } from "react-icons/bs";
import moment from "moment";
import "moment-timezone";
import { HiClock } from "react-icons/hi";
import { GiMicrophone } from "react-icons/gi";
import linkedin from "images/linkedin.png";
import internet from "images/internet.png";
import { default as momentTz } from "moment-timezone";
const Agenda = ({ currentEventDetails }) => {

  let newData = [];

  const mydata = currentEventDetails?.AgendaSessionData.map((agenda) => {
    let obj = {
      id: "",
      SessionDate: "",
      data: [],
    };

    const timeUTCtoLocalTime = (date, time) => {
     
      const formattedDate = moment(date).format("MM/DD/YYYY");
      const formattedTime = moment(time, "HH:mm:ss").format("hh:mm:ss a");
      const dateAndTimeString = formattedDate + " " + formattedTime;
      var local = momentTz
        .tz(
          moment.utc(dateAndTimeString),
          "MM/DD/YYYY hh:mm:ss a",
          currentEventDetails?.TimeZone
        )
        .format("MM/DD/YYYY hh:mm:ss a");
      const Time = moment(local, "MM/DD/YYYY hh:mm:ss a");
      return Time
    };

    let convertedAgendaData = { ...agenda };
    const { SessionDate, SessionEndTime, SessionStartTime } =
      convertedAgendaData;
      convertedAgendaData["SessionDate"] = timeUTCtoLocalTime(
        SessionDate,
        SessionStartTime
      );
    convertedAgendaData["SessionStartTime"] = timeUTCtoLocalTime(
      SessionDate,
      SessionStartTime
    );
    convertedAgendaData["SessionEndTime"] = timeUTCtoLocalTime(
      SessionDate,
      SessionEndTime
    );

    if (
      !newData.some((d) =>
        moment(moment(d.SessionDate).format("MM/DD/YYYY"), "MM/DD/YYYY").isSame(
          moment(moment(convertedAgendaData.SessionDate).format("MM/DD/YYYY"), "MM/DD/YYYY")
        )
      )
    ) {
     
      obj.SessionDate = convertedAgendaData.SessionDate;
      obj.id = convertedAgendaData.AgendaSessionId;
      obj.data.push(convertedAgendaData);
      newData.push(obj);
    } else {
     
      let data = newData.find((d) =>
      moment(moment(d.SessionDate).format("MM/DD/YYYY"), "MM/DD/YYYY").isSame(
        moment(moment(convertedAgendaData.SessionDate).format("MM/DD/YYYY"), "MM/DD/YYYY")
      )
      );
      data.data.push(convertedAgendaData);
    }

      // Sort newData array by SessionDate in ascending order
      newData.sort((a, b) => {
          const dateA = moment(a.SessionDate, "MM/DD/YYYY");
          const dateB = moment(b.SessionDate, "MM/DD/YYYY");
          return dateA - dateB;
      });
  });

  let sortedAgendaData = newData?.map((agenda)=> {
    const obj = Object.assign({}, agenda);
    obj["data"] = agenda?.data.sort(
      (a, b) =>
        new moment(a.SessionStartTime).format("X") -
        new moment(b.SessionStartTime).format("X")
    );
    return obj;
  })


  const RenderAgenda = () => {
    return (
      <>
        {sortedAgendaData?.map((data) => (
          <>
            <div className="date_button_container">
              <div className="date_button">
                {moment(data.SessionDate).format("MMMM DD")}
              </div>
            </div>
            <div class="steps">
              {data?.data?.map((event, index) => (
                <div class="steps-container">
                  <div class="date">
                    {moment(event.SessionStartTime).format("hh:mm A")}
                  </div>
                  <div class="content">
                    <h3 style={{ fontWeight: 600 }}>{event.SessionTitle} </h3>
                    <h3 style={{ marginTop: "20px" }}>
                      <div
                        style={{ fontWeight: 500, lineHeight: "30px" }}
                        dangerouslySetInnerHTML={{ __html: event.Description }}
                      />
                    </h3>
                    <div style={{ marginTop: 20 }} className="timeline_time">
                      <HiClock color="#a5a5a5" size={20} />
                      <p style={{ marginLeft: 10, color: "#a5a5a5" }}>
                        {moment(event.SessionStartTime).format(
                          "hh:mm A"
                        )}{" "}
                        -{' '}
                        {moment(event.SessionEndTime).format(
                          "hh:mm A"
                        )}
                      </p>
                    </div>
                    {event?.Location && (
                      <div className="timeline_time">
                        <MdLocationOn color="#a5a5a5" size={20} />
                        <p style={{ marginLeft: 10, color: "#a5a5a5" }}>
                          {event.Location}
                        </p>
                      </div>
                    )}

                    {event?.ListAgendaSpeaker?.length > 0 && (
                      <div className="timeline_time">
                        <GiMicrophone color="#a5a5a5" size={20} />
                        <p style={{ marginLeft: 10, color: "#a5a5a5" }}>
                          {event.ListAgendaSpeaker?.length}{" "}
                          {event.ListAgendaSpeaker?.length > 1
                            ? "Speakers"
                            : "Speaker"}
                        </p>
                      </div>
                    )}
                    {currentEventDetails &&
                      event?.ListAgendaSpeaker?.map((item) => (
                        <div className="timeline_speaker">
                          <div style={{ marginTop: 10, marginLeft: 20 }}>
                            <p
                              style={{
                                marginLeft: 10,
                                color: "#000",
                                fontWeight: 600,
                              }}
                            >
                              {item.Topic}
                            </p>
                            <p style={{ marginLeft: 10, color: "#a5a5a5" }}>
                              {item.SpeakerName}{" "}
                              {item.LinkedIn !== "" ? (
                                <a
                                style={{ marginLeft: 5}}
                                  target="_blank"
                                  href={
                                    item?.LinkedIn.substring(0, 8) !==
                                    "https://"
                                      ? `https://${item.LinkedIn}`
                                      : `${item?.LinkedIn}`
                                  }
                                >
                                  <img style={{ width: 21 , marginTop:-4}} src={linkedin} />
                                </a>
                              ) : null}{" "}
                              {item.Website !== "" ? (
                                <a
                                style={{marginLeft: 5,}}
                                  href={
                                    item?.Website.substring(0, 8) === "https://" ||
                                    item?.Website.substring(0, 7) === "http://" ?
                                    `${item?.Website}`:
                                    `https://${item.Website}` 
                                  }
                                >
                                  <img style={{ width: 26, marginTop:-4 }} src={internet} />{" "}
                                </a>
                              ) : null}
                            </p>
                          </div>
                        </div>
                      ))}
                  </div>
                  <i class="step-line"></i>
                  <div class="circle_left"></div>
                </div>
              ))}
            </div>
          </>
        ))}
      </>
    );
  };

  return (
    <div className="timeline_container_2">
      <div className="agenda__Heading">
        <h2>
          {" "}
          <span className="event_footer_app_agenda">Agenda</span> For{" "}
          {currentEventDetails?.Title.replace(
            /(^\w{1})|(\s+\w{1})/g,
            (letter) => letter.toUpperCase()
          )}{" "}
        </h2>
      </div>
      <div style={{ padding: 10 }}>
        {RenderAgenda()}
        <div className="date_button_container">
          <div
            style={{
              backgroundColor: "#8914a2",
              width: 20,
              height: 20,
              borderRadius: 50,
            }}
          ></div>
        </div>
      </div>

      {/* {currentEventDetails?.EndDateTime && (
        <div className="date_button_container">
          <div className="date_button">
            {" "}
            {moment(currentEventDetails?.EndDateTime).format("MMMM DD")}
          </div>
        </div>
      )} */}
      {/* {AgendaDerails.length > 0 && AgendaDerails} */}
    </div>
  );
};

export default Agenda;
