import React from "react";
import "./Vendor.css";
import { AiFillInfoCircle } from "react-icons/ai";
import LandingPageLineChart from "./LandingPageLineChart";
import ToolTip from "./tools/ToolTip";
import {
  pdf,
  Page,
  Image,
  StyleSheet,
  Text,
  Document,
  View,
  Link,
  PDFViewer,
} from "@react-pdf/renderer";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PDFDocument from "../Document";
import { useCurrentPng } from "recharts-to-png";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import GraphData from "../Document/GraphData";
import { URLs, Text as Texts } from "Constants";

import VendorView from "./vendor/VendorView";
import moment from "moment";
function Vendors({ activityData, eventTitle }) {
  const downloadPdf = async (
    getPng,
    Description,
    Email,
    Logo,
    Name,
    Phone,
    Website
  ) => {
    const png = await getPng();
    const pdfBlob = await pdf(
      <PDFDocument
        {...{ Description, Email, Logo, Name, Phone, Website, png }}
      />
    ).toBlob();
    const pdfDataUrl = URL.createObjectURL(pdfBlob);
    const link = document.createElement("a");
    link.href = pdfDataUrl;
    link.download = "my-pdf-file.pdf";
    document.body.appendChild(link);
    link.click();
  };

  let VendorData = [];
  if (Array.isArray(activityData?.VendorClickthrough?.Vendor)) {
    VendorData = activityData?.VendorClickthrough?.Vendor;
  } else {
    VendorData.push(activityData?.VendorClickthrough?.Vendor);
  }
  return (
    <div>
      <div className="appEngageBorder" />
      <div className="appEngagementDesc">
        <h3 style={{ fontSize: 20, fontWeight: 500 }}>
          {Texts.Vendors} -{" "}
          <span style={{ fontSize: 16, fontWeight: 500 }}>
            {/* {Texts.VendorsText} */}
            View results and download reports for your vendors
          </span>
        </h3>
      </div>
      <div className="activityHeader monitizationHeader">
        {ToolTip(
          "Vendor",
          "Insights on impressions, clicks, phone calls and email inquiries for each vendor"
        )}
      </div>
      {activityData?.VendorClickthrough?.Vendor ? (
        <>
          {VendorData?.map(
            ({
              Description,
              Email,
              Logo,
              Name,
              Phone,
              Website,
              Interaction,
              Address,
            }) => {
              const checkisArray = (data, name) => {
                let PageStatistics = [];
                if (Array.isArray(data)) {
                  PageStatistics = data;
                } else {
                  PageStatistics.push(data);
                }
                if (name === "impressions") {
                  return !PageStatistics[0]
                    ? []
                    : PageStatistics?.map((items) => ({
                        value: Number(items?.Count?.replace(/,/g, "")),
                        title: name,
                        Date: moment(items.Date).format("MM/DD/YYYY"),
                      }));
                } else {
                  return !PageStatistics[0]
                    ? []
                    : PageStatistics?.filter(
                        (item) =>
                          item?.hasOwnProperty("@Interaction") &&
                          item["@Interaction"] === name
                      )?.map((items) => ({
                        value: Number(
                          items?.hasOwnProperty("@Count")
                            ? items["@Count"]?.replace(/,/g, "")
                            : 0
                        ),
                        title: name,
                        Date: items?.hasOwnProperty("@CreatedDate")
                          ? moment(items["@CreatedDate"]).format("MM/DD/YYYY")
                          : "",
                      }));
                }
              };

              return (
                <VendorView
                  {...{
                    checkisArray,
                    activityData,
                    Description,
                    Email,
                    Logo,
                    Name,
                    Phone,
                    Address,
                    Website,
                    Interaction,
                    eventTitle,
                  }}
                />
              );
            }
          )}
        </>
      ) : (
        <div
          style={{
            width: "400px",
            height: 150,
            display: "flex",
            alignItems: "center",
          }}
        >
          <h4 style={{ color: "#808080", marginLeft: 30, textAlign: "center" }}>
            {Texts.NoData}
          </h4>
        </div>
      )}
      <div></div>
    </div>
  );
}

export default Vendors;
