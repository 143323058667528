import React from "react";
import moment from "moment";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Avatar, Backdrop, Button, Collapse, Modal } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import axiosI from "Service/service";
import { FaPoll } from "react-icons/fa";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import { getDetailGIF, error, hidePopUp, setSelectedMultimediaData, clearDetailedGif } from "Actions/messagesAction";
import { GrClose } from "react-icons/gr";
import "./EventChat.css";
import converter from "Helper/convertFile";
import "rc-time-picker/assets/index.css";
import ScheduleDataTimeComp from "./ScheduleDateTime";
import { TransitionGroup } from "react-transition-group";
import { ws } from "../../../../../App";
import storeFolder from "../../../../../store";
import { MdKeyboardArrowDown } from "react-icons/md";
import { IoCloseOutline, IoSend } from "react-icons/io5";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { HiOutlinePlusSm } from "react-icons/hi";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit"
import { IoIosTimer } from "react-icons/io";
import { UserIconsPic } from "Components/UBMeEvents/Components_UBMeEvents/Navbar/Alphabets/UserIconsPic";
import { FaCheck } from "react-icons/fa6";
import { RxCross2 } from "react-icons/rx";
import ToolTip from "../../Analytics/Component/tools/ToolTip";

const styles = {
  avatar: {
    margin: 10,
    height: 50,
    width: 50,
    fontSize: 40,
  },
};

let initialOptions = [{ text: "" }, { text: "" }];

class PollsFooter extends React.Component {
  constructor() {
    let storeData = storeFolder.getState();
    const timeZone = localStorage.getItem("CurrentTimeZone");
    super();
    this.state = {
      showStickers: false,
      makeGif: true,
      textMessage: "",
      gif: [],
      stickers: [],
      showPopUp: false,
      isOpen: false,
      isScheduling: false,
      isScheduledChatOpen: false,
      isPollScheduling: false,
      pollQuestion: "",
      inputFields: JSON.parse(JSON.stringify(initialOptions)),
      scheduleTime: {
        date: new Date(),
        time: moment().utc().tz(JSON.parse(timeZone))?.add(1, 'm'),
      },
      scheduleTimeEdit: {
        date: new Date(),
        time: moment().add(1, 'm'),
      },
      scheduleEndTimeEdit: {
        date: new Date(),
        time: moment().add(1, 'm'),
      },
      scheduleEndTime: {
        date: localStorage.getItem("EventEndDate") ? new Date(moment(localStorage.getItem("EventEndDate")).format("YYYY/MM/DD")) : new Date(moment(storeData?.eventUserEvents?.eventsAbout?.EndDateTime).format("YYYY/MM/DD")),
        time: moment().utc().tz(JSON.parse(timeZone))?.add(1, 'm'),
      },
      imgUrl: "",
      isScheduleTimeEdit: false,
      validationOnPoll: "",
      validationOnMessage: "",
      isValidEndDateTime: false,
      isValidEventEndDateTime: false,
      isDateChanged: moment().tz(JSON.parse(timeZone))?.format("YYYYMMDD"),
      isTimeChanged: moment().tz(JSON.parse(timeZone))?.format("HH:mm"),
      isEndDateChanged: moment().tz(JSON.parse(timeZone))?.format("YYYYMMDD"),
      isEndTimeChanged: moment().tz(JSON.parse(timeZone))?.format("HH:mm"),
      multimediaData: null,
      scheduleChats: storeData?.messageReducer?.scheduledMessages || [],
      initialPageIndex: 1,
      isScheduleMessageEdit: false,
      editChatDetails: "",
      isSchedulePollEdit: false,
      editMessage: "",
      editPollAnswers: [],
      gifEdit: false,
      scheduleMessagesCount: 0,
      isDeleteModalOpen:false,
      isPollDateValidate:false,
    };
    this.divRef = React.createRef()
  }


  componentDidMount() {
    let { stickers } = this.state;
    for (let i = 1; i <= 30; i++) {
      stickers.push(
        <div className="sticker" onClick={() => this.sendSticker(i)}>
          <img
            src={require(`images/Stickers/${i}.png`).default}
            alt={i}
            height={100}
            width={100}
          />
        </div>
      );
    }
    this.props.setSelectedMultimediaData(null)
    this.setState({ stickers });
    if (this.state.multimediaData && this.divRef.current) {
      // Focus the div when multimediaData is present and the component updates
      this.divRef.current.focus();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log({ 'state': this.state.multimediaData });
    // Check if multimediaData is available and the divRef is not null
    const storeData = storeFolder.getState();
    const multimediaData = storeData?.messageReducer?.selectedMultimediaData;
    if (this.state.multimediaData && this.divRef.current) {
      // Focus the div when multimediaData is present and the component updates
      this.divRef.current.focus();

      if (this.state.isScheduleMessageEdit && multimediaData) {
        // console.log("multimediaData", multimediaData , this.state.editMessage !== multimediaData.text ,
        // this.state.imgUrl !== multimediaData.imgUrl);
        if (
          this.state.editMessage !== multimediaData.text ||
          this.state.imgUrl !== multimediaData.imgUrl
        ) {
          this.setState({
            editMessage: multimediaData.text,
            imgUrl: multimediaData.imgUrl,
          });
        }
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    const { messageData } = nextProps;
    let { showPopUp } = this.state;
    const storeData = storeFolder.getState();
    const multimediaData = storeData?.messageReducer?.selectedMultimediaData;
    if (messageData.showPopUp) {
      let chatElement = document.getElementById("chat-component-wrapper");
      if (
        chatElement?.clientHeight + chatElement?.scrollTop + 65 <
        chatElement?.scrollHeight
      ) {
        showPopUp = true;
      } else {
        this.props.hidePopUp();
      }
    } else {
      showPopUp = false;
    }

    if (this.state.isScheduleMessageEdit && multimediaData) {

      this.setState({
        editMessage: multimediaData.text,
        imgUrl: multimediaData.imgUrl,
      });
    }
    this.setState({ showPopUp });
    this.setState({ scheduleChats: storeData?.messageReducer?.scheduleMessages || [] })
  }

  sendSticker = (id) => {
    let data = {
      text: "[Sticker message]",
      stickerId: `${id}.png`,
    };

    this.setState({
      textMessage: "",
      showStickers: !this.state.showStickers,
    })
    this.state.isScheduleMessageEdit && this.setState({editMessage:data.text,imgUrl:data.stickerId})

    this.props.setSelectedMultimediaData(data)
  };

  // onTextChange = (e) => {
  //   if(this.state.editMessage){
  //     this.setState(
  //       {
  //         [e.target.id]: e.target.value,
  //         scroll: false,
  //       }
  //     );
  //   }
  //   this.setState(
  //     {
  //       [e.target.id]: e.target.value,
  //       scroll: false,
  //     }
  //   );
  // };

  onTextChange = (e) => {
    const { id, value } = e.target;
    if (this.state.isScheduleMessageEdit) {
      this.setState({
        editMessage: value,
        scroll: false,
      });
    } else {
      this.setState({
        [id]: value,
        scroll: false,
      });
    }
  };

  getMessageType = (type) => {
    switch (type) {
      case "[Sticker message]":
        return "5"
      case "[Picture message]":
        return "2"
      case "[GIF message]":
        return "6"
      default: return ""
    }
  }

  // async getScheduleCount(){
  //   const dialogId = localStorage.getItem("currentDialogId");
  //   let storeData = storeFolder.getState();

  //   await ws.json({
  //     userId: localStorage.getItem("UserId"),
  //     pageIndex: this.state.initialPageIndex,
  //     pageSize: 15,
  //     dialogId: dialogId,
  //     type: "getScheduledPendingChat",
  //   },()=>{
  //     const scheduledChats = storeData?.messageReducer?.scheduledMessages?.length || 0;
  //     console.log("count",scheduledChats)
  //     this.setState({
  //       scheduleMessagesCount:scheduledChats
  //     })
  //   })
  // }

  sendMessage = async () => {
    let { textMessage } = this.state;
    const userId =
      storeFolder.getState().loginResponse.results.ProfileDetail.UserId; // from redux store
    const dialogId = localStorage.getItem("currentDialogId");
    let date = new Date();
    let currentDate = moment().utc().format("YYYY-MM-DD HH:mm:ss");
    const storeData = storeFolder.getState();
    const multimediaData = storeData?.messageReducer?.selectedMultimediaData;

    if (textMessage.trim().length > 0 && dialogId) {
      await ws.json({
        msgid: date.getTime().toString().slice(4),
        chat_message: {
          text: textMessage,
        },
        message_type: "1",
        chat_time: currentDate.toString(),
        user_id: userId.toString(),
        type: "businesschat",
        chat_type: "Event",
        token_id: localStorage.getItem("token"),
        googlePlacedId: dialogId.toString(),
      });

      textMessage = "";
      document.getElementById('textMessage').style.height = 'auto';
      this.props.updateWebViewState("scroll", true);
      this.setState({ textMessage });
    } else if (multimediaData && dialogId) {
      await ws.json({
        msgid: date.getTime().toString().slice(4),
        chat_message: multimediaData,
        message_type: this.getMessageType(multimediaData?.text),
        chat_time: currentDate,
        user_id: localStorage.getItem("UserId"),
        type: "businesschat",
        chat_type: "Event",
        token_id: localStorage.getItem("token"),
        googlePlacedId: localStorage.getItem("currentDialogId"),
      });
      if (multimediaData.text === "[GIF message]") {
        this.props.clearDetailedGif();
      }
      textMessage = "";
      this.props.updateWebViewState("scroll", true);
      this.props.setSelectedMultimediaData(null)
    }
  };

  onStickerClick = () => {
    this.setState({
      showStickers: !this.state.showStickers,
    });

    // let panel = document.getElementById('webViewMessage');
    // if (!this.state.showStickers) {
    //     panel.style.height = 'calc(100vh - 360px)';
    // } else {
    //     panel.style.height = 'calc(100vh - 360px)';
    // }
  };

  convertUnicode = (input) => {
    return input.replace(/\\u(\w\w\w\w)/g, (a, b) => {
      let charcode = parseInt(b, 16);
      return String.fromCharCode(charcode);
    });
  };

  handleImageChange = (e) => {
    let url = "";
    let length;
    let date = new Date();
    if (e.target.files.length > 5) {
      alert("Only 5 images can be selected per time.");
      length = 5;
    } else length = e.target.files.length;
    for (let i = 0; i < length; i++) {
      if (e.target.files[i].type.split("/")[0] === "image") {
        // eslint-disable-next-line no-loop-func
        converter.toBase64(e.target.files[i]).then((dt) => {
          dt = dt.substring(dt.indexOf(",") + 1);
          url = dt;

          let data = {
            ProfileImage: url,
            messageId: date.getTime().toString().slice(4),
          };
          axiosI
            .post("/UploadChatImage", data, {
              headers: {
                "Content-Type": "application/json",
              },
            })
            .then((response) => {
              if (
                response.data.results.Message ===
                "Chat image saved successfully"
              ) {
                let socketData = {
                  text: "[Picture message]",
                  imgUrl: response.data.results.ImageUrl,
                };
                this.setState({
                  textMessage: ""
                })
                this.state.isScheduleMessageEdit && this.setState({editMessage:socketData.text,imgUrl:socketData.imgUrl})
                this.props.setSelectedMultimediaData(socketData)
              }
            })
            .catch((err) => {
              console.log("Error uploading image to the server.", err);
              this.props.error();
            });
        });
      } else {
        this.props.toggleAlert(
          "Please select image of png, jpg and jpeg type only."
        );
      }
    }
  };

  do_resize = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      this.sendMessage(e);
      e.target.style.height = 'auto';
    } else if (e.key === 'Enter' && e.shiftKey) {
      e.preventDefault();
      const caret = e.target.selectionStart;
      const text = e.target.value;

      e.target.value =
        text.substring(0, caret) + "\n" + text.substring(caret);

      e.target.style.height = 'auto';
      e.target.style.height = e.target.scrollHeight + 'px';

      e.target.selectionEnd = caret + 1;
    }
    else {
      let el = e.target;
      setTimeout(() => {
        // for box-sizing other than "content-box" use:
        // el.style.cssText = '-moz-box-sizing:content-box';
        if (el.scrollHeight < 120) {
          el.style.cssText = "padding:0 max-height:200px overflow-y:scroll";
          el.style.cssText = "height:" + el.scrollHeight + "px";
        }
      }, 0);
    }
  };

  hidePopUp = () => {
    this.setState({
      showPopUp: false,
    });
    this.props.hidePopUp();
    let chatElement = document.getElementById("chatWrapper");
    chatElement.scrollTop = chatElement.scrollHeight - chatElement.clientHeight;
  };
  handleChangeInput = (index, event) => {
    const { value } = event.target;
    const { inputFields, editPollAnswers } = this.state;
    if (this.state.isSchedulePollEdit) {
      const updatedEditPollAnswers = [...editPollAnswers];
      updatedEditPollAnswers[index].name = value;
      this.setState({ editPollAnswers: updatedEditPollAnswers });
      return;
    }
    const updatedInputFields = [...inputFields];
    updatedInputFields[index].text = value;
    this.setState({ inputFields: updatedInputFields });
  };

  handleAddFields = () => {
    const { inputFields, editPollAnswers } = this.state;
    if (this.state.isSchedulePollEdit) {
      this.setState({ editPollAnswers: [...editPollAnswers, { option: "" }] });
      return;
    }
    this.setState({ inputFields: [...inputFields, { text: "" }] });
  };

  handleRemoveFields = (index) => {
    const { inputFields } = this.state;
    this.setState({ inputFields: inputFields });
  };

  handleRemovePollOptions = (index) => {
    const inputFields = this.state.inputFields;
    const editPollAnswers = this.state.editPollAnswers;
    editPollAnswers.splice(index, 1);
    inputFields.splice(index, 1);
    if (this.state.isSchedulePollEdit) {
      this.setState({ editPollAnswers: [...editPollAnswers] });
      return;
    }
    this.setState({ inputFields: [...inputFields] });
  };

  getDate = (date) => {
    if (date !== null || "") {
      const dateObj = {
        month: parseInt(moment(date).format("MM")),
        day: parseInt(moment(date).format("DD")),
        year: parseInt(moment(date).format("YYYY")),
      };
      return dateObj;
    } else {
      return null;
    }
  };

  ChangeEndDate = (date) => {
    const completeDate = `${date.month}-${date.day}-${date.year}`;

    const timeChanged = this.state.isEndTimeChanged;
    const currentTime = moment().format("HH:mm");

    // this.setState({isPollDateValidate:this.isScheduleTimeGreaterThanEndTime()})
    if (timeChanged > currentTime) {
      this.setState({ isValidEndDateTime: true });
    }

    const changedDate = moment(completeDate, "MM-DD-YYYY").format("YYYYMMDD")
    const currentDate = moment().format("YYYYMMDD");

    if (changedDate !== currentDate) {
      this.setState({ isValidEndDateTime: false });
    }

    if (this.state.isSchedulePollEdit) {
      this.setState((prevState) => ({
        scheduleEndTimeEdit: {
          ...prevState.scheduleEndTimeEdit,
          date: moment(completeDate, "MM/DD/YYYY").format("MM/DD/YYYY"),
        },
      }));
      return;
    }

    this.setState((prevState) => ({
      scheduleEndTime: {
        ...prevState.scheduleEndTime,
        date: moment(completeDate, "MM/DD/YYYY").format("MM/DD/YYYY"),
      },
    }));

    this.setState({ isEndDateChanged: changedDate });
  };

  ChangeStartDate = (date) => {
    const completeDate = `${date.month}-${date.day}-${date.year}`;

    const timeChanged = this.state.isTimeChanged;
    const currentTime = moment().format("HH:mm");
    // this.setState({isPollDateValidate:this.isScheduleTimeGreaterThanEndTime()})

    if (timeChanged < currentTime) {
      this.setState({ isValidEndDateTime: true });
    }

    const changedDate = moment(completeDate, "MM-DD-YYYY").format("YYYYMMDD")
    const currentDate = moment().format("YYYYMMDD");

    if (changedDate !== currentDate) {
      this.setState({ isValidEndDateTime: false });
    }

    if (this.state.isSchedulePollEdit || this.state.isScheduleTimeEdit) {
      this.setState((prevState) => ({
        scheduleTimeEdit: {
          ...prevState.scheduleTimeEdit,
          date: moment(completeDate, "MM/DD/YYYY").format("MM/DD/YYYY"),
        },
      }));
      return;
    }

    this.setState((prevState) => ({
      scheduleTime: {
        ...prevState.scheduleTime,
        date: moment(completeDate, "MM/DD/YYYY").format("MM/DD/YYYY"),
      },
    }));

    this.setState({ isDateChanged: changedDate });
  };

  ChangeStartTime = (date) => {

    const dateChanged = this.state.isDateChanged;
    const currentDate = moment().format("YYYYMMDD");

    const currentTime = moment().format("YYYY-MM-DD HH:mm:ss");
    let status = date.isBefore(currentTime);
    // this.setState({isPollDateValidate:this.isScheduleTimeGreaterThanEndTime()})

    this.setState({ isTimeChanged: date.format("HH:mm") });

    if (dateChanged === currentDate && status) {
      this.setState({ isValidEndDateTime: status });
    } else {
      this.setState({ isValidEndDateTime: false });
    }

    if (this.state.isSchedulePollEdit || this.state.isScheduleTimeEdit) {
      if (date === null || date === "invalid date") {
        this.setState((prevState) => ({
          scheduleTimeEdit: {
            ...prevState.scheduleTimeEdit,
            time: moment(),
          },
        }));
      } else {
        this.setState((prevState) => ({
          scheduleTimeEdit: {
            ...prevState.scheduleTimeEdit,
            time: moment(date),
          },
        }));
      }
      return;
    }

    if (date === null || date === "invalid date") {
      this.setState((prevState) => ({
        scheduleTime: {
          ...prevState.scheduleTime,
          time: moment(),
        },
      }));
    } else {
      this.setState((prevState) => ({
        scheduleTime: {
          ...prevState.scheduleTime,
          time: moment(date),
        },
      }));
    }
  };

  ChangeEndTime = (date) => {

    const dateChanged = this.state.isEndDateChanged;
    const currentDate = moment().format("YYYYMMDD");

    const currentTime = moment().format("YYYY-MM-DD HH:mm:ss");
    let status = date.isBefore(currentTime);
    // this.setState({isPollDateValidate:this.isScheduleTimeGreaterThanEndTime()})

    this.setState({ isEndTimeChanged: date.format("HH:mm") });

    // if (dateChanged === currentDate && status) {
    //   this.setState({ isValidEndDateTime: status });
    // } else {
    //   this.setState({ isValidEndDateTime: false });
    // }

    if (this.state.isSchedulePollEdit) {
      if (date === null || date === "invalid date") {
        this.setState((prevState) => ({
          scheduleEndTimeEdit: {
            ...prevState.scheduleEndTimeEdit,
            time: moment(),
          },
        }));
      } else {
        this.setState((prevState) => ({
          scheduleEndTimeEdit: {
            ...prevState.scheduleEndTimeEdit,
            time: moment(date),
          },
        }));
      }
      return;
    }

    if (date === null || date === "invalid date") {
      this.setState((prevState) => ({
        scheduleEndTime: {
          ...prevState.scheduleEndTime,
          time: moment(),
        },
      }));
    } else {
      this.setState((prevState) => ({
        scheduleEndTime: {
          ...prevState.scheduleEndTime,
          time: moment(date),
        },
      }));
    }
  };

  scheduleDateAndTimeCheck = () => {
    if(this.state.isSchedulePollEdit){
      const { date, time } = this.state.scheduleTimeEdit;
      const combinedDateTime = `${moment(date).format("MM/DD/YYYY")} ${moment(time).format("HH:mm")}`;
      const momentDateTime = moment(combinedDateTime, 'MM/DD/YYYY HH:mm');

      const combinedEndDateTime = `${moment(this.state.scheduleEndTimeEdit.date).format("MM/DD/YYYY")} ${moment(this.state.scheduleEndTimeEdit.time).format("HH:mm")}`;
      const momentEndDateTime = moment(combinedEndDateTime, 'MM/DD/YYYY HH:mm');
      return momentDateTime.isAfter(momentEndDateTime);
    }
    const { date, time } = this.state.scheduleTime;
    const combinedDateTime = `${moment(date).format("MM/DD/YYYY")} ${moment(time).format("HH:mm")}`;
    const momentDateTime = moment(combinedDateTime, 'MM/DD/YYYY HH:mm');

    const combinedEndDateTime = `${moment(this.state.scheduleEndTime.date).format("MM/DD/YYYY")} ${moment(this.state.scheduleEndTime.time).format("HH:mm")}`;
    const momentEndDateTime = moment(combinedEndDateTime, 'MM/DD/YYYY HH:mm');
    // console.log("START END",momentDateTime,momentEndDateTime);
    return momentDateTime.isAfter(momentEndDateTime) ;
  };
  isScheduleTimeGreaterThanEndTime = () => {
    if (this.state.isSchedulePollEdit) {
        const { date, time } = this.state.scheduleTimeEdit;
        const combinedDateTime = `${moment(date).format("MM/DD/YYYY")} ${moment(time).format("HH:mm")}`;
        const momentDateTime = moment(combinedDateTime, 'MM/DD/YYYY HH:mm');
  
        const combinedEndDateTime = `${moment(this.state.scheduleEndTimeEdit.date).format("MM/DD/YYYY")} ${moment(this.state.scheduleEndTimeEdit.time).format("HH:mm")}`;
        const momentEndDateTime = moment(combinedEndDateTime, 'MM/DD/YYYY HH:mm');
        
        if (momentDateTime.isSame(momentEndDateTime, 'day')) {
            // Dates are the same, compare times
            return moment(time, 'HH:mm').isAfter(moment(this.state.scheduleEndTimeEdit.time, 'HH:mm')) ? 
                "End time cannot be smaller than Start time." : null;
        } else {
            // Dates are different, compare date and time together
            return momentDateTime.isAfter(momentEndDateTime) ?
                "End date cannot be smaller than Start date." : null;
        }
    } else {
        const { date, time } = this.state.scheduleTime;
        const combinedDateTime = `${moment(date).format("MM/DD/YYYY")} ${moment(time).format("HH:mm")}`;
        const momentDateTime = moment(combinedDateTime, 'MM/DD/YYYY HH:mm');
 
        const combinedEndDateTime = `${moment(this.state.scheduleEndTime.date).format("MM/DD/YYYY")} ${moment(this.state.scheduleEndTime.time).format("HH:mm")}`;
        const momentEndDateTime = moment(combinedEndDateTime, 'MM/DD/YYYY HH:mm');
        
        if (momentDateTime.isSame(momentEndDateTime, 'day')) {
            // Dates are the same, compare times
            return moment(time, 'HH:mm').isAfter(moment(this.state.scheduleEndTime.time, 'HH:mm')) ?
                "End time cannot be smaller than Start time." : null;
        } else {
            // Dates are different, compare date and time together
            return momentDateTime.isAfter(momentEndDateTime) ?
                "End date cannot be smaller than Start date." : null;
        }
    }
};

  compareDates = (originalDate, convertedDate) => {
    const momentOriginalDate = moment(originalDate, "MMM DD");
    const momentConvertedDate = moment(convertedDate, "MMM DD");
    if (momentOriginalDate.isSame(momentConvertedDate)) {
      const finalDate = moment(convertedDate).subtract(1,"day");
      const converted = moment(momentOriginalDate).format("MMM DD");
      return converted;
    } else if (momentOriginalDate.isBefore(momentConvertedDate) || momentOriginalDate.isAfter(momentConvertedDate)) {
      const finalDate = moment(convertedDate).subtract(1,"day");
      const converted = moment(finalDate).format("MMM DD");
      return converted;
    }
  };

  compareDatesModal = (originalDate, convertedDate) => {
    const momentOriginalDate = moment(originalDate, "YYYY/MM/DD");
    const momentConvertedDate = moment(convertedDate, "YYYY/MM/DD");

    if (momentOriginalDate.isSame(momentConvertedDate)) {
      const finalDate = moment(convertedDate).subtract(1,"day");
      const converted = moment(finalDate).format("YYYY/MM/DD")
      return convertedDate;
    } else if (momentOriginalDate.isBefore(momentConvertedDate) || momentOriginalDate.isAfter(momentConvertedDate)) {
      const finalDate = moment(convertedDate).subtract(1,"day");
      const converted = moment(finalDate).format("YYYY/MM/DD")
      return converted;
    }
  };

  scheduleModal = () => {
    return (
      <Modal
        open={this.state.isScheduling && !this.state.isOpen}
        onClose={() => {this.setState({
          isScheduling: false, isScheduleTimeEdit: false, scheduleTimeEdit: {
            date: new Date(),
            time: moment().add(1, 'm'),
          },
        })
        this.handleCancelEditScheduledChatCancelButton()
      }}
      >
        <div className="polls_modal_wrapper">
          <div>
            <div style={{ position: "absolute", left: "10px", marginTop: "10px", marginLeft: "10px", fontSize: "18px", fontWeight: "600" }}>
              {this.state.isScheduleTimeEdit ? "Edit Scheduled Time" : "Schedule Message"}
            </div>
            <div style={{ position: "absolute", right: "20px" }}>
              <IconButton onClick={() => {this.setState({
                isScheduling: false, isScheduleTimeEdit: false, scheduleTimeEdit: {
                  date: new Date(),
                  time: moment().add(1, 'm'),
                },
              })
              this.handleCancelEditScheduledChatCancelButton()
            }}>
                <GrClose />
              </IconButton>
            </div>
          </div>
          <div style={{ display: "flex", width: "100%", height: "10px" }}></div>
          <div className="poll_wrapper">
            {this.state.validationOnMessage && (
              <p className="validationOnPolls">
                {this.state.validationOnMessage}
              </p>
            )}
            <ScheduleDataTimeComp prop={this} type={"start"} isSchedulePollEdit={this.state.isScheduleTimeEdit} chatDetails={this.state.scheduleTimeEdit} initialDate={this.state.scheduleTime} onChangeDate={this.ChangeStartDate} onChangeTime={this.ChangeStartTime} />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
              }}
              className="btn-gap"
            >
              <div
                onClick={() => {
                  this.setState({ isScheduling: true });
                  this.state.isScheduleTimeEdit ? this.sendEditMessage() : this.handleScheduledMessage();
                }}
                disabled={this.state.isValidEndDateTime}
                style={{
                  color: "white",
                  fontSize: "1.5rem",
                  padding: "8px 20px",
                  backgroundColor: "#a435a6",
                  borderRadius: "4px",
                  pointerEvents: this.state.isValidEndDateTime ? 'none' : 'auto',
                  textAlign:"center",
                  cursor:"pointer"
                }}
              >
                {this.state.isScheduleTimeEdit ? "Save":"Schedule"}
              </div>
              <div
                onClick={() => {
                  this.setState({
                  isScheduling: false, isScheduleTimeEdit: false, scheduleTimeEdit: {
                    date: new Date(),
                    time: moment().add(1, 'm'),
                  },
                })
                this.handleCancelEditScheduledChatCancelButton();
              }
              }
                style={{
                  color: "black",
                  fontSize: "1.5rem",
                  padding: "8px 20px",
                  backgroundColor: "white",
                  border: "1px solid #00000080",
                  borderRadius: "4px",
                  cursor:"pointer"
                }}
                title="Cancel"
              >
                Cancel
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  deleteModal = () => {
    return (
      <Modal
        open={this.state.isDeleteModalOpen}
        onClose={() => this.setState({
          isDeleteModalOpen:false
        })}
      >
        <div className="polls_modal_wrapper">
          <div>
            <div style={{ position: "absolute", left: "10px", marginTop: "10px", marginLeft: "10px", fontSize: "18px", fontWeight: "600" }}>
              Confirm deletion
            </div>
            <div style={{ position: "absolute", right: "20px" }}>
              <IconButton onClick={() => this.setState({
                isDeleteModalOpen:false
              })}>
                <GrClose />
              </IconButton>
            </div>
          </div>
          <div style={{ display: "flex", width: "100%", height: "40px" }}></div>
          <div className="poll_wrapper">

            <div style={{fontSize:"17px",display:"flex",justifyContent:"center"}}>
              Delete this item?
            </div>
            <div style={{ display: "flex", width: "100%", height: "40px" }}></div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
              }}
            >
              <Button
                onClick={() => {
                  ws?.json({
                    "chatId": this.state.editChatDetails.chat_id,
                    "contentId": this.state.editChatDetails?.ContentId,
                    "type": "deleteScheduleEventChat",
                    "messageType": this.state.editChatDetails.message_type,
                  });
                  this.setState({ isDeleteModalOpen: false ,editChatDetails:""});
                    const count = localStorage.getItem("ScheduledChatCount");
                    localStorage.setItem("ScheduledChatCount", count-1);
                }}
                style={{
                  color: "white",
                  fontSize: "1.5rem",
                  padding: "8px 20px",
                  backgroundColor: "#a435a6",
                  borderRadius: "4px",
                  textTransform:"initial",
                }}
                title="Schedule Message"
              >
                Delete
              </Button>
              <div
                onClick={() => this.setState({
                  isDeleteModalOpen:false,
                  editChatDetails:""
                })}
                style={{
                  color: "black",
                  fontSize: "1.5rem",
                  padding: "8px 20px",
                  backgroundColor: "white",
                  border: "1px solid #00000080",
                  borderRadius: "4px"
                }}
                title="Cancel"
              >
                Cancel
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  };


  formatMessage(text) {
    const urlRegex = /\b((?:https?:\/\/|www\.)[^\s]+)\b/gi;
    const parts = text?.split(urlRegex);
    return parts.map((part, index) => {

      if (urlRegex.test(part)) {
        let url
        let newPart
        try {
          if (!part.includes('://')) {
            newPart = `https://${part}`;
          } else {
            newPart = part;
          }
          url = new URL(newPart);
          return <a style={{ wordBreak: 'break-word', color: 'black', textDecoration: 'underline' }} target="_blank" rel="noreferrer" href={url}>{part}</a>;
        } catch (error) {
          return part
        }
      } else {
        return (
          <span key={index}>
            {part.includes("\n") ? (
              part.split("\n").map((line, i) => (
                <span key={i}>
                  {line}
                  {i < part.split("\n").length - 1 && <br />}
                </span>
              ))
            ) : (
              part
            )}
          </span>
        )
      }
    });
  }

  // handleScroll = (event) => {
  //   const { target } = event;
  //   const scrollPercentage = (target.scrollTop / (target.scrollHeight - target.clientHeight)) * 100;
  //   if (scrollPercentage >=80) {
  //     this.fetchMoreData();
  //   }
  // };

  async fetchMoreData() {
    const dialogId = localStorage.getItem("currentDialogId");
    await ws.json({
      userId: localStorage.getItem("UserId"),
      pageIndex: this.state.initialPageIndex + 1,
      pageSize: 15,
      dialogId: dialogId,
      type: "getScheduledPendingChat",
    });
    this.setState({ initialPageIndex: this.state.initialPageIndex + 1 })
  }

  async fetchLessData() {
    const dialogId = localStorage.getItem("currentDialogId");
    await ws.json({
      userId: localStorage.getItem("UserId"),
      pageIndex: this.state.initialPageIndex - 1,
      pageSize: 15,
      dialogId: dialogId,
      type: "getScheduledPendingChat",
    });
    this.setState({ initialPageIndex: this.state.initialPageIndex - 1 })
  }

  async handleDeleteScheduledChat(chatDetails) {

    this.setState({
      isScheduledChatOpen:false,
      editChatDetails:chatDetails,
      isDeleteModalOpen:true,
    })
    // await ws.json({
    //   "chatId": chatDetails.chat_id,
    //   "contentId": "",
    //   "type": "deleteScheduleEventChat",
    //   "messageType": chatDetails.message_type,
    // })
  }

  handleEditScheduledChat(chatDetails) {

    const message = JSON.parse(chatDetails.message_json)
    this.setState({ isScheduledChatOpen: false, isScheduleMessageEdit: true, editChatDetails: chatDetails, editMessage: message.text, chatId: chatDetails.chat_id });
  }

  handleCancelEditScheduledChat() {

    this.setState({ isScheduledChatOpen: false, isScheduleMessageEdit: false, editMessage: "", multimediaData: null })
    this.props.setSelectedMultimediaData(null)
  }

  handleCancelEditScheduledChatCancelButton() {

    this.setState({ isScheduledChatOpen: false, isScheduleMessageEdit: false, editMessage: "", multimediaData: null })
  }

  handleMessageScheduleTimeEdit(chatDetails) {
    const message = JSON.parse(chatDetails.message_json);

    const timeZone = localStorage.getItem("CurrentTimeZone");
    const originalDate = moment.utc(chatDetails.ScheduleDateTime).format("YYYY/MM/DD");
                    const convertedDate = moment(chatDetails.ScheduleDateTime)
                                          .utc(chatDetails.ScheduleDateTime)
                                          .tz(JSON.parse(timeZone))
                                          .format("YYYY/MM/DD");
    this.setState({
      isScheduledChatOpen: false,
      isScheduling: true,
      isScheduleTimeEdit: true,
      editChatDetails: chatDetails,
      editMessage: message.text,
      imgUrl: message.imgUrl ? message?.imgUrl : message?.stickerId,
      scheduleTimeEdit: {
        date: moment(chatDetails.ScheduleDateTime).tz(JSON.parse(timeZone)),
        time: moment(chatDetails.ScheduleDateTime).tz(JSON.parse(timeZone)),
      }
    })
  }

  handleEditScheduledPoll(chatDetails) {

    const message = JSON.parse(chatDetails.message_json);
    const pollAns = message.pollAnswers.map(item => ({ name: item.option }));
    const timeZone = localStorage.getItem("CurrentTimeZone");
    const originalDate = moment.utc(chatDetails.ScheduleDateTime).format("YYYY/MM/DD");
    const convertedDate = moment(chatDetails.ScheduleDateTime)
                          .utc(chatDetails.ScheduleDateTime)
                          .tz(JSON.parse(timeZone))
                          .format("YYYY/MM/DD");
    const originalDateEnd = moment.utc(chatDetails.scheduleEndDateTime).format("YYYY/MM/DD");
    const convertedDateEnd = moment(chatDetails.scheduleEndDateTime)
                        .utc(chatDetails.scheduleEndDateTime)
                        .tz(JSON.parse(timeZone))
                        .format("YYYY/MM/DD");
    this.setState({
      isScheduledChatOpen: false,
      isSchedulePollEdit: true,
      pollQuestion: message.question,
      editPollAnswers: pollAns,
      editChatDetails: chatDetails,
      isPollScheduling: true,
      validationOnMessage: "",
      validationOnPoll: "",
      isValidEndDateTime:"",
      scheduleTimeEdit: {
        date: moment(chatDetails.ScheduleDateTime).tz(JSON.parse(timeZone)),
        time: moment(chatDetails.ScheduleDateTime).tz(JSON.parse(timeZone))
      },
      scheduleEndTimeEdit: {
        date: moment(chatDetails.scheduleEndDateTime).tz(JSON.parse(timeZone)),
        time: moment(chatDetails.scheduleEndDateTime).tz(JSON.parse(timeZone))
      },
    }, () => {
      this.openModal()

    });
  }

  openModal() {
    this.setState({
      isOpen: true,
    })
  }

  handleCancelEditScheduledPoll() {
    this.setState({ isScheduledChatOpen: false, isSchedulePollEdit: false, isOpen: false, pollQuestion: "", editPollAnswers: [], editChatDetails: "", isPollScheduling: false })
  }

  // Set an edit function data.
  handleEditScheduledImage(chatDetails) {
    const message = JSON.parse(chatDetails.message_json)
    this.setState({
      multimediaData: message,
      isScheduledChatOpen: false,
      isScheduleMessageEdit: true,
      editChatDetails: chatDetails,
      editMessage: message.text,
      chatId: chatDetails.chat_id,
      imgUrl: message.imgUrl
    })
    this.props.setSelectedMultimediaData(message)
  }


  sendEditMessage() {
    const userId =
      storeFolder.getState().loginResponse.results.ProfileDetail.UserId; // from redux store
    const dialogId = localStorage.getItem("currentDialogId");
    // let scheduleTime = moment.utc(this.state.scheduleTimeEdit.time).format('HH:mm:ss');
    // let scheduleDate = moment.utc(this.state.scheduleTimeEdit.date).format('YYYY-MM-DD');
    // let scheduleDateAndTime = `${scheduleDate} ${scheduleTime}`;
    const timeZone = localStorage.getItem('CurrentTimeZone')
    let scheduleDateFormatted = moment(this.state.scheduleTimeEdit.date).format('YYYY-MM-DD');
    let scheduleTimeFormatted = moment(this.state.scheduleTimeEdit.time, 'HH:mm').format('HH:mm:ss');
    let scheduleDateTime = moment.tz(`${scheduleDateFormatted} ${scheduleTimeFormatted}`, JSON.parse(timeZone)).utc().format('YYYY-MM-DD HH:mm:ss');
    const storeData = storeFolder.getState();
    const multimediaData = storeData.messageReducer.selectedMultimediaData;


    if (this.state.editMessage === "[Sticker message]" ||
      this.state.editMessage === "[GIF message]" ||
      this.state.editMessage ===
      "[Picture message]") {
      ws.json({
        // "msgid": `${date.getTime().toString().slice(4)}`,
        "chat_id": `${this.state.editChatDetails.chat_id}`,
        "message_type": this.getMessageType(this.state.editMessage),
        "chat_time": this.state.isScheduleTimeEdit ? `${scheduleDateTime}` : `${this.state.editChatDetails.ScheduleDateTime}`,
        "user_id": userId.toString(),
        "type": "businesschat",
        "chat_type": "Event",
        "contentId":"",
        "edit": true,
        "token_id": localStorage.getItem("token"),
        "googlePlacedId": `${dialogId.toString()}`,
        "isScheduled": "true",
        "scheduleDateTime": this.state.isScheduleTimeEdit ? `${scheduleDateTime}` : `${this.state.editChatDetails.ScheduleDateTime}`,
        "chat_message":this.state.editMessage === "[Sticker message]" ? (multimediaData ? multimediaData : {
          "text": `${this.state.editMessage}`,
          "stickerId": `${this.state.imgUrl}`
        }) :
        {
          "text": `${this.state.editMessage}`,
          "imgUrl": `${this.state.imgUrl}`
        }

      });
      this.setState({
        editMessage: "", isScheduleMessageEdit: false, chatId: "", editChatDetails: "", isScheduleTimeEdit: false, scheduleTimeEdit: {
          date: new Date(),
          time: moment().add(1, 'm'),
        },
        isScheduling: false,
        multimediaData: null
      });
      this.props.setSelectedMultimediaData(null)
      return;
    }

    ws.json({
      // "msgid": `${date.getTime().toString().slice(4)}`,
      "chat_id": `${this.state.editChatDetails.chat_id}`,
      "message_type": "1",
      "chat_time": this.state.isScheduleTimeEdit ? `${scheduleDateTime}` : `${this.state.editChatDetails.ScheduleDateTime}`,
      "user_id": userId.toString(),
      "type": "businesschat",
      "chat_type": "Event",
      "contentId":"",
      "edit": true,
      "token_id": localStorage.getItem("token"),
      "googlePlacedId": `${dialogId.toString()}`,
      "isScheduled": "true",
      "scheduleDateTime": this.state.isScheduleTimeEdit ? `${scheduleDateTime}` : `${this.state.editChatDetails.ScheduleDateTime}`,
      "chat_message": {
        "text": `${this.state.editMessage}`
      }
    });
    this.setState({
      editMessage: "", isScheduleMessageEdit: false, chatId: "", editChatDetails: "", isScheduleTimeEdit: false, scheduleTimeEdit: {
        date: new Date(),
        time: moment().add(1, 'm'),
      }, isScheduling: false
    });
  }

  scheduleMessagesModal = () => {
    let storeData = storeFolder.getState();
    const scheduledChats = storeData?.messageReducer?.scheduledMessages || [];
    const userProfile = storeData.loginResponse.results.ProfileDetail


    return (
      <Modal
        open={this.state.isScheduledChatOpen && !this.state.isOpen}
        onClose={() => this.setState({ isScheduledChatOpen: false })}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <div
          style={{
            position: "relative",
            float: "right",
          }}
          className="addAttendeeModal"
        >
          <div
            style={{
              overflow: "auto",
              backgroundColor: "#fff",
              // width: window.innerWidth < 600 ? window.innerWidth : 600,
              height: "100vh",
              padding: "10px 15px",
            }}
            className="modalWidth"
          >
            <div>
              <div className="edit_container">
                <div style={{ padding: 10 }} className="edit_container_close_btn">
                  <IoCloseOutline
                    size={25}
                    color="rgba(0, 0, 0, 0.54)"
                    onClick={() => this.setState({ isScheduledChatOpen: false })}
                  />
                </div>
                <p className="edit_container_header">Scheduled Messages</p>
                <div />
              </div>
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                overflowY: 'scroll',
                marginTop: '15px',
                marginBottom: "15px"
              }}
                onScroll={this.handleScroll}
              >
                {scheduledChats.sort((a, b) => {

                  // const timeZone = localStorage.getItem("CurrentTimeZone");
                  // const originalDateA = moment.utc(a.ScheduleDateTime).format("MMM DD");
                  // const convertedDateA = moment(a.ScheduleDateTime)
                  //                       .utc(a.ScheduleDateTime)
                  //                       .tz(JSON.parse(timeZone))
                  //                       .format("MMM DD");
                  // const originalDateB = moment.utc(b.ScheduleDateTime).format("MMM DD");
                  // const convertedDateB = moment(b.ScheduleDateTime)
                  //                       .utc(b.ScheduleDateTime)
                  //                       .tz(JSON.parse(timeZone))
                  //                       .format("MMM DD");

                  // const timeA = this.compareDates(originalDateA,convertedDateA);
                  // const timeB = this.compareDates(originalDateB,convertedDateB);

                  const timeA = new Date(a.ScheduleDateTime);
                  const timeB = new Date(b.ScheduleDateTime);

                  return timeA - timeB;
                })
                  .map((item, index) => {
                    // console.log("ITEM_101", item);
                    try {
                      var chatDetails =
                        typeof item?.message_json === "string"
                          ? JSON.parse(item?.message_json)
                          : item?.message_json;
                    } catch (error) {

                    }
                    const timeZone = localStorage.getItem("CurrentTimeZone");
                    const progression = 0;
                    const isPoll = chatDetails?.hasOwnProperty('PollQuestionId')

                    const originalDate = moment(item?.ScheduleDateTime).format("MMM DD");
                    const convertedDate = moment(item?.ScheduleDateTime)
                                          // .utc(item?.ScheduleDateTime)
                                          .tz(JSON.parse(timeZone))
                                          ?.format("MMM DD");

                    const finalDate = this.compareDates(originalDate,convertedDate);

                    if (chatDetails?.text) {
                      return (
                        chatDetails?.text && (
                          <div
                            key={index}
                          style={{
                            padding: '15px',
                            gap: '10px',
                            marginRight: '10px',
                            borderTop: index === 0 ? '' : '1px solid #c277ca',
                            backgroundColor: '#ffffff',
                            display:"flex",
                            flexDirection:"column"
                          }}>

                                <div className="schedule-hidden-reverse">
                                <p>
                                    Scheduled for {
                                      moment(item?.ScheduleDateTime)
                                      //   .utc(item?.ScheduleDateTime)
                                         .tz(JSON.parse(timeZone))
                                      .format("MMM DD, ")
                                      // finalDate
                                    } {
                                      moment(item?.ScheduleDateTime)
                                        // .utc(item?.ScheduleDateTime)
                                        .tz(JSON.parse(timeZone))
                                        .format("[at] hh:mm a")
                                    }
                                  </p>
                                </div>

                            <div style={{
                              display: ' flex',
                              gap: '10px',
                              backgroundColor: '#ffffff',
                              }} key={index}
                            >

                            <div>
                              <a href="#!">
                                <img
                                  style={{
                                    width: 35,
                                    height: 35,
                                    borderRadius: 50,
                                    objectFit: "cover",
                                  }}
                                  src={
                                    userProfile.ProfileImage !== null
                                      ? userProfile.ProfileImage
                                      : UserIconsPic(userProfile.FirstName[0]?.toUpperCase())
                                  }
                                  alt="1"
                                />
                              </a>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                              <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', height:"24px" }}>
                                  <h4 style={{ fontWeight: 'bold' , width:"max-content" }}>{userProfile.FirstName}</h4>
                                </div>
                                <div className="schedule-hidden">
                                  <p>
                                    Scheduled for {
                                      moment(item?.ScheduleDateTime)
                                      //   .utc(item?.ScheduleDateTime)
                                         .tz(JSON.parse(timeZone))
                                      .format("MMM DD, ")
                                      // finalDate
                                    } {
                                      moment(item?.ScheduleDateTime)
                                        // .utc(item?.ScheduleDateTime)
                                        .tz(JSON.parse(timeZone))
                                        .format("[at] hh:mm a")
                                    }
                                  </p>
                                </div>
                              </div>
                              {chatDetails?.text ===
                                "[Sticker message]" ||
                                chatDetails?.text === "[GIF message]" ||
                                chatDetails?.text ===
                                "[Picture message]" ? (
                                <>
                                  <div style={{ display: 'flex', gap: 10, justifyContent: "end", width: "100%" }}>
                                    <DeleteIcon style={{ cursor: 'pointer', color: "#ff0000" }}
                                      onClick={() => this.handleDeleteScheduledChat(item)} height={35} width={35}
                                    />
                                    <EditIcon style={{ cursor: 'pointer' }} onClick={() => this.handleEditScheduledImage(item)} height={35} width={35} />
                                    <IoIosTimer style={{ cursor: 'pointer' }} onClick={() => this.handleMessageScheduleTimeEdit(item)} size={18} />
                                  </div>
                                  <img
                                    style={{
                                      width: "26%",
                                      marginTop: "10px"
                                    }}
                                    //*HERE
                                    src={
                                      chatDetails?.text ===
                                        "[Sticker message]"
                                        ?
                                        (chatDetails?.text === "[Sticker message]" &&
                                        (
                                          chatDetails?.stickerId.split(".")[0] >
                                          0 &&
                                          chatDetails?.stickerId.split(".")[0] <=
                                          30
                                        )) ?
                                        require(`images/Stickers/${chatDetails?.stickerId}`)?.default
                                        : `https://d2gnb7jphto2jj.cloudfront.net/ChatImages/Sticker/Images/${
                                          chatDetails?.stickerId}`
                                        : chatDetails?.imgUrl
                                    }
                                    alt="media"
                                  />
                                </>
                              ) : (
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '4px' }} key={index}>
                                  <span style={{}} >
                                    {this.formatMessage(chatDetails?.text)}
                                  </span>
                                  <div style={{ display: 'flex', gap: 10 }}>
                                    <DeleteIcon style={{ cursor: 'pointer', color: "#ff0000" }} onClick={() => this.handleDeleteScheduledChat(item)} height={35} width={35} />
                                    <EditIcon style={{ cursor: 'pointer' }} onClick={() => this.handleEditScheduledChat(item)} height={35} width={35} />
                                    <IoIosTimer style={{ cursor: 'pointer' }} onClick={() => this.handleMessageScheduleTimeEdit(item)} size={18} />
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          </div>
                        ))
                    } else if (item.message_type === 7) {

                      const chatDetails =
                        typeof item?.message_json === "string"
                          ? JSON.parse(item?.message_json)
                          : item?.message_json;

                      const pollAnswers =
                        chatDetails?.pollAnswers ? (
                          typeof chatDetails?.pollAnswers === "string"
                            ? JSON.parse(chatDetails?.pollAnswers)
                            : chatDetails?.pollAnswers
                        ) : (
                          typeof chatDetails?.pollAnswers === "string"
                            ? JSON.parse(chatDetails?.answers)
                            : chatDetails?.answers
                        );
                      return (
                        <div
                          style={{
                            padding: '15px',
                            gap: '10px',
                            marginRight: '10px',
                            borderTop: index === 0 ? '' : '1px solid #c277ca',
                            backgroundColor: '#ffffff',
                            display:"flex",
                            flexDirection:"column"
                          }}
                        >

                              <div className="schedule-hidden-reverse">
                              <p>
                                    Scheduled for {
                                      moment(item?.ScheduleDateTime)
                                      //   .utc(item?.ScheduleDateTime)
                                         .tz(JSON.parse(timeZone))
                                      ?.format("MMM DD, ")
                                      // finalDate
                                    } {
                                      moment(item?.ScheduleDateTime)
                                        // .utc(item?.ScheduleDateTime)
                                        .tz(JSON.parse(timeZone))
                                        ?.format("[at] hh:mm a")
                                    }
                                  </p>
                              </div>


                          <div style={{
                          display: ' flex',
                          gap: '10px',
                          backgroundColor: '#ffffff',
                        }} key={index}>
                          <div>
                            <a href="#!">
                              <img
                                style={{
                                  width: 35,
                                  height: 35,
                                  borderRadius: 50,
                                  objectFit: "cover",
                                }}
                                src={
                                  userProfile.ProfileImage !== null
                                    ? userProfile.ProfileImage
                                    : UserIconsPic(userProfile.FirstName[0]?.toUpperCase())
                                }
                                alt="1"
                              />
                            </a>
                          </div>
                          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                              <div style={{ display: 'flex', flexDirection: 'column',height:"24px" }}>
                                <h4 style={{ fontWeight: 'bold' , width:"max-content"}}>{userProfile.FirstName}</h4>
                              </div>
                              <div className="schedule-hidden">
                              <p>
                                    Scheduled for {
                                      moment(item?.ScheduleDateTime)
                                      //   .utc(item?.ScheduleDateTime)
                                         .tz(JSON.parse(timeZone))
                                      ?.format("MMM DD, ")
                                      // finalDate
                                    } {
                                      moment(item?.ScheduleDateTime)
                                        // .utc(item?.ScheduleDateTime)
                                        .tz(JSON.parse(timeZone))
                                        ?.format("[at] hh:mm a")
                                    }
                                  </p>
                              </div>
                            </div>
                            {chatDetails?.question && (
                              <div style={{ display: 'flex', flexDirection: 'column', columnGap: '4px' }}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '4px' }} key={index}>
                                  <span style={{ marginBlock: '4px' }}>{chatDetails?.question}</span>
                                  <div style={{ display: 'flex', gap: 10 }}>
                                    <DeleteIcon style={{ cursor: 'pointer', color: "#ff0000" }}
                                      onClick={() => this.handleDeleteScheduledChat(item)}
                                      height={35} width={35}
                                    />
                                    <EditIcon style={{ cursor: 'pointer' }} onClick={() => this.handleEditScheduledPoll(item)} height={35} width={35} />
                                  </div>
                                </div>
                                {pollAnswers?.map(
                                  (answer, index) => {
                                    return (
                                      <div key={answer.optionId}
                                        style={{
                                          display: 'flex',
                                          borderRadius: '8px',
                                          justifyContent: 'space-between',
                                          alignItems: 'center',
                                          paddingBlock: '5px',
                                          paddingInline: '10px',
                                          position: 'relative',
                                          marginBottom: '4px',
                                          background: index === 0 ? `linear-gradient(to right, #c277ca 0%, #c277ca ${(answer?.votes / chatDetails?.totalVotes) * 100}%, transparent ${(answer?.votes / chatDetails?.totalVotes) * 100}%, transparent 100%` : `linear-gradient(to right, #cbd9e3 0%, #cbd9e3 ${(answer?.votes / chatDetails?.totalVotes) * 100}%, transparent ${(answer?.votes / chatDetails?.totalVotes) * 100}%, transparent 100%`
                                        }}>
                                        <div style={{ display: 'flex', gap: '7px', alignItems: 'center' }}>
                                          {progression === 0 && (
                                            <div style={{ width: '5px', height: '25px', backgroundColor: '#AAB8C2', borderRadius: '8px' }} />
                                          )}
                                          <div style={{
                                            fontWeight: 'bold',
                                            position: 'relative'
                                          }}>
                                            {answer?.option ? answer?.option : answer?.name}
                                          </div>
                                        </div>

                                        <div style={{ marginLeft: '20px' }}>
                                          {answer?.votes ? answer?.votes == 0 ? "0" : (answer?.votes / chatDetails?.totalVotes) * 100 : "0"}%
                                        </div>
                                      </div>
                                    )
                                  })}
                              </div>
                            )}
                          </div>
                        </div>
                        </div>
                      )
                    } else {
                      return null
                    }
                  })}
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: this.state.initialPageIndex === 1 ? "end" : "space-between", width: "100%", padding: "0px 35px", fontSize: "18px", color: '#ca3dd9', }}>
              {this.state.initialPageIndex > 1 && <div style={{ cursor: "pointer" }} onClick={() => this.fetchLessData()}>
                Previous
              </div>}
              {scheduledChats.length >= 15 &&
                <div style={{ justifyItems: "end", cursor: "pointer" }} onClick={() => this.fetchMoreData()}>
                  Next
                </div>
              }
            </div>
            <div style={{
              width: "100%",
              height: "80px"
            }}>
            </div>
          </div>
        </div>
      </Modal >
    );
  };

  isPollDisabled = () => {
    return (
      // this.state.pollQuestion.length > 35 ||
      this.state.inputFields.some((item) => item.text.length > 25)
    );
  }

  schedulePollModal = () => {
    const storeData = storeFolder.getState();
    const validationMessage = this.isScheduleTimeGreaterThanEndTime();
    const validate = this.scheduleDateAndTimeCheck();
    const timeZone = localStorage.getItem("CurrentTimeZone");

    return (
      <Modal
        open={this.state.isOpen}
        onClose={() => this.setState({
          isOpen: false, pollQuestion: "", inputFields: JSON.parse(JSON.stringify(initialOptions)), scheduleTime: {
            date: new Date(),
            time: moment().add(1, "m"),
          },
          scheduleEndTime: {
            date: localStorage.getItem("EventEndDate") ? new Date(moment(localStorage.getItem("EventEndDate")).format("YYYY/MM/DD")) : new Date(moment(storeData?.eventUserEvents?.eventsAbout?.EndDateTime).format("YYYY/MM/DD")),
            time: moment().add(1, "m"),
          },
          isPollScheduling: false,
          isScheduledChatOpen: false,
          isSchedulePollEdit: false,
          isOpen: false,
          pollQuestion: "",
          editPollAnswers: [],
          editChatDetails: "",
          validationOnMessage: "",
          validationOnPoll: "",
        })}
      >
        <TransitionGroup className="polls_modal_wrapper">
          <div style={{ marginBottom: "8px" }}>
            <div style={{ position: "absolute", left: "10px", marginTop: "10px", marginLeft: "10px", fontSize: "18px", fontWeight: "600" }}>
              {this.state.isSchedulePollEdit ? "Edit poll" : "Create a poll"}
            </div>
            <div style={{ float: "right" }}>
              <IconButton
                onClick={() => {
                  if (this.state.isSchedulePollEdit) {
                    this.handleCancelEditScheduledPoll()
                  }
                  this.setState({
                    isOpen: false, isPollScheduling: false, pollQuestion: "", inputFields: JSON.parse(JSON.stringify(initialOptions)), scheduleTime: {
                      date: new Date(),
                      time: moment().add(1, "m"),
                    },
                    scheduleEndTime: {
                      date: localStorage.getItem("EventEndDate") ? new Date(moment(localStorage.getItem("EventEndDate")).format("YYYY/MM/DD")) : new Date(moment(storeData?.eventUserEvents?.eventsAbout?.EndDateTime).format("YYYY/MM/DD")),
                      time: moment().add(1, "m"),
                    },
                    validationOnMessage: "",
                    validationOnPoll: "",
                  })
                }
                }
              >
                <GrClose />
              </IconButton>
            </div>
          </div>
          {/* <div style={{display:"flex",width:"100%",height:"4px"}}></div> */}
          <TransitionGroup className="poll_wrapper">
            <div>
              <input
                type="text"
                value={this.state.pollQuestion}
                className="question_input"
                placeholder="Ask a question"
                onChange={(e) => {
                  // if(this.state.isSchedulePollEdit){
                  //   this.setState({ ...this.state, editPollquestion: e.target.value })
                  // }
                  this.setState({ ...this.state, pollQuestion: e.target.value })
                }
                }
              />
              {/* {
                <p style={{
                  marginLeft: "8px",
                  fontSize: "12px",
                  color: this.state.pollQuestion.length < 26 ? "black" : "red"
                }}>
                  {this.state.pollQuestion.length}/25
                  {this.state.pollQuestion.length > 25 &&
                    <span style={{
                      marginLeft: "8px",
                      fontSize: "12px",
                      color: "red"
                    }}>
                      Word limit exceeded
                    </span>
                  }
                </p>
              } */}
              {/* {
                this.state.pollQuestion.length > 25 &&
                <p style={{
                  marginLeft: "8px",
                  fontSize: "12px",
                  color: "red"
                }}>
                  Word limit exceeded
                </p>
              } */}
              <TransitionGroup className="poll_wrapper_inputs">
                {
                  this.state.isSchedulePollEdit ? (
                    <>
                      {this.state.editPollAnswers.map((inputField, index) => {
                        return (
                          // <Collapse>
                          <div className="poll_wrapper_input_field" key={index}>
                            <input
                              name={`Option ${index + 1}`}
                              type="text"
                              className="choice_input"
                              onChange={(event) =>
                                this.handleChangeInput(index, event)
                              }
                              value={inputField.name}
                              placeholder={`Choice ${index + 1}`}
                              maxLength={25}
                            />

                            {<p style={{
                              fontSize: "12px",
                              color: "gray",
                              position: "absolute",
                              right: index == 2 && this.state.editPollAnswers.length === 3 ? "107px" : this.state.editPollAnswers.length > 2 ? "50px" : "68px",
                              backgroundColor: "white",
                              padding: "5px"
                            }}>
                              {inputField?.name?.length ? inputField?.name?.length : 0}/25
                            </p>}

                            {index === this.state.editPollAnswers.length - 1 &&
                              this.state.editPollAnswers.length < 4 && (
                                <IconButton onClick={this.handleAddFields}>
                                  <HiOutlinePlusSm style={{ color: "#a435a6" }} size={20} />
                                </IconButton>
                              )}

                            {index > 1 && index <= 3 && (
                              <IconButton
                                className="optionDeleteBottom"
                                onClick={() => this.handleRemovePollOptions(index)}
                              >
                                <ClearIcon />
                              </IconButton>
                            )}

                          </div>
                          /* {inputField.option.length > 25 && (
                            <p style={{ marginLeft: '10px' }} className="error">Option must be 25 characters or less</p>
                          )}

                        </Collapse> */
                        )
                      })}
                    </>
                  ) : (
                    <>
                      {this.state.inputFields.map((inputField, index) => {
                        return (
                          // <Collapse>
                          <div className="poll_wrapper_input_field" key={index}>
                            <input
                              name={`Option ${index + 1}`}
                              type="text"
                              className="choice_input"
                              onChange={(event) =>
                                this.handleChangeInput(index, event)
                              }
                              value={inputField.text}
                              placeholder={`Choice ${index + 1}`}
                              maxLength={25}
                            />

                            {<p style={{
                              fontSize: "12px",
                              color: inputField.text.length < 26 ? "gray" : "red",
                              position: "absolute",
                              right: index == 2 && this.state.inputFields.length === 3 ? "107px" : this.state.inputFields.length > 2 ? "50px" : "68px",
                              backgroundColor: "white",
                              padding: "5px"
                            }}>
                              {inputField.text.length}/25
                            </p>}

                            {index === this.state.inputFields.length - 1 &&
                              this.state.inputFields.length < 4 && (
                                <IconButton onClick={this.handleAddFields}>
                                  {/* <AddIcon color="#a435a6"/> */}
                                  <HiOutlinePlusSm style={{ color: "#a435a6" }} size={20} />
                                </IconButton>
                              )}

                            {index > 1 && index <= 3 && (
                              <IconButton
                                className="optionDeleteBottom"
                                onClick={() => this.handleRemovePollOptions(index)}
                              >
                                <ClearIcon />
                              </IconButton>
                            )}

                          </div>
                          /* {inputField.text.length > 25 && (
                            <p style={{ marginLeft: '10px' }} className="error">Option must be 25 characters or less</p>
                          )} */

                          // </Collapse>
                        )
                      })}
                    </>
                  )
                }
              </TransitionGroup>
            </div>
            {
              this.state.isPollScheduling ? (
                <div>
                </div>
              ) : (
                this.state.isSchedulePollEdit ? (
                  <div>
                    <div className="btn-gap" style={{ display: "flex", justifyContent: "center", flexDirection: "row-reverse", marginRight: "20px" }}>
                      {/* <div
                        className="send_now_poll"
                        onClick={() => this.handlePollRequest()}
                        style={{ pointerEvents: this.isPollDisabled() && "none" }}
                      >
                        <p>Post poll</p>
                      </div> */}
                      <Button
                        onClick={() => this.handlePollRequest()}
                        disabled={this.state.isValidEndDateTime}
                        style={{
                          color: "white",
                          fontSize: "1.5rem",
                          padding: "8px 20px",
                          backgroundColor: "#a435a6",
                          borderRadius: "4px",
                          textTransform: "none"
                        }}
                      >
                        Edit
                      </Button>
                      {/* <button
                        onClick={() => this.setState({ isPollScheduling: true })}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          color: "#a435a6",
                          fontSize: "14px",
                          padding: "0px",
                          border: "none",
                          outline: "none",
                          fontWeight: "600",
                          backgroundColor: "transparent",
                        }}
                      >
                        <img
                          style={{ width: "15px", marginRight: "2px" }}
                          src={require("images/Message Icons/clock-icon.png").default}
                          alt="clock-icon"
                        />
                        Schedule for later
                      </button> */}
                      <Button
                        className="scheduleBtn"
                        onClick={() => this.handleCancelEditScheduledPoll()}
                        style={{
                          color: "black",
                          fontSize: "1.5rem",
                          padding: "8px 20px",
                          backgroundColor: "white",
                          border: "1px solid #00000080",
                          borderRadius: "4px",
                          textTransform: "none"
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="btn-gap" style={{ display: "flex", justifyContent: "center", flexDirection: "row-reverse", marginRight: "20px" }}>
                      {/* <div
                        className="send_now_poll"
                        onClick={() => this.handlePollRequest()}
                        style={{ pointerEvents: this.isPollDisabled() && "none" }}
                      >
                        <p>Post poll</p>
                      </div> */}
                      <Button
                        onClick={() => this.handlePollRequest()}
                        disabled={this.state.isValidEndDateTime}
                        style={{
                          color: "white",
                          fontSize: "1.5rem",
                          padding: "8px 15px",
                          backgroundColor: "#a435a6",
                          borderRadius: "4px",
                          textTransform: "none",
                          lineHeight:"normal"
                        }}
                      >
                        Post poll
                      </Button>
                      {/* <button
                        onClick={() => this.setState({ isPollScheduling: true })}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          color: "#a435a6",
                          fontSize: "14px",
                          padding: "0px",
                          border: "none",
                          outline: "none",
                          fontWeight: "600",
                          backgroundColor: "transparent",
                        }}
                      >
                        <img
                          style={{ width: "15px", marginRight: "2px" }}
                          src={require("images/Message Icons/clock-icon.png").default}
                          alt="clock-icon"
                        />
                        Schedule for later
                      </button> */}
                      <Button
                        className="scheduleBtn"
                        onClick={() => this.setState({
                          isPollScheduling: true, scheduleTime: {
                            date: new Date(moment().utc().tz(JSON.parse(timeZone))),
                            time: moment().utc().tz(JSON.parse(timeZone)).add(1, 'm'),
                          }, scheduleEndTime: {
                            date: localStorage.getItem("EventEndDate") ? new Date(moment(localStorage.getItem("EventEndDate")).format("YYYY/MM/DD")) : new Date(moment(storeData?.eventUserEvents?.eventsAbout?.EndDateTime).format("YYYY/MM/DD")),
                            time: moment().utc().tz(JSON.parse(timeZone)).add(1, 'm'),
                          },
                        })}
                        style={{
                          color: "black",
                          fontSize: "1.5rem",
                          padding: "8px 15px",
                          backgroundColor: "white",
                          border: "1px solid #00000080",
                          borderRadius: "4px",
                          textTransform: "none",
                          lineHeight:"normal"
                        }}
                      >
                        Schedule for later
                      </Button>
                    </div>
                  </>
                )
              )
            }

            {this.state.validationOnPoll && (
              <p className="validationOnPolls">
                {this.state.validationOnPoll}
              </p>
            )}

            {/* {
              validate ? (
                <p className="validationOnPolls">
                  End date cannot be smaller than Start date.
                </p>
              ) : (<></>)
            } */}
            {<p className="validationOnPolls">{validationMessage}</p>}


            {this.state.isPollScheduling && (
              <Collapse>
                <p style={{ marginLeft: "6px" }}>Start time</p>
                <ScheduleDataTimeComp prop={this} type={"start"} isSchedulePollEdit={this.state.isSchedulePollEdit} chatDetails={this.state.scheduleTimeEdit} initialDate={this.state.scheduleTime}
                  onChangeDate={this.ChangeStartDate} onChangeTime={this.ChangeStartTime} time={this.state.scheduleTime.time}
                />{" "}
                <p style={{ marginLeft: "6px" }}>End time</p>
                <ScheduleDataTimeComp prop={this} type={"end"} initialDate={this.state.scheduleEndTime} isSchedulePollEdit={this.state.isSchedulePollEdit} chatDetails={this.state.scheduleEndTimeEdit}
                  onChangeDate={this.ChangeEndDate} onChangeTime={this.ChangeEndTime} time={this.state.scheduleEndTime.time}
                />{" "}
              </Collapse>
            )}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: this.state.isPollScheduling
                  ? "space-evenly"
                  : "flex-start",
              }}
            >
              {this.state.isPollScheduling ? (
                <div className="btn-gap">
                  <Button
                    // className={classes.deleteModalSaveAndCancelButton}
                    onClick={() => this.state.isSchedulePollEdit ? this.handlePollRequest() : this.handlePollSchedule()}
                    disabled={this.state.isValidEndDateTime}
                    style={{
                      color: "white",
                      fontSize: "1.5rem",
                      padding: "8px 15px",
                      backgroundColor: "#a435a6",
                      borderRadius: "4px",
                      pointerEvents: (this.isPollDisabled() > 35 && "none") || (validate && "none"),
                      textTransform: "none",
                      lineHeight:"normal"
                    }}
                  >
                    {this.state.isSchedulePollEdit ? "Save" : "Schedule"}
                  </Button>
                  <Button
                    className="scheduleBtn"
                    onClick={() => {
                      {this.state.isSchedulePollEdit && this.handleCancelEditScheduledPoll()}
                      this.setState({
                        isPollScheduling: false,
                        scheduleTime: {
                          date: new Date(),
                          time: moment().add(1, "m"),
                        },
                        scheduleEndTime: {
                          date: localStorage.getItem("EventEndDate") ? new Date(moment(localStorage.getItem("EventEndDate")).format("YYYY/MM/DD")) : new Date(moment(storeData?.eventUserEvents?.eventsAbout?.EndDateTime).format("YYYY/MM/DD")),
                          time: moment().add(1, "m"),
                        },
                      })
                    }
                    }
                    style={{
                      color: "black",
                      fontSize: "1.5rem",
                      padding: "8px 15px",
                      backgroundColor: "white",
                      border: "1px solid #00000080",
                      borderRadius: "4px",
                      textTransform: "none",
                      lineHeight:"normal"
                    }}
                  >
                    {this.state.isSchedulePollEdit ? "Cancel" : "Cancel Scheduling"}
                  </Button>
                </div>
              ) : (
                // <button
                //   onClick={() => this.setState({ isPollScheduling: true })}
                //   style={{
                //     display: "flex",
                //     alignItems: "center",
                //     justifyContent: "center",
                //     color: "#a435a6",
                //     fontSize: "14px",
                //     padding: "0px",
                //     border: "none",
                //     outline: "none",
                //     fontWeight: "600",
                //     backgroundColor: "transparent",
                //   }}
                // >
                //   <img
                //     style={{ width: "15px", marginRight: "2px" }}
                //     src={require("images/Message Icons/clock-icon.png").default}
                //     alt="clock-icon"
                //   />
                //   Schedule for later
                // </button>
                <p></p>
              )
              }
            </div>
          </TransitionGroup>
        </TransitionGroup>
      </Modal >
    );
  };

  handleScheduledMessage = async () => {
    let { textMessage } = this.state;
    const userId =
      storeFolder.getState().loginResponse.results.ProfileDetail.UserId;
    const dialogId = localStorage.getItem("currentDialogId");
    const date = new Date();
    let currentDate = moment().utc().format("YYYY-MM-DD HH:mm:ss");

    // const dateAndTimeString =
    //   moment(this.state.scheduleTime.date).format("MM/DD/YYYY") +
    //   " " +
    //   this.state.scheduleTime.time.format("hh:mm:ss a");

    // const timeZone =
    //   storeFolder.getState().eventUserEvents.eventsAbout.TimeZone;

    // const dateTime = moment(dateAndTimeString, "MM/DD/YYYY hh:mm:ss a");

    // const formatedScheduleDateTime = dateTime.tz(timeZone).format();

    // console.log(formatedScheduleDateTime);
    const storeData = storeFolder.getState();
    const multimediaData = storeData?.messageReducer?.selectedMultimediaData;
    if (this.state.textMessage.trim().length === 0 && !multimediaData) {
      this.setState({ validationOnMessage: "Please enter a message first" });
      return;
    }
    if (textMessage.trim().length > 0 && dialogId) {
      // let scheduleTime = moment.utc(this.state.scheduleTime.time).format('YYYY-MM-DD HH:mm:ss');
      // let scheduleTime = moment.utc(this.state.scheduleTime.time).format('HH:mm:ss');
      // let scheduleDate = moment.utc(this.state.scheduleTime.date).format('YYYY-MM-DD');
      // let scheduleDateAndTime = `${scheduleDate} ${scheduleTime}`;
      // console.log("message time",moment.utc(this.state.scheduleTime.time).format('YYYY-MM-DD HH:mm:ss'),"date",moment.utc(this.state.scheduleTime.date).format('YYYY-MM-DD'));
      const timeZone = localStorage.getItem('CurrentTimeZone')
      let scheduleDateFormatted = moment(this.state.scheduleTime.date).format('YYYY-MM-DD');
      let scheduleTimeFormatted = moment(this.state.scheduleTime.time, 'HH:mm').format('HH:mm:ss');
      let scheduleDateTime = moment.tz(`${scheduleDateFormatted} ${scheduleTimeFormatted}`, JSON.parse(timeZone)).utc().format('YYYY-MM-DD HH:mm:ss');
      console.log("final date and time",scheduleDateTime);
      await ws.json({
        msgid: date.getTime().toString().slice(4),
        chat_message: {
          text: textMessage,
        },
        message_type: "1",
        // chat_time: currentDate.toString(),
        chat_time: scheduleDateTime,
        user_id: userId.toString(),
        type: "businesschat",
        chat_type: "Event",
        token_id: localStorage.getItem("token"),
        googlePlacedId: dialogId,
        isScheduled: "true",
        scheduleDateTime: scheduleDateTime,
      });

      textMessage = "";
      document.getElementById('textMessage').style.height = 'auto';
      this.setState({ isScheduling: false });
      this.setState({ textMessage });
    } else if (multimediaData && dialogId) {
      // let scheduleTime = moment.utc(this.state.scheduleTime.time).format('HH:mm:ss');
      // let scheduleDate = moment.utc(this.state.scheduleTime.date).format('YYYY-MM-DD');
      // let scheduleDateAndTime = `${scheduleDate} ${scheduleTime}`
      const timeZone = localStorage.getItem('CurrentTimeZone')
      let scheduleDateFormatted = moment(this.state.scheduleTime.date).format('YYYY-MM-DD');
      let scheduleTimeFormatted = moment(this.state.scheduleTime.time, 'HH:mm').format('HH:mm:ss');
      let scheduleDateTime = moment.tz(`${scheduleDateFormatted} ${scheduleTimeFormatted}`, JSON.parse(timeZone)).utc().format('YYYY-MM-DD HH:mm:ss');
      console.log("final date and time",scheduleDateTime);
      await ws.json({
        msgid: date.getTime().toString().slice(4),
        chat_message: multimediaData,
        message_type: this.getMessageType(multimediaData?.text),
        chat_time: scheduleDateTime,
        user_id: userId.toString(),
        type: "businesschat",
        chat_type: "Event",
        token_id: localStorage.getItem("token"),
        googlePlacedId: dialogId,
        isScheduled: "true",
        scheduleDateTime: scheduleDateTime,
      });
      if (multimediaData.text === "[GIF message]") {
        this.props.clearDetailedGif();
      }
      this.setState({ isScheduling: false });
      // localStorage.setItem('TotalScheduledChatCount', Number(TotalScheduledChatCount) + 1)
      textMessage = "";
      this.props.updateWebViewState("scroll", true);
      this.props.setSelectedMultimediaData(null)
    }
  };

  handlePollRequest = async () => {
    let date = new Date();
    let currentDate = moment().utc().format("YYYY-MM-DD HH:mm:ss");
    const { inputFields, pollQuestion } = this.state;

    let answersLength = 0;
    let answers = [];
    for (let i = 0; i <= inputFields.length - 1; i++) {
      let option = inputFields[i];
      if (option.text.length > 0) {
        answersLength++;
        const name = option.text;
        answers.push({ name });
      }
    }

    if (this.state.isSchedulePollEdit) {
      // if (this.state.pollQuestion.trim().length === 0 || this.state.editPollAnswers <= 1) {
      //   this.setState({ validationOnPoll: "Please enter a question and atleast two options" });
      //   return;
      // }
      // let scheduleTime = moment.utc(this.state.scheduleTimeEdit.time).format('HH:mm:ss');
      // let scheduleDate = moment.utc(this.state.scheduleTimeEdit.date).format('YYYY-MM-DD');
      // let scheduleDateAndTime = `${scheduleDate} ${scheduleTime}`
      const timeZone = localStorage.getItem('CurrentTimeZone')
      let scheduleDateFormatted = moment(this.state.scheduleTimeEdit.date).format('YYYY-MM-DD');
      let scheduleTimeFormatted = moment(this.state.scheduleTimeEdit.time, 'HH:mm').format('HH:mm:ss');
      let scheduleDateTime = moment.tz(`${scheduleDateFormatted} ${scheduleTimeFormatted}`, JSON.parse(timeZone)).utc().format('YYYY-MM-DD HH:mm:ss');
      console.log("final date and time",scheduleDateTime);
      let scheduleTimeEnd = moment.utc(this.state.scheduleEndTimeEdit.time).format('HH:mm:ss');
      let scheduleDateEnd = moment.utc(this.state.scheduleEndTimeEdit.date).format('YYYY-MM-DD');
      let scheduleEndDateAndTime = `${scheduleDateEnd} ${scheduleTimeEnd}`
      await ws.json({
        "message_type": "7",
        "contentId": `${this.state.editChatDetails.ContentId}`,
        "chat_id": `${this.state.editChatDetails.chat_id}`,
        "chat_time": `${scheduleDateTime}`,
        "user_id": `${localStorage.getItem("UserId")}`,
        "isScheduled": "true",
        "scheduleDateTime": `${scheduleDateTime}`,
        "scheduleEndDateTime": `${scheduleEndDateAndTime}`,
        "type": "businesschat",
        "chat_type": "Event",
        "edit": true,
        "token_id": `${localStorage.getItem("token")}`,
        "googlePlacedId": `${localStorage.getItem("currentDialogId")}`,
        "chat_message": {
          "question": this.state.pollQuestion,
          "answers": this.state.editPollAnswers
        }
      })

      this.setState({ isScheduledChatOpen: false, isSchedulePollEdit: false, isOpen: false, pollQuestion: "", editPollAnswers: [], editChatDetails: "", isPollScheduling: false })
      return
    }

    if (this.state.pollQuestion.trim().length === 0 || answersLength <= 1) {
      this.setState({ validationOnPoll: "Please enter a question and atleast two options" });
      return;
    }

    if (this.state.pollQuestion.trim().length !== 0 && answersLength > 1) {

      await ws.json({
        msgid: date.getTime().toString().slice(4),
        message_type: "7",
        chat_time: currentDate.toString(),
        user_id: localStorage.getItem("UserId"),
        isScheduled: "",
        scheduleDateTime: "",
        scheduleEndDateTime: "",
        type: "businesschat",
        chat_type: "Event",
        token_id: localStorage.getItem("token"),
        googlePlacedId: localStorage.getItem("currentDialogId"),
        chat_message: {
          question: pollQuestion,
          answers: answers,
        },
      });

      this.setState({
        isOpen: false,
        inputFields: JSON.parse(JSON.stringify(initialOptions)),
        pollQuestion: "",
        validationOnPoll: ""
      });
    }
  };

  handlePollSchedule = async () => {
    let date = new Date();
    let currentDate = moment().utc().format("YYYY-MM-DD HH:mm:ss");
    const { inputFields, pollQuestion } = this.state;

    let answersLength = 0;
    let answers = [];
    for (let i = 0; i <= inputFields.length - 1; i++) {
      let option = inputFields[i];
      if (option.text.length > 0) {
        answersLength++;
        const name = option.text;
        answers.push({ name });
      }
    }

    if (this.state.pollQuestion.trim().length === 0 || answersLength <= 1) {
      this.setState({ validationOnPoll: "Please enter a question and atleast two options" });
      return;
    }

    if (this.state.pollQuestion.trim().length !== 0 && answersLength > 1) {
      // let scheduleTime = moment.utc(this.state.scheduleTime.time).format('YYYY-MM-DD HH:mm:ss');
      // let scheduleTime = moment.utc(this.state.scheduleTime.time).format('HH:mm:ss');
      // let scheduleDate = moment.utc(this.state.scheduleTime.date).format('YYYY-MM-DD');
      // let scheduleDateAndTime = `${scheduleDate} ${scheduleTime}`
      const timeZone = localStorage.getItem('CurrentTimeZone')
      let scheduleDateFormatted = moment(this.state.scheduleTime.date).format('YYYY-MM-DD');
      let scheduleTimeFormatted = moment(this.state.scheduleTime.time, 'HH:mm').format('HH:mm:ss');
      let scheduleDateTime = moment.tz(`${scheduleDateFormatted} ${scheduleTimeFormatted}`, JSON.parse(timeZone)).utc().format('YYYY-MM-DD HH:mm:ss');
      let storeData = storeFolder.getState();
      // let scheduleEndTime = moment.utc(this.state.scheduleEndTime.time).format('HH:mm:ss');
      // let scheduleEndDate = moment.utc(this.state.scheduleEndTime.date).format('YYYY-MM-DD');
      // let scheduleEndDateAndTime = `${scheduleEndDate} ${scheduleEndTime}`;
      let scheduleEndDateFormatted = moment(this.state.scheduleEndTime.date).format('YYYY-MM-DD');
      let scheduleEndTimeFormatted = moment(this.state.scheduleEndTime.time, 'HH:mm').format('HH:mm:ss');
      let scheduleEndDateTime = moment.tz(`${scheduleEndDateFormatted} ${scheduleEndTimeFormatted}`, JSON.parse(timeZone)).utc().format('YYYY-MM-DD HH:mm:ss');

      await ws.json({
        msgid: date.getTime().toString().slice(4),
        message_type: "7",
        chat_time: scheduleDateTime,
        user_id: localStorage.getItem("UserId"),
        isScheduled: "true",
        scheduleDateTime: scheduleDateTime,
        scheduleEndDateTime: scheduleEndDateTime,
        type: "businesschat",
        chat_type: "Event",
        token_id: localStorage.getItem("token"),
        googlePlacedId: localStorage.getItem("currentDialogId"),
        chat_message: {
          question: pollQuestion,
          answers: answers,
        },
      });
      this.setState({
        isOpen: false,
        isPollScheduling: false,
        inputFields: JSON.parse(JSON.stringify(initialOptions)),
        pollQuestion: "",
        validationOnPoll: "",
        scheduleTime: {
          date: new Date(),
          time: moment().add(1, "m"),
        },
        scheduleEndTime: {
          date: new Date(moment(storeData?.eventUserEvents?.eventsAbout?.EndDateTime).format("YYYY/MM/DD")),
          time: moment().add(1, "m"),
        },
      });
    }
  };

  isCurrentUser = (id) => {
    let storeData = storeFolder.getState();
    if (storeData.loginResponse.results.ProfileDetail.UserId == id) {
      return true;
    }
    return false;
  };

  render() {
    const { classes, messageData } = this.props;
    const {
      showPopUp,
      showGif,
      showStickers,
      textMessage,
      stickers,
    } = this.state;

    let currentScheduledMessageCount = localStorage.getItem('ScheduledChatCount')
    let storeData = storeFolder.getState();
    const timeZone = localStorage.getItem("CurrentTimeZone");
    const multimediaData = storeData.messageReducer.selectedMultimediaData;
    if (multimediaData && this.state.multimediaData !== multimediaData && !this.state.isScheduleMessageEdit) {
      this.setState({ multimediaData });
    }

    return (
      <React.Fragment>
        {this.schedulePollModal()}
        {this.scheduleModal()}
        {this.scheduleMessagesModal()}
        {this.deleteModal()}
        {showPopUp &&
          Object.keys(messageData.backUpChat).includes(
            messageData.selectedChat.dialog_id
          ) && (
            <div className="unread-pop-up" onClick={this.hidePopUp}>
              <Avatar className={classes.avatar}>
                <img
                  src={require(`images/Alphabets/${messageData.selectedChat.FullName.match(
                    /[a-z]/i
                  )[0].toUpperCase()}.png`).default}
                  height={65}
                  width={65}
                  alt="img"
                />
              </Avatar>
              <div className="unread-pop-up-wrapper">
                <p>{messageData.selectedChat.FullName}</p>
                <p>
                  {
                    JSON.parse(
                      messageData.chatHistory[
                        messageData.chatHistory.length - 1
                      ].message_json
                    ).text
                  }
                </p>
              </div>
            </div>
          )}
        {currentScheduledMessageCount > 0 && storeData.liveChatReducer.chatState !== "LOADING" && (
          <p className="scheduled-messages-box">
            <span>{currentScheduledMessageCount} {currentScheduledMessageCount == 1 ? "message" : "messages"} scheduled.</span>
            <span style={{ color: '#ca3dd9', marginBottom: '2px', cursor: 'pointer' }}
              onClick={async () => {
                const dialogId = localStorage.getItem("currentDialogId");
                await ws.json({
                  userId: localStorage.getItem("UserId"),
                  pageIndex: 1,
                  pageSize: 15,
                  dialogId: dialogId,
                  type: "getScheduledPendingChat",
                });
                this.setState({ isScheduledChatOpen: true, initialPageIndex: 1 })
              }}>
              {' '} See all scheduled messages
            </span>
          </p>
        )}
        <div className="footer">
          {multimediaData ? (
            <div className="chat-text-area"
              ref={this.divRef}
              tabIndex={0}
              onKeyDown={this.do_resize}
              style={{ outline: "none", height: "auto" }}
            >
              <div
                style={{
                  border: '1px solid #ca3dd9',
                  borderRadius: '8px',
                  padding: '10px',
                  marginBottom: '10px',
                  width: 'fit-content',
                  position: 'relative',
                  outline: "none"
                }}>
                <div style={{ position: "absolute", top: '5px', right: "5px" }}>
                  <IconButton onClick={() => {
                    this.setState({
                      textMessage: "",
                      editMessage:""
                    })
                    this.props.setSelectedMultimediaData(null)
                  }}
                    style={{
                      backgroundColor: 'white',
                      borderRadius: '50%',
                      padding: '2px'
                    }} >
                    <GrClose />
                  </IconButton>
                </div>
                <img
                  style={{
                    width:
                      multimediaData?.text ===
                        "[Sticker message]"
                        ? "70px"
                        : "70px",
                    height:
                      multimediaData?.text ===
                        "[Sticker message]"
                        ? "70px"
                        : "70px",
                  }}
                  className={
                    this.isCurrentUser(localStorage.getItem("UserId"))
                      ? "message-from-them-right"
                      : "message-from-them-left"
                  }
                  //*HERE
                  src={
                    multimediaData?.text ===
                      "[Sticker message]"
                      ? require(`images/Stickers/${multimediaData?.stickerId}`)?.default
                      : multimediaData?.imgUrl
                  }
                  alt="media"
                />
              </div>
            </div>
          ) : (
            <div className="chat-text-area" style={{ outline: "none" }}>
              <textarea
                // defaultValue={this.state.isScheduleMessageEdit ? this.state.editMessage : "" }
                disabled={showGif || showStickers}
                id="textMessage"
                placeholder="Type your message"
                onChange={this.onTextChange}
                value={this.state.isScheduleMessageEdit ? this.state.editMessage : textMessage}
                onKeyDown={this.do_resize}
                cols="20"
                rows="1"
              />
            </div>
          )}
          <div className="chat-emoji-send-area">
            {(textMessage.trim().length > 0 || storeData?.messageReducer?.selectedMultimediaData) ? (
              this.state.editMessage ? (<div>
                <div className="footer-send-btn">
                  <HtmlTooltip title={
                    <React.Fragment>
                      <p style={{ textAlign: "center" }}>Edit</p>
                      <p style={{ fontWeight: "normal", color: "gray", marginTop: "4px" }}>Press <span style={{ padding: "2px 4px", borderRadius: "4px", backgroundColor: "#404040", color: "white", marginLeft: "4px" }}>Return</span></p>
                    </React.Fragment>
                  } placement="top" arrow >
                    <div
                      className="footer-chat-btn-send"
                      onClick={() => {

                        this.sendEditMessage()
                      }}
                    >
                      <FaCheck size={22}
                      />
                    </div>
                  </HtmlTooltip>
                  <div style={{ width: "1px", height: "20px", backgroundColor: "white" }}></div>
                  <div
                  // onClick={() =>
                  //   this.setState({
                  //     isScheduling: true,
                  //     isOpen: false,
                  //     validationOnMessage: "",
                  //     validationOnPoll: "",
                  //     isValidEndDateTime: false,
                  //     scheduleTime: {
                  //       date: new Date(),
                  //       time: moment().add(1, "m"),
                  //     }
                  //   })
                  // }
                  >
                    <HtmlTooltip2
                      placement="bottom"
                      arrow
                      title={
                        <React.Fragment>
                          <p
                            style={{ textAlign: "center", margin: "5px 0px", cursor: "pointer" }}
                          >Cancel</p>
                        </React.Fragment>
                      }
                    >
                      <div
                        onClick={() => this.handleCancelEditScheduledChat()}
                      >
                        <RxCross2 size={22} className="footer-chat-btn-send" />
                      </div>
                    </HtmlTooltip2>
                  </div>
                </div>
              </div>) : (
                <div className="footer-send-btn">
                  <HtmlTooltip title={
                    <React.Fragment>
                      <p style={{ textAlign: "center" }}>Send Now</p>
                      <p style={{ fontWeight: "normal", color: "gray", marginTop: "4px" }}>Press <span style={{ padding: "2px 4px", borderRadius: "4px", backgroundColor: "#404040", color: "white", marginLeft: "4px" }}>Return</span></p>
                    </React.Fragment>
                  } placement="top" arrow >
                    <div
                      className="footer-chat-btn-send"
                      onClick={this.sendMessage}
                    >
                      <IoSend size={22} />
                    </div>
                  </HtmlTooltip>
                  <div style={{ width: "1px", height: "20px", backgroundColor: "white" }}></div>
                  <div
                    onClick={() =>
                      this.setState({
                        isScheduling: true,
                        isOpen: false,
                        validationOnMessage: "",
                        validationOnPoll: "",
                        isValidEndDateTime: false,
                        scheduleTime: {
                          date: new Date(),
                          time: moment().utc().tz(JSON.parse(timeZone)).add(1, 'm'),
                        }
                      })
                    }
                  >
                    <HtmlTooltip2
                      placement="bottom"
                      arrow
                      title={
                        <React.Fragment>
                          <p
                            style={{ textAlign: "center", margin: "5px 0px", cursor: "pointer" }}
                          >Schedule Message</p>
                        </React.Fragment>
                      }
                    >
                      <div>
                        <MdKeyboardArrowDown size={22} className="footer-chat-btn-send" />
                      </div>
                    </HtmlTooltip2>
                  </div>
                </div>
              )
            ) : (
              this.state.editMessage ? (
                <div>
                  <div className="footer-send-btn">
                    <HtmlTooltip title={
                      <React.Fragment>
                        <p style={{ textAlign: "center" }}>Edit</p>
                        <p style={{ fontWeight: "normal", color: "gray", marginTop: "4px" }}>Press <span style={{ padding: "2px 4px", borderRadius: "4px", backgroundColor: "#404040", color: "white", marginLeft: "4px" }}>Return</span></p>
                      </React.Fragment>
                    } placement="top" arrow >
                      <div
                        className="footer-chat-btn-send"
                        onClick={() => this.sendEditMessage()}
                      >
                        <FaCheck size={22} />
                      </div>
                    </HtmlTooltip>
                    <div style={{ width: "1px", height: "20px", backgroundColor: "white" }}></div>
                    <div
                    // onClick={() =>
                    //   this.setState({
                    //     isScheduling: true,
                    //     isOpen: false,
                    //     validationOnMessage: "",
                    //     validationOnPoll: "",
                    //     isValidEndDateTime: false,
                    //     scheduleTime: {
                    //       date: new Date(),
                    //       time: moment().add(1, "m"),
                    //     }
                    //   })
                    // }
                    >
                      <HtmlTooltip2
                        placement="bottom"
                        arrow
                        title={
                          <React.Fragment>
                            <p
                              style={{ textAlign: "center", margin: "5px 0px", cursor: "pointer" }}
                            >Cancel</p>
                          </React.Fragment>
                        }
                      >
                        <div
                          onClick={() => this.handleCancelEditScheduledChat()}
                        >
                          <RxCross2 size={22} className="footer-chat-btn-send" />
                        </div>
                      </HtmlTooltip2>
                    </div>
                  </div>
                </div>
              ) : (
                this.state.isScheduleMessageEdit ? (
                  <div className="footer-send-btn-disabled">
                  <HtmlTooltip title={
                    <React.Fragment>
                      <p style={{ textAlign: "center" }}>Send Now</p>
                      <p style={{ fontWeight: "normal", color: "gray", marginTop: "4px" }}>Press <span style={{ padding: "2px 4px", borderRadius: "4px", backgroundColor: "#404040", color: "white", marginLeft: "4px" }}>Return</span></p>
                    </React.Fragment>
                  } placement="top" arrow >
                    <div
                      className="footer-chat-btn-send"

                    >
                      <FaCheck size={22} />
                    </div>
                  </HtmlTooltip>
                  <div style={{ width: "1px", height: "20px", backgroundColor: "white" }}></div>
                  <div>
                    <HtmlTooltip2
                      placement="bottom"
                      arrow
                      title={
                        <React.Fragment>
                          <p
                            style={{ textAlign: "center", margin: "5px 0px", cursor: "pointer" }}
                          >Schedule Message</p>
                        </React.Fragment>
                      }
                    >
                      <div>
                        {/* <MdKeyboardArrowDown size={22} className="footer-chat-btn-send" /> */}
                        <RxCross2 size={22} className="footer-chat-btn-send" />
                      </div>
                    </HtmlTooltip2>
                  </div>
                </div>
                ) : (
                  <div className="footer-send-btn-disabled">
                  <HtmlTooltip title={
                    <React.Fragment>
                      <p style={{ textAlign: "center" }}>Send Now</p>
                      <p style={{ fontWeight: "normal", color: "gray", marginTop: "4px" }}>Press <span style={{ padding: "2px 4px", borderRadius: "4px", backgroundColor: "#404040", color: "white", marginLeft: "4px" }}>Return</span></p>
                    </React.Fragment>
                  } placement="top" arrow >
                    <div
                      className="footer-chat-btn-send"
                      // onClick={this.sendMessage}
                    >
                      <IoSend size={22} />
                    </div>
                  </HtmlTooltip>
                  <div style={{ width: "1px", height: "20px", backgroundColor: "white" }}></div>
                  <div
                    // onClick={() =>
                    //   this.setState({
                    //     isScheduling: true,
                    //     isOpen: false,
                    //     validationOnMessage: "",
                    //     validationOnPoll: "",
                    //     isValidEndDateTime: false,
                    //     scheduleTime: {
                    //       date: new Date(),
                    //       time: moment().utc().tz(JSON.parse(timeZone)).add(1, 'm'),
                    //     }
                    //   })
                    // }
                  >
                    <HtmlTooltip2
                      placement="bottom"
                      arrow
                      title={
                        <React.Fragment>
                          <p
                            style={{ textAlign: "center", margin: "5px 0px", cursor: "pointer" }}
                          >Schedule Message</p>
                        </React.Fragment>
                      }
                    >
                      <div>
                        <MdKeyboardArrowDown size={22} className="footer-chat-btn-send" />
                      </div>
                    </HtmlTooltip2>
                  </div>
                </div>
                )
              )
            )}
          </div>
        </div>
        <div className="imageWrapper">
          <img
            src={require("images/Message Icons/Icon_Gallery_Blue.png").default}
            style={{height:"42px",marginTop:"-2px",width:"auto"}}
            alt="img"
            height={45}
            onClick={() => this.refs.img.click()}
          />
          <input
            type="file"
            ref="img"
            multiple={false}
            style={{ display: "none" }}
            name="chatImage"
            title="Load File"
            onChange={(e) => {
              this.handleImageChange(e);
              e.target.value = null;
            }}
            accept="image/jpeg, image/png"
          />
          <img
            src={require("images/Message Icons/Icon_Poll_Blue.png").default}
            alt="img"
            style={{height:"40px",marginTop:"-2px",width:"auto",marginLeft:"-5px"}}
            height={45}
            onClick={() =>
              this.setState({
                isOpen: true,
                validationOnPoll: "",
                isValidEndDateTime: false
              })
            }
          />
            {/* <FaPoll color="#65ACF9" size={28} style={{ marginTop: "5px" }} />
          </div> */}
          <img
            src={require("images/Message Icons/Icon_Camera_Blue.png").default}
            alt="img"
            style={{height:"47px",marginTop:"-5px",width:"auto",marginLeft:"-5px"}}
            height={45}
            onClick={() => {
              this.setState({
                textMessage: ""
              })
              this.props.toggleCamera()
            }}
          />
          <img
            src={require("images/Message Icons/Icon_GIF_Blue.png").default}
            alt="img"
            height={45}
            style={{height:"45px",marginTop:"-4px",width:"auto",marginLeft:"-3px"}}
            onClick={() => {
              this.setState({
                textMessage: "",
              })
              this.props.onGifClick()
            }}
          />
          <img
            src={require("images/Message Icons/Icon_Purple_Soundmoji.png").default}
            alt="img"
            style={{height:"45px",marginTop:"-5px",width:"auto",marginLeft:"-5px"}}
            height={40}
            className="stickerEmoji"
            onClick={() => {
              this.setState({
                textMessage: ""
              })
              this.onStickerClick()
            }}
          />
          <div style={{ width: "max-content", paddingTop:"5px" }}>
            {ToolTip(
              "",
              "Schedule messages, polls, photos and GIFs ahead of time and engage live with your attendees during your event"
            )}
          </div>
        </div>

        {showStickers && <div className="sticker-wrapper">{stickers}</div>}
      </React.Fragment >
    );
  }
}

const mapStateToProps = (state) => {
  return {
    messageData: state.messageReducer,
    chatHistory: state.messageReducer.chatHistory,
    user: state.loginResponse.results,
  };
};

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getDetailGIF,
      error,
      hidePopUp,
      setSelectedMultimediaData,
      clearDetailedGif
    },
    dispatch
  );
};

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'black',
    color: 'white',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(14),
    border: '1px solid black',
  },
}));
const HtmlTooltip2 = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'black',
    color: 'white',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(14),
    border: '1px solid black',
  },
}));

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(withStyles(styles)(PollsFooter));
