import React, { useRef, useState } from "react";
import * as constants from "Constants";
import GooglePlaces from "./GooglePlacesV2";
import styles from "./PlacesV2.module.css";
const AutoCompleteV2 = ({ GetEventData }) => {
  return (
    <div className={styles.location_search}>
      <h1
        style={{
          margin: 0,
          fontSize: 35,
          fontWeight: "bold",
          color: "#000000",
        }}
      >
        {constants.Text.location}
      </h1>
      <div style={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
        <GooglePlaces
          {...{ styles, GetEventData }}
        />
      </div>
    </div>
  );
};

export default AutoCompleteV2;
