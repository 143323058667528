import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getUnreadCount,
  unreadCountToZero,
  emptyBackupChat,
  emptyLiveChat,
} from "../../../Actions/messagesAction";
import FavoriteImage from "images/Messages.jpg";
import RouteHeader from "../RouteHeader/routeHeader";
import Card from "../../../Helper/CardWrapper/cardWrapper";
import { withStyles } from "@material-ui/core/styles";
import ContentGrid from "../../../Helper/gridWrapper/gridWrapper";
import { ws } from "../../../App";
import AppView from "./AppView";
import WebView from "./WebView";
import Alert from "../../../Helper/alert/errorAlert";
import AlertComponent from "../../../Helper/ErrorComponent/errorComponent";
import "./messages.css";
import Navbar from "Components/UBMeEvents/Components_UBMeEvents/Navbar/Navbar";
import Footer from "Components/UBMeEvents/Components_UBMeEvents/Footer/Footer";
import { Redirect } from "react-router-dom";
import { routeName } from "Service/env";

const styles = {
  rows: {
    display: "flex",
    justifyContent: "center",
    margin: 10,
  },
  row: {
    height: 0,
  },
  avatar: {
    margin: 10,
    height: 60,
    width: 65,
    fontSize: 40,
  },
  button: {
    fontSize: "22px",
    fontWeight: "600",
  },
};

class Messages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      connected: false,
      showInfo: false,
      userId: Number(localStorage.getItem("UserId")),
      recentChatIndex: 1,
      showAlert: false,
      alertMessage: "",
    };
  }

  componentDidMount() {
    let { messageData, getUnreadCount } = this.props;
    if (messageData && messageData.connection) {
      if (!messageData.recentChat) {
        this.getRecentChat();
      } else {
        messageData.selectedChat &&
          this.props.unreadCountToZero(messageData.selectedChat.dialog_id);
      }
    }
    setInterval(() => {
      if (messageData.connection) {
        ws.json({
          type: "ping",
        });
      }
    }, 100000);
    getUnreadCount();
  }

  componentWillUnmount() {
    this.props.emptyBackupChat();
    this.props.emptyLiveChat();

    let data = this.props.messageData;
    if (data && data.selectedChat) {
      data.selectedChat = [];
    }
  }

  updateState = (state, value) => {
    this.setState({
      [state]: value,
    });
  };

  getRecentChat = () => {
    if (this.state.messageData && this.state.messageData.recentChat.length === 0 && this.state.userId) {
      ws.json({
        user_id: this.state.userId,
        type: "recentchat",
        count: 20,
        index: 1,
      });
    }

    this.setState({
      recentChatIndex: 2,
    });
  };

  connection = () => {
    let { messageData } = this.props;

    if (messageData.connection) {
      if (!messageData.recentChat) {
        this.getRecentChat();
      }
      this.setState({
        connected: true,
      });
    }
  };

  onCLoseImage = () => {
    document.getElementById("myModal").style.display = "none";
  };

  onClick = () => {
    this.setState({ showInfo: false });
  };

  toggleAlert = (msg) => {
    this.setState({
      showAlert: !this.state.showAlert,
      alertMessage: msg,
    });
  };

  messagePanelStyles = () => {
    return {
      paddingBottom: "14px",
      // height: 'calc(100vh - 167px)'
    };
  };

  componentDidMount() {
    // !this.state.connected && this.connection();
  }

  render() {
    const { user, error } = this.props;
    const { showAlert, alertMessage } = this.state;

    return (
      <React.Fragment>
        {localStorage.getItem("UserId") === null ? (
          <Redirect to={`${routeName}`} />
        ) : null}
        {/* {localStorage.getItem("UserId") === null ? <Redirect to={`${routeName}`} /> : null} */}
        <Navbar />
        <div className="">
          <img
            style={{ width: "100%", height: "100%" }}
            src={FavoriteImage}
                alt=""
          />
        </div>
        <div className="message_container">
          {showAlert && (
            <AlertComponent msg={alertMessage} clickMethod={this.toggleAlert} />
          )}
          {error && <Alert />}
          <div style={{ height: "auto" }} className="container-div">
            <ContentGrid
              content={
                <Card
                  id="webViewMessage"
                  CardContentProps={{
                    style: this.messagePanelStyles(),
                  }}
                  content={
                    <WebView
                      userId={this.state.userId}
                      updateState={this.updateState}
                      toggleAlert={this.toggleAlert}
                    />
                  }
                />
              }
              appView={
                <Card
                  content={
                    <AppView
                      userId={this.state.userId}
                      recentChatIndex={this.state.recentChatIndex}
                      updateState={this.updateState}
                    />
                  }
                  CardContentProps={{ className: "appViewCardContent" }}
                />
              }
            />
            <div id="myModal" className="modal">
              <span className="close custom-close" onClick={this.onCLoseImage}>
                &times;
              </span>
              <img className="modal-content" id="zoomImage" alt="img" />
            </div>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    messageData: state.messageReducer,
    user: state.loginResponse.results,
    error: state.messageReducer.error,
  };
};
const matchDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getUnreadCount,
      unreadCountToZero,
      emptyBackupChat,
      emptyLiveChat,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(withStyles(styles)(Messages));
