import React, {
  useEffect,
  useCallback,
  useState,
  useLayoutEffect,
} from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import Contact from "./Components/Contact";
import EmailInvitationView from "./Components/EmailInvitationView";
import Invitation from "./Components/Invitation";
import Subject from "./Components/Subject";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import CloseIcon from "@material-ui/icons/Close";
import { Box } from "@material-ui/core";
import Time from "./Components/Time";
import Confetti from "images/confetti.png";
import * as constants from "Constants";
import { MdEdit } from "react-icons/md";
import {
  Modal,
  Tab,
  Tabs,
  Grid,
  Button,
  Tooltip,
  IconButton,
  withStyles,
} from "@material-ui/core";
import "./emailinvitation.css";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import ImageUpload from "./Components/ImageUpload";
import XLSX from "xlsx";
import {
  EditorState,
  convertToRaw,
  convertFromHTML,
  ContentState,
} from "draft-js";

import { Info } from "@material-ui/icons";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { BsInstagram, BsTwitter } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";
import { IoCloseOutline } from "react-icons/io5";
import { axiosE } from "../../../../Service/service";
import axios from "axios";
import { handlbars, HandlBars } from "./Components/handlbars";
import Handlebars, { log } from "handlebars";
import ScheduleEmail from "./Components/ScheduleEmail";
import { connect } from "react-redux";
import moment from "moment";
import { Link, Redirect } from "react-router-dom";
import { eventApis } from "Constants";
import { routeName } from "Service/env";
import { history } from "store";
import { checkIfEventExists, validateEmail } from "Helper/Validations";
import CreateEventErrorMessage from "../createEventErrorMessage";
import { UpdateEventDetailsByUser } from "Actions/eventActions";
import ShowErrorToast from "./Components/ShowErrorToast";
import CurrentPlanCard from "./Components/CurrentPlanCard";
import { keys } from "lodash";
import { EmailHeaderValidator } from "./Components/EmailHeaderValidator";
import { Grammarly, GrammarlyEditorPlugin } from "@grammarly/editor-sdk-react";
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(2),
      width: "100%",
      fontSize: 40,
      color: "red",
    },
  },
  feild: {
    fontSize: 40,
    color: "red",
  },
}));
const EmailInvitation = ({
  eventUserEvents,
  eventPublish,
  dispatch,
  UpdateEventDetailsByUser,
}) => {
  const [EditScheduleEmailData, setEditScheduleEmailData] = useState("");

  const [mainEventId, setMainEventId] = useState(
    eventUserEvents?.eventsAbout?.EventId
  );
  const [dataArray, setdataArray] = useState([]);

  const [isOpen, setIsOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [base64img, setBase64img] = useState("");
  const [baseImgUrl, setBaseImgUrl] = useState("");
  const [editorHtml1, setEditorHtml1] = useState("");
  const [UserId] = useState(localStorage.getItem("UserId"));
  const [newTime, setNewTime] = useState("");
  const [scheduleTimeType, setScheduleTimeType] = useState("Immediately");
  const [eventData, setEventData] = useState([]);
  // const [organisationName, setOrganisationName] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [organizer, setOrganizer] = useState("");
  const [city, setCity] = useState("");
  const [region, setRegion] = useState("");
  const [zip, setZip] = useState("");
  const [country, setCountry] = useState("");
  const [facebookLink, setFacebookLink] = useState("");
  const [instagramLink, setInstagramLink] = useState("");
  const [twitterLink, setTwitterLink] = useState("");
  const [barValue, setBarValue] = useState(0);
  const [uploadedContacts, setUploadedContacts] = useState("");
  const [isFacebook, setIsFacebook] = useState(false);
  const [isInstagram, setIsInstagram] = useState(false);
  const [isTwitter, setIsTwitter] = useState(false);
  const [scheduledEmailData, setScheduledEmailData] = useState([]);

  const [eventIds, setEventIds] = useState([]);
  const [isEdit, setIsEdit] = useState("");
  const [saveUpdateDetails, setSaveUpdateDetails] = useState(false);
  const [eventTitle, setEventTitle] = useState("");
  const [fromName, setfromName] = useState("");
  const [totaUploadedEmail, setTotalUploadedEmail] = useState(0);
  const [contactIds, setContactIds] = useState([]);
  const [imageError, setImageError] = useState({ show: false, message: "" });
  const [isEmailSend, setisEmailSend] = useState(false);
  const [scheduleTimeValue, setScheduleTimeValue] = useState("");
  const [duplicateEmails, setDuplicateEmails] = useState(0);
  const [invalidEmails, setInvalidEmails] = useState(0);
  const [eventDetailsByUser, setEventDetailsByUser] = useState("");
  const [unsubscribeEmails, setUnsubscribeEmails] = useState(0);
  const [limits, setLimits] = useState("");
  const [daysBefore, setDaysBefore] = useState("");
  const [eventDetails, setEventDetails] = useState("");

  const isRecurringStr = localStorage.getItem("isRecurring");
  const isRecurring = isRecurringStr === "true" ? true : false;

  const convertTimeUtcToLocal = (
    time,
    TimeZone = eventUserEvents?.eventsAbout?.TimeZone
  ) => {
    const UtcToLocal = moment.utc(time).local().format("MM/DD/YYYY hh:mm:ss A");
    var Time = moment(UtcToLocal, "MM/DD/YYYY hh:mm:ss A");

    const convertedTime = Time.tz(TimeZone);
    return moment(convertedTime);
  };

  const ConvertedStartDateTime = (Time) => {
    return `${moment(Time).format("MMM DD, YYYY")} at ${moment(Time).format(
      "hh:mm A"
    )}`;
  };

  const DefaultTextHeader = `
  <h2 style="font-size:30px !important; font-weight: 600; text-align:center; color: rgb(94, 94, 94);">Let's get together</h2>
  <p style="color: #888; text-align: center; font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 14px; font-weight: 500;">
    We're hosting a new event, and we'd love to see you there. Join us for ${
      eventDetails?.Title
    }, ${ConvertedStartDateTime(
    convertTimeUtcToLocal(eventDetails?.StartDateTime)
  )}
  </p>
  <p style="color: #888; text-align: center; font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 14px; font-weight: 500;">Register soon because space is limited.</p>
  <p style="color: #888; text-align: center; font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 14px; font-weight: 500;">We hope you're able to join us!</p>   
`;

  const html =
    EditScheduleEmailData !== ""
      ? EditScheduleEmailData?.Header
      : eventDetailsByUser !== ""
      ? eventDetailsByUser?.EmailHeader
      : DefaultTextHeader;
  // console.log("html",html);
  const blocksFromHTML = convertFromHTML(html);
  const contentState = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap
  );

  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(contentState)
  );

  const saveFormDetail = (face, insta, twit) => {
    face ? setIsFacebook(face) : setIsFacebook("");
    insta ? setIsInstagram(insta) : setIsInstagram("");
    twit ? setIsTwitter(twit) : setIsTwitter("");
  };

  // useEffect(() => {
  //   if (checkIfEventExists(history, eventUserEvents?.eventsAbout?.EventId)) {
  //     axiosE
  //       .get(eventApis.getEventStatus + eventUserEvents?.eventsAbout?.EventId)
  //       .then(({ data }) => {
  //         const { Status, BillingAmount, AccessToken } = JSON.parse(
  //           data.results.Data
  //         );

  //         if (Status !== "Published") {
  //           history.push(`${routeName}/Dashboard/Publish`);
  //         }
  //       })
  //       .catch((e) => {});
  //   }
  // }, []);

  useEffect(() => {
    if (EditScheduleEmailData !== "") {
      setEditorState(EditorState.createWithContent(contentState));
    }
    if (eventDetails !== "") {
      setEditorState(EditorState.createWithContent(contentState));
    }
  }, [EditScheduleEmailData, eventDetails]);

  function toggleModal() {
    setIsOpen(!isOpen);
  }
  const classes = useStyles();

  const handleChange = (e, event) => {
    // setisChecked(e.target.checked);

    if (!dataArray.some(({ EventId }) => EventId === event.EventId)) {
      setdataArray([...dataArray, event]);
    } else {
      let freshArray = dataArray.filter(
        ({ EventId }) => EventId !== event.EventId
      );
      setdataArray([...freshArray]);
    }
  };

  useEffect(() => {
    axiosE
      .get(`GetAllPublishedEventsByOrganizer/${UserId}/0`)
      .then(({ data }) => {
        if (data.results.ResponseCode) {
          let AllPublishedEventsByUser = [...data.results.EventList];
          setEventData(
            AllPublishedEventsByUser.filter((e) => e.EventId !== mainEventId)
          );
        }
      })
      .catch((err) => console.log(err));
  }, []);

  // const SetCoverPhoto = (event) => {
  //   if (event.target.files) {
  //     setCoverImage(URL.createObjectURL(event.target.files[0]));
  //     console.log(URL.createObjectURL(event.target.files[0]));
  //     let file = event.target.files[0];
  //   }
  // };
  const styleMap = {
    STRIKETHROUGH: {
      textDecoration: "line-through",
    },
    textAlign: "center",
  };

  const getFileBase64 = (file, callback) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    // Since FileReader is asynchronous,
    // we need to pass data back.
    reader.onload = () => callback(reader.result);
    // TODO: catch an error
    reader.onerror = (error) => {};
  };

  const imageUploadCallback = (file) => {
    if (file) {
      return new Promise((resolve, reject) =>
        getFileBase64(file, (data) => resolve({ data: { link: data } }))
      );
    }
  };

  const largeTextField = (title, editorState, onEditorStateChange) => {
    const mandatoryOrNot = title === "Parking" ? false : true;

    return (
      <React.Fragment>
        <GrammarlyEditorPlugin clientId="client_FdjdCy5cG4i2Wxi74peEG2">
          <div className="centered-editor">
            <Editor
              customStyleMap={styleMap}
              editorState={editorState}
              editorClassName="editor-class-about"
              toolbarClassName="toolbar-class-about"
              wrapperClassName="wrapper-class-about"
              onEditorStateChange={onEditorStateChange}
              toolbar={{
                textAlign: { inDropdown: true },
                inline: { inDropdown: true },
                list: { inDropdown: true },
                link: { inDropdown: true },
                history: { inDropdown: true },
                image: {
                  previewImage: true,
                  urlEnabled: true,
                  uploadEnabled: true,
                  uploadCallback: (file) => imageUploadCallback(file),
                  inputAccept:
                    "image/gif,image/jpeg,image/jpg,image/png,image/svg",
                  defaultSize: {
                    height: "auto",
                    width: "100%",
                  },
                },
              }}
              handlePastedText={() => imageUploadCallback()}
              // handlePastedText={() => false}
            />
          </div>
        </GrammarlyEditorPlugin>
      </React.Fragment>
    );
  };

  const replcaeEmptyTagwithBreakForEditor = (html) => {
    const newHtml = html
      ?.replaceAll("<p></p>", "<h6></h6>")
      .replaceAll("<h1></h1>", "<h6></h6>")
      .replaceAll("<h2></h2>", "<h6></h4>")
      .replaceAll("<h3></h3>", "<h6></h6>")
      .replaceAll("<h4></h4>", "<h6></h6>")
      .replaceAll("<h5></h5>", "<h6></h6>")
      .replaceAll("<h6></h6>", "<h6></h6>")
      .replaceAll("<pre></pre>", "<h6></h6>")
      .replaceAll("<blockquote></blockquote>", "<h6></h6>");

    return newHtml;
  };

  const onEditorStateChange = (editorState) => {
    const editorHtml = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );

    setEditorHtml1(replcaeEmptyTagwithBreakForEditor(editorHtml));
    setEditorState(editorState);
  };

  // Add Constacts
  const [fileName, setFileName] = useState("");
  const [data, setData] = useState([]);
  const [senddata, setSendData] = useState("");
  const [error, setError] = useState("");
  const [batchLimit, setBatchLimit] = useState(0);
  const [totalLimit, setTotalLimit] = useState(2000);
  // console.log("batchLimit", batchLimit);
  useEffect(() => {
    fetchData();
    resendData();
  }, []);

  const resendData = async () => {
    const file = localStorage.getItem("file_name");
    const value = JSON.parse(localStorage.getItem("mydata"));
    setTimeout(() => {
      if (value !== null && file !== {}) {
        if (value.length > 0) {
          let elements = [...value];
          sendData(elements, file);
          setFileName(file);
        }
      }
    }, 5000);
  };

  const CheckIsLinkValid = (URL) => {
    if (URL) {
      return URL.substring(0, 8) === "https://" ||
        URL.substring(0, 7) === "http://"
        ? `${URL}`
        : `https://${URL}`;
    } else {
      return null;
    }
  };

  const replcaeEmptyTagwithBreak = (html) => {
    return html
      ?.replaceAll("<p></p>", "<br>")
      .replaceAll("<h1></h1>", "<br>")
      .replaceAll("<h2></h2>", "<br>")
      .replaceAll("<h3></h3>", "<br>")
      .replaceAll("<h4></h4>", "<br>")
      .replaceAll("<h5></h5>", "<br>")
      .replaceAll("<h6></h6>", "<br>")
      .replaceAll("<pre></pre>", "<br>")
      .replaceAll("<blockquote></blockquote>", "<br>");
  };
  const setBase64imgFn = (base64) => {
    // setBaseImgUrl("")
    setBase64img(base64);
  };

  const Data = {
    Title: dataArray && dataArray[0]?.Title,
    StartDateTime:
      dataArray &&
      ConvertedStartDateTime(
        convertTimeUtcToLocal(dataArray[0]?.StartDateTime)
      ),
    editorHtml1: replcaeEmptyTagwithBreak(editorHtml1),
    headerImg: baseImgUrl,
    data: dataArray.map((item) => {
      let emailInviteDataObj = Object.assign({}, item);
      emailInviteDataObj["RecurringTicket"] = item.RecurringTicket = item.RecurringTicket === "true" || item.RecurringTicket === true;
      emailInviteDataObj["StartDateTime"] = ConvertedStartDateTime(
        convertTimeUtcToLocal(item.StartDateTime)
      );
      return emailInviteDataObj;
    }),
    mainEventId,
    UserId,
    facebook: CheckIsLinkValid(facebookLink),
    instagram: CheckIsLinkValid(instagramLink),
    twitter: CheckIsLinkValid(twitterLink),
    address1,
    organizer,
    address2,
    city,
    region,
    country,
    zip,
    routeName,
    baseUrl: constants.URLs.baseUrl,
    hostName: constants.URLs.hostName,
    isRecurring
  };
  const template = Handlebars.compile(handlbars);
  const emailTemplate = template(Data);
  const GetAllEmailQueueInvitation = () => {
    axiosE
      .get(`GetAllEmailQueueInvitationByUserId/${UserId}`)
      .then(({ data }) => {
        if (data.results.ResponseCode) {
          setScheduledEmailData(data.results.ListInvitation);
        }
      })
      .catch((err) => console.log(err));
  };
  const updateEmailToPromote = async () => {
    const updateData = {
      EventId: mainEventId,
      HeaderImage: base64img.split(",")[1],
      Header: editorHtml1 === "" ? DefaultTextHeader : editorHtml1,
      Footer: JSON.stringify({
        Add1: address1,
        Add2: address2,
        City: city,
        Region: region,
        Country: country,
        PostalCode: zip,
        Organizer: organizer,
      }),
      SocialMedia: JSON.stringify({
        instagramLink,
        facebookLink,
        twitterLink,
      }),
    };

    try {
      const { data } = await axiosE.post(
        "/Event/UpdateInvitationData",
        updateData
      );
      // console.log("data", JSON.parse(data.results.Data).HeaderImage);
      if (data.results.ResponseCode) {
        if (!isEdit) {
          setEventDetailsByUser(JSON.parse(data.results.Data));
          // dispatch(UpdateEventDetailsByUser(JSON.parse(data.results.Data)));
        }
        toggleModal();
        // handleSubmitEmail();
      }
    } catch (errorMessage) {}
  };

  const handleSubmitEmail = async () => {
    let EventIds = [];
    dataArray.map(({ EventId }) => {
      EventIds.push(EventId);
    });
    const payload = {
      UserId,
      Subject: eventTitle,
      FromName: fromName,
      MainEventId: mainEventId,
      EventIds: EventIds?.join(", "),
      SubscriberFileIds: contactIds.join(", "),
      ScheduleDateTime: newTime,
      Body: emailTemplate,
    };

    const { MainEventId, ...rest } = payload;

    if (isEdit) {
      try {
        const { data } = await axiosE.post(
          "/QueueEventInvitationEmail/Update",
          {
            ...rest,
            QueueId: isEdit,
          }
        );
        if (data.results.ResponseCode) {
          setIsEdit("");
          setSaveUpdateDetails(true);
          GetAllEmailQueueInvitation();
          setisEmailSend(true);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axiosE.post(
          "/QueueEventInvitationEmail/add",
          payload
        );

        if (data.results.ResponseCode) {
          GetAllEmailQueueInvitation();
          setSaveUpdateDetails(true);
          setisEmailSend(true);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const fetchData = async () => {
    const value = localStorage.getItem("mydata");
    if (value !== null) {
      setData(JSON.parse(value));
    }
  };

  useEffect(() => {
    if (isEdit && EditScheduleEmailData) {
      const address =
        EditScheduleEmailData?.Footer !== ""
          ? JSON.parse(EditScheduleEmailData?.Footer)
          : "";
      const socialMedia =
        EditScheduleEmailData?.SocialMedia !== ""
          ? JSON.parse(EditScheduleEmailData?.SocialMedia)
          : "";
      setAddress1(address?.Add2);
      setAddress2(address?.Add2);
      setOrganizer(address?.Organizer);
      setfromName(address?.Organizer);
      setCity(address?.City);
      setZip(address?.PostalCode);
      setCountry(address?.Country);
      setRegion(address?.Region);
      setFacebookLink(socialMedia?.facebookLink);
      setTwitterLink(socialMedia?.twitterLink);
      setInstagramLink(socialMedia?.instagramLink);
      // setBase64img(EditScheduleEmailData?.HeaderImage);
      setBaseImgUrl(EditScheduleEmailData?.HeaderImage);
      setEditorHtml1(EditScheduleEmailData?.Header);
      setEventTitle(EditScheduleEmailData?.Subject);
      // setEventDetails(dataArray[0]);
    }
  }, [EditScheduleEmailData]);
  // console.log("eventUserEvents?.eventsAbout", eventUserEvents);
  useEffect(() => {
    setAddress1(
      eventDetailsByUser !== ""
        ? JSON.parse(eventDetailsByUser?.EmailFooter)?.Add1
        : eventUserEvents?.eventsAbout?.Add2
    );
    setAddress2(
      eventDetailsByUser !== ""
        ? JSON.parse(eventDetailsByUser?.EmailFooter)?.Add2
        : ""
    );
    setCity(
      eventDetailsByUser !== ""
        ? JSON.parse(eventDetailsByUser?.EmailFooter)?.City
        : eventUserEvents?.eventsAbout?.City
    );
    setZip(
      eventDetailsByUser !== ""
        ? JSON.parse(eventDetailsByUser?.EmailFooter)?.PostalCode
        : eventUserEvents?.eventsAbout?.PostalCode
    );
    setCountry(
      eventDetailsByUser !== ""
        ? JSON.parse(eventDetailsByUser?.EmailFooter)?.Country
        : eventUserEvents?.eventsAbout?.Country
    );
    setRegion(
      eventDetailsByUser !== ""
        ? JSON.parse(eventDetailsByUser?.EmailFooter)?.Region
        : eventUserEvents?.eventsAbout?.Region
    );
    if (
      !dataArray.some(
        ({ EventId }) => EventId === eventUserEvents?.eventsAbout?.EventId
      )
    ) {
      setdataArray([...dataArray, eventUserEvents?.eventsAbout]);
    } else {
      setdataArray([...dataArray]);
    }
    // setdataArray([...dataArray, eventUserEvents?.eventsAbout]);
    setEventTitle(eventUserEvents?.eventsAbout?.Title);
    setOrganizer(
      eventDetailsByUser !== ""
        ? JSON.parse(eventDetailsByUser?.EmailFooter)?.Organizer
        : localStorage?.getItem("userFullName")
    );
    setfromName(
      fromName === "" ? localStorage?.getItem("userFullName") : fromName
    );
    // setOrganizer(localStorage?.getItem("userFullName"));
    GetAllEmailQueueInvitation();
    if (eventDetailsByUser !== "") {
      setFacebookLink(
        eventDetailsByUser !== ""
          ? JSON.parse(eventDetailsByUser?.SocialMedia)?.facebookLink
          : null
      );
      setTwitterLink(
        eventDetailsByUser !== ""
          ? JSON.parse(eventDetailsByUser?.SocialMedia)?.twitterLink
          : null
      );
      setInstagramLink(
        eventDetailsByUser !== ""
          ? JSON.parse(eventDetailsByUser?.SocialMedia)?.instagramLink
          : null
      );
      setBaseImgUrl(
        eventDetailsByUser !== "" ? eventDetailsByUser?.HeaderImage : ""
      );
      // setBase64img(
      //   eventDetailsByUser !== "" ? eventDetailsByUser?.HeaderImage : ""
      // );
      setEditorHtml1(
        eventDetailsByUser !== "" ? eventDetailsByUser?.EmailHeader : ""
      );
    }

    setEventDetails(eventUserEvents?.eventsAbout);
    getUserplandetails();
  }, [eventUserEvents, eventDetailsByUser]);

  const getUserplandetails = async () => {
    try {
      axiosE
        .get(`GetEmailQuota/${UserId}`)
        .then(({ data }) => {
          setLimits(JSON.parse(data.results.Data));
        })
        .catch(() => {});
    } catch (error) {}
  };

  //upload file
  const handleFileChange = useCallback(
    (e) => {
      setInvalidEmails(0);
      setUnsubscribeEmails(0);
      // console.log("totalLimit - batchLimit", batchLimit, totalLimit);
      setUploadedContacts("");
      setTotalUploadedEmail(0);
      setDuplicateEmails(0);
      setBarValue(0);
      const file = e.target.files[0];
      localStorage.setItem("file_name", file.name);
      // setFileName(file.name);
      var reader = new FileReader();
      const isBinary = !!reader.readAsBinaryString;
      reader.onload = ({ target: { result } }) => {
        var workbook = XLSX.read(result, {
          type: isBinary ? "binary" : "array",
        });
        var firstSheet = workbook.Sheets[workbook.SheetNames[0]];
        const elements = XLSX.utils.sheet_to_json(firstSheet, {
          blankrows: false,
          header: "A",
          raw: true,
          rawNumbers: true,
        });
        if (
          elements.length <=
          Number(limits.EmailSubscribersLimit) - Number(limits.TotalSubscribers)
        ) {
          let emailPattern =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          // To get unkown key that contains email
          function getKeyByValue(object) {
            return Object.keys(object).find((key) =>
              emailPattern.test(String(object[key]).toLowerCase())
            );
          }

          const emailFormatError = (email) => {
            email = email && email?.trim();
            if (emailPattern.test(String(email).toLowerCase())) return true;
            return false;
          };

          var error = {};
          try {
            const promises = elements.map((value) => {
              const keyName = getKeyByValue(value);
              // validating email
              if (emailFormatError(value[keyName])) {
                // setError("Please check your file and try again");
                // setData([]);
                // localStorage.setItem("mydata", null);
                // setTimeout(() => {
                //   setError("");
                // }, 3000);
                // throw error;
              }
              return keyName;
            });

            Promise.all(promises).then(function (results) {
              let duplicateElements = results?.filter(
                (item, index) => results.indexOf(item) !== index
              );
              duplicateElements =
                duplicateElements.length > 0 ? duplicateElements : results;

              if (results[0] !== "EmailAddress") {
                elements.map((item) => {
                  item.EmailAddress = item[duplicateElements[0]];
                  delete item[duplicateElements[0]];
                });
              }

              // console.log("EmailAddress", elements);
              const emails = elements.filter((email) =>
                emailFormatError(email.EmailAddress)
              );

              localStorage.setItem("mydata", JSON.stringify(emails));
              setData(emails);
              let invalidEmailsLength = elements.length - emails.length;
              setInvalidEmails(
                elements.some((id) =>
                  EmailHeaderValidator(id.EmailAddress?.toLowerCase())
                )
                  ? invalidEmailsLength > 1
                    ? invalidEmailsLength - 1
                    : 0
                  : invalidEmailsLength
              );
              if (emails?.length > 0) {
                sendData(emails, file.name);
              } else {
                setError("file-error");
                setTimeout(() => {
                  setError("");
                }, 5000);
              }
            });
          } catch (e) {
            if (e !== error) throw e;
          }
        } else {
          setError(
            Number(limits.EmailSubscribersLimit) -
              Number(limits.TotalSubscribers)
          );
          setTimeout(() => {
            setError("");
          }, 5000);
        }
      };
      if (isBinary) reader.readAsBinaryString(file);
      else reader.readAsArrayBuffer(file);
    },
    [batchLimit, limits]
  );

  const UploadCsvFile = async (UploadData, elements) => {
    try {
      const response = await axiosE.post(
        constants.API.PersistSubscribers,
        UploadData
      );

      const toatalEmails =
        Number(response.data.results.TotalEmailInserted) +
        Number(response.data.results.DuplicatesFound) +
        Number(response.data.results.TotalUnSubscribers);
      const emailValue =
        Number(response.data.results.DuplicatesFound) === 0 &&
        Number(response.data.results.TotalUnSubscribers) === 0
          ? Number(response.data.results.TotalEmailInserted)
          : toatalEmails;
      let progressBar = (emailValue / elements.length) * 100;
      setBarValue(progressBar);
      setDuplicateEmails(
        (prev) => prev + Number(response.data.results.DuplicatesFound)
      );
      setUnsubscribeEmails(
        (prev) => prev + Number(response.data.results.TotalUnSubscribers)
      );
      setTotalUploadedEmail(
        (prev) => prev + Number(response.data.results.TotalEmailInserted)
      );
      setBatchLimit(
        (prev) => prev + Number(response.data.results.TotalEmailInserted)
      );
      setUploadedContacts(response.data.results);
      localStorage.setItem("mydata", null);
      fetchData();
      return response.data.results;
    } catch (e) {
      return console.log(e);
    }
  };

  const UplaodCsvFileInloop = async (
    UploadData,
    newData,
    sendData,
    elements
  ) => {
    try {
      const response = await axiosE.post("/PersistSubscribers", UploadData);
      const toatalEmails =
        Number(response.data.results.TotalEmailInserted) +
        Number(response.data.results.DuplicatesFound) +
        Number(response.data.results.TotalUnSubscribers);
      const emailValue =
        Number(response.data.results.DuplicatesFound) === 0 &&
        Number(response.data.results.TotalUnSubscribers) === 0
          ? Number(response.data.results.TotalEmailInserted)
          : toatalEmails;
      let progressBar = (emailValue / elements.length) * 100;
      setBarValue((prev) => prev + progressBar);
      setDuplicateEmails(
        (prev) => prev + Number(response.data.results.DuplicatesFound)
      );
      setUnsubscribeEmails(
        (prev) => prev + Number(response.data.results.TotalUnSubscribers)
      );
      setTotalUploadedEmail(
        (prev_1) => prev_1 + Number(response.data.results.TotalEmailInserted)
      );
      setBatchLimit(
        (prev) => prev + Number(response.data.results.TotalEmailInserted)
      );
      setUploadedContacts(response.data.results);
      setData(newData);
      setSendData(sendData);
      localStorage.setItem("mydata", JSON.stringify(newData));
      fetchData();
      return response.data.results;
    } catch (e) {
      return console.log(e);
    }
  };

  const sendData = async (elements, file) => {
    let uploadedFileData = [...elements];

    let UploadData = {
      SubscriberData: {
        SubscribersFile: {
          FileId: "",
          UserId: localStorage.getItem("UserId"),
          FileName: file,
        },
        Subscriber: uploadedFileData,
      },
    };
    if (uploadedFileData.length < 100) {
      // if number of contacts less then 100
      UploadCsvFile(UploadData, elements);
    } else {
      let fileData = [...elements];
      let num = 0;
      if (fileData.length % 100 !== 0) {
        num = Math.floor(fileData.length / 100) + 1;
      } else {
        num = fileData.length / 100;
      }

      try {
        const newData = uploadedFileData.splice(100);
        const Data = uploadedFileData.slice(0, 100);
        uploadedFileData = newData;
        UploadData.SubscriberData.Subscriber = Data;
        UplaodCsvFileInloop(UploadData, newData, Data, elements).then(
          (data) => {
            let FileId = data.FileId;
            UploadData.SubscriberData.Subscriber.FileId = FileId;
            let interval = 600; // how much time should the delay between two iterations be (in milliseconds)?
            let promise = Promise.resolve();
            [...Array(num - 1)].forEach((el) => {
              promise = promise.then(function () {
                if (uploadedFileData.length >= 100) {
                  const newData = uploadedFileData.splice(100);
                  const Data = uploadedFileData.slice(0, 100);
                  uploadedFileData = newData;
                  UploadData.SubscriberData.Subscriber = Data;
                  UplaodCsvFileInloop(
                    {
                      ...UploadData,
                      SubscriberData: {
                        ...UploadData.SubscriberData,

                        SubscribersFile: {
                          ...UploadData.SubscriberData.SubscribersFile,
                          FileId: FileId,
                        },
                      },
                    },
                    newData,
                    Data,
                    elements
                  );
                } else {
                  const newData = uploadedFileData.splice(
                    uploadedFileData.length
                  );
                  const Data = uploadedFileData.slice(
                    0,
                    uploadedFileData.length
                  );
                  UploadData.SubscriberData.Subscriber = Data;
                  UplaodCsvFileInloop(
                    {
                      ...UploadData,
                      SubscriberData: {
                        ...UploadData.SubscriberData,

                        SubscribersFile: {
                          ...UploadData.SubscriberData.SubscribersFile,
                          FileId: FileId,
                        },
                      },
                    },
                    newData,
                    Data,
                    elements
                  );
                }
                return new Promise(function (resolve) {
                  setTimeout(resolve, interval);
                });
              });
            });
            promise.then(function () {
              console.log("Loop finished.");
            });
          }
        );
      } catch (error) {}
    }
  };

  // event tooltip fn
  if (imageError.show) {
    setTimeout(() => {
      hideError();
    }, 5000);
  }

  const showError = (message) => {
    setImageError({ show: true, message });
  };
  const hideError = () => {
    setImageError({ show: false, message: "" });
  };

  const CreateEventDetailsAboutToolTip = withStyles(() => ({
    tooltip: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: "16px",
      border: "1px solid #dadde9",
    },
  }))(Tooltip);

  const titleWithToolTip = (title, tipText, mandatory = false) => {
    return (
      <span style={{ fontSize: 16, fontWeight: "bold", color: "#000000" }}>
        {title}
        {mandatory && <span style={{ color: "red" }}> *</span>}
        <CreateEventDetailsAboutToolTip
          title={<React.Fragment>{tipText}</React.Fragment>}
        >
          <span
            style={{
              padding: "0 0 0 10px",
              cursor: "pointer",
              color: "rgb(161, 3, 150)",
            }}
          >
            <Info />
          </span>
        </CreateEventDetailsAboutToolTip>
      </span>
    );
  };

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  }
  const [width, height] = useWindowSize();

  const [err, seterr] = useState("");
  const pattern = /^(http:\/\/|https:\/\/|www\.)/i;
  let UrlError =
    "Please enter a valid URL starting with http://, https://, or www.";
  const UrlValidator = (state) => {
    // if (!pattern.test(state)) {
    //   seterr(' )
    // } else {
    //   seterr("")
    // }
    // return err
  };

  return (
    <div>
      {eventUserEvents.eventPublish ? null : (
        <Redirect to={`${routeName}/Dashboard/Publish`} />
      )}

      <div className="timeline_container">
        <div className="email_invite_wrapper">
          <ShowErrorToast message={error} closeToast={() => setError("")} />
          <div className="email_invite_header">
            <div className="email_invite">
              <span style={{ fontSize: 30 }}>
                {constants.Text.EmailInvitations}
              </span>
              {isEmailSend ? null : (
                <button onClick={toggleModal} className="email_invite_button">
                  {constants.Text.EditEmailInvite}
                </button>
              )}
            </div>
          </div>
        </div>
        {isEmailSend ? (
          <div
            style={{
              height: "80vh",
              padding: 20,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <img src={Confetti} style={{ width: "100px", height: "auto" }} />
            <p style={{ color: "#808088", fontSize: 25, marginTop: 40 }}>
              {constants.Text.Congratulations}
            </p>
            <p
              style={{
                color: "#808088",
                fontSize: 25,
                marginTop: 10,
                textAlign: "center",
                lineHeight: "26px",
              }}
            >
              {scheduleTimeType === "Immediately"
                ? "Your email invite is on it's way."
                : "Your email invite is scheduled."}
            </p>
            <Button
              onClick={() => {
                setisEmailSend(false);
                // window.location.reload();
              }}
              style={{
                backgroundColor: "rgb(163, 56, 164)",
                fontSize: 14,
                marginTop: 25,
                height: 50,
                color: "#fff",
                padding: "12px 60px",
                border: "none",
                width: 320,
                borderRadius: 9,
                textTransform: "capitalize",
              }}
              className="btn_save_edit_details"
              // className={classes.eventDetailsAboutUploadMap}
              color={"primary"}
              variant={"contained"}
              component="span"
            >
              {constants.Text.SendAnotherInvite}
            </Button>
          </div>
        ) : (
          <div>
            <Modal
              open={isOpen}
              onClose={() => {
                toggleModal();
              }}
            >
              <div
                style={{
                  position: "relative",
                  float: "right",
                }}
              >
                <div
                  style={{
                    display: imageError.show ? "block" : "none",
                    position: "absolute",
                    top: 0,
                    right: "0",
                    zIndex: "2",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      background: "white",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: "10px 30px",
                      background: "#d90d0d",
                      color: "white",
                      margin: "0px auto",
                      animationName: "slide-from-bottom",
                      animationDuration: "1s",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <ErrorOutlineIcon />
                      <p
                        style={{
                          margin: "0 0 0 20px",
                          textAlign: "start",
                        }}
                      >
                        {imageError.message}
                      </p>
                    </div>
                    <IconButton onClick={hideError}>
                      <CloseIcon
                        height={30}
                        width={30}
                        style={{ color: "white" }}
                      />
                    </IconButton>
                  </Box>
                </div>
                <div
                  className="edit_container_wrapper"
                  style={{ width: width < 600 ? width : 600 }}
                  // style={{
                  //   overflow: "auto",
                  //   backgroundColor: "#fff",
                  //   width: 490,
                  //   height: "100vh",
                  // }}
                >
                  <div style={{ padding: 20 }}>
                    <div className="edit_container">
                      <div className="edit_container_close_btn">
                        <IoCloseOutline
                          size={20}
                          color="rgba(0, 0, 0, 0.54)"
                          onClick={toggleModal}
                        />
                      </div>
                      <p className="edit_container_header">
                        {constants.Text.EditEmailInvite}
                      </p>
                      <div />
                    </div>
                  </div>
                  <div
                    className="edit_container_content"
                    style={{ position: "relative" }}
                  >
                    <span
                      style={{
                        color: "red",
                        fontSize: 16,
                        position: "absolute",
                      }}
                    >
                      *
                    </span>
                    <p style={{ marginLeft: 10 }}>
                      {constants.Text.updatesText}
                    </p>
                  </div>
                  {/* <hr style={{width:"80%",  backgroundColor: "rgba(0,0,0,0.9)", margin:0}} /> */}
                  <div className="edit_container_content">
                    <ImageUpload
                      showError={showError}
                      hideError={hideError}
                      files={files}
                      image={baseImgUrl !== "" ? baseImgUrl : base64img}
                      setFiles={setFiles}
                      setBase64img={setBase64imgFn}
                      setBaseImgUrl={setBaseImgUrl}
                    />

                    {largeTextField(
                      "Event Description",
                      editorState,
                      onEditorStateChange
                    )}
                    {eventData?.length > 0 ? (
                      <div className="additionalEvent">
                        <h3 style={{ marginBottom: 20 }}>
                          {constants.Text.ChooseAdditionalInclude}
                        </h3>
                        {eventData?.map((event, i) => (
                          <div key={i} className="event_list_wrapper">
                            <div className="event_list">
                              <input
                                type="checkbox"
                                className="event_checkbox"
                                checked={dataArray.some(
                                  ({ EventId }) => EventId === event?.EventId
                                )}
                                onChange={(e) => handleChange(e, event)}
                              />

                              <div style={{ marginRight: 10, marginLeft: 20 }}>
                                <p
                                  style={{
                                    color: "#fc6603",
                                    fontWeight: "600",
                                    textAlign: "center",
                                  }}
                                  className="event_list_text"
                                >
                                  {moment(
                                    convertTimeUtcToLocal(
                                      event?.StartDateTime,
                                      event.TimeZone
                                    )
                                  )
                                    .format("MMM")
                                    .toLocaleUpperCase()}
                                </p>
                                <p
                                  style={{ textAlign: "center" }}
                                  className="event_list_text"
                                >
                                  {moment(
                                    convertTimeUtcToLocal(
                                      event?.StartDateTime,
                                      event.TimeZone
                                    )
                                  ).format("DD")}
                                </p>
                              </div>

                              <img
                                className="event_list_img"
                                src={event?.ImageSmall}
                                alt="event"
                              />
                            </div>
                            <div style={{ padding: 10 }}>
                              <p className="event_list_text_header">
                                {event?.Title}
                              </p>
                              <p className="event_list_text">
                                {event?.VenueName}
                              </p>
                              <p className="event_list_text">
                                {moment(
                                  convertTimeUtcToLocal(
                                    event?.StartDateTime,
                                    event.TimeZone
                                  )
                                ).format("MMM DD YYYY, hh:mm A")}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : null}

                    <div className="emailInvitationDetail">
                      <h3>{constants.Text.Footer}</h3>
                      <p>{constants.Text.spamText}</p>

                      <div className="input_area">
                        <div class="label-float">
                          <input
                            onChange={(e) => setOrganizer(e.target.value)}
                            type="text"
                            placeholder=" "
                            value={organizer}
                          />
                          <label>
                            {constants.Text.OrganizerName}
                            <sup style={{ color: "red" }}>*</sup>
                          </label>
                        </div>
                      </div>

                      <div className="input_area">
                        <div class="label-float">
                          <input
                            onChange={(e) => setAddress1(e.target.value)}
                            type="text"
                            placeholder=""
                            value={address1}
                          />
                          <label>
                            {constants.Text.Address1}
                            <sup style={{ color: "red" }}>*</sup>
                          </label>
                        </div>
                      </div>

                      <div className="input_area">
                        <div class="label-float">
                          <input
                            onChange={(e) => setAddress2(e.target.value)}
                            type="text"
                            value={address2}
                            placeholder=" "
                          />
                          <label>{constants.Text.Address2}</label>
                        </div>
                      </div>

                      <div className="input_area">
                        <div class="label-float">
                          <input
                            onChange={(e) => setCity(e.target.value)}
                            type="text"
                            value={city}
                            placeholder=" "
                          />
                          <label>
                            {constants.Text.City}
                            <sup style={{ color: "red" }}>*</sup>
                          </label>
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div className="input_area">
                          <div class="label-float">
                            <input
                              onChange={(e) => setRegion(e.target.value)}
                              type="text"
                              placeholder=" "
                              value={region}
                            />
                            <label>
                              {constants.Text.State}
                              <sup style={{ color: "red" }}>*</sup>
                            </label>
                          </div>
                        </div>
                        <div style={{ width: "50px" }} />
                        <div className="input_area">
                          <div class="label-float">
                            <input
                              onChange={(e) => setZip(e.target.value)}
                              type="number"
                              placeholder=" "
                              value={zip}
                              onKeyDown={(e) =>
                                ["e", "E", "+", "-", "."].includes(e.key) &&
                                e.preventDefault()
                              }
                            />
                            <label>
                              {constants.Text.ZipCode}
                              <sup style={{ color: "red" }}>*</sup>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="input_area">
                        <div class="label-float">
                          <input
                            onChange={(e) => setCountry(e.target.value)}
                            type="text"
                            value={country}
                            placeholder=" "
                          />
                          <label>
                            {constants.Text.Country}{" "}
                            <sup style={{ color: "red" }}>*</sup>
                          </label>
                        </div>
                      </div>

                      {/* <div  className="input_area">
               <div class="label-float">
                  <input type="text" placeholder=" " required />
                  <label>Username</label>
                </div>
               </div> */}

                      <div className="input_area">
                        <FaFacebookF
                          size={20}
                          style={{ marginLeft: 5 }}
                          color="gray"
                        />
                        <div class="label-float">
                          <input
                            onChange={(e) => setFacebookLink(e.target.value)}
                            type="text"
                            placeholder=" "
                            value={facebookLink}
                          />
                          <label>{constants.Text.FacebookLink}</label>
                        </div>
                      </div>
                      {facebookLink !== "" && (
                        <p style={{ color: "red", fontSize: 14 }}>
                          {facebookLink && !pattern.test(facebookLink)
                            ? UrlError
                            : null}
                        </p>
                      )}
                      <div className="input_area">
                        <BsInstagram
                          size={20}
                          style={{ marginLeft: 5 }}
                          color="gray"
                        />
                        <div class="label-float">
                          <input
                            onChange={(e) => setInstagramLink(e.target.value)}
                            type="text"
                            placeholder=" "
                            value={instagramLink}
                          />
                          <label>{constants.Text.InstagramLink}</label>
                        </div>
                      </div>
                      {instagramLink !== "" && (
                        <p style={{ color: "red", fontSize: 14 }}>
                          {instagramLink && !pattern.test(instagramLink)
                            ? UrlError
                            : null}
                        </p>
                      )}
                      <div className="input_area">
                        <BsTwitter
                          size={20}
                          style={{ marginLeft: 5 }}
                          color="gray"
                        />
                        <div class="label-float">
                          <input
                            onChange={(e) => setTwitterLink(e.target.value)}
                            type="text"
                            value={twitterLink}
                            placeholder=" "
                          />
                          <label>{constants.Text.TwitterLink}</label>
                        </div>
                      </div>
                      {twitterLink !== "" && (
                        <p style={{ color: "red", fontSize: 14 }}>
                          {twitterLink && !pattern.test(twitterLink)
                            ? UrlError
                            : null}
                        </p>
                      )}
                    </div>
                    <hr />
                    <div className="detailAction" id="eventCommonModal">
                      {/* <button
                    onClick={toggleModal}
                    style={{ backgroundColor: "#fff" }}
                  >
                    Cancel
                  </button> */}
                      <Button
                        onClick={toggleModal}
                        style={{
                          width: 120,
                          height: 50,
                          backgroundColor: "#fff",
                          fontSize: "1.5rem",
                          color: "#000000",
                          padding: "12px 60px",
                          borderColor: "#968e8e",
                        }}
                        className="btn_save_edit_details"
                        // className={classes.eventDetailsAboutUploadMap}
                        color={"primary"}
                        variant={"outlined"}
                        component="span"
                      >
                        {constants.Text.Cancel}
                      </Button>

                      <Button
                        onClick={updateEmailToPromote}
                        disabled={
                          organizer === "" ||
                          address1 === "" ||
                          city === "" ||
                          country === "" ||
                          zip === "" ||
                          region === "" ||
                          (facebookLink && !pattern.test(facebookLink)) ||
                          (twitterLink && !pattern.test(twitterLink)) ||
                          (instagramLink && !pattern.test(instagramLink))
                            ? true
                            : false
                        }
                        style={{
                          backgroundColor:
                            organizer === "" ||
                            address1 === "" ||
                            city === "" ||
                            country === "" ||
                            zip === "" ||
                            region === "" ||
                            (facebookLink && !pattern.test(facebookLink)) ||
                            (twitterLink && !pattern.test(twitterLink)) ||
                            (instagramLink && !pattern.test(instagramLink))
                              ? "lightgray"
                              : "rgb(163, 56, 164)",
                          fontSize: "1.5rem",
                          height: 50,
                          color: "#fff",
                          padding: "12px 60px",
                          border: "none",
                          width: 120,
                          borderRadius: 9,
                        }}
                        className="btn_save_edit_details"
                        // className={classes.eventDetailsAboutUploadMap}
                        color={"primary"}
                        variant={"contained"}
                        component="span"
                      >
                        {constants.Text.Save}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
            {/* <div dangerouslySetInnerHTML={{ __html: emailTemplate }} /> */}
            <div>
              <CurrentPlanCard limits={limits} />
            </div>
            <br />
            <br />
            <div>
              <ScheduleEmail
                setEditorState={() =>
                  setEditorState(EditorState.createWithContent(contentState))
                }
                setMainEventId={setMainEventId}
                setEventTitle={setEventTitle}
                setFiles={setFiles}
                setIsEdit={setIsEdit}
                setdataArray={(data) => {
                  setdataArray(data);
                }}
                scheduledEmailData={scheduledEmailData}
                setScheduledEmailData={setScheduledEmailData}
                setEditScheduleEmailData={(data) => {
                  setBaseImgUrl("");
                  setBase64img("");
                  setEventDetails({
                    Title: data.Subject,
                    StartDateTime: data.ScheduleDateTime,
                  });
                  setEditScheduleEmailData(data);
                }}
              />
            </div>

            <div className="container_email">
              <div className="container_timeline">
                <div className="timeline-block timeline-block-right">
                  <div className="marker" />
                  <div className="timeline-content">
                    <h3>
                      {titleWithToolTip(
                        "Create email invitations",
                        "Email invites are a great way to bring in current email subscribers, previous event attendees or existing customers!",
                        false
                      )}
                    </h3>
                    <br />
                    <br />
                    <Subject
                      {...{
                        fromName,
                        setfromName,
                        setEventTitle,
                        eventTitle,
                      }}
                    />

                    <EmailInvitationView
                      {...{
                        address1,
                        address2,
                        city,
                        region,
                        country,
                        organizer,
                        zip,
                        UserId,
                        mainEventId,
                      }}
                      StartDateTime={ConvertedStartDateTime(
                        convertTimeUtcToLocal(dataArray[0]?.StartDateTime)
                      )}
                      data={dataArray.map((item) => {
                        let emailInviteDataObj = Object.assign({}, item);
                        emailInviteDataObj["StartDateTime"] =
                          convertTimeUtcToLocal(item.StartDateTime).format(
                            "llll"
                          );
                        return emailInviteDataObj;
                      })}
                      files={files}
                      base64img={baseImgUrl}
                      editorHtml1={replcaeEmptyTagwithBreak(editorHtml1)}
                      facebook={CheckIsLinkValid(facebookLink)}
                      instagram={CheckIsLinkValid(instagramLink)}
                      twitter={CheckIsLinkValid(twitterLink)}
                      isRecurring ={isRecurring}
                    />
                  </div>
                </div>

                <div className="timeline-block timeline-block-left">
                  <div className="marker">
                    <i className="fa fa-check" aria-hidden="true"></i>
                  </div>
                  <div className="timeline-content">
                    <Invitation
                      limits={limits}
                      titleWithToolTip={titleWithToolTip}
                      subject={eventTitle}
                      emailBody={emailTemplate}
                      UserId={UserId}
                    />
                  </div>
                </div>

                <div className="timeline-block timeline-block-right active">
                  <div className="marker"></div>
                  <div className="timeline-content">
                    <h3>
                      {titleWithToolTip(
                        "Who should come ?",
                        "Upload a csv or xlsx file to import all of your existing subscribers - select the list to add them to your mailing list"
                      )}
                    </h3>
                    <Contact
                      scheduledEmailData={scheduledEmailData}
                      getUserplandetails={getUserplandetails}
                      isDataEmpty={data}
                      UserId={UserId}
                      error={error}
                      limits={limits}
                      contactIds={contactIds}
                      setContactIds={setContactIds}
                      //  responsedata={resData}
                      totaUploadedEmail={totaUploadedEmail}
                      unsubscribeEmails={unsubscribeEmails}
                      duplicateEmails={duplicateEmails}
                      invalidEmails={invalidEmails}
                      uploadedContacts={uploadedContacts}
                      setUploadedContacts={setUploadedContacts}
                      barValue={barValue}
                      fileName={fileName}
                      handleFileChange={handleFileChange}
                    />
                  </div>
                </div>
              </div>
              {/* End Here */}
              <div className="timeline-block timeline-block-left">
                <div className="marker">
                  <i className="fa fa-check" aria-hidden="true"></i>
                </div>
                <div className="timeline-content">
                  <h3>
                    {titleWithToolTip(
                      "When do you want to send it?",
                      "Choose whether or not you want to send your email out immediately or schedule it for later"
                    )}
                  </h3>
                  <Time
                    limits={limits}
                    StartDateTime={convertTimeUtcToLocal(
                      dataArray[0]?.StartDateTime
                    )}
                    EndDatetime={convertTimeUtcToLocal(
                      dataArray[0]?.EndDateTime
                    )}
                    timezone={dataArray[0]?.TimeZone}
                    contactIds={contactIds}
                    daysBefore={daysBefore}
                    setDaysBefore={setDaysBefore}
                    updateEmailToPromote={handleSubmitEmail}
                    isEdit={isEdit}
                    newTime={newTime}
                    setNewTime={setNewTime}
                    scheduleTimeType={scheduleTimeType}
                    setScheduleTimeType={setScheduleTimeType}
                    handleSubmitEmail={handleSubmitEmail}
                    scheduleTimeValue={scheduleTimeValue}
                    setScheduleTimeValue={setScheduleTimeValue}
                    saveUpdateDetails={saveUpdateDetails}
                  />
                </div>
              </div>
              {isEmailSend ? null : (
                <div>
                  <button
                    onClick={toggleModal}
                    className="edit_container_toggle_btn"
                  >
                    <MdEdit color="#ffffff" size={30} />
                  </button>
                </div>
              )}
            </div>
          </div>
        )}

        {isEmailSend ? null : (
          <div className="save_and_continue">
            <div className="send_email_invite_btn2">
              {Number(limits.TotalCurrentMonthEmailSent) >=
              Number(limits.MonthlySendingEmailQuota) ? (
                <div className="limit_exceeds">
                  <p>
                    {constants.Text.monthlyLimit}
                    <b>{constants.Text.PhoneNumber}</b>
                    {constants.Text.ToIncreaseYourLimit}
                  </p>
                </div>
              ) : (
                <Button
                  disabled={
                    (scheduleTimeValue !== "" &&
                      scheduleTimeValue !== "daysbefore" &&
                      contactIds?.length > 0) ||
                    (scheduleTimeValue === "daysbefore" &&
                      daysBefore !== "" &&
                      contactIds?.length > 0)
                      ? false
                      : true
                  }
                  onClick={() => handleSubmitEmail()}
                  style={{
                    marginTop: 20,
                    backgroundColor:
                      (scheduleTimeValue !== "" &&
                        scheduleTimeValue !== "daysbefore" &&
                        contactIds?.length > 0) ||
                      (scheduleTimeValue === "daysbefore" &&
                        daysBefore !== "" &&
                        contactIds?.length > 0)
                        ? "#851679"
                        : "#f7adff",
                    fontSize: 14,
                    color: "#fff",
                    padding: "12px 20px",
                    borderRadius: 8,
                    textTransform: "capitalize",
                  }}
                  color={"primary"}
                  variant={"contained"}
                  component="span"
                >
                  <span style={{ color: "#fff" }}>
                    {" "}
                    {scheduleTimeType === "Immediately"
                      ? "Send now"
                      : "Schedule"}
                  </span>
                </Button>
              )}
            </div>

            <Link
              to={`${routeName}/Dashboard/Flyers`}
              className="button_flyrs"
              style={{
                backgroundColor: "#a338a4",
                textDecoration: "none",
              }}
              disabled={saveUpdateDetails ? false : true}
            >
              {constants.Text.Continue}
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { eventUserEvents } = state;
  return {
    eventUserEvents: eventUserEvents,
    eventPublish: state.eventUserEvents.eventPublish,
  };
};

const matchDispatchToProps = (dispatch) => {
  return {
    // AddToFavorite: () => dispatch(AddToFavorite),
    // SetCardData: () => dispatch(SetCardData),
    // LoginPopup: () => dispatch(LoginPopup),
    // GetUserProfileDetail,
    // saveUserData,
    UpdateEventDetailsByUser,
    dispatch,
  };
};

export default connect(mapStateToProps, matchDispatchToProps)(EmailInvitation);
