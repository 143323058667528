import React from "react";
import ReactDOM from "react-dom";
import store, { history, persistor } from "./store";
import { ConnectedRouter } from "react-router-redux";
import { Provider } from "react-redux";
// import MuiPickersUtilsProvider from '@material-ui/pickers/MuiPickersUtilsProvider';
// import MomentUtils from "@date-io/moment";
import "./index.css";
import App from "./App";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { Notifications } from "react-push-notification";
import { PersistGate } from "redux-persist/integration/react";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#851679",
    },
    secondary: {
      main: "#b764ac",
    },
  },
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#b764ac",
      },
    },
    MuiPickerDTTabs: {
      tabs: {
        backgroundColor: "#b764ac",
      },
    },
  },
});

ReactDOM.render(
  <Provider store={store} context={store}>
    <PersistGate persistor={persistor}>
      <ConnectedRouter history={history}>
        {/*<MuiPickersUtilsProvider utils={MomentUtils}>*/}
        <MuiThemeProvider theme={theme}>
          <App />
        </MuiThemeProvider>
        {/*</MuiPickersUtilsProvider>*/}
      </ConnectedRouter>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
