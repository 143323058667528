import React from "react";
import "./Footer.css";
import * as constants from "Constants";
import { homeRoute, routeName } from "Service/env";
import { connect } from "react-redux";
import { GetUserProfileDetail, LoginPopup, logoutAction } from "Actions";
import { history } from "store";
import {
  clearEventDetails,
  removeEventByEventUser,
} from "Actions/eventActions";

function Footer({
  LoginPopup,
  dispatch,
  isLoggedIn,
  clearEventDetails,
  removeEventByEventUser,
}) {
  const LoginPopupOpen = (val) => {
    dispatch(LoginPopup(val));
  };

  const cleareEventsAndCreateNewone = () => {
    dispatch(clearEventDetails());
    dispatch(removeEventByEventUser());
    localStorage.setItem("isItCreateOrEditEvent", "Edit");
    // localStorage.setItem("persist:persisted-key", "");
    // this.props.removeEventByEventUser();
    localStorage.setItem("buyerFee", null);
    localStorage.removeItem("isCloneEvent");

    setTimeout(() => {
      if (isLoggedIn) {
        window.location.href = `${routeName}/Dashboard/About`;
      } else {
        history.push(`/${homeRoute}`);
      }
    }, 200);
  };
  return (
    <footer>
      <div class="footer_wrapper">
        <div class="footer-container">
          <div class="company-description">
            <h5>{constants.FooterText.About}</h5>
            <p>{constants.FooterText.AboutUs}</p>
            <div class="icons">
              <a
                href="https://www.tiktok.com/@ubmeevents?_t=8b8eqIfZCqg&_r=1"
                target="_blank"
              >
                <span class="facebook_icon">
                  <i class="fab fa-tiktok"></i>{" "}
                </span>
              </a>

              <a href="https://www.instagram.com/" target="_blank">
                <span class="instagram_icon">
                  <i class="fa-brands fa-instagram"></i>
                </span>
              </a>
            </div>
          </div>
          <div className="footer_right">
            <div class="menu">
              <ul className="footer_ul">
                <h5>{constants.FooterText.navigation}</h5>
                <li>
                  <span
                    onClick={() =>
                      !isLoggedIn
                        ? LoginPopupOpen({
                            path: "Dashboard/About",
                            value: true,
                          })
                        : cleareEventsAndCreateNewone()
                    }
                  >
                    {constants.NavbarText.createEvent}
                  </span>
                </li>
                <li>
                  <a href={`${routeName}/overview`} target="_blank">
                    {constants.FooterText.overview}
                  </a>
                </li>
                <li>
                  <a href={`${routeName}/pricing`} target="_blank">
                    {constants.FooterText.Pricing}
                  </a>
                </li>
                <li>
                  <a href={`${routeName}/FrequentlyAskedQuestions`} target="_blank">
                    {constants.FooterText.FrequentlyAskedQuestions}
                  </a>
                </li>
                <li>
                  <span
                    onClick={() =>
                      !isLoggedIn
                        ? LoginPopupOpen({ path: "", value: true })
                        : {}
                    }
                  >
                    {constants.NavbarText.signin}
                  </span>
                </li>
                <li>
                  <a href={`${routeName}/report`}>
                    {constants.FooterText.Reportissue}
                  </a>
                </li>
              </ul>
            </div>
            <div class="contact">
              <ul className="footer_ul_add">
                <h5>{constants.FooterText.ContactUs}</h5>
                {/* <li>
                <i style={{ marginRight: 10 }} class="fa-solid fa-phone"></i>
                <p>{constants.FooterText.phone}</p>
              </li> */}
                <li>
                  <i
                    style={{ marginRight: 10 }}
                    class="fa-solid fa-envelope"
                  ></i>
                  <p>
                    <a href="mailto:support@ubme.com">
                      {constants.FooterText.email}
                    </a>
                  </p>
                </li>
                <li>
                  <i
                    style={{
                      marginRight: 10,
                      paddingRight: 12,
                      paddingLeft: 12,
                    }}
                    class="fa-solid fa-location-dot"
                  ></i>
                  <p>{constants.FooterText.address}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <hr class="underline" />
      <div class="company-details">
        <div class="rights">{constants.FooterText.rights} </div>
        <div class="terms">
          {" "}
          <a href={`${routeName}/terms`} target="_blank">
            {constants.FooterText.terms}
          </a>
        </div>
        <div class="privacy">
          <a href={`${routeName}/privacy`} target="_blank">
            {" "}
            {constants.FooterText.privacy}
          </a>
        </div>
      </div>
    </footer>
  );
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.loginResponse.isLoggedIn,
  };
};
const matchDispatchToProps = (dispatch) => {
  return {
    // AddToFavorite: () => dispatch(AddToFavorite),
    // SetCardData: () => dispatch(SetCardData),
    // LoginPopup: () => dispatch(LoginPopup),
    clearEventDetails,
    removeEventByEventUser,
    LoginPopup,
    dispatch,
  };
};
export default connect(mapStateToProps, matchDispatchToProps)(Footer);
