import moment from "moment";
import React from "react";
import { useEffect } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Brush,
  AreaChart,
  Area,
  ResponsiveContainer,
} from "recharts";
import { useCurrentPng } from "recharts-to-png";
 const Tabledata = [
    {
    Date: moment().format("MM/DD/YYYY"),
    value:0
  },
  
 ]

function GraphData({startDownload, title, data, setImage}) {
  const [getPng, { ref, isLoading }] = useCurrentPng();
const graphData =  data?.sort((a, b) => new Date(a?.Date) - new Date(b?.Date));
  const number = data?.reduce(
    (a, b) => a + Number(b.value),
    0
  );

  useEffect(()=>{
    if(startDownload === "start"){
      ChartTopng();
    }
  },[startDownload])

  const ChartTopng = async() => {
    const png = await getPng();
    setImage(png)
  }

  const CustomTooltip = ({ active, payload, label, name }) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            background: "#ffffff",
            border: "1px solid #808080",
            height: 80,
            width: "100%",
            padding: 10,
            zIndex: 999,
          }}
          className="custom-tooltip"
        >
          <p style={{ fontSize: 14, marginLeft: 7, marginBottom: 8 }}>
            {label}
          </p>
          <p
            style={{
              color:
                name === "Sales"
                  ? "rgb(0, 171, 85)"
                  : name === "Tickets Sold"
                  ? "#faa82d"
                  : "rgb(118, 53, 220)",
              fontSize: 14,
              fontWeight: 400,
            }}
            className="label"
          >{`${name} : ${
             Intl.NumberFormat().format(payload[0].value)
          }`}</p>
        </div>
      );
    }

    return null;
  };
  const CustomizedAxisTick = ({ x, y, payload }) => {
    if (payload.index === 0 || payload.index === graphData?.length - 1) {
      return <text x={x} y={y} dy={16} textAnchor="middle">{payload.value}</text>;
    }
    return null;
  };

  const yAxisFormatter = (value) => parseInt(value, 10);
  return (
    <div style={{marginBottom:30}}>
      <div className="landingPageLineChartHeading">
        <h3 style={{marginTop:8}}>{title}</h3>
        <h1 style={{marginTop:8}}>{ Intl.NumberFormat().format(number) || 0  }</h1>
      </div>
      
        <LineChart
          ref={ref}
          width={400}
          height={250}
          data={graphData.length > 0 ? graphData : Tabledata}
          // syncId="anyId"
          // margin={{
          //   top: 10,
          //   right: 30,
          //   left: 0,
          //   bottom: 0,
          // }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="Date" tick={<CustomizedAxisTick />}/>
          <YAxis tickFormatter={yAxisFormatter}/>
          <Tooltip content={<CustomTooltip name={title} />} />
          <Line
            type="monotone"
            dataKey="value"
            stroke={
              title === "Sales"
                ? "rgb(0, 171, 85)"
                : title === "Tickets Sold"
                ? "#faa82d"
                : "rgb(118, 53, 220)"
            }
            dot={false}
          />
        </LineChart>
 
    </div>
  );
}

export default GraphData;
