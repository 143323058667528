import React, {useEffect} from "react";
import moment from "moment";
import {useState} from "react";
import {Button} from "@material-ui/core";
import DatePicker from "react-modern-calendar-datepicker";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import {default as momentTz} from "moment-timezone";
import {Text} from "Constants";

const Time = ({
                  StartDateTime,
                  EndDatetime,
                  setNewTime,
                  newTime,
                  scheduleTimeType,
                  setScheduleTimeType,
                  handleSubmitEmail,
                  saveUpdateDetails,
                  updateEmailToPromote,
                  scheduleTimeValue,
                  setScheduleTimeValue,
                  contactIds,
                  timezone,
                  setDaysBefore,
                  daysBefore,
                  limits
              }) => {
    const [scheduleTime, setScheduleTime] = useState({
        date: new Date(),
        time: moment(),
    });

    let nowDate = momentTz
        .tz(moment.utc(StartDateTime), "MM/DD/YYYY hh:mm:ss a", timezone)
        .utc()
        .subtract(daysBefore, "days")
        .format("MM/DD/YYYY hh:mm:ss a");

    // convert UTC time into LOCAL
    const timeUTCtoLocal = (date, timezone) => {
        var local = momentTz
            .tz(moment.utc(date), "MM/DD/YYYY hh:mm:ss a", timezone)
            .format("MM/DD/YYYY hh:mm:ss a");
        return moment(local, "MM/DD/YYYY hh:mm:ss a");
    };

    // convert LOCAL time into UTC
    const timeLocaltoUtc = (date, time, timezone) => {
        const formattedDate = moment(date).format("MM/DD/YYYY");
        const formattedTime = moment(time).format("hh:mm:ss a");
        const dateAndTimeString = formattedDate + " " + formattedTime;
        var dateTimeInUtc = momentTz
            .tz(dateAndTimeString, "MM/DD/YYYY hh:mm:ss a", timezone)
            .utc()
            .format();
        return dateTimeInUtc;
    };

    useEffect(() => {
        if (scheduleTimeValue !== "") {
            setScheduleTimeType(scheduleTimeValue);
        }
        if (scheduleTimeValue === "daysbefore") {
            setNewTime(nowDate);
        } else if (scheduleTimeValue === "scheduled") {
            setNewTime(timeLocaltoUtc(scheduleTime.date, scheduleTime.time, timezone));
        } else {
            setNewTime(moment.utc().format());
        }
    }, [scheduleTime, scheduleTimeValue, daysBefore]);

    const getDate = (date) => {
        if (date !== null || "") {
            const dateObj = {
                month: parseInt(moment(date).format("MM")),
                day: parseInt(moment(date).format("DD")),
                year: parseInt(moment(date).format("YYYY")),
            };
            return dateObj;
        } else {
            return null;
        }
    };


    const ChangeStartDate = (date) => {
        const completeDate = `${date.month}-${date.day}-${date.year}`;
        setScheduleTime({
            ...scheduleTime,
            date: moment(completeDate, "MM/DD/YYYY").format("MM/DD/YYYY"),
        });
    };


    const ChangeEndTime = (date) => {
        if (date === null || date === "invalid date") {
            setScheduleTime({...scheduleTime, time: moment()})
        } else {
            setScheduleTime({...scheduleTime, time: moment(date)})

        }
    };

    const RadioInput = ({disabled, value, checked, setter}) => {
        return (
            <label>
                <input
                    style={{margin: 0}}
                    type="radio"
                    disabled={disabled}
                    checked={checked == value}
                    onChange={() => setter(value)}
                />
            </label>
        );
    };

    return (
        <>
            <div className="time">
                <div style={{display: "flex", gap: 10, alignItems: "center"}}>
                    <RadioInput
                        value="Immediately"
                        disabled={moment(
                            timeUTCtoLocal(EndDatetime, timezone),
                            "MM/DD/YYYY hh:mm:ss A"
                        ).isBefore(
                            moment(
                                moment().tz(timezone),
                                "MM/DD/YYYY hh:mm:ss A"
                            )
                        )}
                        checked={scheduleTimeValue}
                        setter={setScheduleTimeValue}
                    />
                    <span style={{color: "#000", marginLeft: 5}}>{Text.Immediately}</span>
                </div>
                <br/>
                <div className="timeanddate_wrapper">
                    <div style={{color: "#000", width: 130}}>
                        <RadioInput
                            value="scheduled"
                            disabled={moment(
                                timeUTCtoLocal(EndDatetime, timezone),
                                "MM/DD/YYYY hh:mm:ss A"
                            ).isBefore(
                                moment(
                                    moment().tz(timezone),
                                    "MM/DD/YYYY hh:mm:ss A"
                                )
                            )}
                            checked={scheduleTimeValue}
                            setter={setScheduleTimeValue}
                        />
                        <span style={{color: "#000", marginLeft: 14}}>{Text.SelectDate}</span>
                    </div>

                    <DatePicker
                        value={getDate(scheduleTime.date)}
                        onChange={ChangeStartDate}
                        inputClassName="date_picker_input"

                        colorPrimary="#851679" // added this
                        minimumDate={
                            moment(
                                timeUTCtoLocal(StartDateTime, timezone),
                                "MM/DD/YYYY hh:mm:ss A"
                            ).isBefore(
                                moment(
                                    moment().tz(timezone),
                                    "MM/DD/YYYY hh:mm:ss A"
                                )
                            )
                                ? moment(
                                    timeUTCtoLocal(EndDatetime, timezone),
                                    "MM/DD/YYYY hh:mm:ss A"
                                ).isBefore(
                                    moment(
                                        moment().tz(timezone),
                                        "MM/DD/YYYY hh:mm:ss A"
                                    )
                                ) ? getDate(
                                        moment(moment().tz(timezone), "MM/DD/YYYY hh:mm:ss A")
                                    )
                                    : getDate(
                                        moment(
                                            timeUTCtoLocal(StartDateTime, timezone),
                                            "MM/DD/YYYY hh:mm:ss A"
                                        )
                                    )
                                :
                                getDate(
                                    moment(moment().tz(timezone), "MM/DD/YYYY hh:mm:ss A")
                                )
                        }
                        // format={"MM/DD/YYYY"}
                        formatInputText={() =>
                            moment(scheduleTime.date).format("MM/DD/YYYY")
                        }
                        // wrapperClassName="date_picker_wrapper"
                        maximumDate={getDate(moment(timeUTCtoLocal(EndDatetime, timezone)).format(
                            "MM/DD/YYYY hh:mm:ss A"
                        ))}
                        inputPlaceholder="Select Your Time"
                    />

                    <TimePicker
                        placeholder="Select Time"
                        use12Hours
                        showSecond={false}
                        focusOnOpen={true}
                        disabled={moment(
                            timeUTCtoLocal(EndDatetime, timezone),
                            "MM/DD/YYYY hh:mm:ss A"
                        ).isBefore(
                            moment(
                                moment().tz(timezone),
                                "MM/DD/YYYY hh:mm:ss A"
                            )
                        )}
                        format="hh:mm A"
                        value={scheduleTime.time}
                        onChange={(e) =>
                            ChangeEndTime(e)
                        }
                        //  className="time_picker"
                        minuteStep={1}
                    />
                </div>
                <br/>
                <div style={{display: "flex", gap: 10, alignItems: "center"}}>
                    <RadioInput
                        value="daysbefore"
                        disabled={moment(
                            timeUTCtoLocal(EndDatetime, timezone),
                            "MM/DD/YYYY hh:mm:ss A"
                        ).isBefore(
                            moment(
                                moment().tz(timezone),
                                "MM/DD/YYYY hh:mm:ss A"
                            )
                        )}
                        checked={scheduleTimeValue}
                        setter={setScheduleTimeValue}
                    />{" "}
                    <input
                        type="text"
                        disabled={moment(
                            timeUTCtoLocal(StartDateTime, timezone),
                            "MM/DD/YYYY"
                        ).isBefore(moment(moment().tz(timezone), "MM/DD/YYYY"))}
                        style={{marginLeft: 8, marginTop: 8, fontSize: 16}}
                        className="text-input"
                        value={daysBefore}
                        maxLength={3}
                        onKeyDown={(e) =>
                            ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
                        }
                        onChange={(e) => setDaysBefore(e.target.value)}
                        onInput={(e) => {
                            let isSpecial = /^-?\d+\.?\d*$/.test(e.target.value);
                            e.target.value = isSpecial ? e.target.value : "";
                        }}
                        onWheel={(event) => {
                            event.preventDefault();
                        }}
                    />
                    <>
                        <p style={{color: "#000", marginLeft: 5}}>
                            {Text.DaysBeforeEvent} <br/>
                            ({Text.OptimisedTime})
                        </p>
                    </>
                </div>
                {/* <p style={{ color: "#000", marginLeft: 5 }}>
          </p> */}
                <br/>

                <div className="send_email_invite_btn">
                    {Number(limits.TotalCurrentMonthEmailSent) >=
                    Number(limits.MonthlySendingEmailQuota) ? (
                            <div className="limit_exceeds">
                                <p>
                                    {Text.monthlyLimit} <b>{Text.PhoneNumber}</b>{" "}
                                    {Text.ToIncreaseYourLimit}
                                </p>
                            </div>
                        ) :
                        <Button
                            disabled={
                                (scheduleTimeValue !== "" &&
                                    scheduleTimeValue !== "daysbefore" &&
                                    contactIds?.length > 0) ||
                                (scheduleTimeValue === "daysbefore" &&
                                    daysBefore !== "" &&
                                    contactIds?.length > 0)
                                    ? false
                                    : true
                            }
                            onClick={() => handleSubmitEmail()}
                            style={{
                                marginTop: 20,
                                backgroundColor:
                                    (scheduleTimeValue !== "" &&
                                        scheduleTimeValue !== "daysbefore" &&
                                        contactIds?.length > 0) ||
                                    (scheduleTimeValue === "daysbefore" &&
                                        daysBefore !== "" &&
                                        contactIds?.length > 0)
                                        ? "#851679"
                                        : "#f7adff",
                                fontSize: 14,
                                color: "#fff",
                                padding: "12px 20px",
                                borderRadius: 8,
                                textTransform: "capitalize",
                            }}
                            color={"primary"}
                            variant={"contained"}
                            component="span"
                        >
          <span style={{color: "#fff"}}>
            {" "}
              {scheduleTimeType === "Immediately" ? "Send now" : "Schedule"}
          </span>
                        </Button>}
                </div>


            </div>

            <style jsx="true">
                {`

                  .select_box {
                    font-size: 16px;
                    padding: 7px;
                    width: auto;
                  }

                  .time input {
                    margin-top: 20px;
                  }

                  .text-input {
                    width: 50px;
                    padding: 7px;
                  }

                  .snd-btn {
                    margin-top: 20px;
                    margin-left: 5px;
                    padding: 15px 20px;
                    background: #ca3dd9;
                    width: 150px
                    outline: none;
                    border: none;
                    color: #fff;
                    border-radius: 5px;
                  }
                `}
            </style>
        </>
    );
};

export default Time;
