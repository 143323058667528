import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    messageAction,
    onOpen,
    error,
    getUnreadCount,
} from "./Actions/messagesAction";
import { getUserAction, GetUserProfileDetail } from "./Actions/index";
import IndexPage from "./Components/Index";
import Dashboard from "./Components/BusinessPortal/Index";
import { Switch, Route, withRouter } from "react-router-dom";
import PrivateRoute from "./Components/RouteTypes/privateRoute";
import PublicRoute from "./Components/RouteTypes/publicRoute";
import "./App.css";
import { homeRoute, routeName } from "../src/Service/env";
import ErrorPage from "./Helper/ErrorPage/errorPage";
import LoginLoader from "./Helper/loader/loginLoader";
import HomeScreen from "Components/UBMeEvents/Home/HomeScreen/HomeScreen";
import Favorites from "Components/UBMeEvents/Favorites/Favorite";
import UserAccount from "Components/UBMeEvents/UserAccount/UserAccount";
import Events from "Components/UBMeEvents/Events/EventScreen/Events";
import CreateEvent from "Components/UBMeEvents/CreateEvent/CreateEvent";
import Tickets from "Components/UBMeEvents/Tickets/Tickets";
import messages from "Components/BusinessPortal/Messages/messages";
import AddMoreTickets from "Components/UBMeEvents/Events/checkout/AddMoreTickets/AddMoreTickets";
import Payment from "Components/UBMeEvents/Events/checkout/payment/Payment";
import LoginBeforeCheckOut from "Components/UBMeEvents/Events/checkout/LoginBeforeCheckout/LoginBeforeCheckOut";
import UnSubscribe from "Components/UBMeEvents/Unscubscribe/UnSubscribe";
import StripeContainer from "Components/UBMeEvents/Events/Stripe/StripeContainer";
import PaymentSuccess from "Components/UBMeEvents/Events/Stripe/PaymentSuccess";
import PageNotFound from "Components/UBMeEvents/Home/404 page-not-found/PageNotFound";
import Contact from "Components/UBMeEvents/Home/404 page-not-found/Contact";
import ReportIssue from "Components/UBMeEvents/Report/ReportIssue";
import Pricing from "Components/UBMeEvents/Pricing/Pricing";
import OrganizerLandingPage from "Components/UBMeEvents/organizerLandingPage/organizerLandingPage";
import FrequentlyAskedQuestions from "Components/UBMeEvents/FrequentlyAskedQuestions/FrequentlyAskedQuestions";
import TicketingFees from "Components/UBMeEvents/Pricing/TicketingFees";
import OverView from "Components/UBMeEvents/overview/OverView";
import ThankYou from "Components/UBMeEvents/ThankYou/ThankYou";
import TermAndCondtion from "Components/UBMeEvents/termsAndCondition";
import PrivactAndPolicy from "Components/UBMeEvents/privacyAndPolicy";
import Sockette from "sockette";
export let ws = null;
class App extends Component {
    componentDidMount() {
        // listen window storage event if new user logged in
        window.addEventListener("storage", this.handleStorageChange);
        console.log("Dhvani APP JS:- " + localStorage.getItem("isLogedIn"));
        // const isLogedIn = localStorage.getItem("isLogedIn");
        // if (isLogedIn === 'true') {
        //     localStorage.removeItem("pendingEventsButtonClick");
        // }
        this.initializeWebSocket();
    }
    componentWillUnmount() {
        // remove event listner
        window.removeEventListener("storage", this.handleStorageChange);
    }

    initializeWebSocket = () => {
        const { user, onOpen, error, getUnreadCount, messageAction } = this.props;
        const date = new Date();
        ws = new Sockette("wss://socket.ubme.com/", {
        // ws = new Sockette("wss://socketdevelopment.ubme.com:8088/", {
        // ws = new Sockette("ws://localhost:8080/", {
            protocol: "echo-protocol",
            onopen: () => {
                if (user && user.ProfileDetail && user.ProfileDetail.UserId) {
                    if(((localStorage !== undefined) && ((localStorage.messageClickWithoutLogin === undefined) || (localStorage.messageClickWithoutLogin !== undefined && localStorage.messageClickWithoutLogin !== "true"))) || window.location.href === `${routeName}/messages`) {
                        ws.json({
                            token_id: localStorage.getItem("token"),
                            user_id: user.ProfileDetail.UserId.toString(),
                            type: "identity",
                            isOnline: 1,
                            msgid: date.getTime().toString().slice(4),
                        });
                        ws.json({
                            user_id: user.ProfileDetail.UserId.toString(),
                            type: "recentchat",
                            count: 20,
                            index: 1,
                        });
                        getUnreadCount();
                    }

                    if (localStorage.messageClickWithoutLogin === "true") {
                        localStorage.removeItem("messageClickWithoutLogin");
                    }
                }
                onOpen("Open");
            },
            onmessage: (e) => {
                messageAction(e);
            },
            onreconnect: () => {
                if (user && user.ProfileDetail && user.ProfileDetail.UserId) {
                    ws.json({
                        token_id: localStorage.getItem("token"),
                        user_id: user.ProfileDetail.UserId.toString(),
                        type: "identity",
                        isOnline: 1,
                        msgid: date.getTime().toString().slice(4),
                    });
                }
                onOpen("Open");
                error("sc");
            },
            onmaximum: () => console.log("Stop Attempting..!!"),
            onclose: () => {
                if (user && user.ProfileDetail && user.ProfileDetail.UserId) {
                    ws.json({
                        type: "disconnect",
                        userId: user.ProfileDetail.UserId.toString(),
                        tokenId: localStorage.getItem("token"),
                    });
                }
            },
            onerror: (e) => {
                console.log(e);
                error("Cannot connect to server.");
            },
        });
    };

    handleStorageChange = (event) => {
        if (event.key === "UserId" || event.key === "isLogedIn") {
            window.location.reload();
        }
    };

    render() {
        console.log("App Status:🌍", process.env.NODE_ENV, "&", "App Version:", "v1.0.1");

        const { user, onOpen, error, getUnreadCount, messageAction } = this.props;
        const date = new Date();
        if(window.location.href === `${routeName}/messages`) {
            ws.json({
                token_id: localStorage.getItem("token"),
                user_id: user.ProfileDetail.UserId.toString(),
                type: "identity",
                isOnline: 1,
                msgid: date.getTime().toString().slice(4),
            });
            ws.json({
                user_id: user.ProfileDetail.UserId.toString(),
                type: "recentchat",
                count: 20,
                index: 1,
            });
            getUnreadCount();
        }

        const publicRoute = (
            <PublicRoute exact path={`${routeName}/messages`} component={messages} />
        );

        const privateRoute = (
            <PrivateRoute path={`${routeName}/Dashboard`} component={Dashboard} user={this.props.user} />
        );

        return (
            <React.Fragment>
                <LoginLoader />

                <Switch>
                    {/* ubme routes */}
                    {process.env.NODE_ENV === "development" && (
                        <PublicRoute exact path={"/"} component={IndexPage} onpage={true} />
                    )}

                    <Route exact path={`/${homeRoute}`} component={HomeScreen} />
                    <Route exact path={`${routeName}/Favorite`} component={Favorites} />
                    <Route exact path={`${routeName}/account`} component={UserAccount} />
                    <Route exact path={`${routeName}/event/:eventId`} component={Events}/>
                    <Route exact path={`${routeName}/event/:eventId?checkout=true`} component={Events}/>
                    <Route exact path={`${routeName}/report`} component={ReportIssue} />
                    <Route exact path={`${routeName}/pricing`} component={Pricing} />
                    <Route exact path={`${routeName}/:organizerId~:organizerUsername`} component={OrganizerLandingPage} />
                    <Route exact path={`${routeName}/frequentlyAskedQuestions`} component={FrequentlyAskedQuestions} />
                    <Route exact path={`${routeName}/ticketfees`} component={TicketingFees} />
                    <Route exact path={`${routeName}/terms`} component={TermAndCondtion} />
                    <Route exact path={`${routeName}/privacy`} component={PrivactAndPolicy} />
                    <Route exact path={`${routeName}/overview`} component={OverView} />
                    <Route exact path={`${routeName}/checkout`} component={AddMoreTickets} />
                    <Route exact path={`${routeName}/payment`} component={Payment} />
                    <Route exact path={`${routeName}/success`} component={PaymentSuccess} />
                    <Route exact path={`${routeName}/payment/stripe`} component={StripeContainer} />
                    <Route exact path={`${routeName}/login/checkout`} component={LoginBeforeCheckOut} />
                    <Route exact path={`${routeName}/unsubscribe/:userid/:email`} component={UnSubscribe} />
                    <Route exact path={`${routeName}/404`} component={PageNotFound} />
                    <Route exact path={`${routeName}/contact`} component={Contact} />
                    <Route exact path={`${routeName}/CreateEvent`} component={CreateEvent} />
                    <Route exact path={`${routeName}/tickets`} component={Tickets} />

                    {publicRoute}
                    {privateRoute}

                    <Route path="*" component={ErrorPage} />
                </Switch>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.loginResponse.results,
        isLoggedIn: state.loginResponse.isLoggedIn,
        latlong: state.favoriteReducer.latlong,
        results: state.loginResponse.results,
    };
};

const matchDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            messageAction,
            onOpen,
            getUserAction,
            error,
            getUnreadCount,
            GetUserProfileDetail,
        },
        dispatch
    );
};

export default withRouter(connect(mapStateToProps, matchDispatchToProps)(App));
