import {
  EVENT_SEARCH_DATA,
  CURRENT_EVENT_TICKET_DETAILS,
  TICKET_CART_ITEM,
  DISCOUNT_DETAILS,
  CREATE_SHOPPING_DETAILS,
  PAYMENT_INTENT_DETAILS,
  START_TIMMER,
  REMAINING_UTILIZATION,
  EVENTS_TOTAL_COUNT,
  PERSIST_SHOPING_CART_DATA,
  CLEAR_CART_SESSION_DISCOUNT_ID,
  STORE_EVENT_DATE_DETAILS,
  REMOVE_EVENT_DATE_DETAILS_BY_TICKET_ID,
  REMOVE_ALL_EVENT_DATE_DETAILS,
  GET_ALL_CHECKED_IN_USERS,
  GET_EVENT_ACTIVITIES,
  CLEAR_EVENT_ACTIVITIES,
  CLEAR_EVENT_CHECKED_IN_LIST,
  SET_USER_CHECKED_IN_LIST,
  IS_CHECKED_IN_USER_LIST_LOADING,
  IS_EVENT_ACTIVITY_LOADING
} from "../Actions/actionTypes";
import { cloneDeep } from "lodash";
const initial = {
  EventSearchData: {
    categoryName: "",
    category: "",
    searchItem: "",
    totalCounts: "",
    radius: "30",
    page: 1,
    rowOfPages: 10,
  },
  currentEventDetails: null,
  cartData: [],
  discountData: {
    DiscountType: "",
    disCountAmount: 0.0,
    disCountId: "",
    TicketsId: [],
    RemainingUtilization: "",
    ResponseCode: false,
    DiscountCode: "",
  },
  createshoppingCartDetails: null,
  paymentIntent: {
    PublicKey: null,
    paymentId: null,
  },
  DiscountUtilization: null,
  checkOutTimer: false,
  eventsCount: 0,
  persistShopingCart: {},
  EventDateDetails: {},
  eventActivityList:[],
  checkedInUsersList: [],
  totalCheckedInUsers:"",
  totalActivity:"",
  isCheckedInUsersLoading:true,
  isEventActivityListLoading:true,
};

export default (state = initial, action) => {
  switch (action.type) {
    case GET_EVENT_ACTIVITIES:
        if(action.payload.eventActivity){
          return cloneDeep({
            ...state,
            eventActivityList: action?.payload.eventActivity,
            totalActivity: action.payload.eventActivity?.[0]?.TotalCounts,
            isEventActivityListLoading:false,
          });
        } else {
          return cloneDeep({
            ...state,
            eventActivityList: [],
            totalActivity:""
          });
        };
      case CLEAR_EVENT_CHECKED_IN_LIST:
        return cloneDeep({
          ...state,
          checkedInUsersList:[],
        });
    case IS_CHECKED_IN_USER_LIST_LOADING:
          return cloneDeep({
            ...state,
            isCheckedInUsersLoading:true,
          });
    case IS_EVENT_ACTIVITY_LOADING:
      return cloneDeep({
        ...state,
        isEventActivityListLoading:true,
      });
    case GET_ALL_CHECKED_IN_USERS:
      if (action.payload.chats && action.payload.chats.length > 0) {
        return cloneDeep({
          ...state,
          checkedInUsersList: action.payload.chats,
          totalCheckedInUsers:action.payload.totalCheckedInUsers,
          isCheckedInUsersLoading: false
        })
      } else {
        return cloneDeep({
          ...state,
          checkedInUsersList: [],
          totalCheckedInUsers:""
        })
      }
    case SET_USER_CHECKED_IN_LIST:
      return cloneDeep({
        ...state,
        checkedInUsersList: [],
      });
    case CLEAR_EVENT_ACTIVITIES:
        return cloneDeep({
          ...state,
          eventActivityList: [],
          totalActivity:""
        });
    case EVENTS_TOTAL_COUNT:
      state.eventsCount = action.payload;
      return cloneDeep({ ...state });

    case REMAINING_UTILIZATION:
      state.DiscountUtilization = action.payload;

      return cloneDeep({ ...state });
    case DISCOUNT_DETAILS:
      state.discountData = action.payload;

      return cloneDeep({ ...state });

    case CREATE_SHOPPING_DETAILS:
      state.createshoppingCartDetails = action.payload;

      return cloneDeep({ ...state });
    case PAYMENT_INTENT_DETAILS:
      state.paymentIntent = action.payload;

      return cloneDeep({ ...state });
    case START_TIMMER:
      state.checkOutTimer = action.payload;
      return cloneDeep({ ...state });
    case TICKET_CART_ITEM:
      state.cartData = action.payload;
      return cloneDeep({ ...state });
    case EVENT_SEARCH_DATA:
      state.EventSearchData = action.payload;
      return cloneDeep({ ...state });
    case CURRENT_EVENT_TICKET_DETAILS:
      state.currentEventDetails = action.payload;
      return cloneDeep({ ...state });
    case PERSIST_SHOPING_CART_DATA:
      state.persistShopingCart = action.payload;
      return cloneDeep({ ...state });

    case CLEAR_CART_SESSION_DISCOUNT_ID:
      state.discountData.ResponseCode = false;
      state.persistShopingCart.ShoppingCart.CartSession.DiscountId = "";
      return cloneDeep({ ...state });

    case STORE_EVENT_DATE_DETAILS:
      const eventDateDetails = action.payload;
      const { item, ...rest } = eventDateDetails;
      if (item?.TicketId) {
        state.EventDateDetails = {
          ...state.EventDateDetails,
          [item?.TicketId]: eventDateDetails,
        };
        return cloneDeep({ ...state });
      }

    case REMOVE_EVENT_DATE_DETAILS_BY_TICKET_ID:
      const eventTicketId = action.payload;
      if (eventTicketId) {
        const removeObjectById = (parentObject, uniqueIdToRemove) => {
          if (parentObject?.hasOwnProperty(uniqueIdToRemove)) {
            // If the parentObject has the specified uniqueIdToRemove, delete it
            delete parentObject[uniqueIdToRemove];
          }
          return parentObject;
        };

        state.EventDateDetails = {
          ...removeObjectById(state?.EventDateDetails, eventTicketId),
        };
        return cloneDeep({ ...state });
      }

    case REMOVE_ALL_EVENT_DATE_DETAILS:
      state.EventDateDetails = {};
      return cloneDeep({ ...state });

    default:
      return state;
  }
};
