import React from "react";
import "./AnalyticCard.css";
import { AiFillInfoCircle } from "react-icons/ai";
import { Tooltip } from "@mui/material";
import { withStyles } from "@material-ui/styles";
import { Info } from "@material-ui/icons";
function AnalyticCards({ title, tipText, number, Icon }) {
  
    // const [limits, setLimits] = useState("")
    const CreateEventDetailsAboutToolTip = withStyles(() => ({
      tooltip: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 220,
        fontSize:20,
        border: "1px solid #dadde9",
      },
    }))(Tooltip);
    
    const titleWithToolTip = (title, mandatory = false) => {
      return (
        <span style={{ fontSize: 16, fontWeight: "bold", color: "#000000" }}>
          {/* {title} */}
          {mandatory && <span style={{ color: "red" }}> *</span>}
          <CreateEventDetailsAboutToolTip
            title={<h5>{tipText}</h5>}
          >
            <span
              style={{
                padding: "0 0 0 10px",
                cursor: "pointer",
                color: "#fff",
              }}
            >
              <Info />
            </span>
          </CreateEventDetailsAboutToolTip>
        </span>
      );
    };
  



  return (
    <div className="AnalyticCard">
      <div className="contents">
        <div style={{display:'flex',alignItems:'center'}}>
          {Icon && <Icon/>}
          <p style={{fontSize:15}}>{title}</p>
        </div>
        <h2 className="contents_text">{number || 0}</h2>
        <div className="cardIcon">
          <p style={{fontSize:"16px", }}> {titleWithToolTip(title, false)}</p>
          {/* <AiFillInfoCircle className="infoIcon" /> */}
        </div>
      </div>
    </div>
  );
}

export default AnalyticCards;
