import React, { useEffect, useState } from "react";
import styles from "./EventFooter.module.css";
import EventCardWrapper from "../../Components_UBMeEvents/EventCardWrapper/EventCardWrapper";
import { UserIconsPic } from "Components/UBMeEvents/Components_UBMeEvents/Navbar/Alphabets/UserIconsPic";
import moment from "moment";
import { Text } from "Constants";
import {
  BsArrowRightSquare,
  BsArrowRightSquareFill,
  BsArrowLeftSquare,
  BsArrowLeftSquareFill,
} from "react-icons/bs";

const EventFooter = ({
                       data,
                       AddFavorite,
                       favoriteData,
                       isLoggedIn,
                       LoginPopup,
                       results,
                       userProfileData,
                     }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(4);

  const newTimeZoneData = (data || []).map((item) => {
    const UtcToLocal = moment.utc(item.StartDateTime).local().format("MM/DD/YYYY HH:mm:ss A");
    const convertedTime = moment(UtcToLocal, "MM/DD/YYYY HH:mm:ss A").tz(item.TimeZone);
    return { ...item, StartDateTime: convertedTime };
  });

  const sortedArray = [...newTimeZoneData].sort(
      (a, b) => new moment(a.StartDateTime).format("X") - new moment(b.StartDateTime).format("X")
  );

  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentData = sortedArray.slice(firstPostIndex, lastPostIndex);

  const pages = Array.from({ length: Math.ceil(sortedArray.length / postsPerPage) }, (_, i) => i + 1);

  const handleAssigneeNextOnClick = () => {
    if (currentPage < pages.length) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handleAssigneePreviousOnClick = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };



    function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  }

  const [width] = useWindowSize();

  return (
      <div className={styles.event_footer_container}>

        {data?.length > 0 && (
            <div className={styles.event_footer_text}>
                <img
                    src={
                        userProfileData?.OrginizerDetails?.ProfileImage === null
                            ? UserIconsPic(userProfileData?.OrginizerDetails?.FirstName[0].toUpperCase())
                            : userProfileData?.OrginizerDetails?.ProfileImage
                    }
                    alt=""
                    className={styles.event_footer_image}
                    style={{ maxWidth: "100px", height: "auto" }}
                />
                <h4 className={styles.event_footer_city}>
                    {`${userProfileData?.OrginizerDetails?.FirstName} ${userProfileData?.OrginizerDetails?.LastName || ''}`}
                </h4>
                <p className={styles.event_footer_about_city}>
                    {userProfileData?.OrginizerDetails?.Bio || "I Love UBMe"}
                </p>
                <h4 className={styles.event_footer_heading_4}>
                    {`${Text.MOREEVENTSFROM} ${userProfileData?.OrginizerDetails?.FirstName?.toUpperCase()} ${userProfileData?.OrginizerDetails?.LastName ? userProfileData?.OrginizerDetails?.LastName.toUpperCase() : ''}`}
                </h4>
            </div>
        )}

        {width > 1015 && (
            <div className={styles.event_footer_card_carousel}>
              {(currentPage > 1 || currentPage < pages.length) && (
                  <div style={{ marginBottom: 20 }}>
              <span
                  style={{ marginRight: 20 }}
                  className={styles.event_footer_card_carousel_icons}
                  onClick={handleAssigneePreviousOnClick}
              >
                {currentPage > 1 ? (
                    <BsArrowLeftSquareFill size={30} />
                ) : (
                    <BsArrowLeftSquare size={30} />
                )}
              </span>
                    <span
                        style={{ marginRight: 20 }}
                        className={styles.event_footer_card_carousel_icons}
                        onClick={handleAssigneeNextOnClick}
                    >
                {currentPage < pages.length ? (
                    <BsArrowRightSquareFill size={30} />
                ) : (
                    <BsArrowRightSquare size={30} />
                )}
              </span>
                  </div>
              )}
            </div>
        )}

        {data?.length > 0 && (
            <div style={{ marginBottom: 30 }}>
              <EventCardWrapper
                  gridStyle={4}
                  isLoggedIn={isLoggedIn}
                  data={width > 1015 ? currentData : data}
                  results={results}
                  favoriteData={favoriteData}
                  LoginPopup={LoginPopup}
                  AddFavorite={AddFavorite}
              />
            </div>
        )}
      </div>
  );
};

export default EventFooter;
