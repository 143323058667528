import axiosI, { axiosE } from "../Service/service";
import {
  LOGIN,
  LOGOUT,
  GET_USER,
  REGISTER,
  RESET_PASSWORD,
  ENABLE_LOADER,
  DISABLE_LOADER,
  FLOW,
  STARTED_FROM_BEGINNING,
  HISTORY,
  LOGIN_POP_UP,
} from "./actionTypes";
import { ws } from "../App";
import store from "../store";
import { getUnreadCount } from "./messagesAction";
import * as constants from "../Constants";

export const saveUserData = (data) => {
  return (dispatch) =>
    dispatch({
      type: LOGIN,
      payload: data,
    });
};

export const loginAction = (credentials) => {
  return (dispatch) => {
    return axiosI
      .post(constants.API.userLoginApi, credentials)
      .then(({ data }) => {
        if (data.results.ResponseCode && data.results !== null) {
          const user = {
            results: { ProfileDetail: data.results },
          };

          dispatch({
            type: LOGIN,
            payload: user,
          });
          let date = new Date();
          let connection = store.getState().messageReducer.connection;

          if (connection && data.results) {
            ws.json({
              token_id: data.results.Token,
              user_id: data.results.UserId.toString(),
              type: "identity",
              isOnline: 1,
              msgid: date.getTime().toString().slice(4),
            });
          }

          getRecentChat(data.results.UserId);
          localStorage.setItem("token", data.results.Token);

          data.results &&
            localStorage.setItem("ProfileImage", data.results.ProfileImage);
          data.results &&
            localStorage.setItem("UserName", data.results.UserName);
          data.results && localStorage.setItem("UserId", data.results.UserId);

          data.results &&
            localStorage.setItem("userFullName", data.results.FirstName);

          data.results && localStorage.setItem("isLogedIn", "true");

          getUnreadCount()(dispatch);
        }
        return data;
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const getRecentChat = (userId) => {
  ws.json({
    user_id: userId.toString(),
    type: "recentchat",
    count: 20,
    index: 1,
  });
};

export const resetPasswordAction = (email) => {
  return (dispatch) => {
    return axiosI
      .post("/LinkUpsUserForgetPassword", { email })
      .then(({ data }) => {
        dispatch({
          type: RESET_PASSWORD,
          payload: data,
        });
        return data;
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const logoutAction = () => {
  return (dispatch) => {
    ws.json({
      type: "disconnect",
      userId: localStorage.getItem("UserId"),
      tokenId: localStorage.getItem("token"),
    });
    localStorage.clear();
    clearInterval(window.interval);
    dispatch({
      type: LOGOUT,
    });
  };
};

export const GetUserProfileDetail = (requestData) => {
  return (dispatch) => {
    return axiosI
      .get(
        `${constants.API.GetUserProfileDetail}/${requestData.UserId}/${requestData.OtherUserId}/${requestData.lat}/${requestData.lng}`
      )
      .then(({ data }) => {
        if (data.results.ResponseCode && requestData.UserId === requestData.OtherUserId) {
          dispatch({
            type: LOGIN,
            payload: data,
          });
        }
        return data;
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const ValidateUser = (userName) => {
  return (dispatch) => {
    return axiosI
      .get(`${constants.API.ValidateUser}/${userName}`)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const registerAction = (registerationData) => {
  return (dispatch) => {
    dispatch({ type: ENABLE_LOADER });
    return axiosI
      .post("/LinkUpsUserRegistrationWithReferredBy", registerationData)
      .then(({ data }) => {
        dispatch({ type: DISABLE_LOADER });
        const user = {
          results: { ProfileDetail: data.results },
        };
        dispatch({
          type: REGISTER,
          payload: user,
        });
        let date = new Date();
        let connection = store.getState().messageReducer.connection;

        if (connection && data.results) {
          ws.json({
            token_id: data.results.Token,
            user_id: data.results.UserId.toString(),
            type: "identity",
            isOnline: 1,
            msgid: date.getTime().toString().slice(4),
          });
        }

        getRecentChat(data.results.UserId);
        localStorage.setItem("token", data.results.Token);
        localStorage.setItem("token", data?.results.Token);
        localStorage.setItem("UserId", data?.results.UserId);
        localStorage.setItem("userFullName", data?.results.FirstName);
        localStorage.setItem("UserName", data?.results?.UserName);

        data.results && localStorage.setItem("isLogedIn", "true");

        getUnreadCount()(dispatch);

        return data;
      })
      .catch((err) => {
        dispatch({ type: DISABLE_LOADER });
        console.log(err);
      });
  };
};

export const fetchSuggestions = (data) => {
  return (dispatch) => {
    return axiosI.post("/UsernameSuggestions", data).then(({ data }) => {
      dispatch({
        type: "FETCH_SUGGESTIONS",
        payload: data,
      });
    });
  };
};

export const userCheckIn = (data) => {
  axiosI
    .post("/UserCheckIn", data)
    .then((response) => {
      // console.log(response.data);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const userCheckOut = (data) => {
  axiosI
    .post("/UserCheckOutPlace", data)
    .then((response) => {
      // console.log(response.data);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getGraphDataAction = (endPoint, startDate, endDate) => {
  return async (dispatch) => {
    let id = localStorage.getItem("UserId");
    try {
      const { data } = await axiosE.post(endPoint, {
        OrganizerId: id,
        StartDate: startDate, // startDate
        EndDate: endDate, // endDate
      });
      if (data.results.ResponseCode) {

        return JSON.parse(data.results.Data);
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const SalesListByOrganizerId = (searchdata) => {
  return async (dispatch) => {
    // let id = localStorage.getItem("UserId");
    try {
      // {"OrganizerId":"359","Status":"Draft/Published","SearchTerm":"", "PageNumber":"1", "PageSize":"50"}
      const { data } = await axiosE.post(
        constants.API.SalesListByOrganizerId,
        searchdata
      );
      // if (data.results.ResponseCode) {
      // console.log("###############",data.results.Data);
      return data;
      // }
    } catch (err) {
      console.log(err);
    }
  };
};

export const getUserAction = () => {
  return (dispatch) => {
    let id = localStorage.getItem("UserId");
    return axiosI
      .get(`/GetBusinessDetails/${id}`)
      .then(({ data }) => {
        if (data.results.ResponseCode) {
          localStorage.setItem("userEmail", data.results.Email);
          localStorage.setItem("UserId", data.results.UserId);
          dispatch({
            type: GET_USER,
            payload: data,
          });
        } else {
          logoutAction()(dispatch);
        }
        return data;
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const enableLoader = () => {
  return (dispatch) => dispatch({ type: ENABLE_LOADER });
};

export const LoginPopup = (data) => {

  return (dispatch) => dispatch({ type: LOGIN_POP_UP, payload: data });
};

export const SetHistory = (history) => {
  return (dispatch) => dispatch({ type: HISTORY, payload: history });
};
export const disableLoader = () => {
  return (dispatch) => dispatch({ type: DISABLE_LOADER });
};

export const flow = (flow) => (dispatch) => {
  dispatch({
    type: FLOW,
    payload: flow,
  });
};

export const startedFromBeginning = () => (dispatch) => {
  dispatch({
    type: STARTED_FROM_BEGINNING,
    payload: true,
  });
};
