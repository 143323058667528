import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

const styles={
    grid:{
        backgroundColor: '#EB4C42',
        padding:0,
        marginTop: -20,
        marginBottom:1,
        height: 40,
        color: '#fff',
        fontSize: '16px',
        lineHeight:'5.2vh',
    },
    button:{
        color:'#fff',
        fontSize:'14px',
        float:'right'
    }
};

class ErrorComponent extends React.Component{
    componentDidMount(){
        setTimeout(()=>{
            this.props.clickMethod(false);
        },3000)
    }

    render(){
        const { classes } = this.props;
        return(
            <Grid container className={classes.grid}>
                <Grid item sm={12} style={{marginLeft:10}}>
                    {this.props.msg}
                </Grid>
            </Grid>
        )
    }
}
export default withStyles(styles)(ErrorComponent);