import React, { useEffect, useState } from "react";
import "./UserAccount.css";
import { connect } from "react-redux";
import * as constants from "Constants";
import {
  validateEmail,
  ValidateName,
  validatePassword,
  validatePhoneNo,
  validateUserName,
} from "Helper/Validations";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import Modal from "../Components_UBMeEvents/modal/Modal";
import ResetPassword from "./ResetPassword/ResetPassword";
import { UserIconsPic } from "../Components_UBMeEvents/Navbar/Alphabets/UserIconsPic";
import FavoriteImage from "images/Account.jpg";
import Navbar from "../Components_UBMeEvents/Navbar/Navbar";
import Footer from "../Components_UBMeEvents/Footer/Footer";
import axiosI from "Service/service";
import { Redirect } from "react-router-dom";
import { routeName } from "Service/env";
import { FiChevronDown } from "react-icons/fi";
import {
  getUserAction,
  saveUserData,
  GetUserProfileDetail,
} from "Actions/index";
import UseOutsideClick from "../Components_UBMeEvents/OutSideClick/UseOutsideClick";
import { useRef } from "react";
import moment from "moment";
import { UserCoverPhoto } from "./BusinessProfile";
import DatePicker from "react-modern-calendar-datepicker";
const UserAccount = ({
  results,
  saveUserData,
  latlong,
  dispatch,
  GetUserProfileDetail,
}) => {
  const [visible, setVisible] = useState(false);
  const [saveBio, setSaveBio] = useState(false);
  const closeModal = () => setVisible(false);
  const openModal = () => setVisible(true);
  const [editBio, setEditBio] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(false);
  const inputRef = useRef(null);
  const [userBio, setUserBio] = useState("");
  const [formData, setFormData] = useState({
    Bio: null,
    FirstName: "",
    Email: "",
    Mobile: "",
    Gender: "",
    CustomGender: "",
    Birthday: "",
    UserName: "",
  });
  const [phoneNumber, setPhoneNumber] = useState("");
  const [formErrors, setFormErrors] = useState({
    FirstNameError: "",
    UserNameError: "",
    EmailError: "",
    GenderError: "",
    MobileError: "",
  });

  const [suggestUserName, setSuggestUserName] = useState([]);
  const [emailError, setEmailError] = useState("");
  const [userNameError, setUserNameError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const { FirstName, Email, Mobile, Gender, Birthday, UserName } = formData;
  const [countDown, setCountdown] = useState("");

  useEffect(() => {
    let Userdata = {
      UserId: results?.ProfileDetail?.UserId,
      OtherUserId: results?.ProfileDetail?.UserId,
      lat: latlong.lat,
      lng: latlong.lng,
    };
    dispatch(GetUserProfileDetail(Userdata));
  }, []);

  let apiRequestData = {
    EmailId: formData.Email,
    Username: formData.UserName,
    Name: formData.FirstName,
  };

  useEffect(() => {
    if (validateEmail(formData.Email) === "") {
      if (formData.Email && isFirstRender) {
        axiosI
          .get(`${constants.API.VerifyAccountEmail}/${apiRequestData.EmailId}`)
          .then(async ({ data }) => {
            if (
              data.results.Message === "Email exist" &&
              results?.ProfileDetail.Email !== apiRequestData.EmailId
            ) {
              setEmailError("Email already exists");
            } else {
              setEmailError("");
            }
          })
          .catch((err) => {});
      }
    }
  }, [formData.Email]);

  useEffect(() => {
    if (
      formData.Email &&
      formData.FirstName &&
      formData.UserName &&
      isFirstRender
    ) {
      if (validateUserName(formData.UserName) === "") {
        axiosI
          .post(constants.API.VerifyAccountUserName, apiRequestData)
          .then(async ({ data }) => {
            if (
              data.results.Message === "username exist" &&
              results?.ProfileDetail.UserName !== formData.UserName
            ) {
              setUserNameError("Username already exists");
              setSuggestUserName(data.results.Suggestions);
            } else {
              setUserNameError("");
            }
          })
          .catch((err) => {});
      } else {
        // setSuggestUserName([]);
        setUserNameError("");
      }
    }
    if (formData.UserName === "") {
      setSuggestUserName([]);
      setUserNameError("");
    }
  }, [formData.UserName]);

  UseOutsideClick(
    () => {
      if (editBio) {
        setEditBio(false);

        if (userBio !== "") {
          setEditBio(false);
          setFormData({ ...formData, Bio: userBio });
        }
      }
    },
    ".Menu-list-modal",
    inputRef
  );

  const handleCahngeInput = (e) => {
    const name = e.target.name;
    let value = e.target.value;

    let formDataErrors = { ...formErrors };
    let fomatedNmber = "";
    let cleaned = "" + value.replace(/\D/g, "");
    for (let index = 0; index < cleaned.length; index++) {
      if (index == 0) {
        fomatedNmber = "";
      } else if (index == 3) {
        fomatedNmber = fomatedNmber + "";
      } else if (index == 6) {
        fomatedNmber = fomatedNmber + "";
      }
      fomatedNmber = fomatedNmber + cleaned[index];
    }

    if (name === "Mobile") {
      if (fomatedNmber.length > 9) {
        if (validatePhoneNo(value) === "") {
          axiosI
            .get(`${constants.API.VerifyAccountPhoneNumber}/${fomatedNmber}`)
            .then(async ({ data }) => {
              if (
                data.results.Message === "Phone Number exist" &&
                results?.ProfileDetail.PhoneNumber !== fomatedNmber
              ) {
                setPhoneNumberError(data.results.Message);
              } else {
                setPhoneNumberError("");
                setPhoneNumber(fomatedNmber);
              }
            })
            .catch((err) => {});
        } else {
          setPhoneNumberError("");
        }
      } else {
        setPhoneNumberError("");
      }

      // console.log("fomatedNmber====>>>>>>",fomatedNmber);
      if (!/[a-zA-Z]/.test(value)) {

        let cleaned = "" + value.replace(/\D/g, "");
        for (let index = 0; index < cleaned.length; index++) {
          if (index == 0) {
            value = "(";
          } else if (index == 3) {
            value = value + ") ";
          } else if (index == 6) {
            value = value + "-";
          }
          value = value + cleaned[index];
        }
      } else {
        value = formData.Mobile;
      }
    }

    switch (name) {
      case "FirstName":
        formDataErrors.FirstNameError = ValidateName(value);
        break;
      case "UserName":
        formDataErrors.UserNameError = validateUserName(value);

        break;
      case "Email":
        formDataErrors.EmailError = validateEmail(value);

        break;
      case "Mobile":
        formDataErrors.MobileError = validatePhoneNo(value);

        break;

      default:
        break;
    }

    setFormErrors(formDataErrors);
    setIsFirstRender(true);
    setFormData({ ...formData, [name]: value });
  };
  // const scrollToTop = () => {
  //   window.scroll({
  //     top: 0,
  //     left: 0,
  //     behavior: "smooth",
  //   });
  // };

  function randomIntFromInterval(min, max) {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  useEffect(() => {
    // scrollToTop();

    let fomatedNmber = "";

    if (results?.ProfileDetail?.PhoneNumber) {
      let cleaned = "" + results?.ProfileDetail?.PhoneNumber.replace(/\D/g, "");
      for (let index = 0; index < cleaned.length; index++) {
        if (index == 0) {
          fomatedNmber = "(";
        } else if (index == 3) {
          fomatedNmber = fomatedNmber + ") ";
        } else if (index == 6) {
          fomatedNmber = fomatedNmber + "-";
        }
        fomatedNmber = fomatedNmber + cleaned[index];
      }
    }

    setFormData({
      ...formData,
      Bio: results?.ProfileDetail?.Bio,
      FirstName: results?.ProfileDetail?.FirstName,
      Email: results?.ProfileDetail?.Email,
      Mobile: fomatedNmber,
      Gender: results?.ProfileDetail?.Gender,
      Birthday: results?.ProfileDetail?.BirthDay,
      UserName: results?.ProfileDetail?.UserName,
    });

    if (results && results.ProfileDetail?.ProfileImage !== null) {
      setImage(results?.ProfileDetail?.ProfileImage);
    } else {
      setImage(
        UserIconsPic(results?.ProfileDetail?.FirstName[0]?.toUpperCase())
      );
    }
    if (results && results?.ProfileDetail?.CoverImage !== null) {
      setCoverImage(results?.ProfileDetail?.CoverImage);
    } else {
      setCoverImage(UserCoverPhoto(randomIntFromInterval(1, 4)));
    }
    // c
  }, [results]);

  const [image, setImage] = useState("");
  const [coverImage, setCoverImage] = useState("");

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object

        baseURL = reader.result;

        resolve(baseURL);
      };

    });
  };

  const Footerdata = {
    Add1: "wefw",
    Add2: "wewee",
    City: "wr",
    Country: "wefew",
    Region: "jnfvkds",
    PostalCode: "893489",
  };

  const stringify = JSON.stringify(Footerdata);

  const SetProfilePic = async (event) => {
    if (event.target.files) {
      setImage(URL.createObjectURL(event.target.files[0]));

      let file = event.target.files[0];

      getBase64(file)
        .then((result) => {
          file["base64"] = result;


          var base64 = result.split(",")[1];

          axiosI
            .post(constants.API.UploadUserProfileImage, {
              UserId: results?.ProfileDetail.UserId,
              ProfileImage: base64,
            })
            .then(async ({ data }) => {
              let Userdata = {
                UserId: results?.ProfileDetail.UserId,
                OtherUserId: results?.ProfileDetail.UserId,
                lat: latlong.lat,
                lng: latlong.lng,
              };
              if (data.results.ResponseCode) {
                dispatch(GetUserProfileDetail(Userdata));
              }
            })
            .catch((err) => {});
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const SetCoverPhoto = (event) => {
    if (event.target.files) {
      setCoverImage(URL.createObjectURL(event.target.files[0]));

      let file = event.target.files[0];

      getBase64(file)
        .then((result) => {
          file["base64"] = result;


          var base64 = result.split(",")[1];

          axiosI
            .post(constants.API.UploadUserCoverImage, {
              UserId: results?.ProfileDetail.UserId,
              CoverImage: base64,
            })
            .then(async ({ data }) => {
              let Userdata = {
                UserId: results?.ProfileDetail.UserId,
                OtherUserId: results?.ProfileDetail.UserId,
                lat: latlong.lat,
                lng: latlong.lng,
              };
              if (data.results.ResponseCode) {
                dispatch(GetUserProfileDetail(Userdata));
              }
            })
            .catch((err) => {});
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const updateBio = () => {
    let payload = {
      Bio: formData.Bio,
      UserId: results?.ProfileDetail.UserId,
    };

    axiosI
      .post(constants.API.UpdateUserData, payload)
      .then(async ({ data }) => {
        let Userdata = {
          UserId: results?.ProfileDetail.UserId,
          OtherUserId: results?.ProfileDetail.UserId,
          lat: latlong.lat,
          lng: latlong.lng,
        };
        if (data.results.ResponseCode) {
          dispatch(GetUserProfileDetail(Userdata));
          toast.success("Bio updated successfully", {
            closeButton: true, // or MyCustomCloseButton
          });
        }
      })
      .catch((err) => {});
  };
  const handleChange = (e) => {
    setUserBio(formData.Bio);
    setSaveBio(!saveBio);
  };
  const resetuserpassword = (data) => {
    let payload = {
      UserId: results?.ProfileDetail.UserId,
      OldPassword: data.oldPassword,
      Password: data.newPassword,
    };

    axiosI
      .post(constants.API.UpdateUserData, payload)
      .then(async ({ data }) => {
        closeModal();

        let Userdata = {
          UserId: results?.ProfileDetail.UserId,
          lat: latlong.lat,
          lng: latlong.lng,
        };
        if (data.results.ResponseCode) {
          // dispatch(GetUserProfileDetail(Userdata));
          toast("Password reset successfully");
        }
      })
      .catch((err) => {});
    return true;
  };
  const UpdateUserprofile = (e) => {
    e.preventDefault();
    let payload = {
      Email:
        formData.Email === results?.ProfileDetail.Email ? "" : formData.Email,
      Bio: formData.Bio,
      Name: formData.FirstName,
      UserName:
        formData.UserName === results?.ProfileDetail.UserName
          ? ""
          : formData.UserName,
      Phone:
        phoneNumber === results?.ProfileDetail.PhoneNumber ? "" : phoneNumber,
      UserId: results?.ProfileDetail.UserId,
      OldPassword: "",
      Password: "",
      Birthday: formData.Birthday,
      Gender:
        formData.Gender === "Custom" ? formData.CustomGender : formData.Gender,
    };
    if (
      !emailError &&
      !userNameError &&
      !phoneNumberError &&
      !formErrors.EmailError &&
      !formErrors.FirstNameError &&
      !formErrors.UserNameError &&
      !formErrors.MobileError
    ) {
      axiosI
        .post(constants.API.UpdateUserData, payload)
        .then(async ({ data }) => {
          let Userdata = {
            UserId: results?.ProfileDetail.UserId,
            lat: latlong.lat,
            lng: latlong.lng,
          };
          if (data.results.ResponseCode) {
            localStorage.setItem("userFullName", formData.FirstName);
            // dispatch(GetUserProfileDetail(Userdata));
            // toast.success("UnSubscribe successfully");
            toast("Profile updated successfully");
          }
        })
        .catch((err) => {});
    }
  };

  const getDate = (date) => {
    let newDate = date === "" ? null : date;

    if (newDate) {
      const dateObj = {
        year: parseInt(moment(newDate).format("YYYY")),
        month: parseInt(moment(newDate).format("MM")),
        day: parseInt(moment(newDate).format("DD")),
      };
      return dateObj;
    } else {
      return null;
    }
  };

  const getDateFormat = (date) => {
    if (date) {
      const dateObj = getDate(date);
      return `${dateObj?.month}/${dateObj?.day}/${dateObj?.year}`;
    } else {
      return null;
    }
  };

  const ChangeStartDate = (date) => {

    const completeDate = `${date.month}-${date.day}-${date.year}`;
    setFormData({
      ...formData,
      Birthday: moment(completeDate, "MM/DD/YYYY").format("YYYY/MM/DD"),
    });
  };

  return (
    <>
      {localStorage.getItem("UserId") === null ? (
        <Redirect to={`${routeName}`} />
      ) : null}
      <Navbar />
      <div>
        <ToastContainer
          className="toaster-container"
          position="top-right"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {visible && (
          <Modal closeModal={closeModal}>
            <ResetPassword
              UserId={results?.ProfileDetail.UserId}
              setPassword={(data) => resetuserpassword(data)}
            />
          </Modal>
        )}
        <div className="">
          <img
            style={{ width: "100%", height: "100%" }}
            src={FavoriteImage}
            alt=""
          />
        </div>
        {/* <div className="favorite-container">

        </div> */}

        <div class="wrapper">
          <div class="profile">
            <div class="content_acc">
              <div style={{ position: "relative" }}>
                <div className="user_cover_photo">
                  <img
                    className="cover-pic"
                    // style={{ widht: 200, height: 200, objectFit: "contain" }}
                    src={coverImage}
                    alt=""
                  />
                  <input
                    className="uploadCoverInput"
                    type="file"
                    name="profile_pic"
                    id="newCoverPhoto"
                    //   accept="image/*"
                    style={{ opacity: 0 }}
                    onChange={SetCoverPhoto}
                  />
                  <label for="newCoverPhoto" class="upload-file-cover-block">
                    <div class="text-center">
                      <div class="mb-2">
                        <i class="fa fa-camera fa-2x"></i>
                      </div>
                      <div class="text-uppercase">
                        {constants.UserAccountText.update} <br />{" "}
                        {constants.UserAccountText.coverPhoto}
                      </div>
                    </div>
                  </label>
                </div>
                <div class="user-profile-pic">
                  <div class="pic-holder">
                    {image === "" ? (
                      <div
                        style={{
                          backgroundColor: "lightgreen",
                          borderRadius: 50,
                          width: 100,
                          height: 100,
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <h1 style={{ margin: 0 }}>
                          {UserIconsPic(FirstName[0]?.toUpperCase())}
                        </h1>
                      </div>
                    ) : (
                      <img id="profilePic" className="pic" src={image} />
                    )}

                    <input
                      className="uploadProfileInput"
                      type="file"
                      name="profile_pic"
                      id="newProfilePhoto"
                      //   accept="image/*"
                      style={{ opacity: 0 }}
                      onChange={SetProfilePic}
                    />
                    <label for="newProfilePhoto" class="upload-file-block">
                      <div class="text-center">
                        <div class="mb-2">
                          <i class="fa fa-camera fa-2x"></i>
                        </div>
                        <div class="text-uppercase">
                          {constants.UserAccountText.update} <br />{" "}
                          {constants.UserAccountText.profilePicture}
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
              </div>

              <form action="">
                <div className="user_bio">
                  {editBio && (
                    <React.Fragment>
                      <textarea
                        className="Menu-list-modal"
                        maxlength="140"
                        ref={inputRef}
                        type="text"
                        autoFocus={true}
                        onChange={(e) => setUserBio(e.target.value)}
                        placeholder="Bio"
                        // onBlur={handleChange}
                        style={{
                          resize: "none",
                          padding: 10,
                          textAlign: "center",
                          overflowWrap: "break-word",
                          width: "100%",
                          height: 80,

                          // border: "none",
                        }}
                        onFocus={handleChange}
                        value={userBio}
                      />
                      <span className="user_bio_ind">
                        {userBio.length} / 140
                      </span>
                    </React.Fragment>
                  )}
                  {!editBio && (
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => setEditBio(true)}
                    >
                      <p style={{ padding: 10 }}>
                        {formData.Bio === null ? "I love UBMe" : formData.Bio}
                      </p>
                    </div>
                  )}

                  <span style={{ color: "red", fontSize: 20 }}></span>
                </div>
                {/* {editBio && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: 10,
                    }}
                  >
                    <button
                    disabled={userBio === "" ? true : false}
                      onClick={(e) => {
                        e.preventDefault();
                        setEditBio(false);
                        setFormData({...formData, Bio:userBio});
                      }}
                      style={{
                        backgroundColor:userBio === "" ? "#f2b8f5" : "#ca3dd9",
                        border: "none",
                        outline: "none",
                        color: "#fff",
                        borderRadius: 5,
                        paddingLeft: 30,
                        paddingRight: 30,
                        paddingBottom: 5,
                        paddingTop: 5,
                      }}
                    >
                      Save
                    </button>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setEditBio(false);
                        setUserBio("")
                      }}
                      style={{
                        color: "#fff",
                        border: "none",
                        outline: "none",
                        borderRadius: 5,
                        backgroundColor: "#ca3dd9",
                        paddingLeft: 30,
                        paddingRight: 30,
                        paddingBottom: 5,
                        paddingTop: 5,
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                )} */}

                <div className="user_folloswers_section">
                  <div style={{ borderLeft: "none" }} className="user_follower">
                    <h2>{results.ProfileDetail?.FriendCount}</h2>
                    <p>{constants.UserAccountText.friends} </p>
                  </div>
                  <div className="user_follower">
                    <h2>{results.ProfileDetail?.FollowerCount}</h2>
                    <p>{constants.UserAccountText.followers} </p>
                  </div>
                  <div
                    style={{ borderRight: "none" }}
                    className="user_follower"
                  >
                    <h2>{results.ProfileDetail?.FolloweingCount}</h2>
                    <p>{constants.UserAccountText.following} </p>
                  </div>
                </div>
                <fieldset>
                  <div class="grid-35">
                    <label for="fname">{constants.UserAccountText.name} </label>
                  </div>
                  <div class="grid-65">
                    <input
                      placeholder="Name"
                      className="input_feilds"
                      type="text"
                      id="fname"
                      tabindex="1"
                      name="FirstName"
                      value={FirstName}
                      onChange={handleCahngeInput}
                    />
                    <div style={{ color: "red", fontSize: 12 }}>
                      {formErrors.FirstNameError !== ""
                        ? formErrors.FirstNameError
                        : ""}
                    </div>
                  </div>
                </fieldset>

                <fieldset>
                  <div class="grid-35">
                    <label for="username">
                      {constants.UserAccountText.username}{" "}
                    </label>
                  </div>
                  <div class="grid-65">
                    <input
                      placeholder="Username"
                      className="input_feilds"
                      type="text"
                      id="username"
                      tabindex="2"
                      name="UserName"
                      value={UserName}
                      onChange={handleCahngeInput}
                    />
                    {formErrors.UserNameError !== "" ? (
                      <div style={{ color: "red", fontSize: 12 }}>
                        {formErrors.UserNameError}
                      </div>
                    ) : (
                      ""
                    )}

                    {userNameError !== "" ? (
                      <div className="error">
                        <span>{userNameError}</span>
                      </div>
                    ) : null}
                    {suggestUserName.length > 0 && (
                      <div
                        style={{
                          marginTop: userNameError !== "" ? 6 : 20,
                          flexWrap: "wrap",
                        }}
                        className="suggetios"
                      >
                        {suggestUserName.length > 0 &&
                          suggestUserName.map((usernames, index) => (
                            <h6
                              key={index}
                              onClick={() => {
                                setFormData({
                                  ...formData,
                                  UserName: usernames,
                                });
                                // clearErrors();
                              }}
                              style={{
                                marginBottom: 8,
                                marginRight: 10,
                                cursor: "pointer",
                                color: "#000000",
                              }}
                            >
                              {usernames}
                            </h6>
                          ))}
                      </div>
                    )}
                  </div>
                </fieldset>
                <fieldset>
                  <div class="grid-35">
                    <label for="email">
                      {constants.UserAccountText.email}{" "}
                    </label>
                  </div>
                  <div class="grid-65">
                    <input
                      placeholder="Email"
                      className="input_feilds"
                      type="email"
                      id="email"
                      tabindex="6"
                      name="Email"
                      value={Email}
                      onChange={handleCahngeInput}
                    />
                    {formErrors.EmailError !== "" ? (
                      <div style={{ color: "red", fontSize: 12 }}>
                        {formErrors.EmailError}
                      </div>
                    ) : (
                      ""
                    )}

                    {emailError !== "" ? (
                      <div className="error">
                        <span>{emailError}</span>
                      </div>
                    ) : null}
                  </div>
                </fieldset>

                <fieldset>
                  <div class="grid-35">
                    <label for="number">
                      {constants.UserAccountText.phone}{" "}
                    </label>
                  </div>
                  <div class="grid-65">
                    <input
                      placeholder="phone"
                      type="text"
                      className="input_feilds"
                      autocomplete="chrome-off"
                      // id="number"
                      tabindex="2"
                      name="Mobile"
                      value={Mobile}
                      maxLength="14"
                      onChange={handleCahngeInput}
                    />
                    {formErrors.MobileError !== "" ? (
                      <div style={{ color: "red", fontSize: 12 }}>
                        {formErrors.MobileError}
                      </div>
                    ) : (
                      ""
                    )}

                    {phoneNumberError !== "" ? (
                      <div className="error">
                        <span>{phoneNumberError}</span>
                      </div>
                    ) : null}
                  </div>
                </fieldset>

                <fieldset>
                  <div class="grid-35">
                    <label for="date">
                      {constants.UserAccountText.birthday}{" "}
                    </label>
                  </div>
                  <div class="grid-65">
                    <DatePicker
                      value={getDate(Birthday)}
                      onChange={ChangeStartDate}
                      inputClassName="date_picker_input_2"
                      colorPrimary="#851679" // added this
                      // minimumDate={getDate(moment())}
                      wrapperClassName="date_picker_wrapper"
                      // maximumDate={getDate(moment("2010/01/01"))}
                      inputPlaceholder="Select Your Birthdate"
                      formatInputText={(date) => getDateFormat(Birthday)}
                    />

                    {/*
                    <input
                      placeholder="Birthday"
                      className="input_feilds"
                      type="date"
                      id="date"
                      max="2010-12-30"
                      tabindex="2"
                      name="Birthday"
                      value={Birthday}
                      onChange={handleCahngeInput}
                    /> */}
                  </div>
                </fieldset>
                <fieldset>
                  <div class="grid-35">
                    <label for="forHire">
                      {constants.UserAccountText.gender}{" "}
                    </label>
                  </div>
                  <div class="grid-65">
                    {formData.Gender === "Custom" ? (
                      <div style={{ position: "relative" }}>
                        <input
                          placeholder="Enter your gender (Optional)"
                          className="input_feilds"
                          type="text"
                          id="date"
                          tabindex="2"
                          name="CustomGender"
                          value={formData.CustomGender}
                          onChange={handleCahngeInput}
                        />
                        <span
                          style={{
                            cursor: "pointer",
                            position: "absolute",
                            top: 18,
                            right: 8,
                          }}
                          onClick={() =>
                            setFormData({ ...formData, Gender: "" })
                          }
                        >
                          <FiChevronDown />
                        </span>
                      </div>
                    ) : (
                      <select
                        name="Gender"
                        onChange={handleCahngeInput}
                        style={{ width: "100%" }}
                        id="forHire"
                        tabindex="7"
                        value={Gender}
                      >
                        {Gender !== "Male" && Gender !== "Female" ? (
                          <option value={Gender}>{Gender}</option>
                        ) : null}

                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Custom">Custom</option>
                      </select>
                    )}
                  </div>
                </fieldset>
                <fieldset>
                  <div class="grid-35">
                    <label for="reset">
                      {constants.UserAccountText.password}{" "}
                    </label>
                  </div>
                  <div class="grid-65">
                    <a
                      id="reset"
                      onClick={openModal}
                      style={{ color: "#e23beb" }}
                    >
                      {constants.UserAccountText.resetpassword}
                    </a>
                  </div>
                </fieldset>
                <div className="account_submit">
                  <button
                    disabled={
                      !emailError &&
                      !userNameError &&
                      !phoneNumberError &&
                      !formErrors.EmailError &&
                      !formErrors.FirstNameError &&
                      !formErrors.UserNameError &&
                      !formErrors.MobileError
                        ? false
                        : true
                    }
                    className="account_btn"
                    style={{
                      backgroundColor:
                        !emailError &&
                        !userNameError &&
                        !phoneNumberError &&
                        !formErrors.EmailError &&
                        !formErrors.FirstNameError &&
                        !formErrors.UserNameError &&
                        !formErrors.MobileError
                          ? "#e23beb"
                          : "#f2b8f5",
                    }}
                    onClick={(e) => {
                      UpdateUserprofile(e);
                      // handleFileUpload();
                    }}
                  >
                    {constants.UserAccountText.save}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    latlong: state.favoriteReducer.latlong,
    results: state.loginResponse.results,
  };
};

const matchDispatchToProps = (dispatch) => {
  return {
    // AddToFavorite: () => dispatch(AddToFavorite),
    // SetCardData: () => dispatch(SetCardData),
    // LoginPopup: () => dispatch(LoginPopup),
    GetUserProfileDetail,
    saveUserData,
    dispatch,
  };
};

export default connect(mapStateToProps, matchDispatchToProps)(UserAccount);
