import React, { Component } from "react";
import ModernDatepicker from "react-modern-datepicker";
import TimePicker from "rc-time-picker";
import { withStyles } from "@material-ui/styles";
import { styleClasses } from "./ticketsStyleClasses";
import moment from "moment";
import CreateEventErrorMessage from "../createEventErrorMessage";
import DatePicker from "react-modern-calendar-datepicker";
const styles = {
  inputLabel: styleClasses.inputLabel,
};

export class DateAndTime extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: { show: false, message: "" },
    };
  }

  getMinDate = (date) => {
    const today = moment();
    if (date.isBefore(today)) {
      return this.getDate(today);
    } else {
      return this.getDate(date);
    }
  };

  getDate = (date) => {
    const dateObj = {
      year: parseInt(date.format("YYYY")),
      month: parseInt(date.format("MM")),
      day: parseInt(date.format("DD")),
    };
    return dateObj;
  };

  getGateFormat = (date) => {
    const dateObj = this.getDate(date);
    return `${dateObj.month}/${dateObj.day}/${dateObj.year}`;
  };

  mandatoryFieldTag = () => {
    return <span style={{ color: "red" }}> *</span>;
  };
  timeAndDateInput = (title) => {
    const {
      varStartDate,
      varEndDate,
      varEndTime,
      varStartTime,
      maxEndDate,
      minDate,
    } = this.props;

    const { classes } = this.props;
    const {
      funcChangeStartDate,
      funcChangeEndDate,
      funcChangeStartTime,
      funcChangeEndTime,
    } = this.props;

    return (
      <div className="date_time_pickers">
        <div style={{ position: "relative" }}>
          <span className={classes.inputLabel}>
            {title} Date {this.mandatoryFieldTag()}
          </span>
          <DatePicker
            value={
              title == "Start"
                ? this.getDate(varStartDate)
                : this.getDate(varEndDate)
            }
            onChange={(date) =>
              title == "Start"
                ? funcChangeStartDate(date)
                : funcChangeEndDate(date)
            }
            inputClassName="date_picker_input"
            colorPrimary="#851679" // added this
            minimumDate={
              title == "Start"
                ? this.getDate(moment())
                : this.getDate(varStartDate)
            }
            // maximumDate={this.getDate(maxEndDate)}
            inputPlaceholder="Select a day"
            formatInputText={(date) => {
              return title == "Start"
                ? this.getGateFormat(varStartDate)
                : this.getGateFormat(varEndDate);
            }}
          />
        </div>
        <div>
          <span className={classes.inputLabel}>
            {title} Time {this.mandatoryFieldTag()}
          </span>
          <TimePicker
            placeholder="Select Time"
            use12Hours
            showSecond={false}
            focusOnOpen={true}
            format="hh:mm A"
            value={title === "Start" ? varStartTime : varEndTime}
            onChange={(e) =>
              title === "Start" ? funcChangeStartTime(e) : funcChangeEndTime(e)
            }
            className="time_picker"
            style={{ margin: "15px !important" }}
            disabledHours={() =>
              title === "Start"
                ? this.getDisabledHoursStart()
                : this.getDisabledHoursEnd()
            }
            disabledMinutes={() =>
              title === "Start"
                ? this.getDisabledMinutesStart()
                : this.getDisabledMinutesEnd()
            }
          />
        </div>
      </div>
    );
  };
  getDisabledHoursStart = () => {
    let hours = [];

    const { varStartTime, varStartDate, minDate, maxEndDate } = this.props;

    const startDateFormatted = varStartDate.format("DD:MM:YYYY");
    const minDateFormatted = minDate.format("DD:MM:YYYY");
    const maxEndDateFormatted = maxEndDate.format("DD:MM:YYYY");
    const todayFormatted = moment().format("DD:MM:YYYY");
    if (startDateFormatted === todayFormatted) {
      const currentHour = moment().hour();
      for (let i = 0; i < currentHour; i++) {
        hours.push(i);
      }
    }
    if (startDateFormatted === maxEndDateFormatted) {
      for (let j = 24; j > maxEndDate.hour(); j--) {
        hours.push(j);
      }
    }
    return hours;
  };
  getDisabledHoursEnd = () => {
    let hours = [];

    const { varStartDate, varEndDate, varStartTime, minDate, maxEndDate } =
      this.props;

    const startDateFormatted = varStartDate.format("DD:MM:YYYY");
    const endDateFormatted = varEndDate.format("DD:MM:YYYY");
    const maxEndDateFormatted = maxEndDate.format("DD:MM:YYYY");
    const minDateFormatted = minDate.format("DD:MM:YYYY");

    if (startDateFormatted === endDateFormatted) {
      for (let i = 0; i <= varStartTime.hour(); i++) {
        hours.push(i);
      }
    }
    if (endDateFormatted === maxEndDateFormatted) {
      for (let j = 24; j > maxEndDate.hour(); j--) {
        hours.push(j);
      }
    }
    return hours;
  };

  handleErrorModal = () => {
    this.setState({
      error: { show: false, message: "" },
    });
  };

  getDisabledMinutesStart = () => {
    var minutes = [];
    const { varStartDate, varEndDate, varStartTime, minDate, maxEndDate } =
      this.props;
    const startDateFormatted = varStartDate.format("DD:MM:YYYY");
    const today = moment().format("DD:MM:YYYY");
    const endDateFormatted = varEndDate.format("DD:MM:YYYY");
    const maxEndDateFormatted = maxEndDate.format("DD:MM:YYYY");
    const minDateFormatted = minDate.format("DD:MM:YYYY");

    if (today === startDateFormatted) {
      if (varStartTime.hour() === moment().hour()) {
        for (var i = 0; i < moment().minute(); i++) {
          minutes.push(i);
        }
      }
    }
    if (startDateFormatted === maxEndDateFormatted) {
      if (varStartTime.hour() === maxEndDate.hour()) {
        for (var i = 60; i > maxEndDate.minute(); i--) {
          minutes.push(i);
        }
      }
    }
    return minutes;
  };
  getDisabledMinutesEnd = () => {
    var minutes = [];
    const {
      varStartDate,
      varEndDate,
      varStartTime,
      varEndTime,
      minDate,
      maxEndDate,
    } = this.props;
    const startDateFormatted = varStartDate.format("DD:MM:YYYY");
    const today = moment().format("DD:MM:YYYY");
    const endDateFormatted = varEndDate.format("DD:MM:YYYY");
    const maxEndDateFormatted = maxEndDate.format("DD:MM:YYYY");
    const minDateFormatted = minDate.format("DD:MM:YYYY");

    if (today === endDateFormatted) {
      if (varEndTime.hour() === moment().hour()) {
        for (var i = 0; i < moment().minute(); i++) {
          minutes.push(i);
        }
      }
    }
    if (startDateFormatted === endDateFormatted) {
      if (varStartTime.hour() === varEndTime.hour()) {
        for (var i = 0; i <= varStartTime.minute(); i++) {
          minutes.push(i);
        }
      }
    }
    if (endDateFormatted === maxEndDateFormatted) {
      if (maxEndDate.hour() === varEndTime.hour()) {
        for (var i = 60; i > maxEndDate.minute(); i--) {
          minutes.push(i);
        }
      }
    }
    return minutes;
  };
  render() {
    return (
      <>
        {this.timeAndDateInput("Start")}
        {this.timeAndDateInput("End")}
        <CreateEventErrorMessage
          state={this.state.error}
          close={this.handleErrorModal}
        />
      </>
    );
  }
}

export default withStyles(styles)(DateAndTime);
