import { combineReducers } from "redux";
import suggestionReducer from "./reducer-suggestions";
import loginReducer from "./reducer-login";
import messageReducer from "./messageReducer";
import ownEventReducer from "./reducer-ownevents";
import progressReducer from "./reducer-progress";
import activityReducer from "./reducer-activity";
import myAccountReducer from "./reducer-myAccount";
import chartReducer from "./reducer-chartDetail";
import loaderReducer from "./loaderReducer";
import { routerReducer } from "react-router-redux";
import eventUserEventsReducer from "./reducer-eventUserEvents";
import liveChatReducer from "./liveChatReducer";
import favoriteReducer from "./favoriteReducer";
import eventReducer from "./eventReducer"
let allReducers = combineReducers({
    suggestions: suggestionReducer,
    loginResponse: loginReducer,
    messageReducer: messageReducer,
    progress: progressReducer,
    myAccount: myAccountReducer,
    activity: activityReducer,
    chartDetail: chartReducer,
    ownEvents: ownEventReducer,
    loaderReducer: loaderReducer,
    router: routerReducer,
    eventUserEvents: eventUserEventsReducer,
    liveChatReducer: liveChatReducer,
    favoriteReducer: favoriteReducer,
    eventReducer:eventReducer
});
const rootReducer = (state, action) => {
    if (action.type === "LOGOUT") {
        state = undefined;
        state = {
            messageReducer: {
                connection: true,
            },
        };
    }
    return allReducers(state, action);
};
export default rootReducer;
