import React, { useEffect } from 'react'
import {loadStripe} from '@stripe/stripe-js'
import './StripeContainer.css'
import { useState } from 'react'
import { routeName } from '../../../../Service/env'

const PUBLIC_KEY = 'pk_test_51KHk9FSIDPHIN8xjNSUZ9No9h18JY2scMq2TuaxIUk5R3jafWF4QlbkR0X5UKomrAM56JukF5Vok00hL9MNFuYwg00L7q1yk9G'

let stripePromise

const getStripe = () => {
    if(!stripePromise){
        stripePromise = loadStripe(PUBLIC_KEY)
    }

    return stripePromise
}

const StripeContainer = () => {
    
    const [stripeError, setStripeError] = useState(null)

    const item = {
        price:'price_1LjfHSSIDPHIN8xjQ1Js7AGw',
        quantity:1
    }

    const checkoutOptions = {
        lineItems: [item],
        mode:"payment",
        successUrl: `${window.location.origin}${routeName}/success`,
        cancelUrl: `${window.location.origin}/cancel`
    }

    const redirectToCheckout = async() => {


        const stripe = await getStripe()
        const {error} = await stripe.redirectToCheckout(checkoutOptions)
        console.log('stripe checkout error', error)
        if(error){
            setStripeError(error.message)
        }   
    }

    if(stripeError){
        alert(stripeError)
    }

    useEffect(() => {
        redirectToCheckout()
    },[])  

  return (
    <div>
        {/* <button onClick={redirectToCheckout}>Redirect</button> */}
    </div>
  )
}

export default StripeContainer;