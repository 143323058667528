import React from "react";
import { LinearProgress, makeStyles, withStyles } from "@material-ui/core";
import "./eventOverviewMobular.css";
import { useRef } from "react";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import UseOutsideClick from "Components/UBMeEvents/Components_UBMeEvents/OutSideClick/UseOutsideClick";
import { useState } from "react";
import moment from "moment";
import * as constants from "Constants";
import { GrDownload } from "react-icons/gr";
import { routeName } from "Service/env";
import Share from "../../../UBMeEvents/Components_UBMeEvents/share/Share";
import ShareModal from "../../../UBMeEvents/Components_UBMeEvents/modal/Modal";
import {axiosE} from "../../../../Service/service";
import ExcelJS from "exceljs";
import * as FileSaver from "file-saver";
const { jsPDF } = require("jspdf");
require("jspdf-autotable");


const EventOverviewMobular = ({
  setModal,
  data,
  index,
  editThisEvent,
  cloneThisEvent,
  deleteThisEvent,
  modal,
  downloadCSV,
}) => {
  const {
    StartDate,
    ImageSmall,
    Title,
    Add1,
    VenueName,
    TimeZone,
    PercentageCheckedIn,
    Sales,
    TicketSold,
    EventId,
    TicketsIssue,
    Status,
    EngagementCount,
    IsRecurringTicketPresent,
    IsEventExperienceAccessible,
    EndDate
  } = data;

  // console.log(data);
  const [eventId, setEventId] = useState(null);
  const [visible, setVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const mobileMenuRef = useRef(null);
  const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 6,
      borderRadius: 0,
    },
    colorPrimary: {
      backgroundColor:
        theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
    },
    bar: {
      borderRadius: 0,
      backgroundColor: "rgb(0, 171, 85)",
      transform: ({ value }) => {
        return value > 0
          ? `translateX(${value}%)`
          : `translateX(-101%) !important`;
      },
    },
  }))(LinearProgress);

  UseOutsideClick(
    () => {
      if (open) {
        setOpen(false);
      }
      console.log("outside");
    },
    ".Menu-list-wrapper",
    mobileMenuRef
  );

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
  }));

  const classes = useStyles();
  function ListItemLink(props) {
    return (
      <ListItem
        primaryTypographyProps={{ style: { fontSize: 14 } }}
        button
        component="a"
        {...props}
      />
    );
  }

  const shareButton = (eventId) => {
    setEventId(eventId)
    setVisible(true);
  };

  const closeModal = () => {
    setVisible(false)
  };

  const convertTimeUtcToLocal = (time, TimeZone) => {
    const UtcToLocal = moment.utc(time).local().format("MM/DD/YYYY hh:mm:ss A");
    var Time = moment(UtcToLocal, "MM/DD/YYYY hh:mm:ss A");

    const convertedTime = Time.tz(TimeZone);
    return moment(convertedTime);
  };

  const downloadEmailInvitationContacts = async (EventId, Title, TimeZone) => {
    try {
      // Fetch Attendees list
      const { data } = await axiosE.get(`DownloadAllAttendeeByEventId/${EventId}`);

      const timeUTCtoLocal = (date) => {
        var local = moment.tz(moment.utc(date), "MM/DD/YYYY hh:mm:ss a", TimeZone).format("MM/DD/YYYY hh:mm:ss a");
        return moment(local, "MM/DD/YYYY hh:mm:ss a").format("llll");
      };

      if (data?.results?.ListAttendee?.length > 0) {
        const filterdObjects = jsonFormatterHelper(data.results.ListAttendee);

        // Create a new workbook
        const workbook = new ExcelJS.Workbook();

        // Add a worksheet to the workbook
        const worksheet = workbook.addWorksheet("Attendee List");

        // Set column headers and make them bold
        const predefinedColumns = [
          { header: "Order ID", key: "OrderId", width: 15 },
          { header: "Event ID", key: "EventId", width: 15 },
          { header: "Full Name", key: "FullName", width: 20 },
          { header: "Email", key: "Email", width: 25 },
          { header: "Phone", key: "Phone", width: 15 },
          { header: "Order Date", key: "OrderDate", width: 20 },
          { header: "Confirmed Date/Time", key: "ScannedDateTime", width: 20 },
          { header: "Status", key: "Status", width: 15 },
          { header: "Ticket Type", key: "TicketType", width: 30 },
          { header: "Ticket Quantity", key: "TicketQuantity", width: 15 },
          { header: "Ticket ID", key: "TicketId", width: 15 },
          { header: "Ticket Cost", key: "TicketCost", width: 15 },
          { header: "Discount", key: "Discount", width: 15 },
          { header: "Discount Code", key: "DiscountCode", width: 15 },
          { header: filterdObjects[0].TaxName !== '' ? filterdObjects[0].TaxName : "Tax", key: "Tax", width: 15 },
          { header: "UBMe Fee", key: "UBMeFee", width: 15 },
          { header: "Order Total", key: "OrderTotal", width: 15 },
          { header: "Order PDF", key: "OrderPDF", width: 50 },
        ];

        // Convert UTC OrderDate to local time in sortedObjects
        const sortedObjects = filterdObjects.map((obj) => {
          const localOrderDate = timeUTCtoLocal(obj.OrderDate); // Assuming the OrderDate field contains UTC timestamps
          return { ...obj, OrderDate: localOrderDate };
        });

        // Sort the sortedObjects array in descending order based on OrderDate
        sortedObjects.sort((a, b) => new Date(b.OrderDate) - new Date(a.OrderDate));

        // Define a list of columns to exclude
        const columnsToExclude = [
          "Amount",
          "BookingDate",
          "BookingTime",
          "CurrencyCode",
          "CurrencySymbol",
          "DiscountAmount",
          "DiscountCode",
          "Email",
          "EventId",
          "FullName",
          "OrderDate",
          "OrderId",
          "PdfFile",
          "Phone",
          "QRCode",
          "Price",
          "RecurringInterval",
          "RecurringTicket",
          "ScannedDateTime",
          "Re_ScannedDateTime",
          "Status",
          "TaxAmount",
          "TaxName",
          "Title",
          "TotalCounts",
          "UBMeFeeAmount",
          "isAbsorb",
          "Terms_And_Conditions",
          "TermsAndConditions",
          "isBundle",
          "SlotPerBundle",
          "TicketDescription",
          "Notes",
        ];

        // Define additional columns by filtering out excluded columns
        const additionalColumns = Object.keys(sortedObjects[0]).filter((columnName) => {
          return !columnsToExclude.includes(columnName);
        }).map((columnName) => ({
          header: columnName.replace(/_/g, ' '), // Display name for the column
          key: columnName,    // Unique identifier for the column
          width: 15,          // Optional: specify the column width
        }));

        // Combine predefined and additional columns for the header row
        const allHeaders = predefinedColumns.concat(additionalColumns);

        // Extract keys from allHeaders
        const allHeadersKeys = allHeaders.map(header =>  header.header);

        // Add the header row for detailed headers (5th row) with headers and widths
        const headerRow = worksheet.addRow(allHeadersKeys.map(key => key)); // Include allHeadersKeys here if needed
        headerRow.font = { bold: true };

        // Loop through each cell in the header row and set wrapText alignment
        // headerRow.eachCell({ includeEmpty: true }, (cell) => {
        //   cell.alignment = { wrapText: true };
        // });

        let currencySymbol = ""

        // Group rows by orderId and collect ticket details for each orderId
        const groupedRows = sortedObjects.reduce((acc, row) => {
          const key = `${row.OrderId}-${row.FullName}-${row.Email}-${row.Phone}-${row.OrderDate}`;
          if (!acc[key]) {
            acc[key] = { ...row, TicketType: [], TicketId: [], TicketCost: [], UBMeFee: [], TicketStatuses: [], TaxFee: [], DiscountCode: [], DiscountAmount: [], TicketDetails: [] };
          }

          currencySymbol = row.CurrencySymbol;

          const ticketType = (row.Status === 'Refunded') ? `${row.Title} (Refunded)` : row.Title;

          acc[key].TicketType.push(ticketType);
          acc[key].TicketId.push(row.QRCode);
          acc[key].TicketStatuses.push(row.Status);

          const ticketDetail = {
            TicketName: row.Title,
            TicketId: row.QRCode,
            ConfirmedDateTime: row.ScannedDateTime !== "" ? timeUTCtoLocal(row.ScannedDateTime) : "",
            ReScannedDateTime: [],  // Store multiple ReScannedDateTime values
          };

          // Parse the ReScannedDateTime JSON string into an array of objects
          let reScannedDateTimeArray = [];
          try {
            reScannedDateTimeArray = JSON.parse(row.Re_ScannedDateTime);
          } catch (error) {
            console.error("Error parsing Re_ScannedDateTime:", error);
          }

          // Check if ReScannedDateTime is available and push it to the array
          reScannedDateTimeArray.forEach(entry => {
            ticketDetail.ReScannedDateTime.push(timeUTCtoLocal(entry.ScannedDateTime));
          });

          acc[key].TicketDetails.push(ticketDetail);

          acc[key].TicketCost.push((row.Price !== "" && row.Price !== '0') ? formatCurrency(Number(row.Price), currencySymbol) : "Free");
          acc[key].DiscountCode.push(row.DiscountCode);
          // acc[key].DiscountAmount.push(row.DiscountAmount);

          // Add Discount Amount value only once for each unique ticket type
          if (!acc[key].DiscountAmount[ticketType] && row.DiscountAmount !== "") {
            acc[key].DiscountAmount[ticketType] =  (row.isBundle === "True") ? (parseFloat(row.DiscountAmount.replace(currencySymbol, "").replace(",", "")) * row.SlotPerBundle).toString() : row.DiscountAmount
          }

          // Add UBMe fee value only once for each unique ticket type
          if (!acc[key].UBMeFee[ticketType]) {
            acc[key].UBMeFee[ticketType] = (row.isAbsorb !== '1' ? (row.isBundle === "True")? (parseFloat(row.UBMeFeeAmount.replace(currencySymbol, "").replace(",", "")) * row.SlotPerBundle).toString() : row.UBMeFeeAmount : "")
          }

          // Add Tax fee value only once for each unique ticket type
          if (!acc[key].TaxFee[ticketType] && row.TaxAmount !== "") {
            acc[key].TaxFee[ticketType] = (row.isBundle === "True") ? (parseFloat(row.TaxAmount.replace(currencySymbol, "").replace(",", "")) * row.SlotPerBundle).toString() : row.TaxAmount
          }

          return acc;
        }, {});

        // Add data rows and apply alternate row background color
        let prevOrderId = null;
        let rowIndex = 6;
        Object.values(groupedRows).forEach((rowData, index) => {

          const {
            OrderId,
            FullName,
            Email,
            Phone,
            EventId,
            OrderDate,
            TicketDetails,
            TicketType,
            TicketId,
            TicketCost,
            TicketStatuses,
            DiscountAmount,
            DiscountCode,
            TaxFee,
            UBMeFee,
            Amount,
            PdfFile,
            isBundle,
            SlotPerBundle
          } = rowData;

          // Initialize an object to store TicketId and corresponding Rescanned Date/Time
          const ticketRescannedData = {};

          // Loop through TicketDetails to group "TicketId" and "Rescanned Date/Time" values
          TicketDetails.forEach((ticketDetail) => {
            const ticketId = ticketDetail.TicketId;
            const ticketName = ticketDetail.TicketName;
            const confirmedDateTime = ticketDetail.ConfirmedDateTime
            const reScannedDateTimeValues = ticketDetail.ReScannedDateTime.join("\n"); // Join with line breaks

            if (!ticketRescannedData[ticketId]) {
              ticketRescannedData[ticketId] = {
                TicketName: ticketName,
                ConfirmedDateTime: confirmedDateTime,
                RescannedDateTimes: [],
              };
            }

            // Add ConfirmedDateTime at the beginning of the RescannedDateTimes array
            ticketRescannedData[ticketId].RescannedDateTimes.unshift(confirmedDateTime);

            ticketRescannedData[ticketId].RescannedDateTimes = ticketRescannedData[ticketId].RescannedDateTimes.concat(reScannedDateTimeValues);
          });

          const ubmeFeeValues = Object.values(UBMeFee).map(value => {
            const amount = parseFloat(value.replace(currencySymbol, "").replace(",", ""));
            return isNaN(amount) ? 0 : amount;
          });

          const taxFeeValues = Object.values(TaxFee).map(value => {
            const amount = parseFloat(value.replace(currencySymbol, "").replace(",", ""));
            return isNaN(amount) ? 0 : amount;
          });

          const nonEmptyDiscountCode = DiscountCode.find(code => code !== "");

          const nonEmptyDiscountAmount = Object.values(DiscountAmount).map(value => {
            const amount = parseFloat(value.replace(currencySymbol, "").replace(",", ""));
            return isNaN(amount) ? 0 : amount;
          });

          // const nonEmptyDiscountAmount = DiscountAmount.find(amount => amount !== "");

          let rescannedDateTimeValues = "";
          rescannedDateTimeValues = Object.keys(ticketRescannedData).map((id) => {
            const rescannedData = ticketRescannedData[id];
            if (rescannedData.RescannedDateTimes[0] !== undefined && rescannedData.RescannedDateTimes[0] !== "") {
              return `${rescannedData.TicketName}:\n${id}:\n${rescannedData.RescannedDateTimes.join("\n")}`;
            }
          }).filter(Boolean).join("\n\n");

          const ticketStatuses = [...new Set(TicketStatuses)]; // Remove duplicate statuses
          let formattedStatus = "";

          if (ticketStatuses.every(status => status === "Refunded")) {
            formattedStatus = "Refunded"; // All tickets refunded
          } else if (ticketStatuses.every(status => status === "CheckedIn")) {
            formattedStatus = ""; // All tickets checked in
          } else if (ticketStatuses.includes("Refunded")) {
            formattedStatus = "Partially Refunded"; // Some tickets refunded
          } else if (ticketStatuses.includes("CheckedIn")) {
            formattedStatus = ""; // Some tickets checked in
          } else {
            formattedStatus = ""; // Add more cases if needed
          }

          // Calculate the sum of UBMeFee values
          const DiscountAmountSum = nonEmptyDiscountAmount.reduce((sum, value) => sum + value, 0);

          // Calculate the sum of UBMeFee values
          const ubmeFeeSum = ubmeFeeValues.reduce((sum, value) => sum + value, 0);

          // Calculate the sum of TaxFee values
          const taxFeeSum = taxFeeValues.reduce((sum, value) => sum + value, 0);

          // Define the array of values for predefined columns
          const predefinedDataArray = [
            OrderId,
            EventId,
            FullName,
            Email,
            Phone,
            OrderDate,
            rescannedDateTimeValues,
            formattedStatus,
            TicketType.join("\n"),
            TicketType.length,
            TicketId.join("\n"),
            TicketCost.join("\n"),
            (DiscountAmountSum !== undefined && DiscountAmountSum !== "" && DiscountAmountSum !== 0) ? formatCurrency(DiscountAmountSum, currencySymbol) : "",
            (nonEmptyDiscountCode !== undefined && nonEmptyDiscountCode !== "") ? nonEmptyDiscountCode : "",
            (taxFeeSum !== undefined && taxFeeSum !== "" && taxFeeSum !== 0) ? formatCurrency(taxFeeSum, currencySymbol) : "",
            (ubmeFeeSum !== 0) ? formatCurrency(ubmeFeeSum, currencySymbol) : "",
            (Amount !== "" && Amount !== '0') ? formatCurrency(Amount, currencySymbol) : "Free",
            PdfFile
          ];

          // Create an array for additional column data based on column order
          const additionalDataArray = additionalColumns.map(column => rowData[column.key] || '');

          // Combine predefined and additional data arrays
          const rowDataArray = predefinedDataArray.concat(additionalDataArray);

          // Add the row to the worksheet
          const orderRow = worksheet.addRow(rowDataArray);

          // Apply alternate row background color
          orderRow.fill = {type: "pattern", pattern: "solid", fgColor: {argb: index % 2 === 0 ? "FFFFFF" : "EFEFEF"}};

          // Get the column index for "OrderPDF"
          const orderPdfColumnIndex = predefinedColumns.findIndex(header => header.key === "OrderPDF");
          let orderPdfCell = orderRow.getCell(orderPdfColumnIndex + 1);
          if (orderPdfCell.value) {
            const hyperlinkText = orderPdfCell.value;
            orderPdfCell.value = {
              text: hyperlinkText,
              hyperlink: hyperlinkText, // Provide the URL here
            };
            orderPdfCell.alignment = {wrapText: true, horizontal: "center"};
            orderPdfCell.font = {color: {argb: "0000FF"}, underline: true};
          } else {
            orderPdfCell.value = ""; // Empty cell if no PDF link
          }

          if (prevOrderId !== OrderId) {
            prevOrderId = OrderId;
          }

          rowIndex++;
        });

        // Set specific column widths based on column index
        worksheet.columns.forEach((column, columnIndex) => {
          if (columnIndex === 0 || columnIndex === 1 || columnIndex === 2 || columnIndex === 6 || columnIndex === 7 || columnIndex === 8 || columnIndex === 10) {
            column.width = 40;
          } else if (columnIndex === 3 || columnIndex === 4 || columnIndex === 5 ) {
            column.width = 25;
          } else if (columnIndex === 17) {
            column.width = 100;
          } else {
            column.width = 15
          }
        });

        // Function to apply center alignment to specified columns
        const applyCenterAlignmentToColumns = (worksheet, columnKeys) => {
          columnKeys.forEach((key) => {
            const column = worksheet.getColumn(key);
            column.eachCell((cell) => {
              cell.alignment = { horizontal: "center", vertical: 'middle', wrapText: true };
            });
          });
        };

        // Call the function to apply center alignment to "TicketCost" and "Discount" columns
        applyCenterAlignmentToColumns(worksheet, [10, 12, 13, 14, 15, 16, 17]);

        // Function to apply bold and green font to "OrderTotal" column
        const applyBoldAndGreenFontToColumn = (worksheet, columnKey) => {
          const column = worksheet.getColumn(columnKey);
          column.eachCell((cell, rowNumber) => {
            if (rowNumber !== 1) {
              cell.font = {bold: true, color: {argb: "00AA00"}}; // Green color code
            }
          });
        };

        // Call the function to apply bold and green font to "OrderTotal" column
        applyBoldAndGreenFontToColumn(worksheet, 17);

        // Generate XLSX file
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        FileSaver.saveAs(blob, `${Title}.xlsx`);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const jsonFormatterHelper = (ListAttendee) => {
    // console.log({ ListAttendee });
    const filterdObjects = ListAttendee.map((item) => {
      const jsonobj = item.AdditionalDetails;
      const {
        Data,
        Message,
        ResponseCode,
        AdditionalDetails,
        Email,
        ...restItem
      } = item;

      if (jsonobj) {
        let parsedData = {};
        try {
          const parsedJsonObj = parseAndValidateJSON(jsonobj);
          let restInfo = {};
          if (parsedJsonObj.success && parsedJsonObj.data?.FullName) {
            restInfo = { ...parsedJsonObj.data };
          } else {
            const modifiedJson = JSON.parse(jsonobj, (key, value) => {
              if (typeof value === "string") {
                // Replace single quotes within string values with apostrophes
                return value.replace(/'/g, "\\'");
              }
              return value;
            });

            parsedData = JSON.parse(jsonobj);
            const data = JSON.parse(parsedData);

            restInfo = { ...modifiedJson, ...data };
          }

          return { ...restInfo, ...restItem };
        } catch (e) {
          const isObject = isPlainObject(parsedData);

          if (isObject) {
            return { ...parsedData, ...restItem };
          }

          // Replace single quotes with double quotes
          const correctedJsonString = parsedData.replace(/'/g, '"');
          const newParsedData = JSON.parse(correctedJsonString);
          return { ...newParsedData, ...restItem };
        }
      } else {
        return { FullName: "", Email: "", Phone: "", ...restItem };
      }
    });
    const filteredDetails = filterdObjects?.map((obj) =>
        filterUnwantedKeys(obj)
    );
    return filteredDetails;
  };

  const filterUnwantedKeys = (obj) => {
    const filteredObject = {};

    for (const key in obj) {
      if (isNaN(key)) {
        filteredObject[key] = obj[key];
      }
    }

    return filteredObject;
  };

  const parseAndValidateJSON = (jsonString) => {
    // Replace 'null' with 'null' and single quotes with double quotes
    const replacedString = jsonString
        .replace(/'/g, '"')
        .replace(/null/g, "null");

    try {
      const parsedData = JSON.parse(replacedString);
      return { success: true, data: parsedData };
    } catch (error) {
      return { success: false, error: error.message };
    }
  };

  const isPlainObject = (variable) => {
    return (
        typeof variable === "object" &&
        variable !== null &&
        !Array.isArray(variable)
    );
  };

  function formatCurrency(value, currencySymbol) {
    // Format the number as currency
    return currencySymbol + Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(Number(value));
  }

  const downloadPDF = async (EventId, Title, TimeZone, EventStartDate, EventEndDate) => {
    try {
      // Fetch Attendees list
      const { data } = await axiosE.get(`DownloadAllAttendeeByEventId/${EventId}`);

      const timeUTCtoLocal = (date) => {
        var local = moment.tz(moment.utc(date), "MM/DD/YYYY hh:mm:ss a", TimeZone).format("MM/DD/YYYY hh:mm:ss a");
        return moment(local, "MM/DD/YYYY hh:mm:ss a").format("llll");
      };

      if (data?.results?.ListAttendee?.length > 0) {
        const filteredObjects = jsonFormatterHelper(data.results.ListAttendee);

        // Set column headers
        const allHeaders = [
          { header: "", key: "CheckMark"},
          { header: "Order ID", key: "OrderId"},
          { header: "Full Name", key: "FullName"},
          { header: "Email", key: "Email"},
          { header: "Phone", key: "Phone"},
          { header: "Ticket Type", key: "TicketType"},
        ];

        // Convert UTC OrderDate to local time in sortedObjects
        const sortedObjects = filteredObjects.map((obj) => {
          const localOrderDate = timeUTCtoLocal(obj.OrderDate); // Assuming the OrderDate field contains UTC timestamps
          return { ...obj, OrderDate: localOrderDate };
        });

        // Sort the sortedObjects array in descending order based on OrderDate
        sortedObjects.sort((a, b) => new Date(b.OrderDate) - new Date(a.OrderDate));

        // Define rows data
        const rows = sortedObjects.map((attendee, index) => [
          "",
          attendee.OrderId,
          attendee.FullName,
          attendee.Email,
          attendee.Phone,
          attendee.Title,
        ]);

        // Define the font you want to use
        const fontName = "Arial";

        // Create a new PDF document
        const doc = new jsPDF('landscape');
        doc.setFont(fontName);

        // Center-align the title
        const titleWidth = doc.getStringUnitWidth(Title) * doc.internal.getFontSize() / doc.internal.scaleFactor;
        const titleX = (doc.internal.pageSize.width - titleWidth) / 2;
        // Set text color to red
        doc.setTextColor('#851679');
        doc.text(Title, titleX, 10);
        doc.setFontSize(18)

        // change back to what we want for other stuff
        doc.setFontSize(12);
        doc.setTextColor(0, 0, 0);

        // Format event start date-time and end date-time
        const eventStart = moment.tz(EventStartDate, TimeZone).format("dddd, MMMM DD, YYYY hh:mm A");
        let eventEnd = moment.tz(EventEndDate, TimeZone).format("dddd, MMMM DD, YYYY hh:mm A");
        // Display end time only if it's different from the start date
        if (moment(EventStartDate).format("YYYY-MM-DD") === moment(EventEndDate).format("YYYY-MM-DD")) {
          eventEnd = moment.tz(EventEndDate, TimeZone).format("hh:mm A");
        }

        // Center-align the start and end date-time
        const eventDateTimeWidth = doc.getStringUnitWidth(eventStart + " - " + eventEnd) * doc.internal.getFontSize() / doc.internal.scaleFactor;
        const eventDateTimeX = (doc.internal.pageSize.width - eventDateTimeWidth) / 2;
        doc.text(eventStart + " - " + eventEnd, eventDateTimeX, 20);

        // Customize the content of the cell using the didParseCell hook
        const didParseCell = function (data) {
          data.cell.styles.font = fontName;
        };

        // Draw rectangle in the first column after cell is drawn
        const didDrawCell = function (data) {
          if (data.column.index === 0 && data.section === 'body') { // Draw for data rows only
            const padding = 2; // Adjust padding as needed
            const cellWidth = data.cell.width; // Increase cell width by 10 units
            const cellHeight = data.cell.height;
            const startX = data.cell.x + padding;
            const startY = data.cell.y + padding;
            const rectSize = Math.min(cellWidth, cellHeight) - (2 * padding); // Choose the smaller dimension as the size of the square
            doc.rect(startX, startY, rectSize, rectSize);
          }
        };

        // Generate autoTable after event date-time
        doc.autoTable({
          headStyles: {
            fillColor: '#851679'
          },
          head: [allHeaders.map(header => header.header)],
          body: rows,
          startY: 40,
          columnStyles: {
            0: { // index of the first column
              cellWidth: 8 // specify the width for the first column
            }
          },
          didParseCell: didParseCell,
          didDrawCell: didDrawCell,
        });

        // Add footer with page numbers
        const totalPages = doc.internal.getNumberOfPages();
        for (let i = 1; i <= totalPages; i++) {
          doc.setPage(i);
          doc.text('Page ' + i + ' of ' + totalPages, doc.internal.pageSize.width / 2, doc.internal.pageSize.height - 10, { align: 'center' });
        }

        // Save the PDF
        doc.save(`${Title}.pdf`);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <div key={index} className="event_overiew_wrapper">
      <div className="event_overiew_card">
        <div className="event_overiew_card_content">
          <div className="event_overiew_card_content_header">
            {/* <figure> */}
            <img
              className="event_overiew_card_content_header_img"
              src={ImageSmall}
              alt=""
            />
            {/* </figure> */}

            <div className="event_overiew_card_content_header_text_wrapper">
              <div className="event_overiew_card_content_header_text">
                <p className="event_overiew_card_content_header_title">
                  {Title}
                </p>
                <p className="event_overiew_card_content_header_venuename">
                  {VenueName}
                </p>
                <p className="event_overiew_card_content_header_time">
                  {" "}
                  {moment(convertTimeUtcToLocal(StartDate, TimeZone)).format(
                    "llll"
                  )}
                </p>
              </div>
              <div className="event_overiew_card_content_bottom">
                <div className="event_overiew_card_content_bottom_sales">
                  <div className="event_overiew_card_content_bottom_sales_bar">
                    <p style={{ marginBottom: 10, color: "rgb(57 179 174)" }}>
                      {TicketSold}/{TicketsIssue}
                    </p>
                    <BorderLinearProgress
                      variant="determinate"
                      value={(Number(TicketSold) / Number(TicketsIssue)) * 100}
                    />
                  </div>
                  <p className="event_overiew_card_content_bottom_sales_text">
                    $
                    {Number(Sales)
                      ? Intl.NumberFormat(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(Sales)
                      : 0}
                  </p>
                </div>
                <p className="event_overiew_card_content_bottom_engagement">
                  {constants.Text.EngagementScore}{" "}
                  <span style={{ color: "#851679" }}>
                    {PercentageCheckedIn}%
                  </span>
                </p>
                <p className="event_overiew_card_content_bottom_engagement">
                  {constants.Text.Status}{" "}
                  {Status === "Published" ? (
                    <span style={{ color: "rgb(0, 171, 85)" }}>Live</span>
                  ) : (
                    <span
                      style={{
                        color:
                          Status === "Canceled"
                            ? "rgb(255, 48, 48)"
                            : "#808080",
                      }}
                    >
                      {Status}
                    </span>
                  )}
                </p>

                <p className="event_overiew_card_content_bottom_engagement">
                  {constants.Text.AppEngagements}{" "}
                  <span style={{ color: "#000000" }}>{0}</span>
                </p>
                <p className="event_overiew_card_content_bottom_engagement">
                  {constants.Text.LandingPageViews}{" "}
                  <span style={{ color: "#000000" }}>
                    {EngagementCount !== ""
                      ? Intl.NumberFormat().format(EngagementCount)
                      : 0}
                  </span>
                </p>
                <p className="event_overiew_card_content_bottom_engagement">
                  {constants.Text.CheckIns}{" "}
                  <span style={{ color: "#000000" }}>
                    {0}{" "}
                    {/* <a onClick={() =>downloadCSV(EventId, Title)} href="#">CSV Export</a> */}
                  </span>
                </p>
              </div>
            </div>
            <div style={{ position: "relative" }}>
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={() => setOpen(true)}
              >
                <MoreVertIcon size={20} />
              </IconButton>
              {open ? (
                <div ref={mobileMenuRef} className="Menu-list-wrapper">
                  <div className="Menu-list-popover">
                    <div className={classes.root}>
                      <List component="nav" aria-label="main mailbox folders">
                        {Status !== "Canceled" && Status !== "Ended" && (
                          <ListItemLink
                            target="_blank"
                            href={`${constants.URLs.hostName}${routeName}/event/${EventId}`}
                          >
                            <ListItemText
                              primaryTypographyProps={{
                                style: { fontSize: 14 },
                              }}
                              primary="View"
                            />
                          </ListItemLink>
                        )}

                        {Status !== "Canceled" && Status !== "Ended" && (
                          <ListItem
                            onClick={() => {
                              setOpen(false);
                              localStorage.setItem("isRecurring",
                                  IsRecurringTicketPresent === "1"
                                      ? true
                                      : false
                              );
                              editThisEvent(EventId);
                            }}
                            button
                          >
                            <ListItemText
                              primaryTypographyProps={{
                                style: { fontSize: 14 },
                              }}
                              primary="Edit"
                            />
                          </ListItem>
                        )}

                        {Status === "Published" && (
                          <ListItem
                            onClick={() => {
                              setOpen(false);
                              shareButton(EventId)
                              // navigator.clipboard.writeText(
                              //   `${constants.URLs.hostName}${routeName}/event/${EventId}`
                              // );
                            }}
                            button
                          >
                            <ListItemText
                              primaryTypographyProps={{
                                style: { fontSize: 14 },
                              }}
                              primary="Share"
                            />
                          </ListItem>
                        )}

                        <ListItem
                          onClick={() => {
                            setOpen(false);
                            cloneThisEvent(EventId);
                          }}
                          button
                        >
                          <ListItemText
                            primaryTypographyProps={{ style: { fontSize: 14 } }}
                            primary="Clone"
                          />
                        </ListItem>

                        {IsRecurringTicketPresent == 1 && (
                          <ListItemLink
                            target="_blank"
                            onClick={() => {
                              localStorage.setItem(
                                "CurrentEventId",
                                JSON.stringify(EventId)
                              );

                              localStorage.setItem(
                                "CurrentTimeZone",
                                JSON.stringify(TimeZone)
                              );
                            }}
                            href={`${routeName}/Dashboard/Bookings/${EventId}`}
                            button
                          >
                            <ListItemText
                              primaryTypographyProps={{
                                style: { fontSize: 14 },
                              }}
                              primary="Bookings"
                            />
                          </ListItemLink>
                        )}
                        {
                                IsEventExperienceAccessible === "True" && (
                                  <ListItemLink
                                  target="_blank"
                                  href = {`${routeName}/Dashboard/EventExperience`}
                                  onClick={() => {
                                    setOpen(false);
                                    let eventTitle = Title?.replaceAll(
                                        "&",
                                        "$"
                                    );
                                    localStorage.setItem(
                                      "CurrentEventId",
                                      JSON.stringify(EventId)
                                  );
                                  localStorage.setItem(
                                      "CurrentTimeZone",
                                      JSON.stringify(TimeZone)
                                  );
                                  localStorage.setItem(
                                    "EventEndDate",
                                    JSON.stringify(EndDate)
                                  );
                                    // let storeData = storeFolder.getState();
                                    // ws?.json({
                                    //   eventId: row.original.EventId,
                                    //   userId: storeData.loginResponse.results.ProfileDetail.UserId,
                                    //   type: "eventUserCheckedIn"
                                    // })
                                      // editThisEvent(EventId, path);
                                  }}
                                  >
                                    <ListItemText
                                        primaryTypographyProps={{ style: { fontSize: 14 } }}
                                        primary="Event Experience"
                                    />
                                  </ListItemLink>
                                )
                              }

                        <ListItemLink
                          target="_blank"
                          // href={`${routeName}/Dashboard/Analytics`}

                          href={`${routeName}/Dashboard/Analytics/?eventId=${EventId}&title=${Title}`}
                        >
                          <ListItemText
                            primaryTypographyProps={{ style: { fontSize: 14 } }}
                            primary="Analytics"
                          />
                        </ListItemLink>

                        {Number(TicketSold) > 0  && (
                            <ListItem
                                onClick={() => {
                                  setOpen(false);
                                  downloadEmailInvitationContacts(
                                      EventId,
                                      Title,
                                      TimeZone
                                  )
                                }}
                                button
                            >
                              <ListItemText
                                  primaryTypographyProps={{ style : {fontSize: 14} }}
                                  primary="Download Attendees"
                              />
                            </ListItem>
                        )}

                        {Number(TicketSold) > 0  && (
                            <ListItem
                                onClick={() => {
                                  setOpen(false);
                                  downloadPDF(
                                      EventId,
                                      Title,
                                      TimeZone,
                                      StartDate,
                                      EndDate
                                  )
                                }}
                                button
                            >
                              <ListItemText
                                  primaryTypographyProps={{ style : {fontSize: 14} }}
                                  primary="Print Attendees"
                              />
                            </ListItem>
                        )}

                        {Status === "Published" &&
                          Number(TicketSold) !== 0 &&
                          Status !== "Ended" && (
                            <ListItem
                              // disabled={Number(row.original.TicketSold) !== 0}
                              onClick={() => {
                                setOpen(false);
                                setModal({
                                  isShow: true,
                                  title: "cancel",
                                  eventId: EventId,
                                });

                                // deleteThisEvent(row.original.EventId);
                              }}
                              button
                            >
                              <ListItemText
                                primaryTypographyProps={{
                                  style: { fontSize: 14 },
                                }}
                                primary="Cancel"
                              />
                            </ListItem>
                          )}
                        {(Number(TicketSold) === 0 && Status === "Canceled") ||
                        (Number(TicketSold) !== 0 && Status === "Canceled") ||
                        (Number(TicketSold) === 0 && Status !== "Canceled") ||
                        Status === "Ended" ? (
                          <ListItem
                            // disabled={ Number(TicketSold) !== 0 && Status !== "Canceled"}
                            onClick={() => {
                              setOpen(false);
                              setModal({
                                isShow: true,
                                title: "delete",
                                eventId: EventId,
                              });
                            }}
                            button
                          >
                            <ListItemText
                              primaryTypographyProps={{
                                style: { fontSize: 14 },
                              }}
                              primary="Delete"
                            />
                          </ListItem>
                        ) : null}
                      </List>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      {visible && (
          <ShareModal closeModal={closeModal}>
            <Share
                url={`${constants.URLs.baseUrl}/event/` + eventId}
            />
          </ShareModal>
      )}
    </div>
  );
};

export default EventOverviewMobular;
