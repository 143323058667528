import React, { useEffect, useState } from "react";
import "./orderDetail.css";
import { AiFillCaretDown } from "react-icons/ai";
import { IoCloseOutline } from "react-icons/io5";
import { axiosE } from "Service/service";
import { default as momentTz } from "moment-timezone";
import moment from "moment/moment";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import { Text } from "Constants";
import { RiErrorWarningFill } from "react-icons/ri";
import { Box, Button, Modal } from "@material-ui/core";
import dayjs from "dayjs";
import CircularProgress from "@mui/material/CircularProgress";
import { parsePhoneNumberFromString, format } from "libphonenumber-js";

function OrderDetail({
  isOpen,
  orderId,
  setOrderId,
  TimeZone,
  GetAllAttendees,
  isEventBookings = false,
}) {
  const [orderDeatails, setOrderDetails] = useState("");
  const [notRefundedTicket, setNotRefundedTicket] = useState([]);
  const [itemId, setItemId] = useState([]);
  const [visible, setVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const DEFAULT_COUNTRY_CODE = '1'; // Replace with your desired default country code

  const Subtotal = orderDeatails.OrderItems?.reduce(
    (a, c) => a + Number(c.SubTotal),
    0
  );
  const discount = orderDeatails.OrderItems?.reduce(
    (a, c) => a + Number(c.DiscountAmount === "0" ? 0 : c.DiscountAmount),
    0
  );
  const total = orderDeatails.OrderItems?.reduce(
    (a, c) => a + Number(c.Total),
    0
  );
  const UbmeFee = orderDeatails.OrderItems?.reduce(
    (a, c) =>
      a +
      Number(
        c.isAbsorb === "0"
          ? c.UBMeFeeAmount === "0"
            ? c.UBMeFeeAmount
            : c.UBMeFeeAmount
          : 0
      ),
    0
  );

  const Tax = orderDeatails.OrderItems?.reduce(
    (a, c) => a + Number(c.TaxAmount),
    0
  );

  const data = [
    {
      title: "Sub Total",
      number: Subtotal,
    },
    {
      title: "Discount",
      number: discount,
    },
    {
      title: "Tax",
      number: Tax,
    },

    {
      title: "Fee",
      number: UbmeFee || 0,
    },
    {
      title: "Total",
      number: orderDeatails?.Total,
    },
  ];


  useEffect(() => {
    GetOrderDeatilByOrderId();
    GetAllNotRefundedTicketsByUserId();
  }, [orderId]);

  const GetAllNotRefundedTicketsByUserId = async () => {
    try {
      const UserId = localStorage.getItem("UserId");
      const { data } = await axiosE.get(
        `GetAllNotRefundedTicketsByOrderId/${orderId.orderId}`
      );

      setNotRefundedTicket(JSON.parse(data.results.Data));
    } catch (error) {}
  };

  const handleChangeRefund = (ticket) => {
    if (!itemId.some(({ QrCode }) => QrCode === ticket.QRCodeId)) {
      setItemId([
        ...itemId,
        { QrCode: ticket.QRCodeId, OrderItemId: ticket.ItemId },
      ]);
    } else {
      setItemId(itemId.filter((item) => item.QrCode !== ticket.QRCodeId));
    }
  };

  const selectAllRefunds = (data) => {
    if (
      notRefundedTicket?.length !== itemId?.length &&
      data.filter((t) => t.Status !== "Refunded")?.length !== itemId?.length
    ) {
      setItemId(
        data
          .filter((t) => t.Status !== "Refunded")
          .map((ticket) => ({
            QrCode: ticket.QRCodeId,
            OrderItemId: ticket.ItemId,
          }))
      );
    } else {
      setItemId([]);
    }
  };

  console.log(
    "###########",
    notRefundedTicket?.filter((t) => t.Status !== "Refunded")?.length
  );
  const FullRefund = async () => {
    try {
      const { data } = await axiosE.post("CreateFullRefund", {
        OrderId: orderId.orderId,
      });
      if (data.results.ResponseCode) {
        toast("Tickets refunded successfully");
        setTimeout(() => {
          GetAllAttendees();
        }, 1000);
        isOpen();
      } else {
        toast("Already refunded");
        isOpen();
      }
    } catch (error) {}
  };

  const CreatePartialRefund = async () => {
    if (notRefundedTicket?.length !== itemId?.length) {
      try {
        const { data } = await axiosE.post("CreatePartialRefund", {
          Order: {
            OrderId: orderId.orderId,
            ItemsList: itemId,
          },
        });
        if (data.results.ResponseCode) {
          toast("Tickets refunded successfully");
          isOpen();
          setTimeout(() => {
            GetAllAttendees();
          }, 1000);
        } else {
          toast("Already refunded");
          isOpen();
        }

      } catch (error) {}
    } else {
      FullRefund();
    }
  };

  // const GetOrderDeatilByOrderId = async () => {
  //   setIsLoading(true);
  //   try {
  //     if (orderId) {
  //       const { data } = await axiosE.get(
  //         `GetOrderDeatilByOrderId/${orderId.orderId}`
  //       );
  //       if (data.results.ResponseCode) {
  //         // const jsonobj = JSON.parse(data.results.AdditionalDetails);
  //         // console.log("jsonobj",jsonobj);
  //         if (data.results.AdditionalDetails) {
  //           // // replace single qoute with double qoute for valid json format
  //           // const additionalInfo = data.results.AdditionalDetails.replace(
  //           //   /'/g,
  //           //   '"'
  //           // );

  //           // const newString = additionalInfo.replaceAll("\\", "");
  //           // const modifiedString = newString.replace(/([^:])"(s|t)/g, "$1'$2");
  //           // // console.log("additionalInfo",additionalInfo);
  //           // // replace Apostrophe if is there otherwise it create some issue
  //           // // const modifiedString = additionalInfo.replace(
  //           // //   /(?<!:)"s/g,
  //           // //   (match) => {
  //           // //     return "'s";
  //           // //   }
  //           // // );
  //           // // const IsApostrophe = additionalInfo.replace(/"s/g, "'s");
  //           // // finally parsing the object
  //           // const additionalInfoObject = JSON.parse(
  //           //   modifiedString.replace(/"{/g, "{").replace(/}"/g, "}")
  //           // );

  //           const parsedData = JSON.parse(data.results.AdditionalDetails);
  //           const dataParse = JSON.parse(parsedData);

  //           // console.log({  parsedData, dataParse });

  //           // combined the objects
  //           const { AdditionalDetails, ...rest } = data.results;
  //           const newdata = { ...dataParse, ...rest };
  //           // console.log("additionalInfoObject",newdata);
  //           setOrderDetails(newdata);
  //           console.log("newdata", newdata);

  //           setIsLoading(false);
  //         }
  //         // console.log("data.results.ResponseCode", data);
  //       }
  //     }
  //   } catch (error) {
  //     setIsLoading(false);
  //     console.log("error", error);
  //   }
  // };

  const isPlainObject = (variable) => {
    return (
      typeof variable === "object" &&
      variable !== null &&
      !Array.isArray(variable)
    );
  };

  const GetOrderDeatilByOrderId = async () => {
    setIsLoading(true);
    try {
      if (orderId) {
        const { data } = await axiosE.get(
          `GetOrderDeatilByOrderId/${orderId.orderId}`
        );

        if (data.results.ResponseCode) {
          if (data.results.AdditionalDetails) {
            const filterdObjects = jsonFormatterHelper(data.results);
            setOrderDetails(filterdObjects);
          } else {
            setOrderDetails([]);
          }
        }
      }
    } catch (error) {
      setIsLoading(false);
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const scheduleConfirmModal = () => {
    return (
      <Modal open={isModalOpen} onClose={toggleModal}>
        <div className="delete_modal_wrapper">
          <div
            style={{
              width: "100px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100px",
            }}
          >
            <RiErrorWarningFill size={30} />
          </div>
          <p style={{ textAlign: "center", fontSize: 16 }}>
            {Text.SureToCancelEvent} {"confirm this attendee?"}
          </p>
          <div style={{ display: "flex" }}>
            <Button
              onClick={() => toggleModal()}
              style={{
                color: "blue",
                fontSize: "1.5rem",
                padding: "10px 25px",
              }}
            >
              {Text.Cancel}
            </Button>
            <Button
              onClick={
                () => toggleModal()
                // CheckedIn(checkedIn.check, checkedIn.qrid, checkedIn.eventId)
              }
              style={{
                color: "red",
                fontSize: "1.5rem",
                padding: "10px 25px",
              }}
            >
              {"YES, CONFIRM "}
            </Button>
          </div>
        </div>
      </Modal>
    );
  };

  const parseAndValidateJSON = (jsonString) => {
    // Replace 'null' with 'null' and single quotes with double quotes
    const replacedString = jsonString
        .replace(/'/g, '"')
        .replace(/null/g, "null");

    try {
      const parsedData = JSON.parse(replacedString);
      return { success: true, data: parsedData };
    } catch (error) {
      return { success: false, error: error.message };
    }
  };

  const jsonFormatterHelper = (item) => {
    const jsonobj = item.AdditionalDetails;
    const {
      Data,
      Message,
      ResponseCode,
      AdditionalDetails,
      Email,
      ...restItem
    } = item;

    if (jsonobj) {
      let parsedData = {};
      try {
        const parsedJsonObj = parseAndValidateJSON(jsonobj);
        let restInfo = {};
        if (parsedJsonObj.success && parsedJsonObj.data?.FullName) {
          restInfo = { ...parsedJsonObj.data };
        } else {
          const modifiedJson = JSON.parse(jsonobj, (key, value) => {
            if (typeof value === "string") {
              // Replace single quotes within string values with apostrophes
              return value.replace(/'/g, "\\'");
            }
            return value;
          });

          parsedData = JSON.parse(jsonobj);
          const data = JSON.parse(parsedData);
          // console.log({ modifiedJson, parsedData, data });

          restInfo = { ...modifiedJson, ...data };
        }
        // console.log({ restInfo });

        return { ...restInfo, ...restItem };
      } catch (e) {
        const isObject = isPlainObject(parsedData);

        if (isObject) {
          return { ...parsedData, ...restItem };
        }

        // Replace single quotes with double quotes
        const correctedJsonString = parsedData.replace(/'/g, '"');
        const newParsedData = JSON.parse(correctedJsonString);
        return { ...newParsedData, ...restItem };
      }
    } else {
      return { FullName: "", Email: "", Phone: "", ...restItem };
    }
};

  const filterUnwantedKeys = (obj) => {
    const filteredObject = {};

    for (const key in obj) {
      if (isNaN(key)) {
        filteredObject[key] = obj[key];
      }
    }

    return filteredObject;
  };

  const timeUTCtoLocal = (date) => {
    var local = momentTz
      .tz(moment.utc(date), "MM/DD/YYYY hh:mm:ss a", TimeZone)
      .format("MM/DD/YYYY hh:mm:ss a");
    return moment(local, "MM/DD/YYYY hh:mm:ss a").format("llll");
  };

  const formatPhoneNumber = (phoneValue) => {

    try {
      // Use libphonenumber-js to parse the phone number
      let phoneNumberObject = parsePhoneNumberFromString(phoneValue);

      if (!phoneNumberObject) {
        let phoneValueWithPlus = "+" + phoneValue
        phoneNumberObject = parsePhoneNumberFromString(phoneValueWithPlus);

        if(!phoneNumberObject) {
          let phoneValueWithPlusWithDefaultCode = "+" + DEFAULT_COUNTRY_CODE + phoneValue
          phoneNumberObject = parsePhoneNumberFromString(phoneValueWithPlusWithDefaultCode);

          if (phoneNumberObject) {
            phoneValue =  phoneNumberObject.formatInternational()
          }
        } else if (!phoneNumberObject.isValid()) {
          let phoneValueWithPlusWithDefaultCode = "+" + DEFAULT_COUNTRY_CODE + phoneValue
          phoneNumberObject = parsePhoneNumberFromString(phoneValueWithPlusWithDefaultCode);

          if (phoneNumberObject) {
            phoneValue =  phoneNumberObject.formatInternational()
          }
        } else {
          phoneValue =  phoneNumberObject.formatInternational()
        }
      }

      // Format the phone number in international format
      phoneValue =  phoneNumberObject.formatInternational()
    } catch (error) {
      // Handle any errors here
      console.error('Error formatting phone number:', error);
      return phoneValue;
    }

    return phoneValue;
  };

  const formatDateWithTimeAndTimeZone = (dateString, timeString, timeZone) => {
    const dateTimeString = `${dateString} ${timeString}`;
    const utcDateTime = momentTz
      .utc(dateTimeString, "MM/DD/YYYY HH:mm:ss")
      .toDate();
    const formattedDate = momentTz(utcDateTime)
      .tz(timeZone)
      .format("ddd, MMM D YYYY, h:mm A");
    return formattedDate;
  };

  const eventDateDetails = (ticket) => {
    if (ticket) {
      const date = ticket?.BookingDate?.split(" ")[0];
      const time = ticket?.BookingTime;
      const timeZone = JSON.parse(localStorage.getItem("CurrentTimeZone"));
      // console.log({date, time, timeZone});
      return formatDateWithTimeAndTimeZone(date, time, timeZone);
    }
  };

  const progressLoader = () => {
    return (
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "80vh",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
        }}
      >
        <CircularProgress color="secondary" />
      </Box>
    );
  };


  return (
    <div className="orderDetailModal">
      {scheduleConfirmModal()}
      <ToastContainer
        className="toaster-container"
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="edit_container">
        <div style={{ padding: 10 }} className="edit_container_close_btn">
          <IoCloseOutline
            size={25}
            color="rgba(0, 0, 0, 0.54)"
            onClick={isOpen}
          />
        </div>
        <p className="edit_container_header">{Text.OrderDetails}</p>
        <div />
      </div>

      {!isLoading ? (
        <>
          <div className="orderDetailHeader">
            <div className="orderDetailHeaderLeft">
              <p style={{ fontSize: 14, marginBottom: 10, fontWeight: 500 }}>
                {orderDeatails?.FullName}
              </p>
              <p style={{ fontSize: 14, marginBottom: 10, fontWeight: 500 }}>
                {orderDeatails?.Email}
              </p>
              <p style={{ fontSize: 14, marginBottom: 10, fontWeight: 500 }}>
                {orderDeatails?.Phone !== undefined
                  ? formatPhoneNumber(orderDeatails?.Phone)
                  : orderDeatails?.Phone}
              </p>
            </div>
            <div className="orderDetailHeaderRight">
              <p style={{ fontSize: 14, marginBottom: 10, fontWeight: 600 }}>
                {Text.ORDERHash}:{" "}
                <span style={{ fontWeight: 500 }}>
                  {orderDeatails?.OrderId}
                </span>
              </p>
              <p style={{ fontSize: 14, marginBottom: 10, fontWeight: 600 }}>
                {Text.STATUS}:{" "}
                <span style={{ fontWeight: 500 }}>
                  {" "}
                  {orderDeatails.Status === "ByOrganizer" &&
                  orderDeatails?.Total === "0"
                    ? "Free"
                    : orderDeatails.Status}
                </span>
              </p>
              {orderDeatails.Status === "ByOrganizer" ? (
                <p style={{ fontSize: 14, marginBottom: 10, fontWeight: 600 }}>
                  {Text.ORDERTYPE}:{" "}
                  <span style={{ fontWeight: 500 }}>Manual Entry</span>
                </p>
              ) : (
                <p style={{ fontSize: 14, marginBottom: 10, fontWeight: 600 }}>
                  {Text.ORDERTOTAL}:{" "}
                  <span style={{ fontWeight: 500 }}>
                    {localStorage.getItem("CurrencySymbol")}
                    {Intl.NumberFormat("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(Number(orderDeatails?.Total))}
                  </span>
                </p>
              )}

              <p style={{ fontSize: 14, marginBottom: 10, fontWeight: 600 }}>
                {Text.ORDERDATE}:{" "}
                <span style={{ fontWeight: 500 }}>
                  {orderDeatails &&
                    timeUTCtoLocal(
                      orderDeatails?.OrderItems[0]?.CreatedDateTime
                    )}
                </span>
              </p>
            </div>
          </div>
          <div className="orderDetailTable">
            <div className="orderDetailTable-header">
              <div style={{ width: "100%" }}>
                <h5>{Text.TicketType}</h5>
              </div>
              <div style={{ width: "100%", textAlign: "center" }}>
                <h5>{Text.Quantity}</h5>
              </div>
              <div style={{ width: "100%", textAlign: "end" }}>
                <h5>{Text.Price}</h5>
              </div>
            </div>

            {/* Move the variable declaration inside the code block */}
            {(() => {
              if (orderDeatails.OrderItems) {
                const groupedItems = orderDeatails.OrderItems.reduce((acc, item) => {
                  if (!acc[item.TicketId]) {
                    acc[item.TicketId] = {
                      title: item.Title,
                      Quantity: 0,
                      Price: 0.00,
                      dates: []
                    };
                  }

                  acc[item.TicketId].Quantity = parseInt(item.Quantity);
                  acc[item.TicketId].Price += parseFloat(item.Price);

                  if (item.RecurringTicket === "True") {
                    acc[item.TicketId].dates.push(eventDateDetails(item));
                  }
                  return acc;
                }, {});

                return Object.values(groupedItems).map((groupedItem, idx) => (
                    <div className="orderDetailTable-data" key={idx}>
                      <div style={{width: "100%"}}>
                        <span>{groupedItem.title}</span>
                        {groupedItem.dates.map((date, i) => (
                            <p
                                key={i}
                                style={{
                                  fontSize: "12px",
                                  color: "gray",
                                  fontWeight: "400",
                                }}
                            >
                              {date}
                            </p>
                        ))}
                      </div>
                      <div style={{width: "100%", textAlign: "center"}}>
                        <span>{groupedItem.Quantity}</span>
                      </div>
                      <div style={{width: "100%", textAlign: "end"}}>
<span>
  {Number(groupedItem.Price) === 0
      ? "Free"
      : `${localStorage.getItem("CurrencySymbol")}${Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(Number(groupedItem.Price))}`}
</span>
                      </div>
                    </div>
                ));
              } else {
                orderDeatails?.OrderItems?.map((item, idx) => {
                  const { Quantity, Price, Title, RecurringTicket } = item;
                  return (
                      <div className="orderDetailTable-data" key={idx}>
                        <div style={{ width: "100%" }}>
                          <span>{Title}</span>
                          {RecurringTicket === "True" && (
                              <p
                                  style={{
                                    fontSize: "12px",
                                    color: "gray",
                                    fontWeight: "400",
                                  }}
                              >
                                {eventDateDetails(item)}
                              </p>
                          )}
                        </div>
                        <div style={{ width: "100%", textAlign: "center" }}>
                          <span>{Quantity}</span>
                        </div>
                        <div style={{ width: "100%", textAlign: "end" }}>
        <span>
          {Number(Price) === 0
              ? "Free"
              : `${localStorage.getItem("CurrencySymbol")}${Intl.NumberFormat("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(Number(Price))}`}
        </span>
                        </div>
                      </div>
                  );
                })
              }
            })()}
          </div>
          <div className="totalOrder">
            {orderDeatails.Status !== "ByOrganizer" && (
              <div className="totalOrderContent">
                {orderDeatails &&
                  data.map((item) => {
                    return (
                      <div>
                        {(item.title === "Discount" && item.number === 0) ||
                        (item.title === "Tax" && item.number === 0) ? null : (
                          <div className="totalOrderDetail">
                            <p style={{ fontWeight: 600 }}>{item.title}</p>
                            <p
                              style={{
                                fontWeight: item.number === "$0.00" ? 500 : 600,
                                color:
                                  item.title === "Discount"
                                    ? "#19d400"
                                    : "#000",
                              }}
                            >
                              {item.title === "Discount" ? "-" : ""} {localStorage.getItem("CurrencySymbol")}
                              {Intl.NumberFormat("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }).format(Number(item.number))}
                            </p>
                          </div>
                        )}
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
          <div
            onClick={() => setVisible(!visible)}
            className="orderDetailRefund"
          >
            <p style={{ color: "#ca3dd9", fontWeight: 600 }}>
              {Text.IssueRefund}
            </p>
            <p>
              <AiFillCaretDown />
            </p>
          </div>
          {visible ? (
            <div className="refund">
              <h3>{Text.RefundText} </h3>
              <div className="checkList">
                <div
                  style={{
                    marginLeft: 8,
                    marginTop: 10,
                    display: "flex",
                    alignItems: "center",
                    gap: 25,
                  }}
                >
                  <input
                    checked={
                      notRefundedTicket?.length === itemId?.length ||
                      notRefundedTicket?.filter((t) => t.Status !== "Refunded")
                        ?.length === itemId.length
                    }
                    onChange={() => selectAllRefunds(notRefundedTicket)}
                    className="contacts_checkbox"
                    type="checkbox"
                  />
                  <span style={{ fontWeight: 600, fontSize: 16 }}>
                    {Text.RefundAll}
                  </span>
                </div>
                <table>
                  {notRefundedTicket?.length > 0 &&
                    notRefundedTicket?.map((ticket) => (
                      <tr>
                        <td>
                          {ticket.Status === "Refunded" ||
                          ticket.Status === "Canceled" ? (
                            <span style={{ color: "red" }}>
                              {Text.REFUNDED}
                            </span>
                          ) : (
                            <input
                              onChange={() => handleChangeRefund(ticket)}
                              checked={itemId.some(
                                ({ QrCode }) => QrCode === ticket?.QRCodeId
                              )}
                              type="checkbox"
                            />
                          )}
                        </td>
                        <td>
                          {
                            orderDeatails.Status === "ByOrganizer"
                            ? "-"
                            : `${localStorage.getItem("CurrencySymbol")}${Intl.NumberFormat("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }).format(Number(ticket.Total))}`
                          }
                        </td>
                        <td>{ticket.Title}</td>
                        <td>
                          <div>
                            <p>
                              {ticket.QRCodeId}
                            </p>
                            {
                                ticket.BookingDate !== undefined && ticket.BookingDate !== null && ticket.BookingDate !== "" &&
                                <p style={{color: "#5d1d63", fontWeight:"normal"}}>
                                  {eventDateDetails(ticket)}
                                </p>
                            }
                          </div>
                        </td>
                      </tr>
                    ))}
                </table>
              </div>

              <div className="orderFooter">
                <div className="orderFooterContent">
                  <div
                    style={{
                      cursor: itemId?.length > 0 ? "pointer" : "auto",
                      backgroundColor:
                        itemId?.length > 0 ? "#fff" : "rgb(236 236 236)",
                      color: itemId?.length > 0 ? "#ca3dd9" : "#000",
                    }}
                    onClick={() =>
                      itemId?.length > 0 ? CreatePartialRefund() : {}
                    }
                    className="orderFooterButton"
                  >
                    <p>{Text.ProcessRefund}</p>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </>
      ) : (
        progressLoader()
      )}
    </div>
  );
}

export default OrderDetail;
