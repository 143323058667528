import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import '../EventsOverview/EventsOverview.css';
class ManageEventsLineGraph extends Component {

  constructor(props) {
    super(props);
    this.state = {
      chartData: [{
        date: moment(),
        "Attendees": 0,
        "GrossProfit": 0,
        "Engagement": 0
      }],
      checkInData: [{
        x: 0,
        y: 0
      }],
      attendeesCount: 0,
      grossProfitTotal: 0,
      engagementPercentage: 0,
      chart: []
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
        attendeesCount: 0,
        grossProfitTotal: 0,
        engagementPercentage: 0
    });
    // if(nextProps.eventDashboardLineGraphData?.Result) {
    //   if(nextProps.eventDashboardLineGraphData?.Result.length > 0) {
    //     this.groupChartData(nextProps.eventDashboardLineGraphData?.Result);
    //   } else {
    //     this.setState({
    //      chartData: [{
    //        date: moment(),
    //        "Attendees": 0,
    //        "GrossProfit": 0,
    //        "Engagement": 0
    //       }],
    //       attendeesCount: 0,
    //       grossProfitTotal: 0,
    //       engagementPercentage: 0
    //     });
    //     this.props.dataCount(0, 0, 0);
    //   }
    // }
  }

  groupChartData = (chartData) => {
    let chartDetailDate = [];
    for (let i = 0; i < chartData.length; i++) {
      if (!chartDetailDate.includes(moment(moment.utc(chartData[i]["Activitydate"]).toDate()).local().format('MM/DD/YYYY'))) {
        chartDetailDate.push(moment(moment.utc(chartData[i]["Activitydate"]).toDate()).format('MM/DD/YYYY'));
      }
    }

    let sortedCars = chartDetailDate.sort((a, b) => Date.parse(new Date(a)) - Date.parse(new Date(b)));

    let dates = [...sortedCars];
    let formatArray = [];
    for(let i = 0; i < dates.length; i++) {
      let attendeesCount = 0;
      let grossProfitTotal = 0;
      let engagementPercentage = 0;
      for (let j = 0; j < chartData.length; j++) {
        if (moment(moment.utc(chartData[j]["Activitydate"])).local().format('YYYY/MM/DD') === dates[i]) {
          attendeesCount += chartData[j]["Attendees"];
          grossProfitTotal += chartData[j]["GrossProfitTotal"];
          engagementPercentage += chartData[j]["EngagementPercentage"];
        }
      }
      formatArray.push({
        date: dates[i],
        attendees: attendeesCount,
        grossProfitTotal: grossProfitTotal,
        engagementPercentage: engagementPercentage
      });
    }
    this.setState({chart: formatArray}, () => {
      let chartData = this.state.chart.map((d, i) => {
        return {
          date: moment(d.date).format('MMM Do, YYYY'),
          "Attendees": d.attendees,
          "Gross Profit": d.grossProfitTotal,
          "Engagement": d.engagementPercentage
        }
      });
      this.setState({chartData});
      chartData.forEach((data) => {
        this.state.attendeesCount += data['Attendees'];
      });
      chartData.forEach((data) => {
        this.state.grossProfitTotal += data['Gross Profit'];
      });
      chartData.forEach((data) => {
        this.state.engagementPercentage += data['Engagement'];
      });
      this.props.dataCount(this.state.attendeesCount, this.state.grossProfitTotal, this.state.engagementPercentage);
    });
  }


  

  render() {
    const { chartData } = this.props;
    const sortedArray = chartData?.sort(
      (a, b) =>
        new moment(a.date).format("X") -
        new moment(b.date).format("X")
    );

    const CustomTooltip = ({ active, payload, label, name }) => {
      if (active && payload && payload.length) {

        return (
          <div style={{backgroundColor:"#ffffff", border:"1px solid #808080" , height:80, width:'100%', padding:10 }} className="custom-tooltip">
            <p style={{fontSize:14, marginLeft:7, marginBottom:8 }} >
              {label}
            </p>
            {/*<p style={{color:name === "Sales" ? "rgb(0, 171, 85)" : name === "Attendees" ? "#faa82d" :  "rgb(118, 53, 220)", fontSize:14, fontWeight:400}} className="label">{`${name === "Attendees" ? "Ticket Holders" : name} : ${name === "Sales" ? "$" + Intl.NumberFormat(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(payload[0].value) : Intl.NumberFormat().format(payload[0].value)}`}</p>*/}
            <p style={{color:name === "Attendees" ? "#faa82d" :  "rgb(118, 53, 220)", fontSize:14, fontWeight:400}} className="label">{`${name === "Attendees" ? "Ticket Holders" : name} : ${name === "Sales" ? "$" + Intl.NumberFormat(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(payload[0].value) : Intl.NumberFormat().format(payload[0].value)}`}</p>
          </div>
        );
      }
    
      return null;
    };
    return (
      <div>
        {!chartData ?  <div
          style={{
            width: "100%",
            height: "345px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h3 style={{ color: "#808080", textAlign: "center" }}>
            {
            //   this.props.activeGraphData.name === "Sales" ?  "There were no sales recorded during this time!" :
            // this.props.activeGraphData.name === "Attendees" ? "There were no ticket holders during this time!" :
            //  "There was no event engagement during this time!"
                  this.props.activeGraphData.name === "Attendees" ? "There were no ticket holders during this time!" :
                      "There was no event engagement during this time!"
            }
          </h3>
        </div>:
            <LineChart
            width={800}
            height={300}
            data={sortedArray}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis  dataKey={`${this.props.activeGraphData.name}`} />
            <Tooltip content={<CustomTooltip name={this.props.activeGraphData.name}/>} />
            <Line
              type="monotone"
              dataKey={`${this.props.activeGraphData.name}`} 
              
              // stroke={this.props.activeGraphData.name === "Sales" ? "rgb(0, 171, 85)" : this.props.activeGraphData.name === "Attendees" ? "#faa82d" : "rgb(118, 53, 220)" }
              stroke={this.props.activeGraphData.name === "Attendees" ? "#faa82d" : "rgb(118, 53, 220)" }
              activeDot={{ r: 8 }}
    
            />
            {/* <Line type="monotone" dataKey="uv" stroke="yellow" /> */}
          </LineChart>
        }
  
    </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    eventDashboardLineGraphData: state.eventDashboardLineGraphData,
    user: state.loginResponse
  }
};
export default connect(mapStateToProps, null)(ManageEventsLineGraph);