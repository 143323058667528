export const handlbars = `<html>
<head>
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
    <meta name="viewport" content="width=device-width; initial-scale=1.0; maximum-scale=1.0;" />
    <title>UBMe Events</title>

    <style type="text/css">
        div,
        p,
        a,
        li,
        td {
            -webkit-text-size-adjust: none;
        }

        * {
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }

        a {
            text-decoration: none;
            color: #0066f7;
            font-weight: 600;
        }

        table td {
            border: 0 !important;
            background-color: transparent;
        }

        p {
            padding: 0 !important;
            margin-top: 0 !important;
            margin-right: 0 !important;
            margin-bottom: 0 !important;
            margin-left: 0 !important;
        }
    </style>
</head>

<body leftmargin="0" topmargin="0" marginwidth="0" marginheight="0" yahoo="fix">
    <!-- Mobile Wrapper -->
    <table width="100%" border="0" cellpadding="0" cellspacing="0" style="background-color: #ffffff !important;">
        <tr>
            <td width="100%">
                <table width="600" border="0" cellpadding="0" cellspacing="0" align="center" object="drag-module-small">
                    <tr>
                        <td width="100%" height="50"></td>
                    </tr>
                </table>

                <table width="600" align="center" style="-webkit-border-radius: 6px; -moz-border-radius: 6px; border-radius: 6px !important; border: 1px solid lightgray; padding: 15px;">
                    <tr>
                        <td width="100%">
                            <table width="500" border="0" cellpadding="0" cellspacing="0" align="center" class="mobile" object="drag-module-small">
                                <tr>
                                    <td width="100%" valign="middle" align="center">
                                        <table width="500" border="0" cellpadding="0" cellspacing="0" align="center" class="fullCenter">
                                            <tr>
                                                <td width="100%" align="center">
                                                    {{#if headerImg}}
                                                    <table border="0" cellpadding="0" cellspacing="0" align="center" class="buttonScale">
                                                        <tr>
                                                            <td align="center" height="40" bgcolor="#3d85b8" style="background: transparent;">
                                                                <img src="{{{headerImg}}}" width="500px" alt="header_img" />
                                                            </td>
                                                        </tr>
                                                    </table>
                                                    {{/if}}
                                                </td>
                                            </tr>
                                        </table>

                                        <!-- start -->
                                        <table width="500" border="0" cellpadding="0" cellspacing="0" align="center" object="drag-module-small">
                                            <tr>
                                                <td width="100%" height="10"></td>
                                            </tr>
                                        </table>

                                        <table width="500" border="0" cellpadding="0" cellspacing="0" align="center" class="mobile">
                                            <tr>
                                                <td width="100%" valign="middle" align="center">
                                                    <table width="500" style="text-align: center;" border="0" cellpadding="0" cellspacing="0" align="center" class="fullCenter">
                                                        {{#if editorHtml1}}
                                                        <tr>
                                                            <td
                                                                valign="middle"
                                                                width="100%"
                                                                style="color: #888; text-align: center; font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 14px; font-weight: 500;"
                                                                class="fullCenter"
                                                            >
                                                            {{{editorHtml1}}}
                                                            </td>
                                                        </tr>
                                                        
                                                         
                                                         {{else}}
                                                       
                                                        <tr>
                                                            <td width="100%" height="30" style="font-size: 1px; line-height: 1px;">
                                                                &nbsp;
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                valign="middle"
                                                                width="100%"
                                                                style="
                                                                    margin: 0px !important;
                                                                    text-align: center;
                                                                    font-family: 'Open Sans', Helvetica, Arial, sans-serif;
                                                                    font-size: 30px !important;
                                                                    color: rgb(94, 94, 94);
                                                                    
                                                                    font-weight: 600;
                                                                "
                                                                class="fullCenter"
                                                            >
                                                                Let's get together
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                valign="middle"
                                                                width="100%"
                                                                style="color: #888; text-align: center; font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 14px; font-weight: 500;"
                                                                class="fullCenter"
                                                            >
                                                                We're hosting a new event, and we'd love to see you there. Join us for {{Title}}{{#unless value.RecurringTicket}}, {{StartDateTime}}{{/unless}}
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td
                                                                valign="middle"
                                                                width="100%"
                                                                style="color: #888; text-align: center; font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 14px; font-weight: 500;"
                                                                class="fullCenter"
                                                            >
                                                                Register soon because space is limited.
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                valign="middle"
                                                                width="100%"
                                                                style="color: #888; text-align: center; font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 14px; font-weight: 500;"
                                                                class="fullCenter"
                                                            >
                                                                We hope you're able to join us!
                                                            </td>
                                                        </tr>
                                                        {{/if}}
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>
                                        <!-- End -->
                                    </td>
                                </tr>
                            </table>

                            <table width="500" border="0" cellpadding="0" cellspacing="0" align="center" class="mobile" object="drag-module-small">
                                <tr>
                                    <td width="100%" valign="middle" align="center">
                                        <table width="600" border="0" cellpadding="0" cellspacing="0" align="center" class="fullCenter">
                                            <tr>
                                                <td width="100%" height="20"></td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                            {{#each data as |value key|}}
                          

                            <table width="500" border="0" align="center" style="background-color: #ffffff; border: 1px solid lightgray; border-radius: 5px; padding: 0px 0px 20px 0px;">
                                <tr>
                                    <td width="100%">
                                        {{#if value.ImageSmall}}
                                        <img src="{{{value.ImageSmall}}}" style="width: 100%; height: auto;" alt="UBMe" />
                                        {{else}}
                                        <img src="{{{value.SmallImage}}}" style="width: 100%; height: auto;" alt="UBMe" />
                                        {{/if}}

                                        <table width="500" border="0" cellpadding="0" cellspacing="0" align="center" object="drag-module-small">
                                            <tr>
                                                <td width="100%" height="20"></td>
                                            </tr>
                                        </table>
                                        <table width="500" border="0" cellpadding="0" cellspacing="0" align="center" object="drag-module-small">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <table width="500" border="0" cellpadding="0" cellspacing="0">
                                                            <tbody>
                                                                <tr>
                                                                    <td width="70%">
                                                                        <table width="100%" border="0" cellpadding="0" cellspacing="0">
                                                                            <tr>
                                                                                {{#if value.RecurringTicket}}
                                                                                <td
                                                                                    valign="middle"
                                                                                    width="100%"
                                                                                    style="
                                                                                        text-align: start;
                                                                                        font-family: 'Open Sans', Helvetica, Arial, sans-serif;
                                                                                        font-size: 14px;
                                                                                        color: #888;
                                                                                        line-height: 24px;
                                                                                        font-weight: 600;
                                                                                        padding-left: 10px;
                                                                                    "
                                                                                    class="fullCenter"
                                                                                >
                                                                                    <p style="font-size: 14px; color: #000000; font-weight: 600;">
                                                                                        {{Title}}
                                                                                    </p>
                                                                                </td>
                                                                                {{else}}
                                                                                <td width="15%" style="vertical-align: top; text-align: end;">
                                                                                {{#unless value.RecurringTicket}}
                                                                                    <img src="https://d2gnb7jphto2jj.cloudfront.net/img/clock.png" style="width: 35px;" alt="clocl-icon" />
                                                                                {{/unless}}
                                                                                </td>
                                                                                <td
                                                                                    valign="middle"
                                                                                    width="85%"
                                                                                    style="
                                                                                        text-align: start;
                                                                                        font-family: 'Open Sans', Helvetica, Arial, sans-serif;
                                                                                        font-size: 14px;
                                                                                        color: #888;
                                                                                        line-height: 24px;
                                                                                        font-weight: 600;
                                                                                        padding-left: 10px;
                                                                                    "
                                                                                    class="fullCenter"
                                                                                >
                                                                                    {{#unless value.RecurringTicket}}
                                                                                        {{value.StartDateTime}}
                                                                                    {{/unless}}
                                                                                    <br />
                                                                                    <p style="font-size: 14px; color: #000000; font-weight: 600;">
                                                                                        {{Title}}
                                                                                    </p>
                                                                                </td>
                                                                                {{/if}}
                                                                            </tr>
                                                                        </table>
                                                                    </td>
                                                                    <td width="30%" style="text-align: end;">
                                                                        <table width="100%" border="0" cellpadding="0" cellspacing="0">
                                                                            <tr>
                                                                                <td
                                                                                    valign="middle"
                                                                                    width="100%"
                                                                                    style="
                                                                                        text-align: center;
                                                                                        font-family: 'Open Sans', Helvetica, Arial, sans-serif;
                                                                                        font-size: 14px;
                                                                                        color: rgb(0, 0, 0);
                                                                                        line-height: 24px;
                                                                                        font-weight: 600;
                                                                                    "
                                                                                    class="fullCenter"
                                                                                >
                                                                                    <a
                                                                                        style="
                                                                                            color: #ffffff;
                                                                                            padding: 15px 20px;
                                                                                            background-color: rgb(205, 79, 236);
                                                                                            text-transform: capitalize;
                                                                                            color: white;
                                                                                            border-radius: 5px;
                                                                                            border: none;
                                                                                            outline: none;
                                                                                            font-weight: 600;
                                                                                            margin-bottom: 2rem;
                                                                                            margin-top: 1.5rem;
                                                                                        "
                                                                                        target="_blank"
                                                                                        href="{{../baseUrl}}/event/{{value.EventId}}"
                                                                                    >
                                                                                        Register
                                                                                    </a>
                                                    
                                                                                </td>
                                                                            </tr>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                            <table width="500" border="0" cellpadding="0" cellspacing="0" align="center" object="drag-module-small">
                            <tr>
                                <td width="100%" height="10"></td>
                            </tr>
                        </table>
                            {{/each}}

                            <table width="500" border="0" cellpadding="0" cellspacing="0" align="center" object="drag-module-small">
                                <tr>
                                    <td width="100%" height="20"></td>
                                </tr>
                            </table>
                            <table width="500" border="0" cellpadding="0" cellspacing="0" align="center" style="padding-bottom: 10px;">
                                <tr>
                                    <td width="100%" align="center">
                                        <img src="https://d2gnb7jphto2jj.cloudfront.net/img/star.png"
                                             alt="star_icon"
                                             style="width: 20px !important; height: 20px !important;"
                                        />
                                        <span style="color: #ca3dd9; padding-left: 10px; text-align: center; font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 18px; font-weight: 500;">Enhanced Experience Available</span>
                                    </td>
                                </tr>
                            </table>

                            <table width="500" border="0" cellpadding="0" cellspacing="0" align="center" class="fullCenter">
                                <tr>
                                    <td width="100%">
                                        <p style="color: #888; text-align: center; font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 14px; font-weight: 500;">
                                            <!-- {{eventTitle}} -->
                                            Check-in using UBMe app for a better experience during the event!
                                        </p>
                                    </td>
                                </tr>
                            </table>

                            <!-- Card View -->
                            <table class="mobile_view_checkIn_hidden" width="500" align="center">
                                <tr>
                                    <td class="ticket" valign="middle" width="50% !important" style="text-align: end;">
                                        <a href="https://play.google.com/store/apps/details?id=com.UBMe&hl=en_IN&gl=US" target="_blank">
                                            <img
                                                src="https://d2gnb7jphto2jj.cloudfront.net/img/android.png"
                                                alt="play_store"
                                                style="width: 130px !important; margin-top: 10px !important; padding-left: 10px !important; padding-right: 10px !important;"
                                            />
                                        </a>
                                    </td>
                                    <td class="ticket" valign="middle" width="50% !important" style="text-align: start;">
                                        <a href="https://apps.apple.com/us/app/ubme/id1200370709" target="_blank">
                                            <img
                                                src="https://d2gnb7jphto2jj.cloudfront.net/img/apple.png"
                                                alt="App_store"
                                                style="width: 130px !important; margin-top: 10px !important; padding-left: 10px !important; padding-right: 10px !important;"
                                            />
                                        </a>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>

                <!-- Social -->
                <table class="mobile_view_checkIn_hidden" width="200" align="center">
                    <tr>
                        {{#if facebook}}
                        <td class="ticket" valign="middle" style="text-align: end;">
                            <a href="{{{facebook}}}" target="_blank">
                                <img src="https://www.bestha.co.uk/images/facebook.png" alt="play_store" style="width: 40px !important; margin-top: 10px !important; padding-left: 10px !important; padding-right: 10px !important;" />
                            </a>
                        </td>
                        {{/if}} {{#if instagram}}
                        <td class="ticket" valign="middle" style="text-align: center;">
                            <a href="{{{instagram}}}" target="_blank">
                                <img
                                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/95/Instagram_logo_2022.svg/2048px-Instagram_logo_2022.svg.png"
                                    alt="App_store"
                                    style="width: 40px !important; margin-top: 10px !important; padding-left: 10px !important; padding-right: 10px !important;"
                                />
                            </a>
                        </td>
                        {{/if}} {{#if twitter}}
                        <td class="ticket" valign="middle" style="text-align: start;">
                            <a href="{{{twitter}}}" target="_blank">
                                <img src="https://pbs.twimg.com/media/FM6j9MKWQAI3aXP.png" alt="App_store" style="width: 40px !important; margin-top: 10px !important; padding-left: 10px !important; padding-right: 10px !important;" />
                            </a>
                        </td>
                        {{/if}}
                    </tr>
                </table>

                <table width="500" border="0" cellpadding="0" cellspacing="0" align="center" object="drag-module-small">
                    <tr>
                        <td width="100%" style="text-align: center; line-height: 26px; font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 14px; font-weight: 600;">
                            <p>{{organizer}}</p>
                            <p>{{address1}}</p>
                            <p>{{address2}}</p>
                            <p>{{city}}, {{region}} {{zip}}, {{country}}</p>
                            <a href="{{baseUrl}}/unsubscribe/{{UserId}}/##UnSubscribeEmail##" target="_blank">Unsubscribe</a>
                        </td>
                    </tr>
                </table>

                <table width="352" border="0" cellpadding="0" cellspacing="0" align="center" object="drag-module-small">
                    <tr>
                        <td width="100%" height="60" style="font-size: 1px; line-height: 1px;">
                            &nbsp;
                        </td>
                    </tr>
                    <tr>
                        <td width="100%" height="1" style="font-size: 1px; line-height: 1px;">
                            &nbsp;
                        </td>
                    </tr>
                </table>
                <!-- End CopyRight -->
            </td>
        </tr>
    </table>
</body>
</html>




`;
