import React from "react";
import { AiFillInfoCircle } from "react-icons/ai";
import LandingPageLineChart from "./LandingPageLineChart";
import "./Sponsors.css";
import ToolTip from "./tools/ToolTip";
import GraphData from "../Document/GraphData";
import { Text, URLs } from "Constants";
import MonetizationView from "./monetization/MonetizationView";
import moment from "moment";

function Monetization({ activityData, title, span, meta, Desc, eventTitle }) {

  let AdsClickthroughData = [];
  if (Array.isArray(activityData?.AdsClickthrough?.Ads)) {
    AdsClickthroughData = activityData?.AdsClickthrough?.Ads;
  } else {
    AdsClickthroughData.push(activityData?.AdsClickthrough?.Ads);
  };


  return (
    <div>
      <div className="appEngageBorder" />
      <div className="appEngagementDesc">
        <h3 style={{ fontSize: 20, fontWeight: 500 }}>
          {title} -{" "}
          <span style={{ fontSize: 16, fontWeight: 500 }}>{span}</span>
        </h3>
      </div>
      <div className="activityHeader monitizationHeader">
        {meta === "Sponser" ? (
          <div>
            {ToolTip(
              "Sponsors",
              "Insights on impressions and clicks for each sponsor"
            )}
          </div>
        ) : (
          <div>
            {ToolTip("Ads", "Detailed information about your set ads")}
          </div>
        )}
      </div>
      <div>
        {activityData?.AdsClickthrough?.Ads ? (
          <>
            {AdsClickthroughData?.map(
              ({ Logo, Title1, URL, Interaction , Impression , Title2 }) => {
                const checkisArray = (data, name) => {
                  let PageStatistics = [];
                  if (Array.isArray(data)) {
                    PageStatistics = data;
                  } else {
                    PageStatistics.push(data);
                  }
                 
                  if (name === "impressions") {
                    return !PageStatistics[0] ? []:  PageStatistics?.map((items) => ({
                      value: Number(items?.Count?.replace(/,/g, "")),
                      title: name,
                      Date:moment(items.Date).format("MM/DD/YYYY")
                    }));
                  } else {
                    return !PageStatistics[0] ? []:  PageStatistics?.map((items) => ({
                      value: Number(
                        items?.hasOwnProperty("@Count") ? items["@Count"]?.replace(/,/g, "") : 0
                      ),
                      title: name,
                      Date: items?.hasOwnProperty("@CreatedDate")
                        ? moment(items["@CreatedDate"]).format("MM/DD/YYYY")
                        : "",
                    }));
                  }
                };
                return (
                 <MonetizationView {...{eventTitle, checkisArray, activityData, Logo, Title1, Title2, website:URL, Interaction , Impression}}/>
                );
              }
            )}
          </>
        ) : (
          <div
            style={{
              width: "400px",
              height: 150,
              display: "flex",
              alignItems: "center",
            }}
          >
            <h4
              style={{ color: "#808080", marginLeft: 30, textAlign: "center" }}
            >
              {Text.NoData}
            </h4>
          </div>
        )}
      </div>
    </div>
  );
}

export default Monetization;
