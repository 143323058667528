import React, { useState } from "react";
import "./ForgotPassword.css";
import * as constants from "Constants";
import { MdAlternateEmail } from "react-icons/md";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { ValidateUser } from "Actions";
import { IoMdRadioButtonOn } from "react-icons/io";
import axiosI from "Service/service";
import { UserIconsPic } from "../../Components_UBMeEvents/Navbar/Alphabets/UserIconsPic";

const ForgotPassword = ({ setForgotPassword, ValidateUser, dispatch }) => {
  const [email, setEmail] = useState("");
  const [valid, setValid] = useState(false);
  const [data, setData] = useState("");
  const [lblErrorMsg, setLblErrorMsg] = useState("");
  const [userPic, setUserPic] = useState("");
  const [text, setText] = useState(
    constants.LoginSignUpText.sendemailtoforgotpass
  );
  const [success, setSuccess] = useState("");
  const handleClick = () => {
    axiosI
      .get(`${constants.API.ValidateUser}/${email}`)
      .then(({ data }) => {
        if (data.results.Message !== "User is invalid") {
          setData(data.results);
          setValid(true);
          setUserPic(
            UserIconsPic(data && data.results.FirstName[0]?.toUpperCase())
          );
          setText(constants.Message.uservalid);
          setLblErrorMsg("");
        } else {
          setValid(false);
          setText(constants.Message.usernotvalid);
          setLblErrorMsg(constants.Message.notAssosiate);
        }
      })
      .catch(() => {});
  };

  const SentEmailResetPass = () => {
    axiosI
      .post(constants.API.LinkUpsUserForgetPassword, { Email: data?.Email })
      .then(({ data }) => {
        if (data.results.ResponseCode) {
          setSuccess(data.results.Message);
        }
      })
      .catch(() => {});
  };

  return (
    <div style={{ marginLeft: 30, marginRight: 30, marginTop: 25 }}>
      <h4
        style={{
          marginTop: 10,
          marginBottom: 16,
          color: "#000000",
          fontWeight: "bold",
        }}
      >
        {constants.LoginSignUpText.forgot_Password}
      </h4>
      <div className="error">
        {lblErrorMsg !== "" ? <h4>{lblErrorMsg}</h4> : <span />}
      </div>
      {success !== "" && (
        <div style={{ marginBottom: 10 }}>
          <h5 style={{ color: "#03ad31" }}>{success}</h5>
        </div>
      )}
      {valid && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img style={{ width: 40, height: 40 }} src={userPic} alt="1" />
          <div style={{ marginLeft: 15 }}>
            <h3 style={{ fontWeight: "bold" }}>{data?.FirstName}</h3>
            <span>
              {data?.UserName[0] === "@"
                ? data?.UserName
                : `@${data?.UserName}`}
            </span>
          </div>
        </div>
      )}
      <div style={{ marginTop: 20, marginBottom: 30 }}>
        <h5 style={{ color: "#808080", lineHeight: 1.5 }}>{text}</h5>
      </div>

      {valid ? (
        <div>
          <div
            style={{ display: "flex", alignItems: "center", marginBottom: 15 }}
          >
            <IoMdRadioButtonOn size={25} />
            <h6>{`${constants.Message.EmailLink} ${
              data && data?.Email?.replace(data?.Email.substring(3, 6), "****")
            }`}</h6>
          </div>
        </div>
      ) : (
        <div class="row_input_forgot">
          <input
            placeholder={constants.Message.forgotPasswordplaceholder}
            name="currpassword"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
          />
        </div>
      )}

      <div className="login_cred">
        <a
          className="login_button "
          href="#!"
          onClick={() => (valid ? SentEmailResetPass() : handleClick())}
          style={{ color: "#fff", fontSize: 18, textDecoration: "none" }}
        >
          {constants.LoginSignUpText.submit}
        </a>
        <Link
          style={{ color: "#03ad31" }}
          onClick={() => {
            setForgotPassword(false);
          }}
          to="#"
        >
          <h4 style={{ fontWeight: "bold" }} className="forgot_password">
            {constants.LoginSignUpText.loginHere}
          </h4>
        </Link>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    latlong: state.favoriteReducer.latlong,
    isLoggedIn: state.loginResponse.isLoggedIn,
    results: state.loginResponse.results,
    cardData: state.favoriteReducer.cardData,
  };
};
const matchDispatchToProps = (dispatch) => {
  return {
    ValidateUser: () => dispatch(ValidateUser),
    dispatch,
  };
};
export default connect(mapStateToProps, matchDispatchToProps)(ForgotPassword);
