import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import EventsOverview from "../EventsOverview/eventsOverview";
import About from "../CreateEvent/EventDetails/eventDetailsAbout";
import FAQ from "../CreateEvent/EventDetails/eventDetailsFAQ";
import Agenda from "../CreateEvent/EventDetails/eventDetailsAgenda";
import Messages from "../Messages/messages";
import PrivateRoute from "../../RouteTypes/privateRoute";
import ErrorPage from "../../../Helper/ErrorPage/errorPage";
import { routeName } from "../../../../src/Service/env";
import "./routes.css";
import createEventVendors from "../CreateEvent/EventDetails/eventDetailsVendors";
import eventDetailsSponsors from "../CreateEvent/EventDetails/eventDetailsSponsors";
import eventDetailsAds from "../CreateEvent/EventDetails/eventDetailsAds";
import CreateEventPromoteEventFlyers from "../CreateEvent/CreateEventPromote/createEventPromoteEventFlyers";
import CreateEventPublish from "../CreateEvent/createEventPublish";
// TICKETS SECTION
import createTickets from "../CreateEvent/Tickets/createTickets";
import discounts from "../CreateEvent/Tickets/discounts";
import salesTax from "../CreateEvent/Tickets/salesTax";
import orderForm from "../CreateEvent/Tickets/orderForm";
import payouts from "../CreateEvent/Tickets/payouts";
import EmailInvitation from "../CreateEvent/EmailInvatation/emailInvitation";
import Flyer from "../CreateEvent/Flyers/Flyer";
import PublicRoute from "../../RouteTypes/publicRoute";
import Analytic from "../CreateEvent/Analytics/Analytic";
import EventExperience from "../CreateEvent/EventExperience/EventExperience";
import HomeScreen from "Components/UBMeEvents/Home/HomeScreen/HomeScreen";
import Sales from "../EventSales/Sales";
import EventActivity from "../EventActivity/eventActivity";
import EventBookings from "../CreateEvent/EventBookings/eventBookings";
import EventAttendence from "../CreateEvent/EventAttendence/EventAttendence";

class Routes extends Component {
  render() {
    const { user } = this.props;
    return (
      <div>
        <Switch>

          <Route path={`${routeName}/Dashboard/Tickets/Payouts`} component={payouts} user={user} />

          <PrivateRoute exact path={`${routeName}/Dashboard/EventsOverview`} component={EventsOverview} user={user} />
          <PrivateRoute exact path={`${routeName}/Dashboard/Tickets/Create`} component={createTickets} user={user} />
          <PrivateRoute exact path={`${routeName}/Dashboard/Tickets/Discounts`} component={discounts} user={user} />
          <PrivateRoute exact path={`${routeName}/Dashboard/Tickets/SalesTax`} component={salesTax} user={user} />
          <PrivateRoute exact path={`${routeName}/Dashboard/Tickets/OrderForm`} component={orderForm} user={user} />
          <PrivateRoute exact path={`${routeName}/Dashboard/About`} component={About} user={user} />
          <PrivateRoute exact path={`${routeName}/Dashboard/FAQ`} component={FAQ} user={user} />
          <PrivateRoute exact path={`${routeName}/Dashboard/Agenda`} component={Agenda} user={user} />
          <PrivateRoute exact path={`${routeName}/Dashboard/Vendors`} component={createEventVendors} user={user} />
          <PrivateRoute exact path={`${routeName}/Dashboard/Sponsors`} component={eventDetailsSponsors} user={user} />
          <PrivateRoute exact path={`${routeName}/Dashboard/Activity`} component={EventActivity} user={user} />
          <PrivateRoute exact path={`${routeName}/Dashboard/Ads`} component={eventDetailsAds} user={user} />
          <PrivateRoute exact path={`${routeName}/Dashboard/Messages`} component={Messages} user={user} />
          <PrivateRoute exact path={`${routeName}/Dashboard/Promote`} component={CreateEventPromoteEventFlyers} user={user} />
          <PrivateRoute exact path={`${routeName}/Dashboard/Publish`} component={CreateEventPublish} user={user} />
          <PrivateRoute exact path={`${routeName}/Dashboard/EmailInvitation`} component={EmailInvitation} user={user} />
          <PrivateRoute exact path={`${routeName}/Dashboard/Flyers`} component={Flyer} user={user} />
          <PrivateRoute exact path={`${routeName}/Dashboard/Attendees`} component={EventAttendence} user={user} />
          <PrivateRoute exact path={`${routeName}/Dashboard/Bookings/:eventId`} component={EventBookings} user={user} />
          <PrivateRoute exact path={`${routeName}/Dashboard/Analytics`} component={Analytic} user={user} />
          <PrivateRoute exact path={`${routeName}/Dashboard/EventExperience`} component={EventExperience} user={user} />
          <PrivateRoute exact path={`${routeName}/Dashboard/AllSales`} component={Sales} user={user}/>

          <Route path="*" component={ErrorPage} />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.loginResponse.results,
  };
};

export default withRouter(connect(mapStateToProps, null)(Routes));
