import React, {useEffect, useRef, useState} from "react";
import Grid from "styles/Grid.jsx";
import {GoogleMap, InfoWindow,} from "@react-google-maps/api";
import {Text} from "Constants";
import {FaDirections} from "react-icons/fa";
import styles from "./EventMap.module.css";
import organizers_background from "images/organizers_background.jpg";
import {UserIconsPic} from "../../Components_UBMeEvents/Navbar/Alphabets/UserIconsPic";
import axiosI, {axiosE} from "../../../../Service/service";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import {routeName} from "../../../../Service/env";

const EventMap = ({
  latlongData,
  title,
  ImageSmall,
  discription,
  startDateTime,
  VenueName,
  Add1,
  parking,
  userProfileData,
  onContactClick,
  isLoggedIn,
  LoginPopup,
  results,
}) => {
  // const { isLoaded } = useLoadScript({
  //   googleMapsApiKey: API.GOOGLE_API_KEY, // Add your API key
  // });
  const OPTIONS = {
    restriction: {
      latLngBounds: { north: 85, south: -85, west: -180, east: 180 },
      strictBounds: true,
    },
  };

  const containerRef = useRef(null);
  const [isScrollable, setIsScrollable] = useState(false);
  const [isFollowing, setIsFollowing] = useState(
        userProfileData?.OrginizerDetails?.IsFollowing === 'True' ? true : false
    );
  const [followerCount, setFollowerCount] = useState(
        userProfileData?.OrginizerDetails?.FollowerCount
            ? parseInt(userProfileData.OrginizerDetails.FollowerCount, 10)
            : 0
    );

  useEffect(() => {
    const container = containerRef.current;

    if (container) {
      const images = container.getElementsByTagName('img');
      let loadedImageCount = 0;

      const handleImageLoad = () => {
        loadedImageCount++;

        if (loadedImageCount === images.length) {
          // All images have loaded, calculate the dimensions
          const containerScrollHeight = container.scrollHeight;
          const containerClientHeight = container.clientHeight;

          setIsScrollable(containerScrollHeight > containerClientHeight);
        }
      };

      // Attach a load event listener to each image
      for (let i = 0; i < images.length; i++) {
        images[i].addEventListener('load', handleImageLoad);
      }

      // Calculate the dimensions immediately in case there are no images
      if (images.length === 0) {
        const containerScrollHeight = container.scrollHeight;
        const containerClientHeight = container.clientHeight;

        setIsScrollable(containerScrollHeight > containerClientHeight);
      }

      // Clean up by removing the event listeners when the component unmounts
      return () => {
        for (let i = 0; i < images.length; i++) {
          images[i].removeEventListener('load', handleImageLoad);
        }
      };
    }
  }, []);

  useEffect(() => {
    const container = containerRef.current;

    if (container) {
      container.style.display = isScrollable ? 'block' : 'flex';
    }
  }, [isScrollable]);


  const replcaeEmptyTagwithBreak = (html) => {
    return html
      ?.replaceAll("<p></p>", "<br>")
      .replaceAll("<h1></h1>", "<br>")
      .replaceAll("<h2></h2>", "<br>")
      .replaceAll("<h3></h3>", "<br>")
      .replaceAll("<h4></h4>", "<br>")
      .replaceAll("<h5></h5>", "<br>")
      .replaceAll("<h6></h6>", "<br>")
      .replaceAll("<li></li>", "<br>")
      .replaceAll("<ul></ul>", "<br>")
      .replaceAll("<pre></pre>", "<br>")
      .replaceAll("<blockquote></blockquote>", "<br>");
  };

  function isEmptyHtmlTag(tag) {
    // console.log({ tag });
    if (tag === "" || !tag) return false;
    // Remove spaces and convert to lowercase
    const cleanedTag = tag.trim().toLowerCase();

    // Use regular expression to match empty HTML-like tags
    const pattern = /^<[^>]+>\s*<\/[^>]+>$/;
    return pattern.test(cleanedTag);
  }

    const renderContactButton = () => {
        if (!isLoggedIn || (results?.ProfileDetail.UserId !== userProfileData?.UserId)) {
            return (
                <button
                    onClick={!isLoggedIn ? () => handleLoginCall("messages") : onContactClick}
                    className={styles.event_map_btn}
                    style={{ backgroundColor: (userProfileData?.EventColor !== undefined && userProfileData?.EventColor !== "") ? userProfileData?.EventColor : "#9b189b"}}
                >
                    {Text.Contact}
                </button>
            );
        }
        return null;
    };

    const renderFollowButton = () => {
        // State for follow status
        const isBlock = userProfileData?.OrginizerDetails?.IsBlock === 'True';

        // Render the follow button
        if (!isLoggedIn || (userProfileData?.UserId !== results?.ProfileDetail.UserId && !isBlock)) {
            return (
                <button
                    onClick={handleFollowClick}
                    className={styles.event_map_btn}
                    style={{ backgroundColor: (userProfileData?.EventColor !== undefined && userProfileData?.EventColor !== "") ? userProfileData?.EventColor : "#9b189b"}}
                >
                    {isFollowing ? Text.Unfollow : Text.Follow}
                </button>
            );
        }

        return null;
    };

    // Event handler for follow button click
    const handleFollowClick = async () => {
        if (!isLoggedIn) {
            handleLoginCall("followUnfollow");

            return;
        }

        try {
            const { data } = await axiosI.get(
                `FollowAndUnFollowUser/${results?.ProfileDetail.UserId}/${userProfileData?.UserId}/${isFollowing ? Text.Unfollow : Text.Follow}`
            );
            if(data.results.ResponseCode){
                setIsFollowing(!isFollowing);
                // Update follower count based on follow/unfollow action
                setFollowerCount(prevCount => isFollowing ? prevCount - 1 : prevCount + 1);

                toast(isFollowing ? `You have unfollowed ${userProfileData?.OrginizerDetails?.FirstName}.` : `You are now following ${userProfileData?.OrginizerDetails?.FirstName}.`);

            };
        } catch (error) {
            console.error('Error updating follow status:', error);
        }
    };

    const handleLoginCall = (type) => {
        if (!isLoggedIn) {
            localStorage.setItem("pendingEventsButtonClick", type);
            if (type === "followUnfollow") {
                localStorage.setItem("pendingFollowUnfollowOrganizerCall", "true");
            }
            LoginPopup(true);
        }
    };

    useEffect(() => {
        const isLogedIn = localStorage.getItem("isLogedIn");
        const pendingAction = localStorage.getItem("pendingEventsButtonClick");
        const pendingFollowUnfollowOrganizerCall = localStorage.getItem("pendingFollowUnfollowOrganizerCall");

        if (isLogedIn) {
            if (pendingAction === "followUnfollow" && pendingFollowUnfollowOrganizerCall === "true") {
                // Perform the follow/unfollow action
                handleFollowClick();
            } else if (pendingAction === "messages") {
                onContactClick();
            }

            // Clear the stored action data from local storage
            localStorage.removeItem("pendingEventsButtonClick");
            localStorage.removeItem("pendingFollowUnfollowOrganizerCall");
        }
    }, []);

  const newDescription = replcaeEmptyTagwithBreak(discription);
  const newParking = replcaeEmptyTagwithBreak(parking);
  const isEmptyTag = isEmptyHtmlTag(parking);


    const contactButton = renderContactButton();
    const followButton = renderFollowButton();

    return (
    <div>
        <ToastContainer
            className="toaster-container"
            position="top-right"
            autoClose={5000}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />

      {" "}
      <Grid row={true} >
        <Grid column={true} sm={12} md={6}  >
          <div
            ref={containerRef}
            style={{
              flexDirection: "column",
              justifyContent: "center"
            }}
            className={styles.about_events_desc}
          >
            <div className={styles.about_events}>
              <h2 className={styles.about_events_title}>
                <span className={styles.event_footer_app_about}>
                  {Text.About}
                </span>{" "}
                {title?.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                  letter.toUpperCase()
                )}
              </h2>
              <div className={styles.about_events_title_underline} />
              <h4 className={styles.about_events_desc_title}>
                {Text.Description}
              </h4>
              <div
                className={styles.mystyle_img}
                dangerouslySetInnerHTML={{
                  __html: newDescription,
                }}
              />
              {parking !== "" && !isEmptyTag && (
                <>
                  <h4 className={styles.about_events_parking_title}>
                    {Text.Parking}
                  </h4>
                  <p className={styles.about_events_parking}>
                    <div
                      className={styles.mystyle_img}
                      dangerouslySetInnerHTML={{ __html: newParking }}
                    />
                  </p>
                </>
              )}
            </div>
          </div>
        </Grid>
        <Grid column={true} sm={12} md={6}>
            <div style={{
                position: 'relative',
                height: '100vh',
                width: '100%',
                overflow: 'hidden',
            }}>
              <div
                  style={{
                      position: 'absolute', // Change to relative
                      height: '100vh',
                      zIndex: -1,
                  }}
              >
                  <img
                      src={organizers_background}
                      alt="Background"
                      style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                          opacity: 0.60,
                      }}
                  />
                  <div
                      style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          // backgroundColor: 'rgba(0, 0, 0, 0.3)', // Adjust opacity as needed
                      }}
                  ></div>
              </div>
              <div
                  style={{
                      position: 'relative',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      zIndex: 0,
                      width: '100%',
                      textAlign: 'center',
                  }}
              >
                  <div style={{
                      paddingBottom:'40px'
                  }}>
                      <h2 className={styles.event_map_heading}>
                          {Text.ORGANIZERDETAILS}
                      </h2>
                      <img
                          src={
                              userProfileData?.OrginizerDetails?.ProfileImage === null
                                  ? UserIconsPic(userProfileData?.OrginizerDetails?.FirstName[0].toUpperCase())
                                  : userProfileData?.OrginizerDetails?.ProfileImage
                          }
                          alt=""
                          className={styles.event_map_image}
                          style={{ maxWidth: "100px", height: "100px" }}
                      />
                      <h4 style={{
                          paddingTop:'20px'
                      }}
                      >
                          {followerCount}  {'followers'}
                      </h4>
                      <h3 className={`${styles.event_map_Organizername} ${styles.clickableOrganizerName}`}>
                          <a target="_blank" href={`${routeName}/${userProfileData?.UserId}~${userProfileData?.OrginizerDetails?.Username}`}>
                              {`${userProfileData?.OrginizerDetails?.FirstName} ${userProfileData?.OrginizerDetails?.LastName || ''}`}
                          </a>
                      </h3>
                      <p className={styles.event_map_OrganizerBio}>
                          {userProfileData?.OrginizerDetails?.Bio || "I Love UBMe"}
                      </p>
                  </div>

                  <div className={styles.event_map_btn_box}>
                      {contactButton}
                      {contactButton && followButton && <div style={{ padding: '0 10px' }} />}
                      {followButton}
                      {/* Add padding between buttons if both are displayed */}
                  </div>

                  {/*<p>Organizer Name</p>*/}
                  {/*<div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>*/}
                  {/*    <button style={{ margin: '10px', minWidth: '120px' }}>Button 1</button>*/}
                  {/*    <button style={{ margin: '10px', minWidth: '120px' }}>Button 2</button>*/}
                  {/*</div>*/}
              </div>
            </div>
          </Grid>
      </Grid>
        {userProfileData &&
            ( ((userProfileData.IsVirtualEvent === "True") && (userProfileData?.SponsorData?.length > 0 || userProfileData?.FaqData?.length > 0 || userProfileData?.AgendaSessionData?.length > 0 || userProfileData?.VendorData?.length > 0)) || (userProfileData.IsVirtualEvent === "False") ) && (
                // <div>
                    <hr />
                // </div>
            )
        }
    </div>
  );
};

export default EventMap;
