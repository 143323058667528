import React, { useEffect, useRef, useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { BiCurrentLocation } from "react-icons/bi";
import axios from "axios";
import { API } from "Constants";
import * as constants from "Constants";
import UseOutsideClick from "../../../Components_UBMeEvents/OutSideClick/UseOutsideClick";
import { LatLongData, SyncLatLongDataV2 } from "Actions/eventActions";
import { connect } from "react-redux";
const GooglePlaces = ({
  dispatch,
  styles,
  selected,
  setSelected,
  SyncLatLongDataV2,
  LatitudeSyncv1,
  setLatlLong = () => {},
  latlng,
  LatLongData,
  // isFirstRender,
  address,
  GetEventData = () => {},
}) => {
  // const [userData, setUserData] = useState("");
  // const [address, setAddress] = useState("");
  const [isFirstRender, setIsFirstRender] = useState(false);
  const [placeHolder, setPlaceHolder] = useState(constants.Text.searchplaces);

  const inputRef = useRef(null);

  UseOutsideClick(
    () => {
      if (selected) {
        // setSelected(false);
        dispatch({ type: "CITY_ADDRESS", payload: placeHolder });

        // setAddress(placeHolder);
      }
    },
    ".Menu-list-modal",
    inputRef
  );

  const ExtractAddress = (place) => {
    const newArray = place.address_components.filter((x) => {
      const newArr = x.types.includes("locality")
        ? x.types.includes("locality")
        : x.types.includes("administrative_area_level_1") ||
          x.types.includes("country");
      return newArr;
    });

    return {
      city: newArray[0]?.long_name,
      country: newArray[newArray.length - 1]?.long_name,
    };
  };

  async function success(response) {
    const latlong = response.data.loc.split(",");


    var GEOCODING =
      API.Geolocation +
      Number(latlong[0]) +
      "%2C" +
      Number(latlong[1]) +
      "&key=" +
      API.GOOGLE_API_KEY +
      "&language=en";
    try {
      const { data } = await axios.get(GEOCODING);
      const { city } = ExtractAddress(data.results[0]);
      dispatch({ type: "CITY_ADDRESS", payload: city });
      // setAddress(city);
      setLatlLong({
        lat: Number(latlong[0]),
        lng: Number(latlong[1]),
      });
    } catch (error) {}
  }

  const getData = async (currLocation) => {


    let latlongdata = JSON.parse(sessionStorage.getItem("latlong"));
    if (currLocation) {
      const response = await axios.get(constants.API.Geolocation_V2);
      let initial = true;
      success(response, initial);
      sessionStorage.removeItem("latlong");
    } else if (latlongdata !== null) {
      let initial = true;

      success(latlongdata, initial);
    } else {
      const response = await axios.get(constants.API.Geolocation_V2);
      let initial = true;
      success(response, initial);
    }
  };

  useEffect(() => {
    if (isFirstRender) {
      setTimeout(() => {
        let currLocation = false;
        getData(currLocation);
      }, 20);
    }
    setIsFirstRender(true);
  }, []);

  //   useEffect(() => {
  //     console.log("latlng",latlng);
  // // console.log("LatitudeSyncv1.lat",LatitudeSyncv1.lat);
  // //     if (LatitudeSyncv1.lat === 0) {

  //       let latlongData = {
  //         data: {
  //           loc: `${latlng.lat},${latlng.lng}`,
  //         },
  //       };
  //       success(latlongData);
  // //     }
  //   }, [latlng]);

  const handleSelect = async (address) => {
    const results = await geocodeByAddress(address);

    const latlong = await getLatLng(results[0]);
    setLatlLong(latlong);
    let latlongData = {
      data: {
        loc: `${latlong.lat},${latlong.lng}`,
      },
    };
    sessionStorage.setItem("latlong", JSON.stringify(latlongData));

    inputRef.current.blur();
    setSelected(false);
    const { city } = ExtractAddress(results[0]);
    dispatch({ type: "CITY_ADDRESS", payload: city });

    // setAddress(city);
  };

  const onFocus = () => {
    if (address === "") {
      setPlaceHolder(placeHolder);
    } else {
      setSelected(true);
      dispatch({ type: "CITY_ADDRESS", payload: "" });

      // setAddress("");
      setPlaceHolder(address);
      inputRef.current.focus();
    }
  };

  return (
    <PlacesAutocomplete
      value={address}
      onChange={(e) => dispatch({ type: "CITY_ADDRESS", payload: e })}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div style={{ position: "relative", width: "100%" }}>
          <input
            {...getInputProps({
              ref: inputRef,
              onFocus: () => {
                onFocus();
              },
              placeholder: `${placeHolder}`,
              className: `${styles.location_search_input} Menu-list-modal`,
            })}
          />

          <div className={styles.autocomplete_dropdown_container}>
            {selected ? (
              <div
                onClick={(event) => {
                  event.stopPropagation();
                  let currLocation = true;
                  getData(currLocation);
                  setSelected(false);
                }}
                className={styles.suggestion_item_my_location}
              >
                <BiCurrentLocation
                  className={styles.suggestion_item_my_location_icon}
                />
                <span>{constants.Text.usemylocation}</span>
              </div>
            ) : null}

            {suggestions.map((suggestion, index) => {
              const className = suggestion.active
                ? `${styles.suggestion_item_active}`
                : `${styles.suggestion_item}`;

              const style = suggestion.active
                ? { backgroundColor: "#fafafa", cursor: "pointer" }
                : { backgroundColor: "#ffffff", cursor: "pointer" };
              return (
                <div
                  {...getSuggestionItemProps(suggestion, {
                    className,
                    style,
                  })}
                  key={suggestion.placeId}
                >
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

const mapStateToProps = (state) => {
  return {
    latlng: state.favoriteReducer.latlong,
    LatitudeSyncv1: state.favoriteReducer.LatitudeSyncv1,
    address: state.favoriteReducer.address,
  };
};
const matchDispatchToProps = (dispatch) => {
  return {
    LatLongData,
    SyncLatLongDataV2,
    dispatch,
  };
};
export default connect(mapStateToProps, matchDispatchToProps)(GooglePlaces);
