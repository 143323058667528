import React from 'react';
import {Avatar} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import {convertUnicode} from '../../../../Helper/methods';
import {Twemoji} from 'react-emoji-render';

const styles = {
    avatar: {
        margin: 10,
        height: 60,
        width: 60,
        fontSize: 40,
    }
};

class FeedChat extends React.Component{
    render() {
        const {classes, data, finalDate, textData} = this.props;
        let res = /^(http:\/\/\.|https:\/\/\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm.test(textData.text);
        return (
            <React.Fragment>
                <div style={{width: '80px'}}>
                    <Avatar
                        className={ classes.avatar }>
                        {
                            data.photo ?
                                <img
                                    src={require(`../../../../images/PlaceIcons/point_of_interest.png`).default}
                                    height={65} width={65} alt="img"
                                />
                                :
                                <img
                                    src={require(`./../../../../images/Alphabets/${data.FullName.match(/[a-z]/i)[0].toUpperCase()}.png`).default}
                                    height={65} width={65} alt="img"
                                />
                        }
                    </Avatar>
                    {
                        data.unread_count >= 1 &&
                        <div className="unread-display"/>
                    }
                </div>
                <div className="chat-history" style={{width: '78%'}}>
                    <div className="header-wrapper">
                        <p className="chat-Header">{data.Type === 'Feed' ? `${data.feed.First_Name}'s Moment` : `${data?.event?.Name}`}</p>
                        <p className="date text-right">{finalDate}</p>
                        <img className="chat-arrow"
                             src={require('../../../../images/edit_arrow.png').default}/>
                    </div>
                    <div
                        className="chat-body">{textData.text === '[Sticker message]'
                        ?
                        Number(data.from_user_id) === Number(this.props.userId) ? '[Sound Moji Sent]' : `${data.FullName}: [Sound Moji Received]`
                        : textData.text === '[Picture message]'
                            ?
                            Number(data.from_user_id) === Number(this.props.userId) ? '[Picture Sent]' : `${data.FullName}: [Picture Received]`
                            : textData.text === '[GIF message]'
                                ? Number(data.from_user_id) === Number(this.props.userId) ? '[GIF Sent]' : `${data.FullName}: [GIF Received]`
                                : <p>
                                    {data.FullName}:{!res ? <Twemoji
                                    text={convertUnicode(textData.text)}/> : JSON.parse(textData).text}</p>
                    }
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default (withStyles(styles)(FeedChat));