import React, { useEffect } from "react";
// import Navbar from "../Components_UBMeEvents/Navbar/Navbar";
import "./Contact.css";
import JotformEmbed from 'react-jotform-embed';
import Navbar from "Components/UBMeEvents/Components_UBMeEvents/Navbar/Navbar";
import Footer from "Components/UBMeEvents/Components_UBMeEvents/Footer/Footer";
import Contactus from "images/Contact-us.jpg"
function Contact() {

    return (
        <>
        <Navbar/>
       
        <div className="contact_form">
        <div className="">
        <img
          style={{ width: "100%", height: "100%" }}
          src={Contactus}
          alt=""
        />
      </div>
            {/* <Navbar /> */}
            <div className="contact_container">
                <div className="contact_container_left">
                    <div className="contact_about">
                        <h2 className="contact_about_h2">About Us</h2>
                        <p className="contact_about_p">
                            At UBMe, we have launched UBMeFood.com to continue our goal of
                            supporting local businesses and building engagement and
                            connectivity amongst communities. Through our easy website
                            navigation, you will be able to order from your favorite local
                            restaurant with ease as well as find new favorite spots! By
                            utilizing the UBMe app, you will still be able to check-in upon
                            your order-pickup arrival.{" "}
                        </p>
                    </div>
                    <div className="google-map-code">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6057.7260200561705!2d-75.371174!3d40.610846!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf455673c4c82d049!2sUBMe!5e0!3m2!1sen!2shk!4v1666281719236!5m2!1sen!2shk"
                            // width="500"
                            // height="450"
                            frameborder="0"
                            style={{ border: 0 }}
                            allowfullscreen=""
                            aria-hidden="false"
                            tabindex="0"
                        ></iframe>
                    </div>
                </div>
                <div className="contact_container_right">
                    <div className="contact_detail">
                        <h3 className="contact_detail_h3">CONTACT UBMEFOOD</h3>
                        <div className="contact_detail_info">
                            <h3>Call Us</h3>
                            <h2>(800) 359-0059</h2>
                        </div>
                        <div className="contact_detail_info">
                            <h3>Address</h3>
                            <h2>520 Evans St, Suite 7 Bethlehem, PA 18015</h2>
                        </div>
                    </div>
                    <div className="contact_form">
                        <h1>Get In Touch</h1>
                        <div className="form">
                            <JotformEmbed className="jot_form" src="https://form.jotform.com/201464531399054" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
        </>
    );
}

export default Contact;
