import React, { Component } from "react";
import { FormControl, Grid, withStyles } from "@material-ui/core";

const styles = {
  headerWrapper: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid rgb(245, 245, 245)",
    paddingBottom: "12px",
    height: "content-fit",
    flexDirection: "row",
    alignItems: "center",
    flex: 0.05,
  },
  headerTitle: {
    fontSize: "30px",
  },
};
export class EventHeader extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { classes, title } = this.props;
    return (
      <div className={classes.headerWrapper}>
        <div>
          <span className={classes.headerTitle}>{title}</span>
        </div>
        <div>{this.props.children}</div>
      </div>
    );
  }
}

export default withStyles(styles)(EventHeader);
