import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  selectedChat,
  getUnreadCount,
  increaseMessageIndex,
  clearData,
  setInitial,
  unreadCountToZero,
  readAllMessages,
} from "../../../Actions/messagesAction";
import Loader from "../../../Helper/loader/loader";
import { ws } from "../../../App";
import moment from "moment";
import Alert from "../../../Helper/alert/alert";
import "./messages.css";
import OneToOne from "./AppViewChats/oneToOne";
import QuestionChat from "./AppViewChats/questionChat";
import FeedChat from "./AppViewChats/feedChat";

class AppView extends React.Component {
  constructor() {
    super();
    this.state = {
      isSearching: false,
      searchArray: [],
      pressStart: 0,
      showDelete: false,
      dataToDelete: {},
      callSelectedChat: false,
      selectedChatData: [],
    };
  }

  componentDidMount() {
    let { messageData } = this.props;
    if (messageData.recentChat && messageData.selectedChat) {
      this.onChatClick(messageData.selectedChat);
    }
    localStorage.removeItem("curbsideCommunicationUserId");
    localStorage.removeItem("curbsideCommunicationUserName");
    localStorage.removeItem("curbsideCommunicationFullName");
    localStorage.removeItem("curbsideCommunicationProfileImage");
  }

  onScroll = (e) => {
    if (
      parseInt(e.target.offsetHeight + e.target.scrollTop + 10) >=
      e.target.scrollHeight
    ) {
      if (
        this.props.messageData.recentChat.length <=
        this.props.messageData.totalRecentChat
      ) {
        ws.json({
          user_id: this.props.userId,
          type: "recentchat",
          count: 20,
          index: this.props.recentChatIndex,
        });
        this.props.updateState(
          "recentChatIndex",
          this.props.recentChatIndex + 1
        );
      }
    }
  };

  onOneToOneClick = (data) => {
    const { userId, setInitial } = this.props;

    ws.json({
      count: 20,
      deleteCount: 0,
      dialog_id: data.dialog_id,
      index: 1,
      type: "chatHistory",
      user_id: userId,
    });
    setInitial("OneToOne");
  };

  onQuestionChatClick = (data) => {
    const { userId, setInitial } = this.props;
    ws.json({
      chat_type:
        data.Type === "PlaceQuestionChat"
          ? "PlaceQuestionChat"
          : data.Type === "Feed"
          ? "Feed"
          : "Event",
      count: 20,
      deleteCount: 0,
      googlePlacedId: data.dialog_id,
      index: 0,
      requesterId: userId,
      type: "businessrecentchat",
      user_id: data.userid,
    });
    setInitial("QuestionChat");
  };

  onChatClick = (data) => {
    const { messageData, messageIndex } = this.props;
    let textAreaElement = document.getElementById("textMessage");

    if (!messageData.scroll && textAreaElement) {
      var div = document.getElementById("floatChat");
      if (div !== null) {
        div.scrollTop = div.scrollHeight - div.clientHeight;
      }
      textAreaElement.focus();
    }

    // drawerStatus &&
    //   showAllMessages &&
    //   textAreaElement &&
    //   textAreaElement.focus();
    // drawerStatus &&
    //   showAllMessages &&
    //   textAreaElement &&
    //   (textAreaElement.scrollTop =
    //     textAreaElement.scrollHeight + textAreaElement.clientHeight);
    this.props.unreadCountToZero(data.dialog_id);
    if (
      (messageData.selectedChat && messageData.selectedChat.dialog_id) !==
        data.dialog_id ||
      (data.Type === "PlaceQuestionChat" &&
        messageData.selectedChat.userid !== data.userid)
    ) {
      this.props.clearData(data);
      ws.json({
        to_user_id: data.from_user_id,
        type: "userstatus",
      });
      if (data.Type === "OneToOne") {
        this.onOneToOneClick(data);
      } else {
        this.onQuestionChatClick(data);
      }

      if (
        (messageData.selectedChat.Type && data.Type) === "PlaceQuestionChat" &&
        messageData.selectedChat.QuestionId === data.QuestionId
      ) {
        this.props.increaseMessageIndex(messageIndex + 1);
      } else if (
        (messageData.selectedChat.Type && data.Type) === "PlaceQuestionChat" &&
        messageData.selectedChat.QuestionId !== data.QuestionId
      ) {
        this.props.increaseMessageIndex(1);
      } else if (messageData.selectedChat.dialog_id === data.dialog_id) {
        this.props.increaseMessageIndex(messageIndex + 1);
      } else {
        this.props.increaseMessageIndex(data.Type === "OneToOne" ? 2 : 1);
      }

      let chatElement = document.getElementById("chatWrapper");
      chatElement &&
        (chatElement.scrollTop =
          chatElement.scrollHeight - chatElement.clientHeight);
      this.props.updateState("deleteCount", 0);
      this.props.selectedChat(data);
    }
    this.props.getUnreadCount();
  };

  onSearch = (e) => {
    let { searchArray } = this.state;
    let isSearching;
    if (e.target.value.length > 0) {
      searchArray = [];
      this.props.messageData.recentChat.forEach((v) => {
        if (v.FullName.toLowerCase().includes(e.target.value.toLowerCase())) {
          searchArray.push(v);
        } else if (
          v.locatoinInfo &&
          v.locatoinInfo.toLowerCase().includes(e.target.value.toLowerCase())
        ) {
          searchArray.push(v);
        }
      });
      isSearching = true;
    } else {
      isSearching = false;
    }
    this.setState({
      searchArray,
      isSearching,
    });
  };

  MouseDown = (e) => {
    this.setState({
      pressStart: e.timeStamp,
    });
  };

  MouseUp = (e, data) => {
    if (this.state.pressStart + 1000 < e.timeStamp) {
      // this.setState({
      //     dataToDelete: {
      //         data: data,
      //         index: index
      //     },
      //     showDelete: true,
      // });
    } else {
      this.onChatClick(data);
    }
  };

  deleteChat = () => {
    if (this.state.dataToDelete.data.Type === "PlaceQuestionChat") {
      ws.json({
        dialog_id: this.state.dataToDelete.data.dialog_id,
        type: "deleterequest",
        user_id: this.props.userId,
        chat_type: "PlaceQuestionChat",
      });
    } else {
      ws.json({
        dialog_id: this.state.dataToDelete.data.dialog_id,
        type: "deleterequest",
        user_id: this.props.userId,
        chat_type: "OneToOne",
      });
    }
    this.setState({
      showDelete: false,
    });
  };

  cancelDelete = () => {
    this.setState({
      showDelete: false,
    });
  };

  render() {
    const { messageData } = this.props;
    const { showDelete, isSearching, searchArray } = this.state;
    let unReadMessages = messageData && messageData.unreadCount;
    return (
      <React.Fragment>
        {showDelete && (
          <Alert
            warning="true"
            showCancel="true"
            confirmText="Delete"
            confirmStyle="danger"
            cancelStyle="default"
            title={<span>"Like it never existed"</span>}
            Confirm={this.deleteChat}
            Cancel={this.cancelDelete}
          />
        )}
        <h1 className="app-header">Live View</h1>
        <p className="app-heading">{`Messages ${
          unReadMessages ? `(${unReadMessages})` : ""
        }`}</p>
        <div className="text-holder">
          <input
            type="text"
            id="searchText"
            placeholder="Search"
            onChange={this.onSearch}
          />
        </div>
        <div
          id="appBody"
          className={
            (messageData.totalRecentChat && messageData.totalRecentChat) !== 0
              ? "app-body"
              : "no-chats"
          }
          onScroll={this.onScroll}
        >
          {(messageData.totalRecentChat && messageData.totalRecentChat) ===
          0 ? (
            <div className="no-message-display">No Messages to display.</div>
          ) : isSearching ? (
            searchArray.map((data, index) => {
              const textData = JSON.parse(data.last_message_json);
              const date = moment
                .utc(data.utc_message_date)
                .local()
                .format("YYYY-MM-DD HH:mm:ss");
              let finalDate = moment(date).fromNow();
              finalDate =
                finalDate.includes("hour ago") ||
                finalDate.includes("hours ago")
                  ? Number(finalDate.slice(0, 2)) > 12
                    ? "Yesterday"
                    : moment(date).format("LT")
                  : finalDate.includes("a day ago")
                  ? "Yesterday"
                  : finalDate.includes("minute ago") ||
                    finalDate.includes("minutes ago")
                  ? `${
                      finalDate.slice(0, 1) === "a" ? 1 : finalDate.slice(0, 2)
                    } m`
                  : finalDate.includes("second ago") ||
                    finalDate.includes("seconds ago")
                  ? "Just now"
                  : moment().startOf("day").diff(date, "days") <= 7
                  ? moment(date).format("ddd")
                  : moment(date).format("l");
              let initialChat = messageData.selectedChat.userid;

              return (
                <div
                  // style={{marginLeft:300}}
                  key={index}
                  className="chat"
                  onMouseDown={this.MouseDown}
                  onMouseUp={(e) => {
                    this.MouseUp(e, data, index);
                  }}
                >
                  {data.Type === "OneToOne" ? (
                    <OneToOne
                      data={data}
                      finalDate={finalDate}
                      textData={textData}
                      userId={this.props.userId}
                      initialChat={initialChat}
                      onOneToOneClick={(data) => this.onOneToOneClick(data)}
                      readAllMessages={(data) =>
                        this.props.readAllMessages(data)
                      }
                    />
                  ) : data.Type === "PlaceQuestionChat" ? (
                    <QuestionChat
                      data={data}
                      finalDate={finalDate}
                      textData={textData}
                      userId={this.props.userId}
                      onOneToOneClick={(data) => this.onOneToOneClick(data)}
                      readAllMessages={(data) =>
                        this.props.readAllMessages(data)
                      }
                    />
                  ) : data.Type === "Feed" ? (
                    <FeedChat
                      data={data}
                      finalDate={finalDate}
                      textData={textData}
                      userId={this.props.userId}
                      onOneToOneClick={(data) => this.onOneToOneClick(data)}
                      readAllMessages={(data) =>
                        this.props.readAllMessages(data)
                      }
                    />
                  ) : (
                    ""
                  )}
                </div>
              );
            })
          ) : messageData.recentChat && messageData.recentChat.length !== 0 ? (
            // this.props.messageData.recentChat[0].FullName &&
            this.props.messageData.recentChat.map((data, index) => {
              if (Object.keys(data.last_message_json).length !== 0) {
                const textData = JSON.parse(data.last_message_json);
                const date = moment
                  .utc(data.utc_message_date)
                  .local()
                  .format("YYYY-MM-DD HH:mm:ss");
                let finalDate = moment(date).fromNow();
                finalDate =
                  finalDate.includes("hour ago") ||
                  finalDate.includes("hours ago")
                    ? Number(finalDate.slice(0, 2)) > 12
                      ? "Yesterday"
                      : moment(date).format("LT")
                    : finalDate.includes("a day ago")
                    ? "Yesterday"
                    : finalDate.includes("minute ago") ||
                      finalDate.includes("minutes ago")
                    ? `${
                        finalDate.slice(0, 1) === "a"
                          ? 1
                          : finalDate.slice(0, 2)
                      } m`
                    : finalDate.includes("second ago") ||
                      finalDate.includes("seconds ago")
                    ? "Just now"
                    : moment().startOf("day").diff(date, "days") <= 7
                    ? moment(date).format("ddd")
                    : moment(date).format("l");
                let initialChat = messageData.selectedChat.userid;
                return (
                  <div
                    key={index}
                    className="chat"
                    style={{
                      backgroundColor:
                        data.userid === messageData.selectedChat.userid
                          ? "rgb(241 241 241)"
                          : "white",
                    }}
                    onMouseDown={this.MouseDown}
                    onMouseUp={(e) => {
                      this.MouseUp(e, data, index);
                    }}
                  >
                    {data.Type === "OneToOne" ? (
                      <OneToOne
                        data={data}
                        finalDate={finalDate}
                        textData={textData}
                        userId={this.props.userId}
                        initialChat={initialChat}
                        onOneToOneClick={(data) => this.onOneToOneClick(data)}
                        readAllMessages={(data) =>
                          this.props.readAllMessages(data)
                        }
                      />
                    ) : data.Type === "PlaceQuestionChat" ? (
                      <QuestionChat
                        data={data}
                        finalDate={finalDate}
                        textData={textData}
                        userId={this.props.userId}
                        onOneToOneClick={(data) => this.onOneToOneClick(data)}
                        readAllMessages={(data) =>
                          this.props.readAllMessages(data)
                        }
                      />
                    ) : (
                      <FeedChat
                        data={data}
                        finalDate={finalDate}
                        textData={textData}
                        userId={this.props.userId}
                      />
                    )}
                  </div>
                );
              }
              return <React.Fragment />;
            })
          ) : (
            <React.Fragment>
              <div style={{ textAlign: "center" }}>
                <Loader />
              </div>
            </React.Fragment>
          )}
        </div>
        <div id="appEnd" />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    messageData: state.messageReducer,
    messageIndex: state.messageReducer.messageIndex,
    showAllMessages: state.messageReducer.showAllMessages,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      selectedChat,
      getUnreadCount,
      setInitial,
      increaseMessageIndex,
      clearData,
      unreadCountToZero,
      readAllMessages,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AppView);
