import React from "react";
import { Tooltip, withStyles } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";

const CreateEventDetailsAboutToolTip = withStyles(() => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    width: "auto",
    fontSize: "16px",
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const TitleWithToolTip = ({ tooltipText }) => {
  return (
    <span style={{ position: "relative", bottom: "-3px", marginLeft: "5px" }}>
      <CreateEventDetailsAboutToolTip
        title={<React.Fragment>{tooltipText}</React.Fragment>}
      >
        <InfoIcon fontSize="medium" style={{ color: "#851679" }} />
      </CreateEventDetailsAboutToolTip>
    </span>
  );
};

export default TitleWithToolTip;
