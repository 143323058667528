import React, { useEffect } from "react";
import { Avatar } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { convertUnicode } from "../../../../Helper/methods";
import { Twemoji } from "react-emoji-render";
import { Badge } from "@material-ui/core";

const styles = {
  avatar: {
    margin: 10,
    height: 60,
    width: 60,
    fontSize: 40,
    objectFit: "cover",
  },
  customBadge: {
    backgroundColor: "#a435a6",
    color: "white",
    fontSize: "12px",
    marginTop: "-5px",
    marginRight: "12px",
    alignContent: "center",
  },
};
// color: white;
// font-size: 12px;
// margin-top: -5px;
// margin-right: 44px;
// align-content: center;
// background-color: #a435a6;

const OneToOne = (props) => {
  const {
    classes,
    data,
    finalDate,
    textData,
    userId,
    initialChat = false,
    readAllMessages,
    onOneToOneClick
  } = props;
  
  useEffect(() => {
    if (data.userid === initialChat && data.unread_count > 0) {
      readAllMessages(data);
      // onOneToOneClick(data)
    }
  }, [data]);

  let res =
    /^(http:\/\/\.|https:\/\/\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm.test(
      textData.text
    );
  return (
    <React.Fragment>
      <div style={{ width: "80px" }}>
        <Avatar className={classes.avatar}>
          {data.photo ? (
            <img src={data.photo} className="oneToOne-avatar-img" />
          ) : (
            <img
              src={require(`./../../../../images/Alphabets/${data.FullName.match(
                /[a-z]/i
              )[0].toUpperCase()}.png`).default}
              className="oneToOne-avatar-img"
            />
          )}
        </Avatar>
      </div>
      <div className="chat-history" style={{ width: "78%", flexDirection:'column', display:'flex', gap:"16px" }}>
        <div className="header-wrapper">
          <p className="chat-Header">{data.FullName}</p>
          <p className="date text-right">
            {finalDate === "a few seconds ago" ? "Just Now" : finalDate}
          </p>
          <img
            className="chat-arrow"
            src={require("../../../../images/edit_arrow.png").default}
          />
        </div>
        <div>
          <div className="chat-body">
            {textData.text === "[Sticker message]" ? (
              Number(data.from_user_id) === Number(userId) ? (
                "[Sound Moji Sent]"
              ) : (
                "[Sound Moji Received]"
              )
            ) : textData.text === "[Picture message]" ? (
              Number(data.from_user_id) === Number(userId) ? (
                "[Picture Sent]"
              ) : (
                "[Picture Received]"
              )
            ) : textData.text === "[GIF message]" ? (
              Number(data.from_user_id) === Number(userId) ? (
                "[GIF Sent]"
              ) : (
                "[GIF Received]"
              )
            ) : (
              <p>
                {!res ? (
                  <Twemoji text={convertUnicode(textData.text)} />
                ) : (
                  typeof textData === "string" ?  JSON.parse(textData).text : textData.text
                )}
              </p>
            )}
          </div>
          <div
            style={{
              float: "right",
              display: "flex",
            }}
          >
            {data.unread_count >= 1 && (
              <Badge
                badgeContent={data.unread_count}
                // badgeContent={data.userid === initialChat ? 0 : data.unread_count}

                classes={{ badge: classes.customBadge }}
              />
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withStyles(styles)(OneToOne);
