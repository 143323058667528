import React from "react";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = (theme) => ({
  progress: {
    margin: theme.spacing.unit * 2,
  },
  loaderWrapper: {
    textAlign: "center",

    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "10px",
    padding: "10px",
    zIndex: 500,
  },
});

const Loader = (props) => {
  const { classes } = props;
  return (
    <div className={classes.loaderWrapper}>
      <CircularProgress
        className={classes.progress}
        color={"primary"}
        size={65}
      />
    </div>
  );
};

export default withStyles(styles)(Loader);