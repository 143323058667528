import moment from "moment";
import "moment-timezone";
import React, { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import OrderSummary from "../ticketOrderSummary/OrderSummary";
import "../ticketOrderSummary/ticketOrderSummary.css";
import Select from "react-select";
import "./AddMoreTickets.css";
import { CircularProgress } from "@material-ui/core";
import * as constants from "Constants";
import { IoClose } from "react-icons/io5";
import {
  ticktesCartItem,
  CreateShoppingCartSession,
  PersistShoppingCartSession,
} from "Actions/eventActions";
import { useLayoutEffect } from "react";
import TimeOutScreen from "../SuccessFull/TimeOutScreen";
import EventSlotsScheduler from "../EventSlotsScheduler/EventSlotsScheduler";
import BundleScheduler from "../BundleScheduler/BundleScheduler";

const AddMoreTickets = ({
                            setOpenCheckOut,
                            cartItems,
                            discountData,
                            CreateShoppingCartSession,
                            currentEventDetails,
                            ticktesCartItem,
                            dispatch,
                            setContinueToCheckOut,
                            minutes,
                            setMinutes,
                            seconds,
                            shoppingCartDetails,
                            paymentIntent,
                            Token,
                            setRecurringData,
                            recurringData,
                            selectedOption,
                            setSelectedOption,
                            hasCheckoutQuery
                        }) => {
    const [showOrderSummary, setShowOrderSummary] = useState(false);
    const [loading, setLoading] = useState(false);
    const [bundleSelectedQuantity, setBundleSelectedQuantity] = useState({});
    const [currentTicket, setCurrentTicket] = useState("");
    const [remainingUtilization, setRemainingUtilization] = useState(0);
    const [ticketData, setTicketData] = useState([]);

    useEffect(() => {
        setRemainingUtilization(Number(discountData?.RemainingUtilization));
    }, [discountData]);

    const handleChange = (Quantity, ticket, recurringData) => {
        //  coming from api response

        const exist = cartItems?.find((x) => x.TicketId === ticket.TicketId);
        if (exist && Quantity.value > 0) {
            if (ticket.RecurringTicket === "True") {
                if (ticket.isBundle) {
                    setBundleSelectedQuantity((prevQuantities) => ({
                        ...prevQuantities,
                        [ticket.TicketId]: Quantity.value,
                    }));

                    if (bundleSelectedQuantity[ticket.TicketId] !== Quantity.value) {
                        dispatch(
                            ticktesCartItem(
                                cartItems.filter((x) => x.TicketId !== ticket.TicketId)
                            )
                        );
                    } else {
                        dispatch(
                            ticktesCartItem(
                                cartItems?.map((x) =>
                                    x.TicketId === ticket.TicketId
                                        ? {...exist, Quantity: Quantity.value, ...recurringData}
                                        : x
                                )
                            )
                        );
                    }
                } else {
                    dispatch(
                        ticktesCartItem(
                            cartItems?.map((x) =>
                                x.TicketId === ticket.TicketId
                                    ? {...exist, Quantity: Quantity.value, ...recurringData}
                                    : x
                            )
                        )
                    );
                }
            } else {
                dispatch(
                    ticktesCartItem(
                        cartItems?.map((x) =>
                            x.TicketId === ticket.TicketId
                                ? { ...exist, Quantity: Quantity.value }
                                : x
                        )
                    )
                );
            }
        } else {
            if (Quantity.value > 0) {
                if (ticket.RecurringTicket === "True") {
                    if (ticket.isBundle) {
                        setBundleSelectedQuantity((prevQuantities) => ({
                            ...prevQuantities,
                            [ticket.TicketId]: Quantity.value,
                        }));
                    }

                    if (ticket.isBundle && (recurringData !== undefined && recurringData !== null && recurringData.selectedSlots !== undefined && recurringData.selectedSlots !== null)) {
                        dispatch(
                            ticktesCartItem([
                                ...cartItems,
                                {...ticket, Quantity: Quantity.value, ...recurringData},
                            ])
                        );
                    } else if (!ticket.isBundle) {
                        dispatch(
                            ticktesCartItem([
                                ...cartItems,
                                {...ticket, Quantity: Quantity.value, ...recurringData},
                            ])
                        );
                    }
                } else {
                    dispatch(
                        ticktesCartItem([
                            ...cartItems,
                            { ...ticket, Quantity: Quantity.value },
                        ])
                    );
                }
            } else {
                if (ticket.isBundle) {
                    setBundleSelectedQuantity((prevQuantities) => ({
                        ...prevQuantities,
                        [ticket.TicketId]: Quantity.value,
                    }));
                }
                dispatch(
                    ticktesCartItem(
                        cartItems.filter((x) => x.TicketId !== ticket.TicketId)
                    )
                );
            }
        }
    };

    useEffect(() => {
        if (currentEventDetails !== null && shoppingCartDetails === null) {
            localStorage.setItem("additionalInfo", null);
            setLoading(true);
            dispatch(CreateShoppingCartSession(currentEventDetails?.EventId))
                .then((data) => {
                    const convertTimeUtcToLocal = (time) => {
                        const UtcToLocal = moment
                            .utc(time)
                            .local()
                            .format("MM/DD/YYYY hh:mm:ss A");
                        var Time = moment(UtcToLocal, "MM/DD/YYYY hh:mm:ss A");

                        const convertedTime = Time.tz(currentEventDetails?.TimeZone);
                        return moment(convertedTime).format("MM/DD/YYYY hh:mm:ss A");
                    };

                    let newData = { ...data };
                    const ticketDataUtctoLocal = newData?.CartItems?.map((ticketdata) => {
                        return {
                            ...ticketdata,
                            ActiveDate: convertTimeUtcToLocal(ticketdata.ActiveDate),
                            InActiveDate: convertTimeUtcToLocal(ticketdata.InActiveDate),
                        };
                    });

                    setTicketData(ticketDataUtctoLocal);

                    dispatch({
                        type: "CREATE_SHOPPING_DETAILS",
                        payload: { ...data, CartItems: ticketDataUtctoLocal },
                    });
                    setLoading(false);
                })
                .catch(() => {});
        }
    }, [currentEventDetails, shoppingCartDetails]);

    function useWindowSize() {
        const [size, setSize] = useState([0, 0]);
        useLayoutEffect(() => {
            function updateSize() {
                setSize([window.innerWidth, window.innerHeight]);
            }
            window.addEventListener("resize", updateSize);
            updateSize();
            return () => {
                window.removeEventListener("resize", updateSize);
            };
        }, []);
        return size;
    }
    const [width, height] = useWindowSize();

    const istToUtc = (datetime) => {
        const timeZone = currentEventDetails?.TimeZone;
        const istDateTime = moment.tz(datetime, "MM/DD/YYYY hh:mm:ss A", timeZone);
        const utcDateTime = istDateTime.clone().tz("UTC");
        return utcDateTime;
    };

    const checkDateWithinRange = (startDateTime, endDateSTime) => {
        const nowUtc = moment.utc();
        const startUtc = istToUtc(startDateTime);
        const endUtc = istToUtc(endDateSTime);
        return nowUtc.isBetween(startUtc, endUtc);
    };

    return (
        <div style={{ height: "100%" }}>
            {loading ? (
                <div
                    style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <CircularProgress size={50} color="secondary" />
                </div>
            ) : minutes === 0 && seconds === 0 ? (
                <TimeOutScreen
                    setMinutes={setMinutes}
                    setOpenCheckOut={setOpenCheckOut}
                    setContinueToCheckOut={setContinueToCheckOut}
                    hasCheckoutQuery={hasCheckoutQuery}
                />
            ) : (
                <div className="add-more-container">
                    <div class="container_1">
                        <div
                            style={{
                                position: "relative",
                            }}
                            id="header"
                        >
                            <h4 id="header_h3">{currentEventDetails?.Title}</h4>
                            <div
                                style={{
                                    padding:
                                        paymentIntent?.paymentId !== null
                                            ? "5px 0px"
                                            : "8px 0px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                {paymentIntent?.paymentId !== null && (
                                    <small style={{ color: "rgb(255, 38, 38)" }}>
                                        Time left {minutes < 10 ? `0${minutes}` : minutes}:
                                        {seconds < 10 ? `0${seconds}` : seconds}
                                    </small>
                                )}
                            </div>

                            <h4 id="header_h4">
                                {moment(currentEventDetails?.StartDateTime).format("llll")} -{" "}
                                {moment(currentEventDetails?.EndDateTime).format("llll")}
                            </h4>
                            {width <= 800 && !Token && !hasCheckoutQuery ? (
                                <div
                                    onClick={() => setOpenCheckOut(false)}
                                    className="order_close"
                                >
                                    <IoClose size={20} />
                                </div>
                            ) : null}
                        </div>
                        <div className="underline" />
                        <div class="col_9">
                            <div>
                                {shoppingCartDetails?.CartItems?.length > 0 &&
                                    shoppingCartDetails?.CartItems?.map((item, idx) => (
                                        <div key={idx}>
                                            <RenderTicktsData
                                                discountData={discountData}
                                                cartItems={cartItems}
                                                item={item}
                                                session={shoppingCartDetails?.CartSession}
                                                TimeZone={currentEventDetails?.TimeZone}
                                                handleChange={handleChange}
                                                selectedOption={selectedOption}
                                                checkDateWithinRange={checkDateWithinRange}
                                                currentTicket={currentTicket}
                                                bundleSelectedQuantity={bundleSelectedQuantity}
                                                dispatch={dispatch}
                                                recurringData={recurringData}
                                                setSelectedOption={setSelectedOption}
                                                setBundleSelectedQuantity = {setBundleSelectedQuantity}
                                                index={idx}
                                            />

                                            {item?.RecurringTicket === "True" &&
                                                checkDateWithinRange(
                                                    item.ActiveDate,
                                                    item.InActiveDate
                                                ) && (
                                                    item.isBundle ? (
                                                        (() => {
                                                                // const isRecData = cartItems.find((x) => x.TicketId === item.TicketId);
                                                            if (bundleSelectedQuantity[item.TicketId] > 0) {
                                                                return (
                                                                    <BundleScheduler
                                                                        item={item}
                                                                        key={item?.TicketId}
                                                                        cartItems={cartItems}
                                                                        handleChange={handleChange}
                                                                        selectedOption={selectedOption}
                                                                        setSelectedOption={setSelectedOption}
                                                                        setRecurringData={setRecurringData}
                                                                        currentTicket={currentTicket}
                                                                        setCurrentTicket={setCurrentTicket}
                                                                        dispatch={dispatch}
                                                                        recurringData={recurringData}
                                                                        bundleSelectedQuantity={bundleSelectedQuantity}
                                                                    />
                                                                );
                                                            }
                                            })()
                                                    ) : (
                                                        <EventSlotsScheduler
                                                            item={item}
                                                            key={item?.TicketId}
                                                            cartItems={cartItems}
                                                            handleChange={handleChange}
                                                            selectedOption={selectedOption}
                                                            setSelectedOption={setSelectedOption}
                                                            setRecurringData={setRecurringData}
                                                            currentTicket={currentTicket}
                                                            setCurrentTicket={setCurrentTicket}
                                                            dispatch={dispatch}
                                                            recurringData={recurringData}
                                                        />
                                                    )
                                                )}
                                        </div>
                                    ))}
                            </div>
                        </div>

                        <div className="language-button">
                            <div id="footer">
                                {width <= 800 && cartItems.length > 0 ? (
                                    <a
                                        className="open_summury_btn"
                                        onClick={() => setShowOrderSummary(!showOrderSummary)}
                                        href="#"
                                    >
                                        {showOrderSummary ? "Hide Summary " : "View Summary"}
                                    </a>
                                ) : null}

                                <button
                                    id="submit_btn"
                                    style={{
                                        backgroundColor: (cartItems.length > 0 &&
                                            (cartItems.every(cartitem => !cartitem.isBundle) ?
                                                    "#c31491" :
                                                    cartItems.every(cartitem => cartitem.isBundle && (parseInt(cartitem.SlotPerBundle) === (cartitem.selectedSlots !== undefined && cartitem.selectedSlots !== null && cartitem.selectedSlots.length))) ? "#c31491" :
                                                        (cartItems.some(cartitem => !cartitem.isBundle) &&
                                                            cartItems.some(cartitem => cartitem.isBundle && (parseInt(cartitem.SlotPerBundle) === (cartitem.selectedSlots !== undefined && cartitem.selectedSlots !== null && cartitem.selectedSlots.length)))
                                                        ) ? "#c31491" : "#ff91e0"
                                            )
                                        ) || "#ff91e0"
                                    }}
                                    onClick={() =>
                                        (cartItems.length > 0 &&
                                            (cartItems.every(cartitem => !cartitem.isBundle) ?
                                                    setContinueToCheckOut(true) :
                                                    cartItems.every(cartitem => cartitem.isBundle && (parseInt(cartitem.SlotPerBundle) === (cartitem.selectedSlots !== undefined && cartitem.selectedSlots !== null && cartitem.selectedSlots.length))) ?
                                                        setContinueToCheckOut(true) :
                                                        (cartItems.some(cartitem => !cartitem.isBundle) &&  cartItems.some(cartitem => cartitem.isBundle && (parseInt(cartitem.SlotPerBundle) === (cartitem.selectedSlots !== undefined && cartitem.selectedSlots !== null  && cartitem.selectedSlots.length)))) ?
                                                            setContinueToCheckOut(true) :
                                                            null
                                            )
                                        ) || null
                                    }
                                >
                                    {constants.Text.continue}
                                </button>

                            </div>
                        </div>
                    </div>

                    <OrderSummary
                        Token={Token}
                        selectedOption={selectedOption}
                        setShowOrderSummary={setShowOrderSummary}
                        showOrderSummary={showOrderSummary}
                        isPromo={true}
                        setOpenCheckOut={setOpenCheckOut}
                        hasCheckoutQuery={hasCheckoutQuery}
                    />
                </div>
            )}
        </div>
    );
};

const RenderTicktsData = ({
                            item,
                            session,
                            handleChange,
                            TimeZone,
                            cartItems,
                            selectedOption,
                            setSelectedOption,
                            checkDateWithinRange,
                            setBundleSelectedQuantity,
                            bundleSelectedQuantity,
                            recurringData,
                            index,
                          }) => {
  const [isSaleOn, setIsSaleOn] = useState(false);
  const [saleEnded, setSaleEnded] = useState(false);
  const [isShowInput, setIsShowInput] = useState(false);
  const [availableQnty, setAvailableQnty] = useState(0);

  useEffect(() => {
    const isRecData = cartItems.filter((x) => x.TicketId === item?.TicketId);
    if (
        isRecData?.length > 0 &&
        isRecData[0]?.selectedDate === selectedOption?.selectedDate &&
        isRecData[0]?.selectedSlot !== null
    ) {
      setAvailableQnty(isRecData[0]?.selectedSlot?.AvailableTickets);
      setIsShowInput(true);
        if (isRecData[0]?.isBundle && isRecData[0]?.Quantity !== null) {
            setBundleSelectedQuantity((prevQuantities) => ({
                ...prevQuantities,
                [isRecData[0].TicketId]: isRecData[0]?.Quantity,
            }));
        }
    } else if (
        isRecData?.length > 0 &&
        isRecData[0]?.selectedDate === selectedOption?.selectedDate &&
        isRecData[0]?.selectedSlot === null
    ) {
      setIsShowInput(false);
    } else if (
        isRecData?.length > 0 &&
        isRecData[0]?.selectedDate !== selectedOption?.selectedDate &&
        isRecData[0]?.selectedSlot !== null
    ) {
      setAvailableQnty(isRecData[0]?.selectedSlot?.AvailableTickets);
      setIsShowInput(true);
        if (isRecData[0]?.isBundle && isRecData[0]?.Quantity !== null) {
            setBundleSelectedQuantity((prevQuantities) => ({
                ...prevQuantities,
                [isRecData[0].TicketId]: isRecData[0]?.Quantity,
            }));
        }
    } else if (
        selectedOption?.TicketId === item?.TicketId &&
        selectedOption?.selectedSlot === null
    ) {
      setIsShowInput(false);
    } else {
      // console.log({ selectedOption, cartItems, isRecData, recurringData });
    }
  }, [selectedOption?.selectedDate]);

  useEffect(() => {
    const isRecData = cartItems.filter((x) => x.TicketId === item?.TicketId);
    if (
        isRecData?.length > 0 &&
        isRecData[0]?.selectedDate === selectedOption?.selectedDate &&
        isRecData[0]?.selectedSlot !== null
    ) {
      setAvailableQnty(isRecData[0]?.selectedSlot?.AvailableTickets);
      setIsShowInput(true);
      // handleRecurringTicketDefaultValue();
    } else if (
        isRecData?.length > 0 &&
        isRecData[0]?.selectedDate === selectedOption?.selectedDate &&
        isRecData[0]?.selectedSlot == null
    ) {
      setIsShowInput(false);
    } else {
      if (
          selectedOption?.item?.TicketId === item?.TicketId &&
          selectedOption?.selectedSlot !== null
      ) {
        setAvailableQnty(selectedOption?.selectedSlot?.AvailableTickets);
        setIsShowInput(true);
        // handleRecurringTicketDefaultValue();
      } else if (
          selectedOption?.TicketId === item?.TicketId &&
          selectedOption?.selectedSlot == null
      ) {
        setIsShowInput(false);
      } else if (selectedOption?.TicketId === item?.TicketId) {
        setIsShowInput(false);
      } else if (
          isRecData?.length <= 0 &&
          selectedOption?.selectedSlot === null
      ) {
        if (selectedOption?.TicketId !== item?.TicketId) {
          setIsShowInput(false);
        } else {
          setIsShowInput(true);
          // handleRecurringTicketDefaultValue();
        }
      } else {
        // console.log({ selectedOption, cartItems, isRecData });
      }
    }
  }, [selectedOption?.selectedSlot]);

  useEffect(() => {
    if (
        selectedOption?.isStateNull &&
        selectedOption?.TicketId === item?.TicketId
    ) {
      setIsShowInput(false);
    }
  }, [selectedOption]);

  useEffect(() => {
    if (TimeZone &&
        moment(item?.ActiveDate, "MM/DD/YYYY hh:mm:ss A").isAfter(
            moment(
                moment().tz(TimeZone).format("MM/DD/YYYY hh:mm:ss A"),
                "MM/DD/YYYY hh:mm:ss A"
            )
        )
    ) {
      setIsSaleOn(true);
    }
    if (TimeZone &&
        moment(item?.InActiveDate, "MM/DD/YYYY hh:mm:ss A").isBefore(
            moment(
                moment().tz(TimeZone).format("MM/DD/YYYY hh:mm:ss A"),
                "MM/DD/YYYY hh:mm:ss A"
            )
        )
    ) {
      setSaleEnded(true);
    }
  }, []);

  const handleNumberOnlyInput = (e) => {
    if (e.nativeEvent.code === "KeyE" || e.nativeEvent.code === "Minus") {
      e.preventDefault();
    }
  };

  const defaultValue = cartItems?.find((p) => p.TicketId === item?.TicketId);

  const customStyles = {
    // control represent the select component
    control: (provided) => ({
      ...provided,
      width: "100px",
    }),
  };

  const handleSetSelectedOption = (val) => {
    setSelectedOption((prevState) => {
      return {
        ...prevState,
        qnty: val,
        selectedSlot: null,
        TicketId: item?.TicketId,
      };
    });
  };

  const handleRecurringTicketDefaultValue = () => {
      if (item.RecurringTicket === "True" && !item.isBundle) {
        const exist = cartItems?.find((x) => x.TicketId === item.TicketId);
        if (exist) {
            handleChange({value:exist.Quantity, label: exist.Quantity}, item, recurringData);
        } else {
            handleChange({value:1, label: 1}, item, recurringData);
        }
      }
  }

  return (
      <>
        <div key={item.EventId} class="body_row" style = {{paddingTop: index !== 0 ? "10%" : "0"}} >
          <div class="col_10">
            <h4 style={{ fontSize: 22, marginBottom: 10, fontWeight: 600 }}>
              {item.Title}
            </h4>
            {item.Description !== "" && (
                <h4 style={{ fontSize: 15, marginBottom: 10, color: "darkgray", wordWrap: "break-word" }}>
                    {item.Description}
                </h4>
            )}
            <h5 style={{ fontSize: 20, marginBottom: 5, fontWeight: 600 }}>
              {item.TicketType === "0"
                  ? "Free"
                  : `${session.CurrencySymbol}${Intl.NumberFormat(undefined, {
                    minimumFractionDigits: 2,
                  }).format(item?.Price)}`}
            </h5>

            <div>
              {!isSaleOn && (
                  <p style={{ color: "#878f99", fontWeight: 600 }}>
                    {" "}
                    {constants.Text.salesEndOn}{" "}
                    {moment(item?.InActiveDate).format("MMM DD, yyyy")}
                  </p>
              )}
            </div>

          {
              item.isBundle ?
                  (bundleSelectedQuantity[item.TicketId] > 0) ? (
              cartItems?.find((x) => x.TicketId === item.TicketId && (x.selectedSlots !== undefined && x.selectedSlots !== null  && x.selectedSlots.length) === parseInt(item.SlotPerBundle)) ? (
              <div>
                  {!isSaleOn && (
                      <p style={{ color: "#47c94d", fontWeight: 600 }}>
                          {" "}
                          {constants.Text.bundleTicketSelectionSuccessMessage1}{item.SlotPerBundle}{constants.Text.bundleTicketSelectionSuccessMessage2}
                      </p>
                  )}
              </div> ) :( <div>
                      {!isSaleOn && (
                          <p style={{ color: "#FF0000", fontWeight: 600 }}>
                              {" "}
                              {constants.Text.bundleTicketMessage1}{(cartItems?.find((x) => x.TicketId === item.TicketId && (x.selectedSlots !== undefined && x.selectedSlots !== null && x.selectedSlots.length)) ? cartItems.find((x) => x.TicketId === item.TicketId).selectedSlots.length : 0)}{"/"}{item.SlotPerBundle}{constants.Text.bundleTicketMessage2}
                          </p>
                      )}
                  </div>
              )
          ) : (
              <div>
                  {!isSaleOn && (
                      <p style={{ fontWeight: 600 }}>
                        {" Please select the number of people attending."}
                      </p>
                  )}
              </div>
            )
              : ""
          }
          </div>
          <div class="col_2">
            <div
                className="ticket_menu_options"
            >
              {saleEnded && Number(item?.AvailableTicket) !== 0 && (
                  <p
                      style={{
                        fontWeight: 600,
                        color: "rgb(135, 143, 153)",
                        bottom: -6,
                        left: 36,
                        fontSize: 14,
                        padding: "20px 0px",
                        textAlign: "right",
                      }}
                  >
                    {constants.Text.ticketSaleEnd}
                  </p>
              )}

              {item?.RecurringTicket !== "True" &&
                  Number(item?.AvailableTicket) === 0 &&
                  Number(item?.ReservedTicket) === 0 && (
                      <p
                          style={{
                            fontWeight: 600,
                            color: "rgb(135, 143, 153)",
                            bottom: -6,
                            left: 36,
                            fontSize: 14,
                            padding: "20px 0px",
                            textAlign: "right",
                          }}
                      >
                        {constants.Text.soldOut}
                      </p>
                  )}

              {item?.RecurringTicket == "True" &&
              selectedOption?.availableSlots?.length > 0
                  ? selectedOption?.availableSlots?.every(
                  (obj) =>
                      obj.TicketId === item?.TicketId && obj.AvailableTickets == 0
              ) && (
                  <p
                      style={{
                        fontWeight: 600,
                        color: "rgb(135, 143, 153)",
                        bottom: -6,
                        left: 36,
                        fontSize: 14,
                        padding: "20px 0px",
                        textAlign: "right",
                      }}
                  >
                    {constants.Text.soldOut}
                  </p>
              )
                  : ""}

              {item?.RecurringTicket !== "True" &&
                  Number(item?.AvailableTicket) === 0 &&
                  Number(item?.ReservedTicket) !== 0 && (
                      <p
                          style={{
                            fontWeight: 600,
                            color: "rgb(135, 143, 153)",
                            bottom: -6,
                            left: 36,
                            fontSize: 14,
                            padding: "20px 0px",
                            textAlign: "right",
                          }}
                      >
                        {constants.Text.Reserved}
                      </p>
                  )}

              {isSaleOn && (
                  <p
                      style={{
                        fontWeight: 600,
                        color: "rgb(135, 143, 153)",
                        bottom: -6,
                        left: 36,
                        fontSize: 14,
                        textAlign: "right",
                        padding: "20px 0px",
                      }}
                  >
                    {`On Sale ${moment(item?.ActiveDate).format("MMM DD")} at
                   ${moment(item?.ActiveDate).format("hh:mm A")}`}
                  </p>
              )}

              {((item?.RecurringTicket !== "True" &&
                  Number(item?.AvailableTicket) !== 0) || item?.isBundle) ? (
                  <React.Fragment>
                    {!isSaleOn && !saleEnded && (
                        item?.isBundle ? (
                            <Select
                                styles={customStyles}
                                components={{
                                    IndicatorSeparator: () => null,
                                }}
                                isSearchable={false}
                                onKeyDown={handleNumberOnlyInput}
                                value={{
                                    value: (() => {
                                        const isRecData = cartItems.find((x) => x.TicketId === item.TicketId);
                                        return isRecData ? isRecData.Quantity : bundleSelectedQuantity[item.TicketId] || 0;
                                    })(),
                                    label: (() => {
                                        const isRecData = cartItems.find((x) => x.TicketId === item.TicketId);
                                        return isRecData ? isRecData.Quantity : bundleSelectedQuantity[item.TicketId] || 0;
                                    })(), // Use defaultValue
                                }}
                                // cartItems?.find((x) => x.TicketId === item.TicketId) ? bundleSelectedQuantity[item.TicketId] || 0, // Use defaultValue
                                onChange={(val) => {
                                    const isRecData = cartItems.filter(
                                        (x) => x.TicketId === item?.TicketId
                                    );
                                    if (isRecData.length > 0) {
                                        handleChange(val, item);
                                        if (val.value == 0) {
                                            handleSetSelectedOption(val);
                                        }
                                    } else {
                                        handleChange(val, item);
                                    }
                                }}
                                options={[
                                    ...Array(
                                        Number(16)
                                    ).keys(),
                                ].map((i) => {
                                    return { value: i, label: i };
                                })}
                            />
                        ) : (
                            <Select
                                styles={customStyles}
                                components={{
                                    IndicatorSeparator: () => null,
                                }}
                                isSearchable={false}
                                onKeyDown={handleNumberOnlyInput}
                                value={{
                                    value:
                                        defaultValue === undefined
                                            ? 0
                                            : cartItems.length > 0
                                                ? Number(defaultValue?.Quantity)
                                                : 0,
                                    label:
                                        defaultValue === undefined
                                            ? 0
                                            : cartItems.length > 0
                                                ? defaultValue?.Quantity
                                                : 0,
                                }}
                                onChange={(val) => {
                                    handleChange(val, item);
                                }}
                                options={[
                                    ...Array(
                                        Number(
                                            Number(item?.AvailableTicket) > 15
                                                ? 16
                                                : Number(item?.AvailableTicket) + 1
                                        )
                                    ).keys(),
                                ].map((i) => {
                                    return { value: i, label: i };
                                })}
                            />
                        )
                    )}
                  </React.Fragment>
              ) : null}

              {item?.RecurringTicket === "True" && !item?.isBundle ? (
                  <React.Fragment>
                    {checkDateWithinRange(item?.ActiveDate, item?.InActiveDate) &&
                        isShowInput && (
                            <Select
                                styles={customStyles}
                                components={{
                                  IndicatorSeparator: () => null,
                                }}
                                isSearchable={false}
                                onKeyDown={handleNumberOnlyInput}
                                value={{
                                  value:
                                      defaultValue === undefined
                                          ? 1
                                          : cartItems.length > 0
                                              ? Number(defaultValue?.Quantity)
                                              : 0,
                                  label:
                                      defaultValue === undefined
                                          ? 1
                                          : cartItems.length > 0
                                              ? defaultValue?.Quantity
                                              : 0,
                                }}
                                onChange={(val) => {
                                  const isRecData = cartItems.filter(
                                      (x) => x.TicketId === item?.TicketId
                                  );
                                  if (isRecData.length > 0) {
                                    handleChange(val, item, {
                                      selectedDate: isRecData[0]?.selectedDate,
                                      selectedSlot: isRecData[0]?.selectedSlot,
                                    });
                                    if (val.value == 0) {
                                      handleSetSelectedOption(val);
                                    }
                                  } else {
                                    handleChange(val, item, recurringData);
                                  }
                                }}
                                options={[
                                  ...Array(
                                      Number(
                                          Number(availableQnty) > 15
                                              ? 16
                                              : Number(availableQnty) + 1
                                      )
                                  ).keys(),
                                ].map((i) => {
                                  return { value: i, label: i };
                                })}
                            />
                        )}
                  </React.Fragment>
              ) : null}
            </div>
          </div>
        </div>
      </>
  );
};

const mapStateToProps = (state) => {
  return {
    results: state.loginResponse.results,
    isLoggedIn: state.loginResponse.isLoggedIn,
    currentEventDetails: state.eventReducer.currentEventDetails,
    cartItems: state.eventReducer.cartData,
    discountData: state.eventReducer.discountData,
    shoppingCartDetails: state.eventReducer.createshoppingCartDetails,
    paymentIntent: state.eventReducer.paymentIntent,
  };
};
const matchDispatchToProps = (dispatch) => {
  return {
    PersistShoppingCartSession,
    CreateShoppingCartSession,
    ticktesCartItem,
    dispatch,
  };
};
export default connect(mapStateToProps, matchDispatchToProps)(AddMoreTickets);
