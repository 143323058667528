import { GET_ACTIVITY } from '../Actions/actionTypes';
const initial = {
    results: {
        BusinessActivity: [],
        activityReceived:false
    }
};
export default (state=initial,action)=>{
    switch(action.type){
        case GET_ACTIVITY:
            if (action.payload.results.BusinessActivity!==null && action.payload.results.ResponseCode)
                return {...action.payload,activityReceived:true};
            else
                return {...state,activityReceived:true};
        default:
            return state;
    }
}