import React from 'react';
import {connect} from 'react-redux';
import './erroralert.css';

class ErrorAlert extends React.Component{
    render() {
        return(
            <React.Fragment>
                {this.props.data.message !== 'sc' && <div style={{background:'#EB4C42',marginTop:'-19px'}}>
                <div className="error-message">{this.props.data.message}</div>
                </div>}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        data: state.messageReducer.error
    };
};

export default connect(mapStateToProps,null)(ErrorAlert);
