import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  Box,
  Button,
  createMuiTheme,
  FormControl,
  Grid,
  IconButton,
  Modal,
  TextField,
  Tooltip,
  withStyles,
} from "@material-ui/core";
import draftToHtml from "draftjs-to-html";
import { Info } from "@material-ui/icons";
import {
  addUpdateEventByEventUser,
  updateEventByEventUser,
  currentEventStatus,
  editEvenntByEventUser,
  updateDescriptor,
} from "../../../../Actions/eventActions";
import converter from "../../../../Helper/convertFile";
import DateFnsUtils from "@date-io/date-fns";
import ContentGrid from "../../../../Helper/gridWrapper/gridWrapper";
import {
  EditorState,
  convertToRaw,
  convertFromHTML,
  ContentState,
  insertNewUnstyledBlock,
  RichUtils,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import NativeSelect from "@material-ui/core/NativeSelect";
import EventHeader from "./eventHeader";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import load from "load-script";
import moment from "moment-timezone";
import DateAndTime from "../Tickets/dateAndTime";
import "./editor.css";
import tz from "tz-lookup";
import Resizer from "react-image-file-resizer";
import ModernDatepicker from "react-modern-datepicker";
import DatePicker from "react-modern-calendar-datepicker";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import axios from "axios";
import { history } from "../../../../store";
import { axiosE } from "../../../../Service/service";
import ImageCropper from "./ImageCropper";
import Loader from "../../../../Helper/loader/loader";
import { default as momentTz } from "moment-timezone";
import CreateEventErrorMessage from "../createEventErrorMessage";
import pica from "pica";
import ClearIcon from "@material-ui/icons/Clear";
import { ErrorMessages, eventApis, Events, Text } from "Constants";
import { Grammarly, GrammarlyEditorPlugin } from "@grammarly/editor-sdk-react";
import htmlToDraft from "html-to-draftjs";
import { ws } from "App";
import store from "store";
import storeFolder from "../../../../store";

const styles = {
  eventDetailsAboutGrid: {
    "@media (max-width: 480px)": {
      padding: "0px",
    },
  },
  eventDetailsAboutMainWrapper: {
    padding: "40px",
    height: "90vh",
    overflow: "auto",
    marginTop: "0px -12px 0px -12px",
    background: "white",

    "@media (max-width: 445px)": {
      padding: "10px",
    },
  },
  eventDetailsAboutWrapper: {
    display: "flex",
    justifyContent: "center",

    "@media (max-width: 445px)": {
      padding: "10px",
    },

    "@media screen and (max-device-width:768px)": {
      paddingBottom: "50px",
    },
  },

  eventDetailsAboutFormWrapper: {
    width: "100%",
    margin: "80px 240px 0px 10px",
    // "@media only  screen and (max-width:450px)": {
    //   margin: "80px 10px 0px 0px",
    // },
    "@media only screen and (max-width: 980px)": {
      margin: "80px 100px 0px 20px",
    },

    "@media only  screen and (max-width:725px)": {
      margin: "80px 0px 0px 20px",
    },
  },

  eventDetailsAboutVerticalLineWithCirclesWrapper: {
    margin: "83px 0px 0px 0px",
    "@media screen and (max-device-width:450px)": {
      display: "none",
    },
  },

  eventDetailsAboutCircleOnVerticalLine: {
    backgroundColor: "#a338a4",
    height: "15px",
    width: "15px",
    margin: "0px 0px 112px -9px",
    borderRadius: "50%",
    "@media screen and (max-device-width:450px)": {
      display: "none",
    },
  },

  eventDetailsAboutInputLabel: {
    fontSize: "16px",
    fontWeight: "bold",
  },

  eventDetailsAboutLongInputField: {
    border: "1px solid #ced4da",
    borderRadius: "5px",
    fontSize: "16px",
    padding: "10px",
    margin: "15px 0px 30px 0px",
    width: "auto",
  },

  eventDetailsAboutInputText: {
    fontSize: "16px",
  },

  eventDetailsAboutNameLocationVenueGridWrapper: {
    margin: "2px 0px 0px 0px",
    display: "grid",
  },

  eventDetailsAboutStartEndDateGridWrapper: {
    margin: "0px 0px 0px 0px",
  },

  eventDetailsAboutStartTimeInputLabel: {
    margin: "0%",
    fontSize: "16px",
    fontWeight: "bold",
    "@media screen and (max-device-width:450px)": {},
  },

  eventDetailsAboutEndTimeInputLabel: {
    margin: "0px 0px 0px 0px",
    fontSize: "16px",
    fontWeight: "bold",
    "@media screen and (max-device-width:450px)": {
      margin: "0px",
    },
  },

  eventDetailsAboutLowerGridWrapper: {
    display: "grid",
    marginTop: "25px",
  },

  eventDetailsAboutUploadMap: {
    borderRadius: "5px",
    fontFamily:
        "HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif",
    fontSize: "14px",
    padding: "10px 25px 10px 25px",
    textTransform: "none",
    margin: "0px 0px 54px 0px",
    width: "150px",
    "@media screen and (max-device-width:450px)": {
      margin: "0px 0px 30px 0px",
    },
  },

  eventDetailsAboutEventTypeDropdown: {
    width: "50%",
    "@media screen and (max-device-width:1024px)": {
      width: "100%",
    },
    margin: "10px 0px 35px 0px",
  },

  eventDetailsAboutTimeInputField: {
    border: "1px solid #ced4da",
    borderRadius: "5px",
    width: "auto",
    margin: "15px 0px 28px 0px",
    padding: "10px",
    fontSize: "16px",
  },

  eventDetailsAboutDateTimePickerFont: {
    fontSize: "16px",
  },

  eventDetailsAboutLargeInputForm: {
    border: "1px solid #ced4da",
    borderRadius: "5px",
    fontSize: "16px",
    padding: "10px",
    margin: "0px 0px 20px 0px",
    width: "600px",
  },

  eventDetailsAboutButtonWrapper: {
    margin: "2% 0% 0% 0%",
    textAlign: "end",
  },

  eventDetailsAboutMakeYourEventLiveButton: {
    backgroundColor: "#a338a4",
    color: "white",
    borderRadius: "20px",
    fontFamily:
        "HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif",
    fontSize: "15px",
    padding: "10px 25px 10px 25px",
    margin: "0px 0px 40px 0px",
    textTransform: "none",
    zIndex: 4,
    "@media (max-width: 768px)": {
      margin: 0,
      width: "100%",
      borderRadius: 0,
      position: "absolute",
      bottom: 0,
      right: 0,
    },
    "&:hover": {
      color: "black",
    },
    "&:disabled": {
      background: "lightgray !important",
    },
  },

  eventDetailsAboutHTMLEditorContainer: {
    height: "300px",
    marginBottom: "50px",
    border: "1px solid lightgray",
    padding: "10px",

    "@media  screen and (max-device-width:450px)": {
      margin: "0px 0px 30px 0px",
    },
  },

  nativeSelect: {
    fontSize: "14px",
    padding: "10px",
    border: "1px solid lightgray",
  },

  suggestionActiveItem: {
    padding: "3px",
    backgroundColor: " #f1f1f1",
    cursor: "pointer",
    fontSize: " 14px",
    color: "black",
  },

  suggestionItem: {
    padding: "3px",
    backgroundColor: "#fff",
    cursor: "pointer",
    fontSize: "14px",
    color: "black",
  },
  lineHeightVerticalLineCalculator: {
    float: "left",
    borderLeft: "2px solid lightgray",
    margin: "83px 0px 0px 0px",
    "@media screen and (max-device-width:450px)": {
      display: "none",
    },

    "@media screen and (max-device-width:768px)": {
      margin: "83px 0px 0px 20px",
    },
  },
  eventImageCropperModal: {
    textAlign: "center",

    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    // borderRadius: "10px",
    padding: "10px",
    backgroundColor: "white",
    width: "100vw",
    height: "100vh",
  },
  eventDetailsAboutInputHelperText: {
    textAlign: "end",
    margin: 0,
    fontSize: "9px",
  },
};

const CreateEventDetailsAboutToolTip = withStyles(() => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: "16px",
    border: "1px solid #dadde9",
  },
}))(Tooltip);

export class EventDetailsAbout extends Component {
  constructor(props) {
    const todayDateAndTime = moment(new Date());
    super(props);
    this.state = {
      eventTitle: "",
      eventLocation: "",
      eventAddress: "",
      eventVenue: "",
      eventStartDate: todayDateAndTime,
      eventEndDate: todayDateAndTime,
      eventStartTime: todayDateAndTime,
      eventEndTime: todayDateAndTime,
      eventMap: "",
      eventImages: {},
      eventDescription: "",
      eventParking: "",
      dropdownItem: "1",
      saveClickedForErrorMessages: false,
      editorState: EditorState.createEmpty(),
      editorState2: EditorState.createEmpty(),
      virtualEventDescription: EditorState.createEmpty(),
      editorHtml1: "",
      editorHtml2: "",
      virtualEventDescriptionHtml: "",
      loadAutocomplete: false,
      locationSuggestionExpanded: false,
      timeStartDisable: { hour: "", min: "", sec: "" },
      timeEndDisable: { hour: "", min: "", sec: "" },
      categories: [],
      eventAboutUpdate: false,
      submitButtonDisabled: false,
      eventImageCropperModal: false,
      eventImageToCrop: "",
      loader: false,
      error: { show: false, message: "" },
      addressError: false,
      eventPDF: "",
      clonealert: false,
      isValidEndDateTime: false,
      isVirtual: false, // New state for toggling virtual fields
      status: "",
    };
  }

  htmlToEditorState(prop) {
    const blocksFromHTML = htmlToDraft(prop);
    const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
    );
    const newState = this.customContentStateConverter(state);
    return EditorState.createWithContent(newState);
  }
  customContentStateConverter = (contentState) => {
    // changes block type of images to 'atomic'
    const newBlockMap = contentState.getBlockMap().map((block) => {
      const entityKey = block.getEntityAt(0);
      if (entityKey !== null) {
        const entityBlock = contentState.getEntity(entityKey);
        const entityType = entityBlock.getType();
        switch (entityType) {
          case "IMAGE": {
            const newBlock = block.merge({
              type: "atomic",
              text: "img",
            });
            return newBlock;
          }
          default:
            return block;
        }
      }
      return block;
    });
    const newContentState = contentState.set("blockMap", newBlockMap);
    return newContentState;
  };

  deleteEventMap = () => {
    const { eventId } = this.props;
    if (eventId) {
      axiosE.post(eventApis.deleteEventMap, { EventId: eventId });
    }
  };
  timeUTCtoLocal = (date, timezone) => {
    var local = momentTz
        .tz(moment.utc(date), "MM/DD/YYYY hh:mm:ss a", timezone)
        .format("MM/DD/YYYY hh:mm:ss a");
    return moment(local, "MM/DD/YYYY hh:mm:ss a");
  };

  GetEventFeeData = (eventId) => {
    return axiosE
        .get(`GetEventFeeData/${eventId}`)
        .then(({ data }) => {
          localStorage.setItem(
              "buyerFee",
              JSON.stringify(Number(data.results.UBMeFee))
          );
          localStorage.setItem(
              "additionalFee",
              JSON.stringify(Number(data.results.UbmeAdditionalCharge))
          );
          localStorage.setItem(
              "CurrencySymbol",
              data.results.CurrencySymbol
          );
          return data;
        })
        .catch(() => {});
  };

  toggleVirtual = () => {
      this.setState((prevState) => ({isVirtual: !prevState.isVirtual}));
  };

  componentDidMount() {
    this.loaderState(true);
    const state = store.getState();
    const eventID = state.eventUserEvents.eventId;
    let storeData = storeFolder.getState();
    const localState = localStorage.getItem("isItCreateOrEditEvent");
    const localEventId = localStorage.getItem("CurrentEventId");

    setTimeout(() => {
      if(storeData.messageReducer?.connection){
      localEventId && ws?.json({
        eventId: JSON.parse(localEventId),
        userId: storeData.loginResponse.results.ProfileDetail.UserId,
        type: "eventUserCheckedIn"
      })};
  }, 2000);

    if (
        localState === null ||
        localState === undefined ||
        localState === "" ||
        localState === "undefined"
    ) {
      localStorage.setItem("isItCreateOrEditEvent", "Edit");
      // window.location.reload();
    }
    const { eventAbout } = this.props;
    axiosE
        .get(eventApis.getAllEventCategories)
        .then((cat) => {
          this.setState({ categories: cat.data.results.Categories });
        })
        .catch((err) => {
          this.setState({ error: { show: true, message: err.message } });
        });
    /*.finally(() => {
        this.loaderState(false);
      });*/

    // const { eventId } = this.props;
    var eventId = JSON.parse(localStorage.getItem("CurrentEventId"));
    if (!eventId) {
      eventId = this.props.eventId;
    }

    if (eventId) {
      // const { eventId } = this.props;
      // localStorage.setItem("CurrentEventId", JSON.stringify(eventId));
      // const { eventId } = this.props;
      const userId = localStorage.getItem("UserId");

      this.GetEventFeeData(eventId)

      this.setState({
        eventAboutUpdate: true,
      });
      axiosE
          .get(`${eventApis.GetEventMasterById}${eventId}/${userId}/Admin`)
          .then(({ data }) => {
            const isCloneEvent = localStorage.getItem("isCloneEvent");
            if (isCloneEvent === "true" && data.results.Status !== "Published") {
              this.setState({ clonealert: true });
            }
            const { results } = data;
            this.props.editEvenntByEventUser(results);
            localStorage.setItem(
                "CurrentTimeZone",
                JSON.stringify(results?.TimeZone)
            );
            // localStorage.setItem(
            //   "PaymentDescriptor",
            //   results.PaymentDescriptor
            // );
            this.props.updateDescriptor({
              EventId: eventId,
              PaymentDescriptor: results.PaymentDescriptor,
            });
            if (Boolean(results.EventId)) {
              this.setState({
                eventTitle: results.Title,
                eventLocation: results.Add1,
                eventAddress: {
                  name: results.Add1,
                  addr: results.Add2,
                  lat: results.Latitude,
                  lng: results.Longitude,
                  city: results.City,
                  country: results.Country,
                  state: results.Region,
                  timezone: results.TimeZone,
                  postalCode: results.PostalCode,
                },
                eventVenue: results.VenueName,
                eventStartDate: this.timeUTCtoLocal(
                    isCloneEvent === "true"
                        ? moment(new Date())
                        : results.StartDateTime,
                    results.TimeZone
                ),
                eventEndDate: this.timeUTCtoLocal(
                    isCloneEvent === "true"
                        ? moment(new Date())
                        : results.EndDateTime,
                    results.TimeZone
                ),
                eventStartTime: this.timeUTCtoLocal(
                    isCloneEvent === "true"
                        ? moment(new Date())
                        : results.StartDateTime,
                    results.TimeZone
                ),
                eventEndTime: this.timeUTCtoLocal(
                    isCloneEvent === "true"
                        ? moment(new Date())
                        : results.EndDateTime,
                    results.TimeZone
                ),
                eventMap: this.validatePDF(results.EventMap),
                eventImages: {
                  large: results.ImageLarge,
                  medium: results.ImageMedium,
                  small: results.ImageSmall,
                },
                eventDescription: results.Description,
                eventParking: results.Parking,
                dropdownItem: results.EventType,
                saveClickedForErrorMessages: false,
                editorState: this.htmlToEditorState(results.Description),
                editorState2: this.htmlToEditorState(results.Parking),
                virtualEventDescription: this.htmlToEditorState(results.VirtualEventDetails),
                editorHtml1: results.Description,
                editorHtml2: results.Parking,
                virtualEventDescriptionHtml: results.VirtualEventDetails,
                loadAutocomplete: true,
                locationSuggestionExpanded: false,
                isVirtual: results.IsVirtualEvent === "True" ? true : false,
                status: results.Status,
              });
            }
          })
          .catch((err) => {
            this.setState({ error: { show: true, message: err.message } });
            console.log(err);
          })
          .finally(() => this.loaderState(false));
    } else {
      this.loaderState(false);
    }
  }

  componentWillUnmount() {
    // localStorage.removeItem("CurrentEventId");
  }

  validatePDF = (url) => {
    if (url) {
      const urlParts = url.split(".");
      if (urlParts[urlParts.length - 1] === "pdf") {
        return url;
      }
    }
    return "";
  };

  removeEventMap = () => {
    const url = this.props.eventAbout && this.props.eventAbout.EventMap;
    if (url) {
      const urlParts = url.split(".");
      if (urlParts[urlParts.length - 1] === "pdf") {
        this.deleteEventMap();
      }
    }
    this.setState({ eventMap: "" });
  };

  componentWillMount() {
    load(
        "https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyBr9MSaEjodT_Q9bnUqUcusJ8HWmQmJRC0",
        (e, err) => {
          if (e) {
            this.setState({ error: { show: true, message: err.message } });
          } else {
            this.setState({
              loadAutocomplete: true,
            });
          }
        }
    );
  }
  onEditorStateChange = (editorState) => {
    const editorHtml = draftToHtml(
        convertToRaw(editorState.getCurrentContent())
    );

    this.setState({
      editorState,
      editorHtml1: editorHtml,
    });
  };

  onEditorStateChange1 = (editorState2) => {
    const innerContent = convertToRaw(editorState2.getCurrentContent());
    // const editorHtml = !!innerContent.blocks[0].text
    //   ? draftToHtml(innerContent)
    //   : "";
    const editorHtml = draftToHtml(innerContent);
    this.setState({
      editorState2,
      editorHtml2: editorHtml,
    });
  };

  onVirtualEventStateChange = (virtualEventDescription) => {
    const innerContent = convertToRaw(virtualEventDescription.getCurrentContent());
    // const editorHtml = !!innerContent.blocks[0].text
    //   ? draftToHtml(innerContent)
    //   : "";
    const editorHtml = draftToHtml(innerContent);
    this.setState({
      virtualEventDescription,
      virtualEventDescriptionHtml: editorHtml,
    });
  };

  handleDropdownChange = (e) => {
    this.setState({
      dropdownItem: e.target.value,
    });
  };

  getCityStateCountry = (results) => {
    const address = results[0].formatted_address;
    let addr, city, state, country, postalCode;
    for (let i = 0; i < results[0].address_components.length; i++) {
      for (let j = 0; j < results[0].address_components[i].types.length; j++) {
        switch (results[0].address_components[i].types[j]) {
          case "street_number":
            addr = results[0].address_components[i].long_name;
            break;
          case "premise":
            addr = addr
                ? addr + " " + results[0].address_components[i].long_name
                : results[0].address_components[i].long_name;
            break;
          case "route":
            addr = addr
                ? addr + " " + results[0].address_components[i].long_name
                : results[0].address_components[i].long_name;
            break;
          case "sublocality":
            city = results[0].address_components[i].long_name;
            break;
          case "locality":
            city = results[0].address_components[i].long_name;
            break;
          case "postal_town":
            city = results[0].address_components[i].long_name;
            break;
          case "sublocality_level_1":
            city = results[0].address_components[i].long_name;
            break;
          case "administrative_area_level_1":
            state = results[0].address_components[i].long_name;
            break;
          case "country":
            country = results[0].address_components[i].long_name;
            break;
          case "postal_code":
            postalCode = results[0].address_components[i].long_name;
            break;
        }
      }
    }

    return [address, addr, country, city, state, postalCode];
  };

  handleLocationSelect = (address) => {
    let fullAddress;
    geocodeByAddress(address)
        .then((results) => {
          fullAddress = this.getCityStateCountry(results);
          return getLatLng(results[0]);
        })
        .then((latLng) => {
          this.setState({
            eventAddress: {
              name: fullAddress[0],
              addr: fullAddress[1],
              ...latLng,
              timezone: tz(latLng.lat, latLng.lng),
              country: fullAddress[2],
              city: fullAddress[3],
              state: fullAddress[4],
              postalCode: fullAddress[5],
            },
            eventLocation: address,
          });
        });
  };
  inputChange = (textField, e) => {
    if (textField === "eventLocation" && !this.state["eventAddress"]) {
      this.setState({ addressError: true });
    }
    this.setState({
      [textField]: textField == "eventLocation" ? e : e.target.value,
    });
  };
  ChangeStartDate = (date) => {
    const completeDate = `${date.month}-${date.day}-${date.year}`;
    this.setState({
      eventStartDate: moment(completeDate, "MM/DD/YYYY"),
      // eventEndDate: moment(completeDate, "MM/DD/YYYY"),
    });
  };
  ChangeEndDate = (date) => {
    const completeDate = `${date.month}-${date.day}-${date.year}`;
    this.setState({
      eventEndDate: moment(completeDate, "MM/DD/YYYY"),
    });
  };
  ChangeStartTime = (date) => {
    if (date === null || date === "invalid date") {
      this.setState({
        eventStartTime: moment(),
        eventEndTime: moment(),
      });
    } else {
      this.setState({
        eventEndTime: moment(date),
        eventStartTime: moment(date),
      });
    }
  };
  ChangeEndTime = (date) => {
    if (date === null || date === "invalid date") {
      this.setState({
        eventEndTime: moment(),
      });
    } else {
      this.setState({
        eventEndTime: moment(date),
      });
    }
  };

  uploadPicture = async (e, type) => {
    e.stopPropagation();
    const file = e.target.files[0];
    if (file !== undefined) {
      // this.checkDimensions(e);
      if (file.size <= 5000000) {
        // this.increaseSize(file);
        const reader = new FileReader();
        reader.onload = () => {
          this.setState({
            eventImageToCrop: reader.result,
            eventImageCropperModal: true,
          });
        };
        reader.readAsDataURL(file);
      } else {
        this.setState({
          error: { show: true, message: ErrorMessages.imageSize },
        });
      }
    } else {
      this.setState({
        error: { show: true, message: ErrorMessages.imageNotFound },
      });
    }
  };

  setMultipleResizedImages = async (img) => {
    this.setState({ eventImageCropperModal: false });
    let images = await [
      { width: 1920, height: 960, title: "large" },
      { width: 512, height: 256, title: "medium" },
      { width: 240, height: 120, title: "small" },
    ].map((size) => {
      this.increaseSize(img, size);
    });
  };

  setCroppedImage = (img) => {
    this.setState({
      eventCroppedImage: img,
    });
  };

  handleCropImageModal = (show) => {
    this.setState({
      eventImageCropperModal: show,
    });
  };

  imageLoader = (file, spec) => {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.crossOrigin = "Anonymous";
      image.onload = (e) => {
        resolve({
          image,
          width: spec.width,
          height: spec.height,
        });
      };

      const reader = new FileReader();
      reader.onload = () => {
        image.src = reader.result;
      };
      reader.readAsDataURL(file);
    });
  };

  calculate = (t) => {
    return (
        Math.round((performance.now() - t + Number.EPSILON) * 100) / 100 + "ms"
    );
  };
  format = ({ size }) => {
    if (size === 0) return "0 Bytes";
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(size) / Math.log(k));
    return parseFloat((size / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
  };

  increaseSize = (file, spec) => {
    const resizer = new pica();

    if (file) {
      const t0 = performance.now();
      this.imageLoader(file, spec).then(({ image, width, height }) => {
        const canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;
        resizer
            .resize(image, canvas)
            .then((result) => resizer.toBlob(result, "image/png"))
            .then((blob) => {
              if (file) {
                file.src = URL.createObjectURL(blob);
              }

              const reader = new FileReader();
              reader.onload = () => {
                this.setState({
                  eventImages: {
                    ...this.state.eventImages,
                    [spec.title]: reader.result,
                  },
                });
              };
              reader.readAsDataURL(blob);
              canvas.remove();
              image.remove();
            }).catch(err=>console.log(err));
      }).catch(err=>console.log(err));
    }
  };

  checkDimensions = (e) => {
    const fileUpload = e.target;

    // Remove Previous Images from State
    this.setState({
      eventImages: {},
    });

    var reader = new FileReader();
    reader.readAsDataURL(fileUpload.files[0]);

    const ResizeImages = (fileUpload) =>
        this.setMultipleResizedImages(fileUpload);

    //Read the contents of Image File.
    reader.onload = function (e) {
      //Initiate the JavaScript Image object.
      var image = new Image();

      //Set the Base64 string return from FileReader as source.
      image.src = e.target.result;

      //Validate the File Height and Width.
      image.onload = function () {
        var height = this.height;
        var width = this.width;
        if (height >= 793 && width >= 1920) {
          ResizeImages(fileUpload);
        } else {
        }
      };
    };
  };

  createEventDetailsAboutErrorMessageLabelStyles = (textField) => {
    const { CreateEventErrorMessage } = this.state;
    return {
      color: "red",
      fontSize: "16px",
      fontWeight: "100",
      margin: "0px 0px 0px 10px",
      display:
          CreateEventErrorMessage && this.state[textField] === ""
              ? "initial"
              : "none",
    };
  };

  addressErrorMessage = () => {
    const { addressError } = this.state;
    return {
      color: "red",
      fontSize: "16px",
      fontWeight: "100",
      margin: "0px 0px 0px 10px",
      display:
          addressError && this.state["eventAddress"] === "" ? "initial" : "none",
    };
  };

  createEventDetailsAboutEventCreatedSuccessMessageStyles = () => {
    return {
      color: "green",
      margin: "50px 0px 50px 0px",
      textAlign: "center",
      display: "none",
    };
  };

  fileToBase64 = (file, cb) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(null, reader.result);
    };
    reader.onerror = function (error) {
      cb(error, null);
    };
  };

  uploadPDF = ({ target }) => {
    if (target.files < 1 || !target.validity.valid) {
      return;
    }
    this.setState({
      eventPDF: target.files[0],
    });
    this.fileToBase64(target.files[0], (err, result) => {
      if (result) {
        this.setState({
          eventMap: result,
        });
      }
    });
  };

  textFieldSuggestionInputWithTitle = (title) => {
    const { classes } = this.props;
    const formTitleMap = {
      "Event Title": "eventTitle",
      [title]: "eventLocation",
      "Venue Name": "eventVenue",
    };
    const searchOptions = {
      types: ["(cities)"],
    };

    const { About } = Events;

    return (
        <FormControl>
        <span className={classes.eventDetailsAboutInputLabel}>
          {title !== "Address" && this.titleWithToolTip(title, true)}
          <p
              style={this.createEventDetailsAboutErrorMessageLabelStyles(
                  "eventLocation"
              )}
          >{`Please Select a Location`}</p>
        </span>
          <span className={classes.eventDetailsAboutInputLabel}>
          {title === "Address" &&
              this.titleWithToolTip(
                  title,
                  About.addressExtraText,
                  true
              )}
            <p style={this.addressErrorMessage()}>{`Please Select a Location`}</p>
        </span>

          {this.state.loadAutocomplete && (
              <PlacesAutocomplete
                  value={this.state.eventLocation}
                  onChange={(e) => this.inputChange(formTitleMap[title], e)}
                  onSelect={(address) => this.handleLocationSelect(address)}
                  // searchOptions={searchOptions}
              >
                {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                    <>
                      <TextField
                          {...getInputProps({
                            fullWidth: true,
                            required: true,
                            className: classes.eventDetailsAboutLongInputField,
                            placeholder: title,
                            margin: "normal",
                            InputProps: {
                              disableUnderline: true,
                              className: classes.eventDetailsAboutInputText,
                            },
                          })}
                      />
                      <div
                          style={{
                            margin: "-35px 0px 10px 0px",
                            // overflow: "hidden",
                            overflowY: "auto",
                            height: "50px",
                            backgroundColor: "white",
                            border:
                                suggestions.length > 0 ? "1px solid #ced4da" : "0px",
                            borderRadius: "5px",
                          }}
                      >
                        {suggestions.map((suggestion) => {
                          const className = suggestion.active
                              ? classes.suggestionActiveItem
                              : classes.suggestionItem;

                          return (
                              <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                  })}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                          );
                        })}
                      </div>
                    </>
                )}
              </PlacesAutocomplete>
          )}
        </FormControl>
    );
  };

  config = {
    default: {
      small: {
        withMap: "1850px",
        withoutMap: "1800px"
      },
      large: {
        withMap: "1730px",
        withoutMap: "1680px"
      },
      circleMargins: [
        "124px", "132px", "170px", "110px", "130px", "128px", "130px", "104px", "510px", "0px"
      ]
    },
    virtual: {
      "1576": {
        small: {
          withMap: "2460px",
          withoutMap: "2410px"
        },
        large: {
          withMap: "2340px",
          withoutMap: "2290px"
        },
        circleMargins: [
          "124px", "134px", "160px", "598px", "112px", "130px", "128px", "130px", "104px", "510px", "0px"
        ]
      },
      "1152": {
        small: {
          withMap: "2480px",
          withoutMap: "2430px"
        },
        large: {
          withMap: "2360px",
          withoutMap: "2310px"
        },
        circleMargins: [
          "124px", "134px", "160px", "618px", "112px", "130px", "128px", "130px", "104px", "510px", "0px"
        ]
      },
      default: {
        small: {
          withMap: "2500px",
          withoutMap: "2450px"
        },
        large: {
          withMap: "2380px",
          withoutMap: "2330px"
        },
        circleMargins: [
          "124px", "134px", "160px", "638px", "112px", "130px", "128px", "130px", "104px", "510px", "0px"
        ]
      }
    }
  };

  getConfig = (isVirtual, windowWidth, eventImages, eventMap) => {
    const size = eventImages.small ? 'small' : 'large';
    const mapStatus = eventMap === "" ? 'withoutMap' : 'withMap';

    if (isVirtual) {
      if (windowWidth >= 1576) {
        return this.config.virtual["1576"];
      } else if (windowWidth >= 1152) {
        return this.config.virtual["1152"];
      } else {
        return this.config.virtual.default;
      }
    } else {
      return this.config.default;
    }
  };


  verticalLineWithCircles = () => {
    const { classes } = this.props;
    const { eventImages, isVirtual, eventMap } = this.state;
    const extraMargin = eventImages.small ? "248px" : "128px";
    const extraPdfMargin = eventMap === "" ? "130px" : "179px";

    const windowWidth = window.innerWidth;
    const { small, large, circleMargins } = this.getConfig(isVirtual, windowWidth, eventImages, eventMap);

    const size = eventImages.small ? 'small' : 'large';
    const mapStatus = eventMap === "" ? 'withoutMap' : 'withMap';
    const lineHeight = (eventImages.small ? small[mapStatus] : large[mapStatus]);

    const updatedCircleMargins = [...circleMargins];
    updatedCircleMargins[5] = extraMargin;
    updatedCircleMargins[6] = extraPdfMargin;

    return (
        <React.Fragment>
      <span
          className={classes.lineHeightVerticalLineCalculator}
          style={{
            height: lineHeight,
          }}
      ></span>
          <div className={classes.eventDetailsAboutVerticalLineWithCirclesWrapper}>
            {updatedCircleMargins.map((margin, index) => (
                <div
                    key={index}
                    className={classes.eventDetailsAboutCircleOnVerticalLine}
                    style={{
                      margin: `0px 0px ${margin} -9px`,
                    }}
                ></div>
            ))}
          </div>
        </React.Fragment>
    );
  };

  getDate = (date) => {
    const dateObj = {
      year: parseInt(date.format("YYYY")),
      month: parseInt(date.format("MM")),
      day: parseInt(date.format("DD")),
    };
    return dateObj;
  };

  getGateFormat = (date) => {
    const dateObj = this.getDate(date);
    return `${dateObj.month}/${dateObj.day}/${dateObj.year}`;
  };

  textFieldLongInputWithTitle = (title, isDisabled = false, defaultValue = '') => {
    const { classes } = this.props;
    const formTitleMap = {
      "Event Title": "eventTitle",
      Location: "eventLocation",
      "Venue Name": "eventVenue",
    };
    return (
        <FormControl>
        <span className={classes.eventDetailsAboutInputLabel}>
          {this.titleWithToolTip(
              title,
              title === "Event Title"
                  ? "List the title of your event"
                  : "List the name of the venue your event will be taking place at",
              true
          )}
          <p
              style={this.createEventDetailsAboutErrorMessageLabelStyles(
                  formTitleMap[title]
              )}
          >{`Please enter ${title}`}</p>
        </span>
          <TextField
              required
              className={classes.eventDetailsAboutLongInputField}
              placeholder={isDisabled ? defaultValue : title}
              margin="normal"
              fullWidth
              value={isDisabled ? '' : this.state[formTitleMap[title]]}
              onChange={(e) => this.inputChange(formTitleMap[title], e)}
              InputProps={{
                disableUnderline: true,
                className: classes.eventDetailsAboutInputText,
                readOnly: isDisabled,
              }}
              onInput={(e) => {
                e.target.value =
                    title === "Event Title" || title === "Venue Name"
                        ? e.target.value.length > title === "Venue Name"
                            ? 40
                            : 75
                                ? e.target.value.slice(0, title === "Venue Name" ? 40 : 75)
                                : e.target.value
                        : e.target.value;
              }}
              helperText={
                title === "Event Title"
                    ? `${this.state[formTitleMap[title]].length}/75`
                    : title === "Venue Name" &&
                    `${isDisabled ? defaultValue.length : this.state[formTitleMap[title]].length}/40`
              }
              FormHelperTextProps={{
                className: classes.eventDetailsAboutInputHelperText,
              }}
          />
        </FormControl>
    );
  };

  textFieldTimeInput = (title) => {
    const { classes } = this.props;
    const {
      eventStartDate,
      eventStartTime,
      eventEndDate,
      eventEndTime,
      eventAboutUpdate,
    } = this.state;

    const isCloneEvent = localStorage.getItem("isCloneEvent");
    const date_picker_error =
        isCloneEvent === "true"
            ? title == "Start"
                ? moment(this.state.eventStartDate).format("MM-DD-YYYY") ===
                this.timeUTCtoLocal(
                    moment(),
                    this.props.eventAbout?.TimeZone
                ).format("MM-DD-YYYY")
                    ? "date_picker_error"
                    : ""
                : moment(this.state.eventEndDate).format("MM-DD-YYYY") ===
                this.timeUTCtoLocal(
                    moment(),
                    this.props.eventAbout?.TimeZone
                ).format("MM-DD-YYYY")
                    ? "date_picker_error"
                    : ""
            : "";

    return (
        <div
            style={{
              position: "relative",
            }}
        >
          <div className="date_time_pickers">
            <DatePicker
                value={
                  title == "Start"
                      ? this.getDate(eventStartDate)
                      : this.getDate(eventEndDate)
                }
                onChange={(date) => {
                  title == "Start"
                      ? this.ChangeStartDate(date)
                      : this.ChangeEndDate(date);
                  this.isValidEndDateTime(date, "Date", title);
                }}
                style={{ border: "1px solid #f00" }}
                inputClassName={`date_picker_input ${date_picker_error}`}
                colorPrimary="#851679" // added this
                minimumDate={
                  title == "Start"
                      ? this.getDate(moment())
                      : this.getDate(eventStartDate)
                }
                // maximumDate={this.getDate(maxEndDate)}
                inputPlaceholder="Select a day"
                formatInputText={(date) =>
                    title == "Start"
                        ? this.getGateFormat(eventStartDate)
                        : this.getGateFormat(eventEndDate)
                }
            />
            <TimePicker
                placeholder="Select Time"
                use12Hours
                showSecond={false}
                focusOnOpen={true}
                format="hh:mm A"
                value={title === "Start" ? eventStartTime : eventEndTime}
                onChange={(e) => {
                  title === "Start"
                      ? this.ChangeStartTime(e)
                      : this.ChangeEndTime(e);
                  this.isValidEndDateTime(e, "Time", title);
                }}
                className={`time_picker ${
                    date_picker_error === "date_picker_error"
                        ? "time_picker_input"
                        : ""
                } `}
                minuteStep={1}
                // disabledHours={() =>
                //   title === "Start"
                //     ? this.getDisabledHoursStart()
                //     : this.getDisabledHoursEnd()
                // }
                // disabledMinutes={() => this.getDisabledMinutes()}
            />
          </div>

          {title === "End" && this.state.isValidEndDateTime && (
              <div className="isValidEndDateTime">
                <p>End date can not be set before the start date of the event.</p>
              </div>
          )}
        </div>
    );
  };

  // getDisabledHoursStart = () => {
  //   let hours = [];

  //   const { eventStartDate } = this.state;

  //   const startDate = eventStartDate.format("DD/MM/YYYY");
  //   const today = moment().format("DD/MM/YYYY");

  //   if (startDate === today) {
  //     for (let i = 0; i < moment().hour(); i++) {
  //       hours.push(i);
  //     }
  //   }

  //   return hours;
  // };

  // getDisabledHoursEnd = () => {
  //   let hours = [];

  //   const { eventStartDate, eventEndDate, eventStartTime } = this.state;

  //   const startDate = eventStartDate.format("DD/MM/YYYY");
  //   const endDate = eventEndDate.format("DD/MM/YYYY");

  //   if (startDate === endDate) {
  //     for (let i = 0; i <= eventStartTime.hour() - 1; i++) {
  //       hours.push(i);
  //     }
  //   }

  //   return hours;
  // };

  // getDisabledMinutes = () => {
  //   let minutes = [];

  //   const { eventStartTime, eventStartDate, eventEndTime, eventEndDate } =
  //     this.state;

  //   const startDate = eventStartDate.format("DD/MM/YYYY");
  //   const endDate = eventEndDate.format("DD/MM/YYYY");

  //   if (startDate === endDate) {
  //     if (eventStartTime.hour() === eventEndTime.hour()) {
  //       for (var i = 0; i < eventStartTime.minute(); i++) {
  //         minutes.push(i);
  //       }
  //     }
  //   }

  //   return minutes;
  // };

  isValidEndDateTime = (input, type, title) => {
    const { eventStartTime, eventStartDate, eventEndTime, eventEndDate } =
        this.state;

    let eventDateStart = eventStartDate;
    let eventDateEnd = eventEndDate;
    if (type === "Date" && title === "Start") {
      eventDateStart = moment(
          `${input.year}-${input.month}-${input.day}`,
          "YYYY-MM-DD"
      );
    } else if (type === "Date" && title === "End") {
      eventDateEnd = moment(
          `${input.year}-${input.month}-${input.day}`,
          "YYYY-MM-DD"
      );
    }

    let eventTimeStart = eventStartTime;
    let eventTimeEnd = eventEndTime;
    if (type === "Time" && title === "Start") {
      eventTimeStart = moment(input);
      return this.setState({ isValidEndDateTime: false });
    } else if (type === "Time" && title === "End") {
      eventTimeEnd = moment(input);

    }

    const startDate = eventDateStart.format("DD/MM/YYYY");
    const endDate = eventDateEnd.format("DD/MM/YYYY");

    const startTime = eventTimeStart.format("HH:mm");
    const endTime = eventTimeEnd.format("HH:mm");

    const startDateTime = moment(
        `${startDate} ${startTime}`,
        "DD/MM/YYYY HH:mm"
    ).utcOffset("+00:00"); // Set the UTC offset
    const endDateTime = moment(
        `${endDate} ${endTime}`,
        "DD/MM/YYYY HH:mm"
    ).utcOffset("+00:00"); // Set the UTC offset

    const status = endDateTime.isBefore(startDateTime);
    const { isValidEndDateTime } = this.state;
    this.setState({ isValidEndDateTime: status });
  };

  getCompositeDateAndTime = (date, time) => {
    const { timezone } = this.state.eventAddress;
    const formattedDate = date.format("MM/DD/YYYY");
    const formattedTime = time.format("hh:mm:ss a");
    const dateAndTimeString = formattedDate + " " + formattedTime;
    const dateAndTime = moment(dateAndTimeString, "MM/DD/YYYY hh:mm:ss a");
    var dateTimeInUtc = momentTz
        .tz(dateAndTimeString, "MM/DD/YYYY hh:mm:ss a", timezone)
        .utc()
        .format();
    return dateTimeInUtc;
  };

  getCompositeDateAndTime2 = (date, time) => {
    const { timezone } = this.state.eventAddress;

    const formattedDate = date.format("MM/DD/YYYY");
    const formattedTime = time.format("hh:mm:ss a");

    const dateAndTimeString = formattedDate + " " + formattedTime;
    const dateAndTime = moment(dateAndTimeString, "MM/DD/YYYY hh:mm:ss a");
    var dateTimeInUtc = momentTz
        .tz(dateAndTimeString, "MM/DD/YYYY hh:mm:ss a", timezone)
        .utc()
        .format();

    return dateTimeInUtc;
  };

  convertToTimeZone = (date) => {
    const timeZone = "America/New_York";
    const timeZoneDate = moment.tz(date, timeZone);
    return timeZoneDate;
  };

  selectEventTypeDropdown = (title) => {
    const { classes } = this.props;
    const { categories } = this.state;
    return (
        <React.Fragment>
        <span className={classes.eventDetailsAboutInputLabel}>
          {this.titleWithToolTip(
              title,
              "Select which category best represents your event",
              true
          )}
        </span>
          <FormControl
              className={classes.eventDetailsAboutEventTypeDropdown}
              required={true}
              variant="outlined"
          >
            <NativeSelect
                fullWidth
                value={this.state.dropdownItem}
                onChange={this.handleDropdownChange}
                name="eventCategoryId"
                className={classes.nativeSelect}
            >
              {categories.map((c, i) => {
                return (
                    <option key={i} value={c.CategoryId}>
                      {c.CategoryName}
                    </option>
                );
              })}
            </NativeSelect>
          </FormControl>
        </React.Fragment>
    );
  };

  titleWithToolTip = (title, tipText, mandatory = false) => {
    const { classes } = this.props;
    const { About } = Events;
    return (
        <span className={classes.eventDetailsAboutInputLabel} style={{}}>
        {title}
          {mandatory && <span style={{ color: "red" }}> *</span>}
          {title === "Address" && (
              <span> - {About.addressExtraText}</span>
          )}
          <CreateEventDetailsAboutToolTip
              title={<React.Fragment>{tipText}</React.Fragment>}
          >
          <IconButton color={"primary"} style={{ padding: "0 0 0 10px" }}>
            <Info />
          </IconButton>
        </CreateEventDetailsAboutToolTip>
      </span>
    );
  };

  getFileBase64 = (file, callback) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    // Since FileReader is asynchronous,
    // we need to pass data back.
    reader.onload = () => callback(reader.result);
    // TODO: catch an error
    reader.onerror = (error) => {};
  };

  imageUploadCallback = (file) => {
    if (file) {
      if (file.size <= 2000000) {

        return new Promise((resolve, reject) => {
          this.getFileBase64(file, (dataUrl) => {
            const image = new Image();
            image.src = dataUrl;
            image.onload = () => {
              const canvas = document.createElement("canvas");
              const maxWidth = 800;
              const maxHeight = 800;
              let width = image.width;
              let height = image.height;

              if (width > height) {
                if (width > maxWidth) {
                  height *= maxWidth / width;
                  width = maxWidth;
                }
              } else {
                if (height > maxHeight) {
                  width *= maxHeight / height;
                  height = maxHeight;
                }
              }

              canvas.width = width;
              canvas.height = height;

              const ctx = canvas.getContext("2d");
              ctx.drawImage(image, 0, 0, width, height);

              canvas.toBlob(
                  (blob) => {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                      resolve({ data: { link: reader.result } });
                    };
                    reader.readAsDataURL(blob);
                  },
                  "image/jpeg",
                  0.7
              );
            };
          });
        });

        // return new Promise((resolve, reject) =>
        //     this.getFileBase64(file, (data) => resolve({data: {link: data}}))
        // );
      } else {
        this.setState({
          error: { show: true, message: ErrorMessages.imageSize2MB },
        });
      }
    }
  };

  largeTextField = (title, editorState, onEditorStateChange, type) => {
    const { classes } = this.props;
    const mandatoryOrNot = title === "Parking" ? false : true;
    const styleMap = {
      STRIKETHROUGH: {
        textDecoration: "line-through",
      },
      textAlign: "center",
    };
    return (
        <React.Fragment>
          {this.titleWithToolTip(
              title,
              title === "Parking"
                  ? "Describe parking and/or upload relevant parking images/maps"
                  : "Describe your event",
              mandatoryOrNot
          )}
          {/* <Grammarly > */}
          <GrammarlyEditorPlugin clientId="client_FdjdCy5cG4i2Wxi74peEG2">
            <Editor
                editorStyle={{ textAlign: "center" }}
                editorState={editorState}
                customStyleMap={styleMap}
                editorClassName="editor-class-about"
                toolbarClassName="toolbar-class-about"
                wrapperClassName="wrapper-class-about"
                onEditorStateChange={onEditorStateChange}
                toolbar={{
                  image: {
                    previewImage: true,
                    urlEnabled: true,
                    uploadEnabled: true,
                    // alt: { present: true, mandatory: true },
                    uploadCallback: (file) => this.imageUploadCallback(file),
                    inputAccept:
                        "image/gif,image/jpeg,image/jpg,image/png,image/svg",
                    defaultSize: {
                      height: "auto",
                      width: "100%",
                    },
                  },
                }}
                handlePastedText={() => this.imageUploadCallback()}
                //    handleReturn={(event) => {
                //     // override behavior for enter key
                //     var newEditorState = null;
                //     if (event.keyCode === 13 && event.shiftKey) {
                //         // with shift, make a new block
                //         newEditorState = insertNewUnstyledBlock(editorState);
                //     } else if (event.keyCode === 13 && !event.shiftKey) {
                //         // without shift, just a normal line break
                //         newEditorState = RichUtils.insertSoftNewline(editorState);
                //     }
                //     if (newEditorState) {
                //       if(type === 1){
                //        this.setState({editorState: newEditorState})
                //         return true;
                //       }else{
                //         this.setState({editorState2: newEditorState})
                //         return true;
                //       }

                //     }
                //     return false;
                // }}
            />
          </GrammarlyEditorPlugin>
          {/* </Grammarly> */}
        </React.Fragment>
    );
  };

  virtualEventDescriptionTextField = (title, editorState, onEditorStateChange) => {
    const { classes } = this.props;

    const styleMap = {
      STRIKETHROUGH: {
        textDecoration: "line-through",
      },
      textAlign: "center",
    };
    return (
        <React.Fragment>
          {this.titleWithToolTip(
              title,
               "Describe your event",
              true
          )}
          <div style={{marginTop:"10px", marginBottom:"10px"}}>
            <span>
              - Use this space to add your streaming provider's meeting link + password. Your ticket holders will receive this link after they register and in reminder emails.
            </span>
            <br/>
            <span>
              - Please use the 'Address' field above to add your city/state so attendees in that area can find this event on the UBMe Map!
            </span>
            <br/>
            <span>
              - Once you publish an online event, you <span style={{fontWeight:"600"}}>CANNOT</span> change it to a physical event.
            </span>
          </div>
          {/* <Grammarly > */}
          <GrammarlyEditorPlugin clientId="client_FdjdCy5cG4i2Wxi74peEG2">
            <Editor
                editorStyle={{ textAlign: "center" }}
                editorState={editorState}
                customStyleMap={styleMap}
                editorClassName="editor-class-about"
                toolbarClassName="toolbar-class-about"
                wrapperClassName="wrapper-class-about"
                onEditorStateChange={onEditorStateChange}
                toolbar={{
                  image: {
                    previewImage: true,
                    urlEnabled: true,
                    uploadEnabled: true,
                    // alt: { present: true, mandatory: true },
                    uploadCallback: (file) => this.imageUploadCallback(file),
                    inputAccept:
                        "image/gif,image/jpeg,image/jpg,image/png,image/svg",
                    defaultSize: {
                      height: "auto",
                      width: "100%",
                    },
                  },
                }}
                handlePastedText={() => this.imageUploadCallback()}
                //    handleReturn={(event) => {
                //     // override behavior for enter key
                //     var newEditorState = null;
                //     if (event.keyCode === 13 && event.shiftKey) {
                //         // with shift, make a new block
                //         newEditorState = insertNewUnstyledBlock(editorState);
                //     } else if (event.keyCode === 13 && !event.shiftKey) {
                //         // without shift, just a normal line break
                //         newEditorState = RichUtils.insertSoftNewline(editorState);
                //     }
                //     if (newEditorState) {
                //       if(type === 1){
                //        this.setState({editorState: newEditorState})
                //         return true;
                //       }else{
                //         this.setState({editorState2: newEditorState})
                //         return true;
                //       }

                //     }
                //     return false;
                // }}
            />
          </GrammarlyEditorPlugin>
          {/* </Grammarly> */}
        </React.Fragment>
    );
  };

  loaderState = (show) => {
    this.setState({ loader: show });
  };

  saveEvent = async () => {
    const eventImagesExist =
        Object.keys(this.state.eventImages).length > 0 ? "exists" : "";

    // Open Loader
    this.loaderState(true);

    const mandatoryFields = [
      this.state.eventTitle,
      this.state.eventLocation,
      this.state.isVirtual ? 'Online Event' : this.state.eventVenue,
      this.state.eventStartDate,
      this.state.eventEndDate,
      this.state.eventStartTime,
      this.state.eventEndTime,
      this.state.dropdownItem,
      this.state.editorHtml1,
      this.state.eventImages.length,
      eventImagesExist,
    ];

    if (this.state.isVirtual) {
      mandatoryFields.push(this.state.virtualEventDescriptionHtml); // Add all virtual-specific fields here
    }

    this.props.currentEventStatus(true);

    if (mandatoryFields.some((val) => val === "")) {
      this.setState({
        saveClickedForErrorMessages: true,
      });
      window.scrollTo({ top: 0 });
    } else {
      this.setState({
        submitButtonDisabled: true,
      });
      this.state.eventAboutUpdate
          ? this.updateEventAboutRemote()
          : this.addEventAboutRemote();
    }
  };

  replcaeEmptyTagwithBreak = (html) => {
    const newHtml = html
        ?.replaceAll("<p></p>", "<h6></h6>")
        .replaceAll("<h1></h1>", "<h6></h6>")
        .replaceAll("<h2></h2>", "<h6></h4>")
        .replaceAll("<h3></h3>", "<h6></h6>")
        .replaceAll("<h4></h4>", "<h6></h6>")
        .replaceAll("<h5></h5>", "<h6></h6>")
        .replaceAll("<h6></h6>", "<h6></h6>")
        .replaceAll("<pre></pre>", "<h6></h6>")
        .replaceAll("<blockquote></blockquote>", "<h6></h6>");

    return newHtml;
  };

  addEventAboutRemote = async () => {
    const {
      eventTitle,
      eventVenue,
      eventLocation,
      eventAddress,
      eventStartDate,
      eventEndDate,
      eventMap,
      eventDescription,
      eventParking,
      saveClickedForErrorMessages,
      eventImages,
      eventStartTime,
      eventEndTime,
      dropdownItem,
      editorHtml1,
      editorHtml2,
      virtualEventDescriptionHtml,
      isVirtual
    } = this.state;

    const response = await this.props
        .addUpdateEventByEventUser({
          UserId: localStorage.getItem("UserId"),
          Title: eventTitle,
          Description: this.replcaeEmptyTagwithBreak(editorHtml1),
          VenueName: isVirtual ? 'Online Event' : eventVenue,
          Add1: eventAddress.name,
          City: eventAddress.city || "",
          Region: eventAddress.state || "",
          Country: eventAddress.country || "",
          TimeZone: eventAddress.timezone,
          Latitude: eventAddress.lat,
          Longitude: eventAddress.lng,
          IsVirtual: isVirtual ? '1' : '0',
          VirtualEventDetails: this.replcaeEmptyTagwithBreak(virtualEventDescriptionHtml) || "",
          StartDateTime: this.getCompositeDateAndTime(eventStartDate, eventStartTime),
          EndDateTime: this.getCompositeDateAndTime(eventEndDate, eventEndTime),
          SmallImage:
              Object.keys(eventImages).length > 0
                  ? eventImages.small.split(",")[1]
                  : "",
          MediumImage:
              Object.keys(eventImages).length > 0
                  ? eventImages.medium.split(",")[1]
                  : "",
          LargeImage:
              Object.keys(eventImages).length > 0
                  ? eventImages.large.split(",")[1]
                  : "",
          EventType: this.state.dropdownItem,
          Parking: this.replcaeEmptyTagwithBreak(editorHtml2) || "",
          EventMap: eventMap.split(",")[1] || "",
          Status: "Draft", // Not Have
          Add2: eventAddress.addr || "",
          PostalCode: eventAddress.postalCode || "",
        })
        .then((val) => {
          localStorage.removeItem("isCloneEvent");

          if (val && val.status >= 200 && val.status <= 300) {
            this.GetEventFeeData(JSON.parse(val.data.results.Data).EventId);

            this.props.updateDescriptor({
              EventId: JSON.parse(val.data.results.Data).EventId,
              PaymentDescriptor: JSON.parse(val.data.results.Data)
                  .PaymentDescriptor,
            });

            localStorage.setItem("isItCreateOrEditEvent", "Edit");
            history.push("FAQ");
          } else {
            this.setState({
              error: { show: true, message: ErrorMessages.somethingWentWrong },
              submitButtonDisabled: false,
            });
          }
        })
        .catch((err) => {
          // console.log(err);
          this.setState({
            error: { show: true, message: err.message },
            submitButtonDisabled: false,
          });
        })
        .finally(() => this.loaderState(false));
  };

  checkDateTimeChange = (date, time, StartDateTime, TimeZone) => {
    const formattedDate = date.format("MM/DD/YYYY");
    const formattedTime = time.format("hh:mm:ss a");
    const dateAndTimeString = formattedDate + " " + formattedTime;
    var local = momentTz
        .tz(moment.utc(StartDateTime), "MM/DD/YYYY hh:mm:ss a", TimeZone)
        .format("MM/DD/YYYY hh:mm:ss a");
    const dateString = moment(local, "MM/DD/YYYY hh:mm:ss a");
    return (
        moment(dateString).format("MM/DD/YYYY hh:mm:ss a") === dateAndTimeString
    );
  };

  updateEventAboutRemote = async () => {
    const {
      eventTitle,
      eventVenue,
      eventLocation,
      eventAddress,
      eventStartDate,
      eventEndDate,
      eventMap,
      eventDescription,
      eventParking,
      saveClickedForErrorMessages,
      eventImages,
      eventStartTime,
      eventEndTime,
      dropdownItem,
      editorHtml1,
      editorHtml2,
      virtualEventDescriptionHtml,
      isVirtual
    } = this.state;
    const { eventAbout } = this.props;
    this.props
        .updateEventByEventUser({
          EventId: this.props.eventId,
          UserId: localStorage.getItem("UserId"),
          Title: eventTitle,
          Description: this.replcaeEmptyTagwithBreak(editorHtml1),
          VenueName: isVirtual ? 'Online Event' : eventVenue,
          Add1:
              eventAddress.name === eventAbout.Add1 ? "" : eventAddress.name || "",
          City: eventAddress.city,
              //eventAddress.city === eventAbout.City ? "" : eventAddress.city || "",
          Region: eventAddress.state,
             // eventAddress.state === eventAbout.Region ? "" : eventAddress.state || "",
          Country: eventAddress.country,
             // eventAddress.country === eventAbout.Country ? "" : eventAddress.country || "",
          TimeZone: eventAddress.timezone,
          Latitude: eventAddress.lat,
          Longitude: eventAddress.lng,
          IsVirtual: isVirtual ? '1' : '0',
          VirtualEventDetails: isVirtual? this.replcaeEmptyTagwithBreak(virtualEventDescriptionHtml) || "" : "",
          StartDateTime: this.checkDateTimeChange(
              eventStartDate,
              eventStartTime,
              eventAbout.StartDateTime,
              eventAbout.TimeZone
          )
              ? ""
              : this.getCompositeDateAndTime2(eventStartDate, eventStartTime),
          EndDateTime: this.checkDateTimeChange(
              eventEndDate,
              eventEndTime,
              eventAbout.EndDateTime,
              eventAbout.TimeZone
          )
              ? ""
              : this.getCompositeDateAndTime2(eventEndDate, eventEndTime),
          SmallImage:
              Object.keys(eventImages).length > 0
                  ? eventImages.small.split(",")[1]
                  : "",
          MediumImage:
              Object.keys(eventImages).length > 0
                  ? eventImages.medium.split(",")[1]
                  : "",
          LargeImage:
              Object.keys(eventImages).length > 0
                  ? eventImages.large.split(",")[1]
                  : "",
          EventType: this.state.dropdownItem,
          Parking: this.replcaeEmptyTagwithBreak(editorHtml2) || "",
          EventMap: eventMap.split(",")[1] || "",
          Status: "", // Not Have
          Add2:
              eventAbout.Add2 === eventAddress.addr ? "" : eventAddress.addr || "",
          PostalCode:
              eventAbout.PostalCode === eventAddress.postalCode ? "" : eventAddress.postalCode || "", // Don't Have
          EventCode: eventAbout.EventCode,
        })
        .then((val) => {
          localStorage.removeItem("isCloneEvent");

          if (val && val.status >= 200 && val.status <= 300) {
            this.GetEventFeeData(JSON.parse(val.data.results.Data).EventId);

            localStorage.setItem(
                "PaymentDescriptor",
                JSON.parse(val.data.results.Data).PaymentDescriptor
            );
            localStorage.setItem("isItCreateOrEditEvent", "Edit");
            history.push("FAQ");
          } else {
            this.setState({
              error: {
                show: true,
                message: val ? val.message : "Something went wrong",
              },
              submitButtonDisabled: false,
            });
          }
        })
        .catch((err) => {
          this.setState({
            error: { show: true, message: err.message },
            submitButtonDisabled: false,
          });
        })
        .finally(() => this.loaderState(false));
  };

  progressLoader = () => {
    const { loader } = this.state;

    return loader && <Loader />;
  };

  handleError = () => {
    this.setState({ error: { show: false, message: "" } });
  };

  // check if its a pdf url or not
  isPdf = (url) => {
    return url.match(/\.(pdf)$/i);
  };

  openPDf = (pdfString) => {
    if (this.isPdf(pdfString)) {
      window.open(pdfString, "_blank");
    } else {
      this.b6ç4toUrl(pdfString).then((val) => {
        window.open(val, "_blank");
      });
    }
  };

  b6ç4toUrl = async (base64Data) => {
    const r = await fetch(base64Data);
    const blob = await r.blob();
    return URL.createObjectURL(blob);
  };

  render() {
    const { eventStartDate, eventStartTime, editorHtml2 } = this.state;
    const { eventAbout } = this.props;
    const { classes } = this.props;
    const eventImagesExist =
        Object.keys(this.state.eventImages).length > 0 ? "exists" : "";
    const pageTitle = "About";
    const isCloneEvent = localStorage.getItem("isCloneEvent");
    const mandatoryFields = [
      this.state.eventTitle,
      this.state.eventAddress.toString(),
      this.state.isVirtual ? 'Online Event' : this.state.eventVenue,
      this.state.dropdownItem.toString(),
      this.state.editorState.getCurrentContent().getPlainText("\u0001"),
      eventImagesExist,
    ];
    const { About } = Events;

    if (this.state.isVirtual) {
      mandatoryFields.push(this.state.virtualEventDescription.getCurrentContent().getPlainText("\u0001")); // Add all virtual-specific fields here
    }

    const scheduleEmailDeleteModal = () => {
      return (
          <Modal
              open={this.state.clonealert}
              onClose={() => this.setState({ clonealert: false })}
          >
            <div className="delete_modal_wrapper">
              <div
                  style={{
                    width: "100px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100px",
                  }}
              >
                {/* <img
                src={deleteLabels.img}
                alt="Delete Icon"
                style={{ width: 30 }}
                // className={classes.deleteModalImg}
              /> */}
              </div>
              <p style={{ textAlign: "center", fontSize: 16 }}>
                {Text.publishYourEvent}
              </p>
              <div>
                <Button
                    // className={classes.deleteModalSaveAndCancelButton}
                    onClick={() => this.setState({ clonealert: false })}
                    style={{
                      color: "red",
                      fontSize: "1.5rem",
                      padding: "10px 25px",
                    }}
                >
                  {Text.Understood}
                </Button>
              </div>
            </div>
          </Modal>
      );
    };

    return (
        <React.Fragment>
          {scheduleEmailDeleteModal()}
          <ContentGrid
              content={
                <Grid
                    className={`${classes.eventDetailsAboutGrid} relative-wrapper-component `}
                >
                  <section className={classes.eventDetailsAboutMainWrapper}>
                    <EventHeader title={pageTitle} />
                    <div
                        className={classes.eventDetailsAboutWrapper}
                        style={{ pointerEvents: this.state.loader ? "none" : "auto" }}
                    >
                      {this.verticalLineWithCircles()}

                      <div className={classes.eventDetailsAboutFormWrapper}>
                        <Grid
                            className={
                              classes.eventDetailsAboutNameLocationVenueGridWrapper
                            }
                        >
                          {this.textFieldLongInputWithTitle(About.title)}
                          {this.textFieldSuggestionInputWithTitle(About.address)}
                          {this.state.isVirtual
                              ?
                            this.textFieldLongInputWithTitle(About.venue, true, 'Online Event')
                              :
                             this.textFieldLongInputWithTitle(About.venue)
                          }
                          {this.state.status !== "Published" &&
                          <span style={{ marginBottom: "20px", color: "#a338a4" }}>
                                            Is this a virtual event?{" "}
                            <span
                                style={{ color: "#a338a4", cursor: "pointer", textDecoration: 'underline' }}
                                onClick={this.toggleVirtual}
                            >
                              {this.state.isVirtual
                                ? "Oops, my event is in person - click here to hide virtual details"
                                : "Click Here"}
                            </span>
                          </span>
                          }
                          {this.state.isVirtual && (
                              <>
                                {this.virtualEventDescriptionTextField(
                                    About.virtualInvitationDetails,
                                    this.state.virtualEventDescription,
                                    this.onVirtualEventStateChange,
                                )}
                              </>
                          )}
                        </Grid>

                        <Grid
                            container
                            spacing={2}
                            className={
                              classes.eventDetailsAboutStartEndDateGridWrapper
                            }
                        >
                          <Grid item xs={12} sm={8} md={6}>
                        <span
                            className={
                              classes.eventDetailsAboutStartTimeInputLabel
                            }
                        >
                          {this.titleWithToolTip(
                              "Start",
                              "Fill in the start date and time of your event",
                              true
                          )}
                        </span>
                          </Grid>
                        </Grid>

                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={8} md={6}>
                            {this.textFieldTimeInput(
                                "Start",
                                "Fill in the start date and time of your event"
                            )}
                          </Grid>
                        </Grid>

                        <Grid
                            container
                            spacing={2}
                            className={
                              classes.eventDetailsAboutStartEndDateGridWrapper
                            }
                        >
                          <Grid item xs={12} sm={8} md={6}>
                        <span
                            className={classes.eventDetailsAboutEndTimeInputLabel}
                        >
                          {this.titleWithToolTip(
                              "End",
                              "Fill in the end date and time of your event",
                              true
                          )}
                        </span>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={8} md={6}>
                            {this.textFieldTimeInput(
                                "End",
                                "Fill in the end date and time of your event"
                            )}
                          </Grid>
                        </Grid>
                        <Grid className={classes.eventDetailsAboutLowerGridWrapper}>
                          {this.titleWithToolTip(
                              About.image,
                              "Upload a photo for your event’s landing page header",
                              true
                          )}
                          <div
                              style={{
                                position: "relative",
                                display: this.state.eventImages.small
                                    ? "block"
                                    : "none",
                              }}
                          >
                            <img
                                src={this.state.eventImages.small}
                                style={{
                                  width: "240px",
                                  height: "120px",
                                }}
                            />
                          </div>
                          <input
                              style={{ display: "none" }}
                              id="eventDetailsAboutImage"
                              multiple={true}
                              type="file"
                              onClick={(e) => {
                                e.target.value = null;
                              }}
                              onChange={(e) => this.uploadPicture(e, "image")}
                              accept=".png,.jpg,.jpeg"
                          />
                          <label
                              htmlFor="eventDetailsAboutImage"
                              style={{
                                width: "150px",
                                height: "45px",
                                marginBottom: "55px",
                                marginTop: "20px",
                              }}
                          >
                            <Button
                                className={classes.eventDetailsAboutUploadMap}
                                color={"primary"}
                                variant={"contained"}
                                component="span"
                            >
                              {Text.UploadImage}
                            </Button>
                          </label>
                          {this.titleWithToolTip(
                              About.pdf,
                              "Upload a PDF of your event’s map (if required)",
                              false
                          )}

                          <div
                              style={{
                                position: "relative",
                                marginTop: "10px",
                                fontStyle: "normal",
                                display: this.state.eventMap ? "flex" : "none",
                                alignItems: "center",
                              }}
                          >
                            <a onClick={(e) => this.openPDf(this.state.eventMap)}>
                              {Text.ViewMap}
                            </a>
                            <IconButton
                                color={"primary"}
                                variant="outlined"
                                onClick={() => this.removeEventMap()}
                                style={{
                                  marginLeft: "25px",
                                }}
                            >
                              <ClearIcon />
                            </IconButton>
                          </div>
                          <input
                              style={{ display: "none" }}
                              id="eventDetailsAboutEventMap"
                              multiple={true}
                              type="file"
                              onChange={(e) => this.uploadPDF(e)}
                              accept=".pdf,.doc"
                              onClick={(e) => {
                                e.target.value = null;
                              }}
                          />
                          <label
                              htmlFor="eventDetailsAboutEventMap"
                              style={{
                                width: "150px",
                                height: "0px",
                                marginBottom: "102px",
                                marginTop: "20px",
                              }}
                          >
                            <Button
                                className={classes.eventDetailsAboutUploadMap}
                                color={"primary"}
                                variant={"contained"}
                                component="span"
                            >
                              {Text.UploadMap}
                            </Button>
                          </label>
                          {this.selectEventTypeDropdown(About.type)}
                          {/* <span className={classes.eventDetailsAboutInputLabel}>
                       {this.titleWithToolTip("Event Description", false)}
                       </span> */}
                          {/* <textarea style={{height:300, width:"100%", resize: "none",
                        padding: 10,
                        whiteSpace:'pre-line',
                        margin:"10px 0px 20px 0px",
                        border: '1px solid #ced4da',
                        outline:'none',
                        overflowWrap: "break-word",}} />  */}
                          {this.largeTextField(
                              About.description,
                              this.state.editorState,
                              this.onEditorStateChange,
                              1
                          )}

                          {this.largeTextField(
                              About.parking,
                              this.state.editorState2,
                              this.onEditorStateChange1,
                              2
                          )}
                          {/* <span className={classes.eventDetailsAboutInputLabel}>
                       {this.titleWithToolTip("Parking", false)}
                       </span> */}
                          {/* <textarea style={{height:300, width:"100%", resize: "none",
                        padding: 10,
                        whiteSpace:'pre-line',
                        margin:"10px 0px 20px 0px",
                        border: '1px solid #ced4da',
                        outline:'none',
                        overflowWrap: "break-word",}} />  */}
                        </Grid>
                      </div>
                    </div>
                    <div className={classes.eventDetailsAboutButtonWrapper}>
                      <Button
                          className={classes.eventDetailsAboutMakeYourEventLiveButton}
                          variant={"contained"}
                          onClick={() => this.saveEvent()}
                          disabled={
                              mandatoryFields.some(
                                  (val) => val === "" || val.trim().length === 0
                              ) ||
                              this.state.submitButtonDisabled ||
                              this.state.isValidEndDateTime
                          }
                          sx={{
                            "&.Mui-disabled": {
                              background: "#eae",
                              color: "#c0c0c0",
                            },
                          }}
                      >
                        {Text.SaveAndContinue}
                      </Button>
                      <Modal
                          open={this.state.eventImageCropperModal}
                          onClose={() => this.handleCropImageModal(false)}
                      >
                        <div className={classes.eventImageCropperModal}>
                          <ImageCropper
                              imgSrc={this.state.eventImageToCrop}
                              setImage={this.setMultipleResizedImages}
                              cropModal={this.handleCropImageModal}
                          />
                        </div>
                      </Modal>
                      {this.progressLoader()}
                      <CreateEventErrorMessage
                          state={this.state.error}
                          close={this.handleError}
                      />
                    </div>
                  </section>
                </Grid>
              }
          />
          <h1
              style={this.createEventDetailsAboutEventCreatedSuccessMessageStyles()}
          >
            {Text.EventSaved}
          </h1>
        </React.Fragment>
    );
  }
}

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators(
      {
        addUpdateEventByEventUser,
        updateEventByEventUser,
        currentEventStatus,
        editEvenntByEventUser,
        updateDescriptor,
      },
      dispatch
  );
};

const mapStateToProps = (state) => {
  return {
    eventAbout: state.eventUserEvents.eventsAbout,
    eventId: state.eventUserEvents.eventId,
    eventDetails: state.eventUserEvents,
  };
};

export default connect(
    mapStateToProps,
    matchDispatchToProps
)(withStyles(styles)(EventDetailsAbout));
