import React, { useEffect, useState } from 'react'
import { UserIconsPic } from "Components/UBMeEvents/Components_UBMeEvents/Navbar/Alphabets/UserIconsPic";
import './EventActivity.css'
import { CircularProgress } from "@material-ui/core";

import { LazyLoadImage } from "react-lazy-load-image-component";
import UserActivityForm from './UserActivityForm'

import TablePagination from "@mui/material/TablePagination";
import moment from "moment";
import { default as momentTz } from "moment-timezone";
import storeFolder from "../../../../../store";
import { ws } from 'App';
import UserActivity from './UserActivity';


const EventActivity = ({

}) => {
  let storeData = storeFolder.getState();

  const [activities, setActivities] = useState(storeData.messageReducer.eventExperienceEventActivities)
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState("");
  const CurrentEventId = JSON.parse(localStorage.getItem("CurrentEventId"));
  // useEffect(() => {
  //   return () => {
  //     dispatch(clearEventExperienceChat())
  //   }
  // }, [])
  const handleChangePage = (event, newPage) => {
    setLoading(true)
    setPage(newPage);
    setActivities(storeData.messageReducer.eventExperienceEventActivities)
    ws.json({
      "pageIndex": newPage + 1,
      "pageSize": 5,
      "eventId": CurrentEventId,
      "type": "getEventActivities"
    });
    setTimeout(() => {
      setLoading(false)
    }, [1000])
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };

  const timeUTCtoLocal = (date, timezone) => {
    var local = momentTz
      .tz(moment.utc(date), "MM/DD/YYYY hh:mm:ss a", timezone)
      .format("MM/DD/YYYY hh:mm:ss a");

    const Time = moment(local, "MM/DD/YYYY hh:mm:ss a");
    return `${moment(Time).format("MMM DD, YYYY")} at ${moment(Time).format(
      "hh:mm A"
    )}`;
  };
  return (
    <div className="eventActivity">
      <div>
        <UserActivity/>
      </div>
      {/* <div>
        <div className='event_activity_base' />
        <div className='event_activity_notification'>
          <p>Send Notification/Alert</p>
          <span>(Notification will be sent to all event app attendees)</span>
        </div>
      </div>
      <UserActivityForm /> */}
    </div>
  )
}
export default EventActivity