import React from 'react'
import "./index.css"
const PrivactAndPolicy = () => {
  return (
    <div className='privacy_and_policy' ><div >
    <p style={{textAlign:"center"}}>
    UBMe , Inc.
    </p>
    <p style={{textAlign:"center"}}>
        <span >Privacy Policy</span>
    </p>
</div>
<span >
    <br/>
</span>
<div>

    <p style={{textAlign:"center"}}>

        <span style={{textAlign:"center"}} >
            Last
            modified: January 24, 2017
        </span>
    </p>


    <p>
        <a name="co_anchor_a598115_1"></a><b>
            <u>
                <span >Introduction</span>
            </u>
        </b><span >&nbsp;&nbsp;</span>
    </p>

    <p>
        <span >
            &nbsp;&nbsp;&nbsp;&nbsp;UBMe<span >
               
            </span><span ></span>,
            Inc. (”<b>Company</b>” or “<b>We</b>”) respects your privacy and is committed
            to protecting it through our compliance with this policy. This policy
            describes:
        </span>
    </p>

    <p>
        
    </p>

    <p >
        <span >
            <span >
                •<span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
            </span>
        </span><span >
            The types of information we may collect or that you may provide
            when you purchase, download, install, register with, access, or use the UBMe
            (the “<b>App</b>”) or interact with our
        </span><span >
            website and domains, including at {window.location.hostname}
            and all of its webpages, subdomains, country level domain variants and subparts
            of those websites (collectively, our “
        </span><span >Site”), or download or use</span><span >
            any
            software that we provide to you that allows you to access either our Site or
            App (collectively, the “Services”)
        </span><span >.</span>
    </p>

    <p >
        <span >
            <span >
                •<span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
            </span>
        </span><span >
            Our practices for collecting, using, maintaining, protecting, and
            disclosing that information.
        </span>
    </p>

    <p >
        <span >
            &nbsp;&nbsp;&nbsp;&nbsp;This
            policy applies only to information we collect in this App and in email, text,
            and other electronic communications sent through or in connection with our
            Services.
        </span>
    </p>

    <p>
        
    </p>

    <p>
        <span >
            &nbsp;&nbsp;&nbsp;&nbsp;This policy DOES NOT apply to information
            that:
        </span>
    </p>

    <p>
        
    </p>

    <p >
        <span >
            <span >
                •<span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
            </span>
        </span><span >
            We collect offline or on any other Company apps or websites,
            including websites you may access through this App.
        </span>
    </p>

    <p >
        <span >
            <span >
                •<span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
            </span>
        </span><span >
            You provide to or is collected by any third party.<span ></span>See Third-Party Information Collection below.
        </span>
    </p>

    <p >
        <span >
            &nbsp;&nbsp;&nbsp;&nbsp;Third
            parties may have their own privacy policies, which we encourage you to read
            before providing information on or through them.
        </span>
    </p>

    <p>
        
    </p>

    <p>
        <span >
            &nbsp;&nbsp;&nbsp;&nbsp;Please read this policy carefully to
            understand our policies and practices regarding your information and how we
            will treat it. If you do not agree with our policies and practices, do not
            download, register with, or use this App. By downloading, registering with, or
            using this App, you agree to this privacy policy. This policy may change from
            time to time. Your continued use of this App after we make changes is deemed to
            be acceptance of those changes, so please check the policy periodically for
            updates.
        </span>
    </p>

    <p>
        
    </p>

    <p>
        <a name="co_anchor_a1019988_1"></a><b>
            <u>
                <span >
                    Children
                    Under the Age of 13
                </span>
            </u>
        </b><span>&nbsp;&nbsp;</span>
    </p>

    <p>
        <span >
            &nbsp;&nbsp;&nbsp;&nbsp;The App is not intended for children under
            13 years of age, and we do not knowingly collect personal information from
            children under 13. If we learn we have collected or received personal
            information from a child under 13 without verification of parental consent, we
            will delete that information. If you believe we might have any information from
            or about a child under 13, please contact us at support@ubme.com
        </span>
    </p>

    <p>
        
    </p>

    <p>
        <a name="co_anchor_a216371_1"></a><b>
            <u>
                <span >
                    Information
                    We Collect and How We Collect It
                </span>
            </u>
        </b><span >&nbsp;&nbsp;</span>
    </p>

    <p>
        <span >
            &nbsp;&nbsp;&nbsp;&nbsp;We collect information from and about
            users of our App:
        </span>
    </p>

    <p>
        
    </p>

    <p >
        <span >
            <span >
                •<span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
            </span>
        </span><span >Directly from you when you provide it to us.</span>
    </p>

    <p>
        <span >
            <span >
                •<span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
            </span>
        </span><span >Automatically when you use the App.</span>
    </p>

   

    <p >
        <span >
            &nbsp;&nbsp;&nbsp;&nbsp;<b>
                <u>
                    Information
                    You Provide to Us
                </u>
            </b>.
        </span>
    </p>

    <p>
        <span >&nbsp;</span>
    </p>

    <p>
        <span >
            &nbsp;&nbsp;&nbsp;&nbsp;When you download, register with, or use
            this App, we may ask you provide information:
        </span>
    </p>

    <p>
        
    </p>

    <p>
        <span >
            <span >
                •<span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
            </span>
        </span><span >
            By which you may be personally identified, such as name, postal
            address, email address, telephone number, or any other identifier by which you may
            be identified or contacted online or offline (”<b>personal information</b>”).
        </span>
    </p>

    <p >
        <span >
            <span >
                •<span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
            </span>
        </span><span >That is about you but individually does not identify you.</span>
    </p>

    <p>
        <span >&nbsp;&nbsp;&nbsp;&nbsp;This information includes:</span>
    </p>

    <p>
        
    </p>

    <p >
        <span >
            <span >
                •<span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
            </span>
        </span><span >
            Information that you provide by filling in forms in the App. This
            includes information provided at the time of registering to use the App,
            subscribing to our service, posting material, and requesting further services.
            We may also ask you for information when you enter a contest or promotion
            sponsored by us and when you report a problem with the App.
        </span>
    </p>

    <p >
        <span >
            <span >
                •<span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
            </span>
        </span><span >
            Records and copies of your correspondence (including email
            addresses and phone numbers), if you contact us.
        </span>
    </p>

    <p >
        <span >
            <span >
                •<span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
            </span>
        </span><span >
            Your responses to surveys that we might ask you to complete for
            research purposes.
        </span>
    </p>

    <p >
        <span >
            <span >
                •<span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
            </span>
        </span><span >Your search queries on the App.</span>
    </p>
    
    <p >
        <span >
            <span >
                •<span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
            </span>
        </span><span >Your contact list to help you connect with your friends on the App.</span>
    </p>
    
    <p >
        <span >
            <span >
                •<span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
            </span>
        </span><span >Access to your photos, allowing you to share photos from your library and save photos to your camera roll.</span>
    </p>

    <p >
        <span >
            &nbsp;&nbsp;&nbsp;&nbsp;You
            may provide information to be published or displayed (”<b>Posted</b>”) to
            specific users or on public areas of the App (collectively, “<b>
                User
                Contributions
            </b>”). Your User Contributions are Posted and transmitted to
            others at your own risk. Although you can manage your privacy settings and limit
            the information that others can see by leaving profile information incomplete,
            please be aware that no security measures are perfect or impenetrable.
            Additionally, we cannot control the actions of third parties with whom you may
            choose to share your User Contributions. Therefore, we cannot and do not
            guarantee that your User Contributions will not be viewed by unauthorized
            persons.
        </span>
    </p>

    <p>
        
    </p>

    <p>
        <a name="co_anchor_a683129_1"></a>
    </p>

    <p>
        <span >
            &nbsp;&nbsp;&nbsp;&nbsp;<b>
                <u>
                    Automatic
                    Information Collection and Tracking
                </u>
            </b>.
        </span>
    </p>

    <p>
        <span >&nbsp;</span>
    </p>

    <p>
        <span >
            &nbsp;&nbsp;&nbsp;&nbsp;When you download, access, and use the
            App, it may use technology to automatically collect:
        </span>
    </p>

    <p>
        
    </p>

    <p >
        <span >
            <span >
                •<span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
            </span>
        </span><b>
            <span>Usage Details.</span>
        </b><span >
            When
            you access and use the App, we may automatically collect certain details of
            your access to and use of the App, including traffic data, location data, logs,
            and other communication data and the resources that you access and use on or
            through the App.
        </span>
    </p>

    <p >
        <span >
            <span >
                •<span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
            </span>
        </span><b>
            <span >Device Information.</span>
        </b><span >
            We
            may collect information about your mobile device and internet connection,
            including the device’s unique device identifier, IP address, operating system,
            browser type, mobile network information, and the device’s telephone number.
        </span>
    </p>

    <p >
        <span >
            <span >
                •<span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
            </span>
        </span><b>
            <span>Stored Information and Files.</span>
        </b><span >
            The
            App also may access metadata and other information associated with other files
            stored on your device. This may include, for example, photographs, audio, and
            video clips, personal contacts, and address book information.
        </span>
    </p>

    <p >
        <span >
            <span >
                •<span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
            </span>
        </span><b>
            <span>Location Information.</span>
        </b><span >
            This
            App collects real-time information about the location of your device.
        </span>
    </p>

    <p >
        <span >
            &nbsp;&nbsp;&nbsp;&nbsp;If
            you do not want us to collect this information do not download the App or
            delete it from your device. For more information, see Choices about How We Use
            and Disclose Your Information below. Note, however, that opting out of the
            App’s collection of location information will cause its location-based features
            to be disabled.
        </span>
    </p>

    <p>
        
    </p>

    <p>
        <span >
            &nbsp;&nbsp;&nbsp;&nbsp;[We also may use these technologies to
            collect information about your activities over time and across third-party
            websites, apps, or other online services (behavioral tracking). We do not
            currently respond to do-not-track signals that may be sent from your device.
        </span>
    </p>

    <p>
        <span >
            <span >   </span>We may retain your personal
            information as long as you are registered to use the Services. You may close
            your account by emailing us at support@ubme.com. However, we may retain personal
            information for an additional period as is permitted or required under
            applicable laws. Even if we delete your personal information, it may persist on
            backup or archival media for an additional period of time for legal, tax or
            regulatory reasons or for legitimate and lawful business purposes.
        </span>
    </p>

    <p>
        
    </p>

    <p>
        <a name="co_anchor_a813824_1"></a>
    </p>

    <p>
        <span >
            &nbsp;&nbsp;&nbsp;&nbsp;<b>
                <u>
                    Information Collection and Tracking
                    Technologies
                </u>
            </b>. The technologies we use for automatic information
            collection may include:
        </span>
    </p>

    <p>
        
    </p>

    <p >
        <span >
            <span >
                •<span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
            </span>
        </span><b>
            <span >Cookies (or mobile cookies).</span>
        </b><span >
            A
            cookie is a small file placed on your smartphone. It may be possible to refuse
            to accept mobile cookies by activating the appropriate setting on your
            smartphone. However, if you select this setting you may be unable to access
            certain parts of our App or enjoy some of our Services.
        </span>
    </p>

    <p >
        <span >
            <span >
                •<span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
            </span>
        </span><b>
            <span >Web Beacons.</span>
        </b><span >
            Pages
            of the App and our emails may contain small electronic files known as web
            beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs)
            that permit the Company, for example, to count users who have visited those
            pages or opened an email and for other related app statistics (for example,
            recording the popularity of certain app content and verifying system and server
            integrity).
        </span>
    </p>

    <p>
        <span >
            <span >
                •<span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
            </span>
        </span>
    </p>

    <p>
        <a name="co_anchor_a314576_1"></a><b>
            <u>
                <span >
                    Third-Party
                    Information Collection
                </span>
            </u>
        </b><span >&nbsp;&nbsp;</span>
    </p>

    <p>
        <span >
            &nbsp;&nbsp;&nbsp;&nbsp;We work with third-party providers to help
            us operate, provide, improve, understand, customize, support, and market our App.
            For example, we work with companies to distribute our apps, provide our
            infrastructure, delivery, and other systems, supply map and places information,
            help us understand how people use our Services, and market our Services.<span ></span>Thus, when you use the App or its content, you
            will encounter third-party providers, some of which may use automatic
            information collection technologies to collect information about you or your
            device. These third parties may include, but are not limited to, the following:
        </span>
    </p>

    <p>
        
    </p>

    <p >
        <span >
            <span >
                •<span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
            </span>
        </span><span >Advertisers, ad networks, and ad servers.</span>
    </p>

    <p >
        <span >
            <span >
                •<span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
            </span>
        </span><span >Analytics companies.</span>
    </p>

    <p >
        <span >
            <span >
                •<span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
            </span>
        </span><span >Your mobile device manufacturer.</span>
    </p>

    <p >
        <span >
            <span >
                •<span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
            </span>
        </span><span >Your mobile service provider.</span>
    </p>

    <p >
        <span >
            <span >
                •<span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
            </span>
        </span><span >The app store where you downloaded the App.</span>
    </p>

    <p >
        <span >
            &nbsp;&nbsp;&nbsp;&nbsp;You
            can connect your App account to your accounts on third party services like
            Facebook, in which case we may collect, use, disclose, transfer and store or retain
            information relating to your account with such third party services in
            accordance with this privacy policy. For example, if you connect with Facebook,
            we may store your Facebook id, first name, last name, email, location, friends
            list and profile picture and use them to connect with your Facebook account to
            provide certain functionality on the App, like recommending events, friends, or
            groups that your Facebook friends are interested in and sharing the events,
            friends, or groups you are interested in, with certain groups of people like
            your Facebook friends.
        </span>
    </p>

    <p >
        <span >
            These third parties may use
            tracking technologies to collect information about you when you use this app.
            The information they collect may be associated with your personal information
            or they may collect information, including personal information, about your
            online activities over time and across different websites, apps, and other
            online services websites. They may use this information to provide you with
            interest-based (behavioral) advertising or other targeted content.
        </span>
    </p>

    <p>
        
    </p>

    <p>
        <span >
            &nbsp;&nbsp;&nbsp;&nbsp;We do not control these third parties’
            tracking technologies or how they may be used. If you have any questions about
            an advertisement or other targeted content, you should contact the responsible
            provider directly.&nbsp;
        </span>
    </p>

    <p>
        <a name="co_anchor_a183824_1"></a><b>
            <u>
                <span >
                    How We
                    Use Your Information
                </span>
            </u>
        </b><span >&nbsp;&nbsp;</span>
    </p>

    <p>
        <span >
            &nbsp;&nbsp;&nbsp;&nbsp;We use information that we collect about
            you or that you provide to us, including any personal information, to:
        </span>
    </p>

    <p>
        
    </p>

    <p >
        <span >
            <span >
                •<span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
            </span>
        </span><span >
            Provide you with the App and its contents, and any other
            information, products or Services that you request from us or use.
        </span>
    </p>

    <p >
        <span >
            <span >
                •<span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
            </span>
        </span><span >Fulfill any other purpose for which you provide it.</span>
    </p>

    <p>
        <span >
            <span >
                •<span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
            </span>
        </span><span >
            Give you notices about your account, including expiration and
            renewal notices.
        </span>
    </p>

    <p>
        <span >
            <span >
                •<span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
            </span>
        </span><span >
            Carry out our obligations and enforce our rights arising from any
            contracts entered into between you and us, including for billing and
            collection.
        </span>
    </p>

    <p>
        <span >
            <span >
                •<span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
            </span>
        </span><span >
            Notify you when App updates are available, and of changes to any
            products or services we offer or provide though it.
        </span>
    </p>

    <p>
        <span >
            <span >
                •<span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
            </span>
        </span>
            Help you connect with your community, local events, and other 
    </p>

    <p>
        <span >
            &nbsp;&nbsp;&nbsp;&nbsp;The usage information we collect helps us
            to improve our App and to deliver a better and more personalized experience by
            enabling us to:
        </span>
    </p>

    <p>
        
    </p>

    <p>
        <span >
            <span >
                •<span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
            </span>
        </span><span >Estimate our audience size and usage patterns.</span>
    </p>

    <p>
        <span >
            <span >
                •<span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
            </span>
        </span><span >
            Store information about your preferences, allowing us to customize
            our App according to your individual interests.
        </span>
    </p>

    <p>
        <span >
            <span >
                •<span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
            </span>
        </span><span >Speed up your searches.</span>
    </p>

    <p>
        <span >
            <span >
                •<span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
            </span>
        </span><span >Recognize you when you use the App.</span>
    </p>

    <p >
        <span >
            &nbsp;&nbsp;&nbsp;&nbsp;We
            may request access to your current location – provided by your mobile device
            using GPS or similar technologies – so that we can provide content that is more
            suitable to your location. For example, we may use information about the
            location of your device for certain messaging features or share information
            about the location of your device with advertisers to tailor the advertising
            you see to your interests or present you with offers from the businesses around
            you. You are free to accept or block access to your location information. You
            can switch off access to your location at any time through your device settings,
            although denying access to your current location may inhibit some of our
            Services.
        </span>
    </p>

    <p>
        
    </p>

    <p>
        <span >
            &nbsp;&nbsp;&nbsp;&nbsp;Advertisements.<span ></span>We may use the information we collect to
            display advertisements to our advertisers’ target audiences. Even though we do
            not disclose your personal information for these purposes without your consent,
            if you click on or otherwise interact with an advertisement, the advertiser may
            assume that you meet its target criteria.
        </span>
    </p>

    <p>
        
    </p>

    <p>
        <a name="co_anchor_a998196_1"></a><b>
            <u>
                <span >
                    Disclosure
                    of Your Information
                </span>
            </u>
        </b><span >&nbsp;&nbsp;</span>
    </p>

    <p>
        <span >
            &nbsp;&nbsp;&nbsp;&nbsp;We may disclose aggregated information
            about our users and information that does not identify any individual or
            device, without restriction.
        </span>
    </p>

    <p>
        
    </p>

    <p>
        <span >
            &nbsp;&nbsp;&nbsp;&nbsp;In addition, we may disclose personal
            information that we collect or you provide:
        </span>
    </p>

    <p>
        
    </p>

    <p>
        <span >
            <span >
                •<span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
            </span>
        </span><span >To our subsidiaries and affiliates.</span>
    </p>

    <p>
        <span >
            <span >
                •<span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
            </span>
        </span><span >
            To contractors, service providers, and other third parties we use
            to support our business.
        </span>
    </p>

    <p>
        <span >
            <span >
                •<span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
            </span>
        </span><span >
            To a buyer or other successor in the event of a merger,
            divestiture, restructuring, reorganization, dissolution, or other sale or
            transfer of some or all of the Company’s assets, whether as a going concern or
            as part of bankruptcy, liquidation, or similar proceeding, in which personal
            information held by the Company about our App users is among the assets
            transferred.
        </span>
    </p>

    <p>
        <span >
            <span >
                •<span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
            </span>
        </span>
    </p>

    <p>
        <span >
            <span >
                •<span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
            </span>
        </span><span >
            To fulfill the purpose for which you provide it. For example, if
            you give us an email address to use the “share” feature of our App, we will
            transmit the contents of that email and your email address to the recipients.
        </span>
    </p>

    <p>
        <span >
            <span >
                •<span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
            </span>
        </span>
            For any other purpose disclosed by us when you provid 
    </p>

    <p>
        <span >
            <span >
                •<span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
            </span>
        </span><span >To verify your identity.</span>
    </p>

    <p>
        <span >
            <span >
                •<span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
            </span>
        </span><span >
            To connect you with your App contacts and others as enabled by our
            Service.
        </span>
    </p>

    <p>
        <span >
            <span >
                •<span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
            </span>
        </span><span >
            To share your profile with App contacts and others on the Service
            in accordance with your account settings.
        </span>
    </p>

    <p>
        <span >
            <span >
                •<span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
            </span>
        </span><span >
            To allow you use of certain features of our Services that may
            occasionally be offered (such as premium services, games, greetings, animations,
            etc.) and to help customize your user experience. 
        </span>
    </p>

    <p>
        <span >
            <span >
                •<span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
            </span>
        </span><span >
            To contact you via email, SMS, or otherwise for the purpose of
            informing you about new products and services or promotions offered by us.<span ></span>You can always opt-out of such emails by
            clicking the unsubscribe or deleting your account.
        </span>
    </p>

    <p>
        <span >
            <span >
                •<span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
            </span>
        </span>
            For internal operations, including troubleshooting problems, data
            analysis, testing, research improvements to the Service, detecting and
            protecting against error, fraud, or othe 
    </p>

    <p>
        <span >
            <span >
                •<span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
            </span>
        </span><span >
            To comply with any court order, law, or legal process, including
            to respond to any government or regulatory request.
        </span>
    </p>

    <p>
        <span >
            <span >
                •<span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
            </span>
        </span><span >
            To enforce our rights arising from any contracts entered into
            between you and us, including an App EULA, terms of use, or for billing and
            collection.
        </span>
    </p>

    <p>
        <span >
            <span >
                •<span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
            </span>
        </span><span >
            If we believe disclosure is necessary or appropriate to protect
            the rights, property, or safety of the Company, our customers, or others. This
            includes exchanging information with other companies and organizations for the
            purposes of fraud protection and credit risk reduction.
        </span>
    </p>

    <p>
        <a name="co_anchor_a741158_1"></a><b>
            <u>
                <span >
                    Your
                    Choices About Our Collection, Use, and Disclosure of Your Information
                </span>
            </u>
        </b><span >&nbsp;&nbsp;</span>
    </p>

    <p>
        <span >
            &nbsp;&nbsp;&nbsp;&nbsp;We strive to provide you with choices
            regarding the personal information you provide to us. This section describes
            mechanisms we provide for you to control certain uses and disclosures of over
            your information.
        </span>
    </p>

    <p>
        
    </p>

    <p>
        <span >
            <span >
                •<span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
            </span>
        </span><b>
            <span >Tracking Technologies.</span>
        </b><span >
            You
            can set your browser to refuse all or some browser cookies, or to alert you
            when cookies are being sent.
        </span>
    </p>

    <p>
        <span >
            <span >
                •<span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
            </span>
        </span><b>
            <span >Location Information.</span>
        </b><span >
            You
            can choose whether or not to allow the App to collect and use real-time
            information about your device’s location by switching off access to your
            location at any time through your device settings. If you block the use of
            location information, however, some parts of the App may then be inaccessible
            or fail to function properly.
        </span>
    </p>

    <p>
        <span >
            <span >
                •<span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
            </span>
        </span><b>
            <span >Promotion by the Company.</span>
        </b><span >
            If
            you do not want us to use your email address or other contact information to
            promote our own or third parties’ products or services, you can opt-out by
            stating your request to support@ubme.com. 
        </span>
    </p>

    <p>
        <span >
            <span >
                •<span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
            </span>
        </span><b>
            <span >Targeted Advertising by the Company.</span>
        </b><span >
            If
            you do not want us to use information that we collect or that you provide to us
            to deliver advertisements according to our advertisers’ target-audience
            preferences, you can opt-out by stating your request to support@ubme.com. 
        </span>
    </p>

    <p >
        <span >
            &nbsp;&nbsp;&nbsp;&nbsp;We
            do not control third parties’ collection or use of your information to serve
            interest-based advertising. However these third parties may provide you with
            ways to choose not to have your information collected or used in this way. You
            can opt out of receiving targeted ads from members of the Network Advertising
            Initiative (”<b>NAI</b>”) on the NAI’s <a href="http://www.networkadvertising.org/managing/opt_out.asp">
                <span style={{color:"black", textDecoration:"none", textUnderline:"none"}}>website</span>
            </a>.
        </span>
    </p>

    <p>
        
    </p>

    <p>
        <a name="co_anchor_a87692_1"></a><b>
            <u>
                <span >
                    Accessing
                    and Correcting Your Personal Information
                </span>
            </u>
        </b><span >&nbsp;&nbsp;</span>
    </p>

    <p>
        <span >
            &nbsp;&nbsp;&nbsp;&nbsp;You can review and change your personal
            information by logging into the App and visiting your account profile page.
        </span>
    </p>

    <p>
        
    </p>

    <p>
        <span >
            &nbsp;&nbsp;&nbsp;&nbsp;You may also send us an email at
            support@ubme.com to request access to, correct, or delete any personal
            information that you have provided to us. We cannot delete your personal
            information except by also deleting your user account. We may not accommodate a
            request to change information if we believe the change would violate any law or
            legal requirement or cause the information to be incorrect.
        </span>
    </p>

    <p>
        
    </p>

    <p>
        <span >
            &nbsp;&nbsp;&nbsp;&nbsp;If you delete your User Contributions from
            the App, copies of your User Contributions may remain viewable in cached and
            archived pages, exist in other users’ pages, or might have been copied or
            stored by other App users. Proper access and use of information provided on the
            App, including User Contributions, is governed by our terms of use.
        </span>
    </p>

    <p>
        
    </p>

    <p>
        <a name="co_anchor_a820658_1"></a><b>
            <u>
                <span >
                    Your
                    California Privacy Rights
                </span>
            </u>
        </b><span >&nbsp;&nbsp;</span>
    </p>

    <p>
        <span >
            &nbsp;<span >   </span>California Civil
            Code Section 1798.83 permits users of our App that are California residents to
            request certain information regarding our disclosure of personal information to
            third parties for their direct marketing purposes. To make such a request,
            please send an email to support@ubme.com or write us at: 543 West 3<sup>rd</sup>
            Street 1A, Bethlehem PA 18015.
        </span>
    </p>

    <p>
        
    </p>

    <p>
        <span >
            <span >   </span>California law further requires
            that operators of websites and online services disclose how they respond to a
            Do Not Track signal and whether other third parties may collect personally
            identifiable information about an individual's online activities from their
            site or service.
        </span>
    </p>

    <p>
        
    </p>

    <p>
        <span >
            <span >   </span>Some browsers have
            incorporated “Do Not Track” features. Most of these features, when turned on,
            send a signal or preference to the website or online service that a user
            visits, indicating that the user does not wish to be tracked. Because there is
            not yet a common understanding of how to interpret Do Not Track signals, we do
            not currently respond to Do Not Track signals.
        </span>
    </p>

    <p>
        
    </p>

    <p>
        <span >
            <span >   </span>For more information
            about how to opt out of having your web browsing information used for
            behavioral advertising purposes, please visit www.aboutads.info/choices.
        </span>
    </p>

    <p>
        
    </p>

    <p>
        <a name="co_anchor_a539528_1"></a><b>
            <u>
                <span >
                    Data
                    Security
                </span>
            </u>
        </b><span >&nbsp;&nbsp;</span>
    </p>

    <p>
        <span >
            &nbsp;&nbsp;&nbsp;&nbsp;We have implemented measures designed to
            secure your personal information from accidental loss and from unauthorized
            access, use, alteration, and disclosure. All information you provide to us is
            stored on our secure servers behind firewalls. Any payment transactions<span ></span>will be encrypted using SSL technology.
        </span>
    </p>

    <p>
        
    </p>

    <p>
        <span >
            &nbsp;&nbsp;&nbsp;&nbsp;The safety and security of your
            information also depends on you. Where we have given you (or where you have
            chosen) a password for access to certain parts of our App, you are responsible
            for keeping this password confidential. We ask you not to share your password
            with anyone. We urge you to be careful about giving out information in public
            areas of the App like message boards. The information you share in public areas
            may be viewed by any user of the App.
        </span>
    </p>

    <p>
        
    </p>

    <p>
        <span >
            &nbsp;&nbsp;&nbsp;&nbsp;Unfortunately, the transmission of
            information via the internet and mobile platforms is not completely secure.
            Although we do our best to protect your personal information, we cannot
            guarantee the security of your personal information transmitted through our
            App. Any transmission of personal information is at your own risk. We are not
            responsible for circumvention of any privacy settings or security measures we
            provide.
        </span>
    </p>

    <p>
        
    </p>

    <p>
        <a name="co_anchor_a286592_1"></a><b>
            <u>
                <span >
                    Changes
                    to Our Privacy Policy
                </span>
            </u>
        </b><span >&nbsp;&nbsp;</span>
    </p>

    <p>
        <span >
            &nbsp;&nbsp;&nbsp;&nbsp;We may update our privacy policy from time
            to time. If we make material changes to how we treat our users’ personal
            information, we will post the new privacy policy on this page with a notice
            that the privacy policy has been updated and notify you by email using the
            primary email address you provide us or an in-App alert the first time you use
            the App after we make the change.
        </span>
    </p>

    <p>
        
    </p>

    <p>
        <span >
            &nbsp;&nbsp;&nbsp;&nbsp;The date the privacy policy was last
            revised is identified at the top of the page. You are responsible for ensuring
            we have an up-to-date active and deliverable email address for you and for
            periodically visiting this privacy policy to check for any changes.
        </span>
    </p>

    <p>
        
    </p>

    <p>
        <a name="co_anchor_a955470_1"></a><b>
            <u>
                <span >
                    Contact
                    Information
                </span>
            </u>
        </b><span >&nbsp;&nbsp;</span>
    </p>

    <p>
        <span >
            &nbsp;&nbsp;&nbsp;&nbsp;To ask questions or comment about this
            privacy policy and our privacy practices, contact us at:
        </span>
    </p>

    <p>
        
    </p>

    <p>
        <span >support@ubme.com&nbsp;</span>
    </p>

    <p>
        <span >&nbsp;&nbsp;&nbsp;&nbsp;</span>
    </p>

    <p>
        
    </p>

    <p >
        
    </p>

</div></div>
  )
}

export default PrivactAndPolicy;