var audio = new Audio();

export function audioFunc(mute, loop, sound) {
    var source = sound;
    // use the constructor in JavaScript, just easier that way
    audio.load();
    audio.preload = "auto";                      // intend to play through
    audio.loop = loop;
    audio.muted = mute;
    audio.volume = 1;
    audio.autoplay = true; // add this
    audio.src = source;

    audio.play().catch(reason =>
        {
           console.log("PLAY ERROR " + reason)
        }
    )
}


