import React, { Component } from "react";
import { Button, Chip, FormControl, Grid, TextField } from "@material-ui/core";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import PhoneInput from "react-phone-input-2";
import "react-multi-email/style.css";
import "./createEventPromotion.css";
import "react-phone-input-2/lib/style.css";
class CreateEventPromoteEventFlyers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emails: [],
      phoneNumbers: [],
      value: undefined,
      emailError: null,
      phoneNumbersError: false,
    };
  }

  handleKeyDown = (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();
      this.setState({ phoneNumbersError: false });

      var value = "+1" + this.state.value.trim();
      if (value && value.length >= 10 && value.length <= 16) {
        this.setState({
          phoneNumbers: [...this.state.phoneNumbers, this.state.value],
          value: "",
        });
      } else {
        this.setState({ phoneNumbersError: true });
      }
    } else if (["Backspace"].includes(evt.key)) {
      if (this.state.value == "" && this.state.phoneNumbers.length > 0) {
        this.handleDelete(this.state.phoneNumbers.length - 1);
      }
    }
  };

  handleChange = (evt) => {
    this.setState({
      value: evt,
    });
  };

  handleDelete = (idx) => {
    this.setState({
      phoneNumbers: this.state.phoneNumbers.filter((i, index) => index !== idx),
    });
  };

  render() {
    const { emails, phoneNumbers, phoneNumbersError } = this.state;

    return (
      <Grid className={"eventDetailsPromoteGrid"}>
        <section>
          <div className={"eventDetailsPromoteWrapper"} container>
            <div className={"eventDetailsPromoteFormWrapper"}>
              <div class={"row"}>
                <div class={"col-25"}>
                  <span className={"eventDetailsPromoteInputLabel"}>
                    {"Your Email:"}
                  </span>
                </div>
                <div class="col-75">
                  <div class="contentwidth">
                    <ReactMultiEmail
                      placeholder="Enter Emails"
                      emails={emails}
                      onChange={(_emails) => {
                        this.setState({ emails: _emails });
                      }}
                      validateEmail={(email) => {
                        return isEmail(email); // return boolean
                      }}
                      style={{ width: "100%" }}
                      getLabel={(email, index, removeEmail) => {
                        return (
                          <Chip
                            label={email}
                            onDelete={() => removeEmail(index)}
                            variant="outlined"
                            style={{
                              margin: "2px",
                              fontSize: "11.5px",
                            }}
                          />
                        );
                      }}
                      className={"eventDetailsPromoteLongInputField"}
                    />
                    <p className={"createEventPromotePreviewText"}>
                      {emails.join("   ") || "email@example.com"}
                    </p>
                  </div>
                </div>
              </div>
              <div class={"row"}>
                <div class={"col-25"}>
                  <p className={"eventDetailsPromoteInputLabel"}>
                    {"Your Phone Number:"}
                  </p>
                </div>
                <div class={"col-75"}>
                  <div class="contentwidth">
                    <div
                      style={{
                        border: phoneNumbersError
                          ? "1px solid tomato"
                          : "1px solid gray",
                        boxSizing: "border-box",
                        resize: "vertical",
                      }}
                    >
                      {phoneNumbers.length > 0 &&
                        phoneNumbers.map((item, index) => (
                          <Chip
                            label={item}
                            onDelete={() => this.handleDelete(index)}
                            // color="primary"
                            variant="outlined"
                            style={{
                              margin: "2px",
                              fontSize: "11.5px",
                            }}
                          />
                        ))}

                      <PhoneInput
                        value={this.state.value}
                        placeholder="Type phone numbers."
                        onKeyDown={this.handleKeyDown}
                        onChange={(e) => this.handleChange(e)}
                        country={"us"}
                        disableDropdown={true}
                        onlyCountries={["us"]}
                        disableCountryCode={true}
                        prefix={"+1"}
                        dropdownStyle={{
                          border: "none",
                          outline: "none !important",
                          outlineWidth: "0 !important",
                          boxShadow: "none",
                        }}
                        inputStyle={{
                          //   padding: "5px",
                          border: "none",
                          outline: "none !important",
                          outlineWidth: "0 !important",
                          boxShadow: "none",
                          width: "100%",
                        }}
                      />
                    </div>
                    <p className={"createEventPromotePreviewText"}>
                      {phoneNumbers.join("  ") || "111-111-1111"}
                    </p>
                  </div>
                </div>
              </div>
              <div class={"row"}>
                <div class={"col-25"}>
                  <></>
                </div>
                <div class={"col-75"}>
                  <Button
                    variant={"contained"}
                    class="contentwidth"
                    style={{
                      fontSize: "14px",
                      backgroundColor: "green",
                      padding: "10px 25px 10px 25px",
                      color: "white",
                      textTransform: "none",
                      borderRadius: "5px",
                      fontFamily:
                        "HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif",
                    }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Grid>
    );
  }
}

export default CreateEventPromoteEventFlyers;
