import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";

import DatePickerCalender from "./DatePickerCalender/DatePickerCalender";
import "./cventSlotsScheduler.css";
import moment from "moment";
import React, {useEffect, useLayoutEffect, useState} from "react";
import { axiosE } from "Service/service";

import { TransitionGroup } from "react-transition-group";
import { Collapse } from "@material-ui/core";
import { default as momentTz } from "moment-timezone";

const EventSlotsScheduler = ({
  isAttendee = false,
  item,
  handleChange,
  setSelectedOption,
  selectedOption,
  setRecurringData,
  currentTicket,
  setCurrentTicket,
  dispatch,
  recurringData,
  cartItems,
}) => {
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [selectedSlot, setSelectedSlot] = useState("");

  const [availableSlots, setAvailableSlotes] = useState([]);
  const [showSlots, setShowSlots] = useState(false);
  const [highlightDays, setHighlightDays] = useState([]);

  const formatDate = (date) => {
    let newDate = date.format("DD/MM/YYYY");
    return moment(newDate, "DD/MM/YYYY").format("dddd, MMMM D");
  };

  const handleSelectSlot = (time, item) => {
    setSelectedSlot({ ...time });

    setSelectedOption({
      qnty: { value: time?.AvailableTickets },
      item,
      selectedDate,
      selectedSlot: time,
      TicketId: item?.TicketId,
    });
  };

  const formatSelectedDate = (date) => {
    return date.format("DD/MM/YYYY");
  };

  const findMatchingDate = (inputDate) => {
    const formattedInputDate = formatSelectedDate(inputDate);

    try {
      const dates = JSON.parse(item?.AvailableDates);

      if (!dates || !Array.isArray(dates.Dates)) {
        return false;
      }

      const formattedDates = dates.Dates.map((date) =>
        dayjs(date).format("DD/MM/YYYY")
      );
      setHighlightDays(formattedDates);

      const matchingDate = formattedDates.find(
        (date) => date === formattedInputDate
      );

      if (!matchingDate) {
        return false;
      }

      return matchingDate;
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return false;
    }
  };

  useEffect(() => {
    getCurrentHighlightedDays();
    setSelectedDate(null);
    const isRecData = cartItems?.filter((x) => x.TicketId === item?.TicketId);
    if (isRecData?.length > 0) {
      setSelectedDate(dayjs(isRecData[0]?.selectedDate, "DD/MM/YYYY"));
      getRecurringData(dayjs(isRecData[0]?.selectedDate, "DD/MM/YYYY"));
    }
  }, []);

  useEffect(() => {
    setRecurringData({ selectedDate, selectedSlot });
    fetchHighlightedDaysData();
    const isRecData = cartItems?.filter((x) => x.TicketId === item?.TicketId);
    if (isRecData?.length > 0) {
      if (isRecData[0]?.selectedDate === selectedDate?.format("DD/MM/YYYY")) {
        setSelectedSlot(isRecData[0]?.selectedSlot);

        setSelectedOption({
          qnty: { value: null },
          item,
          selectedDate,
          selectedSlot: isRecData[0]?.selectedSlot,
          TicketId: item?.TicketId,
          availableSlots,
        });
      } else {
        if (isRecData?.length <= 0) {
          setSelectedSlot(null);
          handleChange({ value: 0 }, item, {
            selectedDate,
            selectedSlot: null,
          });

          setSelectedOption({
            qnty: { value: null },
            item,
            selectedDate,
            selectedSlot: null,
            TicketId: item?.TicketId,
            availableSlots,
          });
        }
      }
    } else {
      setSelectedSlot(null);
      handleChange({ value: 0 }, item, { selectedDate, selectedSlot: null });
      setSelectedOption({
        qnty: { value: null },
        item,
        selectedDate,
        selectedSlot: null,
        TicketId: item?.TicketId,
        availableSlots,
      });
    }
  }, [selectedDate]);

  useEffect(() => {
    if (
      selectedOption?.qnty?.value == 0 &&
      selectedOption?.TicketId === item?.TicketId
    ) {
      setSelectedOption((prevState) => {
        return {
          ...prevState,
          isStateNull: true,
        };
      });
      setSelectedSlot(null);
    }
  }, [selectedOption?.qnty?.value]);

  const getRecurringData = (selectedDay) => {
    const matchedDate = selectedDay ? findMatchingDate(selectedDay) : "";
    if (matchedDate) {
      setShowSlots(true);
      const formattedMatchDate = matchedDate?.split("/").reverse().join("-");

      const params = {
        TicketId: item.TicketId,
        BookingDate: formattedMatchDate,
        TimeZone: JSON.parse(localStorage.getItem("CurrentTimeZone")),
        UserId: localStorage.getItem("UserId")  || 0
      };

      axiosE
        .post(`/Ticket/GetTicketTimeSlot`, params)
        .then((res) => {
          const parsingData = JSON.parse(res.data.results.Data);
          setAvailableSlotes(parsingData);
        })
        .catch((err) => console.log({ err }));
    } else {
      setShowSlots(false);
    }
  };

  useEffect(() => {
    setSelectedOption((prevState) => {
      return {
        ...prevState,
        availableSlots,
      };
    });
  }, [availableSlots]);

  const getCurrentHighlightedDays = () => {
    try {
      const dates = JSON.parse(item?.AvailableDates);

      if (!dates || !Array.isArray(dates.Dates)) {
        return false;
      }

      const formattedDates = dates.Dates.map((date) =>
        dayjs(date).format("DD/MM/YYYY")
      );
      setHighlightDays(formattedDates);
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return false;
    }
  };

  const [isLoading, setIsLoading] = useState(false);
  const [highlightedDays, setHighlightedDays] = useState([]);
  const fetchHighlightedDaysData = () => {
    setIsLoading(true);
    const formattedHighlightedDays = highlightDays?.map((day) =>
      dayjs(day, "DD/MM/YYYY")
    );
    setHighlightedDays(formattedHighlightedDays);
    setIsLoading(false);
  };

  const convertTo12HourFormat = (time) => {
    const date = selectedDate ? selectedDate.format("DD/MM/YYYY") : "";
    const timeZone = JSON.parse(localStorage.getItem("CurrentTimeZone"));
    const dateTimeString = `${date} ${time}`;
    const utcDateTime = moment.utc(dateTimeString, "DD/MM/YYYY h:mm");
    const istDateTime = utcDateTime.tz(timeZone);
    const istLocalTime = istDateTime.format("h:mm A");
    return istLocalTime;
  };

  const isPreviousDate = (date, time) => {
    const targetTimezone = JSON.parse(localStorage.getItem("CurrentTimeZone"));

    // Parse the date string into a Moment.js object
    const selectedDate = moment(date, "DD/MM/YYYY");

    // Split the time string into hours and minutes
    const [hours, minutes] = time.split(':');

    // Create a Moment.js object with the time components
    const utcDateTime = momentTz.utc(time, 'HH:mm');

    // Convert the UTC time to local time in the specified timezone
    const localTime = utcDateTime.clone().tz(targetTimezone);

    // Format the local time as desired (e.g., "HH:mm")
    const localTimeFormatted = localTime.format("HH:mm");

    const [localHours, localMinutes] = localTimeFormatted.split(':');

    // Set the time components for the selected date
    selectedDate.set({
      hour: localHours,
      minute: localMinutes,
    });

    const currentDateTime = moment.tz(targetTimezone);

    console.log({
      currentDateTime: currentDateTime.format(),
      selectedDateTime: selectedDate.format(),
    });

    return selectedDate.isBefore(currentDateTime);
  };

  const getCurrentLocalTime = (timeZone) => {
    return moment().tz(timeZone);
  };

  const isSelectedSlot = (time) => {
    if (selectedOption !== undefined) {
      const isItMomentObject = selectedOption.selectedDate && typeof selectedOption.selectedDate === 'object' && typeof selectedOption.selectedDate.format === 'function'
      const selectedDate = isItMomentObject ? selectedOption.selectedDate.format("DD/MM/YYYY") : selectedOption.selectedDate;

      const isRecData = cartItems.find((x) => (x.TicketId === selectedOption.TicketId) && (x.selectedDate === selectedDate))

      const currentData =
          selectedSlot?.StartTime === time.StartTime &&
          selectedSlot?.EndTime === time.EndTime &&
          isRecData
      ;

      return currentData;
    }
    return false;
  };

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => {
        window.removeEventListener("resize", updateSize);
      };
    }, []);
    return size;
  }
  const [width] = useWindowSize();

  return (
    <div
      className="calenderFeatureDiv"
      style={{
        padding: isAttendee ? "0px" : "0 10%",
      }}
    >
      <div className="DatePickerCalender">
        <TransitionGroup>
          {!selectedDate && (
            <Collapse>
              <p>Click a date to browse availability.</p>
            </Collapse>
          )}
          <DatePickerCalender
            item={item}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            setCurrentTicket={setCurrentTicket}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            isLoading={isLoading}
            fetchHighlightedDaysData={fetchHighlightedDaysData}
            highlightedDays={highlightedDays}
            getRecurringData={getRecurringData}
            selectedSlot={selectedSlot}
            setSelectedSlot={setSelectedSlot}
            cartItems={cartItems}
            availableSlots={availableSlots}
            recurringData={recurringData}
            handleChange={handleChange}
          />
        </TransitionGroup>
        {width <= 800 && cartItems.length > 0 ? (
            <p style={{marginTop:"20px"}}>Check Summary for Details</p>
        ) : null}
      </div>
      {showSlots && (
        <div className="timeSlotsDiv">
          <b><p>{selectedDate ? formatDate(selectedDate) : ""}</p></b>
          <p style={{color:"#c31491"}}>Please Select Time</p>
          <div className="timeSlots">
            {availableSlots?.map((time) => {
              const uniqueId = uuidv4();
              return (
                !isPreviousDate(selectedDate.format("DD/MM/YYYY"), time.StartTime) && (
                  <div key={uniqueId}>
                    <input
                      disabled={
                        time?.AvailableTickets == 0 ||
                        (time?.ReserverdTickets > 0 &&
                          time?.AvailableTickets == 0)
                      }
                      type="radio"
                      id={uniqueId}
                      name={uniqueId}
                      defaultChecked={isSelectedSlot(time)}
                      onClick={() => {
                        handleSelectSlot(time, item);
                        handleChange({value: 1}, item, {
                          selectedDate: selectedDate?.format("DD/MM/YYYY"),
                          selectedSlot: time,
                        });
                        setCurrentTicket(item?.TicketId);
                        setRecurringData({
                          selectedDate: selectedDate?.format("DD/MM/YYYY"),
                          selectedSlot: time,
                        });
                      }}
                    />
                    <label
                      htmlFor={uniqueId}
                      style={{
                        width: "100%",
                        cursor: time?.AvailableTickets == 0 && "default",
                        border: time?.AvailableTickets == 0 && "1px solid gray",
                      }}
                    >
                      <h4
                        style={{
                          color: time?.AvailableTickets == 0 && "gray",
                        }}
                      >{`${convertTo12HourFormat(
                        time.StartTime
                      )} - ${convertTo12HourFormat(time.EndTime)}`}</h4>
                      <p
                        style={{
                          color: time?.AvailableTickets == 0 && "gray",
                        }}
                      >
                        {time?.ReserverdTickets > 0 &&
                        time?.AvailableTickets == 0
                          ? "The tickets are in the process of being purchased!"
                          : time?.AvailableTickets > 0
                          ? `Only ${time.AvailableTickets} left`
                          : "Sold out!"}
                      </p>
                    </label>
                  </div>
                )
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default EventSlotsScheduler;
