import React, { createRef, useEffect, useState } from "react";
import "./Flyer.css";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { exportComponentAsJPEG } from "react-component-export-image";
import Flyer1 from "images/flyers/Flyer-04.jpg";
import Flyer2 from "images/flyers/Flyer-05.jpg";
import Flyer3 from "images/flyers/Flyer-06.jpg";
import { connect } from "react-redux";
import QRCode from "react-qr-code";
import * as constants from "Constants";
import moment from "moment";
import "moment-timezone";
import { useLayoutEffect } from "react";
import { UserIconsPic } from "Components/UBMeEvents/Components_UBMeEvents/Navbar/Alphabets/UserIconsPic";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Redirect } from "react-router-dom";
import { routeName } from "Service/env";
function Flyer({ eventUserEvents, results }) {
  const certificateWrapper1 = createRef();
  const certificateWrapper2 = createRef();
  const certificateWrapper3 = createRef();
  const [card, setCard] = useState([
    { BgImage: Flyer1, id: "FestCard1", ref: certificateWrapper1 },
    { BgImage: Flyer2, id: "FestCard2", ref: certificateWrapper2 },
    { BgImage: Flyer3, id: "FestCard3", ref: certificateWrapper3 },
  ]);
  const [pdf, setPdf] = useState(true);
  const [isSelected, setIsSelected] = useState(false);
  const [selected, setSelected] = useState();

  const selectDocumentType = (i) => {
    setIsSelected(!isSelected);
    const selectedCard = card.filter((item) => item == i);
    setSelected(selectedCard);
  };

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  }
  const [width, height] = useWindowSize();
  if (width < 400 && width !== 0) {
    document
      .querySelector("meta[name=viewport]")
      .setAttribute("content", "width=415px");
  } else if (width > 300 && width !== 0) {
  }

  const downloadCard = async (isPdf, id, ref) => {
    if (isPdf) {
      const input = document.getElementById(id);
      html2canvas(input, {
        quality: 10,
        scale: 2,
        useCORS: true,
        // allowTaint: true,
        // logging: true
      }).then((canvas) => {
        var ctx = canvas.getContext("2d");
        ctx.webkitImageSmoothingEnabled = false;
        ctx.mozImageSmoothingEnabled = false;
        ctx.imageSmoothingEnabled = false;
        ctx.shadowBlur = 20;
        ctx.shadowColor = "black";
        const imgData = canvas.toDataURL("image/jpeg", 1);
        const pdf = new jsPDF("a4");
        pdf.addImage(imgData, "jpeg", 0, 15, 210, 270);
        pdf.save(eventUserEvents.eventsAbout?.Title);
        setSelected();
      });
    } else {
       
      const element = document.getElementById(id),
      canvas = await html2canvas(element,{ quality: 10,
        scale: 2,
        useCORS: true,}),
      data = canvas.toDataURL('image/jepg'),
      link = document.createElement('a');
   
      link.href = data;
      link.download =  `${eventUserEvents.eventsAbout?.Title}.jpeg`;
   
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    }
  };

  const convertTimeUtcToLocal = (
    time,
    TimeZone = eventUserEvents?.eventsAbout?.TimeZone
  ) => {
    const UtcToLocal = moment.utc(time).local().format("MM/DD/YYYY hh:mm:ss A");
    var Time = moment(UtcToLocal, "MM/DD/YYYY hh:mm:ss A");

    const convertedTime = Time.tz(TimeZone);
    return moment(convertedTime).format("llll");
  };

  React.useEffect(() => {
    return () => {
      document
        .querySelector("meta[name=viewport]")
        .setAttribute("content", "width=device-width, initial-scale=1");
    };
  }, []);

  const TruncateExtraText = (Text, maxLength) => {
    if (Text && Text.length > maxLength) {
      return Text.substring(0, maxLength) + "...";
    }
    return Text;
  };

  useEffect(() => {
    UserIconsPic(results.ProfileDetail?.FirstName[0]?.toUpperCase());
  }, []);


  return (
    <div>
       {
        eventUserEvents.eventPublish ? null : <Redirect to={`${routeName}/Dashboard/Publish`}/> 
      }
   
    <div className="flyer">
      <div className="email_invite_wrapper">
        {/* <ShowErrorToast message={error} closeToast={() => setError("")} /> */}
        <div className="email_invite_header">
          <div className="email_invite">
            <span style={{ fontSize: 30 }}>Flyers</span>
          </div>
        </div>
      </div>
      <div className="flyerCards">
        {card.map((item, i) => (
          <div key={i} className="flyerCard">
            <div
              className="cardContent"
              style={{
                // backgroundImage: `url(${item.BgImage})` ,
                position: "relative",
              }}
              id={item.id}
              ref={item.ref}
            >
              <LazyLoadImage
                src={item.BgImage}
                width={"100%"}
                height={"100%"}
                PlaceholderSrc={"PlaceholderImage"}
                effect="blur"
              />
              {/* <img src={} style={{ width: "100%" }} /> */}
              <div style={{ position: "absolute" }}>
                <div className="cardUpperContent">
                  <div style={{ marginTop: 18 }}>
                    <div className="venue_name">
                      <h4>{eventUserEvents.eventsAbout?.VenueName}</h4>
                    </div>
                    <div className="venue_name">
                      <p>
                        {TruncateExtraText(
                          eventUserEvents.eventsAbout?.Add1,
                          65
                        )}
                      </p>
                    </div>
                  </div>

                  <div className="venue_iamge">
                  <LazyLoadImage
                src={ results.ProfileDetail.ProfileImage === null
                  ? UserIconsPic(
                      results.ProfileDetail?.FirstName[0]?.toUpperCase()
                    )
                  : results.ProfileDetail.ProfileImage}
                  className="Profile_img"
                // wrapperClassName="Profile_img"
                PlaceholderSrc={"PlaceholderImage"}
                effect="blur"
              />
                    {/* <img
                      className="Profile_img"
                      src={
                        results.ProfileDetail.ProfileImage === null
                          ? UserIconsPic(
                              results.ProfileDetail?.FirstName[0]?.toUpperCase()
                            )
                          : results.ProfileDetail.ProfileImage
                      }
                    /> */}
                    {/* <img
                      className="Profile_img"
                      style={{ objectFit: "cover" }}
                      src={user}
                    /> */}
                  </div>
                </div>
                <div className="eventImageUrl">
                <LazyLoadImage
                src={ eventUserEvents.eventsAbout.MediumImage ||
                  eventUserEvents.eventsAbout.ImageMedium}
                  className="eventimg"
                // wrapperClassName="Profile_img"
                PlaceholderSrc={"PlaceholderImage"}
                effect="blur"
              />
                  {/* <img
                    src={
                     
                    }
                  /> */}

                  {/* <img src={event} /> */}
                </div>

                <div className="event_content">
                  <h3 style={{ fontSize: 18,fontWeight:600 }}>
                    {TruncateExtraText(eventUserEvents.eventsAbout?.Title, 75)}
                  </h3>
                </div>
                <div className="cardLowerContent">
                  <div className="QrCode">
                    <div className="QrCode_image">
                      <QRCode
                        size={51}
                        value={`${constants.URLs.baseUrl}/event/${eventUserEvents.eventsAbout.EventId}`}
                        viewBox={`0 0 25 25`}
                      />
                    </div>
                  </div>
                </div>
                <div className="event_content_time">
                  <p>
                    {convertTimeUtcToLocal(
                      eventUserEvents?.eventsAbout?.StartDateTime
                    )}
                  </p>
                  <p>
                    {convertTimeUtcToLocal(
                      eventUserEvents?.eventsAbout?.EndDateTime
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: 15,
                justifyContent: "center",
              }}
            >
              <button
                className="actionButton"
                onClick={() => {
                  downloadCard(true, item.id, item.ref);
                }}
              >
                {constants.Text.DownloadPDF}
              </button>
              <button
                className="actionButton"
                onClick={() => {
                  downloadCard(false, item.id, item.ref);
                }}
              >
             {constants.Text.DownloadJPG}
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { eventUserEvents } = state;
  return {
    eventUserEvents: eventUserEvents,
    eventPublish: state.eventUserEvents.eventPublish,
    results: state.loginResponse.results,
  };
};

const matchDispatchToProps = (dispatch) => {
  return {
    // AddToFavorite: () => dispatch(AddToFavorite),
    // SetCardData: () => dispatch(SetCardData),
    // LoginPopup: () => dispatch(LoginPopup),
    // GetUserProfileDetail,
    // saveUserData,
    // UpdateEventDetailsByUser,
    // dispatch,
  };
};

export default connect(mapStateToProps, matchDispatchToProps)(Flyer);


