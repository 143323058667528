import axios from "axios";
import { history } from "../store";
import { routeName } from "../Service/env";

let axiosI = axios.create({
  baseURL: "https://api.ubme.com/MenumamaService.svc/Web/",
});
let axiosE = axios.create({
  baseURL: "https://api.ubme.com/MenumamaService.svc/EventMgmt",
});

const apiInterceptors = (apiEndPoint) => {
  apiEndPoint.interceptors.request.use(
    function (config) {
      const token = localStorage.getItem("token");
      if (token !== null) {
        config.headers.Authorization = token;
      }
      return config;
    },
    function (err) {
      return Promise.reject(err);
    }
  );

  apiEndPoint.interceptors.response.use((config) => {
    if (localStorage.length > 0) {
      if (config.data.results.Message === "Authentication Fail") {
        localStorage.clear();
        // history.push(`${routeName}`);
      } else {
        return config;
      }
    }
    return config;
  });
};

apiInterceptors(axiosI);
apiInterceptors(axiosE);

export { axiosE };
export default axiosI;
