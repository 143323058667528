import React from "react";
import { useTable } from "react-table";

const SalesTable = ({ salesData }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: "Event Name",
        accessor: "Title", // accessor is the "key" in the data
      },
      {
        Header: "Event Start Date",
        accessor: "StartDateTime",
      },
      {
        Header: "Tickets Holders",
        accessor: "TicketSold",
      },
      {
          Header: "Tax / Fee Collected",
          accessor: "TaxAmount",
      },
      {
        Header: "Total Sales",
        accessor: "Sale",
      },
      {
        Header: "UBMe Fees",
        accessor: "UBMeFee",
      },
      // {
      //   Header: "Processing Fees",
      //   accessor: "ProcessingFee",
      // },
      {
          Header: "Total Payout",
        accessor: "Payout",
      },
    ],
    []
  );

  function Table({ columns, data }) {
    // Use the state and functions returned from useTable to build your UI
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
      useTable({
        columns,
        data,
      });

    // Render the UI for your table
    return (
      <table className="tickets_table" {...getTableProps()}>
        <thead className="tickets_table_thead">
          {headerGroups.map((headerGroup, i) => (
            <tr
              key={i}
              style={{ backgroundColor: "#ffffff" }}
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column, i) => (
                <th
                  key={i}
                  style={{
                    fontSize: 14,
                    paddingBottom: 20,
                    textAlign: "center",
                  }}
                  {...column.getHeaderProps()}
                >
                  {column.render("Header")}
                  { column.id === "Payout" ?
                      <div style={{ fontSize: 12, color: "#999" }}>Card processing fees NOT included.</div>
                      : ""
                      // : <div style={{ height: "14px"}}></div>
                  }
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);

            return (
              <tr className="ticket-table-tr" key={i} {...row.getRowProps()}>
                {row.cells.map((cell, i) => {
                  return (
                    <td
                      key={i}
                      data-label={cell.column.Header}
                      className={`ticket_td text-align-td text-white ${
                        cell.column.className ?? ""
                      }`}
                      {...cell.getCellProps({
                        style: {
                          width: cell.column.width,
                        },
                      })}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  return (
    <div>
      {salesData.length > 0 && <Table columns={columns} data={salesData} />}
    </div>
  );
};

export default SalesTable;
