import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import { Editor } from "react-draft-wysiwyg";
import ContentGrid from "../../../../Helper/gridWrapper/gridWrapper";
import EventHeader from "./eventHeader";
import "./editor.css";
import { Box, Modal } from "@material-ui/core";
import { connect } from "react-redux";
import { addFAQ, deleteFAQ, updateFAQ } from "../../../../Actions/eventActions";
import { bindActionCreators } from "redux";
import draftToHtml from "draftjs-to-html";
import {
  EditorState,
  convertToRaw,
  convertFromHTML,
  ContentState,
} from "draft-js";
import { Close } from "@material-ui/icons";
import { eventApis, Events, Text } from "../../../../Constants";
import { axiosE } from "../../../../Service/service";
import { de } from "date-fns/locale";
import Loader from "../../../../Helper/loader/loader";
import AddIcon from "@material-ui/icons/Add";
import { reusableClasses } from "./resusableClasses";
import { routeName } from "../../../../Service/env";
import { checkIfEventExists } from "../../../../Helper/Validations";
const styles = {
  createEventFAQGrid: {
    "@media (max-width: 480px)": {
      padding: "0px",
    },
  },
  createEventFAQWrapper: {
    ...reusableClasses.contentWrapper,
    flex: 1,
    height: "auto",
  },
  createEventFAQHeader: {
    width: "90%",
    height: "fit-content",

    display: "flex",
    alignItems: "start",
    justifyContent: "space-between",

    paddingBottom: "20px",
    margin: "0px auto",

    borderBottom: "1px solid #F5F5F5",
  },
  createEventFAQModal: {
    ...reusableClasses.addOrUpdateModalMainWrapperOuter,
  },
  createEventFAQModalHeader: {
    ...reusableClasses.addOrUpdateModalHeader,
  },
  createEventFAQFormWrapper: {},
  createEventFAQGridWrapper: {
    margin: "2px 0px 0px 0px",
    display: "grid",
  },
  createEventFAQVerticalLineWithCirclesWrapper: {
    margin: "48px 0px 0px 0px",
    "@media screen and (max-device-width:450px)": {
      display: "none",
    },
  },
  createEventFAQSecondCircleOnVerticalLine: {
    backgroundColor: "rgb(182, 14, 181)",
    height: "15px",
    width: "15px",
    margin: "0px 0px 610px -9px",
    borderRadius: "50%",
    "@media screen and (max-device-width:450px)": {
      display: "none",
    },
  },
  createEventFAQInputLabel: {
    fontSize: "16px",
    fontWeight: "550",
  },
  createEventFAQLongInputField: {
    border: "1px solid #ced4da",
    borderRadius: "5px",
    fontSize: "16px",
    padding: "10px",
    margin: "15px 0px 35px 0px",
    width: "auto",
  },
  createEventFAQInputText: {
    fontSize: "16px",
  },
  createEventFAQLargeInputForm: {
    border: "1px solid #ced4da",
    height: "300px",
    marginBottom: "45px",
    marginTop: "15px",
    padding: "10px",
    width: "auto",
    "@media  screen and (max-device-width:450px)": {
      margin: "20px 0px 30px 0px",
      height: "400px",
    },
  },

  createEventFAQAddFAQButton: {
    ...reusableClasses.addButton,
  },
  createEventFAQSaveFAQButton: {
    ...reusableClasses.addOrUpdateModalHeaderButtonSave,
  },
  createEventFAQCancelFAQButton: {
    ...reusableClasses.addOrUpdateModalHeaderButtonCancel,
    margin: "0px !important",
  },
  createEvenFAQDeleteFAQModal: {
    width: "430px",
    height: "150px",
    backgroundColor: "white",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "10px",
    padding: "10px",

    "@media (max-width: 540px)": {
      width: "70%",
    },
    "@media (max-width: 410px)": {
      width: "90%",
    },
  },
  createEventFAQSubmittedFAQ: {
    width: "auto",
    margin: "30px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderLeft: "10px solid green",
    padding: "8px",

    "@media (max-width: 450px)": {
      margin: "5%",
    },
  },
  createEventFAQSubmittedFAQText: {
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    alignItems: "center",
    fontSize: "16px",
    margin: 0,
  },
  createEventFAQEditAndDeleteFAQButtonWrapper: {
    margin: "0px 0px 0px 5px",
    justifyContent: "flex-end",
    alignSelf: "center",
    display: "flex",
  },
  createEventFAQEditAndDeleteFAQButton: {
    color: "gray",
    cursor: "pointer",
  },
  createEventFAQButtonWrapper: {
    ...reusableClasses.saveAndContinueButtonWrapper,
    flex: "0.05",

    "@media (max-width: 450px)": {
      flex: "auto",
    },
  },

  createEventFAQButtonWrapperModal: {
    ...reusableClasses.addOrUpdateModalHeaderButtonWrapper,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
    "@media  screen and (max-width:450px)": {
      // flexDirection:'column',
      margin: "0px 0px 14px 0px",
    },
  },

  createEventFAQSaveAndContinueButton: {
    ...reusableClasses.saveAndContinueButton,
  },
  createEventFAQNewButtonWrapper: {
    ...reusableClasses.addButtonWrapper,
  },
  createEventFAQEmptyFAQ: {
    display: "flex",
    flexDirection: "column",
    margin: "50px auto",
    justifyContent: "center",
    alignItems: "center",
    opacity: 0.2,
    flex: 0.9,
  },
  eventDetailsAboutMainWrapper: {
    ...reusableClasses.mainWrapper,
  },
  createEventFAQAddText: {
    ...reusableClasses.addButtonText,
  },
  createEventFAQPlusIcon: {
    ...reusableClasses.addButtonIcon,
  },
  createEventFAQSWrapper: {
    flex: 0.9,

    "@media (max-width: 450px)": {
      paddingBottom: "52px",
      flex: 1,
    },
  },
  createEventFAQImgWrapper: {
    width: "100px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100px",
  },
  createEventFAQImg: {
    width: "30px",
  },
  createFAQDeleteFAQButton: {
    fontSize: "10px",
    padding: "10px 25px",
  },
  createFAQAdAndDeleteModal: {
    ...reusableClasses.addOrUpdateModalMainWrapperInner,
  },
  createFAQModalHeader: {
    ...reusableClasses.addOrUpdateModalHeaderText,
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",

    "@media (max-width: 450px)": {
      flex: 1,
    },
  },
  createFAQModalBody: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    flex: "0.95",

    "@media (max-width: 450px)": {
      flex: 1,
    },
  },
};
export class CreateEventFAQ extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modelOpen: false,
      deleteFAQ: { show: false, targetFaq: "" },
      verticalLineHeight: "625px",
      currentFaq: {
        currentQuestion: "",
        currentAnswer: "",
        showEdit: false,
        faqId: "",
      },
      faqs: [],
      loader: false,
    };
  }

  componentDidMount() {
    const { history, eventId } = this.props;

    checkIfEventExists(history, eventId);

    this.setState({ loader: true });
    axiosE.get(`${eventApis.getFaqByEventId}${eventId}/Admin`).then((res) => {
      let faqs = res.data.results.ListFaq;
      if (faqs && faqs.length > 0) {
        this.setState({
          faqs: faqs.map((faq) => ({
            question: faq.Question,
            answer: faq.Answer,
            showEdit: false,
            faqId: faq.FaqId,
          })),
        });
      }
      this.setState({ loader: false });
    });
  }

  // htmlToEditorState(prop) {
  //   const blocksFromHTML = convertFromHTML(prop);
  //   const state = ContentState.createFromBlockArray(
  //     blocksFromHTML.contentBlocks,
  //     blocksFromHTML.entityMap
  //   );
  //   const newState = this.customContentStateConverter(state);
  //   return EditorState.createWithContent(newState);
  // }
  customContentStateConverter = (contentState) => {
    const newBlockMap = contentState.getBlockMap().map((block) => {
      const entityKey = block.getEntityAt(0);
      if (entityKey !== null) {
        const entityBlock = contentState.getEntity(entityKey);
        const entityType = entityBlock.getType();
        switch (entityType) {
          case "IMAGE": {
            const newBlock = block.merge({
              type: "atomic",
              text: "img",
            });
            return newBlock;
          }
          default:
            return block;
        }
      }
      return block;
    });
    const newContentState = contentState.set("blockMap", newBlockMap);
    return newContentState;
  };

  componentDidUpdate(props, state) {
    // console.log("PROP:", props);
    // console.log("State:", state);
  }
  verticalLineStyles = (extraCircles) => {
    return {
      float: "left",
      borderLeft: "2px solid lightgray",
      margin: "48px 0px 0px 30px",
      height: this.calculateLineHeight(extraCircles),
      "@media screen and (max-device-width:450px)": {
        display: "none",
      },
    };
  };
  circleOnVerticalLineStyles = (index) => {
    return {
      backgroundColor: "rgb(182, 14, 181)",
      height: "15px",
      width: "15px",
      margin:
        "0px 0px " + this.calculateCircleOnVerticalLineMargin(index) + "-9px",
      borderRadius: "50%",
      "@media screen and (max-device-width:450px)": {
        display: "none",
      },
    };
  };

  calculateLineHeight = (extraCircles) => {
    const { faqs } = this.state;
    let extraLineHeight = 0;
    faqs.forEach((faq, index) => (extraLineHeight += 10));
    return faqs.length > 1 ? 0 + extraCircles * 85 + "px" : "0px";
  };

  calculateCircleOnVerticalLineMargin = (index) => {
    const { faqs } = this.state;
    const faqsCircle =
      faqs.length > 0
        ? index !== faqs.length - 1
          ? "69.5px "
          : "69.5px "
        : "69.5px ";
    return faqsCircle;
  };

  closeFaqEditForm = (index) => {
    this.setState({
      faqs: this.state.faqs.map((faq, i) =>
        i === index
          ? {
              question: this.state.faqs[i].question,
              answer: this.state.faqs[i].answer,
              showEdit: !this.state.faqs[i].showEdit,
            }
          : this.state.faqs[i]
      ),
    });
  };

  verticalLineWithCircles = () => {
    const { classes } = this.props;
    const { faqs } = this.state;
    let extraCircles = faqs.map((faq, index) => (
      <div key={index} style={this.circleOnVerticalLineStyles(index)}></div>
    ));
    extraCircles = extraCircles.splice(0, extraCircles.length - 1);
    let elements = [];
    elements.push(
      <div style={{ display: "flex" }}>
        <div
          className={classes.createEventFAQVerticalLineWithCirclesWrapper}
          style={this.verticalLineStyles(extraCircles.length)}
        ></div>
        <div className={classes.createEventFAQVerticalLineWithCirclesWrapper}>
          {extraCircles}
          {faqs.length > 0 && (
            <div style={this.circleOnVerticalLineStyles(faqs.length - 1)}></div>
          )}
        </div>
      </div>
    );
    return (
      <React.Fragment key={extraCircles.length}>{elements} </React.Fragment>
    );
  };

  mandatoryFieldTag = () => {
    return <span style={{ color: "red" }}> *</span>;
  };

  enterFrequentlyAskedQuestionAndAnswerInputs = (
    questionTitle,
    answerTitle
  ) => {
    const { classes } = this.props;
    const { FAQ } = Events;
    return (
      <>
        <Grid className={classes.createEventFAQGridWrapper}>
          <FormControl>
            <span className={classes.createEventFAQInputLabel}>
              {questionTitle} {this.mandatoryFieldTag()}
            </span>
            <TextField
              required
              className={classes.createEventFAQLongInputField}
              onChange={this.setCurrentFAQQuestion}
              value={this.state.currentFaq.currentQuestion}
              placeholder={FAQ.addFAQ.questionPlaceHolder}
              margin="normal"
              fullWidth
              InputProps={{
                disableUnderline: true,
                className: classes.createEventFAQInputText,
              }}
              // helperText={

              //   `${this.state.currentFaq.currentQuestion?.length}/30`
              // }
            />
          </FormControl>
        </Grid>
        <Grid style={{ display: "grid" }}>
          <React.Fragment>
            <span className={classes.createEventFAQInputLabel}>
              {answerTitle}
              {this.mandatoryFieldTag()}
            </span>
            <textarea
              value={this.state.currentFaq.currentAnswer}
              onChange={(e) =>
                this.setState({
                  currentFaq: {
                    ...this.state.currentFaq,
                    currentAnswer: e.target.value,
                  },
                })
              }
              style={{
                height: 300,
                width: "100%",
                resize: "none",
                padding: 10,
                whiteSpace: "pre-line",
                margin: "10px 0px 20px 0px",
                border: "1px solid #ced4da",
                outline: "none",
                overflowWrap: "break-word",
              }}
            />
            {/* <Editor
              editorState={this.state.currentFaq.currentAnswer}
              editorClassName="editor-class"
              toolbarClassName="toolbar-class"
              wrapperClassName="wrapper-class"
              onEditorStateChange={this.setCurrentFAQAnswer}
              handlePastedText={() => false}
            /> */}
          </React.Fragment>
        </Grid>
      </>
    );
  };

  setCurrentFAQQuestion = (event) => {
    this.setState({
      currentFaq: {
        currentQuestion: event.currentTarget.value,
        currentAnswer: this.state.currentFaq.currentAnswer,
        showEdit: this.state.currentFaq.showEdit,
        faqId: "",
      },
    });
  };

  setCurrentFAQAnswer = (event) => {
    this.setState({
      currentFaq: {
        currentQuestion: this.state.currentFaq.currentQuestion,
        currentAnswer: event,
        showEdit: this.state.currentFaq.showEdit,
        faqId: "",
      },
    });
  };

  // getHtml = (state) => {
  //   return draftToHtml(convertToRaw(state.getCurrentContent()));
  // };

  addFaq = async () => {
    const que = this.state.currentFaq.currentQuestion;
    const ans = this.state.currentFaq.currentAnswer;
    this.loaderState(true);
    if (
      this.state.currentFaq.currentQuestion !== "" &&
      this.state.currentFaq.currentAnswer !== ""
    ) {
      this.setState({
        currentFaq: {
          currentQuestion: "",
          currentAnswer: "",
          showEdit: false,
          faqId: "",
        },
        faqs: [
          ...this.state.faqs,
          {
            question: que,
            answer: ans,
            showEdit: this.state.currentFaq.showEdit,
            faqId: await this.sendFAQtoServer(que, ans),
          },
        ],
        modelOpen: false,
      });
    }
  };

  isFieldEmpty = (ques, ans) => {
    let Question = true;
    let Answer = true;

    if (ans) {
      if (ans.getCurrentContent().getPlainText("\u0001").trim().length !== 0) {
        Answer = false;
      }
    }
    if (ques.trim().length !== 0) {
      Question = false;
    }
    return Question && Answer;
  };
  sendFAQtoServer = async (que, ans) => {
    const { addFAQ, eventId } = this.props;
    const FAQid = await addFAQ({
      EventId: eventId,
      Question: que,
      Answer: ans,
    }).finally(() => this.loaderState(false));
    return FAQid.FaqId;
  };

  cancelFaq = () => {
    this.setState({
      currentFaq: {
        currentQuestion: "",
        currentAnswer: "",
        showEdit: false,
        faqId: "",
      },
      modelOpen: false,
    });
  };
  checkFAQ = (questionTitle, answerTitle) => {
    const { classes } = this.props;
    const { faqs } = this.state;

    return faqs.length > 0 ? (
      <div className={classes.createFAQModalBody}>
        {this.verticalLineWithCircles()}
        <div className={classes.createEventFAQSWrapper}>
          {this.displaySubmittedFAQs(questionTitle, answerTitle)}
        </div>
      </div>
    ) : (
      <div className={classes.createEventFAQEmptyFAQ}>
        <img
          src={Events.FAQ.emptyFaq.img}
          alt="emptyFAQ"
          style={{ objectFit: "contain", width: "150px" }}
        />
        <h1>{Events.FAQ.emptyFaq.headline}</h1>
        <p>{Events.FAQ.emptyFaq.message}</p>
      </div>
    );
  };
  displaySubmittedFAQs = (questionTitle, answerTitle) => {
    const { classes } = this.props;
    const { faqs, currentFaq, deleteModal } = this.state;
    let elements = [];
    faqs.forEach((faq, index) => {

      elements.push(
        <React.Fragment key={index}>
          <Paper className={classes.createEventFAQSubmittedFAQ}>
            <p className={classes.createEventFAQSubmittedFAQText}>
              {faq.question}
            </p>
            <div
              className={classes.createEventFAQEditAndDeleteFAQButtonWrapper}
            >
              <IconButton
                className={classes.createEventFAQEditAndDeleteFAQButton}
                onClick={() => this.setDelete(true, false, faq)}
              >
                <DeleteIcon />
              </IconButton>
              <IconButton
                className={classes.createEventFAQEditAndDeleteFAQButton}
                onClick={() => {
                  faq.showEdit
                    ? this.toggleEditFAQForm(index, false)
                    : this.toggleEditFAQForm(index, true);
                }}
              >
                <CreateIcon />
              </IconButton>
            </div>
          </Paper>
          <Modal
            open={this.state.faqs[index].showEdit}
            onClose={() => this.toggleEditFAQForm(index, false)}
          >
            <Box className={classes.createEventFAQModal}>
              <div className={classes.createFAQAdAndDeleteModal}>
                <div>
                  <FormControl style={{ width: "100%" }}>
                    <div className={classes.createEventFAQModalHeader}>
                      <IconButton
                        onClick={() => this.toggleEditFAQForm(index, false)}
                      >
                        <Close height={30} />
                      </IconButton>
                      <p className={classes.createFAQModalHeader}>Edit FAQ</p>
                      {this.addExtraSpaceToBalance()}
                    </div>
                    <span className={classes.createEventFAQInputLabel}>
                      {questionTitle}
                      {this.mandatoryFieldTag()}
                    </span>
                    <TextField
                      required
                      className={classes.createEventFAQLongInputField}
                      margin="normal"
                      fullWidth
                      value={this.state.currentFaq.currentQuestion}
                      onChange={(event) => this.editFAQQuestion(event)}
                      InputProps={{
                        disableUnderline: true,
                        className: classes.createEventFAQInputText,
                      }}
                    />
                  </FormControl>
                  <span className={classes.createEventFAQInputLabel}>
                    {answerTitle}
                    {this.mandatoryFieldTag()}
                  </span>
                  <textarea
                    value={this.state.currentFaq.currentAnswer}
                    onChange={(e) => this.editFAQAnswer(e.target.value)}
                    style={{
                      height: 300,
                      width: "100%",
                      resize: "none",
                      padding: 10,
                      whiteSpace: "pre-line",
                      margin: "10px 0px 20px 0px",
                      border: "1px solid #ced4da",
                      outline: "none",
                      overflowWrap: "break-word",
                    }}
                  />
                </div>

                <div
                  className={classes.createEventFAQButtonWrapperModal}
                  id="eventCommonModal"
                >
                  <Button
                    className={classes.createEventFAQCancelFAQButton}
                    onClick={() => this.toggleEditFAQForm(index, false)}
                  >
                    {Text.Cancel}
                  </Button>
                  <Button
                    className={classes.createEventFAQSaveFAQButton}
                    onClick={() => this.saveEditFAQForm(index)}
                    variant={"contained"}
                    disabled={
                      !(
                        (
                          this.state.currentFaq.currentQuestion.trim() &&
                          this.state.currentFaq.currentAnswer
                        )
                        // &&
                        // this.state.currentFaq.currentAnswer
                        //   .getCurrentContent()
                        //   .getPlainText("\u0001")
                        //   .trim()
                      )
                    }
                    style={{
                      backgroundColor: !(
                        (
                          this.state.currentFaq.currentQuestion.trim() &&
                          this.state.currentFaq.currentAnswer
                        )
                        // &&
                        // this.state.currentFaq.currentAnswer
                        //   .getCurrentContent()
                        //   .getPlainText("\u0001")
                        //   .trim()
                      )
                        ? "rgba(163, 56, 164,.4)"
                        : "rgb(163, 56, 164)",
                      color: "white",
                    }}
                  >
                    {Text.Save}
                  </Button>
                </div>
              </div>
            </Box>
          </Modal>
        </React.Fragment>
      );
    });
    return elements;
  };

  setDelete = (modal, item, faq = "") => {
    if (modal && !item) {
      this.setState({
        deleteFAQ: { show: !this.state.deleteFAQ.show, targetFaq: faq },
      });
    } else if (!modal && item) {
      this.removeFAQ();
    } else {
      this.setState({
        deleteFAQ: { show: false, targetFaq: "" },
      });
    }
  };

  removeFAQ = () => {
    const faq = this.state.deleteFAQ;
    this.setState({
      faqs: this.state.faqs.filter(
        (currentFaq) => currentFaq.faqId !== faq.targetFaq.faqId
      ),
    });
    this.props.deleteFAQ({ FaqId: faq.targetFaq.faqId });
    this.setDelete(false, false);
  };

  faqDeleteModal = () => {
    const { deleteFAQ } = this.state;
    const { classes } = this.props;

    return (
      <Modal open={deleteFAQ.show} onClose={() => this.setDelete(false, false)}>
        <Box className={classes.createEvenFAQDeleteFAQModal}>
          <div className={classes.createEventFAQImgWrapper}>
            <img
              src={Events.FAQ.deleteFAQ.img}
              alt="Delete Icon"
              className={classes.createEventFAQImg}
            />
          </div>

          <p style={{ textAlign: "center" }}>{Events.FAQ.deleteFAQ.message}</p>

          <div>
            <Button
              className={classes.createFAQDeleteFAQButton}
              onClick={() => this.setDelete(false, false)}
              style={{ color: "blue" }}
            >
              {Text.Cancel}
            </Button>

            <Button
              className={classes.createFAQDeleteFAQButton}
              onClick={() => this.setDelete(false, true)}
              style={{ color: "red" }}
            >
              {Text.Delete}
            </Button>
          </div>
        </Box>
      </Modal>
    );
  };

  editFAQQuestion = (event) => {
    this.setState({
      currentFaq: {
        currentQuestion: event.currentTarget.value,
        currentAnswer: this.state.currentFaq.currentAnswer,
        showEdit: this.state.showEdit,
        faqId: this.state.currentFaq.faqId,
      },
    });
  };

  editFAQAnswer = (event) => {
    this.setState({
      currentFaq: {
        currentQuestion: this.state.currentFaq.currentQuestion,
        currentAnswer: event,
        showEdit: this.state.showEdit,
        faqId: this.state.currentFaq.faqId,
      },
    });
  };

  toggleEditFAQForm = (index, show) => {
    this.setState({
      faqs: this.state.faqs.map((faq, i) =>
        i === index
          ? {
              question: this.state.faqs[i].question,
              answer: this.state.faqs[i].answer,
              showEdit: show,
              faqId: this.state.faqs[i].faqId,
            }
          : this.state.faqs[i]
      ),
      currentFaq: {
        currentQuestion: show ? this.state.faqs[index].question : "",
        currentAnswer: show ? this.state.faqs[index].answer : "",
        showEdit: show,
        faqId: show ? this.state.faqs[index].faqId : "",
      },
    });

  };
  saveEditFAQForm = (index) => {
    const { currentQuestion, currentAnswer, faqId } = this.state.currentFaq;
    this.setState({
      faqs: this.state.faqs.map((faq, i) =>
        i === index
          ? {
              question: currentQuestion,
              answer: currentAnswer,
              showEdit: false,
              faqId: faqId,
            }
          : this.state.faqs[i]
      ),
      currentFaq: {
        currentQuestion: "",
        currentAnswer: "",
        showEdit: false,
        faqId: "",
      },
    });
    this.props
      .updateFAQ({
        Question: currentQuestion,
        Answer: currentAnswer,
        FaqId: faqId,
      })
      .finally(() => this.loaderState(false));
  };

  handModalClose = () => {
    this.setState({
      modelOpen: false,
    });
  };
  handModalOpen = () => {
    this.setState({
      modelOpen: true,
    });
  };

  progressLoader = () => {
    const { loader } = this.state;

    return loader && <Loader />;
  };

  loaderState = (show) => {
    this.setState({ loader: show });
  };

  addExtraSpaceToBalance = () => {
    return <span style={{ width: "30px", height: "30px" }}></span>;
  };

  render() {
    const { classes } = this.props;
    const { currentFaq, modelOpen, loader } = this.state;
    const questionLable = Events.FAQ.addFAQ.question;
    const answerLabel = Events.FAQ.addFAQ.answer;
    const addFAQLabel = Events.FAQ.addFAQ;
    return (
      <React.Fragment>
        <ContentGrid
          content={
            <Grid
              className={`${classes.createEventFAQGrid} relative-wrapper-component`}
            >
              <section
                className={classes.eventDetailsAboutMainWrapper}
                style={{ pointerEvents: loader ? "none" : "auto" }}
              >
                <EventHeader title={"FAQ"}>
                  <div className={classes.createEventFAQNewButtonWrapper}>
                    <Button
                      className={classes.createEventFAQAddFAQButton}
                      onClick={this.handModalOpen}
                    >
                      <span className={classes.createEventFAQAddText}>
                        {addFAQLabel.headline}
                      </span>
                      <AddIcon className={classes.createEventFAQPlusIcon} />
                    </Button>
                  </div>
                </EventHeader>
                <div className={classes.createEventFAQWrapper}>
                  <Modal open={modelOpen} onClose={this.handModalClose}>
                    <Box className={`${classes.createEventFAQModal}`}>
                      <div className={classes.createFAQAdAndDeleteModal}>
                        <div className="modalDetailsForm">
                          <div className={classes.createEventFAQModalHeader}>
                            <IconButton onClick={this.handModalClose}>
                              <Close height={30} width={30} />
                            </IconButton>
                            <p className={classes.createFAQModalHeader}>
                              {addFAQLabel.headline}
                            </p>
                            {this.addExtraSpaceToBalance()}
                          </div>
                          <div className={classes.createEventFAQFormWrapper}>
                            {this.enterFrequentlyAskedQuestionAndAnswerInputs(
                              questionLable,
                              answerLabel
                            )}
                          </div>
                        </div>
                        <div
                          className={classes.createEventFAQButtonWrapperModal}
                          id="eventCommonModal"
                        >
                          <Button
                            className={classes.createEventFAQCancelFAQButton}
                            onClick={this.cancelFaq}
                          >
                            {Text.Cancel}
                          </Button>
                          <Button
                            className={classes.createEventFAQSaveFAQButton}
                            onClick={this.addFaq}
                            variant={"contained"}
                            disabled={
                              !(
                                (
                                  currentFaq.currentQuestion &&
                                  currentFaq.currentQuestion.trim() &&
                                  currentFaq.currentAnswer
                                )
                                //  &&
                                // currentFaq.currentAnswer
                                //   .getCurrentContent()
                                //   .getPlainText("\u0001")
                                //   .trim()
                              )
                            }
                            style={{
                              backgroundColor: !(
                                (
                                  currentFaq.currentQuestion &&
                                  currentFaq.currentQuestion.trim() &&
                                  currentFaq.currentAnswer
                                )
                                // &&
                                // currentFaq.currentAnswer
                                //   .getCurrentContent()
                                //   .getPlainText("\u0001")
                                //   .trim()
                              )
                                ? "rgba(163, 56, 164,.4)"
                                : "rgb(163, 56, 164)",
                              color: "white",
                            }}
                          >
                            {Text.Save}
                          </Button>
                        </div>
                      </div>
                    </Box>
                  </Modal>
                  {this.checkFAQ(questionLable, answerLabel)}
                  <div className={classes.createEventFAQButtonWrapper}>
                    <Button
                      className={classes.createEventFAQSaveAndContinueButton}
                      variant={"contained"}
                      onClick={() => this.props.history.push("Agenda")}
                    >
                      {Text.SaveAndContinue}
                    </Button>
                  </div>
                </div>
              </section>
              {this.faqDeleteModal()}
              {this.progressLoader()}
            </Grid>
          }
        ></ContentGrid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const { eventUserEvents } = state;
  return { eventId: eventUserEvents.eventId, faqs: eventUserEvents.faqs };
};
const matchDispatchToProps = (dispatch) => {
  return bindActionCreators({ addFAQ, deleteFAQ, updateFAQ }, dispatch);
};
export default connect(
  mapStateToProps,
  matchDispatchToProps
)(withStyles(styles)(CreateEventFAQ));
