import React, { useState, useEffect } from "react";
// import styled from "styled-components";
import "./tickets.css";
import { useTable } from "react-table";
import FavoriteImage from "images/tickets1.jpg";
import Navbar from "../Components_UBMeEvents/Navbar/Navbar";
import { connect } from "react-redux";
import { GetOrdersByUserId } from "Actions/eventActions";
import * as constants from "Constants";
import { CircularProgress } from "@material-ui/core";
import UserMessagesModal from "./Messages";
import moment from "moment";
import Footer from "../Components_UBMeEvents/Footer/Footer";
import { removeEmptyDialog } from "Actions/messagesAction";
import { routeName } from "Service/env";
import { Redirect } from "react-router-dom";
import { default as momentTz } from "moment-timezone";
const Tickets = ({
  results,
  GetOrdersByUserId,
  dispatch,
  currentEventDetails,
  messageData,
}) => {
  const [OrdersByUser, setOrdersByUser] = useState([]); // use an empty array as initial value
  const [loading, setloading] = useState(false); // set a loader for api response
  const [messagesModalIsOpen, setMessagesModalIsOpen] = useState(false);
  const [organizerDetails, setOrganizerDetails] = useState("");

  useEffect(() => {
    if (results?.ProfileDetail?.UserId) {
      setloading(true);
      dispatch(GetOrdersByUserId(results?.ProfileDetail?.UserId)).then(
        (data) => {
          setOrdersByUser(data.results.ListOrders);
          setloading(false);
        }
      );
    }
  }, []);

  const handleOpenMessageModal = (data) => {
    setOrganizerDetails(data);
    setMessagesModalIsOpen(true);
  };


  const columns = React.useMemo(
    () => [
      {
        Header: "ORDER #",
        accessor: "OrderId",
        width: 200,
      },
      {
        Header: "EVENT",
        accessor: "Title",
        className: "bg-red",
        width: 350,

        Cell: ({ row }) => (
          <div>
            <a
              href={`${constants.URLs.baseUrl}/event/${row.original.EventId}`}
              target="_blank"
              style={{
                marginBottom: 10,
                fontSize: 14,
                textAlign: "center",
                color: "#7894f5",
              }}
            >
              {row.values.Title}
            </a>
          </div>
        ),
      },
      {
        Header: "EVENT DATE",
        accessor: "StartDateTime",
        width: 200,
        Cell: ({ row }) => {
          const UtcToLocal = moment
            .utc(row.original.StartDateTime)
            .local()
            .format("MM/DD/YYYY hh:mm:ss A");
          var Time = moment(UtcToLocal, "MM/DD/YYYY hh:mm:ss A");
          const convertedTime = Time.tz(row.original.EventTimeZone);
          return (
            <div>
              <p style={{ fontSize: 14, fontWeight: 500 }}>
                {`${moment(convertedTime).format("llll")}`}
              </p>
            </div>
          );
        },
      },
      {
        Header: "TOTAL",
        accessor: "Total",
        width: 100,
        Cell: ({ row }) => (
          <div>
            <p className="total_price_text">
              {Number(row.values.Total) === 0
                ? "Free"
                : `${row.original.CurrencySymbol}${Intl.NumberFormat(undefined, {
                    minimumFractionDigits: 2, 
                    maximumFractionDigits: 2,
                  }).format(row.values.Total)}`}
            </p>
          </div>
        ),
      },
        {
            Header: "TICKET TYPE",
            accessor: "OrderItems",
            width: 150,
            Cell: ({ row }) => {
                if (row.values.OrderItems) {
                    // Create a map to group items by ticketId
                    const groupedItems = row.values.OrderItems.reduce((acc, item) => {
                        if (!acc[item.TicketId]) {
                            acc[item.TicketId] = {title: item.Title, quantity: 0};
                        }
                        acc[item.TicketId].quantity += Number(item.Quantity);
                        return acc;
                    }, {});

                    // Iterate over the grouped items and display the summarized information
                    return (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                gap: 5,
                            }}
                        >
                            {Object.values(groupedItems).map((groupedItem, i) => (
                                <h5 key={i}>
                                    {`${groupedItem.title} - `}
                                    <span style={{fontWeight: 600}}>{`${groupedItem.quantity} Ticket${
                                        groupedItem.quantity > 1 ? "s" : ""
                                    }`}</span>
                                </h5>
                            ))}
                        </div>
                    );
                } else {
                    return (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                gap: 5,
                            }}
                        >
                            {row.values.OrderItems?.map((item, i) => (
                                <h5 key={i}>
                                    {`${item.Title} - `}
                                    <span style={{ fontWeight: 600 }}>{`${item.Quantity} Ticket${
                                        Number(item.Quantity) > 1 ? "s" : ""
                                    }`}</span>
                                </h5>
                            ))}
                        </div>
                    )
                }
            },
        },
      {
        Header: "DATE PURCHASED",
        accessor: "datepurcheshed",
        width: 200,
        Cell: ({ row }) => {
          const timeUTCtoLocal = (date, timezone) => {
            var local = momentTz
              .tz(moment.utc(date), "MM/DD/YYYY hh:mm:ss a", timezone)
              .format("MM/DD/YYYY hh:mm:ss a");
            return moment(local, "MM/DD/YYYY hh:mm:ss a");
          };

          const UtcToLocal =
            row.values.OrderItems?.length > 0 &&
            timeUTCtoLocal(
              row.values.OrderItems[0]?.CreatedDateTime,
              row.original.EventTimeZone
            ).format("MM/DD/YYYY hh:mm:ss A");
          return (
            <h5 style={{ marginBottom: 10 }}>
              {`${moment(UtcToLocal).format("llll")}`}
            </h5>
          );
        },
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            ACTION
          </div>
        ),
        accessor: "action",
        Cell: ({ row }) => (
          <div className="button_print">
            {row.original.Status === "Fully Refunded" ||
            row.original.Status === "Canceled" ? (
              <sapn target="_blank" className="button_print_ticktes_status">
                {row.original.Status}
              </sapn>
            ) : (
              <a
                href={`${row.original.PdfFile}`}
                target="_blank"
                className="button_print_ticktes"
              >
                {constants.Text.PrintTickets}
              </a>
            )}
            {row.original.OrginizerDetails.OrganizerId !=
            results?.ProfileDetail?.UserId ? (
              <a
                href="#"
                onClick={() =>
                  handleOpenMessageModal(row.original.OrginizerDetails)
                }
                className="button_print_ticktes"
              >
                {constants.Text.ContactOrganizer}
              </a>
            ) : null}
          </div>
        ),
      },
    ],
    []
  );

  function Table({ columns, data }) {
    // Use the state and functions returned from useTable to build your UI
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
      useTable({
        columns,
        data,
      });

    // Render the UI for your table
    return (
      <table className="tickets_table" {...getTableProps()}>
        <thead className="tickets_table_thead">
          {headerGroups.map((headerGroup, i) => (
            <tr
              key={i}
              style={{ backgroundColor: "#ffffff" }}
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column, i) => (
                <th
                  className="tickets_table_th"
                  key={i}
                  style={{
                    fontSize: 14,
                    paddingBottom: 20,
                    textAlign:
                      column.render("Header") == "TOTAL" ? "center" : "left",
                  }}
                  {...column.getHeaderProps()}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);

            const hasRecurringTicket = row?.original?.OrderItems?.some(
              (item) => item.RecurringTicket === "True"
            );
            // console.log({ row, hasRecurringTicket });

            return (
              <tr className="ticket-table-tr" key={i} {...row.getRowProps()}>
                {row.cells.map((cell, i) => {
                  return (
                    <td
                      key={i}
                      data-label={cell.column.Header}
                      className={`ticket_td text-white ${
                        cell.column.className ?? ""
                      }`}
                      {...cell.getCellProps({
                        style: {
                          width: cell.column.width,
                        },
                      })}
                    >
                      {/* Check if the column is "Event Date" and has a recurring ticket */}
                      {cell.column.Header === "EVENT DATE" && hasRecurringTicket
                        ? "Event date and time is listed on ticket"
                        : // Render the cell normally
                          cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  const closeUserMessagesModal = (data) => {
    if (Object.keys(messageData.selectedChat.last_message_json).length == 0) {
      dispatch(removeEmptyDialog(data));
    }
    localStorage.removeItem("curbsideCommunicationUserId");
    localStorage.removeItem("curbsideCommunicationUserName");
    localStorage.removeItem("curbsideCommunicationFullName");
    localStorage.removeItem("curbsideCommunicationProfileImage");

    setMessagesModalIsOpen(false);
  };

  return (
    <div>
      {localStorage.getItem("UserId") === null ? (
        <Redirect to={`${routeName}`} />
      ) : null}
      <Navbar />
      {messagesModalIsOpen ? (
        <UserMessagesModal
          {...{
            closeUserMessagesModal,
            messageData,
            UserId: results?.ProfileDetail?.UserId,
            organizer: organizerDetails,
            messagesModalIsOpen,
          }}
        />
      ) : null}
      {loading ? (
        <div
          style={{
            width: "100%",
            height: "90vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={50} color="secondary" />
        </div>
      ) : (
        <div>
          <div className="">
            <img
              style={{ width: "100%", height: "100%" }}
              src={FavoriteImage}
              alt=""
            />
          </div>
          <div className="table_container">
            {OrdersByUser?.length > 0 ? (
              <Table columns={columns} data={OrdersByUser} />
            ) : (
              <div
                style={{
                  width: "100%",
                  height: "40vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h1 style={{ color: "#808080", textAlign: "center" }}>
                  {Text}
                </h1>
              </div>
            )}
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    results: state.loginResponse.results,
    currentEventDetails: state.eventReducer.currentEventDetails,
    messageData: state.messageReducer,
  };
};

const matchDispatchToProps = (dispatch) => {
  return {
    GetOrdersByUserId,
    removeEmptyDialog,
    dispatch,
  };
};

export default connect(mapStateToProps, matchDispatchToProps)(Tickets);
