const { routeName, homeRoute } = require("../Service/env");

exports.ValidateName=(name)=>{
  let err = "";
  if (name == "") return err;
  name = name && name.trim();
  
  var fullNamePattern =/^(?![0-9._ ]*$)[a-zA-Z0-9._"' ]+$/;
  var FindSpacialCharacters = (name.match(/[@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g) || []).length;
  let fullName=[];
  let oneLetter = /[a-zA-Z]/;
  var expressionForSpace = /\s+/g;
  var spaceCount = (name.split(" ").length - 1);

  var expr =   /^[a-z\d\-._\s]+$/i;

  if(name.includes(" ")) {
      fullName = name.split(" ");
  }
  
  if(!oneLetter.test(name)){
      err = 'Must contain at least 1 letter'
  }

  

  if(name.length<2) {
      err = 'Your name must be greater than 2 characters';
  }
  else if(name.length>30) {
      err =  'Your name must have 30 characters or less';
  } else if(spaceCount > 1){
      err = 'Only one space is allowed'
  } else if(!expr.test(name)){
      err = 'You can use only use . & _ special character';
  } 
  // else if(!fullNamePattern.test(String(name).toLowerCase())){
  //     err =  'Enter Valid Name';
  // }
  else if(fullName.length>0) {
      fullName.forEach((nm)=>{
          if(nm.length>2) {
              if (!fullNamePattern.test(String(nm).toLowerCase())) {
                  err = 'Must contain at least 1 letter';
              }
          }
      })

  }
  return err;
};


exports.ValidateFirstAndLastName=(name)=>{
  let err = "";
  if (name == "") return err;
  name = name && name.trim();
  var onSpaceAllowed = /^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$/;
  var fullNamePattern =/^(?![0-9._ ]*$)[a-zA-Z0-9._"' ]+$/;
  var FindSpacialCharacters = (name.match(/[@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g) || []).length;
  let fullName=[];
  
  if(name.includes(" ")) {
      fullName = name.split(" ");
  }
  
  if(name.length<2) {
      err = 'Your name must be greater than 2 characters';
  }else if(FindSpacialCharacters > 1){
      err = 'You can use only one special character';
  }
  else if(name.length>30) {
      err =  'Your name must have 30 characters or less';
  }if(!onSpaceAllowed.test(name)){
      err =  'You can use only one space';
  }
  // else if(!fullNamePattern.test(String(name).toLowerCase())){
  //     err =  'Enter Valid Name';
  // }
  else if(fullName.length>0) {
      fullName.forEach((nm)=>{
          if(nm.length>2) {
              if (!fullNamePattern.test(String(nm).toLowerCase())) {
                  err = 'Each word must contain at least 1 letter';
              }
          }
      })

  }
  return err;
};

exports.validateUserName=(name)=>{
   let err = "";
   if (name == "") return err;
  name = name && name.trim();
  var userNamePattern =/^(?![0-9._ ]*$)[a-zA-Z0-9._]+$/;
 
  if(name.length<4) {
      err = 'Username must be greater than 3 characters';
  }else if(name.length>15) {
      err =  'User name must have 15 characters or less';
  }
  else if(name.includes(" ")) {
      err =  'User name can not have Space';
  }
  else if (!/[a-z]+/g.test(name.toLowerCase())) {
      err = "User name must contain at least one letter";
  }
  else if(!userNamePattern.test(String(name).toLowerCase())){
      err =  'Username can only have numbers and characters. It cannot contain any special characters.';
  }
  return err;
};

exports.validatePassword=(password)=>{
  let err = "";
  if (password == "") return err;
  password = password && password.trim();
  if(password.length<6) {
      err = 'Your password must be greater than 6 characters';
  }else if(password.length>16) {
      err =  'Your password must have 16 characters or less';
  }
  else if(password.includes(" ")){
      err =  'Password must not have space';
  }
  return err;
};


exports.validateEmail=(email)=> {
   let err = "";
  if (email == "") return err;
  email = email && email.trim();
  let emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if(email==='')
      return 'Must Enter Email.';
  else if(!emailPattern.test(String(email).toLowerCase()))
      return 'Must be a valid email format';
  else
      return err;
};

exports.validatePhoneNo = (phoneNo) =>   {
  let err = "";
  if (phoneNo.length === 0) return err;
  phoneNo = phoneNo.replace(/\D/g,'');
  if(phoneNo.length === 0)
      return 'Must Enter Phone No';
  else
      return phoneNo.length < 10 || phoneNo.length > 10 ? 'Please enter a valid 10-digit phone number' : err;
};

exports.validateBusinessName = (businessName) =>    {

  let err = '';
  if(businessName==='')
       err = 'Must Enter Business Name';
  else if (businessName.length < 6)
      err = 'Your Business Name must have at least 6 characters';
  return err;
};
exports.validateDate=(date)=>{
  let val = date.split('/');
  let d=Number(val[0]);
  let m=Number(val[1]);
  let y=Number(val[2]);
let today=new Date();
  var ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
  if(y === today.getFullYear() || y === today.getFullYear()+1 ) {
    if (m === 1 || m > 2 || m < 12) {
      if (d > ListofDays[m - 1]) {
        return false
      }
      if (m === 2) {
        let lyear = false
        if ((!(y % 4) && y % 100) || !(y % 400)) {
          lyear = true
        }
        if ((lyear === false) && (d >= 29)) {
          return false
        }
        if ((lyear === true) && (d > 29)) {
          return false
        }
      }
      else if (m > 12) {
        return false;
      }
      else {
        return true;
      }
    }
  }
  else{
   return false;
  }

};


exports.checkIfEventExists = (history, id) => {
  // get isItCreateOrEditEvent from local storage
  const isItCreateOrEditEvent = localStorage.getItem("isItCreateOrEditEvent");

  if (
    isItCreateOrEditEvent === "undefined" ||
    isItCreateOrEditEvent === undefined ||
    isItCreateOrEditEvent === null ||
    isItCreateOrEditEvent === "" ||
    (isItCreateOrEditEvent === "Edit" && !id)
  ) {

    history.push(`/${homeRoute}`);
    localStorage.setItem("isItCreateOrEditEvent", "undefined");
    return false;
  }
  return true;
};

// exports.IsEventPublished = (EventID) => {

// }

exports.validateBusinessName = (businessName) => {
  let err = "";
  if (businessName === "") err = "Must Enter Business Name.";
  else if (businessName.length < 6)
    err = "Your Business Name must have at least 6 characters";
  return err;
};