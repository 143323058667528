import React, { useEffect, useRef, useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { BiCurrentLocation } from "react-icons/bi";
import axios from "axios";
import { API } from "Constants";
import * as constants from "Constants";
import UseOutsideClick from "../../../Components_UBMeEvents/OutSideClick/UseOutsideClick";
import {
  LatLongData,
  SyncLatLongDataV1,
  SyncLatLongDataV2,
} from "Actions/eventActions";
import { connect } from "react-redux";

const GooglePlaces = ({
  dispatch,
  styles,
  LatLongData,
  SyncLatLongDataV1,
  SyncLatLongDataV2,
  GetEventData = () => { },
  LatitudeSyncv2,
  latlng,
  address,
}) => {
  const [selected, setSelected] = useState(false);
  // const [address, setAddress] = useState("");
  const [placeHolder, setPlaceHolder] = useState(constants.Text.searchplaces);
  const inputRef = useRef(null);
  const onPressInput = (event) => {
    event.stopPropagation();
    if (address === "") {
      setPlaceHolder(placeHolder);
    } else {
      setSelected(true);
      dispatch({ type: "CITY_ADDRESS", payload: "" });
      // setAddress("");
      setPlaceHolder(address);
      inputRef.current.focus();
    }
  };

  UseOutsideClick(
    () => {
      if (selected) {
        // setSelected(false);
        dispatch({ type: "CITY_ADDRESS", payload: placeHolder });
        // setAddress(placeHolder);
      }
    },
    ".Menu-list-modal",
    inputRef
  );

  const ExtractAddress = (place) => {
    const newArray = place.address_components.filter((x) => {
      const newArr = x.types.includes("locality")
        ? x.types.includes("locality")
        : x.types.includes("administrative_area_level_1") ||
        x.types.includes("country");
      return newArr;
    });

    return {
      city: newArray[0]?.long_name,
      country: newArray[newArray.length - 1]?.long_name,
    };
  };

  async function success(response, initial, currLocation) {
    let latlong = response.data.loc.split(",");

    var GEOCODING =
      API.Geolocation +
      Number(latlong[0]) +
      "%2C" +
      Number(latlong[1]) +
      "&key=" +
      API.GOOGLE_API_KEY +
      "&language=en";
    try {
      const { data } = await axios.get(GEOCODING);
      const { city } = ExtractAddress(data.results[0]);
      dispatch({ type: "CITY_ADDRESS", payload: city });
      // setAddress(city);

      if (initial) {
        GetEventData({
          lat: Number(latlong[0]),
          lng: Number(latlong[1]),
        });

        dispatch(
          LatLongData({
            lat: Number(latlong[0]),
            lng: Number(latlong[1]),
          })
        );
      }

      // if (currLocation) {
      //   dispatch(
      //     SyncLatLongDataV1({
      //       lat: Number(latlong[0]),
      //       lng: Number(latlong[1]),
      //     })
      //   );
      // }
    } catch (error) { }
  }

  const getData = async (currLocation) => {


    let latlongdata = JSON.parse(sessionStorage.getItem("latlong"));
    if (currLocation) {
      const response = await axios.get(constants.API.Geolocation_V2);
      let initial = true;
      success(response, initial);
      sessionStorage.removeItem("latlong");
    } else if (latlongdata !== null) {
      let initial = true;

      success(latlongdata, initial);
    } else {
      const response = await axios.get(constants.API.Geolocation_V2);
      let initial = true;
      success(response, initial, currLocation);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      let currLocation = false;
      getData(currLocation);
    }, 20);
  }, []);

  //   useEffect(() => {
  //     console.log("latlng",latlng);
  // // console.log("LatitudeSyncv1.lat",LatitudeSyncv1.lat);
  // //     if (LatitudeSyncv1.lat === 0) {

  //       // let latlongData = {
  //       //   data: {
  //       //     loc: `${latlng.lat},${latlng.lng}`,
  //       //   },
  //       // };
  //       // success(latlongData ,false);
  // //     }
  //   dispatch({type:"CITY_ADDRESS",  payload:address})
  //   }, [latlng]);
  const handleSelect = async (address) => {
    const results = await geocodeByAddress(address);

    const { lat, lng } = await getLatLng(results[0]);
    const latlong = {
      lat: Number(lat).toFixed(4),
      lng: Number(lng).toFixed(4),
    };

    let latlongData = {
      data: {
        loc: `${latlong.lat},${latlong.lng}`,
      },
    };

    sessionStorage.setItem("latlong", JSON.stringify(latlongData));
    GetEventData(latlong);
    dispatch(LatLongData(latlong));

    const { city } = ExtractAddress(results[0]);
    dispatch(SyncLatLongDataV1(latlong));
    inputRef.current.blur();
    dispatch({ type: "CITY_ADDRESS", payload: city });
    // setAddress(city);
    setSelected(false);
  };

  const onFocus = () => { };

  return (
    <PlacesAutocomplete
      value={address}
      onChange={(e) => dispatch({ type: "CITY_ADDRESS", payload: e })}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div style={{ position: "relative", width: "100%" }}>
          <div onClick={(e) => onPressInput(e)} style={{ cursor: "pointer" }}>
            <h3 style={{ position: "absolute", top: -10, left: 0 }}>
              <i class="fa-solid fa-angle-down"></i>
            </h3>
            <input
              {...getInputProps({
                ref: inputRef,
                onFocus: () => {
                  onFocus();
                },
                placeholder: `${placeHolder}`,
                className: `${styles.location_search_input} Menu-list-modal`,
                autoComplete: `search`,
              })}
            />
          </div>
          <div className={styles.autocomplete_dropdown_container}>
            {selected ? (
              <div
                onClick={(event) => {
                  try {
                    event.stopPropagation();

                    let currLocation = true;
                    getData(currLocation);
                    setSelected(false);
                  } catch (error) {
                    console.error("Error in onClick handler:", error);
                  }
                }}
                className={styles.suggestion_item_my_location}
              >
                <BiCurrentLocation
                  className={styles.suggestion_item_my_location_icon}
                />
                <span>{constants.Text.usemylocation}</span>
              </div>
            ) : null}

            {suggestions.map((suggestion, index) => {
              const className = suggestion.active
                ? `${styles.suggestion_item_active}`
                : `${styles.suggestion_item}`;
              // inline style for demonstration purpose

              const style = suggestion.active
                ? { backgroundColor: "#fafafa", cursor: "pointer" }
                : { backgroundColor: "#ffffff", cursor: "pointer" };
              return (
                <div
                  onClick={(event) => {
                    event.stopPropagation();
                    setSelected(false);
                  }}
                  {...getSuggestionItemProps(suggestion, {
                    className,
                    style,
                  })}
                  key={suggestion.placeId}
                >
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

const mapStateToProps = (state) => {
  return {
    latlng: state.favoriteReducer.latlong,
    LatitudeSyncv2: state.favoriteReducer.LatitudeSyncv2,
    address: state.favoriteReducer.address,
  };
};
const matchDispatchToProps = (dispatch) => {
  return {
    LatLongData,
    SyncLatLongDataV1,
    SyncLatLongDataV2,
    dispatch,
  };
};
export default connect(mapStateToProps, matchDispatchToProps)(GooglePlaces);
