import React from "react";
import styles from "./Sponsors.module.css";
import { axiosE } from "Service/service";
import { Text } from "Constants";
const Sponsors = ({ currentEventDetails }) => {

  const InsertEngagementClickthrough = (typeId) => {
    try {
      const {data} = axiosE.post("/Event/InsertEngagementClickthrough",
      {
        EventId: currentEventDetails.EventId,
        ClickthroughType: "Sponsor",
        Interaction: "URL",
        Platform: "Web",
        TypeId:typeId
    }
      )

    } catch (error) {
      
    }
  }


  return (
    <>
      <div className={styles.sponsors__heading}>
        <h4 style={{ fontWeight: 600 }}>{Text.ThankYouToOurSponsors}</h4>
      </div>

      <div className={styles.sponsors__Container}>
        {currentEventDetails.SponsorData.map((item, index) => (
          <div key={index} className={styles.sponsors__content}>
            <a
              onClick={()=>InsertEngagementClickthrough(item.SponsorId)}
              style={{ display: "contents" }}
              href={
                  item?.Url.substring(0, 8) === "https://" ||
                  item?.Url.substring(0, 7) === "http://" ?
                  `${item?.Url}`:
                  `https://${item.Url}` 
              }
              target="_blank"
            >
              <img src={item.Logo} className={styles.sponsors_Images} />
            </a>
          </div>
        ))}
      </div>
    </>
  );
};

export default Sponsors;
