import React from 'react';
import {connect} from 'react-redux';
import './loading.css';
import * as constants from  "Constants";

class LoginLoader extends React.Component{
    render() {
        return (
            <React.Fragment>
                {
                    this.props.data.show &&
                    <React.Fragment>
                        <h4 className="loder-message">{constants.Message.SeveralMinutes}</h4>
                        <div className="Login-loder">
                        </div>
                    </React.Fragment>
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) =>{
    return {
        data: state.loaderReducer
    };
};
export default connect(mapStateToProps,null)(LoginLoader);
