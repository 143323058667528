import moment from "moment";
import React from "react";
import * as constants from "Constants";
import { FiClock } from "react-icons/fi";
const EmailInvitationView = ({
  base64img,
  data,
  files,
  editorHtml1,
  facebook,
  instagram,
  twitter,
  StartDateTime,
  organizer,
  address1,
  address2,
  city,
  region,
  country,
  zip,
  UserId,
  mainEventId,
}) => {
  const isRecurring = data?.some((d) => d.RecurringTicket === "True");
  return (
    <>
      <div className="container-email">
        <div className="wrapper-1">
          <div className="img_wrapper card_wrapper">
            {base64img !== "" && (
              <img
                alt="Preview"
                key={base64img}
                src={base64img}
                className="header_img"
                // style={previewStyle}
              />
            )}

            {/* {files.map((file) => (
              <img
                alt="Preview"
                key={file.preview}
                src={file.preview}
                className="header_img"
                // style={previewStyle}
              />
            ))} */}
            {/* <img
              className="header_img"
              src="https://www.seevividly.com/images/m_Optometric_Extension_Program_Foundation_LOGO.jpeg"
            /> */}
          </div>
          <div className="wrapper_header_text card_wrapper">
            {editorHtml1 !== "" ? (
              <div dangerouslySetInnerHTML={{ __html: editorHtml1 }} />
            ) : (
              <>
                <h2>{constants.Text.LetsToGather}</h2>

                <p>
                  {constants.Text.HoistingNewEvent} {data && data[0]?.Title}
                  {!isRecurring && ", "}
                  {!isRecurring && StartDateTime && StartDateTime}
                </p>
                <p>{constants.Text.LimitedSpace}</p>
                <p>{constants.Text.JoinUs}</p>
              </>
            )}
          </div>
          {data?.map((d, i) => (
            <div
              key={i}
              style={{ marginBottom: "30px" }}
              className="card_wrapper"
            >
              <div className="card">
                <div className="card__img">
                  <img src={d?.ImageSmall || d?.SmallImage} />
                </div>
                <div className="card__content">
                  <div className="card_content_text">
                    {!d?.RecurringTicket && (
                      <span>
                        <FiClock color="#848484" size={20} />
                      </span>
                    )}
                    <div className="card__kicker">
                      {!d?.RecurringTicket && <p>{d?.StartDateTime}</p>}
                      <h5
                        className="card__title"
                        style={{ paddingRight: isRecurring ? "20px" : "0" }}
                      >
                        {d?.Title}
                      </h5>
                    </div>
                  </div>

                  <div className="card__link">
                    <a
                      target="_blank"
                      href={`${constants.URLs.baseUrl}/event/${d?.EventId}`}
                      className="card__link_button"
                    >
                      {constants.Text.Register}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))}

          <div className="app_container card_wrapper">
            <div>
              <div className="experience_text">
                <img
                  className="star_img"
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/99/Star_icon_stylized.svg/807px-Star_icon_stylized.svg.png"
                />
                <h4>{constants.Text.EnhancedExperienceAvailable}</h4>
              </div>
              <p>{constants.Text.CheckIn}</p>
            </div>
          </div>
          <div className="app_store">
            <a
              href="https://play.google.com/store/apps/details?id=com.UBMe&hl=en_IN&gl=US"
              target="_blank"
            >
              <img
                className="play_store_img"
                src="https://d2gnb7jphto2jj.cloudfront.net/img/play_store.png"
                alt=""
              />
            </a>
            <a
              href="https://apps.apple.com/us/app/ubme/id1200370709"
              target="_blank"
            >
              <img
                className="app_store_img"
                src="https://d2gnb7jphto2jj.cloudfront.net/img/App_store.png"
                alt=""
              />
            </a>
          </div>
        </div>

        <div className="email_footer">
          {/* social icons */}
          <div className="socialIcons">
            {facebook && (
              <a
                style={{ marginTop: "20px", marginBottom: "10px" }}
                href={facebook}
                target="_blank"
              >
                <img
                  className="facebookImg"
                  src="https://www.bestha.co.uk/images/facebook.png"
                  alt=""
                />
              </a>
            )}
            {instagram && (
              <a
                href={instagram}
                style={{ marginTop: "20px", marginBottom: "10px" }}
                target="_blank"
              >
                <img
                  className="instagramImg"
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/95/Instagram_logo_2022.svg/2048px-Instagram_logo_2022.svg.png"
                  alt=""
                />
              </a>
            )}
            {twitter && (
              <a
                href={twitter}
                style={{ marginTop: "20px", marginBottom: "10px" }}
                target="_blank"
              >
                <img
                  className="twitterImg"
                  src="https://pbs.twimg.com/media/FM6j9MKWQAI3aXP.png"
                  alt=""
                />
              </a>
            )}
          </div>
          <br />
          <p style={{ margin: 0 }}>{organizer}</p>
          <p style={{ margin: 0 }}> {address1}</p>
          <p style={{ margin: 0 }}>{address2}</p>
          <p style={{ marginBottom: 10 }}>
            {city}
            {!city ? "" : ","} {region} {zip}
            {!zip ? "" : ","} {country}
          </p>
          <p style={{ color: "rgb(0, 102, 255)", fontWeight: 600 }}>
            {constants.Text.Unsubscribe}
          </p>
          <br />
        </div>
      </div>
    </>
  );
};

export default EmailInvitationView;
