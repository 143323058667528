import React, {Component} from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  Modal,
  TextField,
  Typography,
  withStyles,
  Collapse,
  Grid,
  InputAdornment,
  Input,
  InputLabel
} from "@material-ui/core";
import {TransitionGroup} from "react-transition-group";
import TicketLayout, {ContentLayout} from "./layout";
import EventHeader from "../EventDetails/eventHeader";
import {styleClasses} from "./ticketsStyleClasses";
import {eventApis, Events, Text, Tickets} from "../../../../Constants";
import {
  AddBox,
  AvTimerRounded,
  Close,
  Create,
  Delete,
} from "@material-ui/icons";
import moment, {isMoment} from "moment";
import TimePicker from "rc-time-picker";
import DateAndTime from "./dateAndTime";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {
  addTicket as addTicketAction,
  deleteTicket as deleteTicketAction,
  setEventType,
  updateTicket as updateTicketAction,
} from "../../../../Actions/eventActions";
import {axiosE} from "../../../../Service/service";
import {default as momentTz} from "moment-timezone";
import loader from "../../../../Helper/loader/loader";
import Loader from "../../../../Helper/loader/loader";
import CreateEventErrorMessage from "../createEventErrorMessage";
import AddIcon from "@material-ui/icons/Add";
import {number} from "prop-types";
import {checkIfEventExists} from "../../../../Helper/Validations";
import MultipleSelectCheckmarks from "./MultipleSelectCheckmarks";

const momentTimeZone = require("moment-timezone");

const styles = {
  eventCreateTicketContentWrapper: styleClasses.eventCreateTicketContentWrapper,
  addButton: styleClasses.addButton,
  createButtonAddText: styleClasses.createButtonAddText,
  createButtonPlusIcon: styleClasses.createButtonPlusIcon,
  emptyState: styleClasses.emptyState,
  generateHeight: styleClasses.generateHeight,
  // Save And Continue Button
  saveAndContinueButton: styleClasses.saveAndContinueButton,
  saveAndContinueButtonWrapper: styleClasses.saveAndContinueButtonWrapper,
  // Add Modal Styles Starts
  addUpdateModal: styleClasses.addUpdateModal,
  addUpdateModalMainWrapper: styleClasses.addUpdateModalMainWrapper,
  addUpdateModalHeader: styleClasses.addUpdateModalHeader,
  addUpdateModalHeaderText: styleClasses.addUpdateModalHeaderText,
  addUpdateModalButtonWrapper: styleClasses.addUpdateModalButtonWrapper,
  addUpdateModalButtonCancel: styleClasses.addUpdateModalButtonCancel,
  addUpdateModalButtonSave: styleClasses.addUpdateModalButtonSave,
  // Main Screen Content Wrapper
  mainContentWrapper: styleClasses.mainContentWrapper,

  inputLabel: styleClasses.inputLabel,
  inputTextField: {
    border: "1px solid #ced4da",
    borderRadius: "5px",
    fontSize: "16px",
    padding: "10px",
    margin: "10px 0px 15px 0px",
    width: "100%",
  },
  ticketTypeBox: {
    width: "80px",
    height: "50px",
    borderRadius: "9px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    marginRight: "15px",
    border: "1px solid #ced4da",
  },
  selectedTicketTypeBox: {
    backgroundColor: "rgb(182, 14, 181)",
    color: "white",
  },
  calculation: {
    padding: "10px 0px 5px",
    borderBottom: "1px solid #ced4da",
    marginBottom: "15px",
  },
  calculationLabel: {
    fontSize: "16px",
    fontWeight: "600",
    marginBottom: "20px",
  },
  calculationWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "10px 0px",
    padding: "0 10px",
  },
  calculationTotal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "10px 0px",
    color: "white",
    borderRadius: "15px",
    padding: "10px",
    fontSize: "16px",
  },
  formControlLabel: {
    fontSize: "16px",
  },
  flexCenterCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: "10px",
  },
  ticketWrapper: {
    display: "flex",
    justifyContent: "space-between",
    padding: "22px",
    border: "1px solid #ced4da",
    borderRadius: "10px",
    "@media (max-width: 480px)": {
      display: "block",
      marginTop: "10px",
      textAlign: "start",
      alignItems: "start",
      padding: "15px",
    },
  },
  ticketCardColumnOne: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "start",
    gap: "15px",
  },
  ticketCardColumnTwo: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-end",
    "@media (max-width: 480px)": {
      flexDirection: "row-reverse",
      marginTop: "10px",
      textAlign: "start",
      alignItems: "start",
    },
  },
  ticketCardSpecialFlag: {
    padding: "3px 30px",
    color: "white",
    borderRadius: "10px",
    marginRight: "10px",
  },
  ticketCardButtonWrapper: {
    display: "flex",
    gap: "20px",
    opacity: 0.8,
  },
  // DELETE MODAL
  deleteModalMainWrapper: {
    ...styleClasses.deleteModalMainWrapper,
    width: "auto",
    minWidth: "430px",
  },
  deleteModalImgWrapper: styleClasses.deleteModalImgWrapper,
  deleteModalImg: styleClasses.deleteModalImg,
  deleteModalSaveAndCancelButton: styleClasses.deleteModalSaveAndCancelButton,

  // Add Button
  addButtonWrapper: styleClasses.addButtonWrapper,
};

class CreateTickets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addModal: false,
      currentTicket: {
        name: "",
        description: "",
        price: 0,
        quantity: "",
        type: 1,
        absorbFee: false,
        startDate: moment(),
        endDate: this.props.eventsAbout && moment(this.timeUTCtoLocal(this.props.eventsAbout.EndDateTime), "MM/DD/YYYY hh:mm:ss A"),
        startTime: moment(),
        endTime: this.props.eventsAbout && moment(this.timeUTCtoLocal(this.props.eventsAbout.EndDateTime), "MM/DD/YYYY hh:mm:ss A"),
        buyerFee: "",
        AdditionalFee: "",
        status: "Active",
        ticketSold: 0,
        eventRecurring: "False",
        recurringQuantity: "",
        recurringBundleQuantity: "1",
        isBundle: "False",
        selectDays: [],
        selectedTimeSlot: "0",
        customField: 0,
        showCustom: false,
        recurringStartTime: moment(),
        recurringEndTime: moment(),
        disabledRecurring: true,
        isTicketSlod: false,
        availableSlots: 0,
      },
      tickets: [],
      editTicketModal: {show: false, index: null},
      deleteTicketModal: {show: false, index: null},
      loading: false,
      error: {show: false, message: ""},
      disableButton: false,
      priceError: false,
      selectDaysError: false,
      selectedTimeSlotError: false,
      priceErrorMessage: "",
      deleteError: {show: false, messageTitle: "", messageBody: ""},
      quantityError: false,
      quantityErrorMessage: "",
      recurringQuantityError: false,
      recurringQuantityErrorMsg: "",
      recurringBundleQuantityError: false,
      recurringBundleQuantityErrorMsg: "",
    };
  }

  handleError = () => {
    this.setState({error: {show: false, message: ""}});
  };

  errorMessage = () => (
      <CreateEventErrorMessage
          state={this.state.error}
          close={this.handleError}
      />
  );

  checkEventIsExpired = () => {
    const About = this.props.eventsAbout;
    const EndDateTime = About ? About.EndDateTime : null;

    if (this.timeUTCtoLocal(EndDateTime).isBefore(moment())) {
      this.setState({
        loading: false,
        error: {
          show: true,
          message: "Event Expired! Change event end date or create a new Date",
          disableButton: true,
        },
      });
    }
  };

  convertTimezone = (utcTime) => {
    const {TimeZone} = this.props.eventsAbout;
    // Use moment.js to parse the UTC time and set the timezone
    const utcDateTime = moment.utc(utcTime, "HH:mm:ss");
    const targetDateTime = utcDateTime.clone().tz(TimeZone);

    // Format the target datetime as "HH:mm A"
    const formattedTime = targetDateTime.format("hh:mm A");
    return formattedTime;
  };

  GetTicketsByEventID = (eventId) => {
    axiosE(`${eventApis.getTicketByEventId}${eventId}`).then((res) => {
      const tickets = res.data.results.ListTicket;

      if (tickets && tickets.length > 0) {
        const isRecurring = tickets?.some(
            (ticket) =>
                (ticket?.RecurringTicket === "True" ||
                    ticket?.RecurringTicket === "true") &&
                ticket.Status !== "Deleted"
        );
        localStorage.setItem("isRecurring", isRecurring);


        const CurrentTimeZone = JSON.parse(
            localStorage.getItem("CurrentTimeZone")
        );

        this.setState({
          tickets: tickets.map((ticket, index) => ({
            TicketId: ticket.TicketId,
            name: ticket.Title,
            description: ticket.Description,
            price: parseInt(ticket.TicketType)
                ? /true/i.test(ticket.isAbsorb)
                    ? Number(ticket.BillingAmount).toFixed(2)
                    : Number(ticket.BillingAmount).toFixed(2)
                : // : this.getNetPriceBeforeTax(
                  //     ticket.BillingAmount,
                  //     ticket.TaxAmount
                  //   )
                0,

            quantity:
                ticket.RecurringTicket !== "True" ? ticket.TotalTicketIssue : "",
            type: parseInt(ticket.TicketType),
            absorbFee: /true/i.test(ticket.isAbsorb),
            startDate: this.timeUTCtoLocal(ticket.ActiveDate),
            endDate: this.timeUTCtoLocal(ticket.InActiveDate),
            startTime: this.timeUTCtoLocal(ticket.ActiveDate),
            endTime: this.timeUTCtoLocal(ticket.InActiveDate),

            eventRecurring: ticket.RecurringTicket,
            customField: ![15, 30, 45, 60].includes(
                Number(ticket?.RecurringInterval)
            )
                ? ticket.RecurringInterval
                : 0,
            showCustom: ![0, 15, 30, 45, 60].includes(
                Number(ticket?.RecurringInterval)
            )
                ? true
                : false,
            recurringQuantity:
                ticket.RecurringTicket === "True"
                    ? parseInt(ticket.TotalTicketIssue)
                    : "",
            selectDays:
                ticket.RecurringDays !== ""
                    ? ticket.RecurringDays?.split(",")
                    : [],
            selectedTimeSlot: `${ticket.RecurringInterval} min`,
            recurringStartTime:
                ticket.RecurringStartTime === ""
                    ? this.timeUTCtoLocal(ticket.ActiveDate)
                    : this.convertTimezone(ticket.RecurringStartTime),
            recurringEndTime:
                ticket.RecurringEndTime === ""
                    ? this.timeUTCtoLocal(ticket.InActiveDate)
                    : this.convertTimezone(ticket.RecurringEndTime),
            // buyerFee: ticket.TaxAmount / 100,
            AdditionalFee: this.state.currentTicket.AdditionalFee,
            buyerFee: this.state.currentTicket.buyerFee,
            status: ticket.Status,
            ticketSold: parseInt(ticket.TicketSold),
            availableSlots: parseInt(ticket.AvailableSlots),
            recurringBundleQuantity: ticket.RecurringTicket === "True" ? (ticket.SlotPerBundle === "" ? 1 : ticket.SlotPerBundle) : "",
            isBundle: ticket.isBundle,
          })),
          loading: false,
        });
      } else {
        this.setState({loading: false});
      }
    });
  };

  componentDidMount() {
    const { eventId, eventsAbout } = this.props;
    const { payouts } = this.props;

    checkIfEventExists(this.props.history, this.props.eventId);
    this.setState({
      loading: true,
    });

    this.setState({
      currentTicket: {
        ...this.state.currentTicket,
        AdditionalFee: Number(localStorage.getItem("additionalFee")),
        buyerFee: Number(localStorage.getItem("buyerFee")) / 100,
      },
    });

    // Check if Event Date is Passed
    this.checkEventIsExpired();

    this.GetTicketsByEventID(eventId);

  }

  componentDidUpdate(prevProps, prevState) {
    // Check if the value of b has changed
    if (this.state.addModal !== prevState.addModal) {
      this.setState({
        ...this.state,
        currentTicket: {
          ...this.state.currentTicket,
          eventRecurring: "False",
          selectedTimeSlot: "",
          recurringQuantity: "",
          recurringBundleQuantity: "1",
          isBundle: "False",
          selectDays: [],
          customField: 0,
          showCustom: false,
          recurringStartTime: moment(),
          recurringEndTime: moment(),
          disabledRecurring: false,
        },
      });
    }

    if (
        this.state.addModal !== prevState.addModal ||
        this.state.editTicketModal.show !== prevState.editTicketModal.show
    ) {
      this.setState((prevState) => ({
        ...prevState,
        error: {...prevState.error, show: false, message: ""},
        priceError: false,
        selectDaysError: false,
        selectedTimeSlotError: false,
        priceErrorMessage: "",
        quantityError: false,
        quantityErrorMessage: "",
        recurringQuantityError: false,
        recurringQuantityErrorMsg: "",
      }));
    }

    if (this.state.editTicketModal.show !== prevState.editTicketModal.show) {
      this.validateRecurring("selectDays");
      this.validateRecurring("selectedTimeSlot");
      this.setState({
        ...this.state,
        currentTicket: {
          ...this.state.currentTicket,
          disabledRecurring: true,
          isTicketSlod: this.state.currentTicket.ticketSold > 0,
        },
      });
    }
  }

  getNetPriceBeforeTax = (price, tax) => {
    const preTaxed = (Number(price) * 100) / (100 + Number(tax));
    return Intl.NumberFormat(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(preTaxed);
  };

  checkIfDecimalAndFix = (price) => {
    const numberPrice = Number(price);
    if (numberPrice % 1 != 0) {
      return Intl.NumberFormat(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(numberPrice);
    } else {
      return numberPrice;
    }
  };

  timeUTCtoLocal = (date, check = false) => {
    const About = this.props.eventsAbout;
    let TimeZone = About ? About.TimeZone : null;

    var local = momentTz
        .tz(moment.utc(date), "MM/DD/YYYY hh:mm:ss a", TimeZone)
        .format("MM/DD/YYYY hh:mm:ss a");
    if (check) console.log("LOCAL TIME", local, TimeZone, date);
    return moment(local, "MM/DD/YYYY hh:mm:ss a");
  };

  checkDeletePossibility = (index) => {
    // console.log("#########",index);
    const {tickets} = this.state;
    const {payouts} = this.props;
    const activeTickets = tickets.filter((ticket) => ticket.TicketId);
    const noOfFreeTickets = activeTickets.filter(
        (ticket) => ticket.type === 0 && ticket.status !== "Deleted"
    ).length;
    // console.log("$$$$$$$$$$$$$$$", noOfFreeTickets , activeTickets[index].type === 0,   typeof payouts === "undefined");
    const {eventPublish} = this.props;
    if (eventPublish && activeTickets.length === 1) {
      this.handleDeleteError(
          true,
          Tickets.createTickets.deleteError,
          Tickets.createTickets.lastTicketError
      );
      return false;
    } else if (
        eventPublish &&
        typeof payouts === "undefined" &&
        noOfFreeTickets === 1 &&
        activeTickets[index].type === 0
    ) {
      this.handleDeleteError(
          true,
          Tickets.createTickets.deleteError,
          Tickets.createTickets.ticketPaymentError
      );
      return false;
    } else {
      return true;
    }
  };
  setDeleteModalState = (show, index = -1) => {
    const canDelete = index === -1 ? true : this.checkDeletePossibility(index);
    if (canDelete) {
      this.setState({
        deleteTicketModal: {show, index},
      });
    }
  };

  setEventTypeOnDelete = (index, tickets) => {
    const noOfPaidTickets = tickets.filter(
        (ticket) => ticket.type === 1
    ).length;
    const noOfFreeTickets = tickets.filter(
        (ticket) => ticket.type === 0
    ).length;

    if (tickets.length === 1) {
      this.props.setEventType({type: ""});
    } else if (
        noOfPaidTickets === 1 &&
        tickets[index].type === 1 &&
        noOfFreeTickets > 0
    ) {
      this.props.setEventType({type: "Free"});
    }
  };

  deleteTicket = async (index) => {
    const {tickets} = this.state;
    this.setState({loading: true});

    // Set Event Type
    this.setEventTypeOnDelete(index, tickets);
    if (Number(tickets[index].ticketSold) !== 0) {
      await this.deleteTicketRemote(tickets[index].TicketId);
    } else {
      await this.deleteTicketRemote(tickets[index].TicketId);
    }

    this.setState({
      tickets: tickets.filter((ticket, i) => i !== index),
      deleteTicketModal: {show: false, index: null},
    });
  };

  deleteTicketRemote = async (id) => {
    const {deleteTicketAction, eventId} = this.props;
    deleteTicketAction({TicketId: id}).then((res) => {
      this.GetTicketsByEventID(eventId);
      // this.setState({ loading: false });
    });
  };

  addButton = (title) => {
    const {classes} = this.props;
    const {disableButton} = this.state;
    return (
        !disableButton && (
            <div className={classes.addButtonWrapper}>
              <Button
                  className={classes.addButton}
                  onClick={() => this.toggleAddModal(true)}
              >
                <span className={classes.createButtonAddText}>Add {title}</span>
                <AddIcon className={classes.createButtonPlusIcon}/>
              </Button>
            </div>
        )
    );
  };

  emptyState = () => {
    const {classes} = this.props;
    const {createTickets} = Tickets;

    return (
        <div className={classes.emptyState}>
          <img
              src={createTickets.emptyTickets.img}
              alt="empty"
              style={{objectFit: "contain", width: "150px"}}
          />
          <h1>{createTickets.emptyTickets.headline}</h1>
          <p>{createTickets.emptyTickets.message}</p>
        </div>
    );
  };

  //set eventType
  setEventTypeRemote = (type) => {
    const {setEventType, eventType} = this.props;
    if (!eventType || eventType === "") {
      if (type === 1) {
        setEventType({type: "Paid"});
      }
      if (type === 0) {
        setEventType({type: "Free"});
      }
    } else if (eventType === "Free" && type === 1) {
      setEventType({type: "Paid"});
    }
  };

  addTicket = async () => {
    const {currentTicket} = this.state;
    this.setState({loading: true});
    this.setEventTypeRemote(currentTicket.type);
    const { TicketId, TotalTicketIssue } = await this.addTicketRemote();

    this.setState({
      tickets: [
        ...this.state.tickets,
        {...currentTicket, TicketId, TotalTicketIssue},
      ],
      currentTicket: {
        ...this.currentDefault,
        buyerFee: this.state.currentTicket.buyerFee,
        AdditionalFee: this.state.currentTicket.AdditionalFee,
      },
      addModal: false,
      loading: false,
    });
  };

  convertLocalToUTC(datetime) {
    const { TimeZone } = this.props.eventsAbout;

    let formattedDateTime;

    // Check if the input string matches the "hh:mm A" format
    const hasTimeFormat = /^\d{2}:\d{2} [APap][Mm]$/.test(datetime);


    if (hasTimeFormat) {
      formattedDateTime = datetime
    } else {
      formattedDateTime = datetime.format('YYYY-MM-DD HH:mm:ss');
    }

    // Parse the formattedDateTime in the source timezone
    const sourceDateTime = momentTz.tz(formattedDateTime, hasTimeFormat ? 'hh:mm A' : 'YYYY-MM-DD HH:mm:ss', TimeZone);

    // Convert the time to UTC and format as "HH:mm"
    const utcTime = sourceDateTime.utc().format('HH:mm');

    return utcTime;
  }

  addTicketRemote = async () => {
    const {currentTicket} = this.state;
    const {addTicketAction, eventId} = this.props;

    const res = await addTicketAction({
      EventId: eventId,
      Title: currentTicket.name,
      Description: currentTicket.description,
      BillingAmount: currentTicket.type
          ? currentTicket.absorbFee
              ? currentTicket.price
              : //Number(currentTicket.buyerFee) * Number(currentTicket.price) +
              Number(currentTicket.price)
          : 0,
      TotalTicketIssue:
          currentTicket.eventRecurring !== "True"
              ? currentTicket.quantity
              : currentTicket.recurringQuantity,
      TaxAmount: currentTicket.buyerFee * 100,
      ActiveDate: this.getCompositeDateAndTime(
          currentTicket.startDate,
          currentTicket.startTime
      ),
      InActiveDate: this.getCompositeDateAndTime(
          currentTicket.endDate,
          currentTicket.endTime
      ),
      TicketType: parseInt(currentTicket.type),
      isAbsorb: currentTicket.absorbFee ? 1 : 0,
      RecurringTicket: currentTicket.eventRecurring,
      RecurringInterval:
          currentTicket.selectedTimeSlot === "custom"
              ? "0"
              : currentTicket.selectedTimeSlot?.split(" ")[0] === "" ||
              currentTicket.selectedTimeSlot?.split(" ")[0] === undefined
                  ? "0"
                  : currentTicket.selectedTimeSlot?.split(" ")[0],
      RecurringDays:
          currentTicket.eventRecurring === "True"
              ? currentTicket.selectDays?.join(",")
              : "",
      RecurringStartTime:
          currentTicket.eventRecurring === "True"
              ? this.convertLocalToUTC(currentTicket.recurringStartTime)
              : "",
      RecurringEndTime:
          currentTicket.eventRecurring === "True"
              ? this.convertLocalToUTC(currentTicket.recurringEndTime)
              : "",
      isBundle: (currentTicket.eventRecurring === "True" && currentTicket.recurringBundleQuantity > 1) ? 1 : 0,
      SlotPerBundle: currentTicket.eventRecurring === "True" ? currentTicket.recurringBundleQuantity : 0,
      UserId: localStorage.getItem("UserId"),
    });

    localStorage.setItem(
        "isRecurring",
        currentTicket?.eventRecurring === "True" ? true : false
    );

    return res;
  };

  saveAndContinue = () => {
    const {classes} = this.props;
    const {tickets} = this.state;

    return (
        <div className={classes.saveAndContinueButtonWrapper}>
          <Button
              className={classes.saveAndContinueButton}
              variant={"contained"}
              onClick={() => this.props.history.push("Payouts")}
              style={{display: tickets.length > 0 ? "initial" : "none"}}
          >
            {Text.SaveAndContinue}
          </Button>
        </div>
    );
  };

  toggleAddModal = (show) => {
    this.setState({addModal: show});

    !show &&
    this.setState({
      currentTicket: {
        ...this.currentDefault,
        buyerFee: this.state.currentTicket.buyerFee,
        AdditionalFee: this.state.currentTicket.AdditionalFee,
      },
    });
  };

  addOrUpdateModal = () => {
    const {classes} = this.props;
    const {addModal, editTicketModal} = this.state;
    const {currentTicket} = this.state;
    const {
      name,
      description,
      quantity,
      price,
      type,
      eventRecurring,
      selectDays,
      recurringQuantity,
      recurringBundleQuantity,
      selectedTimeSlot,
    } = currentTicket;
    let mandatoryFields = [];
    if (type) {
      if (eventRecurring !== "True") {
        mandatoryFields = [name.trim(), quantity, price];
      } else {
        mandatoryFields = [name.trim(), recurringQuantity, price];
      }
    } else {
      if (eventRecurring !== "True") {
        mandatoryFields = [name.trim(), quantity];
      } else {
        mandatoryFields = [name.trim(), recurringQuantity];
      }
    }

    return (
        <Modal
            open={addModal || editTicketModal.show}
            onClose={() =>
                editTicketModal.show
                    ? this.setEditTicketState("", false)
                    : this.toggleAddModal(false)
            }
        >
          <Box className={classes.addUpdateModal}>
            <div className={classes.addUpdateModalMainWrapper}>
              <div>
                <div className={classes.addUpdateModalHeader}>
                  <IconButton
                      onClick={() =>
                          editTicketModal.show
                              ? this.setEditTicketState("", false)
                              : this.toggleAddModal(false)
                      }
                  >
                    <Close height={30} width={30}/>
                  </IconButton>
                  <p className={classes.addUpdateModalHeaderText}>
                    {editTicketModal.show ? "Edit" : "Add"} {Text.Ticket}
                  </p>
                </div>
                <div>{this.inputFields(currentTicket)}</div>
              </div>

              <div
                  className={classes.addUpdateModalButtonWrapper}
                  id="eventCommonModal"
              >
                <Button
                    className={classes.addUpdateModalButtonCancel}
                    onClick={() => {
                      editTicketModal.show
                          ? this.setEditTicketState("", false)
                          : this.toggleAddModal(false);
                    }}
                >
                  {Text.Cancel}
                </Button>
                <Button
                    className={classes.addUpdateModalButtonSave}
                    variant={"contained"}
                    onClick={() => {
                      editTicketModal.show
                          ? this.saveEditedTicket()
                          : this.addTicket();
                    }}
                    disabled={
                        mandatoryFields.some(
                            (field) => field === "" || field === 0
                        ) ||
                        // parseFloat(price) <= 0 ||
                        // this.state.priceError ||
                        (eventRecurring !== "True"
                            ? this.state.quantityError
                            : false) ||
                        // this.state.selectedTimeSlotError ||
                        this.state.selectDaysError ||
                        this.state.recurringQuantityError
                    }
                    style={{
                      backgroundColor:
                          mandatoryFields.some(
                              (field) => field === "" || field === 0
                          ) ||
                          // parseFloat(price) <= 0 ||
                          // this.state.priceError ||
                          (eventRecurring !== "True"
                              ? this.state.quantityError
                              : false) ||
                          // this.state.selectedTimeSlotError ||
                          this.state.selectDaysError ||
                          this.state.recurringQuantityError
                              ? "rgba(163, 56, 164,.4)"
                              : "rgb(163, 56, 164)",
                    }}
                >
                  {Text.Save}
                </Button>
              </div>
            </div>
          </Box>
        </Modal>
    );
  };

  paymentErrorMessage = () => {
    const {classes, payouts} = this.props;
    const {tickets} = this.state;
    const isPaid = tickets.some((ticket) => ticket.type === 1);
    const PaymentLabel = Tickets.createTickets.paymentError;

    if (typeof payouts === "undefined" && isPaid) {
      return (
          <div style={{textAlign: "center"}}>
            <img
                style={{width: "30px", marginBottom: "30px"}}
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSJMApQvNCc3uQ6jZppL-KCxtAKPvFmqXiIuR7QLSgPZ1j3JTUY9Ffu-Nlz0K2CQEy8cFg&usqp=CAU"
                alt="Error Message"
            />
            <p style={{color: "red", fontSize: "16px"}}>
              {PaymentLabel.slice(0, PaymentLabel.indexOf("next"))}
              <br/>{" "}
              {PaymentLabel.slice(
                  PaymentLabel.indexOf("next"),
                  PaymentLabel.length - 1
              )}
            </p>
          </div>
      );
    }
  };

  handleDeleteError = (show, title = "", body = "") => {
    this.setState({
      deleteError: {show, messageTitle: title, messageBody: body},
    });
  };

  deleteErrorMessage = () => {
    const {classes} = this.props;
    const {deleteError} = this.state;
    const {show, messageTitle, messageBody} = deleteError;

    return (
        <Modal open={show} onClose={() => this.handleDeleteError(false)}>
          <Box className={classes.deleteModalMainWrapper}>
            <div className={classes.deleteModalImgWrapper}>
              <img
                  src={Events.deleteImg}
                  alt="Delete Icon"
                  className={classes.deleteModalImg}
              />
            </div>
            <div style={{textAlign: "center"}}>
              <strong>{messageTitle}</strong>
              {messageBody === "add payment method" ? (
                  <p>
                    {Text.ActiveAtLeastOneTicket}{" "}
                    <a
                        href="#"
                        style={{fontSize: "14px"}}
                        onClick={() => this.props.history.push("Payouts")}
                    >
                      {Text.PayoutMethod}
                    </a>
                  </p>
              ) : (
                  <p>{messageBody}</p>
              )}
            </div>
          </Box>
        </Modal>
    );
  };

  ticketDeleteModal = () => {
    const {classes} = this.props;
    const {show, index} = this.state.deleteTicketModal;
    const deleteLabels = Tickets.deleteTicket;
    return (
        <Modal open={show} onClose={() => this.setDeleteModalState(false)}>
          <Box className={classes.deleteModalMainWrapper}>
            <div className={classes.deleteModalImgWrapper}>
              <img
                  src={deleteLabels.img}
                  alt="Delete Icon"
                  className={classes.deleteModalImg}
              />
            </div>
            <p style={{textAlign: "center"}}>
              {deleteLabels.message}{" "}
              {this.state.tickets[index] ? this.state.tickets[index].name : ""}?
            </p>
            <p
                style={{
                  fontSize: "13px",
                  textAlign: "center",
                  lineHeight: "15px",
                  padding: "10px",
                  color: "gray",
                }}
            >
              {Tickets.deleteTicket.info}
            </p>
            <div>
              <Button
                  className={classes.deleteModalSaveAndCancelButton}
                  onClick={() => this.setDeleteModalState(false)}
                  style={{color: "blue"}}
              >
                {Text.Cancel}
              </Button>

              <Button
                  className={classes.deleteModalSaveAndCancelButton}
                  onClick={() => this.deleteTicket(index)}
                  style={{color: "red"}}
              >
                {Text.Delete}
              </Button>
            </div>
          </Box>
        </Modal>
    );
  };
  mandatoryFieldTag = () => {
    return (
        <span style={{color: "red", margin: "0px", padding: "0px"}}> *</span>
    );
  };

  ifNaNReturnZero = (num) => (Number.isNaN(num) ? 0 : num.toFixed(2));

  validateInput = (e, name, sold) => {
    const newValue = e.target.value;

    if (name === "quantity") {
      if (newValue > 0) {
        if (this.state.currentTicket.hasOwnProperty("TicketId")) {
          if (newValue < sold) {
            this.setState({
              quantityError: true,
              quantityErrorMessage:
                  "Quantity can not be less then tickets sold",
            });
          } else {
            this.setState({quantityError: false, quantityErrorMessage: ""});
          }
        } else {
          this.setState({quantityError: false, quantityErrorMessage: ""});
        }
      } else {
        this.setState({
          quantityError: true,
          quantityErrorMessage: "Quantity must be greater than 0",
        });
      }
    } else if (name === "recurringQuantity") {
      if (newValue > 0) {
        if (this.state.currentTicket.hasOwnProperty("TicketId")) {
          if (newValue < sold) {
            this.setState({
              recurringQuantityError: true,
              recurringQuantityErrorMsg:
                  "Recurring quantity can not be less then tickets sold",
            });
          } else {
            this.setState({
              recurringQuantityError: false,
              recurringQuantityErrorMsg: "",
            });
          }
        } else {
          this.setState({
            recurringQuantityError: false,
            recurringQuantityErrorMsg: "",
          });
        }
      } else {
        this.setState({
          recurringQuantityError: true,
          recurringQuantityErrorMsg:
              "Recurring quantity must be greater than 0",
        });
      }
    } else if (name === "recurringBundleQuantity") {
      if (newValue > 0) {
        if (this.state.currentTicket.hasOwnProperty("TicketId")) {
          if (newValue < sold) {
            this.setState({
              recurringBundleQuantityError: true,
              recurringBundleQuantityErrorMsg:
                  "Recurring bundle quantity can not be less then tickets sold",
            });
          } else {
            this.setState({
              recurringBundleQuantityError: false,
              recurringBundleQuantityErrorMsg: "",
            });
          }
        } else {
          this.setState({
            recurringBundleQuantityError: false,
            recurringBundleQuantityErrorMsg: "",
          });
        }
      } else {
        this.setState({
          recurringBundleQuantityError: true,
          recurringBundleQuantityErrorMsg:
              "Recurring bundle quantity must be greater than 0",
        });
      }
    }

    this.handleInputChange(e, name);
  };

  handleSelectTimeSlot = (event) => {
    const {currentTicket} = this.state;
    const {showCustom, selectedTimeSlot, customField} = currentTicket;
    const {value} = event.target;
    if (value === "custom" && !showCustom) {
      this.setState(() => ({
        currentTicket: {
          ...currentTicket,
          selectedTimeSlot: value,
          showCustom: true,
        },
      }));
    } else if (value === selectedTimeSlot) {
      this.setState(() => ({
        currentTicket: {
          ...currentTicket,
          selectedTimeSlot: "",
          showCustom: false,
          customField: 0,
        },
      }));
    } else {
      this.setState(() => ({
        currentTicket: {
          ...currentTicket,
          selectedTimeSlot: value,
          showCustom: false,
        },
      }));
    }

    this.validateRecurring("selectedTimeSlot");
  };

  recurringEventSlots = () => {
    const {
      selectedTimeSlot,
      customField,
      showCustom,
      eventRecurring,
      isTicketSlod,
    } = this.state.currentTicket;
    let timeslot = ["15 min", "30 min", "45 min", "60 min"];
    return (
        <div className="recurringEventSlots">
          {timeslot.map((item, idx) => (
              <>
                <input
                    disabled={isTicketSlod}
                    type="radio"
                    id={item}
                    value={item}
                    name="option"
                    onChange={() => this.validateRecurring("selectedTimeSlot")}
                    checked={selectedTimeSlot === item}
                    onClick={this.handleSelectTimeSlot}
                />
                <label htmlFor={item}>
                  <h4>{item}</h4>
                </label>
              </>
          ))}

          {showCustom ? (
              <>
                <div
                    style={{
                      display: "flex",
                      gap: customField > 99 ? "5px" : "0px",
                      backgroundColor: "#b60eb5",
                      color: "white",
                      padding: "9px 15px 9.4px",
                      borderRadius: "5px",
                      transition: "0.2s",
                    }}
                    onClick={this.handleSelectTimeSlot}
                    onChange={() => this.validateRecurring("selectedTimeSlot")}
                >
                  <input
                      disabled={isTicketSlod}
                      maxLength={4}
                      required
                      style={{
                        display: "block",
                        textAlign: "center",
                        maxWidth: "30px",
                        border: "none",
                        outline: "none",
                        fontSize: "18px",
                        backgroundColor: "#b60eb5",
                        color: "white",
                      }}
                      autoFocus={true}
                      value={customField === 0 ? 0 : customField}
                      onChange={(e) => this.handleInputChange(e, "customField")}
                      type="number"
                      onKeyDown={(e) =>
                          ["e", "E", "+", "-", "."].includes(e.key) &&
                          e.preventDefault()
                      }
                  />
                  <h4 style={{position: "relative", top: "4px"}}>min</h4>
                </div>
              </>
          ) : (
              <>
                <input
                    type="radio"
                    id="custom"
                    name="option"
                    value={"custom"}
                    checked={selectedTimeSlot === "custom"}
                    onClick={(event) => {
                      if (!isTicketSlod) {
                        console.log({onClick: isTicketSlod});
                        this.handleSelectTimeSlot(event);
                      }
                    }}
                    onChange={() => this.validateRecurring("selectedTimeSlot")}
                />
                <label htmlFor="custom">
                  <h4>custom</h4>
                </label>
              </>
          )}
        </div>
    );
  };

  handleSelectDays = (event) => {
    const {currentTicket} = this.state;
    const {value} = event.target;
    this.setState(() => ({
      currentTicket: {...currentTicket, selectDays: value},
    }));

    this.validateRecurring("selectDays");
  };

  validateRecurring = (field) => {
    setTimeout(() => {
      const {
        eventRecurring,
        selectDays,
        selectedTimeSlot,
        customField,
        showCustom,
      } = this.state.currentTicket;

      if (eventRecurring === "True") {
        if (field === "selectDays") {
          if (selectDays?.length === 0 || selectDays === "") {
            this.setState({
              selectDaysError: true,
            });
          } else {
            this.setState({
              selectDaysError: false,
            });
          }
        } else if (field === "selectedTimeSlot") {
          if (
              selectedTimeSlot === "" ||
              selectedTimeSlot === " min" ||
              !selectedTimeSlot
          ) {
            this.setState({
              selectedTimeSlotError: true,
            });
          } else if (showCustom && customField <= 0) {
            this.setState({
              selectedTimeSlotError: true,
              currentTicket: {
                ...this.state.currentTicket,
                selectedTimeSlot: !showCustom ? "0" : selectedTimeSlot,
              },
            });
          } else {
            this.setState({
              selectedTimeSlotError: false,
            });
          }
        }
      } else {
        this.setState({
          selectDaysError: false,
          selectedTimeSlotError: false,
        });
      }
    }, 100);
  };

  changeRecurringTime = (date, textFieldName) => {
    const {currentTicket} = this.state;
    if (date === null || date === "invalid date") {
      if (textFieldName === "Start Time") {
        this.setState({
          currentTicket: {
            ...currentTicket,
            recurringStartTime: moment(),
          },
        });
      } else {
        this.setState({
          currentTicket: {
            ...currentTicket,
            recurringEndTime: moment(),
          },
        });
      }
    } else {
      if (textFieldName === "Start Time") {
        this.setState({
          currentTicket: {
            ...currentTicket,
            recurringStartTime: moment(date),
          },
        });
      } else {
        this.setState({
          currentTicket: {
            ...currentTicket,
            recurringEndTime: moment(date),
          },
        });
      }
    }
  };

  getMoment = (time) => {
    const newTime = isMoment(time) ? time : moment(time, "h:mm A");
    return newTime;
  };

  timePickerComponent = (textFieldName) => {
    const {currentTicket} = this.state;

    const {isTicketSlod} = currentTicket;

    return (
        <React.Fragment>
        <span style={{position: "relative", top: "5px"}}>
          {textFieldName} {this.mandatoryFieldTag()}
        </span>
          <TimePicker
              disabled={isTicketSlod}
              placeholder={textFieldName}
              use12Hours
              showSecond={false}
              focusOnOpen={true}
              format="h:mm A"
              value={
                textFieldName === "Start Time"
                    ? this.getMoment(currentTicket.recurringStartTime)
                    : this.getMoment(currentTicket.recurringEndTime)
              }
              onChange={(e) => this.changeRecurringTime(e, textFieldName)}
              className="time_picker"
          />
        </React.Fragment>
    );
  };

  recurringEvents = () => {
    const {currentTicket} = this.state;
    const {
      quantity,
      ticketSold,
      eventRecurring,
      recurringQuantity,
      recurringBundleQuantity,
      selectDays,
      selectedTimeSlot,
      disabledRecurring,
    } = this.state.currentTicket;

    const {classes} = this.props;
    const {handleSelectDays} = this;

    return (
        <TransitionGroup>
          <FormControlLabel
              disabled={disabledRecurring}
              style={{margin: "0px", position: "relative", left: "-10px"}}
              control={
                <Checkbox
                    sx={{
                      "& .MuiSvgIcon-root": {color: "#b60eb5"},
                      color: "#b60eb5",
                    }}
                    style={{transform: "scale(1.2)"}}
                    name="Event Recurring"
                />
              }
              label={
                <p style={{...styles.formControlLabel, color: "#b60eb5"}}>
                  Is this event recurring? Expand for more options.
                </p>
              }
              labelPlacement="end"
              checked={eventRecurring === "True"}
              onChange={() => {
                this.validateRecurring("selectDays");
                this.validateRecurring("selectedTimeSlot");
                this.setState(() => ({
                  currentTicket: {
                    ...currentTicket,
                    eventRecurring: eventRecurring === "True" ? "False" : "True",
                    quantity: "",
                  },
                  quantityError: false,
                  quantityErrorMessage: "",
                }));
              }}
          />

          {eventRecurring === "True" && (
              <Collapse in={eventRecurring}>
                <Box style={{marginTop: "5px"}}>
                  <p>
                    Select How Often This Event Occurs (Leave blank if the event
                    occurs only once per day)
                  </p>
                  {this.recurringEventSlots()}
                </Box>

                <Box style={{marginTop: "15px"}}>
              <span className={classes.inputLabel}>
                Quantity Available For Each Time Slot {this.mandatoryFieldTag()}
              </span>
                  <TextField
                      required
                      className={classes.inputTextField}
                      value={recurringQuantity}
                      margin="normal"
                      onChange={(e) =>
                          this.validateInput(e, "recurringQuantity", ticketSold)
                      }
                      fullWidth
                      InputProps={{
                        disableUnderline: true,
                        style: {fontSize: "14px"},
                      }}
                      onKeyDown={(e) =>
                          ["e", "E", "+", "-", "."].includes(e.key) &&
                          e.preventDefault()
                      }
                      onInput={(e) => {
                        let isSpecial = !/[*\":<>[\]{}`\\()'=;@%#!^$.]/.test(
                            e.target.value
                        );
                        e.target.value = isSpecial
                            ? e.target.value.slice(0, 6)
                            : quantity;
                      }}
                      placeholder="50"
                      type="number"
                      helperText={this.state.recurringQuantityErrorMsg} // error message
                      error={this.state.recurringQuantityError}
                  />
                </Box>
                <Box style={{marginTop: "15px"}}>
              <span className={classes.inputLabel}>
                Quantity of time slots included in this ticket type/package. {this.mandatoryFieldTag()}
              </span>
                  <TextField
                      required
                      className={classes.inputTextField}
                      value={recurringBundleQuantity}
                      margin="normal"
                      onChange={(e) =>
                          this.validateInput(e, "recurringBundleQuantity", ticketSold)
                      }
                      fullWidth
                      InputProps={{
                        disableUnderline: true,
                        style: {fontSize: "14px"},
                      }}
                      onKeyDown={(e) =>
                          ["e", "E", "+", "-", "."].includes(e.key) &&
                          e.preventDefault()
                      }
                      onInput={(e) => {
                        let isSpecial = !/[*\":<>[\]{}`\\()'=;@%#!^$.]/.test(
                            e.target.value
                        );
                        e.target.value = isSpecial
                            ? e.target.value.slice(0, 6)
                            : quantity;
                      }}
                      placeholder="50"
                      type="number"
                      helperText={this.state.recurringBundleQuantityErrorMsg} // error message
                      error={this.state.recurringBundleQuantityError}
                  />
                </Box>
                <Box style={{marginTop: "10px"}}>
              <span className={classes.inputLabel}>
                Which Days Does It Occur?
              </span>

                  <Box style={{display: "flex", marginTop: "10px"}}>
                    <Grid container spacing={2}>
                      <Grid item xs={6} sm={3}>
                        {this.timePickerComponent("Start Time")}
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        {this.timePickerComponent("End Time")}
                      </Grid>
                    </Grid>
                  </Box>
                  <Box style={{marginBottom: "15px"}}>
                    <MultipleSelectCheckmarks
                        selectDays={selectDays}
                        handleSelectDays={handleSelectDays}
                        This={this}
                    />
                  </Box>
                </Box>
              </Collapse>
          )}
        </TransitionGroup>
    );
  };

  inputFields = (state) => {
    const {classes} = this.props;
    const {currentTicket} = this.state;
    const {
      name,
      description,
      quantity,
      price,
      type,
      startDate,
      endDate,
      startTime,
      endTime,
      absorbFee,
      buyerFee,
      ticketSold,
      AdditionalFee,
      eventRecurring,
      recurringQuantity,
      recurringBundleQuantity,
      selectDays,
    } = this.state.currentTicket;
    const mandatoryFields = [name.trim(), quantity, price];
    const {
      ChangeStartDate,
      ChangeEndDate,
      ChangeStartTime,
      ChangeEndTime,
      handleSelectDays,
    } = this;
    const inputsLabels = Tickets.createTickets.addTicket;

    return (
        <TransitionGroup>
          <FormControl style={{display: "grid"}}>
          <span className={classes.inputLabel}>
            {inputsLabels.name} {this.mandatoryFieldTag()}
          </span>
            <TextField
                required
                className={classes.inputTextField}
                style={{
                  borderLeft: "10px solid #01B9FF",
                }}
                value={name}
                margin="normal"
                onChange={(e) => this.handleInputChange(e, "name")}
                fullWidth
                InputProps={{
                  disableUnderline: true,
                  style: {fontSize: "14px"},
                }}
                onInput={(e) => {
                  e.target.value =
                      e.target.value.length > 50
                          ? e.target.value.slice(0, 50)
                          : e.target.value;
                }}
                helperText={`${name?.length}/50`}
            />

            <span className={classes.inputLabel}>
            {inputsLabels.description}
            </span>
            <TextField
                value={description}
                onChange={(e) => this.handleInputChange(e, "description")}
                style={{
                  height: "auto", // Let the height adjust based on content
                  width: "100%",
                  padding: 10,
                  margin: "10px 0px 20px 0px",
                  border: "1px solid #ced4da",
                  outline: "none",
                  overflowWrap: "break-word",
                }}
                InputProps={{
                  disableUnderline: true,
                  style: {
                    fontSize: "14px",
                    minHeight: "100px", // Set a minimum height to ensure consistent appearance
                    padding: "10px", // Adjust padding as needed
                    flexDirection: "column",
                  },
                  endAdornment: (
                      <Input
                          disableUnderline
                          fullWidth
                          multiline
                          rowsMax={4}
                          readOnly
                          value={`${description?.length}/160`}
                          style={{
                            fontSize: "10px",
                            color: "#757575",
                            textAlign: "right",
                            paddingRight: "10px",
                            paddingBottom: "0px",
                          }}
                      />
                  ),
                }}
                multiline
                rows={4} // This ensures the TextField itself has a maximum of 4 visible rows
                onInput={(e) => {
                  e.target.value =
                      e.target.value.length > 160
                          ? e.target.value.slice(0, 160)
                          : e.target.value;
                }}
            />

            <TransitionGroup>
              {eventRecurring !== "True" && (
                  <Collapse in={eventRecurring}>
                <span className={classes.inputLabel}>
                  {inputsLabels.quantity} {this.mandatoryFieldTag()}
                </span>
                    <Box
                        sx={{
                          "& input[type='number']::-webkit-inner-spin-button,input[type='number']::-webkit-outer-spin-button":
                              {
                                appearance: "none",
                                margin: 0,
                              },
                        }}
                    >
                      <TextField
                          required
                          className={classes.inputTextField}
                          value={quantity}
                          margin="normal"
                          onChange={(e) =>
                              this.validateInput(e, "quantity", ticketSold)
                          }
                          fullWidth
                          InputProps={{
                            disableUnderline: true,
                            style: {fontSize: "14px"},
                          }}
                          onKeyDown={(e) =>
                              ["e", "E", "+", "-", "."].includes(e.key) &&
                              e.preventDefault()
                          }
                          onInput={(e) => {
                            let isSpecial = !/[*\":<>[\]{}`\\()'=;@%#!^$.]/.test(
                                e.target.value
                            );
                            e.target.value = isSpecial
                                ? e.target.value.slice(0, 6)
                                : quantity;
                          }}
                          placeholder="50"
                          type="number"
                          helperText={this.state.quantityErrorMessage} // error message
                          error={this.state.quantityError}
                      />
                    </Box>
                  </Collapse>
              )}
            </TransitionGroup>

            <div style={{display: "flex", marginBottom: "20px"}}>
              <Box
                  className={`${classes.ticketTypeBox} ${
                      type === 1 && classes.selectedTicketTypeBox
                  }`}
                  onClick={() => this.handleInputChange(1, "type", false)}
              >
                {Text.Paid}
              </Box>
              <Box
                  className={`${classes.ticketTypeBox} ${
                      type === 0 && classes.selectedTicketTypeBox
                  }`}
                  onClick={() => {
                    this.handleInputChange(0, "type", false);
                  }}
              >
                {Text.Free}
              </Box>
            </div>

            {type === 1 ? (
                <>
              <span className={classes.inputLabel}>
                {inputsLabels.price} {this.mandatoryFieldTag()}
              </span>

                  <Box
                      sx={{
                        "& input[type='number']::-webkit-inner-spin-button,input[type='number']::-webkit-outer-spin-button":
                            {
                              appearance: "none",
                              margin: 0,
                            },
                      }}
                  >
                    <TextField
                        required
                        className={classes.inputTextField}
                        value={price === 0 ? "" : price}
                        margin="normal"
                        onChange={(e) => this.validate(e, "price")}
                        fullWidth
                        onWheel={(e) => e.target.blur()}
                        InputProps={{
                          disableUnderline: true,
                          style: {fontSize: "14px"},
                        }}
                        placeholder={localStorage.getItem("CurrencySymbol") + " 0.00"}
                        type="number"
                        onKeyDown={(e) =>
                            ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                        }
                        helperText={this.state.priceErrorMessage} // error message
                        error={this.state.priceError}
                    />
                  </Box>

                  {this.recurringEvents()}

                  <div className={classes.calculation}>
                    <p className={classes.calculationLabel}>
                      {inputsLabels.buyerSection.headline}
                    </p>
                    <div className={classes.calculationWrapper}>
                      <span>{inputsLabels.buyerSection.price}</span>
                      <span>
                    {localStorage.getItem("CurrencySymbol")}
                        {Intl.NumberFormat(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(this.ifNaNReturnZero(parseFloat(price)))}
                  </span>
                    </div>
                    <div className={classes.calculationWrapper}>
                      <span>{inputsLabels.buyerSection.fees}</span>
                      <span>
                    + {localStorage.getItem("CurrencySymbol")}
                        {Intl.NumberFormat(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(
                            Number(
                                parseFloat(price) === 0
                                    ? 0
                                    : absorbFee
                                        ? 0
                                        : AdditionalFee
                            ) +
                            Number(
                                this.ifNaNReturnZero(
                                    absorbFee
                                        ? 0
                                        : parseFloat(buyerFee) * parseFloat(price)
                                )
                            )
                        )}
                  </span>
                    </div>
                    <div
                        className={classes.calculationTotal}
                        style={{backgroundColor: "#01B9FF"}}
                    >
                      <span>{inputsLabels.buyerSection.total}</span>
                      <span>
                    {localStorage.getItem("CurrencySymbol")}
                        {Intl.NumberFormat(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(
                            Number(
                                parseFloat(price) === 0
                                    ? 0
                                    : absorbFee
                                        ? 0
                                        : AdditionalFee
                            ) +
                            Number(
                                this.ifNaNReturnZero(
                                    absorbFee
                                        ? parseFloat(price)
                                        : parseFloat(buyerFee) * parseFloat(price) +
                                        parseFloat(price)
                                )
                            )
                        )}
                  </span>
                    </div>
                  </div>

                  <div className={classes.calculation}>
                    <p className={classes.calculationLabel}>
                      {inputsLabels.revenueSection.headline}
                    </p>
                    <div className={classes.calculationWrapper}>
                      <span>{inputsLabels.revenueSection.buyerTotal}</span>
                      <span>
                    {localStorage.getItem("CurrencySymbol")}
                        {Intl.NumberFormat(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(
                            Number(
                                parseFloat(price) === 0
                                    ? 0
                                    : absorbFee
                                        ? 0
                                        : AdditionalFee
                            ) +
                            Number(
                                this.ifNaNReturnZero(
                                    absorbFee
                                        ? parseFloat(price)
                                        : parseFloat(buyerFee) * parseFloat(price) +
                                        parseFloat(price)
                                )
                            )
                        )}
                  </span>
                    </div>
                    <div className={classes.calculationWrapper}>
                      <span>{inputsLabels.revenueSection.fees}</span>
                      <span>
                    - {localStorage.getItem("CurrencySymbol")}
                        {Intl.NumberFormat(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(
                            Number(parseFloat(price) === 0 ? 0 : AdditionalFee) +
                            Number(
                                this.ifNaNReturnZero(
                                    parseFloat(buyerFee) * parseFloat(price)
                                )
                            )
                        )}
                  </span>
                    </div>
                    <div
                        className={classes.calculationTotal}
                        style={{backgroundColor: "rgb(182, 14, 181)"}}
                    >
                      <span>{inputsLabels.revenueSection.total}</span>
                      <span>
                    {localStorage.getItem("CurrencySymbol")}
                        {Intl.NumberFormat(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(
                            Number(
                                this.ifNaNReturnZero(
                                    absorbFee
                                        ? parseFloat(price) -
                                        parseFloat(buyerFee) * parseFloat(price)
                                        : parseFloat(price)
                                )
                            ) -
                            Number(
                                parseFloat(price) === 0
                                    ? 0
                                    : absorbFee
                                        ? AdditionalFee
                                        : 0
                            )
                        )}
                  </span>
                    </div>
                    <FormControlLabel
                        className={classes.flexCenterCenter}
                        control={
                          <Checkbox
                              checked={state.absorbFee}
                              onChange={() =>
                                  this.handleInputChange(
                                      !state.absorbFee,
                                      "absorbFee",
                                      false
                                  )
                              }
                              name="Absorb Fee"
                              style={{transform: "scale(1.2)"}}
                          />
                        }
                        label={
                          <Typography style={styles.formControlLabel}>
                            {inputsLabels.absorbFees}
                          </Typography>
                        }
                    />
                  </div>
                </>
            ) : (
                <>{this.recurringEvents()}</>
            )}

            <p className={classes.calculationLabel}>{inputsLabels.saleTime}</p>
            <DateAndTime
                varStartDate={startDate}
                varEndDate={endDate}
                varEndTime={endTime}
                varStartTime={startTime}
                maxEndDate={this.timeUTCtoLocal(
                    this.props.eventsAbout && this.props.eventsAbout.EndDateTime
                )}
                minDate={this.timeUTCtoLocal(
                    this.props.eventsAbout && this.props.eventsAbout.StartDateTime
                )}
                funcChangeStartDate={this.ChangeStartDate}
                funcChangeEndDate={this.ChangeEndDate}
                funcChangeStartTime={this.ChangeStartTime}
                funcChangeEndTime={this.ChangeEndTime}
            />
          </FormControl>
        </TransitionGroup>
    );
  };

  handleInputChange = (e, field, inputType = true) => {
    const inputValue = inputType ? e.currentTarget.value : e;

    if (field === "type" && inputValue === "0") {

      this.setState({
        currentTicket: {
          ...this.state.currentTicket,
          price: "",
          absorbFee: false,
          [field]: inputValue,
        },
      });
    } else if (field === "customField") {
      const inputValue = e.currentTarget.value;
      const limitedValue =
          inputValue.length > 0
              ? Math.max(0, parseInt(inputValue.slice(0, 3), 10))
              : inputValue;

      this.setState({
        currentTicket: {
          ...this.state.currentTicket,
          customField: limitedValue,
          selectedTimeSlot: `${limitedValue} min`,
        },
      });
    } else {

      this.setState({
        currentTicket: {
          ...this.state.currentTicket,
          [field]: inputValue < 0 ? 0 : inputValue,
        },
      });
    }
  };

  validate = (e, field) => {
    // if (this.state.currentTicket.eventRecurring !== "True") {
    if (e.target.value > 0) {
      this.setState({
        priceError: false,
        priceErrorMessage: "",
      });
    } else if (e.target.value <= 0) {
      this.setState({
        priceError: true,
        priceErrorMessage: "Price must be greater then 0",
      });
    }
    // }

    this.handleInputChange(e, field);
  };

  ChangeStartDate = (date) => {
    const completeDate = `${date.day}-${date.month}-${date.year}`;
    const newDate = moment(completeDate, "DD-MM-YYYY");

    const {eventsAbout} = this.props;
    const {EndDateTime} = eventsAbout;

    const endDate = moment(
        this.timeUTCtoLocal(EndDateTime).format("DD-MM-YYYY"),
        "DD-MM-YYYY"
    );
    if (newDate.isSame(endDate)) {
      this.setState({
        currentTicket: {
          ...this.state.currentTicket,
          startDate: newDate,
          // endDate: newDate,
          // endTime: this.timeUTCtoLocal(EndDateTime),
        },
      });
    } else {
      this.setState({
        currentTicket: {
          ...this.state.currentTicket,
          startDate: newDate,
          // endDate: newDate,
        },
      });
    }
  };

  ChangeEndDate = (date) => {
    const completeDate = `${date.day}-${date.month}-${date.year}`;

    const newDate = moment(completeDate, "DD-MM-YYYY");

    const {eventsAbout} = this.props;
    const {EndDateTime} = eventsAbout;

    const endDate = moment(
        this.timeUTCtoLocal(EndDateTime).format("DD-MM-YYYY"),
        "DD-MM-YYYY"
    );

    if (newDate.isSame(endDate)) {
      this.setState({
        currentTicket: {
          ...this.state.currentTicket,
          endDate: newDate,
          endTime: this.timeUTCtoLocal(EndDateTime),
        },
      });
    } else {
      this.setState({
        currentTicket: {
          ...this.state.currentTicket,
          endDate: moment(completeDate, "DD-MM-YYYY"),
        },
      });
    }
  };
  ChangeStartTime = (date) => {

    if (date === null || date === "invalid date") {
      this.setState({
        currentTicket: {
          ...this.state.currentTicket,
          startTime: moment(),
          // endTime: moment(),
        },
      });
    } else {

      this.setState({
        currentTicket: {
          ...this.state.currentTicket,
          // endTime: moment(date),
          startTime: moment(date),
        },
      });
    }
  };

  ChangeEndTime = (date) => {

    if (date === null || date === "invalid date") {
      this.setState({
        currentTicket: {
          ...this.state.currentTicket,
          endTime: moment(),
        },
      });
    } else {
      this.setState({
        currentTicket: {
          ...this.state.currentTicket,
          endTime: moment(date),
        },
      });
    }
  };

  getCompositeDateAndTime = (date, time) => {
    const {TimeZone} = this.props.eventsAbout;

    const formattedDate = date.format("DD-MM-YYYY");
    const formattedTime = time.format("hh:mm:ss a");

    const dateAndTimeString = formattedDate + " " + formattedTime;
    var dateTimeInUtc = momentTz
        .tz(dateAndTimeString, "DD-MM-YYYY hh:mm:ss a", TimeZone)
        .utc()
        .format();

    return dateTimeInUtc;
  };

  currentDefault = {
    name: "",
    description: "",
    price: 0,
    quantity: "",
    type: 1,
    absorbFee: false,
    startDate: moment(),
    endDate: this.props.eventsAbout && moment(this.timeUTCtoLocal(this.props.eventsAbout.EndDateTime), "MM/DD/YYYY hh:mm:ss A"),
    startTime: moment(),
    endTime: this.props.eventsAbout && moment(this.timeUTCtoLocal(this.props.eventsAbout.EndDateTime), "MM/DD/YYYY hh:mm:ss A"),
    buyerFee: "",
    AdditionalFee: "",
    status: "Active",
  };
  saveEditedTicket = async () => {
    const {editTicketModal} = this.state;
    this.setEventTypeRemote(this.state.currentTicket.type);
    this.setState({
      loading: true,
    });

    const { TotalTicketIssue } = await this.updateTicketRemote();

    this.setState({
      tickets: this.state.tickets.map((ticket, i) =>
          i === editTicketModal.index
              ? {
                ...this.state.currentTicket,
                TotalTicketIssue,
              }
              : ticket
      ),
      currentTicket: {
        ...this.currentDefault,
        buyerFee: this.state.currentTicket.buyerFee,
        AdditionalFee: this.state.currentTicket.AdditionalFee,
      },
      editTicketModal: {show: false, index: ""},
    });
  };

  updateTicketRemote = async () => {
    const { currentTicket } = this.state;
    const { updateTicketAction, eventId } = this.props;

    const res = await updateTicketAction({
      TicketId: currentTicket.TicketId,
      EventId: eventId,
      Title: currentTicket.name,
      Description: currentTicket.description,
      BillingAmount: currentTicket.type
          ? currentTicket.absorbFee
              ? currentTicket.price
              : //Number(currentTicket.buyerFee) * Number(currentTicket.price) +
              Number(currentTicket.price)
          : 0,
      TotalTicketIssue:
          currentTicket.eventRecurring !== "True"
              ? currentTicket.quantity
              : currentTicket.recurringQuantity,
      TaxAmount: currentTicket.buyerFee * 100,
      ActiveDate: this.getCompositeDateAndTime(
          currentTicket.startDate,
          currentTicket.startTime
      ),
      InActiveDate: this.getCompositeDateAndTime(
          currentTicket.endDate,
          currentTicket.endTime
      ),
      TicketType: currentTicket.type,
      isAbsorb: currentTicket.absorbFee ? 1 : 0,
      RecurringTicket:
          currentTicket.eventRecurring === "True" ? "true" : "false",
      RecurringInterval:
          currentTicket.selectedTimeSlot === "custom"
              ? "0"
              : currentTicket.selectedTimeSlot?.split(" ")[0] === ""
                  ? "0"
                  : currentTicket.selectedTimeSlot?.split(" ")[0],
      RecurringDays:
          currentTicket.eventRecurring === "True"
              ? currentTicket.selectDays?.join(",")
              : "",
      RecurringStartTime:
          currentTicket.eventRecurring === "True"
              ? this.convertLocalToUTC(currentTicket.recurringStartTime)
              : "",
      RecurringEndTime:
          currentTicket.eventRecurring === "True"
              ? this.convertLocalToUTC(currentTicket.recurringEndTime)
              : "",
      isBundle: (currentTicket.eventRecurring === "True" && currentTicket.recurringBundleQuantity > 1) ? 1 : 0,
      SlotPerBundle: currentTicket.eventRecurring === "True" ? currentTicket.recurringBundleQuantity : 0,
      UserId: localStorage.getItem("UserId"),
    });
    // .then((res) => {
    //   this.setState({ loading: false });
    //   console.log({ res });
    //   return res;
    // })
    // .catch((err) => {
    //   console.error(err);
    // });

    if (res?.data?.results?.ResponseCode) {
      this.setState({ loading: false });
      return JSON.parse(res?.data?.results?.Data);
    } else {
      console.error({err: "Error in updateTicketRemote api!"});
    }
  };

  setEditTicketState = (index = "", show) => {
    this.setState({
      editTicketModal: {show: show, index: index},
    });

    if (show) {
      this.setState({
        currentTicket: {...this.state.tickets[index]},
      });
    } else {
      this.setState({
        currentTicket: {
          ...this.currentDefault,
          buyerFee: this.state.currentTicket.buyerFee,
          AdditionalFee: this.state.currentTicket.AdditionalFee,
        },
      });
    }
  };

  combineDateAndTime = (date, time) => {
    const formattedDate = date.format("DD-MM-YY");
    const formattedTime = time.format("hh:mm:ss a");

    const dateAndTime = moment(
        formattedDate + " " + formattedTime,
        "DD-MM-YY hh:mm:ss a"
    );

    return dateAndTime;
  };
  showTickets = () => {
    const {classes} = this.props;
    const {tickets} = this.state;
    // console.log({ tickets });
    return (
        <div className={classes.mainContentWrapper}>
          {this.paymentErrorMessage()}
          {tickets.map((ticket, i) => (
              <div className={classes.ticketWrapper} key={i}>
                <div className={classes.ticketCardColumnOne}>
                  <span
                      style={{
                        fontSize: "32px",
                        wordBreak: "break-all",
                        hyphens: "auto",
                      }}
                  >
                    {ticket.name}
                    {ticket.eventRecurring === "True" && (
                        <img
                            style={{
                              width: "30px",
                              margin: "0px 5px",
                              position: "relative",
                              top: "-1px",
                            }}
                            src={
                              ticket.recurringBundleQuantity > 1
                                  ? require("../../../../images/bundle.png").default
                                  : require("../../../../images/recurring.png").default
                            }
                            alt={(ticket.recurringBundleQuantity > 1) ? "Bundle" : "Recurring"}
                        />
                    )}
                  </span>

                  <span
                      className={classes.ticketCardSpecialFlag}
                      style={{
                        backgroundColor:
                            ticket.TicketId === "" || ticket.status === "Deleted"
                                ? "rgb(255, 48, 48)"
                                : "rgb(182, 14, 181)",
                      }}
                  >
                {ticket.TicketId === "" || ticket.status === "Deleted"
                    ? "Deleted"
                    : this.combineDateAndTime(
                        ticket.startDate,
                        ticket.startTime
                    ).isBefore(moment()) &&
                    this.combineDateAndTime(
                        ticket.endDate,
                        ticket.endTime
                    ).isAfter(moment())
                        ? "On Sale"
                        : this.combineDateAndTime(
                            ticket.startDate,
                            ticket.startTime
                        ).isBefore(moment()) ||
                        this.combineDateAndTime(
                            ticket.endDate,
                            ticket.endTime
                        ).isBefore(moment())
                            ? "Ended"
                            : "Scheduled"}
              </span>
                  {this.combineDateAndTime(ticket.endDate, ticket.endTime).isBefore(
                      moment()
                  ) ? null : ticket.status !== "Deleted" ? (
                      <span style={{opacity: 0.4}}>
                  {this.combineDateAndTime(
                      ticket.startDate,
                      ticket.startTime
                  ).isBefore(moment()) &&
                  this.combineDateAndTime(
                      ticket.endDate,
                      ticket.endTime
                  ).isAfter(moment())
                      ? "From"
                      : "Available from "}{" "}
                        {ticket.startDate.format("MM/DD/YYYY")} to{" "}
                        {ticket.endDate.format("MM/DD/YYYY")}
                </span>
                  ) : null}
                </div>
                <div className={classes.ticketCardColumnTwo}>
                  {ticket.status === "Deleted" ? (
                      <div></div>
                  ) : (
                      <div
                          className={classes.ticketCardButtonWrapper}
                          style={{display: ticket.TicketId ? "initial" : "none"}}
                      >
                        <IconButton onClick={() => this.setEditTicketState(i, true)}>
                          <Create/>
                        </IconButton>
                        <IconButton onClick={() => this.setDeleteModalState(true, i)}>
                          <Delete/>
                        </IconButton>
                      </div>
                  )}

                  <div>
                <span style={{fontSize: "35px", display: "block"}}>
                  {ticket.type
                      ? `${localStorage.getItem("CurrencySymbol")}${Intl.NumberFormat(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(
                          Number(this.checkIfDecimalAndFix(ticket.price))
                      )}`
                      : "Free"}
                </span>
                    <span style={{opacity: 0.4, textAlign: "end"}}>
                  {ticket?.ticketSold === "" || ticket?.ticketSold === undefined
                      ? "0"
                      : ticket?.ticketSold}
                      /
                      {ticket?.eventRecurring === "True"
                          ? ticket?.TotalTicketIssue > 0
                              ? ticket?.TotalTicketIssue
                              : ticket?.recurringQuantity * ticket?.availableSlots
                          : ticket?.quantity}{" "}
                      TICKETS SOLD
                </span>
                  </div>
                </div>
              </div>
          ))}
        </div>
    );
  };

  progressLoader = () => {
    const {loading} = this.state;

    return loading && <Loader/>;
  };

  render() {
    const { classes, eventUserEvents } = this.props;
    const { tickets } = this.state;

    return (
        <TicketLayout>
          <EventHeader title={"Create Tickets"}>
            {this.addButton("Ticket")}
          </EventHeader>
          <ContentLayout>
            {tickets.length > 0 ? this.showTickets() : this.emptyState()}
            {this.saveAndContinue()}
            {/* MODALS */}
            {this.addOrUpdateModal()}
            {this.ticketDeleteModal()}
            {this.progressLoader()}
            {this.errorMessage()}
            {this.deleteErrorMessage()}
          </ContentLayout>
        </TicketLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const {eventUserEvents} = state;
  return {
    eventId: eventUserEvents.eventId,
    tickets: eventUserEvents.tickets,
    eventsAbout: eventUserEvents.eventsAbout,
    eventType: eventUserEvents.eventType,
    eventPublish: eventUserEvents.eventPublish,
    payouts: eventUserEvents.payouts,
    eventUserEvents: eventUserEvents,
  };
};

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators(
      {addTicketAction, deleteTicketAction, updateTicketAction, setEventType},
      dispatch
  );
};

export default connect(
    mapStateToProps,
    matchDispatchToProps
)(withStyles(styles)(CreateTickets));
