import { Select } from "@material-ui/core";
import React, { memo } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null,
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

function MultipleSelectCheckmarks({ selectDays = [], handleSelectDays, This }) {
  const selectedDaysText = selectDays.join(", ").replace(/^, /, "");
  return (
    <div className="MultipleSelectCheckmarks">
      <FormControl sx={{ m: 1, width: "100%" }}>
        <InputLabel
          color="secondary"
          sx={{ fontSize: "14px" }}
          id="demo-multiple-checkbox-label"
        >
          Event Days {This.mandatoryFieldTag()}
        </InputLabel>
        <Select
          multiple
          value={selectDays}
          onChange={handleSelectDays}
          input={<OutlinedInput color="secondary" label={`Event Days *`} />}
          renderValue={(selected) => selectedDaysText}
          MenuProps={MenuProps}
          style={{ fontSize: "14px" }}
        >
          {names?.map((name) => (
            <MenuItem key={name} value={name} sx={{ scale: 1.4 }}>
              <Checkbox
                sx={{
                  "& .MuiSvgIcon-root": { fontSize: 20, color: "#b60eb5" },
                  color: "#b60eb5",
                }}
                checked={selectDays.indexOf(name) > -1}
              />
              <ListItemText
                sx={{
                  "& .css-10hburv-MuiTypography-root": { fontSize: "14px" },
                }}
                primary={name}
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default memo(MultipleSelectCheckmarks);
