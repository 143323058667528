import  axiosI  from '../Service/service';
import { EVENT_LINE_GRAPH_DATA } from './actionTypes';

export const EventDashboardLineGraphData = (userId, startDate, endDate, isAllTime) => {
  return (dispatch => {
    return axiosI.get(`/EventDashboardLineGraphData/${userId}/${startDate}/${endDate}/${isAllTime}`)
    .then(({data}) => {
      dispatch({
        type: EVENT_LINE_GRAPH_DATA,
        payload: data.results
      });
    })
  })
};
