import { Grid, withStyles } from "@material-ui/core";
import React, { Component } from "react";
import ContentGrid from "../../../../Helper/gridWrapper/gridWrapper";
import { reusableClasses } from "../EventDetails/resusableClasses";

const styles = {
  eventCreateTicketGrid: {
    position: "relative",
    "@media (max-width: 480px)": {
      padding: "0px",
    },
  },
  eventCreateTicketMainWrapper: {
    ...reusableClasses.mainWrapper,
  },
};

class TicketLayout extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <ContentGrid
          content={
            <Grid className={classes.eventCreateTicketGrid}>
              <section className={classes.eventCreateTicketMainWrapper}>
                {this.props.children}
              </section>
            </Grid>
          }
        />
      </React.Fragment>
    );
  }
}
export class ContentLayout extends Component {
  constructor(props) {
    super(props);
  }
  contentWrapper = {
    ...reusableClasses.contentWrapper,
  };

  render() {
    const extraStyle = this.props.style ? this.props.style : {};
    return (
      <section style={{ ...this.contentWrapper, ...extraStyle }}>
        {this.props.children}
      </section>
    );
  }
}
export default withStyles(styles)(TicketLayout);
