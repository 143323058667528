import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
// import Card from "../../../Helper/CardWrapper/cardWrapper";
import { getUserAction } from "../../../Actions/index";
import RecentActivities from "./recentActivities";
import TablePagination from "@mui/material/TablePagination";
import Card from "@material-ui/core/Card";
import Pagination from "./Pagination/Pagination";
import { useState } from "react";
const styles = {
  button: {
    padding: 0,
    fontSize: "1em",
    minWidth: "88px",
    color: "#a435a6",
  },
  table: {
    minWidth: 200,
  },
  cardStyles: {
    height: "100%",
  },
  headLine: {
    fontWeight: 500,
    fontSize: 16,
  },
};

function RecentActivity({ refresher, user }) {
  const [activity, setActivity] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  // console.log("activity[0]",activity[0]?.TotalCounts);
  const handleChangePage = (event, newPage) => {

    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {

    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  return (
      <React.Fragment>
        <Card className={styles.cardStyles}>
          <div style={{ padding: 10 }}>
            <h3>Recent Activity</h3>
          </div>
          <div
              style={{
                overflow: "auto",
                padding: 0,
                borderTop: "1px solid #ddd",
                // height: "475px",
              }}
          >
            <RecentActivities
                {...{ activity, setActivity, rowsPerPage, page, refresher, user }}
            />
          </div>
          {/* <Pagination {...{ activity, nextPage, prevPage, pageNumber, currentPage, rowsPerPage }} /> */}

          {activity?.length > 0 &&
              <div className="custom-pagination">
                <TablePagination
                    rowsPerPageOptions={[]}
                    component="div"
                    count={activity?.length > 0 ? Number(activity[0]?.TotalCounts) : 0}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    showFirstButton
                    showLastButton
                />
              </div>
          }
        </Card>
        {/* <Card
        header={"Recent Activity"}
        CardHeaderProps={{
          classes: {
            title: styles.headLine,
          },
        }}
        className={styles.cardStyles}
        content={
          <div>

          </div>

          // <ActivityTable rowsPerPage={rowsPerPage}/>
        }

        CardContentProps={{
          style: {
            overflow: "auto",
            padding: 0,
            borderTop: "1px solid #ddd",
            height: "475px",
          },
        }}
      /> */}
      </React.Fragment>
  );
}

// const mapStateToProps = (state) => {
//     return {
//         user: state.loginResponse,
//         activity: state.activity,
//         visitors: state.visitors,
//         messageData: state.messageReducer,
//     }
//   };

//   const matchDispatchToProps = (dispatch) => {
//     return bindActionCreators({ getUserAction }, dispatch);
//   };

export default RecentActivity;

//   export default connect(mapStateToProps, matchDispatchToProps)(withStyles(styles)(RecentActivity));
