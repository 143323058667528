import React from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const styles = {
	root: {
		flexGrow: 1,
		height:'100%',
		alpha:1
	},
};
const ProgressBar =(props)=> {
		const { classes } = props;
		return (
			<div>
				{
					props.progress ? <div className={classes.root}>
							<LinearProgress color="primary" /></div>
						: null
				}
			</div>
		)
};

const mapStateToProps = (state) => {
	return {
		progress:state.progress
	}
};
export default connect(mapStateToProps,null)(withStyles(styles)(ProgressBar));