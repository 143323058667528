import React, { useEffect, useRef, useState } from "react";
import "./styles.css";
import {
  pdf,
  Page,
  Image,
  StyleSheet,
  Text,
  Document,
  View,
  Link,
  Font
} from "@react-pdf/renderer";
import fonts from "images/fonts/EBGaramond-VariableFont_wght.ttf"
// import { toPNG } from 'recharts-to-png/lib/esm';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { useCurrentPng } from "recharts-to-png";
import UbmeLogo from "images/ubme_logo.png"
import { Text as Texts } from "Constants";
// import "react-pdf/dist/esm/Page/TextLayer.css";

const styles = StyleSheet.create({
  page: {
    height: 200,
    backgroundColor: "#fff",
    padding: 50,
  },
  box: {
    padding: "10px",
    margin: 10,
    backgroundColor: "#f0f0f0",
  },
  section: {
    marginBottom: 30,
    // marginLeft: 100,
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    // Set the height of the section to center vertically
  },
  image: {
    alignSelf: "center", // Center the image vertically
    width: 300,
    height: "auto",
    maxHeight: "100%",
    maxWidth: "100%",
  },

  title: { margin: 10, fontSize: 30, textAlign: "center", color: "#ca3dd9" },
  heading: { margin: 10, fontSize: 22, textAlign: "center", color: "#ca3dd9" },

  sub_heading: {
    margin: 10,
    fontSize: 24,
    // fontFamily: 'EB Garamond, serif',
    fontWeight:700,
    textAlign: "center",
    color: "#000000",
  },
  description: {
    margin: 5,
    fontSize: 20,
    fontWeight:700,
    textAlign: "center",
    color: "#000000",
  },
  // sub_heading: {
  //   margin: 5,
  //   fontSize: 16,
  //   textAlign: "center",
  //   color: "#000000",
  // },
  link: {
    margin: 5,
    fontSize: 16,
    textAlign: "center",
    color: "rgb(74, 135, 226)",
    textDecoration: "none",
  },
  Title2: {
    margin: 5,
    fontSize: 16,
    textAlign: "center",
    // color: "rgb(74, 135, 226)",
    textDecoration: "none",
  },
  add: { margin: 5, fontSize: 16, textAlign: "center" },
});




const PDFDocument = ({
  png,
  Description,
  Email,
  Logo,
  Name,
  URL = "",
  Phone,
  Address="",
  website,
  image,
  image1,
  image2,
  image3 = undefined,
  image4 = undefined,
  Pdfname,
  eventTitle,
  EmailCount= 0,
  PhoneCount =0,
  ClicksCount=0,
  ImpressionCount=0,
  Title2
}) => {

  Font.register({
    family: 'EB Garamond',
    src: fonts,
  });
  const TruncateExtraText = (Text, maxLength) => {
    if (Text && Text.length > maxLength) {
      return Text.substring(0, maxLength) + "...";
    }
    return Text;
  };
  const img1 = image1;
  const img2 = image2;
  const img3 = image3;
  const img4 = image4;

  return (
    <>
      <Document>
        <Page pageNumber={1} size="A4" style={styles.page} renderTextLayer={false} >
          <View style={styles.box}>
            <View style={{ border: "1px splid green", padding: "10px" }}>
              <View>
                <Text style={styles.title}>{Pdfname} {Texts.Report}</Text>
                <Text style={styles.sub_heading}>{eventTitle}</Text>
              </View>
              <View
                style={{ margin: "10px", borderBottom: "1px solid #808080" }}
              />
              <View>
                <Text style={styles.description}>{Name}</Text>
                {
                  Pdfname === "Ads" ? (
                    <Text style={styles.Title2}>
                      {Title2}
                    </Text>
                  ) : 
                  (
                    <Link src={website || URL} style={styles.link}>
                      {TruncateExtraText(
                              website || URL,
                                80
                              )}
                      
                      </Link>
                  )
                }
                <Text style={styles.add}> {Address}</Text>
                <Text style={styles.add}>
                 {Phone}
                </Text>
              </View>
            </View>
          </View>
        </Page>

        <Page style={styles.page} pageNumber={2}>
          <View style={{ marginTop: 50 }}>
            <Text style={styles.heading}>
              [{Name}] <Text style={{ color: "#000000" }}>{Texts.Highlights}</Text>
            </Text>
            {/* {
              Pdfname === "Polls" && (
                <View style={{ marginTop: 50 }}>
                <Text
                  style={{
                    textAlign: "left",
                    marginTop: 5,
                    fontSize: 12,
                    color: "#000000",
                  }}
                >
                   Poll data
                </Text>
                <Text
                  style={{
                    textAlign: "left",
                    marginTop: 5,
                    fontSize: 12,
                    color: "#000000",
                  }}
                >
                  <Image style={styles.image} source={`${image}`} />
                </Text>
              </View>
              )
            } */}
            {Pdfname === "Vendor" ? (
              <View style={{ marginTop: 50 }}>
                <Text
                  style={{
                    textAlign: "left",
                    marginTop: 5,
                    fontSize: 12,
                    color: "#000000",
                  }}
                >
                  <Text style={{ color: "green", fontSize: 20 }}>.</Text>
                   {Texts.Youradreceived} {ClicksCount} {Texts.WebsiteClicks}
                </Text>
                <Text
                  style={{
                    textAlign: "left",
                    marginTop: 5,
                    fontSize: 12,
                    color: "#000000",
                  }}
                >
                  <Text style={{ color: "green", fontSize: 20 }}>.</Text>
                   {Texts.Youradreceived} {ImpressionCount} {Texts.Impressions}{" "}
                </Text>
                
                <Text
                  style={{
                    textAlign: "left",
                    marginTop: 5,
                    fontSize: 12,
                    color: "#000000",
                  }}
                >
                  <Text style={{ color: "green", fontSize: 20 }}>.</Text>
                   {Texts.Youradreceived} {PhoneCount} {Texts.PhoneCalls}
                </Text>
                <Text
                  style={{
                    textAlign: "left",
                    marginTop: 5,
                    fontSize: 12,
                    color: "#000000",
                  }}
                >
                  <Text style={{ color: "green", fontSize: 20 }}>.</Text>
                   {Texts.Youradreceived} {EmailCount} {Texts.EmailInquiries}{" "}
                </Text>
              </View>
            ) : (
              <View style={{ marginTop: 50 }}>
                <Text
                  style={{
                    textAlign: "left",
                    marginTop: 5,
                    fontSize: 12,
                    color: "#000000",
                  }}
                >
                  <Text style={{ color: "green", fontSize: 20 }}>.</Text>  {Texts.YourAdBroughtIn} {ClicksCount} {Texts.WebsiteClicks}
                </Text>
                <Text
                  style={{
                    textAlign: "left",
                    marginTop: 5,
                    fontSize: 12,
                    color: "#000000",
                  }}
                >
                  <Text style={{ color: "green", fontSize: 20 }}>.</Text>  {Texts.Youradreceived} {ImpressionCount} {Texts.Impressions}
                </Text>
              </View>
            )}
          </View>
        </Page>

        <Page style={styles.page} pageNumber={2}>
          {image1 ? (
            <View>
              <Text style={styles.heading}>
                [{Name}]{" "}
                <Text style={{ color: "#000000" }}>{Texts.Impressions}</Text>
              </Text>
              <View>
                <View style={{ marginTop: 50 }}>
                  <Text
                    style={{
                      marginBottom: 10,
                      fontSize: 14,
                      textAlign: "left",
                      marginLeft: 100,
                    }}
                  >
                {Texts.Impressions}
                  </Text>
                  <Text
                    style={{
                      marginBottom: 10,
                      fontSize: 24,
                      textAlign: "left",
                      marginLeft: 100,
                      color: "#808080",
                    }}
                  >
                    {ImpressionCount}
                  </Text>
                </View>
                <View style={styles.section}>
                  <Image style={styles.image} source={`${img1}`} />
                </View>
              </View>
            </View>
          ) : (
            <Text
              style={{
                textAlign: "center",
                marginBottom: 20,
                marginTop: 20,
                fontSize: 16,
                fontWeight: 600,
              }}
            >
             {Texts.NoData}
            </Text>
          )}
        </Page>

        <Page style={styles.page} pageNumber={3}>
          {image2 ? (
            <View>
              <Text style={styles.heading}>
                [{Name}] <Text style={{ color: "#000000" }}>{Texts.Clicks}</Text>
              </Text>
              <View>
                <View style={{ marginTop: 50 }}>
                  <Text
                    style={{
                      marginBottom: 10,
                      fontSize: 14,
                      textAlign: "left",
                      marginLeft: 100,
                    }}
                  >
                    {Texts.Clicks}
                  </Text>
                  <Text
                    style={{
                      marginBottom: 10,
                      fontSize: 24,
                      textAlign: "left",
                      marginLeft: 100,
                      color: "#808080",
                    }}
                  >
                    {ClicksCount}
                  </Text>
                </View>
                <View style={styles.section}>
                  <Image style={styles.image} source={`${img2}`} />
                </View>
              </View>
            </View>
          ) : (
            <Text
              style={{
                textAlign: "center",
                marginBottom: 20,
                marginTop: 20,
                fontSize: 16,
                fontWeight: 600,
              }}
            >
              {Texts.NoData}
            </Text>
          )}
        </Page>

        {Pdfname === "Vendor" && (
          <Page style={styles.page} pageNumber={4}>
            {img3 ? (
              <View>
                <Text style={styles.heading}>
                  [{Name}] <Text style={{ color: "#000000" }}>{Texts.Calls}</Text>
                </Text>
                <View>
                  <View style={{ marginTop: 50 }}>
                    <Text
                      style={{
                        marginBottom: 10,
                        fontSize: 14,
                        textAlign: "left",
                        marginLeft: 100,
                      }}
                    >
                      {Texts.Calls}
                    </Text>
                    <Text
                      style={{
                        marginBottom: 10,
                        fontSize: 24,
                        textAlign: "left",
                        marginLeft: 100,
                        color: "#808080",
                      }}
                    >
                      {PhoneCount}
                    </Text>
                  </View>
                  <View style={styles.section}>
                    <Image style={styles.image} source={`${img3}`} />
                  </View>
                </View>
              </View>
            ) : (
              <Text
                style={{
                  textAlign: "center",
                  marginBottom: 20,
                  marginTop: 20,
                  fontSize: 16,
                  fontWeight: 600,
                }}
              >
                {Texts.NoData}
              </Text>
            )}
          </Page>
        )}

        {Pdfname === "Vendor" && (
          <Page style={styles.page} pageNumber={5}>
            {image4 ? (
              <View>
                <Text style={styles.heading}>
                  [{Name}]{" "}
                  <Text style={{ color: "#000000" }}>{Texts.EmailInquires}</Text>
                </Text>
                <View>
                  <View style={{ marginTop: 50 }}>
                    <Text
                      style={{
                        marginBottom: 10,
                        fontSize: 14,
                        textAlign: "left",
                        marginLeft: 100,
                      }}
                    >
                      {Texts.EmailInquires}
                    </Text>
                    <Text
                      style={{
                        marginBottom: 10,
                        fontSize: 24,
                        textAlign: "left",
                        marginLeft: 100,
                        color: "#808080",
                      }}
                    >
                      {EmailCount}
                    </Text>
                  </View>
                  <View style={styles.section}>
                    <Image style={styles.image} source={`${img4}`} />
                  </View>
                </View>
              </View>
            ) : (
              <Text
                style={{
                  textAlign: "center",
                  marginBottom: 20,
                  marginTop: 20,
                  fontSize: 16,
                  fontWeight: 600,
                }}
              >
               {Texts.NoData}
              </Text>
            )}
          </Page>
        )}

        <Page style={styles.page} pageNumber={6}>
          <View>
            <View>
              <Text style={styles.sub_heading}>{Texts.Reportedby}</Text>
            </View>
            <View
              style={{ margin: "10px", borderBottom: "1px solid #808080" }}
            />
            <View style={styles.section}>
            <Image style={{width:200}} src={UbmeLogo} />
            </View>
          </View>
        </Page>
      </Document>
    </>
  );
};

export default PDFDocument;
