import React, { Component } from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { Text } from 'Constants';

const styles = {
    eventStatusDropdown:{
        // margin: '10px 10px 0px 0px',
        border: '1px solid #e0e0e0',
        width: '220px',
        color: 'grey',
        verticalAlign: 'top',
        height: '50px',
        padding:'10px 0px',
        "@media screen and (max-device-width: 545px)": {
            width:'100%',
            margin: '5px',
        }
    },
    menuItem:   {
        fontSize: '16px'
    },
    eventStatusLabel:   {
        // margin: '3px 0px 0px 10px',
        fontSize: '8px'
    }
};

export class EventStatusDropdown extends Component {

    constructor(props)  {
        super(props);
        this.state = {
            name: 'AttendeeName'
        }
    }

    handleChange = (event) => {
        this.props.setCategoryType(event.target.value);
        // this.setState({ name: event.target.value });
      };

    render() {
        const { classes, title, name } = this.props;
        return (
            <FormControl className={classes.eventStatusDropdown}>
                {/* <InputLabel className={classes.eventStatusLabel}></InputLabel> */}
                <Select
                    value={name}
                    onChange={this.handleChange}
                    name='AttendeeName'
                    className={classes.menuItem}
                    disableUnderline
                    SelectDisplayProps={{
                        style: {padding: '5px 0px 5px 25px'}
                    }}
                >
                    <MenuItem
                        value={'AttendeeName'}
                        className={classes.menuItem}>
                       {Text.Name}
                    </MenuItem>

                    <MenuItem
                        value={'Email'}
                        className={classes.menuItem}>
                        {Text.Email}
                    </MenuItem>
                    <MenuItem
                        value={'OrderId'}
                        className={classes.menuItem}>
                        {Text.OrderHash}
                    </MenuItem>

                    <MenuItem
                        value={'QRCode'}
                        className={classes.menuItem}>
                        {Text.TicketHash}
                    </MenuItem>

                    <MenuItem
                        value={'TicketType'}
                        className={classes.menuItem}>
                        {Text.TicketType}
                    </MenuItem>
                </Select>
            </FormControl>
        )
    }
}

export default withStyles(styles)(EventStatusDropdown);
