import React, { useEffect, useState } from "react";
import "./EventCardView.css";
import cardBg from "images/cards-bg.png";
import share from "images/share.png";
import { FiClock } from "react-icons/fi";
import { GoLocation } from "react-icons/go";
import { BiDollar } from "react-icons/bi";
import { Link } from "react-router-dom";
import moment from "moment";
import Modal from "../../modal/Modal";
import Share from "../../share/Share";
import { axiosE } from "Service/service";
import * as constants from "Constants";
import { CategoryBG } from "./categoryBg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { routeName } from "Service/env";
function EventCardView({
  ref,
  CategoryName,
  City,
  Distance,
  EventId,
  ImageMedium,
  Latitude,
  Liked,
  Longitude,
  Price,
  Region,
  StartDateTime,
  Title,
  favoriteData,
  AddFavorite,
  isLoggedIn,
  LoginPopup,
  results,
  CategoryId,
  VenueName,
  BillingAmount,
  CurrencySymbol
}) {
  const [visible, setVisible] = useState(false);

  const handleClick = async (data) => {
    if (favoriteData.find((x) => x.EventId === EventId)) {
      let newdata = [...favoriteData];
      const filterData = newdata.filter((i) => i.EventId !== EventId);

      AddFavorite(filterData);
    } else {
      AddFavorite([...favoriteData, data]);
    }
    try {
      const res = await axiosE.post("/Event/UpdateLike", {
        EventId: EventId,
        UserId: results.ProfileDetail.UserId,
        Platform: 'Web'
      });
      if (res.data.results.ResponseCode) {
        // console.log("res.data.results", res.data.results);
      }
    } catch (error) {
      let newdata = [...favoriteData];
      const filterData = newdata.filter((i) => i.EventId !== EventId);
      AddFavorite(filterData);
    }
  };



  const shareButoon = (event) => {
    event.stopPropagation();
    setVisible(true);
  };
  const closeModal = () => setVisible(false);

  const handleLoginCall = (type, eventId) => {

    if (!isLoggedIn) {
      localStorage.setItem('pendingUpdateEventApiCall', eventId)
      localStorage.setItem('pendingEventsButtonClick', type)
      LoginPopup(true)
    }
  }

  return (
    <>
      <article ref={ref} key={EventId}>
        {visible && (
          <Modal closeModal={closeModal}>
            <Share
              url={`${constants.URLs.baseUrl}/event/` + EventId}
            />
          </Modal>
        )}

        <div className="card_img_heart">
          {favoriteData?.find((x) => x?.EventId === EventId) ? (
            <span
              onClick={(e) => {
                e.stopPropagation();
                isLoggedIn
                  ? handleClick({
                    CategoryName,
                    City,
                    Distance,
                    EventId,
                    ImageMedium,
                    Latitude,
                    Liked,
                    Longitude,
                    Price,
                    Region,
                    StartDateTime,
                    Title,
                    CategoryId,
                    VenueName,
                    BillingAmount,
                  })
                  : handleLoginCall("Like", JSON.stringify({
                    CategoryName,
                    City,
                    Distance,
                    EventId,
                    ImageMedium,
                    Latitude,
                    Liked,
                    Longitude,
                    Price,
                    Region,
                    StartDateTime,
                    Title,
                    CategoryId,
                    VenueName,
                    BillingAmount,
                  })
                  )
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="svg_select"
                width="32"
                height="32"
                viewBox="0 0 24 24"
              >
                <path d="M12 4.435c-1.989-5.399-12-4.597-12 3.568 0 4.068 3.06 9.481 12 14.997 8.94-5.516 12-10.929 12-14.997 0-8.118-10-8.999-12-3.568z" />
              </svg>
            </span>
          ) : (
            <span
              onClick={(e) => {
                e.stopPropagation();
                isLoggedIn
                  ? handleClick({
                    CategoryName,
                    City,
                    Distance,
                    EventId,
                    ImageMedium,
                    Latitude,
                    Liked,
                    Longitude,
                    Price,
                    Region,
                    StartDateTime,
                    Title,
                    CategoryId,
                    VenueName,
                    BillingAmount,
                  })
                  : handleLoginCall("Like", JSON.stringify({
                    CategoryName,
                    City,
                    Distance,
                    EventId,
                    ImageMedium,
                    Latitude,
                    Liked,
                    Longitude,
                    Price,
                    Region,
                    StartDateTime,
                    Title,
                    CategoryId,
                    VenueName,
                    BillingAmount,
                  })
                  );
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="svg"
                width="32"
                height="32"
                viewBox="0 0 24 24"
              >
                <path d="M12 4.435c-1.989-5.399-12-4.597-12 3.568 0 4.068 3.06 9.481 12 14.997 8.94-5.516 12-10.929 12-14.997 0-8.118-10-8.999-12-3.568z" />
              </svg>
            </span>
          )}
        </div>
        <a target="_blank" href={`${routeName}/event/${EventId}`}>
          <div
            className="card_img_category"
            style={{ backgroundColor: CategoryBG(CategoryId) }}
          >
            <p>{CategoryName}</p>
          </div>

          {ImageMedium ? (
            <LazyLoadImage
              src={ImageMedium}
              //  width={"100%"}
              //  height={"100%"}
              PlaceholderSrc={"PlaceholderImage"}
              effect="blur"
            />
            // <img src={ImageMedium} alt="Sample photo" />
          ) : (
            <LazyLoadImage
              src={cardBg}
              // width={"100%"}
              // height={"100%"}
              PlaceholderSrc={"PlaceholderImage"}
              effect="blur"
            />
            // <img src={cardBg} alt="Sample photo" />
          )}

          <div class="card_text">
            <div style={{ height: 64 }}>
              <h3
                className="card_item_title"
                style={{ color: "#000000", marginTop: 0, marginLeft: 5 }}
              >
                {Title}
              </h3>
            </div>

            <div style={{ marginTop: 30 }}>
              <div className="card_sub_text">
                <FiClock color="#848484" size={20} />{" "}
                <p
                  style={{ fontWeight: "bold", color: "#000000" }}
                  className="card_sub_icon"
                >
                  {moment(StartDateTime).format("llll")}
                  {/* {moment(StartDateTime).format('MM/DD/YYYY, HH:mm:ss A')} */}
                </p>
              </div>
              <div className="card_sub_text">
                <GoLocation color="#848484" size={20} />{" "}
                <div>
                  <p className="card_sub_venue">{VenueName}</p>
                </div>
              </div>
              <div className="card_sub_text">
                <p
                  className="card_sub_icon"
                  style={{ marginLeft: 33 }}
                >{City ? `${City}, ${Region}` : `${Region}`}</p>
              </div>

              <div className="card_sub_text">
                {/*<BiDollar color="#848484" size={20} />*/}
                <p className="currency_Symbol">{CurrencySymbol}</p>
                <p className="card_sub_icon">
                  {BillingAmount === "0" || BillingAmount === null
                    ? "Free"
                    : `${new Intl.NumberFormat(undefined, { minimumFractionDigits: 2 }).format(BillingAmount)}`}
                </p>
              </div>
            </div>
            <a href="#!" onClick={shareButoon} className="share_event">
              <img src={share} alt="share" className="share_image" />
              <p style={{ marginRight: 0, marginBottom: 12 }}>share</p>
            </a>
          </div>
        </a>
      </article>
    </>
  );
}

export default EventCardView;


