import React from "react";
import { Link } from "react-router-dom";
import image404 from "images/404_animation.gif";
import "./404.css";
import Handlebars, { log } from "handlebars";
import Navbar from "Components/UBMeEvents/Components_UBMeEvents/Navbar/Navbar";
import Footer from "Components/UBMeEvents/Components_UBMeEvents/Footer/Footer";
const PageNotFound = () => {
  return (
    <>
      <Navbar />
      <main className="event_404_main">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <img src={image404} className="event_404_img" />
            <br />
            <h1 className="event_404_heading">
              Whoops, the page or event you are looking for was not found.
            </h1>
            <br />
            <h2 style={{ fontSize: 25, color: "#6F7287" }}>
              If you feel this message is in error, please{" "}
              <a
                style={{
                  textDecoration: "none",
                  color: "rgb(202, 61, 217)",
                  fontSize: 25,
                }}
                
                href="mailto:support@ubme.com"
              >
                {" "}
                let us know.
              </a>
            </h2>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 30,
              }}
            >
              <a
                style={{ color: "#fff" }}
                href="/"
                class="button-88"
                role="button"
              >
                Home
              </a>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default PageNotFound;
