import axiosI from '../Service/service';
import {GET_ACTIVITY, UPDATE_VISIT} from './actionTypes';

export const fetchActivityAction=(placeId)=>{
    return (dispatch => {
        return axiosI.get(`/GetBusinessActivity/${placeId}`).then(({data})=>{
            dispatch({
                type: GET_ACTIVITY,
                payload: data
            });
        }).catch((err)=>{
            console.log(err);
        })
    })
};

export const updateVisitAction=(PlaceId,UpdateValue)=>{
    return (dispatch => {
        return axiosI.post('/UpdateBusinessPanelSeenStatus',{PlaceId,UpdateValue}).then(()=>{
            dispatch({
                type: UPDATE_VISIT,
                payload: UpdateValue
            });
        }).catch((err)=>{
            console.log(err);
        })
    })
};
