import React, { useEffect } from "react";
import { AiFillPrinter } from "react-icons/ai";
import "./Analytic.css";
import AnalyticCards from "./Component/AnalyticCards";
import { AiFillInfoCircle } from "react-icons/ai";
import SurveyCard from "./Component/SurveyCard";
import ChatActivity from "./Component/ChatActivity";
import Polls from "./Component/Polls";
import AnalyticChart from "./Component/AnalyticChart";
import LandingPage from "./Component/LandingPage";
import Monetization from "./Component/Monetization";
import Vendors from "./Component/Vendors";
import {
  BsCalendar2Date,
  BsListUl,
  BsQuestionCircleFill,
  BsEmojiLaughing,
} from "react-icons/bs";
import { FcSurvey } from "react-icons/fc";
import { FiMapPin } from "react-icons/fi";
import { HiOutlineChatAlt2 } from "react-icons/hi";
import { IoNotifications } from "react-icons/io";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import IconEvents from "./Component/Icons/Event-Impressions.png";
import IconSurvey from "./Component/Icons/Poll-Participants.png";
import IconsLocation from "./Component/Icons/location.svg";
import IconsQues from "./Component/Icons/question.svg";
import IconsMenu from "./Component/Icons/Check-Ins.png";
import IconsChat from "./Component/Icons/Chat-Participants.png";
import IconsNotification from "./Component/Icons/Notification-Opens.png";
import IconsSmily from "./Component/Icons/Interest.png";
import { axiosE } from "Service/service";
import { useState } from "react";
import { routeName } from "Service/env";
import MyPDF from "./Document";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Sponsors from "./Component/Sponsors";
import { Text } from "Constants";

function Analytic({ eventUserEvents, location }) {
  const [activityData, setActivityData] = useState("");
  const params = new URLSearchParams(location.search);
  const [pollData , setPollData] = useState([]);
  const [messageData , setMessageData] = useState([]);
  const [checkInsCount , setcheckInsCount] = useState([]);
  let eventId = params.get("eventId") || eventUserEvents?.eventsAbout?.EventId;
  let title = params.get("title")?.replaceAll("$", "&") || eventUserEvents?.eventsAbout?.Title;
  let pollParticipantsCount = 0;
  pollData?.forEach(item => {
    pollParticipantsCount += parseInt(item.totalVotes);
  });

  // let engagementCount = 0;
  // likesCount?.forEach(item => {
  //   engagementCount += item.LikesCount;
  // });

  let appEngagement = parseInt(pollParticipantsCount) + parseInt(activityData?.App_ExpressInterest?.Count) + parseInt(activityData?.LiveChatCheckedIn?.Count)

  let landingPageViews = parseInt(activityData?.LandingPage_Web?.Count) + parseInt(activityData?.App_EventImpresion?.Count);
  
  const analyticCard = [
    {
      title: "Check-Ins",
      tipText:
        "Number of attendees that checked-in to the event on the UBMe app",
      number: Intl.NumberFormat().format(activityData?.LiveChatCheckedIn?.Count),
    },
    {
      title: "Confirmed Attendees",
      tipText: "Number of attendees that were scanned in at the event",
      number: Intl.NumberFormat().format(activityData?.CheckedIn?.Count),
    },
    {
      title: "Ticket Purchased",
      tipText: "Total of people who registred for the event",
      number: Intl.NumberFormat().format(activityData?.Atendee?.Count),
    },
    {
      title: "Sales",
      tipText: "Total number of sales for this event",
      number: activityData.Sales ? `${activityData?.Sales?.CurrencySymbol}${Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(parseFloat(activityData?.Sales?.Sum.replace(activityData?.Sales?.CurrencySymbol, '')))}` : 0,
    },
    {
      title: "App Engagements",
      tipText: "All engagement that occurs on the app (includes messages, likes, polls and poll interactions)",
      number: appEngagement,
    },
    {
      title: "Landing Page Views",
      tipText: "Total number of web and app views of your event registration page",
      number: landingPageViews,
    },
  ];

  const surveys = [
    {
      icons: IconSurvey,
      title: "Poll Participants",
      tipText: "Number of people who participated in the polls",
      number: pollParticipantsCount,
    },
    {
      icons: IconEvents,
      title: "Event Impressions",
      tipText: "Total number of event views on the app",
      number: activityData?.App_EventImpresion?.Count,
    },
    // {
    //   icons: IconsLocation,
    //   title: "Survey Clicks",
    //   number: "16k",
    // },
    // {
    //   icons: IconsQues,
    //   title: "Survey Clicks",
    //   number: "238",
    // },
    {
      icons: IconsMenu,
      title: "Check-Ins",
      tipText: "Number of attendees that checked-in to the event on the UBMe app",
      number: activityData?.LiveChatCheckedIn?.Count,
    },
    {
      icons: IconsChat,
      title: "Chat Participants",
      tipText: "Total number of attendees who participated in the Live Chat during the event",
      number: activityData?.ChatParticipants?.Count,
    },
    // {
    //   icons: IconsNotification,
    //   title: "Notification Opens",
    //   number: 0,
    // },
    {
      icons: IconsSmily,
      title: "Likes",
      tipText: "Total number of engagement 'likes' in the Live Chat",
      number: activityData?.App_ExpressInterest?.Count,
    },
  ];

  const GetOverAllActivityByEventId = async () => {
    try {
      const { data } = await axiosE.post(
        "ManageEvents/GetOverAllActivityByEventId",
        {
          EventId: eventId,
        }
      );
      if (data.results.ResponseCode) {
        setActivityData(JSON.parse(data.results.Data).Analytics);
       console.log("ANALYTICS",JSON.parse(data.results.Data))
      }
    } catch (error) {}
  };

  const getAnalyticsData = async () =>{
    try {
      const { data } = await axiosE.get(
        `/EngagementData/EventExperiance/${eventId}`
      );
      if(data.results.ResponseCode){
        const res = JSON.parse(data?.results?.Data);
        console.log("res",res);
        setPollData(res?.PollData);
        setMessageData(res?.MessageCounts);
        setcheckInsCount(res?.CheckInsCounts);
      };
    } catch (error) {
      console.log("error");
    }
  };
  

  useEffect(() => {
    GetOverAllActivityByEventId();
    getAnalyticsData();
  }, []);

  return (
    <div className="sales_container">
      <div>
        {eventUserEvents.eventPublish ?  null : eventId ? null :
          <Redirect to={`${routeName}/Dashboard/Publish`} />
         }
        <div className="">
          <div className="email_invite_wrapper">
            {/* <ShowErrorToast message={error} closeToast={() => setError("")} /> */}
            <div className="email_invite_header">
              <div className="email_invite">
                <span style={{ fontSize: 30 }}>Analytics</span>
                {/* <div className="AnlayticHeader-Print">
            <p>Print Report</p>
            <AiFillPrinter className="iconsPrint" />
          </div> */}
              </div>
            </div>
          </div>
          {/* <div className="analyticHeader">
          <h1>Analytics</h1>
          <div className="AnlayticHeader-Print">
            <p>Print Report</p>
            <AiFillPrinter className="iconsPrint" />
          </div>
        </div> */}
          {/* <MyPDF/> */}
          {/* <PDFDownloadLink document={<MyPDF />} fileName="my-document.pdf">
        {({ blob, url, loading, error }) =>
          loading ? 'Loading document...' : 'Download now!'
        }
      </PDFDownloadLink> */}

          <div className="analyticEngagement">
            <h2>
              {Text.OverallEngagement} -{" "}
              <span>
                {Text.OverallEngagementDesc} {title}, {Text.RealTime}
              </span>
            </h2>
          </div>
          <div className="analytic_card">
            {analyticCard?.map((item) => (
              <AnalyticCards
                title={item.title}
                tipText={item.tipText}
                number={item.number}
              />
            ))}
          </div>
          {/* <div className="analyticChart">
            <AnalyticChart activityData={activityData} />
          </div> */}
          <div className="appEngagement">
            <div className="appEngageBorder" />
            <div className="appEngagementDesc">
              <h2>
              {Text.AppEngagement} -{" "}
                <span>
                  {Text.AppEngagementDesc} {title}, {Text.RealTime}
                </span>
              </h2>
            </div>
            <div className="appEngageSurvey">
              {surveys.map((item) => (
                <div className="surveyCard">
                  <SurveyCard
                    title={item.title}
                    number={Intl.NumberFormat().format(item.number)}
                    Icon={item.icons}
                    tipText={item.tipText}
                  />
                </div>
              ))}
            </div>

            <div className="chatActivity">
              <ChatActivity messageData={messageData} checkInsCount={checkInsCount}/>
            </div>

            <div className="polls">
              <Polls pollData={pollData} />
            </div>

            <div className="pageAnalytics">
              <LandingPage
                activityData={activityData}
              
              />
            </div>
          {
            activityData?.AdsClickthrough?.Ads && 
            <div className="monetization">
              <Monetization
              eventTitle={title}
              activityData={activityData}
                title="Monetization"
                span="See how your Live Chat Banner Ads are performing"
                meta="Ads"
                Desc="Aman's Artisian Indian Cuisine Award winnng Punjabi Indian restaurant"
              />
            </div>
          }

          {
            activityData?.SponsorClickthrough?.Sponsor && 
            <div className="monetization">
              <Sponsors
                eventTitle={title}
                activityData={activityData}
                title="Sponsors"
                span="View results and download reports for your sponsors"
                meta="Sponser"
                Desc="Big Sky"
              />
            </div>
          }
            
            {
              activityData?.VendorClickthrough?.Vendor && 
              <div className="monetization">
              <Vendors eventTitle={title} activityData={activityData} />
            </div>
            }
           
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { eventUserEvents } = state;
  return {
    eventUserEvents: eventUserEvents,
    eventPublish: state.eventUserEvents.eventPublish,
    results: state.loginResponse.results,
  };
};

const matchDispatchToProps = (dispatch) => {
  return {
    // AddToFavorite: () => dispatch(AddToFavorite),
    // SetCardData: () => dispatch(SetCardData),
    // LoginPopup: () => dispatch(LoginPopup),
    // GetUserProfileDetail,
    // saveUserData,
    // UpdateEventDetailsByUser,
    // dispatch,
  };
};

export default connect(mapStateToProps, matchDispatchToProps)(Analytic);
