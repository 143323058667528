import React from "react";
import moment from "moment";
// import Loader from "../../../Helper/loader/loader";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// import axiosI from "../../../Service/service";
import { withStyles } from "@material-ui/core/styles";
// import Alert from "../../../Helper/alert/alert";
import storeFolder from "../../../../../store";
import { GrClose } from "react-icons/gr";
import {
  getGIF,
  deleteMessage,
  getDetailGIF,
  deleteBusinessMessage,
  clearDetailedGif,
  clearEventExperienceChat,
  setEventExperienceMessagesState,
  setSelectedMultimediaData,
  setLoadEarlierMessage
  // getEventExperienceChat,
} from "Actions/messagesAction";

import "Components/BusinessPortal/Messages/messages.css";
import heart_outlined from "../../../../../images/Message Icons/heart_outlined.png";
import heart_filled from "../../../../../images/Message Icons/heart_filled.png";
import heart_filled_gray from "../../../../../images/Message Icons/heart_filled_gray.png";
// import Gif from "./WebView/Gif";
import AlertComponent from "../../../../../Helper/ErrorComponent/errorComponent";
// import GifComponent from "./WebView/GifComponent";
import { CircularProgress, Grid, Modal } from "@material-ui/core";
import Camera from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
import axiosI from "Service/service";
// import {audioFunc} from "../../../Service/helper";
import { ws } from "App";
import { audioFunc } from "Service/helper";
import Alert from "Helper/alert/alert";
import PollsFooter from "./PollsFooter";

import ScrollToBottom from "react-scroll-to-bottom";
import Avatar from "react-avatar";
import { UserIconsPic } from "Components/UBMeEvents/Components_UBMeEvents/Navbar/Alphabets/UserIconsPic";

const styles = {
  modal: {
    height: 350,
    width: 350,
    marginTop: "250px",
    marginLeft: "250px",
  },
};


class ChatComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      showCamera: false,
      showGif: false,
      gif: [],
      dataToDelete: {},
      showDelete: false,
      deleteCount: 0,
      showModal: false,
      detailGifData: [],
      pressStart: 0,
      audioURL: "",
      selectedGif: "",
      showDetailed: false,
      searchArray: [],
      isSearching: false,
      backgroundColorDiv: [
        "#516077",
        "#9b8f88",
        "#c14b05",
        "#5e3721",
        "#8c824e",
        "#e2e1d9",
        "#f2d702",
        "#51db02",
        "#289186",
        "#5f7baf",
        "#9050c4",
        "#511682",
        "#af1574",
      ],
      chatsIndex: localStorage.getItem("ChatsIndex") || 0,
      totalCount: localStorage.getItem("TotalCount") || 0,
      selectedRadio: {},
      scheduledChatCount: 0,
      currentDate: moment().utc(),
      isLiked:"nil",
      loading:false
    };
  }

  componentDidMount() {
    this.props.getGIF("https://api.tenor.co/v1/tags?key=RH3P1OZMBSCK");
    const totalCount = localStorage.getItem("TotalCount");
    this.showLoadEarlierMessagesButton(totalCount);
    setTimeout(() => {
      this.getScheduledMessages();
      this.interval = setInterval(this.getScheduledMessages, 30000);
    }, 30000);
  }

  componentDidUpdate() {
    let { detailedGIF } = this.props.messageData;
    let { detailGifData } = this.state;
    let { getDetailGIF } = this.props;

    let scrollElement = document.getElementById("web-modal");
    if (scrollElement) {
      if (
        scrollElement.scrollTop ===
        scrollElement.scrollHeight - scrollElement.clientHeight
      ) {
        let details = {
          name: detailGifData.searchterm,
          page: detailedGIF && detailedGIF.next,
        };
        detailedGIF && detailedGIF.next && getDetailGIF(details);
      }
    }
  }

  componentWillUnmount() {
    localStorage.setItem("ChatsIndex", JSON.stringify(0));
    this.props.clearEventExperienceChat();
    this.props.setEventExperienceMessagesState('LOADING')
    clearInterval(this.interval);
  }

  getScheduledMessages = () => {
    const dialogId = localStorage.getItem("currentDialogId");
    const userId = localStorage.getItem("UserId");
    ws.json({
      "type": "scheduledMessages",
      "userId": `${userId}`,
      "dialogId": `${dialogId}`
    });
  }

  showLoadEarlierMessagesButton(updateCount) {
    if (this.state.totalCount < updateCount) {
      this.setState({ totalCount: updateCount });
    }
  }

  updateWebViewState = (state, value) => {
    this.setState({
      [state]: value,
    });
  };

  MouseDown = (e) => {
    this.setState({
      pressStart: e.timeStamp,
    });
  };

  MouseUp = (e, data) => {
    if (this.state.pressStart + 1000 < e.timeStamp) {
      if (
        Number(data.from_user_id) === this.props.userId ||
        data.GooglePlaceId ||
        data.GooglePlacedId
      ) {
        if (data.RowNumber !== "1") {
          // this.setState({
          //     dataToDelete: {
          //         data: data
          //     },
          //     showDelete: true,
          // });
        }
      }
    } else {
      if (!data.GooglePlaceId && !data.GooglePlacedId) {
        if (localStorage.getItem("curbsideCommunicationUserId")) {
          window.open(e.currentTarget.src + "", "_blank");
          window.close();
        } else {
          this.imageClicked(e, data);
        }
      }
    }
  };

  deleteChat = () => {
    let { dataToDelete, deleteCount } = this.state;
    let showDelete;
    let { messageData, deleteBusinessMessage, deleteMessage } = this.props;
    if (messageData.selectedChat.Type === "OneToOne") {
      ws.json({
        chat_id: dataToDelete.data.chat_id,
        to_user_id: messageData.selectedChat.userid,
        type: "removemessage",
      });
      deleteMessage(dataToDelete.data.chat_id);
      showDelete = false;
      deleteCount = deleteCount + 1;
    } else {
      ws.json({
        chat_type:
          messageData.selectedChat.Type === "PlaceQuestionChat"
            ? "PlaceQuestionChat"
            : messageData.selectedChat.Type === "Feed"
              ? "Feed"
              : "Event",
        chat_id: dataToDelete.data.CommentId,
        user_id: dataToDelete.data.UserId,
        type: "removebusinessmessage",
        googlePlacedId: dataToDelete.data.GooglePlaceId,
        question_id: "0",
      });
      deleteBusinessMessage(dataToDelete.data.CommentId);
      showDelete = false;
      deleteCount = deleteCount + 1;
    }
    this.setState({ showDelete, deleteCount });
  };

  hideDelete = () => {
    this.setState({
      showDelete: false,
    });
  };

  imageClicked = (e, data) => {
    let { audioURL } = this.state;

    if (data.text === "[Sticker message]") {
      if (Number(data.stickerId.split(".")[0]) <= 30) {
        audioURL = require(`Sounds/${data.stickerId.split(".")[0]
          }.wav`);
        audioFunc(false, false, audioURL?.default);
      } else {
        audioURL = `https://d2gnb7jphto2jj.cloudfront.net/ChatImages/Sticker/Sounds/${data.stickerId.split(".")[0]
          }.wav`;
          console.log(audioURL)
        audioFunc(false, false, audioURL);
      }
    } else if (
      data.text === "[GIF message]" ||
      data.text === "[Picture message]"
    ) {
      let modal = document.getElementById("myModal");
      let targetDestination = document.getElementById("zoomImage");
      modal.style.display = "block";
      targetDestination.src = e.target.src;
    }
    this.setState({ audioURL });
  };

  onGifClick = () => {
    // console.log("onGifClick");
    const { messageData } = this.props;
    this.setState(
      {
        showGif: !this.state.showGif,
      },
      () => {
        if (this.state.showGif) {
          let { gif } = this.state;
          messageData &&
            messageData.gif &&
            messageData.gif.forEach((g, index) => {
              gif.push(
                <div
                  key={index}
                  className="gif"
                  onClick={() => {
                    if (g) {
                      let details = {
                        name: g.searchterm,
                        page: messageData.detailedGIF
                          ? messageData.detailedGIF.next
                          : 0,
                      };
                      this.props.getDetailGIF(details);
                      this.setState({
                        showDetailed: true,
                        detailGifData: g,
                      });
                    }
                    // this.openDetailed(g);
                  }}
                >
                  <img src={g.image} alt={g.name} className="gif-img" />
                  <p className="gifText">{g.searchterm}</p>
                </div>
              );
            });
          this.setState({
            gif,
          });
        } else {
        }
      }
    );
  };

  onCameraClick = (image) => {
    const { error } = this.props;
    let date = new Date();
    image = image.substring(image.indexOf(",") + 1);
    let data = {
      ProfileImage: image,
      messageId: date.getTime().toString().slice(4),
    };
    axiosI
      .post("/UploadChatImage", data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data.results.Message === "Chat image saved successfully") {
          let data = {
            text: "[Picture message]",
            imgUrl: response.data.results.ImageUrl,
          };
          this.props.setSelectedMultimediaData(data)
          return data;
        }
      })
      .catch((err) => {
        console.log("Error uploading image to the server.", err);
        error();
      });
    this.setState({
      showCamera: false,
    });
  };

  onCLoseImage = () => {
    document.getElementById("myModal").style.display = "none";
  };

  toggleCamera = () => {
    this.setState({
      showCamera: !this.state.showCamera,
    });
  };

  isCurrentUser = (id) => {
    let storeData = storeFolder.getState();
    if (storeData.loginResponse.results.ProfileDetail.UserId == id) {
      return true;
    }
    return false;
  };

  GifView() {
    const {
      showGif,
      showCamera,
      detailGifData,
      gif,
      searchArray,
      isSearching,
    } = this.state;
    const { toggleAlert, messageData } = this.props;
    return showGif || showCamera ? (
      //   <div>
      //     <GifComponent
      //       onGifClick={this.onGifClick}
      //       sendGif={this.sendGif}
      //       updateWebViewState={this.updateWebViewState}
      //       userId={userId}
      //       showCamera={showCamera}
      //       toggleCamera={this.toggleCamera}
      //       toggleAlert={toggleAlert}
      //       showGif={showGif}
      //       onCameraClick={this.onCameraClick}
      //     />
      //     <p>hatatta</p>
      //   </div>
      <React.Fragment>
        {showCamera ? (
          <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={true}
            onClose={this.toggleCamera}
          > 
            <div className="display-camera" onClick={this.toggleCamera}>
            <div className="camera-close-btn" onClick={this.toggleCamera}>
                <GrClose size={24}/>
                </div>
              <div onClick={this.toggleCamera}>
                <Camera
                  onTakePhoto={this.onCameraClick}
                  onCameraError={(e) => {
                    if (
                      e.toString() === "NotFoundError: Requested device not found"
                    ) {
                      this.toggleCamera();
                      console.log(
                        "Please connect camera to use this functionality."
                      );
                    } else if (
                      e.toString().includes("Only secure origins are allowed")
                    ) {
                      this.toggleCamera();
                      console.log(`${window.location.origin} is not secured.`);
                    } else if (e.toString().includes("Permission denied")) {
                      this.toggleCamera();
                      console.log(
                        `Permission Denied. Please allow the camera to use this functionality.`
                      );
                    } else if (e.toString().includes("no stream to stop!")) {
                    } else {
                      console.log(`Something went wrong while accessing camera.`);
                      console.log(e);
                    }
                  }}
                />
              </div>
            </div>
          </Modal>
        ) : (
          <React.Fragment>
            <Modal
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              open={true}
              onClose={()=>{
                this.onGifClick()
                this.setState({searchArray:[],isSearching: false,gif:[]})
              }}
            >
              <Grid container spacing={24}>
                <Grid item sm={12} />
                <div className="paper">
                <div style={{width:"100%",display:"flex",justifyContent:"end"}}>
                  <GrClose onClick={()=>{
                    this.onGifClick()
                    this.setState({searchArray:[],isSearching: false,gif:[]})
                  }} style={{fontSize:"18px",marginBottom:"5px",marginRight:"5px",cursor:"pointer"}}/>
                </div>
                  <div className="gifWrapper">
                    <input
                      type="text"
                      placeholder="Search for GIF"
                      onChange={(e) => {
                        if (e.target.value.length > 0) {
                          let { searchArray } = this.state;
                          searchArray = [];
                          gif.forEach(
                            (v) =>
                              v.props.children[1].props.children
                                .toLowerCase()
                                .includes(e.target.value.toLowerCase()) &&
                              searchArray.push(v)
                          );
                          this.setState({
                            isSearching: true,
                            searchArray,
                          });
                        } else {
                          this.setState({
                            isSearching: false,
                          });
                        }
                      }}
                    />
                    <div className="gifComponent">
                      {isSearching ? searchArray : gif}
                    </div>
                  </div>
                </div>
                <Grid />
              </Grid>
            </Modal>
            {this.state.showDetailed && (
              <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={true}
                onClose={() => {
                  this.setState({
                    showDetailed: false,
                  });
                  this.props.clearDetailedGif();
                }}
              >
                <div className="paper">
                <div style={{width:"100%",display:"flex",justifyContent:"end"}}>
                  <GrClose onClick={()=>{
                    this.setState({
                      showDetailed: false,
                    });
                    this.props.clearDetailedGif();
                  }} style={{fontSize:"18px",marginBottom:"5px",marginRight:"5px",cursor:"pointer"}}/>
                </div>
                  <div
                    className="gifWrapper"
                    id="gifWrapper"
                    onScroll={() => {
                      let scrollElement = document.getElementById("gifWrapper");
                      if (scrollElement) {
                        if (
                          scrollElement.scrollTop >=
                          scrollElement.scrollHeight -
                          scrollElement.clientHeight
                        ) {
                          let details = {
                            name: detailGifData.searchterm,
                            page:
                              messageData.detailedGIF &&
                              messageData.detailedGIF.next,
                          };
                          messageData.detailedGIF &&
                            messageData.detailedGIF.next &&
                            this.props.getDetailGIF(details);
                        }
                      }
                    }}
                  >
                    <div className="detailGifComponent">
                      {messageData.detailedGIF &&
                        messageData.detailedGIF.results.map((gif) => {
                          let color =
                            this.state.backgroundColorDiv[
                            Math.floor(Math.random() * 13) + 1
                            ];
                          return (
                            <div
                              className="gif"
                              onClick={() => {
                                let date = new Date();
                                let currentDate = moment()
                                  .utc()
                                  .format("YYYY-MM-DD HH:mm:ss");
                                let data = {
                                  text: "[GIF message]",
                                  imgUrl: `${gif.itemurl}.gif`,
                                };
                                this.updateWebViewState("showGif", false);
                                this.props.setSelectedMultimediaData(data)
                                this.setState({
                                  showDetailed: false,
                                });
                                this.props.clearDetailedGif();
                              }}
                            >
                              <img
                                src={`${gif.itemurl}.gif`}
                                alt={"GIF"}
                                className="gif-img"
                                gifstyle={{ background: color }}
                              />
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </Modal>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    ) : null;
  }

  updateChatCount = () => {
    let storeData = storeFolder.getState();
    const unscheduledChats = storeData.messageReducer.eventExperienceEventChat.filter(chat => chat.isScheduled).length;
    this.setState({
      scheduledChatCount: unscheduledChats,
    });
  }

  loadEarlierMessages = () => {
    this.setState({loading:true});
    this.props.setLoadEarlierMessage("TRUE");
    ws.json({
      index: ++this.state.chatsIndex,
      count: "20",
      user_id: localStorage.getItem("UserId"),
      type: "businessrecentchat",
      chat_type: "Event",
      deleteCount: "0",
      googlePlacedId: localStorage.getItem("currentDialogId"),
    });
    setTimeout(() => {
      this.setState({loading:false});
    }, 1100);

    localStorage.setItem("ChatsIndex", JSON.stringify(this.state.chatsIndex));
    let currentScheduledMessageCount = localStorage.getItem('TotalScheduledChatCount')
  };

  handlePollVote = (questionId) => {
    let optionId = this.state.selectedRadio[questionId];

    ws.json({
      dialog_Id: localStorage.getItem("currentDialogId"),
      user_id: localStorage.getItem("UserId"),
      type: "processPoll",
      option_id: optionId,
      question_id: questionId,
    });
  };

  isValidUrl = (value) => {
    try {
      new URL(value);
      return true;
    } catch (error) {
      return false;
    }
  };

  decodeUnicodeEscapeSequences(text) {
    return text.replace(/\\u[\dA-F]{4}/gi, match =>
        String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16))
    );
}

  formatMessage(text) {
    const urlRegex = /\b((?:https?:\/\/|www\.)[^\s]+)\b/gi;
    const parts = text?.split(urlRegex);
    return parts?.length && parts.map((part, index) => {

      if (urlRegex.test(part)) {
        let url
        let newPart
        try {
          if (!part.includes('://')) {
            newPart = `https://${part}`;
          } else {
            newPart = part;
          }
          url = new URL(newPart);
          return <a style={{ wordBreak: 'break-word', color: 'white', textDecoration: 'underline' }} target="_blank" rel="noreferrer" href={url}>{part}</a>;
        } catch (error) {
          return part
        }
      } else {
        return (
          <span key={index}>
            {part.includes("\n") ? (
              part.split("\n").map((line, i) => (
                <span key={i}>
                  {line}
                  {i < part.split("\n").length - 1 && <br />}
                </span>
              ))
            ) : (
              part
            )}
          </span>
        )
      }
    });
  }

  areDatesDifferent(previousDate, newDate) {
    const prevDay = moment(previousDate).format('DD MM YYYY');
    const newDay = moment(newDate).format('DD MM YYYY');
    // console.log("PREV DAY",prevDay,"NEW DAY",newDay);
    return prevDay !== newDay;
  }

  renderTimeLabel = (item) => {
    const timeZone = localStorage.getItem("CurrentTimeZone");
    const messageDate = item?.isScheduled
        ? moment(item.ScheduleTime).tz(JSON.parse(timeZone))
        : moment.utc(item.chat_time).tz(JSON.parse(timeZone));

    const today = moment().tz(JSON.parse(timeZone))?.startOf('day');
    const yesterday = moment().tz(JSON.parse(timeZone))?.subtract(1, 'day')?.startOf('day');

    if (messageDate?.isSame(today, 'day')) {
        return 'Today';
    } else if (messageDate?.isSame(yesterday, 'day')) {
        return 'Yesterday';
    } else {
        return messageDate?.format("Do MMM YYYY");
    }
  }

  parseAndValidateJSON = (jsonString) => {
    // Replace 'null' with 'null' and single quotes with double quotes
    const replacedString = jsonString
      .replace(/'/g, '"')
      .replace(/null/g, "null");

    try {
      const parsedData = JSON.parse(replacedString);
      return { success: true, data: parsedData };
    } catch (error) {
      return { success: false, error: error.message };
    }
  };

  sanitizeJsonString = (jsonString) => {
    // Replace escaped double quotes with a placeholder
    const sanitizedString = jsonString.replace(/\\"/g, '__DOUBLE_QUOTE__');
    return sanitizedString;
  };

  handleLikeRealTime = (messageId, isLiked) => {
    let storeData = storeFolder.getState()
    const updatedChats = storeData.messageReducer.eventExperienceEventChat.map((chat) => {
      if (chat.chat_id === messageId) {
        chat.messageLikes = isLiked ? 1 : 0;
        chat.messageLikesCount = isLiked ? (chat?.messageLikesCount ?? 0) + 1 : (chat?.messageLikesCount ?? 0) - 1;
      }
      return chat;
    });

    this.setState({
      isLiked,
      eventExperienceEventChat: updatedChats,
    });
  };

  render() {
    const {
      showAlert,
      showGif,
      showDelete,
      detailGifData,
      gif,
    } = this.state;
    const {
      toggleAlert,
      curbsideCommunication,
    } = this.props;
    let storeData = storeFolder.getState();
    const likeData = storeData.messageReducer.messageLike;
    const timeZone = localStorage.getItem("CurrentTimeZone");
    const chats =
    storeData.messageReducer.eventExperienceEventChat
    const currentDialogId = localStorage.getItem("currentDialogId");
    const currentEventId = localStorage.getItem("CurrentEventId");
    const currentUser = localStorage.getItem("UserId");
    return (
      <React.Fragment>
        {showAlert && <AlertComponent clickMethod={this.toggleAlert} />}
        {this.GifView()}
        {showDelete && (
          <Alert
            warning="true"
            showCancel="true"
            confirmText="Delete"
            confirmStyle="danger"
            cancelStyle="default"
            title={<span>"Like it never existed"</span>}
            Confirm={this.deleteChat}
            Cancel={this.hideDelete}
          />
        )}

        <div className="chat-component-wrapper">
          <div>
            <>
              <div className="eventExperienceContent_heading">
                <div>
                  <h2>Engage: </h2>
                </div>

                <div>
                  <p>
                    Below is your event's live chat - begin engaging with all of your attendees and see what they are chatting about!
                  </p>
                  <span>
                    Post live updates, create polls, or schedule posts ahead of
                    time.
                  </span>
                </div>
              </div>

              <ScrollToBottom className="chatComponent">
                <div className="loadPrevMsg">
                  {storeData.messageReducer.eventExperienceEventChat &&
                    storeData.messageReducer.eventExperienceEventChat.length <
                    (localStorage.getItem("TotalCount") - localStorage.getItem("ScheduledChatCount")) ? (
                      this.state.loading ? (<button>Load Previous Messages</button>) :
                    <button onClick={this.loadEarlierMessages}>
                      Load Previous Messages
                    </button>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="messaging">
                  {storeData.messageReducer.eventExperienceEventChat &&
                    storeData.messageReducer.eventExperienceEventChat.length >
                    0 ? (
                    storeData.messageReducer.eventExperienceEventChat
                      // .filter((item) => !item.isScheduled || (item.isScheduled && (moment(item?.scheduleDateTime).utc(item?.scheduleDateTime))
                      //   .isSameOrBefore(moment().utc()
                      //     )))
                      .sort((a, b) => {
                        const timeA = a.isScheduled ?
                        moment(a.scheduleDateTime)
                        //.utc(a?.ScheduleTime)
                        .tz(JSON.parse(timeZone))
                        :
                        moment(a?.chat_time)
                        .utc(a?.chat_time)
                        .tz(JSON.parse(timeZone))
                        const timeB = b.isScheduled ?
                        moment(b.scheduleDateTime)
                        //.utc(b?.ScheduleTime)
                        .tz(JSON.parse(timeZone))
                        :
                        moment(b?.chat_time)
                        .utc(b?.chat_time)
                        .tz(JSON.parse(timeZone))

                        return timeA - timeB;
                      })
                      .map(
                        (item, idx, recentChats) => {
                          try {
                            // const correctedJsonData = item?.chat_message.replace(/"text": "(.*?)"/, (match, p1) => {
                            //   const correctedText = p1.replace(/\\*"/g, '\\"');
                            //   return `"text": "${correctedText}"`;
                            // });
    //                         let correctedJsonData = item?.chat_message.replace(/\\u([\d\w]{4})/gi, (match, grp) => String.fromCharCode(parseInt(grp, 16)));
    // correctedJsonData = correctedJsonData.replace(/"text": "(.*?)"/, (match, p1) => {
    //     const correctedText = p1.replace(/\\*"/g, '\\"');
    //     return `"text": "${correctedText}"`;
    // });

                            var chatDetails =
                              typeof item?.chat_message === "string"
                                ? (
                                  JSON.parse(item?.chat_message)
                                )
                                : item?.chat_message;
                            // console.log("chat",chatDetails)
                          } catch (error) {
                            // console.log("JSON ERROR",error);
                          }
                          const pollAnswers =
                            chatDetails?.pollAnswers ? (
                              typeof chatDetails?.pollAnswers === "string"
                                ? JSON.parse(chatDetails?.pollAnswers)
                                : chatDetails?.pollAnswers
                            ) : (
                              typeof chatDetails?.pollAnswers === "string"
                                ? JSON.parse(chatDetails?.answers)
                                : chatDetails?.answers
                            )

                          const timeZone = localStorage.getItem("CurrentTimeZone");
                          const prevChat = recentChats[idx - 1];
                          const prevTime = prevChat?.isScheduled ? moment(prevChat?.ScheduleTime)
                                                                    //.utc(item?.ScheduleTime)
                                                                    .tz(JSON.parse(timeZone))
                                                                    :
                                                                    moment(prevChat?.chat_time)
                                                                    .utc(prevChat?.chat_time)
                                                                    .tz(JSON.parse(timeZone))

                          const newTime = item?.isScheduled ? moment(item?.ScheduleTime)
                                                              //.utc(item?.ScheduleTime)
                                                              .tz(JSON.parse(timeZone))
                                                              :
                                                              moment(item?.chat_time)
                                                              .utc(item?.chat_time)
                                                              .tz(JSON.parse(timeZone))

                          const shouldRenderDate = !prevChat || this.areDatesDifferent(prevTime, newTime);


                          const percentages = chatDetails?.question ?  pollAnswers.map(answer => (answer.votes / chatDetails?.totalVotes) * 100) : 0;
                          const maxPercentage = chatDetails?.question ?  Math.max(...percentages) : 0;
                          const isMultipleMax = chatDetails?.question ?  percentages.filter(percentage => percentage === maxPercentage).length > 1 : 0;

                          // console.log("TIME",moment(item?.ScheduleTime)
                          // // .utc(item?.ScheduleTime)
                          // .tz(JSON.parse(timeZone))
                          // .format("hh:mm a"))
                          return (
                            <>
                              {
                                // (this.areDatesDifferent(prevTime,newTime) || !prevChat) &&
                                shouldRenderDate &&
                                (
                                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} key={idx}>
                                    {this.renderTimeLabel(item)}
                                  </div>
                                )

                              }
                              <div
                                className="outerMsgBox"
                                key={item?.user_id + idx}
                                style={{
                                  justifyContent: this.isCurrentUser(item?.user_id)
                                    ? "flex-end"
                                    : "flex-start",
                                  width:"100%"
                                }}
                              >

                                <div
                                  className="messageBox"
                                  style={{
                                    flexDirection: this.isCurrentUser(item?.user_id)
                                      ? "column"
                                      : undefined,
                                    gap: this.isCurrentUser(item?.user_id)
                                      ? "8px"
                                      : "4px",
                                    alignItems:
                                      chatDetails?.question &&
                                        !this.isCurrentUser(item?.user_id)
                                        ? "flex-start"
                                        : "flex-end",
                                    width:"100%",
                                    // border: "1px solid blue",
                                  }}
                                >
                                  <div
                                    style={{
                                      display:"flex",
                                      flexDirection: this.isCurrentUser(item?.user_id) ? "row":"row-reverse",
                                      alignItems:"center",
                                      width:"100%",
                                      justifyContent:"space-between"
                                    }}
                                  >
                                    <div style={{
                                      display:"flex",
                                      flexDirection:"column",
                                      justifyContent:"center"
                                    }}>
                                      <div>
                                      {
                                        item?.messageLikesCount > 0 ?
                                        (item?.messageLikes == 1 ?
                                          (<img src={heart_filled} alt="heart"
                                            onClick={async()=>{
                                              await ws?.json({
                                                "messageId" : `${item?.chat_id}`,
                                                "dialogId" : `${currentDialogId}`,
                                                "type" : "deleteMessageLike",
                                                "chat_type" : "Event",
                                                "userId" : `${currentUser}`,
                                              });
                                              this.handleLikeRealTime(item?.chat_id, false);
                                            }}
                                          />) :
                                          (<img src={heart_filled_gray} alt="heart"
                                            onClick={async()=>{
                                              await ws?.json(
                                                { "messageId" : `${item?.chat_id}`,
                                                "dialogId" : `${currentDialogId}`,
                                                "type" : "addMessageLike",
                                                "chat_type" : "Event",
                                                "userId" : `${currentUser}` }
                                              );
                                              this.handleLikeRealTime(item?.chat_id, true);
                                            }}
                                          />)
                                        ) :
                                        (<img src={heart_outlined} alt="heart" onClick={async()=>{
                                          await ws?.json(
                                            { "messageId" : `${item?.chat_id}`,
                                            "dialogId" : `${currentDialogId}`,
                                            "type" : "addMessageLike",
                                            "chat_type" : "Event",
                                            "userId" : `${currentUser}` }
                                          );
                                          this.handleLikeRealTime(item?.chat_id, true);
                                        }}/>)
                                      }
                                      </div>
                                      <div style={{
                                        textAlign:"center",
                                        fontSize:"12px",
                                        color:"gray"
                                      }}>
                                        {item?.messageLikesCount > 0 ? item?.messageLikesCount : ""}
                                      </div>
                                    </div>
                                    <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      position: "relative",
                                      // border: "1px solid red",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent:
                                          this.isCurrentUser(item?.user_id) &&
                                          "flex-end",
                                        alignItems: "flex-end",
                                        // border: "1px solid green",
                                      }}
                                    >
                                      <div
                                        className="message-user-image"
                                        style={{
                                          display: this.isCurrentUser(item?.user_id)
                                            ? "none"
                                            : "block",
                                          marginTop: chatDetails?.question
                                            ? "20px"
                                            : "7px",
                                        }}
                                      >
                                        <div className="imageWithTimelline">
                                          <img
                                            style={{
                                              height: "35px",
                                              width: "35px",
                                              borderRadius: "40px",
                                              objectFit: "cover",
                                            }}
                                            src={
                                              item.photo
                                                ? `${item.photo}`
                                                : UserIconsPic(item?.firstname[0]?.toUpperCase())
                                            }
                                            // src={
                                            //   // item?.photo !== null
                                            //   //   ? item?.photo
                                            //   //   :
                                            //     UserIconsPic(item?.firstname[0]?.toUpperCase())
                                            // }
                                            alt=""
                                          />

                                          <p style={{ marginTop: "4px" }}>
                                            {/* {moment
                                              .utc(item?.chat_time)
                                              .local()
                                              .format("hh:mm a")} */}
                                              {
                                                item?.isScheduled ? (
                                                  moment(item?.ScheduleTime)
                                                    // .utc(item?.ScheduleTime)
                                                    .tz(JSON.parse(timeZone))
                                                    ?.format("hh:mm a")
                                                ) :
                                                  (moment(item?.chat_time)
                                                    .utc(item?.chat_time)
                                                    .tz(JSON.parse(timeZone))
                                                    ?.format("hh:mm a"))}
                                          </p>
                                        </div>
                                      </div>

                                      <div
                                        className="chatDetail"
                                        style={{
                                          bottom: this.isCurrentUser(item?.user_id)
                                            ? "0px"
                                            : "30px",
                                          marginTop:
                                            chatDetails &&
                                              Object.keys(chatDetails)?.length > 1 &&
                                              !this.isCurrentUser(item?.user_id)
                                              ? "20px"
                                              : "0px",
                                        }}
                                      >
                                        <p
                                          style={{
                                            fontWeight: 600,
                                            color: "rgba(0,0,0,0.4)",
                                            display: this.isCurrentUser(
                                              item?.user_id
                                            )
                                              ? "none"
                                              : "flex",
                                          }}
                                        >
                                          {item?.firstname}
                                        </p>

                                        <div
                                          className="i-message"
                                          style={{
                                            backgroundColor:
                                              chatDetails?.text ===
                                                "[Sticker message]"
                                                ? "transparent"
                                                : this.isCurrentUser(item?.user_id)
                                                  ? "#ca3dd9"
                                                  : "#e6e6eb",
                                          }}
                                        >
                                          {chatDetails?.text ===
                                            "[Sticker message]" ||
                                            chatDetails?.text === "[GIF message]" ||
                                            chatDetails?.text ===
                                            "[Picture message]" ? (
                                            <img
                                              style={{
                                                width:
                                                  chatDetails?.text ===
                                                    "[Sticker message]"
                                                    ? "120px"
                                                    : "",
                                              }}
                                              className={
                                                this.isCurrentUser(item?.user_id)
                                                  ? "message-from-them-right"
                                                  : "message-from-them-left"
                                              }
                                              //*HERE
                                              src={
                                                chatDetails?.text ===
                                                  "[Sticker message]"
                                                  ?
                                                  (chatDetails?.text === "[Sticker message]" &&
                                                  (
                                                    chatDetails?.stickerId.split(".")[0] >
                                                    0 &&
                                                    chatDetails?.stickerId.split(".")[0] <=
                                                    30
                                                  )) ?
                                                  require(`images/Stickers/${chatDetails?.stickerId}`)?.default
                                                  : `https://d2gnb7jphto2jj.cloudfront.net/ChatImages/Sticker/Images/${
                                                    chatDetails?.stickerId}`
                                                  : chatDetails?.imgUrl
                                              }
                                              alt="media"
                                              onMouseDown={(e)=>this.MouseDown(e)}
                                              onMouseUp={(e) => {
                                                this.MouseUp(e, chatDetails);
                                              }}
                                            />
                                          ) : chatDetails?.question ? (
                                            <p
                                              className={
                                                this.isCurrentUser(item?.user_id)
                                                  ? "message-from-them-right"
                                                  : "message-from-them-left"
                                              }
                                              style={{
                                                overflowWrap: "anywhere",
                                                textAlign: "left"
                                              }}
                                            >
                                              {chatDetails?.question}
                                            </p>
                                          ) : (
                                            <p
                                              className={
                                                this.isCurrentUser(item?.user_id)
                                                  ? "message-from-them-right"
                                                  : "message-from-them-left"
                                              }
                                              style={{
                                                overflowWrap: "anywhere",
                                                textAlign: "left"
                                              }}
                                            >
                                              {chatDetails?.text && this.formatMessage(this.decodeUnicodeEscapeSequences(chatDetails?.text))}
                                              {/* {chatDetails?.text} */}
                                              {/* {chatDetails?.text && this.decodeUnicodeEscapeSequences(chatDetails?.text)} */}
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                    </div>

                                    {chatDetails?.question && (
                                      <div
                                        className="pollRadioGroup"
                                        style={{
                                          position:
                                            !this.isCurrentUser(item?.user_id) &&
                                            "relative",
                                          left:
                                            !this.isCurrentUser(item?.user_id) &&
                                            "60px",
                                          right:
                                            this.isCurrentUser(item?.user_id) &&
                                            "0px",
                                          bottom:
                                            !this.isCurrentUser(item?.user_id) &&
                                            "30px",
                                          minWidth: "170px",
                                        }}
                                      >
                                        <div style={{ display: 'flex', flexDirection: 'column', columnGap: '4px' }}>
                                          {pollAnswers?.map(
                                            (answer, index) => {
                                              const percentage = (answer.votes / chatDetails?.totalVotes) * 100;
                                              const backgroundColor = (percentage === maxPercentage && !isMultipleMax) ? '#c277ca' : '#cbd9e3';
                                              return (
                                                <div key={answer.optionId}
                                                  style={{
                                                    display: 'flex',
                                                    borderRadius: '8px',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    paddingBlock: '5px',
                                                    paddingInline: '10px',
                                                    position: 'relative',
                                                    marginBottom: '4px',
                                                    background: `linear-gradient(to right, ${backgroundColor} 0%, ${backgroundColor} ${percentage}%, transparent ${percentage}%, transparent 100%)`
                                                  }}>
                                                  <div style={{ display: 'flex', gap: '7px', alignItems: 'center' }}>
                                                    {/* {progression === 0 && (
                                                      <div style={{ width: '5px', height: '25px', backgroundColor: '#AAB8C2', borderRadius: '8px' }} />
                                                    )} */}
                                                    <div style={{
                                                      position: 'relative'
                                                    }}>
                                                      {answer?.option ? answer?.option : answer?.name}
                                                    </div>
                                                  </div>

                                                  <div style={{ marginLeft: '20px',fontWeight: 'bold', }}>
                                                    {answer?.votes ? answer?.votes == 0 ? "0" : Math.round(((answer?.votes / chatDetails?.totalVotes) * 100) * 10) / 10 : "0"}%
                                                  </div>
                                                </div>
                                              )
                                            })}
                                        </div>
                                        <div
                                          className="pollDetails"
                                          style={{
                                            display:
                                              this.isCurrentUser(item?.user_id) &&
                                              "flex",
                                            flexDirection:
                                              this.isCurrentUser(item?.user_id) &&
                                              "row-reverse",
                                            left:
                                              !this.isCurrentUser(item?.user_id) &&
                                              "10px",
                                            right:
                                              this.isCurrentUser(item?.user_id) &&
                                              "10px",
                                          }}
                                        >
                                          {!this.isCurrentUser(item?.user_id) && (
                                            <button
                                              style={{
                                                marginRight:
                                                  !this.isCurrentUser(
                                                    item?.user_id
                                                  ) && "8px",
                                                marginLeft:
                                                  this.isCurrentUser(
                                                    item?.user_id
                                                  ) && "8px",
                                              }}
                                              onClick={() =>
                                                this.handlePollVote(
                                                  chatDetails?.questionId
                                                )
                                              }
                                            >
                                              Vote
                                            </button>
                                          )}

                                          <p
                                            style={{
                                              marginRight:
                                                this.isCurrentUser(item?.user_id) &&
                                                "3px",
                                            }}
                                          >
                                            {chatDetails?.totalVotes ? chatDetails?.totalVotes : "0"} votes
                                          </p>
                                          {/* <p> &#8226; </p> */}
                                          {/* {item?.isScheduled ? <p>This poll is scheduled for
                                            {moment(item?.scheduleDateTime)
                                              .utc(item?.scheduleDateTime)
                                              .local()
                                              .format("MM-DD-YYYY hh:mm a")}</p> : <p></p>} */}
                                        </div>
                                      </div >
                                    )}
                                    </div >
                                  </div>

                                  <div
                                    className="imageWithTimelline"
                                    style={{
                                      position: this.isCurrentUser(item?.user_id)
                                        ? "relative"
                                        : "absolute",
                                      display: this.isCurrentUser(item?.user_id)
                                        ? "block"
                                        : "none",
                                    }}
                                  >
                                    <p>
                                      {
                                        item?.isScheduled ? (
                                          moment(item?.ScheduleTime)
                                            // .utc(item?.ScheduleTime)
                                            .tz(JSON.parse(timeZone))
                                            ?.format("hh:mm a")
                                        ) :
                                          (moment(item?.chat_time)
                                            .utc(item?.chat_time)
                                            .tz(JSON.parse(timeZone))
                                            ?.format("hh:mm a"))}
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div id="myModal" className="modal">
                                <span className="close custom-close" onClick={this.onCLoseImage}>
                                  &times;
                                </span>
                                <img className="modal-content" id="zoomImage" alt="img" />
                              </div>
                            </>
                          );
                        }
                      )
                  ) : (
                    storeData.liveChatReducer.chatState === "LOADING"
                      && storeData.liveChatReducer.chatState !== "EMPTY" ? (
                      <div className="no-message-display-webview">
                        <CircularProgress size={50} color="secondary" />
                      </div>
                    ) : (
                      <div className="no-message-display-webview">
                        No Messages to display
                      </div>
                    )

                  )}
                </div>
              </ScrollToBottom>
            </>
          </div>
          <PollsFooter
            updateWebViewState={this.updateWebViewState}
            detailGifData={detailGifData}
            gif={gif}
            showGif={showGif}
            toggleCamera={this.toggleCamera}
            onGifChange={this.onGifChange}
            //   isSearching={isSearching}
            //   searchArray={searchArray}
            onGifClick={this.onGifClick}
            toggleAlert={toggleAlert}
            ref={(e) => (this.WebViewFooter = e)}
            curbsideCommunication={curbsideCommunication}
          />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    messageData: state.messageReducer,
    chatHistory: state.messageReducer.chatHistory,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getGIF,
      deleteMessage,
      getDetailGIF,
      deleteBusinessMessage,
      clearDetailedGif,
      clearEventExperienceChat,
      setEventExperienceMessagesState,
      setSelectedMultimediaData,
      setLoadEarlierMessage
    },
    dispatch
  );
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(ChatComponent)
);
