import React, { Component } from "react";
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  Modal,
  TextField,
  withStyles,
} from "@material-ui/core";
import TicketLayout, { ContentLayout } from "./layout";
import EventHeader from "../EventDetails/eventHeader";
import { styleClasses } from "./ticketsStyleClasses";
import { Button } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { Close, RemoveCircle } from "@material-ui/icons";
import {
  addOrderForm,
  updateOrderForm,
  deleteOrderForm,
} from "../../../../Actions/eventActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Loader from "../../../../Helper/loader/loader";
import AddIcon from "@material-ui/icons/Add";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { checkIfEventExists } from "../../../../Helper/Validations";
import { cloneDeep } from "lodash";
import { eventApis, Text, Tickets } from "../../../../Constants";
import { axiosE } from "../../../../Service/service";
import DeleteConfirmationModal from "../EventAttendence/Component/ModalAction/DeleteConfirmationDialog";
import * as constants from "../../../../Constants";

const styles = {
  eventCreateTicketContentWrapper: styleClasses.eventCreateTicketContentWrapper,
  generateHeight: styleClasses.generateHeight,
  addButton: styleClasses.addButton,
  createButtonAddText: styleClasses.createButtonAddText,
  createButtonPlusIcon: styleClasses.createButtonPlusIcon,
  addUpdateModal: styleClasses.addUpdateModal,
  addUpdateModalMainWrapper: styleClasses.addUpdateModalMainWrapper,
  addUpdateModalHeader: styleClasses.addUpdateModalHeader,
  addUpdateModalHeaderText: styleClasses.addUpdateModalHeaderText,
  addUpdateModalButtonWrapper: {
    ...styleClasses.addUpdateModalButtonWrapper,
    marginTop: "20px",
  },
  addUpdateModalButtonWrapperCondition: {
    display: "flex",
    gap: "10px",
    width: "100%",
    padding: "30px",
    alignItems: "center",
    justifyContent: "center",
    borderTop: "1px solid #ced4da",
    marginTop: "auto",
    textAlign: "center",
  },
  addUpdateModalButtonCancel: styleClasses.addUpdateModalButtonCancel,
  addUpdateModalButtonSave: styleClasses.addUpdateModalButtonSave,
  addUpdateModalCloseIcon: styleClasses.addUpdateModalCloseIcon,
  termsAndConditionsModal: {
    top: "50%",
    left: "50%",
    height: "fit-content",
    display: "flex",
    position: "absolute",
    transform: "translate(-50%, -50%)",
    alignItems: "center",
    borderRadius: "10px",
    flexDirection: "column",
    justifyContent: "flex-start",
    backgroundColor: "white",
    width: "100%",
    maxWidth: "700px",
  },

  addCustomLabelModal: {
    top: "50%",
    left: "50%",
    width: "648px",
    height: "231px",
    display: "flex",
    padding: "10px",
    position: "absolute",
    transform: "translate(-50%, -50%)",
    alignItems: "center",
    borderRadius: "10px",
    flexDirection: "column",
    justifyContent: "center",
    gap: "30px",
    backgroundColor: "white",
  },
  deleteModalSaveAndCancelButton: styleClasses.deleteModalSaveAndCancelButton,
  // Save And Continue Button
  saveAndContinueButton: styleClasses.saveAndContinueButton,
  saveAndContinueButtonWrapper: styleClasses.saveAndContinueButtonWrapper,
  createEventFAQInputLabel: {
    fontSize: "16px",
    fontWeight: "550",
  },
  createEventFAQInputText: {
    fontSize: "16px",
    marginTop: "-10px",
  },
  orderForm: {
    backgroundColor: "#FAFAFA",
    width: "100%",
    padding: "10px",
    boxShadow: "rgb(99 99 99 / 20%) 0px 2px 8px 0px",
    paddingTop: "20px",
  },
  addLabel: {
    fontSize: "16px",
    color: "grey",
    cursor: "pointer",
  },
};

const defaultFields = [...cloneDeep(Tickets.orderForm.defaultFields)];

class orderForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: [...defaultFields],
      questions: [...cloneDeep(Tickets.orderForm.fields)],
      addModal: false,
      customFieldModal: { show: false, index: "" },
      customTextFileds: [],
      loading: false,
      termsAndConditions: "",
      currentTermsAndConditions: "",
      termsAndConditionsFormFieldId: "",
      termsAndConditionsModal: false,
      showConfirmationDialog: false,
      deletingCustomFieldIndex: null,
      characterCount: 0,
      maxCharacters: 500,
    };
  }

  termsAndConditionsModal = (state) => {
    this.setState({
      termsAndConditionsModal: state,
      currentTermsAndConditions: "",
    });
    if (state) {
      this.setState({
        currentTermsAndConditions: this.state.termsAndConditions,
      });
    }
  };

  handleChange = (e) => {
    const inputValue = e.target.value;

    // Check if the input length exceeds the character limit
    if (inputValue.length > this.state.maxCharacters) {
      return; // Do not update state if character limit is reached
    }

    this.setState({
      currentTermsAndConditions: inputValue,
      characterCount: inputValue.length,
    });
  };

  componentDidMount() {
    checkIfEventExists(this.props.history, this.props.eventId);
    const { eventId } = this.props;

    this.setState({ loading: true });
    axiosE
      .get(eventApis.getOrderFormByEventId + eventId)
      .then((res) => {
        const orderForms = res.data.results.ListOrderFormField;

        // Send Default Fields to remote
        defaultFields.map((field) => {
          if (!orderForms) {

            this.sendOrderForm(field, true);
          }
        });

        if (orderForms.length > 0) {
          ["Phone", "Email", "Full Name"].map((item) => {
            const index = orderForms?.findIndex((object) => {
              return object?.FieldName === item;
            });
            const toIndex = 0;
            const element = orderForms.splice(index, 1)[0];
            orderForms.splice(toIndex, 0, element);
          });
        }

        if (orderForms && orderForms[0] && orderForms.length > 0) {
          const termsAndConditionsForm = orderForms.filter(
            (form) => form.FieldName === "Terms And Conditions"
          );
          if (termsAndConditionsForm && termsAndConditionsForm[0]) {
            this.setState({
              termsAndConditions: termsAndConditionsForm[0].DefaultValue,
              termsAndConditionsFormFieldId:
                termsAndConditionsForm[0].FormFieldId,
            });
          }
          const orderFormWithoutTermsAndConditions = orderForms.filter(
            (form) => form.FieldName !== "Terms And Conditions"
          );
          this.setState({
            fields: orderFormWithoutTermsAndConditions.map((field) => {
              return {
                label: field.FieldName,
                required: field.RequiredField,
                FormFieldId: field.FormFieldId,
              };
            }),
            questions: this.setQuestionOnMount(
              orderFormWithoutTermsAndConditions
            ),
          });
        }
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  setQuestionOnMount = (stateFields) => {
    const origArr = this.state.questions;
    const updatingArr = stateFields;

    for (var i = 0, l = updatingArr.length; i < l; i++) {
      let notFound = true;
      for (var j = 0, ll = origArr.length; j < ll; j++) {
        if (
          updatingArr[i].FieldName === origArr[j].label ||
          updatingArr[i].FieldName === "Email" ||
          updatingArr[i].FieldName === "Full Name" ||
          updatingArr[i].FieldName === "Phone"
        ) {
          notFound = false;
        }
      }
      if (notFound) {

        origArr.push({
          label: updatingArr[i].FieldName,
          value: updatingArr[i].FieldName,
          required: updatingArr[i].RequiredField,
          FormFieldId: updatingArr[i].FormFieldId,
          state: true,
          type: "custom",
        });
      }
    }

    for (var i = 0, l = origArr.length; i < l; i++) {
      for (var j = 0, ll = updatingArr.length; j < ll; j++) {
        if (origArr[i].label === updatingArr[j].FieldName) {
          origArr.splice(i, 1, {
            label: updatingArr[j].FieldName,
            state: true,
            value: updatingArr[j].FieldName,
            FormFieldId: updatingArr[j].FormFieldId,
            type: origArr[i].type === "custom" ? "custom" : "",
          });
          break;
        }
      }
    }

    return origArr;
  };

  handleAddModal = () => {
    this.setState({ addModal: !this.state.addModal });
  };

  customBoxStyle = (name) => {
    return { width: "100%" };
  };

  customStyles = (field, fieldsPresent) => {
    if (field && field.label === "Custom") {
      return {
        cursor: "pointer",
        borderRadius: "5px",
        padding: "20px",
        fontSize: "16px",
        backgroundColor: "#FFFFFF",
        color: "black",
        border: "1px solid rgb(224, 224, 224)",
      };
    } else {
      return {
        cursor: "pointer",
        borderRadius: "5px",
        border: "1px solid rgb(224, 224, 224)",
        padding: "20px",
        fontSize: "16px",
        backgroundColor: field && field.state ? "rgb(182, 14, 181)" : "#FFFFFF",
        color: field && field.state ? "white" : "black",
      };
    }
  };

  fieldBoxGenerator = (questions, i) => {
    return questions.map((field, index) => {
      return (
        <Grid
          item
          xs={6}
          key={index}
          align="center"
          justifyContent="center"
          style={this.customBoxStyle(field && field.label)}
        >
          <Box
            onClick={() =>
              field && field.label === "Custom"
                ? this.addCustomLabelField(index)
                : this.changeFieldState(index)
            }
            sx={this.customStyles(
              field,
              this.state.questions.some(
                (question) => question.type === "custom"
              )
            )}
          >
            {field && field.type === "custom" ? (
              field.value || field.label
            ) : field && field.label === "Custom" ? (
              <>
                {field && field.label}
                {/* <AddIcon /> */}
              </>
            ) : (
              field && field.label
            )}
          </Box>
        </Grid>
      );
    });
  };

  addCustomLabelField = () => {
    this.setState({
      questions: [
        ...this.state.questions,
        { label: "Custom Label", state: true, type: "custom" },
      ],
    });
  };

  saveAndContinue = () => {
    const { classes } = this.props;
    let customField = this.state.fields.filter(
      (question) => question.label === "Custom"
    );

    return (
      <div className={classes.saveAndContinueButtonWrapper}>
        <Button
          className={classes.saveAndContinueButton}
          variant={"contained"}
          onClick={() => this.props.history.push("Discounts")}
        >
          {Text.SaveAndContinue}
        </Button>
      </div>
    );
  };

  sendOrderForm = async (field) => {
    const { eventId } = this.props;
    let res;
    if (field.type === "custom") {
      res = await this.addFormField({
        EventId: eventId,
        FieldName: field.value,
        RequiredField: false,
      });
    } else {
      res = await this.addFormField({
        EventId: eventId,
        FieldName: field.label,
        RequiredField: field.required || false,
      });
    }
    return res;
  };

  addFormField = async (data) => {
    const { addOrderForm } = this.props;
    const res = await addOrderForm(data);
    return res;
  };

  updateFormField = async (data) => {
    const { updateOrderForm, eventId } = this.props;
    const res = await updateOrderForm({
      FormFieldId: data.FormFieldId,
      EventId: eventId,
      FieldName: data.label,
      RequiredField: data.required || false,
    });
    return res;
  };

  addFields = async () => {
    const { questions, fields } = this.state;
    const state = false;

    // Helper function to add a delay
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    // Fire Up Loading Animation
    this.setState({ loading: true });

    let currentActiveState = [];
    questions.forEach((question) => {
      if (question.state) {
        currentActiveState.push({ ...question });
      }
    });

    // New fields: fields which are in currentActiveState but not in fields
    let newFields = this.getFilteredArray(currentActiveState, fields);

    // Custom fields: all fields which are in currentActiveState and have a type of custom
    let customFields = currentActiveState.filter((field) => field.type === "custom");
    let updatedCustomFields = [];

    // If custom fields are present, add them to the database
    for (let customField of customFields) {
      let updated = false;
      for (let field of fields) {
        if (field.FormFieldId && field.FormFieldId === customField.FormFieldId) {
          if (field.label !== customField.label) {
            updated = true;
            break;
          }
        }
      }
      if (updated) {
        this.updateFormField(customField);
      }
    }

    // Old fields: fields which are in fields but not in currentActiveState
    let deleteFields = this.getFilteredArray(fields, currentActiveState);
    const defaultFieldsNames = Array.from(defaultFields, (field) => field.label);
    deleteFields = deleteFields.filter(
        (field) => !defaultFieldsNames.includes(field.label)
    );

    // Delete fields from remote
    for (let field of deleteFields) {
      await this.deleteFromRemote(field).then((res) => {
        this.setState((prevState) => ({
          questions: prevState.questions.map((question) => {
            if (question && question.label === field.label) {
              if (question && question.type !== "custom") {
                return { label: question.label, state: false };
              }
            }
            return question;
          }),
          fields: prevState.fields.filter((field2) => field2.label !== field.label),
        }));
      });
    }

    // Add fields to remote
    let fieldsInPromise = [];
    for (const field of currentActiveState) {
      if (field.hasOwnProperty("FormFieldId")) {
        this.setState((prevState) => ({
          fields: [...defaultFields, ...fieldsInPromise, field],
        }));
        fieldsInPromise.push(field);
      } else {
        await delay(100); // Adding a 0.5-second delay between API calls
        try {
          const res = await this.sendOrderForm(field);
          this.setState((prevState) => ({
            fields: [
              ...defaultFields,
              ...fieldsInPromise,
              { ...field, FormFieldId: res.FormFieldId },
            ],
            questions: prevState.questions.map((ques) =>
                ques.label === field.label
                    ? { ...field, FormFieldId: res.FormFieldId }
                    : ques
            ),
          }));
          fieldsInPromise.push({ ...field, FormFieldId: res.FormFieldId });
        } catch (error) {
          console.error("Error sending order form:", error);
        }
      }
    }

    // Set loading to false only after all custom fields have been processed
    this.setState({
      loading: false,
      addModal: false,
    });
  };

  deleteFromRemote = async (field) => {
    const { deleteOrderForm } = this.props;

    const res = await deleteOrderForm({
      FormFieldId: field.FormFieldId,
    });

    return res;
  };

  getFilteredArray = (ar1, ar2) => {
    // existing fields, current fields
    let newArray = [];
    for (let existingField of ar1) {
      let noPresent = true;
      for (let currentField of ar2) {
        if (existingField.FormFieldId === currentField.FormFieldId)
          noPresent = false;
      }
      if (noPresent) newArray.push(existingField);
    }

    return newArray;
  };
  changeFieldState = (index) => {
    let questions = this.state.questions;
    questions[index].state = !questions[index].state;
    this.setState({ questions });
  };

  addModal = () => {
    const { classes } = this.props;
    const { addModal, questions } = this.state;

    return (
      <Modal open={addModal} onClose={this.handleAddModal}>
        <Box className={classes.addUpdateModal}>
          <div className={classes.addUpdateModalMainWrapper}>
            <div>
              <div className={classes.addUpdateModalHeader}>
                <div className={classes.addUpdateModalCloseIcon}>
                  <IconButton onClick={this.handleAddModal}>
                    <Close height={30} width={30} />
                  </IconButton>
                </div>
                <p className={classes.addUpdateModalHeaderText}>
                  {this.state.fields.length > 2
                    ? "Add/Remove Question"
                    : "Add Question"}
                </p>
              </div>
              <Grid container spacing={3} style={{ paddingBottom: "20px" }}>
                {this.fieldBoxGenerator(
                  questions.filter((question) => question.type !== "custom")
                )}
                {questions.map((question, index) => {
                  if (question && question.type === "custom") {
                    if (question.state) {
                      return (
                        <Grid
                          item
                          xs={12}
                          align="center"
                          justifyContent="center"
                          key={index}
                          onChange={(e) => this.changeCustomField(e, index)}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "13px",
                          }}
                        >
                          <TextField
                            value={question.value}
                            placeholder="Type Question Label"
                            variant="outlined"
                            style={{ width: "100%" }}
                            inputProps={{
                              style: {
                                fontSize: "16px",
                              },
                            }}
                          />
                          <IconButton
                            onClick={() => this.deleteCustomField(index)}
                            style={{
                              transform: "scale(1.2)",
                              color: "red",
                              cursor: "pointer",
                            }}
                          >
                            <RemoveCircle height={24} width={24} />
                          </IconButton>
                        </Grid>
                      );
                    }
                  }
                })}
              </Grid>
            </div>

            <div
              className={classes.addUpdateModalButtonWrapper}
              id="eventCommonModal"
            >
              <Button
                className={classes.addUpdateModalButtonCancel}
                onClick={this.handleAddModal}
              >
                {Text.Cancel}
              </Button>
              <Button
                className={classes.addUpdateModalButtonSave}
                variant={"contained"}
                onClick={() => this.addFields()}
                disabled={this.state.questions.some(
                  (question) =>
                    question.label === "Custom Label" ||
                    question.label.trim() === ""
                )}
              >
                {Text.Save}
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    );
  };

  deleteCustomField = (index) => {
    this.setState({
      showConfirmationDialog: true,
      deletingCustomFieldIndex: index,
    });
  };

  confirmDeleteCustomField = () => {
    const { deletingCustomFieldIndex } = this.state;
    let questions = this.state.questions;
    questions.splice(deletingCustomFieldIndex, 1);
    this.setState({ questions });

    // Close the confirmation dialog
    this.setState({
      showConfirmationDialog: false,
      deletingCustomFieldIndex: null,
    });
  };

  cancelDeleteCustomField = () => {
    // Close the confirmation dialog without deleting
    this.setState({
      showConfirmationDialog: false,
      deletingCustomFieldIndex: null,
    });
  };


  progressLoader = () => {
    const { loading } = this.state;

    return loading && <Loader />;
  };

  changeCustomField = (e, index) => {
    let questions = this.state.questions;
    questions[index].value = e.target.value;
    questions[index].label = e.target.value;
    this.setState({ questions });
  };

  setFieldLabel = (e, index) => {
    const { fields, customTextFiled } = this.state;

    fields[index].value = e.currentTarget.value;
    this.setState({ fields });
  };

  termsAndConditions = () => {
    const { classes } = this.props;
    const {
      termsAndConditions,
      termsAndConditionsModal,
      currentTermsAndConditions,
      characterCount,
      maxCharacters
    } = this.state;
    return (
      <Modal
        open={termsAndConditionsModal}
        onClose={() => this.termsAndConditionsModal(false)}
      >
        <Box className={classes.termsAndConditionsModal}>
          <p
            style={{
              fontSize: 18,
              padding: "20px 0px 20px 0px",
              width: "100%",
              textAlign: "center",
              borderBottom: "1px solid #ced4da",
            }}
          >
            {termsAndConditions.length > 0 ? "Update " : "Add "}
            {Tickets.orderForm.termsAndConditions.headline}
          </p>
          <div style={{ width: '100%' }}>
            <TextField
                value={currentTermsAndConditions}
                onChange={this.handleChange}
                multiline
                rows={10}
                variant="outlined"
                style={{ width: "100%", padding: "30px" }}
                inputProps={{
                  style: {
                    fontSize: 16,
                    paddingTop: "5px",
                    lineHeight: "20px",
                  },
                }}
                placeholder={Tickets.orderForm.termsAndConditions.placeHolder}
            />
            <div style={{ marginBottom: '10px', textAlign: 'right', color: characterCount > maxCharacters ? 'red' : 'inherit', paddingRight:'30px' }}>
              {characterCount}/{maxCharacters}
            </div>
          </div>
          <div className={classes.addUpdateModalButtonWrapperCondition}>
            <Button
              className={classes.addUpdateModalButtonCancel}
              onClick={() => this.termsAndConditionsModal(false)}
            >
              {Text.Cancel}
            </Button>
            <Button
              className={classes.addUpdateModalButtonSave}
              variant={"contained"}
              onClick={() => this.addTermsAndConditions()}
            >
              {Text.Save}
            </Button>
          </div>
        </Box>
      </Modal>
    );
  };
  addTermsAndConditions = async () => {
    const { currentTermsAndConditions, termsAndConditionsFormFieldId } =
      this.state;
    const { eventId, updateOrderForm } = this.props;
    let newId;
    if (termsAndConditionsFormFieldId.length > 0) {
      let id = await updateOrderForm({
        FormFieldId: termsAndConditionsFormFieldId,
        EventId: eventId,
        FieldName: "Terms And Conditions",
        RequiredField: false,
        DefaultValue:
          currentTermsAndConditions.length > 0
            ? currentTermsAndConditions
            : " ",
      });
      newId = termsAndConditionsFormFieldId;
    } else {
      newId = await this.addFormField({
        EventId: eventId,
        FieldName: "Terms And Conditions",
        RequiredField: false,
        DefaultValue: currentTermsAndConditions,
      });
      newId = newId.FormFieldId;
    }
    this.setState({
      termsAndConditions: currentTermsAndConditions,
      termsAndConditionsModal: false,
      currentTermsAndConditions: "",
      termsAndConditionsFormFieldId: newId,
    });
  };

  render() {
    const { classes } = this.props;
    const { fields, termsAndConditions } = this.state;

    return (
        <div>
          {this.state.showConfirmationDialog && (
              <DeleteConfirmationModal
                  isOpen={this.state.showConfirmationDialog}
                  onClose={() => this.cancelDeleteCustomField()}
                  onDelete={() => this.confirmDeleteCustomField()}
                  message={constants.Text.deleteOrderFormCustomFieldMessage} // Pass the message
              />
          )}

          <TicketLayout>
        <EventHeader title={"Order Form"}>
          <Button className={classes.addButton} onClick={this.handleAddModal}>
            <span className={classes.createButtonAddText}>
              {fields.length > 2 ? "Add/Remove Question" : "Add Question"}
            </span>
            <AddIcon className={classes.createButtonPlusIcon} />
          </Button>
        </EventHeader>
        <ContentLayout>
          <Box
            sx={{
              display: "flex",
              marginInline: "auto",
              width: "80%",
              margin: "auto",
              padding: "20px 0px",
              flexDirection: "column",

              "@media (max-width: 768px)": {
                paddingBottom: "60px",
              },
            }}
          >
            <Paper style={{ width: "100%" }}>
              <Box
                sx={{
                  padding: "2.5rem",
                }}
              >
                <h1>{Tickets.orderForm.headline}</h1>
                <p>{Tickets.orderForm.subHead}</p>
                <Box
                  sx={{
                    marginTop: "30px",
                  }}
                >
                  {fields.map((field, i) => (
                    <FormControl className={classes.orderForm} key={i}>
                      <span className={classes.createEventFAQInputLabel}>
                        {field.type === "custom" ? field.value : field.label}
                        {field.required ? (
                          <span style={{ color: "red" }}> *</span>
                        ) : (
                          <span style={{ color: "red" }}> *</span>
                        )}
                      </span>

                      <TextField
                        required={field.required}
                        placeholder={
                          field.type === "custom" ? field.value : field.label
                        }
                        margin="normal"
                        disabled={true}
                        fullWidth
                        type="text"
                        InputProps={{
                          disableUnderline: true,
                          className: classes.createEventFAQInputText,
                        }}
                      />
                    </FormControl>
                  ))}
                </Box>
                {this.addModal()}
              </Box>
            </Paper>
            <p
              style={{
                padding: "15px 25px",
                color: "#a338a4",
                cursor: "pointer",
              }}
              onClick={() => this.termsAndConditionsModal(true)}
            >
              {termsAndConditions.length > 0 ? "Update " : "Add "}
              {Text.AdditionalTerms}
            </p>
          </Box>
          <div style={{ flex: 0.1 }}>{this.saveAndContinue()}</div>
        </ContentLayout>
        {this.progressLoader()}
        {this.termsAndConditions()}
      </TicketLayout>
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { eventUserEvents } = state;
  return {
    eventId: eventUserEvents.eventId,
    discounts: eventUserEvents.discounts,
    orderForms: state.eventUserEvents.orderForms,
  };
};
const matchDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { addOrderForm, updateOrderForm, deleteOrderForm },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(withStyles(styles)(orderForm));
