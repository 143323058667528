import React, { Component } from 'react';
import { InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

const styles = {
    searchEventsOnDashboard:    {
        margin: '10px 10px 0px 0px',
        border: '1px solid #e0e0e0',
        padding: '5px',
        width: '220px',
        color: 'grey',
        "@media screen and (max-device-width: 545px)": {
            width:'100%',
            margin: '10px 10px 10px 10px',
        }
    },

    searchInputEventsOnDashboard:   {
        fontSize: '16px'
    } 
};

class EventsOverviewSearchBar extends Component {
    
    render() {
        const { classes } = this.props;
        return (
            <React.Fragment>
                <TextField
                    onChange={this.props.onSearch}
                    value={this.props.value}
                    className={classes.searchEventsOnDashboard}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon/>
                            </InputAdornment>
                        ),
                        className: classes.searchInputEventsOnDashboard,
                        disableUnderline: true,
                    }}
                    placeholder='Search events'
                />
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(EventsOverviewSearchBar);
