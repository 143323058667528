import React from "react";
// import DatePicker from "react-modern-calendar-datepicker";
// import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import DatePicker from '@hassanmojab/react-modern-calendar-datepicker';
import TimePicker from "rc-time-picker";
import moment from "moment";
import "./EventChat.css";
import storeFolder from "../../../../../store";

const ScheduleDataTimeComp = ({ prop , initialDate , onChangeDate , onChangeTime , type , isSchedulePollEdit , chatDetails}) => {

  const getDate = (date) => {
    let newDate = date === "" ? null : date;
    if (newDate) {
      const dateObj = {
        year: parseInt(moment(newDate).format("YYYY")),
        month: parseInt(moment(newDate).format("MM")),
        day: parseInt(moment(newDate).format("DD")),
      };
      return dateObj;
    } else {
      return null;
    }
  };
  const timeZone = localStorage.getItem("CurrentTimeZone");
  const storeData = storeFolder.getState();
  const endDate = localStorage.getItem("EventEndDate") ? moment(localStorage.getItem("EventEndDate")).format("YYYY/MM/DD") : moment(storeData?.eventUserEvents?.eventsAbout?.EndDateTime).format("YYYY/MM/DD");
  const editDate = moment(chatDetails?.date)
  const initialDt = moment(initialDate?.date).tz(JSON.parse(timeZone))

  return (
    <>
      {type === "start" && prop.state.isValidEndDateTime && (
        <div className="validationOnPollSchedule">
          <p>You can only set the time for future.</p>
        </div>
      )
      }
      {type === "end" && prop.state.isValidEventEndDateTime && (
        <div className="validationOnPollSchedule">
          <p>You cannot set the time after event's end date.</p>
        </div>
      )
      }
      <div
        className="scheduleDateTime"
        style={{ display: "flex", alignItems: "center", gap: 5 }}
      >
        <DatePicker
          value={isSchedulePollEdit && type==="start" ? prop.getDate(moment(chatDetails?.date)) : isSchedulePollEdit && type==="end" ? prop.getDate(moment(chatDetails?.date)) : prop.getDate(moment(initialDate?.date))}
          onChange={onChangeDate}
          inputClassName="date_picker_input"
          colorPrimary="#851679" // added prop
          minimumDate={prop.getDate(
            moment(storeData?.eventUserEvents?.eventAbout?.StartDateTime).format("MM/DD/YYYY hh:mm:ss A")
          )}
          // format={"MM/DD/YYYY"}
          formatInputText={() =>
            isSchedulePollEdit && type==="start" ? moment(editDate).format("MM/DD/YYYY") : isSchedulePollEdit && type==="end" ? moment(editDate).format("MM/DD/YYYY") : moment(initialDate?.date).format("MM/DD/YYYY")
          }
          // wrapperClassName="date_picker_wrapper"
          maximumDate={getDate(endDate)}
          inputPlaceholder="Select Your Time"
        />

        <TimePicker
          placeholder="Select Time"
          use12Hours
          showSecond={false}
          focusOnOpen={true}
          // disabled={moment(
          //   timeUTCtoLocal(EndDatetime, timezone),
          //   "MM/DD/YYYY hh:mm:ss A"
          // ).isBefore(
          //   moment(
          //     moment().tz(timezone),
          //     "MM/DD/YYYY hh:mm:ss A"
          //   )
          // )}
          format="hh:mm A"
          value={isSchedulePollEdit && type==="start" ? (moment(chatDetails?.time)) : isSchedulePollEdit && type==="end" ? (moment(chatDetails?.time)): initialDate?.time}
          onChange={(e) => onChangeTime(e)}
          //  className="time_picker"
          minuteStep={1}
        />
      </div>
    </>
  );
};

export default ScheduleDataTimeComp;
