import React from "react";
import { bindActionCreators } from "redux";
import { connect, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import axios from "axios";
import {
  Button,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  withStyles,
} from "@material-ui/core";
import logo from "../../../images/logo.png";
import copyToClipboard from "../../../images/copy-to-clipboard.png";
import "./sidebar.css";
import { default as momentTz } from "moment-timezone";
import { routeName } from "../../../../src/Service/env";
import { audioFunc } from "../../../Service/helper";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import ListSubheader from "@material-ui/core/ListSubheader";
import { history } from "../../../store";
import { Check } from "@material-ui/icons";
import {
  addSideBarFunctions,
  clearEventDetails,
  currentEventStatus,
  setEventStatus,
  removeEventByEventUser,
  setStripeUserId,
  setEventExperienceData
} from "../../../Actions/eventActions";
import {
  clearEventExperienceActivity , clearEventUserCheckedInList
} from "../../../Actions/messagesAction";
import moment from "moment";
import { eventApis, SidebarLabels } from "../../../Constants";
import { axiosE } from "../../../Service/service";
import * as constants from "../../../Constants";

const styles = (theme) => ({
  drawerPaper: {
    backgroundColor: "#f5f5f5",
    zIndex: "1",
    width: 220,
    height: "100%",
    [theme.breakpoints.up("lg")]: {
      position: "relative",
    },
    maxHeight: "100vh",
    overflowY: "auto",
  },
  toolbar: theme.mixins.toolbar,
  subheading: {
    fontSize: "0.9em",
    fontWeight: "500",
    "&:active": {
      color: "#e13d43",
    },
    color: "rgba(0, 0, 0, 0.87)",
    lineHeight: "1.5em",
    marginLeft: "-10px",
  },

  subheading2: {
    fontSize: "0.9em",
    fontWeight: "500",
    "&:active": {
      color: "#e13d43",
    },
    color: "rgba(0, 0, 0, 0.87)",
    lineHeight: "1.5em",
  },
  subTab: {
    fontSize: "0.9em",
    fontWeight: "500",
    "&:active": {
      color: "#e13d43",
    },
    color: "rgba(0, 0, 0, 0.87)",
    lineHeight: "1.5em",
    paddingLeft: "10px",
  },
  logoIcon: {
    position: "absolute",
    left: "64%",
    transform: "translate(-128%, -128%)",
    background: "none",
    [theme.breakpoints.between("767px", "1022px")]: {
      left: "70%",
      transform: "translate(-180%, -180%)",
      backgroundColor: "red",
    },
    height: "36px",
    width: "36px",
    [theme.breakpoints.up("mdDown")]: {
      display: "none",
    },
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  expandMoreLessIcon: {
    width: "28px",
    height: "28px",
    flex: "auto",
    margin: "0px 20px 0px 10px",
  },
  subHeader: {
    justifyContent: "center",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "1.5em",
    fontWeight: 500,
    marginTop: "10px",
  },
  disabledTabs: {
    "&:hover": {},
    "&:active": {
      pointerEvents: "none",
    },
  },
  disabledTextColor: {
    color: "gray",
    fontSize: "0.9em",
    fontWeight: "500",
    lineHeight: "1.5em",
  },
  disabledTextColorWithCount: {
    color: "gray",

    "&:active": {
      pointerEvents: "none",
    },
  },
  disabledSubTabTextColor: {
    color: "gray",
    fontSize: "0.9em",
    fontWeight: "500",
    lineHeight: "1.5em",
    paddingLeft: "10px",
  },
});

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: 15,
    backgroundColor: "rgba(220, 220, 220, 1)",
    borderStyle: "solid",
    borderColor: "lightgray",
  },
}))(Tooltip);

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    const currentRoute =
      this.props.route.location.pathname.split("/")[
        this.props.route.location.pathname.split("/").length - 1
      ];
    const DetailsSection = [
      "Details",
      "About",
      "FAQ",
      "Agenda",
      "Ads",
      "Sponsors",
      "Vendors",
    ];
    const TicketSection = [
      "Create",
      "Payouts",
      "OrderForm",
      "Discounts",
      "SalesTax",
    ];
    this.state = {
      activeTab: "EventsOverview",
      open: true,
      openDetails: DetailsSection.includes(currentRoute),
      openTicket: TicketSection.includes(currentRoute),
      TicketReady: false,
      PublishReady: false,
      eventStatus: "",
      isRecurring: false,
      APIres: "",
      Checkhover: false,
      tooltipOpen: false,
    };
  }

  setActiveTab = (tab) => {
    this.setState({ activeTab: tab });
  };

  // API calling for check IsEventExperiance
  async componentDidMount() {
    const { eventId, eventStatus } = this.props.eventDetails;

    const CurrentEventId = JSON.parse(localStorage.getItem("CurrentEventId"));
    const recurringTicket = localStorage.getItem("isRecurring");

    console.log({
      isRecurring: recurringTicket === "true" ? true : false,
      type: typeof recurringTicket,
    });

    this.setState((prevState) => {
      return {
        ...prevState,
        isRecurring: recurringTicket === "true" ? true : false,
      };
    });

    if (CurrentEventId) {
      const fetchData = async () => {
        try {
          const userId = localStorage.getItem("UserId");

          axiosE
            .get(
              `${eventApis.GetEventMasterById}${CurrentEventId}/${userId}/Admin`
            )
            .then(({ data }) => {
              this.setState({ APIres: data.results.IsEventExperiance });
              // this.props.setEventExperienceData(data.results.IsEventExperiance);
              // dispatch(setEventExperienceData(data.results.IsEventExperiance))
            });
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      await fetchData();
      axiosE.get(eventApis.getEventStatus + CurrentEventId).then(({ data }) => {
        if (data.results.ResponseCode) {
          this.setState({ eventStatus: JSON.parse(data.results.Data) });
          const { stripeId, setStripeUserId } = this.props;

          if (
            !stripeId &&
            JSON.parse(data.results.Data)?.StripeAccountStatus !== ""
          ) {
            setStripeUserId({
              stripeId: JSON.parse(data.results.Data)?.StripeAccountStatus,
            });
          }
        } else {
          setStripeUserId({
            stripeId: "",
          });
        }
      });
    }

    this.setState({
      activeTab:
        this.props.route.location.pathname.split("/")[
          this.props.route.location.pathname.split("/").length - 1
        ],
      open: this.state.open,
    });
  }

  // setState after redux state update
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.eventDetails.eventStatus !== this.props.eventDetails.eventStatus
    ) {
      this.setState({
        eventStatus:
          this.props.eventDetails.eventStatus === undefined
            ? ""
            : JSON.parse(this.props.eventDetails.eventStatus),
      });
    } else if (
      this.state.eventStatus !== "" &&
      this.props.eventDetails.eventsAbout === null
    ) {
      this.setState({ eventStatus: "" });
    }

    const isRecurring = localStorage.getItem("isRecurring");
    const isRecurringToStr = isRecurring === "true" ? true : false;
    if (isRecurringToStr !== prevState.isRecurring) {
      this.setState((prevState) => {
        return {
          ...prevState,
          isRecurring: isRecurring === "true" ? true : false,
        };
      });
    }
  }

  handleDetailsExpand = () => {

    this.setState({
      openDetails: !this.state.openDetails,
    });
  };
  handleTicketExpand = () => {
    this.setState({
      openTicket: !this.state.openTicket,
    });
  };

  numComponent = (index, status, active) => {
    return (
      <IconButton
        style={{
          height: "8px",
          width: "8px",
          borderRadius: "50%",
          backgroundColor: status == true ? "green" : "#a338a4",
          color: "white",
          fontSize: "10px",
          margin: active ? "0px 0px 0px 12px" : "0px 0px 0px 12px",
        }}
      >
        {status == true ? <Check /> : index + 1}
      </IconButton>
    );
  };

  changeTab = (tab) => () => {
    audioFunc(true, false, "");
    this.setState({
      open: true,
    });

    if (tab === "About") {
      history.push(`${routeName}/Dashboard/About`);
    }

    this.setState({
      activeTab: tab,
    });

    const DetailsSection = [
      "Details",
      "FAQ",
      "Agenda",
      "Ads",
      "Sponsors",
      "Vendors",
    ];
    const TicketSection = [
      "Tickets/Create",
      "Tickets/Payouts",
      "Tickets/OrderForm",
      "Tickets/Discounts",
      "Tickets/SalesTax",
    ];

    if (DetailsSection.includes(tab)) {
      this.setState({
        openDetails: true,
        openTicket: false,
      });
    } else if (TicketSection.includes(tab)) {
      this.setState({
        openDetails: false,
        openTicket: true,
      });
    } else if (tab === "Publish") {
      this.setState({
        openDetails: false,
        openTicket: false,
      });
    }

    localStorage.removeItem("curbsideCommunicationUserId");
    localStorage.removeItem("curbsideCommunicationUserName");
    localStorage.removeItem("curbsideCommunicationFullName");
    localStorage.removeItem("curbsideCommunicationProfileImage");

    this.props.toggle();
  };

  eventPortalTabs = () => {
    const { eventDetails } = this.props;
    const { activeTab } = this.state;
    const currentRoute =
      this.props.route.location.pathname.split("/")[
        this.props.route.location.pathname.split("/").length - 1
      ];

    if (
      this.props.sidebarFunctions === undefined ||
      !this.props.sidebarFunctions.hasOwnProperty("setActiveTab")
    ) {
      this.props.addSideBarFunctions({
        setDetail: this.setDetailsOption,
        setTicket: this.setTicketOption,
        setActiveTab: this.setActiveTab,
      });
    }
    let eventExists =
      eventDetails &&
      !(
        eventDetails.eventId === undefined ||
        eventDetails.eventId === "undefined" ||
        eventDetails.eventId === null ||
        eventDetails.eventId === ""
      )
        ? true
        : false;
    if (
      (!eventExists && currentRoute === "About") ||
      currentRoute === "Details"
    ) {
      eventExists = true;
    } else if (
      currentRoute === "EventsOverview" ||
      currentRoute === "AllSales" ||
      currentRoute === "Activity"
    ) {
      eventExists = false;
    }

    if (
      localStorage.getItem("isItCreateOrEditEvent") !== undefined &&
      localStorage.getItem("isItCreateOrEditEvent") !== null &&
      eventExists
    ) {
      return [
        [
          { route: "EventsOverview", primaryText: "Manage Events" },
          { route: "About", primaryText: "Create Event" },
          { route: "Activity", primaryText: "Activity" },
          { route: "AllSales", primaryText: "Sales" },
          { route: "VisitMyProfile", primaryText: "Visit My Profile" },
        ],
        [
          {
            route: "Details",
            primaryText: "Details",
          },
          {
            route: "Tickets/Create",
            primaryText: "Tickets",
          },
          { route: "Publish", primaryText: "Publish" },
        ],
        [
          { route: "EmailInvitation", primaryText: "Email Invitation" },
          { route: "Flyers", primaryText: "Flyers" },
        ],
        [
          { route: "EventExperience", primaryText: "Event Experience (Pro)" },
          { route: "Attendees", primaryText: "Attendees" },
          { route: "Bookings", primaryText: "Bookings" },
          { route: "Analytics", primaryText: "Analytics" },
        ],
      ];
    } else {
      return [
        [
          { route: "EventsOverview", primaryText: "Manage Events" },
          { route: "About", primaryText: "Create Event" },
          { route: "Activity", primaryText: "Activity" },
          { route: "AllSales", primaryText: "Sales" },
          { route: "VisitMyProfile", primaryText: "Visit My Profile" },
        ],
        // [
        //   {
        //     route: "Details",
        //     primaryText: "Details",
        //   },
        //   {
        //     route: "Tickets/Create",
        //     primaryText: "Tickets",
        //   },
        //   { route: "Publish", primaryText: "Publish" },
        // ],
        // [
        //   { route: "EmailInvitation", primaryText: "Email Invitation" },
        //   { route: "Flyers", primaryText: "Flyers" },
        // ],
        // [
        //   { route: "EventExperience", primaryText: "Event Experience" },
        //   { route: "Attendees", primaryText: "Attendees" },
        //   { route: "Analytics", primaryText: "Analytics" },
        // ],
      ];
    }
  };
  timeUTCtoLocal = (date, timezone) => {
    var local = momentTz
      .tz(moment.utc(date), "DD-MM-YYYY hh:mm:ss a", timezone)
      .format("DD-MM-YYYY hh:mm:ss a");
    return moment(local, "DD-MM-YYYY hh:mm:ss a");
  };
  setEventStatus = () => {
    const { eventDetails } = this.props;
    const statuses = SidebarLabels.eventStatus;
    if (
      eventDetails !== undefined &&
      eventDetails !== null &&
      eventDetails !== "" &&
      eventDetails.eventsAbout !== undefined &&
      eventDetails.eventsAbout !== null &&
      eventDetails.eventsAbout !== ""
    ) {
      const { StartDateTime, EndDateTime, TimeZone } = eventDetails.eventsAbout;
      const StartDate = this.timeUTCtoLocal(StartDateTime, TimeZone);
      const EndDate = this.timeUTCtoLocal(EndDateTime, TimeZone);
      const currentDate = moment();
      if (
        (this.props.eventDetails.eventPublish ||
          //There is a proble in safari browser
          this.state.eventStatus?.Status === "Published") &&
        this.state.eventStatus?.Status !== "Draft"
      ) {
        return statuses.live;
      } else if (
        currentDate.isBetween(StartDate, EndDate) ||
        StartDate.isAfter(currentDate) ||
        this.state.eventStatus?.Status === "Draft"
      ) {
        return statuses.draft;
      } else {
        return statuses.ended;
      }
    } else {
      return statuses.draft;
    }
  };

  sideBarLists = (isDrawer) => {
    const { classes } = this.props;
    const lists = this.eventPortalTabs();
    let subLists = [];
    {
      lists.forEach((list, index) => {
        subLists.push(
          <React.Fragment key={index}>
            <List
              className="nav"
              style={{
                width: "100%",
                color: "black !important",
              }}
              subheader={
                list.map((element) => element.route).includes("Details") ? (
                  <ListSubheader className={classes.subHeader}>
                    {localStorage.getItem("isItCreateOrEditEvent") !==
                      undefined &&
                    localStorage.getItem("isItCreateOrEditEvent") !== null ? (
                      localStorage
                        .getItem("isItCreateOrEditEvent")
                        .match("Create") ? (
                        "Create Event"
                      ) : localStorage
                          .getItem("isItCreateOrEditEvent")
                          .match("Edit") ? (
                        <div style={{ textAlign: "center" }}>
                          {this.setEventStatus().text !== "Live" ? (
                            <p style={{ fontSize: "1.7rem" }}>
                              You are creating an event <br />
                            </p>
                          ) : (
                            <>
                              <p
                                style={{
                                  fontSize: "1.7rem",
                                  width: "175px",
                                  wordWrap: "break-word",
                                  margin: "0px auto",
                                }}
                              >
                                {this.props.eventDetails.eventsAbout.Title}
                              </p>
                              <Button
                                variant="contained"
                                color="primary"
                                style={{ borderRadius: "30px" }}
                                onClick={() =>
                                  window.open(
                                    SidebarLabels.eventUrl +
                                      this.props.eventDetails.eventId,
                                    "_blank"
                                  )
                                }
                              >
                                {SidebarLabels.eventView}
                              </Button>
                            </>
                          )}
                          <p>
                            Status:{" "}
                            <span
                              style={{ color: this.setEventStatus().color }}
                            >
                              {this.setEventStatus().text}
                            </span>
                          </p>
                        </div>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </ListSubheader>
                ) : list
                    .map((element) => element.route)
                    .includes("EmailInvitation") ? (
                  <ListSubheader className={classes.subHeader}>
                    {localStorage.getItem("isItCreateOrEditEvent") !==
                      undefined &&
                    localStorage.getItem("isItCreateOrEditEvent") !== null ? (
                      <p
                        style={{
                          fontSize: "2rem",
                          marginTop: "10px",
                          marginBottom: 20,
                        }}
                      >
                        Promote Event
                      </p>
                    ) : (
                      ""
                    )}
                  </ListSubheader>
                ) : list
                    .map((element) => element.route)
                    .includes("Attendees") ? (
                  <ListSubheader className={classes.subHeader}>
                    {localStorage.getItem("isItCreateOrEditEvent") !==
                      undefined &&
                    localStorage.getItem("isItCreateOrEditEvent") !== null ? (
                      <p
                        style={{
                          fontSize: "2rem",
                          marginTop: "10px",
                          marginBottom: 20,
                        }}
                      >
                        Manage Event
                      </p>
                    ) : (
                      ""
                    )}
                  </ListSubheader>
                ) : (
                  ""
                )
              }
            >
              {index === 0 && isDrawer ? (
                <img src={logo} alt="logo1" className={classes.logoIcon} />
              ) : (
                ""
              )}
              {this.sideBarSubLists(list)}
            </List>

            <Divider />
          </React.Fragment>
        );
      });
    }
    return <React.Fragment>{subLists}</React.Fragment>;
  };

  setDetailsOption = (bol) => {
    this.setState({ openDetails: bol });
  };
  setTicketOption = (bol) => {
    this.setState({ openTicket: bol });
  };
  setStateToEmpty = () => {
    const { activeTab } = this.state;
    this.props.clearEventDetails();
    // this.setState({eventStatus:""})
    localStorage.setItem("isItCreateOrEditEvent", "Edit");
    // localStorage.setItem("persist:persisted-key", "");
    this.props.removeEventByEventUser();
    localStorage.setItem("buyerFee", null);
    localStorage.setItem("isCloneEevent", null);

    this.setState({
      activeTab: "About",
      openDetails: true,
      openTicket: false,
    });
    setTimeout(() => {
      if (activeTab === "About" || activeTab === "Details") {
        window.location.reload();
      } else {
        // history.push(`${routeName}/Dashboard/About`);
        window.location.reload();
      }
    }, 200);
  };

  sideBarSubLists = (list) => {
    let subList = [];
    let { open, openTicket, openDetails } = this.state;
    const { classes, eventDetails } = this.props;
    let { activeTab } = this.state;

    let eventExists = false;

    const DetailsSection = [
      "Details",
      "About",
      "FAQ",
      "Agenda",
      "Ads",
      "Sponsors",
      "Vendors",
    ];
    const TicketSection = [
      "Create",
      "Payouts",
      "OrderForm",
      "Discounts",
      "SalesTax",
    ];

    // Check if eventDetails Exists
    if (
      eventDetails !== undefined &&
      eventDetails !== null &&
      eventDetails !== "" &&
      eventDetails.eventsAbout !== undefined &&
      eventDetails.eventsAbout !== null &&
      eventDetails.eventsAbout !== ""
    ) {
      eventExists = true;
    }

    activeTab =
      this.props.route.location.pathname.split("/")[
        this.props.route.location.pathname.split("/").length - 1
      ];

    list.forEach((element, index) => {
      const { route, primaryText } = element;

      subList.push(
        <React.Fragment key={index}>
          {this.sideBarElement(element, index)}

          {localStorage.getItem("isItCreateOrEditEvent") !== undefined &&
          localStorage.getItem("isItCreateOrEditEvent") !== null &&
          primaryText === "Details" ? (
            <div style={{ width: "100%" }}>
              <Collapse
                in={openDetails}
                timeout="auto"
                unmountOnExit
                style={{ width: "100%" }}
              >
                <List
                  component="div"
                  disablePadding
                  style={{ paddingLeft: "20px" }}
                >
                  <ListItem
                    className={activeTab === "About" ? `sideBar-About` : ""}
                    onClick={this.changeTab(route)}
                  >
                    <NavLink
                      to={`${routeName}/Dashboard/About`}
                      style={{ width: "100%" }}
                    >
                      <ListItemText
                        primary="About"
                        primaryTypographyProps={{
                          className: classes.subTab,
                        }}
                      />
                    </NavLink>
                  </ListItem>
                  {localStorage
                    .getItem("isItCreateOrEditEvent")
                    .match("Edit") && eventExists ? (
                    <ListItem
                      className={
                        localStorage
                          .getItem("isItCreateOrEditEvent")
                          .match("Edit") && eventExists
                          ? activeTab === "FAQ"
                            ? `sideBar-${activeTab}`
                            : ""
                          : classes.disabledTabs
                      }
                      onClick={this.changeTab("FAQ")}
                    >
                      <NavLink
                        to={`${routeName}/Dashboard/FAQ`}
                        style={{ width: "100%" }}
                      >
                        <ListItemText
                          primary="FAQ"
                          primaryTypographyProps={{
                            className: localStorage
                              .getItem("isItCreateOrEditEvent")
                              .match("Edit")
                              ? classes.subTab
                              : classes.disabledSubTabTextColor,
                          }}
                        />
                      </NavLink>
                    </ListItem>
                  ) : (
                    <LightTooltip title={SidebarLabels.eventDetailsError}>
                      <ListItem
                        className={
                          localStorage
                            .getItem("isItCreateOrEditEvent")
                            .match("Edit") && eventExists
                            ? activeTab === "FAQ"
                              ? `sideBar-${activeTab}`
                              : ""
                            : classes.disabledTabs
                        }
                        onClick={this.changeTab("FAQ")}
                      >
                        <NavLink
                          to={`${routeName}/Dashboard/FAQ`}
                          style={{ width: "100%" }}
                        >
                          <ListItemText
                            primary="FAQ"
                            primaryTypographyProps={{
                              className:
                                localStorage
                                  .getItem("isItCreateOrEditEvent")
                                  .match("Edit") && eventExists
                                  ? classes.subTab
                                  : classes.disabledSubTabTextColor,
                            }}
                          />
                        </NavLink>
                      </ListItem>
                    </LightTooltip>
                  )}

                  {localStorage
                    .getItem("isItCreateOrEditEvent")
                    .match("Edit") && eventExists ? (
                    <ListItem
                      className={
                        localStorage
                          .getItem("isItCreateOrEditEvent")
                          .match("Edit") && eventExists
                          ? activeTab === "Agenda"
                            ? `sideBar-${activeTab}`
                            : ""
                          : classes.disabledTabs
                      }
                      onClick={this.changeTab("Agenda")}
                    >
                      <NavLink
                        to={`${routeName}/Dashboard/Agenda`}
                        style={{ width: "100%" }}
                      >
                        <ListItemText
                          primary="Agenda"
                          primaryTypographyProps={{
                            className:
                              localStorage
                                .getItem("isItCreateOrEditEvent")
                                .match("Edit") && eventExists
                                ? classes.subTab
                                : classes.disabledSubTabTextColor,
                          }}
                        />
                      </NavLink>
                    </ListItem>
                  ) : (
                    <LightTooltip title={SidebarLabels.eventDetailsError}>
                      <ListItem
                        className={
                          localStorage
                            .getItem("isItCreateOrEditEvent")
                            .match("Edit") && eventExists
                            ? activeTab === "Agenda"
                              ? `sideBar-${activeTab}`
                              : ""
                            : classes.disabledTabs
                        }
                        onClick={this.changeTab("Agenda")}
                      >
                        <NavLink
                          to={`${routeName}/Dashboard/Agenda`}
                          style={{ width: "100%" }}
                        >
                          <ListItemText
                            primary="Agenda"
                            primaryTypographyProps={{
                              className:
                                localStorage
                                  .getItem("isItCreateOrEditEvent")
                                  .match("Edit") && eventExists
                                  ? classes.subTab
                                  : classes.disabledSubTabTextColor,
                            }}
                          />
                        </NavLink>
                      </ListItem>
                    </LightTooltip>
                  )}

                  {localStorage
                    .getItem("isItCreateOrEditEvent")
                    .match("Edit") && eventExists ? (
                    <ListItem
                      className={
                        localStorage
                          .getItem("isItCreateOrEditEvent")
                          .match("Edit") && eventExists
                          ? activeTab === "Ads"
                            ? `sideBar-${activeTab}`
                            : ""
                          : classes.disabledTabs
                      }
                      onClick={this.changeTab("Ads")}
                    >
                      <NavLink
                        to={`${routeName}/Dashboard/Ads`}
                        style={{ width: "100%" }}
                      >
                        <ListItemText
                          primary="Ads"
                          primaryTypographyProps={{
                            className:
                              localStorage
                                .getItem("isItCreateOrEditEvent")
                                .match("Edit") && eventExists
                                ? classes.subTab
                                : classes.disabledSubTabTextColor,
                          }}
                        />
                      </NavLink>
                    </ListItem>
                  ) : (
                    <LightTooltip title={SidebarLabels.eventDetailsError}>
                      <ListItem
                        className={
                          localStorage
                            .getItem("isItCreateOrEditEvent")
                            .match("Edit") && eventExists
                            ? activeTab === "Ads"
                              ? `sideBar-${activeTab}`
                              : ""
                            : classes.disabledTabs
                        }
                        onClick={this.changeTab("Ads")}
                      >
                        <NavLink
                          to={`${routeName}/Dashboard/Ads`}
                          style={{ width: "100%" }}
                        >
                          <ListItemText
                            primary="Ads"
                            primaryTypographyProps={{
                              className:
                                localStorage
                                  .getItem("isItCreateOrEditEvent")
                                  .match("Edit") && eventExists
                                  ? classes.subTab
                                  : classes.disabledSubTabTextColor,
                            }}
                          />
                        </NavLink>
                      </ListItem>
                    </LightTooltip>
                  )}

                  {localStorage
                    .getItem("isItCreateOrEditEvent")
                    .match("Edit") && eventExists ? (
                    <ListItem
                      className={
                        localStorage
                          .getItem("isItCreateOrEditEvent")
                          .match("Edit") && eventExists
                          ? activeTab === "Vendors"
                            ? `sideBar-${activeTab}`
                            : ""
                          : classes.disabledTabs
                      }
                      onClick={this.changeTab("Vendors")}
                    >
                      <NavLink
                        to={`${routeName}/Dashboard/Vendors`}
                        style={{ width: "100%" }}
                      >
                        <ListItemText
                          primary="Vendors"
                          primaryTypographyProps={{
                            className:
                              localStorage
                                .getItem("isItCreateOrEditEvent")
                                .match("Edit") && eventExists
                                ? classes.subTab
                                : classes.disabledSubTabTextColor,
                          }}
                        />
                      </NavLink>
                    </ListItem>
                  ) : (
                    <LightTooltip title={SidebarLabels.eventDetailsError}>
                      <ListItem
                        className={
                          localStorage
                            .getItem("isItCreateOrEditEvent")
                            .match("Edit") && eventExists
                            ? activeTab === "Vendors"
                              ? `sideBar-${activeTab}`
                              : ""
                            : classes.disabledTabs
                        }
                        onClick={this.changeTab("Vendors")}
                      >
                        <NavLink
                          to={`${routeName}/Dashboard/Vendors`}
                          style={{ width: "100%" }}
                        >
                          <ListItemText
                            primary="Vendors"
                            primaryTypographyProps={{
                              className:
                                localStorage
                                  .getItem("isItCreateOrEditEvent")
                                  .match("Edit") && eventExists
                                  ? classes.subTab
                                  : classes.disabledSubTabTextColor,
                            }}
                          />
                        </NavLink>
                      </ListItem>
                    </LightTooltip>
                  )}

                  {localStorage
                    .getItem("isItCreateOrEditEvent")
                    .match("Edit") && eventExists ? (
                    <ListItem
                      className={
                        localStorage
                          .getItem("isItCreateOrEditEvent")
                          .match("Edit") && eventExists
                          ? activeTab === "Sponsors"
                            ? `sideBar-${activeTab}`
                            : ""
                          : classes.disabledTabs
                      }
                      onClick={this.changeTab("Sponsors")}
                    >
                      <NavLink
                        to={`${routeName}/Dashboard/Sponsors`}
                        style={{ width: "100%" }}
                      >
                        <ListItemText
                          primary="Sponsors"
                          primaryTypographyProps={{
                            className:
                              localStorage
                                .getItem("isItCreateOrEditEvent")
                                .match("Edit") && eventExists
                                ? classes.subTab
                                : classes.disabledSubTabTextColor,
                          }}
                        />
                      </NavLink>
                    </ListItem>
                  ) : (
                    <LightTooltip title={SidebarLabels.eventDetailsError}>
                      <ListItem
                        className={
                          localStorage
                            .getItem("isItCreateOrEditEvent")
                            .match("Edit") && eventExists
                            ? activeTab === "Sponsors"
                              ? `sideBar-${activeTab}`
                              : ""
                            : classes.disabledTabs
                        }
                        onClick={this.changeTab("Sponsors")}
                      >
                        <NavLink
                          to={`${routeName}/Dashboard/Sponsors`}
                          style={{ width: "100%" }}
                        >
                          <ListItemText
                            primary="Sponsors"
                            primaryTypographyProps={{
                              className:
                                localStorage
                                  .getItem("isItCreateOrEditEvent")
                                  .match("Edit") && eventExists
                                  ? classes.subTab
                                  : classes.disabledSubTabTextColor,
                            }}
                          />
                        </NavLink>
                      </ListItem>
                    </LightTooltip>
                  )}
                </List>
              </Collapse>
            </div>
          ) : localStorage.getItem("isItCreateOrEditEvent") &&
            localStorage.getItem("isItCreateOrEditEvent").match("Edit") &&
            primaryText === "Tickets" ? (
            <div style={{ width: "100%" }}>
              <Collapse
                in={openTicket}
                timeout="auto"
                unmountOnExit
                style={{ width: "100%" }}
              >
                <List
                  component="div"
                  disablePadding
                  style={{ paddingLeft: "20px" }}
                >
                  {[
                    { name: "Create Tickets", route: "Tickets/Create" },
                    { name: "Payouts", route: "Tickets/Payouts" },
                    { name: "Order Form", route: "Tickets/OrderForm" },
                    { name: "Discount Codes", route: "Tickets/Discounts" },
                    { name: "Tax and Fee Options", route: "Tickets/SalesTax" },
                  ].map(({ name, route }) =>
                    localStorage
                      .getItem("isItCreateOrEditEvent")
                      .match("Edit") && eventExists ? (
                      <ListItem
                        className={
                          localStorage
                            .getItem("isItCreateOrEditEvent")
                            .match("Edit") && eventExists
                            ? activeTab === route.split("/")[1]
                              ? `sideBar-Ticket-Option`
                              : ""
                            : classes.disabledTabs
                        }
                        onClick={this.changeTab(route)}
                        key={route}
                      >
                        <NavLink
                          to={`${routeName}/Dashboard/${route}`}
                          style={{ width: "100%" }}
                        >
                          <ListItemText
                            primary={name}
                            primaryTypographyProps={{
                              className:
                                localStorage
                                  .getItem("isItCreateOrEditEvent")
                                  .match("Edit") && eventExists
                                  ? classes.subTab
                                  : classes.disabledSubTabTextColor,
                            }}
                          />
                        </NavLink>
                      </ListItem>
                    ) : (
                      <LightTooltip title={SidebarLabels.eventDetailsError}>
                        <ListItem
                          className={
                            localStorage
                              .getItem("isItCreateOrEditEvent")
                              .match("Edit") && eventExists
                              ? activeTab === route.split("/")[1]
                                ? `sideBar-Ticket-Option`
                                : ""
                              : classes.disabledTabs
                          }
                          onClick={this.changeTab(route)}
                        >
                          <NavLink
                            to={`${routeName}/Dashboard/${route}`}
                            style={{ width: "100%" }}
                          >
                            <ListItemText
                              primary={name}
                              primaryTypographyProps={{
                                className:
                                  localStorage
                                    .getItem("isItCreateOrEditEvent")
                                    .match("Edit") && eventExists
                                    ? classes.subTab
                                    : classes.disabledSubTabTextColor,
                              }}
                            />
                          </NavLink>
                        </ListItem>
                      </LightTooltip>
                    )
                  )}
                </List>
              </Collapse>
            </div>
          ) : (
            ""
          )}
        </React.Fragment>
      );
    });
    return <React.Fragment>{subList}</React.Fragment>;
  };

  handleTicketTooltipOpen = () => {
    const eventUserEvents = this.props.eventDetails;
    const TicketReady = eventUserEvents && eventUserEvents.eventId;
    if (!TicketReady) {
      this.setState({ TicketReady: true });
    }
  };

  handleTicketTooltipClose = () => {
    this.setState({ TicketReady: false });
  };
  handleTooltipOpen = (dependency, state) => {
    const eventUserEvents = this.props.eventDetails;
    const ready = eventUserEvents && eventUserEvents[dependency];
    if (!ready) {
      this.setState({ [state]: true });
    } else {
      this.setState({ [state]: false });
    }
  };
  handleTooltipClose = (state) => {
    this.setState({ [state]: false });
  };

  getEventStatus = async () => {
    const { eventId } = this.props.eventDetails;
    if (eventId) {
      axiosE.get(eventApis.getEventStatus + eventId).then((res) => {
        this.setState({ eventStatus: JSON.parse(res.data.results.Data) });
      });
    }
  };
  sideBarElement = (element, index) => {
    const { classes } = this.props;
    let { activeTab, open, openDetails, openTicket, tooltipOpen } = this.state;
    activeTab =
      this.props.route.location.pathname.split("/")[
        this.props.route.location.pathname.split("/").length - 1
      ];
    const { route, primaryText } = element;
    const ticketsComplete =
      (this.state.eventStatus
        ? this.state.eventStatus.BillingAmount !== ""
        : false) ||
      (this.props.eventDetails.tickets &&
        this.props.eventDetails.tickets.length > 0);

    return  primaryText === "Details" ? (
      <ListItem
        style={{ cursor: "pointer" }}
        onClick={this.handleDetailsExpand}
        className={activeTab === route ? `sideBar-${route}` : ""}
      >
        {this.numComponent(
          index,
          this.props.eventDetails.eventsAbout &&
            this.props.eventDetails?.eventsAbout?.EventId?.length > 0,
          activeTab === route
        )}
        <ListItemText
          primary={primaryText}
          primaryTypographyProps={{
            className: classes.subheading,
            style: {
              paddingLeft: "20px",
              fontSize: "0.9em",
              color: "rgba(0, 0, 0, 0.87)",
            },
          }}
        />
        <div>
          {openDetails ? (
            <ExpandLess className={classes.expandMoreLessIcon} />
          ) : (
            <ExpandMore
              className={classes.expandMoreLessIcon}
              // style={{ cursor: "pointer" }}
              // onClick={this.handleDetailsExpand}
            />
          )}
        </div>
      </ListItem>
    ) : primaryText === "Tickets" ? (
      <LightTooltip
        title={SidebarLabels.eventAboutError}
        open={this.state.TicketReady}
        onClose={this.handleTicketTooltipClose}
        onOpen={this.handleTicketTooltipOpen}
      >
        <ListItem
          style={{ cursor: "pointer" }}
          onClick={this.handleTicketExpand}
          className={activeTab === route ? `sideBar-${route}` : ""}
        >
          {this.numComponent(index, ticketsComplete, activeTab === route)}
          <ListItemText
            primary={primaryText}
            primaryTypographyProps={{
              className: classes.subheading,
              style: {
                paddingLeft: "20px",
                fontSize: "0.9em",
                color: "rgba(0, 0, 0, 0.87)",
              },
            }}
          />
          <div>
            {openTicket ? (
              <ExpandLess
                className={classes.expandMoreLessIcon}
                // style={{ cursor: "pointer" }}
                // onClick={this.handleTicketExpand}
              />
            ) : (
              <ExpandMore
                className={classes.expandMoreLessIcon}
                // style={{ cursor: "pointer" }}
                // onClick={this.handleTicketExpand}
              />
            )}
          </div>
        </ListItem>
      </LightTooltip>
    ) : primaryText === "Publish" ? (
      <LightTooltip
        title={SidebarLabels.eventAboutError}
        open={this.state.PublishReady}
        onOpen={() => this.handleTooltipOpen("eventsAbout", "PublishReady")}
        onClose={() => this.handleTooltipClose("PublishReady")}
      >
        <ListItem
          className={
            this.props.eventDetails && this.props.eventDetails.eventsAbout
              ? activeTab === route
                ? `sideBar-${route}`
                : ""
              : classes.disabledTabs
          }
          onClick={this.changeTab(route)}
        >
          {primaryText === "Publish"
            ? this.numComponent(
                index,
                this.state?.eventStatus?.Status === "Published",
                activeTab === route
              )
            : ""}

          <NavLink
            to={`${routeName}/Dashboard/${route}`}
            style={{
              width: "100%",
              padding: "0px",
              height: "34.64px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <ListItemText
              primary={primaryText}
              primaryTypographyProps={{
                className: classes.subheading,
                style: {
                  paddingLeft: "20px",
                  fontSize: "12.6px",
                  color: "rgba(0, 0, 0, 0.87)",
                },
              }}
            />
          </NavLink>
        </ListItem>
      </LightTooltip>
    ) : primaryText === "Attendees" ||
      primaryText === "Bookings" ||
      primaryText === "Event Experience (Pro)" ||
      primaryText === "Analytics" ||
      primaryText === "Email Invitation" ||
      primaryText === "Flyers" ? (
      this.props.eventDetails && this.props.eventDetails.eventPublish ? (
        <LightTooltip title="">
          <ListItem
            // disabled={route === "EventExperience"}
            className={
              activeTab === route
                ? `sideBar-${route} ${classes.subheading2}`
                : classes.subheading2
            }
            onClick={() => {
              this.changeTab(route);

              if (primaryText === "Bookings") {
                localStorage.setItem(
                  "CurrentEventId",
                  JSON.stringify(this.props.eventDetails.eventId)
                );
              }
            }}
          >
            {primaryText === "Bookings" ? (
              (this.props.eventDetails?.eventsAbout?.RecurringTicket ===
                "True" ||
                this.state.isRecurring) && (
                <NavLink
                  to={`${routeName}/Dashboard/${route}/${this.props.eventDetails.eventId}`}
                  style={{ width: "100%" }}
                >
                  <ListItemText
                    primary={primaryText}
                    primaryTypographyProps={{
                      className: classes.subheading2,
                    }}
                    style={{
                      paddingBottom:
                        primaryText === "Analytics" ? "0px" : "0px",
                    }}
                  />
                </NavLink>
              )
            ) : /* EventExperience   check true or false */

            route === "EventExperience" ? (
              this.state.APIres ==
              "True" /* if you do not  want to access the EventExperience Tag jus True the value  */ ? (
                <NavLink
                  to={`${routeName}/Dashboard/${route}`}
                  style={{ width: "100%" }}
                >
                  <ListItemText
                    primary={primaryText}
                    primaryTypographyProps={{
                      className: classes.subheading2,
                    }}
                    style={{
                      paddingBottom:
                        primaryText === "Analytics" ? "0px" : "0px",
                    }}
                  />
                </NavLink>
              ) : (
                <LightTooltip
                  title={
                    "This is a premium feature: to activate, please email support@ubme.com or call (800)-359-0059"
                  }
                >
                  {
                    this.state.APIres === "True"  ? (
                      <NavLink to={"#"} style={{ width: "100%" }}>
                    <ListItemText
                        primary={primaryText}
                        primaryTypographyProps={{
                          className: classes.subheading2,
                          className: classes.disabledTextColor,
                        }}
                        style={{
                          paddingBottom:
                            primaryText === "Analytics" ? "0px" : "0px",
                        }}
                      />
                    </NavLink>
                    ) : (
                      <div style={{marginLeft:"25px"}}>
                        <NavLink to={"#"} style={{ width: "100%" }}>
                          <ListItemText
                            primary={primaryText}
                            primaryTypographyProps={{
                              className: classes.subheading2,
                              className: classes.disabledTextColor,
                            }}
                            style={{
                              paddingBottom:
                                primaryText === "Analytics" ? "0px" : "0px",
                            }}
                          />
                        </NavLink>
                      </div>
                    )
                  }
                </LightTooltip>
              )
            ) : (
              <NavLink
                to={`${routeName}/Dashboard/${route}`}
                style={{ width: "100%" }}
              >
                <ListItemText
                  primary={primaryText}
                  primaryTypographyProps={{
                    className: classes.subheading2,
                  }}
                  style={{
                    paddingBottom: primaryText === "Analytics" ? "0px" : "0px",
                  }}
                />
              </NavLink>
            )}
          </ListItem>
        </LightTooltip>
      ) : (
        <LightTooltip
          title={SidebarLabels.eventPublishError}
          open={this.state[primaryText]}
          onOpen={() => this.handleTooltipOpen("eventPublish", primaryText)}
          onClose={() => this.handleTooltipClose(primaryText)}
        >
          <ListItem
            className={classes.disabledTabs}
            onClick={this.changeTab(route)}
          >
            {primaryText !== "Bookings" ? (
              <NavLink
                to={`${routeName}/Dashboard/${route}`}
                style={{ width: "100%" }}
              >
                <ListItemText
                  primary={primaryText}
                  primaryTypographyProps={{
                    className: classes.disabledTextColor,
                  }}
                />
              </NavLink>
            ) : (
              ""
            )}
          </ListItem>
        </LightTooltip>
      )
    ) : primaryText === "Create Event" ? (
      <ListItem
        className={classes.subheading2}
        onClick={() => {
          localStorage.removeItem("CurrentEventId");
          localStorage.removeItem("isRecurring");
          this.props.clearEventExperienceActivity();
          this.props.clearEventUserCheckedInList();
          this.setStateToEmpty(primaryText, route);
        }}
      >
        <NavLink to={`${routeName}/Dashboard/About`} style={{ width: "100%" }}>
          <ListItemText
            primary={primaryText}
            primaryTypographyProps={{
              className: classes.subheading2,
            }}
          />
        </NavLink>
      </ListItem>
    ) : primaryText === "Manage Events" ||
      primaryText === "Sales" ||
      primaryText === "Activity" ? (
      <ListItem
        className={classes.subheading2}
        onClick={() => this.setLocalStorageToCreate(route)}
      >
        <NavLink
          to={`${routeName}/Dashboard/${route}`}
          style={{ width: "100%" }}
        >
          <ListItemText
            primary={primaryText}
            primaryTypographyProps={{
              className: classes.subheading2,
            }}
          />
        </NavLink>
      </ListItem>
    ) : primaryText === "Visit My Profile" ? (
        <div style={{ justifyContent: "center"}}>
          {/*<Button*/}
          {/*    variant="contained"*/}
          {/*    color="primary"*/}
          {/*    style={{ borderRadius: "30px" }}*/}
          {/*    onClick={() =>*/}
          {/*        window.open(*/}
          {/*            `${routeName}/${this.props?.user?.ProfileDetail?.UserId}~${this.props?.user?.ProfileDetail?.UserName}`,*/}
          {/*            "_blank"*/}
          {/*        )*/}
          {/*    }*/}
          {/*>*/}
          {/*  {primaryText}*/}
          {/*</Button>*/}
          <a style={{
            fontSize:"0.9em",
            fontWeight:"500",
            lineHeight:"1.5em",
            cursor: "pointer",
            textDecoration: "underline",
            color: "#ac39ac"
          }}
              target="_blank" href={`${routeName}/${this.props?.user?.ProfileDetail?.UserId}~${this.props?.user?.ProfileDetail?.UserName}`}>
            {primaryText}
          </a>
          <Tooltip
              title="Copied!"
              open={tooltipOpen}
              disableHoverListener
              disableFocusListener
              disableTouchListener
              onClose={() => this.setState({ tooltipOpen: false })}
              PopperProps={{
                disablePortal: true,
              }}
              placement="bottom"
              arrow
          >
            <button
                style={{
                  backgroundColor: "inherit",
                  border: "none",
                  marginLeft: "-15px",
                  cursor: "pointer"
                }}
                onClick={() => this.handleCopy(`${constants.URLs.hostName}${routeName}/${this.props?.user?.ProfileDetail?.UserId}~${this.props?.user?.ProfileDetail?.UserName}`)}
                className="your-button-class"
            >
              <img
                  src={copyToClipboard}
                  alt="Copy"
                  style={{ width: "24px" }}
              />
            </button>
          </Tooltip>
        </div>
        // <ListItem
        //     className={classes.subheading2}
        //     onClick={() => this.changeTab(route)}
        // >
        //   <NavLink
        //       to={`${routeName}/${this.props?.user?.ProfileDetail?.UserId}~${this.props?.user?.ProfileDetail?.UserName}`}
        //       style={{ width: "100%" }}
        //   >
        //     <ListItemText
        //         primary={primaryText}
        //         primaryTypographyProps={{
        //           className: classes.subheading2,
        //         }}
        //     />
        //   </NavLink>
        // </ListItem>
    ) : (
      <ListItem className={classes.subheading2} onClick={this.changeTab(route)}>
        <NavLink
          to={`${routeName}/Dashboard/${route}`}
          style={{ width: "100%" }}
        >
          <ListItemText
            primary={primaryText}
            primaryTypographyProps={{
              className: classes.subheading2,
            }}
          />
        </NavLink>
      </ListItem>
    );
  };

  handleCopy = async (checkoutUrl) => {
    try {
      await navigator.clipboard.writeText(checkoutUrl);
      this.setState({ tooltipOpen: true });
      // setButtonText("Copied!");
      setTimeout(() => {
        this.setState({ tooltipOpen: false });
      }, 2000); // Reset to "Copy" after 2 seconds
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  setLocalStorageToCreate = (route) => {
    const { eventDetails } = this.props;
    if (
      eventDetails === null ||
      eventDetails === undefined ||
      eventDetails === "" ||
      eventDetails === "undefined" ||
      !(eventDetails.eventId && eventDetails.eventId.length > 0)
    ) {
      localStorage.setItem("isItCreateOrEditEvent", undefined);
    }
    this.setState({
      activeTab: route,
    });
  };

  handleDrawerToggle = () => {
    this.props.toggle();
  };

  handleClick = (ele) => {
    let open = this.state.open;
    this.setState({
      open: !open,
    });
  };

  render() {
    const { classes, theme } = this.props;
    let { activeTab } = this.state;

    activeTab =
      this.props.route.location.pathname.split("/")[
        this.props.route.location.pathname.split("/").length - 1
      ];
    const count = this.props.messageData && this.props.messageData.unreadCount;
    return (
      <div>
        <Hidden lgUp>
          <Drawer
            open={this.props.mobileOpen}
            onClose={() => this.props.toggle()}
            anchor={theme.direction === "rtl" ? "right" : "left"}
            variant="temporary"
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={classes.toolbar} />
            {this.sideBarLists(true)}
          </Drawer>
        </Hidden>
        <Hidden mdDown implementation="css">
          <Drawer
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={classes.toolbar} />
            {this.sideBarLists(false)}
          </Drawer>
        </Hidden>
      </div>
    );
  }
}

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    user: state.loginResponse.results,
    messageData: state.messageReducer,
    stripeId: state.eventUserEvents.payouts,
    route: state.router,
    eventDetails: state.eventUserEvents,
    sidebarFunctions: state.eventUserEvents.sidebarFunctions,
  };
};

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      currentEventStatus,
      clearEventDetails,
      addSideBarFunctions,
      setEventStatus,
      removeEventByEventUser,
      setStripeUserId,
      clearEventUserCheckedInList , clearEventExperienceActivity
    //   setEventExperienceData,
    },
    dispatch
  );
};
export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, matchDispatchToProps,)(Sidebar)
);
