export const reusableClasses = {
  // Main Page Layout
  mainWrapper: {
    padding: "40px",
    overflow: "auto",
    marginTop: "0px -12px 0px -12px",
    background: "white",
    height: "90vh",

    display: "flex",
    flexDirection: "column",

    "@media (max-width: 445px)": {
      padding: "10px",
    },
  },

  contentWrapper: {
    flex: 1,
    height: "auto",

    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },

  // Save and Continue Button
  saveAndContinueButtonWrapper: {
    textAlign: "end",
    margin: "20px 0",
  },

  saveAndContinueButton: {
    backgroundColor: "#a338a4",
    color: "white",
    borderRadius: "20px",
    fontFamily:
      "HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif",
    fontSize: "15px",
    padding: "10px 25px 10px 25px",
    textTransform: "none",

    "@media (max-width: 768px)": {
      margin: 0,
      width: "100%",
      borderRadius: 0,
      position: "absolute",
      bottom: 0,
      right: 0,
    },

    "@media (max-width: 445px)": {
      padding: "10px",
      bottom: "0%",
    },

    "&:hover": {
      color: "black !important",
    },
  },

  continueButton: {
    backgroundColor: "#a338a4",
    color: "white",
    borderRadius: "20px",
    fontFamily:
        "HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif",
    fontSize: "15px",
    padding: "10px 25px 10px 25px",
    textTransform: "none",
    width: "200px",

    "@media (max-width: 768px)": {
      margin: 0,
      width: "100%",
      borderRadius: 0,
      position: "absolute",
      bottom: 0,
      right: 0,
    },

    "@media (max-width: 445px)": {
      padding: "10px",
      bottom: "0%",
    },

    "&:hover": {
      color: "black !important",
    },
  },

  // Add Button With Plus Sign
  addButtonWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  addButton: {
    backgroundColor: "rgb(182, 14, 181)",
    color: "white",
    fontFamily:
      "HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif",
    fontSize: "14px",
    width: "auto",
    padding: "5px 25px 5px 25px",
    textTransform: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "&:hover": {
      color: "black !important",
      "& > span > svg": {
        color: "black !important",
        fill: "black !important",

        stroke: "black  !important",
        strokeWidth: "2px !important",
      },
    },

    "@media (max-width: 445px)": {
      borderRadius: "50%",
      width: "50px",
      height: "50px",
      position: "absolute",
      bottom: "60px",
      right: "10px",
      minWidth: "50px",
    },
  },

  addButtonText: {
    "@media (max-width: 445px)": {
      display: "none",
    },
  },

  addButtonIcon: {
    stroke: "white",
    strokeWidth: "2px",
    marginLeft: "6px",

    "@media (max-width: 445px)": {
      strokeWidth: "0",
      fontSize: "50px",
      marginLeft: "0",
      position: "absolute",
      // top: "-24.5px",
    },
  },
  // Add/Update Modals

  addOrUpdateModalMainWrapperOuter: {
    minHeight: "100vh",
    backgroundColor: "white",
    width: "600px",
    position: "absolute",
    right: 0,
    padding: "20px 0px 0px 20px",

    "@media (max-width: 540px)": {
      width: "100%",
    },
  },

  addOrUpdateModalMainWrapperInner: {
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "calc(100vh - 30px)",
    paddingRight: "20px",
  },
  addOrUpdateModalHeader: {
    display: "flex",
    alignItems: "center",
    paddingBottom: "10px",
    borderBottom: "1px solid #F5F5F5",
    marginBottom: "30px",
  },
  addOrUpdateModalHeaderText: {
    margin: "0px",
    color: "#A338A4",
    fontSize: "2.5rem",
    flex: 1,
    textAlign: "center",
  },
  addOrUpdateModalHeaderButtonWrapper: {
    textAlign: "center",
    borderTop: "1px solid #ced4da",
    paddingTop: "20px",
  },

  addOrUpdateModalHeaderButtonCancel: {
    fontSize: "1.5rem",
    marginRight: "20px",
    border: "1px solid #968e8e",
    width: "120px",
    height: "50px",
    textTransform: "none",
  },

  addOrUpdateModalHeaderButtonSave: {
    backgroundColor: "rgb(182, 14, 181)",
    fontSize: "1.5rem !important",
    color: "white",
    borderRadius: "9px",
    textTransform: "none",

    width: "120px",
    height: "50px",
  },
  // Content Cards
  cardWrapper: {
    flex: 0.9,

    "@media (max-width: 450px)": {
      flex: 1,
      paddingBottom: "52px",
    },
  },
  verticalLineWrapper: {
    display: "flex",
    "@media (max-width: 450px)": {
      display: "none",
    },
  },
  card: {
    width: "auto",
    margin: "15px 30px 25px 30px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    boxShadow: "none",
    borderLeft: "10px solid green",
    padding: "5px",
    border: "1px solid rgb(0, 185, 200)",

    "@media (max-width: 450px)": {
      margin: "5%",
    },
  },
};
