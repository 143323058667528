import React, { Component } from "react";
import {
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  withStyles,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import TicketLayout, { ContentLayout } from "./layout";
import EventHeader from "../EventDetails/eventHeader";
import { styleClasses } from "./ticketsStyleClasses";
import {
  addAdditionalTax,
  updateAdditionalTax,
  deleteAdditionalTax,
} from "../../../../Actions/eventActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { checkIfEventExists } from "../../../../Helper/Validations";
import { eventApis, Text, Tickets } from "../../../../Constants";
import { axiosE } from "../../../../Service/service";
import Loader from "../../../../Helper/loader/loader";
const styles = {
  eventCreateTicketContentWrapper: styleClasses.eventCreateTicketContentWrapper,
  saveAndContinueButton: styleClasses.saveAndContinueButton,
  saveAndContinueButtonWrapper: styleClasses.saveAndContinueButtonWrapper,
  mainContentWrapper: styleClasses.mainContentWrapper,
  generateHeight: styleClasses.generateHeight,
  inputFieldWrapper: styleClasses.inputFieldWrapper,
  OutlinedInputStyle: {
    width: "235px",
    "@media (max-width: 600px)": {
      width: "100% !important",
    },
  },
};

class salesTax extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chargeTax: 0,
      taxName: "",
      taxRate: "",
      update: false,
      loader: false,
    };
  }

  progressLoader = () => {
    const { loader } = this.state;

    return loader && <Loader />;
  };

  componentDidMount() {
    checkIfEventExists(this.props.history, this.props.eventId);
    const { eventId } = this.props;

    this.setState({ loader: true });
    axiosE
      .get(eventApis.getTaxByEventId + eventId)
      .then((res) => {
        const additionalTaxes = res.data.results.ListDiscount;
        if (additionalTaxes && additionalTaxes.length > 0) {
          this.setState({
            chargeTax: parseInt(additionalTaxes[0].TaxType),
            taxName: additionalTaxes[0].TaxName,
            taxRate: additionalTaxes[0].TaxAmount,
            AdditionalTaxId: additionalTaxes[0].AdditionalTaxId,
            update: true,
          });
        }
      })
      .finally(() => {
        this.setState({ loader: false });
      });
  }

  changeRoute = (route) => {
    let newRoute = this.props.history.location.pathname.split("/");
    newRoute.pop();
    newRoute.pop();
    newRoute.push("Publish");

    this.props.history.push(newRoute.join("/"));

    this.props.sidebarFunctions.setTicket(false);
    this.props.sidebarFunctions.setDetail(false);
  };

  sendAdditionalTax = async () => {
    const { chargeTax, taxName, taxRate, update } = this.state;
    const { eventId, addAdditionalTax, deleteAdditionalTax } = this.props;
    const userId = localStorage.getItem("UserId");
    let AdditionalTaxId;
    if (update) {
      AdditionalTaxId = this.state.AdditionalTaxId;
    }
    const data = {
      EventId: eventId,
      UserId: userId,
      TaxName: taxName,
      TaxAmount: taxRate,
      TaxType: chargeTax,
    };
    chargeTax === 1 || chargeTax === "1"
      ? update
        ? this.updateRemoteAdditionalTax().then(() => this.changeRoute())
        : addAdditionalTax(data).then(() => this.changeRoute())
      : update
      ? deleteAdditionalTax({ AdditionalTaxId }).then(() => this.changeRoute())
      : this.changeRoute();
  };

  updateRemoteAdditionalTax = async () => {
    const { chargeTax, taxName, taxRate, AdditionalTaxId } = this.state;
    const { eventId, updateAdditionalTax } = this.props;
    const userId = localStorage.getItem("UserId");
    const data = {
      EventId: eventId,
      UserId: userId,
      TaxName: taxName,
      TaxAmount: taxRate,
      TaxType: chargeTax,
      AdditionalTaxId: AdditionalTaxId,
    };
    const res = await updateAdditionalTax(data);
    return res;
  };

  saveAndContinue = () => {
    const { classes } = this.props;
    const { chargeTax, taxName, taxRate } = this.state;

    const mandatoryFields =
      chargeTax === 1 && (taxName.trim() === "" || taxRate === "");

    return (
      <div className={classes.saveAndContinueButtonWrapper}>
        <Button
          className={classes.saveAndContinueButton}
          variant={"contained"}
          onClick={() => this.sendAdditionalTax()}
          disabled={mandatoryFields}
        >
          {Text.SaveAndContinue}
        </Button>
      </div>
    );
  };
  mandatoryFieldTag = () => {
    return <span style={{ color: "red" }}> *</span>;
  };

  handleInputChange = (event, field) => {
    if (field === "taxRate") {
      this.setState({
        [field]:
          event.target.value == 0
            ? ""
            : Math.min(Math.max(Number(event.target.value), 0), 100),
      });
    } else {
      this.setState({
        [field]: event.target.value,
      });
    }
  };

  render() {
    const { classes } = this.props;
    const { chargeTax, taxName, taxRate } = this.state;
    const labels = Tickets.taxOption;
    return (
      <TicketLayout>
        <EventHeader title={labels.headline}>
          {/* HERE COMES THE HEADER BUTTONS IF ANY */}
        </EventHeader>
        <ContentLayout>
          <div className={classes.mainContentWrapper}>
            <div>
              <p style={{ fontSize: "16px" }}>{labels.description}</p>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span style={{ fontSize: "16px" }}>{labels.dropdown}</span>
              <Select
                value={chargeTax}
                variant="outlined"
                style={{ width: "100px", fontSize: "16px", marginTop: "10px" }}
                onChange={(e) => this.handleInputChange(e, "chargeTax")}
              >
                <MenuItem value={0} style={{ fontSize: "16px" }}>
                  No
                </MenuItem>
                <MenuItem value={1} style={{ fontSize: "16px" }}>
                  Yes
                </MenuItem>
              </Select>
            </div>
            {chargeTax === 1 && (
              <div className={classes.inputFieldWrapper}>
                <div style={{ marginBottom: 20 }}>
                  <FormControl
                    style={{ flex: ".5" }}
                    fullWidth
                    className={classes.margin}
                    variant="outlined"
                  >
                    <InputLabel
                      htmlFor="outlined-adornment-amount"
                      style={{ fontSize: "14px" }}
                    >
                      {labels.taxName}
                      {this.mandatoryFieldTag()}
                    </InputLabel>
                    <OutlinedInput
                      label={labels.taxName}
                      id="outlined-adornment-amount"
                      value={taxName}
                      className={classes.OutlinedInputStyle}
                      onChange={(e) => this.handleInputChange(e, "taxName")}
                      placeholder={labels.taxName}
                      labelWidth={60}
                      style={{ fontSize: "16px" }}
                      onInput={(e) => {
                        e.target.value =
                          e.target.value.length > 30
                            ? e.target.value.slice(0, 30)
                            : e.target.value;
                      }}
                    />
                    <span
                      style={{
                        fontSize: 10,
                        color: "#808080",
                        position: "absolute",
                        bottom: 4,
                        right: 10,
                      }}
                    >{`${taxName.length}/30`}</span>
                  </FormControl>
                </div>
                <FormControl
                  style={{ flex: ".5" }}
                  fullWidth
                  className={classes.margin}
                  variant="outlined"
                >
                  <InputLabel
                    htmlFor="outlined-adornment-amount"
                    style={{ fontSize: "14px" }}
                  >
                    {labels.taxRate} {this.mandatoryFieldTag()}
                  </InputLabel>
                  <OutlinedInput
                    label={labels.taxRate}
                    id="outlined-adornment-amount"
                    value={taxRate}
                    type="number"
                    onChange={(e) => this.handleInputChange(e, "taxRate")}
                    className={classes.OutlinedInputStyle}
                    endAdornment={
                      <InputAdornment
                        position="end"
                        style={{ transform: "scale(1.7)" }}
                      >
                        %
                      </InputAdornment>
                    }
                    onKeyDown={(e) =>
                      ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                    }
                    labelWidth={60}
                    style={{ fontSize: "16px" }}
                  />
                </FormControl>
              </div>
            )}
          </div>
          {this.saveAndContinue()}
          {this.progressLoader()}
        </ContentLayout>
      </TicketLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const { eventUserEvents } = state;
  return {
    eventId: eventUserEvents.eventId,
    additionalTaxes: eventUserEvents.additionalTaxes,
    sidebarFunctions: eventUserEvents.sidebarFunctions,
  };
};
const matchDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { addAdditionalTax, updateAdditionalTax, deleteAdditionalTax },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(withStyles(styles)(salesTax));
