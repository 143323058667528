import React, { useState, useEffect, useRef } from "react";
import { Link as ReactLink } from "react-scroll";
import { Link } from "react-router-dom";
import "./EventNavbar.css";

import logo from "images/ubme_logo.png";
import burgerMenu from "images/burger_menu.png";
import { connect } from "react-redux";
import { logoutAction, SetHistory, ValidateUser } from "Actions";
// import "../modal/Modal.css";
import Login from "Components/UBMeEvents/auth/Login/Login";
// import Modal from "../modal/Modal";
import SignUp from "Components/UBMeEvents/auth/Signup/SignUp";
import { BsThreeDotsVertical } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import * as constants from "Constants";

import { history } from "store";
import { routeName } from "Service/env";
import { LoginPopup } from "Actions";
import { GrDown } from "react-icons/gr";
import { A } from "../../Components_UBMeEvents/Navbar/Alphabets/UserIcons";
import UseOutsideClick from "../../Components_UBMeEvents/OutSideClick/UseOutsideClick";
import { UserIconsPic } from "../../Components_UBMeEvents/Navbar/Alphabets/UserIconsPic";
import styled from "styled-components";
import moment from "moment";
import ForgotPassword from "Components/ForgotPassword/forgotPassword";
import Modal from "Components/UBMeEvents/Components_UBMeEvents/modal/Modal";

const Overlay = styled.div`
  /* visibility: ${(props) => (props.open ? "visible" : "hidden")}; */
  position: absolute;

  height: ${(props) =>
    props.open ? (props.isLoggedIn ? "475px" : "475px") : "0px"};
  right: -1px;

  width: 300px;
  top: 65px;
  background: #ffffff;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.3s ease-in-out;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  @media (min-width: 1280px) {
    display: none;
  }
`;

const OverlayMenu = styled.ul`
  padding: 0;
  margin-top: 10px;
  opacity: ${(props) => (props.open ? 1 : 0)};
  visibility: ${(props) => (props.open ? "visible" : "hidden")};
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.3s ease-in-out;

  li {
    margin: 0;
    opacity: ${(props) => (props.open ? 1 : 0)};
    visibility: ${(props) => (props.open ? "visible" : "hidden")};
    font-size: 25px;

    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }
`;

function Navbar({
  favoriteData,
  results,
  dispatch,
  isLoggedIn,
  loginPopUp,
  SetHistory,
  unreadCount,
  agendaData,
  currentEventDetails,
  setOpenCheckOut,
  soldOut,
  eventIsDrfat,
  openMessage,
}) {
  const ref = useRef();
  const [click, setClick] = useState(false);
  const [toggle, toggleNav] = useState(false);
  const [button, setButton] = useState(true);
  const [isSignUp, setIsSignUp] = useState(false);
  const [userPic, setUserPic] = useState(A);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => toggleNav(false);
  const [active, setActive] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [userHistory, setHistory] = useState(null);
  const [isTicketSaleEnded, setIsTicketSaleEnded] = useState(false);
  const [ticketSaleSarted, setTicketSaleStarted] = useState(false);
  const [ticketUnavailable, setTicketUnavailable] = useState(false);
  const [ticketType, setTicketType] = useState(0);
  const closeModal = () => {
    const isLogedIn = localStorage.getItem("isLogedIn");
    if (isLogedIn === 'false') {
      localStorage.removeItem("pendingEventsButtonClick");
    }
    dispatch(LoginPopup(false));
    setIsSignUp(false);

    setForgotPassword(false);
  };
  const openModal = (path) => {
    dispatch(LoginPopup(true));
    setHistory(path);
  };

  UseOutsideClick(
    () => {
      if (toggle) closeMobileMenu();
    },
    ".Menu-list-modal",
    ref
  );

  useEffect(() => {
    const showButton = () => {
      if (window.innerWidth <= 960) {
        setButton(false);
      } else {
        setButton(true);
      }
    };
    window.addEventListener("resize", showButton);
    return () => {
      window.removeEventListener("resize", showButton);
    };
  }, []);

  const Logout = () => {
    dispatch(logoutAction());
    setHistory(`${routeName}`);
    history.push(`${routeName}`);
    window.location.reload();
  };

  const renderSignupLogin = () =>
    isSignUp ? (
      <SignUp closeModal={closeModal} setIsSignUp={setIsSignUp} />
    ) : forgotPassword ? (
      <ForgotPassword
        closeModal={closeModal}
        setForgotPassword={setForgotPassword}
      />
    ) : (
      <Login
        userHistory={`messages`}
        callBackfn={() => openMessage(true)}
        setForgotPassword={setForgotPassword}
        closeModal={closeModal}
        setIsSignUp={setIsSignUp}
      />
    );

  useEffect(() => {
    if (results.ProfileDetail?.FirstName) {
      setUserPic(
        UserIconsPic(
          results.ProfileDetail &&
            results.ProfileDetail?.FirstName[0]?.toUpperCase()
        )
      );
    }
  }, [results]);

  useEffect(() => {
    setIsTicketSaleEnded(false);
    setTicketSaleStarted(false);
    if (currentEventDetails !== null) {
      if (
        moment(
          currentEventDetails?.StartDateTime,
          "MM/DD/YYYY, hh:mm:ss A"
        ).isBefore(
          moment(
            moment()
              .tz(currentEventDetails?.TimeZone)
              .format("MM/DD/YYYY hh:mm:ss A"),
            "MM/DD/YYYY hh:mm:ss A"
          )
        )
      ) {
        setIsTicketSaleEnded(true);
      }

      if (
        moment(
          currentEventDetails?.TicketData[0]?.ActiveDate,
          "MM/DD/YYYY hh:mm:ss A"
        ).isAfter(
          moment(
            moment()
              .tz(currentEventDetails?.TimeZone)
              .format("MM/DD/YYYY hh:mm:ss A"),
            "MM/DD/YYYY hh:mm:ss A"
          )
        )
      ) {
        setTicketSaleStarted(true);
      }
    }
  }, [currentEventDetails]);

  useEffect(() => {
    setTicketUnavailable(false);

    if (currentEventDetails) {
      if (currentEventDetails?.Status !== "Published") {
        if (
          currentEventDetails?.UserId === Number(localStorage.getItem("UserId"))
        ) {
          if (!currentEventDetails?.TicketData.length > 0) {
            setTicketUnavailable(true);
            setIsTicketSaleEnded(false);
          }
        }
      }
    }

    if (currentEventDetails?.TicketData.length > 0) {
      setTicketType(0);
      const ticketData = currentEventDetails?.TicketData.map((ticket) => {
        if (Number(ticket.TicketType) === 1) {
          setTicketType(1);
        }
      });

      setIsTicketSaleEnded(
        currentEventDetails?.TicketData.every((t) => {
          return moment(t?.InActiveDate, "MM/DD/YYYY hh:mm:ss A").isBefore(
            moment(
              moment()
                .tz(currentEventDetails?.TimeZone)
                .format("MM/DD/YYYY hh:mm:ss A"),
              "MM/DD/YYYY hh:mm:ss A"
            )
          );
        })
      );
    }
  }, [currentEventDetails]);
  return (
    <>
      {loginPopUp && (
        <Modal signup={isSignUp ? true : false} closeModal={closeModal}>
          {renderSignupLogin()}
        </Modal>
      )}
      <nav className="navbar" style={{ position: "sticky" }}>
        <div className="navbar-container-event">
          <Link to="/" className="navbar-logo-2" onClick={closeMobileMenu}>
            <img
              src={logo}
              alt="logo_ubme_events"
              style={{ width: 150, marginRight: 8 }}
            />
          </Link>

          <div className="menu-icon">
            <div onClick={() => toggleNav(!toggle)}>
              {toggle ? (
                <AiOutlineClose size={25} style={{ marginRight: 12 }} />
              ) : (
                <img
                  style={{ width: 30, height: 30, marginRight: 10 }}
                  src={burgerMenu}
                  alt="1"
                />
              )}
            </div>
          </div>

          <ul
            style={{ paddingTop: 4, marginLeft: "auto", marginBottom: 0 , height:"auto"}}
            className="nav-menu"
          >
            <li className="nav-item">
              <ReactLink
                className="nav-links"
                activeClass="active"
                to="home"
                spy={true}
                smooth={true}
                style={{ fontSize: 16 }}
              >
                {constants.Text.Home}
              </ReactLink>
            </li>
            <li className="nav-item">
              <ReactLink
                style={{ fontSize: 16 }}
                to="about"
                className="nav-links"
                spy={true}
                smooth={true}
              >
                {constants.Text.About}
              </ReactLink>
            </li>
            {agendaData?.FaqData.length > 0 && (
              <li className="nav-item">
                <ReactLink
                  style={{ fontSize: 16 }}
                  to="faq"
                  className="nav-links"
                  spy={true}
                  smooth={true}
                >
                  {constants.Text.FAQ}
                </ReactLink>
              </li>
            )}

            {agendaData?.AgendaSessionData.length > 0 && (
              <li className="nav-item">
                <ReactLink
                  style={{ fontSize: 16 }}
                  to="agenda"
                  className="nav-links"
                  spy={true}
                  smooth={true}
                >
                  {constants.Text.Agenda}
                </ReactLink>
              </li>
            )}
            {agendaData?.VendorData.length > 0 && (
              <li className="nav-item">
                <ReactLink
                  style={{ fontSize: 16 }}
                  to="vendors"
                  className="nav-links"
                  spy={true}
                  smooth={true}
                >
                  {constants.Text.Vendors}
                </ReactLink>
              </li>
            )}
            <li className="nav-item">
              <ReactLink
                style={{ fontSize: 16 }}
                to="aboutapp"
                className="nav-links"
                spy={true}
                smooth={true}
              >
                {constants.Text.WhenYouArrive}
              </ReactLink>
            </li>
            <li className="nav-item">
              <ReactLink
                style={{ fontSize: 16 }}
                to="organizer"
                className="nav-links"
                spy={true}
                smooth={true}
              >
                {constants.Text.Organizer}
              </ReactLink>
            </li>
            <li
              style={{ position: "relative", backgroundColor: "transparent" }}
              className="nav-item"
            >
              <Link
                className="ticket_button"
                style={{
                  width: 360,
                  marginRight: 40,
                  textAlign: "center",
                  backgroundColor:
                    currentEventDetails?.Status !== "Published" ||
                    isTicketSaleEnded ||
                    ticketUnavailable ||
                    (ticketSaleSarted && !isTicketSaleEnded) ||
                    soldOut ||
                    (currentEventDetails?.TicketData?.length > 0 &&
                      !currentEventDetails?.TicketData.some(
                        (item) =>
                          Number(item.TotalAvailable) !== 0 &&
                          moment(
                            item?.InActiveDate,
                            "MM/DD/YYYY hh:mm:ss A"
                          ).isAfter(
                            moment(
                              moment()
                                .tz(currentEventDetails?.TimeZone)
                                .format("MM/DD/YYYY hh:mm:ss A"),
                              "MM/DD/YYYY hh:mm:ss A"
                            )
                          )
                      ) &&
                      !soldOut &&
                      !isTicketSaleEnded)
                      ? "#9c9c9c"
                      : (currentEventDetails?.EventColor !== undefined && currentEventDetails.EventColor !== "") ? currentEventDetails.EventColor :  "rgb(161 3 150)",
                  color: "#fff",
                }}
                onClick={() =>
                  currentEventDetails?.Status !== "Published" ||
                  isTicketSaleEnded ||
                  ticketUnavailable ||
                  (ticketSaleSarted && !isTicketSaleEnded) ||
                  soldOut ||
                  (currentEventDetails?.TicketData?.length > 0 &&
                    !currentEventDetails?.TicketData.some(
                      (item) =>
                        Number(item.TotalAvailable) !== 0 &&
                        moment(
                          item?.InActiveDate,
                          "MM/DD/YYYY hh:mm:ss A"
                        ).isAfter(
                          moment(
                            moment()
                              .tz(currentEventDetails?.TimeZone)
                              .format("MM/DD/YYYY hh:mm:ss A"),
                            "MM/DD/YYYY hh:mm:ss A"
                          )
                        )
                    ) &&
                    !soldOut &&
                    !isTicketSaleEnded)
                    ? null
                    : setOpenCheckOut(true)
                }
                to="#"
              >
                {ticketUnavailable
                  ? "Unavailable"
                  : ticketType === 1
                  ? "Purchase Tickets"
                  : "Register"}
              </Link>
              {isTicketSaleEnded && soldOut && (
                <p
                  style={{
                    position: "absolute",
                    textAlign: "center",
                    fontWeight: 600,
                    color: "#f03063",
                    bottom: -27,
                    left: 0,
                    right: 40,
                  }}
                >
                  {constants.Text.ticketSaleEnd}
                </p>
              )}
              {eventIsDrfat && (
                <p
                  style={{
                    position: "absolute",
                    textAlign: "center",
                    fontWeight: 600,
                    color: "#f03063",
                    bottom: -27,
                    left: 0,
                    right: 40,
                  }}
                >
                  {constants.Text.draftEvent}
                </p>
              )}
              {currentEventDetails?.TicketData?.length > 0 &&
                !currentEventDetails?.TicketData.some(
                  (item) =>
                    Number(item.TotalAvailable) !== 0 &&
                    moment(item?.InActiveDate, "MM/DD/YYYY hh:mm:ss A").isAfter(
                      moment(
                        moment()
                          .tz(currentEventDetails?.TimeZone)
                          .format("MM/DD/YYYY hh:mm:ss A"),
                        "MM/DD/YYYY hh:mm:ss A"
                      )
                    )
                ) &&
                !soldOut &&
                !isTicketSaleEnded && (
                  <p
                    style={{
                      position: "absolute",
                      textAlign: "center",
                      fontWeight: 600,
                      color: "#f03063",
                      bottom: -27,
                      left: 0,
                      right: 40,
                    }}
                  >
                    {constants.Text.ticketSaleEnd}
                  </p>
                )}
              {isTicketSaleEnded && !soldOut && !eventIsDrfat && (
                <p
                  style={{
                    position: "absolute",
                    textAlign: "center",
                    fontWeight: 600,
                    color: "#f03063",
                    bottom: -27,
                    left: 0,
                    right: 40,
                  }}
                >
                  {constants.Text.ticketSaleEnd}
                </p>
              )}

              {!isTicketSaleEnded && soldOut && (
                <p
                  style={{
                    position: "absolute",
                    textAlign: "center",
                    fontWeight: 600,
                    color: "#f03063",
                    bottom: -27,
                    left: 0,
                    right: 40,
                  }}
                >
                  {constants.Text.SoldOut}
                </p>
              )}

              {ticketSaleSarted && !isTicketSaleEnded && !eventIsDrfat && (
                <p
                  style={{
                    position: "absolute",
                    textAlign: "center",
                    fontWeight: 600,
                    color: "#f03063",
                    bottom: -27,
                    left: 0,
                    right: 40,
                  }}
                >
                  {`On Sale ${moment(
                    currentEventDetails?.TicketData[0]?.ActiveDate
                  ).format("MMM DD")} at
                   ${moment(
                     currentEventDetails?.TicketData[0]?.ActiveDate
                   ).format("hh:mm A")}`}
                </p>
              )}
            </li>
          </ul>

          {/* mobile menu */}

          <Overlay isLoggedIn={isLoggedIn} open={toggle}>
            <OverlayMenu ref={ref} open={toggle} className="Menu-list-modal">
              <li
                className="nav-item-mobile list"
                style={{ display: "flex", listStyleType: "none" }}
              >
                <ReactLink
                  activeClass="active"
                  to="home"
                  spy={true}
                  smooth={true}
                  className="nav-links"
                  onClick={closeMobileMenu}
                >
                  {constants.Text.Home}
                </ReactLink>
              </li>
              <li
                className="nav-item-mobile list"
                style={{ display: "flex", listStyleType: "none" }}
              >
                <ReactLink
                  to="about"
                  className="nav-links"
                  spy={true}
                  smooth={true}
                  onClick={closeMobileMenu}
                >
                  {constants.Text.About}
                </ReactLink>
              </li>

              {agendaData?.FaqData.length > 0 && (
                <li
                  className="nav-item-mobile list"
                  style={{ display: "flex", listStyleType: "none" }}
                >
                  <ReactLink
                    to="faq"
                    className="nav-links"
                    spy={true}
                    smooth={true}
                    onClick={closeMobileMenu}
                  >
                    {constants.Text.FAQ}
                  </ReactLink>
                </li>
              )}

              {agendaData?.AgendaSessionData.length > 0 && (
                <li
                  className="nav-item-mobile list"
                  style={{ display: "flex", listStyleType: "none" }}
                >
                  <ReactLink
                    to="agenda"
                    className="nav-links"
                    spy={true}
                    smooth={true}
                    onClick={closeMobileMenu}
                  >
                    {constants.Text.Agenda}
                  </ReactLink>
                </li>
              )}
              {agendaData?.VendorData.length > 0 && (
                <li
                  className="nav-item-mobile list"
                  style={{ display: "flex", listStyleType: "none" }}
                >
                  <ReactLink
                    to="vendors"
                    className="nav-links"
                    spy={true}
                    smooth={true}
                    onClick={closeMobileMenu}
                  >
                    {constants.Text.Vendors}
                  </ReactLink>
                </li>
              )}
              <li
                className="nav-item-mobile list"
                style={{ display: "flex", listStyleType: "none" }}
              >
                <ReactLink
                  to="aboutapp"
                  className="nav-links"
                  spy={true}
                  smooth={true}
                  onClick={closeMobileMenu}
                >
                  {constants.Text.WhenYouArrive}
                </ReactLink>
              </li>
              <li
                className="nav-item-mobile list"
                style={{ display: "flex", listStyleType: "none" }}
              >
                <ReactLink
                  to="organizer"
                  className="nav-links"
                  spy={true}
                  smooth={true}
                  onClick={closeMobileMenu}
                >
                  {constants.Text.Organizer}
                </ReactLink>
              </li>
            </OverlayMenu>
          </Overlay>
        </div>
      </nav>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    currentEventDetails: state.eventReducer.currentEventDetails,
    favoriteData: state.favoriteReducer.favoriteData,
    cardData: state.favoriteReducer.cardData,
    results: state.loginResponse.results,
    isLoggedIn: state.loginResponse.isLoggedIn,
    loginPopUp: state.loginResponse.loginPopUp,
    pushhistory: state.loginResponse.history,
    unreadCount: state.messageReducer.unreadCount,
  };
};
const matchDispatchToProps = (dispatch) => {
  return {
    logoutAction: () => dispatch(logoutAction),
    LoginPopup: () => dispatch(LoginPopup),
    SetHistory: () => dispatch(SetHistory),
    dispatch,
  };
};
export default connect(mapStateToProps, matchDispatchToProps)(Navbar);
