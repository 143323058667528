import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import "./Navbar.css";

import logo from "images/ubme_logo.png";
import burgerMenu from "images/burger_menu.png";
import { connect } from "react-redux";
import { logoutAction, SetHistory, ValidateUser } from "Actions";
import "../modal/Modal.css";
import Login from "Components/UBMeEvents/auth/Login/Login";
import Modal from "../modal/Modal";
import SignUp from "Components/UBMeEvents/auth/Signup/SignUp";
import { BsThreeDotsVertical } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import * as constants from "Constants";
import ForgotPassword from "Components/UBMeEvents/auth/Forgotpasswoed/ForgotPassword";
import { history } from "store";
import { homeRoute, routeName } from "Service/env";
import { LoginPopup } from "Actions";
import { GrDown } from "react-icons/gr";
import { A } from "./Alphabets/UserIcons";
import UseOutsideClick from "../OutSideClick/UseOutsideClick";
import { UserIconsPic } from "./Alphabets/UserIconsPic";
import styled from "styled-components";
import { axiosE } from "Service/service";
import {
  clearEventDetails,
  removeEventByEventUser,
} from "Actions/eventActions";

const Overlay = styled.div`
  /* visibility: ${(props) => (props.open ? "visible" : "hidden")}; */
  position: absolute;

  height: ${(props) =>
    props.open ? (props.isLoggedIn ? "660px" : "280px") : "0px"};
  right: -1px;

  width: 280px;
  top: 65px;
  background: #ffffff;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.3s ease-in-out;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  @media (min-width: 1280px) {
    // display: none;
  }
`;

const OverlayMenu = styled.ul`
  padding: 0;
  margin-top: 10px;
  opacity: ${(props) => (props.open ? 1 : 0)};
  visibility: ${(props) => (props.open ? "visible" : "hidden")};
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.3s ease-in-out;

  li {
    margin: 0;
    opacity: ${(props) => (props.open ? 1 : 0)};
    visibility: ${(props) => (props.open ? "visible" : "hidden")};
    font-size: 25px;

    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }
`;

function Navbar({
  position = "sticky",
  favoriteData,
  results,
  dispatch,
  isLoggedIn,
  loginPopUp,
  SetHistory,
  unreadCount,
  clearEventDetails,
  isToggle = false,
  loginHistory,
  toggleSideNav = () => {},
}) {
  const ref = useRef();
  const [click, setClick] = useState(false);
  const [toggle, toggleNav] = useState(false);
  const [button, setButton] = useState(true);
  const [isSignUp, setIsSignUp] = useState(false);
  const [userPic, setUserPic] = useState(A);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => toggleNav(false);
  const [active, setActive] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [userHistory, setHistory] = useState(null);
  const [ordersCount, setOrdersCount] = useState(0);
  const closeModal = () => {
    setIsSignUp(false);
    dispatch(LoginPopup({ path: `/${homeRoute}`, value: false }));

    setForgotPassword(false);
  };
  const openModal = (path) => {
    dispatch(LoginPopup({ path: path, value: true }));
    setHistory(path);
  };

  UseOutsideClick(
    () => {
      if (toggle) closeMobileMenu();
    },
    ".Menu-list-modal",
    ref
  );

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    if (results.ProfileDetail?.UserId) {
      setIsSignUp(false);
      closeModal();
      showButton();
      axiosE
        .get(
          `${constants.API.GetTotalTicketsPurchasedByUserId}/${results.ProfileDetail?.UserId}`
        )
        .then(({ data }) => {
          // return data.results;
          setOrdersCount(JSON.parse(data.results.Data)?.TotalTickets);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [results.ProfileDetail?.UserId]);

  const Logout = () => {
    dispatch(logoutAction());
    setHistory(`/${homeRoute}`);
    history.push(`/${homeRoute}`);
    localStorage.setItem("isLogedIn", "false");
    window.location.reload();
  };

  window.addEventListener("resize", showButton);
  const rebderSignupLogin = () =>
    isSignUp ? (
      <SignUp
        userHistory={loginHistory}
        closeModal={closeModal}
        setIsSignUp={setIsSignUp}
      />
    ) : forgotPassword ? (
      <ForgotPassword
        closeModal={closeModal}
        setForgotPassword={setForgotPassword}
      />
    ) : (
      <Login
        userHistory={loginHistory}
        setForgotPassword={setForgotPassword}
        closeModal={closeModal}
        setIsSignUp={setIsSignUp}
      />
    );

  useEffect(() => {
    if (results.ProfileDetail?.FirstName) {
      setUserPic(
        UserIconsPic(
          results.ProfileDetail &&
            results.ProfileDetail?.FirstName[0]?.toUpperCase()
        )
      );
    }
  }, [results]);

  const cleareEventsAndCreateNewone = () => {
    closeMobileMenu();
    dispatch(clearEventDetails());
    dispatch(removeEventByEventUser());

    localStorage.setItem("isItCreateOrEditEvent", "Edit");

    localStorage.setItem("buyerFee", null);
    localStorage.removeItem("isCloneEvent");

    setTimeout(() => {
      if (isLoggedIn) {
        window.location.href = `${routeName}/Dashboard/About`;
      } else {
        history.push(`/${homeRoute}`);
      }
    }, 200);
  };

  return (
    <>
      {loginPopUp && (
        <Modal signup={isSignUp ? true : false} closeModal={closeModal}>
          {rebderSignupLogin()}
        </Modal>
      )}
      <nav className="navbar" style={{ position: position }}>
        <div className="navbar-container">
          {isToggle ? (
            <div onClick={() => toggleSideNav()}>
              <img
                className="portal_icon"
                style={{ width: 30, height: 30, marginLeft: 15 }}
                src={burgerMenu}
                alt="1"
              />
            </div>
          ) : null}

          <Link
            to={`/${homeRoute}`}
            className="navbar-logo"
            onClick={() => {
              closeMobileMenu();
            }}
          >
            <img
              src={logo}
              alt="logo_ubme_events"
              style={{ width: 150, marginRight: 8 }}
            />
          </Link>

          <div className="menu-icon-2">
            <div onClick={() => toggleNav(!toggle)}>
              {toggle ? (
                <AiOutlineClose size={25} style={{ marginRight: 12 }} />
              ) : isLoggedIn ? (
                <a href="#!" class="notification">
                  <img
                    style={{
                      width: 35,
                      height: 35,
                      borderRadius: 50,
                      objectFit: "cover",
                    }}
                    src={
                      results.ProfileDetail?.ProfileImage !== null
                        ? results.ProfileDetail?.ProfileImage
                        : userPic
                    }
                    alt="1"
                  />
                  <span class="badge">{unreadCount}</span>
                </a>
              ) : (
                <img
                  style={{ width: 30, height: 30, marginRight: 10 }}
                  src={burgerMenu}
                  alt="1"
                />
              )}
            </div>
          </div>

          <ul
            style={{ paddingTop: 12, marginLeft: "auto", height:"auto" }}
            className="nav-menu"
          >
            <li className="nav-item">
              <Link
                style={{ fontSize: 16 }}
                to={`${routeName}/overview`}
                className="nav-links"
              >
                {constants.NavbarText.ubmeEvents}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                style={{ fontSize: 16 }}
                to={`${routeName}/pricing`}
                className="nav-links"
              >
                {constants.NavbarText.Pricing}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                  style={{ fontSize: 16 }}
                  to={`${routeName}/FrequentlyAskedQuestions`}
                  className="nav-links"
              >
                {constants.NavbarText.FrequentlyAskedQuestions}
              </Link>
            </li>
            <li style={{ backgroundColor: "#fff" }} className="nav-item">
              <span
                style={{
                  fontSize: 16,
                  paddingLeft: 27,
                  paddingRight: 27,
                  paddingTop: 8,
                  paddingBottom: 7,
                  textDecoration: "none",
                  cursor: "pointer",
                }}
                onClick={() => {
                  localStorage.removeItem("CurrentEventId");
                  localStorage.removeItem("isRecurring");
                  !isLoggedIn
                    ? openModal("Dashboard/About")
                    : cleareEventsAndCreateNewone();
                }}
                className="nav-button"
              >
                {constants.NavbarText.createEventPlus}
              </span>
            </li>
          </ul>

          <ul style={{ paddingTop: 8 }} className="nav-menu">
            {isLoggedIn ? (
              <li
                style={{
                  width: 299,
                  paddingTop: 20,
                  paddingBottom: 14,
                  paddingRight: 12,
                  paddingLeft: 0,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
                className="nav-item"
              >
                <a
                  href="#"
                  style={{ marginLeft: 20, justifyContent: "end" }}
                  // to="/"
                  as="li"
                  className="nav-links"
                  onClick={() => setActive(!active)}
                >
                  <span style={{ marginRight: "auto" }}>Hey There!</span>

                  <a href="#" class="notification">
                    <img
                      style={{
                        width: 35,
                        height: 35,
                        borderRadius: 50,
                        objectFit: "cover",
                      }}
                      src={
                        results.ProfileDetail?.ProfileImage !== null
                          ? results.ProfileDetail?.ProfileImage
                          : userPic
                      }
                      alt="1"
                    />

                    <span class="badge">{unreadCount}</span>
                  </a>

                  <GrDown className="down_up" />
                </a>

                <div className="menu_options_nav">
                  <div className="menu_options_nav_ts">
                    <Link
                      to={`${routeName}`}
                      className="option_link-2"
                      style={{ padding: 0 }}
                    >
                      <div to={`${routeName}`} className="span">
                        {constants.NavbarText.browseEvents}
                      </div>
                    </Link>
                    <Link
                      to={`${routeName}/Dashboard/EventsOverview`}
                      className="option_link-2"
                      style={{ padding: 0 }}
                    >
                      <div className="span">
                        {constants.NavbarText.manageEvents}
                      </div>
                    </Link>
                    <Link
                        to={`${routeName}/${results.ProfileDetail?.UserId}~${results.ProfileDetail?.UserName}`}
                        className="option_link-2"
                        style={{ padding: 0 }}
                    >
                      <div className="span">
                        {constants.NavbarText.visitMyProfile}
                      </div>
                    </Link>
                    <Link
                      to={`${routeName}/tickets`}
                      className="option_link-2"
                      style={{ padding: 0 }}
                    >
                      <div className="span">
                        {constants.NavbarText.tickets}
                        {Number(ordersCount) ? (
                          <span className="message_badge">{ordersCount}</span>
                        ) : null}
                      </div>
                    </Link>
                    <Link
                      onClick={() => {
                        closeMobileMenu();
                        !isLoggedIn ? openModal("Favorite") : console.log();
                      }}
                      to={
                        isLoggedIn ? `${routeName}/Favorite` : `/${homeRoute}`
                      }
                      className="option_link-2"
                      style={{ padding: 0 }}
                    >
                      <div className="span">
                        {constants.NavbarText.favorite}
                      </div>
                    </Link>

                    <Link
                      onClick={() => {
                        closeMobileMenu();
                        !isLoggedIn ? openModal("messages") : console.log();
                      }}
                      to={
                        isLoggedIn ? `${routeName}/messages` : `/${homeRoute}`
                      }
                      className="option_link-2"
                      style={{ padding: 0 }}
                    >
                      <div className="span">
                        {constants.NavbarText.message}

                        <span className="message_badge">{unreadCount}</span>
                      </div>
                    </Link>

                    <Link
                      to={`${routeName}/account`}
                      className="option_link-2"
                      style={{ padding: 0 }}
                    >
                      <div className="span">{constants.NavbarText.account}</div>
                    </Link>

                    <Link
                      to="#"
                      className="option_link-2"
                      style={{ padding: 0 }}
                      onClick={() => {
                        closeMobileMenu();
                        Logout();
                      }}
                    >
                      <div className="span">{constants.NavbarText.logout}</div>
                    </Link>
                  </div>
                </div>
              </li>
            ) : (
              <li className="nav-item">
                <a
                  href="#!"
                  style={{ marginLeft: 15, marginRight: 15, fontSize: 16 }}
                  className="nav-links"
                  onClick={() => openModal()}
                >
                  {constants.NavbarText.signin}
                </a>
              </li>
            )}
          </ul>

          {/* mobile menu */}

          <Overlay isLoggedIn={isLoggedIn} open={toggle} className="navbar-md">
            {isLoggedIn ? (
              <OverlayMenu ref={ref} open={toggle} className="Menu-list-modal" style={{height:"auto"}}>
                <li
                  className="nav-item-mobile list"
                  style={{ display: "flex", listStyleType: "none" }}
                >
                  <span
                    onClick={() => {
                      localStorage.removeItem("CurrentEventId");
                      localStorage.removeItem("isRecurring");
                      !isLoggedIn
                        ? openModal("Dashboard/About")
                        : cleareEventsAndCreateNewone();
                    }}
                    style={{ fontSize: 16, fontWeight: 600 }}
                    className="nav-links"
                  >
                    {constants.NavbarText.createEvent}
                  </span>
                </li>
                <li
                  className="nav-item-mobile list"
                  style={{ display: "flex", listStyleType: "none" }}
                >
                  <Link
                    to={`${routeName}`}
                    className="nav-links"
                    onClick={closeMobileMenu}
                  >
                    {constants.NavbarText.browseEvents}
                  </Link>
                </li>
                <li
                  className="nav-item-mobile list"
                  style={{ display: "flex", listStyleType: "none" }}
                >
                  <Link
                    to={`${routeName}/Dashboard/EventsOverview`}
                    className="nav-links"
                    onClick={closeMobileMenu}
                  >
                    {constants.NavbarText.manageEvents}
                  </Link>
                </li>
                <li
                    className="nav-item-mobile list"
                    style={{ display: "flex", listStyleType: "none" }}
                >
                  <Link
                      to={`${routeName}/${results.ProfileDetail?.UserId}~${results.ProfileDetail?.UserName}`}
                      className="nav-links"
                      onClick={closeMobileMenu}
                  >
                    {constants.NavbarText.visitMyProfile}
                  </Link>
                </li>
                <li
                  className="nav-item-mobile list"
                  style={{ display: "flex", listStyleType: "none" }}
                >
                  <Link
                    to={`${routeName}/tickets`}
                    className="nav-links"
                    onClick={closeMobileMenu}
                  >
                    {constants.NavbarText.tickets}
                    {Number(ordersCount) ? (
                      <span className="message_badge">{ordersCount}</span>
                    ) : null}
                  </Link>
                </li>
                <li
                  className="nav-item-mobile list"
                  style={{ display: "flex", listStyleType: "none" }}
                >
                  <Link
                    onClick={() => {
                      closeMobileMenu();
                      !isLoggedIn ? openModal("Favorite") : console.log();
                    }}
                    to={isLoggedIn ? `${routeName}/Favorite` : `/${homeRoute}`}
                    className="nav-links"
                  >
                    {constants.NavbarText.favorite}
                  </Link>
                </li>
                <li
                  className="nav-item-mobile list"
                  style={{
                    display: "flex",
                    listStyleType: "none",
                    alignItems: "center",
                  }}
                >
                  <Link
                    onClick={() => {
                      closeMobileMenu();
                      !isLoggedIn ? openModal("messages") : console.log();
                    }}
                    to={isLoggedIn ? `${routeName}/messages` : `/${homeRoute}`}
                    className="nav-links"
                  >
                    {constants.NavbarText.message}
                    <span className="message_badge">{unreadCount}</span>
                  </Link>
                </li>
                <li
                  className="nav-item-mobile list"
                  style={{ display: "flex", listStyleType: "none" }}
                >
                  <Link
                    onClick={() => {
                      closeMobileMenu();
                      !isLoggedIn ? openModal("account") : console.log();
                    }}
                    to={isLoggedIn ? `${routeName}/account` : `/${homeRoute}`}
                    className="nav-links"
                  >
                    {constants.NavbarText.account}
                  </Link>
                </li>
                <li
                  className="nav-item-mobile list"
                  style={{ display: "flex", listStyleType: "none" }}
                >
                  <Link
                    to={`${routeName}/overview`}
                    className="nav-links"
                    onClick={closeMobileMenu}
                  >
                    {constants.NavbarText.ubmeEvents}
                  </Link>
                </li>
                <li
                  className="nav-item-mobile list"
                  style={{ display: "flex", listStyleType: "none" }}
                >
                  <a
                    href={`${routeName}/pricing`}
                    className="nav-links"
                    onClick={closeMobileMenu}
                  >
                    {constants.NavbarText.Pricing}
                  </a>
                </li>
                <li
                    className="nav-item-mobile list"
                    style={{ display: "flex", listStyleType: "none" }}
                >
                  <a
                      href={`${routeName}/FrequentlyAskedQuestions`}
                      className="nav-links"
                      onClick={closeMobileMenu}
                  >
                    {constants.NavbarText.FrequentlyAskedQuestions}
                  </a>
                </li>
                <li
                  className="nav-item-mobile list"
                  style={{ display: "flex", listStyleType: "none" }}
                >
                  <a
                    href="#demo-modal"
                    className="nav-links"
                    onClick={() => {
                      closeMobileMenu();
                      closeModal();
                      Logout();
                    }}
                  >
                    {constants.NavbarText.logout}
                  </a>
                </li>
              </OverlayMenu>
            ) : (
              <OverlayMenu
                isLoggedIn={isLoggedIn}
                ref={ref}
                open={toggle}
                className="Menu-list-modal"
              >
                <li
                  className="nav-item-mobile list"
                  style={{ display: "flex", listStyleType: "none" }}
                >
                  <Link
                    to={`${routeName}/overview`}
                    className="nav-links"
                    onClick={closeMobileMenu}
                  >
                    {constants.NavbarText.ubmeEvents}
                  </Link>
                </li>
                <li
                  className="nav-item-mobile list"
                  style={{ display: "flex", listStyleType: "none" }}
                >
                  <Link
                    to={`${routeName}/pricing`}
                    className="nav-links"
                    onClick={closeMobileMenu}
                  >
                    {constants.NavbarText.Pricing}
                  </Link>
                </li>
                <li
                    className="nav-item-mobile list"
                    style={{ display: "flex", listStyleType: "none" }}
                >
                  <Link
                      to={`${routeName}/FrequentlyAskedQuestions`}
                      className="nav-links"
                      onClick={closeMobileMenu}
                  >
                    {constants.NavbarText.FrequentlyAskedQuestions}
                  </Link>
                </li>
                <li
                  className="nav-item-mobile list"
                  style={{ display: "flex", listStyleType: "none" }}
                >
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      localStorage.removeItem("CurrentEventId");
                      localStorage.removeItem("isRecurring");
                      !isLoggedIn
                        ? openModal("Dashboard/About")
                        : cleareEventsAndCreateNewone();
                    }}
                    className="nav-links"
                  >
                    {constants.NavbarText.createEvent}
                  </span>
                </li>
                <li
                  className="nav-item-mobile list"
                  style={{ display: "flex", listStyleType: "none" }}
                >
                  <a
                    href="#"
                    className="nav-links"
                    onClick={() => {
                      closeMobileMenu();
                      openModal();
                    }}
                  >
                    {constants.NavbarText.signin}
                  </a>
                </li>
              </OverlayMenu>
            )}
          </Overlay>
        </div>
      </nav>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    favoriteData: state.favoriteReducer.favoriteData,
    cardData: state.favoriteReducer.cardData,
    results: state.loginResponse.results,
    isLoggedIn: state.loginResponse.isLoggedIn,
    loginPopUp: state.loginResponse.loginPopUp,
    loginHistory: state.loginResponse.loginHistory,
    pushhistory: state.loginResponse.history,
    unreadCount: state.messageReducer.unreadCount,
  };
};
const matchDispatchToProps = (dispatch) => {
  return {
    logoutAction: () => dispatch(logoutAction),
    LoginPopup: () => dispatch(LoginPopup),
    SetHistory: () => dispatch(SetHistory),
    clearEventDetails: () => dispatch(clearEventDetails),
    removeEventByEventUser: () => dispatch(removeEventByEventUser),
    dispatch,
  };
};
export default connect(mapStateToProps, matchDispatchToProps)(Navbar);
